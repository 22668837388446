import cx from 'classnames'
import React from 'react'

import { useIntl } from '@babylon/intl'

import withData from './data'

import styles from './styles.module.scss'

const mapping = {
  PRESCRIPTION_VOIDED: {
    label: 'Voided by: ',
    classname: styles.red,
  },
  PRESCRIPTION_APPROVED: {
    label: 'Approved by: ',
  },
  PRESCRIPTION_DRAFTED: {
    label: 'Prescribed by: ',
  },
}
const AuditAlerts = ({ data }) => {
  const intl = useIntl()
  const audit = data.prescriptionAudit
  return audit ? (
    audit.map((event) => {
      const details = mapping[event.eventType]
      return (
        <div className={cx(styles.alert, details.classname)} key={event.id}>
          <div className={styles.description}>
            {`${details.label} ${event.performedByName} at ${intl.formatDate(
              event.eventTimestamp,
              {
                format: 'shortWithTime',
              }
            )}. Note: ${event.eventDetails}`}
          </div>
        </div>
      )
    })
  ) : (
    <div />
  )
}

export default withData(AuditAlerts)
