import cn from 'classnames'
import React from 'react'
import useResizeObserver from 'use-resize-observer'

import { Spinner, Tag, Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { ConsultationScheduleItemPluginInterface } from '~/core/config/modules/generated/types'
import PatientNameDisplay from '~/core/patientNameFormatter'
import {
  Appointment,
  Consultation,
  ConsultationScheduleItemDetailsQuery,
} from '~/generated'
import Link from '~/ui/Link'

import PanelIcon from './PanelIcon'
import useHasRecentIncompleteConsultationsQuery from './useHasRecentIncompleteConsultationsQuery'
import { createAlertList, leftIconMap, ScheduleStatus } from './utils'

import messages from './ConsultationScheduleItem.messages'
import styles from './styles.module.scss'

const useIsNarrow = (breakpoint: number) => {
  const { width, ref } = useResizeObserver<HTMLDivElement>()

  const isNarrow = Boolean(width && width < breakpoint)

  return [ref, isNarrow] as const
}

export type ConsultationDetails = NonNullable<
  ConsultationScheduleItemDetailsQuery['consultation']
>

const extractPatient = (
  patientResult: ConsultationDetails['patient'] | undefined
) => {
  if (patientResult?.__typename === 'Patient') {
    return patientResult
  }

  return null
}

// *** START: Example of pattern we should use for components that need extra params injected ***
type InjectedProps = {
  p1: number
  p2: string
}

type LocalProps = {
  x: number
  y: string
  z: boolean
}

export const ComponentFactory = ({ p1, p2 }: InjectedProps) => ({
  x,
  y,
  z,
}: LocalProps) => (
  <div>
    <span>{p1}</span>
    <span>{p2}</span>
    <span>{x}</span>
    <span>{y}</span>
    <span>{z}</span>
  </div>
)
// *** END: Example of pattern we should use for components that need extra params injected ***

export type ConsultationScheduleItemProps = Pick<
  Consultation,
  | 'id'
  | 'scheduledTime'
  | 'consultationType'
  | 'status'
  | 'statusV2'
  | 'patientNote'
  | 'languageRequested'
> & {
  appointment?: Pick<Appointment, 'id' | 'duration_minutes'> | null
  hasPatientImage?: ConsultationDetails['hasPatientImage'] | undefined
  patient?: ConsultationDetails['patient'] | undefined
  consumerNetwork?: ConsultationDetails['consumerNetwork'] | undefined
  loadingDetails?: boolean
  onClick?: () => void
  scheduleStatus: ScheduleStatus
  hideIncompleteConsultationNotifications: boolean
}

type ConsultationScheduleItemComponent = (
  options: Parameters<ConsultationScheduleItemPluginInterface>[0] &
    ConsultationScheduleItemProps
) => JSX.Element

const ConsultationScheduleItem: ConsultationScheduleItemComponent = ({
  scheduleStatus,
  id: consultationId,
  status: consultationStatus,
  statusV2,
  scheduledTime,
  appointment,
  consultationType,
  patientNote,
  hasPatientImage,
  languageRequested,
  patient,
  consumerNetwork,
  onClick,
  hideIncompleteConsultationNotifications,
  loadingDetails,
  CheckIn,
}) => {
  const { formatMessage: fm, formatTime } = useIntl()

  const [containerRef, isNarrow] = useIsNarrow(600)

  const {
    id: patientId,
    firstName = '',
    lastName = '',
    dob,
    age,
    gender,
    minor,
    alerts,
  } = extractPatient(patient) || {}

  const isNoShow = scheduleStatus === ScheduleStatus.noShow
  const textColor = isNoShow ? 'light-grey' : 'light-grey-type'

  const patientInfo = () => {
    if (patient?.__typename !== 'Patient') {
      return (
        <Text bold size="large" color="light-grey-type">
          {fm(messages.failed_to_load_patient)}
        </Text>
      )
    }

    const formattedDob = dob
      ? fm(messages.age_unit, {
          ageInYears: age,
        })
      : '-'

    const isUpcoming = scheduleStatus === ScheduleStatus.upcoming
    const isDue = scheduleStatus === ScheduleStatus.due

    return (
      <div className={styles.patientInfo}>
        <Text
          bold
          size="large"
          color={isNoShow ? 'light-grey' : 'dark-grey-type'}
        >
          <PatientNameDisplay firstName={firstName} lastName={lastName} />
        </Text>
        <div>
          {gender && (
            <Text className={styles.gender} color={textColor}>
              ({gender}){' '}
            </Text>
          )}
          <Text className={styles.dob} color={textColor}>
            {formattedDob}
          </Text>
          <Tag
            margin={false}
            className={styles.consumerNetwork}
            color={
              isUpcoming || isDue
                ? 'clinical-green'
                : isNoShow
                ? 'white-on-light-grey'
                : 'white-on-type-grey'
            }
          >
            {consumerNetwork?.__typename === 'ConsumerNetwork'
              ? consumerNetwork.name
              : fm(messages.consumer_network_not_available)}
          </Tag>
        </div>
      </div>
    )
  }

  const hasRecentIncompleteConsultations = useHasRecentIncompleteConsultationsQuery(
    {
      scheduledTime,
      patientId,
      consultationStatus,
      consultationId,
    }
  )

  const showRecentIncompleteConsultations =
    !hideIncompleteConsultationNotifications && hasRecentIncompleteConsultations

  const alertValues = createAlertList({
    formatMessage: fm,
    scheduleStatus,
    patientAlerts: alerts || undefined,
    minor,
    languageRequested,
    hasPatientImage,
    showRecentIncompleteConsultations,
  })

  const duration = appointment?.duration_minutes

  return (
    <div ref={containerRef} data-testid="consultation-schedule-item">
      <Link
        to={`/consultation/${consultationId}`}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick()
          }
        }}
        className={cn(styles.consultationSlot, isNarrow && styles.narrow)}
      >
        <div className={styles.time} data-public>
          <Text tag="div" size="large" className={styles.scheduledTime}>
            {formatTime(scheduledTime)}
          </Text>
          <Text tag="div" color="grey-type" className={styles.duration}>
            {duration
              ? fm(messages.duration, {
                  min: duration,
                })
              : '--'}
          </Text>
        </div>

        <div className={cn(styles.panel, styles[scheduleStatus])}>
          {loadingDetails ? (
            <Spinner
              testid="spinner"
              color="#87919e"
              className={styles.spinner}
            />
          ) : (
            <div className={styles.grid}>
              <div
                className={leftIconMap[scheduleStatus] || styles.leftIcon}
                data-testid={`consultation-status-${scheduleStatus}`}
              >
                <PanelIcon
                  consultationType={consultationType}
                  scheduleStatus={scheduleStatus}
                />
              </div>

              <div
                className={cn(styles.centreInfo, {
                  [styles.span2]: alertValues.length === 0,
                })}
              >
                <div className={styles.details}>
                  <div>{patientInfo()}</div>
                  <Text
                    className={styles.patientNote}
                    color={textColor}
                    tag="div"
                  >
                    {patientNote}
                  </Text>
                </div>

                {isFilledArray(alertValues) ? (
                  <div className={styles.rightInfo}>{alertValues}</div>
                ) : null}
              </div>
              {CheckIn && (
                <div className={styles.checkInContainer}>
                  <CheckIn
                    consultationId={consultationId}
                    scheduledTime={scheduledTime}
                    consultationStatus={statusV2}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default ConsultationScheduleItem
