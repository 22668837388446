import React, { useEffect, useRef } from 'react'
import { IntlShape } from 'react-intl'
import { Router } from 'react-router-dom'

import { useConsultantUser } from '@babylon/babylon-user'
import { useChangeLocale } from '@babylon/intl'
import clipboard from '@babylon/restricted-clipboard'
import { setLogRocketUser } from '@babylon/tracking/logrocket'

import { ENABLE_CONFIG_OVERWRITE_EDITOR } from '~/constants'
import analytics, { initialiseAnalytics } from '~/core/analytics'
import { Apollo } from '~/core/apollo'
import apolloClient from '~/core/apollo/client'
import { Authentication, initialiseAuth } from '~/core/auth'
import ConfigToolbar from '~/core/config-toolbar'
import { GlobalOptionsProvider } from '~/core/core-modules'
import ErrorBoundary from '~/core/ErrorBoundary'
import { useMessages } from '~/core/hooks'
import I18n from '~/core/i18n'
import { resolveLocale } from '~/core/i18n/locale'
import { modulePermissions } from '~/core/module-permissions'
import { PermissionProvider } from '~/core/permissions'
import { initialiseSentry, setSentryUserContext } from '~/core/sentry'
import initialiseValidation from '~/core/validation'
import ErrorPage from '~/ui/ErrorPage'

import GlobalErrorBoundary from './GlobalErrorBoundary'
import { history } from './history'
import Overlays from './Overlays'
import useInitConfig from './useInitConfig'

import messages from './Shell.messages'

/**
 * Initialisers
 */
initialiseSentry()
initialiseAuth()
initialiseAnalytics()

const onIntlReady = (intl: IntlShape) => {
  const copyBlockerMessage = intl.formatMessage(
    messages.copying_not_allowed_text
  )

  initialiseValidation(intl.formatMessage)

  if (process.env.NODE_ENV !== 'development') {
    clipboard.init(copyBlockerMessage)
  }
}

export const Authenticated = () => {
  const f = useMessages(messages)
  const partners = useRef<string[]>([])

  const user = useConsultantUser()
  useEffect(() => {
    analytics.setUser(user.uuid)
    setSentryUserContext(user)
    setLogRocketUser(user)
  }, [user])

  if (user.partnerIds) {
    partners.current = user.partnerIds
  }

  const { globalOptions, layout, configError } = useInitConfig(
    partners.current,
    user.uuid
  )

  const updateLocale = useChangeLocale()

  useEffect(() => {
    if (globalOptions?.languagesAvailable) {
      const locale = resolveLocale(globalOptions.languagesAvailable)
      updateLocale(locale)
    }
  }, [globalOptions, updateLocale])

  if (configError) {
    const itServiceDeskUrl = globalOptions?.support?.itServiceDeskUrl

    return (
      <ErrorPage
        title={f('config_error_title')}
        message={f('config_error_message')}
        itServiceDeskUrl={itServiceDeskUrl}
      />
    )
  }

  if (!globalOptions || !layout) {
    return null
  }

  return (
    <GlobalOptionsProvider value={globalOptions}>
      <ErrorBoundary gaAction="shell_error_boundary" fill="screen">
        <PermissionProvider permissions={modulePermissions}>
          <Router history={history}>{layout}</Router>
        </PermissionProvider>
        <Overlays />
      </ErrorBoundary>
    </GlobalOptionsProvider>
  )
}

const Shell = () => (
  <>
    <GlobalErrorBoundary>
      <I18n onIntlReady={onIntlReady}>
        <Apollo client={apolloClient}>
          <Authentication>
            <Authenticated />
          </Authentication>
        </Apollo>
      </I18n>
    </GlobalErrorBoundary>
    {ENABLE_CONFIG_OVERWRITE_EDITOR && <ConfigToolbar />}
  </>
)

export default Shell
