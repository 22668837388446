const retrieveComments = (reportId) => {
  const value = sessionStorage.getItem(reportId) || '{}'
  return JSON.parse(value)
}

export const retrieveComment = (reportId, profileCode) =>
  retrieveComments(reportId)[profileCode]

export const saveComment = (reportId, profileCode, comment) => {
  const comments = retrieveComments(reportId)
  comments[profileCode] = comment
  sessionStorage.setItem(reportId, JSON.stringify(comments))
}

export const clearComments = (reportId) => sessionStorage.removeItem(reportId)

export const mapComments = (reportId) => mapForGql(retrieveComments(reportId))

export const mapForGql = (comments) =>
  Object.keys(comments)
    .filter((code) => comments[code])
    .map((code) => ({ code, comment: comments[code] }))
