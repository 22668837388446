import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { withEnhancedRouter } from '~/core/compose'

import GpDetails from './GpDetails'

const enhance = compose(injectIntl, withEnhancedRouter)

export default enhance(GpDetails)
