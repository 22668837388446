import { NetworkStatus } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'

import { observableValue } from '~/core/container'
import { CpConsultationPageQuery } from '~/generated'

export interface FeatureFlags {
  athenaDeepLinkEnabled?: boolean
  patientRecordDeepLinkEnabled?: boolean
  carePlanEnabled?: boolean
  consultationCheckInDetailsEnabled?: boolean
  readOnlyCompleteConsultationNotesEnabled?: boolean
  carePlanInPatientHistoryEnabled?: boolean
  preCallCheckEnabled?: boolean
  appointmentInvitesEnabled?: boolean
  recurringInvitesEnabled?: boolean
  recurringInvitesMultipleInvitesEnabled?: boolean
  recurringInvitesBookingForPatientEnabled?: boolean
  recurringInvitesBiweeklyBookingEnabled?: boolean
  serviceTypesInvitesEnabled?: boolean
  f2FMediumServiceTypeInvitesSupported?: boolean
  restrictCompleteConsultEditEnabled?: boolean
  showEditedFinalizedConsultationWarningEnabled?: boolean
  readOnlyCompleteConsultationActionsEnabled?: boolean
  editedFinalizedConsultationWarningEnabled?: boolean
  memberProfileBupaTagEnabled?: boolean
  noteAssistantAudioCallsEnabled?: boolean
  noteAssistantExternalCopyEnabled?: boolean
  noteAssistantSummaryEnabled?: boolean
  upNextNotificationsEnabled?: boolean
  callRecordingEnabled?: boolean
  futureTimelineSectionEnabled?: boolean
  notesHighlightOnPasteEnabled?: boolean
  transcriptCollectionEnabled?: boolean
  signatureRxClinicianPinEnabled?: boolean
  showClinicianPinValidationDisabledNotice?: boolean
  overrideIdEnabled?: boolean
  multiwayCallingEnabled?: boolean
  clinicianViewFinderEnabled?: boolean
  newMmsCfsEnabled?: boolean
  fastCtIndicationsEnabled?: boolean
  showPatientRejectedVideoCall?: boolean
  useNewTwilioSdk?: boolean
  voiceVoipEnabled?: boolean

  /**
   * Shows/hides toggle in Profile header dropdown.
   */
  availableForNewTherapyToggleEnabled?: boolean
  clinicalPresenceHeartbeatEnabled?: boolean
  videoStreamWindowImprovementsEnabled?: boolean

  // Changes support links to point to Salesforce rather than Zendesk or ServiceNow.
  salesforceSupportEnabled?: boolean

  // Changes support links to point to ServiceNow rather than Zendesk.
  serviceNowSupportEnabled?: boolean

  // Enables a virtual background in the clinicians video, with a toggle for clinicians to enable or disable it. (Enabled by default)
  virtualBackgroundEnabled?: boolean

  // Enables the floating chat bubble that connects clinicians to ServiceNow support agents.
  // Must be set with `serviceNowSupportEnabled`.
  serviceNowChatBubbleEnabled?: boolean

  // Enable patient document table in the patient details section for consultations
  documentRepositoryEnabled?: boolean
}

export interface Experiment {
  title: string
  description: string
  enabledForEveryone?: boolean
}

export interface Globals {
  featureFlags?: FeatureFlags
  autoLoadModules?: any[]
  allowedBrowsers?: string[]
  languagesAvailable?: string
  support?: {
    clinicalServiceDeskUrl?: string
    itServiceDeskUrl?: string
    riskManagementSystemUrl?: string
  }
  patientNameDisplayType?: string
  releaseNoteCountryCode?: string
  hideIncompleteConsultationNotifications?: boolean
  experiments?: Experiment[]
  postConsultationFormLink?: string
  noteAssistant?: {
    audioRecordingInterval?: number // The interval that we send audio chunks (Default = 3 seconds)
    fetchNotesAttempts?: number // How many attempts at fetching the notes before failing (Default = 2 attempts)
    fetchNotesRetryDelay?: number // The delay between attempts to fetch the notes (Default = 200ms)
    recordingRestartInterval?: number // The interval that we stop recording and start a new file (Default = 4 minutes)
    sendAudioAttempts?: number // How many attempts at sending the audio before failing (Default = 2 attempts)
    sendAudioRetryDelay?: number // The delay between attempts to send that audio (Default = 200ms)
    summaryPollInterval?: number // The interval we poll for generated notes (Default = 5 seconds)
    stopPollingDelay?: number // The delay after the call has ended that we stop polling for notes and status (Default = 15 seconds)
  }
  salesforce?: {
    organizationId?: string
    deploymentId?: string
    endpointUrl?: string
    scrt2Url?: string
    language?: string
  }
}

export const Global = (options: Globals) => options

const GlobalsContext = React.createContext<Globals>({})

export const GlobalOptionsProvider = GlobalsContext.Provider

/**
 * Returns features config blob
 */
export const useGlobals = (): Globals => useContext(GlobalsContext)

/**
 * Returns feature flags
 */
export const useFeatureFlags = (): FeatureFlags => {
  return useGlobals().featureFlags || {}
}

type Consultation = NonNullable<CpConsultationPageQuery['consultation']>

const createResult = (consultation: Consultation) => ({
  refetch: () => {},
  data: { consultation },
  loading: false,
  networkStatus: NetworkStatus.ready,
  stale: false,
})

export const useContextFromLegacyConsultation = (
  consultation: Consultation
) => {
  const [observable] = useState(() =>
    observableValue(createResult(consultation))
  )

  useEffect(() => {
    if (observable.get()?.data?.consultation !== consultation) {
      observable.next(createResult(consultation))
    }
  }, [consultation, observable])

  return {
    ...observable,
    next() {
      console.log("It's not allowed to update consultation in a static context")
    },
    reset() {
      console.log("It's not allowed to reset consultation in a static context")
    },
    nextResult() {
      console.log("It's not allowed to update consultation in a static context")
    },
  }
}
