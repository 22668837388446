import { defineMessages } from 'react-intl'

const baseId = 'consultation.complete_dialog.mark_as_sensitive_extension'

export default defineMessages({
  yes_send_summary_to_parents: {
    id: `${baseId}.yes_send_summary_to_parents`,
    defaultMessage: 'Yes, send notes to parents or guardian',
  },
  no_dont_send_summary_to_parents: {
    id: `${baseId}.no_dont_send_summary_to_parents`,
    defaultMessage: 'No, do not send notes to parents or guardian',
  },
  parent_information_warning: {
    id: `${baseId}.parent_information_warning`,
    defaultMessage:
      "Remember: Information contained in this summary is sent to the parent's account.",
  },
  send_summary_notes: {
    id: `${baseId}.send_summary_notes`,
    defaultMessage: 'Send consultation notes to the patient',
  },
  this_patient_is_a_minor: {
    id: `${baseId}.this_patient_is_a_minor`,
    defaultMessage:
      'Send consultation notes to patient’s parents or guardian? They will get a copy of your notes.',
  },
  minor: {
    id: `${baseId}.minor`,
    defaultMessage: 'Minor',
  },
  error_complete_without_selection: {
    id: `${baseId}.error_complete_without_selection`,
    defaultMessage:
      'You must select whether you want to send the notes to the patient or not.',
  },
  error_choose_one_option: {
    id: `${baseId}.error_choose_one_option`,
    defaultMessage: 'Choose one of the options',
  },
  error_marking_as_sensitive: {
    id: `${baseId}.error_marking_as_sensitive`,
    defaultMessage:
      'Error marking note as sensitive. Please try again or contact support',
  },
  loading_notes: {
    id: `${baseId}.loading_notes`,
    defaultMessage: 'Please wait while we check all the appointment details.',
  },
  error_loading_notes: {
    id: `${baseId}.error_loading_notes`,
    defaultMessage:
      "We're sorry, but we were not able to load the notes for this appointment. Please ensure that the athenahealth encounter has been signed and closed.",
  },
})
