import * as Sentry from '@sentry/browser'
import { useEffect, useState } from 'react'

import { logException } from '~/core/sentry'

export const CLAIM_MESSAGES = 2 // NOTE: can't be changed without generalising the code first
export const CLAIM_ACKNOWLEDGMENT_DURATION = 250

export const isBroadcastChannelUnsupported = () => {
  return !BroadcastChannel
}

export enum TaintedInfo {
  Pending,
  Safe,
  SafeButMirrored,
  Tainted,
  TaintedButRefreshable,
}

type BasicTaintedInfo =
  | TaintedInfo.Pending
  | TaintedInfo.Safe
  | TaintedInfo.Tainted

type RouteClaimMessage = {
  type: 'route-claim'
  route: string
  id: string
}

type RouteClaimDeniedMessage = {
  type: 'route-claim-denied'
  route: string
  id: string
}

type RouteClaimRelinquishedMessage = {
  type: 'route-claim-relinquished'
  route: string
  id: string
}

type RouteClaimMessageType =
  | RouteClaimMessage
  | RouteClaimDeniedMessage
  | RouteClaimRelinquishedMessage

const routeClaimMessage = (route: string, id: string): RouteClaimMessage => ({
  type: 'route-claim',
  route,
  id,
})

const routeClaimDeniedMessage = (
  route: string,
  id: string
): RouteClaimDeniedMessage => ({
  type: 'route-claim-denied',
  route,
  id,
})

const routeClaimRelinquishedMessage = (
  route: string,
  id: string
): RouteClaimRelinquishedMessage => ({
  type: 'route-claim-relinquished',
  route,
  id,
})

export const taintedInfoEffect = (
  route: string,
  setTaintedInfo: (taintedInfo: TaintedInfo) => void,
  disabled = false
) => () => {
  if (disabled) {
    return () => null
  }

  const bc = new BroadcastChannel('route-claims')
  const id = String(Date.now() + Math.random())
  const externalWindowIds: Record<string, boolean> = {}
  let basicTaintedInfo: BasicTaintedInfo = TaintedInfo.Pending

  const postMessage = (
    message: RouteClaimMessageType,
    errorLocation: string
  ) => {
    try {
      bc.postMessage(message)
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setExtras({ errorLocation })
        logException(err)
      })
    }
  }

  const updateTaintedInfo = (
    updatedBasicTaintedInfo: BasicTaintedInfo = basicTaintedInfo
  ) => {
    const detectedTabCount = Object.keys(externalWindowIds).length
    const isSafeButMirrored =
      detectedTabCount > 0 && basicTaintedInfo === TaintedInfo.Safe
    const isTaintedButRefreshable =
      detectedTabCount === 0 && basicTaintedInfo === TaintedInfo.Tainted

    basicTaintedInfo = updatedBasicTaintedInfo

    setTaintedInfo(
      isSafeButMirrored
        ? TaintedInfo.SafeButMirrored
        : isTaintedButRefreshable
        ? TaintedInfo.TaintedButRefreshable
        : basicTaintedInfo
    )
  }

  const onClaimAcknowledgmentExpiry = () => {
    if (basicTaintedInfo === TaintedInfo.Pending) {
      updateTaintedInfo(TaintedInfo.Safe)
    }
  }

  const claimAcknowledgmentTimeoutId = setTimeout(
    onClaimAcknowledgmentExpiry,
    CLAIM_ACKNOWLEDGMENT_DURATION
  )

  const subsequentRouteClaimTimeoutId = setTimeout(() => {
    postMessage(routeClaimMessage(route, id), 'subsequentRouteClaimTimeout')
  }, CLAIM_ACKNOWLEDGMENT_DURATION / CLAIM_MESSAGES)

  const cleanUp = () => {
    clearTimeout(claimAcknowledgmentTimeoutId)
    clearTimeout(subsequentRouteClaimTimeoutId)
    window.removeEventListener('beforeunload', cleanUp)
    postMessage(routeClaimRelinquishedMessage(route, id), 'cleanUp')
    bc.close()
  }

  window.addEventListener('beforeunload', cleanUp)

  bc.onmessage = (eventObj) => {
    const event: RouteClaimMessageType = eventObj.data

    if (event.route === route) {
      externalWindowIds[event.id] = true

      if (event.type === 'route-claim') {
        postMessage(routeClaimDeniedMessage(route, id), 'routeClaim')
        updateTaintedInfo()
      } else if (event.type === 'route-claim-denied') {
        updateTaintedInfo(TaintedInfo.Tainted)
      } else if (event.type === 'route-claim-relinquished') {
        delete externalWindowIds[event.id]
        updateTaintedInfo()
      }
    }
  }

  postMessage(routeClaimMessage(route, id), 'taintedInfoEffect')

  return cleanUp
}

/**
 * Determine additional tabs or windows the user has open for the same route.
 *
 * @param route Typically the parent route so that sub-routes can be ignored (e.g. using `match.url` from `withRouter`)
 */
const useTaintedInfo = (route: string) => {
  const [taintedInfo, setTaintedInfo] = useState<TaintedInfo>(
    TaintedInfo.Pending
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    taintedInfoEffect(route, setTaintedInfo, isBroadcastChannelUnsupported()),
    [route]
  )

  return taintedInfo
}

export default useTaintedInfo
