import qs from 'qs'
import validator from 'validator'

import { pickTruthyValues } from '@babylon/shared-utils'

const httpRx = /^https?:\/\//

export const normalizeUrl = (url) => (httpRx.test(url) ? url : `http://${url}`)

/**
 * Add items to current query string
 *
 * @param {String} queryString Query string
 * @returns {Object} Pagination criteria object
 *
 * @author Tameem Safi
 */
export const addItemsToQueryString = (queryString, items) => {
  const currentQueryVariables = pickTruthyValues(
    qs.parse(queryString, {
      skipNulls: true,
      ignoreQueryPrefix: true,
    })
  )

  const newQueryVariables = {
    ...(currentQueryVariables || {}),
    ...items,
  }

  return qs.stringify(newQueryVariables, {
    addQueryPrefix: true,
  })
}

/**
 * Check if filter panel should be open or closed based on query string
 *
 * @param {String} queryString Query string
 * @returns {Boolean} Wether or not filter panel is open
 *
 * @author Tameem Safi
 */

export const getQueryVariables = (queryString) => {
  if (!queryString) {
    return {}
  }

  return pickTruthyValues(
    qs.parse(queryString, {
      skipNulls: true,
      ignoreQueryPrefix: true,
    })
  )
}

export const isValidURL = (url) =>
  validator.isURL(url, {
    protocols: ['http', 'https'],
    require_tld: true,
    require_protocol: true,
    require_host: true,
    require_valid_protocol: true,
    allow_underscores: false,
    host_whitelist: false,
    host_blacklist: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
  })
