import { gql } from '@apollo/client'

export const prescriptionFieldsFragment = gql`
  fragment PrescriptionFieldsFragment on Prescription {
    id
    actions
    createdAt
    prescriptionState
    drugs {
      prescriptionDrugId
      drugId
      drugName
      dosage
      duration
      pharmaceuticalForm
      strength
      quantity
      packagingSize
      directions
      notes
      repeatable
      repeats
      refills
      reviewDate
      indications {
        id
        name
        iri
      }
    }
  }
`
