import classnames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

const EmptyMessage = ({ className, ...props }: any) => (
  <div {...props} className={classnames(styles.message, className)} />
)

export default EmptyMessage
