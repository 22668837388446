import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.nhs_appointment_invites_section'

export default defineMessages({
  workflow_action_nhs_appointment_name: {
    id: `${baseId}.workflow_action_nhs_appointment_name_v2`,
    defaultMessage: 'NHS appointment',
  },
  create_button_label: {
    id: `${baseId}.create_button_label`,
    defaultMessage: 'Create NHS appointment',
  },
  appointment_query_error: {
    id: `${baseId}.appointment_query_error`,
    defaultMessage: 'Error loading appointments',
  },
})
