import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'

import { Button, Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { ALERTS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useConsultation } from '~/core/config'
import { PatientAlertsPluginInterface } from '~/core/config/modules/generated/types'
import SignerAlert from '~/features/prescriptions/SignerAlert'
import { useConsultationAlertsQuery } from '~/generated'
import EmptyMessage from '~/ui/EmptyMessage'
import { ErrorPanel } from '~/ui/ErrorPanel'
import Message from '~/ui/Message'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import PatientAlertsModal from '../PatientAlertsModal'

import messages from './PatientAlertsSection.messages'
import styles from './styles.module.scss'

export type FormattedAlert = {
  id: string
  label: string
  type: 'info' | 'warning' | 'error'
  message: string | undefined | null
  onClick?: () => void
}

const PatientAlertsSection: PatientAlertsPluginInterface = ({
  disabled = false,
  consultationContext,
}) => {
  const consultation = useConsultation(consultationContext)
  const consultationId = consultation.id
  const match = useRouteMatch()
  const history = useHistory()
  const { formatMessage: fm, formatDate } = useIntl()

  const { data, loading, error, refetch } = useConsultationAlertsQuery({
    variables: { consultationId },
  })

  if (loading) {
    return <Section title={fm(messages.title)} loading />
  }

  const patientAlerts = data?.consultation?.patient?.patient_alerts
  const patientId = data?.consultation?.patient.uuid

  if (error || !data?.consultation || !patientAlerts || !patientId) {
    return (
      <ErrorPanel
        error={error || new Error('Patient alerts failed to load')}
        title={fm(messages.error_loading_alerts)}
        retry={() => refetch()}
      />
    )
  }

  const {
    promoCodeMessageForGp,
    membershipCodeMessageForGp,
    patient: { canCreatePrescriptionPdf },
  } = data.consultation

  const redirectToCreatePage = () => {
    history.replace(`${match.url}/patient-alert/create`)
  }

  const createAlertClickHandler = (id: string) => () => {
    const url = `${match.url}/patient-alert/edit?id=${id}`
    history.replace(url)
  }

  const createFormattedAlerts = (): FormattedAlert[] => {
    const promoData = [
      {
        label: fm(messages.promo_code_label),
        message: promoCodeMessageForGp,
        shouldDisplay: !!promoCodeMessageForGp,
      },
      {
        label: fm(messages.membership_code_label),
        message: membershipCodeMessageForGp,
        shouldDisplay: !!membershipCodeMessageForGp,
      },
    ]

    const promos = promoData
      .filter(({ shouldDisplay }) => shouldDisplay)
      .map(
        ({ label, message }): FormattedAlert => ({
          id: label,
          label,
          type: 'info',
          message,
        })
      )

    const sortByUpdatedAt = (
      a: { updatedAt?: string | null },
      b: { updatedAt?: string | null }
    ) => {
      if (!a.updatedAt || !b.updatedAt) {
        return 0
      }
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    }

    const sortedAlerts = [...patientAlerts].sort(sortByUpdatedAt)

    const alerts = sortedAlerts.map<FormattedAlert>((alert) => ({
      id: alert.id,
      type: 'error',
      label: alert.updatedAt
        ? `${fm(messages.last_updated)} ${formatDate(alert.updatedAt, {
            format: 'short',
          })}`
        : '',
      message: alert.message,
      onClick: createAlertClickHandler(alert.id),
    }))

    return [...promos, ...alerts]
  }

  const formattedAlerts = createFormattedAlerts()

  const signerAlert = (
    <SignerAlert
      valid={canCreatePrescriptionPdf}
      message={fm(messages.update_patient_details_instructions)}
    />
  )

  const isEmpty = formattedAlerts.length === 0 && !signerAlert

  return (
    <>
      <Section
        title={fm(messages.title)}
        dataTestId="alerts-section"
        topRight={
          <Button
            disabled={disabled}
            intent="secondary"
            onClick={redirectToCreatePage}
          >
            {fm(messages.create_alert_button_label)}
          </Button>
        }
      >
        {signerAlert}
        {isEmpty ? (
          <EmptyMessage>{fm(messages.no_alerts_message)}</EmptyMessage>
        ) : (
          formattedAlerts.map((alert) => (
            <Message
              key={alert.id}
              type={alert.type}
              onClick={alert.onClick}
              dataTestId="alert"
            >
              <Text tag="p" className={styles.alertText}>
                {alert.message}
              </Text>
              <Text
                tag="p"
                className={styles.lastUpdated}
                color="light-grey-type"
              >
                {alert.label}
              </Text>
            </Message>
          ))
        )}
      </Section>
      <Route path="/consultation/:consultationId/patient-alert/:mode(create|edit)">
        <PatientAlertsModal patientId={patientId} />
      </Route>
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: ALERTS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(PatientAlertsSection)
