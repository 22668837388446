import React from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { SignatureRxPinPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { usePermissions } from '~/core/permissions'
import { useObservable } from '~/core/reactive-helpers'
import { PrescriptionPromotionStateType } from '~/features/complete-consultation'
import { CompleteDialogViewDefaultMessage } from '~/features/complete-consultation/CompleteDialogPlugin/CompleteDialogView'
import { SignatureRxPinView } from '~/features/complete-consultation/SignatureRxPinExtension/SignatureRxPinView'
import {
  usePrescriptionQuery,
  useRegenerateClinicianPinMutation,
} from '~/generated'

export const SignatureRxPinContainer: SignatureRxPinPluginInterface = ({
  prescriptionPromotionModel,
  consultationContext,
}) => {
  const [canCreatePrescription] = usePermissions(
    'create_prescription_for_appointment'
  )
  const {
    signatureRxClinicianPinEnabled,
    showClinicianPinValidationDisabledNotice,
  } = useFeatureFlags()

  const consultation = consultationContext.get()
  const updatePin = prescriptionPromotionModel.updatePin

  const { pin, errorMessage } = useObservable(
    prescriptionPromotionModel.state,
    {} as PrescriptionPromotionStateType
  )

  const consultationData = consultation?.data
  const user = useConsultantUser()
  const consultantId = user?.consultant?.uuid || ''
  const consultationId = consultation?.data?.consultation?.id || ''
  const [
    regenerateClinicianPinMutation,
    { data: resetData, loading: resetLoading },
  ] = useRegenerateClinicianPinMutation({ variables: { id: consultantId } })

  const { data, loading: prescriptionsLoading } = usePrescriptionQuery({
    variables: {
      id: consultationId,
    },
    skip: !canCreatePrescription || consultationId === '',
  })

  const prescriptions =
    data?.consultation?.prescriptions?.filter(
      (index) => index.prescriptionState !== 'REJECTED'
    ) ?? []

  const hasDraftPrescriptions = prescriptions.some(
    (prescription) => prescription?.prescriptionState === 'DRAFT'
  )

  // If you can't create a prescription, or if there are no prescriptions then return the normal message
  if (
    !(
      canCreatePrescription &&
      signatureRxClinicianPinEnabled &&
      hasDraftPrescriptions &&
      consultationData?.consultation?.region?.iso_code === 'GBR'
    )
  ) {
    return <CompleteDialogViewDefaultMessage />
  }

  return (
    <SignatureRxPinView
      updatePin={updatePin}
      resetPin={regenerateClinicianPinMutation}
      resetData={resetData}
      resetLoading={resetLoading}
      pin={pin}
      loading={prescriptionsLoading}
      error={errorMessage}
      prescriptions={prescriptions}
      displayDeveloperPinNotice={showClinicianPinValidationDisabledNotice!}
    />
  )
}
