import { defineMessages } from 'react-intl'

const baseId = 'validation'

export default defineMessages({
  required_field: {
    id: `${baseId}.required_field`,
    defaultMessage: 'This field is required',
  },
  invalid_email: {
    id: `${baseId}.invalid_email`,
    defaultMessage: 'Please enter a valid email address',
  },
  invalid_phone: {
    id: `${baseId}.invalid_phone`,
    defaultMessage: 'Please enter a valid phone number',
  },
  invalid_country_code: {
    id: `${baseId}.invalid_country_code`,
    defaultMessage: 'Please enter a valid country code',
  },
})
