import { useHistory, useParams } from 'react-router-dom'

const useRedirectToConsultation = () => {
  // TODO - move to be consistent and just use consultationID
  const { consultationId, id } = useParams<{
    consultationId?: string
    id?: string
  }>()

  const history = useHistory()
  const redirectId = consultationId || id

  if (redirectId) {
    return () => history.replace(`/consultation/${redirectId}`)
  }

  return () => null
}

export default useRedirectToConsultation
