import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { PastConsultationSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useConsultationSummaryBaseQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import ConsultationSummary from './ConsultationSummary'

import messages from './ConsultationSummary.messages'

interface PastConsultationSummaryRuntimeProps {
  consultationId: string
}

const PastConsultationSummary: PastConsultationSummaryPluginInterface = ({
  childComponents,
}) => {
  const Component = ({
    consultationId,
  }: PastConsultationSummaryRuntimeProps) => {
    const fm = useFormatMessage()

    const { data, loading, error } = useConsultationSummaryBaseQuery({
      variables: {
        id: consultationId,
      },
    })

    if (loading) {
      return null
    }

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={fm(messages.error_loading_consultation)}
        />
      )
    }

    if (!data?.consultation) {
      return null
    }

    return (
      <React.StrictMode>
        <ConsultationSummary
          scheduledTime={data?.consultation.scheduledTime}
          consultation={data?.consultation}
          childComponents={childComponents}
        />
      </React.StrictMode>
    )
  }
  return Component
}

export default PastConsultationSummary
