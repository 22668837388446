import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Button } from '@babylon/core-ui'

import Icon from '~/ui/Icon'

import styles from './styles.module.scss'

class DialogComponent extends React.Component {
  componentDidMount() {
    const { hideOnEsc } = this.props
    if (hideOnEsc) {
      window.addEventListener('keydown', this.onKeyDown)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  handleCancel = () => {
    const { id, onCancel, onHideRequest } = this.props

    if (onCancel) {
      onCancel(id)
    }

    onHideRequest(id)
  }

  handleOk = () => {
    const { id, onOk, hideOnOk, onHideRequest } = this.props

    onOk(id)

    if (hideOnOk) {
      onHideRequest(id)
    }

    return {
      hide: () => (hideOnOk ? null : onHideRequest(id)),
    }
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      const { id, onHideRequest } = this.props
      onHideRequest && onHideRequest(id)
    }
  }

  render() {
    const {
      title,
      icon,
      message,
      okLabel,
      cancelLabel,
      cancelStyle,
      children,
      onOk,
      onOkStyle,
      dialogStyle,
      dialogBodyStyle,
      centerContent,
      hideButtons,
      id,
      onHideRequest,
      hideOnBackgroundClick,
    } = this.props

    const onBackgroundClick = hideOnBackgroundClick
      ? () => onHideRequest && onHideRequest(id)
      : null

    return (
      <div
        className={styles.overlay}
        role="button"
        tabIndex="0"
        onClick={onBackgroundClick}
        data-testid="dialog"
      >
        <div
          role="button"
          tabIndex="0"
          onClick={(event) => event.stopPropagation()}
          className={cx(
            styles.dialog,
            dialogStyle,
            centerContent && styles.center
          )}
        >
          <div className={cx(styles.dialogBody, dialogBodyStyle)}>
            {title && (
              <div className={styles.title}>
                {icon && <Icon name={icon} />}
                {title}
              </div>
            )}
            <div className={styles.content}>
              {message && <div>{message}</div>}
              {children}
            </div>
          </div>
          {hideButtons ? null : (
            <div className={cx(styles.buttons, centerContent && styles.center)}>
              <Button inline intent={cancelStyle} onClick={this.handleCancel}>
                {cancelLabel}
              </Button>
              {onOk && (
                <Button inline intent={onOkStyle} onClick={this.handleOk}>
                  {okLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

DialogComponent.defaultProps = {
  okLabel: 'Ok',
  cancelLabel: 'Cancel',
  cancelStyle: 'secondary',
  hideOnOk: true,
  onCancel: null,
  onOk: null,
  onOkStyle: 'secondary',
  centerButtons: false,
  hideButtons: false,
  hideOnEsc: false,
  hideOnBackgroundClick: false,
}

DialogComponent.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.string,
  message: PropTypes.node,
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  okLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onOkStyle: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  hideOnOk: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onHideRequest: PropTypes.func,
  hideOnEsc: PropTypes.bool,
  hideOnBackgroundClick: PropTypes.bool,
}

export default DialogComponent
