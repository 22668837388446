import { useRef } from 'react'

const useBeforeMount = (fn: () => void) => {
  const isFirst = useRef(true)

  if (isFirst.current) {
    fn()
    isFirst.current = false
  }
}

export default useBeforeMount
