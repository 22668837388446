import { defineMessages } from 'react-intl'

const baseId = 'authentication'

export default defineMessages({
  authentication_failed: {
    id: `${baseId}.authentication_failed`,
    defaultMessage: 'Authentication failed',
  },

  authorization_failed: {
    id: `${baseId}.authorization_failed`,
    defaultMessage: 'Authorization failed',
  },
})
