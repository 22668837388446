import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.sick_notes'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private sick notes',
  },
  add_button_label: {
    id: `${baseId}.add_button_label_v2`,
    defaultMessage: 'Add sick note',
  },
})
