import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { CarePlanGoal, CarePlanGoalDraft } from '~/generated'
import DataList from '~/ui/DataList'

import messages from './CarePlanSectionView.messages'

const isDraftGoal = (
  goal: CarePlanGoal | CarePlanGoalDraft
): goal is CarePlanGoalDraft => {
  return (goal as CarePlanGoalDraft).draftId !== undefined
}

const Actions = ({
  actions,
}: {
  actions?:
    | {
        draftId?: string
        id?: string
        description?: string
      }[]
    | null
}) => {
  return (
    <>
      {actions?.map((action) => (
        <div key={action.draftId || action.id}>{action.description}</div>
      ))}
    </>
  )
}

interface GoalsAndActionsProps {
  goals?: Array<CarePlanGoal | CarePlanGoalDraft>
}

const GoalsAndActions = ({ goals = [] }: GoalsAndActionsProps) => {
  const f = useMessages(messages)

  const getDataListData = (goal: CarePlanGoal | CarePlanGoalDraft) => [
    {
      key: f('health_goal_key'),
      value: goal.description,
    },
    {
      key: f('actions_key'),
      value: (
        <Actions
          actions={
            isDraftGoal(goal)
              ? goal.associatedActionDrafts
              : goal.associatedActions
          }
        />
      ),
    },
  ]

  return goals.length === 0 ? (
    <Text
      data-testid="no_goals_and_actions"
      size="medium"
      color="grey-type"
      bold
    >
      {f('no_goals_and_actions')}
    </Text>
  ) : (
    <>
      {goals.map((goal) => {
        const data = getDataListData(goal)

        return (
          <DataList
            key={isDraftGoal(goal) ? goal.draftId : goal.id}
            data={data}
            dataTestId="goals_and_actions"
          />
        )
      })}
    </>
  )
}

export default GoalsAndActions
