import React from 'react'

import { NavigationInterface } from '~/core/config/modules/generated/types'

import styles from './styles.module.scss'

const Navigation: NavigationInterface = ({ items }) => (
  <React.StrictMode>
    <nav className={styles.sidebar} data-public>
      <ul className={styles.list} data-testid="main-navigation">
        {items.map((item, key) =>
          typeof item === 'string' &&
          item.toLowerCase().includes('-divider-') ? (
            <hr key={key} />
          ) : (
            <li key={key}>{item}</li>
          )
        )}
      </ul>
    </nav>
  </React.StrictMode>
)

export default Navigation
