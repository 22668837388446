import { defineMessages } from 'react-intl'

const baseId = 'consultation.section.test.remove'

export default defineMessages({
  title: {
    id: `${baseId}.delete_test_confirmation_title`,
    defaultMessage: 'Delete Test',
  },
  message: {
    id: `${baseId}.confirmation_message`,
    defaultMessage: 'Are you sure you want to delete the test?',
  },
  remove_button_label: {
    id: `${baseId}.remove_button_label`,
    defaultMessage: 'Remove',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
})
