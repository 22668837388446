import React, { FC, useEffect } from 'react'

import { Text } from '@babylon/core-ui'

import useDurationTimer from '~/core/hooks/useDurationTimer'

import styles from './CallDuration.module.scss'

export interface CallDurationProps {
  isCounting: boolean
  clear: boolean
}

const leftFillNum = (num: number, targetLength: number) => {
  return num.toString().padStart(targetLength, '0')
}

const formatDuration = (totalSeconds: number): string => {
  const seconds = totalSeconds % 60
  const paddedSeconds = leftFillNum(seconds, 2)
  const minutes = Math.floor(totalSeconds / 60) % 60
  const paddedMinutes = leftFillNum(minutes, 2)
  const hours = Math.floor(totalSeconds / (60 * 60))

  return `${hours}:${paddedMinutes}:${paddedSeconds}`
}

const CallDuration: FC<CallDurationProps> = ({ isCounting, clear }) => {
  const { duration, startTimer, clearTimer, pauseTimer } = useDurationTimer()

  useEffect(() => {
    if (clear) {
      clearTimer()
    }
  }, [clear]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCounting) {
      startTimer(0)
    }

    return () => {
      if (isCounting) {
        pauseTimer()
      }
    }
  }, [isCounting]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Text className={styles.duration} data-testid="call-duration-timer">
      {typeof duration === 'number' && formatDuration(duration)}
    </Text>
  )
}

export default CallDuration
