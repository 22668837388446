import React, { useState } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'
import { Card, Checkbox, Text } from '@babylon/core-ui'

import {
  NOTE_ASSISTANT_CATEGORY,
  SPEECH_PROCESSING_TOGGLE_LABEL,
  SPEECH_PROCESSING_TOGGLE_OFF_ACTION,
  SPEECH_PROCESSING_TOGGLE_ON_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { CallRecordingPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useMessages, useMount } from '~/core/hooks'
import useUnmount from '~/core/hooks/useUnmount'
import { useExperiment } from '~/features/experiments/useExperiment'
import { useNoteAssistantConsentQuery } from '~/generated'

import useCallRecording from './useCallRecording'

import messages from './CallRecording.messages'
import styles from './styles.module.scss'

export enum SPEAKER_TYPE {
  CLINICIAN = 'clinician',
  PATIENT = 'patient',
}

export type SourceType = 'audio' | 'video'

const trackEvent = analytics.trackEventFactory({
  category: NOTE_ASSISTANT_CATEGORY,
})

const CallRecording: CallRecordingPluginInterface = ({
  eventBus,
  consultationContext,
}) => {
  const {
    noteAssistantAudioCallsEnabled,
    transcriptCollectionEnabled,
  } = useFeatureFlags()
  const [
    patientMediaStream,
    setPatientMediaStream,
  ] = useState<MediaStream | null>()
  const [
    clinicianMediaStream,
    setClinicianMediaStream,
  ] = useState<MediaStream | null>()
  const [consentToggleEnabled, setConsentToggle] = useState<boolean>(false)
  const [sourceType, setSourceType] = useState<SourceType | null>(null)
  const noteAssistantExperimentEnabled = useExperiment('Note Assistant')
  const { uuid: consultationUuid, patientId } = useConsultation(
    consultationContext
  )
  const { uuid: clinicianId } = useConsultantUser()
  const fm = useMessages(messages)

  const shouldRecord =
    noteAssistantExperimentEnabled || transcriptCollectionEnabled
  const shouldRecordAudioCalls = shouldRecord && noteAssistantAudioCallsEnabled

  const { loading: consentLoading } = useNoteAssistantConsentQuery({
    variables: {
      id: consultationUuid,
      speakers: [
        {
          speaker_id: clinicianId,
          speaker_type: SPEAKER_TYPE.CLINICIAN,
        },
        {
          speaker_id: patientId,
          speaker_type: SPEAKER_TYPE.PATIENT,
        },
      ],
    },
    onCompleted: (data) => {
      const permissionGranted = data.noteAssistantConsent.permission_granted
      if (permissionGranted) {
        setConsentToggle(true)
        eventBus.emit('SPEECH_PROCESSING_ENABLED')
      } else {
        eventBus.emit('SPEECH_PROCESSING_DISABLED')
      }
    },
    onError: () => {
      eventBus.emit('SPEECH_PROCESSING_DISABLED')
    },
  })

  const { stopCallRecording } = useCallRecording({
    patientMediaStream,
    clinicianMediaStream,
    consultationUuid,
    eventBus,
    patientId,
    clinicianId,
    sourceType,
    recordingEnabled: consentToggleEnabled,
  })

  const handleCallEnded = () => {
    setPatientMediaStream(null)
    setClinicianMediaStream(null)
    setSourceType(null)
    stopCallRecording()
  }

  const handlePatientVideoCreated = (element: HTMLVideoElement) => {
    const audioStream = new MediaStream()
    const mediaStream: MediaStream = element.srcObject as MediaStream // TODO - fix this typing as mediaStream can be null

    mediaStream?.getAudioTracks().forEach((track) => {
      audioStream.addTrack(track)
    })

    setPatientMediaStream(audioStream)
    setSourceType('video')
  }

  const handleClinicianVideoCreated = (element: HTMLVideoElement) => {
    const audioStream = new MediaStream()
    const mediaStream: MediaStream = element.srcObject as MediaStream // TODO - fix this typing as mediaStream can be null

    mediaStream?.getAudioTracks().forEach((track) => {
      audioStream.addTrack(track)
    })

    setClinicianMediaStream(audioStream)
    setSourceType('video')
  }

  const handlePatientAudioCreated = (mediaStream: MediaStream) => {
    setPatientMediaStream(mediaStream)
    setSourceType('audio')
  }

  const handleClinicianAudioCreated = (mediaStream: MediaStream) => {
    setClinicianMediaStream(mediaStream)
    setSourceType('audio')
  }

  const handleToggleClick = () => {
    if (!consentToggleEnabled) return

    trackEvent({
      label: SPEECH_PROCESSING_TOGGLE_LABEL,
      action: consentToggleEnabled
        ? SPEECH_PROCESSING_TOGGLE_OFF_ACTION
        : SPEECH_PROCESSING_TOGGLE_ON_ACTION,
    })

    eventBus.emit(
      consentToggleEnabled
        ? 'SPEECH_PROCESSING_DISABLED'
        : 'SPEECH_PROCESSING_ENABLED'
    )
    setConsentToggle((enabled) => !enabled)
    stopCallRecording()
  }

  useMount(() => {
    if (shouldRecord) {
      eventBus.on('VIDEO_CALL_ENDED', handleCallEnded)
      eventBus.on('AUDIO_CALL_ENDED', handleCallEnded)
      eventBus.on('PATIENT_VIDEO_CREATED', handlePatientVideoCreated)
      eventBus.on('CLINICIAN_VIDEO_CREATED', handleClinicianVideoCreated)

      if (shouldRecordAudioCalls) {
        eventBus.on('PATIENT_AUDIO_CREATED', handlePatientAudioCreated)
        eventBus.on('CLINICIAN_AUDIO_CREATED', handleClinicianAudioCreated)
      }
    }
  })

  useUnmount(() => {
    if (shouldRecord) {
      stopCallRecording()

      eventBus.removeListener('VIDEO_CALL_ENDED', handleCallEnded)
      eventBus.removeListener('AUDIO_CALL_ENDED', handleCallEnded)
      eventBus.removeListener(
        'PATIENT_VIDEO_CREATED',
        handlePatientVideoCreated
      )
      eventBus.removeListener(
        'CLINICIAN_VIDEO_CREATED',
        handleClinicianVideoCreated
      )

      if (shouldRecordAudioCalls) {
        eventBus.removeListener(
          'PATIENT_AUDIO_CREATED',
          handlePatientAudioCreated
        )
        eventBus.removeListener(
          'CLINICIAN_AUDIO_CREATED',
          handleClinicianAudioCreated
        )
      }
    }
  })

  if (!shouldRecord) return null

  return (
    <Card className={styles.card}>
      <Checkbox
        type="switch"
        disabled={consentLoading || !consentToggleEnabled}
        checked={consentToggleEnabled}
        onChange={handleToggleClick}
      >
        <Text color="light-grey-type" bold>
          {fm(
            consentLoading
              ? 'consent_loading'
              : consentToggleEnabled
              ? 'toggle_enabled'
              : 'toggle_disabled'
          )}
        </Text>
      </Checkbox>
    </Card>
  )
}

export default CallRecording
