import { defineMessages } from 'react-intl'

const baseId = 'components.appointment_invites_modal'
export default defineMessages({
  modal_title: {
    id: `${baseId}.modal_title`,
    defaultMessage: 'Create appointment invite',
  },
  profession_label: {
    id: `${baseId}.profession_label`,
    defaultMessage: 'Profession',
  },
  profession_placeholder: {
    id: `${baseId}.profession_placeholder`,
    defaultMessage: 'Select a profession',
  },
  appointment_type_label: {
    id: `${baseId}.appointment_type_label`,
    defaultMessage: 'Appointment type',
  },
  appointment_type_tooltip: {
    id: `${baseId}.appointment_type_tooltip_message`,
    defaultMessage:
      'You can search for an appointment type in the dropdown list.',
  },
  appointment_type_placeholder: {
    id: `${baseId}.appointment_type`,
    defaultMessage: 'Select service type',
  },
  appointment_reason_label: {
    id: `${baseId}.appointment_reason_label`,
    defaultMessage: 'Appointment reason',
  },
  appointment_reason_placeholder: {
    id: `${baseId}.appointment_reason_placeholder`,
    defaultMessage: 'What is the appointment for?',
  },
  appointment_reason_disclaimer: {
    id: `${baseId}.appointment_reason_disclaimer`,
    defaultMessage:
      'Remind the patient what is the appointment for, when should they book it and who they should book it with.',
  },
  appointment_reason_disclaimer_book: {
    id: `${baseId}.appointment_reason_disclaimer_book`,
    defaultMessage:
      'Remind the patient what is the appointment for and when the appointment has been booked for.',
  },
  duration_label: {
    id: `${baseId}.duration_label`,
    defaultMessage: 'Duration',
  },
  recurrence_dropdown_label: {
    id: `${baseId}.recurrence_dropdown_label`,
    defaultMessage: 'Number of weekly sessions',
  },
  recurrence_and_cadence_dropdown_label: {
    id: `${baseId}.recurrence_and_cadence_dropdown_label`,
    defaultMessage: 'Number of sessions',
  },
  cadence_dropdown_label: {
    id: `${baseId}.cadence_dropdown_label`,
    defaultMessage: 'Repeat',
  },
  recurrence_option_label: {
    id: `${baseId}.recurrence_option_label`,
    defaultMessage: '{num} sessions',
  },
  single_session_label: {
    id: `${baseId}.single_session_label`,
    defaultMessage: '1 session',
  },
  send_to_patient_button: {
    id: `${baseId}.send_to_patient_button`,
    defaultMessage: 'Send to patient',
  },
  book_for_patient: {
    id: `${baseId}.book_for_patient_button`,
    defaultMessage: 'Book for patient',
  },
  recommended_duration_option: {
    id: `${baseId}.recommended_duration_option`,
    defaultMessage: '{defaultDuration} minutes (recommended)',
  },
  duration_option: {
    id: `${baseId}.duration_option`,
    defaultMessage: '{duration} minutes',
  },
  duration_disclaimer: {
    id: `${baseId}.duration_disclaimer`,
    defaultMessage:
      'We advise using the recommended appointment duration. Due to lack of availability, appointments with different durations may require the patient to book further into the future which delays their treatment.',
  },
  dialog_sent_title: {
    id: `${baseId}.dialog_sent_title`,
    defaultMessage: 'Invite sent to patient',
  },
  dialog_sent_message: {
    id: `${baseId}.dialog_sent_message`,
    defaultMessage:
      'The patient will be able to see your invite in the Babylon app and schedule their follow-up appointment.',
  },
  dialog_sent_ok_label: {
    id: `${baseId}.dialog_sent_ok_label`,
    defaultMessage: 'OK',
  },
  dialog_recurring_title: {
    id: `${baseId}.dialog_recurring_title`,
    defaultMessage: 'Appointment booking',
  },
  dialog_recurring_title_confirm: {
    id: `${baseId}.dialog_recurring_title_confirm`,
    defaultMessage: 'Appointment booking confirmation',
  },
  dialog_recurring_title_incomplete: {
    id: `${baseId}.dialog_recurring_title_incomplete`,
    defaultMessage: 'Appointment booking incomplete',
  },
  dialog_recurring_message: {
    id: `${baseId}.dialog_recurring_message`,
    defaultMessage: 'The following appointments will be booked:',
  },
  dialog_recurring_message_confirm: {
    id: `${baseId}.dialog_recurring_message_confirm`,
    defaultMessage:
      'The appointments have been booked. The patient will be able to see their appointments in the Babylon app shortly.',
  },
  dialog_recurring_message_incomplete: {
    id: `${baseId}.dialog_recurring_message_incomplete`,
    defaultMessage:
      'The following appointments were not confirmed and will need to be booked again.',
  },
  dialog_recurring_ok_label: {
    id: `${baseId}.dialog_recurring_ok_label`,
    defaultMessage: 'OK',
  },
  dialog_recurring_submit_label: {
    id: `${baseId}.dialog_recurring_submit_label`,
    defaultMessage: 'Confirm',
  },
  dialog_recurring_cancel_label: {
    id: `${baseId}.dialog_recurring_cancel_label`,
    defaultMessage: 'Cancel',
  },
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: "We can't load appointment invites right now",
  },
  availability_issue: {
    id: `${baseId}.availability_issue`,
    defaultMessage:
      'Booking has failed due to appointment availability, please try a different time',
  },
  submit_error: {
    id: `${baseId}.submit_error`,
    defaultMessage:
      'Booking has failed due to a service error, please contact Support',
  },
  profession_key: {
    id: `${baseId}.profession_key`,
    defaultMessage: 'Profession',
  },
  duration_key: {
    id: `${baseId}.duration_key`,
    defaultMessage: 'Duration',
  },
  duration_value: {
    id: `${baseId}.duration_value`,
    defaultMessage: '{durationMinutes} min',
  },
  reason_key: {
    id: `${baseId}.reason_key`,
    defaultMessage: 'Reason',
  },
  provider_label: {
    id: `${baseId}.provider_label`,
    defaultMessage: 'Provider',
  },
  any_provider_label: {
    id: `${baseId}.any_provider_label`,
    defaultMessage: 'Any provider',
  },
  current_consultant_label: {
    id: `${baseId}.current_consultant_label`,
    defaultMessage: 'Myself as a provider',
  },
  date_picker_label: {
    id: `${baseId}.date_picker_label`,
    defaultMessage: 'To be scheduled from',
  },
  date_picker_label_book: {
    id: `${baseId}.date_picker_label_book`,
    defaultMessage: 'Date',
  },
  time_picker_label: {
    id: `${baseId}.time_picker_label`,
    defaultMessage: 'Time',
  },
  provider_analytics_label: {
    id: `${baseId}.provider_analytics_label`,
    defaultMessage: 'Provider value: {type}',
  },
  date_analytics_label: {
    id: `${baseId}.date_analytics_label`,
    defaultMessage: 'To be scheduled from: {date}',
  },
  no_cadence_label: {
    id: `${baseId}.no_cadence_label`,
    defaultMessage: 'No recurrence',
  },
  cadence_label_weekly: {
    id: `${baseId}.cadence_label_weekly`,
    defaultMessage: 'Weekly',
  },
  cadence_label_biweekly: {
    id: `${baseId}.cadence_label_biweekly`,
    defaultMessage: 'Bi-Weekly',
  },
})
