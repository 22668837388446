import { defineMessages } from 'react-intl'

const baseId = 'call_section_view'

export default defineMessages({
  error_title: {
    id: `${baseId}.error_v2`,
    defaultMessage: 'Call widget error',
  },
  error_description: {
    id: `${baseId}.error_description_v2`,
    defaultMessage:
      'Try reloading the call widget below or start a chat with support if you are running late.',
  },
})
