import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.booking_reason'

export default defineMessages({
  title_new: {
    id: `${baseId}.title_new_v2`,
    defaultMessage: 'Booking reason',
  },
  subtitle: {
    id: `${baseId}.subtitle`,
    defaultMessage: '(Patient entered)',
  },
  empty: {
    id: `${baseId}.empty`,
    defaultMessage: 'Patient did not leave a note',
  },
  patient_note_error: {
    id: `${baseId}.patient_note_error`,
    defaultMessage: 'Error loading presenting complaint',
  },
  no_service_type_available: {
    id: `${baseId}.no_service_type_available`,
    defaultMessage: 'No service type',
  },
})
