import { gql } from '@apollo/client'

export default gql`
  query PlacesSearchQuery($input: PlaceResourceInput!) {
    placesSearch(input: $input) {
      name
      phone
      address {
        line
        postalCode
      }
    }
  }
`
