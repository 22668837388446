import { withHandlers } from 'recompose'

import {
  ADD_SICK_NOTE_ACTION,
  ERROR_ADD_SICK_NOTE_ACTION,
  ERROR_UPDATE_SICK_NOTE_ACTION,
  SICK_NOTE_ACTIONS_CATEGORY,
  SICK_NOTE_LABEL,
  UPDATE_SICK_NOTE_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'

const handleCancelClick = ({ redirectToConsultation }) => () => {
  redirectToConsultation()
}

const handleSaveClick = ({
  errorAlert,
  withValidState,
  createSickNote,
  redirectToConsultation,
  consultationId,
  user,
  markFinalizedConsultationAsEdited,
}) => (input) => {
  withValidState(input, async () => {
    const consultantId = user?.consultant?.uuid

    try {
      await createSickNote(input)
      analytics.trackEvent({
        action: ADD_SICK_NOTE_ACTION,
        category: SICK_NOTE_ACTIONS_CATEGORY,
        label: SICK_NOTE_LABEL,
        consultationId,
        consultantId,
      })
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      redirectToConsultation()
    } catch (exception) {
      analytics.trackEvent({
        action: ERROR_ADD_SICK_NOTE_ACTION,
        category: SICK_NOTE_ACTIONS_CATEGORY,
        label: SICK_NOTE_LABEL,
        consultationId,
        consultantId,
      })

      errorAlert({ logMessage: exception })
    }
  })
}

const handleUpdateClick = ({
  errorAlert,
  query,
  withValidState,
  updateSickNote,
  redirectToConsultation,
  consultationId,
  user,
  markFinalizedConsultationAsEdited,
}) => async (input) => {
  withValidState(input, async () => {
    const sickNoteId = query.id
    const consultantId = user?.consultant?.uuid

    try {
      await updateSickNote(sickNoteId, input)
      analytics.trackEvent({
        action: UPDATE_SICK_NOTE_ACTION,
        category: SICK_NOTE_ACTIONS_CATEGORY,
        label: SICK_NOTE_LABEL,
        consultationId,
        consultantId,
      })
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      redirectToConsultation()
    } catch (exception) {
      analytics.trackEvent({
        action: ERROR_UPDATE_SICK_NOTE_ACTION,
        category: SICK_NOTE_ACTIONS_CATEGORY,
        label: SICK_NOTE_LABEL,
        consultationId,
        consultantId,
      })
      errorAlert({ logMessage: exception })
    }
  })
}

export default withHandlers({
  handleUpdateClick,
  handleCancelClick,
  handleSaveClick,
})
