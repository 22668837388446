import { ApolloError } from '@apollo/client'
import React, { useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'

import { Button, Spinner } from '@babylon/core-ui'

import { ACTIONS_TESTS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useMessages } from '~/core/hooks'
import { usePermissions } from '~/core/permissions'
import { logException } from '~/core/sentry'
import { ConsultationTest } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section, withSectionErrorBoundary } from '~/ui/Section'
import Tooltip from '~/ui/Tooltip'

import TestModal from '../TestModal'
import Test from './Test'
import TestRemoveDialog from './TestRemoveDialog'
import useRemoveTestMutation from './useRemoveTestMutation'

import messages from './TestsSection.messages'

// TODO: remove optionality of loading and refetch once legacy consultation is removed
interface TestsSectionProps {
  consultationId: string
  diagnosticTests: ConsultationTest[]
  pathologyTests: ConsultationTest[]
  restrictFinalizedConsultationEditing: boolean
  loading?: boolean
  error?: ApolloError
  refetch?: () => void
  markFinalizedConsultationAsEdited?: () => void
}

const TestsSection = ({
  consultationId,
  diagnosticTests,
  pathologyTests,
  restrictFinalizedConsultationEditing,
  markFinalizedConsultationAsEdited,
  loading,
  error,
  refetch,
}: TestsSectionProps) => {
  const format = useMessages(messages)
  const history = useHistory()
  const match = useRouteMatch()

  const [removingTest, setRemovingTest] = useState<{
    type: any
    id: any
  } | null>(null)

  const [hideActionTooltips] = usePermissions('hide_action_tooltips')
  const [
    removeTestMutation,
    { loading: mutationLoading },
  ] = useRemoveTestMutation()

  const tests = [...diagnosticTests, ...pathologyTests]

  const navigateToCreateTest = () => {
    history.replace(`${match.url}/test/create`)
  }

  if (error) {
    return (
      <ErrorPanel
        error={new Error('testing something there')}
        // fill="container"
        retry={() => refetch && refetch()}
        title={format('error_loading_tests_section')}
      />
    )
  }

  return (
    <>
      {removingTest && (
        <TestRemoveDialog
          okLoading={mutationLoading}
          onOk={async (dialog) => {
            const { type, id } = removingTest

            try {
              await removeTestMutation({
                consultationId,
                type,
                id,
              })

              if (markFinalizedConsultationAsEdited) {
                markFinalizedConsultationAsEdited()
              }
            } catch (removalError) {
              logException(removalError)
            }

            dialog.closeModal()
          }}
          onClose={() => {
            setRemovingTest(null)
          }}
        />
      )}

      <Section
        type="secondary"
        title={format('title_private')}
        dataTestId="tests"
        topRight={
          <Tooltip
            placement="left"
            message={format('tooltip_message')}
            hidden={hideActionTooltips}
          >
            <Button
              intent="secondary"
              onClick={() => navigateToCreateTest()}
              disabled={restrictFinalizedConsultationEditing}
            >
              {format('add_test_button_label')}
            </Button>
          </Tooltip>
        }
      >
        {loading && (
          <Spinner size="small" testid="tests-sections-loading-spinner" />
        )}

        {tests.map(({ id, type, testId, reason }) => (
          <Test
            key={`${id}-${type}`}
            id={id}
            type={type as string}
            testId={testId}
            reason={reason}
            onUpdate={() => {
              history.replace(`${match.url}/test/edit?id=${id}&type=${type}`)
            }}
            onRemove={() => {
              setRemovingTest({ id, type })
            }}
            restrictFinalizedConsultationEditing={
              restrictFinalizedConsultationEditing
            }
          />
        ))}
      </Section>

      {!restrictFinalizedConsultationEditing && (
        <Route path="/consultation/:consultationId/test/:mode(create|edit)">
          <TestModal
            markFinalizedConsultationAsEdited={
              markFinalizedConsultationAsEdited
            }
          />
        </Route>
      )}
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: ACTIONS_TESTS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title_private,
})(TestsSection)
