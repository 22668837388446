// ACTIONS
export const LOGOUT_ACTION = 'logout'

export const SUPPORT_COPY_SESSION_ID_ACTION = 'support_uk_copy_session_id'
export const SUPPORT_TECHNOLOGY_HELP_ACTION = 'support_technology_help'

export const SUPPORT_LIVE_CHAT_ACTION = 'support_live_chat'
export const SUPPORT_MEDICAL_SERVICE_DESK_ACTION =
  'support_uk_medical_service_desk'
export const SUPPORT_RISK_REPORT_ACTION = 'support_uk_risk_report'

export const EXIT_CONSULTATION_ACTION = 'exit_consultation'

export const CREATE_PRESCRIPTION_ACTION = 'create_prescription'
export const ERROR_CREATE_PRESCRIPTION_ACTION = 'error_create_prescription'
export const ERROR_COMPLETE_CONSULTATION_ACTION = 'error_complete_consultation'
export const UPDATE_CONSULTATION_ACTION = 'update_consultation'

export const ERROR_LOAD_VOICE_SESSION_ACTION = 'error_load_audio_session'
export const START_VOICE_CALL_ACTION = 'start_audio_call'
export const ERROR_START_VOICE_CALL_ACTION = 'error_start_audio_call'
export const END_VOICE_CALL_ACTION = 'end_audio_call'
export const ERROR_END_VOICE_CALL_ACTION = 'error_end_audio_call'
export const SERVICE_DISCONNECT_VOICE_CALL_ACTION =
  'service_disconnect_audio_call'
export const SERVICE_ERROR_VOICE_CALL_ACTION = 'service_error_audio_call'
export const MUTE_AUDIO_ACTION = 'mute_audio'
export const UNMUTE_AUDIO_ACTION = 'unmute_audio'

export const ERROR_LOAD_VIDEO_SESSION_ACTION = 'error_load_video_session'
export const START_VIDEO_SESSION_ACTION = 'start_video_session'
export const ERROR_START_VIDEO_SESSION_ACTION = 'error_start_video_session'
export const SWITCH_TO_VOICE_ACTION = 'switch_to_audio'
export const SWITCH_TO_VIDEO_ACTION = 'switch_to_video'
export const END_VIDEO_SESSION_ACTION = 'end_video_session'
export const ERROR_END_VIDEO_SESSION_ACTION = 'error_end_video_session'
export const ERROR_CREATE_VIDEO_SESSION_ACTION = 'error_create_video_session'
export const DISABLE_VIDEO_ACTION = 'disable_video'
export const ENABLE_VIDEO_ACTION = 'enable_video'
export const MINIMISE_CLINICIAN_VIDEO_ACTION = 'minimise_clinician_video'
export const MAXIMISE_CLINICIAN_VIDEO_ACTION = 'maximise_clinician_video'

export const RESIZE_CALL_COMPONENT_ACTION = 'resize_call_component'

export const APP_NAVIGATION_CLICK_ACTION = 'app_navigation_click'

export const ADD_ALLERGY_ACTION = 'add_allergy'
export const ERROR_ADD_ALLERGY_ACTION = 'error_add_allergy'
export const REMOVE_ALLERGY_ACTION = 'remove_allergy'
export const ERROR_REMOVE_ALLERGY_ACTION = 'error_remove_allergy'
export const ADD_MEDICATION_ACTION = 'add_medication'
export const ERROR_ADD_MEDICATION_ACTION = 'error_add_medication'
export const REMOVE_MEDICATION_ACTION = 'remove_medication'
export const ERROR_REMOVE_MEDICATION_ACTION = 'error_remove_medication'
export const ADD_CONDITION_ACTION = 'add_condition'
export const ERROR_ADD_CONDITION_ACTION = 'error_add_condition'
export const REMOVE_CONDITION_ACTION = 'remove_condition'
export const ERROR_REMOVE_CONDITION_ACTION = 'error_remove_condition'

export const ERROR_ADD_REPEATABLE_DRUG_ACTION = 'error_add_repeatable_drug'
export const ERROR_ADD_DRUG_ACTION = 'error_add_drug'
export const ADD_REPEATABLE_DRUG_ACTION = 'add_repeatable_drug'
export const ADD_DRUG_ACTION = 'add_drug'
export const UPDATE_DRUG_ACTION = 'update_drug'
export const ERROR_UPDATE_DRUG_ACTION = 'error_update_drug'

export const ADD_REFERRAL_ACTION = 'add_referral'
export const ERROR_ADD_REFERRAL_ACTION = 'error_add_referral'
export const UPDATE_REFERRAL_ACTION = 'update_referral'
export const ERROR_UPDATE_REFERRAL_ACTION = 'error_update_referral'

export const ADD_SICK_NOTE_ACTION = 'add_sick_note'
export const ERROR_ADD_SICK_NOTE_ACTION = 'error_add_sick_note'
export const UPDATE_SICK_NOTE_ACTION = 'update_sick_note'
export const ERROR_UPDATE_SICK_NOTE_ACTION = 'error_update_sick_note'

export const ADD_TEST_ACTION = 'add_test'
export const ERROR_ADD_TEST_ACTION = 'error_add_test'
export const UPDATE_TEST_ACTION = 'update_test'
export const ERROR_UPDATE_TEST_ACTION = 'error_update_test'

export const SUCCESSFUL_ADD_NOTES_ACTION = 'successful_add_notes'
export const ERROR_ADD_NOTES_ACTION = 'error_add_notes'

export const SUCCESSFUL_SUGGESTION_ADD_ACTION = 'successful_suggestion_add'
export const ERROR_SUGGESTION_ADD_ACTION = 'error_suggestion_add'

export const REMOVE_DRUG_ACTION = 'remove_drug'
export const ERROR_REMOVE_DRUG_ACTION = 'error_remove_drug'

export const REMOVE_REFERRAL_ACTION = 'remove_referral'
export const ERROR_REMOVE_REFERRAL_ACTION = 'error_remove_referral'

export const REMOVE_SICK_NOTE_ACTION = 'remove_sick_note'
export const ERROR_REMOVE_SICK_NOTE_ACTION = 'error_remove_sick_note'

export const REMOVE_TEST_ACTION = 'remove_test'
export const ERROR_REMOVE_TEST_ACTION = 'error_remove_test'

export const MARK_AS_NO_SHOW_ACTION = 'mark_as_no_show'
export const ERROR_MARK_AS_NO_SHOW_ACTION = 'error_mark_as_no_show'

export const CLICK_RESOURCE_LINK = 'click_resource_link'

export const WORKFLOW_SEND_TO_OPS_ACTION = 'workflow_send_to_ops'

export const SEE_TIMELINE_EVENT_ACTION = 'see_history_event'

export const UPCOMING_CONSULT_SHORTCUT_ACTION = 'upcoming_consult_shortcut'

export const START_REVIEW_ACTION = 'start_review'
export const COMPLETE_REVIEW_ACTION = 'complete_review'
export const TIMELINE_REVIEW_ACTION = 'timeline_review'
export const PROBLEMS_LIST_REVIEW_ACTION = 'problems_list_review'

export const MANUAL_CODES_TOTAL_ACTION = 'manual_codes_total'

export const ONB_OPENED_ACTION = 'found_open'
export const ONB_CLOSED_ACTION = 'found_closed'
export const ONB_SHOW_ACTION = 'show'
export const ONB_HIDE_ACTION = 'hide'
export const ONB_NEXT_ACTION = 'next'
export const ONB_PREV_ACTION = 'previous'
export const ONB_RESTART_ACTION = 'restart'

export const UNIDENTIFIED_ERROR_ACTION = 'unidentified_error'

export const CODING_DROPDOWN_SEARCH = 'coding_dropdown_search'

export const UPDATE_PATIENT_METRICS = 'update_metrics'
export const SAVE_CHANGES_WARNING_PATIENT_METRICS = 'save_changes_warning'

export const OPEN_RELEASE_NOTES_MODAL_ACTION = 'open_release_notes_modal'
export const RELEASE_NOTES_MODAL_VIEWING_DURATION_ACTION =
  'release_notes_modal_viewing_duration'
export const OPEN_LINKS_AND_RESOURCES_MODAL_ACTION =
  'open_links_and_resources_modal'
export const LINKS_AND_RESOURCES_MODAL_VIEWING_DURATION_ACTION =
  'links_and_resources_modal_viewing_duration'

export const GENERIC_ERROR_BOUNDARY_ACTION = 'generic_error_boundary_action'
export const GENERIC_SECTION_ERROR_ACTION = 'generic_section_error_action'
export const ALERTS_SECTION_ERROR_ACTION = 'alerts_section_error_action'
export const COMPLAINT_SECTION_ERROR_ACTION = 'complaint_section_error_action'
export const CARE_PLAN_ERROR_ACTION = 'care_plan_error_action'
export const PRECONSULTATION_SECTION_ERROR_ACTION =
  'preconsultation_section_error_action'
export const PATIENT_SUMMARY_SECTION_ERROR_ACTION =
  'patient_summary_section_error_action'
export const PATIENT_DOCUMENTS_SECTION_ERROR_ACTION =
  'patient_summary_section_error_action'
export const TIMELINE_SUMMARY_SECTION_ERROR_ACTION =
  'TIMELINE_SUMMARY_SECTION_ERROR_ACTION'
export const PATIENT_DETAILS_SECTION_ERROR_ACTION =
  'patient_details_section_error_action'
export const GP_DETAILS_SECTION_ERROR_ACTION = 'gp_details_section_error_action'
export const EXAMINATION_SECTION_ERROR_ACTION =
  'examination_section_error_action'
export const DIAGNOSIS_SECTION_ERROR_ACTION = 'diagnosis_section_error_action'
export const ACTIONS_PRESCRIPTIONS_SECTION_ERROR_ACTION =
  'actions_prescriptions_section_error_action'
export const REFERRALS_SECTION_ERROR_ACTION = 'referrals_section_error_action'
export const ACTIONS_TESTS_SECTION_ERROR_ACTION =
  'actions_tests_section_error_action'
export const ACTIONS_SICK_NOTES_SECTION_ERROR_ACTION =
  'actions_sick_notes_section_error_action'
export const ACTIONS_ATTACHMENTS_SECTION_ERROR_ACTION =
  'actions_attachments_section_error_action'
export const WORKFLOW_ACTIONS_SECTION_ERROR_ACTION =
  'workflow_actions_section_error_action'
export const APPOINTMENT_INVITES_SECTION_ERROR_ACTION =
  'appointment_invites_section_error_action'
export const CONSULTATION_REVIEW_SECTION_ERROR_ACTION =
  'consultation_review_section_error_action'
export const CALL_ERROR_BOUNDARY_ACTION = 'call_error_boundary_action'
export const SECTION_SHOW_MORE_BUTTON_ACTION = 'section_show_more_button'
export const SECTION_SHOW_LESS_BUTTON_ACTION = 'section_show_less_button'

export const INCOMPLETE_CONSULTATION_WARNING = 'incomplete_consultation_warning'

export const CHATBOT_FLOWS_ACTION = 'chatbot_flows'

export const DSM_SURVEY_ACTION = 'DSM_clinician_survey'

export const PRE_CONS_SHOW_MORE_ACTION = 'preconsultation_show_more'

export const CARE_PLAN_EDIT_GOALS_AND_ACTIONS_ACTION =
  'edit_goals_and_actions_click'
export const CARE_PLAN_GOAL_NOTES_ACTION = 'health_goal_notes'
export const CARE_PLAN_ACTION_FOR_GOAL_NOTES_ACTION = 'action_for_goal_notes'
export const CARE_PLAN_CLOSE_EDIT_MODAL_ACTION = 'close_edit_modal'
export const CARE_PLAN_SAVE_DRAFT_ACTION = 'save_draft_click'

export const PATIENT_TIMELINE_ADD_FILTER_ACTION = 'added_filter'
export const PATIENT_TIMELINE_CLOSE_FILTER_ACTION = 'closed_filter'
export const PATIENT_TIMELINE_CLEAR_ALL_FILTERS_ACTION = 'cleared_all_filters'
export const PATIENT_TIMELINE_TYPE_IN_FILTER_BAR_ACTION = 'typed_in_filter_bar'

export const PRE_CALL_CHECK_OPEN_ACTION = 'pre_call_check_open'
export const PRE_CALL_CHECK_RESULTS_GOOD = 'pre_call_check_results_good'
export const PRE_CALL_CHECK_RESULTS_BAD = 'pre_call_check_results_bad'
export const PRE_CALL_CHECK_CLOSE_TROUBLESHOOTING_ACTION =
  'pre_call_check_close_troubleshooting'
export const PRE_CALL_CHECK_CONTACT_SUPPORT = 'pre_call_check_contact_support'

export const SKIP_CONSULTATION_SURVEY_ACTION = 'skip_consultation_survey'
export const CLOSE_CONSULTATION_SURVEY_ACTION = 'close_consultation_survey'
export const DISMISS_CONSULTATION_SURVEY_ACTION = 'dismiss_consultation_survey'

export const COPY_PATIENT_ID_ACTION = 'copy_patient_id'
export const COPY_PUBLIC_HEALTHCARE_ID_ACTION = 'copy_public_healthcare_id'
export const COPY_CONSULTATION_ID_ACTION = 'copy_consultation_id'

export const APPOINTMENT_INVITES_CREATE_ACTION = 'click'
export const APPOINTMENT_INVITES_PROFESSION_ACTION = 'dropdown_select'
export const APPOINTMENT_INVITES_DURATION_ACTION = 'dropdown_select'
export const APPOINTMENT_INVITES_RECURRENCE_ACTION = 'dropdown_select'
export const APPOINTMENT_INVITES_CADENCE_ACTION = 'dropdown_select'
export const APPOINTMENT_INVITES_SEND_ACTION = 'click'
export const APPOINTMENT_INVITES_OK_ACTION = 'click'
export const APPOINTMENT_INVITES_CREATE_MODAL_LOADED_ACTION = 'loaded_modal'
export const APPOINTMENT_INVITES_SEND_MODAL_LOADED_ACTION = 'loaded_modal'
export const APPOINTMENT_INVITES_SELECT_PROVIDER_ACTION = 'click'
export const APPOINTMENT_INVITES_START_DATE_ACTION = 'calendar_select'
export const APPOINTMENT_INVITES_CREATE_MODAL_CONFIRM_ACTION =
  'clicked_confirm_appointment_series_bookings'
export const APPOINTMENT_INVITES_CREATE_MODAL_CANCEL_ACTION =
  'clicked_cancel_on_appointment_series_bookings'
export const APPOINTMENT_INVITES_CREATE_MODAL_INCOMPLETE_ACTION =
  'view_booking_failed_appointment'

export const NOTE_ASSISTANT_COPY_ALL_ACTION = 'clicked_copy_all'
export const NOTE_ASSISTANT_COPY_SEGMENT_ACTION = 'copied_segment'
export const NOTE_ASSISTANT_ERROR_ACTION = 'error_message'
export const NOTE_ASSISTANT_ACTIVE = 'active'

export const NEW_APPT_NOTIFICATIONS = 'new_appointment_notifications'
export const NEW_APPT_NOTIFICATION_SHOWN = 'new_appointment_notification_shown'
export const SPEECH_PROCESSING_TOGGLE_OFF_ACTION = 'off'
export const SPEECH_PROCESSING_TOGGLE_ON_ACTION = 'on'

export const OPEN_PATIENT_RECORD_ACTION = 'clicked_open_patient_record'

// CATEGORIES
export const CONSULTATION_CATEGORY = 'consultation'
export const SUPPORT_CATEGORY = 'support'
export const MULTIMEDIA_CATEGORY = 'multimedia'
export const NAVIGATION_CATEGORY = 'navigation'
export const MEDICAL_TIMELINE_CATEGORY = 'medical_history'
export const DRUG_ACTIONS_CATEGORY = 'drug_actions'
export const REFERRAL_ACTIONS_CATEGORY = 'referral_actions'
export const SICK_NOTE_ACTIONS_CATEGORY = 'sick_note_actions'
export const TEST_ACTIONS_CATEGORY = 'test_actions'
export const CLINICAL_NOTES_CATEGORY = 'clinical_notes'
export const PRESCRIPTION_SUGGESTION_CATEGORY = 'prescription_suggestion'
export const WORKFLOW_CATEGORY = 'workflow'
export const FEATURES_CATEGORY = 'features'
export const LOGOUT_CATEGORY = 'logout'
export const RESOURCE_NAVIGATION_CATEGORY = 'resource_navigation'
export const PATIENT_TIMELINE_CATEGORY = 'patient_history'
export const REVIEW_CATEGORY = 'consultation_review'
export const CODING_CATEGORY = 'coding'
export const PBL_EXPANDED_CATEGORY = 'prob_list_expanded'
export const PBL_FILTERS_CATEGORY = 'prob_list_filters'
export const PBL_MODAL_CATEGORY = 'prob_list_modal'
export const ERROR_BOUNDARY_CATEGORY = 'error_boundary'
export const NOTE_TAKING_CATEGORY = 'note_taking'
export const NOTE_TAKING_V2_CATEGORY = 'note_taking_v2'
export const CREATE_NHS_APPOINTMENT_CATEGORY = 'create_nhs_appointment'
export const PATIENT_METRICS = 'patient_metrics'
export const RELEASE_NOTES_MODAL_CATEGORY = 'release_notes_modal'
export const LINKS_AND_RESOURCES_MODAL_CATEGORY = 'links_and_resources_modal'
export const CHATBOT_TRANSCRIPT_CATEG = 'chatbot_transcript'
export const SECTION_CATEGORY = 'section_component'
export const CARE_PLANS_CATEGORY = 'care_plans'
export const EXPERIMENTS_CATEGORY = 'experiments_page'
export const PRE_CALL_CHECK = 'pre_call_check'
export const CONSULTATION_SURVEY_CATEGORY = 'consultation_survey'
export const CONSULTATION_PROFILE_CATEGORY = 'consultation_profile'
export const APPOINTMENT_INVITES_CATEGORY = 'appointment_invites'
export const NOTE_ASSISTANT_CATEGORY = 'note_assistant'
export const CLINICIAN_ACTION_CATEGORY = 'clinician_action'

// LABELS
export const SICK_NOTE_LABEL = 'sick_note'
export const NORMAL_REVIEW_LABEL = 'normal'
export const MANUAL_CODES_LABEL = 'manual'
export const PBL_EXPANDED_TOTAL = 'prob_list_expandable_total'
export const PBL_CODES_TOTAL = 'prob_list_codes_total'
export const AUTOCOMPLETE_ADD_CODE_LABEL = 'autocomplete_add_clinical_code'
export const SELECTION_ADD_CODE_LABEL = 'selection_add_clinical_code'
export const NOTE_TAKING_TIMER = 'timer'
export const DROPDOWN_SEARCH_TIMER = 'timer'
export const NHS_APPOINTMENT_MEDIUM_DIGITAL_LABEL = 'Digital'
export const NHS_APPOINTMENT_MEDIUM_F2F_LABEL = 'Face-to-face'
export const PAT_MET_OPEN_MODAL = 'update_patient_metrics'
export const PAT_MET_OPEN_BMI_MODAL_CARD = 'bmi_card'
export const PAT_MET_OPEN_SMOKING_STATUS_MODAL_CARD = 'smoking_status_card'
export const PAT_MET_OPEN_BLOOD_PRESSURE_MODAL_CARD = 'blood_pressure_card'
export const PAT_MET_SWITCH_TO_US_MEASUREMENT_TYPE = 'us_bmi_measurement'
export const PAT_MET_SWITCH_TO_IMPERIAL_MEASUREMENT_TYPE = 'uk_bmi_measurement'
export const PAT_MET_SWITCH_TO_METRIC_MEASUREMENT_TYPE =
  'metric_bmi_measurement'
export const PAT_MET_SAVE_HEIGHT = 'save_height'
export const PAT_MET_UPDATE_HEIGHT = 'update_height'
export const PAT_MET_SAVE_WEIGHT = 'save_weight'
export const PAT_MET_UPDATE_WEIGHT = 'update_weight'
export const PAT_MET_SAVE_SMOKING_STATUS = 'save_smoking'
export const PAT_MET_UPDATE_SMOKING_STATUS = 'update_smoking'
export const PAT_MET_SAVE_BLOOD_PRESSURE = 'save_blood_pressure'
export const PAT_MET_UPDATE_BLOOD_PRESSURE = 'update_blood_pressure'
export const PAT_MET_EXPAND_BMI = 'expand_bmi'
export const PAT_MET_COLLAPSE_BMI = 'collapse_bmi'
export const PAT_MET_EXPAND_SMOKING_STATUS = 'expand_smoking'
export const PAT_MET_COLLAPSE_SMOKING_STATUS = 'collapse_smoking'
export const PAT_MET_ABANDON_DATA = 'abandon_data'
export const PAT_MET_RETURN_TO_MODAL = 'return_to_modal'
export const DSM_SURVEY_RIGHT_PERSON_CLICK_LABEL = 'DSMsurvey_rightperson_click'
export const DSM_SURVEY_SUITABLE_TYPE_CLICK_LABEL = 'DSMsurvey_suitable_click'
export const DSM_SURVEY_COMPLETE_LABEL = 'DSMsurvey_complete'
export const SECTION_SHOW_MORE_UNKNOWN_LABEL = 'unknown'
export const CARE_PLAN_HAS_GOALS_AND_ACTIONS_LABEL = 'has_goals_and_actions'
export const CARE_PLAN_NO_GOALS_AND_ACTIONS_LABEL = 'no_goals_and_actions'

export const EXPERIMENT_ENABLED_LABEL = 'experiment_enabled'
export const EXPERIMENT_DISABLED_LABEL = 'experiment_disabled'

export const APPOINTMENT_INVITES_CREATE_LABEL = 'create'
export const APPOINTMENT_INVITES_SEND_LABEL = 'send_to_patient'
export const APPOINTMENT_INVITES_OK_LABEL = 'ok'
export const APPOINTMENT_INVITES_SEND_MODAL_LABEL = 'invite_sent_to_patient'
export const APPOINTMENT_INVITES_CREATE_MODAL_LABEL =
  'create_appointment_invite'

export const APPOINTMENT_INVITES_CREATE_MODAL_CONFIRM_LABEL =
  'confirmed appointment will be booked: '
export const APPOINTMENT_INVITES_CREATE_MODAL_CANCEL_LABEL =
  'cancelled booking appointment: '
export const NOTE_ASSISTANT_ERROR_LABEL = 'stopped_due_to_error'

export const NEW_APPT_ID = 'new_appointment_id'
export const NEW_APPT_NOTIFICATION_ACTIVE =
  'new_appointment_notification_active'
export const NEW_APPT_NOTIFICATION_INACTIVE =
  'new_appointment_notification_inactive'

export const OPT_IN_NOTIFICATIONS = 'opt_in_notifications'
export const OPT_OUT_NOTIFICATIONS = 'opt_out_notifications'

export const CLICKED_CONSULTATION_CARD = 'clicked_consultation_card'

export const SECTION_PATIENT_TIMELINE_LABEL = 'patient_timeline'
export const SPEECH_PROCESSING_TOGGLE_LABEL = 'speech_processing_toggle'

// MODALS
export const LINKS_AND_RESOURCES_MODAL = 'links_and_resources_modal'
export const RELEASE_NOTES_MODAL = 'release_notes_modal'
export const PATIENT_TIMELINE_MODAL = 'patient_timeline_modal'
export const PROBLEMS_LIST_MODAL = 'problems_list_modal'
