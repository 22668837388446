import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ResponsiveTableCell = ({
  header,
  content,
  role = 'cell',
  className,
  cellPadding = 'medium',
}) => {
  const getPaddingStyle = () => {
    switch (cellPadding) {
      case 'small':
        return { padding: '6px' }
      case 'medium':
        return { padding: '10px' }
      default:
        return { padding: '10px' }
    }
  }
  return (
    <span
      className={cn('core-responsive-table__cell', className)}
      role={role}
      style={getPaddingStyle()}
    >
      {header && (
        <div
          className="core-responsive-table__cell__header"
          data-testid="core-responsive-table__cell__header"
        >
          {header}
        </div>
      )}
      {content}
    </span>
  )
}
ResponsiveTableCell.propTypes = {
  header: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  role: PropTypes.string,
  cellPadding: PropTypes.string,
}

export default ResponsiveTableCell
