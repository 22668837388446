import React, { Component } from 'react'

const withVisibleState = (WrappedComponent) =>
  class extends Component {
    state = {
      visible: this.props.defaultVisible,
    }

    onVisibleChange = (visible) => {
      this.setState({ visible })

      if (this.props.onVisibleChange) {
        this.props.onVisibleChange(visible)
      }
    }

    render() {
      const { showOnClick = true } = this.props
      const { visible } = this.state

      return (
        <WrappedComponent
          {...this.props}
          showOnClick={showOnClick}
          visible={visible}
          hide={() => this.onVisibleChange(false)}
          show={() => this.onVisibleChange(true)}
        />
      )
    }

    static defaultProps = {
      defaultVisible: false,
    }
  }

export default withVisibleState
