import { defineMessages } from 'react-intl'

const baseId = 'components.clinical_codes'

export default defineMessages({
  clinical_codes_label: {
    id: `${baseId}.clinical_codes_label`,
    defaultMessage: 'Clinical Codes:',
  },
  manual_clinical_codes_label: {
    id: `${baseId}.manual clinical_codes_label`,
    defaultMessage: 'Clinical Codes (manually added):',
  },
})
