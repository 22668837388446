import React from 'react'

import { Alert, Card, Heading } from '@babylon/core-ui'

import styles from '../styles.module.scss'

import ProfileCommentForm from './ProfileCommentForm'
import Biomarkers from './Biomarkers'
import LabInfo from './LabInfo'

export default ({ profiles = {}, showProfileCommentForm, reportId }) =>
  profiles.map((profile, index) => (
    <Card padding="lg" key={index}>
      <Heading>{profile.name}</Heading>
      {profile.reviewerComments && (
        <Alert
          className={styles[('report__word-break', 'report__alert')]}
          padding={10}
        >
          {profile.reviewerComments}
        </Alert>
      )}
      <Biomarkers biomarkers={profile.biomarkers} />
      {profile.additionalInfo && <LabInfo info={profile.additionalInfo} />}
      {showProfileCommentForm && (
        <ProfileCommentForm profileCode={profile.code} reportId={reportId} />
      )}
    </Card>
  ))
