import Tooltip from 'rc-tooltip'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { ReactComponent as IconCheck } from '~/assets/complete-icon.svg'
import { ReactComponent as IconEdit } from '~/assets/edit.svg'
import { ReactComponent as IconNoShow } from '~/assets/noshow.svg'
import { ReactComponent as IconPhone } from '~/assets/phone.svg'
import { ReactComponent as IconPhysical } from '~/assets/physical.svg'
import { ReactComponent as IconText } from '~/assets/text.svg'
import { ReactComponent as IconVideo } from '~/assets/video.svg'

import { ConsultationTypes, ScheduleStatus } from './utils'

import messages from './ConsultationScheduleItem.messages'

interface PanelIconProps {
  consultationType: string
  scheduleStatus: ScheduleStatus
}

const PanelIcon = ({ consultationType, scheduleStatus }: PanelIconProps) => {
  const fm = useFormatMessage()

  if (scheduleStatus === ScheduleStatus.complete)
    return (
      <Tooltip
        transitionName="tooltip-fade"
        mouseEnterDelay={1}
        mouseLeaveDelay={0}
        trigger={['hover']}
        overlay={fm(messages.completed_tooltip)}
        placement="top"
      >
        <IconCheck data-testid="completed-icon" />
      </Tooltip>
    )

  if (scheduleStatus === ScheduleStatus.noShow)
    return (
      <Tooltip
        transitionName="tooltip-fade"
        mouseEnterDelay={1}
        mouseLeaveDelay={0}
        trigger={['hover']}
        overlay={fm(messages.no_show_tooltip)}
        placement="top"
      >
        <IconNoShow data-testid="noshow-icon" />
      </Tooltip>
    )

  if (scheduleStatus === ScheduleStatus.incomplete) return <IconEdit />

  switch (consultationType) {
    case ConsultationTypes.video: {
      return <IconVideo data-testid="video-icon" />
    }
    case ConsultationTypes.audio: {
      return <IconPhone data-testid="audio-icon" />
    }
    case ConsultationTypes.f2f: {
      return <IconPhysical data-testid="f2f-icon" />
    }
    case ConsultationTypes.text: {
      return <IconText data-testid="text-icon" viewBox="0 0 28 27" />
    }
    default:
      return <IconEdit />
  }
}

export default PanelIcon
