import { Observable } from 'rxjs'

import { behaviorSubject } from './rxjs-fp'

type ObservableState<T extends {}> = readonly [
  values: Observable<T>,
  updateValue: (newValues: Partial<T>, reset?: boolean) => void,
  reset: () => void
]

export function observableState<T extends {}>(
  defaultState: T
): ObservableState<T>

export function observableState(): ObservableState<Record<string, any>>

export function observableState(defaultState: any = {}) {
  const values = behaviorSubject(defaultState)

  const updateValue = (newValues: any, reset: boolean = false) => {
    values.next(
      reset
        ? newValues
        : {
            ...values.getValue(),
            ...newValues,
          }
    )
  }

  const resetValue = () => {
    updateValue(defaultState, true)
  }

  return [values, updateValue, resetValue] as const
}
