import { detect } from 'detect-browser'

const chromeClearCacheLink = 'https://support.google.com/accounts/answer/32050'
const firefoxClearCacheLink =
  'https://support.mozilla.org/en-US/kb/how-clear-firefox-cache'

export const getClearCacheLink = (): string => {
  const browser = detect()

  if (browser?.name === 'firefox') {
    return firefoxClearCacheLink
  }

  return chromeClearCacheLink
}
