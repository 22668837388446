import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.diagnosis'

export default defineMessages({
  error_loading_assessment_message: {
    id: `${baseId}.error_loading_assessment_message`,
    defaultMessage: 'Error loading the assessment',
  },
  diagnosis_label: {
    id: `${baseId}.diagnosis_label`,
    defaultMessage: 'Diagnosis And Plan',
  },
  diagnosis_placeholder: {
    id: `${baseId}.diagnosis_placeholder`,
    defaultMessage: 'Diagnosis',
  },
  suggest_codes_label: {
    id: `${baseId}.suggest_codes_label_v2`,
    defaultMessage: 'Suggested codes',
  },
  suggest_codes_info_message: {
    id: `${baseId}.suggest_codes_info_message`,
    defaultMessage:
      'These codes have been auto extracted by AI and validated by a GP',
  },
  clinical_code_list_label: {
    id: `${baseId}.clinical_code_list_label`,
    defaultMessage: 'Clinical codes',
  },
  advice_label: {
    id: `${baseId}.advice_label`,
    defaultMessage: 'Plan',
  },
  advice_placeholder: {
    id: `${baseId}.advice_placeholder`,
    defaultMessage: 'Plan',
  },
  safety_netting_label: {
    id: `${baseId}.safety_netting_label_v2`,
    defaultMessage: 'Safety netting',
  },
  safety_netting_info: {
    id: `${baseId}.safety_netting_info`,
    defaultMessage:
      'What the patient should do if things are not improving, and things they should look out for. Ensure you record any red flags here for the patient to be aware of.',
  },
  safety_netting_placeholder: {
    id: `${baseId}.safety_netting_placeholder`,
    defaultMessage: 'Safety netting',
  },
  further_information_label: {
    id: `${baseId}.further_information_label_v2`,
    defaultMessage: 'Further information',
  },
  cant_load_diagnosis: {
    id: `${baseId}.cant_load_diagnosis`,
    defaultMessage: "We can't load Diagnosis right now",
  },
  error_saving_diagnosis_title: {
    id: `${baseId}.error_saving_diagnosis_title`,
    defaultMessage: 'Diagnosis not saved',
  },
  error_saving_diagnosis_message: {
    id: `${baseId}.error_saving_diagnosis_message`,
    defaultMessage:
      'If the diagnosis field contains a special character (eg ö or é) you should remove it.  Your notes will not save correctly unless you do this.',
  },
})
