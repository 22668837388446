import { CLINICAL_CODE } from './plugins/ClinicalCodes'
import { HIGHLIGHT } from './plugins/Highlight'
import { INLINE_SEARCH } from './plugins/InlineSearch'
import { PARAGRAPH } from './plugins/Paragraphs'

export const TEXT = 'text'

export default {
  inlines: {
    [CLINICAL_CODE]: {
      isVoid: true,
      parent: { type: PARAGRAPH },
      nodes: [
        {
          match: { object: TEXT },
        },
      ],
    },
    [INLINE_SEARCH]: {
      parent: { type: PARAGRAPH },
      nodes: [
        {
          match: { object: TEXT },
        },
      ],
    },
    [HIGHLIGHT]: {
      parent: { type: PARAGRAPH },
      nodes: [
        {
          match: { object: TEXT },
        },
      ],
    },
  },
}
