import { faCapsules } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { Badge } from '@babylon/core-ui'
import { useFormatMessage, useIntl } from '@babylon/intl'
import * as Access from '@babylon/web-platform-access'

import { NavigationLink } from '~/features/navigation'

import usePrescriptionCount from './usePrescriptionCount'

import navigationMessages from './Navigation.messages'

const MAX_PRESCRIPTION_COUNT = 99

export const LazyPrescriptionsPage = React.lazy(
  () => import('./PrescriptionsPage')
)

interface PrescriptionsPageNavigationItemProps {
  path: string
}

export const PrescriptionsPageNavigationItem = ({
  path,
}: PrescriptionsPageNavigationItemProps) => {
  const fm = useFormatMessage()
  const intl = useIntl()
  const prescriptionCount = usePrescriptionCount()

  const [granted, error] = Access.useDecide('prescriptions-page')

  if (error) {
    throw error
  }

  if (!granted) {
    return null
  }

  const badge = prescriptionCount > 0 && (
    <Badge
      value={
        prescriptionCount > MAX_PRESCRIPTION_COUNT
          ? `${intl.formatNumber(MAX_PRESCRIPTION_COUNT)}+`
          : intl.formatNumber(prescriptionCount)
      }
    />
  )

  return (
    <NavigationLink
      data-testid="prescriptions-navigation-link"
      to={path}
      icon={
        <FontAwesomeIcon
          icon={faCapsules}
          style={{ height: 24, width: 24 }}
          color="#6B7785"
        />
      }
      badge={badge}
    >
      {fm(navigationMessages.prescriptions_text)}
    </NavigationLink>
  )
}
