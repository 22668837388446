import { EventEmitter2 } from 'eventemitter2'

import apolloClient from '~/core/apollo/client'

import {
  ApolloClientInterface,
  EventBusInterface,
  InfoInterface,
  MainInterface,
} from '../config/modules/generated/types'

export * from './global'

export const ApolloClient: ApolloClientInterface = () => apolloClient

export const EventBus: EventBusInterface = () => new EventEmitter2()

export const Info: InfoInterface = (param: any) => param

export const Main: MainInterface = (param: any) => param
