import {
  addDays,
  isToday,
  isTomorrow,
  isWithinInterval,
  isYesterday,
  subDays,
} from 'date-fns'
import React from 'react'

import { CONSULTATION_PROFILE_CATEGORY } from '~/constants/analytics'
import analytics from '~/core/analytics'
import { stringList } from '~/core/string'
import { ConsultationStatusV2, ContactDetails } from '~/generated'

const trackEvent = analytics.trackEventFactory({
  category: CONSULTATION_PROFILE_CATEGORY,
})

export const trackCopyEvent = (action: string) => {
  trackEvent({ action })
}

export enum ConsultationStatusType {
  Completed = 'completed',
  Cancelled = 'cancelled',
  NoShow = 'no_show',
  ComingSoon = 'coming_soon',
  Incomplete = 'incomplete',
}

export const formatContactDetails = (
  contactDetails: ContactDetails,
  email: string | null | undefined
): React.ReactNode => {
  const {
    address_post_code: addressPostCode,
    address_first_line: addressFirstLine,
    address_second_line: addressSecondLine,
    address_third_line: addressThirdLine,
    address_state_code: addressStateCode,
    phone_country_code: phoneCountryCode,
    phone_number: phoneNumber,
  } = contactDetails

  if (addressPostCode) {
    return stringList(addressThirdLine, addressStateCode, addressPostCode)
  }

  if (addressFirstLine || addressSecondLine) {
    return stringList(addressFirstLine, addressSecondLine)
  }

  if (phoneCountryCode && phoneNumber) {
    return `${phoneCountryCode} ${phoneNumber}`
  }

  if (email) {
    return email
  }

  return null
}

export const addSpacesToID = (id: string): string => {
  let idWithSpaces = id

  for (let i = 3; i < idWithSpaces.length; i += 3) {
    if (idWithSpaces.substring(i).length >= 2) {
      const head = idWithSpaces.substring(0, i)
      const tail = idWithSpaces.substring(i)

      idWithSpaces = `${head} ${tail}`
      i += 1
    }
  }

  return idWithSpaces
}

const comingSoonStatus = (scheduledTime: string) => {
  const consultationTime = new Date(scheduledTime)
  const currentDateTime = new Date()

  const start = subDays(consultationTime, 1)
  return isWithinInterval(currentDateTime, {
    start,
    end: consultationTime,
  })
}

const incompleteStatus = (scheduledTime: string) => {
  const consultationTime = new Date(scheduledTime)
  const currentDateTime = new Date()

  const end = addDays(consultationTime, 1)
  return isWithinInterval(currentDateTime, {
    start: consultationTime,
    end,
  })
}

export const getConsultationStatus = (
  status: ConsultationStatusV2,
  scheduledTime: string,
  hasCheckInInformation: boolean
) => {
  if (status === ConsultationStatusV2.Completed) {
    return ConsultationStatusType.Completed
  }
  if (status === ConsultationStatusV2.Cancelled) {
    return ConsultationStatusType.Cancelled
  }
  if (status === ConsultationStatusV2.NoShow) {
    return ConsultationStatusType.NoShow
  }
  if (comingSoonStatus(scheduledTime) && !hasCheckInInformation) {
    return ConsultationStatusType.ComingSoon
  }
  if (incompleteStatus(scheduledTime) && !hasCheckInInformation) {
    return ConsultationStatusType.Incomplete
  }
  return null
}

export enum RelativeDayOptions {
  today = 'today',
  tomorrow = 'tomorrow',
  yesterday = 'yesterday',
}

export const getRelativeDay = (
  scheduledTime: string
): RelativeDayOptions | null => {
  const consultationTime = new Date(scheduledTime)

  if (isToday(consultationTime)) {
    return RelativeDayOptions.today
  }

  if (isYesterday(consultationTime)) {
    return RelativeDayOptions.yesterday
  }

  if (isTomorrow(consultationTime)) {
    return RelativeDayOptions.tomorrow
  }

  return null
}

export enum PARTICIPANT_TYPES {
  GUEST = 'guest',
}
