import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { RegenerateClinicianPinMutation } from '~/generated'

import messages from './SignatureRxPinPlugin.messages'
import styles from './styles.module.scss'

type resetPinProps = {
  resetData: RegenerateClinicianPinMutation | null | undefined
  resetPin: () => void
  resetLoading: boolean
}

const ResetPin = ({ resetData, resetPin, resetLoading }: resetPinProps) => {
  const fm = useFormatMessage()

  const renderBody = () => {
    if (resetLoading) {
      return (
        <span
          className={styles['resetPin__text']}
          data-testid="reset-pin-loading"
        >
          {fm(messages.signature_rx_password_resend_pin_loading)}
        </span>
      )
    }

    // Reset not called yet
    if (!resetData) {
      return (
        <>
          <span
            className={styles['resetPin__text']}
            data-testid="reset-pin-no-data"
          >
            {fm(messages.signature_rx_password_forgot_question)}
          </span>
          <span
            className={styles['resetPin__action-link']}
            data-testid="reset-pin-action-link"
            role="button"
            tabIndex={0}
            aria-label={fm(messages.signature_rx_password_resend_pin)}
            onClick={resetPin}
          >
            {fm(messages.signature_rx_password_resend_pin)}
          </span>
        </>
      )
    }

    // Success
    if (resetData?.regenerateClinicianPin) {
      return (
        <span
          className={styles['resetPin__text']}
          data-testid="reset-pin-success"
        >
          {fm(messages.signature_rx_password_resend_pin_success)}
        </span>
      )
    }

    // Failure
    return (
      <span
        className={styles['resetPin__text']}
        data-testid="reset-pin-failure"
      >
        {fm(messages.signature_rx_password_resend_pin_failure)}
      </span>
    )
  }

  return <div className={styles['resetPin']}>{renderBody()}</div>
}

export default ResetPin
