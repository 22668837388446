import React from 'react'

import { Heading, Text } from '@babylon/core-ui'

import { useGlobals } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'

import ExperimentCheckbox from './ExperimentCheckbox'

import messages from './Experiments.messages'
import styles from './styles.module.scss'

const Experiments = () => {
  const f = useMessages(messages)

  const { experiments } = useGlobals()

  return (
    <div className={styles.experimentsPage}>
      <Heading margin> {f('page_title')}</Heading>
      <div className={styles.instructions}>
        <Text size="medium" color="grey-type">
          {f('instructions')}
        </Text>
      </div>
      <div className={styles.experimentsContainer}>
        {experiments?.length ? (
          experiments.map((experiment) => (
            <ExperimentCheckbox
              key={experiment.title}
              experiment={experiment}
            />
          ))
        ) : (
          <>{f('no_experiments')}</>
        )}
      </div>
    </div>
  )
}

export default Experiments
