import React, { useState } from 'react'
import { MessageDescriptor } from 'react-intl'

import { Input } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { Prescription, RegenerateClinicianPinMutation } from '~/generated'

import ResetPin from './ResetPin'
import SimplePrescriptionsTable from './SimplePrescriptionsTable'

import messages from './SignatureRxPinPlugin.messages'
import styles from './styles.module.scss'

type SignatureRxPinViewProps = {
  // state
  error?: MessageDescriptor
  loading: boolean
  prescriptions: Prescription[]
  updatePin: (newPin: string) => void
  resetPin: () => void
  resetData: RegenerateClinicianPinMutation | null | undefined
  resetLoading: boolean
  pin: string
  displayDeveloperPinNotice: boolean
}

export const SignatureRxPinView = ({
  prescriptions,
  updatePin,
  resetPin,
  pin,
  error,
  loading,
  resetLoading,
  resetData,
  displayDeveloperPinNotice,
}: SignatureRxPinViewProps) => {
  const [isDirty, setIsDirty] = useState(false)

  const fm = useFormatMessage()

  const handlePinChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event?.currentTarget?.value || event.currentTarget?.value === '') {
      updatePin(event.currentTarget.value)
    }

    if (!isDirty) {
      setIsDirty(true)
    }
  }

  const requiredError = isDirty && (!pin || pin.length === 0)

  return (
    <div
      className={styles['SignatureRxPinView']}
      data-testid="signature-rx-pin-view"
    >
      <div className={styles['signatureRxPinView__description']}>
        {fm(messages.signature_rx_description_block)}
      </div>
      <div className={styles['signatureRxPinView__prescriptions']}>
        <div className={styles['signatureRxPinView__prescription-title']}>
          {fm(messages.signature_rx_prescriptions_title)}
        </div>
        <SimplePrescriptionsTable prescriptions={prescriptions} />
      </div>
      <div className={styles['signatureRxPinView__password-block']}>
        <div className={styles['signatureRxPinView__prescription-title']}>
          {fm(messages.signature_rx_password_label)}
        </div>
        {displayDeveloperPinNotice && (
          <div className={styles['signatureRxPinView__developer-notice']}>
            {fm(messages.signature_rx_pin_validation_disabled_notice)}
          </div>
        )}
        <Input
          onChange={handlePinChange}
          data-testid="signature-rx-input"
          value={pin}
          type="password"
          loading={loading}
          placeholder={fm(messages.signature_rx_password_placeholder)}
          intent={error || requiredError ? 'error' : undefined}
          fill
        />
        {requiredError && (
          <div
            className={styles['signatureRxPinView__required-error']}
            data-testid="signature-rx-required-error"
          >
            {fm(messages.signature_rx_password_required_error)}
          </div>
        )}
        <ResetPin
          resetLoading={resetLoading}
          resetPin={resetPin}
          resetData={resetData}
        />
      </div>
    </div>
  )
}
