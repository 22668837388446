import get from 'lodash/fp/get'
import React, { FC, ReactNode } from 'react'
import { Field, FieldProps } from 'formik'

import RadioGroup from '../../foundation/RadioGroup'
import FormField from '../FormField'

export interface FormikRadioGroupProps {
  children: ReactNode
  className?: string
  columns?: number
  id: string
  label?: ReactNode
  name: string
  onChange?: (value: any) => void
  validate?: (value: any) => undefined | string | Promise<any>
}

const FormikRadioGroup: FC<FormikRadioGroupProps> = ({
  label,
  children,
  className,
  id,
  name,
  columns,
  onChange,
  validate,
  ...rest
}) => (
  <Field name={name} validate={validate}>
    {({ field, form: { errors, touched, setFieldValue } }: FieldProps) => {
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)
      const value = String(field.value)

      return (
        <FormField
          className={className}
          id={id}
          label={label}
          errorMessage={errorMessage}
          displayError={displayError}
        >
          <RadioGroup
            {...field}
            value={value}
            columns={columns}
            onChange={(value) => {
              setFieldValue(field.name, value, true)
              if (onChange) onChange(value)
            }}
            {...rest}
          >
            {children}
          </RadioGroup>
        </FormField>
      )
    }}
  </Field>
)

export default FormikRadioGroup
