import { defineMessages } from 'react-intl'

const baseId = 'document_repository'

export default defineMessages({
  delete_button: {
    id: `${baseId}.delete_button`,
    defaultMessage: 'Delete',
  },
  edit_button: {
    id: `${baseId}.edit_button`,
    defaultMessage: 'Edit',
  },
  cancel_button: {
    id: `${baseId}.cancel_button`,
    defaultMessage: 'Cancel',
  },
  save_button: {
    id: `${baseId}.save_button`,
    defaultMessage: 'Save',
  },
  edit_document_header: {
    id: `${baseId}.edit_document_header`,
    defaultMessage: 'Edit Document',
  },
  edit_document_title: {
    id: `${baseId}.edit_document_title`,
    defaultMessage: 'Document title',
  },
  delete_document_header: {
    id: `${baseId}.delete_document_header`,
    defaultMessage: 'Delete the following document?',
  },
  delete_document_title: {
    id: `${baseId}.delete_document_title`,
    defaultMessage: 'Document Title: {documentTitle}',
  },
  delete_in_progress: {
    id: `${baseId}.delete_in_progress`,
    defaultMessage: 'Deleting',
  },
  table_header_document_title: {
    id: `${baseId}.table_header_document_title`,
    defaultMessage: 'Document Title',
  },
  table_header_upload_date: {
    id: `${baseId}.table_header_upload_date`,
    defaultMessage: 'Upload Date',
  },
  table_header_actions: {
    id: `${baseId}.table_header_actions`,
    defaultMessage: 'Actions',
  },
  load_more_documents: {
    id: `${baseId}.load_more_documents`,
    defaultMessage: 'Load more documents',
  },
  empty_document_message: {
    id: `${baseId}.empty_document_message`,
    defaultMessage: 'No documents were found for this patient.',
  },
})
