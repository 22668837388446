import React from 'react'
import { useHistory } from 'react-router-dom'

import { ConsultationContextType, useConsultation } from '~/core/config'

import { CallActiveModalView } from './CallActiveModalView'

interface CallActiveModalProps {
  redirectUrl: string
  stayOnPageUrl: string
  consultationContext: ConsultationContextType
}

const CallActiveModal = ({
  consultationContext,
  redirectUrl,
  stayOnPageUrl,
}: CallActiveModalProps) => {
  const consultation = useConsultation(consultationContext)
  const history = useHistory()
  const { id } = consultation

  const redirectUserEffect = () => {
    const redirectLocation = redirectUrl.replace(':id', id)
    history.replace(redirectLocation)
  }

  const stayInPlaceEffect = () => {
    const stayInPlaceLocation = stayOnPageUrl.replace(':id', id)
    history.replace(stayInPlaceLocation)
  }

  const handleHangUp = () => {
    redirectUserEffect()
  }

  return (
    <CallActiveModalView
      onStayInCall={stayInPlaceEffect}
      onHangUp={handleHangUp}
      dialogVisible
      onClose={stayInPlaceEffect}
    />
  )
}

export default CallActiveModal
