import { format } from 'date-fns'
import { ReplaySubject, zip } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

import { CONSULTATION_STATUSES } from '~/constants'
import { CarePlansCompletionModelInterface } from '~/core/config/modules/generated/types'
import {
  ErrorPolicy,
  observableState,
  promoteCarePlanDraftMutation,
  watchCarePlansQuery,
} from '~/core/reactive-helpers'
import { logException } from '~/core/sentry'
import updateCarePlanDraft from '~/features/care-plan/updateCarePlanDraft'
import { CarePlanDraftQuery } from '~/generated'

import {
  CompleteConsultationExtensionType,
  ExtensionStateType,
  ExtensionStatus,
} from '../CompleteConsultationModel'

import messages from './CarePlansCompletion.messages'

export interface CarePlansCompletionModelStateType extends ExtensionStateType {
  carePlanDraft?: CarePlanDraftQuery['carePlanDraft']
}

export interface CarePlansCompletionModelType
  extends CompleteConsultationExtensionType {}

const defaultState: CarePlansCompletionModelStateType = {
  status: ExtensionStatus.Initializing,

  carePlanDraft: undefined,
  errorMessage: undefined,
}

const formattedTimeZoneOffset = format(new Date(), 'XXX')
const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }

export const createCarePlansCompletionModel = (
  promoteCarePlanDraft: typeof promoteCarePlanDraftMutation,
  watchCarePlans: typeof watchCarePlansQuery
): CarePlansCompletionModelInterface => ({ consultationContext, global }) => {
  const [
    state,
    updateState,
    resetState,
  ] = observableState<CarePlansCompletionModelStateType>(defaultState)

  const carePlanEnabled = global?.featureFlags?.carePlanEnabled

  type ConsultationContextData = {
    consultationUuid: string
    patientUuid: string
    isConsultationComplete: boolean
  }

  const consultationContextData = new ReplaySubject<ConsultationContextData>(1)

  const reset = () => {
    resetState()
  }

  consultationContext.subscribe((response) => {
    const { consultation } = response.data

    if (consultation && consultation.patient) {
      consultationContextData.next({
        consultationUuid: consultation.uuid,
        patientUuid: consultation.patient?.uuid,
        isConsultationComplete:
          consultation.status === CONSULTATION_STATUSES.COMPLETED,
      })
    } else {
      reset()
    }
  })

  const init = () => {
    updateState({
      status: ExtensionStatus.Initializing,
      carePlanDraft: undefined,
      errorMessage: undefined,
    })

    consultationContextData
      .pipe(
        take(1),
        filter(({ isConsultationComplete }) => {
          if (isConsultationComplete || !carePlanEnabled) {
            updateState({ status: ExtensionStatus.Initialized })
            return false
          }
          return !isConsultationComplete
        }),
        map(({ consultationUuid, patientUuid }) => ({
          variables: {
            consultationId: consultationUuid,
            patientId: patientUuid,
          },
          errorPolicy: ErrorPolicy.none,
          context,
        })),
        watchCarePlans()
      )
      .subscribe({
        next: ({ data, loading, error }) => {
          if (loading) {
            updateState({ status: ExtensionStatus.Initializing })
          } else if (error) {
            updateState({
              status: ExtensionStatus.InitError,
              errorMessage: messages.error_fetching_care_plans,
            })
            logException(error)
          } else {
            const carePlanDraft = data?.carePlanDraft
            if (
              carePlanDraft?.__typename !== 'CarePlanDraft' &&
              carePlanDraft?.__typename !== 'CarePlanAuthorizationError' &&
              carePlanDraft?.__typename !== 'None'
            ) {
              updateState({
                status: ExtensionStatus.InitError,
                errorMessage: messages.error_fetching_care_plans,
              })
              logException(new Error('Unknown care plan type'))
              return
            }

            if (
              carePlanDraft?.__typename === 'CarePlanDraft' &&
              carePlanDraft?.metadata?.warnings?.length
            ) {
              updateState({
                status: ExtensionStatus.InitError,
                errorMessage: messages.invalid_care_plan,
              })
              logException(new Error('Invalid care plan'))
              return
            }

            updateState({
              carePlanDraft:
                carePlanDraft?.__typename === 'CarePlanDraft'
                  ? carePlanDraft
                  : undefined,
              status: ExtensionStatus.Initialized,
            })
          }
        },
        error: (error) => {
          updateState({
            status: ExtensionStatus.InitError,
            errorMessage: messages.error_fetching_care_plans,
          })
          logException(error)
        },
      })
  }

  const submit = () => {
    zip(consultationContextData, state)
      .pipe(
        take(1),
        filter(([{ isConsultationComplete }, { carePlanDraft }]) => {
          if (isConsultationComplete || !carePlanEnabled || !carePlanDraft) {
            updateState({
              status: ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
            return false
          }

          return true
        }),
        map(([{ patientUuid, consultationUuid }, { carePlanDraft }]) => ({
          variables: {
            input: {
              carePlanDraftContext: {
                patientId: patientUuid,
                consultationId: consultationUuid,
                carePlanDraftId:
                  (carePlanDraft?.__typename === 'CarePlanDraft' &&
                    carePlanDraft?.draftId) ||
                  '',
                numberOfEdits:
                  (carePlanDraft?.__typename === 'CarePlanDraft' &&
                    carePlanDraft?.metadata?.numberOfEdits) ||
                  0,
              },
            },
          },
          context,
          // needed to update the status of the care plan in the cache
          // in case clinician returns to the consultation page
          // after consultation completion
          update: updateCarePlanDraft(
            {
              patientId: patientUuid,
              consultationId: consultationUuid,
            },
            (
              draftCarePlan: CarePlanDraftQuery['carePlanDraft'],
              mutationResult: any
            ) => {
              if (
                mutationResult.data?.promoteCarePlanDraft.__typename !==
                'PromoteCarePlanDraftSuccess'
              ) {
                return draftCarePlan
              }

              return {
                __typename: 'None',
              }
            }
          ),
        })),
        promoteCarePlanDraft()
      )
      .subscribe({
        next: ({ error, loading }) => {
          if (error) {
            updateState({
              status: ExtensionStatus.SubmitError,
              errorMessage: messages.error_promoting_care_plan,
            })
            logException(error)
          } else {
            updateState({
              status: loading
                ? ExtensionStatus.Submitting
                : ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          updateState({
            status: ExtensionStatus.SubmitError,
            errorMessage: messages.error_promoting_care_plan,
          })
          logException(error)
        },
      })
  }

  return {
    state,
    init,
    submit,
  }
}

export const CarePlansCompletionModel = createCarePlansCompletionModel(
  promoteCarePlanDraftMutation,
  watchCarePlansQuery
)
