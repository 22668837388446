import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import messages from './CallServiceError.messages'
import styles from './CallServiceError.module.scss'

interface CallServiceErrorProps {
  errorMessage: string
}

const CallServiceError = ({ errorMessage }: CallServiceErrorProps) => {
  const fm = useFormatMessage()

  return (
    <div className={styles.redErrorWrapper}>
      <span className={styles.redErrorTitle}>
        {fm(messages.call_service_error_title)}
      </span>

      <div className={styles.redErrorMessage}>{errorMessage}</div>
    </div>
  )
}

export default CallServiceError
