import { isFilledArray } from '~/core'

import { CodeSuggestion, InputRankingListEntry, RankingMap } from './types'
import { useClinicalCodeWeightings } from './useClinicalCodeWeightings'

export const rankCodes = (
  unrankedCodes: CodeSuggestion[],
  inputRankingList: InputRankingListEntry[]
) => {
  if (!isFilledArray(inputRankingList)) {
    return unrankedCodes
  }

  const rankedCodes: CodeSuggestion[] = [...unrankedCodes]
  const rankingMap = inputRankingList.reduce((acc: RankingMap, cur) => {
    acc[cur.iri] = cur.weight
    return acc
  }, {})

  rankedCodes.sort((a, b) => {
    return (rankingMap[b.iri] || 0) - (rankingMap[a.iri] || 0)
  })

  return rankedCodes
}

export const useCodingRankingList = () => {
  const { rankingList } = useClinicalCodeWeightings()
  return (unrankedCodes: CodeSuggestion[]) =>
    rankCodes(unrankedCodes, rankingList)
}
