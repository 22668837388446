import { BlockJSON, Node, NodeJSON, Value, ValueJSON } from 'slate'

import { ConsultationNoteCode } from '~/generated'

import { PARAGRAPH } from '../../plugins/Paragraphs'
import createClinicalCodeNode from './createClinicalCodeNode'
import createTextNode from './createTextNode'

type Options = {
  json: boolean
}

const delimiter = '\n'

const wrapNode = (data: any): NodeJSON => {
  switch (typeof data) {
    case 'string':
      return createTextNode(data)
    case 'object':
      return data
    default:
      throw new Error('Data type is not supported')
  }
}

const sortCodesByOffset = (codes: ConsultationNoteCode[]) => {
  return codes.slice(0).sort((prev, next) => next.offset - prev.offset)
}

const getLastItem = (array: any[]) => {
  return array[array.length - 1] || null
}

const chunkString = (string: string, offset: number, length: number) => {
  return [
    string.substr(0, offset),
    string.substr(offset, length),
    string.substr(offset + length),
  ]
}

const deserialize = (
  string: string,
  codes: Array<ConsultationNoteCode>,
  options: Options = { json: false }
): ValueJSON | Value => {
  // @ts-expect-error
  const defaultBlock = Node.createProperties(PARAGRAPH)

  const lines = (string || '')
    .split(delimiter)
    .reduce((accumulator: any, text: string) => {
      const lastItem = getLastItem(accumulator)
      const offset = lastItem ? lastItem.offset + lastItem.text.length : 0

      return [
        ...accumulator,
        {
          text,
          offset,
          slices: [text],
        },
      ]
    }, [])

  sortCodesByOffset(codes || []).forEach((code) => {
    /**
     * Find line which contains current code
     */
    const line = lines.find(
      (v: any) =>
        v.offset <= code.offset &&
        v.offset + v.text.length >= code.offset + code.length
    )

    if (line) {
      /**
       * Get first chunk
       */
      const firstSlice = line.slices.shift()
      const codeOffset = code.offset - line.offset
      /**
       * Split string into chunks [head, code, tail]
       */
      const [headText, codeText, tailText] = chunkString(
        firstSlice,
        codeOffset,
        code.length
      )
      /**
       * Prepend new slices
       */
      line.slices.unshift(
        headText,
        createClinicalCodeNode(codeText, code.term, code.code),
        tailText
      )
    }
  })

  const paragraphs: BlockJSON[] = lines.map((line) => {
    return {
      ...defaultBlock,
      object: 'block',
      data: {},
      nodes: line.slices.map(wrapNode),
    }
  }) as BlockJSON[]

  const json: ValueJSON = {
    object: 'value',
    document: {
      object: 'document',
      data: {},
      nodes: paragraphs,
    },
  }

  return options.json ? json : Value.fromJSON(json)
}

export default deserialize
