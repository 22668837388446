import { isEmpty, isInstanceOf, isUndefined } from './utils'

export const validateRequired = (value, { required }) => {
  return required && isUndefined(value) && { reason: 'required' }
}

export const validateSingleType = (value, { type }) => {
  return type && !isInstanceOf(value, type)
    ? {
        reason: 'type',
        requiredType: type.name,
        valueType: value == null ? undefined : value.constructor.name,
      }
    : null
}

export const validateEmpty = (value, { empty }) => {
  return empty === false && isEmpty(value) ? { reason: 'empty' } : null
}

export const validateCallback = (value, { validate }) => {
  return validate && !validate(value) ? { reason: 'validate' } : null
}
