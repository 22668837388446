import React from 'react'

import { Tag } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import DataList from '~/ui/DataList'

import { ConsultationWorkflowV2 } from '../../useConsultationWorkflowsV2Query'
import { getWorkflowDetails } from '../../utils'

import messages from './Workflow.messages'

interface WorkflowProps {
  workflow: ConsultationWorkflowV2
}

const Workflow = ({ workflow }: WorkflowProps) => {
  const f = useMessages(messages)
  const { type, status, tagColour } = getWorkflowDetails(workflow)
  const statusLabel = f(`workflow_state_${status}` as keyof typeof messages)

  const data = [
    {
      key: f('workflows_type_key'),
      value: type,
    },
    {
      key: f('workflows_status_key'),
      value: status && <Tag color={tagColour}>{statusLabel}</Tag>,
    },
  ]

  return <DataList key={workflow.id} data={data} />
}

export default Workflow
