import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { PRESCRIPTIONS_QUERY } from '../PrescriptionsSection/queries'
import withDrugHandlers from './drugHandlers'
import {
  AddDrugToPrescriptionMutation,
  CreatePrescriptionMutation,
  SuggestDrugDirectionsMutation,
  UpdatePrescriptionMutation,
  ValidateDrugMutation,
} from './mutations'

export default compose(
  graphql(PRESCRIPTIONS_QUERY, {
    options: ({ consultationId }) => ({
      variables: {
        id: consultationId,
      },
    }),
  }),
  graphql(SuggestDrugDirectionsMutation, {
    name: 'suggestDrugMutation',
  }),
  graphql(ValidateDrugMutation, {
    name: 'validateDrugMutation',
    options: {
      ignoreResults: false,
    },
  }),
  graphql(CreatePrescriptionMutation, {
    name: 'createPrescriptionMutation',
    options: {
      ignoreResults: false,
    },
  }),
  graphql(UpdatePrescriptionMutation, {
    name: 'updatePrescriptionMutation',
    options: {
      ignoreResults: false,
    },
  }),
  graphql(AddDrugToPrescriptionMutation, {
    name: 'addDrugMutation',
  }),
  withDrugHandlers
)
