import { useCallback, useEffect, useRef } from 'react'

import useEffectOnce from './useEffectOnce'

type MouseEventHandler = (event: MouseEvent) => void

interface IuseDragAndDrop {
  onMouseDown?: MouseEventHandler
  onMouseMove?: MouseEventHandler
  onMouseUp?: MouseEventHandler
}

const useDragAndDrop = ({
  onMouseDown,
  onMouseMove,
  onMouseUp,
}: IuseDragAndDrop) => {
  const isDragging = useRef(false)
  const dragHeadRef = useRef<HTMLDivElement>(null)

  const savedMouseDownCallback = useRef<MouseEventHandler | undefined>(() => {})
  const savedMouseMoveCallback = useRef<MouseEventHandler | undefined>(() => {})
  const savedMouseUpCallback = useRef<MouseEventHandler | undefined>(() => {})
  useEffect(() => {
    savedMouseDownCallback.current = onMouseDown
    savedMouseMoveCallback.current = onMouseMove
    savedMouseUpCallback.current = onMouseUp
  })

  const handleMouseDown = useCallback((event: MouseEvent) => {
    if (
      event.target instanceof HTMLElement &&
      dragHeadRef.current?.contains(event.target)
    ) {
      isDragging.current = true

      if (savedMouseDownCallback.current) {
        savedMouseDownCallback.current(event)
      }
    }
  }, [])

  const handleMouseMove = useCallback((event: MouseEvent) => {
    if (isDragging.current && savedMouseMoveCallback.current) {
      savedMouseMoveCallback.current(event)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleMouseUp = useCallback((event: MouseEvent) => {
    if (isDragging.current) {
      isDragging.current = false

      if (savedMouseUpCallback.current) {
        savedMouseUpCallback.current(event)
      }
    }
  }, [])

  useEffectOnce(() => {
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  })

  return dragHeadRef
}

export default useDragAndDrop
