import { withApollo } from '@apollo/client/react/hoc'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import {
  CLINICAL_NOTES_CATEGORY,
  ERROR_ADD_NOTES_ACTION,
  SUCCESSFUL_ADD_NOTES_ACTION,
} from '~/constants/analytics'
import { isFilledArray } from '~/core'
import analytics from '~/core/analytics'
import { BabylonSerializer } from '~/features/clinical-coding/ClinicalNotesEditor'
import { ClinicalNotesDocument } from '~/generated'
import Label from '~/ui/Label'

import DiagnosisCode from './DiagnosisCode'
import { useDiagnosisStore } from './DiagnosisStore'

import messages from './Diagnosis.messages'

type InlineClinicalCode = {
  code: string
  term: string
  offset: number
  length: number
  hasNotes: boolean
}

type DiagnosisCodeListProps = {
  client: any
}

const createKey = (code: InlineClinicalCode) => {
  return `${code.offset}-${code.length}`
}

const joinParagraphs = (...args: Array<string>) => {
  return args
    .map((value) => value || '')
    .join('\n')
    .trim()
}

const trackEvent = analytics.trackEventFactory({
  category: CLINICAL_NOTES_CATEGORY,
})

const DiagnosisCodeList: React.FC<DiagnosisCodeListProps> = ({ client }) => {
  const fm = useFormatMessage()
  const { store, onChange } = useDiagnosisStore()

  const [, codes]: any = BabylonSerializer.serialize(
    // @ts-expect-error - TODO - fix the types here
    store.assessment || null,
    true
  )

  const handleCodeButtonClick = async (iri: string) => {
    try {
      const { data } = await client.query({
        query: ClinicalNotesDocument,
        variables: {
          iris: [{ iri }],
          resolveDrugs: false,
        },
      })

      const suggestions = data.getClinicalNotes || []
      const suggestion = suggestions.find(
        (value: any) => value.furtherActionNotes || value.managementNotes
      )

      if (suggestion) {
        const treatmentPlan = joinParagraphs(
          store.treatmentPlan,
          suggestion.managementNotes
        )

        const fallbackPlan = joinParagraphs(
          store.fallbackPlan,
          suggestion.furtherActionNotes
        )

        onChange({
          treatmentPlan,
          fallbackPlan,
        })
      }

      trackEvent({
        action: SUCCESSFUL_ADD_NOTES_ACTION,
        label: iri,
      })
    } catch (error) {
      trackEvent({
        action: ERROR_ADD_NOTES_ACTION,
        label: iri,
      })
      console.warn(error)
    }
  }

  return isFilledArray<InlineClinicalCode>(codes) ? (
    <Label value={fm(messages.clinical_code_list_label)}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {codes.map((code: InlineClinicalCode) => (
          <DiagnosisCode
            key={createKey(code)}
            iri={code.code}
            hasNotes={code.hasNotes}
            onClick={handleCodeButtonClick}
          >
            {code.term}
          </DiagnosisCode>
        ))}
      </div>
    </Label>
  ) : null
}

// @ts-expect-error
export default withApollo<DiagnosisCodeListProps>(DiagnosisCodeList)
