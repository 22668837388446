import { withHandlers } from 'recompose'

import {
  ADD_DRUG_ACTION,
  ADD_REPEATABLE_DRUG_ACTION,
  DRUG_ACTIONS_CATEGORY,
  ERROR_ADD_DRUG_ACTION,
  ERROR_ADD_REPEATABLE_DRUG_ACTION,
  ERROR_UPDATE_DRUG_ACTION,
  UPDATE_DRUG_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { PRESCRIPTIONS_QUERY } from '~/features/prescriptions/PrescriptionsSection/queries'

const findDraftPrescription = (prescriptions = []) => {
  return prescriptions.find((v) => v.prescriptionState === 'DRAFT')
}

const trackErrorEvent = ({ drug, consultationId, consultantId }) =>
  analytics.trackEvent({
    action: drug.repeatable
      ? ERROR_ADD_REPEATABLE_DRUG_ACTION
      : ERROR_ADD_DRUG_ACTION,
    category: DRUG_ACTIONS_CATEGORY,
    label: drug.drugName,
    consultationId,
    consultantId,
  })

const trackSuccessEvent = ({ drug, consultationId, consultantId }) =>
  analytics.trackEvent({
    action: drug.repeatable ? ADD_REPEATABLE_DRUG_ACTION : ADD_DRUG_ACTION,
    category: DRUG_ACTIONS_CATEGORY,
    label: drug.drugName,
    consultationId,
    consultantId,
  })

const createDrug = ({
  data,
  createPrescriptionMutation,
  addDrugMutation,
  user,
}) => async (drug, patientId, consumerNetwork, errorAlert) => {
  const { id: consultationId, prescriptions, region } = data.consultation
  const consultantId = user?.consultant?.uuid

  const draft = findDraftPrescription(prescriptions)

  if (draft) {
    try {
      await addDrugMutation({
        variables: {
          id: draft.id,
          region: region.iso_code,
          drug,
        },
      })
      trackSuccessEvent({ drug, consultationId, consultantId })
    } catch (exception) {
      trackErrorEvent({ drug, consultationId, consultantId })
      errorAlert({ logMessage: exception })
    }
  } else {
    try {
      await createPrescriptionMutation({
        variables: {
          region: region.iso_code,
          patientId,
          consultationId,
          consumerNetwork,
          drugs: [drug],
        },
        /**
         * TODO: Fix required.
         * We have all data to update cache without refetching, but for some
         * reason manual update leads to loosing all cached data.
         */
        refetchQueries: [
          {
            query: PRESCRIPTIONS_QUERY,
            variables: {
              id: consultationId,
            },
          },
        ],
      })
      trackSuccessEvent({ drug, consultationId, consultantId })
    } catch (exception) {
      trackErrorEvent({ drug, consultationId, consultantId })
      errorAlert({ logMessage: exception })
    }
  }
}

const updateDrug = ({
  query,
  updatePrescriptionMutation,
  data,
  consultationId,
  user,
}) => async (drug, errorAlert) => {
  const { prescriptionId, drugId } = query
  const consultantId = user?.consultant?.uuid

  try {
    await updatePrescriptionMutation({
      variables: {
        id: prescriptionId,
        region: data.consultation.region.iso_code,
        prescriptionDrugId: drugId,
        drug,
      },
    })
    analytics.trackEvent({
      action: UPDATE_DRUG_ACTION,
      category: DRUG_ACTIONS_CATEGORY,
      label: drug.drugName,
      consultationId,
      consultantId,
    })
  } catch (exception) {
    analytics.trackEvent({
      action: ERROR_UPDATE_DRUG_ACTION,
      category: DRUG_ACTIONS_CATEGORY,
      label: drug.drugName,
      consultationId,
      consultantId,
    })
    errorAlert({ logMessage: exception })
  }
}

export default withHandlers({
  createDrug,
  updateDrug,
})
