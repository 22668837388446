import classnames from 'classnames'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import messages from './PrescriptionDrug.messages'
import styles from './styles.module.scss'

const PrescriptionDrug = ({ drug, onClick }) => {
  const fm = useFormatMessage()

  return (
    <tr className={styles.suggestedPrescriptionRow}>
      <td>{drug.ageGroup}</td>
      <td>{drug.drugName}</td>
      <td>{drug.quantity}</td>
      <td>{drug.duration}</td>
      <td>
        <div>{drug.guidance}</div>
        {drug.penAllergy ? (
          <div className={styles.penAllergyYes}>
            <i className="fas fa-exclamation-triangle" />{' '}
            {fm(messages.penicillin_allergy_warning_message)}
          </div>
        ) : (
          <div className={styles.penAllergyNo}>
            <i className="fas fa-check-circle" />{' '}
            {fm(messages.penicillin_allergy_safe_to_use_message)}
          </div>
        )}
      </td>
      <td>
        <i
          role="button"
          tabIndex="0"
          className={classnames('fas fa-plus-circle', styles.addButton)}
          onClick={onClick}
        />
      </td>
    </tr>
  )
}

export default PrescriptionDrug
