import React from 'react'

import styles from './Toggle.module.scss'

type ToggleProps = {
  selected?: boolean
  onChange?: (value: boolean) => void
}

/**
 * ![](https://developer.apple.com/design/human-interface-guidelines/components/selection-and-input/toggles/images/switches-default.png)
 *
 *
 * It's iOS style like toggle/switch component but with Babylon's colors.
 * @param selected - current value
 * @param onChange - onChange callback
 * @constructor
 */
export const Toggle: React.FC<ToggleProps> = ({ selected, onChange }) => {
  return (
    <label htmlFor="switch" className={styles.toggle}>
      <input
        id="switch"
        checked={selected}
        type="checkbox"
        onChange={(event) => {
          const {
            target: { checked },
          } = event
          onChange?.(checked)
        }}
      />
      <span className={styles.slider} />
    </label>
  )
}
