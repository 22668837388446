import { useReducer } from 'react'

import { CallStatusReducerModelProviderInterface } from '~/core/config/modules/generated/types'
import { useModelProvider } from '~/core/container/provider'

import { getInitialState, reducer } from './reducer'
import { CallMediumEntitlement, CallStatusReducerModel } from './types'

export * from './types'
export * from './reducer'

export const useCallStatusReducer = (options: {
  callMedium: CallMediumEntitlement
}): CallStatusReducerModel => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(options.callMedium)
  )
  return {
    state,
    dispatch,
  }
}

export const CallStatusReducerModelProvider: CallStatusReducerModelProviderInterface = (options: {
  callMedium: string // Call medium is a string as its an option but should be one of the values in the enum
}) => {
  return useModelProvider<CallStatusReducerModel>(useCallStatusReducer, options)
}
