export type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

export const isTruthy = <T>(value: T): value is Truthy<T> => Boolean(value)

/**
 * If passed value is not null/undefined then mapping function is applied.
 * Undefined is returned otherwise.
 * @param value - value to be mapped.
 * @param fn - transformation function
 */
export const mapOptional = <T, U>(
  value: T | undefined | null,
  fn: (unwrapped: T) => U
): NonNullable<U> | undefined => {
  if (value == null) {
    return undefined
  }
  const mapped = fn(value)
  return mapped ?? undefined
}
