/* Restricted Clipboard
 * Allows users to copy text within the same window/tab but blocks copying to
 * another window/tab/application.
 *
 * NB: Restricted Clipboard will persist across page refreshes AND across
 * tab duplication, but not across new tabs/windows.
 *
 * NB2: If you want to programmatically add to the clipboard, you can use the
 * native API: `await navigator.clipboard.writeText('message here')` which will
 * not be blocked by the restricted clipboard
 *
 * NB3: If you want to programmatically add to the restricted clipboard, you can use the
 * `copyToRestrictedClipboard()` method exposed by this module. Synthetic clipboard events
 * are not supported and the sessionId can't be stored in the clipboard's json data unless
 * a real clipboard event is triggered using `window.document.execCommand()`
 */

import uuid from 'uuid/v4'

import getValueSetter from './getValueSetter'

let clipboardBlockerMessage =
  'NOTE: Copying data from the Clinical Portal is not allowed.'
let clipboardText = ''
let isInitialised = false
let sessionId = null

const init = (blockerMessage) => {
  if (isInitialised) {
    console.warn('Restricted clipboard already initialised')

    return
  }

  setSessionId()
  // This allows us to save clipboard text over page refreshes
  clipboardText = sessionStorage.getItem('clipboardText') || ''
  window.addEventListener('copy', handleCopy)
  window.addEventListener('paste', handlePaste, true)

  if (blockerMessage) {
    clipboardBlockerMessage = blockerMessage
  }

  isInitialised = true
}

const setSessionId = () => {
  sessionId = sessionStorage.getItem('sessionId')

  if (!sessionId) {
    sessionId = uuid()
    sessionStorage.setItem('sessionId', sessionId)
  }
}

const handleCopy = (e) => {
  e.preventDefault()
  clipboardText = window.getSelection().toString()
  sessionStorage.setItem('clipboardText', clipboardText)
  e.clipboardData.setData('text/plain', clipboardBlockerMessage)
  e.clipboardData.setData('text/html', clipboardBlockerMessage)
  e.clipboardData.setData('application/json', JSON.stringify({ sessionId }))
}

const shouldUseCustomClipboard = (e) => {
  try {
    const jsonData = JSON.parse(e.clipboardData.getData('application/json'))

    return jsonData.sessionId === sessionId
  } catch (err) {
    return false
  }
}

const handlePaste = (e) => {
  if (!shouldUseCustomClipboard(e)) {
    return
  }

  e.preventDefault()

  if (clipboardText.length === 0) {
    return
  }

  const targetField = e.target
  const valueSetter = getValueSetter(targetField)

  if (!valueSetter) {
    return
  }

  if (targetField.selectionStart || targetField.selectionStart === 0) {
    // Get text selection locations
    const startPos = targetField.selectionStart
    const endPos = targetField.selectionEnd
    // Insert clipboardText
    const newValue =
      targetField.value.substring(0, startPos) +
      clipboardText +
      targetField.value.substring(endPos, targetField.value.length)
    valueSetter.call(targetField, newValue)
    // Move caret back to initial location
    targetField.selectionStart = startPos + clipboardText.length
    targetField.selectionEnd = startPos + clipboardText.length
  } else {
    valueSetter.call(targetField, targetField.value + clipboardText)
  }

  // Fire input event so that React onChange handlers get called
  const inputEvent = new Event('input', { bubbles: true })
  targetField.dispatchEvent(inputEvent)
}

const copy = async (text) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text)
  }

  console.warn('This browser does not support copying')

  return undefined
}

const copyToRestrictedClipboard = (node) => {
  if (!isInitialised) {
    throw new Error('The restricted clipboard has not been initialised yet')
  }

  const selection = window.getSelection()
  const range = window.document.createRange()
  selection.removeAllRanges()
  range.selectNode(node)
  selection.addRange(range)
  window.document.execCommand('copy')
  selection.removeAllRanges()
}

const getValue = () => sessionStorage.getItem('clipboardText')

const pause = () => {
  window.removeEventListener('copy', handleCopy)
}

const resume = () => {
  window.addEventListener('copy', handleCopy)
}

export default {
  init,
  copy,
  copyToRestrictedClipboard,
  getValue,
  pause,
  resume,
  shouldUseCustomClipboard,
}
