import React, { ChangeEvent } from 'react'

import FormikInput, { floatFormatter, FormikInputProps } from '../FormikInput'

const FormikNumberInput = ({ onChange, ...rest }: FormikInputProps) => {
  const handleBlur = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue?: (field: string, value: any) => void
  ) => {
    const eventValue = event.target.value

    setFieldValue?.(
      event.target.name,
      eventValue === '' ? undefined : Number(eventValue)
    )
  }

  return <FormikInput format={floatFormatter} onBlur={handleBlur} {...rest} />
}

export default FormikNumberInput
