import { defineMessages } from 'react-intl'

const baseId = 'components.molecules.onboarding'

export default defineMessages({
  previous: {
    id: `${baseId}.previous`,
    defaultMessage: 'Previous',
  },
  restart: {
    id: `${baseId}.restart`,
    defaultMessage: 'Restart',
  },
  next: {
    id: `${baseId}.next`,
    defaultMessage: 'Next',
  },
})
