import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { PresentingComplaintFragment } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import Gallery from '~/ui/LightboxGallery'
import SectionItem from '~/ui/SectionItem'

import { prepareImageUrl } from './utils'

import messages from './ImageGallery.messages'

export type ImageGalleryProps = {
  images?: PresentingComplaintFragment['images']
}

export const ImageGallery = ({ images }: ImageGalleryProps) => {
  const fm = useFormatMessage()

  if (images) {
    if (images?.__typename === 'RetrievalError') {
      return (
        <ErrorPanel
          title={fm(messages.patient_image_error)}
          error={new Error(images.message)}
        />
      )
    }

    if (isFilledArray(images.items)) {
      return (
        <SectionItem
          title={fm(messages.images_title)}
          info={fm(messages.images_tooltip)}
        >
          <Gallery topMargin images={images.items.map(prepareImageUrl)} />
        </SectionItem>
      )
    }
  }

  return null
}
