import React from 'react'

import { USEhrSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useEhrConsultationNotesQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import USEhrSummary from './USEhrSummary'

import messages from './USEhrSummary.messages'

const USEhrSummaryModule: USEhrSummaryPluginInterface = () => {
  const USEhrSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)

    const { data, loading, error, refetch } = useEhrConsultationNotesQuery({
      variables: { id: consultation.id },
      fetchPolicy: 'network-only',
    })
    const usEhrNote = data?.consultation?.usEhrNote

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={f('error_loading')}
          subtitle={f('contact_support')}
          data-testid="review-error"
          retry={() => refetch()}
        />
      )
    }

    if (!usEhrNote || loading) {
      return null
    }

    return <USEhrSummary usEhrNote={usEhrNote} />
  }

  return USEhrSummaryWrapper
}

export default USEhrSummaryModule
