import React from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './Timestamp.messages'

interface TimestampParams {
  timestamp?: string
  className?: string
  performer?: string
}

const Timestamp = ({ timestamp, performer, className }: TimestampParams) => {
  const fm = useFormatMessage()

  const getperformerMessage = () => {
    switch (performer) {
      case 'clinician':
        return fm(messages.metrics_source_clinician)
      case 'patient':
        return fm(messages.metrics_source_patient)
      default:
        return ''
    }
  }
  return timestamp ? (
    <Text className={className} color="grey-type" size="small">
      {timestamp}
      <span>{getperformerMessage()}</span>
    </Text>
  ) : null
}

export default Timestamp
