import React from 'react'

import { BookingReasonSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useBookingReasonQuery } from '~/generated'

import BookingReasonSummary from './BookingReasonSummary'

const BookingReasonSummaryModule: BookingReasonSummaryPluginInterface = () => {
  const BookingReasonSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const { data, loading, error } = useBookingReasonQuery({
      variables: { id: consultation.id },
    })

    if (loading || error) {
      return null
    }

    return <BookingReasonSummary consultation={data?.consultation} />
  }

  return BookingReasonSummaryWrapper
}

export default BookingReasonSummaryModule
