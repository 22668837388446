import { gql } from '@apollo/client'

export const INCOMPLETE_CONSULTATION_WARNING_QUERY = gql`
  query CpIncompleteConsultationWarning(
    $searchCriteria: ConsultationSearchCriteria
  ) {
    consultationsv2(searchCriteria: $searchCriteria) {
      totalItems
    }
  }
`

export const INCOMPLETE_CONSULTATION_WARNING_FRAGMENT = gql`
  fragment CpIncompleteConsultationWarning on Consultation {
    id
    status
    scheduledTime
    patientId
  }
`
