import { useEffect, useState } from 'react'

import { SERVICE_NOW_BASE_URL } from '~/constants'

interface ServiceNowConstructor {
  new (args: { instance: string }): ServiceNowChat
}

export interface ServiceNowChat {
  open: () => void
}

declare global {
  interface Window {
    ServiceNowChat: ServiceNowConstructor
  }
}

// Running this function will add the ServiceNow chat bubble to the page.
function createServiceNowChatBubble() {
  return new window.ServiceNowChat({
    instance: SERVICE_NOW_BASE_URL,
  })
}

export function useServiceNowChatBubble(enabled: boolean = false) {
  const [chat, setChat] = useState<null | ServiceNowChat>(null)
  useEffect(() => {
    if (!enabled) return
    if (enabled && chat) return
    const serviceNowChat = createServiceNowChatBubble()
    setChat(serviceNowChat)
  }, [enabled, chat])
  return chat
}
