import * as Sentry from '@sentry/browser'

import { SENTRY_URL } from '~/constants'

import { randomAlphaNumeric } from './utils'

const isSentryEnabled = !!SENTRY_URL

const logMessage: typeof Sentry.captureMessage = (message, captureContext?) => {
  if (isSentryEnabled) {
    return Sentry.captureMessage(message, captureContext)
  }
  console.log(message) // eslint-disable-line no-console
  /* This is done to return string for testing and localhost */
  return randomAlphaNumeric(32)
}

export { logMessage }
