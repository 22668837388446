import { useEffect } from 'react'

import analytics from '~/core/analytics'

interface UseConsultationAnalyticsFields {
  consultationId: string
  consultationUuid?: string
  patientId?: string
  patientUuid?: string
  clinicianId?: string
  clinicianUuid?: string
}

const useConsultationAnalyticsFields = ({
  consultationId,
  consultationUuid,
  patientId,
  patientUuid,
  clinicianId,
  clinicianUuid,
}: UseConsultationAnalyticsFields) => {
  useEffect(() => {
    analytics.setFields({
      consultationId,
      consultationUuid: consultationUuid || '',
    })

    return () => {
      analytics.clearFields([
        'consultationId',
        'consultationUuid',
        'patientId',
        'patientUuid',
        'clinicianId',
        'clinicianUuid',
      ])
    }
  }, [
    consultationId,
    consultationUuid,
    patientId,
    patientUuid,
    clinicianId,
    clinicianUuid,
  ])
}

export default useConsultationAnalyticsFields
