import { MessageDescriptor } from 'react-intl'

import { MetricRating } from '../bmi'

import messages from './smokingUtils.messages'

export enum SmokingStatus {
  non_smoker = 'non_smoker',
  ex_smoker = 'ex_smoker',
  smoker = 'smoker',
}

export type SmokerDetails = {
  value: MessageDescriptor
  rating: MetricRating
  status?: SmokingStatus
}

export const getSmokerValue = (status?: SmokingStatus) => {
  switch (status) {
    case SmokingStatus.smoker:
      return messages.smoking_status_smoker_text
    case SmokingStatus.non_smoker:
      return messages.smoking_status_non_smoker_text_v2
    case SmokingStatus.ex_smoker:
      return messages.smoking_status_ex_smoker_text
    default:
      return messages.na_text
  }
}

export const getSmokerRating = (status?: SmokingStatus): MetricRating => {
  switch (status) {
    case SmokingStatus.smoker:
      return MetricRating.danger
    case SmokingStatus.non_smoker:
      return MetricRating.normal
    case SmokingStatus.ex_smoker:
      return MetricRating.warning
    default:
      return MetricRating.generic
  }
}

export const getSmokingDetails = (status?: SmokingStatus): SmokerDetails => {
  return {
    value: getSmokerValue(status),
    rating: getSmokerRating(status),
    status,
  }
}
