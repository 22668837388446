/* eslint-disable comma-style */
import { ApolloError } from '@apollo/client'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Form, useField } from 'formik'
import find from 'lodash/find'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  Alert,
  Button,
  FormikDatePicker,
  FormikRadioGroup,
  FormikSelect,
  FormikTextarea,
  Grid,
  Label,
  Radio,
  SelectOptionTypeBase,
  SelectValueType,
  Text,
  Tooltip,
} from '@babylon/core-ui'

import {
  APPOINTMENT_INVITES_CADENCE_ACTION,
  APPOINTMENT_INVITES_CATEGORY,
  APPOINTMENT_INVITES_DURATION_ACTION,
  APPOINTMENT_INVITES_PROFESSION_ACTION,
  APPOINTMENT_INVITES_RECURRENCE_ACTION,
  APPOINTMENT_INVITES_SELECT_PROVIDER_ACTION,
  APPOINTMENT_INVITES_START_DATE_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import {
  ConsumerNetworkProfession,
  InviteFormMedium,
  InviteFormValues,
} from '~/features/appointment-invites/AppointmentInvitesModal/types'
import { RecurrenceFrequency, RecurrencePatterns } from '~/generated'
import Message from '~/ui/Message'

import { ServiceType } from '../types'
import { ProviderType } from './AppointmentInvitesModalView'
import {
  FormOption,
  getDurationOptions,
  getProfessionOptions,
  getServiceTypeOptions,
  isAvailabilityError as isAvailability,
  makeTimeOptions,
  matchRecommendedDuration,
  useIsRecurrenceInviteBooking,
} from './utils'

import messages from './AppointmentInvitesModal.messages'
import styles from './styles.module.scss'

export interface AppointmentInvitesFormProps {
  professions: ConsumerNetworkProfession[]
  serviceTypes: ServiceType[]
  clinicianServiceTypes: ServiceType[]
  isMediumFieldVisible: boolean
  professionName: string
  loading: boolean
  error?: ApolloError
}

const MAX_RECURRENCE_FREQUENCY = 5

const typedKey = (key: keyof InviteFormValues) => key

/**
 * Helper which makes the field type-safe with their keys.
 * Also, It keeps the constant reference of the setter, so it's safe to use it in useEffect deps.
 */
const useInviteFormField = <
  FieldName extends keyof InviteFormValues,
  R = InviteFormValues[FieldName]
>(
  fieldName: FieldName
): {
  value: R
  setValue: (r: R) => void
} => {
  const [{ value }, , { setValue }] = useField<R>(fieldName)
  const setValueRef = useRef(setValue)
  const memoedSetValue = useCallback((v: R) => setValueRef.current(v), [])
  setValueRef.current = setValue
  return {
    value,
    setValue: memoedSetValue,
  }
}

const trackEvent = analytics.trackEventFactory({
  category: APPOINTMENT_INVITES_CATEGORY,
})

const trackRecurrenceOptionChange = (recurrence: number) => {
  trackEvent({
    action: APPOINTMENT_INVITES_RECURRENCE_ACTION,
    label: `Number of sessions: ${recurrence}`,
  })
}

const trackCadenceOptionChange = (cadence?: string | null) => {
  trackEvent({
    action: APPOINTMENT_INVITES_CADENCE_ACTION,
    label: `choose appointment repeat cadences ${cadence?.toLocaleLowerCase()}`,
  })
}

const DateTimeSection = ({
  selectedDate,
  showTime,
}: {
  selectedDate: Date | null
  showTime?: boolean
}) => {
  const f = useMessages(messages)
  const intl = useIntl()

  const trackDateChange = (date?: Date) => {
    trackEvent({
      action: APPOINTMENT_INVITES_START_DATE_ACTION,
      label: f('date_analytics_label', { date: date?.toLocaleDateString() }),
    })
  }

  const times = useMemo(() => {
    return makeTimeOptions(15, intl.formatTime)
  }, [intl])

  return (
    <div className={styles.dateTimeContainer}>
      <FormikDatePicker
        name={typedKey('startDate')}
        id={typedKey('startDate')}
        label={showTime ? f('date_picker_label_book') : f('date_picker_label')}
        dateFormat="M/d/yyyy"
        className={styles.date}
        selected={selectedDate}
        minDate={new Date()}
        icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        onSelect={trackDateChange}
      />
      {showTime ? (
        <FormikSelect
          data-testid="time-input"
          name={typedKey('time')}
          id={typedKey('time')}
          className={styles.time}
          label={f('time_picker_label')}
          placeholder={f('time_picker_label')}
          options={times}
        />
      ) : null}
    </div>
  )
}
const defaultNoRecurrenceCadenceOption = {
  __typename: 'RecurrencePatterns',
  frequency: RecurrenceFrequency.Weekly,
  interval: 1,
  max_sessions: 1,
  label: 'NO_CADENCE',
} as RecurrencePatterns

const useResetSelectedServiceTypeOnServiceTypeOptionsChange = (
  serviceTypeOptions: FormOption<ServiceType>[],
  selectedServiceType: ServiceType | null,
  setSelectedServiceType: (s: ServiceType | null) => void
) => {
  useEffect(() => {
    const isSelectedTypeAvailableInCurrentFormOptions =
      serviceTypeOptions.find((o) => o.value.id === selectedServiceType?.id) !=
      null

    if (!isSelectedTypeAvailableInCurrentFormOptions) {
      setSelectedServiceType(null)
    }
  }, [serviceTypeOptions, setSelectedServiceType, selectedServiceType])
}

const useResetSelectedProviderOnMediumChange = (
  selectedMedium: InviteFormMedium | null,
  setSelectedProvider: (r: ProviderType | null) => void
) => {
  useEffect(() => {
    if (selectedMedium === InviteFormMedium.faceToFace) {
      setSelectedProvider(null)
    }
  }, [selectedMedium, setSelectedProvider])
}

const ProviderTypeSelector = ({
  onChange,
  selectedProvider,
}: {
  onChange: (type: ProviderType) => void
  selectedProvider: ProviderType | null
}) => {
  const f = useMessages(messages)

  return (
    <>
      <Label htmlFor="providerType">{f('provider_label')}</Label>
      <FormikRadioGroup
        name={typedKey('providerType')}
        id={typedKey('providerType')}
        onChange={onChange}
      >
        <div className={styles.radioGroupContainer}>
          <Radio
            value={ProviderType.anyProvider}
            checked={selectedProvider === ProviderType.anyProvider}
          >
            {f('any_provider_label')}
          </Radio>
          <Radio
            value={ProviderType.currentConsultant}
            checked={selectedProvider === ProviderType.currentConsultant}
          >
            {f('current_consultant_label')}
          </Radio>
        </div>
      </FormikRadioGroup>
    </>
  )
}

const MediumTypeSelector = () => (
  <>
    <FormikRadioGroup id={typedKey('medium')} name={typedKey('medium')}>
      <div className={styles.mediumGroupContainer}>
        <Radio value={InviteFormMedium.faceToFace} type="pill" inline>
          Face to face
        </Radio>
        <Radio value={InviteFormMedium.digital} type="pill" inline>
          Digital
        </Radio>
      </div>
    </FormikRadioGroup>
  </>
)

const AppointmentTypeSelector = ({
  serviceTypesInvitesEnabled,
  serviceTypeOptions,
  professionOptions,
  onServiceTypeSelected,
  onProfessionSelected,
}: {
  serviceTypesInvitesEnabled: undefined | boolean
  serviceTypeOptions: FormOption<ServiceType>[]
  onServiceTypeSelected: (s: FormOption<ServiceType>) => void
  professionOptions: FormOption<string>[]
  onProfessionSelected: (p: FormOption<string>) => void
}) => {
  const f = useMessages(messages)
  return (
    <>
      {serviceTypesInvitesEnabled ? (
        <FormikSelect
          name={typedKey('serviceType')}
          id={typedKey('serviceType')}
          label={
            <div className={styles.serviceTypeLabelContainer}>
              {f('appointment_type_label')}
              <Tooltip info={f('appointment_type_tooltip')} placement="right" />
            </div>
          }
          placeholder={f('appointment_type_placeholder')}
          options={serviceTypeOptions}
          onChange={onServiceTypeSelected as (s: SelectValueType) => void}
          isSearchable
        />
      ) : (
        <FormikSelect
          name={typedKey('profession')}
          id={typedKey('profession')}
          label={f('profession_label')}
          placeholder={f('profession_placeholder')}
          options={professionOptions}
          onChange={onProfessionSelected as (s: SelectValueType) => void}
        />
      )}
    </>
  )
}

const RecurrenceOptionSelector = ({
  currentCadence,
  onCadenceChange,
  patternsOptions,
  recurrenceOptions,
  shouldShowCadenceDropdown,
}: {
  shouldShowCadenceDropdown: undefined | boolean
  patternsOptions: FormOption<RecurrencePatterns>[]
  onCadenceChange: (selected: SelectValueType) => void
  currentCadence: RecurrencePatterns | null
  recurrenceOptions: FormOption<number>[]
}) => {
  const f = useMessages(messages)
  return (
    <div
      className={classNames(
        styles.recurrenceSelector,
        shouldShowCadenceDropdown ? styles.cadenceContainer : undefined
      )}
    >
      {shouldShowCadenceDropdown ? (
        <FormikSelect
          name={typedKey('cadence')}
          id={typedKey('cadence')}
          label={f('cadence_dropdown_label')}
          options={patternsOptions}
          className={styles.select}
          onChange={onCadenceChange}
        />
      ) : null}
      <FormikSelect
        isDisabled={
          shouldShowCadenceDropdown &&
          currentCadence?.label === defaultNoRecurrenceCadenceOption.label
        }
        name={typedKey('recurrence')}
        id={typedKey('recurrence')}
        label={
          shouldShowCadenceDropdown
            ? f('recurrence_and_cadence_dropdown_label')
            : f('recurrence_dropdown_label')
        }
        options={recurrenceOptions}
        className={styles.select}
        style={{ order: 1 }}
        onChange={(selected) =>
          trackRecurrenceOptionChange((selected as SelectOptionTypeBase).value)
        }
        data-testid="recurrence_dropdown_value"
      />
    </div>
  )
}

const AppointmentInvitesForm = ({
  professions,
  serviceTypes,
  clinicianServiceTypes,
  professionName,
  isMediumFieldVisible,
  loading,
  error,
}: AppointmentInvitesFormProps) => {
  const f = useMessages(messages)

  const {
    recurringInvitesEnabled,
    recurringInvitesMultipleInvitesEnabled,
    recurringInvitesBiweeklyBookingEnabled,
    serviceTypesInvitesEnabled,
  } = useFeatureFlags()

  const {
    value: selectedProfessionID,
    setValue: setProfession,
  } = useInviteFormField('profession')
  const {
    value: selectedServiceType,
    setValue: setSelectedServiceType,
  } = useInviteFormField('serviceType')
  const { setValue: setDuration } = useInviteFormField('duration')
  const {
    value: currentRecurrence,
    setValue: setRecurrence,
  } = useInviteFormField('recurrence')
  const { value: currentCadence, setValue: setCadence } = useInviteFormField(
    'cadence'
  )
  const {
    value: selectedProvider,
    setValue: setSelectedProvider,
  } = useInviteFormField('providerType')
  const { value: startDate } = useInviteFormField('startDate')
  const { value: selectedMedium } = useInviteFormField('medium')
  const [isRecommendedDuration, setIsRecommendedDuration] = useState<boolean>(
    true
  )

  const professionOptions = useMemo(
    () => getProfessionOptions(professions, professionName, selectedProvider),
    [professions, professionName, selectedProvider]
  )

  const serviceTypeOptions = useMemo(
    () =>
      getServiceTypeOptions(
        serviceTypes,
        clinicianServiceTypes,
        selectedProvider,
        selectedMedium
      ),
    [serviceTypes, clinicianServiceTypes, selectedProvider, selectedMedium]
  )

  const durationOptions = getDurationOptions(
    professions,
    selectedProfessionID!,
    f
  )

  const getTranslatedString = (value: string) => {
    switch (value) {
      case 'WEEKLY':
        return f('cadence_label_weekly')
      case 'BIWEEKLY':
        return f('cadence_label_biweekly')
      default:
        return ''
    }
  }

  const getMaxSessionForFrequencyFromServiceType = (
    serviceType: ServiceType | null,
    cadence: RecurrencePatterns | null
  ) => {
    if (cadence) {
      return cadence?.max_sessions
    }
    const recurrencePatternForFrequency = find(
      serviceType?.service_type_settings?.recurrence_settings
        ?.recurrence_patterns,
      { frequency: RecurrenceFrequency.Weekly, interval: 1 }
    )
    return recurrencePatternForFrequency?.max_sessions
  }

  const createCadenceOptions = (
    serviceType: ServiceType | null
  ): FormOption<RecurrencePatterns>[] => {
    const cadenceFromServiceTypeSettings =
      serviceType?.service_type_settings?.recurrence_settings?.recurrence_patterns
        .map((pattern) => ({
          label: getTranslatedString(pattern.label ?? ''),
          value: pattern,
        }))
        .filter((option) => !!option.label) || []
    if (cadenceFromServiceTypeSettings.length) {
      cadenceFromServiceTypeSettings.unshift({
        label: f('no_cadence_label'),
        value: defaultNoRecurrenceCadenceOption,
      })
    }
    return cadenceFromServiceTypeSettings
  }

  const cadenceOptionsForServiceType = createCadenceOptions(selectedServiceType)

  const createRecurrenceOptions = (
    serviceType: ServiceType | null,
    cadence: RecurrencePatterns | null
  ): FormOption<number>[] => {
    const maxFrequency =
      getMaxSessionForFrequencyFromServiceType(serviceType, cadence) ||
      MAX_RECURRENCE_FREQUENCY
    const options = []
    for (let num = 1; num <= maxFrequency; num++) {
      options.push({
        value: num,
        label:
          num === 1
            ? f('single_session_label')
            : `${f('recurrence_option_label', { num })}`,
      })
    }
    return options
  }

  const handleProfessionChange = (selected: FormOption<string>) => {
    const selectedOption = (selected as SelectOptionTypeBase).value
    const selectedProfession = professions.find(
      ({ id }: ConsumerNetworkProfession) => id === selectedOption
    )
    setDuration(selectedProfession?.defaultSlotDurationMinutes ?? null)
    setIsRecommendedDuration(true)

    trackEvent({
      action: APPOINTMENT_INVITES_PROFESSION_ACTION,
      label: String(selected.label),
    })
  }

  const handleDurationChange = (selectedDuration: SelectValueType) => {
    const selectedAlternativeDuration = matchRecommendedDuration(
      professions,
      selectedProfessionID,
      selectedDuration
    )
    setIsRecommendedDuration(!selectedAlternativeDuration)

    trackEvent({
      action: APPOINTMENT_INVITES_DURATION_ACTION,
      label: String((selectedDuration as SelectOptionTypeBase).label),
    })
  }

  const handleRadioChange = (type: ProviderType) => {
    setProfession(null)
    setDuration(null)
    trackEvent({
      action: APPOINTMENT_INVITES_SELECT_PROVIDER_ACTION,
      label: f('provider_analytics_label', { type }),
    })
  }

  const isRecurrenceInviteBooking = useIsRecurrenceInviteBooking()(
    selectedServiceType,
    selectedProvider
  )

  const recurrenceOptionsForCurrentServiceType = createRecurrenceOptions(
    selectedServiceType,
    currentCadence
  )

  const shouldShowRecurrenceOptions =
    recurringInvitesMultipleInvitesEnabled &&
    selectedProvider === ProviderType.currentConsultant

  const shouldShowCadenceDropdown =
    recurringInvitesBiweeklyBookingEnabled &&
    !!cadenceOptionsForServiceType.length

  const isCadenceInServiceType = (
    serviceType: ServiceType | null,
    cadence: RecurrencePatterns | null
  ): boolean => {
    const serviceTypeRecurrencePatterns =
      serviceType?.service_type_settings?.recurrence_settings
        ?.recurrence_patterns
    if (!serviceTypeRecurrencePatterns || !cadence) return false
    const serviceTypeContainsCadence = find(
      [...serviceTypeRecurrencePatterns, defaultNoRecurrenceCadenceOption],
      { frequency: cadence?.frequency, interval: cadence?.interval }
    )
    return !!serviceTypeContainsCadence
  }

  const handleServiceTypeChange = (
    serviceTypeOption: FormOption<ServiceType>
  ) => {
    const currentValue = (serviceTypeOption as SelectOptionTypeBase)
      .value as ServiceType
    const newRecurrenceOptions = createRecurrenceOptions(
      currentValue,
      currentCadence
    )
    if (
      shouldShowRecurrenceOptions &&
      currentRecurrence > newRecurrenceOptions.length
    ) {
      setRecurrence(newRecurrenceOptions[newRecurrenceOptions.length - 1].value)
    }
    if (
      shouldShowRecurrenceOptions ||
      !isCadenceInServiceType(currentValue, currentCadence)
    ) {
      setCadence(null)
    }
  }

  const handleCadenceChange = (selected: SelectValueType) => {
    const selectedCadence = (selected as SelectOptionTypeBase)
      .value as RecurrencePatterns
    if (selectedCadence?.label === defaultNoRecurrenceCadenceOption.label) {
      setRecurrence(defaultNoRecurrenceCadenceOption.max_sessions)
    }
    if (currentRecurrence > selectedCadence?.max_sessions) {
      setRecurrence(selectedCadence?.max_sessions)
    }
    trackCadenceOptionChange(selectedCadence?.label)
  }

  useResetSelectedProviderOnMediumChange(selectedMedium, setSelectedProvider)
  useResetSelectedServiceTypeOnServiceTypeOptionsChange(
    serviceTypeOptions,
    selectedServiceType,
    setSelectedServiceType
  )

  return (
    <Form name="appointment-invites-form">
      {isMediumFieldVisible && <MediumTypeSelector />}
      {recurringInvitesEnabled &&
      selectedMedium !== InviteFormMedium.faceToFace ? (
        <ProviderTypeSelector
          onChange={handleRadioChange}
          selectedProvider={selectedProvider}
        />
      ) : null}
      <AppointmentTypeSelector
        serviceTypesInvitesEnabled={serviceTypesInvitesEnabled}
        serviceTypeOptions={serviceTypeOptions}
        onServiceTypeSelected={handleServiceTypeChange}
        professionOptions={professionOptions}
        onProfessionSelected={handleProfessionChange}
      />

      <Label htmlFor="appointmentReason">{f('appointment_reason_label')}</Label>
      <Text
        color="light-grey-type"
        tag="div"
        className={styles.reasonDisclaimer}
      >
        {isRecurrenceInviteBooking
          ? f('appointment_reason_disclaimer_book')
          : f('appointment_reason_disclaimer')}
      </Text>
      <FormikTextarea
        name={typedKey('appointmentReason')}
        id={typedKey('appointmentReason')}
        placeholder={f('appointment_reason_placeholder')}
      />
      {!serviceTypesInvitesEnabled && (
        <FormikSelect
          name={typedKey('duration')}
          id={typedKey('duration')}
          label={f('duration_label')}
          options={durationOptions}
          className={styles.select}
          onChange={handleDurationChange}
        />
      )}
      {!isRecommendedDuration && (
        <Grid gap={16} templateRows="auto 1fr">
          <Alert intent="warning">{f('duration_disclaimer')}</Alert>
        </Grid>
      )}
      {isRecurrenceInviteBooking ? (
        <DateTimeSection showTime selectedDate={startDate} />
      ) : recurringInvitesEnabled ? (
        <DateTimeSection selectedDate={startDate} />
      ) : null}
      {shouldShowRecurrenceOptions && (
        <RecurrenceOptionSelector
          currentCadence={currentCadence}
          shouldShowCadenceDropdown={shouldShowCadenceDropdown}
          patternsOptions={cadenceOptionsForServiceType}
          recurrenceOptions={recurrenceOptionsForCurrentServiceType}
          onCadenceChange={handleCadenceChange}
        />
      )}
      {error && (
        <Text color="error">
          {f(isAvailability(error) ? 'availability_issue' : 'submit_error')}
        </Text>
      )}
      {selectedServiceType?.member_instructions != null ? (
        <div className={styles.memberInstructions}>
          <Message
            type="info"
            title={selectedServiceType.member_instructions}
          />
        </div>
      ) : null}
      <div className={styles.button}>
        <Button type="submit" intent="primary" loading={loading}>
          {isRecurrenceInviteBooking
            ? f('book_for_patient')
            : f('send_to_patient_button')}
        </Button>
      </div>
    </Form>
  )
}
export default AppointmentInvitesForm
