import cn from 'classnames'
import React, { FC } from 'react'

import { Button, ExpandableAnchor, Grid, Heading, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { useGlobals } from '~/core/core-modules'
import CopyButton from '~/ui/CopyButton'

import { ErrorPanelViewProps } from '../ErrorPanelView'

import messages from './MiniErrorPanelView.messages'
import styles from './styles.module.scss'

export interface MiniErrorPanelViewProps
  extends Pick<
    ErrorPanelViewProps,
    | 'title'
    | 'description'
    | 'errorId'
    | 'errorCode'
    | 'retry'
    | 'loading'
    | 'className'
    | 'retryLabel'
  > {
  retryAlwaysVisible?: boolean
  startExpanded?: boolean
}

const clipboardData = (errorId: string) => `
Error ID:
${errorId}
`

const MiniErrorPanelView: FC<MiniErrorPanelViewProps> = ({
  title,
  description,
  errorId,
  errorCode,
  retry,
  retryLabel,
  loading,
  className,
  retryAlwaysVisible,
  startExpanded,
}) => {
  const fm = useFormatMessage()
  const { support } = useGlobals()
  const itServiceDeskUrl = support?.itServiceDeskUrl

  return (
    <div
      className={cn(className, styles.wrapper)}
      data-testid="mini-error-panel"
    >
      <Grid gap={8}>
        <Text color="error" uppercase bold>
          {title || fm(messages.default_title)}
        </Text>
        {description && <Text>{description}</Text>}
        <Text>
          <ExpandableAnchor
            anchorText={fm(messages.expand_label)}
            gap={16}
            collapseText={fm(messages.collapse_label)}
            startOpen={startExpanded}
          >
            <Grid gap={16}>
              {(errorCode || errorId) && (
                <div className={styles.errorDetails}>
                  <Grid gap={4}>
                    <Heading level="h4">{fm(messages.id_label)}:</Heading>
                    <Text className={styles.code}>{errorId}</Text>
                    <Heading level="h4">{fm(messages.code_label)}:</Heading>
                    <Text>{errorCode}</Text>
                  </Grid>
                  <CopyButton
                    className={styles.copyButton}
                    clipboardData={clipboardData(errorId || 'unknown')}
                    tooltipMessage={fm(messages.copy_button_tooltip_message)}
                  />
                </div>
              )}
              <Grid gap={12}>
                {itServiceDeskUrl && (
                  <Button
                    intent={retry ? 'secondary' : 'primary'}
                    href={itServiceDeskUrl}
                    target="_blank"
                    fill
                  >
                    {fm(messages.send_report)}
                  </Button>
                )}
                {!retryAlwaysVisible && retry && (
                  <Button
                    intent="primary"
                    loading={loading}
                    onClick={retry}
                    fill
                  >
                    {retryLabel || fm(messages.try_again)}
                  </Button>
                )}
              </Grid>
            </Grid>
          </ExpandableAnchor>
        </Text>
      </Grid>
      {retryAlwaysVisible && retry && (
        <Button
          intent="primary"
          loading={loading}
          onClick={() => retry()}
          fill
          style={{ marginTop: 12 }}
        >
          {fm(messages.try_again)}
        </Button>
      )}
    </div>
  )
}

export default MiniErrorPanelView
