import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ComponentType, LazyExoticComponent } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'
import { useFormatMessage } from '@babylon/intl'
import * as Access from '@babylon/web-platform-access'

import {
  AdminPortalPageInterface,
  AdminPortalPageNavigationItemInterface,
} from '~/core/config/modules/generated/types'
import { NavigationLink } from '~/features/navigation'

import messages from './Navigation.messages'

export const LazyAdminPortalPage: LazyExoticComponent<
  ComponentType<AdminPortalPageInterface>
> = React.lazy(() => import('./AdminPortalPage'))

export const AdminPortalPageNavigationItem: AdminPortalPageNavigationItemInterface = ({
  path,
}) => {
  const fm = useFormatMessage()
  const user = useConsultantUser()

  const [granted, error] = Access.useDecide('availability-page')

  if (error) {
    throw error
  }

  if (!granted) {
    return null
  }

  return (
    <NavigationLink
      data-testid="availability-page-navigation-link"
      to={`${path}/${user.id}/availability`}
      icon={
        <FontAwesomeIcon
          icon={faCalendarCheck}
          style={{ height: 24, width: 24 }}
          color="#6B7785"
        />
      }
    >
      {fm(messages.availability_text)}
    </NavigationLink>
  )
}
