import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ENVIRONMENT } from '~/constants'
import {
  ConsultationContextType,
  useConsultationWithGracefulExit,
} from '~/core/config'
import requestIdGenerator from '~/core/request-id-generator'
import { CpConsultationPageQuery } from '~/generated'

import messages from './CopySessionIdButton.messages'
import styles from './styles.module.scss'

interface CopySessionIdButtonProps {
  onClick?: any
  consultationContext: ConsultationContextType
}

const generateMessage = (
  consultation?: CpConsultationPageQuery['consultation']
) => {
  const consultationMessage = consultation
    ? `
    consultation id: ${consultation.id}
    patient id: ${consultation.patient.id}
    patient uuid: ${consultation.patient.uuid}
    consultant id: ${consultation.consultant?.id || ''}
    consultant uuid: ${consultation.consultant?.uuid || ''}`
    : ''

  return `
    ==== Issue Diagnosis Data ====
    ${new Date().toISOString()}
    userToken: ${requestIdGenerator.userToken()}
    sessionToken: ${requestIdGenerator.sessionToken()}
    env=${ENVIRONMENT}${consultationMessage}
    ========================`
}

const CopySessionIdButton = ({
  onClick,
  consultationContext,
}: CopySessionIdButtonProps) => {
  const [copied, setCopied] = useState(false)

  const consultationData = useConsultationWithGracefulExit(
    consultationContext || {}
  )
  const timeout = useRef<any>(null)
  const fm = useFormatMessage()

  const onCopyClick = async () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    await navigator.clipboard.writeText(generateMessage(consultationData))

    setCopied(true)

    timeout.current = setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  return (
    <Button
      intent="secondary"
      fill
      onClick={() => {
        onCopyClick()

        if (typeof onClick === 'function') {
          onClick()
        }
      }}
    >
      <span>{fm(messages.copy_session_id_button_text)}</span>
      <CSSTransition
        in={copied}
        classNames="copiedFade"
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <span className={styles.copiedText}>
          {fm(messages.copy_session_id_copied_text)}
        </span>
      </CSSTransition>
    </Button>
  )
}

export default CopySessionIdButton
