import React from 'react'

import { Button } from '@babylon/core-ui'

import Question from './Question'

import styles from './styles.module.scss'

interface SingleSelectProps {
  questionText: string
  suggestedAnswers: { id: string; answer: string }[]
  onSubmit: (answerId: string) => void
}

const SingleSelect = ({
  questionText,
  suggestedAnswers,
  onSubmit,
}: SingleSelectProps) => {
  return (
    <Question questionText={questionText}>
      <div className={styles.singleSelectWrapper}>
        {suggestedAnswers.map(({ id, answer }) => (
          <Button
            key={id}
            intent="secondary"
            className={styles.button}
            onClick={() => onSubmit(id)}
          >
            {answer}
          </Button>
        ))}
      </div>
    </Question>
  )
}

export default SingleSelect
