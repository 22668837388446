import classnames from 'classnames'
import React, { Component } from 'react'

import { generateIndex } from '~/core'

import styles from './styles.module.scss'

const provider = {
  addNotification: ({ title, message = '', ...rest }) => {
    if (provider.onNotification) {
      provider.onNotification({
        ...rest,
        title,
        message,
        id: generateIndex(),
        createdAt: Date.now(),
      })
    }
  },

  onNotification: null,
}

export const notify = provider.addNotification

export const withNotify = (Component) => (props) => {
  return <Component {...props} notify={notify} />
}

export const useNotify = () => {
  return notify
}

export class NotificationProvider extends Component {
  constructor(props) {
    super(props)

    const { duration } = this.props

    this.state = {
      queue: [],
    }

    provider.onNotification = (value) => {
      const { state } = this
      const { id } = value

      const notification = {
        ...value,
        destroy: () => {
          if (notification.timeout) {
            clearTimeout(notification.timeout)
            notification.timeout = null
          }

          this.setState({
            queue: state.queue.filter((v) => v.id === id),
          })
        },

        timeout:
          duration === 0
            ? null
            : setTimeout(() => notification.destroy(), duration),
      }

      this.setState({
        queue: state.queue.concat(notification),
      })
    }
  }

  render() {
    const { queue } = this.state
    return (
      <div className={styles.notifications}>
        {queue.map((ntf) => {
          return (
            <div
              key={ntf.id}
              className={classnames(styles.notification, styles[ntf.type])}
              onClick={ntf.destroy}
            >
              <div className={styles.title}>{ntf.title}</div>
              <div className={styles.message}>{ntf.message}</div>
            </div>
          )
        })}
      </div>
    )
  }
}

NotificationProvider.defaultProps = {
  duration: 8000,
}
