import React from 'react'

import { Heading } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { SectionGroupPluginInterface } from '~/core/config/modules/generated/types'
import Card from '~/ui/Card'
import SectionSeparator from '~/ui/SectionSeparator'

import styles from './styles.module.scss'

const SectionGroup: SectionGroupPluginInterface = ({
  title,
  subtitle,
  borderColor,
  titleColor,
  children,
}) => {
  const fm = useFormatMessage()
  return (
    <React.StrictMode>
      <Card className={styles.card}>
        <div
          className={styles.border}
          style={{ backgroundColor: borderColor }}
        />
        {title && (
          <div className={styles.header} data-public>
            <Heading
              level="h5"
              className={styles.title}
              style={{ color: titleColor }}
            >
              {fm(title)}
            </Heading>
            {subtitle && (
              <Heading level="h5" color="grey-type" className={styles.subtitle}>
                {fm(subtitle)}
              </Heading>
            )}
          </div>
        )}
        {isFilledArray(children)
          ? children.filter(Boolean).map((child, index) => {
              const isLastChild = index === children.length - 1
              return isLastChild ? (
                <React.Fragment key={index}>{child}</React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  {child}
                  <SectionSeparator />
                </React.Fragment>
              )
            })
          : children}
      </Card>
    </React.StrictMode>
  )
}

export default SectionGroup
