import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.workflow_actions.workflow'

export default defineMessages({
  workflows_type_key: {
    id: `${baseId}.workflows_type_key`,
    defaultMessage: 'Type',
  },
  workflows_status_key: {
    id: `${baseId}.workflows_status_key`,
    defaultMessage: 'Status',
  },
  workflow_state_ACTIVE: {
    id: `${baseId}.workflow_state_ACTIVE_v2`,
    defaultMessage: 'In progress',
  },
  workflow_state_COMPLETED: {
    id: `${baseId}.workflow_state_COMPLETED`,
    defaultMessage: 'Completed',
  },
  workflow_state_SUSPENDED: {
    id: `${baseId}.workflow_state_SUSPENDED`,
    defaultMessage: 'Suspended',
  },
  workflow_state_INTERNALLY_TERMINATED: {
    id: `${baseId}.workflow_state_INTERNALLY_TERMINATED_v2`,
    defaultMessage: 'Internally terminated',
  },
  workflow_state_EXTERNALLY_TERMINATED: {
    id: `${baseId}.workflow_state_EXTERNALLY_TERMINATED_v2`,
    defaultMessage: 'Externally terminated',
  },
  workflow_state_UNKNOWN: {
    id: `${baseId}.workflow_state_UNKNOWN`,
    defaultMessage: 'Unknown',
  },
})
