import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review'

export default defineMessages({
  referrals_section_title_private: {
    id: `${baseId}.referrals_section_title_private_v2`,
    defaultMessage: 'Private referrals',
  },
  referrals_section_table_category_heading: {
    id: `${baseId}.referrals_section_table_category_heading`,
    defaultMessage: 'Category',
  },
  referrals_section_table_specialism_heading: {
    id: `${baseId}.referrals_section_table_specialism_heading`,
    defaultMessage: 'Specialism',
  },
  error_loading_referrals: {
    id: `${baseId}.error_loading_referrals`,
    defaultMessage: 'Error loading the referrals',
  },
})
