import React, { useState } from 'react'

import {
  NOTE_ASSISTANT_CATEGORY,
  NOTE_ASSISTANT_ERROR_ACTION,
  NOTE_ASSISTANT_ERROR_LABEL,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { NoteAssistantSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useExperiment } from '~/features/experiments/useExperiment'
import { useNoteAssistantNotesQuery } from '~/generated'

import NoteAssistantSummaryView from './NoteAssistantSummaryView'
import { useGeneratedNotePolling } from './useGeneratedNotePolling'

const trackEvent = analytics.trackEventFactory({
  category: NOTE_ASSISTANT_CATEGORY,
})

const NoteAssistantSummaryContainer: NoteAssistantSummaryPluginInterface = ({
  consultationContext,
  eventBus,
}) => {
  const { uuid } = useConsultation(consultationContext)
  const { noteAssistantSummaryEnabled } = useFeatureFlags()
  const noteAssistantExperimentEnabled = useExperiment('Note Assistant')
  const [notesErrorCount, setNotesErrorCount] = useState<number>(0)
  const noteSummaryEnabled: boolean =
    Boolean(noteAssistantSummaryEnabled) && noteAssistantExperimentEnabled

  const {
    data: notesData,
    startPolling: startNotesPolling,
    stopPolling: stopNotesPolling,
    refetch,
  } = useNoteAssistantNotesQuery({
    variables: { id: uuid },
    skip: !noteSummaryEnabled,
    notifyOnNetworkStatusChange: true, // This is required otherwise the onError handler is only called once
    onCompleted: (data) => {
      if (notesErrorCount) {
        setNotesErrorCount(0)
      }

      if (data.noteAssistantNotes.error) {
        stopNotesPolling()
        setIsActive(false)
        trackEvent({
          action: NOTE_ASSISTANT_ERROR_ACTION,
          label: NOTE_ASSISTANT_ERROR_LABEL,
        })
      }
    },
    onError: () => {
      setNotesErrorCount(notesErrorCount + 1)
    },
  })

  const notes = notesData?.noteAssistantNotes.notes || []

  const {
    warning,
    callRecordingError,
    notesError,
    isActive,
    speechProcessingEnabled,
    setIsActive,
  } = useGeneratedNotePolling({
    eventBus,
    startNotesPolling,
    stopNotesPolling,
    refetch,
    pollingEnabled: noteSummaryEnabled,
    notesErrorCount,
  })

  if (!noteSummaryEnabled) return null

  const error =
    notesError || callRecordingError || notesData?.noteAssistantNotes.error

  return (
    <NoteAssistantSummaryView
      notes={notes}
      error={Boolean(error)}
      warning={warning}
      isActive={isActive}
      speechProcessingEnabled={speechProcessingEnabled}
    />
  )
}

export default NoteAssistantSummaryContainer
