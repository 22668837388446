import { ENABLE_BLOOD_PRESSURE_CHR } from '~/constants'
import {
  PatientMetricSource,
  useConsultationPatientMetricsQuery,
} from '~/generated'

interface usePatientMetricsQueryProps {
  id: string
  patientMetricsCount?: number
}

export const queryVariableConstants = {
  patientMetricsFilter: [PatientMetricSource.ChrPrimaryStore],
  bloodPressureOn: ENABLE_BLOOD_PRESSURE_CHR,
}

export const usePatientMetricsQuery = ({
  id,
  patientMetricsCount = 1,
}: usePatientMetricsQueryProps) => {
  return useConsultationPatientMetricsQuery({
    variables: {
      ...queryVariableConstants,
      id,
      patientMetricsCount,
    },
    fetchPolicy: 'cache-first',
  })
}
