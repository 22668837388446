
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Paging": [
      "AdminActiveIngredient",
      "AdminDrugAlert",
      "Prescription",
      "Consultation",
      "ConsultationReferral",
      "TestReport",
      "RepeatTemplate",
      "PortalUser",
      "Comment"
    ],
    "Pagination": [
      "AdminActiveIngredients",
      "AdminDrugAlerts",
      "Comments",
      "ConsultationResults",
      "TestReports",
      "Prescriptions",
      "PortalUserResults",
      "RepeatTemplateResults"
    ],
    "Subject": [
      "Patient",
      "Consultant",
      "MopPatient"
    ],
    "TimelineEventInterface": [
      "Consultation",
      "WorkflowV2",
      "PatientQuestion",
      "ChatscriptConversation",
      "BloodTest",
      "TestReport",
      "TimelineChatConversation"
    ],
    "PatientResult": [
      "Patient",
      "RetrievalError"
    ],
    "Error": [
      "RetrievalError"
    ],
    "ConsumerNetworkResult": [
      "ConsumerNetwork",
      "RetrievalError"
    ],
    "ConsultationImagesResult": [
      "ConsultationImages",
      "RetrievalError"
    ],
    "WorkflowEngineResponse": [
      "WorkflowTasksResponse",
      "WorkflowsResponse"
    ],
    "WorkflowDefinitionVariableMetadataObject": [
      "WorkflowDefinitionVariableMetadataChildAllowedValue",
      "WorkflowDefinitionVariableMetadataAllowedValue",
      "WorkflowDefinitionVariableMetadataAsyncCodeSearchValue",
      "WorkflowDefinitionVariableMetadataDateFormat",
      "WorkflowDefinitionVariableMetadataRegexValidation",
      "WorkflowDefinitionVariableMetadataSource",
      "WorkflowDefinitionVariableMetadataDefaultValue"
    ],
    "TimelineEvent": [
      "Consultation",
      "PatientQuestion",
      "ChatscriptConversation",
      "BloodTest",
      "TestReport",
      "WorkflowV2",
      "RepeatTemplate",
      "TimelineChatConversation"
    ],
    "CarePlanResult": [
      "CarePlan",
      "None",
      "CarePlanAuthorizationError"
    ],
    "CarePlanDraftResult": [
      "CarePlanDraft",
      "None",
      "CarePlanAuthorizationError"
    ],
    "DraftMetadataWarning": [
      "GoalDescriptionEmpty",
      "ActionDescriptionEmpty",
      "GoalWithoutAction",
      "ActionWithoutGoal",
      "CarePlanDraftWithoutGoalsAndActions"
    ],
    "CarePlanPublishedInConsultationResult": [
      "CarePlan",
      "None",
      "CarePlanAuthorizationError"
    ],
    "FhirValueSetInclusion": [
      "FhirValueSetConceptInclusion",
      "FhirValueSetExternalValueSetInclusion",
      "FhirValueSetFilterInclusion"
    ],
    "AddActionDraftResult": [
      "AddActionDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "CarePlanAddGoalDraftResult": [
      "AddGoalDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "AssociateGoalAndActionDraftResult": [
      "AssociateGoalAndActionDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "GoalDraftDoesNotExistError",
      "ActionDraftDoesNotExistError",
      "GoalActionDraftAssociationAlreadyExistError",
      "CarePlanAuthorizationError"
    ],
    "CreateCarePlanDraftResult": [
      "CreateCarePlanDraftSuccess",
      "DraftInFlightAlreadyError",
      "RevisionAlreadyPublishedInConsultationError",
      "ConsultationDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "CarePlanDeleteActionDraftResult": [
      "DeleteActionDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "ActionDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "DeleteCarePlanDraftResult": [
      "DeleteCarePlanDraftSuccess",
      "ConsultationDoesNotExistError",
      "CarePlanDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "CarePlanDeleteGoalDraftResult": [
      "DeleteGoalDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "GoalDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "CarePlanDissociateGoalAndActionDraftResult": [
      "DissociateGoalAndActionDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "GoalDraftDoesNotExistError",
      "ActionDraftDoesNotExistError",
      "GoalActionDraftAssociationDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "CarePlanEditActionDraftResult": [
      "EditActionDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "CarePlanEditGoalDraftResult": [
      "EditGoalDraftSuccess",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanDraftDoesNotExistError",
      "GoalDraftDoesNotExistError",
      "CarePlanAuthorizationError"
    ],
    "PromoteCarePlanDraftResult": [
      "PromoteCarePlanDraftSuccess",
      "CarePlanDraftDoesNotExistError",
      "UnresolvedWarningsError",
      "ConsultationDoesNotExistError",
      "InvalidCarePlanDraftNumbersOfEditsError",
      "CarePlanAuthorizationError"
    ]
  }
};
      export default result;
    