import { ApolloError } from '@apollo/client'
import { useMemo } from 'react'

import { useFeatureFlags } from '~/core/core-modules'
import {
  useGetClinicianServiceTypesQuery,
  useGetConsumerNetworkServiceTypesQuery,
} from '~/generated'

import { isServiceTypeMedium, ServiceType } from '../../types'

const isPartOf = <T,>(
  anotherSet: T[],
  predicate: (supersetItem: T, subsetItem: T) => boolean
) => (subsetItem: T) => anotherSet.some((s) => predicate(s, subsetItem))

type HookReturnType = {
  loading: boolean
  error?: ApolloError
  serviceTypes: ServiceType[]
  clinicianServiceTypes: ServiceType[]
  refetch: () => Promise<void>
}

type UseGetServiceTypesVariables = {
  consumerNetworkId: number
  clinicianId: string
}

const useGetServiceTypes = ({
  consumerNetworkId,
  clinicianId,
}: UseGetServiceTypesVariables): HookReturnType => {
  const { serviceTypesInvitesEnabled } = useFeatureFlags()

  const {
    data: memberServiceTypesResponse,
    loading: serviceTypesLoading,
    error: serviceTypesError,
    refetch: serviceTypesRefetch,
  } = useGetConsumerNetworkServiceTypesQuery({
    skip: !serviceTypesInvitesEnabled,
    variables: { consumerNetworkId: String(consumerNetworkId) },
  })

  const {
    data: clinicianServiceTypesData,
    loading: clinicianServiceTypesLoading,
    error: clinicianServiceTypesError,
    refetch: clinicianServiceTypesRefetch,
  } = useGetClinicianServiceTypesQuery({
    skip: !serviceTypesInvitesEnabled,
    variables: { clinician_id: clinicianId },
  })

  const serviceTypes = useMemo(
    () =>
      (memberServiceTypesResponse?.consumerNetworkServiceTypes ?? []).map(
        (s) => ({
          ...s,
          id: s.uuid,
          allowed_mediums: s.allowed_mediums.filter(isServiceTypeMedium),
        })
      ),
    [memberServiceTypesResponse]
  )

  const clinicianServiceTypes = useMemo(
    () =>
      serviceTypes.filter(
        isPartOf(
          clinicianServiceTypesData?.clinicianServiceTypes ?? [],
          (s1, s2) => s1.id === s2.id
        )
      ),
    [serviceTypes, clinicianServiceTypesData]
  )

  return {
    loading: serviceTypesLoading || clinicianServiceTypesLoading,
    error: serviceTypesError || clinicianServiceTypesError,
    serviceTypes,
    clinicianServiceTypes,
    refetch: async () => {
      if (serviceTypesError) {
        await serviceTypesRefetch()
      }
      if (clinicianServiceTypesError) {
        await clinicianServiceTypesRefetch()
      }
    },
  }
}

export default useGetServiceTypes
