import React from 'react'

import { Button, Grid, Text } from '@babylon/core-ui'

import {
  PRE_CALL_CHECK,
  PRE_CALL_CHECK_CLOSE_TROUBLESHOOTING_ACTION,
  PRE_CALL_CHECK_OPEN_ACTION,
  PRE_CALL_CHECK_RESULTS_BAD,
  PRE_CALL_CHECK_RESULTS_GOOD,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages, useToggle } from '~/core/hooks'

import PreCallCheckResultsDialog from './PreCallCheckResultsDialog'
import PreCallCheckTroubleshootingDialog from './PreCallCheckTroubleshootingDialog'

import messages from './PreCallCheck.messages'
import styles from './styles.module.scss'

const trackEvent = analytics.trackEventFactory({ category: PRE_CALL_CHECK })

const PreCallCheck = () => {
  const f = useMessages(messages)
  const [resultsDialogVisible, toggleResultsDialogVisible] = useToggle(false)
  const [
    troubleshootingDialogVisible,
    toggleTroubleshootingDialogVisible,
  ] = useToggle(false)

  const handleOpenResults = () => {
    toggleResultsDialogVisible(true)

    trackEvent({
      action: PRE_CALL_CHECK_OPEN_ACTION,
    })
  }

  const handleGoodResults = () => {
    trackEvent({ action: PRE_CALL_CHECK_RESULTS_GOOD })

    toggleResultsDialogVisible(false)
  }

  const handleBadResults = () => {
    trackEvent({ action: PRE_CALL_CHECK_RESULTS_BAD })

    toggleResultsDialogVisible(false)
    toggleTroubleshootingDialogVisible(true)
  }

  const handleCloseTroubleshooting = () => {
    toggleTroubleshootingDialogVisible(false)

    trackEvent({ action: PRE_CALL_CHECK_CLOSE_TROUBLESHOOTING_ACTION })
  }

  return (
    <div className={styles.wrapper}>
      <Grid columns={1} rowGap={8}>
        <Text align="center" bold tag="div" color="white">
          {f('title')}
        </Text>
        <Text align="center" tag="div" color="white">
          {f('description')}
        </Text>
        <Button
          href="https://tokbox.com/developer/tools/precall/results"
          target="_blank"
          rel="noopener"
          onClick={handleOpenResults}
          className={styles.button}
        >
          {f('button')}
        </Button>
      </Grid>
      {resultsDialogVisible && (
        <PreCallCheckResultsDialog
          onClose={() => toggleResultsDialogVisible(false)}
          onGoodResults={handleGoodResults}
          onBadResults={handleBadResults}
        />
      )}
      {troubleshootingDialogVisible && (
        <PreCallCheckTroubleshootingDialog
          onClose={handleCloseTroubleshooting}
        />
      )}
    </div>
  )
}

export default PreCallCheck
