import { defineMessages } from 'react-intl'

const baseId = 'document_repository'

export default defineMessages({
  default_error_message: {
    id: `${baseId}.default_error_message`,
    defaultMessage: 'An error was encountered fetching patient documents.',
  },
})
