import { faLink } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { COUNTRY_CODE } from '~/constants'
import {
  LINKS_AND_RESOURCES_MODAL_CATEGORY,
  OPEN_LINKS_AND_RESOURCES_MODAL_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useToggle } from '~/core/hooks'
import { NavigationLink } from '~/features/navigation'

import LinksAndResourcesModal from './LinksAndResourcesModal'
import { useLinksAndResources } from './useLinksAndResources'

import messages from './LinksAndResources.messages'

const LinksAndResources = () => {
  const fm = useFormatMessage()
  const [modalVisible, toggleModalVisible] = useToggle(false)

  const { loading, data, error } = useLinksAndResources(COUNTRY_CODE)

  if (loading) {
    return <Spinner size="small" centered testid="links-spinner" />
  }

  const handleClick = () => {
    analytics.trackEvent({
      category: LINKS_AND_RESOURCES_MODAL_CATEGORY,
      action: OPEN_LINKS_AND_RESOURCES_MODAL_ACTION,
    })
    toggleModalVisible(true)
  }

  return (
    <>
      <NavigationLink
        onClick={handleClick}
        icon={
          <FontAwesomeIcon
            icon={faLink}
            style={{ height: 24, width: 24 }}
            color="#6B7785"
          />
        }
      >
        {fm(messages.button_title)}
      </NavigationLink>
      {modalVisible && (
        <LinksAndResourcesModal
          babylonLinks={data.links}
          resources={data.resources}
          onClose={() => toggleModalVisible(false)}
          error={error}
        />
      )}
    </>
  )
}

export default LinksAndResources
