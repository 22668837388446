import { defineMessages } from 'react-intl'

const baseId = 'call_player.pre_call_check'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Ready for the call?',
  },
  description: {
    id: `${baseId}.description`,
    defaultMessage: 'Check your connection, mic and camera in 30 seconds',
  },
  button: {
    id: `${baseId}.button`,
    defaultMessage: 'Pre-call check',
  },
})
