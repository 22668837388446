import debounce from 'lodash/debounce'
import differenceBy from 'lodash/differenceBy'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { errorOptionRenderer } from '~/ui/ErrorMessage'
import { Creatable } from '~/ui/Select'

class MedicalSelect extends Component {
  onListChange = (nextValue) => {
    const {
      value,
      labelKey,
      valueKey,
      onAddToList,
      onRemoveFromList,
    } = this.props

    if (nextValue.length > value.length) {
      const item = differenceBy(nextValue, value, valueKey)[0]

      if (item) {
        onAddToList(item[labelKey], item)
      }
    } else if (nextValue.length < value.length) {
      const item = differenceBy(value, nextValue, valueKey)[0]

      if (item) {
        onRemoveFromList(item[valueKey], item)
      }
    }
  }

  onDebounceInputChange = debounce((value) => {
    this.props.onDebounceInputChange(value)
  }, this.props.debounce)

  handleInput = (value) => {
    if (this.props.debounce) {
      this.onDebounceInputChange(value)
    }
    this.props.onInputChange(value)
    return value
  }

  render() {
    return (
      // the classname below is a temporary css fix.
      // this should be removed when we upgrade react-select
      <div data-testid="indication-search" className="Select">
        <Creatable
          {...this.props}
          onInputChange={this.handleInput}
          onChange={this.onListChange}
          optionRenderer={errorOptionRenderer((option) => (
            <div data-testid="indication-option">
              {option[this.props.labelKey]}
            </div>
          ))}
          filterOptions={filterCreatableOptions}
          promptTextCreator={(label) => label} // this fixes a bug whereby react-select adds 'Create new option' to the created value
        />
      </div>
    )
  }
}

const filterCreatableOptions = (options) => {
  return options.filter((v) => {
    return v.$ERROR || v.className !== 'Select-create-option-placeholder'
  })
}

MedicalSelect.defaultProps = {
  debounce: 0,
  multi: true,
  clearable: false,
  labelKey: 'info',
  valueKey: 'id',
  options: [],
  arrowRenderer: null,
  isLoading: false,
  filterOptions: filterCreatableOptions,
  onInputChange: () => {},
  onDebounceInputChange: () => {},
  onAddToList: () => {},
  onRemoveFromList: () => {},
}

MedicalSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Array),
  options: PropTypes.instanceOf(Array),
  debounce: PropTypes.number,
  onDebounceInputChange: PropTypes.func,
  onInputChange: PropTypes.func,
}

export default MedicalSelect
