import React from 'react'

import { ENABLE_VIDEO_ON_VOICE_CONSULTATIONS } from '~/constants'
import {
  MULTIMEDIA_CATEGORY,
  SWITCH_TO_VIDEO_ACTION,
  SWITCH_TO_VOICE_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useFeatureFlags } from '~/core/core-modules'

import CallButton, { CallButtonProps } from '../CallButton'
import CallDuration from '../CallDuration'
import { PreferredCallMedium } from '../CallPlayer'
import {
  Action,
  ActionType,
  CallMediumEntitlement,
  CallStatus,
} from '../CallStatusReducerModelProvider'

import styles from './CallControls.module.scss'

export interface CallControlsProps {
  dispatch: React.Dispatch<Action>
  videoStatus: CallStatus
  voiceStatus: CallStatus
  callMediumEntitlement: CallMediumEntitlement
  preferredCallMedium: PreferredCallMedium
}

const trackEvent = analytics.trackEventFactory({
  category: MULTIMEDIA_CATEGORY,
})

const CallControls = ({
  videoStatus,
  voiceStatus,
  callMediumEntitlement,
  preferredCallMedium,
  dispatch,
}: CallControlsProps) => {
  const { voiceVoipEnabled } = useFeatureFlags()

  const isConnecting =
    videoStatus === CallStatus.Connecting ||
    voiceStatus === CallStatus.Connecting
  const isStreaming =
    videoStatus === CallStatus.Streaming || voiceStatus === CallStatus.Streaming

  const handleVoiceClick = () => {
    if (voiceStatus === CallStatus.Ready) {
      if (videoStatus > CallStatus.Ready) {
        trackEvent({ action: SWITCH_TO_VOICE_ACTION })
      }
      if (voiceVoipEnabled) {
        dispatch({ type: ActionType.VOICE_VOIP_CONNECTING })
      } else {
        dispatch({ type: ActionType.VOICE_PSTN_CONNECTING })
      }
      return
    }

    if (voiceStatus > CallStatus.Ready) {
      dispatch({ type: ActionType.VOICE_STOP })
    }
  }

  const handleVideoClick = () => {
    if (videoStatus === CallStatus.Ready) {
      if (voiceStatus > CallStatus.Ready) {
        trackEvent({ action: SWITCH_TO_VIDEO_ACTION })
      }
      dispatch({ type: ActionType.VIDEO_CONNECTING })
      return
    }

    if (videoStatus > CallStatus.Ready) {
      dispatch({ type: ActionType.VIDEO_STOP })
    }
  }

  const fallbackMediumAllowed =
    callMediumEntitlement === CallMediumEntitlement.VoiceAndVideo &&
    preferredCallMedium === 'voice'
      ? ENABLE_VIDEO_ON_VOICE_CONSULTATIONS
      : true

  const videoButtonProps: CallButtonProps = {
    type: 'video',
    status: videoStatus,
    onClick: handleVideoClick,
  }

  const voiceButtonProps: CallButtonProps = {
    type: 'voice',
    status: voiceStatus,
    onClick: handleVoiceClick,
  }

  return (
    <div className={styles.toolbar} data-public>
      <div className={styles.buttonsWrapper}>
        <div className={styles.primaryButtonWrapper}>
          <CallButton
            primary
            {...(preferredCallMedium === 'voice'
              ? voiceButtonProps
              : videoButtonProps)}
          />
        </div>
        <div className={styles.duration}>
          <CallDuration clear={isConnecting} isCounting={isStreaming} />
        </div>
        {fallbackMediumAllowed && (
          <div className={styles.secondaryButtonWrapper}>
            <CallButton
              {...(preferredCallMedium === 'voice'
                ? videoButtonProps
                : voiceButtonProps)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CallControls
