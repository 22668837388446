import React from 'react'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import { ConsultationReferral } from '~/generated'
import DataList from '~/ui/DataList'

import useSpecialisms from './useSpecialisms'

import messages from './Referrals.messages'

export type Referral = Pick<
  ConsultationReferral,
  'id' | 'specialismCategoryId' | 'specialismName'
>

type ReferralsSummaryProps = {
  consultationId: string
  referrals: Referral[]
}

const ReferralsSummary = ({
  consultationId,
  referrals = [],
}: ReferralsSummaryProps) => {
  const f = useMessages(messages)
  const { getCategoryName } = useSpecialisms(consultationId)

  if (!isFilledArray(referrals)) {
    return null
  }

  return (
    <Section title={f('referrals_section_title_private')} testid="cr-referrals">
      {referrals.map((referral) => {
        const data = [
          {
            key: f('referrals_section_table_category_heading'),
            value: referral?.specialismCategoryId
              ? getCategoryName(referral.specialismCategoryId)
              : '',
          },
          {
            key: f('referrals_section_table_specialism_heading'),
            value: referral.specialismName,
          },
        ]

        return <DataList key={referral.id} data={data} />
      })}
    </Section>
  )
}

export default ReferralsSummary
