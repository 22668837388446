import { useMount } from '~/core/hooks'

import analytics from './analytics'

export interface ModalViewTrackerProps {
  modalName: string
}

const useModalViewTracker = ({ modalName }: ModalViewTrackerProps) => {
  useMount(() => {
    analytics.trackModal(modalName)
  })
}

export default useModalViewTracker
