import { differenceInMinutes } from 'date-fns'

import { Consultation } from '~/features/schedule/useConsultations'

import { INotification } from '../HeaderSchedule/HeaderSchedule'

const RECENTLY_CREATED_LIMIT = 5 // 5 minutes

/**
 * Create new consultation notification if the following conditions are met
 * - The next consultation was created in the last 5 mins
 * - A notification hasn't already been created for this consultation
 * @param latest
 * @param currentConsultation
 * @returns
 */
export const showNearTimeNotification = (
  latest: Consultation | undefined,
  notification: INotification | undefined
): boolean => {
  if (!latest) return false
  const nextConsultationCreated = new Date(latest.createdAt).getTime()
  const createdRecently = nextConsultationCreated
    ? differenceInMinutes(new Date(), nextConsultationCreated) <
      RECENTLY_CREATED_LIMIT
    : false
  return notification?.consultationId !== latest?.id && createdRecently
}

export const updateNotification = (
  newNotification: INotification | undefined,
  setNotification: React.Dispatch<
    React.SetStateAction<INotification | undefined>
  >
) => {
  window.localStorage.setItem(
    'up_next_notification',
    newNotification ? JSON.stringify(newNotification) : ''
  )
  setNotification(newNotification)
}
