import React from 'react'

import { ModuleConfig } from '~/core/container'

import { useResize } from './hooks'
import { Options } from './Options'

import styles from './styles.module.scss'

export const Box = (props: ModuleConfig) => {
  const {
    id,
    name,
    options,
    __bucket__,
    __deps__,
    __pos__,
    updateCurves,
    ...rest
  } = props
  const [ref, handleResizeMouseDown, handleRepositionMouseDown] = useResize({
    onUpdate: updateCurves,
  })

  return (
    <div className={styles.module} id={`mod-${id}`} ref={ref}>
      <div
        className={`${styles.field} ${styles.header}`}
        onMouseDown={handleRepositionMouseDown}
      >
        <span>ID:</span> {id}
      </div>
      <div className={styles.field}>
        <span>Name:</span> {name}
      </div>

      {Object.entries(rest).map(([key, value]) => (
        <div key={key} className={styles.field}>
          <span>{key}:</span> {JSON.stringify(value, null, 2)}
        </div>
      ))}
      {options && <Options options={options} />}
      <div onMouseDown={handleResizeMouseDown} className={styles.resizeGrip} />
    </div>
  )
}
