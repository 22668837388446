import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import messages from './PreCallCheckResultsDialog.messages'

interface PreCallCheckResultsDialogProps {
  onClose: () => void
  onGoodResults: () => void
  onBadResults: () => void
}

const PreCallCheckResultsDialog = ({
  onClose,
  onGoodResults,
  onBadResults,
}: PreCallCheckResultsDialogProps) => {
  const f = useMessages(messages)

  return (
    <Dialog
      title={f('title')}
      onClose={onClose}
      ok
      okLabel={f('good_button')}
      onOk={onGoodResults}
      cancel
      cancelLabel={f('not_good_button')}
      onCancel={onBadResults}
    >
      <Text>{f('description')}</Text>
    </Dialog>
  )
}

export default PreCallCheckResultsDialog
