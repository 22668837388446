import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.ehr_button'

export default defineMessages({
  open_ehr_chart_button_label: {
    id: `${baseId}.open_ehr_chart_button_label`,
    defaultMessage: 'Open EHR Chart',
  },
  open_ehr_checkin_button_label: {
    id: `${baseId}.open_ehr_checkin_button_label`,
    defaultMessage: 'Open EHR Check-in',
  },
  error_loading: {
    id: `${baseId}.error_loading`,
    defaultMessage: 'Unable to load EHR Deep Link',
  },
  error_workaround: {
    id: `${baseId}.error_workaround`,
    defaultMessage: 'Please manually open the EHR in another browser window.',
  },
})
