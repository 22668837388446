import React, { useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'

import { Button } from '@babylon/core-ui'

import { ACTIONS_SICK_NOTES_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import SickNoteModal from '~/features/sick-notes/SickNoteModal'
import { CpConsultationPageQuery } from '~/generated'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import SickNote from './SickNote'
import SickNoteRemoveDialog from './SickNoteRemoveDialog'
import useRemoveSickNoteMutation from './useRemoveSickNoteMutation'

import messages from './SickNotes.messages'

interface SickNotesProps {
  consultation: NonNullable<CpConsultationPageQuery['consultation']>
  disabled?: boolean
  restrictFinalizedConsultationEditing: boolean
  markFinalizedConsultationAsEdited?: () => void
}

const SickNotes = ({
  consultation,
  disabled,
  restrictFinalizedConsultationEditing,
  markFinalizedConsultationAsEdited,
}: SickNotesProps) => {
  const history = useHistory()
  const match = useRouteMatch()
  const format = useMessages(messages)

  const [removeSickNoteId, setRemoveSickNoteId] = useState<string | null>(null)
  const [removeSickNoteMutation, { loading }] = useRemoveSickNoteMutation()

  const { sickNotes } = consultation

  const navigateToCreateSickNote = () => {
    history.replace(`${match.url}/sick-note/create`)
  }

  const navigateToEditSickNote = (id: string) => {
    history.replace(`${match.url}/sick-note/edit?id=${id}`)
  }

  const getConditionalHandlers = (id: string) => ({
    onUpdate: () => navigateToEditSickNote(id),
    onRemove: () => setRemoveSickNoteId(id),
  })

  const disableSickNoteEditing =
    disabled || restrictFinalizedConsultationEditing

  return (
    <>
      {removeSickNoteId !== null && (
        <SickNoteRemoveDialog
          onClose={() => {
            setRemoveSickNoteId(null)
          }}
          onOk={async (modal) => {
            try {
              await removeSickNoteMutation({
                consultationId: consultation.id,
                sickNoteId: removeSickNoteId,
              })
              if (markFinalizedConsultationAsEdited) {
                markFinalizedConsultationAsEdited()
              }
            } catch (error) {
              logException(error)
            }

            modal.closeModal()
          }}
          okLoading={loading}
        />
      )}
      <Section
        type="secondary"
        title={format('title_private')}
        dataTestId="sick-notes"
        topRight={
          <Button
            intent="secondary"
            disabled={disableSickNoteEditing || sickNotes.length > 0}
            onClick={navigateToCreateSickNote}
          >
            {format('add_button_label')}
          </Button>
        }
      >
        {sickNotes.map((note) => (
          <SickNote
            key={note.id}
            {...note}
            {...(!disableSickNoteEditing && getConditionalHandlers(note.id))}
          />
        ))}
      </Section>

      {!restrictFinalizedConsultationEditing && (
        <Route path="/consultation/:consultationId/sick-note/:mode(create|edit)">
          <SickNoteModal
            markFinalizedConsultationAsEdited={
              markFinalizedConsultationAsEdited
            }
          />
        </Route>
      )}
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: ACTIONS_SICK_NOTES_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title_private,
})(SickNotes)
