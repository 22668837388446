import { CONSULTATION_STATUSES } from '~/constants'

export const sortConsultationsByScheduledTime = <
  T extends { scheduledTime: string }
>(
  consultations: T[]
): T[] => {
  return consultations.slice(0).sort((prev, next) => {
    const prevDate = new Date(prev.scheduledTime).getTime()
    const nextDate = new Date(next.scheduledTime).getTime()

    return prevDate - nextDate
  })
}

export const filterConsultationsByStatus = <T extends { status: string }>(
  consultations: T[],
  status: string
): T[] => {
  return consultations.filter((v) => v.status === status)
}

export const filterConsultationsByTime = <T extends { scheduledTime: string }>(
  consultations: T[],
  from: number,
  to?: number
): T[] => {
  return consultations.filter(({ scheduledTime }) => {
    const time = new Date(scheduledTime).getTime()

    return (from ? time >= from : true) && (to ? time <= to : true)
  })
}

export const getClosestConsultations = <
  T extends { scheduledTime: string; status: string }
>(
  consultations: T[],
  limit = 1,
  date = new Date()
): T[] => {
  const filtered = filterConsultationsByTime(
    filterConsultationsByStatus(
      consultations || [],
      CONSULTATION_STATUSES.PAID
    ),
    date.getTime()
  )

  const sorted = sortConsultationsByScheduledTime(filtered)

  return sorted.length > 0 ? sorted.slice(0, limit) : []
}
