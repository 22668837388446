import React from 'react'
import PropType from 'prop-types'

import { Textarea, Text } from '../..'

import './index.scss'

const FormTextarea = ({
  id,
  form: { touched, errors },
  field: { value, name, ...fieldProps },
  label,
  placeholder,
}) => {
  const hasError = touched[name] && errors[name] ? true : false

  console.warn(
    'FormTextarea will be deprecated in next release of core-ui. ' +
      'Please use FormikTextarea instead.'
  )

  return (
    <div className="core-form-text-field">
      {label && (
        <label className="core-form-text-field__label" htmlFor={id}>
          {label}
        </label>
      )}

      <Textarea
        name={name}
        value={value || ''}
        intent={hasError ? 'error' : null}
        placeholder={placeholder}
        fill
        {...fieldProps}
      />

      {hasError && (
        <Text size="small" color="error">
          {errors[name]}
        </Text>
      )}
    </div>
  )
}

FormTextarea.propTypes = {
  id: PropType.string,
  label: PropType.string,
  placeholder: PropType.string,
}

export default FormTextarea
