/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'

import { Button, Tag } from '@babylon/core-ui'

import { withEnhancedRouter } from '~/core/compose'
import { prescriptionStateMessages } from '~/core/i18n/shared-messages'
import PrescriptionAuditModal from '~/features/prescriptions/PrescriptionAuditModal'
import VoidPrescriptionDialog from '~/features/prescriptions/PrescriptionsPage/components/VoidPrescriptionDialog'

import AuditAlerts from './AuditAlerts'
import DrugTable from './DrugTable'

import messages from './Prescription.messages'
import styles from './styles.module.scss'

const statusClassNames = {
  DRAFT: 'clinical-green',
  VOIDED: 'clinical-red',
}

class Prescription extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showVoidPrescriptionDialog: false,
    }
  }

  handlePrescriptionRemove = () => {
    const { onPrescriptionRemove, id } = this.props
    onPrescriptionRemove(id)
  }

  handleDrugEdit = ({ prescriptionDrugId }) => {
    const { onDrugEdit, id } = this.props
    onDrugEdit(id, prescriptionDrugId)
  }

  handleDrugRemove = ({ prescriptionDrugId }) => {
    const { onDrugRemove, id } = this.props
    onDrugRemove(id, prescriptionDrugId)
  }

  handleVoidPrescription = (reason) => {
    const {
      onPrescriptionVoid,
      id,
      markFinalizedConsultationAsEdited,
    } = this.props
    if (markFinalizedConsultationAsEdited) {
      markFinalizedConsultationAsEdited()
    }
    return onPrescriptionVoid(id, reason)
  }

  showVoidPrescriptionDialog = () => {
    this.setState({
      showVoidPrescriptionDialog: true,
    })
  }

  hideVoidPrescriptionDialog = () => {
    this.setState({
      showVoidPrescriptionDialog: false,
    })
  }

  handleDuplicateButtonClick = () => {
    const { onDuplicate, id, markFinalizedConsultationAsEdited } = this.props
    if (markFinalizedConsultationAsEdited) {
      markFinalizedConsultationAsEdited()
    }
    onDuplicate && onDuplicate(id)
  }

  render() {
    const {
      id,
      createdAt,
      prescriptionState,
      drugs,
      actions,
      match,
      history,
      consultationStatus,
      canDuplicatePrescription,
      submittingDuplication,
      intl,
      restrictFinalizedConsultationEditing,
    } = this.props
    const date = intl.formatDate(createdAt, { format: 'shortWithTime' })
    const labelColor = statusClassNames[prescriptionState] || 'clinical-orange'
    const fm = (t) => intl.formatMessage(t)

    return (
      <div
        className={styles.prescription}
        data-prescription-id={id}
        data-testid="added-prescription"
      >
        <VoidPrescriptionDialog
          show={this.state.showVoidPrescriptionDialog}
          onOk={this.handleVoidPrescription}
          onClose={this.hideVoidPrescriptionDialog}
        />
        {consultationStatus === 'completed' && (
          <AuditAlerts prescriptionId={id} />
        )}

        <div className={styles.header}>
          <div className={styles.date}>{date}</div>
          <Tag color={labelColor} className={styles.label}>
            {fm(
              prescriptionStateMessages[prescriptionState] ||
                prescriptionStateMessages.unknown_state
            )}
          </Tag>
        </div>
        <div className={styles.content}>
          <DrugTable
            drugs={drugs}
            isDraft={prescriptionState === 'DRAFT'}
            onPrescriptionRemove={this.handlePrescriptionRemove}
            isEditRestricted={restrictFinalizedConsultationEditing}
            onDrugEdit={this.handleDrugEdit}
            onDrugRemove={this.handleDrugRemove}
          />
        </div>
        {prescriptionState !== 'DRAFT' && (
          <div>
            <Button
              inline
              intent="secondary"
              onClick={() => {
                history.replace(
                  `${match.url}/prescription-audit?prescription=${id}`
                )
              }}
            >
              {intl.formatMessage(messages.audit_log)}
            </Button>
            {actions.includes('VOID') && prescriptionState !== 'VOIDED' && (
              <Button
                inline
                intent="secondary"
                onClick={this.showVoidPrescriptionDialog}
                disabled={restrictFinalizedConsultationEditing}
              >
                {intl.formatMessage(messages.void_prescription)}
              </Button>
            )}
            {canDuplicatePrescription &&
              (prescriptionState === 'VOIDED' ||
                prescriptionState === 'REJECTED') && (
                <Button
                  inline
                  className={styles.duplicateButton}
                  loading={submittingDuplication}
                  intent="secondary"
                  onClick={this.handleDuplicateButtonClick}
                  disabled={restrictFinalizedConsultationEditing}
                >
                  {intl.formatMessage(messages.duplicate_prescription)}
                </Button>
              )}
          </div>
        )}
        <Switch>
          <Route
            path="/consultation/:consultationId/prescription-audit"
            component={PrescriptionAuditModal}
          />
        </Switch>
      </div>
    )
  }
}

Prescription.defaultProps = {
  drugs: [],
  actions: [],
}

Prescription.propTypes = {
  id: PropTypes.string.isRequired,
  prescriptionState: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  drugs: PropTypes.array.isRequired,
  actions: PropTypes.array,
  onDrugEdit: PropTypes.func.isRequired,
  onDrugRemove: PropTypes.func.isRequired,
  onPrescriptionVoid: PropTypes.func.isRequired,
}

export default withEnhancedRouter(injectIntl(Prescription))
