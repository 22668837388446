import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck as falCheck,
  faFileEdit as falFileEdit,
  faPaperclip as falPaperClip,
} from '@fortawesome/pro-light-svg-icons'
import {
  faComments as farComments,
  faFilter as farFilter,
  faHeadphones as farHeadphones,
  faHeadphonesAlt as farHeadphonesAlt,
  faLanguage as farLanguage,
  faShield as farShield,
  faShieldAlt as farShieldAlt,
  faSignOut as farSignOut,
  faSortAmountDown as farSortAmountDown,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faExclamationTriangle as fasExclTriangle,
  faFilter as fasFilter,
  faPhone as fasPhone,
  faUserFriends as fasUserFriends,
  faVideo as fasVideo,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  farLanguage,
  farSignOut,
  farFilter,
  fasFilter,
  fasPhone,
  fasVideo,
  fasUserFriends,
  falCheck,
  falFileEdit,
  falPaperClip,
  farSortAmountDown,
  fasExclTriangle,
  farShield,
  farShieldAlt,
  farHeadphones,
  farHeadphonesAlt,
  farComments
)
