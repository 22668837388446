import React from 'react'

import { Tag, Tooltip } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './RecordingAlert.messages'
import styles from './styles.module.scss'

const RecordingAlert = () => {
  const fm = useFormatMessage()

  return (
    <Tooltip message={fm(messages.tooltip_text)}>
      <div className={styles.wrapper}>
        <Tag margin={false} className={styles.alert}>
          {fm(messages.indicator_text)}
        </Tag>
      </div>
    </Tooltip>
  )
}

export default RecordingAlert
