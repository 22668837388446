import React from 'react'

import { useIntl } from '@babylon/intl'

import { useConsultation } from '~/core/config'
import { WorkflowActionsPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import { useUpdateQueryContext } from '~/core/update-query-provider/UpdateQueryProvider'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'
import { useCpConsultationWorkflowsV2Query } from '~/generated'

import { getGroupsFromWorkflows, isInvalidWorkflow } from './utils'
import WorkflowActionsView from './WorkflowActionsView'

const WorkflowActionsContainer: WorkflowActionsPluginInterface = ({
  consultationContext,
}) => {
  const { locale } = useIntl()
  const [canManageWorkflows] = usePermissions('enable_workflow_triggers')
  const updateQueryMap = useUpdateQueryContext()

  const consultation = useConsultation(consultationContext)
  const { id: consultationId, consumerNetwork } = consultation

  const {
    data,
    loading,
    error,
    refetch,
    updateQuery,
  } = useCpConsultationWorkflowsV2Query({
    variables: {
      locale,
      tags: 'clinical-portal',
      consumerNetwork: String(consumerNetwork),
      consultationId,
      includeDefinitions: Boolean(consumerNetwork),
    },
    notifyOnNetworkStatusChange: true,
    skip: !consumerNetwork,
  })
  const workflows = data?.consultationWorkflowsV2?.workflows?.results ?? []
  const workflowDefinitions =
    data?.consultationWorkflowsV2?.workflowDefinitions ?? []

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    consultation?.status
  )

  updateQueryMap.updateWorkflowsV2Query = updateQuery

  const unknownWorkflows = workflows.filter((workflow) =>
    isInvalidWorkflow(workflow)
  )
  const workflowGroups = getGroupsFromWorkflows(workflows, workflowDefinitions)

  if (!canManageWorkflows) {
    return null
  }

  return (
    <WorkflowActionsView
      workflows={workflows}
      workflowDefinitions={workflowDefinitions}
      unknownWorkflows={unknownWorkflows}
      workflowGroups={workflowGroups}
      loading={loading}
      refetch={refetch}
      error={error}
      restrictFinalizedConsultationEditing={
        restrictFinalizedConsultationEditing
      }
    />
  )
}

export default WorkflowActionsContainer
