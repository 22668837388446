import React, { useState } from 'react'

import { useLogException } from '~/core/sentry'

import ErrorCode from '../ErrorCode'
import ErrorPanelView, { ErrorPanelViewProps } from '../ErrorPanelView'

export interface ErrorPanelProps
  extends Pick<
    ErrorPanelViewProps,
    | 'title'
    | 'subtitle'
    | 'retry'
    | 'center'
    | 'fill'
    | 'description'
    | 'retryLabel'
  > {
  error: Error
  description?: string
  className?: string
}

const ErrorPanel = ({
  error,
  center,
  fill,
  title,
  subtitle,
  description,
  retry,
  retryLabel,
  className,
}: ErrorPanelProps) => {
  const errorId = useLogException(error)
  const errorCode = <ErrorCode error={error} />
  const [loading, setLoading] = useState(false)

  const handleRetry = async () => {
    if (retry) {
      setLoading(true)
      try {
        await retry()
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <ErrorPanelView
      center={center}
      fill={fill}
      title={title}
      subtitle={subtitle}
      retry={retry ? handleRetry : undefined}
      retryLabel={retryLabel}
      errorCode={errorCode}
      errorId={errorId}
      description={description}
      className={className}
      loading={loading}
    />
  )
}

export default ErrorPanel
