import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { Alert, Button, Spinner, Tag, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  DISABLE_PRE_CONS_COPY,
  ENABLE_CHATSCRIPT_DISCLAIMER,
} from '~/constants'
import { CHATBOT_TRANSCRIPT_CATEG } from '~/constants/analytics'
import analytics from '~/core/analytics'
import {
  ChatscriptConversation,
  useCpLiteChatscriptMessagesQuery,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import ShowMoreSectionButton from '~/ui/ShowMoreSectionButton'
import Tooltip from '~/ui/Tooltip'

import ChatTranscript from './ChatTranscript'
import { copyMessagesIntoClipboard } from './utils'

import messages from './ChatscriptMessages.messages'
import styles from './styles.module.scss'

const LIMIT_MESSAGES_MAX_VALUE = 50
const MESSAGES_INCREMENT_VALUE = 4
const MESSAGES_INITIAL_VALUE = 4

interface ConversationPreview
  extends Pick<ChatscriptConversation, 'preview' | 'result'> {}

const paginationValues = ['all', 'incremental']

interface ChatscriptMessagesProps {
  patientUuid: string
  eventId: string
  conversationPreview?: ConversationPreview
  pagination?: typeof paginationValues[number]
  showDisclaimer?: boolean
  showTranscriptTitle?: boolean
  gaPaginationAction?: string
  copyFunctionEnabled?: boolean
  noBorder?: boolean
}

const trackEvent = analytics.trackEventFactory({
  category: CHATBOT_TRANSCRIPT_CATEG,
})

const ChatscriptMessages = ({
  eventId,
  patientUuid,
  conversationPreview,
  pagination,
  showDisclaimer = true,
  showTranscriptTitle = true,
  gaPaginationAction,
  copyFunctionEnabled = true,
  noBorder,
}: ChatscriptMessagesProps) => {
  const fm = useFormatMessage()
  const [copySuccess, setCopySuccess] = useState(false)

  const {
    data,
    loading,
    error,
    refetch,
    fetchMore,
  } = useCpLiteChatscriptMessagesQuery({
    variables: {
      eventId,
      patientUuid,
      limit: LIMIT_MESSAGES_MAX_VALUE,
      offset: 0,
    },
  })

  const [visibleMessagesCount, setVisibleMessagesCount] = useState<number>(
    MESSAGES_INITIAL_VALUE
  )

  const copyToClipboard = () => {
    if (data?.chatscriptMessages?.messages?.length) {
      copyMessagesIntoClipboard(data?.chatscriptMessages?.messages)
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 5000)
    }
  }

  const handleShowMoreClick = () => {
    if (pagination === 'all') {
      setVisibleMessagesCount(data?.chatscriptMessages?.messages?.length || 0)
    } else {
      setVisibleMessagesCount(
        Math.min(
          visibleMessagesCount + MESSAGES_INCREMENT_VALUE,
          data?.chatscriptMessages?.messages?.length || 0
        )
      )
    }

    if (gaPaginationAction) {
      trackEvent({ action: gaPaginationAction })
    }
  }

  const handleShowLessClick = () => {
    setVisibleMessagesCount(MESSAGES_INITIAL_VALUE)
  }

  useEffect(() => {
    // make sure we get all messages since we don't have pagination
    if (data?.chatscriptMessages?.more) {
      fetchMore({
        variables: {
          offset: data.chatscriptMessages?.messages?.length,
          eventId,
          patientUuid,
        },
      })
    }
  }, [data, eventId, fetchMore, patientUuid])

  const { result, preview } = conversationPreview || {}
  const outcome = result?.type

  if (loading) {
    return <Spinner color="#87919e" size="medium" centered />
  }

  if (error) {
    return (
      <ErrorPanel
        error={error}
        fill="content"
        retry={() => {
          refetch()
        }}
        title={fm(messages.could_not_get_conversation_message_v2)}
      />
    )
  }

  const allTranscriptMessages = data?.chatscriptMessages?.messages || []
  const visibleMessages = allTranscriptMessages.slice(0, visibleMessagesCount)

  const allMessagesVisible =
    visibleMessagesCount === allTranscriptMessages.length

  const showMoreButtonVisible =
    pagination && allTranscriptMessages.length > MESSAGES_INITIAL_VALUE

  if (data) {
    return (
      <div>
        {showDisclaimer && (
          <Alert
            data-testid="chatbot-disclaimer"
            className={styles.disclaimer}
            icon={<i className="fas fa-exclamation-triangle" />}
          >
            {ENABLE_CHATSCRIPT_DISCLAIMER ? (
              <div>
                This is the outcome and transcript of an automated chatbot
                conversation.
                <ul>
                  <li>
                    This may have been done on behalf of somebody else. Only use
                    this content if the patient completed it for themselves.
                  </li>
                  <li>
                    Always confirm the data with the patient before making
                    clinical decisions.
                  </li>
                </ul>
              </div>
            ) : (
              fm(messages.disclaimer_2)
            )}
          </Alert>
        )}

        {conversationPreview && (
          <div className={styles.outcomeContainer}>
            <Text
              className={styles.outcomeContainerTitle}
              tag="div"
              size="large"
              bold
            >
              {fm(messages.outcome_heading)}
              <Tag
                className={styles.outcomeTag}
                color="reverse-grey"
                uppercase={false}
              >
                {outcome?.replace(/_/g, ' ') || fm(messages.no_outcome)}
              </Tag>
            </Text>

            {preview && (
              <pre className={styles.preText}>
                <Text className={styles.preview} tag="div">
                  {preview}
                </Text>
              </pre>
            )}
          </div>
        )}

        {showTranscriptTitle && (
          <Text
            data-testid="transcript-header"
            tag="div"
            className={styles.transcriptTitle}
            bold
            size="large"
          >
            {fm(messages.transcript_heading)}
          </Text>
        )}

        {!DISABLE_PRE_CONS_COPY && copyFunctionEnabled && (
          <Tooltip
            placement="left"
            message={
              copySuccess
                ? fm(messages.information_copied_v2)
                : fm(messages.copy_information_v2)
            }
          >
            <Button
              className={styles.copyButton}
              dataTestId="transcript-copy-button"
              icon={
                copySuccess ? (
                  <FontAwesomeIcon
                    className={styles.successButtonIcon}
                    icon={faCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className={styles.copyButtonIcon}
                    icon={faCopy}
                  />
                )
              }
              intent="link"
              onClick={copyToClipboard}
            />
          </Tooltip>
        )}

        <ChatTranscript
          conversation={pagination ? visibleMessages : allTranscriptMessages}
          noBorder={noBorder}
        />

        {showMoreButtonVisible && (
          <ShowMoreSectionButton
            handleOnClick={
              allMessagesVisible ? handleShowLessClick : handleShowMoreClick
            }
            buttonLabel={
              allMessagesVisible
                ? fm(messages.show_less)
                : fm(messages.show_more)
            }
          />
        )}
      </div>
    )
  }
  return <div />
}

export default ChatscriptMessages
