import React, { useState } from 'react'

import { Checkbox, CheckboxGroup } from '@babylon/core-ui'

import Question from './Question'

import styles from './styles.module.scss'

interface MultiSelectProps {
  questionText: string
  suggestedAnswers: { id: string; answer: string }[]
  onSubmit: (answerIds: string[]) => void
  onDismiss: () => void
}

const MultiSelect = ({
  questionText,
  suggestedAnswers,
  onSubmit,
  onDismiss,
}: MultiSelectProps) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([])

  return (
    <Question
      questionText={questionText}
      onSubmit={() => onSubmit(selectedAnswers)}
      onDismiss={onDismiss}
    >
      <CheckboxGroup
        name="suggested_answers"
        value={selectedAnswers}
        onChange={setSelectedAnswers}
        className={styles.checkboxGroup}
      >
        {suggestedAnswers.map(({ id, answer }) => (
          <Checkbox value={id} key={id}>
            {answer}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Question>
  )
}

export default MultiSelect
