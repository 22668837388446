import { defineMessages } from 'react-intl'

const baseId = 'note_assistant_summary'

export default defineMessages({
  error_retrieving_notes: {
    id: `${baseId}.error_retrieving_notes`,
    defaultMessage: 'Error retrieving generated notes',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Note Assistant',
  },
  generating_note_message: {
    id: `${baseId}.generating_note_message`,
    defaultMessage: 'Generating note...',
  },
  generating_notes: {
    id: `${baseId}.generating_notes`,
    defaultMessage: 'Generating notes',
  },
  fetching_notes: {
    id: `${baseId}.fetching_notes`,
    defaultMessage: 'Fetching recent notes...',
  },
  not_supported_warning: {
    id: `${baseId}.not_supported_warning`,
    defaultMessage: 'Note Assistant is only available for video calls.',
  },
  error: {
    id: `${baseId}.error`,
    defaultMessage:
      'Note assistant has stopped due to a problem. It may not be possible to restart so please document manually.',
  },
  copy_all: {
    id: `${baseId}.copy_all`,
    defaultMessage: 'Copy all',
  },
  status_active: {
    id: `${baseId}.status_active`,
    defaultMessage: 'Active',
  },
  status_inactive: {
    id: `${baseId}.status_inactive`,
    defaultMessage: 'Inactive',
  },
  speech_processing_disabled: {
    id: `${baseId}.speech_processing_disabled`,
    defaultMessage:
      'Speech processing is currently switched off. Please document manually.',
  },
})
