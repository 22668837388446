import { useIntl } from '@babylon/intl'

import {
  CpConsultationWorkflowsV2QueryResult,
  useCpConsultationWorkflowsV2Query,
} from '~/generated'

export type ConsultationWorkflowV2 = NonNullable<
  CpConsultationWorkflowsV2QueryResult['data']
>['consultationWorkflowsV2']['workflows']['results'][number]

export type ConsultationWorkflowV2Group = NonNullable<
  ConsultationWorkflowV2['workflowDefinition']
>['group']

interface Params {
  consultationId: string
  consumerNetwork: number | null | undefined
  skip?: boolean
  includeDefinitions?: boolean
}

const useConsultationWorkflowsV2Query = ({
  consultationId,
  consumerNetwork,
  skip,
  includeDefinitions = true,
}: Params) => {
  const { locale } = useIntl()

  return useCpConsultationWorkflowsV2Query({
    variables: {
      locale,
      tags: 'clinical-portal',
      consumerNetwork: String(consumerNetwork),
      consultationId,
      includeDefinitions: includeDefinitions && Boolean(consumerNetwork),
    },
    notifyOnNetworkStatusChange: true,
    skip,
  })
}

export default useConsultationWorkflowsV2Query
