import jwtDecode from 'jwt-decode'

const defaultExpiryTolerance = 30 * 60 // thirty mins

const isExpiring = (token: any, expiryTolerance = defaultExpiryTolerance) => {
  const { exp } = jwtDecode(token) || {}

  if (typeof exp !== 'number') {
    throw new Error("Token doesn't have an exp claim")
  }

  const nowInSeconds = Math.floor(new Date().getTime() / 1000)

  return exp < nowInSeconds + expiryTolerance
}

export default {
  isExpiring,
}
