import React from 'react'

import { Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ENABLE_EXAMINATION_CLINICAL_CODES } from '~/constants'
import { useConsultation } from '~/core/config'
import { ExaminationNotesPluginInterface } from '~/core/config/modules/generated/types'
import { useIsEditDisabled } from '~/features/consultation/utils'
import { useExaminationNotesQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section } from '~/ui/Section'

import ExaminationFieldEditor from '../ExaminationFieldEditor'

import styles from '../styles.module.scss'
import ExploredByGPMessages from './ExploredByGP.messages'
import ToldByPatientMessages from './ToldByPatient.messages'

const ExaminationNotes: ExaminationNotesPluginInterface = ({
  consultationContext,
  editedConsultationWarningModel,
}) => {
  const fm = useFormatMessage()
  const consultation = useConsultation(consultationContext)

  const markFinalizedConsultationAsEdited = editedConsultationWarningModel?.useModelContext()
    ?.markFinalizedConsultationAsEdited

  const { data, loading, error, refetch } = useExaminationNotesQuery({
    variables: {
      consultationId: consultation.id,
    },
  })

  const editDisabled = useIsEditDisabled(consultation.status)

  const { toldByPatient, exploredByGp, toldByPatientCodes, exploredByGpCodes } =
    data?.consultation?.consultationNote || {}

  if (loading) {
    return <Spinner size="small" />
  }

  if (error) {
    return (
      <ErrorPanel
        error={error}
        fill="container"
        center
        retry={() => refetch()}
        title={fm(ExploredByGPMessages.error_loading_notes)}
      />
    )
  }

  return (
    <Section>
      <fieldset className={styles.field}>
        <ExaminationFieldEditor
          // key is needed for reseting the state when consultation changes
          // do not remove
          key={`${consultation.id} wytu`}
          name="history"
          consultationId={consultation?.id}
          text={toldByPatient}
          codes={toldByPatientCodes}
          label={fm(ToldByPatientMessages.label)}
          subLabel={fm(ToldByPatientMessages.subLabel)}
          placeholder={fm(ToldByPatientMessages.placeholderText)}
          mutationTextFieldName="toldByPatient"
          mutationCodesFieldName="toldByPatientCodes"
          enableSelection={ENABLE_EXAMINATION_CLINICAL_CODES}
          readOnly={editDisabled}
          id="told-by-patient"
          dataTestId="told-by-patient"
          markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
        />

        <ExaminationFieldEditor
          // key is needed for reseting the state when consultation changes
          // do not remove
          key={`${consultation.id} wwe`}
          name="examination"
          consultationId={consultation?.id}
          text={exploredByGp}
          codes={exploredByGpCodes}
          label={fm(ExploredByGPMessages.label)}
          placeholder={fm(ExploredByGPMessages.placeholder)}
          mutationTextFieldName="exploredByGp"
          mutationCodesFieldName="exploredByGpCodes"
          enableSelection={ENABLE_EXAMINATION_CLINICAL_CODES}
          readOnly={editDisabled}
          id="explored-by-gp"
          dataTestId="explored-by-gp"
          markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
        />
      </fieldset>
    </Section>
  )
}

export default ExaminationNotes
