import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import Label from '~/ui/Label'

import messages from './PrescriptionFormulary.messages'
import styles from './styles.module.scss'

const PrescriptionFormulary = ({ indication, guidelines }) => {
  const fm = useFormatMessage()

  return (
    <tr>
      <td colSpan="7">
        <Label className={styles.indication} value={indication.name}>
          <div className={styles.formAdvice}>
            <div className={styles.formAdviceTitle}>
              {' '}
              {fm(messages.formulary_advice_title)}
            </div>
            {guidelines}
          </div>
        </Label>
      </td>
    </tr>
  )
}

export default PrescriptionFormulary
