import React from 'react'
import { Route } from 'react-router'

import { useConsultation } from '~/core/config'
import { PatientMetricsPluginInterface } from '~/core/config/modules/generated/types'
import PatientMetricsModal from '~/features/patient-metrics/PatientMetricsModal/PatientMetricsModal'
import { queryVariableConstants } from '~/features/patient-metrics/utils/dataManagement'
import { useConsultationPatientMetricsQuery } from '~/generated'

import PatientMetricsSection from './PatientMetricsSection'

const PatientMetricsWrapper: PatientMetricsPluginInterface = ({
  consultationContext,
}) => {
  const consultation = useConsultation(consultationContext)
  const consultationId = consultation?.id

  if (!consultationId) {
    throw new Error('Consultation ID required')
  }

  const { data, error, refetch, loading } = useConsultationPatientMetricsQuery({
    variables: {
      ...queryVariableConstants,
      id: consultationId,
    },
  })

  return (
    <>
      <PatientMetricsSection
        patient={data?.consultation?.patient}
        error={error}
        refetch={refetch}
        loading={loading}
      />
      <Route
        path="/consultation/:consultationId/patient-metrics/edit"
        component={PatientMetricsModal}
      />
    </>
  )
}

export default PatientMetricsWrapper
