import { ApolloError } from '@apollo/client'
import React from 'react'
import { FormattedDate } from 'react-intl'

import { Spinner } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import { ConsultationSickNote, SickNotesQueryResult } from '~/generated'
import DataList from '~/ui/DataList'
import { ErrorPanel } from '~/ui/ErrorPanel'

import messages from './SickNotes.messages'

export interface SickNotesSummaryProps {
  sickNotes: ConsultationSickNote[]
  loading: boolean
  error: ApolloError | undefined
  refetch?: SickNotesQueryResult['refetch']
}

const SickNotesSummary = ({
  sickNotes,
  loading,
  error,
  refetch,
}: SickNotesSummaryProps) => {
  const f = useMessages(messages)

  if (loading) {
    return (
      <Section title={f('sick_notes_section_title_private')}>
        <Spinner size="small" testid="cr-sick-notes-loading" />
      </Section>
    )
  }

  if (error) {
    return (
      <Section title={f('sick_notes_section_title_private')}>
        <ErrorPanel
          error={error}
          title={f('sick_notes_section_error')}
          retry={() => refetch?.()}
        />
      </Section>
    )
  }

  if (!sickNotes.length) return null

  return (
    <Section
      title={f('sick_notes_section_title_private')}
      testid="cr-sick-notes"
    >
      {sickNotes.map((note) => {
        const data = [
          {
            key: f('sick_notes_section_table_reason_heading'),
            value: note.reason,
          },
          {
            key: f('sick_notes_section_table_start_heading'),
            value: (
              <FormattedDate
                value={note.startDate ?? undefined}
                format="short"
              />
            ),
          },
          {
            key: f('sick_notes_section_table_end_heading'),
            value: (
              <FormattedDate value={note.endDate ?? undefined} format="short" />
            ),
          },
        ]

        return <DataList key={note.id} data={data} />
      })}
    </Section>
  )
}

export default SickNotesSummary
