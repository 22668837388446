import cn from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

interface ReviewProps {
  type: 'complete' | 'incomplete' | 'upcoming'
  light?: boolean
  children: React.ReactNode
}

const Review = ({ type, light, children }: ReviewProps) => (
  <div
    className={cn(
      styles.review,
      type === 'incomplete' && styles.incomplete,
      type === 'upcoming' && styles.upcoming,
      light && styles.light
    )}
  >
    {children}
  </div>
)

export default Review
