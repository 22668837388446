import cn from 'classnames'
import React, { useRef } from 'react'

import useClickOutsideElement from './useClickOutsideElement'

import styles from './styles.module.scss'

interface DropdownProps extends React.HTMLProps<HTMLDivElement> {
  width?: number
  children: React.ReactNode
  onClickOutside?: Function
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  className,
  onClickOutside,
  width,
  style,
  ...rest
}) => {
  const ref = useRef(null)

  useClickOutsideElement(ref, () => {
    if (onClickOutside) {
      onClickOutside()
    }
  })

  return (
    <div
      {...rest}
      ref={ref}
      style={{ ...style, width }}
      className={cn(styles.dropdown, className)}
    >
      {children}
    </div>
  )
}

export default Dropdown
