import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history.timeline_test_result_event'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Test result',
  },
  title_reviewer: {
    id: `${baseId}.title_reviewer`,
    defaultMessage: ' reviewed by {name}',
  },
  view_result: {
    id: `${baseId}.view_result`,
    defaultMessage: 'Click to view results',
  },
  test_title: {
    id: `${baseId}.test_title`,
    defaultMessage: 'Test:',
  },
})
