import React, { useEffect } from 'react'

import { Spinner } from '@babylon/core-ui'

import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import { MiniErrorPanel } from '~/ui/ErrorPanel'

import {
  Action,
  ActionType,
  CallEndReason,
  CallStatus,
  isActive,
  isCriticallyFailed,
  isEnabled,
  isLoading,
  isReady,
} from '../CallStatusReducerModelProvider'
import PreCallCheck from '../PreCallCheck'
import CallEndMessage from './CallEndMessage'
import StatusMessage from './StatusMessage'

import messages from './StatusIndicator.messages'
import styles from './styles.module.scss'

interface StatusIndicatorProps {
  voiceStatus: CallStatus
  voiceError: Error | null
  videoStatus: CallStatus
  videoError: Error | null
  dispatch: React.Dispatch<Action>
  callEndReason: CallEndReason | null
  isVoiceFallbackVisible: boolean
}

const StatusIndicator = ({
  voiceStatus,
  voiceError,
  videoStatus,
  videoError,
  dispatch,
  callEndReason,
  isVoiceFallbackVisible,
}: StatusIndicatorProps) => {
  const f = useMessages(messages)
  const { preCallCheckEnabled, voiceVoipEnabled } = useFeatureFlags()

  /**
   * Error Prep
   */
  const hasVoiceLoadingError = isCriticallyFailed(voiceStatus)
  const hasVideoLoadingError = isCriticallyFailed(videoStatus)
  const hasCriticalLoadingError =
    hasVideoLoadingError || (hasVoiceLoadingError && !isEnabled(videoStatus))
  const error = isEnabled(videoStatus) ? videoError || voiceError : voiceError
  const ignoreVoiceError =
    hasVoiceLoadingError && !videoError && isEnabled(videoStatus)

  useEffect(() => {
    if (ignoreVoiceError && voiceError) {
      logException(voiceError)
    }
  }, [ignoreVoiceError, voiceError])

  /**
   * Loading Spinner
   */
  if (
    isLoading(voiceStatus) ||
    (isEnabled(videoStatus) && isLoading(videoStatus))
  ) {
    return (
      <div className={styles.wrapper}>
        <Spinner testid="call-spinner" centered color="#fff" />
      </div>
    )
  }

  /**
   * Voice call in progress
   */
  if (isActive(voiceStatus)) {
    return <StatusMessage>{f('voice_in_progress')}</StatusMessage>
  }

  /**
   * Call end reason
   */
  if (callEndReason) {
    const messageMap: Record<CallEndReason, string> = {
      patientClientDisconnected: f('patient_hung_up'),
      patientNetworkDisconnected: f('patient_lost_connection'),
      clinicianNetworkDisconnected: f('clinician_lost_connection'),
    }

    const message = messageMap[callEndReason]

    if (message) {
      return (
        <CallEndMessage
          message={message}
          onTimeoutComplete={() => {
            dispatch({ type: ActionType.CLEAR_CALL_END_REASON })
          }}
        />
      )
    }
  }

  /**
   * ErrorPanel
   */
  if (error && !ignoreVoiceError) {
    const title =
      hasVideoLoadingError || hasVoiceLoadingError
        ? 'error_title_default'
        : 'error_starting_title'

    const description =
      hasVideoLoadingError && !hasVoiceLoadingError
        ? 'error_loading_video_description'
        : 'error_description_default'

    const retry = () => {
      if (hasVoiceLoadingError) {
        dispatch({ type: ActionType.VOICE_LOADING })
      }
      if (hasVideoLoadingError) {
        dispatch({ type: ActionType.VIDEO_LOADING })
      }
    }

    return (
      <MiniErrorPanel
        title={f(title)}
        description={f(description)}
        retry={hasCriticalLoadingError ? retry : undefined}
        error={error}
        className={styles.errorPanel}
        retryAlwaysVisible
      />
    )
  }

  /**
   * Ready to call
   */
  const isReadyToCall = () => {
    if (voiceVoipEnabled && isVoiceFallbackVisible) return false

    if (isReady(videoStatus)) {
      const voiceLoadingComplete =
        isReady(voiceStatus) || hasVoiceLoadingError || !isEnabled(voiceStatus)

      return voiceLoadingComplete
    }

    if (isActive(videoStatus)) {
      return false
    }

    return isReady(voiceStatus)
  }

  if (isReadyToCall()) {
    if (preCallCheckEnabled) {
      return <PreCallCheck />
    }

    return <StatusMessage>{f('ready_to_call')}</StatusMessage>
  }

  return null
}

export default StatusIndicator
