import React from 'react'
import { Spinner } from '@babylon/core-ui'
import styles from './LoadingIndicator.module.css'

export interface LoadingIndicatorProps {
  loading: boolean
}

export function LoadingIndicator({ loading }: LoadingIndicatorProps) {
  if (!loading) {
    return null
  }

  return (
    <div className={styles.LoadingIndicator}>
      <Spinner testid="document_table_loading_spinner" />
    </div>
  )
}
