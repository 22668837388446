import React from 'react'

import { Alert } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './IncompleteConsultationMessage.messages'
import styles from './styles.module.scss'

const IncompleteConsultationMessage = () => {
  const fm = useFormatMessage()

  return (
    <Alert
      data-testid="incomplete-alert"
      className={styles.title}
      title={fm(messages.title)}
      intent="warning"
      icon={<i className="fas fa-exclamation-triangle" />}
    >
      <div className={styles.message}>{fm(messages.message)}</div>
    </Alert>
  )
}

export default IncompleteConsultationMessage
