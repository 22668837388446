import { defineMessages } from 'react-intl'

const baseId = 'consultation.complete_dialog.us_ehr_notes_completion_model'

export default defineMessages({
  loading_notes: {
    id: `${baseId}.loading_notes`,
    defaultMessage: 'Please wait while we check all the appointment details.',
  },
  error_loading_notes: {
    id: `${baseId}.error_loading_notes`,
    defaultMessage:
      "We're sorry, but we were not able to load the notes for this appointment. Please ensure that the athenahealth encounter has been signed and closed.",
  },
})
