import { sanitizeUrl } from '@braintree/sanitize-url'
import React from 'react'
// react-awesome-lightbox does not have a types export
// @ts-expect-error
import Lightbox from 'react-awesome-lightbox'

type Images = {
  url?: string
  title?: string
}[]
interface LightboxProps {
  images: Images
  startIndex?: number
  onClose: () => void
}

const getSanitizedImages = (images: Images) =>
  images.map((image) => {
    const { url } = image
    return { ...image, url: url ? sanitizeUrl(url) : '' }
  })

const LightBoxImageViewer = ({
  images,
  startIndex = 0,
  onClose,
}: LightboxProps) => {
  return images.length === 1 ? (
    <Lightbox
      image={getSanitizedImages(images)[0].url}
      onClose={onClose}
      title={images[0].title}
    />
  ) : (
    <Lightbox
      images={getSanitizedImages(images)}
      onClose={onClose}
      startIndex={startIndex}
    />
  )
}

export default LightBoxImageViewer
