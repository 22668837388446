import { TextJSON } from 'slate'

const createTextNode = (text: string): TextJSON => ({
  object: 'text',
  leaves: [
    {
      object: 'leaf',
      text,
      marks: [],
    },
  ],
})

export default createTextNode
