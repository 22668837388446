import React from 'react'

import { Card, Heading } from '@babylon/core-ui'
import LabInfo from './LabInfo'
import Profiles from './Profiles'

import { isFilledArray } from '../../../utils'
import { mapProfile } from '../../../utils/mapData'
import styles from './styles.module.scss'

export default ({
  report = {},
  loading = false,
  error = null,
  showProfileCommentForm,
}) => {
  const profiles = report.measures
    ? [
        {
          name: 'Results',
          biomarkers: report.measures,
        },
      ]
    : report.profiles

  switch (true) {
    case isFilledArray(profiles):
      return (
        <>
          <Profiles
            profiles={profiles.map(mapProfile)}
            showProfileCommentForm={showProfileCommentForm}
            reportId={report.id}
          />

          {
            /*This is legacy and can be removed once we switch to v2*/
            report.additionalInfo && <LabInfo info={report.additionalInfo} />
          }
        </>
      )
    case loading:
      return (
        <Card>
          <Heading level="h3" className={styles.resultState}>
            Loading results...
          </Heading>
        </Card>
      )
    case !!error:
      return (
        <Card>
          <Heading level="h3" className={styles['resultState--error']}>
            Failed to load results, an error occured.
          </Heading>
        </Card>
      )
    default:
      return (
        <Card>
          <Heading level="h3" className={styles.resultState}>
            This report has no results and no technical error has been caught.
          </Heading>
        </Card>
      )
  }
}
