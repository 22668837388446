import axios from 'axios'
import qs from 'qs'
import { useEffect, useState } from 'react'

import { AuthClient, getAuthClient } from '@babylon/web-platform-utils-auth'

import { APP_NAME, CONFIG_URL, ENABLE_NEW_AUTH } from '~/constants'
import requestIdGenerator from '~/core/request-id-generator'

import { FeatureFlags } from '../core-modules'

type ProductConfigResponse = {
  clinicalPortal?: {
    featureFlags: FeatureFlags
  }
}

let authClient: AuthClient

const fetchConfig = async (
  userUuid: string,
  contractId: string
): Promise<ProductConfigResponse> => {
  const query = qs.stringify(
    {
      namespaces: 'clinical_portal',
      contracts: contractId,
      appname: APP_NAME,
      expand: 'true',
    },
    {
      addQueryPrefix: true,
      skipNulls: true,
    }
  )

  const headers: Record<string, any> = {
    'X-App-Name': APP_NAME,
    'babylon-request-id': requestIdGenerator.generate(),
    'X-Ab-Identifier': userUuid,
  }

  if (ENABLE_NEW_AUTH) {
    if (!authClient) {
      authClient = getAuthClient()
    }

    const token = await authClient.getAuthToken()
    headers.Authorization = token ? `Bearer ${token}` : ''
  }

  const response = await axios.get<ProductConfigResponse>(
    `${CONFIG_URL}${query}`,
    {
      withCredentials: true,
      headers,
    }
  )

  return response?.data
}

const usePatientContractFeatureFlags = (
  userUuid?: string,
  contractUuid?: string
): {
  featureFlags: FeatureFlags
  loading: boolean
  error?: Error
} => {
  const [
    clinicalPortalConfig,
    setClinicalPortalConfig,
  ] = useState<FeatureFlags>({})
  const [error, setError] = useState<Error | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (userUuid && contractUuid) {
      setLoading(true)
      fetchConfig(userUuid, contractUuid)
        .then((response) => {
          setLoading(false)
          if (response?.clinicalPortal?.featureFlags) {
            setClinicalPortalConfig(response.clinicalPortal.featureFlags)
          } else {
            throw new Error(
              `Unable to get patient feature flags for user uuid: ${userUuid}`
            )
          }
        })
        .catch((e) => {
          setLoading(false)
          setError(
            e instanceof Error ? e : new Error(`Unknown error ${e?.toString()}`)
          )
        })
    }
  }, [userUuid, contractUuid])

  return {
    featureFlags: clinicalPortalConfig,
    loading,
    error,
  }
}

export default usePatientContractFeatureFlags
