import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.prescriptions.suggestions'

export default defineMessages({
  prescription_title: {
    id: `${baseId}.prescription_title`,
    defaultMessage: 'Prescription {number}',
  },
  suggestions_not_available: {
    id: `${baseId}.suggestions_not_available`,
    defaultMessage: 'Suggestions are not available for the current diagnosis',
  },
  error_loading_message: {
    id: `${baseId}.error_loading_message`,
    defaultMessage:
      'Error loading the suggested prescriptions. Please try again later.',
  },
  confirm_add_suggestion_cancel_button_label: {
    id: `${baseId}. confirm_add_suggestion_cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  confirm_add_suggestion_override_button_label: {
    id: `${baseId}. confirm_add_suggestion_override_button_label`,
    defaultMessage: 'Override',
  },
})
