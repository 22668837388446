import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.patient_metrics'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient metrics',
  },
  update_patient_metrics_button_label: {
    id: `${baseId}.update_patient_metrics_button_label_v2`,
    defaultMessage: 'Update patient metrics',
  },
  height_label: {
    id: `${baseId}.h_label`,
    defaultMessage: 'H',
  },
  weight_label: {
    id: `${baseId}.w_label`,
    defaultMessage: 'W',
  },
  bmi_label: {
    id: `${baseId}.bmi_label`,
    defaultMessage: 'BMI',
  },
  height_unit: {
    id: `${baseId}.height_unit`,
    defaultMessage: 'cm',
  },
  weight_unit: {
    id: `${baseId}.weight_unit`,
    defaultMessage: 'kg',
  },
  smoking_status_label: {
    id: `${baseId}.smoking_status_label`,
    defaultMessage: 'Smoking status',
  },
  blood_pressure_label: {
    id: `${baseId}.blood_pressure_label`,
    defaultMessage: 'Blood pressure',
  },
  smoking_status_smoker_text: {
    id: `${baseId}.smoking_status_smoker_text`,
    defaultMessage: 'Smoker',
  },
  smoking_status_non_smoker_text: {
    id: `${baseId}.smoking_status_non_smoker_text`,
    defaultMessage: 'Non-smoker',
  },
  smoking_status_ex_smoker_text: {
    id: `${baseId}.smoking_status_ex_smoker_text`,
    defaultMessage: 'Ex-smoker',
  },
  na_text: {
    id: `${baseId}.na_text`,
    defaultMessage: 'N/A',
  },
  bmi_na: {
    id: `${baseId}.bmi_na`,
    defaultMessage: 'N/A',
  },
  bmi_out_of_range: {
    id: `${baseId}bmi_bmi_out_of_range`,
    defaultMessage: '-out of range-',
  },
  error_loading_metrics: {
    id: `${baseId}error_loading_metrics`,
    defaultMessage:
      'An error has occurred and the patient metrics section is currently unavailable.',
  },
  error_panel_loading_metrics: {
    id: `${baseId}error_panel_loading_metrics`,
    defaultMessage: 'We can’t load patient metrics right now',
  },
})
