import { Field } from 'formik'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { Grid, NumberInput } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { HeightConversion } from '@babylon/shared-utils'

import { usePrevious } from '~/core/hooks'
import Timestamp from '~/features/patient-metrics/utils/Timestamp'

import { usePatientMetricsState } from '../PatientMetricsStateProvider'
import BmiSectionHeader from './BmiSectionHeader'
import { validateHeight } from './utils'

import messages from '../PatientMetricsModal.messages'
import styles from '../styles.module.scss'

type InputEvent = ChangeEvent<HTMLInputElement>

interface ImperialHeightFormParams {
  timestamp?: string
}

const ImperialHeight: React.FC<ImperialHeightFormParams> = ({ timestamp }) => {
  const fm = useFormatMessage()
  const {
    values: { height },
    displayValues: { displayHeight, performerHeight },
    setFieldValue,
    setFieldTouched,
    errors,
  } = usePatientMetricsState()

  const { ft = '', inch = '' } =
    height == null ? {} : HeightConversion.metric.toUk(height)
  const [imperialFeet, setImperialFeet] = useState<string>(String(ft))
  const [imperialInch, setImperialInch] = useState(String(inch))
  const prevHeightFeet = useRef<string | number>(String(ft))

  const prevHeight = usePrevious<number | undefined>(height)

  useEffect(() => {
    if (prevHeight && height == null) {
      setImperialInch('')
      setImperialFeet('')
    }
  }, [height, prevHeight])

  const handleFeetChange = ({ target: { value } }: InputEvent) => {
    setImperialFeet(value)
  }

  const handleFeetDecimal = (value: string) => {
    const imperialHeight = HeightConversion.uk.normalizeFromFeet(
      parseFloat(value)
    )
    const feetString = String(imperialHeight.ft || '')
    setImperialFeet(feetString)
    setImperialInch(String(imperialHeight.inch || ''))
    setFieldValue('height', HeightConversion.uk.toMetric(imperialHeight))
    prevHeightFeet.current = feetString
  }

  const handleFeetBlur = ({ target: { value } }: InputEvent) => {
    if (value === '' && imperialInch === '') {
      setFieldValue('height', null)
      prevHeightFeet.current = value
      return
    }

    if (parseFloat(value) % 1) {
      handleFeetDecimal(value)
      return
    }

    setFieldValue(
      'height',
      HeightConversion.uk.toMetric({
        ft: parseInt(value),
        inch: parseFloat(imperialInch),
      })
    )
    setFieldTouched('height', true, false)
    if (value !== prevHeightFeet.current) {
      setImperialInch('')
    }
    prevHeightFeet.current = value
  }

  const handleInchChange = ({ target: { value } }: InputEvent) => {
    setImperialInch(value)
  }

  const handleInchBlur = ({ target: { value } }: InputEvent) => {
    if (value === '' && imperialFeet === '') {
      setFieldValue('height', null)
      return
    }
    const { ft, inch } = HeightConversion.uk.normalizeFromInch(
      parseFloat(value)
    )
    const newFt = (parseFloat(imperialFeet) || 0) + (ft || 0)
    setFieldValue('height', HeightConversion.uk.toMetric({ ft: newFt, inch }))
    setImperialFeet(String(newFt.toFixed(1) || ''))
    setImperialInch(String(inch?.toFixed(1) || ''))
    prevHeightFeet.current = String(newFt || '')
    setFieldTouched('height', true, false)
  }

  const displayImperialHeight = displayHeight
    ? HeightConversion.metric.toUk(displayHeight)
    : null

  const displayImperialHeightLabel =
    displayImperialHeight &&
    `${displayImperialHeight.ft} ${fm(
      messages.height_label_feet_abbreviation
    )}    ${displayImperialHeight.inch} ${fm(
      messages.height_label_inches_abbreviation
    )} `

  return (
    <>
      <BmiSectionHeader
        label={fm(messages.height_section_label)}
        htmlFor="height"
      >
        {displayImperialHeightLabel}
        <Timestamp
          performer={performerHeight}
          timestamp={timestamp}
          className={styles.timestamp}
        />
      </BmiSectionHeader>
      <Grid columns={5}>
        <Field name="height" validate={validateHeight(fm)}>
          {() => (
            <>
              <NumberInput
                label={fm(messages.height_label_feet)}
                id="imperial_height_feet"
                placeholder={fm(messages.height_placeholder_feet)}
                value={imperialFeet}
                onChange={handleFeetChange}
                onBlur={handleFeetBlur}
                autoComplete="off"
                errorMessage={errors.height}
                displayError={!!errors.height}
              />
              <NumberInput
                label={fm(messages.height_label_inches)}
                id="imperial_height_inches"
                placeholder={fm(messages.height_placeholder_inches)}
                value={imperialInch}
                onChange={handleInchChange}
                onBlur={handleInchBlur}
                autoComplete="off"
                displayError={!!errors.height}
              />
            </>
          )}
        </Field>
      </Grid>
    </>
  )
}

export default ImperialHeight
