import React, { FC } from 'react'

import { Tag, Text } from '@babylon/core-ui'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import {
  filterWorkflowsV2,
  getGroupsFromWorkflows,
} from '~/features/workflows/WorkflowActions/utils'
import {
  AppointmentInvitesQueryResult,
  TimelineConsultationActionsQueryResult,
} from '~/generated'

import styles from './styles.module.scss'
import messages from './TimelineFollowUpActions.messages'

type TimelineConsultation = NonNullable<
  TimelineConsultationActionsQueryResult['data']
>['consultation']

interface Props {
  workflows: NonNullable<TimelineConsultation>['workflowsV2']
  appointmentInvites: NonNullable<
    NonNullable<AppointmentInvitesQueryResult['data']>['consultation']
  >['appointmentInvites']
}

const TimelineFollowUpActions: FC<Props> = ({
  workflows,
  appointmentInvites,
}) => {
  const f = useMessages(messages)

  const hasAppointmentInvites = isFilledArray(appointmentInvites)
  const hasWorkflows = isFilledArray(workflows)
  const workflowGroups = workflows ? getGroupsFromWorkflows(workflows) : []

  return (
    <>
      {hasWorkflows &&
        workflowGroups.map((workflowGroup) => {
          const { type, label } = workflowGroup
          const workflowsInGroup = workflows
            ? filterWorkflowsV2(workflows, type)
            : []

          return (
            <div className={styles.workflowGroup} key={type}>
              <Text className={styles.completeTimelineActionLabel}>
                {f('workflow_label', { label })}
              </Text>
              {workflowsInGroup.map((workflow) => {
                return (
                  <Tag
                    key={workflow.id}
                    className={styles.followUpclinicalCode}
                  >
                    {workflow.workflowDefinition?.workflow_definition_name}
                  </Tag>
                )
              })}
            </div>
          )
        })}

      {hasAppointmentInvites && (
        <div className={styles.workflowGroup}>
          <Text className={styles.completeTimelineActionLabel}>
            {f('appointment_invites_label')}
          </Text>
          {appointmentInvites?.map(({ id, service_type: serviceType }) => (
            <Tag className={styles.followUpclinicalCode} key={id}>
              {serviceType?.name ?? f('no_service_type')}
            </Tag>
          ))}
        </div>
      )}
    </>
  )
}

export default TimelineFollowUpActions
