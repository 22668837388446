import { format } from 'date-fns'
import { ExecutionResult } from 'graphql'

import {
  AddActionDraftMutation,
  AddAndAssociateActionDraftMutation,
  AddGoalDraftMutation,
  AssociateGoalAndActionDraftMutation,
  CarePlanGoalActionAssociationDraft,
  DeleteActionDraftMutation,
  DeleteGoalDraftMutation,
  DissociateGoalAndActionDraftMutation,
  EditActionDraftMutation,
  EditGoalDraftMutation,
  useAddActionDraftMutation,
  useAddAndAssociateActionDraftMutation,
  useAddGoalDraftMutation,
  useAssociateGoalAndActionDraftMutation,
  useDeleteActionDraftMutation,
  useDeleteGoalDraftMutation,
  useDissociateGoalAndActionDraftMutation,
  useEditActionDraftMutation,
  useEditGoalDraftMutation,
} from '~/generated'

import updateCarePlanDraft from '../updateCarePlanDraft'

interface UseCarePlanDraftMutatorsProps {
  patientUuid: string
  consultationUuid: string
}

interface AddGoalDraftArgs {
  carePlanDraftId: string
  description: string
  numberOfEdits: number
}

interface EditGoalDraftArgs {
  carePlanDraftId: string
  goalDraftId: string
  description: string
  numberOfEdits: number
}

interface DeleteGoalDraftArgs {
  carePlanDraftId: string
  goalDraftId: string
  numberOfEdits: number
}

interface AddActionDraftArgs {
  carePlanDraftId: string
  description: string
  numberOfEdits: number
}

interface AssociateGoalAndActionDraftArgs {
  carePlanDraftId: string
  goalDraftId: string
  actionDraftId: string
  numberOfEdits: number
}

interface AddAndAssociateActionDraftArgs {
  carePlanDraftId: string
  description: string
  numberOfEdits: number
  goalDraftId: string
  actionDraftId: string
}

interface EditActionDraftArgs {
  carePlanDraftId: string
  actionDraftId: string
  description: string
  numberOfEdits: number
}

interface DissociateGoalAndActionDraftArgs {
  carePlanDraftId: string
  actionDraftId: string
  goalDraftId: string
  numberOfEdits: number
}

interface DeleteActionDraftArgs {
  carePlanDraftId: string
  actionDraftId: string
  numberOfEdits: number
}

export interface CarePlanDraftMutators {
  addGoalDraft: (
    input: AddGoalDraftArgs
  ) => Promise<ExecutionResult<AddGoalDraftMutation>>
  editGoalDraft: (
    input: EditGoalDraftArgs
  ) => Promise<ExecutionResult<EditGoalDraftMutation>>
  deleteGoalDraft: (
    input: DeleteGoalDraftArgs
  ) => Promise<ExecutionResult<DeleteGoalDraftMutation>>
  addActionDraft: (
    input: AddActionDraftArgs
  ) => Promise<ExecutionResult<AddActionDraftMutation>>
  associateGoalAndActionDraft: (
    input: AssociateGoalAndActionDraftArgs
  ) => Promise<ExecutionResult<AssociateGoalAndActionDraftMutation>>
  addAndAssociateActionDraft: (
    input: AddAndAssociateActionDraftArgs
  ) => Promise<ExecutionResult<AddAndAssociateActionDraftMutation>>
  editActionDraft: (
    input: EditActionDraftArgs
  ) => Promise<ExecutionResult<EditActionDraftMutation>>
  deleteActionDraft: (
    input: DeleteActionDraftArgs
  ) => Promise<ExecutionResult<DeleteActionDraftMutation>>
  dissociateGoalAndActionDraft: (
    input: DissociateGoalAndActionDraftArgs
  ) => Promise<ExecutionResult<DissociateGoalAndActionDraftMutation>>
}

const useCarePlanDraftMutators = ({
  patientUuid,
  consultationUuid,
}: UseCarePlanDraftMutatorsProps): CarePlanDraftMutators => {
  const variables = {
    patientId: patientUuid,
    consultationId: consultationUuid,
  }

  const [_addGoalDraft] = useAddGoalDraftMutation({
    update: updateCarePlanDraft(variables, (carePlanDraft, mutationResult) => {
      if (
        mutationResult.data?.addGoalDraft.__typename !== 'AddGoalDraftSuccess'
      ) {
        return carePlanDraft
      }

      return {
        ...carePlanDraft,
        goals: [
          ...carePlanDraft.goals,
          {
            ...mutationResult.data.addGoalDraft.goalDraft,
            associatedActionDrafts: [],
          },
        ],
        metadata:
          mutationResult.data.addGoalDraft.goalDraft.parentCarePlanDraft
            ?.metadata || carePlanDraft.metadata,
      }
    }),
  })

  const [_editGoalDraft] = useEditGoalDraftMutation({
    update: updateCarePlanDraft(variables, (carePlanDraft, mutationResult) => {
      if (
        mutationResult.data?.editGoalDraft.__typename !== 'EditGoalDraftSuccess'
      ) {
        return carePlanDraft
      }

      const updatedGoal = mutationResult.data.editGoalDraft.goalDraft

      return {
        ...carePlanDraft,
        goals: carePlanDraft.goals.map((existingGoal) => {
          if (existingGoal.draftId === updatedGoal.draftId) {
            return {
              ...updatedGoal,
              associatedActionDrafts: existingGoal.associatedActionDrafts,
            }
          }

          return existingGoal
        }),
        metadata:
          mutationResult.data.editGoalDraft.goalDraft.parentCarePlanDraft
            ?.metadata || carePlanDraft.metadata,
      }
    }),
  })

  const [_deleteGoalDraft] = useDeleteGoalDraftMutation()
  const [_addActionDraft] = useAddActionDraftMutation()
  const [
    _associateGoalAndActionDraft,
  ] = useAssociateGoalAndActionDraftMutation()
  const [_editActionDraft] = useEditActionDraftMutation({
    update: updateCarePlanDraft(variables, (carePlanDraft, mutationResult) => {
      if (
        mutationResult.data?.editActionDraft.__typename !==
        'EditActionDraftSuccess'
      ) {
        return carePlanDraft
      }

      const updatedActionDraft =
        mutationResult.data?.editActionDraft.actionDraft

      return {
        ...carePlanDraft,
        goals: carePlanDraft.goals.map((existingGoalDraft) => ({
          ...existingGoalDraft,
          associatedActionDrafts: existingGoalDraft.associatedActionDrafts?.map(
            (existingActionDraft) => {
              if (existingActionDraft.draftId === updatedActionDraft.draftId) {
                return { ...updatedActionDraft }
              }

              return existingActionDraft
            }
          ),
        })),
        metadata:
          mutationResult.data.editActionDraft.actionDraft.parentCarePlanDraft
            ?.metadata || carePlanDraft.metadata,
      }
    }),
  })

  const [
    _dissociateGoalAndActionDraft,
  ] = useDissociateGoalAndActionDraftMutation()
  const [_deleteActionDraft] = useDeleteActionDraftMutation({
    update: updateCarePlanDraft(variables, (carePlanDraft, mutationResult) => {
      if (
        mutationResult.data?.deleteActionDraft.__typename !==
        'DeleteActionDraftSuccess'
      ) {
        return carePlanDraft
      }

      return {
        ...carePlanDraft,
        metadata:
          mutationResult.data.deleteActionDraft.parentCarePlanDraft?.metadata ||
          carePlanDraft.metadata,
      }
    }),
  })
  const [_addAndAssociateActionDraft] = useAddAndAssociateActionDraftMutation({
    update: updateCarePlanDraft(variables, (carePlanDraft, mutationResult) => {
      if (
        mutationResult.data?.addActionDraft.__typename !==
          'AddActionDraftSuccess' ||
        mutationResult.data?.associateGoalAndActionDraft.__typename !==
          'AssociateGoalAndActionDraftSuccess'
      ) {
        return carePlanDraft
      }

      const goalActionAssociation: CarePlanGoalActionAssociationDraft = {
        __typename: 'CarePlanGoalActionAssociationDraft',
        goalDraftId:
          mutationResult.data.associateGoalAndActionDraft.goalDraft.draftId,
        actionDraftId:
          mutationResult.data.associateGoalAndActionDraft.actionDraft.draftId,
      }

      const updatedMetadata =
        mutationResult.data.associateGoalAndActionDraft.actionDraft
          .parentCarePlanDraft?.metadata

      const addedAction = mutationResult.data.addActionDraft.actionDraft

      return {
        ...carePlanDraft,
        goals: carePlanDraft.goals.map((goal) => {
          if (goal.draftId !== goalActionAssociation.goalDraftId) {
            return goal
          }

          const existingActionDrafts = goal.associatedActionDrafts || []

          return {
            ...goal,
            associatedActionDrafts: [...existingActionDrafts, addedAction],
          }
        }),
        metadata: updatedMetadata || carePlanDraft.metadata,
      }
    }),
  })

  const getCarePlanDraftContext = (
    carePlanDraftId: string,
    numberOfEdits: number
  ) => ({
    patientId: patientUuid,
    consultationId: consultationUuid,
    carePlanDraftId,
    numberOfEdits,
  })

  const addGoalDraft = ({
    carePlanDraftId,
    description,
    numberOfEdits,
  }: AddGoalDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = {
      headers: { 'Time-Zone-Offset': formattedTimeZoneOffset },
    }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _addGoalDraft({
      variables: {
        input: {
          description,
          carePlanDraftContext,
        },
      },
      context,
    })
  }

  const editGoalDraft = ({
    carePlanDraftId,
    goalDraftId,
    description,
    numberOfEdits,
  }: EditGoalDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _editGoalDraft({
      variables: {
        input: {
          carePlanDraftContext,
          goalDraftId,
          goalDataPatch: { description: { newValue: description } },
        },
      },
      context,
    })
  }

  const deleteGoalDraft = ({
    goalDraftId,
    carePlanDraftId,
    numberOfEdits,
  }: DeleteGoalDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _deleteGoalDraft({
      variables: {
        input: {
          carePlanDraftContext,
          goalDraftId,
        },
      },
      context,
      update: updateCarePlanDraft(
        variables,
        (carePlanDraft, mutationResult) => {
          if (
            mutationResult.data?.deleteGoalDraft.__typename !==
            'DeleteGoalDraftSuccess'
          ) {
            return carePlanDraft
          }

          return {
            ...carePlanDraft,
            goals: carePlanDraft.goals.filter(
              (goal) => goal.draftId !== goalDraftId
            ),
            metadata:
              mutationResult.data.deleteGoalDraft.parentCarePlanDraft
                ?.metadata || carePlanDraft.metadata,
          }
        }
      ),
    })
  }

  const addActionDraft = ({
    carePlanDraftId,
    description,
    numberOfEdits,
  }: AddActionDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _addActionDraft({
      variables: {
        input: {
          description,
          carePlanDraftContext,
        },
      },
      context,
    })
  }

  const associateGoalAndActionDraft = ({
    carePlanDraftId,
    goalDraftId,
    actionDraftId,
    numberOfEdits,
  }: AssociateGoalAndActionDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _associateGoalAndActionDraft({
      variables: {
        input: {
          goalDraftId,
          actionDraftId,
          carePlanDraftContext,
        },
      },
      context,
    })
  }

  const addAndAssociateActionDraft = ({
    carePlanDraftId,
    actionDraftId,
    goalDraftId,
    description,
    numberOfEdits,
  }: AddAndAssociateActionDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext1 = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    const carePlanDraftContext2 = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits + 1
    )

    return _addAndAssociateActionDraft({
      variables: {
        addActionInput: {
          actionDraftId,
          description,
          carePlanDraftContext: carePlanDraftContext1,
        },
        associateInput: {
          goalDraftId,
          actionDraftId,
          carePlanDraftContext: carePlanDraftContext2,
        },
      },
      context,
    })
  }

  const editActionDraft = ({
    carePlanDraftId,
    actionDraftId,
    description,
    numberOfEdits,
  }: EditActionDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _editActionDraft({
      variables: {
        input: {
          carePlanDraftContext,
          actionDraftId,
          actionDataPatch: { description: { newValue: description } },
        },
      },
      context,
    })
  }

  const dissociateGoalAndActionDraft = ({
    carePlanDraftId,
    goalDraftId,
    actionDraftId,
    numberOfEdits,
  }: DissociateGoalAndActionDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _dissociateGoalAndActionDraft({
      variables: {
        input: {
          carePlanDraftContext,
          actionDraftId,
          goalDraftId,
        },
      },
      context,
      update: updateCarePlanDraft(
        variables,
        (carePlanDraft, mutationResult) => {
          if (
            mutationResult.data?.dissociateGoalAndActionDraft.__typename !==
            'DissociateGoalAndActionDraftSuccess'
          ) {
            return carePlanDraft
          }

          return {
            ...carePlanDraft,
            goals: carePlanDraft.goals.map((goal) => {
              if (goal.draftId !== goalDraftId) {
                return goal
              }

              return {
                ...goal,
                associatedActionDrafts: goal.associatedActionDrafts?.filter(
                  (action) => action.draftId !== actionDraftId
                ),
              }
            }),
            metadata:
              mutationResult.data.dissociateGoalAndActionDraft.actionDraft
                ?.parentCarePlanDraft?.metadata || carePlanDraft.metadata,
          }
        }
      ),
    })
  }

  const deleteActionDraft = ({
    carePlanDraftId,
    actionDraftId,
    numberOfEdits,
  }: DeleteActionDraftArgs) => {
    const formattedTimeZoneOffset = format(new Date(), 'XXX')
    const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
    const carePlanDraftContext = getCarePlanDraftContext(
      carePlanDraftId,
      numberOfEdits
    )

    return _deleteActionDraft({
      variables: {
        input: {
          carePlanDraftContext,
          actionDraftId,
        },
      },
      context,
    })
  }

  return {
    addGoalDraft,
    editGoalDraft,
    deleteGoalDraft,
    addActionDraft,
    associateGoalAndActionDraft,
    addAndAssociateActionDraft,
    editActionDraft,
    dissociateGoalAndActionDraft,
    deleteActionDraft,
  }
}

export default useCarePlanDraftMutators
