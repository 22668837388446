/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '../graphql/federated-graph'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type DeleteDocumentMutationVariables = Types.Exact<{
  input: Types.DeleteDocumentInput
}>

export type DeleteDocumentMutation = { __typename?: 'Mutation' } & {
  deleteDocument?: Types.Maybe<
    | ({ __typename?: 'AuthorizationError' } & Pick<
        Types.AuthorizationError,
        'errorMessage'
      >)
    | ({ __typename?: 'DeleteDocumentResponse' } & Pick<
        Types.DeleteDocumentResponse,
        'isSuccess'
      >)
    | ({ __typename?: 'ResourceNotFoundError' } & Pick<
        Types.ResourceNotFoundError,
        'errorMessage'
      >)
  >
}

export type DocumentReferenceByPatientsByIdentifierQueryVariables = Types.Exact<{
  filter: Types.GetByIdentifierFilter
  pageSize: Types.Scalars['NonNegativeInt']
  documentSort?: Types.Maybe<Array<Types.Maybe<Types.DocumentReferenceSort>>>
  documentFilter?: Types.Maybe<Types.DocumentReferenceFilter>
  after?: Types.Maybe<Types.Scalars['Cursor']>
}>

export type DocumentReferenceByPatientsByIdentifierQuery = {
  __typename?: 'Query'
} & {
  patientsByIdentifier?: Types.Maybe<
    | { __typename?: 'AuthorizationError' }
    | ({ __typename?: 'PatientConnection' } & {
        nodes: Array<
          Types.Maybe<
            { __typename?: 'Patient' } & {
              documents?: Types.Maybe<
                { __typename?: 'DocumentReferenceConnection' } & Pick<
                  Types.DocumentReferenceConnection,
                  'totalCount'
                > & {
                    pageInfo: { __typename?: 'PageInfo' } & Pick<
                      Types.PageInfo,
                      | 'endCursor'
                      | 'hasNextPage'
                      | 'hasPreviousPage'
                      | 'startCursor'
                    >
                    nodes: Array<
                      Types.Maybe<
                        { __typename?: 'DocumentReference' } & Pick<
                          Types.DocumentReference,
                          'id' | 'date' | 'status'
                        > & {
                            contents?: Types.Maybe<
                              {
                                __typename?: 'DocumentReferenceContentConnection'
                              } & {
                                nodes: Array<
                                  Types.Maybe<
                                    {
                                      __typename?: 'DocumentReferenceContent'
                                    } & {
                                      attachment?: Types.Maybe<
                                        | ({ __typename?: 'Attachment' } & Pick<
                                            Types.Attachment,
                                            | 'attachmentId'
                                            | 'contentType'
                                            | 'title'
                                            | 'url'
                                          >)
                                        | { __typename?: 'None' }
                                      >
                                    }
                                  >
                                >
                              }
                            >
                          }
                      >
                    >
                  }
              >
            }
          >
        >
      })
  >
}

export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      ... on DeleteDocumentResponse {
        isSuccess
      }
      ... on AuthorizationError {
        errorMessage
      }
      ... on ResourceNotFoundError {
        errorMessage
      }
    }
  }
`
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, baseOptions)
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export const DocumentReferenceByPatientsByIdentifierDocument = gql`
  query DocumentReferenceByPatientsByIdentifier(
    $filter: GetByIdentifierFilter!
    $pageSize: NonNegativeInt!
    $documentSort: [DocumentReferenceSort]
    $documentFilter: DocumentReferenceFilter
    $after: Cursor
  ) {
    patientsByIdentifier(_filter: $filter, first: 1) {
      ... on PatientConnection {
        nodes {
          documents(
            first: $pageSize
            _sort: $documentSort
            _filter: $documentFilter
            after: $after
          ) {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            nodes {
              id
              date
              status
              contents(first: 1) {
                nodes {
                  attachment {
                    ... on Attachment {
                      attachmentId
                      contentType
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export function useDocumentReferenceByPatientsByIdentifierQuery(
  baseOptions: Apollo.QueryHookOptions<
    DocumentReferenceByPatientsByIdentifierQuery,
    DocumentReferenceByPatientsByIdentifierQueryVariables
  >
) {
  return Apollo.useQuery<
    DocumentReferenceByPatientsByIdentifierQuery,
    DocumentReferenceByPatientsByIdentifierQueryVariables
  >(DocumentReferenceByPatientsByIdentifierDocument, baseOptions)
}
export function useDocumentReferenceByPatientsByIdentifierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentReferenceByPatientsByIdentifierQuery,
    DocumentReferenceByPatientsByIdentifierQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    DocumentReferenceByPatientsByIdentifierQuery,
    DocumentReferenceByPatientsByIdentifierQueryVariables
  >(DocumentReferenceByPatientsByIdentifierDocument, baseOptions)
}
export type DocumentReferenceByPatientsByIdentifierQueryHookResult = ReturnType<
  typeof useDocumentReferenceByPatientsByIdentifierQuery
>
export type DocumentReferenceByPatientsByIdentifierLazyQueryHookResult = ReturnType<
  typeof useDocumentReferenceByPatientsByIdentifierLazyQuery
>
export type DocumentReferenceByPatientsByIdentifierQueryResult = Apollo.QueryResult<
  DocumentReferenceByPatientsByIdentifierQuery,
  DocumentReferenceByPatientsByIdentifierQueryVariables
>
