import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { ConsultationReferral } from '@babylon/babylon-user/src/types'
import { useFormatMessage } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { SpecialismsQueryResult, useRemoveReferralMutation } from '~/generated'
import DataList from '~/ui/DataList'

import { handleRemoveReferral } from './ReferralsHelpers'

import messages from './Referrals.messages'

interface ReferralDataListProps {
  referral: ConsultationReferral
  markFinalizedConsultationAsEdited: (() => void) | undefined
  restrictFinalizedConsultationEditing: boolean
  specialismCategories: NonNullable<
    SpecialismsQueryResult['data']
  >['specialismCategories']
}
export const ReferralDataList = ({
  referral,
  markFinalizedConsultationAsEdited,
  restrictFinalizedConsultationEditing,
  specialismCategories,
}: ReferralDataListProps): JSX.Element => {
  const fm = useFormatMessage()
  const history = useHistory()
  const match = useRouteMatch()
  const specialismCategoryId = referral?.specialismCategoryId as string
  const [removeReferralMutation] = useRemoveReferralMutation()

  const category = specialismCategories.find(
    (item) => item?.id === specialismCategoryId
  )

  const categoryName =
    category?.name ??
    fm(messages.category_id_name, { id: specialismCategoryId })
  const data = [
    {
      key: fm(messages.referrals_category_key),
      value: categoryName,
    },
    {
      key: fm(messages.referrals_specialism_key),
      value: referral.specialismName,
    },
  ]

  if (referral?.externalSpecialist) {
    const { name } = referral.externalSpecialist

    if (name) {
      data.push({
        key: fm(messages.referrals_specialist_key),
        value: name,
      })
    }
  }

  if (isFilledArray(referral?.comments)) {
    const notes = referral.comments.map((item: any) => item.note)
    const value = notes.join('\n').trim()

    if (value) {
      data.push({
        key: fm(messages.referrals_comments_key),
        value,
      })
    }
  }

  let consultationEditingAllowedProps = {}
  if (!restrictFinalizedConsultationEditing) {
    consultationEditingAllowedProps = {
      onUpdate: () => {
        history.replace(`${match.url}/referral/edit?id=${referral.id}`)
      },
      onRemove: () => {
        handleRemoveReferral({
          formatMessage: fm,
          referralId: referral.id,
          removeReferralMutation,
          markFinalizedConsultationAsEdited,
        })
      },
    }
  }

  return <DataList data={data} {...consultationEditingAllowedProps} />
}
