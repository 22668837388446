import { createPortal } from 'react-dom'

import { rootElement } from './utils'

import styles from './styles.module.scss'

const dialogRootElement = rootElement(styles.portal)
const DialogPortal = ({ children }) => createPortal(children, dialogRootElement)

export default DialogPortal
