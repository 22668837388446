import * as Sentry from '@sentry/browser'
import React from 'react'

import { PortalLoginProvider } from '@babylon/babylon-auth'

import { logException, logMessage } from '~/core/sentry'

interface AuthenticationProps {
  children: React.ReactNode
}

const handleAuthError = (error: Error) => {
  logException(error)
}

const handleAutoLogout = async (isUnexpectedLogout: boolean) => {
  if (isUnexpectedLogout) {
    logMessage('User was auto-logged out unexpectedly')
  }
  // Wait for pending Sentry events to be flushed before allowing Clinical Portal to shutdown
  await Sentry.close()
}

export const CoreRubyBasedAuthProvider = ({
  children,
}: AuthenticationProps) => {
  return (
    <PortalLoginProvider
      onError={handleAuthError}
      onAutoLogout={handleAutoLogout}
    >
      {children}
    </PortalLoginProvider>
  )
}
