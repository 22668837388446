import { defineMessages } from 'react-intl'

const baseId = 'google_form_dialog'

export default defineMessages({
  skip_button: {
    id: `${baseId}.skip_button`,
    defaultMessage: 'Skip',
  },
  close_button: {
    id: `${baseId}.close_button`,
    defaultMessage: 'Close',
  },
  form_loading: {
    id: `${baseId}.form_loading`,
    defaultMessage: 'Loading...',
  },
})
