import React from 'react'

import { Tag } from '@babylon/core-ui'

import useMessages from '~/core/hooks/useMessages'
import DataList from '~/ui/DataList'

import useTestTypes from '../../TestsSummary/useTestTypes'

import messages from './Test.messages'

interface TestProps {
  id: string
  type: string
  testId: string
  reason?: string
  onRemove: Function
  onUpdate: Function
  restrictFinalizedConsultationEditing?: boolean
}

const Test = ({
  id,
  type,
  testId,
  reason,
  onUpdate,
  onRemove,
  restrictFinalizedConsultationEditing,
}: TestProps) => {
  const format = useMessages(messages)
  const { getTestName } = useTestTypes()
  const conditionalHandlers = {
    onUpdate,
    onRemove,
  }

  return (
    <DataList
      key={id}
      data={[
        {
          key: format('test_name_key'),
          value: getTestName(type, testId),
        },
        {
          key: format('test_type_key'),
          value: <Tag color="clinical-blue">{type}</Tag>,
        },
        {
          key: format('test_reason_key'),
          value: reason,
          optional: true,
        },
      ]}
      {...(!restrictFinalizedConsultationEditing && conditionalHandlers)}
    />
  )
}

export default Test
