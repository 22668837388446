import React, { useState, useEffect } from 'react'
import { Button, Text, Textarea } from '@babylon/core-ui'
import cx from 'classnames'

import styles from '../styles.module.scss'

const isValidMessage = (message) => !message || message.length <= 1000

export default ({ standardMessage, submitting, onSubmit }) => {
  const [reviewerComments, setReviewerComments] = useState(null)
  const [showLengthError, setShowLengthError] = useState(false)

  const textAreaClass = cx(styles['report__decorated-textarea__input'], {
    [styles['report__decorated-textarea__error-border']]: showLengthError,
  })

  const handleButtonClick = () => {
    if (isValidMessage(reviewerComments)) {
      setShowLengthError(false)
      onSubmit({ reviewerComments })
    } else {
      setShowLengthError(true)
    }
  }

  useEffect(() => {
    if (isValidMessage(reviewerComments)) setShowLengthError(false)
  }, [reviewerComments])

  return (
    <div data-testid="reviewer-comments-form">
      <h3>Standard message that will be sent to the patient</h3>
      <Text italic>{standardMessage}</Text>
      <h3 className={styles.report__subheading}>GP Comments</h3>
      <div className={styles['report__decorated-textarea']}>
        <Textarea
          placeholder="Optional comment for the user on the results"
          className={textAreaClass}
          onChange={(event) => setReviewerComments(event.target.value)}
        />
        {showLengthError && (
          <Text
            color="error"
            className={styles['report__decorated-textarea__left']}
            size="small"
          >
            Maximum number of characters reached
          </Text>
        )}
        <Text
          color={showLengthError ? 'error' : 'grey-type'}
          size="small"
          className={styles['report__decorated-textarea__right']}
        >
          ({(reviewerComments || '').length}/1000)
        </Text>
      </div>
      <Button
        intent="primary"
        loading={submitting}
        disabled={submitting}
        onClick={handleButtonClick}
        className={styles['report__submit-button']}
      >
        Send to patient
      </Button>
    </div>
  )
}
