import { defineMessages } from 'react-intl'

const baseId = 'workflow_definition_modal'

export default defineMessages({
  start_new_workflow: {
    id: `${baseId}.start_new_workflow`,
    defaultMessage: 'Start New Workflow',
  },
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: "We can't load this section right now",
  },
  reload_data: {
    id: `${baseId}.reload_data`,
    defaultMessage: 'Reload Data',
  },
  type: {
    id: `${baseId}.type`,
    defaultMessage: 'Type',
  },
})
