import parser from 'accept-language-parser'
import React from 'react'
import { useIntl } from 'react-intl'

import { useMessages } from '~/core/hooks'
import { setLocale } from '~/core/i18n'
import languages from '~/core/i18n/languages'
import { DropdownLink } from '~/ui/Dropdown'

import { faGlobe, faLanguage } from './icons'

import messages from '../Profile.messages'

interface DropdownLinkLanguageProps {
  languagesAvailable: string | undefined
}

const DropdownLinkLanguage = ({
  languagesAvailable,
}: DropdownLinkLanguageProps) => {
  const { locale, formatMessage: fm } = useIntl()
  const f = useMessages(messages)

  const matchedLanguages =
    languagesAvailable &&
    languages().filter((v) => parser.pick([v.code], languagesAvailable))

  if (!matchedLanguages || matchedLanguages.length < 2) {
    return null
  }

  return (
    <DropdownLink title={f('language')} icon={faLanguage}>
      {matchedLanguages.map((language) => (
        <DropdownLink
          key={language.code}
          icon={faGlobe}
          active={language.code === locale}
          title={fm(language.name)}
          onClick={() => {
            setLocale(language.code)
          }}
        />
      ))}
    </DropdownLink>
  )
}

export default DropdownLinkLanguage
