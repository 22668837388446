import cn from 'classnames'
import React, { useState } from 'react'

import { Button, Grid, Heading, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import Modal from '../Modal'
import { ModalContext } from '../Modal/Modal'

import messages from './Dialog.messages'
import styles from './styles.module.scss'

export interface DialogProps {
  title?: React.ReactNode
  ok?: boolean
  okIcon?: React.ReactNode
  okLabel?: React.ReactNode
  okLoading?: boolean
  okError?: boolean
  type?: 'primary' | 'success' | 'warning' | 'error'
  onOk?: (context: ModalContext) => void | Promise<void>
  cancel?: boolean
  cancelIcon?: React.ReactNode
  cancelLabel?: React.ReactNode
  onCancel?: Function
  onOpen?: Function
  onClose?: Function
  async?: boolean // deprecated
  error?: string
  className?: string
  closeOnBackgroundClick?: boolean
  children: React.ReactNode | ((context: ModalContext) => React.ReactNode)
  testId?: string
}

const isString = (value: any): value is String => {
  return typeof value === 'string'
}

interface DialogTitleProps {
  value: React.ReactNode
}

const Title = ({ value }: DialogTitleProps) => {
  if (!value) {
    return null
  }

  if (isString(value)) {
    return <Heading level="h3">{value}</Heading>
  }

  return <div>{value}</div>
}

const Dialog = ({
  cancel,
  cancelIcon,
  cancelLabel,
  children,
  className,
  closeOnBackgroundClick,
  error,
  ok,
  okIcon,
  okLabel,
  okLoading = false,
  okError = false,
  onCancel,
  onClose,
  onOk,
  onOpen,
  title,
  type = 'primary',
  testId,
}: DialogProps) => {
  const [visible, setVisible] = useState(true)

  const f = useMessages(messages)

  return (
    <Modal
      visible={visible}
      onVisibleChange={setVisible}
      transition="dialog-transition"
      className={cn(styles.dialogComponent, className)}
      onTransitionEntered={onOpen}
      onTransitionExited={onClose}
      closeOnBackgroundClick={closeOnBackgroundClick}
      testId={testId}
    >
      {(modal: ModalContext) => {
        const handleOkClick = () => {
          if (onOk) {
            onOk(modal)
          }
        }

        const handleCancelClick = () => {
          if (onCancel) {
            onCancel()
          }
          modal.closeModal()
        }

        const hasFooter = ok || cancel || error

        const content: React.ReactNode =
          typeof children === 'function' ? children(modal) : children

        return (
          <Grid gap={16} dataTestId="dialog-grid">
            <Title value={title} />
            {content && <div>{content}</div>}
            {hasFooter && (
              <div className={styles.footer}>
                {error && <Text color="error">{error}</Text>}
                <div className={styles.controls}>
                  {cancel && (
                    <Button
                      intent="secondary"
                      icon={cancelIcon}
                      className={styles.button}
                      onClick={handleCancelClick}
                    >
                      {cancelLabel || f('cancel_label')}
                    </Button>
                  )}
                  {ok && (
                    <Button
                      intent={type}
                      icon={okIcon}
                      className={styles.button}
                      loading={okLoading}
                      onClick={handleOkClick}
                      disabled={okError}
                    >
                      {okLabel || f('ok_label')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Grid>
        )
      }}
    </Modal>
  )
}

export default Dialog
