import { subHours } from 'date-fns'

import {
  ConsultationStatus,
  useIncompleteConsultationsQuery,
} from '~/generated'

interface UseRecentIncompleteConsultations {
  scheduledTime: string
  patientId?: string
  consultationStatus: string
  consultationId: string
}

const useHasRecentIncompleteConsultationsQuery = ({
  scheduledTime,
  patientId,
  consultationStatus,
  consultationId,
}: UseRecentIncompleteConsultations) => {
  const searchStartDate = subHours(new Date(scheduledTime), 24)

  const searchCriteria = {
    dateRange: {
      endDate: new Date(scheduledTime).toISOString(),
      startDate: searchStartDate.toISOString(),
    },
    statuses: [ConsultationStatus.Confirmed],
    patientSearchCriteria: { id: patientId },
  }

  const skip =
    !patientId ||
    consultationStatus !== ConsultationStatus.Paid ||
    searchStartDate > new Date()

  const { data } = useIncompleteConsultationsQuery({
    variables: {
      searchCriteria,
    },
    skip,
  })

  const recentIncompleteConsultations = data?.consultationsv2.items ?? []

  return Boolean(
    recentIncompleteConsultations.some(
      (item) => item?.id && item.id !== consultationId
    )
  )
}

export default useHasRecentIncompleteConsultationsQuery
