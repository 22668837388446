import { FetchResult, MutationUpdaterFn } from '@apollo/client'

import {
  CarePlanDraftDocument,
  CarePlanDraftQuery,
  CarePlanDraftQueryVariables,
} from '~/generated'

const updateCarePlanDraft = <TMutation>(
  variables: {
    patientId: string
    consultationId: string
  },
  updater: (
    carePlanDraft: Extract<
      CarePlanDraftQuery['carePlanDraft'],
      { __typename: 'CarePlanDraft' }
    >,
    mutationResult: FetchResult<
      TMutation,
      Record<string, any>,
      Record<string, any>
    >
  ) => CarePlanDraftQuery['carePlanDraft']
): MutationUpdaterFn<TMutation> => (cache, mutationResult) => {
  const cachedCarePlanDraftQueryResult = cache.readQuery<
    CarePlanDraftQuery,
    CarePlanDraftQueryVariables
  >({
    query: CarePlanDraftDocument,
    variables,
  })

  if (
    cachedCarePlanDraftQueryResult?.carePlanDraft.__typename !== 'CarePlanDraft'
  ) {
    throw new Error(
      `Cannot update a non-existant carePlanDraft. Current __typename is: ${cachedCarePlanDraftQueryResult?.carePlanDraft.__typename}`
    )
  }

  cache.writeQuery({
    query: CarePlanDraftDocument,
    variables,
    data: {
      ...cachedCarePlanDraftQueryResult,
      carePlanDraft: updater(
        cachedCarePlanDraftQueryResult.carePlanDraft,
        mutationResult
      ),
    },
  })
}

export default updateCarePlanDraft
