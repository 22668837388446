import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import { Snackbar } from '@babylon/core-ui'

import Report from './components/Report/index'
import ReportList from './components/ReportList/index'
import { Tab, TabGroup } from './components/Tab'

import { scrollToTopOfLayout } from './utils'

import styles from './components/styles.module.scss'

const SNACKBAR_MESSAGE = 'The test was successfully updated.'

class TestsAndKits extends React.Component {
  constructor() {
    super()
    this.state = {
      activeTab: 'pending',
      snackbarOpen: false,
    }
  }

  toggleSnackbar = (open) => {
    this.setState({ snackbarOpen: !!open })
  }

  onTabChange = (e) => {
    this.setState({ activeTab: e.activeTabName })
    this.props.history.push('/tests')
  }

  onCloseReport = (options) => {
    this.props.history.push('/tests')
    this.toggleSnackbar(options.snackbar)
    scrollToTopOfLayout()
  }

  render() {
    const { activeTab } = this.state

    return (
      <div className={styles['tests-and-kits']}>
        <h1>Private Tests</h1>

        <TabGroup onTabChange={this.onTabChange} default="pending">
          <Tab name="pending" title="To be reviewed" />
          <Tab name="reviewed" title="Reviewed" />
          <Tab name="errored" title="ClinOps" />
        </TabGroup>

        <Switch>
          <Route
            path="/tests/:id"
            render={(props) => (
              <Report {...props} onCloseReport={this.onCloseReport} />
            )}
          />
          <Route
            path="/tests"
            render={(props) => <ReportList {...props} activeTab={activeTab} />}
          />
          <Redirect to="/tests" />
        </Switch>

        <Snackbar
          intent="success"
          message={SNACKBAR_MESSAGE}
          autoHideDuration={3000}
          open={this.state.snackbarOpen}
          onClose={() => this.toggleSnackbar(false)}
        />
      </div>
    )
  }
}

export default withRouter(TestsAndKits)
