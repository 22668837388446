import { InlineJSON, TextJSON } from 'slate'

export interface ClinicalCodeDataJSON {
  term: string
  code: string
}

export interface ClinicalCodeJSON extends InlineJSON {
  type: 'clinical_code'
  data: ClinicalCodeDataJSON
  nodes?: TextJSON[]
}

const createClinicalCodeNode = (
  text: string,
  term: string,
  code: string
): ClinicalCodeJSON => ({
  object: 'inline',
  type: 'clinical_code',
  data: {
    term,
    code,
  },
  nodes: [
    {
      object: 'text',
      leaves: [
        {
          object: 'leaf',
          text,
          marks: [],
        },
      ],
    },
  ],
})

export default createClinicalCodeNode
