import { gql } from '@apollo/client'

export const PATIENT_METRICS_FRAGMENT = gql`
  fragment PatientMetrics on Consultation {
    id
    uuid: consultationId
    consultant {
      id
      uuid
      role
    }
    consultationType
    patient {
      id
      uuid
      age @client
      date_of_birth
      patient_metrics(
        filter: $patientMetricsFilter
        count: $patientMetricsCount
      ) {
        id
        height {
          id
          value
          timestamp
          performer
        }
        weight {
          id
          value
          timestamp
          performer
        }
        smoking_status {
          id
          value
          timestamp
          performer
        }
        blood_pressure @include(if: $bloodPressureOn) {
          id
          values {
            code
            value
          }
          performer
          timestamp
        }
      }
    }
  }
`
