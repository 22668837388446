import { TestTypesQueryResult, useTestTypesQuery } from '~/generated'

type TestTypeMap = {
  pathology: NonNullable<TestTypesQueryResult['data']>['pathologyTestTypes']
  diagnostic: NonNullable<TestTypesQueryResult['data']>['diagnosticTestTypes']
}

const useTestTypes = () => {
  const { data } = useTestTypesQuery({
    fetchPolicy: 'cache-first',
  })

  const testTypes: TestTypeMap = {
    pathology: data?.pathologyTestTypes ?? [],
    diagnostic: data?.diagnosticTestTypes ?? [],
  }

  const getTestName = (type: string, id: string) => {
    if (type === 'pathology' || type === 'diagnostic') {
      const types = testTypes[type]
      const test = types.find((v) => v?.testType?.toString() === id.toString())

      return test?.name ?? null
    }

    return null
  }

  return {
    testTypes,
    getTestName,
  }
}

export default useTestTypes
