import { defineMessages } from 'react-intl'

const baseId = 'document_repository'

export default defineMessages({
  cancel_button: {
    id: `${baseId}.cancel_button`,
    defaultMessage: 'Cancel',
  },
  confirm_button: {
    id: `${baseId}.confirm_button`,
    defaultMessage: 'Confirm',
  },
  drag_and_drop: {
    id: `${baseId}.drag_and_drop`,
    defaultMessage: 'Drag and drop your files here or click to browse files',
  },
  drag_and_drop_invalid_filetype: {
    id: `${baseId}.drag_and_drop_invalid_filetype`,
    defaultMessage:
      'Invalid file type was detected, please select a valid file type (PDF or image file)',
  },
  upload_in_progress: {
    id: `${baseId}.upload_in_progress`,
    defaultMessage: 'Upload in progress...',
  },
  upload_success: {
    id: `${baseId}.upload_success`,
    defaultMessage: 'Document was successfully uploaded.',
  },
  upload_error: {
    id: `${baseId}.upload_error`,
    defaultMessage:
      'An unexpected error has occurred during the upload. Please try again.',
  },
  upload_confirm_message: {
    id: `${baseId}.upload_confirm_message`,
    defaultMessage:
      'Proceed with upload of file: {fileName} for patient{patientName}?  {confirmButton} {cancelButton}',
  },
})
