import { gql } from '@apollo/client'

export const UpdateGpDetailsMutation = gql`
  mutation updateGpInAppointmentMutation(
    $appointmentId: Int!
    $formData: GPInformation!
  ) {
    updateGpInAppointment(appointmentId: $appointmentId, formData: $formData) {
      id
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
      gp_full_address
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
    }
  }
`

export const UpdateAppointmentMutation = gql`
  mutation updateAppointmentMutation($id: ID!, $gp_consent: Boolean!) {
    updateAppointmentGpConsent(id: $id, gp_consent: $gp_consent) {
      id
      gp_consent
    }
  }
`
