import React from 'react'

import Pill from '~/ui/Pill'

export type DiagnosisCodeProps = {
  onClick: Function
  iri: string
  children: React.ReactNode
  hasNotes: boolean
}

const DiagnosisCode: React.FC<DiagnosisCodeProps> = ({
  iri,
  hasNotes,
  onClick,
  children,
}) => (
  <Pill
    value={children}
    type="code"
    icon={hasNotes ? 'far fa-sticky-note' : ''}
    onClick={() => onClick(iri)}
    dataTestId="selected-clinical-code"
  />
)

export default DiagnosisCode
