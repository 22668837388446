import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.gp_details'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'GP details',
  },
  update_button_label: {
    id: `${baseId}.update_button_label_v2`,
    defaultMessage: 'Update GP details',
  },
  gp_name_label: {
    id: `${baseId}.gp_name_label`,
    defaultMessage: 'GP name',
  },
  address_label: {
    id: `${baseId}.address_label`,
    defaultMessage: 'Address',
  },
  phone_number_label: {
    id: `${baseId}.phone_number_label_v2`,
    defaultMessage: 'Phone number',
  },
  consent_to_share_with_gp_given: {
    id: `${baseId}.consent_to_share_with_gp_given`,
    defaultMessage: 'Consent to share with GP given?',
  },
  gp_details_warning_title: {
    id: `${baseId}.gp_details_warning_title`,
    defaultMessage: 'Please complete GP details',
  },
  yes: {
    id: `${baseId}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${baseId}.no`,
    defaultMessage: 'No',
  },
  show_more_message: {
    id: `${baseId}.show_more_message`,
    defaultMessage: 'Show GP details',
  },
  error_updating_gp_consent: {
    id: `${baseId}.error_updating_gp_consent`,
    defaultMessage: 'Error updating GP consent',
  },
})
