const permissions = {
  prescriptions: [
    'nurse',
    'gp',
    'specialist',
    'prescribing_pharmacist',
    'hca_clinician',
    'phlebotomist',
    'super_consultant',
  ],
  consultations: [
    'senior_nurse',
    'nurse',
    'gp',
    'therapist',
    'dietitian',
    'specialist',
    'prescribing_pharmacist',
    'hca_clinician',
    'phlebotomist',
    'care_coordinator',
    'super_consultant',
  ],
  privateTests: ['super_consultant'],
  admin: ['super_consultant'],
}

const modulePermissions = Object.entries(permissions).reduce(
  (modulePermissions, [moduleName, permissionList]) => {
    permissionList.forEach((role) => {
      modulePermissions[`${moduleName}:${role}`] = true
    })
    return modulePermissions
  },
  {} as Record<string, boolean>
)

export default modulePermissions
