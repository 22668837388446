import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review'

export default defineMessages({
  tests_section_title_private: {
    id: `${baseId}.tests_section_title_private_v2`,
    defaultMessage: 'Private tests',
  },
  tests_table_name_heading: {
    id: `${baseId}.tests_table_name_heading`,
    defaultMessage: 'Name',
  },
  tests_table_type_heading: {
    id: `${baseId}.tests_table_type_heading`,
    defaultMessage: 'Type',
  },
  tests_table_reason_heading: {
    id: `${baseId}.tests_table_reason_heading`,
    defaultMessage: 'Reason',
  },
  error_loading_tests: {
    id: `${baseId}.error_loading_tests`,
    defaultMessage: 'Error loading the tests',
  },
})
