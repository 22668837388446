import { format } from 'date-fns'
import React from 'react'

import { Grid, Tag, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  RELEASE_NOTES_MODAL,
  RELEASE_NOTES_MODAL_CATEGORY,
  RELEASE_NOTES_MODAL_VIEWING_DURATION_ACTION,
} from '~/constants/analytics'
import { useModalViewAndDurationTracker } from '~/core/analytics'
import sanitize from '~/core/sanitize'
import { ErrorPanelView } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'

import { ReleaseNote } from '../useRelevantReleaseNotes'

import messages from './ReleaseNotesModal.messages'
import styles from './styles.module.scss'

interface NoteProps extends Omit<ReleaseNote, 'id' | 'countryCode' | 'title'> {
  latest: boolean
}

const Note = ({ releaseDate, versionNumber, changes, latest }: NoteProps) => {
  const fm = useFormatMessage()
  const date = new Date(releaseDate)
  const formattedDate = format(date, 'dd MMMM yyyy')
  const versionMessage = fm(messages.version_message_v2, {
    version: versionNumber,
    date: formattedDate,
  })

  return (
    <div className={styles.releaseNote} data-testid="release-notes">
      <Text size="small" color="grey-type" className={styles.releaseTitle}>
        {versionMessage}
        {latest && (
          <Tag style={{ marginLeft: '10px' }} color="confirmation-green">
            {fm(messages.latest_tag)}
          </Tag>
        )}
      </Text>
      <Text>
        <div
          className={styles.noteDetails}
          dangerouslySetInnerHTML={{ __html: sanitize(changes) }}
        />
      </Text>
    </div>
  )
}

interface ReleaseNotesModalProps {
  notes: ReleaseNote[]
  error?: Error
  loading: boolean
  onClose: () => void
}

const ReleaseNotesModal = ({
  notes = [],
  error,
  loading,
  onClose,
}: ReleaseNotesModalProps) => {
  const fm = useFormatMessage()

  useModalViewAndDurationTracker({
    category: RELEASE_NOTES_MODAL_CATEGORY,
    action: RELEASE_NOTES_MODAL_VIEWING_DURATION_ACTION,
    modalName: RELEASE_NOTES_MODAL,
  })

  return (
    <Slideout
      title={fm(messages.modal_title)}
      onClose={onClose}
      loading={loading}
    >
      {error ? (
        <ErrorPanelView
          title={fm(messages.error_title)}
          description={fm(messages.error_description)}
        />
      ) : (
        <Grid columns={1} className={styles.releaseContainer}>
          {notes.length ? (
            notes.map((note, i) => {
              const { id, releaseDate, versionNumber, changes } = note

              return (
                <Note
                  key={id}
                  releaseDate={releaseDate}
                  versionNumber={versionNumber}
                  changes={changes}
                  latest={i === 0}
                />
              )
            })
          ) : (
            <div>{fm(messages.no_release_notes)}</div>
          )}
        </Grid>
      )}
    </Slideout>
  )
}

export default ReleaseNotesModal
