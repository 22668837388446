import { defineMessages } from 'react-intl'

const baseId = 'mandatory_diagnostic_codes_model'

export default defineMessages({
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: 'The diagnosis must include a clinical code',
  },
})
