import React from 'react'
import { Route, useHistory } from 'react-router'

import styles from './styles.module.scss'

const HeaderBackButton = () => {
  const history = useHistory()
  /**
   * If the route was reached through the 'PUSH' action then
   * go back, if not go to the consultations route
   *
   * PUSH action:
   * -----------
   * The link was pushed to the react router history instead of
   * taken from the url which happens on page load
   */
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    if (history.action === 'PUSH') {
      return history.goBack()
    }

    return history.push('/consultations')
  }

  return (
    <Route
      path="/consultation/:id"
      render={() => (
        <div>
          <button
            type="button"
            className={styles.headerButton}
            onClick={onClick}
          >
            <i className="fas fa-chevron-left" />
          </button>
        </div>
      )}
    />
  )
}

export default HeaderBackButton
