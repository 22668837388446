import { defineMessages } from 'react-intl'

const baseId = 'consultation.complete_dialog.signature_rx_pin_extension'

export default defineMessages({
  signature_rx_description_block: {
    id: `${baseId}.signature_rx_description_block`,
    defaultMessage:
      'Sign the prescription and complete the consultation. On completion, the patient will get a copy of your notes.',
  },
  signature_rx_prescriptions_title: {
    id: `${baseId}.signature_rx_prescriptions_title`,
    defaultMessage: 'What you prescribed:',
  },
  signature_rx_password_label: {
    id: `${baseId}.signature_rx_password_label`,
    defaultMessage: 'Enter your pin code to sign the prescription',
  },
  signature_rx_drug_table_label: {
    id: `${baseId}.signature_rx_drug_table_label`,
    defaultMessage: 'What you prescribed:',
  },
  signature_rx_password_placeholder: {
    id: `${baseId}.signature_rx_password_placeholder`,
    defaultMessage: 'Your 6 digits pin code',
  },
  signature_rx_pin_validation_disabled_notice: {
    id: `${baseId}.signature_rx_pin_validation_disabled_notice`,
    defaultMessage: 'Note: Pin validation has been disabled in non prod envs.',
  },
  signature_rx_password_required_error: {
    id: `${baseId}.signature_rx_password_required_error`,
    defaultMessage: 'This field is mandatory',
  },
  signature_rx_password_forgot_question: {
    id: `${baseId}.signature_rx_password_forgot_question`,
    defaultMessage: 'Forgot your pin code?',
  },
  signature_rx_password_resend_pin: {
    id: `${baseId}.signature_rx_password_resend_pin`,
    defaultMessage: 'Reset and send new pin code via email.',
  },
  signature_rx_password_resend_pin_loading: {
    id: `${baseId}.signature_rx_password_resend_pin_loading`,
    defaultMessage: 'Resetting pin...',
  },
  signature_rx_password_resend_pin_success: {
    id: `${baseId}.signature_rx_password_resend_pin_success`,
    defaultMessage: 'Pin reset successful. Email has been sent.',
  },
  signature_rx_password_resend_pin_failure: {
    id: `${baseId}.signature_rx_password_resend_pin_failure`,
    defaultMessage: 'Pin reset failed.',
  },
})
