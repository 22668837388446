import React from 'react'

import { Spinner } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import {
  CarePlanDraftQuery,
  CarePlanDraftQueryResult,
  CarePlanQueryResult,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import CarePlanModalForm from './CarePlanModalForm/CarePlanModalForm'
import useCarePlanDraftMutators from './useCarePlanDraftMutators'

import messages from './CarePlanModal.messages'

interface CarePlanModalProps {
  consultationUuid: string
  patientUuid: string
  carePlanQueryResult: CarePlanQueryResult
  carePlanDraftQueryResult: CarePlanDraftQueryResult
  carePlanDraft?: Extract<
    CarePlanDraftQuery['carePlanDraft'],
    { __typename: 'CarePlanDraft' }
  >
}

const CarePlanModal = ({
  consultationUuid,
  patientUuid,
  carePlanQueryResult,
  carePlanDraftQueryResult,
  carePlanDraft,
}: CarePlanModalProps) => {
  const f = useMessages(messages)

  const carePlanDraftMutators = useCarePlanDraftMutators({
    patientUuid,
    consultationUuid,
  })

  if (carePlanDraftQueryResult.error) {
    return (
      <ErrorPanel
        error={carePlanDraftQueryResult.error}
        fill="container"
        center
        retry={() => carePlanDraftQueryResult.refetch()}
        title={f('cant_load_care_plan_draft')}
      />
    )
  }

  if (
    carePlanDraftQueryResult.loading ||
    carePlanQueryResult.loading ||
    !carePlanDraft
  ) {
    return <Spinner testid="spinner" centered size="medium" />
  }

  return (
    <>
      <CarePlanModalForm
        carePlanDraft={carePlanDraft}
        refetchCarePlanDraft={carePlanDraftQueryResult.refetch}
        carePlanDraftMutators={carePlanDraftMutators}
      />
    </>
  )
}

export default CarePlanModal
