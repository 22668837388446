import React from 'react'

import { Button, Grid } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import messages from './GoogleFormDialog.messages'
import styles from './styles.module.scss'

type GoogleFormDialogViewType = {
  link: string
  onClose: () => void
  onComplete: (type: string) => void
}

export const GoogleFormDialogView = ({
  link,
  onClose,
  onComplete,
}: GoogleFormDialogViewType) => {
  const f = useMessages(messages)

  return (
    <Dialog onClose={onClose} className={styles.dialog}>
      <Grid
        flow="column"
        justifyContent="space-between"
        rowGap="4"
        className={styles.buttons}
      >
        <Button
          intent="secondary"
          onClick={() => onComplete('skip')}
          className={styles.button}
        >
          {f('skip_button')}
        </Button>
        <div
          data-testid="close_button"
          className={styles.closeButton}
          onKeyPress={() => onComplete('complete')}
          role="button"
          title={f('close_button')}
          tabIndex={0}
          onClick={() => onComplete('complete')}
        >
          <i className="fas fa-times" />
        </div>
      </Grid>
      <iframe
        title="Google Form"
        src={link}
        width="460"
        height="430"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
      >
        {f('form_loading')}
      </iframe>
    </Dialog>
  )
}
