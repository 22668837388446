import React, { ReactNode } from 'react'
import { Field, FieldProps } from 'formik'
import Select, { ReactSelectProps, Option, OptionValues } from 'react-select'
import 'react-select/dist/react-select.css'
import get from 'lodash/fp/get'

import FormField from '../FormField'

export type LegacyFormikDropdownOption =
  | Option<OptionValues>
  | { [key: string]: string }

export type LegacyFormikDropdownOptionValues = OptionValues

export interface LegacyFormikDropdownProps extends ReactSelectProps {
  name: string
  options: LegacyFormikDropdownOption[]
  label?: ReactNode
  validate?: (prop: any) => string | null
  outerClassName?: string
  'data-testid'?: string
}

const LegacyFormikDropdown = ({
  id,
  clearable = false,
  searchable = false,
  multi = false,
  name,
  label,
  options,
  valueKey = 'value',
  validate,
  onChange,
  outerClassName,
  'data-testid': dataTestId,
  ...rest
}: LegacyFormikDropdownProps) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'DEPRECATED: Please use FormikSelect instead - migration notes https://react-select.com/upgrade-guide'
    )
  }

  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { errors, setFieldValue, touched },
      }: FieldProps) => {
        const fieldName = get(name)
        const displayError = fieldName(touched) && !!fieldName(errors)
        const errorMessage = fieldName(errors)

        return (
          <FormField
            id={id}
            label={label}
            errorMessage={errorMessage}
            displayError={displayError}
            data-testid={dataTestId}
            className={outerClassName}
          >
            <Select
              id={id}
              name={name}
              value={value}
              clearable={clearable}
              searchable={searchable}
              multi={multi}
              options={options}
              valueKey={valueKey}
              onChange={(option: LegacyFormikDropdownOption | null) => {
                if (name) {
                  setFieldValue(name, option?.[valueKey] ?? null)
                }

                if (onChange) {
                  onChange(option)
                }
              }}
              {...rest}
            />
          </FormField>
        )
      }}
    </Field>
  )
}

export default LegacyFormikDropdown
