import React from 'react'

import { Text } from '@babylon/core-ui'

import {
  PRE_CALL_CHECK,
  PRE_CALL_CHECK_CONTACT_SUPPORT,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useGlobals } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import { getClearCacheLink } from './utils'

import messages from './PreCallCheckTroubleshootingDialog.messages'
import styles from './styles.module.scss'

interface PreCallCheckTroubleshootingDialogProps {
  onClose: () => void
}

const PreCallCheckTroubleshootingDialog = ({
  onClose,
}: PreCallCheckTroubleshootingDialogProps) => {
  const f = useMessages(messages)

  const clearCacheLink = getClearCacheLink()
  const contactSupportLink = useGlobals().support?.itServiceDeskUrl

  const handleSupportClick = () => {
    analytics.trackEvent({
      category: PRE_CALL_CHECK,
      action: PRE_CALL_CHECK_CONTACT_SUPPORT,
    })
  }

  return (
    <Dialog
      title={f('title')}
      onClose={onClose}
      cancel
      cancelLabel={f('close_button')}
    >
      <Text>
        <ul className={styles.checklist}>
          {f('checklist', {
            li: (str) => <li key={str.toString()}>{str}</li>,
            a: (str) => (
              <a
                key={str.toString()}
                href={clearCacheLink}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {str}
              </a>
            ),
          })}
        </ul>
      </Text>
      {contactSupportLink && (
        <Text>
          {f('contact_support', {
            a: (str) => (
              <a
                key={str.toString()}
                href={contactSupportLink}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                onClick={handleSupportClick}
              >
                {str}
              </a>
            ),
          })}
        </Text>
      )}
    </Dialog>
  )
}

export default PreCallCheckTroubleshootingDialog
