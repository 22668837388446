import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Icon from '~/ui/Icon'

import styles from './styles.module.scss'

const Input = ({
  disabled,
  icon,
  iconClassName,
  buttonLabel,
  buttonClassName,
  onButtonClick,
  tip,
  tipClassName,
  defaultValue,
  value,
  inputClassName,
  wrapperClassName,
  placeholder,
  onChange,
  inputRef,
  ...rest
}) => {
  return (
    <div className={styles.componentWrapper}>
      <div className={classnames(styles.inputWrapper, wrapperClassName)}>
        {icon && (
          <Icon
            className={classnames(styles.icon, iconClassName)}
            name={icon}
          />
        )}
        <input
          className={classnames(styles.input, inputClassName)}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          ref={inputRef}
          {...rest}
        />
        {buttonLabel && (
          <button
            disabled={disabled}
            className={classnames(styles.button, buttonClassName)}
            onClick={onButtonClick}
          >
            {buttonLabel}
          </button>
        )}
      </div>
      {!!tip && (
        <div className={classnames(styles.tip, tipClassName)}>{tip}</div>
      )}
    </div>
  )
}

Input.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  iconClassName: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  tipClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  inputClassName: PropTypes.string,
  onButtonClick: PropTypes.func,
  onChange: PropTypes.func,
}

export default Input
