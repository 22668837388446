export const BRAND_COLOR_TURQUOISE = '#1BB4D0'
export const BRAND_COLOR_PURPLE = '#400099'
export const BRAND_COLOR_PINK = '#FF518A'
export const BRAND_COLOR_ORANGE = '#FF9E01'
export const BRAND_COLOR_BLUE = '#4481FF'

export const BRAND_COLOR_DARK_TURQUOISE = '#00829A'
export const BRAND_COLOR_DARK_PINK = '#DB3068'
export const BRAND_COLOR_DARK_ORANGE = '#CB4E00'
export const BRAND_COLOR_DARK_BLUE = '#3670E9'
