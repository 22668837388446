import cn from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

type HashTable = { [key: string]: string }

const types: HashTable = {
  code: styles.code,
}

type PillProps = {
  value: React.ReactNode
  icon?: string
  onClick?: Function
  type?: string
  className?: string
  dataTestId?: string
}

const Pill: React.FC<PillProps> = ({
  value,
  icon,
  onClick,
  children,
  type,
  className,
  dataTestId,
}) => {
  return (
    <div className={styles.pillContainer}>
      <div
        className={cn(styles.pill, type ? types[type] : null, className)}
        data-testid={dataTestId}
      >
        {value}
        {icon && (
          <i
            tabIndex={0}
            role="button"
            onClick={() => {
              onClick && onClick()
            }}
            className={icon}
            data-testid={`${dataTestId}-button`}
          />
        )}
      </div>
      {children}
    </div>
  )
}

export default Pill
