import { pseudotranslate } from '@shopify/i18n'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'

import { TEST_LOCALE } from './locale'

const pseudoOptions = {
  prepend: '[[!',
  append: '!]]',
}

const usePseudotranslate = () => {
  const intl = useIntl()
  const fm = useRef(intl.formatMessage)
  const { locale } = intl

  useEffect(() => {
    if (locale === TEST_LOCALE) {
      intl.formatMessage = (...args) =>
        // @ts-expect-error
        pseudotranslate(fm.current(...args), pseudoOptions)
    } else {
      intl.formatMessage = fm.current
    }
  }, [locale, intl])
}

export default usePseudotranslate
