import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './ResponsiveTable.scss'
import {
  extractColumns,
  calculateGridTemplate,
  useWindowSize,
  constants,
  generateIDs,
} from './utils'
import ResponsiveTableRow from './ResponsiveTableRow'
import ResponsiveTableHeader from './ResponsiveTableHeader'

const { DEFAULT_COLLAPSE_BREAKPOINT, RESPONSIVE_BEHAVIOUR_OPTIONS } = constants

const ResponsiveTable = ({
  children,
  label,
  data = [],
  ignoreColumns = [],
  responsiveBehaviour = 'scroll',
  scrollMinWidth,
  collapseBreakpoint = DEFAULT_COLLAPSE_BREAKPOINT,
  columns: columnsProp,
  showHeader = true,
  showCellHeaders = false,
  showRowDividers = true,
  fullWidth = false,
  striped = true,
  border = false,
  descriptionId,
  rowClickHandler,
  cellPadding = 'medium',
}) => {
  const tableData = generateIDs(data)

  const columns =
    columnsProp && columnsProp[0]
      ? columnsProp
      : extractColumns(tableData, ignoreColumns)

  const windowSize = useWindowSize()
  const collapsed =
    windowSize.innerWidth < collapseBreakpoint &&
    responsiveBehaviour === 'collapseRows'
  const headerVisible = showHeader && !showCellHeaders && !collapsed
  const cellHeadersVisible = collapsed || showCellHeaders

  const useScrollBehaviour = responsiveBehaviour === 'scroll'

  // TODO: extract class and style logic to a util
  const tableClassnames = cn('core-responsive-table', {
    'core-responsive-table--full-width': fullWidth,
    'core-responsive-table--striped': striped,
    'core-responsive-table--scroll': useScrollBehaviour,
    'core-responsive-table--border': border,
    'core-responsive-table--collapsed': collapsed,
    'core-responsive-table--cell-headers': showCellHeaders,
    'core-responsive-table--row-dividers': showRowDividers,
  })

  const scrollTableMinWidth = scrollMinWidth || `${columns.length * 100}px`

  const tableMinWidth =
    useScrollBehaviour && !fullWidth ? scrollTableMinWidth : '100%'

  const tableStyles = {
    gridTemplateColumns: calculateGridTemplate(columns, collapsed),
    minWidth: useScrollBehaviour ? tableMinWidth : null,
  }

  const containerStyles = {
    overflowX: useScrollBehaviour ? 'auto' : null,
  }

  return (
    <div className="core-responsive-table__container" style={containerStyles}>
      <div
        className={tableClassnames}
        style={tableStyles}
        role="table"
        aria-label={label}
        aria-describedby={descriptionId}
        data-testid="core-responsive-table"
      >
        {headerVisible && <ResponsiveTableHeader columns={columns} />}
        <div
          className="core-responsive-table__rowgroup core-responsive-table__body"
          role="rowgroup"
        >
          {children ||
            tableData.map((row) => (
              <ResponsiveTableRow
                key={row.responsiveTableID}
                row={row}
                columns={columns}
                rowClickHandler={rowClickHandler}
                showCellHeaders={cellHeadersVisible}
                cellPadding={cellPadding}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

ResponsiveTable.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  ignoreColumns: PropTypes.arrayOf(PropTypes.string),
  responsiveBehaviour: PropTypes.oneOf(RESPONSIVE_BEHAVIOUR_OPTIONS),
  scrollMinWidth: PropTypes.string,
  collapseBreakpoint: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      size: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
  showHeader: PropTypes.bool,
  showCellHeaders: PropTypes.bool,
  showRowDividers: PropTypes.bool,
  fullWidth: PropTypes.bool,
  striped: PropTypes.bool,
  descriptionId: PropTypes.string,
  cellPadding: PropTypes.string,
  border: PropTypes.bool,
}

export default ResponsiveTable
