import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

const TableRow = ({ children }: Props) => {
  return <tr className="core-table__row">{children}</tr>
}

export default TableRow
