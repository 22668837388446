import { addHours, endOfDay, isToday, startOfDay, subHours } from 'date-fns'

const EXTRA_END_HOURS = 6 // 6 hours
const EXTRA_START_HOURS = 1 // 1 hour

export const isTodayPost6pm = (date: Date) => {
  const hours = date.getHours()

  return isToday(date) && hours >= 18
}

export const isTodayPre1am = (date: Date) => {
  const hours = date.getHours()

  return isToday(date) && hours < 1
}
/**
 * Get the start of the day and subtract extra hours if the time is before 1am
 *
 * @returns {Date}
 */

export const startOfDayWithExtraHours = (date: Date): Date => {
  return subHours(startOfDay(date), EXTRA_START_HOURS)
}

/**
 * Get end of current day and add extra hours if time is after 6pm
 *
 * @returns {Date}
 */

export const endOfDayWithExtraHours = (date: Date): Date => {
  return addHours(endOfDay(date), EXTRA_END_HOURS)
}

export const getScheduleDateTimeRange = (date: Date) => {
  /** After 6pm show consultations from the next day - so that
   appointments after midnight are not missed */
  const toDate = isTodayPost6pm(date)
    ? endOfDayWithExtraHours(date).toISOString()
    : endOfDay(date).toISOString()

  /** Before 1am show consultations from the previous day - so that overdue
   appointments before midnight are not missed */
  const fromDate = isTodayPre1am(date)
    ? startOfDayWithExtraHours(date).toISOString()
    : startOfDay(date).toISOString()

  return { fromDate, toDate }
}
