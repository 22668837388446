import { addSeconds } from 'date-fns'
import { filter } from 'graphql-anywhere'
import React, { useEffect, useRef, useState } from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  INCOMPLETE_CONSULTATION_WARNING,
  PATIENT_TIMELINE_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { IncompleteConsultationWarningModalPluginInterface } from '~/core/config/modules/generated/types'
import {
  ConsultationStatus,
  useCpIncompleteConsultationWarningQuery,
} from '~/generated'
import { Dialog } from '~/ui/Modal'

import { INCOMPLETE_CONSULTATION_WARNING_FRAGMENT } from './operations'

import messages from './IncompleteConsultationWarning.messages'

const isIncomplete = (status: string) => {
  return ['paid', 'confirmed'].includes(status)
}

const IncompleteConsultationWarning: IncompleteConsultationWarningModalPluginInterface = ({
  consultationContext,
  hideIncompleteConsultationModal,
}) => {
  const fm = useFormatMessage()

  const mountTime = useRef<Date>(new Date())
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const consultation = useConsultation(consultationContext)
  const data = filter(INCOMPLETE_CONSULTATION_WARNING_FRAGMENT, consultation)
  const isCurrentConsultationComplete = !isIncomplete(data.status)
  const scheduledTime = new Date(data.scheduledTime)

  const query = useCpIncompleteConsultationWarningQuery({
    skip: isCurrentConsultationComplete || hideIncompleteConsultationModal,
    variables: {
      searchCriteria: {
        dateRange: {
          // One second after scheduled time for current consultation
          startDate: addSeconds(scheduledTime, 1).toISOString(),
          // Till now
          endDate: mountTime.current.toISOString(),
        },
        statuses: [ConsultationStatus.Completed, ConsultationStatus.Confirmed],
        patientSearchCriteria: {
          id: data.patientId,
        },
      },
    },
  })

  useEffect(() => {
    const totalItems = query?.data?.consultationsv2?.totalItems
    const hasIncompleteConsultations =
      typeof totalItems === 'number' && totalItems > 0

    setIsVisible(hasIncompleteConsultations)
    if (hasIncompleteConsultations) {
      analytics.trackEvent({
        category: PATIENT_TIMELINE_CATEGORY,
        action: INCOMPLETE_CONSULTATION_WARNING,
      })
    }
  }, [query.data]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!isVisible || hideIncompleteConsultationModal) {
    return null
  }

  return (
    <Dialog
      title={fm(messages.title)}
      cancel
      cancelLabel={fm(messages.button_label)}
      onClose={() => setIsVisible(false)}
      testId="incomplete-consultation-modal"
    >
      <Text tag="div">{fm(messages.description_line_1)}</Text>
      <Text tag="div" style={{ marginTop: '0.5em' }}>
        {fm(messages.description_line_2_v2)}
      </Text>
    </Dialog>
  )
}

export default IncompleteConsultationWarning
