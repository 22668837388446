import { defineMessages } from 'react-intl'

const baseId = 'browser_version_warning'

export default defineMessages({
  warning_message: {
    id: `${baseId}.browser_version_warning`,
    defaultMessage:
      'This browser is not supported. We recommend using Google Chrome on a PC',
  },
})
