const defaultScrollOptions = {
  block: 'start',
  inline: 'nearest',
  behavior: 'smooth',
}

export const scrollTo = (to, scrollOptions = defaultScrollOptions) => {
  if (to) {
    const target = `[data-scroll-element="${to}"]`
    const element = document.querySelector(target)

    if (element) {
      element.scrollIntoView(scrollOptions)
    } else if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(`Element ${target} not found`)
    }
  }
}
