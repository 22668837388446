import { defineMessages } from 'react-intl'

const baseId = 'consultation.mark_as_no_show'

export default defineMessages({
  open_dialog_button_label: {
    id: `${baseId}.open_dialog_button_label_v2`,
    defaultMessage: 'Mark as no show',
  },
  ok_button_label: {
    id: `${baseId}.ok_button_label_v2`,
    defaultMessage: 'Mark as no show',
  },
  try_again_button_label: {
    id: `${baseId}.try_again_button_label`,
    defaultMessage: 'Try again',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  operation_failed_title: {
    id: `${baseId}.operation_failed_title`,
    defaultMessage: 'Operation failed',
  },
  operation_failed_message: {
    id: `${baseId}.operation_failed_message`,
    defaultMessage: 'Failed to mark consultation as "no show"',
  },
  dialog_title: {
    id: `${baseId}.dialog_title_v2`,
    defaultMessage: 'Mark as no show',
  },
  dialog_body: {
    id: `${baseId}.dialog_body`,
    defaultMessage:
      'Are you sure you want to mark this appointment as a no show?',
  },
})
