import { ApolloError } from '@apollo/client'
import React from 'react'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import Workflow from '~/features/workflows/WorkflowActions/components/Workflow'
import {
  AppointmentInvitesQueryResult,
  CpConsultationWorkflowsV2QueryResult,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import AppointmentInvites from '../NhsAppointmentInvites/components/AppointmentInvite'

import messages from './NhsAppointmentInvitesSummary.messages'

export interface NhsAppointmentInvitesSummaryViewProps {
  appointmentInvites: NonNullable<
    NonNullable<AppointmentInvitesQueryResult['data']>['consultation']
  >['appointmentInvites']
  appointmentWorkflows: NonNullable<
    CpConsultationWorkflowsV2QueryResult['data']
  >['consultationWorkflowsV2']['workflows']['results']
  refetch: () => void
  error?: ApolloError
  faceToFaceInvitesEnabled: boolean
  digitalInvitesEnabled: boolean
}

const NhsAppointmentInvitesSummaryView = ({
  appointmentInvites,
  appointmentWorkflows,
  refetch,
  error,
  faceToFaceInvitesEnabled,
  digitalInvitesEnabled,
}: NhsAppointmentInvitesSummaryViewProps) => {
  const f = useMessages(messages)

  if (
    !isFilledArray(appointmentInvites) &&
    !isFilledArray(appointmentWorkflows)
  ) {
    return null
  }

  if (error) {
    return (
      <ErrorPanel
        title={f('error_message')}
        error={error}
        retry={() => refetch()}
      />
    )
  }

  return (
    <Section title={f('title')} testid="cr-nhs-appointments">
      {faceToFaceInvitesEnabled && (
        <AppointmentInvites appointmentInvites={appointmentInvites || []} />
      )}
      {digitalInvitesEnabled &&
        appointmentWorkflows.map((workflow) => (
          <Workflow key={workflow.id} workflow={workflow} />
        ))}
    </Section>
  )
}

export default NhsAppointmentInvitesSummaryView
