import { defineMessages } from 'react-intl'

const baseId = 'schedule_item.consultation_schedule_checkin'

export default defineMessages({
  detailed_check_in_information: {
    id: `${baseId}.detailed_check_in_information`,
    defaultMessage: 'Patient ready and waiting',
  },
  generic_check_in_information: {
    id: `${baseId}.generic_check_in_information`,
    defaultMessage: 'Patient checked in at {time}',
  },
  checked_in: {
    id: `${baseId}.checked_in`,
    defaultMessage: 'checked in',
  },
  at_registered_address: {
    id: `${baseId}.at_registered_address`,
    defaultMessage: 'At registered address',
  },
  unknown_location: {
    id: `${baseId}.unknown_location`,
    defaultMessage: 'unknown location',
  },
  at_temporary_location_not_registered_address: {
    id: `${baseId}.at_temporary_location`,
    defaultMessage: 'At {location} in {country} (not registered address)',
  },
  check_in_status_not_available: {
    id: `${baseId}.check_in_status_not_available`,
    defaultMessage: 'Check-in status not available at the moment',
  },
  cant_tell_if_the_patient_is_ready: {
    id: `${baseId}.cant_tell_if_the_patient_is_ready`,
    defaultMessage: 'we can’t tell if the patient is ready or not',
  },
  you_can_still_call_the_patient: {
    id: `${baseId}.you_can_still_call_the_patient`,
    defaultMessage:
      'You can still call the patient but may need to confirm some information like ID checks with them.',
  },
})
