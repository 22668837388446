// Note, the types are dynamically imported here to ensure that we only import
// types from opentok and dont include opentok in the main bundle

export type OTSession = import('@opentok/client').Session
export type OTPublisher = import('@opentok/client').Publisher
export type OTSubscriber = import('@opentok/client').Subscriber
export type OTError = import('@opentok/client').OTError
export type OTExceptionEvent = import('@opentok/client').ExceptionEvent
export type OTPublisherProperties = import('@opentok/client').PublisherProperties
export type OTPublisherStats = import('@opentok/client').PublisherStats
export type OTPublisherStatContainer = import('@opentok/client').PublisherStatContainer
export type OTPublisherStatsArr = import('@opentok/client').PublisherStatsArr
export type OTSubscriberProperties = import('@opentok/client').SubscriberProperties
export type OTSubscriberStats = import('@opentok/client').SubscriberStats
export type OTStream = import('@opentok/client').Stream
export type OTConnection = import('@opentok/client').Stream['connection']

export type OTEvent<T = {}> = {
  type: string
  reason?: string
  cancelable: boolean
  target: any
  element: HTMLElement
  stream?: OTStream
  connection?: OTConnection

  isDefaultPrevented(): boolean
  preventDefault(): void
} & T

export type OTSubscriberGetStats = OTSubscriber['getStats']
export type OTPublisherGetStats = OTPublisher['getStats']

export enum SessionStatus {
  NOT_ACTIVE = 'NOT_ACTIVE',
  ACTIVE = 'ACTIVE',
}

export enum SendNotifications {
  NOT_READY = 'NOT_READY',
  READY = 'READY',
}

export * from './useOpentokEventHandlers/types'
