import 'rc-tooltip/assets/bootstrap_white.css'
import './styles.module.scss'

import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RCTooltip from 'rc-tooltip'
import React from 'react'

type RCTooltipProps = React.ComponentProps<typeof RCTooltip>

export interface TooltipProps extends Omit<RCTooltipProps, 'overlay'> {
  message?: React.ReactNode
  info?: React.ReactNode
  hidden?: boolean
  overlay?: RCTooltipProps['overlay']
}

const Tooltip = ({
  children,
  message,
  overlay,
  overlayStyle,
  trigger = ['hover'],
  info,
  placement = 'top',
  hidden,
  ...rest
}: TooltipProps) => {
  const content = info ? <FontAwesomeIcon icon={faInfoCircle} /> : children

  if (hidden) {
    return <>{content}</>
  }

  return (
    <RCTooltip
      transitionName="tooltip-fade"
      mouseEnterDelay={0.05}
      mouseLeaveDelay={0}
      trigger={trigger}
      overlay={overlay || info || message}
      placement={placement}
      overlayStyle={overlayStyle}
      {...rest}
    >
      {content}
    </RCTooltip>
  )
}

export default Tooltip
