import { OTPublisherProperties, OTSubscriberProperties } from './types'

const transparentPixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

export const PUBLISHER_PROPERTIES: OTPublisherProperties = {
  insertMode: 'append',
  width: '100%',
  height: '100%',
  frameRate: 30,
  style: {
    archiveStatusDisplayMode: 'off',
    buttonDisplayMode: 'off',
    nameDisplayMode: 'off',
  },
}

export const SUBSCRIBER_PROPERTIES: OTSubscriberProperties = {
  insertMode: 'append',
  width: '100%',
  height: '100%',
  style: {
    buttonDisplayMode: 'off',
    nameDisplayMode: 'off',
    backgroundImageURI: transparentPixel,
  },
}

export const DEVICE_KIND = {
  VIDEO_INPUT: 'videoInput',
  AUDIO_INPUT: 'audioInput',
}

export const sessionEventNames = [
  'connectionCreated',
  'connectionDestroyed',
  'sessionConnected',
  'sessionDisconnected',
  'sessionReconnecting',
  'sessionReconnected',
  'streamCreated',
  'streamDestroyed',
  'streamPropertyChanged',
  'signal',
]

export const subscriberEventNames = [
  'audioBlocked',
  'audioLevelUpdated',
  'audioUnblocked',
  'connected',
  'destroyed',
  'disconnected',
  'videoDimensionsChanged',
  'videoDisabled',
  'videoDisableWarning',
  'videoDisableWarningLifted',
  'videoElementCreated',
  'videoEnabled',
]

export const publisherEventNames = [
  'accessAllowed',
  'accessDenied',
  'accessDialogClosed',
  'accessDialogOpened',
  'audioLevelUpdated',
  'destroyed',
  'mediaStopped',
  'streamCreated',
  'streamDestroyed',
  'videoDimensionsChanged',
  'videoElementCreated',
]

export const OPENTOK_ERROR_NAMES = {
  OT_HARDWARE_UNAVAILABLE: 'OT_HARDWARE_UNAVAILABLE',
  OT_INVALID_PARAMETER: 'OT_INVALID_PARAMETER',
  OT_MEDIA_ERR_ABORTED: 'OT_MEDIA_ERR_ABORTED',
  OT_MEDIA_ERR_DECODE: 'OT_MEDIA_ERR_DECODE',
  OT_MEDIA_ERR_NETWORK: 'OT_MEDIA_ERR_NETWORK',
  OT_MEDIA_ERR_SRC_NOT_SUPPORTED: 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED',
  OT_NOT_SUPPORTED: 'OT_NOT_SUPPORTED',
  OT_NO_DEVICES_FOUND: 'OT_NO_DEVICES_FOUND',
  OT_NO_VALID_CONSTRAINTS: 'OT_NO_VALID_CONSTRAINTS',
  OT_SCREEN_SHARING_NOT_SUPPORTED: 'OT_SCREEN_SHARING_NOT_SUPPORTED',
  OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED:
    'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED',
  OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED:
    'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED',
  OT_REPORT_ISSUE_FAILED: 'OT_REPORT_ISSUE_FAILED',
  OT_AUTHENTICATION_ERROR: 'OT_AUTHENTICATION_ERROR',
  OT_BADLY_FORMED_RESPONSE: 'OT_BADLY_FORMED_RESPONSE',
  OT_CONNECT_FAILED: 'OT_CONNECT_FAILED',
  OT_CONNECTION_LIMIT_EXCEEDED: 'OT_CONNECTION_LIMIT_EXCEEDED',
  OT_EMPTY_RESPONSE_BODY: 'OT_EMPTY_RESPONSE_BODY',
  OT_INVALID_SESSION_ID: 'OT_INVALID_SESSION_ID',
  OT_NOT_CONNECTED: 'OT_NOT_CONNECTED',
  OT_TERMS_OF_SERVICE_FAILURE: 'OT_TERMS_OF_SERVICE_FAILURE',
  OT_INVALID_HTTP_STATUS: 'OT_INVALID_HTTP_STATUS',
  OT_XDOMAIN_OR_PARSING_ERROR: 'OT_XDOMAIN_OR_PARSING_ERROR',
  OT_PERMISSION_DENIED: 'OT_PERMISSION_DENIED',
  OT_CHROME_MICROPHONE_ACQUISITION_ERROR:
    'OT_CHROME_MICROPHONE_ACQUISITION_ERROR',
  OT_CONSTRAINTS_NOT_SATISFIED: 'OT_CONSTRAINTS_NOT_SATISFIED',
  OT_CREATE_PEER_CONNECTION_FAILED: 'OT_CREATE_PEER_CONNECTION_FAILED',
  OT_ICE_WORKFLOW_FAILED: 'OT_ICE_WORKFLOW_FAILED',
  OT_SET_REMOTE_DESCRIPTION_FAILED: 'OT_SET_REMOTE_DESCRIPTION_FAILED',
  OT_STREAM_CREATE_FAILED: 'OT_STREAM_CREATE_FAILED',
  OT_TIMEOUT: 'OT_TIMEOUT',
  OT_USER_MEDIA_ACCESS_DENIED: 'OT_USER_MEDIA_ACCESS_DENIED',
  OT_UNEXPECTED_SERVER_RESPONSE: 'OT_UNEXPECTED_SERVER_RESPONSE',
  OT_NOT_FOUND: 'OT_NOT_FOUND',
  OT_RATE_LIMIT_EXCEEDED: 'OT_RATE_LIMIT_EXCEEDED',
  OT_DISCONNECTED: 'OT_DISCONNECTED',
  OT_STREAM_DESTROYED: 'OT_STREAM_DESTROYED',
  OT_STREAM_LIMIT_EXCEEDED: 'OT_STREAM_LIMIT_EXCEEDED',
  OT_STREAM_NOT_FOUND: 'OT_STREAM_NOT_FOUND',
}
