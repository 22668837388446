import { ApolloError } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { ChangeEvent, useState } from 'react'
import * as Yup from 'yup'

import {
  Button,
  FormField,
  FormikCheckbox,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
  Grid,
  Text,
} from '@babylon/core-ui'

import { useFeatureFlags } from '~/core/core-modules'
import { ClinicalPortalError } from '~/core/ErrorBoundary'
import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import {
  Gender,
  useOverrideIdentityCheckMutation,
  useUpdatePatientDetailsMutation,
  VerificationStatus,
} from '~/generated'
import AddressInput from '~/ui/AddressInput'
import Message from '~/ui/Message'
import { Dialog, Slideout } from '~/ui/Modal'
import PhoneNumberInput from '~/ui/PhoneNumberInput'

import { PatientDetails } from '../types'

import messages from './PatientDetailsModal.messages'
import styles from './styles.module.scss'

export interface PatientDetailsModalViewProps {
  onClose: () => void
  patient: PatientDetails | undefined
  updateLoading: boolean
  overrideLoading: boolean
  updateError?: ApolloError
  overrideError?: ApolloError
  updatePatientDetailsMutation: ReturnType<
    typeof useUpdatePatientDetailsMutation
  >[0]
  overrideIdentityCheckMutation: ReturnType<
    typeof useOverrideIdentityCheckMutation
  >[0]
  enableIdVerification?: boolean
}

interface PatientDetailsNextOfKinValues {
  first_name: string
  last_name: string
  phone_number: string
  relation: string
}

interface PatientDetailsFormValues {
  first_name: string
  last_name: string
  gender: Gender | null
  email: string
  date_of_birth: string
  phone_country_code: string
  phone_number: string
  address_first_line: string
  address_second_line: string
  address_third_line: string
  address_state_code: string
  address_post_code: string
  next_of_kin: PatientDetailsNextOfKinValues
  id_verified: boolean
}

const validationSchema = (yup: typeof Yup) => {
  return yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email(),
    phone_number: yup.string().phone().required(),
    phone_country_code: yup.string().phoneCountryCode().required(),
    next_of_kin: yup.object().shape({
      phone_number: yup.string().phone(),
    }),
  })
}

const PatientDetailsModalView = ({
  onClose,
  patient,
  updateLoading,
  overrideLoading,
  updateError,
  overrideError,
  updatePatientDetailsMutation,
  overrideIdentityCheckMutation,
  enableIdVerification,
}: PatientDetailsModalViewProps) => {
  const f = useMessages(messages)
  const { memberProfileBupaTagEnabled } = useFeatureFlags()

  if (!patient) {
    throw new ClinicalPortalError({
      title: f('error_title'),
      message: f('error_message'),
    })
  }

  const { contact_details: contactDetails, next_of_kin: nextOfKin } =
    patient ?? {}
  const regionName = patient?.region?.name ?? ''

  const profileLocked =
    !!memberProfileBupaTagEnabled && !!patient?.profile_locked

  const [idVerified, setIdVerified] = useState(
    patient?.id_verification === VerificationStatus.Succeeded
  )
  const [showOverridePopup, setShowOverridePopup] = useState(false)

  const initialFormValues: PatientDetailsFormValues = {
    first_name: patient?.first_name ?? '',
    last_name: patient?.last_name ?? '',
    gender: patient?.gender ?? null,
    email: patient?.email ?? '',
    date_of_birth: patient?.date_of_birth ?? '',
    phone_country_code: contactDetails?.phone_country_code ?? '',
    phone_number: contactDetails?.phone_number ?? '',
    address_first_line: contactDetails?.address_first_line ?? '',
    address_second_line: contactDetails?.address_second_line ?? '',
    address_third_line: contactDetails?.address_third_line ?? '',
    address_state_code: contactDetails?.address_state_code ?? '',
    address_post_code: contactDetails?.address_post_code ?? '',
    next_of_kin: {
      first_name: nextOfKin?.first_name ?? '',
      last_name: nextOfKin?.last_name ?? '',
      phone_number: nextOfKin?.phone_number ?? '',
      relation: nextOfKin?.relation ?? '',
    },
    id_verified: idVerified,
  }

  const handleSave = (closeModal: () => void) => async (
    values: PatientDetailsFormValues
  ) => {
    try {
      const { id_verified, ...details } = values
      await updatePatientDetailsMutation({
        variables: {
          // @ts-expect-error - TODO: fix this
          patientId: patient.id,
          input: details,
        },
      })

      closeModal()
    } catch (err) {
      logException(err)
    }
  }

  const handleVerifyId = async () => {
    try {
      await overrideIdentityCheckMutation({
        variables: {
          patientId: patient.id,
        },
      })

      setShowOverridePopup(false)
      setIdVerified(true)
    } catch (err) {
      logException(err)
    }
  }

  return (
    <div>
      <Slideout title={f('title')} onClose={onClose}>
        {({ closeModal }) => {
          return (
            <>
              {profileLocked && (
                <div style={{ marginBottom: '50px' }}>
                  <Message type="info" dataTestId="profile_locked_message">
                    {f('locked', {
                      lockType:
                        patient.profile_locks.length > 0
                          ? patient.profile_locks
                              .map((locks) => locks.name.toUpperCase())
                              .join(', ')
                          : '',
                    })}
                  </Message>
                </div>
              )}
              <Formik<PatientDetailsFormValues>
                initialValues={initialFormValues}
                onSubmit={handleSave(closeModal)}
                validationSchema={validationSchema(Yup)}
              >
                {({ values, setFieldValue }) => (
                  <Form name="patient-details-form">
                    <div className={styles.row} data-testid="patient-details-1">
                      {enableIdVerification && (
                        <div className={styles.id_verification}>
                          <FormikCheckbox
                            id="id_verified"
                            name="id_verified"
                            label={f('identity_label')}
                            checked={idVerified}
                            onChange={() => {
                              setShowOverridePopup(!showOverridePopup)
                            }}
                            disabled={idVerified || profileLocked}
                            inline
                          >
                            {f('verified_label')}
                          </FormikCheckbox>
                        </div>
                      )}
                      <div className={styles.firstName}>
                        <FormikInput
                          name="first_name"
                          id="first_name"
                          label={f('first_name_label')}
                          placeholder={f('first_name_placeholder')}
                          disabled={profileLocked}
                        />
                      </div>
                      <div className={styles.lastName}>
                        <FormikInput
                          name="last_name"
                          id="last_name"
                          label={f('last_name_label')}
                          placeholder={f('last_name_placeholder')}
                          disabled={profileLocked}
                        />
                      </div>
                      <div
                        className={styles.gender}
                        data-testid="patient-gender"
                      >
                        <FormikSelect
                          name="gender"
                          id="gender"
                          label={f('gender_label')}
                          options={[
                            {
                              label: f('gender_option_female_label'),
                              value: Gender.Female,
                            },
                            {
                              label: f('gender_option_male_label'),
                              value: Gender.Male,
                            },
                          ]}
                          isDisabled={profileLocked}
                        />
                      </div>
                    </div>
                    <Grid gap={4} columns={2} dataTestId="patient-details-2">
                      <div>
                        <FormikInput
                          name="email"
                          id="email"
                          label={f('email_label')}
                          placeholder={f('email_placeholder')}
                          disabled={profileLocked}
                        />
                        <FormikDatePicker
                          name="date_of_birth"
                          id="date_of_birth"
                          label={f('date_of_birth_label')}
                          stateFormat="yyyy-MM-dd"
                          data-testid="dob-picker"
                          disabled={profileLocked}
                        />
                        <FormField label={f('phone_number_label')}>
                          <PhoneNumberInput
                            fieldName="phone_number"
                            countryCodeFieldName="phone_country_code"
                            value={values.phone_number}
                            placeholder={f('phone_number_placeholder')}
                            countryCode={values.phone_country_code}
                            countryCodePlaceholder={f('country_code_label')}
                            className={styles.row}
                            disabled={profileLocked}
                          />
                        </FormField>
                      </div>
                      <FormField label={f('address_label')}>
                        <AddressInput
                          className={styles.address}
                          values={values}
                          regionName={regionName}
                          onAddressChange={(key: string) => (
                            event: ChangeEvent<HTMLInputElement>
                          ) => setFieldValue(key, event.target.value)}
                          disabled={profileLocked}
                        />
                      </FormField>
                    </Grid>
                    <div className={styles.nextOfKin}>
                      <Text tag="p" size="large" bold>
                        {f('next_of_kin_label')}
                      </Text>
                      <Grid
                        gap={4}
                        rowGap={0}
                        columns={2}
                        dataTestId="next-of-kin"
                      >
                        <FormikInput
                          name="next_of_kin.first_name"
                          id="next_of_kin.first_name"
                          label={f('next_of_kin_first_name_label')}
                          placeholder={f('next_of_kin_first_name_placeholder')}
                          disabled={profileLocked}
                        />
                        <FormikInput
                          name="next_of_kin.last_name"
                          id="next_of_kin.last_name"
                          label={f('next_of_kin_last_name_label')}
                          placeholder={f('next_of_kin_last_name_placeholder')}
                          disabled={profileLocked}
                        />
                        <FormikInput
                          name="next_of_kin.relation"
                          id="next_of_kin.relation"
                          label={f('next_of_kin_relation_label')}
                          placeholder={f('next_of_kin_relation_placeholder')}
                          disabled={profileLocked}
                        />
                        <FormikInput
                          name="next_of_kin.phone_number"
                          id="next_of_kin.phone_number"
                          label={f('next_of_kin_phone_number_label')}
                          placeholder={f(
                            'next_of_kin_phone_number_placeholder'
                          )}
                          disabled={profileLocked}
                        />
                      </Grid>
                    </div>
                    {updateError && (
                      <Text
                        tag="div"
                        align="right"
                        color="error"
                        style={{ marginTop: 8 }}
                      >
                        {f('fatal_error')}
                      </Text>
                    )}
                    <div className={styles.footer}>
                      <Button intent="secondary" onClick={closeModal} inline>
                        {f('cancel_button_label')}
                      </Button>
                      <Button
                        type="submit"
                        intent="primary"
                        loading={updateLoading}
                        inline
                        disabled={profileLocked}
                      >
                        {f('save_button_label')}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )
        }}
      </Slideout>
      {showOverridePopup && (
        <Dialog
          title={f('override_popup_title')}
          okLabel={f('save_button_label')}
          cancelLabel={f('cancel_button_label')}
          ok
          cancel
          onOk={handleVerifyId}
          onCancel={() => {
            setShowOverridePopup(false)
            setIdVerified(false)
          }}
          okLoading={overrideLoading}
        >
          {f('override_popup_content')}
          {overrideError && (
            <Text tag="div" color="error" style={{ marginTop: 8 }}>
              {f('fatal_error')}
            </Text>
          )}
        </Dialog>
      )}
    </div>
  )
}

export default PatientDetailsModalView
