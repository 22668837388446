import { defineMessages } from 'react-intl'

const baseId = 'components.appointment_invites_table'

export default defineMessages({
  profession_key: {
    id: `${baseId}.profession_key`,
    defaultMessage: 'Profession',
  },
  type_key: {
    id: `${baseId}.type_key`,
    defaultMessage: 'Type',
  },
  provider_key: {
    id: `${baseId}.provider_key`,
    defaultMessage: 'Provider',
  },
  provider_tag: {
    id: `${baseId}.provider_tag`,
    defaultMessage: 'You',
  },
  duration_key: {
    id: `${baseId}.duration_key`,
    defaultMessage: 'Duration',
  },
  duration_value: {
    id: `${baseId}.duration_value`,
    defaultMessage: '{durationMinutes} mins',
  },
  medium_key: {
    id: `${baseId}.medium_key`,
    defaultMessage: 'Medium',
  },
  medium_value_digital: {
    id: `${baseId}.medium_value_digital`,
    defaultMessage: 'Digital',
  },
  medium_value_faceToFace: {
    id: `${baseId}.medium_value_facetoface`,
    defaultMessage: 'Face to Face',
  },
  reason_key: {
    id: `${baseId}.reason_key`,
    defaultMessage: 'Reason',
  },
  when_key: {
    id: `${baseId}.when_key`,
    defaultMessage: 'When',
  },
  when_value: {
    id: `${baseId}.when_value`,
    defaultMessage: 'To be scheduled from',
  },
  recurrence_key: {
    id: `${baseId}.recurrence_key`,
    defaultMessage: 'Recurrence',
  },
  recurrence_value: {
    id: `${baseId}.recurrence_value`,
    defaultMessage: '{amount} weekly sessions',
  },
})
