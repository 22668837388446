import { setRequestIdGenerator } from '@babylon/babylon-auth'
import { RequestIdGenerator } from '@babylon/shared-utils'

import { APP_NAME } from '~/constants'

const requestIdGenerator = new RequestIdGenerator({ app: APP_NAME })

setRequestIdGenerator(requestIdGenerator)

export default requestIdGenerator
