import { defineMessages } from 'react-intl'

const baseId = 'components.care_plan_modal'

export default defineMessages({
  cant_load_care_plan_draft: {
    id: `${baseId}.cant_load_care_plan_draft`,
    defaultMessage: "We can't load the care plan draft right now",
  },
})
