import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React from 'react'

import { checkConnectionIsGuest } from '@babylon/connect-client-core'
import { Grid } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import {
  CallStatus,
  isActive,
  isConnecting,
  isRejected,
} from '../../CallStatusReducerModelProvider'
import { StatusMessage } from '../../StatusIndicator'
import {
  OTPublisher,
  OTStream,
  OTSubscriber,
  VideoDegradation,
} from '../useVideoStreamer/useOpentok'

import styles from './styles.module.scss'
import messages from './VideoStatusMessage.messages'

interface MessageListProps {
  items: React.ReactNode[]
  inlineBackground?: boolean
}

const MessageList = ({
  items = [],
  inlineBackground = true,
}: MessageListProps) => {
  return (
    <StatusMessage>
      <Grid gap={8}>
        {items.map((item, index) => (
          <div
            key={index}
            className={cn(inlineBackground && styles.inlineBackground)}
            data-testid="status-message-item"
          >
            {item}
          </div>
        ))}
      </Grid>
    </StatusMessage>
  )
}

interface VideoStatusProps {
  callStatus: CallStatus
  streams: OTStream[]
  publisher: OTPublisher | undefined
  subscriber: OTSubscriber | undefined
  subscriberVideoDegradation: VideoDegradation | undefined
}

const VideoStatusMessage = ({
  callStatus,
  streams,
  publisher,
  subscriber,
  subscriberVideoDegradation,
}: VideoStatusProps) => {
  const f = useMessages(messages)

  if (isConnecting(callStatus)) {
    return <MessageList inlineBackground={false} items={[f('connecting')]} />
  }

  if (!isActive(callStatus)) {
    return null
  }

  if (isRejected(callStatus)) {
    return (
      <MessageList
        inlineBackground={false}
        items={[f('video_call_rejected')]}
      />
    )
  }

  const patientStreams = streams.filter(
    (stream) => publisher?.stream?.streamId !== stream.streamId
  )

  if (patientStreams.length === 0) {
    return (
      <MessageList
        inlineBackground={false}
        items={[f('waiting_for_patient')]}
      />
    )
  }

  if (subscriber?.isAudioBlocked()) {
    return (
      <StatusMessage className={styles.wrapperBackground}>
        <div className={styles.audioBlockedMessage}>
          {f('autoplay_blocked', {
            icon: (
              <FontAwesomeIcon
                key="icon"
                className={styles.icon}
                icon={faPlayCircle}
              />
            ),
          })}
        </div>
      </StatusMessage>
    )
  }

  const subscriberVideoDegradationMessage = () => {
    if (subscriberVideoDegradation === 'warning') {
      return f('video_disable_warning')
    }

    if (subscriberVideoDegradation === 'disabled') {
      return f('video_disabled')
    }

    return null
  }

  const patientMediaMessage = () => {
    const somePatientStreamHasNoVideo = patientStreams.some(
      (stream) =>
        !checkConnectionIsGuest(stream.connection) && stream.hasVideo === false
    )
    const somePatientStreamHasNoAudio = patientStreams.some(
      (stream) =>
        !checkConnectionIsGuest(stream.connection) && stream.hasAudio === false
    )

    if (somePatientStreamHasNoVideo && somePatientStreamHasNoAudio) {
      return f('patients_camera_and_mic_off')
    }

    if (somePatientStreamHasNoVideo) {
      return f('patients_camera_off')
    }

    if (somePatientStreamHasNoAudio) {
      return f('patients_microphone_off')
    }

    return null
  }

  const clinicianMediaMessage = () => {
    const clinicianStream = publisher?.stream

    if (!clinicianStream) {
      return null
    }

    const clinicianStreamHasVideo = clinicianStream.hasVideo
    const clinicianStreamHasAudio = clinicianStream.hasAudio

    if (!clinicianStreamHasVideo && !clinicianStreamHasAudio) {
      return f('clinicians_camera_and_mic_off')
    }

    if (!clinicianStreamHasVideo) {
      return f('clinicians_camera_off')
    }

    if (!clinicianStreamHasAudio) {
      return f('clinicians_microphone_off')
    }

    return null
  }

  const messageList = [
    subscriberVideoDegradationMessage(),
    patientMediaMessage(),
    clinicianMediaMessage(),
  ].filter(Boolean)

  if (messageList.length > 0) {
    return <MessageList items={messageList} />
  }

  return null
}

export default VideoStatusMessage
