import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Alert, Card, Heading } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'
import { Results } from '@babylon/tests-and-kits'

import { ModalContent, RouteModal } from '~/ui/LegacyModal'
import Loader from '~/ui/Loader'

import styles from './styles.module.scss'
import messages from './TestReportModal.messages'

const TestReportModal = ({ data, redirectToConsultation }) => {
  const intl = useIntl()

  const { loading, error, getReport, getPanel } = data
  const report = getReport || getPanel
  const sentOn = report && report.sentOn
  const appointmentSuggested = report && report.appointmentSuggested
  const clinician = report?.sender?.fullName

  return (
    <RouteModal onRequestClose={redirectToConsultation}>
      <ModalContent
        title={<TestReportModalTitle clinician={clinician} />}
        rightText={
          sentOn &&
          `${intl.formatMessage(messages.sent_at)} ${intl.formatDate(sentOn, {
            format: 'shortWithTime',
          })}`
        }
      >
        <Loader
          waitFor={!loading}
          render={() => (
            <>
              {report?.reviewedOn && (
                <p className={styles.description}>
                  {appointmentSuggested
                    ? intl.formatMessage(messages.appointment_suggested)
                    : intl.formatMessage(messages.appointment_not_suggested)}
                </p>
              )}
              {report.reviewerComments && (
                <Card padding="lg" className={styles.commentCard}>
                  <Heading level="h3">GP Comments</Heading>
                  <Alert padding={10}>{report.reviewerComments}</Alert>
                </Card>
              )}
              <Results loading={loading} error={error} report={report} />
            </>
          )}
        />
      </ModalContent>
    </RouteModal>
  )
}

const TestReportModalTitle = ({ clinician }) => (
  <FormattedMessage
    {...messages.title}
    values={{
      withMessage: clinician && (
        <span className={styles.titleMeta}>
          <FormattedMessage
            {...messages.title_clinician}
            values={{
              name: clinician,
            }}
          />
        </span>
      ),
    }}
  />
)

export default TestReportModal
