import { defineMessages } from 'react-intl'

const baseId = 'app'

export default defineMessages({
  copying_not_allowed_text: {
    id: `${baseId}.copying_not_allowed_text`,
    defaultMessage:
      'NOTE: Copying data from the Clinical Portal is not allowed.',
  },
  page404Title: {
    id: `${baseId}.page_404_title`,
    defaultMessage: 'Page not found',
  },
  page404Content: {
    id: `${baseId}.page_404_content`,
    defaultMessage: 'The page you are trying to access does not exist.',
  },
  config_error_title: {
    id: `${baseId}.config_error_title`,
    defaultMessage: 'Your account is not correctly configured',
  },
  config_error_message: {
    id: `${baseId}.config_error_message`,
    defaultMessage:
      "There's something wrong with your account configuration and you can't use the clinical portal.  This is most likely because you have not been assigned the correct partner(s).  Please contact support to resolve the issue.",
  },
})
