import React from 'react'

import sanitize from '~/core/sanitize'

import { OptionsProps } from './ConfigEditor'

import styles from './styles.module.scss'

export const Options = ({ options }: OptionsProps) => {
  return (
    <div className={styles.options}>
      <pre
        // contentEditable
        dangerouslySetInnerHTML={{
          __html: sanitize(
            JSON.stringify(options, null, 2)
              ?.replace(
                /\{\s*"\$ref":\s*"(.*?)"\s*\}/gs,
                `<span class="${styles.reference}">@$1</span>`
              )
              .replace(/^\s*\{/, '')
              .replace(/\s*\}\s*$/, '')
              .replace(/\n\s*/g, (str) => `\n${str.substring(3)}`)
              .replace(/"(.*?)":/g, '$1:')
              .trim()
          ),
        }}
      />
    </div>
  )
}
