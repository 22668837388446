import { gql } from '@apollo/client'

export const SuggestedDrugsQuery = gql`
  query SuggestedDrugsQuery($query: String!, $region: String!) {
    drugs(query: $query, region: $region, limit: 40) {
      id
      name
      generic
      expensive
      strength
      controlled
      blacklisted
      drugPreparation
      activeIngredients
      pharmaceuticalForm
      packaging {
        packagingId
        packageSize
      }
    }
  }
`
