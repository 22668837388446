import { useCallback, useEffect, useRef, useState } from 'react'

const useDurationTimer = () => {
  const [duration, setDuration] = useState(0)
  const [isCounting, setCounting] = useState(false)
  const timerRef = useRef(null)

  const startInterval = useCallback(() => {
    setCounting(true)
    timerRef.current = setInterval(
      () => setDuration((currentDuration) => currentDuration + 1),
      1000
    )
  }, [timerRef])

  useEffect(() => {
    if (isCounting) {
      startInterval()
    }
    return function cleanup() {
      clearInterval(timerRef.current)
    }
  }, [isCounting, startInterval])

  return {
    duration,
    startTimer: (startValue) => {
      if (startValue !== undefined) {
        clearInterval(timerRef.current)
        setDuration(startValue)
      }
      startInterval()
    },
    pauseTimer: () => {
      clearInterval(timerRef.current)
      setCounting(false)
    },
    clearTimer: () => {
      clearInterval(timerRef.current)
      setDuration(0)
      setCounting(false)
    },
  }
}

export default useDurationTimer
