import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'

interface UseConsultationSentryTags {
  consultationId: string
  consultationUuid?: string
  patientId?: string
  patientUuid?: string
}

const useConsultationSentryTags = ({
  consultationId,
  consultationUuid,
  patientId,
  patientUuid,
}: UseConsultationSentryTags) => {
  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setTag('consultation_id', consultationId)
      scope.setTag('consultation_uuid', consultationUuid || '')
      scope.setTag('patient_id', patientId || '')
      scope.setTag('patient_uuid', patientUuid || '')
    })

    return () => {
      Sentry.configureScope((scope) => {
        scope.setTag('consultation_id', null)
        scope.setTag('consultation_uuid', null)
        scope.setTag('patient_id', null)
        scope.setTag('patient_uuid', null)
      })
    }
  }, [consultationId, consultationUuid, patientId, patientUuid])
}

export default useConsultationSentryTags
