import { gql } from '@apollo/client'

export const SickNotesQuery = gql`
  query SickNote($consultationId: ID!) {
    consultation(id: $consultationId) {
      id
      sickNotes {
        id
        reason
        startDate
        endDate
      }
    }
  }
`
