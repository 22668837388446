import { useState } from 'react'

import useInterval from './useInterval'

const useNow = (scheduleUpdateInterval = 60 * 1000) => {
  const [now, setNow] = useState<Date>(new Date())

  useInterval(() => {
    setNow(new Date())
  }, scheduleUpdateInterval)

  return now
}

export default useNow
