import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import { Heading, Tag } from '@babylon/core-ui'

import Tooltip from '~/ui/Tooltip'

import styles from './styles.module.scss'

interface SectionProps {
  title: string
  testid?: string
  children: React.ReactNode
  collapsible?: boolean
  tooltip?: string
  classname?: string
  tag?: string
}

const Section = ({
  title,
  testid,
  collapsible,
  children,
  tooltip,
  classname,
  tag,
}: SectionProps) => {
  const [expanded, setExpanded] = useState(false)

  const wrapper = useRef<HTMLDivElement>(null)
  const { height } = useResizeObserver<HTMLDivElement>({ ref: wrapper })

  const handleClick = () => {
    if (wrapper && wrapper.current) {
      setExpanded(!expanded)
    }
  }

  return children ? (
    <div
      className={classnames(
        styles.reviewSection,
        collapsible && styles.accordion
      )}
      onClick={collapsible ? handleClick : () => {}}
      data-testid={testid}
    >
      {title && (
        <div
          className={styles.heading}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Heading data-testid="section-title" level="h3">
            {collapsible && (
              <FontAwesomeIcon
                className={styles.chevron}
                icon={expanded ? faChevronDown : faChevronRight}
              />
            )}
            {title}
            {tooltip && (
              <div style={{ paddingLeft: '4px', display: 'inline' }}>
                <Tooltip info={tooltip} />{' '}
              </div>
            )}
          </Heading>
          {tag && <Tag color="clinical-green">{tag}</Tag>}
        </div>
      )}

      <div
        style={
          collapsible
            ? {
                overflow: 'hidden',
                maxHeight: expanded ? height : '0px',
              }
            : {}
        }
      >
        <div ref={wrapper}>
          <div className={classnames(styles.content, classname)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null
}
export default Section
