import React from 'react'
import { Heading } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'

export interface ErrorViewProps {
  message?: string
}

export function ErrorView({ message }: ErrorViewProps = {}) {
  const fm = useFormatMessage()

  return (
    <Heading level="h3" color="error">
      {message || fm(messages.default_error_message)}
    </Heading>
  )
}
