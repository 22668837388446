import {
  Action,
  ActionType,
  CallMediumEntitlement,
  CallStatus,
  CallStatusReducerState,
  ErrorActionType,
} from './types'

const shouldDisable = (
  callMedium: CallMediumEntitlement,
  callType: string
): boolean => {
  return (
    callMedium !== CallMediumEntitlement.VoiceAndVideo &&
    callType !== callMedium
  )
}

export const isEnabled = (currentStatus: CallStatus): boolean => {
  return currentStatus !== CallStatus.Disabled
}

export const isCriticallyFailed = (currentStatus: CallStatus): boolean => {
  return currentStatus === CallStatus.CriticallyFailed
}

export const isLoading = (currentStatus: CallStatus): boolean => {
  return currentStatus === CallStatus.Loading
}

export const isReady = (currentStatus: CallStatus): boolean => {
  return currentStatus === CallStatus.Ready
}

export const isActive = (currentStatus: CallStatus): boolean => {
  return currentStatus > CallStatus.Ready
}

export const isConnecting = (currentStatus: CallStatus): boolean => {
  return currentStatus === CallStatus.Connecting
}

export const isStreaming = (currentStatus: CallStatus): boolean => {
  return currentStatus === CallStatus.Streaming
}

export const isRejected = (currentStatus: CallStatus): boolean => {
  return currentStatus === CallStatus.Rejected
}

const getInitialVideoStatus = (callMedium: CallMediumEntitlement) => {
  return shouldDisable(callMedium, CallMediumEntitlement.Video)
    ? CallStatus.Disabled
    : CallStatus.Loading
}

const getInitialVoiceStatus = (callMedium: CallMediumEntitlement) => {
  return shouldDisable(callMedium, CallMediumEntitlement.Voice)
    ? CallStatus.Disabled
    : CallStatus.Loading
}

export const getBaseState = (
  videoStatus: CallStatus,
  voiceStatus: CallStatus
): CallStatusReducerState => {
  return {
    videoStatus,
    voiceStatus,
    videoError: null,
    voiceError: null,
    callServiceError: null,
    isSurveyVisible: false,
    isAccessDialogOpen: false,
    callEndReason: null,
    isCallActive: false,
    isVoipCall: false,
    isVoiceFallbackVisible: false,
  }
}

export const getInitialState = (
  callMedium: CallMediumEntitlement
): CallStatusReducerState => {
  return getBaseState(
    getInitialVideoStatus(callMedium),
    getInitialVoiceStatus(callMedium)
  )
}

export const resetCallReducerState = (state: CallStatusReducerState) => {
  return getBaseState(
    state.videoStatus === CallStatus.Disabled
      ? CallStatus.Disabled
      : CallStatus.Loading,
    state.voiceStatus === CallStatus.Disabled
      ? CallStatus.Disabled
      : CallStatus.Loading
  )
}

const stopIfActive = (currentStatus: CallStatus): CallStatus => {
  return isActive(currentStatus) ? CallStatus.Ready : currentStatus
}

const setStreamingIfConnecting = (currentStatus: CallStatus): CallStatus => {
  return currentStatus === CallStatus.Connecting
    ? CallStatus.Streaming
    : currentStatus
}

export const reducer = (
  state: CallStatusReducerState,
  action: Action
): CallStatusReducerState => {
  switch (action.type) {
    case ActionType.RESET_TO_INITIAL:
      return resetCallReducerState(state)
    case ActionType.VIDEO_LOADING:
      return {
        ...state,
        videoError: null,
        videoStatus: CallStatus.Loading,
        isCallActive: isActive(CallStatus.Loading),
      }
    case ActionType.VOICE_LOADING:
      return {
        ...state,
        voiceError: null,
        voiceStatus: CallStatus.Loading,
        isCallActive: isActive(CallStatus.Loading),
      }
    case ActionType.VIDEO_READY:
      return {
        ...state,
        videoStatus: CallStatus.Ready,
        isCallActive: isActive(CallStatus.Ready),
      }
    case ActionType.VOICE_READY:
      return {
        ...state,
        voiceStatus: CallStatus.Ready,
        isCallActive: isActive(CallStatus.Ready),
      }
    case ActionType.VIDEO_CONNECTING:
      return {
        ...state,
        isSurveyVisible: false,
        isVoiceFallbackVisible: false,
        voiceError: null,
        videoError: null,
        videoStatus: CallStatus.Connecting,
        voiceStatus: stopIfActive(state.voiceStatus),
        callEndReason: null,
        isCallActive: isActive(CallStatus.Connecting),
      }
    case ActionType.VIDEO_REJECTED:
      return {
        ...state,
        videoStatus: CallStatus.Rejected,
        isCallActive: isActive(CallStatus.Rejected),
      }
    case ActionType.VOICE_PSTN_CONNECTING:
      return {
        ...state,
        isSurveyVisible: false,
        isVoiceFallbackVisible: false,
        isVoipCall: false,
        videoError: null,
        voiceError: null,
        videoStatus: stopIfActive(state.videoStatus),
        voiceStatus: CallStatus.Connecting,
        callEndReason: null,
        isCallActive: isActive(CallStatus.Connecting),
      }
    case ActionType.VOICE_VOIP_CONNECTING:
      return {
        ...state,
        isSurveyVisible: false,
        isVoiceFallbackVisible: false,
        videoError: null,
        voiceError: null,
        videoStatus: stopIfActive(state.videoStatus),
        voiceStatus: CallStatus.Connecting,
        callEndReason: null,
        isCallActive: isActive(CallStatus.Connecting),
        isVoipCall: true,
      }
    case ActionType.VIDEO_STREAMING:
      return {
        ...state,
        callServiceError: null,
        videoStatus: setStreamingIfConnecting(state.videoStatus),
        isCallActive: isActive(setStreamingIfConnecting(state.videoStatus)),
      }
    case ActionType.VOICE_STREAMING:
      return {
        ...state,
        callServiceError: null,
        voiceStatus: setStreamingIfConnecting(state.voiceStatus),
        isCallActive: isActive(setStreamingIfConnecting(state.voiceStatus)),
      }
    case ActionType.VIDEO_STOP:
      return {
        ...state,
        isSurveyVisible: !isActive(state.voiceStatus),
        videoStatus: CallStatus.Ready,
        callEndReason: action?.payload?.callEndReason || state.callEndReason,
        isCallActive: isActive(CallStatus.Ready),
      }
    case ActionType.VOICE_STOP:
      return {
        ...state,
        isSurveyVisible: !isActive(state.videoStatus),
        voiceStatus: CallStatus.Ready,
        isCallActive: isActive(CallStatus.Ready),
        isVoiceFallbackVisible:
          !isActive(state.videoStatus) && state.isVoipCall, // if we had a previous voip call
      }
    case ActionType.CLEAR_CALL_END_REASON:
      return {
        ...state,
        callEndReason: null,
      }
    case ActionType.MEDIA_ACCESS_DIALOG_OPENED:
      return {
        ...state,
        isAccessDialogOpen: true,
      }
    case ActionType.MEDIA_ACCESS_DIALOG_CLOSED:
      return {
        ...state,
        isAccessDialogOpen: false,
      }
    case ErrorActionType.VIDEO_LOADING_ERROR:
      return {
        ...state,
        videoStatus: CallStatus.CriticallyFailed,
        videoError: action.error,
        isCallActive: isActive(CallStatus.CriticallyFailed),
      }
    case ErrorActionType.VOICE_LOADING_ERROR:
      return {
        ...state,
        voiceStatus: CallStatus.CriticallyFailed,
        voiceError: action.error,
        isCallActive: isActive(CallStatus.CriticallyFailed),
      }
    case ErrorActionType.VIDEO_START_ERROR:
      return {
        ...state,
        videoStatus: CallStatus.Ready,
        videoError: action.error,
        isCallActive: isActive(CallStatus.Ready),
      }
    case ErrorActionType.VOICE_START_ERROR:
      return {
        ...state,
        voiceStatus: CallStatus.Ready,
        voiceError: action.error,
        isCallActive: isActive(CallStatus.Ready),
      }
    case ActionType.COMPLETE_SURVEY:
      return {
        ...state,
        isSurveyVisible: false,
      }
    case ErrorActionType.CALL_SERVICE_ERROR:
      return {
        ...state,
        callServiceError: action.error?.message,
      }

    default:
      throw new Error()
  }
}
