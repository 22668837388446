import { defineMessages } from 'react-intl'

const baseId = 'consultation.feature.ic_warning'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage:
      'IMPORTANT: New consultation(s) while this consultation was left incomplete',
  },
  description_line_1: {
    id: `${baseId}.description_line_1`,
    defaultMessage:
      "Check the patient timeline to see what's happened and avoid conflicting treatments when completing this consultation.",
  },
  description_line_2_v2: {
    id: `${baseId}.description_line_2_v2`,
    defaultMessage:
      'Contact the senior clinical team through Support for questions or advice.',
  },
  button_label: {
    id: `${baseId}.button_label`,
    defaultMessage: 'Continue',
  },
})
