export * from './env'

export const DEFAULT_INPUT_DEBOUNCE = 400
export const DEFAULT_POPOVER_WIDTH = 300
export const CONSULTATION_OVERDUE_PERIOD = 30 // 10 min

export const CONSULTATION_STATUSES = {
  CONFIRMED: 'confirmed',
  PAID: 'paid',
  COMPLETED: 'completed',
  TIMED_OUT: 'timed_out',
  NO_SHOW: 'no_show',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
}

export const CONSULTATION_IGNORED_STATUSES = [
  CONSULTATION_STATUSES.CANCELLED,
  CONSULTATION_STATUSES.TIMED_OUT,
]

export const CONSULTATION_TYPES = {
  TEXT: 'text',
  VIDEO: 'video',
  F2F: 'physical',
  AUDIO: 'voice',
}
