import { useCallback } from 'react'

import { useInterval } from '~/core/hooks'

import { OTPublisherGetStats, OTSubscriberGetStats } from '../useOpentok'
import useQueue from './useQueue'
import { CallMetric, formatCallMetrics, OTCallStats, OTRole } from './utils'

interface IuseMetricsQueue {
  isCallActive: boolean
  getSubscriberStats: OTSubscriberGetStats | undefined
  getPublisherStats: OTPublisherGetStats | undefined
}

const METRICS_GATHERING_INTERVAL_DURATION = 5000

const useMetricsQueue = ({
  getPublisherStats,
  getSubscriberStats,
  isCallActive,
}: IuseMetricsQueue) => {
  const { pushToQueue, consumeQueue } = useQueue<CallMetric>()

  const pushMetricsToQueue = useCallback(
    (stats: OTCallStats, role: OTRole) => {
      const formattedMetrics = formatCallMetrics(stats, role)
      pushToQueue(formattedMetrics)
    },
    [pushToQueue]
  )

  const getLatestMetrics = () => {
    if (getPublisherStats) {
      getPublisherStats((error, statsArr) => {
        if (!error && statsArr) {
          statsArr.forEach(({ stats }) => {
            pushMetricsToQueue(stats, 'publisher')
          })
        }
      })
    }

    if (getSubscriberStats) {
      getSubscriberStats((error, stats) => {
        if (!error && stats) {
          pushMetricsToQueue(stats, 'subscriber')
        }
      })
    }
  }

  useInterval(
    getLatestMetrics,
    isCallActive ? METRICS_GATHERING_INTERVAL_DURATION : null
  )

  return consumeQueue
}

export default useMetricsQueue
