import React from 'react'
import { Inline, Path, Value } from 'slate'
import { Editor, RenderInlineProps } from 'slate-react'

import { insertClinicalCode } from '../ClinicalCodes/commands'
import { INLINE_SEARCH } from './constants'
import InputSearchInline from './InputSearchInline'

const GATEWAY_KEY = '@'

function InlineSearch() {
  const insertEmptyInlineSearchNode = (editor: Editor) => {
    editor.insertInline({
      type: INLINE_SEARCH,
      // @ts-expect-error
      nodes: [
        {
          object: 'text',
          leaves: [
            {
              object: 'leaf',
              text: '  ',
            },
          ],
        },
      ],
    })
  }

  /**
   * If current selection is inside INLINE_SEARCH - then returns the node,
   * otherwise returns null
   */
  const findFocusedInlineSearch = (value: Value) => {
    const { selection, document } = value
    const key = selection.start.key as Path
    const selected = document.getParent(key) as Inline

    return selected && selected.type === INLINE_SEARCH ? selected : null
  }

  const onGatewayKeyDown = (event: KeyboardEvent, editor: Editor) => {
    event.preventDefault()
    /**
     * Insert inline and move within
     */
    insertEmptyInlineSearchNode(editor)
    editor.moveBackward(1)
  }

  /**
   * Replace INLINE_SEARCH node with text
   */
  const convertInlineSearchToText = (editor: Editor, node?: Inline) => {
    const { value } = editor
    const inlineSearchNode = node || findFocusedInlineSearch(value)

    if (inlineSearchNode) {
      editor.unwrapInlineByKey(inlineSearchNode.key, INLINE_SEARCH) // .insertText(text)
    }
  }

  const onEnter = (event: KeyboardEvent, editor: Editor, next: Function) => {
    const { value } = editor
    const selected = findFocusedInlineSearch(value)

    event.preventDefault()

    if (!selected) {
      return next()
    }

    convertInlineSearchToText(editor)
  }

  const convertToClinicalCode = (
    event: KeyboardEvent,
    editor: Editor,
    next: Function
  ) => {
    const { value } = editor
    const selected = findFocusedInlineSearch(value)

    if (selected) {
      event.preventDefault()

      const innerText = value.startText ? value.startText.text : null

      if (innerText) {
        editor.removeNodeByKey(selected.key)

        insertClinicalCode(editor, {
          term: innerText,
          code: 'foo', // TODO: Add code
        })

        editor.moveToEndOfInline().moveForward()
      }

      return null
    }

    return next()
  }

  /**
   * Handler makes sure that user cannot get outside of the INLINE_SEARCH
   * boundaries
   *
   * Only call when inline search is focused
   */
  const onArrowRight = (
    event: KeyboardEvent,
    editor: Editor,
    next: Function
  ) => {
    event.preventDefault()

    const { value } = editor
    const { offset } = value.selection.end
    const { length } = value.focusText.text

    if (offset + 1 >= length) {
      return null
    }

    return next()
  }
  /**
   * Handler makes sure that user cannot get outside of the INLINE_SEARCH
   * boundaries
   *
   * Only call when inline search is focused
   */
  const onArrowLeft = (
    event: KeyboardEvent,
    editor: Editor,
    next: Function
  ) => {
    event.preventDefault()

    const { offset } = editor.value.selection.start

    if (offset <= 1) {
      return null
    }

    return next()
  }

  const blockEvent = (event: KeyboardEvent) => {
    event.preventDefault()
  }

  const getBlurredInlineSearchNotes = (value: Value) => {
    const inlines = value.document.getInlines().filter((inline) => {
      return !!inline && inline.type === INLINE_SEARCH
    })

    const focusedInlineSearch = findFocusedInlineSearch(value)

    if (!focusedInlineSearch) {
      return inlines
    }

    return inlines.filter((inline) => {
      return !!inline && inline.key !== focusedInlineSearch.key
    })
  }

  const findInlineSearchNodeByKey = (
    value: Value,
    key: string
  ): Inline | null => {
    const inlineSearchNode = value.document.findDescendant((node) => {
      return (
        node.object === 'inline' &&
        node.type === INLINE_SEARCH &&
        node.key === key
      )
    })

    return inlineSearchNode ? (inlineSearchNode as Inline) : null
  }

  const destructors: Dictionary<any> = {}

  const removeDestructor = (key: string) => {
    if (destructors[key]) {
      clearTimeout(destructors[key])
      delete destructors[key]
    }
  }

  const addDestructor = (editor: Editor, inline: Inline) => {
    if (!inline || destructors[inline.key]) {
      return
    }

    const { key } = inline

    destructors[key] = setTimeout(() => {
      const node = findInlineSearchNodeByKey(editor.value, key)

      if (node) {
        convertInlineSearchToText(editor, node)
        removeDestructor(node.key)
      }
    }, 6000)
  }

  return {
    onChange: (editor: Editor) => {
      const { value } = editor
      const focusedInlineSearchNode = findFocusedInlineSearch(value)
      const blurredInlineSearchNodes = getBlurredInlineSearchNotes(value)

      if (focusedInlineSearchNode) {
        removeDestructor(focusedInlineSearchNode.key)
      }

      blurredInlineSearchNodes.forEach((inline) => {
        if (inline) {
          addDestructor(editor, inline)
        }
      })
    },

    onKeyDown: (event: KeyboardEvent, editor: Editor, next: Function) => {
      const inlineSearch: Inline | null = findFocusedInlineSearch(editor.value)
      /**
       * Use these handlers only when we are focused on inline search component
       */
      if (inlineSearch) {
        switch (event.key) {
          case 'Escape':
          case ':':
            return convertToClinicalCode(event, editor, next)
          case 'Enter':
            return onEnter(event, editor, next)
          case 'ArrowLeft':
            return onArrowLeft(event, editor, next)
          case 'ArrowRight':
            return onArrowRight(event, editor, next)
          case 'ArrowUp':
          case 'ArrowDown':
            return blockEvent(event)

          default:
            return next()
        }
      }
      /**
       * Create a new inline search component
       */
      if (event.key === GATEWAY_KEY) {
        return onGatewayKeyDown(event, editor)
      }

      return next()
    },
    renderNode: (props: RenderInlineProps, editor: Editor, next: Function) => {
      const { attributes, children, node, isFocused } = props

      if (node.type === INLINE_SEARCH) {
        return (
          <InputSearchInline {...attributes} isFocused={isFocused}>
            {children}
          </InputSearchInline>
        )
      }

      return next()
    },
  }
}

export default InlineSearch
