import React from 'react'

import ClinicianRating from '../ClinicianRating'
import ProfileImage from '../ProfileImage'

import styles from './styles.module.scss'

interface DropdownProfileProps {
  avatarUrl: string | null | undefined
  name: string
  clinicianRatingEnabled: boolean
  consultantId: string
}

/**
 * Renders consultant's profile info & rating
 */
const DropdownProfile: React.FC<DropdownProfileProps> = ({
  avatarUrl,
  name,
  clinicianRatingEnabled,
  consultantId,
}) => (
  <div className={styles.profile} data-testid="consultant-profile-dropdown">
    <ProfileImage url={avatarUrl} size={46} />
    <div className={styles.name}>{name}</div>
    {clinicianRatingEnabled && <ClinicianRating consultantId={consultantId} />}
  </div>
)

export default DropdownProfile
