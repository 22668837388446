import React, { useState, useEffect } from 'react'
import { Button } from '@babylon/core-ui'
import { retrieveComment, saveComment } from '../../../utils/profileComments'

import styles from '../styles.module.scss'

import LimitTextarea from '../LimitTextarea'

export default ({ profileCode, reportId }) => {
  const existingComment = retrieveComment(reportId, profileCode)

  const [formOpen, setFormOpen] = useState(!!existingComment)
  const [comment, updateComment] = useState(existingComment)

  useEffect(() => {
    saveComment(reportId, profileCode, comment)
  }, [reportId, profileCode, comment])

  return (
    <div className={styles.report__profileCommentForm}>
      {!formOpen ? (
        <Button intent="secondary" onClick={() => setFormOpen(true)}>
          Add comment
        </Button>
      ) : (
        <>
          <h3 className={styles.report__subheading}>GP Comments</h3>
          <LimitTextarea
            value={comment}
            placeholder="Optional comment for the user on the results"
            onChange={(event) => updateComment(event.target.value)}
            charLimit={1000}
          />
        </>
      )}
    </div>
  )
}
