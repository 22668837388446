import cx from 'classnames'
import React from 'react'

import Tooltip from '~/ui/Tooltip'

import styles from './styles.module.scss'

type TooltipProps = React.ComponentProps<typeof Tooltip>

interface LabelProps {
  value?: React.ReactNode
  overlayStyle?: TooltipProps['overlayStyle']
  info?: React.ReactNode
  infoPlacement?: TooltipProps['placement']
  className?: string
  dataTestId?: string
}

const Label: React.FC<LabelProps> = ({
  value,
  info,
  infoPlacement,
  overlayStyle,
  children,
  className,
  dataTestId,
}) => {
  return (
    <div className={styles.labelWrapper} data-testid={dataTestId}>
      <label className={cx(styles.label, className)}>
        {value}{' '}
        {info ? (
          <Tooltip
            info={info}
            overlayStyle={overlayStyle}
            placement={infoPlacement || 'top'}
          />
        ) : null}
      </label>
      {children}
    </div>
  )
}

export default Label
