import { defineMessages } from 'react-intl'

const baseId = 'consultation.modal.referrals'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private referral',
  },
  category_label: {
    id: `${baseId}.category_label`,
    defaultMessage: 'Category*',
  },
  category_placeholder: {
    id: `${baseId}.category_placeholder`,
    defaultMessage: 'Choose a category',
  },
  specialism_label: {
    id: `${baseId}.specialism_label`,
    defaultMessage: 'Specialism*',
  },
  specialism_placeholder: {
    id: `${baseId}.specialism_placeholder`,
    defaultMessage: 'Choose a specialism',
  },
  postcode_label: {
    id: `${baseId}.postcode_label`,
    defaultMessage: 'Postcode',
  },
  postcode_placeholder: {
    id: `${baseId}.postcode_placeholder`,
    defaultMessage: "Specialist's postcode",
  },
  lookup_button_label: {
    id: `${baseId}.lookup_button_label`,
    defaultMessage: 'Look up',
  },
  specialist_label: {
    id: `${baseId}.specialist_label`,
    defaultMessage: 'Specialist',
  },
  specialist_placeholder: {
    id: `${baseId}.specialist_placeholder`,
    defaultMessage: 'Choose a specialism',
  },
  comments_label: {
    id: `${baseId}.comments_label`,
    defaultMessage: 'Comments',
  },
  comments_add_button_label: {
    id: `${baseId}.comments_add_button_label`,
    defaultMessage: 'Add a comments',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  create_button_label: {
    id: `${baseId}.create_button_label`,
    defaultMessage: 'Create',
  },
  update_button_label: {
    id: `${baseId}.update_button_label`,
    defaultMessage: 'Update',
  },
  option_urgent_label: {
    id: `${baseId}.option_urgent_label`,
    defaultMessage: 'Urgent',
  },
  referral_with_specialism_already_exists: {
    id: `${baseId}.referral_with_specialism_already_exists`,
    defaultMessage: 'A referral with this specialism already exists',
  },
  please_choose_different_specialism: {
    id: `${baseId}.please_choose_different_specialism`,
    defaultMessage:
      'Please choose a different specialism or remove the existing one',
  },
  ok: {
    id: `${baseId}.ok`,
    defaultMessage: 'OK',
  },
})
