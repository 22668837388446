import { useCallback } from 'react'

import { APP_NAME, APP_VERSION } from '~/constants'
import { logException } from '~/core/sentry'
import { CallStep, useReportCallStepMutation } from '~/generated'

const useReportCallStep = (consultationId: string) => {
  const [reportCallStepMutation, result] = useReportCallStepMutation()

  const reportCallStep = useCallback(
    async (step: CallStep) => {
      try {
        await reportCallStepMutation({
          variables: {
            consultationId,
            step,
            appName: APP_NAME,
            appVersion: APP_VERSION,
          },
        })
      } catch (e) {
        logException(e)
      }
    },
    [reportCallStepMutation, consultationId]
  )

  return [reportCallStep, result] as const
}

export default useReportCallStep
