import { format } from 'date-fns'
import React from 'react'

import { Spinner } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import {
  useCarePlanDraftQuery,
  useCarePlanPublishedInConsultationQuery,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import GoalsAndActions from '../CarePlanSection/CarePlanSectionView/GoalsAndActions'

import messages from './CarePlansSummary.messages'

const CarePlanReviewSection = ({
  patientUuid,
  consultationUuid,
  isComplete,
}: {
  patientUuid: string
  consultationUuid: string
  isComplete: boolean
}) => {
  const f = useMessages(messages)
  const formattedTimeZoneOffset = format(new Date(), 'XXX')
  const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }

  const {
    loading: carePlanDraftLoading,
    error: carePlanDraftError,
    data: carePlanDraftData,
    refetch: carePlanDraftRefetch,
  } = useCarePlanDraftQuery({
    fetchPolicy: 'network-only',
    variables: {
      patientId: patientUuid,
      consultationId: consultationUuid,
    },
    context,
    skip: isComplete,
  })

  const carePlanDraft =
    carePlanDraftData?.carePlanDraft.__typename === 'CarePlanDraft'
      ? carePlanDraftData.carePlanDraft
      : undefined

  const {
    loading: carePlanRevisionLoading,
    error: carePlanRevisionError,
    data: carePlanRevisionData,
    refetch: carePlanRevisionRefetch,
  } = useCarePlanPublishedInConsultationQuery({
    fetchPolicy: 'network-only',
    variables: {
      patientId: patientUuid,
      consultationId: consultationUuid,
    },
    context,
  })

  const carePlan =
    carePlanRevisionData?.carePlanPublishedInConsultation.__typename ===
    'CarePlan'
      ? carePlanRevisionData.carePlanPublishedInConsultation
      : undefined

  if (carePlanDraftLoading || carePlanRevisionLoading) {
    return (
      <Section title={f('section_title')}>
        <Spinner size="small" />
      </Section>
    )
  }

  const resultError = carePlanDraftError || carePlanRevisionError
  const refetch = carePlanDraftError
    ? carePlanDraftRefetch
    : carePlanRevisionRefetch
  const retryTitle = carePlanDraftError
    ? f('cant_load_care_plan_draft')
    : f('cant_load_care_plan')
  if (resultError) {
    return (
      <Section title={f('section_title')}>
        <ErrorPanel
          error={resultError}
          fill="content"
          retry={refetch}
          title={retryTitle}
        />
      </Section>
    )
  }

  const goals =
    carePlan !== undefined ? carePlan?.goals || [] : carePlanDraft?.goals || []

  if (goals.length === 0) {
    return null
  }

  const consultationTag = isComplete
    ? ''
    : carePlanDraft
    ? f('tag_draft')
    : f('tag_published')

  return (
    <Section
      title={f('section_title')}
      testid="cr-care-plan"
      tag={consultationTag}
    >
      <GoalsAndActions goals={goals} />
    </Section>
  )
}

export default CarePlanReviewSection
