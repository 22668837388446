import { ApolloCache } from '@apollo/client'
import { IntlFormatters } from 'react-intl'

import {
  ERROR_REMOVE_REFERRAL_ACTION,
  REFERRAL_ACTIONS_CATEGORY,
  REMOVE_REFERRAL_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { errorAlert } from '~/core/with-error-message'
import { useRemoveReferralMutation } from '~/generated'
import { Dialog } from '~/ui/Dialog'

import messages from './Referrals.messages'

interface HandleRemoveReferralProps {
  formatMessage: IntlFormatters['formatMessage']
  referralId: string
  removeReferralMutation: ReturnType<typeof useRemoveReferralMutation>[0]
  markFinalizedConsultationAsEdited?: () => void
}
export const handleRemoveReferral = ({
  formatMessage,
  referralId,
  removeReferralMutation,
  markFinalizedConsultationAsEdited,
}: HandleRemoveReferralProps) => {
  Dialog.show({
    message: formatMessage(messages.delete_referral_confirmation_message),
    cancelLabel: formatMessage(messages.delete_referral_cancel_button_label),
    okLabel: formatMessage(messages.delete_referral_remove_button_label),
    onOkStyle: 'negative',
    centerContent: true,
    onOk: async () => {
      try {
        await removeReferralMutation({
          variables: {
            referralId,
          },
          update: (cache: ApolloCache<any>) => {
            const deletedReferral = cache.identify({
              __typename: 'ConsultationReferral',
              id: referralId,
            })
            cache.evict({ id: deletedReferral })
            cache.gc()
          },
        })

        markFinalizedConsultationAsEdited?.()

        analytics.trackEvent({
          action: REMOVE_REFERRAL_ACTION,
          category: REFERRAL_ACTIONS_CATEGORY,
          label: referralId,
        })
      } catch (err) {
        analytics.trackEvent({
          action: ERROR_REMOVE_REFERRAL_ACTION,
          category: REFERRAL_ACTIONS_CATEGORY,
          label: referralId,
        })
        errorAlert({ msg: null, logMessage: err })
      }
    },
  })
}
