interface IClinicalPortalError {
  title?: string
  message?: string
  description?: string
  originalError?: any
  errorCode?: any
}

class ClinicalPortalError extends Error {
  public message: string

  public description?: string

  public title?: string

  public originalError?: any

  public errorCode?: any

  constructor({
    title,
    message,
    description,
    originalError,
    errorCode,
  }: IClinicalPortalError) {
    super(message)
    this.title = title
    this.message = message || title || 'Error'
    this.description = description
    this.originalError = originalError
    this.errorCode = errorCode
    // To make the created object an instance of ClinicalPortalError
    Object.setPrototypeOf(this, ClinicalPortalError.prototype)
  }
}

export default ClinicalPortalError
