import { defineMessages } from 'react-intl'

const baseId = 'prescription_audit_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Prescription Audit',
  },
  error_title: {
    id: `${baseId}.error_title`,
    defaultMessage: "We couldn't load this prescription audit",
  },
})
