import { defineMessages } from 'react-intl'

const baseId = 'consultation.past_consultation_title'

export default defineMessages({
  this_consultation_event_title: {
    id: `${baseId}.this_consultation_event_title_v2`,
    defaultMessage: 'This consultation',
  },
  consultation_event_title: {
    id: `${baseId}.consultation_event_title`,
    defaultMessage: 'Consultation',
  },
  incomplete_event_title: {
    id: `${baseId}.incomplete_event_title_v2`,
    defaultMessage: 'Incomplete',
  },
  no_show_event_title: {
    id: `${baseId}.no_show_event_title`,
    defaultMessage: 'No-show booking',
  },
  consultation_event_with: {
    id: `${baseId}.consultation_event_title_with`,
    defaultMessage: 'with {consultantName}',
  },
  my_consultation_label: {
    id: `${baseId}.my_consultation_label`,
    defaultMessage: 'MY CONSULTATION',
  },
  incomplete_label: {
    id: `${baseId}.incomplete_label`,
    defaultMessage: 'INCOMPLETE',
  },
  attachments: {
    id: `${baseId}.attachments`,
    defaultMessage: 'Attachments',
  },
  future_event_prefix: {
    id: `${baseId}.future_event_prefix`,
    defaultMessage: 'Future',
  },
})
