import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history.timeline_consultation_actions'

export default defineMessages({
  prescriptions_label: {
    id: `${baseId}.prescriptions_label`,
    defaultMessage: 'Prescriptions: ',
  },
  tests_label: {
    id: `${baseId}.tests_label`,
    defaultMessage: 'Tests: ',
  },
  unknown_test_tag: {
    id: `${baseId}.unknown_test_tag`,
    defaultMessage: 'Unknown test',
  },
  referrals_label: {
    id: `${baseId}.referrals_label`,
    defaultMessage: 'Referrals: ',
  },
  timeline_test_results_label: {
    id: `${baseId}.timeline_test_results_label`,
    defaultMessage: 'Test Results:',
    description: 'Label of the test results item on consultation in history',
  },
  timeline_test_results_content: {
    id: `${baseId}.timeline_test_results_content`,
    defaultMessage: `{testResultCount, plural,
      one {{testResultCount} result available}
      other {{testResultCount} results available}
    }`,
    description: 'Display number of test results',
  },
  type_label: {
    id: `${baseId}.type_label`,
    defaultMessage: 'Type: ',
  },
  possible_actions: {
    id: `${baseId}.possible_actions`,
    defaultMessage: 'Possible actions',
  },
  sick_notes_actions_tag_label: {
    id: `${baseId}.sick_notes_actions_tag_label`,
    defaultMessage: 'Sick notes',
  },
  error_title: {
    id: `${baseId}.error_title`,
    defaultMessage: 'Error loading actions for this consultation',
  },
  workflow_label: {
    id: `${baseId}.workflow_label`,
    defaultMessage: '{label}: ',
  },
  appointment_invites_label: {
    id: `${baseId}.appointment_invites_label`,
    defaultMessage: 'Appointment invites: ',
  },
  no_service_type: {
    id: `${baseId}.no_service_type`,
    defaultMessage: 'No service type',
  },
})
