import React from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './CarePlanSectionView.messages'
import styles from './styles.module.scss'

const Introduction = () => {
  const fm = useFormatMessage()

  return (
    <>
      <Text
        data-testid="intro"
        size="medium"
        color="grey-type"
        tag="p"
        className={styles.introduction}
      >
        {fm(messages.introduction_part_1)}
      </Text>
      <Text
        size="medium"
        color="grey-type"
        tag="p"
        className={styles.introduction}
      >
        {fm(messages.introduction_part_2)}
      </Text>
    </>
  )
}

export default Introduction
