import {
  publisherEventNames,
  sessionEventNames,
  subscriberEventNames,
} from '../constants'
import { OTEventHandlerObject, PushEventHandlers } from './types'
import useMergeEventHandlers from './useMergeEventHandlers'

type UseOpentokEventHandlers = (input: {
  initialSessionEventHandlers?: OTEventHandlerObject
  initialSubscriberEventHandlers?: OTEventHandlerObject
  initialPublisherEventHandlers?: OTEventHandlerObject
}) => {
  sessionEventHandlers: OTEventHandlerObject | undefined
  addSessionEventHandlers: PushEventHandlers
  subscriberEventHandlers: OTEventHandlerObject | undefined
  addSubscriberEventHandlers: PushEventHandlers
  publisherEventHandlers: OTEventHandlerObject | undefined
  addPublisherEventHandlers: PushEventHandlers
}

const useOpentokEventHandlers: UseOpentokEventHandlers = ({
  initialSessionEventHandlers,
  initialSubscriberEventHandlers,
  initialPublisherEventHandlers,
}) => {
  const [sessionEventHandlers, addSessionEventHandlers] = useMergeEventHandlers(
    sessionEventNames,
    initialSessionEventHandlers
  )
  const [
    subscriberEventHandlers,
    addSubscriberEventHandlers,
  ] = useMergeEventHandlers(
    subscriberEventNames,
    initialSubscriberEventHandlers
  )
  const [
    publisherEventHandlers,
    addPublisherEventHandlers,
  ] = useMergeEventHandlers(publisherEventNames, initialPublisherEventHandlers)

  return {
    sessionEventHandlers,
    addSessionEventHandlers,
    subscriberEventHandlers,
    addSubscriberEventHandlers,
    publisherEventHandlers,
    addPublisherEventHandlers,
  }
}

export default useOpentokEventHandlers
