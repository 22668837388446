import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review.prescriptions'

export default defineMessages({
  prescriptions_section_title_private: {
    id: `${baseId}.prescriptions_section_title_private_v2`,
    defaultMessage: 'Private prescriptions',
  },
  prescriptions_loading_error: {
    id: `${baseId}.prescriptions_loading_error`,
    defaultMessage: 'Error loading prescriptions for this consultation',
  },
})
