import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.appt_invites'

export default defineMessages({
  medium_key: {
    id: `${baseId}.medium_key`,
    defaultMessage: 'Medium',
  },
  medium_physical: {
    id: `${baseId}.medium_physical`,
    defaultMessage: 'Face to face',
  },
  medium_video: {
    id: `${baseId}.medium_video`,
    defaultMessage: 'Video',
  },
  medium_voice: {
    id: `${baseId}.medium_voice`,
    defaultMessage: 'Voice',
  },
  medium_text: {
    id: `${baseId}.medium_text`,
    defaultMessage: 'Text',
  },
  type_key: {
    id: `${baseId}.type_key`,
    defaultMessage: 'Type',
  },
  clinician_key: {
    id: `${baseId}.clinician_key`,
    defaultMessage: 'Clinician',
  },
  reason_key: {
    id: `${baseId}.reason_key`,
    defaultMessage: 'Reason',
  },
  no_service_type: {
    id: `${baseId}.no_service_type`,
    defaultMessage: 'No service type',
  },
})
