import { sanitizeUrl } from '@braintree/sanitize-url'
import React, { HTMLProps } from 'react'

import { pickTruthyValues } from '@babylon/shared-utils'

import styles from './styles.module.scss'

interface ProfileImageProps extends HTMLProps<HTMLDivElement> {
  url: string | null | undefined
  size: number
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  url,
  size,
  style = {},
}) => (
  <div
    className={styles.image}
    style={pickTruthyValues({
      ...style,
      backgroundImage: url && `url(${sanitizeUrl(url)})`,
      width: size,
      height: size,
    })}
  />
)

export default ProfileImage
