import PropTypes from 'prop-types'
import React from 'react'

import { Alert, Button, Cell, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import TooltipLabel from '~/ui/Label'

import AppointmentDuration from '../AppointmentDuration'
import AppointmentReason from '../AppointmentReason'
import {
  defaultDurationOptions,
  getDurationKeyValuePair,
} from '../utils/duration'
import { AugmentedServiceType, getServiceType } from '../utils/serviceType'
import AppointmentChaperone from './AppointmentChaperone'
import AppointmentServiceTypes from './AppointmentServiceTypes'
import FirstAvailableSlot from './FirstAvailableSlot'

import messages from '../NhsAppointment.messages'

type F2FFormValues = {
  duration?: number
  reason?: string
  intimate?: boolean
  mediumType: 'physical' | 'digital'
  selectedServiceTypeId?: string
  serviceTypeCategory?: string
  urgency?: 'routine' | 'urgent'
}

interface F2FAppointmentFormProps {
  values: F2FFormValues
  serviceTypes: AugmentedServiceType[]
  setFieldValue: () => void
}

const F2FAppointmentForm = ({
  values,
  serviceTypes,
  setFieldValue,
}: F2FAppointmentFormProps) => {
  const { selectedServiceTypeId } = values
  const fm = useFormatMessage()

  const selectedServiceType = selectedServiceTypeId
    ? getServiceType(serviceTypes, selectedServiceTypeId)
    : null

  const durationOptions = selectedServiceType
    ? selectedServiceType.duration_options.map((duration, index) =>
        getDurationKeyValuePair(duration, index === 0)
      )
    : defaultDurationOptions

  const availableSlot = selectedServiceType
    ? selectedServiceType.first_available_slot
    : null

  return (
    <Grid columns={1}>
      <AppointmentServiceTypes
        serviceTypes={serviceTypes || []}
        setFieldValue={setFieldValue}
      />

      <Grid columns={2}>
        <AppointmentChaperone />
        <Cell>
          <TooltipLabel
            value={fm(messages.duration_service_label)}
            info={fm(messages.duration_tooltip)}
            infoPlacement="right"
            overlayStyle={{ width: 300, whiteSpace: 'normal' }}
          />
          <AppointmentDuration options={durationOptions} />
        </Cell>
      </Grid>

      {selectedServiceType && availableSlot && (
        <FirstAvailableSlot
          serviceName={selectedServiceType.name}
          slot={availableSlot.time}
        />
      )}

      <AppointmentReason />
      <Alert intent="primary">{fm(messages.ice_message)}</Alert>

      <Grid columns={4} style={{ marginTop: 16 }}>
        <Cell middle width={3}>
          <Text
            color="grey-type"
            style={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}
          >
            {fm(messages.next_clinician_message)}
          </Text>
        </Cell>
        <Cell>
          <Button type="submit" style={{ float: 'right' }}>
            {fm(messages.send_to_patient_button_label)}
          </Button>
        </Cell>
      </Grid>
    </Grid>
  )
}

F2FAppointmentForm.propTypes = {
  values: PropTypes.shape({
    duration: PropTypes.number,
    reason: PropTypes.string,
    intimate: PropTypes.bool,
    mediumType: PropTypes.string.isRequired,
    selectedServiceTypeId: PropTypes.string,
    serviceTypeCategory: PropTypes.string,
    urgency: PropTypes.any,
  }),
}

export default F2FAppointmentForm
