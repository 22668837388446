import range from 'lodash/range'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../NhsAppointment.messages'

const MAX_GENERATED_DURATION: number = 30
export const DEFAULT_DURATION: number = 10

interface DurationOption {
  key: number
  value: React.ReactNode
}

export const generateDurations = (minDuration: number): number[] =>
  minDuration > MAX_GENERATED_DURATION
    ? [minDuration]
    : range(minDuration, MAX_GENERATED_DURATION + 1, minDuration)

export const getDurationKeyValuePair = (
  val: number,
  isDefault: boolean
): DurationOption => ({
  key: val,
  value: (
    <FormattedMessage
      {...(isDefault
        ? messages.duration_minutes_label_default
        : messages.duration_minutes_label)}
      values={{ time: val }}
    />
  ),
})

export const defaultDurationOptions: DurationOption[] = generateDurations(
  DEFAULT_DURATION
).map((val, index) => getDurationKeyValuePair(val, index === 0))
