import * as LegacyTwilio from 'twilio-client'

import { IVoiceCallService } from '~/features/call/VoiceCall/VoiceCallService'

export class LegacyVoiceCallService implements IVoiceCallService {
  device: LegacyTwilio.Device | null

  connection?: LegacyTwilio.Connection | null

  calling: boolean

  constructor() {
    this.device = null
    this.calling = false

    this.connection = null
  }

  setup(token: string) {
    this.teardown()
    this.device = new LegacyTwilio.Device()
    this.device.setup(token)
  }

  start(
    appointmentId: string,
    consultationId: string,
    patientId: string,
    encryptedVoiceParams: string | null | undefined
  ) {
    if (!this.device) {
      throw new Error('Cannot start twilio call without device')
    }

    if (this.calling) {
      throw new Error('Twilio call already active')
    }

    const twilioParams: Record<string, string> = {
      AppointmentID: appointmentId,
      ConsultationID: consultationId,
      PatientID: patientId,
    }
    if (encryptedVoiceParams) {
      twilioParams.EncryptedVoiceParams = encryptedVoiceParams
    }

    this.connection = this.device.connect(twilioParams)
    this.calling = true
  }

  teardown() {
    this.calling = false
    if (this.device) {
      this.device.disconnectAll()
      this.device.removeAllListeners()
      this.device.destroy()
    }
  }
}
