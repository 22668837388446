import { defineMessages } from 'react-intl'

const baseId = 'test_report_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Test result {withMessage}',
  },
  title_clinician: {
    id: `${baseId}.title_clinician`,
    defaultMessage: 'reviewed by {name}',
  },
  sent_at: {
    id: `${baseId}.sent_at`,
    defaultMessage: 'Sent at: ',
  },
  appointment_suggested: {
    id: `${baseId}.appointment_suggested`,
    defaultMessage:
      'Results were marked as "abnormal" and an appointment was suggested.',
  },
  appointment_not_suggested: {
    id: `${baseId}.appointment_not_suggested`,
    defaultMessage:
      'Results were marked as "normal / mildly abnormal" and no appointment was suggested.',
  },
})
