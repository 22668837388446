import React from 'react'

import { Grid, Heading } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  LINKS_AND_RESOURCES_MODAL,
  LINKS_AND_RESOURCES_MODAL_CATEGORY,
  LINKS_AND_RESOURCES_MODAL_VIEWING_DURATION_ACTION,
} from '~/constants/analytics'
import { useModalViewAndDurationTracker } from '~/core/analytics'
import { ErrorPanelView } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'

import { LinksAndResourcesItem } from '../useLinksAndResources'
import LinkList from './LinkList'

import messages from './LinksAndResourcesModal.messages'

export interface LinksAndResourcesModalProps {
  onClose: Function
  babylonLinks: LinksAndResourcesItem[]
  resources: LinksAndResourcesItem[]
  error?: Error
}

const LinksAndResourcesModal: React.FC<LinksAndResourcesModalProps> = ({
  onClose,
  babylonLinks,
  resources,
  error,
}) => {
  const fm = useFormatMessage()

  useModalViewAndDurationTracker({
    modalName: LINKS_AND_RESOURCES_MODAL,
    action: LINKS_AND_RESOURCES_MODAL_VIEWING_DURATION_ACTION,
    category: LINKS_AND_RESOURCES_MODAL_CATEGORY,
  })

  return (
    <Slideout title={fm(messages.title)} onClose={onClose}>
      <Grid columns={1} gap={24}>
        <div>
          <Heading level="h3" margin>
            {fm(messages.babylon_links_title)}
          </Heading>
          {error ? (
            <ErrorPanelView
              title={fm(messages.babylon_links_error_title)}
              description={fm(messages.babylon_links_error_description)}
            />
          ) : (
            <LinkList testid="babylon-links-list" links={babylonLinks} />
          )}
        </div>
        <div>
          <Heading level="h3" margin>
            {fm(messages.useful_resources_title)}
          </Heading>
          {error ? (
            <ErrorPanelView
              title={fm(messages.resources_error_title)}
              description={fm(messages.resources_error_description)}
            />
          ) : (
            <LinkList testid="useful-resources-list" links={resources} />
          )}
        </div>
      </Grid>
    </Slideout>
  )
}

export default LinksAndResourcesModal
