import React from 'react'

import { Cell, Tag, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ENABLE_CHATSCRIPT_DISCLAIMER } from '~/constants'
import { getTitleForType, isCovidFlow } from '~/features/chatbot-flow/utils'
import TimelineEvent from '~/features/patient-timeline/Timeline/components/TimelineEvent'
import { ChatscriptConversation } from '~/generated'

import { isEventInFuture } from '../../utils'

import timelineTitleMessages from '../TimelineConsultationTitle/TimelineConsultationTitle.messages'
import styles from './styles.module.scss'
import messages from './TimelineChatscriptEvent.messages'

interface TimelineChatscriptEventProps {
  patientUuid: string
  event: Pick<
    ChatscriptConversation,
    'id' | 'preview' | 'eventTime' | 'eventType' | 'type' | 'result'
  >
}

const TimelineChatscriptEvent = ({
  patientUuid,
  event,
}: TimelineChatscriptEventProps) => {
  const fm = useFormatMessage()
  const { result, type, preview } = event
  const typedTitle: string = getTitleForType(
    isCovidFlow(result?.type) ? 'covid-19' : type,
    fm
  )

  const isFutureEvent = isEventInFuture(event.eventTime)
  const title = isFutureEvent
    ? `${fm(
        timelineTitleMessages.future_event_prefix
      )} ${typedTitle.toLowerCase()}`
    : typedTitle
  return (
    <TimelineEvent
      title={
        <div className={styles.titleContainer}>
          <Text className={styles.title} bold={false}>
            {title}
          </Text>
          <Text color="grey-type" bold={false}>
            {fm(messages.with_chatbot_meta)}
          </Text>
        </div>
      }
      event={event}
      patientUuid={patientUuid}
    >
      {ENABLE_CHATSCRIPT_DISCLAIMER && (
        <Text className={styles.disclaimer} color="grey-type" size="medium">
          {fm(messages.someone_else_disclaimer)}
        </Text>
      )}
      <div className={styles.eventContainer}>
        <Cell className={styles.outcomeLabel} area="label">
          <Text color="grey-type" bold size="medium">
            {fm(messages.outcome_label)}
          </Text>
        </Cell>

        <Cell area="outcome">
          <Tag
            className={styles.outcomeTag}
            color="reverse-grey"
            uppercase={false}
          >
            {result?.type?.replace(/_/g, ' ') || fm(messages.no_outcome)}
          </Tag>
        </Cell>
      </div>

      <pre style={{ whiteSpace: 'pre-wrap' }}>
        <Text tag="div" color="grey-type" size="medium">
          {preview}
        </Text>
      </pre>
    </TimelineEvent>
  )
}

export default TimelineChatscriptEvent
