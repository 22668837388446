import { ApolloError } from '@apollo/client'
import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import ImageGallery from '~/features/image-gallery'
import { PresentingComplaintFragment } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section } from '~/ui/Section'
import SectionItem from '~/ui/SectionItem'

import messages from './BookingReason.messages'

type BookingReasonSectionViewProps = {
  loading: boolean
  error?: ApolloError
  serviceType: string
} & PresentingComplaintFragment

const BookingReasonSectionView = ({
  patientNote,
  images,
  category,
  serviceType,
  loading,
  error,
}: BookingReasonSectionViewProps) => {
  const f = useMessages(messages)

  if (loading) {
    return <Section title={f('title_new')} subtitle={f('subtitle')} loading />
  }

  if (error) {
    return (
      <Section title={f('title_new')}>
        <ErrorPanel
          title={f('patient_note_error')}
          error={error || new Error('No Consultation')}
        />
      </Section>
    )
  }

  return (
    <Section
      title={f('title_new')}
      subtitle={f('subtitle')}
      dataTestId="complaint-section"
    >
      <SectionItem title={[category, serviceType].filter((n) => n).join(' > ')}>
        {patientNote ? (
          <Text style={{ whiteSpace: 'pre-wrap' }} size="large">
            {patientNote}
          </Text>
        ) : (
          f('empty')
        )}
      </SectionItem>

      <ImageGallery images={images} />
    </Section>
  )
}

export default BookingReasonSectionView
