import { useContext, useMemo } from 'react'

import context from './Context'

const usePermissions = (...permissionKeys: string[]) => {
  const getPermission = useContext(context)
  if (!getPermission) {
    throw Error(
      'usePermissions cannot be used outside of PermissionProvider context'
    )
  }

  return useMemo(
    () => permissionKeys.map((key) => Boolean(getPermission(key))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPermission, ...permissionKeys]
  )
}

export default usePermissions
