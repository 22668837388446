// TODO: generate this file instead of creating each typed observable query manually

import {
  CarePlanDraftDocument,
  CarePlanDraftQuery,
  CarePlanDraftQueryVariables,
  CpMarkConsultationAsCompleteDocument,
  CpMarkConsultationAsCompleteMutation,
  CpMarkConsultationAsCompleteMutationVariables,
  EhrConsultationNotesDocument,
  EhrConsultationNotesQuery,
  EhrConsultationNotesQueryVariables,
  InsuranceClaimDocument,
  InsuranceClaimQuery,
  InsuranceClaimQueryVariables,
  MarkEhrNotesSensitiveDocument,
  MarkEhrNotesSensitiveMutation,
  MarkEhrNotesSensitiveMutationVariables,
  PrescriptionStateDocument,
  PrescriptionStateQuery,
  PrescriptionStateQueryVariables,
  PromoteCarePlanDraftDocument,
  PromoteCarePlanDraftMutation,
  PromoteCarePlanDraftMutationVariables,
  PromotePrescriptionDocument,
  PromotePrescriptionMutation,
  PromotePrescriptionMutationVariables,
  SetConsultationExemptedDocument,
  SetConsultationExemptedMutation,
  SetConsultationExemptedMutationVariables,
  SetInsuranceClaimExemptedDocument,
  SetInsuranceClaimExemptedMutation,
  SetInsuranceClaimExemptedMutationVariables,
} from '~/generated'

import { mutation, watchQuery } from './apollo'

export const markConsultationAsCompleteMutation = () =>
  mutation<
    CpMarkConsultationAsCompleteMutation,
    CpMarkConsultationAsCompleteMutationVariables
  >(CpMarkConsultationAsCompleteDocument)

export const markEHRNotesSensitiveMutation = () =>
  mutation<
    MarkEhrNotesSensitiveMutation,
    MarkEhrNotesSensitiveMutationVariables
  >(MarkEhrNotesSensitiveDocument)

export const watchUsEhrNotesQuery = () =>
  watchQuery<EhrConsultationNotesQuery, EhrConsultationNotesQueryVariables>(
    EhrConsultationNotesDocument
  )

export const watchPrescriptionStateQuery = () =>
  watchQuery<PrescriptionStateQuery, PrescriptionStateQueryVariables>(
    PrescriptionStateDocument
  )

export const promotePrescriptionMutation = () =>
  mutation<PromotePrescriptionMutation, PromotePrescriptionMutationVariables>(
    PromotePrescriptionDocument
  )
export const watchInsuranceClaimQuery = () =>
  watchQuery<InsuranceClaimQuery, InsuranceClaimQueryVariables>(
    InsuranceClaimDocument
  )

export const setConsultationExemptedMutation = () =>
  mutation<
    SetConsultationExemptedMutation,
    SetConsultationExemptedMutationVariables
  >(SetConsultationExemptedDocument)

export const setInsuranceClaimExemptedMutation = () =>
  mutation<
    SetInsuranceClaimExemptedMutation,
    SetInsuranceClaimExemptedMutationVariables
  >(SetInsuranceClaimExemptedDocument)

export const watchCarePlansQuery = () =>
  watchQuery<CarePlanDraftQuery, CarePlanDraftQueryVariables>(
    CarePlanDraftDocument
  )

export const promoteCarePlanDraftMutation = () =>
  mutation<PromoteCarePlanDraftMutation, PromoteCarePlanDraftMutationVariables>(
    PromoteCarePlanDraftDocument
  )
