import { useConsultantUser } from '@babylon/babylon-user'

import { ENABLE_SIGNER } from '~/constants'
import { useModulePermission } from '~/core/module-permissions'
import { usePrescriptionsInfoQuery } from '~/generated'

const pollInterval = 5 * 60 * 1000

const useQuestionCount = () => {
  const user = useConsultantUser()
  const skip = !useModulePermission('prescriptions')

  const { data } = usePrescriptionsInfoQuery({
    pollInterval,
    skip,
    variables: {
      consultantId: user.id,
    },
  })

  if (!data || !data.prescriptionStatistics) {
    return 0
  }

  const toBePrintedCount = data.prescriptionStatistics.toBePrintedCount || 0
  const toBeSignedCount = data.prescriptionStatistics.toBeSignedCount || 0

  return toBePrintedCount + (ENABLE_SIGNER ? toBeSignedCount : 0)
}

export default useQuestionCount
