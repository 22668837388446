import { CallFlowStep } from '@babylon/connect-client-core'

import { MULTIMEDIA_METRICS_SERVICE_URL } from '~/constants'
import http from '~/core/http-client'

export const storeGetter = async (key: string): Promise<string> =>
  new Promise((resolve) => {
    resolve(localStorage.getItem(key) || '')
  })

export const storeSetter = (key: string, data: string): Promise<void> =>
  new Promise((resolve) => {
    localStorage.setItem(key, data)
    resolve()
  })

export const emitMmsCfs = async (payload: CallFlowStep) => {
  await http.post(
    `${MULTIMEDIA_METRICS_SERVICE_URL}/v1/call_flow_step`,
    payload
  )
}
