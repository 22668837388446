import { gql } from '@apollo/client'

export const GET_CACHED_TESTS = gql`
  query CPGetCachedTests($id: ID!) {
    consultation(id: $id) {
      id
      pathologyTests {
        id
      }
      diagnosticTests {
        id
      }
    }
  }
`

export const REMOVE_TEST_MUTATION = gql`
  mutation CPRemoveTest($consultationId: ID!, $type: String!, $id: String!) {
    removeConsultationTest(
      consultationId: $consultationId
      type: $type
      id: $id
    )
  }
`
