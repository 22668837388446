import React from 'react'

import { PreconsultationSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { usePreconsultationSummaryQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import PreconsultationSummary from './PreconsultationSummary'

import messages from './PreconsultationSummary.messages'

const PreconsultationSummaryModule: PreconsultationSummaryPluginInterface = () => {
  const PreconsultationSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)

    const { data, loading, error } = usePreconsultationSummaryQuery({
      variables: { id: consultation.id },
    })

    if (loading) {
      return null
    }

    if (error) {
      return <ErrorPanel error={error} title={f('error_title')} />
    }

    return <PreconsultationSummary consultation={data?.consultation} />
  }

  return PreconsultationSummaryWrapper
}

export default PreconsultationSummaryModule
