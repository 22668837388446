import { useState } from 'react'

import { useIntl } from '@babylon/intl'

const dateOrUndef = (date?: string | null) =>
  date ? new Date(date) : undefined

export const extractTimestamps = (metrics: any) => {
  return {
    timestampHeight: dateOrUndef(metrics?.height[0]?.timestamp),
    timestampWeight: dateOrUndef(metrics?.weight[0]?.timestamp),
    timestampSmokingStatus: dateOrUndef(metrics?.smoking_status[0]?.timestamp),
    timestampBloodPressure: dateOrUndef(
      metrics?.blood_pressure?.[0]?.timestamp
    ),
  }
}

export const useTimestampFormatter = () => {
  const { formatDate } = useIntl()
  return (date?: Date) => (date ? formatDate(date, { format: 'short' }) : '')
}

export const useTimestamp = (
  date?: Date
): [string, (value: string) => void] => {
  const timestampFormatter = useTimestampFormatter()

  return useState<string>(timestampFormatter(date))
}
