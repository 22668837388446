import { gql } from '@apollo/client'

export const CreateReferralMutation = gql`
  mutation CreateReferralMutation(
    $consultationId: ID!
    $input: ConsultationReferralInput!
  ) {
    createConsultationReferral(consultationId: $consultationId, input: $input) {
      id
      consultationId
      specialismId
      specialismCategoryId
      specialismName
      updatedAt
      urgent
      comments {
        id
        note
      }
      externalSpecialist {
        id
        name
      }
    }
  }
`

export const UpdateReferralMutation = gql`
  mutation UpdateReferralMutation(
    $referralId: ID!
    $input: ConsultationReferralInput!
  ) {
    updateReferral(referralId: $referralId, input: $input) {
      id
      consultationId
      specialismId
      specialismCategoryId
      specialismName
      updatedAt
      urgent
    }
  }
`
