import { getOperationName } from '@apollo/client/utilities'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { ConsultantUser } from '@babylon/babylon-user'

import { useFeatureFlags } from '~/core/core-modules'
import { useFetchSelfManagedServiceTypeUUID } from '~/features/header/Profile/useFetchSelfManagedServiceTypeUUID'
import {
  GetClinicianServiceTypesDocument,
  useAddClinicianToSelfManagedServiceTypeMutation,
  useGetClinicianServiceTypesQuery,
  useRemoveClinicianFromSelfManagedServiceTypeMutation,
} from '~/generated'

const notNull = <V,>(value: V | undefined | null): value is V => value != null

type HookReturnType = {
  featureEnabled: boolean
  loading: boolean
  availableForNewTherapy: boolean
  setAvailableForNewTherapy: (_: boolean) => void
}

type AvailableForNewTherapyState = {
  available: boolean
  setBy: 'theApp' | 'theUser'
}

const useCheckIfTherapyServiceTypeIsInConsultantsServiceTypes = (
  availableForNewTherapyToggleEnabled: boolean,
  user: ConsultantUser
) => {
  const {
    selfManagedServiceTypeUUID,
    error: selfManagedServiceTypeError,
    loading: isLoadingSelfManagedServiceTypeUUID,
  } = useFetchSelfManagedServiceTypeUUID({
    user,
    skip: !availableForNewTherapyToggleEnabled,
  })

  const {
    data: cliniciansServiceTypes,
    loading: loadingServiceTypes,
    error: serviceTypesError,
  } = useGetClinicianServiceTypesQuery({
    variables: { clinician_id: user.consultant.id },
    skip: !availableForNewTherapyToggleEnabled,
  })

  const isClinicianAddedToSelfManagedServiceType = useCallback(() => {
    if (selfManagedServiceTypeUUID == null) return false
    if (!cliniciansServiceTypes?.clinicianServiceTypes?.length) return false

    const serviceTypesUUIDs = cliniciansServiceTypes.clinicianServiceTypes
      .map((s) => s.id)
      .filter(notNull)

    return serviceTypesUUIDs.includes(selfManagedServiceTypeUUID)
  }, [
    cliniciansServiceTypes?.clinicianServiceTypes,
    selfManagedServiceTypeUUID,
  ])

  return {
    didEncounterError:
      selfManagedServiceTypeError != null || serviceTypesError != null,
    loading: loadingServiceTypes || isLoadingSelfManagedServiceTypeUUID,
    selfManagedServiceTypeUUID,
    isClinicianAddedToSelfManagedServiceType,
  }
}

const usePrepareMutations = () => {
  const [
    addClinicianToSelfManagedServiceType,
  ] = useAddClinicianToSelfManagedServiceTypeMutation({
    refetchQueries: [getOperationName(GetClinicianServiceTypesDocument)].filter(
      notNull
    ),
  })

  const [
    removeClinicianFromSelfManagedServiceType,
  ] = useRemoveClinicianFromSelfManagedServiceTypeMutation({
    refetchQueries: [getOperationName(GetClinicianServiceTypesDocument)].filter(
      notNull
    ),
  })
  return {
    addClinicianToSelfManagedServiceType,
    removeClinicianFromSelfManagedServiceType,
  }
}

const usePrepareToggleMutation = (
  user: ConsultantUser,
  isClinicianAddedToSelfManagedServiceType: () => boolean
) => {
  const {
    addClinicianToSelfManagedServiceType,
    removeClinicianFromSelfManagedServiceType,
  } = usePrepareMutations()

  const [toggleIfClinicianIsAddedToSelfManagedService] = useDebouncedCallback(
    useCallback(
      (
        shouldBeAddedOrRemoved: 'toBeAdded' | 'toBeRemoved',
        serviceTypeUUID: string
      ) => {
        const isClinicianCurrentlyAdded = isClinicianAddedToSelfManagedServiceType()

        if (
          isClinicianCurrentlyAdded &&
          shouldBeAddedOrRemoved === 'toBeRemoved'
        ) {
          removeClinicianFromSelfManagedServiceType({
            variables: {
              clinician_id: user.consultant.id,
              service_type_uuid: serviceTypeUUID,
            },
          })
        } else if (
          !isClinicianCurrentlyAdded &&
          shouldBeAddedOrRemoved === 'toBeAdded'
        ) {
          addClinicianToSelfManagedServiceType({
            variables: {
              clinician_id: user.consultant.id,
              service_type_uuid: serviceTypeUUID,
            },
          })
        }
      },
      [
        user.consultant.id,
        isClinicianAddedToSelfManagedServiceType,
        addClinicianToSelfManagedServiceType,
        removeClinicianFromSelfManagedServiceType,
      ]
    ),
    500
  )
  return toggleIfClinicianIsAddedToSelfManagedService
}

const useUpdateToggleStateWithBackendState = (
  loadingBackendState: boolean,
  setAvailableForNewTherapyState: (_: AvailableForNewTherapyState) => void,
  isClinicianAddedToSelfManagedServiceType: () => boolean
) => {
  useEffect(() => {
    if (!loadingBackendState) {
      setAvailableForNewTherapyState({
        available: isClinicianAddedToSelfManagedServiceType(),
        setBy: 'theApp',
      })
    }
  }, [
    isClinicianAddedToSelfManagedServiceType,
    loadingBackendState,
    setAvailableForNewTherapyState,
  ])
}

const useToggleStateOnUserActions = (
  availableForNewTherapyState: AvailableForNewTherapyState,
  user: ConsultantUser,
  isClinicianAddedToSelfManagedServiceType: () => boolean,
  selfManagedServiceTypeUUID?: string
) => {
  const toggleIfClinicianIsAddedToSelfManagedService = usePrepareToggleMutation(
    user,
    isClinicianAddedToSelfManagedServiceType
  )
  useEffect(() => {
    if (
      availableForNewTherapyState.setBy === 'theUser' &&
      selfManagedServiceTypeUUID != null
    ) {
      toggleIfClinicianIsAddedToSelfManagedService(
        availableForNewTherapyState.available ? 'toBeAdded' : 'toBeRemoved',
        selfManagedServiceTypeUUID
      )
    }
  }, [
    toggleIfClinicianIsAddedToSelfManagedService,
    availableForNewTherapyState,
    selfManagedServiceTypeUUID,
    user.consultant.id,
  ])
}

/**
 * Manages the state of the 'availableForNewTherapy' toggle.
 * It follows the optimistic pattern for the toggle - the API call is sent with a small delay until user picks the correct value (it's 'debounced').
 * @param user - needs to have constant reference to the object (used in hooks 'deps' list)
 */
export const useAvailableForNewTherapy = (
  user: ConsultantUser
): HookReturnType => {
  const { availableForNewTherapyToggleEnabled = false } = useFeatureFlags()

  const [
    availableForNewTherapyState,
    setAvailableForNewTherapyState,
  ] = useState<AvailableForNewTherapyState>({
    available: false,
    setBy: 'theApp',
  })

  const {
    selfManagedServiceTypeUUID,
    didEncounterError,
    loading,
    isClinicianAddedToSelfManagedServiceType,
  } = useCheckIfTherapyServiceTypeIsInConsultantsServiceTypes(
    availableForNewTherapyToggleEnabled,
    user
  )

  useUpdateToggleStateWithBackendState(
    loading,
    setAvailableForNewTherapyState,
    isClinicianAddedToSelfManagedServiceType
  )

  useToggleStateOnUserActions(
    availableForNewTherapyState,
    user,
    isClinicianAddedToSelfManagedServiceType,
    selfManagedServiceTypeUUID
  )

  return {
    featureEnabled: availableForNewTherapyToggleEnabled && !didEncounterError,
    availableForNewTherapy: availableForNewTherapyState.available,
    loading,
    setAvailableForNewTherapy: (available) =>
      setAvailableForNewTherapyState({
        available,
        setBy: 'theUser',
      }),
  }
}
