import { IntlFormatters } from 'react-intl'
import * as Yup from 'yup'

import messages from '../validation.messages'

const rePhoneNumber = /^\d{10}$/
const reIntlPhoneNumber = /^(00|\+)\d{11,12}$/
const reCountryCode = /^(00|\+)\d{1,2}$/

export const validatePhoneNumber = (
  formatMessage: IntlFormatters['formatMessage']
) => (
  countryCode?: boolean,
  message: string = formatMessage(messages.invalid_phone)
) => {
  return Yup.string().test('phone', message, (value: string | undefined) => {
    if (!value) {
      return true
    }

    return countryCode
      ? reIntlPhoneNumber.test(value)
      : rePhoneNumber.test(value.replace(/^0|\s|-/g, ''))
  })
}

export const validatePhoneCountryCode = (
  formatMessage: IntlFormatters['formatMessage']
) => (message: string = formatMessage(messages.invalid_country_code)) => {
  return Yup.string().test(
    'phoneCountryCode',
    message,
    (value: string | undefined) => {
      if (!value) {
        return true
      }

      return reCountryCode.test(value)
    }
  )
}
