import { ApolloError } from '@apollo/client'
import React from 'react'

import { Grid } from '@babylon/core-ui'

import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { usePermissions } from '~/core/permissions'
import Card from '~/ui/Card'
import { MiniErrorPanel } from '~/ui/ErrorPanel'

import { CallPlayer, PreferredCallMedium } from '../CallPlayer'
import {
  CallMediumEntitlement,
  CallStatusReducerModel,
} from '../CallStatusReducerModelProvider'
import CallSectionResizer from './CallSectionResizer'
import RecordingConsent from './RecordingConsent'

import messages from './CallSectionView.messages'

interface CallSectionViewProps {
  consultationId: string
  recordingConsent?: string | null
  recordingEnabled: boolean
  callMediumEntitlement: CallMediumEntitlement
  consultationType?: string
  isDesktop: boolean
  error: ApolloError | undefined
  loading: boolean
  callQualityFeedbackEnabled: boolean
  refetch: () => void
  // resizerEnabled prop can be deleted once legacy consultation is no longer used
  resizerEnabled?: boolean
  callStatusReducer: CallStatusReducerModel
}

const CallSectionView = ({
  consultationId,
  recordingConsent,
  recordingEnabled,
  callMediumEntitlement,
  consultationType,
  isDesktop,
  error,
  loading,
  callQualityFeedbackEnabled,
  refetch,
  resizerEnabled = true,
  callStatusReducer,
}: CallSectionViewProps) => {
  const f = useMessages(messages)
  const { videoStreamWindowImprovementsEnabled } = useFeatureFlags()

  const [canShowRecordingConsent] = usePermissions('enable_recording_consent')

  if (loading || !consultationType) {
    return null
  }

  if (error) {
    return (
      <Card>
        <MiniErrorPanel
          title={f('error_title')}
          description={f('error_description')}
          retry={() => refetch()}
          error={error || new Error('No Consultation')}
          retryAlwaysVisible
          startExpanded
        />
      </Card>
    )
  }

  const hasRecordingConsent =
    recordingConsent === 'accepted' || recordingConsent == null

  let aspectRatio

  if (isDesktop || videoStreamWindowImprovementsEnabled) {
    aspectRatio = 4 / 5
  }

  return (
    <Grid gap={0} templateRows="auto 1fr" style={{ height: '100%' }}>
      <CallSectionResizer resizerEnabled={resizerEnabled} isDesktop={isDesktop}>
        <Grid gap={16}>
          <CallPlayer
            consultationId={consultationId}
            aspectRatio={aspectRatio}
            isRecording={recordingEnabled && hasRecordingConsent}
            callMediumEntitlement={callMediumEntitlement}
            preferredCallMedium={consultationType as PreferredCallMedium}
            callQualitySurveyEnabled={callQualityFeedbackEnabled}
            callStatusReducer={callStatusReducer}
          />
          {canShowRecordingConsent && (
            <RecordingConsent
              isRevokedOrRefused={!hasRecordingConsent}
              consultationId={consultationId}
            />
          )}
        </Grid>
      </CallSectionResizer>
    </Grid>
  )
}

export default CallSectionView
