import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.nhs_actions.unknown_workflows'

export default defineMessages({
  message_count: {
    id: `${baseId}.message_count`,
    defaultMessage: `{count, plural,
      one {There is {displayCount} previous action}
      other {There are {displayCount} previous actions}}`,
  },
  details_not_available: {
    id: `${baseId}.details_not_available`,
    defaultMessage: `{count, plural,
      one {Details of the action are not available}
      other {Details of the actions are not available}}`,
  },
})
