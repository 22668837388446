import React from 'react'
import ContentLoader from 'react-content-loader'
import styles from './styles.module.scss'
import { generateIndex } from '../../utils'

export default () => (
  <div className={styles.loadingState}>
    <ContentLoader width={700} height={40}>
      <rect x="0" y="0" rx="5" ry="5" width="100" height="20" />
      <rect x="180" y="0" rx="5" ry="5" width="100" height="20" />
      <rect x="310" y="0" rx="5" ry="5" width="100" height="20" />
      <rect x="490" y="0" rx="5" ry="5" width="0" height="20" />
    </ContentLoader>
    {[...Array(10)].map(() => (
      <ContentLoader key={generateIndex()} width={700} height={56}>
        <rect x="0" y="12" rx="5" ry="5" width="150" height="30" />
        <rect x="180" y="12" rx="5" ry="5" width="100" height="30" />
        <rect x="310" y="12" rx="5" ry="5" width="150" height="30" />
        <rect x="490" y="6" rx="5" ry="5" width="100" height="40" />
      </ContentLoader>
    ))}
  </div>
)
