import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review.attachments'

export default defineMessages({
  attachments_section_title: {
    id: `${baseId}.attachments_section_title`,
    defaultMessage: 'Attachments',
  },
  attachments_loading_error: {
    id: `${baseId}.attachments_loading_error`,
    defaultMessage: 'Error loading attachments for this consultation',
  },
})
