import React, { useEffect, useState } from 'react'

import { Heading, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import messages from './MediaAccessDialog.messages'
import styles from './styles.module.scss'

/**
 * The isAccessDialogOpen prop comes directly from opentok but it is slow and
 * on Chrome it briefly flashes as `true` even if the dialog doesnt display.
 * So we use it as a fallback on Safari and older browsers where
 * `navigator.permissions.query` is not available and the flash does not happen anyway
 */

const isPrompting = async (isAccessDialogOpen: boolean) => {
  if (!isAccessDialogOpen) {
    return false
  }

  try {
    const [cameraStatus, micStatus] = await Promise.all([
      navigator.permissions.query({ name: 'camera' }),
      navigator.permissions.query({ name: 'microphone' }),
    ])

    return cameraStatus.state === 'prompt' || micStatus.state === 'prompt'
  } catch (err) {
    return true
  }
}

interface MediaAccessDialogProps {
  isAccessDialogOpen: boolean
}

const MediaAccessDialog = ({ isAccessDialogOpen }: MediaAccessDialogProps) => {
  const f = useMessages(messages)

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    isPrompting(isAccessDialogOpen).then((prompting) => {
      if (prompting) {
        setMounted(true)
      }
    })
  }, [isAccessDialogOpen])

  if (!mounted) {
    return null
  }

  return (
    <Dialog
      onClose={() => setMounted(false)}
      className={styles.dialog}
      closeOnBackgroundClick={false}
    >
      {({ closeModal }) => {
        isPrompting(isAccessDialogOpen).then((prompting) => {
          if (!prompting) {
            closeModal()
          }
        })

        return (
          <>
            <Heading margin level="h3">
              {f('title')}
            </Heading>
            <Text>{f('body')}</Text>
          </>
        )
      }}
    </Dialog>
  )
}

export default MediaAccessDialog
