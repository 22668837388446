import { withApollo } from '@apollo/client/react/hoc'
import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { useFeatureFlags } from '~/core/core-modules'
import ClinicalNotesEditor from '~/features/clinical-coding/ClinicalNotesEditor'
import useClinicalCodingOptions from '~/features/clinical-coding/useClinicalCodingOptions'
import { useExperiment } from '~/features/experiments/useExperiment'
import Label from '~/ui/Label'

import { useConsultationNoteTimer } from '../utils'
import { useDiagnosisStore } from './DiagnosisStore'

import messages from './Diagnosis.messages'

const DiagnosisField = ({
  intl,
  disabled,
  markFinalizedConsultationAsEdited,
}) => {
  const { fetchOptions, loading, options } = useClinicalCodingOptions()
  const { notesHighlightOnPasteEnabled } = useFeatureFlags()
  const noteAssistantExperimentEnabled = useExperiment('Note Assistant')
  const enableHighlight =
    notesHighlightOnPasteEnabled && noteAssistantExperimentEnabled
  const [warningSent, setWarningSent] = useState(false)
  const { store, onChange } = useDiagnosisStore()

  const { startTimer, stopTimer } = useConsultationNoteTimer('diagnosis')

  const markAsEdited = (value) => {
    if (
      value.document.text !== store.initialAssessment.document.text &&
      !warningSent
    ) {
      markFinalizedConsultationAsEdited()
      setWarningSent(true)
    }
  }

  const handleKeyDown = () => {
    startTimer()
    stopTimer()
  }

  return (
    <Label value={intl.formatMessage(messages.diagnosis_label)}>
      <ClinicalNotesEditor
        name="diagnosis"
        enableSelection
        enableNoteIcon
        enableAutocomplete
        enableHighlight={enableHighlight}
        placeholder={intl.formatMessage(messages.diagnosis_placeholder)}
        value={store.assessment}
        onChange={(value) => {
          onChange({
            assessment: value,
          })
          if (markFinalizedConsultationAsEdited) {
            markAsEdited(value)
          }
        }}
        onKeyDown={handleKeyDown}
        readOnly={disabled}
        getOptions={fetchOptions}
        optionsLoading={loading}
        options={options}
        dataTestId="clinical-codes"
      />
    </Label>
  )
}

export default compose(withApollo, injectIntl)(DiagnosisField)
