import * as Sentry from '@sentry/browser'

import { ENVIRONMENT, SENTRY_URL } from '../constants'

const log = (level = 'info', eventName, data) => {
  const logger = 'tests-and-kits-logger'
  const timestamp = Date.now()
  const message = `${logger}: ${eventName}`

  const options = {
    level,
    logger,
    environment: ENVIRONMENT,
    fingerprint: [logger, eventName],
    tags: {
      module: 'tests-and-kits',
      eventName,
      timestamp,
    },
    extra: {
      ...data,
    },
  }

  if (ENVIRONMENT === 'localhost') console[level](message, options)

  if (SENTRY_URL) {
    Sentry.captureEvent({
      message,
      ...options,
    })
  }
}

export default {
  info(eventName, data) {
    log('info', eventName, data)
  },

  warning(eventName, data) {
    log('warning', eventName, data)
  },

  error(eventName, data) {
    log('error', eventName, data)
  },
}
