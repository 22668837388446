import React from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { CLINICAL_PRESENCE_POLLING_INTERVAL } from '~/constants'
import { useClinicalPresenceMutation } from '~/generated'

import { useInterval } from '../hooks'

// Render this component only if clinicalPresenceHeartbeatEnabled FF is enabled
const ClinicalPresenceHeartbeat = () => {
  const [clinicalPresenceMutation] = useClinicalPresenceMutation()
  const isVisible = usePageVisibility()

  useInterval(() => {
    if (isVisible) {
      clinicalPresenceMutation().catch(() => {
        /**/
      })
    }
  }, CLINICAL_PRESENCE_POLLING_INTERVAL)

  return <></>
}

export default ClinicalPresenceHeartbeat
