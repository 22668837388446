import React from 'react'
import { Route } from 'react-router'

import { useFormatMessage } from '@babylon/intl'

import { PATIENT_SUMMARY_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { PatientSummaryPluginInterface } from '~/core/config/modules/generated/types'
import ProblemsList from '~/features/problems-list'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import ProblemsListSummaryModal from '../ProblemsListSummaryModal'

import messages from './PatientSummary.messages'

const PatientSummary: PatientSummaryPluginInterface = ({
  consultationContext,
  consultationSummary,
}) => {
  const fm = useFormatMessage()

  return (
    <>
      <Section title={fm(messages.title)}>
        <div data-testid="problems-list">
          <ProblemsList consultationContext={consultationContext} />
        </div>
      </Section>
      <Route path="/consultation/:consultationId/patient-summary">
        {consultationSummary && (
          <ProblemsListSummaryModal ConsultationSummary={consultationSummary} />
        )}
      </Route>
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: PATIENT_SUMMARY_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(PatientSummary)
