import { HttpLink } from '@apollo/client'

import { PLATFORM_GATEWAY_URL } from '~/constants'

const platformGatewayHttpLink = new HttpLink({
  uri: `${PLATFORM_GATEWAY_URL}`,
  credentials: 'include',
})

export default platformGatewayHttpLink
