import { minBy, reject, sortBy } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'

import { ConsultantUser } from '@babylon/babylon-user/src'
import { Tag, Text } from '@babylon/core-ui'

import { isFilledArray, isTruthy, mapOptional } from '~/core'
import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import messages from '~/features/appointment-invites/AppointmentInvites/components/AppointmentInvitesTable/AppointmentInvitesTable.messages'
import styles from '~/features/appointment-invites/AppointmentInvites/styles.module.scss'
import { isInviteMedium } from '~/features/appointment-invites/AppointmentInvites/types'
import { Appointment, GetAppointmentInvitesQueryResult } from '~/generated'
import DataList from '~/ui/DataList'

type Invite = AppointmentInvitesTableProps['appointmentInvites'][number]
type AssociatedAppointments = Pick<Appointment, 'id' | 'time'>[]
export interface AppointmentInvitesTableProps {
  appointmentInvites: NonNullable<
    GetAppointmentInvitesQueryResult['data']
  >['appointmentInvites']
  currentConsultant: ConsultantUser['consultant']
}

type InvitesGrouping = {
  groupedInvites: {
    [correlationId: string]: Invite[] | undefined
  }
  ungroupedInvites: Invite[]
}

const isGroup = (inviteOrGroup: Invite | Invite[]): inviteOrGroup is Invite[] =>
  Array.isArray(inviteOrGroup)

const groupInvitesPerGroupCorrelationId = (appointmentInvites: Invite[]) => {
  const initialAccumulator: InvitesGrouping = {
    groupedInvites: {},
    ungroupedInvites: [],
  }

  return appointmentInvites.reduce((accu, invite): InvitesGrouping => {
    const groupId = invite.group_correlation_id
    if (groupId == null) {
      return {
        groupedInvites: accu.groupedInvites,
        ungroupedInvites: [...accu.ungroupedInvites, invite],
      }
    }
    const invitesGroup = accu.groupedInvites[groupId] ?? []

    return {
      groupedInvites: {
        ...accu.groupedInvites,
        [groupId]: [...invitesGroup, invite],
      },
      ungroupedInvites: accu.ungroupedInvites,
    }
  }, initialAccumulator)
}

const inviteRepresentative = (
  inviteOrGroup: Invite | Invite[]
): Invite | undefined => {
  if (Array.isArray(inviteOrGroup)) {
    return minBy(inviteOrGroup, (invite) =>
      Date.parse(invite.earliest_booking_date)
    )
  }
  return inviteOrGroup
}

const sortByEarliestBookingDateASC = (
  lhs: Invite | Invite[],
  rhs: Invite | Invite[]
): number => {
  const lhsTimestamp = mapOptional(
    inviteRepresentative(lhs)?.earliest_booking_date,
    (d) => Date.parse(d)
  )
  const rhsTimestamp = mapOptional(
    inviteRepresentative(rhs)?.earliest_booking_date,
    (d) => Date.parse(d)
  )

  if (lhsTimestamp == null || rhsTimestamp == null) {
    return 0
  }
  return lhsTimestamp - rhsTimestamp
}

const isRecurringInvite = (invite: Invite) =>
  !invite.group_correlation_id &&
  invite.recurrence != null &&
  !!invite.recurrence?.count
const isFailedRecurringInvite = (invite: Invite) =>
  isRecurringInvite(invite) && !invite?.associated_appointments?.length

export const useMakeInviteTables = (
  appointmentInvites: Invite[],
  currentConsultant: AppointmentInvitesTableProps['currentConsultant']
): JSX.Element[] => {
  const format = useMessages(messages)
  const intl = useIntl()
  const { f2FMediumServiceTypeInvitesSupported } = useFeatureFlags()
  const isMyConsultation = (consultantUuid: string) =>
    currentConsultant.uuid === consultantUuid

  const formatDate = (date: string, includeTime?: boolean) => {
    const appointmentDate = new Date(date)
    const formattedDate = intl.formatDate(appointmentDate, { format: 'short' })
    if (includeTime) {
      return `${formattedDate} ${intl.formatTime(appointmentDate, {
        hour12: true,
      })}`
    }
    return formattedDate
  }

  const recurringAppointmentDates = (appointments: AssociatedAppointments) => {
    return (
      <ul className={styles.recurringDates}>
        {sortBy(appointments, ['time']).map(
          (appointment) =>
            !!appointment?.time && (
              <li key={appointment?.id}>
                {formatDate(appointment.time, true)}
              </li>
            )
        )}
      </ul>
    )
  }

  const filteredFailedRecurringInvites = reject(
    appointmentInvites,
    isFailedRecurringInvite
  )
  const invitesGrouping = groupInvitesPerGroupCorrelationId(
    filteredFailedRecurringInvites
  )
  const inviteGroups = Object.keys(invitesGrouping.groupedInvites)
    .map((groupId) => invitesGrouping.groupedInvites[groupId])
    .filter(isTruthy)

  const sortedInviteOrGroups: (Invite | Invite[])[] = [
    ...invitesGrouping.ungroupedInvites,
    ...inviteGroups,
  ].sort(sortByEarliestBookingDateASC)

  return sortedInviteOrGroups
    .map((inviteOrGroup) => {
      const invite = inviteRepresentative(inviteOrGroup)

      if (invite == null) {
        return undefined
      }

      const mediumValue = (() => {
        if (!f2FMediumServiceTypeInvitesSupported) return undefined
        const castedMediums =
          invite.allowed_mediums?.filter(isInviteMedium) ?? []

        if (castedMediums.includes('physical')) {
          return format('medium_value_faceToFace')
        }

        if (
          castedMediums.includes('voice') ||
          castedMediums.includes('video')
        ) {
          return format('medium_value_digital')
        }

        return undefined
      })()

      const {
        earliest_booking_date: earliestBookingDate,
        preferred_profession_name: preferredProfessionName,
        consultant_uuid: consultantUuid,
        duration_minutes: durationMinutes,
        notes_for_member: notesForMember,
        consultant,
        service_type: serviceType,
        associated_appointments: recurringAppointments,
      } = invite

      const data = [
        preferredProfessionName && {
          key: format('profession_key'),
          value: preferredProfessionName,
        },
        serviceType && {
          key: format('type_key'),
          value: serviceType.name,
        },
        consultantUuid && {
          key: format('provider_key'),
          value: (
            <Text>
              {consultant?.name}
              {isMyConsultation(consultantUuid) && (
                <Tag className={styles.tag} color="highlight-violet">
                  {format('provider_tag')}
                </Tag>
              )}
            </Text>
          ),
        },
        {
          key: format('reason_key'),
          value: notesForMember,
        },

        !recurringAppointments?.length && {
          key: format('duration_key'),
          value: format('duration_value', { durationMinutes }),
        },
        mapOptional(mediumValue, (value) => ({
          key: format('medium_key'),
          value,
        })),
        {
          key: format('when_key'),
          value: recurringAppointments?.length
            ? recurringAppointmentDates(recurringAppointments)
            : `${format('when_value')} ${formatDate(earliestBookingDate)}`,
        },
        isGroup(inviteOrGroup) && inviteOrGroup.length > 1
          ? {
              key: format('recurrence_key'),
              value: format('recurrence_value', {
                amount: inviteOrGroup.length,
              }),
            }
          : undefined,
      ].filter(isTruthy)

      return <DataList key={invite.id} data={data} />
    })
    .filter(isTruthy)
}

const AppointmentInvitesTable = ({
  appointmentInvites,
  currentConsultant,
}: AppointmentInvitesTableProps) => {
  const views = useMakeInviteTables(appointmentInvites, currentConsultant)
  return isFilledArray(views) ? <>{views}</> : null
}

export default AppointmentInvitesTable
