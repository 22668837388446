import classnames from 'classnames'
import React, { ReactNode } from 'react'

import { Spinner } from '@babylon/core-ui'

import SectionTitle from './SectionTitle'

import styles from './styles.module.scss'

export interface SectionProps {
  topRight?: ReactNode
  type?: string
  title?: string | null
  icon?: string
  children?: ReactNode
  className?: string
  tooltip?: string
  dataTestId?: string
  subtitle?: ReactNode
  style?: React.CSSProperties
  loading?: boolean
}

const Section = ({
  type,
  className,
  children,
  title,
  subtitle,
  icon,
  topRight,
  tooltip,
  dataTestId,
  style,
  loading,
}: SectionProps) => {
  const content = typeof children === 'function' ? children() : children

  return (
    <div
      className={classnames(styles.section, className)}
      data-testid={dataTestId}
      style={style}
    >
      {title && (
        <SectionTitle
          subtitle={subtitle}
          topRight={topRight}
          icon={icon}
          type={type}
          title={title}
          tooltip={tooltip}
        />
      )}
      {loading ? (
        <Spinner size="small" testid="section-loading-spinner" />
      ) : (
        <>{content}</>
      )}
    </div>
  )
}

export default Section
