import cn from 'classnames'
import React, { ElementType, useMemo } from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { TimelineConsultationEventPluginInterface } from '~/core/config/modules/generated/types'
import { TimelineEventInterface, TimelineEventType } from '~/generated'

import TimelineChatscriptEvent from '../TimelineChatscriptEvent'
import TimelineConsultationEventModule from '../TimelineConsultationEvent'
import TimelineTestResultEvent from '../TimelineTestResultEvent'

import styles from './styles.module.scss'
import messages from './Timeline.messages'

type OtherValidTimelineEventTypes =
  | TimelineEventType.ChatscriptConversation
  | TimelineEventType.TestReport

interface TimelineProps {
  events: TimelineEventInterface[]
  patientId: string
  patientUuid: string
  TimelineConsultationEvent?: ReturnType<TimelineConsultationEventPluginInterface>
  className?: string
  isFuture?: boolean
}

const Timeline = ({
  events,
  patientId,
  patientUuid,
  TimelineConsultationEvent,
  className,
  isFuture = false,
}: TimelineProps) => {
  const fm = useFormatMessage()
  const LegacyTimelineConsultationEvent = useMemo(
    () => TimelineConsultationEventModule({ includeActions: true }),
    []
  )

  const otherEventComponentsMap: {
    [key in OtherValidTimelineEventTypes]: ElementType
  } = {
    ChatscriptConversation: TimelineChatscriptEvent,
    TestReport: TimelineTestResultEvent,
  }

  const emptyMessage = isFuture
    ? messages.no_future_events_found
    : messages.no_history_events_found

  return isFilledArray(events) ? (
    <div
      className={cn(styles.timeline, className, {
        [styles.timelineFuture]: isFuture,
      })}
    >
      {events.map((event) => {
        // Allowed timeline event types:
        // - ChatscriptConversation
        // - TestReport
        // - Consultation
        // nothing else is shown

        const OtherEventComponent =
          otherEventComponentsMap[
            event.eventType as OtherValidTimelineEventTypes
          ]

        if (event.eventType === TimelineEventType.Consultation) {
          return TimelineConsultationEvent ? (
            <TimelineConsultationEvent
              key={event.id}
              event={event}
              patientId={patientId}
            />
          ) : (
            <LegacyTimelineConsultationEvent
              key={event.id}
              event={event}
              patientId={patientId}
            />
          )
        }

        return (
          <OtherEventComponent
            key={event.id}
            patientId={patientId}
            patientUuid={patientUuid}
            event={event}
          />
        )
      })}
    </div>
  ) : (
    <div className={styles.emptyMessageContainer}>
      <Text color="light-grey-type">{fm(emptyMessage)}</Text>
    </div>
  )
}

export default Timeline
