export default {
  queries: {
    getDecorations: ({ value }, type) => {
      return value
        ? value.decorations.find(({ mark }) => mark.type === type)
        : []
    },

    hasDecorations: ({ value }, ...types) => {
      return value
        ? value.decorations.some(({ mark }) => types.includes(mark.type))
        : null
    },
  },
}
