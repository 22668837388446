import { defineMessages } from 'react-intl'

const baseId = 'call_player.media_access_dialog'

export default defineMessages({
  title: {
    id: `${baseId}.access_media_request_title`,
    defaultMessage: 'Allow us to access your camera and microphone',
  },
  body: {
    id: `${baseId}.access_media_request_body`,
    defaultMessage:
      'We need access to your camera so the patient can see you.  You will be asked to confirm this decision on each device that you use',
  },
})
