import { useCallback, useEffect, useState } from 'react'

import { initConfig, resolve } from '~/core/config'
import { observableValue } from '~/core/container'
import { Globals } from '~/core/core-modules'
import { logException } from '~/core/sentry'

interface Config {
  globalOptions?: Globals
  layout?: React.ReactNode
  configError?: Error
}

export const localConfig = observableValue<string | undefined>(undefined)

const useInitConfig = (partners: string[], userUuid: string) => {
  const [config, setConfig] = useState<Config>({})

  const init = useCallback(
    async (localConfig?: string) => {
      try {
        await initConfig(partners, userUuid, localConfig)
        const [global, main] = await Promise.all([
          resolve('global'),
          resolve('main'),
        ])

        setConfig({
          globalOptions: global,
          layout: main.layout,
        })
      } catch (error) {
        logException(error)
        setConfig({ configError: error })
      }
    },
    [partners, userUuid]
  )

  useEffect(() => {
    init(localConfig.get())
    return localConfig.subscribe(init).unsubscribe
  }, [init])

  return config
}

export default useInitConfig
