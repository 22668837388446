import { defineMessages } from 'react-intl'

const baseId = 'core.image_gallery'

export default defineMessages({
  images_title: {
    id: `${baseId}.images_title`,
    defaultMessage: 'Patient attachments',
  },
  images_tooltip: {
    id: `${baseId}.images_tooltip`,
    defaultMessage: 'Images uploaded by the patient',
  },
  patient_image_error: {
    id: `${baseId}.patient_image_error`,
    defaultMessage: 'Error loading images',
  },
})
