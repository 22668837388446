/* eslint-disable react/prop-types */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Text } from '@babylon/core-ui'

import { result } from '~/core'
import { logException } from '~/core/sentry'
import { Dialog } from '~/ui/Dialog'
import { ContentErrorMessage, DialogErrorMessage } from '~/ui/ErrorMessage'

import messages from './errorAlert.messages'

export const errorAlert = ({ msg, logMessage }) => {
  const lastEventId = null
  logException(logMessage)

  Dialog.show({
    title: (
      <Text color="error">
        <FormattedMessage {...messages.title} />
      </Text>
    ),
    message: (
      <Text>
        {msg ? (
          <span>
            {msg} <br />
            <FormattedMessage
              {...messages.error_id}
              values={{ eventId: lastEventId }}
            />
          </span>
        ) : (
          <span>
            <FormattedMessage {...messages.internal_server_error} />
            <br />
            {lastEventId && (
              <FormattedMessage
                {...messages.error_id}
                values={{ eventId: lastEventId }}
              />
            )}
          </span>
        )}
      </Text>
    ),
    cancelLabel: <FormattedMessage {...messages.okay} />,
    centerContent: true,
  })
}

const withErrorMessage = (options) => (Component) => (props) => {
  const {
    name: names,
    message,
    component,
    buttonLabel,
    onButtonClick,
    dialog,
    passErrorMessage,
  } = result(options, props) || {}

  const nameList = Array.isArray(names) ? names : [names || 'data']
  const error = nameList.map((name) => props[name]?.error).find(Boolean)

  if (error) {
    const permissionsError = error.graphQLErrors.find(
      (v) => v.extensions?.response?.status === 403
    )
    let displayMessage = message
    if (permissionsError) {
      const url = permissionsError?.extensions?.response?.url
      displayMessage = (
        <span>
          <FormattedMessage {...messages.no_access_action} />

          {url && (
            <span>
              <br /> {url}
            </span>
          )}
        </span>
      )
    }
    const Message =
      component || (dialog ? DialogErrorMessage : ContentErrorMessage)
    const errorMessage = (
      <Message
        message={displayMessage}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
        centerOnScreen
      />
    )
    return passErrorMessage ? (
      <Component
        {...props}
        errorAlert={errorAlert}
        errorMessage={errorMessage}
      />
    ) : (
      errorMessage
    )
  }
  return <Component {...props} errorAlert={errorAlert} />
}
export default withErrorMessage
