import React from 'react'

import { useTimeout } from '~/core/hooks'

import StatusMessage from './StatusMessage'

interface CallEndMessageProps {
  message: string
  onTimeoutComplete: () => void
}

const CallEndMessage = ({
  message,
  onTimeoutComplete,
}: CallEndMessageProps) => {
  useTimeout(onTimeoutComplete, 3000)

  return <StatusMessage>{message}</StatusMessage>
}

export default CallEndMessage
