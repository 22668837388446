import { defineMessages } from 'react-intl'

const baseId = 'i18n'

export default defineMessages({
  error_title: {
    id: `${baseId}.error_title`,
    defaultMessage: 'An error occurred loading language data',
  },
  error_page_reload_button: {
    id: `${baseId}.error_page_reload_button`,
    defaultMessage: 'Reload',
  },
})
