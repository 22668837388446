import { AxiosResponse } from 'axios'
import { useEffect, useRef } from 'react'

import { refreshKongToken } from '@babylon/babylon-auth'
import { keepAliveAuthSession } from '@babylon/web-platform-utils-auth-session'

import { ENABLE_NEW_AUTH } from '~/constants'
import { logException } from '~/core/sentry'

const AUTH_POLLING_INTERVAL_DURATION = 5 * 60 * 1000 // 5 mins

const useAuthPollingEffect = (isCallActive: boolean) => {
  const authPollingInterval = useRef<number | null>(null)
  const refreshAuth: () => void | Promise<AxiosResponse<any>> = ENABLE_NEW_AUTH
    ? keepAliveAuthSession(true)
    : () => refreshKongToken('portal')

  const updateAuthToken = async () => {
    try {
      await refreshAuth()
    } catch (err) {
      logException(err)
    }
  }

  const startAuthPolling = () => {
    updateAuthToken()

    authPollingInterval.current = window.setInterval(
      updateAuthToken,
      AUTH_POLLING_INTERVAL_DURATION
    )
  }

  const stopAuthPolling = () => {
    if (!authPollingInterval.current) {
      return
    }

    clearInterval(authPollingInterval.current)
    authPollingInterval.current = null
  }

  useEffect(() => {
    // toggle polling when callStatus changes
    if (isCallActive) {
      startAuthPolling()
    }

    if (!isCallActive) {
      stopAuthPolling()
    }
  }, [isCallActive]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // stop polling when component unmounts
    return () => stopAuthPolling()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useAuthPollingEffect
