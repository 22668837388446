import { useLocation } from 'react-router-dom'

export const useUrlSearchParams = () => {
  return new URLSearchParams(useLocation().search)
}

export const useUrlSearchParam = (paramName: string) => {
  const urlSearchParams = useUrlSearchParams()
  return urlSearchParams.get(paramName)
}
