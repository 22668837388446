/* eslint-disable react/no-array-index-key */
import React, { createElement } from 'react'

import { isUSRegion } from '~/core/region'
import { ContactDetails } from '~/generated'

interface AddressProps {
  contactDetails: ContactDetails | null | undefined
  region?: string | null
}

const usaAddressLastLine = (
  thirdLine: string,
  stateCode: string,
  postCode: string
): string => {
  const comma = thirdLine && (stateCode || postCode) ? ',' : ''

  return `${thirdLine}${comma} ${stateCode} ${postCode}`.trim()
}

const div = (value: any, key: number) => {
  return createElement('div', { key }, value)
}

const Address = ({ contactDetails, region }: AddressProps) => {
  const {
    address_first_line: firstLine,
    address_second_line: secondLine,
    address_third_line: thirdLine,
    address_state_code: stateCode,
    address_post_code: postCode,
  } = contactDetails || {}

  const usaRegion = isUSRegion(region || '')

  const fourthLine = usaRegion
    ? usaAddressLastLine(thirdLine || '', stateCode || '', postCode || '')
    : postCode

  const values = [
    firstLine,
    secondLine,
    usaRegion ? null : thirdLine,
    fourthLine,
    region,
  ]
    .filter(Boolean)
    .map(div)

  return <span>{values}</span>
}

export default Address
