import React from 'react'

import { isTruthy } from '~/core'
import { DiagnosisSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useConsultationNotesSummaryQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import DiagnosisSummary from './DiagnosisSummary'

import messages from './DiagnosisSummary.messages'

const DiagnosisSummaryModule: DiagnosisSummaryPluginInterface = () => {
  const DiagnosisSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)
    const { data, loading, error, refetch } = useConsultationNotesSummaryQuery({
      variables: { consultationId: consultation.id },
    })

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={f('diagnosis_summary_loading_error')}
          retry={() => refetch()}
        />
      )
    }

    const consultationNote = data?.consultation?.consultationNote
    const furtherInfo = data?.consultation?.furtherInfo?.filter(isTruthy) || []

    if (!consultationNote) {
      return null
    }

    return (
      <DiagnosisSummary
        consultationNote={consultationNote}
        furtherInformation={furtherInfo}
        loading={loading}
      />
    )
  }

  return DiagnosisSummaryWrapper
}

export default DiagnosisSummaryModule
