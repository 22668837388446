import { branch, compose, pure, renderNothing } from 'recompose'

import { withBabylonUser } from '@babylon/babylon-user'

import { withEnhancedState } from '~/core/compose'
import withErrorMessage from '~/core/with-error-message'
import withModal from '~/core/with-modal'
import { withNotify } from '~/ui/Notification'

import withActions from './actions'
import { defaultState, validation } from './constants'
import withData from './data'
import { mapSickNoteToState } from './mappings'
import SickNote from './SickNote'

const isLoading = ({ data }) => (data ? data.loading : false)

export default compose(
  withNotify,
  withModal({
    validation,
  }),
  withData,
  withBabylonUser,
  branch(isLoading, renderNothing),
  withEnhancedState(({ data, query }) => {
    if (data) {
      const noteId = query.id
      const note = data.consultation.sickNotes.find((v) => v.id === noteId)

      if (note) {
        return {
          ...defaultState,
          ...mapSickNoteToState(note),
        }
      }
    }

    return { ...defaultState }
  }),
  withErrorMessage(),
  withActions,
  pure
)(SickNote)
