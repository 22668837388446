import { defineMessages } from 'react-intl'

const baseId = 'dialog.default'

export default defineMessages({
  ok_label: {
    id: `${baseId}.ok_label`,
    defaultMessage: 'Cancel',
  },
  cancel_label: {
    id: `${baseId}.cancel_label`,
    defaultMessage: 'Cancel',
  },
})
