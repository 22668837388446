import cn from 'classnames'
import React from 'react'

import styles from './VideoViewFinder.module.scss'

const VideoViewFinder = () => {
  const blinker = (side: string) =>
    cn(styles.blinker, side === 'right' ? styles.right : styles.left)

  return (
    <div>
      <div className={blinker('left')} />
      <div className={styles.finder} />
      <div className={blinker('right')} />
    </div>
  )
}

export default VideoViewFinder
