import React, { useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { Text } from '@babylon/core-ui'

import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import ClinicalNotesEditor, {
  BabylonSerializer,
} from '~/features/clinical-coding/ClinicalNotesEditor'
import useClinicalCodingOptions from '~/features/clinical-coding/useClinicalCodingOptions'
import { useExperiment } from '~/features/experiments/useExperiment'
import { useUpdateConsultationNoteMutation } from '~/generated'
import { notify } from '~/ui/Notification'
import Pill from '~/ui/Pill'

import { useConsultationNoteTimer } from '../../utils'

import messages from '../Examination.messages'
import styles from './styles.module.scss'

const ExaminationFieldEditor = (props) => {
  const {
    text,
    codes,
    name,
    enableSelection = true,
    readOnly,
    markFinalizedConsultationAsEdited,
  } = props
  const f = useMessages(messages)
  const [updateConsultationNoteMutation] = useUpdateConsultationNoteMutation()
  const [value, setValue] = useState(
    BabylonSerializer.deserialize(text || '', codes || [])
  )
  const initialValue = useRef(value)
  const [warningSent, setWarningSent] = useState(false)
  const { fetchOptions, loading, options } = useClinicalCodingOptions()
  const { notesHighlightOnPasteEnabled } = useFeatureFlags()
  const noteAssistantExperimentEnabled = useExperiment('Note Assistant')
  const enableHighlight =
    notesHighlightOnPasteEnabled && noteAssistantExperimentEnabled

  const { startTimer, stopTimer } = useConsultationNoteTimer(name)

  const [debounceUpdateConsultation] = useDebouncedCallback(async (val) => {
    const {
      consultationId,
      mutationTextFieldName,
      mutationCodesFieldName,
    } = props

    const [text, codes] = BabylonSerializer.serialize(val)

    try {
      await updateConsultationNoteMutation({
        variables: {
          consultationId,
          input: {
            [mutationTextFieldName]: text,
            [mutationCodesFieldName]: codes,
          },
        },
      })
    } catch (err) {
      notify({
        title: f('error_saving_examination_title'),
        message: f('error_saving_examination_message'),
        type: 'error',
      })
      logException(err)
    }
  }, 500)

  const renderClinicalCodes = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const codes = props.codes || []

    return (
      <div className={styles.codes}>
        {codes.map((code) => (
          <Pill
            key={`${code.offset}-${code.length}`}
            value={code.term}
            type="code"
          />
        ))}
      </div>
    )
  }

  const handleChange = (val) => {
    setValue(val)
    debounceUpdateConsultation(val)

    if (value.document.text !== initialValue.current.document.text) {
      if (markFinalizedConsultationAsEdited && !warningSent) {
        markFinalizedConsultationAsEdited()
        setWarningSent(true)
      }
    }
  }

  const handleKeyDown = () => {
    startTimer()
    stopTimer()
  }

  const { label, subLabel, placeholder, id, dataTestId } = props

  return (
    <div data-testid={dataTestId}>
      <label htmlFor={id} data-public>
        {label}
      </label>
      {subLabel && (
        <Text size="medium" color="black-type" className={styles.subLabel}>
          {subLabel}
        </Text>
      )}

      <ClinicalNotesEditor
        name={name}
        readOnly={readOnly}
        enableSelection={enableSelection}
        enableHighlight={enableHighlight}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        getOptions={fetchOptions}
        optionsLoading={loading}
        options={options}
      />

      {renderClinicalCodes()}
    </div>
  )
}

export default ExaminationFieldEditor
