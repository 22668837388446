import omit from 'lodash/omit'
import { withHandlers } from 'recompose'

const defaultEventTransform = (event) => event.target.value

const handleInputChange = ({ updateState }) => (
  fieldName,
  transform = defaultEventTransform
) => (event) =>
  updateState({
    [fieldName]: transform(event),
  })

const handleSave = ({
  match,
  errorAlert,
  state,
  updateGpMutation,
  updateAppointmentMutation,
  redirectToConsultation,
}) => async () => {
  const { gpConsent, ...rest } = state
  const formData = omit(rest, ['id', '__typename', 'gp_full_address'])

  try {
    await updateGpMutation({
      variables: {
        appointmentId: match.params.consultationId,
        formData,
      },
    })

    await updateAppointmentMutation({
      variables: {
        id: match.params.consultationId,
        gp_consent: gpConsent,
      },
    })

    redirectToConsultation()
  } catch (error) {
    errorAlert({ logMessage: error })
  }
}

const handleGpConsentChange = ({ updateState, state }) => () => {
  updateState({ gpConsent: !state.gpConsent })
}

export default withHandlers({
  handleInputChange,
  handleGpConsentChange,
  handleSave,
})
