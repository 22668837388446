import { gql } from '@apollo/client'

export const DrugInfoQuery = gql`
  query DrugQuery($drugId: ID!, $region: String!) {
    drug(id: $drugId, region: $region) {
      id
      name
      generic
      expensive
      strength
      controlled
      blacklisted
      drugPreparation
      activeIngredients
      pharmaceuticalForm
      packaging {
        packagingId
        packageSize
      }
    }
  }
`
