import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import messages from './CarePlanWarningModal.messages'

export interface CarePlanWarningModalProps {
  title: string
  paragraph1: string
  paragraph2?: string
  onOk: () => void
}

const CarePlanWarningModal = ({
  onOk,
  title,
  paragraph1,
  paragraph2,
}: CarePlanWarningModalProps) => {
  const f = useMessages(messages)

  return (
    <Dialog title={title} ok okLabel={f('ok_button_label')} onOk={onOk}>
      <Text tag="p">{paragraph1}</Text>
      <Text tag="p">{paragraph2}</Text>
    </Dialog>
  )
}

export default CarePlanWarningModal
