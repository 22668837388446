import React from 'react'
import { FormattedDate } from 'react-intl'

import { Button, Text } from '@babylon/core-ui'
import { BabylonTimeAgo } from '@babylon/intl'

import { useMessages } from '~/core/hooks'
import { useCpPreConsConversationQuery } from '~/generated'

import messages from './PreConsultationSection.messages'

interface PreConsultationSubtitleProps {
  patientUuid: string
  preConsultationFlowId: string
}

const PreConsultationSubtitle = ({
  patientUuid,
  preConsultationFlowId,
}: PreConsultationSubtitleProps) => {
  const format = useMessages(messages)
  const { data, error, refetch } = useCpPreConsConversationQuery({
    variables: {
      eventId: preConsultationFlowId,
      patientUuid,
    },
  })

  const conversationTime = data?.chatscriptConversation?.startedAt

  return error ? (
    <Text
      style={{ display: 'flex', alignItems: 'center' }}
      tag="div"
      bold
      color="error"
    >
      {format('cant_load_timestamp_v2')}
      <Button intent="link" onClick={() => refetch()}>
        {format('retry')}
      </Button>
    </Text>
  ) : conversationTime ? (
    <div data-testid="preconsultation-timestamp">
      <Text bold>
        <BabylonTimeAgo date={conversationTime} />
      </Text>
      {', '}
      <FormattedDate value={conversationTime} format="shortWithTime" />
    </div>
  ) : (
    <div />
  )
}

export default PreConsultationSubtitle
