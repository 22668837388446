import { defineMessages } from 'react-intl'

const baseId = 'consultation.consultation_profile.consultation_status'

export default defineMessages({
  with_consultant: {
    id: `${baseId}.with_consultant`,
    defaultMessage: 'with {name}',
  },
  status_completed: {
    id: `${baseId}.status_completed`,
    defaultMessage: 'Completed',
  },
  status_cancelled: {
    id: `${baseId}.status_cancelled`,
    defaultMessage: 'Cancelled',
  },
  status_no_show: {
    id: `${baseId}.status_no_show`,
    defaultMessage: 'No show',
  },
  status_coming_soon_time: {
    id: `${baseId}.status_coming_soon_time`,
    defaultMessage: 'at {time}',
  },
  status_coming_soon_day_yesterday: {
    id: `${baseId}.status_coming_soon_day_yesterday`,
    defaultMessage: 'Yesterday ',
  },
  status_coming_soon_day_today: {
    id: `${baseId}.status_coming_soon_day_today`,
    defaultMessage: 'Today ',
  },
  status_coming_soon_day_tomorrow: {
    id: `${baseId}.status_coming_soon_day_tomorrow`,
    defaultMessage: 'Tomorrow ',
  },
})
