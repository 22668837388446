import { gql } from '@apollo/client'

import { WorkflowDefinition } from '~/generated'

export const WORKFLOW_DEFINITION_QUERY = gql`
  query WorkflowDefinition($id: ID!, $locale: String) {
    workflowDefinition(id: $id, locale: $locale) {
      id
      key
      version
      tenant_id
      group {
        type
        label
        assistive_texts {
          instruction
        }
      }
      variables {
        name
        type
        label
        is_required
        metadata {
          type
          value {
            __typename
            ... on WorkflowDefinitionVariableMetadataChildAllowedValue {
              parent_variable
              parent_mappings
            }
            ... on WorkflowDefinitionVariableMetadataAllowedValue {
              values {
                key
                label
              }
            }
            ... on WorkflowDefinitionVariableMetadataAsyncCodeSearchValue {
              coding_system
            }
            ... on WorkflowDefinitionVariableMetadataDateFormat {
              format
            }
            ... on WorkflowDefinitionVariableMetadataRegexValidation {
              regex
            }
            ... on WorkflowDefinitionVariableMetadataSource {
              type
              source_info {
                placeholder
                default_value
              }
            }
            # TODO: Remove in next release as it's no longer being used
            ... on WorkflowDefinitionVariableMetadataDefaultValue {
              default_value
            }
          }
        }
      }
    }
  }
`

export const SEARCH_POINT_OF_CARE_QUERY = gql`
  query SearchPointOfCare($term: String!, $index: String) {
    searchPointOfCareEntity(term: $term, index: $index) {
      __typename
      total
      concepts {
        code {
          code
          system
          display
        }
      }
    }
  }
`

export interface QueryData {
  workflowDefinition: WorkflowDefinition
}

export interface QueryVariables {
  id: string
  locale?: string
}
