import React from 'react'

import { TestsSectionPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import {
  useConsultationId,
  useConsultationStatus,
} from '~/features/consultation'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'
import { useTestsQuery } from '~/generated'

import TestsSection from './TestsSection'

export const TestsSectionContainer: TestsSectionPluginInterface = ({
  editedConsultationWarningModel,
}) => {
  const consultationId = useConsultationId()
  const status = useConsultationStatus(consultationId)

  const [canManageAppointmentPathologyTests] = usePermissions(
    'manage_appointment_pathology_tests'
  )

  const markFinalizedConsultationAsEdited = editedConsultationWarningModel?.useModelContext()
    .markFinalizedConsultationAsEdited

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    status
  )

  const { data, loading, error, refetch } = useTestsQuery({
    variables: { consultationId },
  })

  const diagnosticTests = data?.consultation?.diagnosticTests || []
  const pathologyTests = data?.consultation?.pathologyTests || []

  if (!canManageAppointmentPathologyTests) {
    return null
  }

  return (
    <TestsSection
      consultationId={consultationId}
      diagnosticTests={diagnosticTests}
      pathologyTests={pathologyTests}
      restrictFinalizedConsultationEditing={
        restrictFinalizedConsultationEditing
      }
      loading={loading}
      error={error}
      refetch={refetch}
      markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
    />
  )
}

export default TestsSectionContainer
