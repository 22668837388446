import cn from 'classnames'
import React from 'react'
import useResizeObserver from 'use-resize-observer'

import { Heading, Text } from '@babylon/core-ui'

import styles from './styles.module.scss'

interface OnboardingProps {
  title?: React.ReactNode
  description?: React.ReactNode
  image?: React.ReactNode
  imageCaption?: React.ReactNode
  fullWidth?: boolean
  bigImage?: boolean
}

const OnboardingMessage: React.FC<OnboardingProps> = ({
  title,
  description,
  image,
  bigImage,
  imageCaption,
  fullWidth,
}) => {
  const { width, ref } = useResizeObserver<HTMLDivElement>()

  const wideImage = (width && width < 780) || fullWidth

  return (
    <div ref={ref}>
      {title && (
        <div className={styles.title}>
          <Heading level="h2">{title}</Heading>
        </div>
      )}
      {description && (
        <div className={styles.description}>
          <Text>{description}</Text>
        </div>
      )}
      <div
        className={cn(
          styles.background,
          wideImage ? styles.wideImage : styles.tallImage,
          bigImage && styles.bigImage
        )}
      >
        {image && <div className={styles.image}>{image}</div>}
        {imageCaption && (
          <div className={styles.imageCaption}>
            <Text>{imageCaption}</Text>
          </div>
        )}
      </div>
    </div>
  )
}

export default OnboardingMessage
