import { graphql } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { withNotify } from '~/ui/Notification'

import MedicalSelectWrapper from './MedicalSelectWrapper'

export default ({ name, gql, gaActions }) =>
  compose(
    withNotify,
    graphql(gql.Query, {
      options: ({ patientId }) => {
        return {
          variables: {
            patientId: parseInt(patientId),
          },
        }
      },
    }),
    graphql(gql.Options, {
      name: 'optionsMutation',
    }),
    graphql(gql.Add, {
      name: 'addMutation',
    }),
    graphql(gql.Remove, {
      name: 'removeMutation',
    }),
    injectIntl
  )(
    MedicalSelectWrapper({
      name,
      gql,
      gaActions,
    })
  )
