import { defineMessages } from 'react-intl'

const baseId = 'call_player.status_messages'

export default defineMessages({
  voice_in_progress: {
    id: `${baseId}.voice_in_progress`,
    defaultMessage: 'Audio call in progress',
  },
  ready_to_call: {
    id: `${baseId}.ready_to_call`,
    defaultMessage: 'Ready to call',
  },
  error_title_default: {
    id: `${baseId}.error_title_default`,
    defaultMessage: 'Something went wrong',
  },
  error_starting_title: {
    id: `${baseId}.error_starting_title`,
    defaultMessage: 'Unable to start the call',
  },
  error_description_default: {
    id: `${baseId}.error_description_default`,
    defaultMessage:
      'Try again or start a chat with support if you are running late.',
  },
  error_loading_video_description: {
    id: `${baseId}.error_loading_video_description`,
    defaultMessage:
      'Try again. If you still have problems, switch to an audio call or start a chat with support.',
  },
  patient_hung_up: {
    id: `${baseId}.patient_hung_up`,
    defaultMessage: 'Patient hung up',
  },
  patient_lost_connection: {
    id: `${baseId}.patient_lost_connection`,
    defaultMessage: 'Patient lost connection',
  },
  clinician_lost_connection: {
    id: `${baseId}.clinician_lost_connection`,
    defaultMessage: 'You lost connection',
  },
})
