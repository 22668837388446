import React, { FC } from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { Dialog } from '~/ui/Modal'

import messages from './TriggerWorkflowDialog.messages'

export interface Props {
  show: boolean
  onClose: () => void
  mode: 'successful' | 'duplicate'
  groupName: string
}

const TriggerWorkflowDialog: FC<Props> = ({
  show,
  onClose,
  mode,
  groupName,
}) => {
  const fm = useFormatMessage()
  const title =
    mode === 'successful'
      ? fm(messages.sent_to_clinops)
      : fm(messages.workflow_already_created, { groupName })

  return (
    (show && (
      <Dialog
        title={title}
        cancel={false}
        onClose={onClose}
        onOk={onClose}
        okLabel={fm(messages.ok)}
        ok
      >
        {mode !== 'successful' && (
          <Text>{fm(messages.duplicate_workflow, { groupName })}</Text>
        )}
      </Dialog>
    )) ||
    null
  )
}

export default TriggerWorkflowDialog
