import { defineMessages } from 'react-intl'

const baseId = 'components.care_plan_section_view'

export default defineMessages({
  create_health_goals_and_actions: {
    id: `${baseId}.create_health_goals_and_actions`,
    defaultMessage: 'Create health goals and actions',
  },
  update_health_goals_and_actions: {
    id: `${baseId}.update_health_goals_and_actions`,
    defaultMessage: 'Update health goals and actions',
  },
  introduction_part_1: {
    id: `${baseId}.introduction_part_1`,
    defaultMessage:
      'Every time you add or update health goals and actions, ask the patient for their consent.',
  },
  introduction_part_2: {
    id: `${baseId}.introduction_part_2`,
    defaultMessage:
      'Please do not add or update health goals and actions unless this is provided.',
  },
  no_goals_and_actions: {
    id: `${baseId}.no_goals_and_actions`,
    defaultMessage: "This patient doesn't have any health goals or actions",
  },
  health_goal_key: {
    id: `${baseId}.health_goal_key`,
    defaultMessage: 'Health goal',
  },
  actions_key: {
    id: `${baseId}.actions_key`,
    defaultMessage: 'Actions',
  },
  goals_and_actions_heading: {
    id: `${baseId}.goals_and_actions_heading`,
    defaultMessage: 'Health goals and actions',
  },
  draft_tag: {
    id: `${baseId}.draft_tag`,
    defaultMessage: 'draft',
  },
  not_editable: {
    id: `${baseId}.not_editable`,
    defaultMessage: 'This content is not editable by certain roles.',
  },
})
