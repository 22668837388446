import { injectIntl } from 'react-intl'
import { branch, compose, pure, renderNothing } from 'recompose'

import { withBabylonUser } from '@babylon/babylon-user'

import withErrorMessage from '~/core/with-error-message'
import withModal from '~/core/with-modal'
import { withNotify } from '~/ui/Notification'

import withActions from './actions'
import { labels, validation } from './constants'
import withData from './data'
import PrescriptionModal from './PrescriptionModal'
import withState from './state'

const enhance = compose(
  injectIntl,
  withNotify,
  withModal({
    validation,
    labels,
  }),
  withBabylonUser,
  withData,
  branch(({ data }) => !data.consultation, renderNothing),
  withState,
  withErrorMessage(),
  withActions,
  pure
)

export default enhance(PrescriptionModal)
