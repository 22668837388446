import { TypePolicies } from '@apollo/client/cache'
import { differenceInYears } from 'date-fns'

import { CpLiteChatscriptMessagesQuery } from '~/generated'

type ChatscriptMessages = CpLiteChatscriptMessagesQuery['chatscriptMessages']

const typePolicies: TypePolicies = {
  ContactDetails: {
    // ContactDetails has no id, but is used for both Patient.contact_details
    // and Patient.account_owner_contact_details.  Setting merge:true merges the
    // results from the ConsultationPage query into the cache without crashing
    merge: true,
  },
  Consultation: {
    fields: {
      prescriptions: {
        // When prescriptions are updated, use the incoming array
        merge: false,
      },
      referrals: {
        // When referrals are updated, use the incoming array
        merge: false,
      },
      diagnosticTests: {
        // When diagnosticTests are updated, use the incoming array
        merge: false,
      },
      pathologyTests: {
        // When pathologyTests are updated, use the incoming array
        merge: false,
      },
      sickNotes: {
        // When sickNotes are updated, use the incoming array
        merge: false,
      },
    },
  },
  Patient: {
    merge: true,
    fields: {
      age: {
        read(_, { readField }) {
          const dob = readField('date_of_birth') as string

          return dob ? differenceInYears(new Date(), new Date(dob)) : null
        },
      },
    },
  },
  WorkflowDefinitionGroup: {
    // WorkflowDefinitionGroup has no ID but the combination of type and label
    // can be assumed to be unique to substitute for an ID
    keyFields: ['type', 'label'],
  },
  PatientMetrics: {
    fields: {
      smoking_status: {
        // Using `merge: false` replaces the existing array of smoking_status values
        // with a new array containing just the newly added metric in it
        // TODO - merge incoming with existing when historic metrics are required
        merge: false,
      },
      height: {
        // Using `merge: false` replaces the existing array of height values
        // with a new array containing just the newly added metric in it
        // TODO - merge incoming with existing when historic metrics are required
        merge: false,
      },
      weight: {
        // Using `merge: false` replaces the existing array of weight values
        // with a new array containing just the newly added metric in it
        // TODO - merge incoming with existing when historic metrics are required
        merge: false,
      },
      blood_pressure: {
        // Using `merge: false` replaces the existing array of blood_pressure values
        // with a new array containing just the newly added metric in it
        // TODO - merge incoming with existing when historic metrics are required
        merge: false,
      },
    },
  },
  CarePlanAction: {
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
    // This embeds the goal and action revisions in the parent care plan revision object
    // This is required as one goal revision can be associated with a different set of actions in different care plan revisions.
    //
    // This affects moving between historic consultations and loading different revisions of the same care plan in patient history:
    // Given two historic completed consultations A and B, each with its own care plan revision,
    // viewing A, then B, then A again should display the correct set of goal and action revisions
    keyFields: false,
  },
  CarePlanGoal: {
    // https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
    // This embeds the goal and action revisions in the parent care plan revision object
    // This is required as one goal revision can be associated with a different set of actions in different care plan revisions.
    //
    // This affects moving between historic consultations and loading different revisions of the same care plan in patient history:
    // Given two historic completed consultations A and B, each with its own care plan revision,
    // viewing A, then B, then A again should display the correct set of goal and action revisions
    keyFields: false,
  },
  CarePlan: {
    // The revision ID is needed to uniquely identify the combinations of goals and actions associated with each care plan revision.
    //
    // For example in patient history, WITHOUT using the revisionId:
    // - given two historic completed consultations A and B, each with its own care plan revision,
    // - when viewing consultation A, the consultationId in the query gets associated with the carePlan.id, and the care plan enters the cache
    // - when viewing consultation B, the consultationId in the query gets associated with the same carePlan.id, and the cache is updated
    // - when returning to consultation A, the cache is hit, and the care plan revision from consultation B is incorrectly displayed
    keyFields: ['id', 'revisionId'],
  },
  CarePlanDraft: {
    // CarePlanDraft does not have an 'id' or '_id' field.
    // The 'draftId' field uniquely identifies the entity.
    keyFields: ['draftId'],
  },
  GoalDraft: {
    // GoalDraft does not have an 'id' or '_id' field.
    // The 'draftId' field uniquely identifies the entity.
    keyFields: ['draftId'],
  },
  ActionDraft: {
    // ActionDraft does not have an 'id' or '_id' field.
    // The 'draftId' field uniquely identifies the entity.
    keyFields: ['draftId'],
  },
  Query: {
    fields: {
      consultation(existing, { args, toReference }) {
        // This is used to redirect queries for `consultation` to the cache if
        // the id arg matches a consultation already in the cache
        // Eg it may already be in the cache from fetching the consultations for the schedule
        return (
          existing ||
          toReference({
            __typename: 'Consultation',
            id: args?.id,
          })
        )
      },

      patient(existing, { args, toReference }) {
        // This is used to redirect queries for `patient` to the cache if
        // the id arg matches a patient already in the cache
        // Eg it may already be in the cache as part of the consultation page query
        return (
          existing ||
          toReference({
            __typename: 'Patient',
            id: args?.id,
          })
        )
      },

      patient_alert: (existing, { args, toReference }) => {
        // This has been copied over from previous cacheRedirects from apollo-client 2
        // It is unknown if it is used or not, the app works ok without it
        // It may help return items from the cache for certain queries
        return (
          existing ||
          toReference({
            __typename: 'PatientAlert',
            id: args?.id,
            patientId: args?.patientId,
          })
        )
      },

      region: (existing, { args, toReference }) => {
        // This has been copied over from previous cacheRedirects from apollo-client 2
        // It is unknown if it is used or not, the app works ok without it
        // It may help return items from the cache for certain queries
        return (
          existing ||
          toReference({
            __typename: 'Region',
            id: args?.id,
          })
        )
      },

      specialist: (existing, { args, toReference }) => {
        // This has been copied over from previous cacheRedirects from apollo-client 2
        // It is unknown if it is used or not, the app works ok without it
        // It may help return items from the cache for certain queries
        return (
          existing ||
          toReference({
            __typename: 'Specialist',
            id: args?.id,
          })
        )
      },

      timelineChatConversation: (existing, { args, toReference }) => {
        // This has been copied over from previous cacheRedirects from apollo-client 2
        // It is unknown if it is used or not, the app works ok without it
        // It may help return items from the cache for certain queries
        return (
          existing ||
          toReference({
            __typename: 'TimelineChatConversation',
            id: args?.id,
            patientId: args?.patientId,
          })
        )
      },

      consultant: (existing, { args, toReference }) => {
        // This has been copied over from previous cacheRedirects from apollo-client 2
        // It is unknown if it is used or not, the app works ok without it
        // It may help return items from the cache for certain queries
        return (
          existing ||
          toReference({
            __typename: 'Consultant',
            id: args?.id,
          })
        )
      },

      drug: (existing, { args, toReference }) => {
        // This has been copied over from previous cacheRedirects from apollo-client 2
        // It is unknown if it is used or not, the app works ok without it
        // It may help return items from the cache for certain queries
        return (
          existing ||
          toReference({
            __typename: 'Drug',
            id: args?.id,
            region: args?.region,
          })
        )
      },

      chatscriptMessages: {
        // This defines how `fetchMore` pagination results for `chatscriptMessages`
        // should be merged into the cache, and which args identify the query
        keyArgs: ['userId', 'conversationId'],
        merge(
          existing: ChatscriptMessages | undefined,
          incoming: ChatscriptMessages
        ) {
          const existingMessages = existing?.messages ?? []
          const incomingMessages = incoming?.messages ?? []
          return {
            ...existing,
            ...incoming,
            messages: [...existingMessages, ...incomingMessages],
          }
        },
      },
    },
  },
}

export default typePolicies
