import { useConsultantUser } from '@babylon/babylon-user'

import { MULTIMEDIA_METRICS_SERVICE_URL } from '~/constants'
import { useInterval, useUnmount } from '~/core/hooks'
import http from '~/core/http-client'
import { logException } from '~/core/sentry'

import {
  OTEventHandlerObject,
  OTPublisherGetStats,
  OTSubscriberGetStats,
} from '../useOpentok'
import useEventMonitorQueue from './useEventMonitorQueue'
import useMetricsQueue from './useMetricsQueue'

const SEND_CALL_DATA_INTERVAL_DURATION = 5000

type UseCallMonitor = (input: {
  consultationId: string
  sessionId: string
  isCallActive: boolean
  addSessionEventHandlers: (eventHandlers: OTEventHandlerObject) => void
  addSubscriberEventHandlers: (eventHandlers: OTEventHandlerObject) => void
  getSubscriberStats: OTSubscriberGetStats | undefined
  getPublisherStats: OTPublisherGetStats | undefined
}) => void

const useCallMonitor: UseCallMonitor = ({
  consultationId,
  sessionId,
  isCallActive,
  addSessionEventHandlers,
  addSubscriberEventHandlers,
  getSubscriberStats,
  getPublisherStats,
}) => {
  const user = useConsultantUser()

  const consumeEventsQueue = useEventMonitorQueue({
    addSessionEventHandlers,
    addSubscriberEventHandlers,
  })

  const consumeMetricsQueue = useMetricsQueue({
    getSubscriberStats,
    getPublisherStats,
    isCallActive,
  })

  const sendCallData = async () => {
    const events = consumeEventsQueue()
    const metrics = consumeMetricsQueue()

    if (events.length === 0 && metrics.length === 0) {
      return
    }

    const payload = {
      time: new Date().toISOString(),
      consultation_id: consultationId,
      client_type: 'clinician',
      client_id: user?.id,
      open_tok_session_id: sessionId,
      open_tok_connection_metrics: metrics,
      events,
    }

    try {
      await http.post(`${MULTIMEDIA_METRICS_SERVICE_URL}/v1/stats`, payload)
    } catch (err) {
      logException(err)
    }
  }

  useInterval(
    sendCallData,
    isCallActive ? SEND_CALL_DATA_INTERVAL_DURATION : null
  )

  useUnmount(sendCallData)
}

export default MULTIMEDIA_METRICS_SERVICE_URL ? useCallMonitor : () => null
