import React, { ReactNode } from 'react'
import classnames from 'classnames'

import './Alert.scss'

export interface Props {
  className?: string
  icon?: ReactNode
  intent?: 'primary' | 'success' | 'warning' | 'error'
  interactive?: boolean
  title?: ReactNode
  children?: ReactNode
}

const getClassName = ({ intent, interactive, className }: Props) =>
  classnames(
    'core-alert',
    intent && `core-alert--${intent}`,
    interactive && 'core-alert--interactive',
    className
  )

const Alert = ({
  className,
  icon,
  intent,
  interactive,
  children,
  title,
  ...rest
}: Props) => (
  <div className={getClassName({ intent, interactive, className })} {...rest}>
    <div className="core-alert__prefix">{icon}</div>
    <div className="core-alert__content">
      {title ? <div className="core-alert__title">{title}</div> : null}
      <div className="core-alert__message">{children}</div>
    </div>
  </div>
)

export default Alert
