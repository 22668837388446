import { endOfDay, startOfDay } from 'date-fns'

import { useConsultantUser } from '@babylon/babylon-user'

import {
  FullConsultationScheduleQuery,
  useFullConsultationScheduleQuery,
} from '~/generated'

import {
  endOfDayWithExtraHours,
  isTodayPost6pm,
  isTodayPre1am,
  startOfDayWithExtraHours,
} from './utils'

export type Consultation = FullConsultationScheduleQuery['consultations'][number]

/**
 * `usePrefetchConsultations` is used to prefetch consultations and populate the cache
 *
 * Note: The querying here is split out into 3 separate calls with variables
 * that will only change when the day changes - to make it easier to cache the results
 */

const usePrefetchConsultations = (date: Date): boolean => {
  const user = useConsultantUser()

  const variables = {
    consultants: [user.id],
  }

  // No matter what day `date` is...
  // query for the day's consultations - 00:00:00 => 23:59:59
  const mainResult = useFullConsultationScheduleQuery({
    variables: {
      ...variables,
      fromDate: startOfDay(date).toISOString(),
      toDate: endOfDay(date).toISOString(),
    },
  })

  // If `date` is today and the time is after 6pm...
  // query for tomorrow's consultations from 23:59:59 tonight => 05:59:59 tomorrow
  const post6pmTodayResult = useFullConsultationScheduleQuery({
    variables: {
      ...variables,
      fromDate: endOfDay(date).toISOString(),
      toDate: endOfDayWithExtraHours(date).toISOString(),
    },
    skip: !isTodayPost6pm(date),
  })

  // If `date` is today and the time is before 1am...
  // query for yesterday's consultations from 23:00:00 yesterday => midnight
  const pre1amTodayResult = useFullConsultationScheduleQuery({
    variables: {
      ...variables,
      fromDate: startOfDayWithExtraHours(date).toISOString(),
      toDate: startOfDay(date).toISOString(),
    },
    skip: !isTodayPre1am(date),
  })

  return (
    mainResult.loading ||
    post6pmTodayResult.loading ||
    pre1amTodayResult.loading
  )
}

export default usePrefetchConsultations
