import { History } from 'history'
import React, { ReactNode } from 'react'
import { match as Match, RouteComponentProps, withRouter } from 'react-router'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ConsultationWorkflowV2 } from '~/features/workflows/WorkflowActions/useConsultationWorkflowsV2Query'
import { Section } from '~/ui/Section'

import Workflow from '../Workflow'

import messages from './WorkflowAction.messages'

const handleButtonClick = (
  path: string | undefined,
  type: string | undefined,
  match: Match,
  history: History
): void => {
  const url = type
    ? `${match.url}/workflow/create/${type}`
    : `${match.url}/workflow/${path}`
  history.replace(url)
}

interface Props extends RouteComponentProps {
  name: Maybe<string> | undefined
  type?: string
  path?: string
  workflows: ConsultationWorkflowV2[]
  canCreate: boolean
  loading?: boolean
  children?: ReactNode
  dataTestId?: string
  restrictFinalizedConsultationEditing: boolean
}

const WorkflowAction = ({
  name,
  type,
  path,
  match,
  history,
  workflows = [],
  canCreate,
  loading = false,
  children,
  dataTestId,
  restrictFinalizedConsultationEditing,
}: Props) => {
  const fm = useFormatMessage()

  return (
    <Section
      loading={loading}
      type="secondary"
      title={name}
      topRight={
        canCreate && (
          <Button
            intent="secondary"
            onClick={() => handleButtonClick(path, type, match, history)}
            disabled={restrictFinalizedConsultationEditing}
          >
            {fm(messages.create_button_label, { name })}
          </Button>
        )
      }
      dataTestId={dataTestId}
    >
      {children}
      {workflows.map((workflow) => (
        <Workflow key={workflow.id} workflow={workflow} />
      ))}
    </Section>
  )
}

export default withRouter(WorkflowAction)
