import React from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { TimelineConsultationEventPluginInterface } from '~/core/config/modules/generated/types'
import { useConsultationId } from '~/features/consultation'
import {
  PatientTimelineConsultationFragmentFragment,
  TimelineConsultationQueryResult,
  useCurrentConsultationQuery,
  useTimelineConsultationQuery,
} from '~/generated'

import TimelineConsultationActions from './TimelineConsultationActions'
import TimelineConsultationEventView from './TimelineConsultationEventView'

export type TimelineConsultation = NonNullable<
  TimelineConsultationQueryResult['data']
>['consultation']

export interface TimelineConsultationEventProps {
  event: PatientTimelineConsultationFragmentFragment
  patientId: string
}

const TimelineConsultationEventModule: TimelineConsultationEventPluginInterface = ({
  includeActions,
}) => {
  const TimelineConsultationEvent = ({
    event,
    patientId,
  }: TimelineConsultationEventProps) => {
    const currentConsultationId = useConsultationId()
    const user = useConsultantUser()

    const isCurrentConsultation = event && currentConsultationId === event.id

    const { data, loading, error } = useTimelineConsultationQuery({
      // If the event on the timeline is the current consultation then skip and retrieve less data from cache using `useCurrentConsultationQuery()`
      skip: isCurrentConsultation,
      variables: {
        consultationId: event.id,
      },
    })
    const consultation = data?.consultation

    const { data: currentConsultationData } = useCurrentConsultationQuery({
      skip: !isCurrentConsultation,
      variables: {
        id: event.id,
      },
    })
    const currentConsultation = currentConsultationData?.consultation

    const isIncomplete = consultation?.status === 'paid'

    return (
      <TimelineConsultationEventView
        patientId={patientId}
        consultation={consultation}
        currentConsultation={currentConsultation}
        event={event}
        isCurrentConsultation={isCurrentConsultation}
        currentUser={user.consultant}
        loading={loading}
        error={error}
        consultationActions={
          includeActions ? (
            <TimelineConsultationActions
              isIncomplete={isIncomplete}
              consultationId={event.id}
            />
          ) : null
        }
      />
    )
  }

  return TimelineConsultationEvent
}

export default TimelineConsultationEventModule
