import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.examination.medical_history'

export default defineMessages({
  allergies: {
    id: `${baseId}.allergies`,
    defaultMessage: 'Search and select allergies...',
  },
  medications: {
    id: `${baseId}.medications`,
    defaultMessage: 'Search and select medications...',
  },
  conditions: {
    id: `${baseId}.conditions`,
    defaultMessage: 'Search and select conditions...',
  },
  default: {
    id: `${baseId}.default`,
    defaultMessage: 'Search and select...',
  },
})
