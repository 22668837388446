import { useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import React, { FC } from 'react'

import { FormikSelect, FormikSelectAsync } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { WorkflowDefinitionVariable } from '~/generated'
import { MiniErrorPanel } from '~/ui/ErrorPanel'

import { FIELD_TYPES, LOAD_OPTIONS_DEBOUNCE } from '../constants'
import {
  getFieldCodingSystem,
  getFieldData,
  VALID_CODE_SYSTEMS,
} from '../utils'
import { SEARCH_POINT_OF_CARE_QUERY } from './queries'

import messages from './WorkflowDefinitionField.messages'

export interface Props {
  field: WorkflowDefinitionVariable
  fields: WorkflowDefinitionVariable[]
  values: any
  setFieldValue: (field: string, value: any) => void
}

const WorkflowDefinitionField: FC<Props> = ({
  field,
  fields,
  values,
  setFieldValue,
}) => {
  const fm = useFormatMessage()

  // This query is skipped so that it doesn't run for every workflow definition field and only for async select fields
  const { refetch } = useQuery(SEARCH_POINT_OF_CARE_QUERY, {
    skip: true,
  })

  const fieldMessages = {
    validation_required: fm(messages.validation_required),
    validation_numeric: fm(messages.validation_numeric),
    validation_decimal: fm(messages.validation_decimal),
    validation_incorrect_value: fm(messages.validation_incorrect_value),
    validation_multiline_charLimit: fm(messages.validation_multiline_charLimit),
    boolean_yes: fm(messages.boolean_yes),
    boolean_no: fm(messages.boolean_no),
  }

  const { fieldProps, displayField } = getFieldData(
    field,
    fields,
    values,
    setFieldValue,
    fieldMessages
  )

  // fetch coding system for async select fields
  const codingSystem = getFieldCodingSystem(field?.metadata)

  if (codingSystem) {
    // display error if coding system is not valid
    if (!Object.values(VALID_CODE_SYSTEMS).includes(codingSystem)) {
      return (
        <MiniErrorPanel
          title={fm(messages.load_options_error)}
          description={fm(messages.coding_system_error_message, {
            codingSystem,
          })}
          error={new Error(fm(messages.coding_system_error_message))}
        />
      )
    }
    const loadOptions = async (
      inputValue: string,
      callback: (arg0: any) => void
    ) => {
      const { data, errors } = await refetch({
        term: inputValue,
        index: codingSystem,
      })

      if (errors) {
        return []
      }

      const options = data?.searchPointOfCareEntity?.concepts.map(
        (concept: {
          code: { code: string; system: string; display: string }
        }) => {
          return {
            value: JSON.stringify({
              code: concept.code.code,
              system: concept.code.system,
            }),
            label: `${concept.code.display} (${concept.code.code})`,
          }
        }
      )
      callback(options)
    }
    const debouncedLoadOptions = debounce(loadOptions, LOAD_OPTIONS_DEBOUNCE)
    fieldProps.loadOptions = (inputValue: string, callback: any) => {
      debouncedLoadOptions(inputValue, callback)
    }
  }

  const Field = fieldProps.loadOptions
    ? FormikSelectAsync
    : fieldProps.options
    ? FormikSelect
    : FIELD_TYPES[field.type]

  return displayField ? (
    <Field data-testid={field.name} {...fieldProps} />
  ) : null
}

export default WorkflowDefinitionField
