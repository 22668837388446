import debounce from 'lodash/debounce'
import React from 'react'

import { DISABLE_MEDICAL_HISTORY_FETCH_OPTIONS } from '~/constants'
import { errorAlert } from '~/core/with-error-message'
import { errorOptions } from '~/ui/ErrorMessage'

import MedicalSelect from './MedicalSelect'

import messages from './MedicalSelect.messages'

export default ({ name, gql, gaActions }) =>
  class MedicalSelectWrapper extends React.Component {
    state = {
      options: [],
      isLoading: false,
      isWaiting: false,
    }

    onDebounceInputChange = debounce((value) => this.fetchOptions(value), 420)

    componentWillUnmount() {
      this.onDebounceInputChange.cancel()
    }

    onInputChange = (query) => {
      this.onDebounceInputChange(query)
      return query
    }

    fetchOptions = async (query) => {
      // This feature flag will be used to UAT test this change with Telus.
      // Once accepted, the options fetching logic can be removed entirely.
      if (DISABLE_MEDICAL_HISTORY_FETCH_OPTIONS) return query

      const { state, props } = this
      if (!state.isLoading) {
        this.setState({ isLoading: true })
      }

      let options = []

      try {
        const { data } = await props.optionsMutation({
          variables: {
            query,
          },
        })

        options = data.options || []
      } catch (exception) {
        console.warn(exception)
        this.setState({
          options: errorOptions,
          isLoading: false,
        })
        return query
      }

      const formattedOptions = options.map((v) => ({
        id: v.suggestedLabel,
        iri: v.iri,
        info: v.suggestedLabel,
      }))

      this.setState({
        options: formattedOptions,
        isLoading: false,
      })

      return query
    }

    onAddToList = async (id, { iri, info }) => {
      const {
        patientId: patientIdString,
        addMutation,
        trackMedicalHistory,
      } = this.props

      if (addMutation) {
        const patientId = parseInt(patientIdString)
        this.setState({ isWaiting: true })

        try {
          await addMutation({
            variables: {
              patientId,
              iri,
              info,
            },
            refetchQueries: [
              {
                query: gql.Query,
                variables: {
                  patientId,
                },
              },
            ],
          })
          trackMedicalHistory({ action: gaActions.addSuccess })
        } catch (exception) {
          trackMedicalHistory({ action: gaActions.addFailure })
          errorAlert({ logMessage: exception })
        }

        this.setState({ isWaiting: false })
      }
    }

    onRemoveFromList = async (id) => {
      const {
        patientId: patientIdString,
        removeMutation,
        trackMedicalHistory,
      } = this.props

      if (removeMutation) {
        const patientId = parseInt(patientIdString)
        this.setState({ isWaiting: true })
        try {
          await removeMutation({
            variables: {
              patientId,
              id,
            },
            refetchQueries: [
              {
                query: gql.Query,
                variables: {
                  patientId,
                },
              },
            ],
          })
          trackMedicalHistory({ action: gaActions.removeSuccess })
        } catch (exception) {
          trackMedicalHistory({ action: gaActions.removeFailure })
          errorAlert({ logMessage: exception })
        }

        this.setState({ isWaiting: false })
      }
    }

    render() {
      const { isLoading, isWaiting, options } = this.state
      const { data, intl, ...rest } = this.props
      const value = data.patient ? data.patient[name] : []

      return (
        <MedicalSelect
          name={name}
          placeholder={intl.formatMessage(messages[name] || messages.default)}
          isLoading={data.loading || isLoading || isWaiting}
          value={value}
          options={options}
          onAddToList={this.onAddToList}
          onRemoveFromList={this.onRemoveFromList}
          onInputChange={this.onInputChange}
          {...rest}
        />
      )
    }
  }
