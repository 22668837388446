import { faVial } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'
import * as Access from '@babylon/web-platform-access'

import { ENABLE_TESTS_AND_KITS_V2 } from '~/constants'
import { NavigationLink } from '~/features/navigation'

import messages from './Navigation.messages'

export const LazyTestsKitsPage = React.lazy(() => import('./TestsKitPage'))

interface TestsKitsPageNavigationItemProps {
  path: string
}

export const TestsKitsPageNavigationItem = ({
  path,
}: TestsKitsPageNavigationItemProps) => {
  const fm = useFormatMessage()
  const [granted, error] = Access.useDecide('test-kits-page')

  if (error) {
    throw error
  }

  if (!granted || !ENABLE_TESTS_AND_KITS_V2) {
    return null
  }

  return (
    <NavigationLink
      data-testid="test-kits-navigation-link"
      to={path}
      icon={
        <FontAwesomeIcon
          icon={faVial}
          style={{ height: 24, width: 24 }}
          color="#6B7785"
        />
      }
    >
      {fm(messages.tests_text)}
    </NavigationLink>
  )
}
