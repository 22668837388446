import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review'

export default defineMessages({
  sick_notes_section_table_reason_heading: {
    id: `${baseId}.sick_notes_section_table_reason_heading`,
    defaultMessage: 'Reason',
  },
  sick_notes_section_table_start_heading: {
    id: `${baseId}.sick_notes_section_table_start_heading`,
    defaultMessage: 'Start',
  },
  sick_notes_section_table_end_heading: {
    id: `${baseId}.sick_notes_section_table_end_heading`,
    defaultMessage: 'End',
  },

  sick_notes_section_title_private: {
    id: `${baseId}.sick_notes_section_title_private_v2`,
    defaultMessage: 'Private sick notes',
  },
  sick_notes_section_error: {
    id: `${baseId}.sick_notes_section_error`,
    defaultMessage: 'Error fetching sick notes',
  },
})
