import React from 'react'
import { injectIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown'

import Message from '~/ui/Message'

import messages from './ClinicalConsent.messages'

const ClinicalConsentMessage = ({ intl }) => (
  <Message type="info">
    <ReactMarkdown source={intl.formatMessage(messages.contentMd)} />
  </Message>
)

export default injectIntl(ClinicalConsentMessage)
