import { IntlFormatters } from 'react-intl'
import * as Yup from 'yup'

import {
  validatePhoneCountryCode,
  validatePhoneNumber,
} from './customValidators'

import messages from './validation.messages'

const initialiseValidation = (
  formatMessage: IntlFormatters['formatMessage']
) => {
  // Default messages
  Yup.setLocale({
    mixed: {
      required: formatMessage(messages.required_field),
    },
    string: {
      email: formatMessage(messages.invalid_email),
    },
  })

  // Custom validators
  Yup.addMethod(Yup.string, 'phone', validatePhoneNumber(formatMessage))
  Yup.addMethod(
    Yup.string,
    'phoneCountryCode',
    validatePhoneCountryCode(formatMessage)
  )
}

declare module 'yup' {
  interface StringSchema {
    phone(
      this: StringSchema,
      countryCode?: boolean,
      message?: string
    ): StringSchema

    phoneCountryCode(this: StringSchema, message?: string): StringSchema
  }
}

export default initialiseValidation
