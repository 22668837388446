import { OTEvent } from '../useOpentok'

export type OTRole = 'publisher' | 'subscriber'

/* eslint-disable camelcase */
export interface CallMetric {
  role: OTRole
  time: string
  audio: {
    bytes_sent?: number
    bytes_received?: number
    packets_sent?: number
    packets_received?: number
    packets_lost?: number
  }
  video: {
    bytes_sent?: number
    bytes_received?: number
    packets_sent?: number
    packets_received?: number
    packets_lost?: number
    frame_rate?: number
  }
}
/* eslint-enable camelcase */

export interface OTCallStats {
  timestamp: number
  audio?: {
    bytesSent?: number
    bytesReceived?: number
    packetsSent?: number
    packetsReceived?: number
    packetsLost?: number
  }
  video?: {
    bytesSent?: number
    bytesReceived?: number
    packetsSent?: number
    packetsReceived?: number
    packetsLost?: number
    frameRate?: number
  }
}

export const formatCallMetrics = (
  stats: OTCallStats,
  role: OTRole
): CallMetric => ({
  role,
  time: new Date(stats.timestamp).toISOString(),
  audio: {
    bytes_sent: stats.audio?.bytesSent,
    bytes_received: stats.audio?.bytesReceived,
    packets_sent: stats.audio?.packetsSent,
    packets_received: stats.audio?.packetsReceived,
    packets_lost: stats.audio?.packetsLost,
  },
  video: {
    bytes_sent: stats.video?.bytesSent,
    bytes_received: stats.video?.bytesReceived,
    packets_sent: stats.video?.packetsSent,
    packets_received: stats.video?.packetsReceived,
    packets_lost: stats.video?.packetsLost,
    frame_rate: stats.video?.frameRate && Math.round(stats.video.frameRate),
  },
})

export interface ConnectionEvent {
  name: string
  time: string
  body?: string
}

export const formatCallEvent = (event?: OTEvent): ConnectionEvent => {
  const time: string = new Date().toISOString()

  if (!event) {
    return {
      time,
      name: 'unknown',
    }
  }

  switch (event.type) {
    case 'sessionConnected': {
      return {
        time,
        name: 'sessionConnected',
      }
    }
    case 'sessionDisconnected': {
      return {
        time,
        name: 'sessionDisconnected',
      }
    }
    case 'streamCreated': {
      return {
        time,
        name: 'subscriberStreamCreated',
      }
    }
    case 'streamDestroyed': {
      return {
        time,
        name: 'subscriberStreamDestroyed',
      }
    }
    case 'videoDisableWarning': {
      return {
        time,
        name: 'subscriberVideoDisableWarning',
      }
    }
    case 'videoDisableWarningLifted': {
      return {
        time,
        name: 'subscriberVideoDisableWarningLifted',
      }
    }
    case 'videoDisabled': {
      return {
        time,
        name: 'subscriberVideoDisabled',
        body: JSON.stringify({ reason: event.reason }),
      }
    }
    case 'videoEnabled': {
      return {
        time,
        name: 'subscriberVideoEnabled',
        body: JSON.stringify({ reason: event.reason }),
      }
    }
    default: {
      return {
        time,
        name: event.type,
      }
    }
  }
}
