import React from 'react'

import { ConsultantUser } from '@babylon/babylon-user/src'

import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import { GetAppointmentInvitesQueryResult } from '~/generated'

import AppointmentInvitesTable from '../AppointmentInvites/components/AppointmentInvitesTable'

import messages from './AppointmentInvitesSummary.messages'
import styles from './styles.module.scss'

export interface AppointmentInvitesSummaryProps {
  appointmentInvites: NonNullable<
    GetAppointmentInvitesQueryResult['data']
  >['appointmentInvites']
  currentConsultant: ConsultantUser['consultant']
  light?: boolean
}

const AppointmentInvitesSummary = ({
  appointmentInvites,
  currentConsultant,
  light,
}: AppointmentInvitesSummaryProps) => {
  const f = useMessages(messages)

  return (
    <div className={light && styles.light}>
      <Section title={f('invites_title')} testid="cr-appointment-invites">
        <AppointmentInvitesTable
          appointmentInvites={appointmentInvites}
          currentConsultant={currentConsultant}
        />
      </Section>
    </div>
  )
}

export default AppointmentInvitesSummary
