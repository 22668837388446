import { Formik, FormikProps } from 'formik'
import React, { createContext, useContext } from 'react'

import { PatientMetricObservationType } from '~/generated'

import { OnSubmitType, SubmitInfo } from './usePatientMetricsData'

export interface PatientMetricsState {
  height?: number
  displayHeight?: number
  weight?: number
  systolic?: number
  diastolic?: number
  blood_pressure?: any
  displayWeight?: number
  smoking_status?: SmokingStatus
  displaySystolic?: number
  displayDiastolic?: number
  displaySmokingStatus?: SmokingStatus
  ageInYears?: number
  performerWeight?: string
  performerHeight?: string
  performerSmoking?: string
  performerBloodPressure?: string
  timestampSmokingStatus?: Date
  timestampHeight?: Date
  timestampWeight?: Date
  timestampBloodPressure?: Date
}

export enum SmokingStatus {
  non_smoker = 'non_smoker',
  ex_smoker = 'ex_smoker',
  smoker = 'smoker',
}

export enum MeasurementType {
  metric = 'metric',
  imperial = 'imperial',
  us = 'us',
}

interface HandleSubmitType {
  (
    tag: PatientMetricObservationType[],
    parentTag: PatientMetricObservationType
  ): void
  (tag: PatientMetricObservationType): void
}
interface PatientMetricsFormikProps<T> extends FormikProps<T> {
  handleTaggedSubmit: HandleSubmitType
  displayValues: PatientMetricsState
  submitInfo: SubmitInfo
}

const PatientMetricsStateContext = createContext<
  PatientMetricsFormikProps<PatientMetricsState>
>({} as PatientMetricsFormikProps<PatientMetricsState>)

export const usePatientMetricsState = () =>
  useContext(PatientMetricsStateContext)

interface PatientMetricsStateProviderProps {
  initialValues: PatientMetricsState
  displayValues: PatientMetricsState
  onSubmit: OnSubmitType
  submitInfo: SubmitInfo
}

const PatientMetricsStateProvider: React.FC<PatientMetricsStateProviderProps> = ({
  children,
  initialValues,
  displayValues,
  onSubmit,
  submitInfo,
}) => {
  return (
    <Formik onSubmit={() => {}} initialValues={initialValues}>
      {(formik: FormikProps<PatientMetricsState>) => (
        <PatientMetricsStateContext.Provider
          value={{
            ...formik,
            displayValues,
            handleTaggedSubmit: (
              tag:
                | PatientMetricObservationType
                | PatientMetricObservationType[],
              parentTag?: PatientMetricObservationType
            ) => {
              if (!Array.isArray(tag)) {
                return onSubmit(tag, formik.values)
              }
              if (parentTag) {
                return onSubmit(tag, formik.values, parentTag)
              }

              throw new Error('Cant submit list of tags without a parent tag')
            },
            submitInfo,
          }}
        >
          {children}
        </PatientMetricsStateContext.Provider>
      )}
    </Formik>
  )
}

export default PatientMetricsStateProvider
