import { OPENTOK_ERROR_NAMES } from './constants'
import { OTError } from './types'

import ERROR_MESSAGES from './opentokErrors.messages'

export const getActionableErrorMessage = ({ name }: OTError) => {
  switch (name) {
    case OPENTOK_ERROR_NAMES.OT_NO_DEVICES_FOUND:
    case OPENTOK_ERROR_NAMES.OT_NO_VALID_CONSTRAINTS:
    case OPENTOK_ERROR_NAMES.OT_CONSTRAINTS_NOT_SATISFIED:
    case OPENTOK_ERROR_NAMES.OT_USER_MEDIA_ACCESS_DENIED: {
      return ERROR_MESSAGES.hardware
    }

    case OPENTOK_ERROR_NAMES.OT_HARDWARE_UNAVAILABLE: {
      return ERROR_MESSAGES.hardware_in_use
    }

    case OPENTOK_ERROR_NAMES.OT_INVALID_PARAMETER:
    case OPENTOK_ERROR_NAMES.OT_REPORT_ISSUE_FAILED:
    case OPENTOK_ERROR_NAMES.OT_AUTHENTICATION_ERROR:
    case OPENTOK_ERROR_NAMES.OT_TERMS_OF_SERVICE_FAILURE:
    case OPENTOK_ERROR_NAMES.OT_PERMISSION_DENIED:
    case OPENTOK_ERROR_NAMES.OT_RATE_LIMIT_EXCEEDED: {
      return ERROR_MESSAGES.contact_support
    }

    case OPENTOK_ERROR_NAMES.OT_MEDIA_ERR_ABORTED:
    case OPENTOK_ERROR_NAMES.OT_MEDIA_ERR_DECODE:
    case OPENTOK_ERROR_NAMES.OT_BADLY_FORMED_RESPONSE:
    case OPENTOK_ERROR_NAMES.OT_EMPTY_RESPONSE_BODY:
    case OPENTOK_ERROR_NAMES.OT_INVALID_SESSION_ID:
    case OPENTOK_ERROR_NAMES.OT_INVALID_HTTP_STATUS:
    case OPENTOK_ERROR_NAMES.OT_XDOMAIN_OR_PARSING_ERROR:
    case OPENTOK_ERROR_NAMES.OT_CREATE_PEER_CONNECTION_FAILED:
    case OPENTOK_ERROR_NAMES.OT_ICE_WORKFLOW_FAILED:
    case OPENTOK_ERROR_NAMES.OT_SET_REMOTE_DESCRIPTION_FAILED:
    case OPENTOK_ERROR_NAMES.OT_STREAM_CREATE_FAILED:
    case OPENTOK_ERROR_NAMES.OT_TIMEOUT:
    case OPENTOK_ERROR_NAMES.OT_UNEXPECTED_SERVER_RESPONSE:
    case OPENTOK_ERROR_NAMES.OT_NOT_FOUND:
    case OPENTOK_ERROR_NAMES.OT_DISCONNECTED:
    case OPENTOK_ERROR_NAMES.OT_STREAM_NOT_FOUND: {
      return ERROR_MESSAGES.try_again
    }

    case OPENTOK_ERROR_NAMES.OT_MEDIA_ERR_NETWORK:
    case OPENTOK_ERROR_NAMES.OT_NOT_CONNECTED:
    case OPENTOK_ERROR_NAMES.OT_CONNECT_FAILED: {
      return ERROR_MESSAGES.network_try_again
    }

    case OPENTOK_ERROR_NAMES.OT_MEDIA_ERR_SRC_NOT_SUPPORTED:
    case OPENTOK_ERROR_NAMES.OT_NOT_SUPPORTED: {
      return ERROR_MESSAGES.media_not_supported
    }

    case OPENTOK_ERROR_NAMES.OT_SCREEN_SHARING_NOT_SUPPORTED:
    case OPENTOK_ERROR_NAMES.OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED:
    case OPENTOK_ERROR_NAMES.OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED: {
      return ERROR_MESSAGES.browser_support
    }

    case OPENTOK_ERROR_NAMES.OT_CONNECTION_LIMIT_EXCEEDED:
    case OPENTOK_ERROR_NAMES.OT_STREAM_LIMIT_EXCEEDED: {
      return ERROR_MESSAGES.session_limit_reached
    }

    case OPENTOK_ERROR_NAMES.OT_CHROME_MICROPHONE_ACQUISITION_ERROR: {
      return ERROR_MESSAGES.chrome_error
    }

    case OPENTOK_ERROR_NAMES.OT_STREAM_DESTROYED: {
      return ERROR_MESSAGES.other_party_diconnected
    }

    default: {
      return ERROR_MESSAGES.try_again
    }
  }
}
