import React from 'react'

interface HideAfterProps {
  date?: string | Date
}

const HideAfter: React.FC<HideAfterProps> = ({ children, date }) => (
  <>{(!date || new Date(date) >= new Date()) && children}</>
)

export default HideAfter
