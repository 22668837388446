import React, { FC } from 'react'

import { Spinner } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { AppointmentInvitesFragment } from '~/generated'
import DataList from '~/ui/DataList'

import messages from './AppointmentInvite.messages'

export interface AppointmentInvitesProps {
  appointmentInvites: AppointmentInvitesFragment['appointmentInvites']
  loading?: boolean
}

const AppointmentInvites: FC<AppointmentInvitesProps> = ({
  appointmentInvites,
  loading,
}) => {
  const f = useMessages(messages)

  if (loading) {
    return <Spinner testid="appointment-invites-loading" size="small" />
  }

  return appointmentInvites?.length ? (
    <>
      {appointmentInvites.map(
        ({
          id,
          service_type: serviceType,
          notes_for_member: notesForMember,
          preferredMedium,
        }) => {
          const mediumValue = `medium_${preferredMedium}` as keyof typeof messages
          const serviceTypeValue = serviceType?.name ?? f('no_service_type')

          const data = [
            {
              key: f('medium_key'),
              value: f(mediumValue),
            },
            {
              key: f('type_key'),
              value: serviceTypeValue,
            },
            {
              key: f('clinician_key'),
              value: serviceType?.grouping,
            },
            {
              key: f('reason_key'),
              value: notesForMember,
            },
          ]

          return <DataList key={id} data={data} />
        }
      )}
    </>
  ) : null
}

export default AppointmentInvites
