import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.prescriptions.prescription_header'

export default defineMessages({
  age_group_title: {
    id: `${baseId}.age_group_title`,
    defaultMessage: 'Age group',
  },
  name_title: {
    id: `${baseId}.name_title`,
    defaultMessage: 'Name',
  },
  quantity_title: {
    id: `${baseId}.quantity_title`,
    defaultMessage: 'Qty',
  },
  duration_title: {
    id: `${baseId}.duration_title`,
    defaultMessage: 'Duration',
  },
  guidance_title: {
    id: `${baseId}.guidance_title`,
    defaultMessage: 'Guidance',
  },
})
