import { useConsultationStatusQuery } from '~/generated'

const useConsultationStatus = (consultationId: string): string | undefined => {
  const { data } = useConsultationStatusQuery({ variables: { consultationId } })
  if (data) {
    return data?.consultation?.status
  }

  return undefined
}

export default useConsultationStatus
