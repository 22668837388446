import { ApolloError } from '@apollo/client'
import React from 'react'
import { Route } from 'react-router-dom'

import { Spinner } from '@babylon/core-ui'

import { WORKFLOW_ACTIONS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useMessages } from '~/core/hooks'
import WorkflowDefinitionsModal from '~/features/workflows/WorkflowDefinitionsModal'
import {
  CpConsultationWorkflowsV2QueryResult,
  WorkflowDefinitionGroup,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section, withSectionErrorBoundary } from '~/ui/Section'
import SectionSeparator from '~/ui/SectionSeparator'

import UnknownWorkflows from '../UnknownWorkflows'
import WorkflowAction from './components/WorkflowAction'
import { filterWorkflowsV2 } from './utils'

import styles from './styles.module.scss'
import messages from './WorkflowActions.messages'

type Workflow = NonNullable<
  CpConsultationWorkflowsV2QueryResult['data']
>['consultationWorkflowsV2']['workflows']['results'][number]

export interface WorkflowActionsViewProps {
  workflows: Workflow[]
  workflowDefinitions: NonNullable<
    CpConsultationWorkflowsV2QueryResult['data']
  >['consultationWorkflowsV2']['workflowDefinitions']
  unknownWorkflows: Workflow[]
  workflowGroups: WorkflowDefinitionGroup[]
  loading: boolean
  refetch: () => void
  error?: ApolloError
  restrictFinalizedConsultationEditing: boolean
}

const WorkflowActionsView = ({
  workflows,
  workflowDefinitions,
  unknownWorkflows,
  workflowGroups,
  loading,
  refetch,
  error,
  restrictFinalizedConsultationEditing,
}: WorkflowActionsViewProps) => {
  const f = useMessages(messages)

  const canCreateWorkflow = (type: string) =>
    workflowDefinitions.some(({ group }) => group.type === type)

  if (error) {
    return (
      <ErrorPanel
        title={f('workflow_definitions_error_message')}
        error={error}
        retry={() => refetch()}
      />
    )
  }

  if (loading) {
    return (
      <Spinner
        className={styles.spinner}
        testid="workflow-actions-loading"
        centered
      />
    )
  }

  return (
    <>
      {workflowGroups &&
        workflowGroups.map(
          ({ type, label }: WorkflowDefinitionGroup, index) => {
            const groupWorkflows = filterWorkflowsV2(workflows, type)
            return (
              <div key={type}>
                {index !== 0 && <SectionSeparator />}
                <WorkflowAction
                  name={label}
                  type={type}
                  workflows={groupWorkflows}
                  canCreate={canCreateWorkflow(type)}
                  restrictFinalizedConsultationEditing={
                    restrictFinalizedConsultationEditing
                  }
                  dataTestId={type}
                />
              </div>
            )
          }
        )}
      {unknownWorkflows.length > 0 && (
        <>
          <SectionSeparator />
          <Section>
            <div className={styles.unknownWorkflows}>
              <UnknownWorkflows count={unknownWorkflows.length} />
            </div>
          </Section>
        </>
      )}
      {!restrictFinalizedConsultationEditing && (
        <Route
          path="/consultation/:consultationId/workflow/create/:type"
          component={WorkflowDefinitionsModal}
        />
      )}
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: WORKFLOW_ACTIONS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.workflow_actions_section_title,
})(WorkflowActionsView)
