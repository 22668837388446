/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import './ResponsiveTable.scss'
import ResponsiveTableCell from './ResponsiveTableCell'

const ResponsiveTableHeader = ({ columns }) => {
  return (
    <div
      className="core-responsive-table__header core-responsive-table__rowgroup"
      role="rowgroup"
      data-testid="core-responsive-table__header"
    >
      <div className="core-responsive-table__row" role="row">
        {columns.map((col, index) => (
          <ResponsiveTableCell
            key={index}
            content={col.label}
            role="columnheader"
          />
        ))}
      </div>
    </div>
  )
}

ResponsiveTableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      size: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
}

export default ResponsiveTableHeader
