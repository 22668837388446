import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React, { useState } from 'react'
import { FormattedDate } from 'react-intl'

import { Anchor, Grid, Tag, Text } from '@babylon/core-ui'
import { supportedTranslationLanguages, useIntl } from '@babylon/intl'

import { ReactComponent as IconPhone } from '~/assets/phone.svg'
import { ReactComponent as IconPhysical } from '~/assets/physical.svg'
import { ReactComponent as IconText } from '~/assets/text.svg'
import { ReactComponent as IconVideo } from '~/assets/video.svg'
import {
  ENABLE_STATE_REGULATIONS_MESSAGE,
  STATE_REGULATIONS_DOCUMENT_URL,
} from '~/constants'
import {
  COPY_CONSULTATION_ID_ACTION,
  COPY_PATIENT_ID_ACTION,
  COPY_PUBLIC_HEALTHCARE_ID_ACTION,
} from '~/constants/analytics'
import { useConsultation } from '~/core/config'
import { ConsultationProfilePluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useMount, useUnmount } from '~/core/hooks'
import PatientNameDisplay from '~/core/patientNameFormatter'
import { capitalize } from '~/core/string'
import { AppointmentMedium } from '~/generated'
import CopyButton from '~/ui/CopyButton'
import { useMobile } from '~/ui/Responsive'

import ConsultationStatus from './components/ConsultationStatus'
import { useGuestParticipant } from './hooks'
import {
  addSpacesToID,
  formatContactDetails,
  getConsultationStatus,
  trackCopyEvent,
} from './utils'

import messages from './ConsultationProfile.messages'
import styles from './styles.module.scss'

const ConsultationProfile: ConsultationProfilePluginInterface = ({
  consultationContext,
  eventBus,
  requestedTranslationEnabled,
  checkIn,
  usEhrDeepLink,
  patientRecordDeepLink,
}) => {
  const { formatMessage: fm, formatDate } = useIntl()
  const {
    athenaDeepLinkEnabled,
    patientRecordDeepLinkEnabled,
    multiwayCallingEnabled,
    memberProfileBupaTagEnabled,
  } = useFeatureFlags()

  const [displayCheckIn, setDisplayCheckIn] = useState(false)
  const isMobile = useMobile()
  const consultation = useConsultation(consultationContext)

  useMount(() => {
    eventBus.on('DISPLAY_PATIENT_CHECK_IN', () => {
      setDisplayCheckIn(true)
    })

    eventBus.on('HIDE_PATIENT_CHECK_IN', () => {
      setDisplayCheckIn(false)
    })
  })

  useUnmount(() => {
    eventBus.removeAllListeners('DISPLAY_PATIENT_CHECK_IN')
    eventBus.removeAllListeners('HIDE_PATIENT_CHECK_IN')
  })

  const {
    id: consultationId,
    scheduledTime,
    languageRequested,
    consumerNetworkName,
    consultationType,
    statusV2,
    consultant,
    patient: {
      id: patientId,
      public_healthcare_identifier: publicHealthcareId,
      first_name: firstName,
      last_name: lastName,
      date_of_birth: dob,
      age,
      minor,
      email,
      gender,
      contact_details: contactDetails,
      clinical_commissioning_group: ccg,
      profile_locked: profileLocked,
      profile_locks: profileLocks,
    },
  } = consultation

  const guest = useGuestParticipant(consultationId, multiwayCallingEnabled)

  const durationMinutes = consultation?.appointment?.durationMinutes

  const dateOfBirth =
    dob &&
    formatDate(dob, {
      format: 'short',
      timeZone: 'UTC',
    })
  const dateOfBirthMessage = dob
    ? fm(messages.dob_and_age, {
        dob: dateOfBirth,
        age,
      })
    : fm(messages.unknown_age)
  const patientContactDetails =
    contactDetails && formatContactDetails(contactDetails, email)
  const displayPatientLanguage =
    requestedTranslationEnabled && languageRequested

  const consultationStatus = getConsultationStatus(
    statusV2,
    scheduledTime,
    displayCheckIn
  )

  const showPatientRecordDeepLink =
    patientRecordDeepLinkEnabled && patientRecordDeepLink

  return (
    <div className={styles.sideCard} data-testid="consultation-profile">
      <Grid columns={1} rowGap={8}>
        <div className={styles.patientDetails}>
          <div className={styles.patientName}>
            <PatientNameDisplay
              firstName={firstName}
              lastName={lastName}
              testId="consultation-profile-patient-name"
              upperCaseLastName
            />
          </div>
          <div className={styles.consultationTags}>
            {minor && (
              <Tag
                color="white-on-orange"
                margin={false}
                className={styles.consultationTag}
              >
                {fm(messages.minor_tag_label)}
              </Tag>
            )}
            {guest.guestInCall && (
              <Tag
                color="clinical-orange"
                margin={false}
                className={styles.consultationTag}
              >
                {fm(messages.multiway_tag_label)}
              </Tag>
            )}
            {displayPatientLanguage && (
              <Tag
                color="clinical-orange"
                margin={false}
                className={styles.consultationTag}
              >
                {fm(messages.interpreter)}
              </Tag>
            )}
            {consumerNetworkName ? (
              <Tag
                color="clinical-green"
                margin={false}
                className={styles.consultationTag}
              >
                {consumerNetworkName}
              </Tag>
            ) : (
              <Tag
                color="clinical-red"
                margin={false}
                className={styles.consultationTag}
              >
                {fm(messages.error_consumer_network)}
              </Tag>
            )}
            {memberProfileBupaTagEnabled &&
              profileLocked &&
              profileLocks.map((lock) => {
                if (lock?.name && lock?.name in messages) {
                  return (
                    <Tag
                      key={`${lock.name}-tag`}
                      color="clinical-blue"
                      margin={false}
                      className={styles.consultationTag}
                    >
                      {fm(messages[lock.name as keyof typeof messages])}
                    </Tag>
                  )
                }
              })}
          </div>
          <Text data-testid="consultation-profile-patient-gender-dob">
            {gender ? capitalize(gender) : fm(messages.unknown_value)} |{' '}
            {dateOfBirthMessage}
          </Text>
          {patientContactDetails && (
            <Text data-testid="consultation-profile-patient-id-check">
              {patientContactDetails}
            </Text>
          )}
          {displayPatientLanguage && (
            <Text>
              {fm(messages.language_label)}:{' '}
              {languageRequested &&
                supportedTranslationLanguages[languageRequested]}
            </Text>
          )}
          <div className={styles.patientIds}>
            <div className={styles.patientId}>
              <Text
                color="grey-type"
                data-testid="consultation-profile-patient-id"
              >
                {fm(messages.patient_id_label)}: {addSpacesToID(patientId)}
              </Text>
              <CopyButton
                clipboardData={patientId}
                tooltipMessage={fm(messages.copy_patient_id)}
                className={styles.copyButton}
                onCopy={() => trackCopyEvent(COPY_PATIENT_ID_ACTION)}
                data-testid="patient-id-copy-button"
              />
            </div>
            {publicHealthcareId && (
              <div
                className={cn(styles.patientId && styles.publicHealthcareId)}
              >
                <Text
                  color="grey-type"
                  data-testid="consultation-profile-public-healthcare-id"
                >
                  {fm(messages.public_healthcare_id_label)}:{' '}
                  {publicHealthcareId}
                </Text>
                <CopyButton
                  clipboardData={publicHealthcareId}
                  tooltipMessage={fm(messages.copy_public_healthcare_id)}
                  className={cn(styles.copyButton && styles.publicIdCopyButton)}
                  onCopy={() =>
                    trackCopyEvent(COPY_PUBLIC_HEALTHCARE_ID_ACTION)
                  }
                  data-testid="public-healthcare-id-copy-button"
                />
              </div>
            )}
          </div>
        </div>
      </Grid>

      {ENABLE_STATE_REGULATIONS_MESSAGE && (
        <>
          <div className={styles.divider} />
          <Text>
            {fm(messages.state_regulations_message)}{' '}
            <Anchor
              href={STATE_REGULATIONS_DOCUMENT_URL}
              className={styles.stateRegulationsLink}
            >
              <Text>{fm(messages.state_regulations_link_label)}</Text>
            </Anchor>
          </Text>

          <div className={styles.divider} />
        </>
      )}
      {consultationStatus && (
        <div className={styles.status}>
          <ConsultationStatus
            status={consultationStatus}
            consultant={consultant}
            scheduledTime={scheduledTime}
          />
        </div>
      )}
      {checkIn}
      {((!consultationStatus && !displayCheckIn) ||
        (athenaDeepLinkEnabled && usEhrDeepLink && !isMobile)) && (
        <div
          className={styles.divider}
          data-testid="consultation-profile-divider"
        />
      )}
      {athenaDeepLinkEnabled && usEhrDeepLink}
      {athenaDeepLinkEnabled && !isMobile && usEhrDeepLink && (
        <div
          className={styles.divider}
          data-testid="consultation-profile-deep-link-divider"
        />
      )}
      {showPatientRecordDeepLink && (
        <>
          {patientRecordDeepLink}
          <div
            className={styles.divider}
            data-testid="consultation-patient-deep-link-divider"
          />
        </>
      )}
      <Grid columns={1} rowGap={8}>
        <div className={styles.consultationDetails}>
          <div
            className={styles.consultationInformation}
            data-testid="consultation-profile-scheduled-time"
            data-public
          >
            <Text
              color="light-grey-type"
              size="large"
              className={styles.consultationInfoIcon}
            >
              <i className="fas fa-calendar-alt" />
            </Text>
            <Text color="light-grey-type">
              <FormattedDate value={scheduledTime} format="shortWithTime" />
            </Text>
          </div>
          {guest.guestDetails && (
            <div
              className={styles.consultationInformation}
              data-testid="consultation-profile-guest-name"
            >
              <Text
                color="light-grey-type"
                size="large"
                className={styles.consultationInfoIcon}
              >
                <FontAwesomeIcon icon={faUser} />
              </Text>
              <Text color="light-grey-type">
                {fm(messages.consultation_caregiver_label, {
                  guestFirstName: guest.guestDetails.firstName,
                  guestLastName: guest.guestDetails.lastName,
                })}
              </Text>
            </div>
          )}
          <div
            className={styles.consultationInformation}
            data-testid="consultation-profile-type-duration"
            data-public
          >
            <div className={styles.consultationInfoIcon}>
              {consultationType === AppointmentMedium.Video && (
                <IconVideo
                  width="16"
                  height="100%"
                  viewBox="0 0 18 16"
                  className={styles.consultationTypeIcon}
                />
              )}
              {consultationType === AppointmentMedium.Voice && (
                <IconPhone
                  width="16"
                  height="100%"
                  viewBox="0 0 22 22"
                  className={styles.consultationTypeIcon}
                />
              )}
              {consultationType === AppointmentMedium.Physical && (
                <IconPhysical
                  width="16"
                  height="100%"
                  viewBox="0 0 22 24"
                  className={styles.consultationTypeIcon}
                />
              )}
              {consultationType === AppointmentMedium.Text && (
                <IconText
                  width="16"
                  height="100%"
                  viewBox="0 0 24 25"
                  className={styles.consultationTypeIcon}
                />
              )}
            </div>
            {consultationType === AppointmentMedium.Text && (
              <Text color="light-grey-type">
                {fm(messages.text_consultation)}
              </Text>
            )}
            {consultationType === AppointmentMedium.Physical && (
              <Text color="light-grey-type">
                {fm(messages.face_to_face_consultation)}
              </Text>
            )}
            {(consultationType === AppointmentMedium.Video ||
              consultationType === AppointmentMedium.Voice) && (
              <Text color="light-grey-type">
                {fm(messages.duration_minutes, { minutes: durationMinutes })}{' '}
                {fm(messages.consultation_type, { type: consultationType })}
              </Text>
            )}
          </div>
          <div
            className={styles.consultationInformation}
            data-testid="consultation-profile-consultation-id"
          >
            <Text color="light-grey-type">
              {fm(messages.consultation_id_label, {
                id: addSpacesToID(consultationId),
              })}
            </Text>
            <CopyButton
              clipboardData={consultationId}
              tooltipMessage={fm(messages.copy_consultation_id)}
              className={styles.copyButton}
              onCopy={() => trackCopyEvent(COPY_CONSULTATION_ID_ACTION)}
              data-testid="consultation-id-copy-button"
            />
          </div>
          {ccg?.id && (
            <div
              className={styles.consultationInformation}
              data-testid="consultation-profile-ccg"
              data-public
            >
              <Text color="light-grey-type">
                {fm(messages.ccg_label)}: {ccg?.name}
              </Text>
            </div>
          )}
        </div>
      </Grid>
    </div>
  )
}

export default ConsultationProfile
