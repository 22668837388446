import classnames from 'classnames'
import React, { Component } from 'react'

import styles from './styles.module.scss'

class Resizer extends Component {
  state = {
    isActive: false,
  }

  handleDocumentMouseUp = () => {
    const { onResizeEnd } = this.props

    this.setState({ isActive: false })
    document.removeEventListener('mouseup', this.handleDocumentMouseUp)
    document.removeEventListener('mousemove', this.handleDocumentMouseMove)
    document.body.classList.remove(styles.resizerBodyCursor)

    if (typeof onResizeEnd === 'function') {
      onResizeEnd()
    }
  }

  handleDocumentMouseMove = (event) => {
    const { startX, startY } = this.state
    const currentX = event.pageX
    const currentY = event.pageY

    const distanceX = currentX - startX
    const distanceY = currentY - startY

    this.distanceX = distanceX
    this.distanceY = distanceY
    const { onResize } = this.props

    onResize &&
      onResize({
        startX,
        startY,
        currentX,
        currentY,
        distanceX,
        distanceY,
      })
  }

  handleMouseDown = (event) => {
    const { onResizeStart } = this.props
    const startX = event.pageX
    const startY = event.pageY

    this.setState({
      isActive: true,
      startX,
      startY,
    })

    document.addEventListener('mousemove', this.handleDocumentMouseMove)
    document.addEventListener('mouseup', this.handleDocumentMouseUp)
    document.body.classList.add(styles.resizerBodyCursor)

    onResizeStart && onResizeStart({ startX, startY })
  }

  render() {
    const { isActive } = this.state
    const { children, disabled } = this.props

    return (
      <>
        <div className={styles.childContent}>{children}</div>
        {!disabled && (
          <div
            className={classnames(styles.resizer, isActive && styles.isActive)}
            onMouseDown={this.handleMouseDown}
            data-testid="call-player-resizer"
          />
        )}
      </>
    )
  }
}

export default Resizer
