import React from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { CarePlanDraftQueryResult, CarePlanQueryResult } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section } from '~/ui/Section'

import messages from '../CarePlanSection.messages'
import sectionViewMessages from './CarePlanSectionView.messages'

interface CarePlanPanelProps {
  carePlanQueryResult: CarePlanQueryResult
  carePlanDraftQueryResult: CarePlanDraftQueryResult
  children: React.ReactNode
}

const CarePlanSectionGuard = ({
  carePlanQueryResult,
  carePlanDraftQueryResult,
  children,
}: CarePlanPanelProps) => {
  const fm = useFormatMessage()

  if (carePlanQueryResult.loading || carePlanDraftQueryResult.loading) {
    return (
      <Section
        title={fm(sectionViewMessages.goals_and_actions_heading)}
        loading
      />
    )
  }

  if (carePlanQueryResult.error) {
    return (
      <Section>
        <ErrorPanel
          error={carePlanQueryResult.error}
          fill="container"
          center
          retry={() => carePlanQueryResult.refetch()}
          title={fm(messages.cant_load_care_plan)}
        />
      </Section>
    )
  }

  if (carePlanDraftQueryResult.error) {
    return (
      <Section>
        <ErrorPanel
          error={carePlanDraftQueryResult.error}
          fill="container"
          center
          retry={() => carePlanDraftQueryResult.refetch()}
          title={fm(messages.cant_load_care_plan_draft)}
        />
      </Section>
    )
  }

  if (
    carePlanQueryResult.data &&
    carePlanQueryResult.data?.carePlan.__typename ===
      'CarePlanAuthorizationError'
  ) {
    return (
      <Section>
        <Text size="medium" color="grey-type">
          {fm(messages.feature_not_available)}
        </Text>
      </Section>
    )
  }

  return <>{children}</>
}

export default CarePlanSectionGuard
