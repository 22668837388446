import { defineMessages } from 'react-intl'

const baseId = 'components.care_plan_modal_form'

export default defineMessages({
  enter_health_goal_placeholder: {
    id: `${baseId}.enter_health_goal_placeholder`,
    defaultMessage: 'Write down a health goal for your patient...',
  },
  enter_action_placeholder: {
    id: `${baseId}.enter_action_placeholder`,
    defaultMessage: 'Write down an action for this health goal...',
  },
  goal_heading: {
    id: `${baseId}.goal_heading`,
    defaultMessage: 'Health goal',
  },
  actions_heading: {
    id: `${baseId}.actions_heading`,
    defaultMessage: 'Actions for this goal',
  },
  error_saving: {
    id: `${baseId}.error_saving`,
    defaultMessage: 'Error saving',
  },
  saved: {
    id: `${baseId}.saved`,
    defaultMessage: 'Your draft was saved',
  },
  nothing_to_save: {
    id: `${baseId}.nothing_to_save`,
    defaultMessage: 'Nothing to save',
  },
  saving: {
    id: `${baseId}.saving`,
    defaultMessage: 'Saving...',
  },
  save: {
    id: `${baseId}.save`,
    defaultMessage: 'Save',
  },
})
