import React from 'react'

import { Label, Text } from '@babylon/core-ui'

import styles from '../styles.module.scss'

interface BmiSectionHeaderProps {
  label: string
  htmlFor: string
}

const BmiSectionHeader: React.FC<BmiSectionHeaderProps> = ({
  label,
  htmlFor,
  children,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <Label htmlFor={htmlFor} classname={styles.bmiLabels}>
        {label}
      </Label>
      {children && (
        <Text size="medium" className={styles.currentValue} tag="div">
          {children}
        </Text>
      )}
    </div>
  )
}

export default BmiSectionHeader
