import classnames from 'classnames/bind'
import React, { ReactNode } from 'react'

import { Heading, Text } from '@babylon/core-ui'

import Icon from '~/ui/Icon'
import Tooltip from '~/ui/Tooltip'

import styles from './styles.module.scss'

const PRIMARY_TYPE = 'primary'
const cx = classnames.bind(styles)

interface SectionTitleProps {
  icon?: string
  type?: string
  title: string
  subtitle?: ReactNode
  topRight?: ReactNode
  tooltip?: string
}

const SectionTitle = ({
  icon,
  type = PRIMARY_TYPE,
  title,
  subtitle,
  topRight,
  tooltip,
}: SectionTitleProps) => {
  return (
    <div className={cx('title', type)}>
      {icon && <Icon className={styles.titleIcon} name={icon} />}
      <span className={styles.titleText} data-public>
        <div>
          <Heading level="h2">{title}</Heading>
          {subtitle && (
            <Text className={styles.subtitle} tag="div">
              {subtitle}
            </Text>
          )}
        </div>
        {tooltip && (
          <Tooltip info={tooltip} overlayClassName={styles.tooltip} />
        )}
      </span>

      {topRight}
    </div>
  )
}

export default SectionTitle
