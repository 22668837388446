import React from 'react'

import { InsuranceClaimCheckPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import { useObservable } from '~/core/reactive-helpers'

import { ExtensionStatus } from '../CompleteConsultationModel'
import InsuranceClaimCheckView from './InsuranceClaimCheckView'

export const InsuranceClaimCheckViewModel: InsuranceClaimCheckPluginInterface = ({
  insuranceClaimCheckModel,
}) => {
  const [enableClaimExemptButton] = usePermissions(
    'show_claim_exempt_button_in_clinical_portal_v3'
  )

  const { insuranceClaim, billable } = useObservable(
    insuranceClaimCheckModel.state,
    {
      status: ExtensionStatus.Initializing,
      insuranceClaim: undefined,
      billable: false,
    }
  )

  if (!enableClaimExemptButton) {
    return null
  }

  return (
    <InsuranceClaimCheckView
      insuranceClaim={insuranceClaim}
      billable={billable}
      updateBillable={insuranceClaimCheckModel.updateBillable}
    />
  )
}
