import classnames from 'classnames/bind'
import React, { FC, ReactNode } from 'react'

import { Button, Grid, Heading, Text } from '@babylon/core-ui'

import { useGlobals } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import CopyButton from '~/ui/CopyButton'

import messages from './ErrorPanelView.messages'
import styles from './ErrorPanelView.module.scss'

export interface ErrorPanelViewProps {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  description?: string
  errorId?: string
  errorCode?: ReactNode | string
  retry?: () => Promise<any> | any
  retryLabel?: string
  loading?: boolean
  center?: boolean
  fill?: 'content' | 'container' | 'screen'
  className?: string
}

const cx = classnames.bind(styles)

const clipboardData = (errorId: string) => `
Error ID:
${errorId}
`

const ErrorPanelView: FC<ErrorPanelViewProps> = ({
  title,
  subtitle,
  description,
  errorId,
  errorCode,
  retry,
  retryLabel,
  loading,
  center = false,
  fill = 'content',
  className,
}) => {
  const format = useMessages(messages)
  const { support } = useGlobals()
  const itServiceDeskUrl = support?.itServiceDeskUrl

  const panelClasses = cx(
    'errorPanel',
    center && 'center',
    fill === 'container' && 'fillContainer',
    fill === 'screen' && 'fullScreen',
    className
  )

  const getRetryLabel = () => {
    if (retryLabel) {
      return retryLabel
    }

    if (retry === window.location.reload) {
      return format('reload_page')
    }

    return format('refresh_section')
  }

  return (
    <div className={panelClasses} data-testid="error-panel">
      <Grid gap={16} style={{ minWidth: 320, maxWidth: 576 }}>
        {title && (
          <>
            <Heading level="h2" color="error">
              {title}
            </Heading>
            {subtitle && <Text>{subtitle}</Text>}
          </>
        )}
        {(errorCode || errorId) && (
          <div className={styles.errorDetails}>
            <Grid gap={4}>
              <Heading level="h3">{format('error_id')}:</Heading>
              <Text size="large">{errorId}</Text>
              <Heading level="h3">{format('error_code')}:</Heading>
              <Text size="large">{errorCode}</Text>
            </Grid>
            <CopyButton
              className={styles.copyButton}
              clipboardData={clipboardData(errorId || 'unknown')}
              tooltipMessage={format('copy_button_tooltip_message')}
            />
          </div>
        )}
        {description && <Text>{description}</Text>}
        <div className={styles.errorControls}>
          {retry && (
            <Button inline onClick={() => retry()} loading={loading}>
              {getRetryLabel()}
            </Button>
          )}
          {itServiceDeskUrl && (
            <Button
              intent={retry ? 'secondary' : 'primary'}
              href={itServiceDeskUrl}
              target="_blank"
              inline
            >
              {format('notify_support')}
            </Button>
          )}
        </div>
      </Grid>
    </div>
  )
}

export default ErrorPanelView
