import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useFormatMessage } from '@babylon/intl'

import { Slideout } from '~/ui/Modal'

import CreatePatientAlert from './CreatePatientAlert/index'
import EditPatientAlert from './EditPatientAlert/index'

import messages from './PatientAlertsModal.messages'

interface PatientAlertsModalProps {
  patientId: string
}

const PatientAlertsModal = ({ patientId }: PatientAlertsModalProps) => {
  const fm = useFormatMessage()
  const history = useHistory()
  const { mode, consultationId } = useParams<{
    mode: string
    consultationId: string
  }>()
  const Content = mode === 'edit' ? EditPatientAlert : CreatePatientAlert

  const onClose = () => history.replace(`/consultation/${consultationId}`)

  return (
    <Slideout title={fm(messages.title)} onClose={onClose}>
      {({ closeModal }) => (
        <Content
          patientId={patientId}
          consultationId={consultationId}
          closeModal={closeModal}
        />
      )}
    </Slideout>
  )
}

export default PatientAlertsModal
