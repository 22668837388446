import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'

const useCallStatusToSentry = (
  isVideoCallActive: boolean,
  isVoiceCallActive: boolean
) => {
  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (isVideoCallActive || isVoiceCallActive) {
        scope.setTag('call_active', isVideoCallActive ? 'video' : 'voice')
      } else {
        scope.setTag('call_active', null)
      }
    })

    return () => {
      Sentry.configureScope((scope) => {
        scope.setTag('call_active', null)
      })
    }
  }, [isVideoCallActive, isVoiceCallActive])
}

export default useCallStatusToSentry
