import { defineMessages } from 'react-intl'

const baseId = 'header.support_options'

export default defineMessages({
  copy_session_id_button_text: {
    id: `${baseId}.copy_session_id_button_text_v2`,
    defaultMessage: 'Copy session ID',
  },
  copy_session_id_copied_text: {
    id: `${baseId}.copy_session_id_copied_text`,
    defaultMessage: 'Copied',
  },
})
