/**
 * Packages such as date-fns and i18n-iso-countries don't consistently add region to the path of locale data
 * E.G. the following don't exist:
 * - date-fns/locale/es-ES
 * - i18n-iso-countries/langs/en-US.json
 * whereas the base language does exist (date-fns/locale/es)
 * therefore we maintain the list below to ensure we can download data where available.
 * This also avoid adding hundreds of unused chunks to the bundle which we don't need for locales that aren't supported.
 */

import kebabCase from 'lodash/kebabCase'

import { logMessage } from '~/core/sentry'

import { languageFromLocale } from './locale'

type LocaleDataMap = Record<
  'dateFns' | 'i18nIsoCountries',
  Record<string, () => Promise<any>>
>

const localeDataMap: LocaleDataMap = {
  dateFns: {
    'en-GB': () =>
      import(/* webpackChunkName: "date-fns.en-GB" */ 'date-fns/locale/en-GB'),
    'en-CA': () =>
      import(/* webpackChunkName: "date-fns.en-CA" */ 'date-fns/locale/en-CA'),
    'en-US': () =>
      import(/* webpackChunkName: "date-fns.en-US" */ 'date-fns/locale/en-US'),
    'fr-CA': () =>
      import(/* webpackChunkName: "date-fns.fr-CA" */ 'date-fns/locale/fr-CA'),
    fr: () =>
      import(/* webpackChunkName: "date-fns.fr" */ 'date-fns/locale/fr'),
    es: () =>
      import(/* webpackChunkName: "date-fns.es" */ 'date-fns/locale/es'),
  },
  i18nIsoCountries: {
    'en-GB': () =>
      import(
        /* webpackChunkName: "i18n-iso-countries.en-GB" */ 'i18n-iso-countries/langs/en.json'
      ).then((module) => module.default),
    'en-CA': () =>
      import(
        /* webpackChunkName: "i18n-iso-countries.en-CA" */ 'i18n-iso-countries/langs/en.json'
      ).then((module) => module.default),
    'en-US': () =>
      import(
        /* webpackChunkName: "i18n-iso-countries.en-US" */ 'i18n-iso-countries/langs/en.json'
      ).then((module) => module.default),
    'fr-CA': () =>
      import(
        /* webpackChunkName: "i18n-iso-countries.fr-CA" */ 'i18n-iso-countries/langs/fr.json'
      ).then((module) => module.default),
    fr: () =>
      import(
        /* webpackChunkName: "i18n-iso-countries.fr" */ 'i18n-iso-countries/langs/fr.json'
      ).then((module) => module.default),
    es: () =>
      import(
        /* webpackChunkName: "i18n-iso-countries.es" */ 'i18n-iso-countries/langs/es.json'
      ).then((module) => module.default),
  },
}

const loadLocaleData = (locale: string, key: keyof typeof localeDataMap) => {
  const dataMap = localeDataMap[key]
  const exactMatch = dataMap[locale]

  if (exactMatch) {
    return exactMatch()
  }

  const langCode = languageFromLocale(locale)
  const partialMatch = dataMap[langCode]

  if (partialMatch) {
    logMessage(
      `${kebabCase(
        key
      )}: partial match found for "${locale}", using "${langCode}"`
    )
    return partialMatch()
  }

  logMessage(
    `${kebabCase(key)}: no match found for "${locale}", falling back to "en-GB"`
  )
  return dataMap['en-GB']()
}

const loadPackageData = async (locale: string) => {
  const packageDataKeys = Object.keys(localeDataMap) as Array<
    keyof typeof localeDataMap
  >
  const packageDataPromises = packageDataKeys.map((key) => {
    return loadLocaleData(locale, key)
  })
  const packageData = await Promise.all(packageDataPromises)

  return packageData.reduce((result, data, idx) => {
    return {
      ...result,
      [packageDataKeys[idx]]: data,
    }
  }, {})
}

export default loadPackageData
