import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.prescriptions.prescription_suggestions'

export default defineMessages({
  penicillin_allergy_warning_message: {
    id: `${baseId}.penicillin_allergy_warning_message`,
    defaultMessage: 'Contraindicated in case of penicillin allergy',
  },
  penicillin_allergy_safe_to_use_message: {
    id: `${baseId}.penicillin_allergy_safe_to_use_message`,
    defaultMessage: 'Safe to use in case of penicillin allergy',
  },
})
