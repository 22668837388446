import React from 'react'
import { AlertFilled } from '@babylon/icons'
import styles from './styles.module.scss'

export default () => (
  <div className={styles.emptyState}>
    <AlertFilled className={styles.errorState__icon} />
    <h1>Error loading tests&nbsp;</h1>
    <h3>Try refreshing the page or contact support if the problem persists.</h3>
  </div>
)
