import { FormikHelpers } from 'formik'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { Spinner, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { errorAlert } from '~/core/with-error-message'
import {
  usePatientAlertQuery,
  useRemovePatientAlertMutation,
  useUpdatePatientAlertMutation,
} from '~/generated'
import { Dialog } from '~/ui/Dialog'

import { removePatientAlertCache } from '../cache'
import PatientAlertForm from '../PatientAlertForm'
import { PatientAlertFormValues } from '../PatientAlertForm/PatientAlertForm'

import messages from '../PatientAlertsModal.messages'

interface EditPatientAlertProps {
  consultationId: string
  patientId: string
  closeModal: () => void
}

const EditPatientAlert = ({
  consultationId,
  patientId,
  closeModal,
}: EditPatientAlertProps) => {
  const fm = useFormatMessage()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const alertId = query.get('id')

  if (!alertId) {
    throw new Error('Alert id is missing from url')
  }

  const { loading, error, data } = usePatientAlertQuery({
    variables: { id: alertId, patientId },
  })

  const [updatePatientAlertMutation] = useUpdatePatientAlertMutation()
  const [removePatientAlertMutation] = useRemovePatientAlertMutation()

  const alert = data?.patient_alert

  if (loading) {
    return <Spinner centered />
  }

  if (error) {
    return <Text color="error">{error}</Text>
  }

  if (!alert) {
    return (
      <Text color="error">
        {fm(messages.no_alert_error, { patientId, alertId })}
      </Text>
    )
  }

  const initialValues: PatientAlertFormValues = {
    message: alert.message || '',
  }

  const updatePatientAlert = async (
    values: PatientAlertFormValues,
    form: FormikHelpers<PatientAlertFormValues>
  ) => {
    try {
      await updatePatientAlertMutation({
        variables: {
          id: alertId,
          patientId,
          message: values.message,
        },
      })

      closeModal()
    } catch (exception) {
      form.setSubmitting(false)
      // @ts-ignore
      errorAlert({ logMessage: exception })
    }
  }

  const removePatientAlert = async () => {
    Dialog.show({
      message: fm(messages.delete_title),
      cancelLabel: fm(messages.delete_cancel_button_label),
      okLabel: fm(messages.delete_remove_button_label),
      onOk: async () => {
        try {
          await removePatientAlertMutation({
            variables: {
              id: alertId,
              patientId,
            },
            update: removePatientAlertCache(consultationId),
          })

          closeModal()
        } catch (exception) {
          // @ts-ignore
          errorAlert({ logMessage: exception })
        }
      },
      onOkStyle: 'negative',
      centerContent: true,
    })
  }

  return (
    <PatientAlertForm
      mode="edit"
      error={error}
      initialValues={initialValues}
      onSubmit={updatePatientAlert}
      onRemove={removePatientAlert}
      onCancel={closeModal}
    />
  )
}

export default EditPatientAlert
