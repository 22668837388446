import { gql } from '@apollo/client'

export const SUGGESTED_CLINICAL_DOES_QUERY = gql`
  query SuggestedClinicalCodesQuery($query: String!, $region: String) {
    searchCodingDiagnosis(query: $query, limit: 50, region: $region) {
      id
      preferredLabel
      iri
      hasNotes
    }
  }
`
