import { History } from 'history'
import React from 'react'
import { match as Match } from 'react-router'

import { useFormatMessage } from '@babylon/intl'

import { ENABLE_BLOOD_PRESSURE_CHR } from '~/constants'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'

import BloodPressureForm from './BloodPressureForm'
import BmiMetricForm from './BmiMetricForm'
import PatientMetricsStateProvider, {
  usePatientMetricsState,
} from './PatientMetricsStateProvider'
import SmokingMetricForm from './SmokingMetricForm'
import useConfirmationDialog from './useConfirmationDialog'
import usePatientMetricsData from './usePatientMetricsData'

import messages from './PatientMetricsModal.messages'

interface PatientMetricsModalProps {
  loading: boolean
  error?: Error
  showContent: boolean
  refetch: () => void
  close: () => void
}

const PatientMetricsModal: React.FC<PatientMetricsModalProps> = ({
  error,
  loading,
  showContent,
  refetch,
  close,
}) => {
  const { values } = usePatientMetricsState()

  const fm = useFormatMessage()

  const [visible, onVisibleChange, confirmationDialog] = useConfirmationDialog({
    title: fm(messages.confirmation_dialog_title),
    okLabel: fm(messages.confirmation_dialog_okLabel),
    cancelLabel: fm(messages.confirmation_dialog_cancelLabel),
    showDialog: () => values && Object.values(values).find((value) => value),
  })

  return (
    <Slideout
      title={fm(messages.patient_metrics_title)}
      onClose={close}
      width="62.3%"
      position="right"
      noPadding="sides"
      loading={loading}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      {showContent && !error && (
        <form>
          <BmiMetricForm />
          <SmokingMetricForm />
          {ENABLE_BLOOD_PRESSURE_CHR && <BloodPressureForm />}
        </form>
      )}
      {error && (
        <ErrorPanel
          error={error}
          center
          fill="container"
          title={fm(messages.error_patient_metrics_modal_load_error)}
          retry={() => refetch()}
        />
      )}
      {confirmationDialog}
    </Slideout>
  )
}

interface StateProps {
  match: Match<any>
  history: History<any>
}

const State: React.FC<StateProps> = ({
  history,
  match: {
    params: { consultationId },
  },
}) => {
  const data = usePatientMetricsData(consultationId)
  const {
    loading,
    error,
    initialValues,
    displayValues,
    onSubmit,
    submitInfo,
    refetch,
  } = data

  const showContent = !!(initialValues && displayValues)

  const close = () => {
    history.replace(`/consultation/${consultationId}`)
  }

  return (
    <PatientMetricsStateProvider
      initialValues={initialValues || {}}
      displayValues={displayValues || {}}
      onSubmit={onSubmit}
      submitInfo={submitInfo}
    >
      <PatientMetricsModal
        loading={loading}
        error={error}
        close={close}
        showContent={showContent}
        refetch={refetch}
      />
    </PatientMetricsStateProvider>
  )
}

export default State
