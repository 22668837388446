import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { ConsultationNoteCode } from '~/generated'
import Label from '~/ui/Label'
import Pill from '~/ui/Pill'

import TextWithCodes from './TextWithCodes'

import messages from './CodedTextWithClinicalCodes.messages'
import styles from './styles.module.scss'

interface CodedTextWithClinicalCodesProps {
  title?: string
  codes: ConsultationNoteCode[]
  text: any
  className?: any
}
interface ClinicalCodesProps {
  codes: ConsultationNoteCode[]
  className?: string
  canSeeAISuggestions?: boolean
}

const CodedTextWithClinicalCodes = ({
  title,
  codes,
  text,
  className,
}: CodedTextWithClinicalCodesProps) => {
  if (!text) {
    return null
  }

  const codedText = <TextWithCodes text={text || ''} codes={codes || []} />

  return (
    <div className={className}>
      {title ? (
        <Label className={styles.topLabel} value={title}>
          {codedText}
        </Label>
      ) : (
        codedText
      )}
    </div>
  )
}

export const ClinicalCodes = ({
  codes,
  className,
  canSeeAISuggestions,
}: ClinicalCodesProps) => {
  const fm = useFormatMessage()

  if (!isFilledArray(codes)) {
    return null
  }

  return (
    <div className={className}>
      {isFilledArray(codes) ? (
        <Label
          value={
            canSeeAISuggestions
              ? fm(messages.manual_clinical_codes_label)
              : fm(messages.clinical_codes_label)
          }
        >
          <div className={styles.container}>
            {codes.map(({ term, offset, length }) => (
              <Pill
                key={`${term}-${offset}-${length}`}
                value={term}
                type="code"
              />
            ))}
          </div>
        </Label>
      ) : null}
    </div>
  )
}

export default CodedTextWithClinicalCodes
