import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history.timeline_consultation_event'

export default defineMessages({
  consultation_event_title: {
    id: `${baseId}.consultation_event_title`,
    defaultMessage: 'Consultation',
  },
  incomplete_consultation_event_title: {
    id: `${baseId}.incomplete_consultation_event_title_v2`,
    defaultMessage: 'Incomplete consultation',
  },
  this_consultation_event_title: {
    id: `${baseId}.this_consultation_event_title_v2`,
    defaultMessage: 'This consultation',
  },
  error_loading_consultation_details_message: {
    id: `${baseId}.error_loading_consultation_details_message`,
    defaultMessage: 'Error loading consultation details',
  },
  incomplete_consultation_disclaimer: {
    id: `${baseId}.incomplete_consultation_disclaimer`,
    defaultMessage:
      'This consultation has not been marked as complete by the clinician. Details like notes and actions might still change.',
  },
  diagnosis_title: {
    id: `${baseId}.diagnosis_title`,
    defaultMessage: 'Diagnosis: ',
  },
})
