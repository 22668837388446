import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog, DialogProps } from '~/ui/Modal'

import messages from './TestRemoveDialog.messages'

interface TestsSectionProps
  extends Pick<DialogProps, 'okLoading' | 'onOk' | 'onClose'> {}

const TestRemoveDialog = ({ okLoading, onOk, onClose }: TestsSectionProps) => {
  const format = useMessages(messages)

  return (
    <Dialog
      title={format('title')}
      ok
      okLabel={format('remove_button_label')}
      okLoading={okLoading}
      cancel
      cancelLabel={format('cancel_button_label')}
      type="error"
      onOk={onOk}
      onClose={onClose}
    >
      <Text>{format('message')}</Text>
    </Dialog>
  )
}

export default TestRemoveDialog
