import React from 'react'

import { ResponsiveTable } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from '~/features/prescriptions/PrescriptionsTable/PrescriptionsTable.messages'
import { Prescription } from '~/generated'

type SimplePrescriptionsTableProps = {
  prescriptions: Prescription[]
}

const SimplePrescriptionsTable = ({
  prescriptions,
}: SimplePrescriptionsTableProps) => {
  const fm = useFormatMessage()

  const activePrescriptions = prescriptions
    .filter((v) => v.prescriptionState !== 'VOIDED')
    .flatMap(({ drugs }) => drugs)

  const columns = [
    {
      label: fm(messages.drug_name),
      accessor: 'drugName',
      size: '2fr',
    },
    {
      label: fm(messages.drug_directions),
      accessor: 'directions',
      size: '2fr',
    },
    {
      label: fm(messages.drug_quantity),
      accessor: 'quantity',
      size: '1fr',
    },
  ]

  return (
    <ResponsiveTable
      label={fm(messages.table_label)}
      collapseBreakpoint={200}
      fullWidth
      columns={columns}
      data={activePrescriptions}
      border
      striped
    />
  )
}

export default SimplePrescriptionsTable
