import React from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ACTIONS_ATTACHMENTS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { isFilledArray } from '~/core'
import FileDownload from '~/ui/FileDownload'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import ConsultationAttachments from './ConsultationAttachments'
import { Attachment } from './ConsultationAttachments/ConsultationAttachments'

import messages from './Attachments.messages'

interface AttachmentsViewProps {
  attachments: Attachment[]
}

const AttachmentsView = ({ attachments }: AttachmentsViewProps) => {
  const fm = useFormatMessage()

  return (
    <Section title={fm(messages.title)} type="secondary">
      {isFilledArray(attachments) ? (
        <ConsultationAttachments
          attachments={attachments}
          renderPdfComponent={({ url, fileName }) => (
            <FileDownload icon="far fa-file-pdf" url={url} label={fileName} />
          )}
        />
      ) : (
        <Text color="grey-type" size="medium">
          {fm(messages.no_attachments_avaliable_message)}
        </Text>
      )}
    </Section>
  )
}

export default withSectionErrorBoundary({
  gaAction: ACTIONS_ATTACHMENTS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(AttachmentsView)
