import { DOWNLOAD_URL } from '~/constants'

const S3Rx = /\.amazonaws\./
// TODO:
// Remove `DOWNLOAD_URL` and return the original URL after Core Ruby turns S3
// storage on in prod
export const prepareImageUrl = (value: string) => {
  if (value.startsWith('static/media/')) {
    // storybook support
    return { url: value }
  }

  const url = S3Rx.test(value) ? value : `${DOWNLOAD_URL}/${value}`

  return { url }
}
