import React from 'react'

import { generateIndex } from '~/core'
import Icon from '~/ui/Icon'

import Popover from './Popover'

import styles from './styles.module.scss'

const PopoverList = ({ children, content, buttons, className, ...popover }) => (
  <Popover
    {...popover}
    className={styles.popoverList}
    content={() => (
      <div className={className}>
        <ul className={styles.list}>
          {buttons.map(({ className, icon, label, action }) => (
            <li key={generateIndex()} className={className}>
              <button onClick={action}>
                {icon && <Icon fixed name={icon} style={{ marginRight: 10 }} />}
                {label && <span>{label}</span>}
              </button>
            </li>
          ))}
        </ul>
        {content}
      </div>
    )}
  >
    {children}
  </Popover>
)

PopoverList.defaultProps = {
  buttons: [],
}

export default PopoverList
