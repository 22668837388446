import cn from 'classnames'
import range from 'lodash/range'
import React, { useEffect, useRef, useState } from 'react'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  ONB_NEXT_ACTION,
  ONB_PREV_ACTION,
  ONB_RESTART_ACTION,
} from '~/constants/analytics'
import { generateIndex } from '~/core'
import analytics from '~/core/analytics'

import messages from './Onboarding.messages'
import styles from './styles.module.scss'

interface OnboardingProps {
  name: string
  autoPlayInterval?: number
  className?: string
}

const Onboarding: React.FC<OnboardingProps> = ({
  name,
  children,
  autoPlayInterval,
  className,
}) => {
  const fm = useFormatMessage()

  const trackEvent = analytics.trackEventFactory({
    category: name,
  })

  const [currentSlideIdx, setCurrentSlideIdx] = useState(0)

  const savedCurrentSlideIdx = useRef(currentSlideIdx)
  savedCurrentSlideIdx.current = currentSlideIdx

  // @ts-expect-error
  const slides: Array<React.ReactNode> = children
  const multiSlide = Array.isArray(children)
  const currentSlide = multiSlide ? slides[currentSlideIdx] : children
  const isLastSlide = multiSlide && currentSlideIdx === slides.length - 1

  const handleNextClick = () => {
    trackEvent({ action: ONB_NEXT_ACTION, label: new Date().toISOString() })
    setCurrentSlideIdx(currentSlideIdx + 1)
  }

  const handleRestartClick = () => {
    trackEvent({ action: ONB_RESTART_ACTION, label: new Date().toISOString() })
    setCurrentSlideIdx(0)
  }

  const handlePreviousClick = () => {
    trackEvent({ action: ONB_PREV_ACTION, label: new Date().toISOString() })
    setCurrentSlideIdx(currentSlideIdx - 1)
  }

  useEffect(() => {
    let timeout: number

    const startTimeout = () => {
      timeout = setTimeout(() => {
        if (savedCurrentSlideIdx.current < slides.length - 1) {
          setCurrentSlideIdx(savedCurrentSlideIdx.current + 1)
        }
        startTimeout()
      }, autoPlayInterval)
    }

    if (autoPlayInterval) {
      startTimeout()
    }

    return () => clearTimeout(timeout)
  }, [autoPlayInterval, slides, savedCurrentSlideIdx])

  return (
    <div className={cn(styles.wrapper, className)}>
      <div>{currentSlide}</div>
      {multiSlide && (
        <div className={styles.controls}>
          <div data-testid="slide-indicator" className={styles.slideIndicator}>
            {range(slides.length).map((idx) => (
              <div
                className={cn({
                  [styles.active]: idx === currentSlideIdx,
                })}
                key={generateIndex()}
              />
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              intent="secondary"
              disabled={currentSlideIdx === 0}
              onClick={handlePreviousClick}
            >
              {fm(messages.previous)}
            </Button>
            {isLastSlide ? (
              <Button intent="secondary" onClick={handleRestartClick}>
                {fm(messages.restart)}
              </Button>
            ) : (
              <Button intent="secondary" onClick={handleNextClick}>
                {fm(messages.next)}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Onboarding
