import classnames from 'classnames'
import React from 'react'

import { Heading } from '@babylon/core-ui'

import { generateIndex, isFilledArray } from '~/core'
import Message from '~/ui/Message'

import styles from './styles.module.scss'

const ModalContent = ({
  className,
  title,
  rightText,
  children,
  warnings = [],
}) => (
  <div className={classnames(className)}>
    <div className={styles.modalHeader}>
      {title && (
        <Heading margin level="h2">
          {title}
        </Heading>
      )}
      {rightText && <div className={styles.rightText}>{rightText}</div>}
    </div>
    <div>{children}</div>
    <div>
      {isFilledArray(warnings) &&
        warnings.map((warning) => (
          <Message
            type={warning.type}
            title={warning.title}
            key={generateIndex()}
          >
            {warning.message}
          </Message>
        ))}
    </div>
  </div>
)

ModalContent.defaultProps = {
  children: undefined,
  className: '',
  title: '',
  rightText: '',
}

export default ModalContent
