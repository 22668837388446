import { gql } from '@apollo/client'

const FeedbackQuestionFragment = gql`
  fragment FeedbackQuestionFragment on MultimediaFeedbackQuestion {
    id
    position
    question_type
    question
    suggested_answers {
      id
      answer
    }
  }
`

export const FEEDBACK_QUESTION_QUERY = gql`
  query CpMultimediaFeedbackQuestion(
    $consultationId: ID!
    $client_type: MultimediaFeedbackClientType = clinician
  ) {
    multimediaFeedbackQuestion(
      consultation_id: $consultationId
      client_type: $client_type
    ) {
      ...FeedbackQuestionFragment
    }
  }
  ${FeedbackQuestionFragment}
`

export const SEND_FEEDBACK_MUTATION = gql`
  mutation CpSubmitMultimediaFeedback($input: MultimediaFeedbackInput!) {
    submitMultimediaFeedback(input: $input) {
      next_question {
        ...FeedbackQuestionFragment
      }
    }
  }
  ${FeedbackQuestionFragment}
`
