import React from 'react'
import cx from 'classnames'

import Anchor from '../Anchor'
import Chevron from '../../../icons/Chevron'
import { useAccordion } from './useAccordion'

import './ExpandableAnchor.scss'

interface ExpandableAnchorProps {
  anchorText: React.ReactNode
  collapseText?: React.ReactNode
  children: React.ReactNode
  gap?: number
  leftPadChildren?: boolean
  onClick?: () => void
  startOpen?: boolean
}

const ExpandableAnchor = ({
  anchorText,
  collapseText,
  children,
  gap = 8,
  leftPadChildren = false,
  onClick,
  startOpen = false,
}: ExpandableAnchorProps) => {
  const { wrapper, handleClick, accordionHeight } = useAccordion(
    startOpen,
    onClick
  )
  const isExpanded = accordionHeight !== 0

  return (
    <div
      className={cx(
        'core-expandable-anchor',
        'core-expandable-anchor__accordion'
      )}
    >
      <Anchor component="div" onClick={handleClick}>
        <Chevron
          direction="down"
          className={cx(
            'core-expandable-anchor__icon',
            isExpanded && 'core-expandable-anchor__icon--expanded'
          )}
        />
        {isExpanded && collapseText ? collapseText : anchorText}
      </Anchor>
      <div
        className={cx(
          'core-expandable-anchor__expanded-section',
          leftPadChildren &&
            'core-expandable-anchor__expanded-section--left-pad'
        )}
        style={{ maxHeight: accordionHeight }}
      >
        <div ref={wrapper}>
          <div style={{ paddingTop: gap }}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ExpandableAnchor
