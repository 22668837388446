export const rootElement = (classname) => {
  const { children } = document.body

  for (let i = 0; i < children.length; i++) {
    const child = children[i]

    if (child.classList.contains(classname)) {
      return child
    }
  }

  const element = document.createElement('div')
  element.classList.add(classname)

  document.body.appendChild(element)

  return element
}

export const last = (array) => {
  return array.length > 0 ? array[array.length - 1] : null
}
