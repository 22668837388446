import { useEffect, useRef, useState } from 'react'

import {
  NOTE_ASSISTANT_CATEGORY,
  NOTE_ASSISTANT_ERROR_ACTION,
  NOTE_ASSISTANT_ERROR_LABEL,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useFeatureFlags, useGlobals } from '~/core/core-modules'
import { EventBusType } from '~/core/event-bus'
import { useMessages, useMount, useUnmount } from '~/core/hooks'

import {
  DEFAULT_FETCH_NOTES_ATTEMPTS,
  DEFAULT_FETCH_NOTES_RETRY_DELAY,
  DEFAULT_STOP_POLLING_DELAY,
  DEFAULT_SUMMARY_POLL_INTERVAL,
} from './constants'

import messages from './NoteAssistantSummary.messages'

interface useGeneratedNotePollingProps {
  eventBus: EventBusType
  startNotesPolling: (pollInterval: number) => void
  stopNotesPolling: () => void
  refetch: () => void
  pollingEnabled: boolean
  notesErrorCount: number
}

export const useGeneratedNotePolling = ({
  eventBus,
  startNotesPolling,
  stopNotesPolling,
  refetch,
  pollingEnabled,
  notesErrorCount,
}: useGeneratedNotePollingProps) => {
  const fm = useMessages(messages)
  const [warning, setWarning] = useState<string>()
  const [callRecordingError, setCallRecordingError] = useState<boolean>()
  const [notesError, setNotesError] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)
  const [
    speechProcessingEnabled,
    setSpeechProcessingEnabled,
  ] = useState<boolean>()
  const pollingTimeout = useRef<NodeJS.Timeout | null>(null)
  const {
    stopPollingDelay = DEFAULT_STOP_POLLING_DELAY,
    fetchNotesAttempts = DEFAULT_FETCH_NOTES_ATTEMPTS,
    fetchNotesRetryDelay = DEFAULT_FETCH_NOTES_RETRY_DELAY,
    summaryPollInterval = DEFAULT_SUMMARY_POLL_INTERVAL,
  } = useGlobals().noteAssistant || {}
  const { noteAssistantAudioCallsEnabled } = useFeatureFlags()

  const clearPollingTimeout = () => {
    if (pollingTimeout.current) {
      clearTimeout(pollingTimeout.current)
      pollingTimeout.current = null
    }
  }

  const handleRecordingStarted = () => {
    setCallRecordingError(undefined)
    setIsActive(true)
    clearPollingTimeout()

    if (pollingEnabled) {
      startNotesPolling(summaryPollInterval)
    }
  }

  const handleRecordingStopped = () => {
    setIsActive(false)
    pollingTimeout.current = setTimeout(stopNotesPolling, stopPollingDelay)
  }

  const handleAudioCallStarted = () => {
    setWarning(fm('not_supported_warning'))
  }

  const handleAudioCallEnded = () => {
    setWarning(undefined)
  }

  const handleCallRecordingError = () => {
    setCallRecordingError(true)
    stopNotesPolling()
  }

  const handleSpeechProcessingEnabled = () => {
    setSpeechProcessingEnabled(true)
  }

  const handleSpeechProcessingDisabled = () => {
    setSpeechProcessingEnabled(false)
  }

  useMount(() => {
    eventBus.on('CALL_RECORDING_STARTED', handleRecordingStarted)
    eventBus.on('CALL_RECORDING_STOPPED', handleRecordingStopped)
    eventBus.on('CALL_RECORDING_ERROR', handleCallRecordingError)
    eventBus.on('SPEECH_PROCESSING_ENABLED', handleSpeechProcessingEnabled)
    eventBus.on('SPEECH_PROCESSING_DISABLED', handleSpeechProcessingDisabled)

    if (!noteAssistantAudioCallsEnabled) {
      eventBus.on('AUDIO_CALL_STARTED', handleAudioCallStarted)
      eventBus.on('AUDIO_CALL_ENDED', handleAudioCallEnded)
    }
  })

  useUnmount(() => {
    stopNotesPolling()
    clearPollingTimeout()

    eventBus.removeListener('CALL_RECORDING_STARTED', handleRecordingStarted)
    eventBus.removeListener('CALL_RECORDING_STOPPED', handleRecordingStopped)
    eventBus.removeListener('CALL_RECORDING_ERROR', handleCallRecordingError)
    eventBus.removeListener(
      'SPEECH_PROCESSING_ENABLED',
      handleSpeechProcessingEnabled
    )
    eventBus.removeListener(
      'SPEECH_PROCESSING_DISABLED',
      handleSpeechProcessingDisabled
    )

    if (!noteAssistantAudioCallsEnabled) {
      eventBus.removeListener('AUDIO_CALL_STARTED', handleAudioCallStarted)
      eventBus.removeListener('AUDIO_CALL_ENDED', handleAudioCallEnded)
    }
  })

  useEffect(() => {
    if (notesErrorCount === 0) {
      setNotesError(false)
    } else if (notesErrorCount < fetchNotesAttempts) {
      setTimeout(() => {
        refetch()
      }, fetchNotesRetryDelay)
    } else {
      setNotesError(true)
      setIsActive(false)
      analytics.trackEvent({
        category: NOTE_ASSISTANT_CATEGORY,
        action: NOTE_ASSISTANT_ERROR_ACTION,
        label: NOTE_ASSISTANT_ERROR_LABEL,
      })
      stopNotesPolling()
    }
  }, [
    eventBus,
    fetchNotesAttempts,
    fetchNotesRetryDelay,
    notesErrorCount,
    refetch,
    stopNotesPolling,
  ])

  return {
    warning,
    callRecordingError,
    notesError,
    isActive,
    setIsActive,
    speechProcessingEnabled,
  }
}
