import { Consultation as SchemaConsultation } from '~/generated'

export enum ConsultationStatus {
  Confirmed = 'confirmed',
  Paid = 'paid',
  Completed = 'completed',
  TimedOut = 'timed_out',
  NoShow = 'no_show',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

export type Consultation = Pick<
  SchemaConsultation,
  | 'id'
  | 'consultationId'
  | 'consultationType'
  | 'scheduledTime'
  | 'languageRequested'
  | 'recordingConsent'
> & {
  status: ConsultationStatus
}
