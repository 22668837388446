export * from './array'
export * from './string'
export * from './tsUtils'

/**
 * If the value is a function it calls it with the args and returns the
 * result. It returns the value otherwise.
 *
 * @param {any} value
 * @param ...args
 */
export const result = (value: any, ...args: any[]) =>
  typeof value === 'function' ? value(...args) : value

/**
 * Generated numeric ID
 */
export const generateIndex = ((index) => () => index++)(0)

export const reduceArrayOfObjects = (
  array: any,
  keyName: string,
  valueName: string
) => {
  if (!Array.isArray(array)) {
    return {}
  }

  return array.reduce((object, value) => {
    return {
      ...object,
      [value[keyName]]: value[valueName],
    }
  }, {})
}
