import { defineMessages } from 'react-intl'

const baseId = 'consultation_lite.action_buttons'

export default defineMessages({
  review_consultation_button_label: {
    id: `${baseId}.review_consultation_button_label_v2`,
    defaultMessage: 'Review consultation',
  },
  exit_review_button_label: {
    id: `${baseId}.exit_review_button_label_v2`,
    defaultMessage: 'Exit review',
  },
  complete_consultation_button_label: {
    id: `${baseId}.complete_consultation_button_label_v2`,
    defaultMessage: 'Complete consultation',
  },
  update_consultation_button_label: {
    id: `${baseId}.update_consultation_button_label_v2`,
    defaultMessage: 'Update consultation',
  },
  edited_finalized_consultation_warning: {
    id: `${baseId}.edited_finalized_consultation_warning`,
    defaultMessage: 'Update to save all changes',
  },
})
