import { defineMessages } from 'react-intl'

const baseId = 'consultation.recording_consent'

export default defineMessages({
  consent_revoked: {
    id: `${baseId}.consent_revoked`,
    defaultMessage: 'Recording consent revoked',
  },
  revoke_consent: {
    id: `${baseId}.revoke_consent`,
    defaultMessage: 'Revoke recording consent',
  },
  revoke_recording_title: {
    id: `${baseId}.revoke_recording_title`,
    defaultMessage: 'Operation failed',
  },
  revoke_recording_message: {
    id: `${baseId}.revoke_recording_message`,
    defaultMessage: 'Failed to revoke recording consent',
  },
})
