// Temporary workaround for workflow subtypes
// Keep in sync with graphql-middleware/src/data/workflow-types
// TODO: Remove after migrating to V2 Worklfows API

const investigationSubTypes = {
  xray: {
    value: 'xray',
    label: 'X-ray',
  },
  ultrasound: {
    value: 'ultrasound',
    label: 'Ultrasound',
  },
  sampleRequired: {
    value: 'sampleRequired',
    label: 'Other Pathology (Home Testing)',
  },
  'OtherPathology - HomeTesting': {
    value: 'sampleRequired',
    label: 'Other Pathology (Home Testing)',
  },
  investigationAppointment: {
    value: 'investigationAppointment',
    label: 'Other Pathology (Appointment)',
  },
  'OtherPathology - Appointment': {
    value: 'investigationAppointment',
    label: 'Other Pathology (Appointment)',
  },
}

const letterSubTypes = {
  MED3: {
    value: 'MED3',
  },
  'Fit to fly': {
    value: 'Fit to fly',
  },
  'Letter to university': {
    value: 'Letter to university',
  },
  'Letter to specialist': {
    value: 'Letter to specialist',
  },
  Other: {
    value: 'Other',
  },
}

const otherSubTypes = {
  'Create or modify a prescription': {
    value: 'Create or modify a prescription',
  },
  'Chase referral result': {
    value: 'Chase referral result',
  },
  'Chase referral status': {
    value: 'Chase referral status',
  },
  'Chase investigation result': {
    value: 'Chase investigation result',
  },
  'Upload data to SystmOne': {
    value: 'Upload data to SystmOne',
  },
  Other: {
    value: 'Other',
  },
}

const referralSubTypes = {
  '2WW': {
    value: '2WW',
    subSpecialties: [
      'Bone',
      'Brain',
      'Breast',
      'Cancer of unknown primary',
      'Children and young people',
      'Gynaecology',
      'Haematology',
      'Head and neck',
      'Lower GI',
      'Lung',
      'Sarcoma',
      'Skin',
      'Upper GI',
      'Urology',
    ],
  },
  Allergy: {
    value: 'Allergy',
    subSpecialties: ['Allergy'],
  },
  Antenatal: {
    value: 'Antenatal',
    subSpecialties: ['Birmingham - Antenatal', 'London - Antenatal'],
  },
  Cardiology: {
    value: 'Cardiology',
    subSpecialties: [
      'Arrhythmia',
      'Cardiology genetics',
      'Congenital Heart Disease',
      'Heart failure',
      'Hypertension',
      'Ischaemic Heart Disease',
      'Lipid Management',
      'Not Otherwise specified',
      'Rapid Access Chest Pain inc Exercise ECG',
      'Valve Disorders',
    ],
  },
  'Children & Adolescent Services': {
    value: 'Children & Adolescent Services',
    subSpecialties: [
      'Allergy',
      'Audiology',
      'Cardiology',
      'Community Paediatric',
      'Dentistry / Orthodontics',
      'Dermatology',
      'Developmental / Learning Disabilities',
      'Diabetes',
      'Endocrinology',
      'ENT',
      'Gastroenterology',
      'Gynaecology',
      'Gynaecology - Pregnancy Advice',
      'Haematology',
      'Immunology',
      'Metabolic Disorders',
      'Nephrology',
      'Neurology',
      'Oncology (Established Diagnosis)',
      'Ophthal - not otherwise specified',
      'Ophthal - Orthoptics',
      'Ophthal - Strabismus / Ocular Motility',
      'Oral and Maxillofacial Surgery',
      'Orthopaedics',
      'Other medical',
      'Plastic Surgery',
      'Respiratory',
      'Rheumatology',
      'Surgery - not otherwise specified',
      'Urology',
    ],
  },
  'Complementary Medicine': {
    value: 'Complementary Medicine',
    subSpecialties: [
      'Allergy',
      'Dermatology',
      'Dietetics',
      'General Medicine',
      'Musculoskeletal / Rheumatology',
      'Oncology (established Diagnosis)',
      'Podiatry',
      'Psychological Medicine',
      'Womennulls Health',
    ],
  },
  'Dentistry & orthodontics': {
    value: 'Dentistry & orthodontics',
    subSpecialties: [
      'Endodontics',
      'Not Otherwise Specified',
      'Orthodontics',
      'Periodontics',
      'Prosthodontics',
      'Surgical Dentistry',
    ],
  },
  Dermatology: {
    value: 'Dermatology',
    subSpecialties: [
      'Acne',
      'Basal Cell Carcinoma',
      'Connective Tissue Disease',
      'Cosmetic Camouflage',
      'Eczema and Dermatitis',
      'Hair',
      'Laser Clinics',
      'Leg Ulcer',
      'Make Genital Skin Disorders',
      'Nails',
      'Not otherwise specified',
      'Oncology  (established Diagnosis)',
      'Patch Testing for Contact Dermatitis',
      'Psoriasis',
      'Skin Surgery for Benign Skin Lesions',
      'Vulval Skin Disorders',
    ],
  },
  'Diabetic Medicine': {
    value: 'Diabetic Medicine',
    subSpecialties: [
      'Erectile Dysfunction',
      'General Diabetic Management',
      'Podiatry and foot',
      'Pregnancy and Maternal',
      'Renal Diabetes',
      'Type 1 Diabetes',
    ],
  },
  'Diagnostic Endoscopy': {
    value: 'Diagnostic Endoscopy',
    subSpecialties: [
      'Colonic Imaging Assessment / Advice',
      'Flexible Sigmoidoscopy',
      'Gastroscopy',
    ],
  },
  'Diagnostic Imaging': {
    value: 'Diagnostic Imaging',
    subSpecialties: [
      'Bone Density Scan (DEXA)',
      'CT',
      'Fluoroscopy and Barium Studies',
      'Intravenous Urogram (IVU)',
      'MRI',
      'Nuclear Medicine',
      'Ultrasound',
      'X-ray Plain film',
    ],
  },
  'Diagnostic physiological measurement': {
    value: 'Diagnostic physiological measurement',
    subSpecialties: [
      'Audiology - Hearing Assess / Reassess',
      'Cardiac Physiology - BP Monitoring',
      'Cardiac Physiology - ECG',
      'Cardiac Physiology - Echocardiogram',
      'GI Physiology - Urea Breath Test',
      'Neurophysiology - EEG (Routine)',
      'Neurophysiology - nerve Conduction Studies',
      'Respiratory - Blood Gas Analysis',
      'Respiratory - Full Lung Function',
      'Respiratory - Oxygen Assessment',
      'Respiratory - Sleep Apnoea Screening',
      'Urodynamics - Free Flow / Residual Urine',
      'Vascular - ABPI Measurement',
      'Vascular - Aortic Aneurysm Assessment',
    ],
  },
  Dietetics: {
    value: 'Dietetics',
    subSpecialties: [
      'Cardiovascular Disease Risk Management',
      'Diabetes',
      'Eating Disorders - see Mental Health',
      'Food Allergy and Intolerance',
      'Gastroenterology',
      'Not otherwise specified',
      'Undernutrition',
      'Weight Management',
    ],
  },
  'Ear, nose & throat': {
    value: 'Ear, nose & throat',
    subSpecialties: [
      'Balance / Dizziness',
      'Ear',
      'Facial Plastic and Skin Lesions',
      'Hearing Tests / Aids - see Diag Phys Meas',
      'Neck Lump / Thyroid',
      'Nose / Sinus',
      'Not Otherwise Specified',
      'Oncology (established diagnosis )',
      'Salivary Gland',
      'Sleep Apnoea - see sleep Medicine',
      'Snoring (not sleep Apnoea)',
      'Throat (incl voice / swallowing)',
      'Tinnitus',
    ],
  },
  'Endocrine and metabolic medicine': {
    value: 'Endocrine and metabolic medicine',
    subSpecialties: [
      'Adrenal Disorders',
      'Endocrine Pregnancy',
      'Gynaecological Endocrinology',
      'Lipid Disorders',
      'Metabolic Bone Disorders',
      'Not Otherwise specified',
      'Oncology (established Diagnosis)',
      'Pituitary & Hypothalamic',
      'Thyroid / Parathyroid',
    ],
  },
  'General medicine': {
    value: 'General medicine',
    subSpecialties: ['General Medicine', 'Transient Ischemic Attack'],
  },
  Genetics: {
    value: 'Genetics',
    subSpecialties: ['Genetics'],
  },
  'Genito-Urinary medicine': {
    value: 'Genito-Urinary medicine',
    subSpecialties: ['Genito - urinary Medicine'],
  },
  'Geriatric medicine': {
    value: 'Geriatric medicine',
    subSpecialties: [
      'Cognitive Disorders',
      'Falls',
      'Metabolic Bone Diseases',
      'Movement Disorders',
      'Not otherwise specified',
      'Stroke (not TIA)',
    ],
  },
  'GI and liver (medicine & surgery)': {
    value: 'GI and liver (medicine & surgery)',
    subSpecialties: [
      'Colorectal Surgery',
      'Endoscopy - see Diagnostic Endoscopy',
      'Gallstones',
      'Hepatology',
      'Inflammatory Bowel Disease (IBD)',
      'Lower GI (medical) excel IBD',
      'Oncology (Established Diagnosis',
      'Upper GI incl Dyspepsia',
    ],
  },
  Gynaecology: {
    value: 'Gynaecology',
    subSpecialties: [
      'Colposcopy',
      'Early Pregnancy Assessment',
      'Family Planning',
      'Infertility',
      'Menopause',
      'Menstrual Disorders',
      'Not other specified',
      'Oncology (established Diagnosis)',
      'Pelvic Pain',
      'Perineal Repair',
      'Post Menopause Bleeding',
      'Pregnancy Advisory Service',
      'Psychosexual',
      'Recurrent Miscarriage',
      'Urogynaecoloogy / Prolapse',
      'Vulva and Perineal Lesions',
    ],
  },
  Haematology: {
    value: 'Haematology',
    subSpecialties: [
      'Anti Coagulant',
      'Clotting Disorders',
      'Haemoglobinopathies',
      'Not otherwise specified',
      'Oncology (established Diagnosis)',
    ],
  },
  'Health promotion': {
    value: 'Health promotion',
    subSpecialties: ['Exercise', 'Smoking cessation', 'Weight management'],
  },
  Immunology: {
    value: 'Immunology',
    subSpecialties: [
      'Autoimmune Disease',
      'Immunodeficiency',
      'Not Otherwise Specified',
    ],
  },
  'Infectious diseases': {
    value: 'Infectious diseases',
    subSpecialties: [
      'Bone and joint infection',
      'Chronic fatigue syndrome',
      'Fever',
      'HIV',
      'Not otherwise specified',
      'Travel advice',
      'Tropical Infections',
      'Tuberculosis',
      'Viral Hepatitis (not Hep A)',
    ],
  },
  'Interventional radiology': {
    value: 'Interventional radiology',
    subSpecialties: [
      'Infection and drainage',
      'Tumour Ablation',
      'Vascular and stent',
    ],
  },
  'Learning disabilities': {
    value: 'Learning disabilities',
    subSpecialties: ['Learning disabilities'],
  },
  'Mental health': {
    value: 'Mental health',
    subSpecialties: ['CAMHS', 'CMHT', 'IAPT', 'Crisis'],
  },
  Nephrology: {
    value: 'Nephrology',
    subSpecialties: ['Hypertension', 'Nephrology', 'Renal Diabetes'],
  },
  Neurology: {
    value: 'Neurology',
    subSpecialties: [
      'Cognitive Disorders',
      'Epilepsy',
      'Headache',
      'Neuroinflammation - multiple sclerosis',
      'Neuromusclar',
      'Not otherwise specified',
      'Oncology (established diagnosis)',
      "Parkinson's / Movement Disorders",
      'Sleep - see sleep medicine',
      'Stroke (not TIA)',
      'Transient Ischaemic Attack',
    ],
  },
  Neurosurgery: {
    value: 'Neurosurgery',
    subSpecialties: ['Movement disorders', 'Not otherwise specified', 'Spinal'],
  },
  Obstetrics: {
    value: 'Obstetrics',
    subSpecialties: [
      'Diabetes',
      'Endocrine Pregnancy',
      'Genetics',
      'Haemoglobinopathies',
      'Maternal Medicine',
      'Maternal / foetal Risk',
      'Multiple Pregnancy',
      'Not otherwise specified',
    ],
  },
  'Occupational therapy': {
    value: 'Occupational therapy',
    subSpecialties: [
      'Falls - see Geriatric Medicine',
      'Not otherwise specified',
      'Upper limb / splinting',
      'Wheelchair / Seating',
    ],
  },
  Ophthalmology: {
    value: 'Ophthalmology',
    subSpecialties: [
      'Cataract',
      'Cornea',
      'Diabetic Medical Retina',
      'External Eye Disease',
      'Glaucoma',
      'Laser (YAG capsulotomy)',
      'Low vision',
      'Neuro- Ophthalmology',
      'Not otherwise specified',
      'Oculoplastics / Orbits / Lacrimal',
      'Oncology (established Diagnosis)',
      'Orthoptics',
      'Other Medical Retina',
      'Squint / Ocular Motility',
      'Viteoreinal',
    ],
  },
  'Oral and Maxillofacial surgery': {
    value: 'Oral and Maxillofacial surgery',
    subSpecialties: [
      'Facial Deformity',
      'Facial Plastics',
      'Head and Neck lumps (not 2WW)',
      'Not otherwise specified',
      'Oncology (established Diagnosis)',
      'Oral Surgery',
      'Salivary Gland Disease',
    ],
  },
  Orthopaedics: {
    value: 'Orthopaedics',
    subSpecialties: [
      'Foot and Ankle',
      'Fracture - non emergency',
      'Hand and wrist',
      'Hip',
      'Knee',
      'Limb Deformity / reconstruction',
      'Oncology (established diagnosis)',
      'Podiatric surgery',
      'Shoulder and Elbow',
      'Spine - Back pain (not scoliosis / deform)',
      'Spine - neck pain',
      'Spine - scoliosis and deformity',
      'Sports trauma',
    ],
  },
  'Orthotics and Prosthetics': {
    value: 'Orthotics and Prosthetics',
    subSpecialties: ['Orthotics', 'Prosthetics'],
  },
  'Pain management': {
    value: 'Pain management',
    subSpecialties: ['Pain management'],
  },
  'Palliative medicine': {
    value: 'Palliative medicine',
    subSpecialties: ['Palliative medicine'],
  },
  Physiotherapy: {
    value: 'Physiotherapy',
    subSpecialties: [
      'Cardiovascular',
      'Falls - see Geriatric Medicine',
      'Gynaecological Physiotherapy',
      'Musculoskeletal',
      'Neurology',
      'Not otherwise specified',
      'Obstetric Physiotherapy',
      'Oncology (established diagnosis)',
      'Respiratory',
      'Neurological Physiotherapy',
    ],
  },
  Podiatry: {
    value: 'Podiatry',
    subSpecialties: [
      'At risk foot',
      'Biomechanical',
      'Diabetes (not ulcer)',
      'Nail surgery',
      'Not otherwise specified',
      'Podiatric Dermatology',
      'Podiatric health education',
      'Podiatric MSK - incl. rheumatology',
      'Podiatric Surgery - excl nail surgery',
    ],
  },
  Rehabilitation: {
    value: 'Rehabilitation',
    subSpecialties: ['Neuro -rehabilitation', 'Not otherwise specified'],
  },
  'Respiratory medicine': {
    value: 'Respiratory medicine',
    subSpecialties: [
      'Allergy',
      'Asthma',
      'COPD',
      'Cystic Fibrosis',
      'Interstitial Lung disease',
      'Not otherwise specified',
      'Occupational Lung Disease',
      'Oncology (established Diagnosis)',
      'Sleep - see sleep Medicine',
      'Tuberculosis',
    ],
  },
  Rheumatology: {
    value: 'Rheumatology',
    subSpecialties: [
      'Bone / Osteoporosis',
      'Inflammatory Arthritis',
      'Musculoskeletal',
      'Other Autoimmune Rheumatic Disease',
      'Spinal Disorders',
    ],
  },
  'Sleep medicine': {
    value: 'Sleep medicine',
    subSpecialties: [
      'Excessive / Intrusive Sleepiness',
      'Insomnia',
      'Parasomnia and other sleep disorders',
      'Sleep apnoea / sleep disordered breathing',
    ],
  },
  'Speech and language therapy': {
    value: 'Speech and language therapy',
    subSpecialties: ['Communication', 'Swallowing'],
  },
  'Sports and exercise medicine': {
    value: 'Sports and exercise medicine',
    subSpecialties: [
      'Exercise Medicine',
      'Non-surgical Musculoskeletal',
      'Soft tissue Injury',
      'Sports injury',
      'Sports medicine',
    ],
  },
  'Surgery - Breast': {
    value: 'Surgery - Breast',
    subSpecialties: [
      'FH of Breast Cancer (non 2WW)',
      'Mammoplasty (non 2WW)',
      'Oncology Established Diagnosis (non 2WW)',
      'Other symptomatic Breast (2WW)',
    ],
  },
  'Surgery - cardiothoracic': {
    value: 'Surgery - cardiothoracic',
    subSpecialties: ['Cardiac surgery', 'Thoracic surgery'],
  },
  'Surgery – Not otherwise specified': {
    value: 'Surgery – Not otherwise specified',
    subSpecialties: ['Endocrine Surgery', 'Hernias', 'Lumps and bumps'],
  },
  'Surgery - plastic': {
    value: 'Surgery - plastic',
    subSpecialties: [
      'Basal Cell Carcinoma',
      'Burns',
      'Craniofacila',
      'Gynaecology and Perineal Reconstruction',
      'Laser',
      'Lower Limb',
      'Mammoplasty',
      'Minor Plastic Surgery',
      'Not otherwise specified',
      'Oncology (established diagnosis)',
      'Upper limb',
      'Vascular Malformation',
    ],
  },
  'Surgery - vascular': {
    value: 'Surgery - vascular',
    subSpecialties: [
      'Arterial',
      'Leg Ulcer',
      'Lymphoedema',
      'Not otherwise specified',
      'Varicose Veins',
    ],
  },
  Urology: {
    value: 'Urology',
    subSpecialties: [
      '(In) Continence',
      'Erectile dysfunction / andrology',
      'Haematuria (not 2WW)',
      'Male Infertility',
      'Not otherwise specified',
      'Oncology (established diagnosis)',
      'Prostate',
      'Urinary calculus',
      'Vasectomy',
    ],
  },
  'Other radiology': {
    value: 'Other radiology',
    subSpecialties: [
      'Head (Brain) MRI',
      'IAMs + C MRI',
      'Pituitary',
      'MSK',
      'CT Abdomen',
    ],
  },
}

const workflowSubTypes: any = {
  letter: letterSubTypes,
  referral: referralSubTypes,
  investigation: investigationSubTypes,
  other: otherSubTypes,
}

export default workflowSubTypes
