import { defineMessages } from 'react-intl'

const baseId = 'consultation.section.tests'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private tests',
  },
  tooltip_message: {
    id: `${baseId}.tooltip_message`,
    defaultMessage:
      "Test requests will be sent to the patient's registered email address after you complete the consultation. Please ensure you get consent for this and check their email address. They should then arrange to have these tests done by a local private provider.",
  },
  add_test_button_label: {
    id: `${baseId}.add_test_button_label_v2`,
    defaultMessage: 'Add test',
  },
  error_loading_tests_section: {
    id: `${baseId}.error_loading_tests_section`,
    defaultMessage: 'Error loading Tests',
  },
})
