import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import { useObservable } from '~/core/container'
import Configurator from '~/core/container/configurator'
import { localConfig } from '~/features/shell/useInitConfig'

import { configData } from '../config/container'
import { requireConfigFiles } from './utils'

import styles from './styles.module.scss'

const loadConfigOptions = (): Record<string, string> => {
  try {
    const context = requireConfigFiles()
    const options = context
      .keys()
      .reduce((acc: string[][], key) => {
        const config = key.replace(/^\.\//, '').replace(/\.json$/, '')
        const partners: string[] = context(key)?.partners || []

        return [
          ...acc,
          [`Environment: ${config}`, config],
          ...partners.map((partner) => [`Partner: ${partner}`, config]),
        ]
      }, [])
      .sort()
    return Object.fromEntries(options)
  } catch (err) {
    return {}
  }
}

const configOptions = loadConfigOptions()

const ConfigToolbar = () => {
  const config = useObservable(configData)
  const [selectedConfig, setSelectedConfig] = useState('')

  const [showConfigEditor, setShowConfigEditor] = useState(false)

  const handleShowConfigEditor = () => {
    setShowConfigEditor(true)
  }

  const handleHideConfigEditor = () => {
    setShowConfigEditor(false)
  }

  const updateConfigValue = (value: string) => {
    localConfig.next(configOptions[value] || undefined)
    setSelectedConfig(value)
    window.localStorage.setItem('cp3-config-toolbar', value)
  }

  const handleConfigChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateConfigValue(event.target.value)
  }

  useEffect(() => {
    const value = window.localStorage.getItem('cp3-config-toolbar')
    if (value) {
      updateConfigValue(value)
    }
  }, [])

  return (
    <div className={styles.configEditor}>
      {showConfigEditor ? (
        <>
          <Configurator configData={config} />
          <button
            className={styles.hideButton}
            onClick={handleHideConfigEditor}
            type="button"
          >
            Close
          </button>
        </>
      ) : (
        <div
          className={cn(
            styles.miniPanel,
            Boolean(selectedConfig) && styles.active
          )}
        >
          <select
            onChange={handleConfigChange}
            value={selectedConfig}
            data-testid="config-toolbar-select"
          >
            <option value="">-- remote config --</option>
            {Object.keys(configOptions).map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          <button onClick={handleShowConfigEditor} type="button">
            Show config
          </button>
        </div>
      )}
    </div>
  )
}

export default ConfigToolbar
