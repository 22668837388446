import React, { ReactElement } from 'react'

import { useConsultationId } from '~/features/consultation'
import { isEventInFuture } from '~/features/patient-timeline/Timeline/utils'
import { ConsultationSummaryBaseQuery } from '~/generated'

import IncompleteConsultationMessage from './IncompleteConsultationMessage'
import Review from './Review'

export interface BaseConsultationSummaryProps {
  consultation: Pick<
    NonNullable<ConsultationSummaryBaseQuery['consultation']>,
    'id'
  >
  light?: boolean
}

export type ConsultationProps = Pick<
  NonNullable<ConsultationSummaryBaseQuery['consultation']>,
  'status' | 'id' | 'consumerNetworkId'
>

type ChildComponent = ({
  consultation,
  consultationId,
  light,
}: {
  consultation: ConsultationProps
  consultationId: string
  light?: boolean
}) => ReactElement | null

interface ConsultationSummaryProps {
  childComponents: ChildComponent[]
  consultation: ConsultationProps
  light?: boolean
  scheduledTime: string
}

const ConsultationSummary = ({
  consultation,
  childComponents,
  light,
  scheduledTime,
}: ConsultationSummaryProps) => {
  const currentConsultationId = useConsultationId()
  const isFutureEvent = isEventInFuture(scheduledTime)
  const isIncomplete = consultation?.status === 'paid'
  const isCurrentConsultation = currentConsultationId === consultation?.id
  const isIncompleteAndNotCurrentConsultation =
    isIncomplete && !isCurrentConsultation
  const reviewType = isFutureEvent
    ? 'upcoming'
    : isIncompleteAndNotCurrentConsultation
    ? 'incomplete'
    : 'complete'

  return (
    <React.StrictMode>
      <Review type={reviewType} light={light}>
        {isIncompleteAndNotCurrentConsultation && (
          <IncompleteConsultationMessage />
        )}
        {consultation &&
          childComponents.map((SectionComponent, index) => (
            <SectionComponent
              key={index}
              consultation={consultation}
              consultationId={consultation.id}
              light={light}
            />
          ))}
      </Review>
    </React.StrictMode>
  )
}

export default ConsultationSummary
