import React from 'react'

import { useScrollContext } from './ScrollProvider'

const ScrollablePanel = ({ ...props }) => {
  const { scrollablePanelRef } = useScrollContext()
  return <div {...props} ref={scrollablePanelRef} />
}

export default ScrollablePanel
