import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE } from '~/constants'
import Message from '~/ui/Message'

import messages from './messages'

const GpConsentWarning = () => {
  const fm = useFormatMessage()

  return (
    <Message
      type="error"
      title={fm(messages.title)}
      dataTestId="gp-consent-warning"
    >
      {fm(
        ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE
          ? messages.message
          : messages.message_without_prescription_consent
      )}
    </Message>
  )
}

export default GpConsentWarning
