import { ApolloQueryResult } from '@apollo/client'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import React from 'react'

import { logException } from '~/core/sentry'
import {
  CarePlanActionDraft,
  CarePlanDraftQuery,
  CarePlanGoalDraft,
} from '~/generated'

import {
  LocalActionDraft,
  LocalCarePlanDraft,
  LocalGoalDraft,
} from '../../CarePlan.types'
import { CarePlanDraftMutators } from '../useCarePlanDraftMutators'
import {
  addAndAssociateAction,
  deleteAction,
  deleteGoal,
  editGoal,
  saveEditedAction,
  saveGoal,
} from './CarePlanDraftPersistence'
import EditGoalAndActions from './EditGoalAndActions'
import {
  addLocalIdsToTransformedCarePlanDraft,
  getEmptyLocalAction,
  getEmptyLocalGoal,
} from './LocalDraftUtils'
import SaveButton, { SaveStatus } from './SaveButton'

export interface CarePlanFormValues extends LocalCarePlanDraft {
  SaveStatus?: SaveStatus
}

const getInitialCarePlanDraftEdits = (
  carePlanDraft: Extract<
    CarePlanDraftQuery['carePlanDraft'],
    { __typename: 'CarePlanDraft' }
  >
): CarePlanFormValues => {
  const localDraftCarePlan = addLocalIdsToTransformedCarePlanDraft(
    carePlanDraft
  )

  return {
    ...localDraftCarePlan,
    goals: [
      ...localDraftCarePlan.goals.map((goal) => ({
        ...goal,
        associatedActionDrafts: [
          ...goal.associatedActionDrafts,
          getEmptyLocalAction(),
        ],
      })),
      getEmptyLocalGoal(),
    ],
  }
}

const handleNothingToSave = (
  formikHelpers: FormikHelpers<CarePlanFormValues>
) => {
  formikHelpers.setSubmitting(false)
  formikHelpers.setFieldValue('SaveStatus', SaveStatus.NOTHING_TO_SAVE)
}

const hasUpdatedGoalDescriptionSince = (goals: CarePlanGoalDraft[]) => (
  localGoal?: LocalGoalDraft
) => {
  if (!localGoal) {
    return false
  }

  const previousGoal = goals.find(
    (previousGoal) => localGoal.draftId === previousGoal.draftId
  )

  if (!previousGoal) {
    return false
  }

  return previousGoal.description !== localGoal.description
}

const hasUpdatedActionDescriptionSince = (
  actions?: CarePlanActionDraft[] | null
) => (localAction: LocalActionDraft) => {
  if (!actions) {
    return false
  }

  const previousAction = actions.find(
    (previousAction) => localAction.draftId === previousAction.draftId
  )

  if (!previousAction) {
    return false
  }

  return previousAction.description !== localAction.description
}

interface CarePlanModalFormProps {
  carePlanDraft: Extract<
    CarePlanDraftQuery['carePlanDraft'],
    { __typename: 'CarePlanDraft' }
  >
  carePlanDraftMutators: CarePlanDraftMutators
  refetchCarePlanDraft: () => Promise<ApolloQueryResult<CarePlanDraftQuery>>
}

const CarePlanModalForm = ({
  carePlanDraft,
  carePlanDraftMutators,
  refetchCarePlanDraft,
}: CarePlanModalFormProps) => {
  const carePlanDraftEdits = getInitialCarePlanDraftEdits(carePlanDraft)

  const save = async (
    localCarePlanDraft: CarePlanFormValues,
    formikHelpers: FormikHelpers<CarePlanFormValues>
  ) => {
    const resetForm = async () => {
      const result = await refetchCarePlanDraft()

      if (result.data.carePlanDraft.__typename === 'CarePlanDraft') {
        formikHelpers.setValues({
          ...getInitialCarePlanDraftEdits(result.data.carePlanDraft),
          SaveStatus: SaveStatus.ERROR,
        })
      }
    }

    try {
      let savedSomething = false
      let intermediateState = {
        intermediateLocalCarePlanDraft: localCarePlanDraft,
        numberOfEdits: carePlanDraft.metadata.numberOfEdits,
      }

      // 1a) Look for deleted actions
      const goalsAndDeletedActions = carePlanDraft.goals.flatMap(
        (existingGoal) => {
          const localGoal = intermediateState.intermediateLocalCarePlanDraft.goals.find(
            (currentGoal) => currentGoal.draftId === existingGoal.draftId
          )

          if (!localGoal && existingGoal.associatedActionDrafts?.length) {
            // The goal has been deleted locally, so delete all it's actions

            return (
              existingGoal.associatedActionDrafts?.map((action) => ({
                goal: existingGoal,
                action,
              })) || []
            )
          }

          return (
            existingGoal.associatedActionDrafts
              ?.filter(
                (existingAction) =>
                  !localGoal?.associatedActionDrafts.some(
                    (localAction) =>
                      localAction.draftId === existingAction.draftId
                  )
              )
              .map((existingAction) => ({
                goal: existingGoal,
                action: existingAction,
              })) || []
          )
        }
      )

      // 1b) Disassociate and deleted actions
      for (const goalAndDeletedAction of goalsAndDeletedActions) {
        const latestNumberOfEdits = await deleteAction({
          actionDraftId: goalAndDeletedAction.action.draftId,
          goalDraftId: goalAndDeletedAction.goal.draftId,
          carePlanDraftMutators,
          latestLocalState: intermediateState.intermediateLocalCarePlanDraft,
          numberOfEdits: intermediateState.numberOfEdits,
          localCarePlanDraft,
          resetForm,
        })

        intermediateState = {
          ...intermediateState,
          numberOfEdits: latestNumberOfEdits,
        }

        savedSomething = true
      }

      // 2a) look for deleted goals
      const deletedGoals = carePlanDraft.goals
        .filter((previousGoal) => !!previousGoal.draftId)
        .filter(
          (previousGoal) =>
            !intermediateState.intermediateLocalCarePlanDraft.goals.some(
              (localGoal) => localGoal.draftId === previousGoal.draftId
            )
        )

      // 2b) delete a goal
      for (const goalToDelete of deletedGoals) {
        const latestNumberOfEdits = await deleteGoal({
          goalDraftId: goalToDelete.draftId,
          carePlanDraftMutators,
          numberOfEdits: intermediateState.numberOfEdits,
          localCarePlanDraft,
          resetForm,
        })

        intermediateState = {
          ...intermediateState,
          numberOfEdits: latestNumberOfEdits,
        }

        savedSomething = true
      }

      // 3a) Look for unsaved goals
      const unsavedGoals: LocalGoalDraft[] = intermediateState.intermediateLocalCarePlanDraft.goals.filter(
        (goal) => !goal.draftId
      )

      // 3b) save 1 unsaved goal
      for (const unsavedGoal of unsavedGoals) {
        if (
          unsavedGoal.description ||
          unsavedGoal.associatedActionDrafts.length > 0
        ) {
          intermediateState = await saveGoal({
            goalLocalId: unsavedGoal.localId,
            goalDescription: unsavedGoal.description || '',
            localCarePlanDraft:
              intermediateState.intermediateLocalCarePlanDraft,
            carePlanDraftMutators,
            numberOfEdits: intermediateState.numberOfEdits,
            resetForm,
          })

          formikHelpers.setValues(
            intermediateState.intermediateLocalCarePlanDraft
          )

          savedSomething = true
        }
      }

      // 4a) Look for unsaved actions
      const goalsAndUnsavedActions = intermediateState.intermediateLocalCarePlanDraft.goals.flatMap(
        (localGoal) => {
          if (!localGoal.draftId) {
            return []
          }

          const unsavedActions = localGoal.associatedActionDrafts.filter(
            (localAction) => !localAction.draftId && localAction.description
          )

          return unsavedActions.map((localUnsavedAction) => ({
            goal: localGoal,
            action: localUnsavedAction,
          }))
        }
      )

      // 4b) Persist one unsaved action
      for (const goalAndUnsavedAction of goalsAndUnsavedActions) {
        if (
          goalAndUnsavedAction.action.description &&
          goalAndUnsavedAction.goal.draftId
        ) {
          intermediateState = await addAndAssociateAction({
            description: goalAndUnsavedAction.action.description,
            goalDraftId: goalAndUnsavedAction.goal.draftId,
            actionLocalId: goalAndUnsavedAction.action.localId,
            localCarePlanDraft:
              intermediateState.intermediateLocalCarePlanDraft,
            carePlanDraftMutators,
            numberOfEdits: intermediateState.numberOfEdits,
            resetForm,
          })

          formikHelpers.setValues(
            intermediateState.intermediateLocalCarePlanDraft
          )
          savedSomething = true
        }
      }

      // 5a) look for edited goals
      const editedGoals = intermediateState.intermediateLocalCarePlanDraft.goals
        .filter((goal) => !!goal.draftId)
        .filter(hasUpdatedGoalDescriptionSince(carePlanDraft.goals))

      // 5b) persist the edited goal
      for (const editedGoal of editedGoals) {
        if (editedGoal.draftId) {
          const lastestNumberOfEdits = await editGoal({
            goalDraftId: editedGoal.draftId,
            goalDescription: editedGoal.description,
            carePlanDraftMutators,
            numberOfEdits: intermediateState.numberOfEdits,
            localCarePlanDraft,
            resetForm,
          })

          intermediateState = {
            ...intermediateState,
            numberOfEdits: lastestNumberOfEdits,
          }

          savedSomething = true
        }
      }

      // 6a) Look for edited actions
      const goalsAndEditedActions = intermediateState.intermediateLocalCarePlanDraft.goals.flatMap(
        (localGoal) => {
          if (!localGoal.draftId) {
            return []
          }

          const matchedExistingGoal = carePlanDraft.goals.find(
            (existingGoal) => existingGoal.draftId === localGoal.draftId
          )

          if (!matchedExistingGoal) {
            return []
          }

          const editedLocalActions = localGoal.associatedActionDrafts
            .filter(
              (localAction) => localAction.draftId && localAction.description
            )
            .filter(
              hasUpdatedActionDescriptionSince(
                matchedExistingGoal.associatedActionDrafts
              )
            )

          return editedLocalActions.map((editedLocalAction) => ({
            goal: localGoal,
            action: editedLocalAction,
          }))
        }
      )

      // 6b) Save all edited actions
      for (const goalAndEditedAction of goalsAndEditedActions) {
        if (
          goalAndEditedAction?.goal.draftId &&
          goalAndEditedAction?.action.draftId &&
          goalAndEditedAction?.action.description
        ) {
          const latestNumberOfEdits = await saveEditedAction({
            actionDraftId: goalAndEditedAction.action.draftId,
            description: goalAndEditedAction.action.description,
            carePlanDraftMutators,
            numberOfEdits: intermediateState.numberOfEdits,
            localCarePlanDraft,
            resetForm,
          })

          intermediateState = {
            ...intermediateState,
            numberOfEdits: latestNumberOfEdits,
          }

          savedSomething = true
        }
      }

      if (savedSomething) {
        formikHelpers.setFieldValue('SaveStatus', SaveStatus.SAVED)
      } else {
        handleNothingToSave(formikHelpers)
      }
    } catch (error) {
      logException(error)

      formikHelpers.setFieldValue('SaveStatus', SaveStatus.ERROR)
    } finally {
      formikHelpers.setSubmitting(false)
    }
  }

  return (
    <Formik<LocalCarePlanDraft>
      initialValues={carePlanDraftEdits}
      onSubmit={save}
    >
      {(formik) => (
        <Form
          onSubmit={(e) => {
            if (formik.isSubmitting) {
              return
            }

            formik.handleSubmit(e)
          }}
          data-testid="care-plan-form"
        >
          <FieldArray name="goals">
            {(arrayHelpers) => (
              <div>
                {formik.values.goals.map((goal, index, goals) => (
                  <EditGoalAndActions
                    index={index}
                    key={goal.localId}
                    length={goals.length}
                    arrayHelpers={arrayHelpers}
                  />
                ))}
                <SaveButton />
              </div>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  )
}

export default CarePlanModalForm
