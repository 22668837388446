import { gql } from '@apollo/client'

import { prescriptionFieldsFragment } from './fragments'

export const RemovePrescriptionMutation = gql`
  mutation RemovePrescriptionMutation($id: ID!) {
    removePrescription(id: $id)
  }
`

export const RemoveDrugFromPrescriptionMutation = gql`
  mutation RemoveDrugFromPrescriptionMutation(
    $id: ID!
    $prescriptionDrugId: ID!
  ) {
    removeDrugFromPrescription(
      id: $id
      prescriptionDrugId: $prescriptionDrugId
    ) {
      id
      createdAt
      prescriptionState
      drugs {
        prescriptionDrugId
        drugId
        drugName
        dosage
        duration
        pharmaceuticalForm
        strength
        quantity
        directions
        notes
      }
    }
  }
`

export const PrescriptionChangeStateMutation = gql`
  mutation PrescriptionChangeStateFromConsultationMutation(
    $id: ID!
    $reason: String
    $action: PrescriptionStateActionType!
    $region: String!
  ) {
    prescriptionChangeState(
      id: $id
      reason: $reason
      action: $action
      region: $region
    ) {
      id
      createdAt
      prescriptionState
      actions
    }
  }
`

export const ValidateDrugMutation = gql`
  mutation ValidateDrugMutation(
    $patientId: ID!
    $drug: PrescriptionDrugInput!
    $consultationId: ID
    $region: String!
  ) {
    validateDrug(
      patientId: $patientId
      drug: $drug
      consultationId: $consultationId
      region: $region
    ) {
      warnings {
        action
        message
        allowPrescribing
      }
    }
  }
`

export const AddDrugToPrescriptionMutation = gql`
  mutation AddDrugToPrescriptionMutation(
    $id: ID!
    $drug: PrescriptionDrugInput!
    $region: String!
  ) {
    addDrugToPrescription(id: $id, drug: $drug, region: $region) {
      ...PrescriptionFieldsFragment
    }
  }
  ${prescriptionFieldsFragment}
`

export const CreatePrescriptionMutation = gql`
  mutation CreatePrescriptionFromConsultationMutation(
    $consultationId: ID!
    $patientId: ID!
    $drugs: [PrescriptionDrugInput]
    $consumerNetwork: String!
    $region: String!
  ) {
    createPrescription(
      consultationId: $consultationId
      patientId: $patientId
      drugs: $drugs
      consumerNetwork: $consumerNetwork
      region: $region
    ) {
      ...PrescriptionFieldsFragment
    }
  }
  ${prescriptionFieldsFragment}
`

export const DuplicatePrescriptionMutation = gql`
  mutation DuplicatePrescriptionMutation($prescriptionId: ID!) {
    duplicatePrescription(prescriptionId: $prescriptionId) {
      ...PrescriptionFieldsFragment
    }
  }
  ${prescriptionFieldsFragment}
`
