import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { useCallback } from 'react'
import { MessageDescriptor } from 'react-intl'

import { useFormatMessage } from '@babylon/intl'

type Format<K, T = any, R = T> = {
  (
    key: K,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
  ): string
  (
    key: K,
    values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T, R>>
  ): string | React.ReactNodeArray
}

type Messages<Keys extends keyof any = string> = {
  [key in Keys]: MessageDescriptor
}

const useMessages = <T extends keyof any>(messages: Messages<T>) => {
  const fm = useFormatMessage()

  return useCallback<Format<T>>(
    (key: T, ...args: any[]) => fm(messages[key], ...args),
    [fm, messages]
  )
}

export default useMessages
