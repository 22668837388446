import React from 'react'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import MarkAsNoShow from '~/features/consultation/MarkAsNoShow'
import Message from '~/ui/Message'

import messages from './ConsultationActions.messages'

type ConsultationActionsViewProps = {
  mode:
    | 'open-review|mark-as-no-show'
    | 'open-review'
    | 'complete|exit-review'
    | 'update|exit-review'
    | 'complete|mark-as-no-show'
    | 'update'
    | 'complete'
    | 'exit'
  onToggleReview: () => void
  onCompleteConsultationClick: () => void
  consultationId: string
  showEditedConsultationWarning?: boolean
}

export const ConsultationActionsView = ({
  mode,
  onToggleReview,
  onCompleteConsultationClick,
  consultationId,
  showEditedConsultationWarning,
}: ConsultationActionsViewProps) => {
  const fm = useFormatMessage()

  const openReviewButton = (
    <Button
      fill
      onClick={() => onToggleReview()}
      dataTestId="consultation-review-button"
    >
      {fm(messages.review_consultation_button_label)}
    </Button>
  )

  const markAsNoShowButton = <MarkAsNoShow consultationId={consultationId} />

  const completeButton = (
    <Button fill onClick={() => onCompleteConsultationClick()}>
      {fm(messages.complete_consultation_button_label)}
    </Button>
  )

  const updateButton = (
    <Button fill onClick={() => onCompleteConsultationClick()}>
      {fm(messages.update_consultation_button_label)}
    </Button>
  )

  const exitReviewButton = (
    <Button fill intent="secondary" onClick={() => onToggleReview()}>
      {fm(messages.exit_review_button_label)}
    </Button>
  )

  const editedFinalizedConsultationWarning = (
    <Message type="warning" dataTestId="edited-consultation-warning-message">
      {fm(messages.edited_finalized_consultation_warning)}
    </Message>
  )

  switch (mode) {
    case 'open-review|mark-as-no-show':
      return (
        <>
          {openReviewButton}
          {markAsNoShowButton}
        </>
      )
    case 'open-review':
      return (
        <>
          {showEditedConsultationWarning && editedFinalizedConsultationWarning}
          {openReviewButton}
        </>
      )
    case 'complete|exit-review':
      return (
        <>
          {completeButton}
          {exitReviewButton}
        </>
      )
    case 'update|exit-review':
      return (
        <>
          {showEditedConsultationWarning && editedFinalizedConsultationWarning}
          {updateButton}
          {exitReviewButton}
        </>
      )
    case 'complete|mark-as-no-show':
      return (
        <>
          {completeButton}
          {markAsNoShowButton}
        </>
      )
    case 'update':
      return updateButton
    case 'complete':
      return completeButton
    case 'exit':
      return exitReviewButton
    default:
      throw new Error(`Invalid "mode" value "${mode}"`)
  }
}
