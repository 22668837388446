import { gql } from '@apollo/client'

import { WorkflowV2, WorkflowVariableInput } from '~/generated'

export const CREATE_WORKFLOW_MUTATION = gql`
  mutation TriggerWorkflow(
    $idempotency_key: String!
    $workflow_definition_key: String!
    $tenant_id: ID!
    $variables: [WorkflowVariableInput!]!
  ) {
    triggerWorkflow(
      idempotency_key: $idempotency_key
      workflow_definition_key: $workflow_definition_key
      tenant_id: $tenant_id
      variables: $variables
    ) {
      id
      start_time
      end_time
      state
      idempotency_key
      workflow_definition_key
      workflow_definition_id
      workflowDefinition {
        id
        key
        workflow_definition_name
        group {
          type
          label
        }
        roles
      }
      tenant_id
      variables {
        name
        value
      }
    }
  }
`

export interface MutationData {
  triggerWorkflow: WorkflowV2
}

export interface MutationVariables {
  idempotency_key: string
  workflow_definition_key: string
  tenant_id?: number | null | undefined
  variables: WorkflowVariableInput[]
}
