import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history.timeline_chatscript_event'

export default defineMessages({
  with_chatbot_meta: {
    id: `${baseId}.with_chatbot_meta`,
    defaultMessage: 'with Chatbot',
  },
  outcome_label: {
    id: `${baseId}.outcome_label`,
    defaultMessage: 'Outcome: ',
  },
  no_outcome: {
    id: `${baseId}.no_outcome`,
    defaultMessage: 'Completed without outcome',
  },
  someone_else_disclaimer: {
    id: `${baseId}.someone_else_disclaimer`,
    defaultMessage:
      'This chat conversation may have been done on behalf of somebody else. Only use this content if the patient completed it for themselves.',
  },
  see_conversation_for_more_details_message: {
    id: `${baseId}.see_conversation_for_more_details_message`,
    defaultMessage: 'Please see the conversation for more details',
  },
})
