import { useCallback, useRef } from 'react'

import useMount from './useMount'

const useHasMounted = () => {
  const mountedRef = useRef<boolean>(false)
  const get = useCallback(() => mountedRef.current, [])

  useMount(() => {
    mountedRef.current = true
  })

  return get
}

export default useHasMounted
