import React from 'react'

import { Button, FormikRadioGroup, Grid, Radio, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  PAT_MET_SAVE_SMOKING_STATUS,
  PAT_MET_UPDATE_SMOKING_STATUS,
  PATIENT_METRICS,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { PatientMetricsFormTemplate } from '~/features/patient-metrics/PatientMetricFormTemplate'
import { getSmokingDetails } from '~/features/patient-metrics/utils/smoking'
import { useTimestamp } from '~/features/patient-metrics/utils/Timestamp'
import { PatientMetricObservationType } from '~/generated'

import {
  SmokingStatus,
  usePatientMetricsState,
} from './PatientMetricsStateProvider'
import { isExpanded } from './utils'

import messages from './PatientMetricsModal.messages'
import styles from './styles.module.scss'

const SmokingMetricForm = () => {
  const startExpanded = isExpanded('SmokingInquiry')

  const fm = useFormatMessage()

  const {
    values: { smoking_status },
    displayValues: {
      displaySmokingStatus,
      timestampSmokingStatus,
      performerSmoking,
    },
    handleTaggedSubmit,
    setFieldValue,
    submitInfo,
  } = usePatientMetricsState()

  const [timestamp, setTimestamp] = useTimestamp(timestampSmokingStatus)

  const submitting = submitInfo.submitting.smoking_status
  const mutationError = submitInfo.error.smoking_status

  const { value, rating } = getSmokingDetails(displaySmokingStatus)

  const handleClick = async () => {
    try {
      await handleTaggedSubmit(PatientMetricObservationType.SmokingStatus)
      setTimestamp(fm(messages.smoking_status_updated_just_now))
      setFieldValue('smoking_status', null)

      analytics.trackEvent({
        label: displaySmokingStatus
          ? PAT_MET_UPDATE_SMOKING_STATUS
          : PAT_MET_SAVE_SMOKING_STATUS,
        category: PATIENT_METRICS,
        action: UPDATE_PATIENT_METRICS,
      })
    } catch (e) {
      // NOOP
    }
  }

  return (
    <PatientMetricsFormTemplate
      title={fm(messages.smoking_status_label)}
      value={fm(value)}
      rating={rating}
      startExpanded={startExpanded}
      timestamp={timestamp}
      performer={performerSmoking}
      dataTestId="smoking-metric-section"
      sectionName="smokingStatusSection"
    >
      <div data-testid="smoking-status-form-content">
        <Grid templateColumns="3fr 1fr" justifyContent="center">
          <FormikRadioGroup name="smoking_status" id="smoking_status">
            <div className={styles.radioButtonContainer}>
              <Radio value={SmokingStatus.non_smoker}>
                <Text>
                  {fm(messages.smoking_status_option_non_smoker_label)}
                </Text>
              </Radio>
              <Radio value={SmokingStatus.ex_smoker}>
                <Text>
                  {fm(messages.smoking_status_option_ex_smoker_label_v2)}
                </Text>
              </Radio>
              <Radio value={SmokingStatus.smoker}>
                <Text>{fm(messages.smoking_status_option_smoker_label)}</Text>
              </Radio>
            </div>
          </FormikRadioGroup>
          <div className={styles.submitButton}>
            <Button
              type="button"
              style={{ minWidth: '90px' }}
              loading={submitting}
              disabled={!smoking_status}
              onClick={handleClick}
            >
              {displaySmokingStatus
                ? fm(messages.submit_button_update_label)
                : fm(messages.submit_button_save_label)}
            </Button>
          </div>
        </Grid>
        {mutationError && (
          <div className={styles.mutationErrorMessage}>
            <Text color="error">
              {fm(messages.error_patient_metrics_mutation_failure)}
            </Text>
          </div>
        )}
      </div>
    </PatientMetricsFormTemplate>
  )
}

export default SmokingMetricForm
