import React from 'react'

import {
  PRE_CONS_SHOW_MORE_ACTION,
  PRECONSULTATION_SECTION_ERROR_ACTION,
} from '~/constants/analytics'
import { useConsultation, usePatient } from '~/core/config'
import { PreConsultationFlowPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { ChatscriptMessages } from '~/features/chatbot-flow'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import PreConsultationOnboarding from './PreConsultationOnboarding'
import PreConsultationSubtitle from './PreConsultationSubtitle'

import messages from './PreConsultationSection.messages'

const PreConsultationSection: PreConsultationFlowPluginInterface = ({
  consultationContext,
}) => {
  const { preConsultationFlowId } = useConsultation(consultationContext)
  const { uuid } = usePatient(consultationContext)

  const format = useMessages(messages)

  if (!preConsultationFlowId) return <div />

  return (
    <Section
      title={format('title_v2')}
      dataTestId="pre_consultation_section"
      subtitle={
        <PreConsultationSubtitle
          patientUuid={uuid}
          preConsultationFlowId={preConsultationFlowId}
        />
      }
    >
      <PreConsultationOnboarding />
      <ChatscriptMessages
        patientUuid={uuid}
        eventId={preConsultationFlowId}
        showDisclaimer={false}
        pagination="all"
        gaPaginationAction={PRE_CONS_SHOW_MORE_ACTION}
        showTranscriptTitle={false}
      />
    </Section>
  )
}

export default withSectionErrorBoundary({
  gaAction: PRECONSULTATION_SECTION_ERROR_ACTION,
  titleDescriptor: messages.cant_load_chatbot_flow,
})(PreConsultationSection)
