import { defineMessages } from 'react-intl'

const baseId = 'consultation.call_service'

export default defineMessages({
  call_service_error_title: {
    id: `${baseId}.call_service_error_title`,
    defaultMessage: 'Error',
  },
})
