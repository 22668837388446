import { ModelProvider } from './provider'

export interface ModuleConfig {
  id?: string
  module: string
  options?: Record<string, any>
  providers?: ModelProvider[]
  [key: string]: any
}

export type Config = Record<string, ModuleConfig>

export interface ModuleContext {
  config: Record<string, ModuleConfig>
  modules: Modules
  getModule: () => any
  moduleConfig: ModuleConfig
  initValue: (value: any) => Promise<any>
  containerContext: Record<string, any>
  moduleContext: Record<string, any>
  [key: string]: any
}

export type Modules = Record<string, any>

export type Transform = (next: () => any, context: ModuleContext) => any

export interface Container {
  resolve: (id: string) => Promise<any>
}

export const initContainer = (
  config: Config,
  modules: Modules,
  options?: {
    transforms?: Transform[]
  }
): Container => {
  if (!config) {
    throw new Error('Configuration is required')
  }

  if (!modules) {
    throw new Error('Modules are required')
  }

  const containerContext = {}

  const CONTEXT: unique symbol = Symbol()

  const initValue = async (value: any): Promise<any> => {
    if (Array.isArray(value)) {
      const array: any[] = []

      for (let i = 0; i < value.length; i++) {
        array[i] = await initValue(value[i])
      }

      return array
    }

    if (typeof value === 'object') {
      if (value === null) {
        return value
      }

      if (value.$ref) {
        return await resolve(value.$ref)
      }

      const entries = Object.entries(value)
      const object: Record<string, any> = {}

      for (let i = 0; i < entries.length; i++) {
        const [name, value] = entries[i]
        object[name] = await initValue(value)
      }

      return object
    }

    return value
  }

  const resolve = async (id: string) => {
    const moduleConfig = config[id]

    if (!moduleConfig) {
      throw new Error(`Unable to find module config with id "${id}"`)
    }

    // @ts-expect-error
    const moduleContext = moduleConfig[CONTEXT] ?? {}

    // @ts-expect-error
    moduleConfig[CONTEXT] = moduleContext

    const getModule = () => {
      const { module: name } = moduleConfig
      const module = modules[name]

      if (!module) {
        throw new Error(`Unable to load module with name "${name}"`)
      }

      return module
    }

    const getOptions = () => initValue(moduleConfig.options)

    const getProviders = () =>
      moduleConfig.providers?.length ? initValue(moduleConfig.providers) : null

    let apply = async () => {
      const module = context.getModule()
      const options = await context.getOptions()

      moduleContext.instance = moduleContext.instance ?? module(options)

      return moduleContext.instance
    }

    const context: ModuleContext = {
      config,
      modules,
      getModule,
      getOptions,
      getProviders,
      moduleConfig,
      initValue,
      containerContext,
      moduleContext,
    }

    const transforms = options?.transforms

    if (transforms?.length) {
      for (let i = transforms.length - 1; i >= 0; i--) {
        const transform = transforms[i]
        const next = apply
        apply = async () => await transform(next, context)
      }
    }

    return apply()
  }

  return { resolve }
}
