import React from 'react'
import { BlockJSON, InlineJSON, TextJSON, ValueJSON } from 'slate'

import { logMessage } from '~/core/sentry'
import { BabylonSerializer } from '~/features/clinical-coding/ClinicalNotesEditor'
import ClinicalCode from '~/features/clinical-coding/ClinicalNotesEditor/components/ClinicalCode'
import Paragraph from '~/features/clinical-coding/ClinicalNotesEditor/components/Paragraph'
import { ClinicalCodeJSON } from '~/features/clinical-coding/ClinicalNotesEditor/serializers/Babylon/createClinicalCodeNode'
import { ConsultationNoteCode } from '~/generated'

interface TextWithCodesProps {
  text: string
  codes?: Array<ConsultationNoteCode>
}

const convertTextNodeToString = (node: TextJSON): string[] => {
  return node ? node.leaves.map((v) => v.text || '') : []
}
/**
 * Check if node is an empty Text node
 */
const isEmptyTextNode = (node: InlineJSON | TextJSON | BlockJSON): boolean => {
  /**
   * When node is text with only one leaf
   */
  if (node.object === 'text' && node.leaves?.length === 1) {
    /**
     * Return true when leaf is empty string
     */
    return node.leaves[0]?.text === ''
  }

  return false
}
/**
 * Check if node is a paragraph with a single empty Text node
 */
const isEmptyParagraph = (node: BlockJSON | InlineJSON | TextJSON): boolean => {
  /**
   * When node is block with only one child
   */
  if (node.object === 'block' && node.nodes?.length === 1) {
    const onlyChild = node.nodes[0]
    /**
     * Return true when the only child is empty text node
     */
    if (onlyChild) {
      return isEmptyTextNode(onlyChild)
    }
  }

  return false
}
/**
 * Renders text nodes & clinical nodes within paragraph, skips everything else
 */
const convertInlineNodeToJSX = (
  inline: TextJSON | ClinicalCodeJSON,
  inlineIndex: number
): React.ReactNode => {
  if (inline.object === 'text') {
    return convertTextNodeToString(inline)
  }

  if (inline.object === 'inline' && inline.type === 'clinical_code') {
    const key = `inline-${inline.object}-${inlineIndex}`

    return (
      <ClinicalCode key={key}>
        {inline.nodes ? inline.nodes.map(convertTextNodeToString) : null}
      </ClinicalCode>
    )
  }

  logMessage(
    `Node ${inline.object} of type ${inline.type} is not supported and will be ignored.`
  )

  return null
}
/**
 * Render paragraph content.
 * When paragraph is empty we return whitespace. The motivation behind it is that HTML
 * will "hide" empty "phrasing content nodes".
 *
 * More here:
 * https://www.w3.org/TR/html52/dom.html#phrasing-content-2
 */
const renderParagraph = (paragraph: any /* BlockJSON */) => {
  return isEmptyParagraph(paragraph)
    ? ' '
    : paragraph.nodes.map(convertInlineNodeToJSX)
}

const TextWithCodes: React.FC<TextWithCodesProps> = ({ text, codes = [] }) => {
  // TODO: Memoize
  const { document } = BabylonSerializer.deserialize(text, codes, {
    json: true,
  }) as ValueJSON

  if (!document) {
    return null
  }

  const nodes = document.nodes || []

  return (
    <>
      {nodes.map((paragraph: any, paragraphIndex: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Paragraph key={`paragraph-${paragraphIndex}`}>
          {renderParagraph(paragraph)}
        </Paragraph>
      ))}
    </>
  )
}

export default TextWithCodes
