import { defineMessages } from 'react-intl'

const baseId = 'prescription_modal.dosage_editor'

export default defineMessages({
  placeholder: {
    id: `${baseId}.title`,
    defaultMessage: 'e.g. 2OD',
  },
})
