import get from 'lodash/get'
import { useMemo } from 'react'

import { useTestTypesQuery } from '~/generated'

const useTestTypes = () => {
  const { loading, error, data = {} } = useTestTypesQuery({
    fetchPolicy: 'cache-first',
  })

  const testTypes = useMemo(() => {
    const { pathologyTestTypes = [], diagnosticTestTypes = [] } = data

    return {
      pathology: pathologyTestTypes,
      diagnostic: diagnosticTestTypes,
    }
  }, [data])

  const getTestName = (type, id) => {
    const types = get(testTypes, type, [])
    const test = types.find((v) => v.testType.toString() === id.toString())

    return test ? test.name : null
  }

  return { loading, error, testTypes, getTestName }
}

export default useTestTypes
