import React from 'react'
import { useHistory } from 'react-router'

import { Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { useConsultationId } from '~/features/consultation'
import {
  useNhsAppointmentConsultationQuery,
  useNhsAppointmentServiceTypesQuery,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'

import NhsAppointment from './NhsAppointment'
import { prepareServiceTypes } from './utils/serviceType'

import messages from './NhsAppointment.messages'

const NhsAppointmentModal = ({
  faceToFaceInvitesEnabled,
  digitalInvitesEnabled,
}) => {
  const fm = useFormatMessage()
  const consultationId = useConsultationId()
  const history = useHistory()

  const {
    data: consultationData,
    loading: consultationLoading,
    error: consultationError,
    refetch: consultationRefetch,
  } = useNhsAppointmentConsultationQuery({
    variables: {
      id: consultationId,
    },
  })
  const consultation = consultationData?.consultation ?? {}

  const {
    data: serviceTypesData,
    loading: serviceTypesLoading,
    error: serviceTypesError,
    refetch: serviceTypesRefetch,
  } = useNhsAppointmentServiceTypesQuery({
    variables: {
      memberId: consultation?.patient?.uuid,
    },
    skip: !consultation?.patient?.uuid || !faceToFaceInvitesEnabled,
  })
  const serviceTypes = serviceTypesData?.serviceTypes ?? []

  const loading = consultationLoading || serviceTypesLoading
  const error = consultationError || serviceTypesError
  const refetch = consultationError ? consultationRefetch : serviceTypesRefetch
  const errorMessage = consultationError
    ? fm(messages.consultation_fetch_error)
    : fm(messages.service_types_fetch_error)

  const onClose = () => history.replace(`/consultation/${consultationId}`)

  return (
    <Slideout title={fm(messages.modal_title)} onClose={onClose} width="62.3%">
      {({ openModal, closeModal }) => {
        if (loading) {
          return <Spinner color="#87919e" size="medium" centered />
        }

        if (error) {
          return (
            <ErrorPanel
              error={error}
              title={errorMessage}
              retry={() => refetch()}
              center
              fill="container"
            />
          )
        }

        return (
          <NhsAppointment
            consultation={consultation}
            serviceTypes={prepareServiceTypes(serviceTypes)}
            openModal={openModal}
            closeModal={closeModal}
            faceToFaceInvitesEnabled={faceToFaceInvitesEnabled}
            digitalInvitesEnabled={digitalInvitesEnabled}
          />
        )
      }}
    </Slideout>
  )
}

export default NhsAppointmentModal
