/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './ResponsiveTable.scss'
import { extractCells } from './utils'
import ResponsiveTableCell from './ResponsiveTableCell'

const ResponsiveTableRow = ({
  row,
  columns,
  showCellHeaders,
  rowClickHandler,
  cellPadding = 'medium',
}) => {
  const isElement = React.isValidElement(row)
  const cellData = isElement ? null : extractCells(row, columns)

  const classes = [
    'core-responsive-table__row',
    'core-responsive-table__data-row',
    rowClickHandler && 'core-responsive-table__row--clickable',
    row.rowClassName,
  ]

  return isElement ? (
    row
  ) : (
    <div
      className={cn(classes)}
      role="row"
      tabIndex={0}
      onClick={rowClickHandler ? () => rowClickHandler(row) : null}
    >
      {cellData.map((cell, index) => (
        <ResponsiveTableCell
          key={index}
          header={showCellHeaders ? cell.label : null}
          content={cell.data}
          className={row.cellClassName}
          cellPadding={cellPadding}
        />
      ))}
    </div>
  )
}

ResponsiveTableRow.propTypes = {
  // disabling rule because row is intentionally vague
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.node]), // eslint-disable-line react/forbid-prop-types
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      size: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
  showCellHeaders: PropTypes.bool,
  cellPadding: PropTypes.string,
}
export default ResponsiveTableRow
