import { defineMessages } from 'react-intl'

const baseId = 'call_recording'

export default defineMessages({
  toggle_enabled: {
    id: `${baseId}.toggle_enabled`,
    defaultMessage: 'Speech processing on',
  },
  toggle_disabled: {
    id: `${baseId}.toggle_disabled`,
    defaultMessage: 'Speech processing off',
  },
  consent_loading: {
    id: `${baseId}.consent_loading`,
    defaultMessage: 'Consent loading',
  },
})
