import { createQueryUpdater } from '~/core/graphql'
import {
  ConsultationAlertsQuery,
  CreatePatientAlertMutationResult,
  RemovePatientAlertMutationResult,
} from '~/generated'

import { CpConsultationAlertsQuery } from './queries'

const updateQuery = createQueryUpdater(CpConsultationAlertsQuery)

export const createPatientAlertCache = (consultationId: string) =>
  updateQuery(
    { consultationId },
    (
      cache: ConsultationAlertsQuery,
      response: CreatePatientAlertMutationResult
    ) => {
      const newAlert = response?.data?.createPatientAlert

      const cachedAlerts = cache.consultation?.patient.patient_alerts || []
      const alerts = [...cachedAlerts, newAlert]

      return {
        ...cache,
        consultation: {
          ...cache.consultation,
          patient: {
            ...cache?.consultation?.patient,
            patient_alerts: alerts,
          },
        },
      }
    }
  )

export const removePatientAlertCache = (consultationId: string) =>
  updateQuery(
    {
      consultationId,
    },
    (
      cache: ConsultationAlertsQuery,
      response: RemovePatientAlertMutationResult
    ) => {
      const id = response?.data?.removePatientAlert
      const alerts = (cache?.consultation?.patient.patient_alerts || []).filter(
        (v) => v.id !== id
      )

      return {
        ...cache,
        consultation: {
          ...cache.consultation,
          patient: {
            ...cache?.consultation?.patient,
            patient_alerts: alerts,
          },
        },
      }
    }
  )
