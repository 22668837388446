import { faFileAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import semverCmp from 'semver-compare'

import { useFormatMessage } from '@babylon/intl'

import {
  OPEN_RELEASE_NOTES_MODAL_ACTION,
  RELEASE_NOTES_MODAL_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useToggle } from '~/core/hooks'
import { NavigationLink } from '~/features/navigation'

import ReleaseNotesModal from './ReleaseNotesModal'
import useRelevantReleaseNotes from './useRelevantReleaseNotes'

import messages from './ReleaseNotes.messages'

const LATEST_VERSION_KEY = 'release-notes-latest-seen-version'

const getIsNewVersion = (latest: any) => {
  if (typeof latest !== 'object') {
    return false
  }

  const lastSeenVersion = localStorage.getItem(LATEST_VERSION_KEY)

  if (!lastSeenVersion || lastSeenVersion === 'undefined') {
    return true
  }

  return semverCmp(lastSeenVersion, latest.versionNumber) < 0
}

interface ReleaseNotesProps {
  releaseNoteCountryCode?: string
}

const ReleaseNotes = ({ releaseNoteCountryCode }: ReleaseNotesProps) => {
  const [modalVisible, toggleModalVisible] = useToggle(false)
  const { loading, error, notes } = useRelevantReleaseNotes(
    releaseNoteCountryCode
  )
  const fm = useFormatMessage()

  const latest = notes[0]

  const isNewVersion = getIsNewVersion(latest)

  useEffect(() => {
    if (isNewVersion) {
      toggleModalVisible(true)
    }
  }, [isNewVersion, toggleModalVisible])

  const onModalClose = () => {
    if (latest) {
      localStorage.setItem(LATEST_VERSION_KEY, latest.versionNumber)
    }

    toggleModalVisible(false)
  }

  const handleClick = () => {
    analytics.trackEvent({
      category: RELEASE_NOTES_MODAL_CATEGORY,
      action: OPEN_RELEASE_NOTES_MODAL_ACTION,
    })
    toggleModalVisible(true)
  }

  return (
    <>
      <NavigationLink
        onClick={handleClick}
        icon={
          <FontAwesomeIcon
            icon={faFileAlt}
            style={{ height: 24, width: 24 }}
            color="#6B7785"
          />
        }
      >
        {fm(messages.release_notes_button_text)}
      </NavigationLink>
      {modalVisible && (
        <ReleaseNotesModal
          notes={notes}
          error={error}
          loading={loading}
          onClose={onModalClose}
        />
      )}
    </>
  )
}

export default ReleaseNotes
