import { defineMessages } from 'react-intl'

const baseId = 'dashboard.navigation'

export default defineMessages({
  availability_text: {
    id: `${baseId}.availability_text`,
    defaultMessage: 'Availability',
  },
})
