import React from 'react'

import { DocumentRepository } from '@babylon/document-repository'
import { useFormatMessage } from '@babylon/intl'

import { PATIENT_DOCUMENTS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useConsultation } from '~/core/config'
import { DocumentRepositoryPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import {
  Section,
  SectionExpander,
  withSectionErrorBoundary,
} from '~/ui/Section'

import messages from './DocumentTable.messages'

const DocumentTable: DocumentRepositoryPluginInterface = ({
  consultationContext,
}) => {
  const { documentRepositoryEnabled } = useFeatureFlags()
  const fm = useFormatMessage()
  const consultation = useConsultation(consultationContext)

  return documentRepositoryEnabled ? (
    <Section title={fm(messages.title)}>
      <SectionExpander
        title={fm(messages.title)}
        showMoreMessage={fm(messages.show_documents)}
      >
        <DocumentRepository memberId={consultation?.patientId} />
      </SectionExpander>
    </Section>
  ) : null
}

export default withSectionErrorBoundary({
  gaAction: PATIENT_DOCUMENTS_SECTION_ERROR_ACTION,
  titleDescriptor: messages.title,
})(DocumentTable)
