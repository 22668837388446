import { useCallback, useState } from 'react'

export type SetState<T> = (
  patch: Partial<T> | ((prevState: T) => Partial<T>)
) => void

/**
 * Mirrors the basic API of React class method `this.setState({...})`.
 */
const useSetState = <T extends object>(
  initialState: T = {} as T
): [T, SetState<T>] => {
  const [state, set] = useState<T>(initialState)
  const setState = useCallback(
    (patch) => {
      set((prevState) => ({
        ...prevState,
        ...(patch instanceof Function ? patch(prevState) : patch),
      }))
    },
    [set]
  )

  return [state, setState]
}

export default useSetState
