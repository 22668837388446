import React from 'react'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import Section from '~/features/consultation-summary/ConsultationSummary/Section'
import { Prescription } from '~/generated'

import PrescriptionsTable from '../PrescriptionsTable'

import messages from './PrescriptionsSummary.messages'

interface PrescriptionsSummaryProps {
  prescriptions?: Prescription[]
}

const PrescriptionsSummary = ({ prescriptions }: PrescriptionsSummaryProps) => {
  const f = useMessages(messages)

  if (!isFilledArray(prescriptions)) {
    return null
  }

  return (
    <Section
      title={f('prescriptions_section_title_private')}
      testid="cr-prescriptions"
    >
      <PrescriptionsTable list={prescriptions} />
    </Section>
  )
}

export default PrescriptionsSummary
