import React from 'react'

import { Checkbox, Text } from '@babylon/core-ui'

import {
  EXPERIMENT_DISABLED_LABEL,
  EXPERIMENT_ENABLED_LABEL,
  EXPERIMENTS_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { Experiment } from '~/core/core-modules'
import { useLocalStorage, useMessages } from '~/core/hooks'

import { experimentNameToKey } from './utils'

import messages from './Experiments.messages'
import styles from './styles.module.scss'

interface ExperimentCheckboxProps {
  experiment: Experiment
}

const ExperimentCheckbox = ({ experiment }: ExperimentCheckboxProps) => {
  const f = useMessages(messages)

  const { title, enabledForEveryone, description } = experiment
  const experimentKey = experimentNameToKey(title)
  const [isEnabled, setEnabled] = useLocalStorage<boolean>(experimentKey, false)

  return (
    <Checkbox
      data-testid="experiment-checkbox"
      className={styles.experiment}
      onChange={() => {
        analytics.trackEvent({
          category: EXPERIMENTS_CATEGORY,
          action: experimentKey,
          label: isEnabled
            ? EXPERIMENT_DISABLED_LABEL
            : EXPERIMENT_ENABLED_LABEL,
        })
        setEnabled(!isEnabled)
      }}
      checked={isEnabled}
      value={title}
      disabled={!!enabledForEveryone}
    >
      <div className={styles.experimentCheckbox}>
        <Text
          size="large"
          color={enabledForEveryone ? 'grey-type' : 'black-type'}
          bold
        >
          {title} {enabledForEveryone && f('experiment_enabled_for_everyone')}
        </Text>
        <Text size="medium" color="grey-type">
          {description}
        </Text>
      </div>
    </Checkbox>
  )
}

export default ExperimentCheckbox
