import React, { useContext } from 'react'

type ProviderProps = {
  children: React.ReactNode
  value?: any
}

export interface ModelProvider<T = any> {
  (): { context: React.Context<T | null> }

  Provider: (props: ProviderProps) => JSX.Element
  useModelContext: () => T
}

export const useModelProvider = <T extends {} = any, P extends {} = any>(
  useModel: (options: P) => T,
  options: P
): ModelProvider<T> => {
  const context = React.createContext<T | null>(null)

  const Provider = ({ children, value }: ProviderProps) => {
    const model = useModel(options)

    return (
      <context.Provider value={value || model}>{children}</context.Provider>
    )
  }

  const modelProvider = () => {
    return {
      context,
    }
  }

  modelProvider.Provider = Provider
  modelProvider.useModelContext = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const value = useContext(context)

    if (value == null) {
      throw new Error('This hook cannot be used without surrounding context')
    }

    return value
  }

  return modelProvider
}

export default useModelProvider
