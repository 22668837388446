import { ApolloError } from '@apollo/client'
import React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'

import { Button } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import NhsAppointmentModal from '~/features/nhs-appointment-invites/NhsAppointmentModal'
import Workflow from '~/features/workflows/WorkflowActions/components/Workflow'
import {
  AppointmentInvitesQueryResult,
  CpConsultationWorkflowsV2QueryResult,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section } from '~/ui/Section'

import AppointmentInvites from './components/AppointmentInvite'

import messages from './NhsAppointmentInvites.messages'

export interface NhsAppointmentInvitesViewProps {
  appointmentInvites: NonNullable<
    NonNullable<AppointmentInvitesQueryResult['data']>['consultation']
  >['appointmentInvites']
  appointmentWorkflows: NonNullable<
    CpConsultationWorkflowsV2QueryResult['data']
  >['consultationWorkflowsV2']['workflows']['results']
  loading: boolean
  refetch: () => void
  error?: ApolloError
  restrictFinalizedConsultationEditing: boolean
  faceToFaceInvitesEnabled: boolean
  digitalInvitesEnabled: boolean
}

const NhsAppointmentInvitesView = ({
  appointmentInvites,
  appointmentWorkflows,
  loading,
  refetch,
  error,
  restrictFinalizedConsultationEditing,
  faceToFaceInvitesEnabled,
  digitalInvitesEnabled,
}: NhsAppointmentInvitesViewProps) => {
  const f = useMessages(messages)
  const history = useHistory()
  const match = useRouteMatch()

  const handleButtonClick = () => {
    history.replace(`${match.url}/workflow/nhs-appointment`)
  }

  if (error && !loading) {
    return (
      <ErrorPanel
        title={f('appointment_query_error')}
        error={error}
        retry={() => refetch()}
      />
    )
  }

  return (
    <>
      <Section
        loading={loading}
        type="secondary"
        title={f('workflow_action_nhs_appointment_name')}
        topRight={
          <Button
            intent="secondary"
            onClick={() => handleButtonClick()}
            disabled={restrictFinalizedConsultationEditing}
          >
            {f('create_button_label')}
          </Button>
        }
        dataTestId="nhs-appointments"
      >
        {faceToFaceInvitesEnabled && (
          <AppointmentInvites
            appointmentInvites={appointmentInvites || []}
            loading={loading}
          />
        )}
        {digitalInvitesEnabled &&
          appointmentWorkflows.map((workflow) => (
            <Workflow key={workflow.id} workflow={workflow} />
          ))}
      </Section>
      {!restrictFinalizedConsultationEditing && (
        <Switch>
          <Route
            path="/consultation/:consultationId/workflow/nhs-appointment"
            render={() => (
              <NhsAppointmentModal
                faceToFaceInvitesEnabled={faceToFaceInvitesEnabled}
                digitalInvitesEnabled={digitalInvitesEnabled}
              />
            )}
          />
        </Switch>
      )}
    </>
  )
}

export default NhsAppointmentInvitesView
