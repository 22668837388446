import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.summary.diagnosis_summary'

export default defineMessages({
  our_assessment_section_title: {
    id: `${baseId}.our_assessment_section_title`,
    defaultMessage: 'Our assessment',
  },
  treatment_section_title: {
    id: `${baseId}.treatment_section_title`,
    defaultMessage: 'What you should do',
  },
  fallback_section_title: {
    id: `${baseId}.fallback_section_title`,
    defaultMessage: 'What to do if things don’t improve',
  },
  further_information_section_title: {
    id: `${baseId}.further_information_section_title`,
    defaultMessage: 'Further information',
  },
  diagnosis_summary_loading_error: {
    id: `${baseId}.diagnosis_summary_loading_error`,
    defaultMessage: 'Error loading diagnosis summary',
  },
})
