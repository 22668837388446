import React from 'react'

import { Button, Tag, Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import {
  CARE_PLAN_EDIT_GOALS_AND_ACTIONS_ACTION,
  CARE_PLAN_HAS_GOALS_AND_ACTIONS_LABEL,
  CARE_PLAN_NO_GOALS_AND_ACTIONS_LABEL,
  CARE_PLANS_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages } from '~/core/hooks'
import { CarePlanDraftQuery, CarePlanQuery } from '~/generated'
import { Section } from '~/ui/Section'

import GoalsAndActions from './GoalsAndActions'
import Introduction from './Introduction'

import messages from './CarePlanSectionView.messages'
import styles from './styles.module.scss'

interface CarePlanViewProps {
  carePlan?: Extract<CarePlanQuery['carePlan'], { __typename: 'CarePlan' }>
  carePlanDraft?: Extract<
    CarePlanDraftQuery['carePlanDraft'],
    { __typename: 'CarePlanDraft' }
  >
  openModal?: () => void
  isConsultationIncomplete: boolean
}

const trackEditGoalsAndActions = (hasExistingGoals: boolean | undefined) => {
  analytics.trackEvent({
    action: CARE_PLAN_EDIT_GOALS_AND_ACTIONS_ACTION,
    category: CARE_PLANS_CATEGORY,
    label: hasExistingGoals
      ? CARE_PLAN_HAS_GOALS_AND_ACTIONS_LABEL
      : CARE_PLAN_NO_GOALS_AND_ACTIONS_LABEL,
  })
}

const CarePlanSectionView = ({
  carePlan,
  carePlanDraft,
  openModal,
  isConsultationIncomplete,
}: CarePlanViewProps) => {
  const { formatDate } = useIntl()
  const f = useMessages(messages)
  const shouldDisplayDraft = !!carePlanDraft?.metadata.numberOfEdits
  const hasExistingGoals =
    (shouldDisplayDraft && !!carePlanDraft?.goals.length) ||
    (!shouldDisplayDraft && !!carePlan?.goals.length)

  const topRight = openModal ? (
    <Button
      dataTestId="open_modal_button"
      intent="secondary"
      onClick={() => {
        openModal()
        trackEditGoalsAndActions(hasExistingGoals)
      }}
    >
      {hasExistingGoals
        ? f('update_health_goals_and_actions')
        : f('create_health_goals_and_actions')}
    </Button>
  ) : null

  const datetime = shouldDisplayDraft
    ? carePlanDraft?.updatedAt
    : carePlan?.revisionCreatedAt

  const formattedDateTime = formatDate(datetime, { format: 'shortWithTime' })

  return (
    <Section title={f('goals_and_actions_heading')} topRight={topRight}>
      {openModal ? (
        <Introduction />
      ) : (
        isConsultationIncomplete && (
          <Text size="medium" color="grey-type" tag="p">
            {f('not_editable')}
          </Text>
        )
      )}
      <div className={styles.goalsAndActionsTags}>
        <Text
          tag="div"
          size="medium"
          color="grey-type"
          data-testid="care_plan_date_time"
        >
          {formattedDateTime}
        </Text>
        {shouldDisplayDraft && (
          <Tag color="clinical-green">{f('draft_tag')}</Tag>
        )}
      </div>
      <GoalsAndActions
        goals={shouldDisplayDraft ? carePlanDraft?.goals : carePlan?.goals}
      />
    </Section>
  )
}

export default CarePlanSectionView
