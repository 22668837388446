import { $ref, Config } from './generated/babylon-us'

import messages from '../messages/config.messages'

const config: Config = {
  main: {
    module: 'Main',
    options: {
      layout: $ref('layout'),
      init: {
        ehrCheckIn: $ref('ehrCheckIn'),
      },
    },
  },

  layout: {
    module: 'Layout',
    reactElement: true,
    options: {
      header: $ref('header'),
      navigation: $ref('navigation'),
      routes: $ref('routes'),
    },
  },

  routes: {
    module: 'Routes',
    reactElement: true,
    options: {
      defaultRoute: '/consultations',
      routes: [
        {
          path: '/consultations/:date?/:status?',
          route: $ref('schedulePage'),
        },
        {
          path: '/consultation/:id',
          route: $ref('consultationPage'),
        },
        {
          path: '/all-consultations',
          route: $ref('allConsultationsPage'),
        },
        {
          path: '/admin/consultants/:userid/availability',
          route: $ref('adminPortalPage'),
        },
        {
          path: '/experiments',
          route: $ref('experimentsPage'),
        },
      ],
    },
  },

  navigation: {
    module: 'Navigation',
    options: {
      items: [
        $ref('schedulePageNavigationItem'),
        $ref('allConsultationsPageNavigationItem'),
        $ref('adminPortalPageNavigationItem'),
        '---DIVIDER---',
        $ref('releaseNotes'),
      ],
    },
  },

  header: {
    module: 'Header',
    reactElement: true,
    options: {
      scheduleEnabled: true,
      clinicianRatingEnabled: true,
      languageSelectorEnabled: false,
      telusLogo: false,
      ConsultationScheduleItem: $ref('headerScheduleItem'),
      consultationContext: $ref('consultationContext'),
    },
  },

  headerScheduleItem: {
    module: 'ConsultationScheduleItemPlugin',
    options: {
      hideIncompleteConsultationNotifications: true,
    },
  },

  releaseNotes: {
    module: 'ReleaseNotesPlugin',
    options: {
      releaseNoteCountryCode: 'us',
    },
    reactElement: true,
  },

  schedulePage: {
    module: 'SchedulePage',
    options: {
      ConsultationScheduleItem: $ref('consultationScheduleItem'),
    },
    reactElement: true,
  },

  consultationScheduleItem: {
    module: 'ConsultationScheduleItemPlugin',
    options: {
      hideIncompleteConsultationNotifications: true,
      CheckIn: $ref('consultationScheduleItemAppointmentCheckIn'),
    },
  },

  consultationScheduleItemAppointmentCheckIn: {
    module: 'ScheduleCheckInPlugin',
    options: {
      displayLocation: false,
      displayDetailedError: false,
    },
  },

  schedulePageNavigationItem: {
    module: 'SchedulePageNavigationItem',
    options: {
      path: '/consultations',
    },
    reactElement: true,
  },

  allConsultationsPage: {
    module: 'AllConsultationsPage',
    reactElement: true,
  },

  allConsultationsPageNavigationItem: {
    module: 'AllConsultationsPageNavigationItem',
    options: {
      path: '/all-consultations',
    },
    reactElement: true,
  },

  adminPortalPage: {
    module: 'AdminPortalPage',
    reactElement: true,
  },

  adminPortalPageNavigationItem: {
    module: 'AdminPortalPageNavigationItem',
    options: {
      path: '/admin/consultants',
    },
    reactElement: true,
  },

  experimentsPage: {
    module: 'ExperimentsPage',
    reactElement: true,
  },

  consultationPage: {
    module: 'ConsultationLitePage',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      main: [$ref('preparationSectionGroup'), $ref('actionsSectionGroup')],
      call: $ref('call'),
      context: $ref('consultationProfile'),
      review: $ref('currentConsultationSummary'),
      actions: $ref('consultationActionsPlugin'),
      completeDialog: $ref('completeDialogPlugin'),
      postConsultationFormModal: $ref('postConsultationFormModal'),
      noteAssistantSummary: $ref('noteAssistantSummary'),
      eventBus: $ref('eventBus'),
      providers: [$ref('callStatusReducerModelProvider')],
      usEhrDeepLinkMobile: $ref('usEhrDeepLinkMobile'),
    },
  },

  consultationProfile: {
    module: 'ConsultationProfilePlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      requestedTranslationEnabled: true,
      checkIn: $ref('consultationProfileAppointmentCheckIn'),
      usEhrDeepLink: $ref('usEhrDeepLink'),
      patientRecordDeepLink: $ref('patientRecordDeepLink'),
    },
  },

  consultationProfileAppointmentCheckIn: {
    module: 'ConsultationCheckInPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      displayLocation: true,
      displayDetailedError: true,
    },
  },

  usEhrDeepLink: {
    module: 'USEhrDeepLinkPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      isMobileHeadingButton: false,
    },
  },

  usEhrDeepLinkMobile: {
    module: 'USEhrDeepLinkPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      isMobileHeadingButton: true,
    },
  },

  patientRecordDeepLink: {
    module: 'PatientRecordDeepLinkPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  noteAssistantSummary: {
    module: 'NoteAssistantSummaryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
    },
  },

  ehrCheckIn: {
    module: 'EhrCheckInUsPlugin',
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      apolloClient: $ref('apolloClient'),
    },
  },

  callStatusReducerModelProvider: {
    module: 'CallStatusReducerModelProvider',
    options: {
      callMedium: 'voice_and_video',
    },
  },

  call: {
    module: 'CallPlugin',
    reactElement: true,
    options: {
      callStatusReducerModelProvider: $ref('callStatusReducerModelProvider'),
      consultationContext: $ref('consultationContext'),
      callQualityFeedbackEnabled: true,
      callMedium: 'voice_and_video',
      recordingEnabled: false,
      callRecording: $ref('callRecording'),
      eventBus: $ref('eventBus'),
    },
  },

  callRecording: {
    module: 'CallRecordingPlugin',
    reactElement: true,
    options: {
      eventBus: $ref('eventBus'),
      consultationContext: $ref('consultationContext'),
    },
  },

  preparationSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.preparation_section_group_title,
      titleColor: 'var(--brand-color-purple)',
      borderColor: 'var(--brand-color-purple)',
      children: [
        $ref('bookingReason'),
        $ref('patientTimeline'),
        $ref('patientDetails'),
      ],
    },
  },

  actionsSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.actions_section_group_title,
      titleColor: 'var(--brand-color-orange)',
      borderColor: 'var(--brand-color-orange)',
      children: [$ref('appointmentInvites')],
    },
  },

  postConsultationFormModal: {
    module: 'PostConsultationFormModal',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  patientTimelineConsultationEvent: {
    module: 'TimelineConsultationEventPlugin',
    options: {
      includeActions: false,
    },
  },

  patientTimeline: {
    module: 'PatientTimelinePlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      consultationSummary: $ref('pastConsultationSummary'),
      timelineConsultationEvent: $ref('patientTimelineConsultationEvent'),
      eventTypes: {
        consultationsEnabled: true,
        chatscriptConversationsEnabled: true,
      },
      enableFutureConsultations: true,
      disablePastConsultations: true,
    },
  },

  patientDetails: {
    module: 'PatientDetailsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      hideIdVerificationEnabled: true,
    },
  },

  markAsSensitiveModel: {
    module: 'MarkAsSensitiveModel',
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  markAsSensitivePlugin: {
    module: 'MarkAsSensitivePlugin',
    reactElement: true,
    options: {
      markAsSensitiveModel: $ref('markAsSensitiveModel'),
    },
  },

  usEhrNotesCompletionModel: {
    module: 'UsEhrNotesCompletionModel',
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  currentConsultationSummary: {
    module: 'CurrentConsultationSummaryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      childComponents: [
        $ref('usEhrSummary'),
        $ref('appointmentInvitesSummary'),
      ],
      light: true,
    },
  },

  usEhrSummary: {
    module: 'USEhrSummaryPlugin',
  },

  appointmentInvitesSummary: {
    module: 'AppointmentInvitesSummaryPlugin',
    options: {
      enableOnlyForGlobalInvites: false,
    },
  },

  pastConsultationSummary: {
    module: 'PastConsultationSummaryPlugin',
    options: {
      childComponents: [$ref('bookingReasonSummary')],
    },
  },

  bookingReasonSummary: {
    module: 'BookingReasonSummaryPlugin',
  },

  completeConsultationModel: {
    module: 'CompleteConsultationModel',
    options: {
      consultationContext: $ref('consultationContext'),
      extensions: [
        $ref('markAsSensitiveModel'),
        $ref('usEhrNotesCompletionModel'),
      ],
      redirectUrl: '/consultation/:consultationId/review/form',
    },
  },

  callActiveModalPlugin: {
    module: 'CallActiveModalPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      redirectUrl: '/consultation/:id/review',
      stayOnPageUrl: '/consultation/:id',
    },
  },

  consultationActionsPlugin: {
    module: 'ConsultationActionsPlugin',
    reactElement: true,
    options: {
      reviewEnabled: true,
      completeConsultationModel: $ref('completeConsultationModel'),
      consultationContext: $ref('consultationContext'),
      callStatusReducerModelProvider: $ref('callStatusReducerModelProvider'),
      callActiveModal: $ref('callActiveModalPlugin'),
    },
  },

  completeDialogPlugin: {
    module: 'CompleteDialogPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      completeConsultationModel: $ref('completeConsultationModel'),
      extensions: [$ref('markAsSensitivePlugin')],
    },
  },

  bookingReason: {
    module: 'BookingReasonPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  appointmentInvites: {
    module: 'AppointmentInvitesPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },
}

export default config
