import { defineMessages } from 'react-intl'

const baseId = 'user_authz_error'

export default defineMessages({
  not_allowed: {
    id: `${baseId}.not_allowed_to_use_cp`,
    defaultMessage: 'You are not allowed to use the Clinical Portal',
  },
  please_use: {
    id: `${baseId}.please_use`,
    defaultMessage: 'Please use {portalToUse} ',
  },
  admin_portal: {
    id: `${baseId}.admin_portal`,
    defaultMessage: 'Admin Portal',
  },
  admin_portal_button_label: {
    id: `${baseId}.admin_portal_button_label`,
    defaultMessage: 'Go to Admin Portal',
  },
  partner_clinical_portal: {
    id: `${baseId}.partner_clinical_portal`,
    defaultMessage: 'Partner Clinical Portal',
  },
  partner_clinical_portal_button_label: {
    id: `${baseId}.partner_clinical_portal_button_label`,
    defaultMessage: 'Go to Partner Clinical Portal',
  },
  log_out: {
    id: `${baseId}.log_out`,
    defaultMessage: 'Log out',
  },
})
