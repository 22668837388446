import { sanitizeUrl } from '@braintree/sanitize-url'
import React from 'react'

import { Button } from '@babylon/core-ui'

import { logout } from '~/core/auth'
import { useGlobals } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'

import messages from './ErrorPage.messages'

type ButtonProps = React.ComponentProps<typeof Button>

export const SupportButton = ({ href, ...rest }: ButtonProps) => {
  const f = useMessages(messages)
  const itServiceDeskUrl = useGlobals()?.support?.itServiceDeskUrl

  const supportLink = href || itServiceDeskUrl

  if (!supportLink) {
    return null
  }

  return (
    <Button
      href={sanitizeUrl(supportLink)}
      target="_blank"
      intent="primary"
      {...rest}
    >
      {f('support_button')}
    </Button>
  )
}

export const LogoutButton = (props: ButtonProps) => {
  const f = useMessages(messages)

  return (
    <Button onClick={() => logout()} intent="secondary" {...props}>
      {f('logout_button')}
    </Button>
  )
}
