import { useConsultantUser } from '@babylon/babylon-user'

import { useFeatureFlags } from '~/core/core-modules'
import { AppointmentBookingStatus } from '~/generated'

export const consultationIsFinalized = (
  status: AppointmentBookingStatus | undefined
) => {
  switch (status) {
    case AppointmentBookingStatus.Cancelled:
    case AppointmentBookingStatus.Completed:
    case AppointmentBookingStatus.NoShow:
      return true
    default:
      return false
  }
}

export const useRestrictConsultationEditing = (
  consultationStatus: string | undefined
): boolean => {
  const user = useConsultantUser()
  const { readOnlyCompleteConsultationActionsEnabled } = useFeatureFlags()
  return (
    !user.superConsultant &&
    !!readOnlyCompleteConsultationActionsEnabled &&
    consultationIsFinalized(consultationStatus as AppointmentBookingStatus)
  )
}
