import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscriber,
  TeardownLogic,
} from 'rxjs'

export const observable = <T>(
  subscribe: (this: Observable<T>, subscriber: Subscriber<T>) => TeardownLogic
) => new Observable(subscribe)

export const subject = <T>() => new Subject<T>()

export const behaviorSubject = <T>(value: T) => new BehaviorSubject<T>(value)

export const replaySubject = <T>(bufferSize?: number, windowTime?: number) =>
  new ReplaySubject<T>(bufferSize, windowTime)
