import { differenceInMinutes } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'

import { EventBusInterface } from '~/core/config/modules/generated/types'
import { useInterval } from '~/core/hooks'
import { ConsultationStatusV2 } from '~/generated'

import AppointmentCheckInView from './AppointmentCheckInView'
import useAppointmentCheckInQuery, {
  CHECK_IN_REFRESH_TIMEOUT,
} from './useAppointmentCheckInQuery'

interface AppointmentCheckInContainerProps {
  consultationId: string
  scheduledTime: string
  consultationStatus: ConsultationStatusV2
  displayLocation?: boolean
  displayDetailedError?: boolean
  eventBus?: ReturnType<EventBusInterface>
  className?: string
}

const RECENT_CHECK_IN_LIMIT = 15 // 15 minutes

const isRecentCheckIn = (checkInDate: Date | null) => {
  return checkInDate
    ? differenceInMinutes(new Date(), checkInDate) < RECENT_CHECK_IN_LIMIT
    : false
}

const AppointmentCheckInContainer = ({
  consultationId,
  scheduledTime,
  consultationStatus,
  displayLocation,
  displayDetailedError,
  eventBus,
  className,
}: AppointmentCheckInContainerProps) => {
  const { checkIn, error } = useAppointmentCheckInQuery({
    consultationId,
    scheduledTime,
    consultationStatus,
  })
  const checkedInAt = checkIn?.checkedInAt

  const checkInDate = useMemo(
    () => (checkedInAt ? new Date(checkedInAt) : null),
    [checkedInAt]
  )

  const [recentCheckIn, setRecentCheckin] = useState<boolean>(() =>
    isRecentCheckIn(checkInDate)
  )

  useInterval(() => {
    setRecentCheckin(isRecentCheckIn(checkInDate))
  }, CHECK_IN_REFRESH_TIMEOUT)

  useEffect(() => {
    setRecentCheckin(isRecentCheckIn(checkInDate))
  }, [checkInDate])

  useEffect(() => {
    if (eventBus && (checkInDate || error)) {
      eventBus.emit('DISPLAY_PATIENT_CHECK_IN')
    }

    if (eventBus && !checkInDate && !error) {
      eventBus.emit('HIDE_PATIENT_CHECK_IN')
    }
  }, [eventBus, checkInDate, error])

  return (
    <AppointmentCheckInView
      checkIn={checkIn}
      checkInDate={checkInDate}
      isRecentCheckIn={recentCheckIn}
      consultationId={consultationId}
      consultationStatus={consultationStatus}
      error={error}
      displayLocation={displayLocation}
      displayDetailedError={displayDetailedError}
      className={className}
    />
  )
}

export default AppointmentCheckInContainer
