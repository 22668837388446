import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { ErrorPanelView } from '~/ui/ErrorPanel'

import messages from './FallbackErrorComponent.messages'

interface FallbackErrorComponentProps {
  errorId?: string
}

const reload = () => window.location.reload()

const FallbackErrorComponent = ({ errorId }: FallbackErrorComponentProps) => {
  const fm = useFormatMessage()

  return (
    <ErrorPanelView
      center
      fill="container"
      title={fm(messages.fallback_error_message)}
      retry={reload}
      errorId={errorId}
      errorCode="BOUNDARY_ERROR"
    />
  )
}

export default FallbackErrorComponent
