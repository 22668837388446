import { useEffect } from 'react'

const useClickOutsideElement = (ref: any, fn: Function) => {
  useEffect(() => {
    const eventListener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      fn(event)
    }

    document.addEventListener('click', eventListener)

    return () => {
      document.removeEventListener('click', eventListener)
    }
  }, [ref, fn])
}

export default useClickOutsideElement
