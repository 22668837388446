import { Block, Inline, Text, Value } from 'slate'

import { ConsultationNoteCode } from '~/generated'

import { CLINICAL_CODE } from '../../plugins/ClinicalCodes'
import { HIGHLIGHT } from '../../plugins/Highlight'

export type BabylonSerializerOutput = [string, Array<ClinicalCode>]

interface ClinicalCode extends ConsultationNoteCode {
  hasNotes?: boolean
}

type InterimSerializedParagraph = {
  text: string
  codes: Array<ClinicalCode>
}

const delimiter: string = '\n'

const isClinicalCode = (node: Block | Inline) => {
  return node.object === 'inline' && node.type === CLINICAL_CODE
}

const isHighlight = (node: Block | Inline) => {
  return node.object === 'inline' && node.type === HIGHLIGHT
}

const serializeParagraph = (withNotes: boolean) => (
  paragraph: Block
): InterimSerializedParagraph => {
  let text: string = ''
  const codes: Array<ClinicalCode> = []

  paragraph.nodes.forEach((node?: Block | Text | Inline) => {
    if (!node) {
      return
    }

    if (node.object === 'text' || isHighlight(node)) {
      text += node.text
      return
    }
    const notesObject = withNotes ? { hasNotes: node.data.get('hasNotes') } : {}
    if (isClinicalCode(node)) {
      codes.push({
        code: node.data.get('code'),
        term: node.data.get('term'),
        offset: text.length,
        length: node.text.length,
        ...notesObject,
      })

      text += node.text
    }
  })

  return { text, codes }
}

const joinNormalizedParagraphs = (
  nodes: Array<InterimSerializedParagraph>
): BabylonSerializerOutput => {
  return [
    nodes.map((v) => v.text).join(delimiter),
    nodes.map((v) => v.codes).flat(),
  ]
}

const repairClinicalCodeOffsets = (
  paragraphs: Array<InterimSerializedParagraph>
) => {
  let characterCount = 0

  return paragraphs.map((paragraph: InterimSerializedParagraph) => {
    const codes = paragraph.codes.map((code: ClinicalCode) => {
      return {
        ...code,
        offset: (code.offset || 0) + characterCount,
      }
    })

    const normalizedParagraph = {
      ...paragraph,
      codes,
    }

    characterCount += paragraph.text.length

    return normalizedParagraph
  })
}

/**
 * Serialize a Slate `value` to Babylon [text, codes]
 */
const serialize = (
  value: Value,
  withNotes: boolean
): BabylonSerializerOutput => {
  if (!value || !value.document) {
    throw new Error('Serialize argument is not a Document')
  }

  const paragraphs = value.document.nodes.toArray() as Array<Block>

  const interimSerializedParagraphs: Array<InterimSerializedParagraph> = paragraphs.map(
    serializeParagraph(withNotes)
  )

  return joinNormalizedParagraphs(
    repairClinicalCodeOffsets(interimSerializedParagraphs)
  )
}

export default serialize
