import { defineMessages } from 'react-intl'

const baseId = 'dashboard.links_and_resouces_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Links and resources',
  },
  babylon_links_title: {
    id: `${baseId}.babylon_links_v2`,
    defaultMessage: 'Babylon links',
  },
  useful_resources_title: {
    id: `${baseId}.useful_resources_v2`,
    defaultMessage: 'Useful resources',
  },
  no_links: {
    id: `${baseId}.no_links`,
    defaultMessage: 'There are no links',
  },
  babylon_links_error_title: {
    id: `${baseId}.babylon_links_error_title`,
    defaultMessage: 'Error on loading the Babylon links',
  },
  babylon_links_error_description: {
    id: `${baseId}.babylon_links_error_description`,
    defaultMessage:
      'We can not load the Babylon links at the moment, please try again later',
  },
  resources_error_title: {
    id: `${baseId}.resources_error_title`,
    defaultMessage: 'Error on loading the useful resources',
  },
  resources_error_description: {
    id: `${baseId}.resources_error_description`,
    defaultMessage:
      'We can not load the useful resources at the moment, please try again later',
  },
})
