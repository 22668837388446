// This function checks whether the patient details babylon has stored
// and the patient details the lab has returned match.
// It returns a boolean, "matched" and an array, "errors".

export default (babylonPatientDetails, labPatientDetails = {}, fields) => {
  let errors = []
  let matched = true
  fields.forEach((field) => {
    if (
      String(babylonPatientDetails[field]).toLowerCase() !==
      String(labPatientDetails[field]).toLowerCase()
    ) {
      errors.push({
        key: field,
        message: `The babylon patient’s ${field} is "${babylonPatientDetails[field]}" but the lab report says "${labPatientDetails[field]}".`,
      })
      matched = false
    }
  })

  return { matched, errors }
}
