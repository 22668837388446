import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { UpdateAppointmentMutation, UpdateGpDetailsMutation } from './mutations'
import { ConsultationGpDetailsQuery } from './queries'

export default compose(
  graphql(ConsultationGpDetailsQuery, {
    options: ({ match }) => {
      return {
        variables: {
          consultationId: match.params.consultationId,
        },
      }
    },
  }),
  graphql(UpdateGpDetailsMutation, {
    name: 'updateGpMutation',
  }),
  graphql(UpdateAppointmentMutation, {
    name: 'updateAppointmentMutation',
  })
)
