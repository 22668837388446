import { take } from 'rxjs/operators'

import { isFilledArray } from '~/core'
import { MandatoryDiagnosticCodesModelInterface } from '~/core/config/modules/generated/types'
import { observableState, replaySubject } from '~/core/reactive-helpers'
import { logException } from '~/core/sentry'
import {
  CompleteConsultationExtensionType,
  ExtensionStateType,
  ExtensionStatus,
} from '~/features/complete-consultation'

import messages from './MandatoryDiagnosticCodesModel.messages'

export interface MandatoryDiagnosticCodesModelType
  extends CompleteConsultationExtensionType {
  // extend type here
}

const defaultState: ExtensionStateType = {
  status: ExtensionStatus.Initializing,
  errorMessage: undefined,
}

export const createMandatoryDiagnosticCodesModel = (): MandatoryDiagnosticCodesModelInterface => ({
  // TODO:  convert it to a model
  consultationContext,
}) => {
  const [state, updateState, resetState] = observableState<ExtensionStateType>(
    defaultState
  )
  const values = replaySubject<{
    enableMandatoryDiagnosisCode: boolean
    hasCodes: boolean
  }>(1)

  const reset = () => {
    resetState()
    values.next({
      enableMandatoryDiagnosisCode: false,
      hasCodes: false,
    })
  }

  consultationContext.subscribe((result) => {
    const { consultation } = result.data

    if (consultation) {
      const permissions = consultation?.permissions ?? []
      const enableMandatoryDiagnosisCode =
        permissions.find(
          (permission) => permission.name === 'enable_mandatory_diagnosis_code'
        )?.value ?? false
      const codes = consultation?.consultationNote?.assessmentCodes
      const hasCodes = isFilledArray(codes)
      values.next({ enableMandatoryDiagnosisCode, hasCodes })
    } else {
      reset()
    }
  })

  const init = () => {
    updateState(defaultState)
    values.pipe(take(1)).subscribe({
      next: ({ enableMandatoryDiagnosisCode, hasCodes }) => {
        if (enableMandatoryDiagnosisCode && !hasCodes) {
          updateState({
            status: ExtensionStatus.InitError,
            errorMessage: messages.error_message,
          })
        } else {
          updateState({
            status: ExtensionStatus.Initialized,
            errorMessage: undefined,
          })
        }
      },
      error: (error) => {
        logException(error)
        updateState({
          status: ExtensionStatus.InitError,
          errorMessage: messages.error_message,
        })
      },
    })
  }

  const submit = () => {
    updateState({
      status: ExtensionStatus.Submitted,
      errorMessage: undefined,
    })
  }

  return { state, init, submit }
}

export const MandatoryDiagnosticCodesModel = createMandatoryDiagnosticCodesModel()
