import React from 'react'

import { Button } from '@babylon/core-ui'

import { ADMIN_PORTAL_URL, CLINICAL_PORTAL_PARTNER_URL } from '~/constants'
import { useMessages } from '~/core/hooks'
import ErrorPage from '~/ui/ErrorPage'

import { LogoutButton } from '../ErrorPage/Buttons'

import messages from './UserAuthorizationError.messages'

type PORTAL = 'admin_portal' | 'partner_clinical_portal'

const redirectProps: Record<
  PORTAL,
  { url: string | undefined; label: keyof typeof messages }
> = {
  admin_portal: {
    url: `${ADMIN_PORTAL_URL}/dashboard`,
    label: 'admin_portal_button_label',
  },
  partner_clinical_portal: {
    url: CLINICAL_PORTAL_PARTNER_URL,
    label: 'partner_clinical_portal_button_label',
  },
}

interface UserAuthorizationErrorProps {
  redirectTo: PORTAL
}

const UserAuthorizationError = ({
  redirectTo,
}: UserAuthorizationErrorProps) => {
  const f = useMessages(messages)
  const { url, label } = redirectProps[redirectTo]

  return (
    <ErrorPage
      title={f('not_allowed')}
      message={f('please_use', { portalToUse: f(redirectTo) })}
      renderButtons={() => (
        <>
          <Button href={url} intent="primary">
            {f(label)}
          </Button>
          <LogoutButton />
        </>
      )}
    />
  )
}

export default UserAuthorizationError
