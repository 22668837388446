import React from 'react'

import { useConsultation } from '~/core/config'
import { ConsultationCheckInPluginInterface } from '~/core/config/modules/generated/types'

import AppointmentCheckInContainer from './AppointmentCheckInContainer'

import styles from './styles.module.scss'

const ConsultationCheckInModule: ConsultationCheckInPluginInterface = ({
  consultationContext,
  eventBus,
  displayLocation,
  displayDetailedError,
}) => {
  const consultation = useConsultation(consultationContext)
  const {
    id: consultationId,
    scheduledTime,
    statusV2: consultationStatus,
  } = consultation

  return (
    <AppointmentCheckInContainer
      consultationId={consultationId}
      scheduledTime={scheduledTime}
      consultationStatus={consultationStatus}
      displayLocation={displayLocation}
      displayDetailedError={displayDetailedError}
      eventBus={eventBus}
      className={styles.consultationCheckIn}
    />
  )
}

export default ConsultationCheckInModule
