import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { Button } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { usePermissions } from '~/core/permissions'

import messages from './messages'
import styles from './styles.module.scss'

const drugTableClassName = (isDraft) =>
  classnames(styles.drugTable, isDraft && styles.editable)
const getDrugName = ({ drugName, packagingSize }) =>
  drugName + (packagingSize ? ` - ${packagingSize}` : '')

const DrugTable = ({
  drugs = [],
  isDraft,
  onDrugEdit,
  onDrugRemove,
  isEditRestricted,
}) => {
  const [isRepeatEnabled] = usePermissions('enable_repeat_prescriptions')
  const intl = useIntl()
  const fm = (t) => intl.formatMessage(t)
  return (
    <table className={drugTableClassName(isDraft)}>
      <thead>
        <tr className={styles.drugTableHeaderRow}>
          <th>{fm(messages.headerDrug)}</th>
          <th className={styles.quantity}>{fm(messages.headerQuantity)}</th>
          <th>{fm(messages.headerDirections)}</th>
          {isRepeatEnabled && <th>{fm(messages.headerRepeatable)}</th>}
          {isDraft && <th className={styles.headerActions} />}
        </tr>
      </thead>
      <tbody>
        {drugs.map((drug) => (
          <tr
            data-testid="drugs-table-row"
            className={styles.drugTableRow}
            key={drug.prescriptionDrugId}
          >
            <td>{getDrugName(drug)}</td>
            <td>{drug.quantity}</td>
            <td>{drug.directions}</td>
            {isRepeatEnabled &&
              (drug.repeatable ? (
                <td>
                  <div>
                    {' '}
                    {fm(messages.labelRepeats)} {drug.repeats}
                  </div>
                  <div>
                    {fm(messages.reviewDate)}{' '}
                    {intl.formatDate(drug.reviewDate, { format: 'short' })}
                  </div>
                </td>
              ) : (
                <td>N/A</td>
              ))}
            {isDraft && (
              <td className={styles.actions}>
                <Button
                  inline
                  intent="secondary"
                  disabled={isEditRestricted}
                  onClick={() => onDrugEdit(drug)}
                >
                  {fm(messages.buttonEdit)}
                </Button>
                <Button
                  inline
                  intent="secondary"
                  onClick={() => onDrugRemove(drug)}
                >
                  {fm(messages.buttonRemove)}
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

DrugTable.defaultProps = {
  drugs: [],
  isDraft: true,
}

DrugTable.propTypes = {
  onDrugEdit: PropTypes.func.isRequired,
  onDrugRemove: PropTypes.func.isRequired,
}

export default injectIntl(DrugTable)
