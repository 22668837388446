import { useState } from 'react'

import { ConsultationContextType, useConsultation } from '~/core/config'
import { EditedConsultationWarningModelProviderInterface } from '~/core/config/modules/generated/types'
import { ModelProvider, useModelProvider } from '~/core/container/provider'
import { useFeatureFlags } from '~/core/core-modules'
import { consultationIsFinalized } from '~/features/consultation/utils/useRestrictConsultationEditing'
import { AppointmentBookingStatus } from '~/generated'

export interface EditedConsultationWarningModel {
  markFinalizedConsultationAsEdited: () => void
  markFinalizedConsultationAsSaved: () => void
  showEditedConsultationWarning: boolean
}

interface useEditedConsultationWarningModelOptions {
  consultationContext: ConsultationContextType
}

export type EditedConsultationWarningModelProviderType = ModelProvider<EditedConsultationWarningModel>

export const useEditedConsultationWarningModel = ({
  consultationContext,
}: useEditedConsultationWarningModelOptions): EditedConsultationWarningModel => {
  const { editedFinalizedConsultationWarningEnabled } = useFeatureFlags()
  const consultation = useConsultation(consultationContext)

  const [
    consultationHasUnsavedChanges,
    setConsultationHasUnsavedChanges,
  ] = useState<EditedConsultationWarningModel['showEditedConsultationWarning']>(
    false
  )

  const markFinalizedConsultationAsEdited = () =>
    setConsultationHasUnsavedChanges(true)

  const markFinalizedConsultationAsSaved = () =>
    setConsultationHasUnsavedChanges(false)

  const showEditedConsultationWarning =
    !!editedFinalizedConsultationWarningEnabled &&
    consultationIsFinalized(consultation.status as AppointmentBookingStatus) &&
    consultationHasUnsavedChanges

  return {
    markFinalizedConsultationAsEdited,
    markFinalizedConsultationAsSaved,
    showEditedConsultationWarning,
  }
}

const EditedConsultationWarningModelProvider: EditedConsultationWarningModelProviderInterface = ({
  consultationContext,
}) => {
  return useModelProvider<
    EditedConsultationWarningModel,
    useEditedConsultationWarningModelOptions
  >(useEditedConsultationWarningModel, { consultationContext })
}

export default EditedConsultationWarningModelProvider
