import { defineMessages } from 'react-intl'

const accessRequestBaseId = 'consultation.access_request_signal'
const videoCallBaseId = 'consultation.video_call'

export default defineMessages({
  body: {
    id: `${accessRequestBaseId}.body`,
    defaultMessage: 'wants to join the call as a caregiver.',
  },
  accept: {
    id: `${accessRequestBaseId}.accept_button`,
    defaultMessage: 'Accept',
  },
  decline: {
    id: `${accessRequestBaseId}.decline_button`,
    defaultMessage: 'Decline',
  },
  clinician_stream_label: {
    id: `${videoCallBaseId}.clinician_stream_label`,
    defaultMessage: 'You',
  },
})
