import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Heading, Tag, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ReactComponent as Heart } from '~/assets/heart_logo.svg'

import messages from '../PatientSummary.messages'
import styles from '../styles.module.scss'

interface PatientSummaryInfoProps {
  summaryContainsMyConsultation: boolean
  problemListLength: number
}

const PatientSummaryInfo = ({
  summaryContainsMyConsultation,
  problemListLength,
}: PatientSummaryInfoProps) => {
  const fm = useFormatMessage()

  return (
    <div>
      {summaryContainsMyConsultation && (
        <div className={styles.summaryText}>
          <Text color="grey-type">
            <FormattedMessage
              {...messages.summary_message}
              values={{
                tag: (
                  <Tag color="highlight-violet" margin={false}>
                    {fm(messages.my_consultation)}
                  </Tag>
                ),
              }}
            />
          </Text>
        </div>
      )}
      <Heading level="h3" margin>
        {fm(messages.problems_list)} {`(${problemListLength})`}
      </Heading>
      <Text tag="div" className={styles.fromBabylonText} color="grey-type">
        {fm(messages.from_babylon)}
        <Heart style={{ paddingLeft: '5px' }} />
      </Text>
    </div>
  )
}

export default PatientSummaryInfo
