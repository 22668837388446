import { useEffect, useState } from 'react'

import { logException } from '.'

const useLogException = (error: any) => {
  const [sentryId, setSentryId] = useState<string>()

  useEffect(() => {
    const currentSentryId = logException(error)
    setSentryId(currentSentryId)
  }, [error])

  return sentryId
}

export default useLogException
