import cn from 'classnames'
import React from 'react'

import { Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { ReactComponent as IncompleteIcon } from '~/assets/incomplete_cons.svg'
import TimelineConsultationTitle, {
  ConsultantMetaIdentity,
} from '~/features/patient-timeline/Timeline/components/TimelineConsultationTitle'
import { isEventInFuture } from '~/features/patient-timeline/Timeline/utils'

import styles from './styles.module.scss'

interface PastConsultationSummaryModalTitleProps {
  status?: string | null
  consultant: ConsultantMetaIdentity | undefined | null
  currentConsultant: ConsultantMetaIdentity
  scheduledTime?: string
  className?: string
}

const PastConsultationSummaryModalTitle = ({
  status,
  consultant,
  currentConsultant,
  scheduledTime,
  className,
}: PastConsultationSummaryModalTitleProps) => {
  const intl = useIntl()
  const isFutureEvent = isEventInFuture(scheduledTime as string)
  const isIncomplete = status === 'paid'
  const isNoShow = status === 'no_show'

  return (
    <div className={cn(styles.wrapper, className)}>
      {isIncomplete && <IncompleteIcon className={styles.incompleteIcon} />}
      <TimelineConsultationTitle
        consultant={consultant}
        currentConsultant={currentConsultant}
        isIncomplete={isIncomplete}
        isNoShow={isNoShow}
        isFutureEvent={isFutureEvent}
      />
      <Text
        size="medium"
        className={styles.date}
        data-testid="consultation-date"
      >
        {scheduledTime &&
          intl.formatDate(scheduledTime, { format: 'shortWithTime' })}
      </Text>
    </div>
  )
}

export default PastConsultationSummaryModalTitle
