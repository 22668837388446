import cn from 'classnames'
import React, { CSSProperties, ReactElement } from 'react'

import Icon from '~/ui/Icon'
import Tooltip from '~/ui/Tooltip'

import styles from './RoundButton.module.scss'

interface RoundButtonProps {
  icon: string
  title?: string
  tooltipMessage?: string
  overlay?: ReactElement
  overlayStyle?: CSSProperties
  onClick?: () => void
  disabled?: boolean
  testId?: string
  className?: string
  size?: number
  tooltipPlacement?: string
}

const RoundButton = ({
  icon,
  title,
  tooltipMessage,
  onClick,
  disabled,
  className,
  testId,
  size = 20,
  tooltipPlacement,
  overlay,
  overlayStyle,
}: RoundButtonProps) => {
  return (
    <Tooltip
      transitionName="tooltip-fade"
      mouseEnterDelay={0.05}
      mouseLeaveDelay={0}
      trigger={['hover']}
      message={tooltipMessage}
      overlay={overlay}
      hidden={!tooltipMessage}
      placement={tooltipPlacement}
      overlayStyle={overlayStyle}
    >
      {/* This span is for the tooltip to work while the button is disabled,
      see https://github.com/react-component/tooltip/issues/18 */}
      <span style={{ display: 'inline-block' }}>
        <button
          type="button"
          onClick={onClick}
          data-testid={testId}
          title={title}
          disabled={disabled}
          className={cn(styles.roundButton, className)}
          style={{ fontSize: size }}
        >
          <Icon name={icon} />
        </button>
      </span>
    </Tooltip>
  )
}

export default RoundButton
