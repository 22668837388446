import { useMemo } from 'react'

import { COUNTRY_CODE } from '~/constants'
import { LinksAndResourcesQuery, useLinksAndResourcesQuery } from '~/generated'

export type LinksAndResourcesItem = LinksAndResourcesQuery['cp3LinksAndResources'][number]

type MappedResults = {
  links: LinksAndResourcesItem[]
  resources: LinksAndResourcesItem[]
}

export const mapData = (results: LinksAndResourcesItem[]): MappedResults => {
  return results.reduce(
    (acc: MappedResults, result: LinksAndResourcesItem) => {
      if (result.dashboardLinkTypes.name === 'Babylon Link') {
        acc.links.push(result)
      }

      if (result.dashboardLinkTypes.name === 'Useful Resource') {
        acc.resources.push(result)
      }
      return acc
    },
    {
      links: [],
      resources: [],
    }
  )
}

export const useLinksAndResources = (countryCode?: string) => {
  const { data, loading, error } = useLinksAndResourcesQuery({
    variables: {
      countryCode: countryCode || COUNTRY_CODE,
    },
  })

  const linksAndResourcesItems = useMemo(() => {
    const linksAndResources = data?.cp3LinksAndResources ?? []

    return mapData(linksAndResources)
  }, [data])

  return { data: linksAndResourcesItems, loading, error }
}
