import { sanitizeUrl } from '@braintree/sanitize-url'
import React from 'react'
import cn from 'classnames'

import './Anchor.scss'

interface AnchorProps {
  href?: string
  target?: string
  component?: string | React.ReactType
  className?: string
  rel?: string
  onClick?: Function
}

const Anchor: React.FC<AnchorProps> = ({
  href: unsafeHref,
  target,
  component: Component = 'a',
  className,
  rel = 'noopener',
  onClick,
  children,
}) => {
  const safeRel = unsafeHref && target ? rel || 'noopener' : undefined

  const href = unsafeHref && sanitizeUrl(unsafeHref)
  return (
    <Component
      href={href}
      target={target}
      rel={safeRel}
      className={cn('core-anchor', className)}
      onClick={onClick}
    >
      {children}
    </Component>
  )
}

export default Anchor
