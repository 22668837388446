import { createElement } from 'react'

import { Transform } from './container'

let lastIdx = 0

const ReactTransform: Transform = async (
  next,
  { moduleConfig, getModule, getProviders, moduleContext, getOptions }
) => {
  if (moduleConfig.reactComponent || moduleConfig.reactElement) {
    if (moduleContext.instance) {
      return moduleContext.instance
    }

    const Component = getModule()

    const { children: configChildren, ...configProps } =
      (await getOptions()) || {}

    const key = `module-${lastIdx++}`

    const instance = moduleConfig.reactComponent
      ? ({ children, ...props }: any) =>
          createElement(
            Component,
            { ...configProps, ...props, key },
            children || configChildren
          )
      : createElement(Component, { ...configProps, key }, configChildren)

    const providers = (await getProviders()) || []

    moduleContext.instance = providers
      .reverse()
      .reduce((child: any, { Provider }: any) => {
        return createElement(Provider, null, child)
      }, instance)

    return moduleContext.instance
  }

  return next()
}

export default ReactTransform
