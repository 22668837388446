import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button } from '@babylon/core-ui'

import Popover from './Popover'

import styles from './styles.module.scss'

class Confirm extends Component {
  state = {
    visible: false,
  }

  setVisible = (visible) => {
    this.setState({ visible })
  }

  handleOk = (event) => {
    const { onOk } = this.props
    onOk && onOk(event)
    this.setVisible(false)
  }

  handleCancel = (event) => {
    const { onCancel } = this.props
    onCancel && onCancel(event)
    this.setVisible(false)
  }

  render() {
    const { visible } = this.state
    const { children, message, cancelLabel, okLabel, ...rest } = this.props

    return (
      <Popover
        {...rest}
        className={styles.confirm}
        visible={visible}
        onVisibleChange={this.setVisible}
        content={() => (
          <div>
            <div>{message}</div>
            <div className={styles.confirmButtons}>
              <Button intent="secondary" onClick={this.handleCancel}>
                {cancelLabel}
              </Button>
              <Button intent="secondary" onClick={this.handleOk}>
                {okLabel}
              </Button>
            </div>
          </div>
        )}
      >
        <span onClick={() => this.setVisible(true)}>{children}</span>
      </Popover>
    )
  }
}

Confirm.propTypes = {
  message: PropTypes.string.isRequired,
}

Confirm.defaultProps = {
  cancelLabel: 'Cancel',
  okLabel: 'Ok',
}

export default Confirm
