import cx from 'classnames'
import React from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ChatscriptMessage } from '~/generated'

import messages from './ChatTranscript.messages'
import styles from './styles.module.scss'

interface ChatTranscriptProps {
  conversation: ChatscriptMessage[]
  noBorder?: boolean
}

const ChatTranscript = ({ conversation, noBorder }: ChatTranscriptProps) => {
  const fm = useFormatMessage()

  if (!conversation) {
    return <div>{fm(messages.could_not_get_conversation_message_v2)}</div>
  }

  return (
    <div className={styles.chatConversation}>
      <div className={styles.transcriptContainer}>
        {conversation.map(({ id, sender, value, input }: ChatscriptMessage) => {
          const choices = input?.params?.choices || []

          return (
            <div
              key={id}
              className={cx(styles.message, {
                [styles.me]: sender !== 'babylon',
                [styles.noBorder]: noBorder,
              })}
              data-testid={
                sender !== 'babylon'
                  ? 'pre-patient-message'
                  : 'pre-chatbot-message'
              }
            >
              <pre className={styles.preText}>
                <Text
                  tag="div"
                  color="black-type"
                  bold={sender !== 'babylon'}
                  className={styles.text}
                >
                  {value?.text}
                </Text>
              </pre>

              {choices.length > 1 && (
                <ul className={styles.bulletList}>
                  {choices.map((choice) => (
                    <li key={choice.id}>
                      <Text className={styles.text}>{choice.label}</Text>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ChatTranscript
