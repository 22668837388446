import { gql } from '@apollo/client'

export const PrescriptionAuditQuery = gql`
  query prescriptionAudit($prescriptionId: ID!) {
    prescriptionAudit(
      prescriptionId: $prescriptionId
      filter: ["PRESCRIPTION_VIEWED"]
    ) {
      id
      babylonRequestId
      consumerNetwork
      eventDetails
      eventTimestamp
      loginType
      eventType
      performedByName
    }
  }
`
