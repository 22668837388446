import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'

import { ENVIRONMENT, NOTE_ASSISTANT_SERVICE_URL } from '~/constants'
import { logException } from '~/core/sentry'

const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay))

export const getCookie = () => {
  try {
    return JSON.parse(Cookies.get('autologin_info') || '{}')
  } catch {
    return {}
  }
}

const getAudioUrl = (recordingId: string): string => {
  const noteAssistantUrl =
    ENVIRONMENT === 'localhost'
      ? 'https://services-uk.dev.babylontech.co.uk/note-assistant'
      : NOTE_ASSISTANT_SERVICE_URL

  return `${noteAssistantUrl}/v1/recordings/${recordingId}`
}

export interface PostAudioPayload {
  chunk: Blob
  speakerId: string
  order: number
  timestamp: number
  final: boolean
}

export const logToDevConsole =
  process.env.NODE_ENV === 'development'
    ? // eslint-disable-next-line no-console
      console.log.bind(console)
    : () => null

export const retryRequest = async (
  url: string,
  formData: FormData,
  maxAttempts: number,
  retryDelay: number
) => {
  let error

  for (let i = 0; i < maxAttempts; i++) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await axios.post(url, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Security-Token': getCookie().csrf_token,
        },
      })
      return
    } catch (err) {
      error = err

      // Receiving a 410 Error means the connection with Google Speech to Text has closed and we do not wish to retry in this case.
      if ((err as AxiosError)?.response?.status === 410) {
        return
      }

      // eslint-disable-next-line no-await-in-loop
      await sleep(retryDelay)
    }
  }

  throw error
}

const boolToStr = (bool: boolean): string => (bool ? 'true' : 'false')

const getFormData = ({
  chunk,
  speakerId,
  order,
  timestamp,
  final,
}: PostAudioPayload): FormData => {
  const formData = new FormData()

  formData.append('chunk', chunk)
  formData.append('speaker_id', speakerId)
  formData.append('order', order.toString())
  formData.append('timestamp', timestamp.toString())
  formData.append('final', boolToStr(final))

  return formData
}

export const sendAudioFile = async (
  recordingId: string,
  payload: PostAudioPayload,
  maxAttempts: number,
  retryDelay: number
) => {
  logToDevConsole('send-audio-file-started', payload)

  const url = getAudioUrl(recordingId)
  const formData = getFormData(payload)

  try {
    await retryRequest(url, formData, maxAttempts, retryDelay)

    logToDevConsole('send-audio-file-completed')
  } catch (error) {
    logException(error)

    throw error
  }
}
