import React from 'react'

import { useConsultation } from '~/core/config'
import { SickNotesPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'

import SickNotes from './SickNotes'

export const SickNotesContainer: SickNotesPluginInterface = ({
  consultationContext,
  editedConsultationWarningModel,
}) => {
  const consultation = useConsultation(consultationContext)
  const [canManageSickNotes, canReadAnySickNote] = usePermissions(
    'manage_sick_notes_for_appointment',
    'read_any_sick_note'
  )

  const markFinalizedConsultationAsEdited = editedConsultationWarningModel?.useModelContext()
    .markFinalizedConsultationAsEdited

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    consultation?.status
  )

  if (!canReadAnySickNote) {
    return null
  }

  return (
    <SickNotes
      consultation={consultation}
      disabled={!canManageSickNotes}
      restrictFinalizedConsultationEditing={
        restrictFinalizedConsultationEditing
      }
      markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
    />
  )
}

export default SickNotesContainer
