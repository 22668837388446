import { gql } from '@apollo/client'

export const SICK_NOTES_FRAGMENT = gql`
  fragment CpSickNotes on Consultation {
    id
    sickNotes {
      id
      reason
      startDate
      endDate
    }
  }
`
