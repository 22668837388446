import React, { useState } from 'react'

import { Button, Checkbox, Input, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { usePermissions } from '~/core/permissions'
import Label from '~/ui/Label'
import { ModalContent, ModalFooter, RouteModal } from '~/ui/LegacyModal'
import { Dialog } from '~/ui/Modal'
import ReactSelect from '~/ui/Select'
import Textarea from '~/ui/Textarea'

import { URGENT_VALUE } from './constants'
import { createReferral, truthy, updateReferral } from './utils'

import messages from './ReferralModal.messages'
import styles from './styles.module.scss'

const ReferralModal = ({
  data,
  mode,
  state,
  user,
  query,
  redirectToConsultation,
  handleCommentsChange,
  handleCategoryChange,
  handleSpecialismChange,
  handlePostcodeChange,
  handleSpecialistLookup,
  handleSpecialistChange,
  handleUrgentChange,
  specialismsQuery,
  warnings,
  createReferralMutation,
  createReferralMutationResult: { loading: createReferralLoading },
  updateReferralMutation,
  updateReferralMutationResult: { loading: updateReferralLoading },
  errorAlert,
  withValidState,
  markFinalizedConsultationAsEdited,
}) => {
  const fm = useFormatMessage()
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)
  const [
    enableContinuityOfCareForReferral,
    enableUrgentReferrals,
  ] = usePermissions(
    'enable_continuity_of_care_for_referral',
    'enable_urgent_referrals'
  )
  const categories = specialismsQuery.specialismCategories || []
  const specialisms = state.category ? state.category.specialisms : []

  const isInCreateMode = mode === 'create'
  const loading = createReferralLoading || updateReferralLoading

  const handleSave = () =>
    isInCreateMode
      ? createReferral(
          data,
          state,
          user,
          query,
          withValidState,
          setShowDuplicateDialog,
          createReferralMutation,
          redirectToConsultation,
          errorAlert,
          markFinalizedConsultationAsEdited
        )
      : updateReferral(
          data,
          state,
          user,
          query,
          withValidState,
          updateReferralMutation,
          redirectToConsultation,
          setShowDuplicateDialog,
          errorAlert,
          markFinalizedConsultationAsEdited
        )

  return (
    <RouteModal onRequestClose={redirectToConsultation}>
      {showDuplicateDialog && (
        <Dialog
          title={fm(messages.referral_with_specialism_already_exists)}
          cancel={false}
          onClose={redirectToConsultation}
          onOk={redirectToConsultation}
          okLabel={fm(messages.ok)}
          ok
        >
          <Text>{fm(messages.please_choose_different_specialism)}</Text>
        </Dialog>
      )}
      <ModalContent title={fm(messages.title_private)} warnings={warnings}>
        <Label value={fm(messages.category_label)} dataTestId="category">
          <ReactSelect
            labelKey="name"
            valueKey="id"
            options={categories}
            clearable={false}
            placeholder={fm(messages.category_placeholder)}
            value={state.category}
            onChange={handleCategoryChange}
            dataTestId="referral-category"
          />
        </Label>
        <Label value={fm(messages.specialism_label)} dataTestId="specialism">
          <ReactSelect
            labelKey="name"
            valueKey="id"
            options={specialisms}
            clearable={false}
            placeholder={fm(messages.specialism_placeholder)}
            value={state.specialism}
            onChange={handleSpecialismChange}
            dataTestId="referral-specialism"
          />
        </Label>
        {enableContinuityOfCareForReferral && (
          <>
            <Label value={fm(messages.postcode_label)}>
              <Input
                placeholder={fm(messages.postcode_placeholder)}
                value={state.postcode}
                onChange={handlePostcodeChange}
              />
            </Label>
            {state.specialism && state.category && state.postcode && (
              <Button
                className={styles.lookupButton}
                size="small"
                loading={state.isLookupLoading}
                onClick={handleSpecialistLookup}
              >
                {fm(messages.lookup_button_label)}
              </Button>
            )}
            {truthy(
              state.showSpecialistSelection,
              state.specialism,
              state.category,
              state.postcode
            ) && (
              <>
                <Label value={fm(messages.specialist_label)}>
                  <ReactSelect
                    labelKey="practitionerName"
                    valueKey="practitionerId"
                    options={state.options}
                    clearable={false}
                    placeholder={fm(messages.specialist_placeholder)}
                    value={state.specialist}
                    onChange={handleSpecialistChange}
                  />
                </Label>
                <Label value={fm(messages.comments_label)}>
                  <Textarea
                    value={state.comments}
                    placeholder={fm(messages.comments_add_button_label)}
                    onChange={handleCommentsChange}
                  />
                </Label>
              </>
            )}
          </>
        )}
        {enableUrgentReferrals && (
          <div className={styles.checkBoxWrap}>
            <Checkbox
              name={URGENT_VALUE}
              id={URGENT_VALUE}
              checked={state.urgent}
              value={URGENT_VALUE}
              onChange={handleUrgentChange}
            >
              {fm(messages.option_urgent_label)}
            </Checkbox>
          </div>
        )}
        <ModalFooter>
          <Button inline intent="secondary" onClick={redirectToConsultation}>
            {fm(messages.cancel_button_label)}
          </Button>
          <Button inline onClick={handleSave} loading={loading}>
            {isInCreateMode
              ? fm(messages.create_button_label)
              : fm(messages.update_button_label)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </RouteModal>
  )
}

export default ReferralModal
