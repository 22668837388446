import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { REFERRALS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { isFilledArray } from '~/core'
import { usePermissions } from '~/core/permissions'
import ReferralModal from '~/features/referrals/ReferralModal'
import {
  ReferralsSectionQueryResult,
  SpecialismsQueryResult,
} from '~/generated'
import { Section, withSectionErrorBoundary } from '~/ui/Section'
import Tooltip from '~/ui/Tooltip'

import { ReferralDataList } from './ReferralsDataList'

import messages from './Referrals.messages'

interface ReferralsViewProps {
  referrals: NonNullable<
    NonNullable<ReferralsSectionQueryResult['data']>['consultation']
  >['referrals']
  restrictFinalizedConsultationEditing: boolean
  markFinalizedConsultationAsEdited: (() => void) | undefined
  specialismCategories: NonNullable<
    SpecialismsQueryResult['data']
  >['specialismCategories']
  loading?: boolean
}
const ReferralsView = ({
  referrals,
  restrictFinalizedConsultationEditing,
  markFinalizedConsultationAsEdited,
  specialismCategories,
  loading,
}: ReferralsViewProps): JSX.Element => {
  const fm = useFormatMessage()
  const [hideActionTooltips] = usePermissions('hide_action_tooltips')
  const history = useHistory()
  const match = useRouteMatch()

  const button = (
    <Button
      intent="secondary"
      onClick={() => {
        history.replace(`${match.url}/referral/create`)
      }}
      disabled={restrictFinalizedConsultationEditing}
    >
      {fm(messages.add_referral_button_label)}
    </Button>
  )

  return (
    <>
      <Section
        type="secondary"
        title={fm(messages.title_private)}
        dataTestId="non-nhs-referrals"
        topRight={
          hideActionTooltips ? (
            button
          ) : (
            <Tooltip
              placement="left"
              message={<FormattedMessage {...messages.tooltip_message} />}
            >
              {button}
            </Tooltip>
          )
        }
        loading={loading}
      >
        {isFilledArray(referrals)
          ? referrals.map((referral) => {
              if (!referral) {
                return null
              }
              return (
                <ReferralDataList
                  referral={referral}
                  key={referral.id}
                  markFinalizedConsultationAsEdited={
                    markFinalizedConsultationAsEdited
                  }
                  restrictFinalizedConsultationEditing={
                    restrictFinalizedConsultationEditing
                  }
                  specialismCategories={specialismCategories}
                />
              )
            })
          : null}
      </Section>

      <Route path="/consultation/:consultationId/referral/:mode(create|edit)">
        <ReferralModal
          markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
        />
      </Route>
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: REFERRALS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title_private,
})(ReferralsView)
