import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { ENABLE_CHATSCRIPT_FLOWS } from '~/constants'
import {
  PatientTimelineQueryResult,
  TimelineEventInterface,
  TimelineEventType,
  usePatientTimelineLazyQuery,
} from '~/generated'

import { FILTER_DEBOUNCE } from './constants'
import { trackTimelineEvents } from './utils'

export type TimelineError = NonNullable<
  NonNullable<
    NonNullable<PatientTimelineQueryResult['data']>['patient']
  >['historyV2']
>['errors'][number]

const usePatientTimelineQuery = (
  patientId: string,
  eventTypes: TimelineEventType[]
) => {
  // Maintaining a separate loading state from the query's loading state to show the loading spinner
  // as soon as the dropdown value changes even though the query is debounced
  const [loading, setLoading] = useState(true)

  const [getEvents, queryResult] = usePatientTimelineLazyQuery({
    context: {
      batch: 'patient-timeline',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const events =
        (data?.patient?.historyV2?.events as TimelineEventInterface[]) ?? []

      setLoading(false)
      trackTimelineEvents(events, TimelineEventType.ChatscriptConversation)
    },
    onError: () => setLoading(false),
  })

  const filterEvents = () => {
    getEvents({
      variables: {
        eventTypes,
        patientId,
        chatscriptOn: ENABLE_CHATSCRIPT_FLOWS,
      },
    })
  }

  const [debouncedFilterEvents] = useDebouncedCallback(() => {
    filterEvents()
  }, FILTER_DEBOUNCE)

  return {
    queryResult,
    filterEvents,
    debouncedFilterEvents,
    loading,
    setLoading,
  }
}

export default usePatientTimelineQuery
