import React, { useState } from 'react'
import { format } from 'date-fns'
import { envFlag } from '@babylon/babylon-env'
import { Modal, Table, TableRow, TableCell, Spinner } from '@babylon/core-ui'
import {
  ButtonSimple,
  ButtonVariant,
  Input,
  LinkButton,
  Text,
} from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import { DocumentEntry } from '../../types'
import StyledDropzone, {
  UploadResponse,
} from '../StyledDropzone/StyledDropzone'

import styles from './DocumentTable.module.css'
import messages from './messages'
import { LoadingIndicator } from '../LoadingIndicator'

export const documentDateFormatString = 'do MMM yyyy HH:mm'

export interface DocumentTableProps {
  documents: DocumentEntry[]
  onLoadMore: () => void
  onEdit: (id?: DocumentEntry) => void
  onDelete: (id: string) => Promise<void>
  memberId?: string
  memberName?: string
  endReached: boolean
  loading: boolean
  onUploadSuccess: (uploadedFile: UploadResponse) => void
}

export function DocumentTable({
  documents,
  onDelete,
  onEdit,
  memberId,
  memberName,
  onLoadMore,
  endReached,
  loading,
  onUploadSuccess,
}: DocumentTableProps) {
  const hasDocuments = !!documents.length
  const showEditButton = envFlag('ENABLE_DOCUMENT_REPOSITORY_EDIT_ACTION')
  const showDeleteButton = envFlag('ENABLE_DOCUMENT_REPOSITORY_DELETE_ACTION')
  const showUploadDropzone = envFlag('ENABLE_DOCUMENT_REPOSITORY_UPLOAD_ACTION')
  const fm = useFormatMessage()
  const [showEditModalForDocument, setShowEditModalForDocument] = useState<
    DocumentEntry | undefined
  >(undefined)
  const [showDeleteModalForDocument, setShowDeleteModalForDocument] = useState<
    { id: string; title: string } | undefined
  >(undefined)
  const headers = [
    fm(messages.table_header_document_title),
    fm(messages.table_header_upload_date),
  ]

  if (showEditButton || showDeleteButton) {
    headers.push(fm(messages.table_header_actions))
  }

  const [documentIdBeingDeleted, setDocumentIdBeingDeleted] = useState<string>(
    ''
  )

  const editButton = (id, title, url, date, contentType) => (
    <div className={styles.ActionContainer}>
      <LinkButton
        onClick={() =>
          setShowEditModalForDocument({
            id,
            title,
            url,
            date,
            contentType,
          })
        }
      >
        {fm(messages.edit_button)}
      </LinkButton>
    </div>
  )

  const deleteButton = (id, title) => (
    <>
      <LinkButton
        className={styles.ActionButton}
        onClick={() =>
          setShowDeleteModalForDocument({
            id,
            title,
          })
        }
      >
        {fm(messages.delete_button)}
      </LinkButton>
    </>
  )

  return (
    <>
      {showUploadDropzone && (
        <section className="container">
          <StyledDropzone
            memberId={memberId}
            memberName={memberName}
            onUploadSuccess={onUploadSuccess}
          />
        </section>
      )}
      <Modal open={!!showEditModalForDocument} includeClose={false}>
        <div style={{ minWidth: 500, width: '40vw' }}>
          <Text variant="h3" className={styles.EditDocumentTitle}>
            {fm(messages.edit_document_header)}
          </Text>
          <Text variant="h3" className={styles.EditDocumentHeader}>
            {fm(messages.edit_document_title)}
          </Text>
          <Input
            style={{ width: '100%', margin: 0 }}
            className={styles.EditTitleInput}
            defaultValue={showEditModalForDocument?.title}
          />
          <div className={styles.EditModalButtonContainer}>
            <ButtonSimple
              type="button"
              variant={ButtonVariant.secondary}
              onClick={() => setShowEditModalForDocument(undefined)}
            >
              {fm(messages.cancel_button)}
            </ButtonSimple>
            <ButtonSimple
              type="button"
              disabled
              onClick={() => onEdit(showEditModalForDocument)}
            >
              {fm(messages.save_button)}
            </ButtonSimple>
          </div>
        </div>
      </Modal>

      <Modal open={!!showDeleteModalForDocument} includeClose={false}>
        <div style={{ minWidth: 500, width: '40vw' }}>
          <Text variant="h3" className={styles.EditDocumentTitle}>
            {fm(messages.delete_document_header)}
          </Text>
          <Text variant="h4" className={styles.EditDocumentHeader}>
            {fm(messages.delete_document_title, {
              documentTitle: (
                <p style={{ fontWeight: 'normal' }}>
                  {showDeleteModalForDocument?.title}
                </p>
              ),
            })}
          </Text>
          <div className={styles.EditModalButtonContainer}>
            <ButtonSimple
              data-testid="cancel-delete-button"
              type="button"
              variant={ButtonVariant.secondary}
              onClick={() => setShowDeleteModalForDocument(undefined)}
            >
              {fm(messages.cancel_button)}
            </ButtonSimple>
            <ButtonSimple
              data-testid="confirm-delete-button"
              type="button"
              isLoading={!!documentIdBeingDeleted}
              loadingLabel={fm(messages.delete_in_progress)}
              // @ts-ignore
              onClick={async () => {
                setDocumentIdBeingDeleted(showDeleteModalForDocument!.id)
                // this gives the spinner enough time to properly show to the user before the row is deleted
                await new Promise((resolve) => setTimeout(resolve, 1000))
                await onDelete(showDeleteModalForDocument!.id)
                setDocumentIdBeingDeleted('')
                setShowDeleteModalForDocument(undefined)
              }}
            >
              {fm(messages.delete_button)}
            </ButtonSimple>
          </div>
        </div>
      </Modal>
      <Table striped fullWidth headers={headers}>
        {hasDocuments &&
          documents.map(({ id, title, url, date, contentType }) => (
            <TableRow key={id}>
              <TableCell>
                <a
                  data-testid={`document-download-link-${id}`}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              </TableCell>
              <TableCell>
                {format(new Date(date), documentDateFormatString)}
              </TableCell>
              <TableCell>
                {(showEditButton || showDeleteButton) && (
                  <div className={styles.ActionContainer}>
                    {documentIdBeingDeleted && documentIdBeingDeleted === id ? (
                      <Spinner />
                    ) : (
                      <>
                        {showEditButton &&
                          editButton(id, title, url, date, contentType)}
                        {showEditButton && showDeleteButton && (
                          <div style={{ marginLeft: 8, marginRight: 8 }}>-</div>
                        )}
                        {showDeleteButton && deleteButton(id, title)}
                      </>
                    )}
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
      </Table>
      <LoadingIndicator loading={loading} />
      {!endReached && (
        <LinkButton className={styles.LoadMoreButton} onClick={onLoadMore}>
          {fm(messages.load_more_documents)}
        </LinkButton>
      )}
      {!hasDocuments && (
        <Text variant="body" className={styles.EmptyDocumentMessage}>
          {fm(messages.empty_document_message)}
        </Text>
      )}
    </>
  )
}
