import { useRef, useState } from 'react'

import {
  MULTIMEDIA_CATEGORY,
  RESIZE_CALL_COMPONENT_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics/analytics'
import { useMount } from '~/core/hooks'

const useWidthResizeHandlers = (constrain: (newWidth: number) => number) => {
  const elementRef = useRef<HTMLDivElement>(null)

  const [prevWidth, setPrevWidth] = useState<number>()

  const saveWidth = () => {
    if (elementRef.current) {
      const currentWidth = elementRef.current.offsetWidth
      setPrevWidth(currentWidth)
    }
  }

  useMount(() => {
    saveWidth()
  })

  const handleResizeStart = () => {
    saveWidth()
  }

  const handleResizeEnd = () => {
    if (elementRef.current) {
      analytics.trackEvent({
        category: MULTIMEDIA_CATEGORY,
        action: RESIZE_CALL_COMPONENT_ACTION,
        label: elementRef.current?.style.width.replace('px', ''),
      })
    }
  }

  const handleResize = (event: { distanceX: number }) => {
    if (elementRef.current && prevWidth !== undefined) {
      const { distanceX } = event
      const width = constrain(prevWidth + distanceX)

      elementRef.current.style.width = `${width}px`
    }
  }

  return {
    ref: elementRef,
    handleResizeStart,
    handleResizeEnd,
    handleResize,
  }
}

export default useWidthResizeHandlers
