import React, { ReactNode } from 'react'

import { Spinner } from '@babylon/core-ui'

import DataNotAvailableText from '~/ui/DataNotAvailableText'
import Icon from '~/ui/Icon'

import styles from './styles.module.scss'

interface DataListItem {
  key: string
  value: ReactNode
  optional?: boolean
}

interface DataListProps {
  data: DataListItem[]
  onUpdate?: Function
  onRemove?: Function
  showProgress?: boolean
  dataTestId?: string
}

const Data = ({ items }: { items: DataListItem[] }) => (
  <>
    {items.map(({ key, value, optional }) => {
      return optional && !value ? null : (
        <tr key={key}>
          <td>{key}</td>
          <td>
            <div>{value || <DataNotAvailableText />}</div>
          </td>
        </tr>
      )
    })}
  </>
)

const DataList = ({
  data,
  onUpdate,
  onRemove,
  showProgress,
  dataTestId,
}: DataListProps) => (
  <table className={styles.dataList} data-testid={dataTestId}>
    <tbody>
      <Data items={data} />

      {onUpdate || onRemove ? (
        <tr className={styles.actionRow}>
          <td />
          <td>
            {onUpdate && (
              <button
                type="button"
                className={styles.updateButton}
                onClick={() => onUpdate()}
              >
                <Icon name="mode_edit" /> <span>Update</span>
              </button>
            )}
            {onRemove && (
              <button
                type="button"
                className={styles.removeButton}
                onClick={() => onRemove()}
              >
                <Icon name="delete_forever" /> <span>Remove</span>
              </button>
            )}
            {showProgress && (
              <Spinner
                className={styles.removalSpinner}
                color="#45576e"
                size="small"
              />
            )}
          </td>
        </tr>
      ) : null}
    </tbody>
  </table>
)

export default DataList
