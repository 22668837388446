/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames'
import React, { ReactNode } from 'react'

import styles from './styles.module.scss'

export interface MessageProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  type: 'warning' | 'info' | 'error'
  children?: ReactNode
  dataTestId?: string
}

const Banner = ({ onClick, ...rest }: any) => {
  return onClick ? (
    <div role="link" tabIndex={0} onClick={onClick} {...rest} />
  ) : (
    <div {...rest} />
  )
}

const Message = ({
  children,
  title,
  type,
  onClick,
  dataTestId,
}: MessageProps) => {
  const className = cn(styles.container, {
    [styles.clickable]: onClick,
    [styles.onlyTitle]: title && !children,
    [styles.onlyMessage]: !title && children,
  })

  return (
    <Banner onClick={onClick} className={className} data-testid={dataTestId}>
      <div className={cn(styles.leftSection, styles[type])}>
        <i className={cn('fas fa-exclamation-triangle', styles.icon)} />
      </div>
      <div>
        {title && <div className={styles.title}>{title}</div>}
        {children && (
          <div className={styles.message} data-testid="message">
            {children}
          </div>
        )}
      </div>
    </Banner>
  )
}

export default Message
