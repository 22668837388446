import { suspense } from '../../lazy-loading'
import { ApolloClient, EventBus, Info, Global, Main } from '~/core/core-modules'
import { ConsultationContext } from '~/core/config/context'
import { useModelProvider } from '~/core/container/provider'
import { LazyAdminPortalPage, AdminPortalPageNavigationItem } from '~/features/admin-portal-view/AdminPortalScreens'
import { ScheduleCheckIn, ConsultationCheckIn } from '~/features/appointment-check-in/AppointmentCheckIn'
import AppointmentInvitesPlugin from '~/features/appointment-invites/AppointmentInvites'
import AppointmentInvitesSummaryPlugin from '~/features/appointment-invites/AppointmentInvitesSummary'
import AttachmentsPlugin from '~/features/attachments'
import AttachmentsSummaryPlugin from '~/features/attachments/AttachmentsSummary'
import BookingReasonSummaryPlugin from '~/features/booking-reason/BookingReasonSummary'
import BookingReasonPlugin from '~/features/booking-reason/BookingReasonSection'
import { CallStatusReducerModelProvider } from '~/features/call/CallStatusReducerModelProvider'
import CallPlugin from '~/features/call'
import CarePlanSummaryPlugin from '~/features/care-plan/CarePlanSummary'
import CarePlanSectionPlugin from '~/features/care-plan/CarePlanSection'
import { DoNotInstantiate, CompleteConsultationModel, CompleteDialogPlugin, ConsultationActionsPlugin, MarkAsSensitiveModel, MarkAsSensitivePlugin, UsEhrNotesCompletionModel, PrescriptionPromotionModel, InsuranceClaimCheckModel, InsuranceClaimCheckPlugin, CarePlansCompletionModel, MandatoryDiagnosticCodesModel, SignatureRxPinPlugin } from '~/features/complete-consultation'
import { EditedConsultationWarningModelProvider } from '~/features/complete-consultation/EditedConsultationWarning'
import { GoogleFormDialog } from '~/features/google-form-dialog'
import CallActiveModalPlugin from '~/features/call/CallActiveModal/CallActiveModal'
import { LazyConsultationLitePage } from '~/features/consultation'
import DiagnosisPlugin from '~/features/consultation/DiagnosisSection/Diagnosis'
import DiagnosisSummaryPlugin from '~/features/consultation/DiagnosisSection/DiagnosisSummary'
import ExaminationSummaryPlugin from '~/features/consultation/Examination/ExaminationSummary'
import ExaminationNotesPlugin from '~/features/consultation/Examination/examination-notes-plugin'
import MedicalHistoryPlugin from '~/features/consultation/Examination/MedicalHistory'
import ExternalTabsWarningBannerPlugin from '~/features/consultation/ExternalTabsWarning'
import IncompleteConsultationWarningModalPlugin from '~/features/consultation/IncompleteConsultationWarning'
import ConsultationProfilePlugin from '~/features/consultation-profile'
import { LazyAllConsultationsPage, AllConsultationsPageNavigationItem } from '~/features/consultation-search/AllConsultationsPage'
import PastConsultationSummaryPlugin from '~/features/consultation-summary/ConsultationSummary/PastConsultationSummary'
import CurrentConsultationSummaryPlugin from '~/features/consultation-summary/ConsultationSummary/CurrentConsultationSummary'
import DocumentRepositoryPlugin from '~/features/document-repository/DocumentTable'
import EhrCheckInUsPlugin from '~/features/ehr-checkin-us'
import USEhrSummaryPlugin from '~/features/us-ehr/USEhrSummary'
import ExperimentsPage from '~/features/experiments'
import GpDetailsPlugin from '~/features/gp-details/GpDetails'
import { Header } from '~/features/header'
import { Layout } from '~/features/layout'
import LinksAndResourcesPlugin from '~/features/links-and-resources'
import { Navigation } from '~/features/navigation'
import NhsAppointmentInvitesPlugin from '~/features/nhs-appointment-invites/NhsAppointmentInvites'
import NhsAppointmentInvitesSummaryPlugin from '~/features/nhs-appointment-invites/NhsAppointmentInvitesSummary'
import NoteAssistantSummaryPlugin from '~/features/note-assistant/NoteAssistantSummary'
import CallRecordingPlugin from '~/features/note-assistant/CallRecording/CallRecording'
import PatientAlertsPlugin from '~/features/patient-alerts'
import PatientDetailsPlugin from '~/features/patient-details'
import PatientMetricsPlugin from '~/features/patient-metrics/PatientMetrics'
import PatientRecordDeepLinkPlugin from '~/features/patient-record-deep-link'
import PatientSummaryPlugin from '~/features/patient-summary/PatientSummary'
import TimelineConsultationEventPlugin from '~/features/patient-timeline/Timeline/components/TimelineConsultationEvent'
import PatientTimelinePlugin from '~/features/patient-timeline/Timeline'
import PreconsultationSummaryPlugin from '~/features/pre-consultation-flow/PreconsultationSummary'
import PreConsultationFlowPlugin from '~/features/pre-consultation-flow/PreConsultationSection'
import { LazyPrescriptionsPage, PrescriptionsPageNavigationItem } from '~/features/prescriptions/PrescriptionsPage'
import PrescriptionsPlugin from '~/features/prescriptions/PrescriptionsSection'
import PrescriptionsSummaryPlugin from '~/features/prescriptions/PrescriptionsSummary'
import ReferralsPlugin from '~/features/referrals/Referrals'
import ReferralsSummaryPlugin from '~/features/referrals/ReferralsSummary'
import ReleaseNotesPlugin from '~/features/release-notes'
import { LazySchedulePage, SchedulePageNavigationItem } from '~/features/schedule/SchedulePage'
import ConsultationScheduleItemPlugin from '~/features/schedule/ConsultationScheduleItem'
import SectionGroupPlugin from '~/features/section-group'
import Routes from '~/features/shell/Routes'
import SickNotesSummaryPlugin from '~/features/sick-notes/SickNotesSummary'
import SickNotesPlugin from '~/features/sick-notes/SickNotes'
import { LazyTestsKitsPage, TestsKitsPageNavigationItem } from '~/features/test-kits/TestsKitsPage'
import TestsSectionPlugin from '~/features/tests/TestsSection'
import TestsSummaryPlugin from '~/features/tests/TestsSummary'
import USEhrDeepLinkPlugin from '~/features/us-ehr/USEhrDeepLink'
import WorkflowActionsPlugin from '~/features/workflows/WorkflowActions'
import WorkflowActionsSummaryPlugin from '~/features/workflows/WorkflowActionsSummary'

export const modules = {
  ApolloClient,
  EventBus,
  Info,
  Global,
  ConsultationContext,
  AbstractModelProvider: useModelProvider,
  Main,
  AdminPortalPage: suspense(LazyAdminPortalPage),
  AdminPortalPageNavigationItem,
  ScheduleCheckInPlugin: ScheduleCheckIn,
  ConsultationCheckInPlugin: ConsultationCheckIn,
  AppointmentInvitesPlugin,
  AppointmentInvitesSummaryPlugin,
  AttachmentsPlugin,
  AttachmentsSummaryPlugin,
  BookingReasonSummaryPlugin,
  BookingReasonPlugin,
  CallStatusReducerModelProvider,
  CallPlugin,
  CarePlanSummaryPlugin,
  CarePlanSectionPlugin,
  AbstractCompleteConsultationExtension: DoNotInstantiate,
  CompleteConsultationModel,
  CompleteDialogPlugin,
  EditedConsultationWarningModelProvider,
  PostConsultationFormModal: GoogleFormDialog,
  CallActiveModalPlugin,
  ConsultationActionsPlugin,
  MarkAsSensitiveModel,
  MarkAsSensitivePlugin,
  UsEhrNotesCompletionModel,
  PrescriptionPromotionModel,
  InsuranceClaimCheckModel,
  InsuranceClaimCheckPlugin,
  CarePlansCompletionModel,
  MandatoryDiagnosticCodesModel,
  ConsultationLitePage: suspense(LazyConsultationLitePage),
  DiagnosisPlugin,
  DiagnosisSummaryPlugin,
  ExaminationSummaryPlugin,
  ExaminationNotesPlugin,
  MedicalHistoryPlugin,
  ExternalTabsWarningBannerPlugin,
  IncompleteConsultationWarningModalPlugin,
  ConsultationProfilePlugin,
  AllConsultationsPage: suspense(LazyAllConsultationsPage),
  AllConsultationsPageNavigationItem,
  PastConsultationSummaryPlugin,
  CurrentConsultationSummaryPlugin,
  DocumentRepositoryPlugin,
  EhrCheckInUsPlugin,
  USEhrSummaryPlugin,
  ExperimentsPage,
  GpDetailsPlugin,
  Header,
  Layout,
  LinksAndResourcesPlugin,
  Navigation,
  NhsAppointmentInvitesPlugin,
  NhsAppointmentInvitesSummaryPlugin,
  NoteAssistantSummaryPlugin,
  CallRecordingPlugin,
  PatientAlertsPlugin,
  PatientDetailsPlugin,
  PatientMetricsPlugin,
  PatientRecordDeepLinkPlugin,
  PatientSummaryPlugin,
  TimelineConsultationEventPlugin,
  PatientTimelinePlugin,
  PreconsultationSummaryPlugin,
  PreConsultationFlowPlugin,
  PrescriptionsPage: suspense(LazyPrescriptionsPage),
  PrescriptionsPageNavigationItem,
  PrescriptionsPlugin,
  PrescriptionsSummaryPlugin,
  SignatureRxPinPlugin,
  ReferralsPlugin,
  ReferralsSummaryPlugin,
  ReleaseNotesPlugin,
  SchedulePage: suspense(LazySchedulePage),
  SchedulePageNavigationItem,
  ConsultationScheduleItemPlugin,
  SectionGroupPlugin,
  Routes,
  SickNotesSummaryPlugin,
  SickNotesPlugin,
  TestsKitsPage: suspense(LazyTestsKitsPage),
  TestsKitsPageNavigationItem,
  TestsSectionPlugin,
  TestsSummaryPlugin,
  USEhrDeepLinkPlugin,
  WorkflowActionsPlugin,
  WorkflowActionsSummaryPlugin
}
