import React, { ReactElement } from 'react'

import { Button } from '@babylon/core-ui'

import styles from './styles.module.scss'

interface ShowMoreSectionButtonProps {
  handleOnClick: () => void
  buttonLabel: string | ReactElement
}

const ShowMoreSectionButton = ({
  handleOnClick,
  buttonLabel,
}: ShowMoreSectionButtonProps) => {
  return (
    <div className={styles.showMoreButton}>
      <Button
        style={{ color: '#2bbbbb', borderColor: '#2bbbbb' }}
        intent="secondary"
        data-testid="more-info-button"
        onClick={handleOnClick}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}

export default ShowMoreSectionButton
