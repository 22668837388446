import React, { useState } from 'react'

import {
  PAT_MET_ABANDON_DATA,
  PAT_MET_RETURN_TO_MODAL,
  PATIENT_METRICS,
  SAVE_CHANGES_WARNING_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { Dialog } from '~/ui/Modal'

import styles from './styles.module.scss'

export interface UseConfirmationDialogOptions {
  title?: string
  message?: string
  okLabel?: string
  cancelLabel?: string
  showDialog?: (value?: React.SetStateAction<boolean>) => boolean
}

const trackEvent = analytics.trackEventFactory({
  category: PATIENT_METRICS,
  action: SAVE_CHANGES_WARNING_PATIENT_METRICS,
})

const useConfirmationDialog = ({
  title,
  message,
  okLabel = 'Ok',
  cancelLabel = 'Cancel',
  showDialog = () => false,
}: UseConfirmationDialogOptions): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  false | JSX.Element
] => {
  const [visible, setVisible] = useState(true)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const onVisibleChange = (value?: React.SetStateAction<boolean>) => {
    if (showDialog(value)) {
      setShowConfirmationDialog(true)
    } else {
      setVisible(!!value)
    }
  }

  const dialog = showConfirmationDialog && (
    <Dialog
      className={styles.dialog}
      title={title}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      ok
      cancel
      onOk={() => {
        setShowConfirmationDialog(false)
        setVisible(false)
        trackEvent({ label: PAT_MET_ABANDON_DATA })
      }}
      onCancel={() => {
        setShowConfirmationDialog(false)
        trackEvent({ label: PAT_MET_RETURN_TO_MODAL })
      }}
    >
      {message && <div style={{ paddingBottom: 20 }}>{message}</div>}
    </Dialog>
  )

  return [visible, onVisibleChange, dialog]
}

export default useConfirmationDialog
