import React, { useEffect, useState } from 'react'

import { Button } from '@babylon/core-ui'

import {
  ONB_CLOSED_ACTION,
  ONB_HIDE_ACTION,
  ONB_OPENED_ACTION,
  ONB_SHOW_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useHideOnboarding } from '~/core/hooks'

import { ReactComponent as ChevronDown } from './chevron-down.svg'
import { ReactComponent as ChevronUp } from './chevron-up.svg'

import styles from './styles.module.scss'

interface ShowHideProps {
  name: string
  showButtonLabel?: React.ReactNode
  hideButtonLabel?: React.ReactNode
}

const ShowHide: React.FC<ShowHideProps> = ({
  children,
  name,
  hideButtonLabel,
  showButtonLabel,
}) => {
  const [onboardingHidden, setOnboardingHidden] = useHideOnboarding(name)
  const displayInitially = !onboardingHidden

  const trackEvent = analytics.trackEventFactory({
    category: name,
  })

  useEffect(() => {
    trackEvent({
      action: displayInitially ? ONB_OPENED_ACTION : ONB_CLOSED_ACTION,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [showContent, setShowContent] = useState(displayInitially)

  const handleShowButtonClick = () => {
    trackEvent({ action: ONB_SHOW_ACTION, label: new Date().toISOString() })
    setShowContent(true)
  }

  const handleHideButtonClick = () => {
    if (displayInitially) {
      setOnboardingHidden(true)
    }
    trackEvent({ action: ONB_HIDE_ACTION, label: new Date().toISOString() })
    setShowContent(false)
  }

  return (
    <div className={styles.showHide}>
      {showContent ? (
        <>
          <Button intent="link" onClick={handleHideButtonClick}>
            {hideButtonLabel}
            <ChevronUp className={styles.chevron} />
          </Button>
          <div className={styles.content}>{children}</div>
        </>
      ) : (
        <Button intent="link" onClick={handleShowButtonClick}>
          {showButtonLabel}
          <ChevronDown className={styles.chevron} />
        </Button>
      )}
    </div>
  )
}

export default ShowHide
