import { useMemo } from 'react'
import semverCmp from 'semver-compare'
import semverRegex from 'semver-regex'

import { APP_VERSION, COUNTRY_CODE } from '~/constants'
import { ReleaseNotesQuery, useReleaseNotesQuery } from '~/generated'

export type ReleaseNote = ReleaseNotesQuery['cp3ReleaseNotes'][number]

const filterAfterVersion = (notes: ReleaseNote[], version: string) =>
  semverRegex().test(version)
    ? notes.filter((v) => semverCmp(v.versionNumber, version) <= 0)
    : notes

const sortByVersion = (first: ReleaseNote, second: ReleaseNote) =>
  semverCmp(first.versionNumber, second.versionNumber) ? -1 : 1

const useRelevantReleaseNotes = (releaseNoteCountryCode?: string) => {
  const { data, loading, error } = useReleaseNotesQuery({
    variables: {
      countryCode: releaseNoteCountryCode || COUNTRY_CODE,
    },
  })

  const currentVersion = APP_VERSION

  const notes = useMemo(() => {
    const releaseNotes = data?.cp3ReleaseNotes ?? []

    return filterAfterVersion(releaseNotes, currentVersion).sort(sortByVersion)
  }, [currentVersion, data])

  return { loading, notes, error }
}

export default useRelevantReleaseNotes
