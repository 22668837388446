import { ApolloError } from '@apollo/client'
import React from 'react'

import {
  Heading,
  Select,
  SelectActionMeta,
  SelectOptionTypeBase,
  SelectValueType,
} from '@babylon/core-ui'

import { TimelineConsultationEventPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { TimelineEventInterface } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { SectionShowMoreButton } from '~/ui/Section'

import { INITIAL_EVENT_COUNT } from '../../constants'
import Timeline from '../Timeline'

import messages from '../../PatientTimeline.messages'
import styles from '../../styles.module.scss'

interface PatientTimelineViewProps {
  loading: boolean
  timelineError: ApolloError | null
  options: SelectOptionTypeBase[]
  handleSelectChange: (value: SelectValueType, action: SelectActionMeta) => void
  onKeyDown: () => void
  selectedOptions: { tagColor: string }[]
  handleRefetch: () => void
  visibleEvents: TimelineEventInterface[]
  patientId: string
  patientUuid: string
  timelineConsultationEvent?: ReturnType<TimelineConsultationEventPluginInterface>
  timelineEvents: TimelineEventInterface[]
  allEventsVisible: boolean
  handleShowLess: () => void
  handleShowMore: () => void
  isFuture?: boolean
}

const PatientTimelineView = ({
  loading,
  timelineError,
  options,
  handleSelectChange,
  onKeyDown,
  selectedOptions,
  handleRefetch,
  visibleEvents,
  patientId,
  patientUuid,
  timelineConsultationEvent,
  timelineEvents,
  allEventsVisible,
  handleShowLess,
  handleShowMore,
  isFuture = false,
}: PatientTimelineViewProps) => {
  const f = useMessages(messages)
  const title = isFuture
    ? `${f('future_timeline_title')} (${timelineEvents.length})`
    : `${f('current_past_timeline_title')}`
  const timelineErrorMessage = isFuture
    ? f('future_timeline_loading_error')
    : f('current_past_timeline_loading_error')

  return (
    <React.StrictMode>
      <>
        <Heading level="h3">{title}</Heading>
        {options.length > 1 && (
          <Select
            className={styles.filter}
            isMulti
            onChange={handleSelectChange}
            options={options}
            formatOptionLabel={(option) => option.label}
            placeholder={f('filter_events_placeholder')}
            onKeyDown={onKeyDown}
            isLoading={loading}
            removeSelected
            value={selectedOptions}
          />
        )}
        {timelineError && (
          <ErrorPanel
            title={timelineErrorMessage}
            error={timelineError}
            retry={handleRefetch}
          />
        )}
        {visibleEvents && (
          <Timeline
            events={visibleEvents}
            patientId={patientId}
            patientUuid={patientUuid}
            TimelineConsultationEvent={timelineConsultationEvent}
            isFuture={isFuture}
          />
        )}
        {timelineEvents.length > INITIAL_EVENT_COUNT && (
          <SectionShowMoreButton
            expanded={allEventsVisible}
            onClick={allEventsVisible ? handleShowLess : handleShowMore}
          />
        )}
      </>
    </React.StrictMode>
  )
}

export default PatientTimelineView
