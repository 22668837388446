import { defineMessages } from 'react-intl'

const baseId = 'header.support_options'

export default defineMessages({
  header_button_title: {
    id: `${baseId}.header_button_title`,
    defaultMessage: 'Support',
  },
  senior_clinical_team_title: {
    id: `${baseId}.senior_medical_team_title`,
    defaultMessage: 'Contact Duty',
  },
  senior_clinical_team_description: {
    id: `${baseId}.senior_medical_team_description`,
    defaultMessage: 'Get help with clinical issues from the duty team',
  },
  live_chat_title: {
    id: `${baseId}.live_chat_title`,
    defaultMessage: 'Live chat with support',
  },
  live_chat_description: {
    id: `${baseId}.live_chat_description`,
    defaultMessage:
      'Contact ClinOps/operations support about urgent issues (e.g. I need to rearrange an appointment)',
  },
  report_risk_title: {
    id: `${baseId}.report_risk_title_v2`,
    defaultMessage: 'Report an incident',
  },
  report_risk_description: {
    id: `${baseId}.report_risk_description`,
    defaultMessage:
      'Report an incident (e.g. A patient trips and falls); action a safety alert (MHRA notice issued for review)',
  },
  technology_help_title: {
    id: `${baseId}.technology_help_title_v2`,
    defaultMessage: 'Technology help',
  },
  technology_help_description: {
    id: `${baseId}.technology_help_description`,
    defaultMessage:
      'Get help with your computer; hardware (e.g. 2-factor authentication) or to report issues with the Clinical Portal (e.g. can’t load a consultation)',
  },
})
