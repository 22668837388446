import upperFirst from 'lodash/upperFirst'

import { presetStyles } from '@babylon/core-ui'

import { workflowStateColours } from '~/features/workflows/utils/mappings'
import { ConsultationWorkflowV2 } from '~/features/workflows/WorkflowActions/useConsultationWorkflowsV2Query'
import workflowSubTypes from '~/features/workflows/WorkflowDefinitionsModal/workflowSubTypes'
import {
  WorkflowDefinition,
  WorkflowDefinitionGroup,
  WorkflowV2,
} from '~/generated'

type GenericWorkflowV2 = Pick<ConsultationWorkflowV2, 'variables' | 'state'>

export const getWorkflowGroups = (
  workflowDefinitions: WorkflowDefinition[]
): WorkflowDefinitionGroup[] => {
  return workflowDefinitions.reduce(
    (groups: WorkflowDefinitionGroup[], { group }) => {
      const { type } = group

      if (
        groups.every((group) => group.type !== type) &&
        type !== 'appointment' &&
        type !== 'prescription'
      ) {
        groups.push(group)
      }

      return groups
    },
    []
  )
}

export const getGroupsFromWorkflows = (
  workflows: ConsultationWorkflowV2[],
  workflowDefinitions?: WorkflowDefinition[]
): WorkflowDefinitionGroup[] => {
  const groups =
    (workflowDefinitions && getWorkflowGroups(workflowDefinitions)) || []

  workflows.forEach(({ workflowDefinition }) => {
    if (workflowDefinition) {
      const { group } = workflowDefinition
      if (
        groups.every((workflowGroup) => workflowGroup.type !== group.type) &&
        group.type !== 'appointment'
      ) {
        groups.push(group)
      }
    }
  })

  return groups
}

export const getGroupLabelByType = (
  workflowGroups: WorkflowDefinitionGroup[],
  type: string
) => {
  const group = workflowGroups.find(
    (group) => group.type.toLowerCase() === type
  )

  return group?.label || upperFirst(type)
}

export const filterWorkflowsV2 = (
  workflows: ConsultationWorkflowV2[],
  type: string
): ConsultationWorkflowV2[] => {
  return workflows.filter(
    (workflow) => workflow.workflowDefinition?.group.type === type
  )
}

export const getWorkflowSubType = <T extends GenericWorkflowV2>(
  workflow: WorkflowV2 | T
): string | null | undefined => {
  const subType = workflow.variables.find(
    (variable) => variable.name === 'actionSubType'
  )

  return subType?.value
}

export const getWorkflowSubTypeLabel = (
  type?: string | null,
  subType?: string | null
): string => {
  const subTypeMapping =
    type && subType && workflowSubTypes[type] && workflowSubTypes[type][subType]

  return (
    subTypeMapping?.label ||
    (subTypeMapping && upperFirst(subTypeMapping.value)) ||
    upperFirst(subType || '')
  )
}

export const getWorkflowDetails = <T extends GenericWorkflowV2>(
  workflow: T
): {
  type: string
  status: string | null | undefined
  tagColour: typeof presetStyles[number]
} => {
  const { variables, state: status } = workflow
  const workflowType = variables.find(({ name }) => name === 'actionType')
  const workflowSubType = getWorkflowSubType(workflow)
  const type = getWorkflowSubTypeLabel(workflowType?.value, workflowSubType)
  const tagColour = status ? workflowStateColours[status] : 'reverse-grey'

  return { type, status, tagColour }
}

export const isInvalidWorkflow = ({
  variables,
  workflowDefinition,
}: ConsultationWorkflowV2) => {
  return (
    variables.every(
      ({ name }) => name !== 'actionType' && name !== 'actionSubType'
    ) || workflowDefinition?.group.label == null
  )
}

export const parseDefaultValue = (value?: string | null) => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return typeof value === 'string' && !Number.isNaN(Number(value))
        ? +value
        : value || null
  }
}
