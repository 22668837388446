import { useMutation } from '@apollo/client'

import {
  CpSubmitMultimediaFeedbackMutation,
  CpSubmitMultimediaFeedbackMutationVariables,
} from '~/generated'

import { SEND_FEEDBACK_MUTATION } from './operations'

const useSendFeedbackMutation = () => {
  return useMutation<
    CpSubmitMultimediaFeedbackMutation,
    CpSubmitMultimediaFeedbackMutationVariables
  >(SEND_FEEDBACK_MUTATION)
}

export default useSendFeedbackMutation
