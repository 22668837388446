import { $ref, Config } from './variants/generated/_services_'

const config: Config = {
  global: {
    module: 'Global',
  },

  eventBus: {
    module: 'EventBus',
  },

  apolloClient: {
    module: 'ApolloClient',
  },

  consultationContext: {
    module: 'ConsultationContext',
    options: {
      apolloClient: $ref('apolloClient'),
    },
  },
}

export default config
