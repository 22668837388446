import { TEST_LOCALE } from '~/core/i18n/locale'

import messages from './shared-messages/languages.messages'

const languages = () => [
  { code: 'en-GB', name: messages.en_gb },
  { code: 'en-US', name: messages.en_us },
  { code: 'en-CA', name: messages.en_ca },
  { code: 'fr-CA', name: messages.fr_ca },
  { code: TEST_LOCALE, name: messages.en_test },
]

export default languages
