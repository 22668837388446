import { MessageDescriptor } from 'react-intl'

import messages from './PatientMetricsModal.messages'

const MIN_SYSTOLIC = 50
const MAX_SYSTOLIC = 300
const MIN_DIASTOLIC = 10
const MAX_DIASTOLIC = 200

export const isExpanded = (name: string) => {
  const open = new URLSearchParams(location.search).get('open')
  return !open || open === name
}

export const validateSystolicBP = (fm: (key: MessageDescriptor) => string) => (
  value: string
) => {
  if (value && Number(value) < 0) return fm(messages.error_non_zeroes)
  if (value && Number(value) < MIN_SYSTOLIC) return fm(messages.error_min)
  if (value && Number(value) > MAX_SYSTOLIC) return fm(messages.error_max)

  return null
}

export const validateDiastolicBP = (fm: (key: MessageDescriptor) => string) => (
  value: string
) => {
  if (value && Number(value) < 0) return fm(messages.error_non_zeroes)
  if (value && Number(value) < MIN_DIASTOLIC) return fm(messages.error_min)
  if (value && Number(value) > MAX_DIASTOLIC) return fm(messages.error_max)

  return null
}
