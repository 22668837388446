import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, DateRangePicker, Text, Textarea } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { useMessages } from '~/core/hooks'
import { usePermissions } from '~/core/permissions'
import Label from '~/ui/Label'
import { ModalContent, ModalFooter, RouteModal } from '~/ui/LegacyModal'

import { MAX_REASON_LENGTH } from './constants'
import { mapStateToSickNote } from './mappings'

import messages from './SickNoteModal.messages'
import styles from './styles.module.scss'

const SickNoteView = ({
  warnings,
  state: initialFormValues,
  mode,
  handleCancelClick,
  handleSaveClick,
  handleUpdateClick,
  createSickNoteMutationResult: { loading: createSickNoteLoading },
  updateSickNoteMutationResult: { loading: updateSickNoteLoading },
}) => {
  const intl = useIntl()
  const f = useMessages(messages)
  const [hideActionTooltips] = usePermissions('hide_action_tooltips')

  const isInCreateMode = mode === 'create'
  const loading = createSickNoteLoading || updateSickNoteLoading

  const [reasonInputError, setReasonInputError] = useState(null)
  const [reason, setReason] = useState(initialFormValues.reason || '')
  const [startDate, setStartDate] = useState(
    initialFormValues.startDate || null
  )
  const [endDate, setEndDate] = useState(initialFormValues.endDate || null)

  const currentCharacterCount = reason.length
  const remainingReasonCharacters = MAX_REASON_LENGTH - currentCharacterCount

  const validReasonCharacterCount = remainingReasonCharacters > -1

  const handleSubmitClick = async () => {
    if (reason.length > MAX_REASON_LENGTH) {
      setReasonInputError(true)
      return
    }

    const input = mapStateToSickNote({ reason, startDate, endDate })
    if (isInCreateMode) {
      await handleSaveClick(input)
    } else {
      await handleUpdateClick(input)
    }
  }

  return (
    <RouteModal onRequestClose={handleCancelClick}>
      <ModalContent title={f('title_private')} warnings={warnings}>
        <Label
          value={`${f('reason_field_label')}*`}
          info={
            hideActionTooltips ? null : (
              <FormattedMessage {...messages.reason_tooltip_message_html} />
            )
          }
          infoPlacement="right"
        >
          <Textarea
            fill
            value={reason}
            testId="sick-note-reason-input"
            intent={reasonInputError ? 'error' : undefined}
            placeholder={f('reason_for_sick_note_placeholder')}
            onChange={(event) => setReason(event.target.value)}
          />
          {!!currentCharacterCount && (
            <Text
              tag="div"
              size="medium"
              color={validReasonCharacterCount ? 'light-grey-type' : 'error'}
              className={styles.characterCount}
            >
              {remainingReasonCharacters >= 0
                ? f('character_count_positive', {
                    remainingReasonCharacters,
                  })
                : f('character_count_negative', {
                    characterOverage: Math.abs(remainingReasonCharacters),
                  })}
            </Text>
          )}
        </Label>
        <Label
          value={`${f('dates_field_label')}*`}
          info={
            hideActionTooltips ? null : (
              <FormattedMessage {...messages.calendar_tooltip_message_html} />
            )
          }
          infoPlacement="right"
        >
          <div>
            <DateRangePicker
              locale={intl.locale}
              startDate={startDate}
              endDate={endDate}
              startPlaceholderText={f('calendar_start_date_placeholder')}
              endPlaceholderText={f('calendar_end_date_placeholder')}
              monthsShown={1}
              onChange={(event) => {
                setStartDate(event.startDate)
                setEndDate(event.endDate)
              }}
            />
          </div>
        </Label>
        <ModalFooter>
          <Button inline intent="secondary" onClick={handleCancelClick}>
            {f('cancel_button_label')}
          </Button>
          <Button inline onClick={handleSubmitClick} loading={loading}>
            {isInCreateMode
              ? f('create_button_label')
              : f('update_button_label')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </RouteModal>
  )
}

export default SickNoteView
