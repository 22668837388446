/* eslint jsx-a11y/click-events-have-key-events: 0 */
import React, { ReactNode } from 'react'

import { Text } from '@babylon/core-ui'

import styles from './styles.module.scss'

interface DropdownTitleProps {
  title: ReactNode
  description?: ReactNode
}

const DropdownTitle = ({ title, description }: DropdownTitleProps) => (
  <div className={styles.container}>
    <Text tag="div" bold>
      {title}
    </Text>
    {description && (
      <Text tag="div" className={styles.description}>
        {description}
      </Text>
    )}
  </div>
)

export default DropdownTitle
