import get from 'lodash/get'
import React, { ReactNode } from 'react'

import { DropdownSelectOption, Radio, SelectOptionType } from '@babylon/core-ui'

import {
  Maybe,
  WorkflowDefinitionVariable,
  WorkflowDefinitionVariableMetadata,
  WorkflowDefinitionVariableMetadataAllowedValue,
  WorkflowDefinitionVariableMetadataAsyncCodeSearchValue,
  WorkflowDefinitionVariableMetadataChildAllowedValue,
  WorkflowDefinitionVariableMetadataDateFormat,
  WorkflowDefinitionVariableMetadataObject,
  WorkflowDefinitionVariableMetadataRegexValidation,
  WorkflowDefinitionVariableMetadataSource,
  WorkflowDefinitionVariableMetadataSourceType,
  WorkflowDefinitionVariableMetadataType,
  WorkflowDefinitionVariableType,
} from '~/generated'

import { mapMetadataToDropDownOptions } from './mappings'

import styles from './WorkflowDefinitionForm/styles.module.scss'

type FieldRenderProps = {
  id: string
  name: string
  label?: string
  placeholder?: string
  autoComplete?: string
  loadOptions?: (
    inputValue: string,
    callback: (options: SelectOptionType[]) => void
  ) => Promise<any> | void
  options?: DropdownSelectOption[] | undefined
  dateFormat?: string
  stateFormat?: string | ((date: Date | string) => Date | string)
  showTimeSelect?: boolean
  searchable?: string
  onChange?: (value?: any) => void
  validate: (
    name: string,
    isRequired: boolean,
    metadata: WorkflowDefinitionVariableMetadata[],
    value: any
  ) => any
  columns?: number
  children?: ReactNode
  className?: string
  autoresize?: boolean
}

const numericRegex = /^[-+]?\d+$/
const decimalsRegex = /^[-+]?[0-9]*\.?[0-9]+$/
const multiLineCharLimit = 4000

export const VALID_CODE_SYSTEMS = {
  ICD_10: 'icd10-cm',
}

export const getFieldMetadata = (
  metadata: WorkflowDefinitionVariableMetadata[],
  type: string
): Array<WorkflowDefinitionVariableMetadata> =>
  metadata.filter((meta) => meta && meta.type === type)

const getChildVariable = (
  name: string,
  fields: WorkflowDefinitionVariable[]
): WorkflowDefinitionVariable | null => {
  for (const field of fields) {
    const { metadata } = field
    const childValues = getFieldMetadata(
      metadata,
      WorkflowDefinitionVariableMetadataType.ChildAllowedValues
    )

    if (childValues.length && childValues[0]) {
      const { parent_variable: parentVariable } = childValues[0]
        .value as WorkflowDefinitionVariableMetadataChildAllowedValue

      if (parentVariable === name) {
        return field
      }
    }
  }

  return null
}

const getMetadataOptions = (
  value?: Maybe<WorkflowDefinitionVariableMetadataObject>
) => {
  const { values } = value as WorkflowDefinitionVariableMetadataAllowedValue

  return mapMetadataToDropDownOptions(values)
}

const getMetadataChildOptions = (
  value?: Maybe<WorkflowDefinitionVariableMetadataObject>,
  formValues?: any
) => {
  const {
    parent_mappings: parentMappings,
    parent_variable: parentVariable,
  } = value as WorkflowDefinitionVariableMetadataChildAllowedValue
  const selectedParent = formValues[parentVariable]

  return (
    selectedParent &&
    parentMappings[selectedParent] &&
    mapMetadataToDropDownOptions(parentMappings[selectedParent])
  )
}

const getMetadataPlaceholder = (
  metadata: WorkflowDefinitionVariableMetadata[]
): string => {
  const placeholderMetadata = getFieldMetadata(
    metadata,
    WorkflowDefinitionVariableMetadataType.Source
  )[0]

  if (placeholderMetadata) {
    const placeholderModel = placeholderMetadata.value as WorkflowDefinitionVariableMetadataSource
    const placeholder = get(placeholderModel, 'source_info.placeholder')

    if (
      placeholderModel.type ===
        WorkflowDefinitionVariableMetadataSourceType.User &&
      placeholder
    ) {
      return placeholder
    }
  }

  return ''
}

const getFieldValidationRules = (
  name: string,
  type: string,
  isRequired: boolean,
  metadata: WorkflowDefinitionVariableMetadata[],
  value: any,
  messages: any
): string | undefined => {
  if (isRequired && (value == null || value === '')) {
    return messages.validation_required
  }

  if (type === WorkflowDefinitionVariableType.Long && value) {
    if (!numericRegex.test(value)) {
      return messages.validation_numeric
    }
  }

  if (type === WorkflowDefinitionVariableType.Double && value) {
    if (!decimalsRegex.test(value)) {
      return messages.validation_decimal
    }
  }

  if (type === WorkflowDefinitionVariableType.MultiLine && value) {
    // This is a validation to ensure the charlimit does not exeed the size supported by the back end service
    if (typeof value === 'string' && value.length > multiLineCharLimit) {
      return messages.validation_multiline_charLimit
    }
  }

  const metadataRules = getFieldMetadata(
    metadata,
    WorkflowDefinitionVariableMetadataType.RegexValidation
  )

  for (const rule of metadataRules) {
    const ruleValue = rule.value as WorkflowDefinitionVariableMetadataRegexValidation

    if (value && !new RegExp(ruleValue.regex).test(value)) {
      return messages.validation_incorrect_value
    }
  }

  return undefined
}

export const getFieldCodingSystem = (
  metadata: WorkflowDefinitionVariableMetadata[]
): string | undefined => {
  const codingSystemMetadata = getFieldMetadata(
    metadata,
    WorkflowDefinitionVariableMetadataType.AsyncCodeSearch
  )[0]

  if (codingSystemMetadata) {
    const codingSystemValue = codingSystemMetadata.value as WorkflowDefinitionVariableMetadataAsyncCodeSearchValue

    return codingSystemValue.coding_system
  }

  return undefined
}

const getFieldDateFormat = (
  metadata: WorkflowDefinitionVariableMetadata[]
): string => {
  const dateFormatMetadata = getFieldMetadata(
    metadata,
    WorkflowDefinitionVariableMetadataType.DateFormat
  )[0]

  if (dateFormatMetadata) {
    const dateFormatValue = dateFormatMetadata.value as WorkflowDefinitionVariableMetadataDateFormat

    return dateFormatValue.format
  }

  return ''
}

export const getDefaultValue = (field: WorkflowDefinitionVariable) => {
  const fieldSource = field.metadata.find(
    (metadata) =>
      metadata.type === WorkflowDefinitionVariableMetadataType.Source
  )
  const sourceValue = fieldSource?.value as WorkflowDefinitionVariableMetadataSource
  return sourceValue?.source_info?.default_value
}

export const getFieldData = (
  {
    name,
    type,
    label,
    metadata,
    is_required: isRequired,
  }: WorkflowDefinitionVariable,
  fields: WorkflowDefinitionVariable[],
  values: any,
  setFieldValue: (field: string, value: any) => void,
  messages: Dictionary<string>
): { fieldProps: FieldRenderProps; displayField: boolean } => {
  const childOptions = getFieldMetadata(
    metadata,
    WorkflowDefinitionVariableMetadataType.ChildAllowedValues
  )[0]
  const fieldOptions =
    childOptions ||
    getFieldMetadata(
      metadata,
      WorkflowDefinitionVariableMetadataType.AllowedValues
    )[0]

  const placeholder = getMetadataPlaceholder(metadata)
  const validate = (value: any) =>
    getFieldValidationRules(name, type, isRequired, metadata, value, messages)

  const fieldProps: FieldRenderProps = {
    id: name,
    name,
    label,
    placeholder,
    autoComplete: 'off',
    validate,
  }

  if (type === WorkflowDefinitionVariableType.MultiLine) {
    fieldProps.autoresize = true
  }

  if (fieldOptions) {
    fieldProps.searchable = 'true'
    fieldProps.options = childOptions
      ? getMetadataChildOptions(fieldOptions.value, values)
      : getMetadataOptions(fieldOptions.value)

    const childField = getChildVariable(name, fields)

    if (childField) {
      fieldProps.onChange = () => setFieldValue(childField.name, null)
    }
  }

  const codingSystem = getFieldCodingSystem(metadata)
  if (codingSystem) {
    fieldProps.searchable = 'true'
  }

  if (
    type === WorkflowDefinitionVariableType.Date ||
    type === WorkflowDefinitionVariableType.DateTimeTz
  ) {
    const format = getFieldDateFormat(metadata)
      .replace(/Y/g, 'y')
      .replace(/D/g, 'd')

    fieldProps.dateFormat = format
    fieldProps.stateFormat = format || "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
  }

  if (type === WorkflowDefinitionVariableType.DateTimeTz) {
    fieldProps.showTimeSelect = true
  }

  if (type === WorkflowDefinitionVariableType.Boolean) {
    fieldProps.children = (
      <div className={styles.booleanRadioGroup}>
        <Radio value="true">{messages.boolean_yes}</Radio>
        <Radio value="false">{messages.boolean_no}</Radio>
      </div>
    )
    fieldProps.onChange = (value: string) =>
      setFieldValue(name, value === 'true')
  }

  const displayField = !!((childOptions && fieldProps.options) || !childOptions)

  return {
    fieldProps,
    displayField,
  }
}
