export const mapWarnings = (warnings) =>
  warnings.map((warning) => {
    return {
      title: warning.action,
      message: warning.message,
      type: warning.allowPrescribing ? 'warning' : 'error',
    }
  })

export const mapStateToDrug = (state) => {
  return {
    drugId: state.value.id,
    drugName: state.value.name,
    dosage: state.dosage,
    packagingSize: state.packSize,
    duration: state.duration,
    quantity: parseInt(state.quantity),
    refills: parseInt(state.refills) || 0,
    directions: state.directions,
    notes: state.notes,
    strength: state.value.strength,
    pharmaceuticalForm: state.value.pharmaceuticalForm,
    indications: (state.indications || []).map((v) => {
      return {
        iri: v.iri,
        name: v.id,
      }
    }),
    reviewDate: state.renewalDate,
    repeats: state.numRepeats ? state.numRepeats : null,
    repeatableDrug: state.repeatable,
    repeatable: state.repeatable,
  }
}

export const mapDrugToState = (drug, drugInfo) => {
  return {
    validationPending: true,
    value: drugInfo || { name: drug.drugName, strength: drug.strength },
    dosage: drug.dosage || '',
    duration: drug.duration || '',
    quantity: `${drug.quantity}`,
    refills: String(drug.refills || ''),
    packSize: drug.packagingSize || '',
    directions: drug.directions,
    notes: drug.notes,
    indications: (drug.indications || []).map((v) => {
      return {
        id: v.name,
        iri: v.iri,
        info: v.name,
      }
    }),
    renewalDate: drug.reviewDate || null,
    numRepeats: typeof drug.repeats === 'number' ? drug.repeats.toString() : '',
    repeatable: drug.repeatable,
    checks: '',
  }
}

export const mapCodesToState = (assessmentCodes, state) => {
  return {
    ...state,
    indications: (assessmentCodes || []).map((c) => {
      return {
        id: c.term,
        iri: c.iri,
        info: c.term,
      }
    }),
  }
}
