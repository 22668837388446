import classNames from 'classnames'
import React from 'react'

import { Tag } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './SelectValue.messages'
import styles from './styles.module.scss'

const SearchValue = ({ value, children }) => {
  const fm = useFormatMessage()
  const { generic, expensive, controlled, blacklisted, drugPreparation } = value

  return (
    <div className={classNames(styles.value)}>
      <div className={styles.valueTitle} data-testid="drug-name">
        {children}
      </div>
      <div className={styles.valueTags}>
        {blacklisted && (
          <Tag color="white-on-black" margin style={{ marginBottom: 0 }}>
            {fm(messages.blacklisted_tag_value)}
          </Tag>
        )}
        {drugPreparation && <Tag margin>{drugPreparation}</Tag>}
        {expensive && (
          <Tag color="white-on-orange" margin style={{ marginBottom: 0 }}>
            {fm(messages.expensive_tag_value)}
          </Tag>
        )}
        {controlled && (
          <Tag color="white-on-red" margin style={{ marginBottom: 0 }}>
            {fm(messages.controlled_tag_value)}
          </Tag>
        )}
        {generic === true && (
          <Tag color="white-on-green" margin style={{ marginBottom: 0 }}>
            {fm(messages.generic_tag_value)}
          </Tag>
        )}
        {generic === false && (
          <Tag margin style={{ marginBottom: 0 }}>
            {fm(messages.branded_tag_value)}
          </Tag>
        )}
      </div>
    </div>
  )
}

export default SearchValue
