import React, { ChangeEvent } from 'react'

import { Button, FormikNumberInput, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  PAT_MET_SAVE_HEIGHT,
  PAT_MET_UPDATE_HEIGHT,
  PATIENT_METRICS,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import Timestamp from '~/features/patient-metrics/utils/Timestamp'
import { PatientMetricObservationType } from '~/generated'

import {
  MeasurementType,
  usePatientMetricsState,
} from '../PatientMetricsStateProvider'
import BmiSectionHeader from './BmiSectionHeader'
import ImperialHeight from './ImperialHeight'
import { validateHeight } from './utils'

import messages from '../PatientMetricsModal.messages'
import styles from '../styles.module.scss'

interface WeightFormParams {
  measurementType: MeasurementType
  onUpdate: () => void
  timestamp?: string
}

const HeightForm: React.FC<WeightFormParams> = ({
  measurementType,
  onUpdate,
  timestamp,
}) => {
  const fm = useFormatMessage()

  const {
    values: { height },
    displayValues: { displayHeight, performerHeight },
    handleTaggedSubmit,
    setFieldValue,
    submitInfo,
    errors,
  } = usePatientMetricsState()

  const submitting = submitInfo.submitting.height
  const mutationError = submitInfo.error.height

  const displayHeightLabel =
    displayHeight &&
    `${displayHeight} ${fm(messages.height_label_cm_abbreviation)}`

  const handleClick = async () => {
    try {
      await handleTaggedSubmit(PatientMetricObservationType.Height)
      onUpdate()
      setFieldValue('height', null)

      analytics.trackEvent({
        label: displayHeight ? PAT_MET_UPDATE_HEIGHT : PAT_MET_SAVE_HEIGHT,
        category: PATIENT_METRICS,
        action: UPDATE_PATIENT_METRICS,
      })
    } catch (e) {
      // NOOP
    }
  }

  return (
    <div data-testid="patient-metrics-height-form">
      <Grid
        templateColumns="3fr 1fr"
        justifyContent="center"
        className={styles.topMargin}
      >
        <Grid columns={1} templateRows="30px 65px" flow="column" columnGap={40}>
          {measurementType === MeasurementType.metric ? (
            <>
              <BmiSectionHeader
                label={fm(messages.height_section_label)}
                htmlFor="height"
              >
                {displayHeightLabel}
                <Timestamp
                  performer={performerHeight}
                  timestamp={timestamp}
                  className={styles.timestamp}
                />
              </BmiSectionHeader>
              <Grid columns={5}>
                <FormikNumberInput
                  id="height"
                  label={fm(messages.height_label_cm)}
                  name="height"
                  placeholder={fm(messages.height_placeholder_cm)}
                  validate={validateHeight(fm)}
                  autoComplete="off"
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    const eventValue = event.target.value

                    setFieldValue(
                      event.target.name,
                      eventValue === ''
                        ? undefined
                        : Number(eventValue).toFixed(1)
                    )
                  }}
                />
              </Grid>
            </>
          ) : (
            <ImperialHeight timestamp={timestamp} />
          )}
        </Grid>
        <div className={styles.submitButton}>
          <Button
            style={{ minWidth: '90px' }}
            type="button"
            loading={submitting}
            disabled={!height || !!errors.height}
            data-testid="height-submit-button"
            onClick={handleClick}
          >
            {displayHeight
              ? fm(messages.submit_button_update_label)
              : fm(messages.submit_button_save_label)}
          </Button>
        </div>
      </Grid>
      {mutationError && (
        <div className={styles.mutationErrorMessage}>
          <Text color="error">
            {fm(messages.error_patient_metrics_mutation_failure)}
          </Text>
        </div>
      )}
    </div>
  )
}

export default HeightForm
