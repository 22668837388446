import { EventEmitter2 } from 'eventemitter2'
import React from 'react'

export type EventBusType = EventEmitter2

export const EventBusContext = React.createContext<EventBusType | undefined>(
  undefined
)

interface EventBusProviderProps {
  children: React.ReactNode
  value: EventBusType
}

export const EventBus = EventEmitter2

export const EventBusProvider = ({
  children,
  value,
}: EventBusProviderProps) => (
  <EventBusContext.Provider value={value}>{children}</EventBusContext.Provider>
)
