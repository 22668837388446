import { defineMessages } from 'react-intl'

const baseId = 'consultation.complete_dialog.care_plans_extension'

export default defineMessages({
  error_fetching_care_plans: {
    id: `${baseId}.error_fetching_care_plans`,
    defaultMessage:
      'Failed to load care plans. Please try again or contact support',
  },
  invalid_care_plan: {
    id: `${baseId}.invalid_care_plan`,
    defaultMessage:
      'The care plan draft is invalid. Please update it before completing the consultation',
  },
  error_promoting_care_plan: {
    id: `${baseId}.error_promoting_care_plan`,
    defaultMessage: 'Error promoting care plan',
  },
})
