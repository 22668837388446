import { defineMessages } from 'react-intl'

const baseId = 'patient_alert_form'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient alert',
  },
  message_box_label: {
    id: `${baseId}.message_box_label`,
    defaultMessage: 'Message*',
  },
  message_box_placeholder: {
    id: `${baseId}.message_box_placeholder`,
    defaultMessage: 'Alert description',
  },
  message_validation_required: {
    id: `${baseId}.message_validation_required`,
    defaultMessage: 'Required field',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  create_button_label: {
    id: `${baseId}.create_button_label`,
    defaultMessage: 'Create',
  },
  remove_button_label: {
    id: `${baseId}.remove_button_label`,
    defaultMessage: 'Remove',
  },
  update_button_label: {
    id: `${baseId}.update_button_label`,
    defaultMessage: 'Update',
  },
})
