import { useEffect, useState } from 'react'

interface Zopim {
  (callback: () => void): void
  livechat: {
    window: {
      show(): void
    }
    departments: {
      filter(...departments: (string | number)[]): void
      setVisitorDepartment(department: string | number): void
    }
  }
}

declare global {
  interface Window {
    zE?: (callback?: () => void) => void
    $zopim?: Zopim
  }
}

const liveChatReady = (callback: (zopim: Zopim) => void) => {
  window.zE?.(() => {
    window.$zopim?.(() => {
      if (window.$zopim) {
        callback(window.$zopim)
      }
    })
  })
}

/**
 *
 * @param department - name or ID of the department
 */
export const setDepartment = (department: number | string) => {
  liveChatReady(($zopim) => {
    $zopim.livechat.departments.filter('')
    $zopim.livechat.departments.setVisitorDepartment(department)
  })
}

export const show = () => {
  liveChatReady(($zopim) => {
    $zopim.livechat.window.show()
  })
}

export const useZendeskChat = () => {
  const [zendeskReady, setZendeskReady] = useState(false)

  useEffect(() => {
    liveChatReady(() => {
      console.log('Zendesk is ready')
      setZendeskReady(true)
    })
  }, [])

  return zendeskReady
}
