import useDurationTracker, { DurationTrackerProps } from './useDurationTracker'
import useModalViewTracker, {
  ModalViewTrackerProps,
} from './useModalViewTracker'

type ModalViewAndDurationTrackerProps = DurationTrackerProps &
  ModalViewTrackerProps

const useModalViewAndDurationTracker = ({
  category,
  action,
  label,
  modalName,
}: ModalViewAndDurationTrackerProps) => {
  useDurationTracker({ category, action, label })
  useModalViewTracker({ modalName })
}

export default useModalViewAndDurationTracker
