import { useMutation } from '@apollo/client'
import { Form, Formik, FormikProps } from 'formik'
import React, { FC, useState } from 'react'
import getUuid from 'uuid-by-string'

import { Button, Cell, Grid, Spinner, Text } from '@babylon/core-ui'
import { useFormatMessage, useIntl } from '@babylon/intl'

import { WORKFLOW_CATEGORY } from '~/constants/analytics'
import { isFilledArray } from '~/core'
import analytics from '~/core/analytics'
import { logException } from '~/core/sentry'
import { useUpdateQueryContext } from '~/core/update-query-provider/UpdateQueryProvider'
import {
  getWorkflowSubType,
  getWorkflowSubTypeLabel,
  parseDefaultValue,
} from '~/features/workflows/WorkflowActions/utils'
import {
  CpWorkflowContextQuery,
  useWorkflowDefinitionQuery,
  WorkflowDefinitionVariable,
  WorkflowDefinitionVariableMetadataSource,
  WorkflowDefinitionVariableMetadataSourceType,
  WorkflowDefinitionVariableMetadataType,
  WorkflowV2,
} from '~/generated'
import ClinicalConsentMessage from '~/ui/ClinicalConsentMessage'
import { ErrorPanel } from '~/ui/ErrorPanel'
import Message from '~/ui/Message'

import {
  mapConsultationDataToWorkflowVariables,
  mapFormValuesToWorkflowVariables,
} from '../mappings'
import { getDefaultValue, getFieldMetadata } from '../utils'
import {
  CREATE_WORKFLOW_MUTATION,
  MutationData,
  MutationVariables,
} from './mutations'
import TriggerWorkflowDialog from './TriggerWorkflowDialog'
import WorkflowDefinitionField from './WorkflowDefinitionField'

import styles from './styles.module.scss'
import messages from './WorkflowDefinitionForm.messages'

export interface Props {
  workflowDefinitionId: string
  workflowDefinitionKey: string | undefined
  tenantId: number | null | undefined
  consultantId: string
  consultantName: string
  closeModal: () => void
  consultation: CpWorkflowContextQuery['consultation']
  action: string
}

interface WorkflowDefinitionFormValues {
  [key: string]: any
}

const WorkflowDefinitionForm: FC<Props> = ({
  workflowDefinitionId,
  workflowDefinitionKey,
  tenantId,
  consultantId,
  consultantName,
  closeModal,
  consultation,
  action,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [dialogMode, setDialogMode] = useState<'successful' | 'duplicate'>(
    'successful'
  )
  const { locale } = useIntl()
  const fm = useFormatMessage()
  const { updateWorkflowsV2Query } = useUpdateQueryContext()

  const { data, loading, error, refetch } = useWorkflowDefinitionQuery({
    variables: {
      id: workflowDefinitionId,
      locale,
    },
    notifyOnNetworkStatusChange: true,
  })
  const workflowDefinition = data?.workflowDefinition ?? null

  const [
    triggerWorkflowMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<MutationData, MutationVariables>(CREATE_WORKFLOW_MUTATION, {
    onCompleted: ({ triggerWorkflow }: MutationData) => {
      const subTypeValue = getWorkflowSubType(triggerWorkflow)
      const subTypeLabel = getWorkflowSubTypeLabel(
        workflowDefinition?.group.type,
        subTypeValue
      )

      if (updateWorkflowsV2Query) {
        updateWorkflowsV2Query((prev) => {
          const previousWorkflows: WorkflowV2[] =
            prev.consultationWorkflowsV2.workflows.results

          const existingWorkflow = previousWorkflows.some(
            (workflow) => workflow.id === triggerWorkflow.id
          )

          setDialogMode(existingWorkflow ? 'duplicate' : 'successful')

          const results = [
            ...previousWorkflows,
            !existingWorkflow && triggerWorkflow,
          ].filter(Boolean)

          return {
            ...prev,
            consultationWorkflowsV2: {
              ...prev.consultationWorkflowsV2,
              workflows: {
                ...prev.consultationWorkflowsV2.workflows,
                results,
              },
            },
          }
        })
      }

      const urgency = triggerWorkflow.variables.find(
        (variable) => variable.name === 'urgent'
      )

      analytics.trackEvent({
        category: WORKFLOW_CATEGORY,
        action: `Add ${action}`,
        label: subTypeLabel,
        value: urgency?.value === '1' ? 1 : 0,
      })

      setShowDialog(true)
    },
  })

  if (loading) {
    return <Spinner color="#333" centered />
  }

  if (error) {
    return (
      <ErrorPanel
        error={error}
        title={fm(messages.error_message, { action })}
        retry={() => refetch()}
        center
        fill="container"
      />
    )
  }

  const assistiveTexts = workflowDefinition?.group.assistive_texts ?? []
  const label = workflowDefinition?.group.label ?? ''
  const fields = workflowDefinition?.variables ?? []
  const userFields =
    fields &&
    fields.filter((field: WorkflowDefinitionVariable) => {
      const fieldMetadata = getFieldMetadata(
        field.metadata,
        WorkflowDefinitionVariableMetadataType.Source
      )

      const [sourceMetadata] = fieldMetadata
      const source = sourceMetadata.value as WorkflowDefinitionVariableMetadataSource

      return (
        source.type !== WorkflowDefinitionVariableMetadataSourceType.Context
      )
    })

  const initialWorkflowFormState =
    userFields &&
    userFields.reduce((values, field) => {
      const initialValue = getDefaultValue(field)

      return {
        ...values,
        [field.name]: parseDefaultValue(initialValue),
      }
    }, {})

  const handleSubmit = async (values: any) => {
    if (consultation?.patient && workflowDefinitionKey && fields) {
      const patientId = consultation.patient.id
      const serializedVariables = JSON.stringify(values)
      const idempotencyKey = getUuid(
        `${consultantId}_${patientId}_${workflowDefinitionId}_${serializedVariables}`
      )

      try {
        await triggerWorkflowMutation({
          variables: {
            idempotency_key: idempotencyKey,
            workflow_definition_key: workflowDefinitionKey,
            tenant_id: tenantId,
            variables: [
              ...mapConsultationDataToWorkflowVariables(
                consultation,
                consultantId,
                consultantName,
                fields
              ),
              ...mapFormValuesToWorkflowVariables(values),
            ],
          },
        })
      } catch (exception) {
        logException(exception)
      }
    }
  }

  const closeDialog = () => {
    setShowDialog(false)
    closeModal()
  }

  return (
    <>
      <TriggerWorkflowDialog
        show={showDialog}
        onClose={closeDialog}
        mode={dialogMode}
        groupName={label || ''}
      />
      {initialWorkflowFormState && (
        <Formik
          key={workflowDefinitionId}
          initialValues={initialWorkflowFormState}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
          }: FormikProps<WorkflowDefinitionFormValues>) => (
            <Form name="workflow-definition-form">
              <Grid>
                {userFields &&
                  userFields.map(
                    (field: WorkflowDefinitionVariable) =>
                      field && (
                        <WorkflowDefinitionField
                          key={field.name}
                          field={field}
                          fields={userFields}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )
                  )}
                {isFilledArray(assistiveTexts) &&
                  assistiveTexts.map(
                    ({ instruction }) =>
                      instruction && (
                        <Message key={instruction} type="info">
                          {instruction}
                        </Message>
                      )
                  )}
                <ClinicalConsentMessage />
                <Cell className={styles.submitRow}>
                  <Text color="error">
                    {mutationError &&
                      fm(messages.mutation_error_message, { action })}
                  </Text>
                  <Button type="submit" loading={mutationLoading}>
                    {fm(messages.submit)}
                  </Button>
                </Cell>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default WorkflowDefinitionForm
