import { defineMessages } from 'react-intl'

const baseId = 'call_player.voice_button'

export default defineMessages({
  voice_loading: {
    id: `${baseId}.voice_loading`,
    defaultMessage: 'Preparing audio call',
  },
  voice_ready: {
    id: `${baseId}.voice_ready`,
    defaultMessage: 'Start audio call',
  },
  voice_active: {
    id: `${baseId}.voice_active`,
    defaultMessage: 'Stop audio call',
  },
  voice_critically_failed: {
    id: `${baseId}.voice_critically_failed`,
    defaultMessage: 'Unable to load audio calls',
  },
  video_loading: {
    id: `${baseId}.video_loading`,
    defaultMessage: 'Preparing video call',
  },
  video_ready: {
    id: `${baseId}.video_ready`,
    defaultMessage: 'Start video call',
  },
  video_active: {
    id: `${baseId}.video_active`,
    defaultMessage: 'Stop video call',
  },
  video_critically_failed: {
    id: `${baseId}.video_critically_failed`,
    defaultMessage: 'Unable to load video calls',
  },
})
