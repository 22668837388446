import { ApolloClient, ApolloLink, RequestHandler } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'

import errorLink from './errorLink'
import graphqlMiddlewareHeadersLink from './graphqlMiddlewareHeadersLink'
import graphqlMiddlewareHttpLink from './graphqlMiddlewareHttpLink'
import headersLink from './headersLink'
import inMemoryCacheConfig from './inMemoryCacheConfig'
import loggerLink from './loggerLink'
import platformGatewayHttpLink from './platformGatewayHttpLink'
import queueLink from './queueLink'

const isDevelopment = process.env.NODE_ENV !== 'production'

const graphqlMiddlewareLinks = [
  errorLink,
  headersLink,
  graphqlMiddlewareHeadersLink,
  queueLink,
  graphqlMiddlewareHttpLink,
] as (ApolloLink | RequestHandler)[]
const platformGatewayLinks = [errorLink, headersLink, platformGatewayHttpLink]

if (isDevelopment) {
  graphqlMiddlewareLinks.unshift(loggerLink)
  platformGatewayLinks.unshift(loggerLink)
}

const graphqlMiddlewareLink = ApolloLink.from(graphqlMiddlewareLinks)
const platformGatewayLink = ApolloLink.from(platformGatewayLinks)

export default new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'platform-gateway',
    platformGatewayLink,
    graphqlMiddlewareLink
  ),
  cache: new InMemoryCache(inMemoryCacheConfig),
  connectToDevTools: isDevelopment,
  queryDeduplication: true,
})
