import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Text } from '@babylon/core-ui'

import { ReactComponent as Heart } from '~/assets/heart.svg'
import { useMessages } from '~/core/hooks'

import messages from './CallQualityFeedback.messages'
import styles from './styles.module.scss'

const ThankYou = () => {
  const f = useMessages(messages)

  return (
    <div className={styles.wrapper}>
      <div className={styles.thankYou}>
        <Heart />
        <Text size="large">
          <ReactMarkdown source={f('thank_you_message')} />
        </Text>
      </div>
    </div>
  )
}

export default ThankYou
