import { defineMessages } from 'react-intl'

const baseId = 'dashboard.navigation'

export default defineMessages({
  all_consultations_text: {
    id: `${baseId}.all_consultations_text_v2`,
    defaultMessage: 'All consultations',
  },
})
