export const defaultState = {
  category: null,
  specialism: null,
  postcode: null,
  specialist: null,
  comments: '',
  showSpecialistSelection: false,
  isLookupLoading: false,
  options: [],
  urgent: false,
}

export const validation = {
  specialism: {
    type: Object,
    required: true,
    empty: false,
  },
  category: {
    type: Object,
    required: true,
    empty: false,
  },
  comments: {
    type: String,
    required: false,
  },
}

export const URGENT_VALUE = 'urgent'
