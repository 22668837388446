import { ConsultantUser, isConsultantUser } from './BabylonUser'
import useBabylonUser from './useBabylonUser'

const useConsultantUser = (): ConsultantUser => {
  const user = useBabylonUser()

  if (!isConsultantUser(user)) {
    throw new Error(
      'useConsultantUser should only be called inside BabylonUserProvider with `consultant` prop'
    )
  }

  return user
}

export default useConsultantUser
