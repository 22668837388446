import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from './styles.module.scss'

const positionOptions = [
  'top',
  'bottom',
  'left',
  'right',
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
]

class Popover extends Component {
  triggerRef = React.createRef()

  contentRef = React.createRef()

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = (e) => {
    const { visible, hide } = this.props
    if (!visible || this.elementContainsTarget(this.contentRef, e.target)) {
      return
    }
    hide()
    if (this.elementContainsTarget(this.triggerRef, e.target)) {
      e.stopPropagation()
    }
  }

  elementContainsTarget = (reactRef, target) =>
    !!reactRef && reactRef.current.contains(target)

  getPopoverClassName = () => {
    const { popoverClassName, position } = this.props
    const hasValidPosition = positionOptions.indexOf(position) !== -1

    return classnames(
      styles.popover,
      popoverClassName,
      hasValidPosition && styles[`position-${position}`]
    )
  }

  handleChildrenClick = () => {
    const { showOnClick, show } = this.props

    if (showOnClick) {
      show()
    }
  }

  render() {
    const {
      className,
      content,
      children,
      width,
      visible,
      hide,
      dataTestId,
    } = this.props
    const componentClassName = classnames(styles.popoverComponent, className)

    return (
      <span className={componentClassName} data-testid={dataTestId}>
        <span onClick={this.handleChildrenClick} ref={this.triggerRef}>
          {children}
        </span>
        {visible && (
          <div
            ref={this.contentRef}
            style={{ width }}
            className={this.getPopoverClassName()}
          >
            {content(hide)}
          </div>
        )}
      </span>
    )
  }
}

Popover.defaultProps = {
  width: null,
  popoverClassName: '',
  visible: false,
  showOnClick: false,
  position: 'bottom-right',
  dataTestId: 'popover',
}

Popover.propTypes = {
  visible: PropTypes.bool,
  showOnClick: PropTypes.bool,
  popoverClassName: PropTypes.string,
  position: PropTypes.string,

  children: PropTypes.element.isRequired,
  content: PropTypes.func.isRequired,

  show: PropTypes.func,
  hide: PropTypes.func,
}

export default Popover
