import React from 'react'

import { Tag } from '@babylon/core-ui'
import { formatDate } from './date'

const flagTag = (flag, isNormalResult) => (
  <Tag color={isNormalResult ? 'clinical-blue' : 'clinical-red'}>{flag}</Tag>
)

const renderRange = ({ lower, upper, labRange }) => {
  if (lower != null && upper != null) {
    return `${lower} - ${upper}`
  }

  if (lower != null && upper == null) {
    return `> ${lower}`
  }

  if (lower == null && upper != null) {
    return `< ${upper}`
  }

  return labRange
}

export const mapPanel = (report) => ({
  id: report.id,
  name: report.name,
  patientId: report.patientId,
  labName: report.labName,
  state: report.state,
  createdAt: formatDate(report.createdOn, { format: 'long', showTime: true }),
  reviewedAt: formatDate(report.reviewedOn, {
    format: 'long',
    showTime: true,
  }),
  reviewer: report.reviewer && report.reviewer.fullName,
  sentAt: formatDate(report.sentOn, { format: 'long', showTime: true }),
  sender: report.sender && report.sender.fullName,
  appointmentSuggested: report.appointmentSuggested ? 'Yes' : 'No',
  additionalInfo: report.additionalInfo,
  supportMessage: report.supportMessage,
})

export const mapProfile = (profile) => ({
  name: profile.name,
  code: profile.code,
  reviewerComments: profile.reviewerComments,
  additionalInfo: profile.additionalInfo,
  biomarkers: profile.biomarkers ? profile.biomarkers.map(mapBiomarker) : null,
})

export const mapBiomarker = (result) => {
  // calculated values
  const normalResult = result.flag !== 'HIGH' && result.flag !== 'LOW'
  const validResult =
    !result.failed && !!(result.valueNumeric || result.valueString)

  return {
    name: result.name,
    value: result.valueNumeric ? result.valueNumeric : result.valueString,
    valueNumeric: result.valueNumeric,
    valuePercentage: result.valuePercentage,
    valueString: result.valueString,
    failed: result.failed,
    failureReason: result.failureReason,
    units: validResult ? result.units : null,
    referenceRange:
      validResult && result.ranges && result.ranges[0]
        ? renderRange(result.ranges[0])
        : null,
    recognised: result.recognisedBiomarker && result.recognisedValue,
    notes: result.notes,
    flag: result.flag ? flagTag(result.flag, normalResult) : '-',
    normalResult,
    validResult,
  }
}

export const mapPatient = (patient) => ({
  name: patient.firstName
    ? `${patient.firstName || ''} ${patient.lastName || ''}`
    : null,
  gender: patient.gender,
  dob: patient.dateOfBirth
    ? formatDate(patient.dateOfBirth, { format: 'long' })
    : null,
  age: patient.age,
  email: patient.email,
  phone: `${patient.phoneCountryCode} ${patient.phoneNumber}`,
})

export default {
  mapPanel,
  mapBiomarker,
  mapPatient,
  mapProfile,
}
