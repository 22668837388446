import { defineMessages } from 'react-intl'

const baseId = 'header.profile'

export default defineMessages({
  logout_text: {
    id: `${baseId}.logout_text_v2`,
    defaultMessage: 'Log out',
  },
  language: {
    id: `${baseId}.language`,
    defaultMessage: 'Language',
  },
  profile_title: {
    id: `${baseId}.profile_title`,
    defaultMessage: 'Profile',
  },
  profile_image: {
    id: `${baseId}.profile_image`,
    defaultMessage: 'Profile image',
  },
  therapy_toggle_title: {
    id: `${baseId}.therapy_toggle_title`,
    defaultMessage: 'Available for new therapy patients',
  },
})
