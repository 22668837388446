export interface Subscription {
  unsubscribe(): void
}

export interface Subscribable<T> {
  subscribe(onNext: (value: T) => void): Subscription
}

export interface Observable<T, Next = T> extends Subscribable<T> {
  next: (value: Next) => void
}

export interface Observer<T, Next = T> {
  closed?: boolean
  next: (value: Next) => void
  error?: (err: any) => void
  complete?: () => void
}

export interface ObservableValue<T, Next = T> extends Observable<T, Next> {
  get: () => T | undefined
}

export const observableValue = <T>(value: T): ObservableValue<T> => {
  const observers: Observer<T>[] = []

  return {
    get: () => {
      return value
    },

    next: (nextValue: T) => {
      value = nextValue
      observers.forEach((observer) => observer.next(value))
    },

    subscribe: (onNext: (value: T) => void) => {
      const observer = { next: onNext }

      observers.push(observer)

      return {
        unsubscribe: () => {
          const idx = observers.lastIndexOf(observer)
          if (idx !== -1) {
            observers.splice(idx, 1)
          }
        },
      }
    },
  }
}
