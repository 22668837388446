import get from 'lodash/fp/get'
import React, { FC, ReactNode } from 'react'
import { Field, FieldProps } from 'formik'

import { Textarea } from '../..'
import FormField from '../FormField'

export interface FormikTextareaProps {
  id?: string
  label?: ReactNode
  name: string
  placeholder?: string
  validate?: (value: any) => undefined | string | Promise<any>
}

const FormikTextarea: FC<FormikTextareaProps> = ({
  id,
  name,
  label,
  placeholder,
  validate,
  ...rest
}) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors, touched },
      field: { value, onChange, onBlur },
    }: FieldProps) => {
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)
      const intent = displayError ? 'error' : null

      return (
        <FormField
          id={id}
          label={label}
          errorMessage={errorMessage}
          displayError={displayError}
        >
          <Textarea
            id={id}
            name={name}
            intent={intent}
            placeholder={placeholder}
            fill
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
          />
        </FormField>
      )
    }}
  </Field>
)

export default FormikTextarea
