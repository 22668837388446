import cn from 'classnames'
import React from 'react'

import CloseSVG from './Close'

import styles from './styles.module.scss'

const ClinicalCode = ({
  children,
  isFocused = false,
  onRemove = null,
  ...data
}) => (
  <span
    {...data}
    className={cn(styles.clinicalCode, isFocused && styles.focused)}
    contentEditable={false}
  >
    <span data-testid="added-clinical-code">{children}</span>
    {onRemove && (
      <span className={styles.removeButtonPlaceholder}>
        <button
          type="button"
          className={styles.removeButton}
          onClick={() => {
            onRemove(data)
          }}
        >
          <CloseSVG className={styles.removeButtonIcon} />
        </button>
      </span>
    )}
  </span>
)

export default ClinicalCode
