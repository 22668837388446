import React, { useEffect, useState } from 'react'

import { Config, ModuleConfig } from '~/core/container'

import { ObservableValue } from '../observable'
import { resolve } from './config'

type ConfigDataContext = ObservableValue<Record<string, ModuleConfig>>

interface ConfiguratorProps {
  configData: Config
}

const Configurator = ({ configData }: ConfiguratorProps) => {
  const [layout, setLayout] = useState<React.ReactNode>()

  useEffect(() => {
    const init = async () => {
      const config: ConfigDataContext = await resolve('configDataContext')
      config.next(configData)
      setLayout(await resolve('layout'))
    }

    init()
  }, [configData])

  return <div>{layout}</div>
}

export default Configurator
