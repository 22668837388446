import React, { useEffect } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'
import { Spinner, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import FreeText from './FreeText'
import MultiSelect from './MultiSelect'
import SingleSelect from './SingleSelect'
import StarRating from './StarRating'
import ThankYou from './ThankYou'
import useFeedbackQuestionQuery from './useFeedbackQuestionQuery'
import useSendFeedbackMutation from './useSendFeedbackMutation'

import messages from './CallQualityFeedback.messages'
import styles from './styles.module.scss'

enum QuestionType {
  STAR_RATING = 'star_rating',
  MULTI_SELECT = 'multi_select',
  FREE_TEXT = 'free_text',
  SINGLE_SELECT = 'single_select',
}

interface CallQualityFeedbackProps {
  consultationId: string
  onCompleted: () => void
  visible: boolean
}

const CallQualityFeedback = ({
  consultationId,
  onCompleted,
  visible,
}: CallQualityFeedbackProps) => {
  const user = useConsultantUser()
  const f = useMessages(messages)

  const { data, loading, error } = useFeedbackQuestionQuery(consultationId)

  const [
    sendFeedbackMutation,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useSendFeedbackMutation()

  const questionFromQuery = data?.multimediaFeedbackQuestion
  const firstQuestion =
    questionFromQuery?.position === 1 ? questionFromQuery : null
  const questionFromMutation =
    mutationData?.submitMultimediaFeedback?.next_question
  const currentQuestion = questionFromMutation || firstQuestion

  useEffect(() => {
    if (error || mutationError || !currentQuestion) {
      onCompleted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, mutationError, currentQuestion])

  useEffect(() => {
    if (
      mutationData?.submitMultimediaFeedback &&
      !mutationData.submitMultimediaFeedback.next_question
    ) {
      setTimeout(onCompleted, 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationData?.submitMultimediaFeedback])

  if (!visible) {
    return null
  }

  if (mutationLoading) {
    return (
      <div className={styles.wrapper}>
        <Text>{f('submitting')}</Text>
        <div style={{ marginTop: 16 }}>
          <Spinner color="#fff" centered />
        </div>
      </div>
    )
  }

  if (loading || error || mutationError || !currentQuestion) {
    return null
  }

  if (
    mutationData?.submitMultimediaFeedback &&
    !mutationData.submitMultimediaFeedback.next_question
  ) {
    return <ThankYou />
  }

  const commonFields = {
    consultation_id: consultationId,
    question_id: currentQuestion.id,
    client_type: 'clinician',
    client_id: user.uuid,
  }

  const {
    question: questionText,
    question_type: questionType,
    suggested_answers: suggestedAnswers,
  } = currentQuestion

  if (questionType === QuestionType.STAR_RATING) {
    return (
      <StarRating
        questionText={questionText}
        onSubmit={(rating) => {
          sendFeedbackMutation({
            variables: { input: { ...commonFields, rating } },
          })
        }}
        onDismiss={onCompleted}
      />
    )
  }

  if (questionType === QuestionType.SINGLE_SELECT && suggestedAnswers) {
    return (
      <SingleSelect
        questionText={questionText}
        suggestedAnswers={suggestedAnswers}
        onSubmit={(answerId) => {
          sendFeedbackMutation({
            variables: { input: { ...commonFields, answer_ids: [answerId] } },
          })
        }}
      />
    )
  }

  if (questionType === QuestionType.MULTI_SELECT && suggestedAnswers) {
    return (
      <MultiSelect
        questionText={questionText}
        suggestedAnswers={suggestedAnswers}
        onSubmit={(answerIds) => {
          sendFeedbackMutation({
            variables: { input: { ...commonFields, answer_ids: answerIds } },
          })
        }}
        onDismiss={onCompleted}
      />
    )
  }

  if (questionType === QuestionType.FREE_TEXT) {
    return (
      <FreeText
        questionText={questionText}
        onSubmit={(comment) => {
          sendFeedbackMutation({
            variables: { input: { ...commonFields, comments: [comment] } },
          })
        }}
        onDismiss={onCompleted}
      />
    )
  }

  return null
}

export default CallQualityFeedback
