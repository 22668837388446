import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { UserType } from '@babylon/connect-client-core'

import {
  ERROR_START_VIDEO_SESSION_ACTION,
  MULTIMEDIA_CATEGORY,
  START_VIDEO_SESSION_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import {
  CallStep,
  CpStartVideoCallMutation,
  CpStartVideoCallMutationVariables,
} from '~/generated'

import { Action, ErrorActionType } from '../CallStatusReducerModelProvider'
import useReportCallStep from '../useReportCallStep'

export const START_VIDEO_CALL = gql`
  mutation CpStartVideoCall(
    $consultationId: ID!
    $sessionId: ID!
    $activeParticipants: [VideoSessionUserTypes!]!
  ) {
    startVideoCall(
      consultationId: $consultationId
      sessionId: $sessionId
      activeParticipants: $activeParticipants
    )
  }
`

interface CpStartVideoCallMutationVariablesForCP
  extends Omit<CpStartVideoCallMutationVariables, 'activeParticipants'> {
  activeParticipants: UserType[]
}

type UseStartVideoCallMutationProps = {
  consultationId: string
  dispatch: React.Dispatch<Action>
  activeParticipants: UserType[]
}

const trackEvent = analytics.trackEventFactory({
  category: MULTIMEDIA_CATEGORY,
})

const useStartVideoCallMutation = ({
  dispatch,
  consultationId,
  activeParticipants,
}: UseStartVideoCallMutationProps) => {
  const [startVideoCall, result] = useMutation<
    CpStartVideoCallMutation,
    CpStartVideoCallMutationVariablesForCP
  >(START_VIDEO_CALL)
  const [reportCallStep] = useReportCallStep(consultationId)
  const performMutation = useCallback(
    async (sessionId: string) => {
      try {
        reportCallStep(CallStep.MultimediaCallServiceInvoked)

        await startVideoCall({
          variables: {
            consultationId,
            sessionId,
            activeParticipants,
          },
        })

        trackEvent({
          action: START_VIDEO_SESSION_ACTION,
          label: sessionId,
        })
      } catch (error) {
        trackEvent({
          action: ERROR_START_VIDEO_SESSION_ACTION,
          label: sessionId,
        })

        dispatch({
          type: ErrorActionType.VIDEO_START_ERROR,
          error,
        })
      }
    },
    [
      startVideoCall,
      reportCallStep,
      consultationId,
      dispatch,
      activeParticipants,
    ]
  )

  return [performMutation, result] as const
}

export default useStartVideoCallMutation
