import React from 'react'

import { SickNotesSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useSickNotesQuery } from '~/generated'

import SickNotesSummary from './SickNotesSummary'

const SickNotesSummaryModule: SickNotesSummaryPluginInterface = () => {
  const SickNotesSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const { data, loading, error, refetch } = useSickNotesQuery({
      variables: { id: consultation.id },
    })

    const sickNotes = data?.consultation?.sickNotes || []

    return (
      <SickNotesSummary
        sickNotes={sickNotes}
        loading={loading}
        error={error}
        refetch={refetch}
      />
    )
  }

  return SickNotesSummaryWrapper
}

export default SickNotesSummaryModule
