import { GraphQLErrorWithExtensions } from '~/core/graphql/errors'

const formatGraphQLError = (graphQLError: GraphQLErrorWithExtensions) => {
  const responseExtension = graphQLError.extensions?.response

  if (!responseExtension || !responseExtension?.url) {
    return 'REQUEST_ERROR'
  }

  let urlObj
  try {
    urlObj = new URL(responseExtension.url)
  } catch (err) {
    return 'REQUEST_ERROR'
  }

  const { hostname, pathname } = urlObj

  const subdomain = hostname.split('.').filter(Boolean)[0]
  const apiName = pathname.split('/').filter(Boolean)[0]
  const status = responseExtension?.status

  return (
    subdomain + (apiName ? `/${apiName}` : '') + (status ? ` ${status}` : '')
  )
}

export const formatReadableErrorList = (
  graphqlErrors: readonly GraphQLErrorWithExtensions[]
): string[] => {
  const errorCodes: string[] = graphqlErrors.map(formatGraphQLError)
  // errorCodes example value
  // ['services-uk/consults 404', 'services-uk/consults 404', 'app-uk/clinical_portal 500']

  const errorCodeCountMap = errorCodes.reduce<Record<string, number>>(
    (map, errorCode) => {
      const prevCount = map[errorCode]
      const count = prevCount ? prevCount + 1 : 1
      return {
        ...map,
        [errorCode]: count,
      }
    },
    {}
  )
  // errorCodeCountMap example value
  // => {'services-uk/consults 404': 2, 'app-uk/clinical_portal 500': 1}

  const errorCodesWithCounts: string[] = Object.keys(errorCodeCountMap).map(
    (errorCode) => {
      const count = errorCodeCountMap[errorCode]
      return (count > 1 ? `${count} × ` : '') + errorCode
    }
  )
  // errorCodesWithCounts example value
  // => ['2 × services-uk/consults 404', 'app-uk/clinical_portal 500']

  return errorCodesWithCounts
}
