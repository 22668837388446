import React, { Component } from 'react'

import styles from './styles.module.scss'

class Textarea extends Component {
  state = {
    height: '',
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.updateHeight()
    }
  }

  componentDidMount() {
    this.updateHeight()
  }

  getHeightRef = (ref) => {
    this.heightElement = ref
  }

  handleChange = (event) => {
    const { onChange } = this.props
    const { value } = event.target

    onChange && onChange(value)
  }

  updateHeight = () => {
    this.setState({
      height: this.heightElement && this.heightElement.offsetHeight,
    })
  }

  getTextareaStyle = () => {
    const { state, props } = this

    return {
      height: state.height || 'auto',
      ...props.style,
    }
  }

  render() {
    const { props } = this

    return (
      <div className={styles.textarea__wrapper}>
        <pre ref={this.getHeightRef} className={styles.textarea}>
          {props.value ? props.value.replace(/\n/g, '\n') : ''}
        </pre>
        <textarea
          className={styles.textarea}
          {...props}
          style={this.getTextareaStyle()}
          onChange={this.handleChange}
          value={this.props.value}
        />
      </div>
    )
  }
}

export default Textarea
