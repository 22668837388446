import { v4 as generateUuid } from 'uuid'

import { CarePlanDraftMutators } from '../useCarePlanDraftMutators'
import { CarePlanFormValues } from './CarePlanModalForm'

export const addDraftIdToLocalGoal = (
  goalDraftId: string,
  localCarePlanDraft: CarePlanFormValues,
  localGoalId: number
): CarePlanFormValues => {
  const indexOfGoalToUpdate = localCarePlanDraft.goals.findIndex(
    (goal) => goal.localId === localGoalId
  )

  if (indexOfGoalToUpdate !== -1) {
    return {
      ...localCarePlanDraft,
      goals: localCarePlanDraft.goals.map((goal) => {
        if (goal.localId === localGoalId) {
          return {
            ...goal,
            draftId: goalDraftId,
          }
        }

        return goal
      }),
    }
  }

  return localCarePlanDraft
}

export const addDraftIdToLocalAction = (
  actionDraftId: string,
  goalDraftId: string,
  localCarePlanDraft: CarePlanFormValues,
  localActionId: number
): CarePlanFormValues => {
  return {
    ...localCarePlanDraft,
    goals: localCarePlanDraft.goals.map((goal) => {
      if (goal.draftId !== goalDraftId) {
        return goal
      }

      return {
        ...goal,
        associatedActionDrafts: goal.associatedActionDrafts.map((action) => {
          if (action.localId === localActionId) {
            return {
              ...action,
              draftId: actionDraftId,
            }
          }

          return action
        }),
      }
    }),
  }
}

export const saveGoal = async ({
  goalLocalId,
  goalDescription,
  localCarePlanDraft,
  carePlanDraftMutators,
  numberOfEdits,
  resetForm,
}: {
  goalLocalId: number
  goalDescription: string
  localCarePlanDraft: CarePlanFormValues
  carePlanDraftMutators: CarePlanDraftMutators
  numberOfEdits: number
  resetForm: () => void
}) => {
  if (!localCarePlanDraft.draftId) {
    throw new Error('localCarePlanDraft.draftId is not present')
  }

  const response = await carePlanDraftMutators.addGoalDraft({
    description: goalDescription,
    numberOfEdits,
    carePlanDraftId: localCarePlanDraft.draftId,
  })

  if (response.data?.addGoalDraft?.__typename === 'AddGoalDraftSuccess') {
    const successDetails = response.data.addGoalDraft
    const updatedValues = addDraftIdToLocalGoal(
      successDetails.goalDraft.draftId,
      localCarePlanDraft,
      goalLocalId
    )

    return {
      intermediateLocalCarePlanDraft: updatedValues,
      numberOfEdits:
        response.data.addGoalDraft.goalDraft.parentCarePlanDraft?.metadata
          .numberOfEdits || 0,
    }
  } else if (
    response.data?.addGoalDraft?.__typename ===
    'InvalidCarePlanDraftNumbersOfEditsError'
  ) {
    await resetForm()
  }

  throw new Error('Failed to save new goal')
}

export const deleteGoal = async ({
  goalDraftId,
  carePlanDraftMutators,
  numberOfEdits,
  localCarePlanDraft,
  resetForm,
}: {
  goalDraftId: string
  carePlanDraftMutators: CarePlanDraftMutators
  numberOfEdits: number
  localCarePlanDraft: CarePlanFormValues
  resetForm: () => void
}) => {
  if (!localCarePlanDraft.draftId) {
    throw new Error('localCarePlanDraft.draftId is not present')
  }

  const response = await carePlanDraftMutators.deleteGoalDraft({
    goalDraftId,
    numberOfEdits,
    carePlanDraftId: localCarePlanDraft.draftId,
  })

  if (response.data?.deleteGoalDraft.__typename === 'DeleteGoalDraftSuccess') {
    return (
      response.data.deleteGoalDraft.parentCarePlanDraft?.metadata
        .numberOfEdits || 0
    )
  } else if (
    response.data?.deleteGoalDraft?.__typename ===
    'InvalidCarePlanDraftNumbersOfEditsError'
  ) {
    await resetForm()
  }

  throw new Error('Failed to delete goal draft')
}

export const editGoal = async ({
  goalDraftId,
  goalDescription,
  carePlanDraftMutators,
  numberOfEdits,
  localCarePlanDraft,
  resetForm,
}: {
  goalDraftId: string
  goalDescription?: string
  carePlanDraftMutators: CarePlanDraftMutators
  numberOfEdits: number
  localCarePlanDraft: CarePlanFormValues
  resetForm: () => void
}) => {
  if (!localCarePlanDraft.draftId) {
    throw new Error('localCarePlanDraft.draftId is not present')
  }

  const response = await carePlanDraftMutators.editGoalDraft({
    goalDraftId,
    description: goalDescription || '',
    numberOfEdits,
    carePlanDraftId: localCarePlanDraft.draftId,
  })

  if (response.data?.editGoalDraft.__typename === 'EditGoalDraftSuccess') {
    return (
      response.data.editGoalDraft.goalDraft.parentCarePlanDraft?.metadata
        .numberOfEdits || 0
    )
  } else if (
    response.data?.editGoalDraft?.__typename ===
    'InvalidCarePlanDraftNumbersOfEditsError'
  ) {
    await resetForm()
  }

  throw new Error('Failed to persist edited goal')
}

export const addAndAssociateAction = async ({
  description,
  goalDraftId,
  actionLocalId,
  localCarePlanDraft,
  carePlanDraftMutators,
  numberOfEdits,
  resetForm,
}: {
  description: string
  goalDraftId: string
  actionLocalId: number
  localCarePlanDraft: CarePlanFormValues
  carePlanDraftMutators: CarePlanDraftMutators
  numberOfEdits: number
  resetForm: () => void
}) => {
  if (!localCarePlanDraft.draftId) {
    throw new Error('localCarePlanDraft.draftId is not present')
  }

  const response = await carePlanDraftMutators.addAndAssociateActionDraft({
    actionDraftId: generateUuid(),
    goalDraftId,
    description,
    numberOfEdits,
    carePlanDraftId: localCarePlanDraft.draftId,
  })

  if (
    response.data?.addActionDraft.__typename === 'AddActionDraftSuccess' &&
    response.data?.associateGoalAndActionDraft.__typename ===
      'AssociateGoalAndActionDraftSuccess'
  ) {
    const updatedValues = addDraftIdToLocalAction(
      response.data.addActionDraft.actionDraft.draftId,
      goalDraftId,
      localCarePlanDraft,
      actionLocalId
    )

    return {
      intermediateLocalCarePlanDraft: updatedValues,
      numberOfEdits:
        response.data.associateGoalAndActionDraft.actionDraft
          ?.parentCarePlanDraft?.metadata.numberOfEdits || 0,
    }
  } else if (
    response.data?.addActionDraft?.__typename ===
      'InvalidCarePlanDraftNumbersOfEditsError' ||
    response.data?.associateGoalAndActionDraft?.__typename ===
      'InvalidCarePlanDraftNumbersOfEditsError'
  ) {
    await resetForm()
  }

  throw new Error('Failed to save new action')
}

export const saveEditedAction = async ({
  actionDraftId,
  description,
  carePlanDraftMutators,
  numberOfEdits,
  localCarePlanDraft,
  resetForm,
}: {
  actionDraftId: string
  description: string
  carePlanDraftMutators: CarePlanDraftMutators
  numberOfEdits: number
  localCarePlanDraft: CarePlanFormValues
  resetForm: () => void
}) => {
  if (!localCarePlanDraft.draftId) {
    throw new Error('localCarePlanDraft.draftId is not present')
  }

  const response = await carePlanDraftMutators.editActionDraft({
    actionDraftId,
    description,
    numberOfEdits,
    carePlanDraftId: localCarePlanDraft.draftId,
  })

  if (response.data?.editActionDraft.__typename === 'EditActionDraftSuccess') {
    return (
      response.data.editActionDraft.actionDraft.parentCarePlanDraft?.metadata
        .numberOfEdits || 0
    )
  } else if (
    response.data?.editActionDraft?.__typename ===
    'InvalidCarePlanDraftNumbersOfEditsError'
  ) {
    await resetForm()
  }

  throw new Error('Failed to save edited action')
}

export const deleteAction = async ({
  actionDraftId,
  goalDraftId,
  carePlanDraftMutators,
  numberOfEdits,
  localCarePlanDraft,
  resetForm,
}: {
  actionDraftId: string
  goalDraftId: string
  carePlanDraftMutators: CarePlanDraftMutators
  latestLocalState: CarePlanFormValues
  numberOfEdits: number
  localCarePlanDraft: CarePlanFormValues
  resetForm: () => void
}): Promise<number> => {
  if (!localCarePlanDraft.draftId) {
    throw new Error('localCarePlanDraft.draftId is not present')
  }

  const response1 = await carePlanDraftMutators.dissociateGoalAndActionDraft({
    goalDraftId,
    actionDraftId,
    numberOfEdits,
    carePlanDraftId: localCarePlanDraft.draftId,
  })

  if (
    response1.data?.dissociateGoalAndActionDraft.__typename ===
    'DissociateGoalAndActionDraftSuccess'
  ) {
    const response2 = await carePlanDraftMutators.deleteActionDraft({
      actionDraftId,
      numberOfEdits:
        response1.data.dissociateGoalAndActionDraft.actionDraft
          ?.parentCarePlanDraft?.metadata.numberOfEdits || 0,
      carePlanDraftId: localCarePlanDraft.draftId,
    })

    if (
      response2.data?.deleteActionDraft.__typename ===
      'DeleteActionDraftSuccess'
    ) {
      return (
        response2.data.deleteActionDraft.parentCarePlanDraft?.metadata
          .numberOfEdits || 0
      )
    } else if (
      response2.data?.deleteActionDraft?.__typename ===
      'InvalidCarePlanDraftNumbersOfEditsError'
    ) {
      await resetForm()
    }
  } else if (
    response1.data?.dissociateGoalAndActionDraft?.__typename ===
    'InvalidCarePlanDraftNumbersOfEditsError'
  ) {
    await resetForm()
  }

  throw new Error('failed to delete action')
}
