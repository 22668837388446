import { isAfter, parseISO } from 'date-fns'
/*
export const isEmpty = value => {
  if (value) {
    return typeof value === 'object' ? Object.keys(value).length === 0 : !value
  }

  return true
}
*/

const today = new Date()

export const reduceToStringByKey = (array, key, separator) => {
  return array
    .reduce((accum, item) => {
      return item[key] ? [...accum, item[key]] : accum
    }, [])
    .join(separator)
}

export const validateString = (value) =>
  value !== '' && value !== undefined && value !== null

export const validateStrictlyPositiveNumber = (value) => {
  return validateString(value) && isFinite(value) && value > 0
}

export const validateFutureDate = (date) => {
  return date && isAfter(parseISO(date), today)
}

export const validate = (state) => {
  const drugValidation = validateString(state.value)
  const quantityValidation = validateStrictlyPositiveNumber(state.quantity)
  const repeatsValidation = state.repeatable
    ? validateStrictlyPositiveNumber(state.numRepeats)
    : true
  const repeatRenewDateValidation = state.repeatable
    ? validateFutureDate(state.renewalDate)
    : true
  const directionValidation = validateString(state.directions)
  const indicationValidations = state.indications.length > 0

  const isValid =
    drugValidation &&
    quantityValidation &&
    directionValidation &&
    indicationValidations &&
    repeatsValidation &&
    repeatRenewDateValidation

  return {
    drugValidation: drugValidation || !state.showValidation,
    quantityValidation: quantityValidation || !state.showValidation,
    directionValidation: directionValidation || !state.showValidation,
    indicationValidations: indicationValidations || !state.showValidation,
    repeatsValidation: repeatsValidation || !state.showValidation,
    repeatRenewDateValidation:
      repeatRenewDateValidation || !state.showValidation,
    isValid,
  }
}

export const createRefillOptions = (min = 0, max = 18) =>
  new Array(max + 1)
    .fill(null, min, max + 1)
    .map((_, index) => ({ label: String(index), value: String(index) }))
