import cn from 'classnames'
import React from 'react'

import styles from './InCallButton.module.scss'

interface InCallButtonProps {
  iconName: string
  onClick?: () => void
  onMouseDown?: () => void
  testId?: string
  className?: string
}

const InCallButton = ({
  onClick = () => null,
  onMouseDown = () => null,
  testId,
  iconName,
  className,
}: InCallButtonProps) => {
  return (
    <div
      className={cn(styles.videoButton, className)}
      role="button"
      tabIndex={0}
      onClick={() => onClick()}
      onMouseDown={() => onMouseDown()}
      data-testid={testId}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onClick()
        }
      }}
    >
      <i className="material-icons">{iconName}</i>
    </div>
  )
}

export default InCallButton
