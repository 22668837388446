import React, { useState } from 'react'

import { Button } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import MarkAsNoShowDialog from './MarkAsNoShowDialog'

import messages from './MarkAsNoShow.messages'

interface MarkAsNoShowProps {
  consultationId: string
}

const MarkAsNoShow = ({ consultationId }: MarkAsNoShowProps) => {
  const [dialogMounted, setDialogMounted] = useState(false)
  const format = useMessages(messages)

  return (
    <>
      {dialogMounted && (
        <MarkAsNoShowDialog
          consultationId={consultationId}
          onClose={() => setDialogMounted(false)}
        />
      )}
      <Button
        fill
        intent="secondary"
        onClick={() => {
          setDialogMounted(true)
        }}
      >
        {format('open_dialog_button_label')}
      </Button>
    </>
  )
}

export default MarkAsNoShow
