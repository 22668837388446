import { useEffect } from 'react'

import {
  ERROR_LOAD_VOICE_SESSION_ACTION,
  MULTIMEDIA_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useCpAudioSessionQuery } from '~/generated'

import { Action, ActionType } from '../CallStatusReducerModelProvider'

interface IuseVoiceSessionQuery {
  consultationId: string
  dispatch: React.Dispatch<Action>
}

const useVoiceSessionQuery = ({
  consultationId,
  dispatch,
}: IuseVoiceSessionQuery) => {
  const { data, ...result } = useCpAudioSessionQuery({
    variables: { id: consultationId },
    context: { batch: 'call-session-query' },
  })

  const { loading, error } = result

  useEffect(() => {
    if (!loading) {
      dispatch({ type: ActionType.VOICE_READY })
    } else {
      dispatch({ type: ActionType.VOICE_LOADING })
    }
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      analytics.trackEvent({
        category: MULTIMEDIA_CATEGORY,
        action: ERROR_LOAD_VOICE_SESSION_ACTION,
      })
    }
  }, [error])

  const consultationUuid = data?.consultation?.consultationId
  const patientId = data?.consultation?.patientId
  const connectionToken = data?.consultation?.audioSession?.token
  const encryptedVoiceParams =
    data?.consultation?.audioSession?.encryptedVoiceParams

  return {
    consultationUuid,
    patientId,
    connectionToken,
    encryptedVoiceParams,
    ...result,
  }
}

export default useVoiceSessionQuery
