import { sanitizeUrl } from '@braintree/sanitize-url'
import classnames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

const FileDownload = ({ icon, url, label }) => (
  <a className={styles.file} href={sanitizeUrl(url)} target={url}>
    {icon && <i className={classnames(styles.icon, icon)} />}
    <div className={styles.label}>{label}</div>
    <i
      className={classnames(
        styles.icon,
        styles.downloadIcon,
        'fas fa-download'
      )}
    />
  </a>
)

export default FileDownload
