import { defineMessages } from 'react-intl'

const baseId = 'workflow_definition_form'

export default defineMessages({
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: "We can't load {action} right now",
  },
  reload_fields: {
    id: `${baseId}.reload_fields_v2`,
    defaultMessage: 'Reload fields',
  },
  mutation_error_message: {
    id: `${baseId}.mutation_error_message`,
    defaultMessage: 'We failed to send this {action}. Please retry.',
  },
  retry: {
    id: `${baseId}.retry`,
    defaultMessage: 'Retry',
  },
  submit: {
    id: `${baseId}.submit`,
    defaultMessage: 'Send to ClinOps',
  },
})
