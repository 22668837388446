import { User } from '@babylon/babylon-user'

import { ProblemRecord } from './types'

export const isMyConsultation = (
  consultant: User['consultant'],
  record: ProblemRecord
): boolean => {
  if (record && consultant) {
    if (record.consultantId && (consultant.uuid || consultant.id)) {
      return (
        consultant.uuid === record.consultantId ||
        consultant.id === record.consultantId
      )
    }
  }

  return false
}
