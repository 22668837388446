import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.test_section.test'

export default defineMessages({
  test_name_key: {
    id: `${baseId}.test_name_key`,
    defaultMessage: 'Name',
  },
  test_type_key: {
    id: `${baseId}.test_type_key`,
    defaultMessage: 'Type',
  },
  test_reason_key: {
    id: `${baseId}.test_reason_key`,
    defaultMessage: 'Reason',
  },
})
