import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.sick_notes.remove'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Delete Sick Note',
  },
  message: {
    id: `${baseId}.message`,
    defaultMessage: 'Are you sure you want to delete the sick note?',
  },
  ok_button_label: {
    id: `${baseId}.ok_button_label`,
    defaultMessage: 'Remove',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
})
