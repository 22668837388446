import React from 'react'

const normalizeNumber = (number) => {
  return Math.max(0, number)
}

const withDropdown = ({ name }) => (Component) => {
  return class WithDropdown extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        mouse: -1,
        keyboard: -1,
      }
    }
    /**
     * Slate Plugin
     */

    slate = ({ enabled }) => {
      return {
        onKeyDown: (event, editor, next) => {
          if (!enabled()) {
            return next()
          }

          switch (event.key) {
            case 'ArrowUp': {
              this.setMouseFocus(null)
              return this.handleArrowUp(event)
            }

            case 'ArrowDown': {
              this.setMouseFocus(null)
              return this.handleArrowDown(event)
            }
            default:
              return next()
          }
        },
      }
    }

    handleArrowUp = (event) => {
      event.preventDefault()
      this.decrementKeyboardFocus()
    }

    handleArrowDown = (event) => {
      event.preventDefault()
      this.incrementKeyboardFocus()
    }

    incrementKeyboardFocus = () => {
      this.setState((prevState) => {
        return {
          keyboard: normalizeNumber(prevState.keyboard + 1),
        }
      })
    }

    decrementKeyboardFocus = () => {
      this.setState((prevState) => {
        return {
          keyboard: normalizeNumber(prevState.keyboard - 1),
        }
      })
    }

    setKeyboardFocus = (value) => {
      this.setState({ keyboard: value })
    }

    setMouseFocus = (value) => {
      this.setState({ mouse: value })
    }

    onDropdownMount = () => {
      this.setState({ mouse: -1, keyboard: -1 })
    }

    onDropdownListItemFocus = ({ index }) => {
      this.setKeyboardFocus(index)
      this.setMouseFocus(index)
    }

    render() {
      const { mouse, keyboard } = this.state

      const dropdown = {
        [name]: {
          attributes: {
            focusedByMouseIndex: mouse,
            focusedByKeyboardIndex: keyboard,
            setKeyboardFocus: this.setKeyboardFocus,
            setMouseFocus: this.setMouseFocus,
            onMount: this.onDropdownMount,
            onListItemFocus: this.onDropdownListItemFocus,
            incrementKeyboardFocus: this.incrementKeyboardFocus,
            decrementKeyboardFocus: this.decrementKeyboardFocus,
          },
          slate: this.slate,
        },
      }

      return <Component {...this.props} {...dropdown} />
    }
  }
}

export default withDropdown
