import { defineMessages } from 'react-intl'

const baseId = 'release_notes_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Release Notes',
  },
  version_message: {
    id: `${baseId}.version_message`,
    defaultMessage: 'Version {version} / {date}',
  },
  version_message_v2: {
    id: `${baseId}.version_message_v2`,
    defaultMessage: '{date} / Version {version}',
  },
  latest_tag: {
    id: `${baseId}.latest_tag`,
    defaultMessage: 'LATEST',
  },
  modal_title: {
    id: `${baseId}.modal_title`,
    defaultMessage: 'Clinical Portal updates',
  },
  no_release_notes: {
    id: `${baseId}.no_release_notes`,
    defaultMessage: 'No release notes',
  },
  error_title: {
    id: `${baseId}.error_title`,
    defaultMessage: 'Error on loading the release notes',
  },
  error_description: {
    id: `${baseId}.error_description`,
    defaultMessage:
      'We can not load the release notes at the moment, please try again later',
  },
})
