import { defineMessages } from 'react-intl'

const baseId = 'smoking_status'

export default defineMessages({
  smoking_status_smoker_text: {
    id: `${baseId}.smoking_status_smoker_text`,
    defaultMessage: 'Smoker',
  },
  smoking_status_non_smoker_text: {
    id: `${baseId}.smoking_status_non_smoker_text`,
    defaultMessage: 'Non-smoker',
  },
  smoking_status_ex_smoker_text: {
    id: `${baseId}.smoking_status_ex_smoker_text`,
    defaultMessage: 'Ex-smoker',
  },
  na_text: {
    id: `${baseId}.na_text`,
    defaultMessage: 'N/A',
  },
  smoking_status_non_smoker_text_v2: {
    id: `${baseId}.smoking_status_non_smoker_text_v3`,
    defaultMessage: 'Never smoked',
  },
})
