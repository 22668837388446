import { defineMessages } from 'react-intl'

const baseId = 'chatscript_component'

export default defineMessages({
  with_chatbot_meta: {
    id: `${baseId}.with_chatbot_meta`,
    defaultMessage: 'with Chatbot',
  },

  could_not_get_conversation_message_v2: {
    id: `${baseId}.could_not_get_conversation_message_v2`,
    defaultMessage: 'Could not get the transcript',
  },
  outcome_heading: {
    id: `${baseId}.outcome_heading`,
    defaultMessage: 'Outcome',
  },
  no_outcome: {
    id: `${baseId}.no_outcome`,
    defaultMessage: 'Completed without outcome',
  },
  disclaimer_2: {
    id: `${baseId}.disclaimer_2`,
    defaultMessage:
      'This is the outcome and transcript of an automated chatbot conversation. Always check the data with the patient before making clinical decisions.',
  },
  show_less: {
    id: `${baseId}.show_less`,
    defaultMessage: 'Show less',
  },
  show_more: {
    id: `${baseId}.show_more`,
    defaultMessage: 'Show more',
  },
  transcript_heading: {
    id: `${baseId}.transcript_heading`,
    defaultMessage: 'Transcript',
  },
  copy_information_v2: {
    id: `${baseId}.copy_information_v2`,
    defaultMessage: 'Copy transcript in order to paste it into the notes',
  },
  information_copied_v2: {
    id: `${baseId}.information_copied_v2`,
    defaultMessage: 'Copied',
  },
})
