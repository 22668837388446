import { gql } from '@apollo/client'

export const REMOVE_FROM_MEDICATIONS_MUTATION = gql`
  mutation removeRemoveFromMedicationsMutation($patientId: Int!, $id: Int!) {
    removeFromMedications(patient_id: $patientId, id: $id) {
      id
      iri
      info
    }
  }
`

export const ADD_TO_MEDICATIONS_MUTATION = gql`
  mutation addAddToMedicationsMutation(
    $patientId: Int!
    $info: String!
    $iri: String
  ) {
    addToMedications(patient_id: $patientId, info: $info, iri: $iri) {
      id
      iri
      info
    }
  }
`

export const REMOVE_FROM_CONDITIONS_MUTATION = gql`
  mutation removeRemoveFromConditionsMutation($patientId: Int!, $id: Int!) {
    removeFromConditions(patient_id: $patientId, id: $id) {
      id
      iri
      info
    }
  }
`

export const ADD_TO_CONDITIONS_MUTATION = gql`
  mutation addAddToConditionsMutation(
    $patientId: Int!
    $info: String!
    $iri: String
  ) {
    addToConditions(patient_id: $patientId, info: $info, iri: $iri) {
      id
      iri
      info
    }
  }
`

export const REMOVE_FROM_ALLERGIES_MUTATION = gql`
  mutation removeRemoveFromAllergiesMutation($patientId: Int!, $id: Int!) {
    removeFromAllergies(patient_id: $patientId, id: $id) {
      id
      iri
      info
    }
  }
`

export const ADD_TO_ALLERGIES_MUTATION = gql`
  mutation addAddToAllergiesMutation(
    $patientId: Int!
    $info: String!
    $iri: String
  ) {
    addToAllergies(patient_id: $patientId, info: $info, iri: $iri) {
      id
      iri
      info
    }
  }
`
