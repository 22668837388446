import { defineMessages } from 'react-intl'

const baseId = 'consultation.complete_dialog.insurance_claim_check'

export default defineMessages({
  insurance_claim_not_billable: {
    id: `${baseId}.insurance_claim_not_billable`,
    defaultMessage: 'Not billable',
  },
  insurance_claim_fetch_error: {
    id: `${baseId}.insurance_claim_fetch_error`,
    defaultMessage: 'Failed to load insurance claim',
  },
  insurance_claim_submission_error: {
    id: `${baseId}.insurance_claim_submission_error`,
    defaultMessage: 'Failed to update insurance claim status',
  },
})
