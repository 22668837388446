import React from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'

import { CONSULTATION_STATUSES } from '~/constants'
import {
  NORMAL_REVIEW_LABEL,
  REVIEW_CATEGORY,
  START_REVIEW_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { ConsultationActionsPluginInterface } from '~/core/config/modules/generated/types'
import { CallStatusReducerModel } from '~/features/call/CallStatusReducerModelProvider'

import { ConsultationActionsView } from './ConsultationActionsView'

// TODO - add tests for this
export const ConsultationActionsViewModel: ConsultationActionsPluginInterface = ({
  reviewEnabled,
  completeConsultationModel,
  consultationContext,
  editedConsultationWarningModel,
  callStatusReducerModelProvider,
  callActiveModal,
}) => {
  const history = useHistory()
  const { status, id: consultationId, consultationType } = useConsultation(
    consultationContext
  )

  const callStatusReducerModel: CallStatusReducerModel = callStatusReducerModelProvider.useModelContext()
  const { isCallActive } = callStatusReducerModel.state

  const showEditedConsultationWarning = editedConsultationWarningModel?.useModelContext()
    .showEditedConsultationWarning

  const match = useRouteMatch('/consultation/:id/review')
  const isOnReviewRoute = Boolean(match?.isExact)

  const handleToggleReview = () => {
    if (isOnReviewRoute) {
      history.replace(`/consultation/${consultationId}`)
    } else {
      analytics.trackEvent({
        action: START_REVIEW_ACTION,
        category: REVIEW_CATEGORY,
        label: NORMAL_REVIEW_LABEL,
      })
      if (isCallActive && callActiveModal) {
        // only redirect to plugin if defined.
        history.replace(`/consultation/${consultationId}/call-in-progress`)
      } else {
        history.replace(`/consultation/${consultationId}/review`)
      }
    }
  }

  const isConsultationIncomplete = status === CONSULTATION_STATUSES.PAID
  const isConsultationNoShow = status === CONSULTATION_STATUSES.NO_SHOW
  const displayMarkAsNoShow = consultationType !== 'text'

  const mode = () => {
    if (!reviewEnabled) {
      if (isConsultationIncomplete) {
        return displayMarkAsNoShow ? 'complete|mark-as-no-show' : 'complete'
      }
      return 'update'
    }

    if (isOnReviewRoute) {
      return isConsultationNoShow
        ? 'exit'
        : isConsultationIncomplete
        ? 'complete|exit-review'
        : 'update|exit-review'
    }

    return isConsultationIncomplete && displayMarkAsNoShow
      ? 'open-review|mark-as-no-show'
      : 'open-review'
  }

  return (
    <>
      <ConsultationActionsView
        mode={mode()}
        onToggleReview={handleToggleReview}
        onCompleteConsultationClick={() => completeConsultationModel.open()}
        consultationId={consultationId}
        showEditedConsultationWarning={showEditedConsultationWarning}
      />
      <Route path="/consultation/:consultationId/call-in-progress">
        {callActiveModal}
      </Route>
    </>
  )
}
