import React, { ChangeEvent, FocusEvent } from 'react'

import { floatFormatter } from '../FormikInput'
import { Input } from '../../foundation'
import FormField from '../FormField'

interface NumberInputProps {
  label?: string
  id: string
  placeholder?: string
  value: string
  displayError?: boolean
  errorMessage?: string | undefined
  onBlur: (event: FocusEvent<HTMLInputElement>) => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  autoComplete?: string
}

const NumberInput = ({
  label,
  id,
  errorMessage,
  onChange,
  displayError,
  onBlur,
  ...rest
}: NumberInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    floatFormatter(event)
    if (onChange) {
      onChange(event)
    }
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    floatFormatter(event)
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <FormField
      label={label}
      id={id}
      displayError={displayError}
      errorMessage={errorMessage}
    >
      <Input
        id={id}
        onChange={handleChange}
        onBlur={handleBlur}
        intent={displayError ? 'error' : undefined}
        type="text"
        {...rest}
      />
    </FormField>
  )
}

export default NumberInput
