import { useParams } from 'react-router'

const useConsultationId = (): string => {
  const { consultationId = '', id = '' } = useParams<
    Partial<{ consultationId: string; id: string }>
  >()

  const value = consultationId || id

  // TODO: enable this and fix tests
  // if (!value) {
  //   throw new Error('consultationId not found')
  // }

  return value
}

export default useConsultationId
