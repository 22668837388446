import { compose, withHandlers, withProps, withState } from 'recompose'

import { withEnhancedRouter } from '~/core/compose'

import { withValidator } from './validator'
import withLabels from './withLabels'

const redirectToConsultation = ({ history, match }) => () => {
  history.replace(`/consultation/${match.params.consultationId}`)
}

const getMissingFieldsMessage = (fields = []) => {
  const string = fields.join(', ')
  const message =
    fields.length > 0
      ? `Please make sure that these fields are filled: ${string}`
      : 'Please fill all required fields'

  return {
    title: 'Required fields are empty',
    message,
    type: 'error',
  }
}

export const withValidState = ({ validate, setWarnings, mapLabels }) => (
  state,
  callback
) => {
  const errors = validate(state, {
    reasons: ['empty'],
  })

  const fields = mapLabels(errors.map((error) => error.key))

  const warnings = fields.length > 0 ? [getMissingFieldsMessage(fields)] : null

  setWarnings(warnings)

  if (!warnings) {
    callback()
  }
}

const withModal = (params = {}) =>
  compose(
    withEnhancedRouter,
    withState('warnings', 'setWarnings', []),
    withLabels(params ? params.labels : null),
    withValidator({
      scheme: params ? params.validation : null,
    }),
    withProps((props) => ({
      ...props.match.params,
    })),
    withHandlers({
      redirectToConsultation,
      withValidState,
    })
  )

export default withModal
