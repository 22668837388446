/* eslint-disable react/no-array-index-key */

import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { pickTruthyValues } from '@babylon/shared-utils'

import { ENABLE_GP_FINDER } from '~/constants'
import { GP_DETAILS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useConsultation } from '~/core/config'
import { usePermissions } from '~/core/permissions'
import Message from '~/ui/Message'
import {
  Section,
  SectionExpander,
  withSectionErrorBoundary,
} from '~/ui/Section'
import SectionItem from '~/ui/SectionItem'

import GpConsentWarning from '../GpConsentWarning'
import GpDetailsModal from '../GpDetailsModal'
import GpFinderModal from '../GpFinderModal'
import ConsentQuickEdit from './ConsentQuickEdit'
import { pre } from './utils'

import messages from './GpDetails.messages'
import styles from './styles.module.scss'

const GpDetails = ({ consultationContext }) => {
  const fm = useFormatMessage()
  const match = useRouteMatch()
  const history = useHistory()
  const consultation = useConsultation(consultationContext)
  const [displayGpConsentWarning] = usePermissions('display_gp_consent_warning')

  const { patient } = consultation
  const name = patient.gp_name
  const surgeryPhoneNumber = patient.gp_surgery_phone_number
  const editGpDetails = () => {
    history.replace(`${match.url}/gp-details/edit`)
  }

  const addressLines = pickTruthyValues([
    patient.gp_surgery_name,
    patient.gp_address_first_line,
    patient.gp_address_second_line,
    patient.gp_address_third_line,
    patient.gp_address_post_code,
  ])

  const showGpDetailsWarning = !(
    patient.gp_address_first_line &&
    patient.gp_address_post_code &&
    patient.gp_surgery_name
  )

  return (
    <>
      <Section
        title={fm(messages.title)}
        topRight={
          <Button onClick={editGpDetails} intent="secondary">
            {fm(messages.update_button_label)}
          </Button>
        }
        dataTestId="gp-details-section"
      >
        {displayGpConsentWarning && (
          <ConsentQuickEdit
            id={consultation.id}
            value={consultation.gpConsent}
          />
        )}

        {displayGpConsentWarning && !consultation.gpConsent && (
          <GpConsentWarning />
        )}
        {displayGpConsentWarning && showGpDetailsWarning && (
          <Message type="error" title={fm(messages.gp_details_warning_title)} />
        )}

        {(name || addressLines.length > 0 || surgeryPhoneNumber) && (
          <SectionExpander
            title={fm(messages.title)}
            showMoreMessage={fm(messages.show_more_message)}
          >
            <div className={styles.moreInfo}>
              {name && (
                <SectionItem
                  title={fm(messages.gp_name_label)}
                  icon="account_circle"
                  description={name}
                />
              )}
              {addressLines.length > 0 && (
                <SectionItem
                  title={fm(messages.address_label)}
                  icon="room"
                  description={pre(
                    <>
                      {addressLines.map((line, index) => (
                        <span key={index} style={{ display: 'block' }}>
                          {line}
                        </span>
                      ))}
                    </>
                  )}
                />
              )}
              {surgeryPhoneNumber && (
                <SectionItem
                  title={fm(messages.phone_number_label)}
                  icon="perm_phone_msg"
                  description={surgeryPhoneNumber}
                />
              )}
            </div>
          </SectionExpander>
        )}
      </Section>
      <Route
        path="/consultation/:consultationId/gp-details/edit"
        component={ENABLE_GP_FINDER ? GpFinderModal : GpDetailsModal}
      />
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: GP_DETAILS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(GpDetails)
