import cn from 'classnames'
import React from 'react'

import { Grid, Tag, Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { ReactComponent as IconCheck } from '~/assets/check.svg'
import { ReactComponent as IconClock } from '~/assets/clock.svg'
import { ReactComponent as IconNoShow } from '~/assets/noshow.svg'
import { useMessages } from '~/core/hooks'
import { Consultant } from '~/generated'

import {
  ConsultationStatusType,
  getRelativeDay,
  RelativeDayOptions,
} from '../../utils'

import messages from './ConsultationStatus.messages'
import styles from './styles.module.scss'

export interface ConsultationStatusProps {
  status: ConsultationStatusType
  consultant?: Pick<Consultant, 'id' | 'name' | 'profession_name'> | null
  scheduledTime: string
}

const DisplayIcon = ({ status }: { status: ConsultationStatusType }) => {
  switch (status) {
    case ConsultationStatusType.Completed:
      return (
        <IconCheck
          data-testid="consultation-completed-icon"
          className={styles.iconCompleted}
        />
      )
    case ConsultationStatusType.NoShow:
      return (
        <IconNoShow
          data-testid="consultation-no-show-icon"
          width="16"
          height="16"
        />
      )
    case ConsultationStatusType.ComingSoon:
      return <IconClock data-testid="consultation-coming-soon-icon" />
    case ConsultationStatusType.Incomplete:
      return <IconClock data-testid="consultation-incomplete-icon" />
    default:
      return null
  }
}

const ConsultationStatus = ({
  status,
  consultant,
  scheduledTime,
}: ConsultationStatusProps) => {
  const f = useMessages(messages)
  const { formatTime } = useIntl()

  const getRelativeDateText = (relativeDate: string | null) => {
    switch (relativeDate) {
      case RelativeDayOptions.today:
        return f('status_coming_soon_day_today')
      case RelativeDayOptions.tomorrow:
        return f('status_coming_soon_day_tomorrow')
      case RelativeDayOptions.yesterday:
        return f('status_coming_soon_day_yesterday')
      default:
        return null
    }
  }

  const relativeDate = getRelativeDay(scheduledTime)

  const displayConsultant =
    consultant && status === ConsultationStatusType.Completed

  const formattedScheduledTime = formatTime(new Date(scheduledTime))

  const showComingSoonOrIncomplete =
    status === ConsultationStatusType.ComingSoon ||
    status === ConsultationStatusType.Incomplete

  const displayIcon =
    status === ConsultationStatusType.Completed ||
    status === ConsultationStatusType.NoShow ||
    status === ConsultationStatusType.ComingSoon ||
    status === ConsultationStatusType.Incomplete

  return (
    <Grid
      className={cn(
        styles.consultationStatus,
        status === ConsultationStatusType.Completed && styles.completed
      )}
      gap={8}
      templateColumns={displayIcon ? 'minmax(16px, auto) 1fr' : '1fr'}
      alignItems={status === ConsultationStatusType.NoShow ? 'center' : 'start'}
      dataTestId="consultation-status"
    >
      <DisplayIcon status={status} />
      <div>
        <Text tag="div" bold>
          {showComingSoonOrIncomplete ? (
            <>
              {getRelativeDateText(relativeDate)}
              {f('status_coming_soon_time', { time: formattedScheduledTime })}
            </>
          ) : (
            f(`status_${status}` as keyof typeof messages)
          )}
        </Text>
        {displayConsultant && (
          <div className={styles.consultant}>
            <Text color="grey-type">
              {f('with_consultant', { name: consultant?.name })}
            </Text>
            <Tag color="black-on-grey" className={styles.profession}>
              {consultant?.profession_name}
            </Tag>
          </div>
        )}
      </div>
    </Grid>
  )
}

export default ConsultationStatus
