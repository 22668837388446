import * as Sentry from '@sentry/browser'

import { SENTRY_URL } from '~/constants'

import { randomAlphaNumeric } from './utils'

const isSentryEnabled = !!SENTRY_URL

const logException: typeof Sentry.captureException = (
  exception,
  captureContext?
) => {
  if (isSentryEnabled) {
    return Sentry.captureException(exception, captureContext)
  }

  console.error(exception) // eslint-disable-line no-console
  /* This is done to return string for testing and localhost */
  return randomAlphaNumeric(32)
}

export { logException }
