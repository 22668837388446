import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.examination.told_by_patient_editor'

export default defineMessages({
  label: {
    id: `${baseId}.label`,
    defaultMessage: 'History',
  },
  subLabel: {
    id: `${baseId}.sub_label`,
    defaultMessage: 'Please include details of the patient’s current location',
  },
  placeholderText: {
    id: `${baseId}.placeholder_text`,
    defaultMessage:
      'Patient’s current location and reason for consulting today',
  },
})
