import cn from 'classnames'
import { getYear } from 'date-fns'
import React, { MouseEvent } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { User } from '@babylon/babylon-user'
import { Tag, Text, useAccordion } from '@babylon/core-ui'

import { ReactComponent as IncompleteIcon } from '~/assets/incomplete_cons.svg'
import { PBL_EXPANDED_CATEGORY } from '~/constants/analytics'
import analytics from '~/core/analytics'
import Tooltip from '~/ui/Tooltip'

import { ProblemRecord } from './utils/types'
import { isMyConsultation } from './utils/utils'

import messages from '../patient-summary/PatientSummary/PatientSummary.messages'
import styles from './styles.module.scss'

interface ProblemGroupProps {
  problemGroup: Array<ProblemRecord>
  consultant: User['consultant']
  consultationId: string
  handleConsultationClick: (record: ProblemRecord) => void
}

interface ProblemRecordCellProps {
  className?: string
  consultant: User['consultant']
  record: ProblemRecord
  handleConsultationClick: (record: ProblemRecord) => void
}

const ProblemRecordCell = ({
  className,
  record,
  consultant,
  handleConsultationClick,
}: ProblemRecordCellProps) => {
  const handleDateClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    handleConsultationClick(record)
  }

  return (
    <div className={cn(styles.eventCell, className)} onClick={handleDateClick}>
      <div className={styles.dateLine} data-testid="problems-list-date">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.status === 'paid' && (
            <Tooltip
              placement="right"
              message={<FormattedMessage {...messages.tooltip_incomplete} />}
            >
              <IncompleteIcon
                data-testid="incomplete-icon"
                style={{ marginRight: '8px' }}
              />
            </Tooltip>
          )}
          <Text color="grey-type">
            <FormattedDate value={record.scheduledTime} format="short" />
          </Text>
        </div>
        <div className={cn(styles.line)} />
      </div>
      {isMyConsultation(consultant, record) && (
        <Tag className={styles.consultationTag} color="highlight-violet">
          <FormattedMessage {...messages.my_consultation} />
        </Tag>
      )}
    </div>
  )
}

const ProblemGroup = ({
  problemGroup,
  consultant,
  consultationId,
  handleConsultationClick,
}: ProblemGroupProps) => {
  const {
    wrapper,
    accordionHeight,
    handleClick: handleAccordionClick,
  } = useAccordion()

  const handleExpandClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!accordionHeight) {
      analytics.trackEvent({
        category: PBL_EXPANDED_CATEGORY,
        action: consultationId,
        label: problemGroup[0].codeId || problemGroup[0].hashNote,
      })
    }
    handleAccordionClick()
    e.stopPropagation()
  }

  const secondProblemRecord = problemGroup.length > 1 && problemGroup[1]
  const expandable = problemGroup.length > 2
  const [firstProblemsRecord, ...expandableGroup] = problemGroup
  const hasIncompleteConstToExpand = expandableGroup.some(
    (problem) => problem.status === 'paid'
  )

  const hasMyConsultation = expandableGroup.some((record) =>
    isMyConsultation(consultant, record)
  )

  return (
    <div className={styles.container}>
      <div className={styles.accordion}>
        <ProblemRecordCell
          className={
            !expandable && !secondProblemRecord
              ? styles.notExpandableOne
              : undefined
          }
          record={firstProblemsRecord}
          consultant={consultant}
          handleConsultationClick={handleConsultationClick}
        />

        {!expandable && secondProblemRecord && (
          <ProblemRecordCell
            className={styles.notExpandableTwo}
            record={secondProblemRecord}
            consultant={consultant}
            handleConsultationClick={handleConsultationClick}
          />
        )}
        {expandable && (
          <div
            className={styles.moreInfo}
            style={{ maxHeight: accordionHeight }}
          >
            <div ref={wrapper}>
              {expandableGroup.map((record: ProblemRecord, idx: number) => (
                <ProblemRecordCell
                  record={record}
                  consultant={consultant}
                  key={`${record.scheduledTime}_${idx}`}
                  handleConsultationClick={handleConsultationClick}
                />
              ))}
            </div>
          </div>
        )}
        {expandable && (
          <div className={styles.eventCell}>
            <div
              onClick={handleExpandClick}
              className={styles.expandButtonContainer}
            >
              {hasIncompleteConstToExpand && !accordionHeight && (
                <Tooltip
                  placement="right"
                  message={
                    <FormattedMessage {...messages.tooltip_incomplete} />
                  }
                >
                  <IncompleteIcon
                    data-testid="incomplete-icon-expand"
                    style={{ marginRight: '8px' }}
                  />
                </Tooltip>
              )}
              <Text color="action-green" style={{ maxWidth: '152px' }}>
                {accordionHeight ? (
                  <FormattedMessage {...messages.collapse_text} />
                ) : (
                  <FormattedMessage
                    {...messages.expand_text}
                    values={{
                      numConsultations: expandableGroup.length,
                      sinceYear: getYear(
                        new Date(
                          expandableGroup[
                            expandableGroup.length - 1
                          ].scheduledTime
                        )
                      ),
                    }}
                  />
                )}
              </Text>
              <div className={styles.expandButton}>
                {accordionHeight ? '-' : '+'}
              </div>
            </div>
            {!accordionHeight && hasMyConsultation && (
              <Tag className={styles.consultationTag} color="highlight-violet">
                <FormattedMessage {...messages.my_consultation} />
              </Tag>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default ProblemGroup
