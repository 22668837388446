import { gql } from '@apollo/client'

export const MARK_AS_NO_SHOW = gql`
  mutation CpMarkConsultationAsNoShow($id: ID!) {
    updateConsultationStatus(id: $id, status: no_show) {
      id
      status
    }
  }
`
