import { compose, withHandlers, withState } from 'recompose'

export default (initialState) =>
  compose(
    withState('state', 'setState', initialState),
    withHandlers({
      updateState: ({ setState, state }) => (patch, callback) =>
        setState({ ...state, ...patch }, callback),
      resetState: ({ setState }) => (callback) =>
        setState(initialState, callback),
    })
  )
