import PropTypes from 'prop-types'
import React from 'react'

import { FormikDropdownSelect } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './NhsAppointment.messages'

const AppointmentDuration = ({ options }) => {
  const fm = useFormatMessage()

  return (
    <FormikDropdownSelect
      id="duration"
      name="duration"
      options={options}
      placeholder={fm(messages.duration_service_dropdown_placeholder)}
      data-testid="appointment-duration"
    />
  )
}

AppointmentDuration.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.any, value: PropTypes.any })
  ),
}

export default AppointmentDuration
