import { Formik } from 'formik'
import React from 'react'

import { UpdatePatientGpDetailsForm } from '@babylon/cce-shared-components'
import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ModalContent, RouteModal } from '~/ui/LegacyModal'

import messages from './GpFinderModal.messages'

const GpFinderModal = ({
  initialFormValues,
  fetchPostcodeResults,
  redirectToConsultation,
  handleFormSubmit,
  error,
}) => {
  const fm = useFormatMessage()

  return (
    <RouteModal onRequestClose={redirectToConsultation}>
      <ModalContent title={fm(messages.title)}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleFormSubmit}
          onReset={redirectToConsultation}
        >
          {(props) => (
            <UpdatePatientGpDetailsForm
              {...props}
              i18nMessages={{
                gp_practice_postcode_lookup: {
                  label: fm(messages.gp_practice_postcode_lookup_label),
                  placeholder: fm(
                    messages.gp_practice_postcode_lookup_placeholder
                  ),
                  info_text: fm(messages.gp_practice_postcode_lookup_info_text),
                  loading: fm(
                    messages.gp_practice_postcode_lookup_loading_message
                  ),
                  invalid_postcode: fm(
                    messages.gp_practice_postcode_lookup_invalid_postcode_error_message
                  ),
                  no_results_found: fm(
                    messages.gp_practice_postcode_lookup_no_results_message
                  ),
                },
                gp_name: {
                  label: fm(messages.gp_name_label),
                  placeholder: fm(messages.gp_name_placeholder),
                },
                gp_consent: {
                  label: fm(messages.gp_consent_label),
                },
                surgery_name: {
                  label: fm(messages.surgery_name_label),
                  placeholder: fm(messages.surgery_name_placeholder),
                },
                surgery_phone_number: {
                  label: fm(messages.surgery_phone_number_label),
                  placeholder: fm(messages.surgery_phone_number_placeholder),
                },
                address_first_line: {
                  label: fm(messages.address_first_line_label),
                  placeholder: fm(messages.address_first_line_placeholder),
                },
                address_second_line: {
                  placeholder: fm(messages.address_second_line_placeholder),
                },
                address_third_line: {
                  placeholder: fm(messages.address_third_line_placeholder),
                },
                address_post_code: {
                  label: fm(messages.address_post_code_label),
                  placeholder: fm(messages.address_post_code_placeholder),
                },
                cancel_button: {
                  label: fm(messages.cancel_button_label),
                },
                save_button: {
                  label: fm(messages.save_button_label),
                },
              }}
              fetchPostcodeResults={fetchPostcodeResults}
            />
          )}
        </Formik>
        {error && (
          <Text color="error">{fm(messages.error_on_saving_gp_details)}</Text>
        )}
      </ModalContent>
    </RouteModal>
  )
}

export default GpFinderModal
