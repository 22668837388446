import React, { ReactElement } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import {
  NORMAL_REVIEW_LABEL,
  PATIENT_TIMELINE_MODAL,
  REVIEW_CATEGORY,
  TIMELINE_REVIEW_ACTION,
} from '~/constants/analytics'
import { useModalViewAndDurationTracker } from '~/core/analytics'
import { useUrlSearchParam } from '~/core/url'
import { useRedirectToConsultation } from '~/features/consultation/utils'
import PastConsultationSummaryModalTitle from '~/features/consultation-summary/ConsultationSummary/PastConsultationSummaryModalTitle'
import { useConsultationSummaryBaseQuery } from '~/generated'
import { Slideout } from '~/ui/Modal'

interface PatientTimelineSummaryModalProps {
  ConsultationSummary: ({
    consultationId,
    scheduledTime,
  }: {
    consultationId: string
    scheduledTime: string
  }) => ReactElement | null
}

const PatientTimelineSummaryModal = ({
  ConsultationSummary,
}: PatientTimelineSummaryModalProps) => {
  const user = useConsultantUser()
  const consultationId = useUrlSearchParam('eventId') as string
  const { data, loading } = useConsultationSummaryBaseQuery({
    variables: {
      id: consultationId,
    },
  })

  const onClose = useRedirectToConsultation()
  useModalViewAndDurationTracker({
    category: REVIEW_CATEGORY,
    label: NORMAL_REVIEW_LABEL,
    action: TIMELINE_REVIEW_ACTION,
    modalName: PATIENT_TIMELINE_MODAL,
  })

  const consultation = data?.consultation

  const { scheduledTime, consultant, status } = consultation || {}

  return (
    <Slideout
      title={
        <PastConsultationSummaryModalTitle
          status={status}
          consultant={consultant}
          currentConsultant={user}
          scheduledTime={scheduledTime}
        />
      }
      onClose={onClose}
      loading={loading}
    >
      {consultation && scheduledTime && (
        <ConsultationSummary
          scheduledTime={scheduledTime}
          consultationId={consultation.id}
        />
      )}
    </Slideout>
  )
}

export default PatientTimelineSummaryModal
