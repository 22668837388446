import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.patient_record_button'

export default defineMessages({
  open_patient_record_button_label: {
    id: `${baseId}.open_patient_record_button_label`,
    defaultMessage: 'Open Patient Record',
  },
})
