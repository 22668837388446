import React from 'react'
import { Route } from 'react-router'

import { TIMELINE_SUMMARY_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { usePatient } from '~/core/config'
import { PatientTimelinePluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import ChatscriptMessagesModal from '~/features/chatbot-flow/ChatscriptMessagesModal'
import TestReportModal from '~/features/test-kits/TestReportModal'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import PatientTimelineSummaryModal from '../PatientTimelineSummaryModal'
import TimelineSection from './components/TimelineSection/TimelineSection'

import messages from './PatientTimeline.messages'

const PatientTimeline: PatientTimelinePluginInterface = ({
  consultationContext,
  consultationSummary,
  timelineConsultationEvent,
  eventTypes,
  enableFutureConsultations,
  disablePastConsultations,
}) => {
  const f = useMessages(messages)
  const { id: patientId, uuid: patientUuid } = usePatient(consultationContext)
  const { futureTimelineSectionEnabled } = useFeatureFlags()

  return (
    <>
      <Section title={f('title')} dataTestId="history-section">
        {futureTimelineSectionEnabled && enableFutureConsultations && (
          <TimelineSection
            eventTypes={eventTypes}
            patientId={patientId}
            patientUuid={patientUuid}
            timelineConsultationEvent={timelineConsultationEvent}
            eventCategory="future"
          />
        )}
        {disablePastConsultations ? null : (
          <TimelineSection
            eventTypes={eventTypes}
            patientId={patientId}
            patientUuid={patientUuid}
            timelineConsultationEvent={timelineConsultationEvent}
            eventCategory="current_and_past"
          />
        )}
      </Section>
      <Route path="/consultation/:consultationId/history/consultation">
        {consultationSummary && (
          <PatientTimelineSummaryModal
            ConsultationSummary={consultationSummary}
          />
        )}
      </Route>
      <Route
        path="/consultation/:consultationId/history/chatscript-conversation"
        component={ChatscriptMessagesModal}
      />
      <Route
        path="/consultation/:consultationId/history/test-report"
        component={TestReportModal}
      />
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: TIMELINE_SUMMARY_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(PatientTimeline)
