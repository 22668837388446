import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Review',
  },
  incomplete_message: {
    id: `${baseId}.incomplete_message`,
    defaultMessage:
      'This consultation is not yet marked complete. Details like notes and actions may still change. Confirm details with the patient or the senior management team before taking clinical decisions.',
  },
  suggested_codes_section_title: {
    id: `${baseId}.suggested_codes_section_title_v2`,
    defaultMessage: 'Suggested codes',
  },
  suggested_codes_section_none_avaliable_message: {
    id: `${baseId}.suggested_codes_section_none_avaliable_message`,
    defaultMessage: 'No suggested codes are available for this consultation.',
  },
  attachments_section_title: {
    id: `${baseId}.attachments_section_title`,
    defaultMessage: 'Attachments',
  },
  booking_reason: {
    id: `${baseId}.booking_reason`,
    defaultMessage: 'Booking reason',
  },
  error_loading_consultation: {
    id: `${baseId}.error_loading_consultation`,
    defaultMessage: 'Error loading the consultation summary',
  },
})
