import { Field, FieldProps } from 'formik'
import React from 'react'

import { Button, Spinner } from '@babylon/core-ui'

import { ReactComponent as IconDone } from '~/assets/done.svg'
import {
  CARE_PLAN_SAVE_DRAFT_ACTION,
  CARE_PLANS_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages } from '~/core/hooks'

import { LocalCarePlanDraft } from '../../CarePlan.types'

import styles from '../styles.module.scss'
import messages from './CarePlanModalForm.messages'

export enum SaveStatus {
  ERROR,
  SAVED,
  NOTHING_TO_SAVE,
}

const trackSaveDraftClick = () => {
  analytics.trackEvent({
    action: CARE_PLAN_SAVE_DRAFT_ACTION,
    category: CARE_PLANS_CATEGORY,
  })
}

const getSaveStatusComponent = (
  status: SaveStatus,
  nothingToSaveMessage: string,
  errorSavingMessage: string,
  savedMessage: string
) => {
  switch (status) {
    case SaveStatus.ERROR:
      return (
        <>
          <i className={`${styles.errorStatus} fas fa-exclamation-triangle`} />
          <p className={`${styles.saveStatus} ${styles.errorStatus}`}>
            {errorSavingMessage}
          </p>
        </>
      )
    case SaveStatus.SAVED:
      return (
        <>
          <IconDone />
          <p className={styles.saveStatus}>{savedMessage}</p>
        </>
      )
    case SaveStatus.NOTHING_TO_SAVE:
      return <p className={styles.saveStatus}>{nothingToSaveMessage}</p>
  }
}

const Saving = () => {
  const f = useMessages(messages)

  return (
    <>
      <Spinner size="small" />
      <span className={styles.saveStatus}>{f('saving')}</span>
    </>
  )
}

const SaveButton = () => {
  const f = useMessages(messages)
  const nothingToSaveMessage = f('nothing_to_save')
  const errorSavingMessage = f('error_saving')
  const savedMessage = f('saved')

  return (
    <div className={styles.saveButtonAndStatus}>
      <Field name="SaveStatus">
        {({
          field: { value: SaveStatus },
          form: { isSubmitting },
        }: FieldProps<SaveStatus, LocalCarePlanDraft>) => (
          <div className={styles.saveStatusContainer}>
            {isSubmitting ? (
              <Saving />
            ) : (
              getSaveStatusComponent(
                SaveStatus,
                nothingToSaveMessage,
                errorSavingMessage,
                savedMessage
              )
            )}
          </div>
        )}
      </Field>
      <Button
        testId="save-care-plan-draft"
        type="submit"
        onClick={() => trackSaveDraftClick()}
      >
        {f('save')}
      </Button>
    </div>
  )
}

export default SaveButton
