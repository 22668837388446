import { Field } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'

import { Grid, NumberInput } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { WeightConversion } from '@babylon/shared-utils'

import { usePrevious } from '~/core/hooks'
import Timestamp from '~/features/patient-metrics/utils/Timestamp'

import { usePatientMetricsState } from '../PatientMetricsStateProvider'
import BmiSectionHeader from './BmiSectionHeader'
import { validateWeight } from './utils'

import messages from '../PatientMetricsModal.messages'
import styles from '../styles.module.scss'

type InputEvent = ChangeEvent<HTMLInputElement>

interface UsWeightFormParams {
  timestamp?: string
}

const UsWeight: React.FC<UsWeightFormParams> = ({ timestamp }) => {
  const fm = useFormatMessage()
  const {
    values: { weight },
    displayValues: { displayWeight, performerWeight },
    setFieldValue,
    setFieldTouched,
    errors,
  } = usePatientMetricsState()

  const usLbsValue =
    weight == null ? '' : String(WeightConversion.metric.toUs(weight))
  const [usLbs, setUsLbs] = useState<string>(usLbsValue)
  const prevWeight = usePrevious<number | undefined>(weight)

  useEffect(() => {
    if (prevWeight && weight == null) {
      setUsLbs('')
    }
  }, [weight, prevWeight])

  const handleWeightChange = ({ target: { value } }: InputEvent) => {
    setUsLbs(value)
  }

  const handleWeightBlur = ({ target: { value } }: InputEvent) => {
    if (value === '') {
      setFieldValue('weight', null)
      return
    }
    setFieldValue('weight', WeightConversion.us.toMetric(parseFloat(value)))
    setFieldTouched('weight', true, false)
    setUsLbs(Number(value).toFixed(1))
  }

  const displayUsWeight = displayWeight
    ? WeightConversion.metric.toUs(displayWeight)?.toFixed(1)
    : null

  const displayUsWeightLabel =
    displayUsWeight &&
    `${displayUsWeight} ${fm(messages.weight_label_lbs_abbreviation)}`

  return (
    <>
      <BmiSectionHeader
        label={fm(messages.weight_section_label)}
        htmlFor="weight"
      >
        {displayUsWeightLabel}
        <Timestamp
          performer={performerWeight}
          timestamp={timestamp}
          className={styles.timestamp}
        />
      </BmiSectionHeader>
      <Grid columns={5}>
        <Field name="weight" validate={validateWeight(fm)}>
          {() => (
            <>
              <NumberInput
                label={fm(messages.weight_label_lbs)}
                id="us_weight_lbs"
                value={usLbs}
                data-testid="us-lbs-input"
                onChange={handleWeightChange}
                onBlur={handleWeightBlur}
                placeholder={fm(messages.weight_placeholder_lbs)}
                autoComplete="off"
                errorMessage={errors.weight}
                displayError={!!errors.weight}
              />
            </>
          )}
        </Field>
      </Grid>
    </>
  )
}

export default UsWeight
