import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { ReferralsSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { ConsultationReferral, useReferralsSummaryQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import ReferralsSummary from './ReferralsSummary'

import messages from './Referrals.messages'

const ReferralsSummaryModule: ReferralsSummaryPluginInterface = () => {
  const ReferralsSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const fm = useFormatMessage()
    const { data, loading, error, refetch } = useReferralsSummaryQuery({
      variables: { id: consultation.id },
    })

    if (loading) {
      return null
    }

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={fm(messages.error_loading_referrals)}
          retry={() => refetch()}
        />
      )
    }

    const referrals = (data?.consultation?.referrals ||
      []) as ConsultationReferral[]

    return (
      <ReferralsSummary
        consultationId={consultation.id}
        referrals={referrals}
      />
    )
  }
  return ReferralsSummaryWrapper
}

export default ReferralsSummaryModule
