import { ApolloError } from '@apollo/client'
import React from 'react'
import { Route, useHistory } from 'react-router'

import { ConsultantUser } from '@babylon/babylon-user/src'
import { Button } from '@babylon/core-ui'

import {
  APPOINTMENT_INVITES_CATEGORY,
  APPOINTMENT_INVITES_CREATE_ACTION,
  APPOINTMENT_INVITES_CREATE_LABEL,
  APPOINTMENT_INVITES_SECTION_ERROR_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages, usePatientContractFeatureFlags } from '~/core/hooks'
import { GetAppointmentInvitesQueryResult } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import AppointmentInvitesModalContainer from '../AppointmentInvitesModal'
import AppointmentInvitesTable from './components/AppointmentInvitesTable'

import messages from './AppointmentInvites.messages'

export interface AppointmentInvitesViewProps {
  appointmentInvites: NonNullable<
    GetAppointmentInvitesQueryResult['data']
  >['appointmentInvites']
  appointmentInvitesEnabled?: boolean
  error?: ApolloError
  patientId: string
  consumerNetworkId: number
  consultationId: string
  consultationTenancyContractId?: string
  currentConsultant: ConsultantUser['consultant']
  loading: boolean
  refetch: () => void
  restrictFinalizedConsultationEditing: boolean
}

const AppointmentInvitesView = ({
  appointmentInvites,
  error,
  patientId,
  consumerNetworkId,
  consultationTenancyContractId,
  consultationId,
  currentConsultant,
  loading,
  refetch,
  restrictFinalizedConsultationEditing,
}: AppointmentInvitesViewProps) => {
  const history = useHistory()
  const f = useMessages(messages)

  // for invite functionality we want to specify by patient network not clinician
  const { featureFlags } = usePatientContractFeatureFlags(
    currentConsultant.uuid,
    consultationTenancyContractId
  )

  const trackEvent = analytics.trackEventFactory({
    category: APPOINTMENT_INVITES_CATEGORY,
  })

  const createAppointmentInvite = () => {
    history.push(`/consultation/${consultationId}/appointment-invites/create`)
    trackEvent({
      action: APPOINTMENT_INVITES_CREATE_ACTION,
      label: APPOINTMENT_INVITES_CREATE_LABEL,
    })
  }

  return featureFlags?.appointmentInvitesEnabled ? (
    <>
      <Section
        type="secondary"
        title={f('section_title')}
        topRight={
          <Button
            intent="secondary"
            onClick={createAppointmentInvite}
            disabled={restrictFinalizedConsultationEditing}
          >
            {f('create_button_label')}
          </Button>
        }
        loading={loading}
      >
        {error ? (
          <ErrorPanel
            title={f('invites_error')}
            error={error}
            retry={() => refetch()}
          />
        ) : (
          <AppointmentInvitesTable
            appointmentInvites={appointmentInvites}
            currentConsultant={currentConsultant}
          />
        )}
      </Section>
      {!restrictFinalizedConsultationEditing && (
        <Route
          path="/consultation/:consultationId/appointment-invites/create"
          render={() => (
            <AppointmentInvitesModalContainer
              patientId={patientId}
              consumerNetworkId={consumerNetworkId}
              consultationId={consultationId}
              clinicianId={currentConsultant.id}
            />
          )}
        />
      )}
    </>
  ) : null
}

export default withSectionErrorBoundary({
  gaAction: APPOINTMENT_INVITES_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.section_title,
})(AppointmentInvitesView)
