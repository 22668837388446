import { graphql } from '@apollo/client/react/hoc'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { Spinner } from '@babylon/core-ui'

import {
  ERROR_SUGGESTION_ADD_ACTION,
  PRESCRIPTION_SUGGESTION_CATEGORY,
  SUCCESSFUL_SUGGESTION_ADD_ACTION,
} from '~/constants/analytics'
import { generateIndex } from '~/core'
import analytics from '~/core/analytics'
import withErrorMessage from '~/core/with-error-message'
import { Dialog } from '~/ui/Dialog'
import { ErrorMessage } from '~/ui/ErrorMessage'
import Label from '~/ui/Label'
import Message from '~/ui/Message'
import { withNotify } from '~/ui/Notification'

import { formatRequestDrug, formatSuggestedDrugs } from '../../mappings'
import {
  AddDrugToPrescriptionMutation,
  CreatePrescriptionMutation,
  ValidateDrugMutation,
} from '../../mutations'
import { PrescriptionQuery } from '../../queries'
import PrescriptionDrug from './PrescriptionDrug'
import PrescriptionFormulary from './PrescriptionFormulary'
import PrescriptionHeader from './PrescriptionHeader'
import PrescriptionSeparator from './PrescriptionSeparator'

import messages from './PrescriptionSuggestions.messages'
import styles from './styles.module.scss'

const enhance = compose(
  injectIntl,
  withNotify,
  graphql(AddDrugToPrescriptionMutation, {
    name: 'addDrugMutation',
  }),
  graphql(CreatePrescriptionMutation, {
    name: 'createPrescriptionMutation',
  }),
  graphql(ValidateDrugMutation, {
    name: 'validateDrugMutation',
  }),
  withErrorMessage()
)

const showConfirmOrAlertDialog = (intl, cancelOny, message) =>
  cancelOny
    ? Dialog.show({
        message,
        cancelLabel: intl.formatMessage(
          messages.confirm_add_suggestion_cancel_button_label
        ),
        centerContent: true,
      })
    : Dialog.show({
        message,
        cancelLabel: intl.formatMessage(
          messages.confirm_add_suggestion_cancel_button_label
        ),
        okLabel: intl.formatMessage(
          messages.confirm_add_suggestion_override_button_label
        ),
        onOkStyle: 'negative',
        onOk: () => null,
        centerContent: true,
      })

const validateDrugs = async (
  drug,
  region,
  patientId,
  consultationId,
  validateDrugMutation,
  intl
) => {
  const response = await validateDrugMutation({
    variables: {
      region: region.iso_code,
      consultationId,
      patientId,
      drug,
    },
  })
  const { warnings } = response.data.validateDrug
  const showWarning = warnings.length > 0
  const isBlocking = warnings.some((warning) => !warning.allowPrescribing)
  if (showWarning) {
    showConfirmOrAlertDialog(
      intl,
      isBlocking,
      <span>
        {warnings.map((warning) => (
          <Message
            type={warning.allowPrescribing ? 'warning' : 'error'}
            title={warning.action}
            key={generateIndex()}
          >
            {warning.message}
          </Message>
        ))}
        <br />
      </span>
    )
    return !isBlocking
  }
  return true
}

class PrescriptionSuggestions extends Component {
  findDraftPrescription = (prescriptions = []) => {
    return prescriptions.find((v) => v.prescriptionState === 'DRAFT')
  }

  addSuggestion = async (value) => {
    const {
      errorAlert,
      patientId,
      consultationId,
      consumerNetwork,
      prescriptions,
      validateDrugMutation,
      region,
    } = this.props

    const drug = formatRequestDrug(value)
    try {
      const isValid = await validateDrugs(
        drug,
        region,
        patientId,
        consultationId,
        validateDrugMutation,
        this.props.intl
      )
      if (isValid) {
        const draft = this.findDraftPrescription(prescriptions)
        if (draft) {
          await this.props.addDrugMutation({
            variables: {
              id: draft.id,
              drug,
              region: region.iso_code,
            },
          })
        } else {
          await this.props.createPrescriptionMutation({
            variables: {
              patientId,
              consultationId,
              consumerNetwork,
              drugs: [drug],
              region: region.iso_code,
            },
            refetchQueries: [
              {
                query: PrescriptionQuery,
                variables: {
                  consultationId,
                },
              },
            ],
          })
        }
        analytics.trackEvent({
          action: SUCCESSFUL_SUGGESTION_ADD_ACTION,
          category: PRESCRIPTION_SUGGESTION_CATEGORY,
          label: drug.indication && drug.indication.iri,
        })
      }
    } catch (err) {
      analytics.trackEvent({
        action: ERROR_SUGGESTION_ADD_ACTION,
        category: PRESCRIPTION_SUGGESTION_CATEGORY,
        label: drug.indication && drug.indication.iri,
      })
      errorAlert({ logMessage: err })
    }
  }

  mapTreatmentPlans = ({ indication, guidelines, treatmentPlans }) => [
    <PrescriptionFormulary
      key={generateIndex()}
      indication={indication}
      guidelines={guidelines}
    />,
    ...treatmentPlans.map((plan, index) => [
      index > 0 ? (
        <PrescriptionSeparator
          key={generateIndex()}
          title={this.props.intl.formatMessage(messages.prescription_title, {
            number: index + 1,
          })}
        />
      ) : (
        <PrescriptionHeader key={generateIndex()} />
      ),
      ...formatSuggestedDrugs(plan, indication).map((suggestion) => (
        <PrescriptionDrug
          key={generateIndex()}
          drug={suggestion}
          onClick={() => {
            this.addSuggestion(suggestion.action)
          }}
        />
      )),
    ]),
  ]

  render() {
    const { suggestions, isLoading, suggestionsError, retry, intl } = this.props

    if (suggestionsError) {
      return (
        <ErrorMessage
          message={intl.formatMessage(messages.error_loading_message)}
          onButtonClick={retry}
          buttonLabel="Retry"
        />
      )
    }

    if (isLoading) {
      return <Spinner color="#45576e" />
    }

    if (suggestions.length === 0) {
      return (
        <Label value={intl.formatMessage(messages.suggestions_not_available)} />
      )
    }

    const filteredSuggestions = suggestions.filter(
      (suggestion) => suggestion.treatmentPlans.length
    )

    return (
      <table className={styles.suggestedPrescriptionsTable}>
        <tbody>{filteredSuggestions.map(this.mapTreatmentPlans)}</tbody>
      </table>
    )
  }
}

export default enhance(PrescriptionSuggestions)
