import { defineMessages } from 'react-intl'

export default defineMessages({
  contentMd: {
    id: 'clinical_consent_message.content_md',
    defaultMessage: `# By pressing "Send to ClinOps" I confirm I have

- Reviewed the patient data
- Advised the patient of the next steps
- Provided an appropriate level of clinical information to ensure the correct task is performed`,
  },
})
