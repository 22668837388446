/* eslint jsx-a11y/click-events-have-key-events: 0 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import React, { ReactNode } from 'react'

import { Grid, Spinner } from '@babylon/core-ui'

import Dropdown from '../Dropdown'

import styles from './styles.module.scss'

interface DropdownLinkProps {
  icon?: any
  iconColor?: string
  title: ReactNode
  children?: ReactNode
  external?: boolean
  active?: boolean
  onClick?: Function
  dataTestId?: string
  loading?: boolean
}

type MouseClickEvent = React.MouseEvent<HTMLDivElement, MouseEvent>

const DropdownLink: React.FC<DropdownLinkProps> = ({
  icon,
  iconColor,
  title,
  children,
  active,
  onClick,
  dataTestId,
  loading = false,
}) => (
  <div className={styles.container} data-testid={dataTestId}>
    {loading && <div className={styles.loadingOverlay} />}
    <div
      role="button"
      tabIndex={0}
      className={cn(styles.link, active && styles.active)}
      onClick={(event: MouseClickEvent) => {
        if (onClick) {
          onClick()
        }

        event.stopPropagation()
      }}
    >
      <Grid
        templateColumns={loading || icon ? 'minmax(24px, auto) 1fr' : '1fr'}
        gap={8}
        alignItems="center"
      >
        {loading ? (
          <Spinner size="small" color="#87919e" />
        ) : (
          icon && <FontAwesomeIcon fixedWidth icon={icon} color={iconColor} />
        )}
        <div>{title}</div>
      </Grid>
    </div>
    {children && <Dropdown className={styles.children}>{children}</Dropdown>}
  </div>
)

export default DropdownLink
