import { FetchResult } from '@apollo/client'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom'

import { CONSULTATION_STATUSES } from '~/constants'
import {
  CARE_PLAN_CLOSE_EDIT_MODAL_ACTION,
  CARE_PLAN_ERROR_ACTION,
  CARE_PLANS_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages } from '~/core/hooks'
import {
  CarePlanDocument,
  CarePlanDraftDocument,
  CarePlanDraftQuery,
  CarePlanDraftQueryVariables,
  CpConsultationPageQuery,
  CreateCarePlanDraftMutation,
  useCarePlanDraftQuery,
  useCarePlanQuery,
  useCreateCarePlanDraftMutation,
  useDeleteCarePlanDraftMutation,
} from '~/generated'
import { Slideout } from '~/ui/Modal'
import { withSectionErrorBoundary } from '~/ui/Section'

import CarePlanModal from '../CarePlanModal/CarePlanModal'
import updateCarePlanDraft from '../updateCarePlanDraft'
import CarePlanSectionView from './CarePlanSectionView'
import CarePlanSectionGuard from './CarePlanSectionView/CarePlanSectionGuard'
import CarePlanWarningModal from './carePlanWarningModal/CarePlanWarningModal'

import messages from './CarePlanSection.messages'

interface CarePlanSectionProps {
  consultation: NonNullable<CpConsultationPageQuery['consultation']>
  patientUuid: string
}

const trackClosingEditModal = () => {
  analytics.trackEvent({
    action: CARE_PLAN_CLOSE_EDIT_MODAL_ACTION,
    category: CARE_PLANS_CATEGORY,
  })
}

const CarePlanSection = ({
  consultation,
  patientUuid,
}: CarePlanSectionProps) => {
  if (!patientUuid) {
    throw new Error('CarePlanSection requires patient data')
  }

  if (!consultation?.uuid) {
    throw Error('CarePlanSection requires Consultation data')
  }

  const f = useMessages(messages)
  const history = useHistory()
  const formattedTimeZoneOffset = format(new Date(), 'XXX')
  const context = { headers: { 'Time-Zone-Offset': formattedTimeZoneOffset } }
  const [isDraftInFlightAlready, setIsDraftInFlightAlready] = useState(false)
  const [
    isRevisionAlreadyPublishedInConsultation,
    setIsRevisionAlreadyPublishedInConsultation,
  ] = useState(false)

  const carePlanDraftVariables = useMemo(
    () => ({
      patientId: patientUuid,
      consultationId: consultation.uuid,
    }),
    [consultation.uuid, patientUuid]
  )

  const carePlanQueryResult = useCarePlanQuery({
    variables: { patientId: patientUuid },
    fetchPolicy: 'network-only',
    context,
  })

  const carePlanDraftQueryResult = useCarePlanDraftQuery({
    variables: carePlanDraftVariables,
    fetchPolicy: 'network-only',
    context,
  })

  const carePlan =
    carePlanQueryResult.data?.carePlan.__typename === 'CarePlan'
      ? carePlanQueryResult.data.carePlan
      : undefined

  const carePlanDraft =
    carePlanDraftQueryResult.data?.carePlanDraft.__typename === 'CarePlanDraft'
      ? carePlanDraftQueryResult.data.carePlanDraft
      : undefined

  const [
    createCarePlanDraftMutation,
    createCarePlanDraftMutationState,
  ] = useCreateCarePlanDraftMutation({
    variables: {
      input: carePlanDraftVariables,
    },
    context,
    update: (cache, mutationResult) => {
      if (
        mutationResult.data?.createCarePlanDraft.__typename ===
        'CreateCarePlanDraftSuccess'
      ) {
        const cachedCarePlanDraftQueryResult = cache.readQuery<
          CarePlanDraftQuery,
          CarePlanDraftQueryVariables
        >({
          query: CarePlanDraftDocument,
          variables: {
            patientId: patientUuid,
            consultationId: consultation.uuid,
          },
        })

        if (
          cachedCarePlanDraftQueryResult?.carePlanDraft.__typename !== 'None'
        ) {
          throw new Error()
        }

        cache.writeQuery<CarePlanDraftQuery, CarePlanDraftQueryVariables>({
          query: CarePlanDraftDocument,
          variables: {
            patientId: patientUuid,
            consultationId: consultation.uuid,
          },
          data: {
            ...cachedCarePlanDraftQueryResult,
            carePlanDraft: {
              __typename: 'CarePlanDraft',
              ...mutationResult.data.createCarePlanDraft.carePlanDraft,
            },
          },
        })
      }
    },
    refetchQueries: (
      mutationResult: FetchResult<CreateCarePlanDraftMutation>
    ) => {
      const isCarePlanOutOfSync =
        mutationResult.data?.createCarePlanDraft.__typename ===
          'CreateCarePlanDraftSuccess' &&
        (!carePlan ||
          mutationResult.data.createCarePlanDraft.carePlanDraft
            .upstreamRevisionId !== carePlan.revisionId)

      if (isCarePlanOutOfSync) {
        return [
          {
            query: CarePlanDocument,
            variables: { patientId: patientUuid },
            context,
          },
        ]
      }

      return []
    },
  })

  const createCarePlanDraftTypename =
    createCarePlanDraftMutationState.data?.createCarePlanDraft.__typename

  useEffect(() => {
    setIsDraftInFlightAlready(
      createCarePlanDraftTypename === 'DraftInFlightAlreadyError'
    )
  }, [createCarePlanDraftTypename])

  useEffect(() => {
    if (
      createCarePlanDraftTypename &&
      createCarePlanDraftTypename !== 'DraftInFlightAlreadyError' &&
      createCarePlanDraftTypename !==
        'RevisionAlreadyPublishedInConsultationError' &&
      createCarePlanDraftTypename !== 'CreateCarePlanDraftSuccess'
    ) {
      history.replace(`/consultation/${consultation.id}`)
      throw new Error(createCarePlanDraftTypename)
    }
  })

  useEffect(() => {
    if (createCarePlanDraftMutationState.error) {
      history.replace(`/consultation/${consultation.id}`)
      throw new Error(createCarePlanDraftMutationState.error.message)
    }
  }, [createCarePlanDraftMutationState.error, history, consultation.id])

  useEffect(() => {
    setIsRevisionAlreadyPublishedInConsultation(
      createCarePlanDraftTypename ===
        'RevisionAlreadyPublishedInConsultationError'
    )
  }, [createCarePlanDraftTypename])

  const [
    deleteCarePlanDraft,
    deleteCarePlanDraftMutation,
  ] = useDeleteCarePlanDraftMutation({
    variables: {
      input: {
        patientId: patientUuid,
        consultationId: consultation.uuid,
        carePlanDraftId: carePlanDraft?.draftId || '',
      },
    },
    context,
  })

  const matchConsultationView = useRouteMatch({
    path: '/consultation/:id',
    exact: true,
  })

  useEffect(() => {
    if (
      matchConsultationView?.isExact &&
      carePlanDraftQueryResult.data?.carePlanDraft.__typename ===
        'CarePlanDraft' &&
      carePlanDraftQueryResult.data?.carePlanDraft.metadata.numberOfEdits ===
        0 &&
      !deleteCarePlanDraftMutation.loading
    ) {
      deleteCarePlanDraft({
        update: updateCarePlanDraft(
          carePlanDraftVariables,
          (cachedCarePlanDraft, mutationResult) => {
            if (
              mutationResult.data?.deleteCarePlanDraft.__typename !==
              'DeleteCarePlanDraftSuccess'
            ) {
              return cachedCarePlanDraft
            }

            return {
              __typename: 'None',
            }
          }
        ),
      })
    }
  }, [
    matchConsultationView,
    carePlanDraftQueryResult,
    deleteCarePlanDraft,
    carePlanDraftVariables,
    deleteCarePlanDraftMutation.loading,
  ])

  let openModal: (() => void) | undefined

  const isConsultationIncomplete =
    consultation.status === CONSULTATION_STATUSES.CONFIRMED ||
    consultation.status === CONSULTATION_STATUSES.PAID ||
    consultation.status === CONSULTATION_STATUSES.PENDING

  if (
    isConsultationIncomplete &&
    carePlanDraftQueryResult.data?.carePlanDraft.__typename !==
      'CarePlanAuthorizationError'
  ) {
    openModal = () =>
      history.replace(`/consultation/${consultation.id}/care-plan/edit`)
  }

  const handleWarningModalClose = () => {
    history.replace(`/consultation/${consultation.id}`)
    setIsDraftInFlightAlready(false)
    setIsRevisionAlreadyPublishedInConsultation(false)
  }

  return (
    <>
      <CarePlanSectionGuard
        carePlanQueryResult={carePlanQueryResult}
        carePlanDraftQueryResult={carePlanDraftQueryResult}
      >
        <CarePlanSectionView
          openModal={openModal}
          carePlan={carePlan}
          carePlanDraft={carePlanDraft}
          isConsultationIncomplete={isConsultationIncomplete}
        />
      </CarePlanSectionGuard>
      <Route path={`/consultation/${consultation.id}/care-plan/edit`}>
        {isConsultationIncomplete ? (
          <>
            <Slideout
              title={f('slideout_title')}
              position="right"
              noPadding="sides"
              onOpen={() => !carePlanDraft && createCarePlanDraftMutation()}
              onClose={() => {
                history.replace(`/consultation/${consultation.id}`)
                trackClosingEditModal()
              }}
            >
              <CarePlanModal
                consultationUuid={consultation.uuid}
                patientUuid={patientUuid}
                carePlanQueryResult={carePlanQueryResult}
                carePlanDraftQueryResult={carePlanDraftQueryResult}
                carePlanDraft={carePlanDraft}
              />
            </Slideout>
            {isDraftInFlightAlready && (
              <CarePlanWarningModal
                title={f('draft_in_flight_title')}
                paragraph1={f('draft_in_flight_paragraph_1')}
                paragraph2={f('draft_in_flight_paragraph_2')}
                onOk={handleWarningModalClose}
              />
            )}
            {isRevisionAlreadyPublishedInConsultation && (
              <CarePlanWarningModal
                title={f('revision_already_published_in_consultation_title')}
                paragraph1={f(
                  'revision_already_published_in_consultation_paragraph_1'
                )}
                onOk={handleWarningModalClose}
              />
            )}
          </>
        ) : (
          <Redirect to={`/consultation/${consultation.id}`} />
        )}
      </Route>
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: CARE_PLAN_ERROR_ACTION,
  titleDescriptor: messages.cant_load_care_plan,
})(CarePlanSection)
