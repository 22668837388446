import cn from 'classnames'
import React from 'react'

import { Grid, Heading, Text } from '@babylon/core-ui'

import { LogoutButton, SupportButton } from './Buttons'

import styles from './styles.module.scss'

interface ErrorPageProps {
  className?: string
  message?: React.ReactNode
  title: React.ReactNode
  itServiceDeskUrl?: string
  renderButtons?: () => React.ReactNode
}

const ErrorPage = ({
  className,
  message,
  title,
  itServiceDeskUrl,
  renderButtons = () => (
    <>
      <SupportButton href={itServiceDeskUrl} />
      <LogoutButton />
    </>
  ),
}: ErrorPageProps) => {
  return (
    <div className={cn(styles.errorPage, className)}>
      <div className={styles.inner}>
        {title && (
          <Heading level="h1" color="error" className={styles.title}>
            {title}
          </Heading>
        )}
        {message && (
          <Text tag="div" className={styles.message}>
            {message}
          </Text>
        )}
        <Grid justifyContent="center" flow="column">
          {renderButtons()}
        </Grid>
      </div>
    </div>
  )
}

export default ErrorPage
