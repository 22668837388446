import { defineMessages } from 'react-intl'

const baseId = 'call_quality_feedback'

export default defineMessages({
  thank_you_message: {
    id: `${baseId}.thank_you_message`,
    defaultMessage: 'Thank you  \nfor helping us improve  \ncall quality!',
  },
  comment_placeholder_and_warning: {
    id: `${baseId}.comment_placeholder_and_warning`,
    defaultMessage: 'Enter comment – don’t include any patient information',
  },
  submitting: {
    id: `${baseId}.submitting`,
    defaultMessage: 'Submitting...',
  },
  dismiss: {
    id: `${baseId}.dismiss`,
    defaultMessage: 'Dismiss',
  },
  submit: {
    id: `${baseId}.submit`,
    defaultMessage: 'Submit',
  },
})
