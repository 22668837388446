import React from 'react'

import { Button, Cell, Grid } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import Message from '~/ui/Message'

import AppointmentReason from '../AppointmentReason'
import AppointmentUrgency from './AppointmentUrgency'

import messages from '../NhsAppointment.messages'
import styles from '../styles.module.scss'

export interface IProps {
  setFieldValue(field: string, value: any): void
  loading: boolean
}

const DigitalAppointmentForm: React.FC<IProps> = ({
  setFieldValue,
  loading,
}) => {
  const fm = useFormatMessage()
  return (
    <>
      <Grid columns={1}>
        <Cell>
          <AppointmentUrgency setFieldValue={setFieldValue} />
        </Cell>
      </Grid>
      <div className={styles.form_section}>
        <AppointmentReason />
      </div>
      <Message
        type="info"
        title={fm(messages.digital_appointment_message_alert_label)}
      >
        <ul>
          <li>{fm(messages.digital_appointment_message_alert_line_1)}</li>
          <li>{fm(messages.digital_appointment_message_alert_line_2)}</li>
          <li>{fm(messages.digital_appointment_message_alert_line_3)}</li>
        </ul>
      </Message>
      <div className={styles.form_section}>
        <Button type="submit" loading={loading} style={{ float: 'right' }}>
          {fm(messages.send_to_clinops_button_label)}
        </Button>
      </div>
    </>
  )
}

export default DigitalAppointmentForm
