import React, { useEffect } from 'react'

import { Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { Dialog } from '~/ui/Modal'

import queueLink from '../queueLink'
import useNetworkOnline from './useNetworkOnline'

import messages from './ApolloNetworkWarning.messages'

const ApolloNetworkConnectionWarning = () => {
  const fm = useFormatMessage()
  const online = useNetworkOnline()
  /**
   * Queue requests until user gets back online
   */
  useEffect(() => {
    if (online) {
      queueLink.open()
    } else {
      queueLink.close()
    }
  }, [online])

  if (online) {
    return null
  }

  return (
    <Dialog closeOnBackgroundClick={false} title={fm(messages.title)}>
      <Text>{fm(messages.message)}</Text>
    </Dialog>
  )
}

export default ApolloNetworkConnectionWarning
