import React, { ChangeEvent, ReactNode, CSSProperties } from 'react'

import { Context } from '../Radio/Radio'
import './RadioGroup.scss'

interface Props {
  children: ReactNode
  columns?: number
  id?: string
  label?: ReactNode
  name: string
  onChange?: (value: any) => void
  value?: string
  ['data-testid']?: string
}

const RadioGroup = ({
  children,
  columns,
  id,
  label,
  name,
  onChange,
  value: selected,
  // TODO: Refactor CP3 Workflow Definitions and CoreUI Formik components to use `dataTestId` instead of `data-testid`
  'data-testid': dataTestId,
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  let labelId: string | undefined

  if (label) {
    labelId = (id || name) && `${id || name}-label`
  }

  const style: CSSProperties = {}

  if (columns) {
    style.columnCount = columns
  }

  return (
    <div
      role="radiogroup"
      className="core-radio-group"
      aria-labelledby={labelId}
      data-testid={dataTestId}
    >
      {label && <div id={labelId}>{label}</div>}
      <div className="core-radio-group__list" style={style}>
        <Context.Provider
          value={{
            name,
            selected,
            onChange: handleChange,
          }}
        >
          {children}
        </Context.Provider>
      </div>
    </div>
  )
}

export default RadioGroup
