import React from 'react'

import ReviewerCommentsForm from './ReviewerCommentsForm'
import ReviewerEmailForm from './ReviewerEmailForm'

const configs = {
  normal: {
    component: ReviewerCommentsForm,
    standardMessage:
      'We’ve got your results back from our lab partner and a Babylon GP has reviewed them for you. Our GP considers that they look fine even if you see some biomarkers’ values higher or lower than the normal range.',
  },
  abnormal: {
    component: ReviewerCommentsForm,
    standardMessage:
      'We’ve got your results back from our lab partner and a Babylon GP has reviewed them for you. There may be a number of reasons why your results are not normal. Please book an appointment to speak with one of our GPs to review them. Alternatively, please speak with your usual healthcare provider.',
  },
  error: {
    component: ReviewerEmailForm,
    standardMessage: '',
  },
}

export default ({ selectedAction, onUpdatePanel, submitting }) => {
  if (selectedAction == null) {
    return null
  }

  const config = configs[selectedAction.key]
  const FormComponent = config.component

  return (
    <FormComponent
      onSubmit={({ reviewerComments, supportMessage } = {}) =>
        onUpdatePanel({ selectedAction, reviewerComments, supportMessage })
      }
      standardMessage={config.standardMessage}
      submitting={submitting}
      selectedAction={selectedAction}
    />
  )
}
