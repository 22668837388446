import { ServerError, ServerParseError } from '@apollo/client'
/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error'

import { logout } from '@babylon/babylon-auth'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const message = JSON.stringify(error, null, 2)

      console.warn('[Service Error]', message)
    })
  }

  if (networkError) {
    console.warn('[Network error]', networkError)

    const serverError = networkError as ServerError | ServerParseError
    if (serverError.statusCode === 401) {
      logout()
    }
  }
})

export default errorLink
