import { gql } from '@apollo/client'

export const SuggestedClinicalCodesQuery = gql`
  query searchCodingDiagnosisQuery($query: String!, $region: String) {
    searchCodingDiagnosis(query: $query, limit: 50, region: $region) {
      preferredLabel
      iri
      hasNotes
    }
  }
`

export const GET_ALLERGIES_QUERY = gql`
  query getAllergiesQuery($patientId: ID!) {
    patient(id: $patientId) {
      id
      allergies {
        id
        iri
        info
      }
    }
  }
`

export const SUGGEST_ALLERGIES_QUERY = gql`
  mutation suggestAllergiesQuery($query: String!) {
    options: suggestAllergies(query: $query, limit: 20) {
      iri
      suggestedLabel
    }
  }
`

export const GET_MEDICATIONS_QUERY = gql`
  query getMedicationsQuery($patientId: ID!) {
    patient(id: $patientId) {
      id
      medications {
        id
        iri
        info
      }
    }
  }
`

export const SUGGEST_MEDICATIONS_QUERY = gql`
  mutation suggestMedicationsQuery($query: String!) {
    options: suggestMedications(query: $query, limit: 20) {
      iri
      suggestedLabel
    }
  }
`

export const GET_CONDITIONS_QUERY = gql`
  query getConditionsQuery($patientId: ID!) {
    patient(id: $patientId) {
      id
      conditions {
        id
        iri
        info
      }
    }
  }
`

export const SUGGEST_CONDITIONS_QUERY = gql`
  mutation suggestConditionsQuery($query: String!) {
    options: suggestConditions(query: $query, limit: 20) {
      iri
      suggestedLabel
    }
  }
`

export const FAST_CT_CONDITIONS = gql`
  query searchFastCTConceptsQuery(
    $term: String!
    $semanticTags: String!
    $pageSize: Int!
  ) {
    searchFastCTConcepts(
      language: "en"
      pageSize: $pageSize
      semanticTags: $semanticTags
      term: $term
    ) {
      concepts {
        iri
        prefLabel {
          value
        }
      }
    }
  }
`
