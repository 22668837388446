import { defineMessages } from 'react-intl'

const baseId = 'languages'

export default defineMessages({
  en_gb: {
    id: `${baseId}.en_gb`,
    defaultMessage: 'English (GB)',
  },
  en_us: {
    id: `${baseId}.en_us`,
    defaultMessage: 'English (US)',
  },
  en_ca: {
    id: `${baseId}.en_ca`,
    defaultMessage: 'English (CA)',
  },
  en_test: {
    id: `${baseId}.en_test`,
    defaultMessage: 'Test',
  },
  fr_ca: {
    id: `${baseId}.fr_ca`,
    defaultMessage: 'French (CA)',
  },
})
