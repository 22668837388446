import cn from 'classnames'
import React from 'react'

import { Text } from '@babylon/core-ui'

import styles from './styles.module.scss'

interface StatusMessageProps {
  children: React.ReactNode
  className?: string
}

const StatusMessage = ({ className, children }: StatusMessageProps) => {
  return (
    <div
      className={cn(styles.wrapper, className)}
      data-testid="status-message"
      data-public
    >
      <Text align="center" color="grey-type-keyline">
        {children}
      </Text>
    </div>
  )
}

export default StatusMessage
