import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors'
import React, { useEffect, useState } from 'react'
import { Device } from 'twilio-client'
import { TwilioError as LegacyTwilioError } from 'twilio-client/es5/twilio/errors'

import {
  MULTIMEDIA_CATEGORY,
  MUTE_AUDIO_ACTION,
  SERVICE_DISCONNECT_VOICE_CALL_ACTION,
  SERVICE_ERROR_VOICE_CALL_ACTION,
  UNMUTE_AUDIO_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useFeatureFlags } from '~/core/core-modules'
import { useEventBus } from '~/core/event-bus'
import { usePrevious, useToggle, useUnmount } from '~/core/hooks'
import jwtUtils from '~/core/jwt'
import { logException } from '~/core/sentry'

import {
  Action,
  ActionType,
  ErrorActionType,
} from '../CallStatusReducerModelProvider'
import InCallButton from '../InCallButton'
import { LegacyVoiceCallService } from './LegacyVoiceCallService'
import useEndVoiceCallMutation from './useEndVoiceCallMutation'
import useStartVoiceCallMutation from './useStartVoiceCallMutation'
import useVoiceSessionQuery from './useVoiceSessionQuery'
import { VoiceCallService } from './VoiceCallService'

import styles from './styles.module.scss'

const callService = new VoiceCallService()
const legacyCallService = new LegacyVoiceCallService()

interface VoiceCallProps {
  consultationId: string
  isCallActive: boolean
  dispatch: React.Dispatch<Action>
  isVoip: boolean
}

const VoiceCall = ({
  consultationId,
  isCallActive,
  dispatch,
  isVoip,
}: VoiceCallProps) => {
  const [setupErrorMessage, setSetupErrorMessage] = useState<Error | null>()
  const wasCallActive = usePrevious<boolean>(isCallActive)
  const [muted, toggleMuted] = useToggle(false)
  const eventBus = useEventBus()
  const { useNewTwilioSdk } = useFeatureFlags()

  /**
   * Queries and mutations
   */
  const {
    consultationUuid,
    patientId,
    connectionToken,
    encryptedVoiceParams,
    error: voiceSessionError,
    refetch: refetchVoiceSession,
  } = useVoiceSessionQuery({ consultationId, dispatch })
  const [startVoiceCallMutation] = useStartVoiceCallMutation(consultationId)
  const [endVoiceCallMutation] = useEndVoiceCallMutation(consultationId)

  /**
   * Callbacks
   */
  const getToken = async () => {
    if (jwtUtils.isExpiring(connectionToken)) {
      const { data } = await refetchVoiceSession()
      return data?.consultation?.audioSession?.token
    }

    return connectionToken
  }

  const handleCallServiceReady = async () => {
    // Note: encryptedVoiceParams is currently optional; no need to check for it in this IF statement
    if (!consultationUuid || !patientId) {
      return
    }

    if (useNewTwilioSdk) {
      await callService.start(
        consultationId,
        consultationUuid,
        patientId,
        encryptedVoiceParams,
        isVoip
      )
    } else {
      legacyCallService.start(
        consultationId,
        consultationUuid,
        patientId,
        encryptedVoiceParams
      )
    }
  }

  const handleCallServiceConnect = async () => {
    try {
      dispatch({ type: ActionType.VOICE_STREAMING })
      await startVoiceCallMutation()
    } catch (err) {
      dispatch({ type: ActionType.VOICE_STOP })
    }

    let clinicianStream
    let patientStream

    if (useNewTwilioSdk) {
      clinicianStream = callService.call?.getLocalStream()
      patientStream = callService.call?.getRemoteStream()
    } else {
      clinicianStream = legacyCallService.connection?.getLocalStream()
      patientStream = legacyCallService.connection?.getRemoteStream()
    }

    if (clinicianStream) {
      eventBus.emit('CLINICIAN_AUDIO_CREATED', clinicianStream)
    }
    if (patientStream) {
      eventBus.emit('PATIENT_AUDIO_CREATED', patientStream)
    }
  }

  const handleCallServiceDisconnect = () => {
    dispatch({ type: ActionType.VOICE_STOP })
    analytics.trackEvent({
      category: MULTIMEDIA_CATEGORY,
      action: SERVICE_DISCONNECT_VOICE_CALL_ACTION,
    })
  }

  const handleCallServiceError = (event: TwilioError | LegacyTwilioError) => {
    const exception = event || new Error('Unknown twilio error')
    const error = Object.assign(exception, {
      message: exception.message || event.message,
    })

    logException(error, {
      extra: { eventMessage: event.message, eventCode: event.code },
    })
    dispatch({
      type: ErrorActionType.CALL_SERVICE_ERROR,
      error,
    })
    analytics.trackEvent({
      category: MULTIMEDIA_CATEGORY,
      action: SERVICE_ERROR_VOICE_CALL_ACTION,
    })
  }

  const handleLegacyCallServiceError = (event: Device.Error) => {
    const exception =
      event.twilioError || new TwilioError('Unknown twilio error')
    handleCallServiceError(exception)
  }

  const handleMute = () => {
    toggleMuted()
    analytics.trackEvent({
      category: MULTIMEDIA_CATEGORY,
      action: muted ? UNMUTE_AUDIO_ACTION : MUTE_AUDIO_ACTION,
    })
  }

  const setupCallNewTwilio = async (token: string) => {
    console.log('Setting up call using twilio/voice-sdk 2.x.x')
    callService.setup(token)

    if (!callService.device) {
      throw new Error('Twilio device not instantiated before adding listeners')
    }

    // when setup is complete
    await handleCallServiceReady()

    // any time connection is closed
    callService.call?.on('disconnect', handleCallServiceDisconnect)

    // when any device error occurs
    callService.call?.on('error', handleCallServiceError)

    // when connection is open
    await handleCallServiceConnect()
  }

  const setupCallLegacyTwilio = (token: string) => {
    console.log('Setting up call using twilio-client 1.x.x')
    legacyCallService.setup(token)
    if (!legacyCallService.device) {
      throw new Error('Twilio device not instantiated before adding listeners')
    }

    // when setup is complete change
    legacyCallService.device.on('ready', handleCallServiceReady)
    // when connection is open
    legacyCallService.device.on('connect', handleCallServiceConnect)
    // any time connection is closed
    legacyCallService.device.on('disconnect', handleCallServiceDisconnect)
    // when any device error occurs
    legacyCallService.device.on('error', handleLegacyCallServiceError)
  }

  const setupCall = async () => {
    try {
      const token = await getToken()
      if (!token) {
        throw new Error('Twilio device requires a token to setup')
      }
      if (useNewTwilioSdk) await setupCallNewTwilio(token)
      else setupCallLegacyTwilio(token)
    } catch (err) {
      setSetupErrorMessage(err)
    }
  }

  const teardownCall = async () => {
    try {
      if (useNewTwilioSdk) callService.teardown()
      else legacyCallService.teardown()
      await endVoiceCallMutation()
    } catch (err) {
      logException(err)
    }
  }

  /**
   * Effects
   */

  useEffect(() => {
    if (isCallActive) {
      setupCall()
    }

    if (!isCallActive && wasCallActive) {
      teardownCall()
    }
  }, [isCallActive]) // eslint-disable-line react-hooks/exhaustive-deps

  useUnmount(() => {
    if (isCallActive) {
      teardownCall()
    }
  })

  const loadingError = voiceSessionError || setupErrorMessage

  useEffect(() => {
    if (loadingError) {
      dispatch({
        type: ErrorActionType.VOICE_LOADING_ERROR,
        error: loadingError,
      })
    }
  }, [loadingError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (useNewTwilioSdk) {
      if (callService.call) {
        callService.call.mute(muted)
      }
    } else {
      const connection = legacyCallService.device?.activeConnection()

      if (connection) {
        connection.mute(muted)
      }
    }
  }, [muted, useNewTwilioSdk])

  if (!isCallActive) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <InCallButton
          iconName={muted ? 'mic_off' : 'mic'}
          onClick={handleMute}
          testId="toggle-microphone-button"
        />
      </div>
    </div>
  )
}

export default VoiceCall
