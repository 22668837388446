import { MessageDescriptor } from 'react-intl'

import { LegacyFormikDropdownOption } from '@babylon/core-ui'

import { ModalTestTypesQuery } from '~/generated'

import { DIAGNOSTIC_TEST_TYPE, PATHOLOGY_TEST_TYPE } from './constants'

import messages from './Tests.messages'

export type TestType = 'diagnosticTestTypes' | 'pathologyTestTypes'

export type TestFormState = {
  testType: TestType
  test: string | null
  reason: string
}

type ConsultationTest = {
  type: string
  testId: string
  reason: string
}

export const getTestTypeOptions = (
  fm: (key: MessageDescriptor) => string,
  enableDiagnosisOptions: boolean
) => {
  return [
    {
      label: fm(messages.test_type_option_pathology),
      value: PATHOLOGY_TEST_TYPE,
    },
  ].concat(
    (enableDiagnosisOptions && {
      label: fm(messages.test_type_option_diagnostic),
      value: DIAGNOSTIC_TEST_TYPE,
    }) ||
      []
  )
}

const getTestType = (value: TestType) => {
  return value === PATHOLOGY_TEST_TYPE ? 'pathology' : 'diagnostic'
}

const getTestsTypeListName = (type: string | undefined) => {
  return type === 'pathology' ? PATHOLOGY_TEST_TYPE : DIAGNOSTIC_TEST_TYPE
}

export const mapTestToState = (
  fm: (key: MessageDescriptor) => string,
  data: ConsultationTest,
  testTypes: ModalTestTypesQuery | undefined
) => {
  const testTypeListName = data.type && getTestsTypeListName(data.type)
  const testTypeList = testTypeListName
    ? testTypes && testTypes[testTypeListName]
    : []

  const test = testTypeList?.find((v) => v?.testType === data.testId)
  const testTypeOptions = getTestTypeOptions(fm, true)
  const testType = testTypeOptions.find((v) => v.value === testTypeListName)

  return {
    testType: (testType?.value as TestType) || PATHOLOGY_TEST_TYPE,
    test: test?.testType || null,
    reason: data.reason,
  }
}

export const mapStateToTest = (state: TestFormState) => {
  return {
    reason: state.reason,
    type: getTestType(state.testType),
    testId: state.test,
  }
}

export const getTestTypes = (
  testTypes: ModalTestTypesQuery | undefined,
  testType: TestType
) => {
  return (
    (testTypes?.[testType]?.filter(
      (option) => option != null
    ) as LegacyFormikDropdownOption[]) || []
  )
}

export const getTestName = (
  testId: string | null,
  testType: TestType,
  testTypes: ModalTestTypesQuery | undefined
) => {
  const testOptions = testTypes && testTypes[testType]
  const selectedTest = testOptions?.find((test) => test?.testType === testId)

  return selectedTest?.name || ''
}

export const validate = (fm: (key: MessageDescriptor) => string) => (
  values: any
) => {
  const errors: Dictionary<string> = {}

  if (!values.testType) {
    errors.testType = fm(messages.test_type_validation)
  }

  if (!values.test) {
    errors.test = fm(messages.test_validation)
  }

  return errors
}
