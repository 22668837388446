import React, { useRef, useState } from 'react'

import { useDragAndDrop, useMount } from '~/core/hooks'

import { inRange } from './utils'

interface DraggableFrameProps {
  width?: number
  height?: number
  x?: number
  children: React.ReactNode
}

const DEFAULT_BORDER_DISTANCE = 8

const DraggableFrame = ({
  width = 100,
  height = 100,
  x,
  children,
}: DraggableFrameProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useMount(() => {
    if (containerRef.current) {
      setPosition({
        x: x || -DEFAULT_BORDER_DISTANCE,
        y: -DEFAULT_BORDER_DISTANCE,
      })
    }
  })

  const dragHeadRef = useDragAndDrop({
    onMouseMove: (event) => {
      if (containerRef.current) {
        const {
          width: containerWidth,
          height: containerHeight,
        } = containerRef.current.getBoundingClientRect()

        setPosition(({ x, y }) => ({
          x: inRange(width - containerWidth, 0, x + event.movementX),
          y: inRange(height - containerHeight, 0, y + event.movementY),
        }))
      }
    },
  })

  return (
    <div
      ref={containerRef}
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <div
        ref={dragHeadRef}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: `${width}px`,
          height: `${height}px`,
          transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default DraggableFrame
