import { gql } from '@apollo/client'
import { PanelFragment } from './queries'

export const UpdatePanelMutation = gql`
  mutation updatePanel(
    $state: String!
    $id: String!
    $version: Int!
    $appointmentSuggested: Boolean!
    $reviewerComments: String
    $profileComments: [TestReportProfileComment]
    $supportMessage: String
  ) {
    updatePanel(
      state: $state
      id: $id
      version: $version
      appointmentSuggested: $appointmentSuggested
      reviewerComments: $reviewerComments
      profileComments: $profileComments
      supportMessage: $supportMessage
    ) {
      ...PanelFragment
    }
  }
  ${PanelFragment}
`
export default UpdatePanelMutation
