import messages from './conversationTypeUtils.messages'

export const isCovidFlow = (outcome) => outcome?.toLowerCase().includes('covid')

const titleMap = {
  'covid-19': messages.covid_flow_title,
  pre_booking: messages.pre_consultation_flow_title,
}

export const getTitleForType = (type, fm) =>
  titleMap[type] ? fm(titleMap[type]) : fm(messages.chatflow_flow_title)
