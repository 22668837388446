import { NetworkStatus } from '@apollo/client'
import cn from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'

import { Checkbox, Grid, Heading, Spinner } from '@babylon/core-ui'
import { useFormatMessage, useIntl } from '@babylon/intl'

import { ReactComponent as Calendar } from '~/assets/calendar-days.svg'
import { ReactComponent as ChevronDown } from '~/assets/header-schedule/chevron-down.svg'
import {
  CLICKED_CONSULTATION_CARD,
  FEATURES_CATEGORY,
  NEW_APPT_ID,
  NEW_APPT_NOTIFICATION_ACTIVE,
  NEW_APPT_NOTIFICATION_INACTIVE,
  NEW_APPT_NOTIFICATION_SHOWN,
  NEW_APPT_NOTIFICATIONS,
  OPT_IN_NOTIFICATIONS,
  OPT_OUT_NOTIFICATIONS,
  UPCOMING_CONSULT_SHORTCUT_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { ConsultationScheduleItemPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { getStatus } from '~/features/schedule/SchedulePage/components/Schedule/utils'
import {
  Consultation,
  useConsultationSchedule,
} from '~/features/schedule/useConsultations'
import { getClosestConsultations } from '~/features/schedule/utils'
import { ErrorMessage } from '~/ui/ErrorMessage'
import { StatefulPopover } from '~/ui/Popover'

import { showNearTimeNotification, updateNotification } from '../utils/utils'

import messages from '../Header.messages'
import styles from '../styles.module.scss'

export const HEADER_CONSULTATIONS_POLL_INTERVAL = 1000 * 60 // 1 minute

const onCalendarIconClick = (
  upNextNotificationsEnabled: boolean | undefined,
  notification: INotification | undefined,
  setNotification: React.Dispatch<
    React.SetStateAction<INotification | undefined>
  >
) => {
  // track if notification is displayed when clicked
  analytics.trackEvent({
    action: UPCOMING_CONSULT_SHORTCUT_ACTION,
    category: FEATURES_CATEGORY,
    label: !upNextNotificationsEnabled
      ? undefined
      : notification?.show
      ? NEW_APPT_NOTIFICATION_ACTIVE
      : NEW_APPT_NOTIFICATION_INACTIVE,
  })
  if (notification?.show) {
    updateNotification({ ...notification, show: false }, setNotification)
  }
}

const onOptInOptionChange = (
  optInNotifications: boolean,
  setOptInNotifications: React.Dispatch<React.SetStateAction<boolean>>
) => {
  analytics.trackEvent({
    action: NEW_APPT_NOTIFICATIONS,
    category: FEATURES_CATEGORY,
    label: optInNotifications ? OPT_IN_NOTIFICATIONS : OPT_OUT_NOTIFICATIONS,
  })
  setOptInNotifications(optInNotifications)
  window.localStorage.setItem(
    'opt_in_notifications',
    optInNotifications.toString()
  )
}

/**
 * Notification interface
 * consultationId: equals near time consultation id
 * createdAt: used to determine if notification is expired
 * show: used to set notification styling
 */
export interface INotification {
  consultationId: string
  createdAt: number
  show: boolean
}

interface LabelProps {
  loading: boolean
  error: Error | undefined
  consultations: Consultation[]
  notification: INotification | undefined
  optInNotifications: boolean
}

const Label = ({
  loading,
  error,
  consultations,
  notification,
  optInNotifications,
}: LabelProps) => {
  const intl = useIntl()
  const fm = intl.formatMessage
  const fd = intl.formatDate
  const { upNextNotificationsEnabled } = useFeatureFlags()
  const [nextConsultation] = consultations

  // update styling if a notification exists and it hasn't been marked as read
  const showNotification =
    upNextNotificationsEnabled &&
    notification?.show &&
    optInNotifications &&
    notification?.consultationId === nextConsultation?.id

  if (loading) {
    return <Spinner size="small" color="#45576e" />
  }

  if (error) {
    return <>{fm(messages.cant_show_upcoming_consultations)}</>
  }

  const date = fd(nextConsultation?.scheduledTime, {
    hour: '2-digit',
    minute: '2-digit',
  })

  return (
    <>
      <span
        className={showNotification ? styles.notificationBadge : null}
        data-testid="upcoming-consultations-icon"
      >
        <Calendar />
      </span>
      <span
        className={styles.message}
        data-testid="upcoming-consultations-message"
      >
        {fm(showNotification ? messages.new_at_text : messages.up_next_text, {
          date: (
            <span
              className={cn(
                styles.dateTag,
                showNotification && styles.nearTime
              )}
              data-testid="upcoming-consultations-date"
            >
              {date}
            </span>
          ),
        })}
      </span>
      <ChevronDown />
    </>
  )
}

interface HeaderConsultationProps {
  ConsultationScheduleItem?: ReturnType<ConsultationScheduleItemPluginInterface>
}

const HeaderConsultation = ({
  ConsultationScheduleItem,
}: HeaderConsultationProps) => {
  const storedNotification = window.localStorage.getItem('up_next_notification')
  const [notification, setNotification] = useState<INotification | undefined>(
    storedNotification ? JSON.parse(storedNotification) : undefined
  )
  const storedOptInNotifications = window.localStorage.getItem(
    'opt_in_notifications'
  )
  const [optInNotifications, setOptInNotifications] = useState(
    storedOptInNotifications === null
      ? true
      : storedOptInNotifications === 'true'
  )
  const { upNextNotificationsEnabled } = useFeatureFlags()
  const fm = useFormatMessage()

  const { data, loading, error, networkStatus } = useConsultationSchedule({
    date: new Date(),
    poll: true,
  })

  const consultations = useMemo(() => {
    return data?.consultations ?? []
  }, [data])
  const closestConsultations = getClosestConsultations(consultations, 5)

  useEffect(() => {
    if (!upNextNotificationsEnabled || !optInNotifications) return

    const [nextConsultation] = getClosestConsultations(consultations, 5)
    if (
      nextConsultation &&
      showNearTimeNotification(nextConsultation, notification)
    ) {
      analytics.trackEvent({
        action: NEW_APPT_NOTIFICATION_SHOWN,
        category: FEATURES_CATEGORY,
        label: NEW_APPT_ID,
        value: parseInt(nextConsultation.id, 10),
      })
      updateNotification(
        {
          consultationId: nextConsultation.id,
          createdAt: new Date().getTime(),
          show: true,
        },
        setNotification
      )
    }
  }, [
    upNextNotificationsEnabled,
    consultations,
    notification,
    optInNotifications,
  ])

  const content = (hidePopover: () => void) => {
    if (networkStatus === NetworkStatus.loading) {
      return <Spinner size="medium" />
    }

    if (error) {
      return <ErrorMessage message={fm(messages.error_loading)} />
    }

    if (!ConsultationScheduleItem) {
      throw new Error('No ConsultationScheduleItem plugin provided')
    }

    return (
      <Grid
        className={styles.scheduleList}
        columns={1}
        gap={24}
        style={{ overflow: 'visible' }}
      >
        {closestConsultations.map((consultation) => (
          <ConsultationScheduleItem
            key={consultation?.id}
            {...consultation}
            scheduleStatus={getStatus(consultation.status)}
            onClick={() => {
              // track clicks of consultation cards shown on the dropdown
              analytics.trackEvent({
                action: UPCOMING_CONSULT_SHORTCUT_ACTION,
                category: FEATURES_CATEGORY,
                label: CLICKED_CONSULTATION_CARD,
                value:
                  closestConsultations.findIndex(
                    (c) => c.id === consultation?.id
                  ) + 1,
              })
              hidePopover()
            }}
          />
        ))}
      </Grid>
    )
  }

  return closestConsultations.length !== 0 ? (
    <div className={styles.nextHeader}>
      <StatefulPopover
        width={700}
        showOnClick={!error}
        position="bottom-left"
        dataTestId="upcoming-consultations-popover"
        content={(hidePopover: () => void) => (
          <div className={styles.consultationList}>
            <div className={styles.listHeading}>
              <Heading level="h2" margin>
                {fm(messages.consultations)}
              </Heading>
              {upNextNotificationsEnabled ? (
                <Checkbox
                  className={styles.optInCheckbox}
                  checked={optInNotifications}
                  onChange={() => {
                    if (!error) {
                      onOptInOptionChange(
                        !optInNotifications,
                        setOptInNotifications
                      )
                    }
                  }}
                >
                  {fm(messages.opt_in_notification_text)}
                </Checkbox>
              ) : null}
            </div>
            <div>{content(hidePopover)}</div>
          </div>
        )}
      >
        <button
          className={cn(
            styles.viewHeaderButton,
            error && styles.viewHeaderButtonDisabled,
            styles.nextConsultation
          )}
          type="button"
          data-testid="upcoming-consultations-button"
          onClick={() => {
            if (!error) {
              onCalendarIconClick(
                upNextNotificationsEnabled,
                notification,
                setNotification
              )
            }
          }}
        >
          <Label
            loading={loading}
            notification={notification}
            consultations={closestConsultations}
            error={error}
            optInNotifications
          />
        </button>
      </StatefulPopover>
    </div>
  ) : null
}

export default HeaderConsultation
