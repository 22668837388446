import React from 'react'
import { MessageDescriptor } from 'react-intl'

import { useFormatMessage } from '@babylon/intl'

import { GENERIC_SECTION_ERROR_ACTION } from '~/constants/analytics'
import ErrorBoundary, { ErrorBoundaryProps } from '~/core/ErrorBoundary'

import messages from './Section.messages'
import styles from './styles.module.scss'

type WithSectionErrorBoundaryProps = Pick<
  ErrorBoundaryProps,
  'retry' | 'gaAction' | 'loading'
> & {
  titleDescriptor?: MessageDescriptor
  sectionTitleDescriptor?: MessageDescriptor
}

/**
 * This is implemented as a HOC specfically to encourage developers to wrap the
 * top level of a section with the error boundary.  This ensures that rendering
 * errors that occur within any of the component rendering logic are caught
 * It also adds the standardised padding that each section has
 */

const withSectionErrorBoundary = ({
  titleDescriptor,
  sectionTitleDescriptor,
  retry,
  gaAction,
  loading,
}: WithSectionErrorBoundaryProps) => <P extends object>(
  Component: React.ComponentType<P>
): React.FunctionComponent<P> =>
  function WithSectionErrorBoundary(props: P) {
    const fm = useFormatMessage()

    const getTitle = () => {
      if (titleDescriptor) {
        return fm(titleDescriptor)
      }

      if (sectionTitleDescriptor) {
        return fm(messages.error_title, {
          sectionTitle: fm(sectionTitleDescriptor),
        })
      }

      return fm(messages.error_title_default)
    }

    return (
      <>
        <ErrorBoundary
          title={getTitle()}
          gaAction={gaAction || GENERIC_SECTION_ERROR_ACTION}
          className={styles.errorBoundary}
          retry={retry}
          loading={loading}
        >
          <Component {...(props as P)} />
        </ErrorBoundary>
      </>
    )
  }

export default withSectionErrorBoundary
