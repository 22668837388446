import { graphql } from '@apollo/client/react/hoc'
import { branch, compose, renderNothing, withProps } from 'recompose'

import { withEnhancedState } from '~/core/compose'

import { defaultState } from './constants'
import { mapCodesToState, mapDrugToState } from './mappings'
import { DrugInfoQuery } from './queries'
/**
 * TODO: Clean this up
 *
 * Here I was trying to get all information that is required for editing
 * prescription.
 *
 * This should be cleaned up.
 */
export default compose(
  withProps(({ data, mode, query }) => {
    if (mode === 'edit') {
      const { prescriptionId, drugId } = query
      const { prescriptions } = data.consultation
      const { drugs } = prescriptions.find((v) => v.id == prescriptionId)
      const editedDrug = drugs.find((v) => v.prescriptionDrugId == drugId)

      return {
        editedDrug,
      }
    }
    const { assessmentCodes } = data.consultation
      ? data.consultation.consultationNote
      : []

    return {
      assessmentCodes,
    }
  }),
  /**
   * Fetching drug info to populate <DrugSelect />
   */
  graphql(DrugInfoQuery, {
    name: 'editedDrugInfoQuery',
    options: ({ data, editedDrug }) => {
      return {
        variables: {
          drugId: editedDrug.drugId,
          region: data.consultation.region.iso_code,
        },
      }
    },
    skip: ({ editedDrug, mode }) =>
      mode === 'create' || (editedDrug && !editedDrug.drugId),
  }),
  /**
   * Wait for DrugInfoQuery to fetch if mode is "edit"
   */
  branch(
    ({ editedDrugInfoQuery }) =>
      editedDrugInfoQuery && editedDrugInfoQuery.loading,
    renderNothing
  ),
  withEnhancedState(({ editedDrug, editedDrugInfoQuery, assessmentCodes }) =>
    editedDrug
      ? mapDrugToState(
          editedDrug,
          editedDrugInfoQuery ? editedDrugInfoQuery.drug : undefined
        )
      : mapCodesToState(assessmentCodes, defaultState)
  )
)
