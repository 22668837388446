import React from 'react'

import { isFilledArray } from '~/core'

import { ModelProvider } from './useModelProvider'

interface IoCProvidersProps {
  models?: ModelProvider[]
  children: React.ReactNode
}

const IoCProviders = ({ models, children }: IoCProvidersProps) => {
  const createProvider = (models: ModelProvider[]) => {
    if (isFilledArray(models)) {
      const { Provider } = models[0]

      return (
        <Provider>{createProvider(models.slice(1, models.length))}</Provider>
      )
    }

    return <>{children}</>
  }

  if (models) {
    return createProvider(models)
  }

  return <>{children}</>
}

export default IoCProviders
