import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.patient_summary'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient summary',
  },
  my_consultation: {
    id: `${baseId}.my_consultation_v2`,
    defaultMessage: 'My consultation',
  },
  summary_message: {
    id: `${baseId}.summary_message`,
    defaultMessage:
      'You have consulted this patient before. Look for the label {tag} in the problems list.',
  },
  problems_list: {
    id: `${baseId}.problems_list_v2`,
    defaultMessage: 'Problems list',
  },
  from_babylon: {
    id: `${baseId}.from_babylon`,
    defaultMessage: 'from Babylon Portal Consultations',
  },
  error_loading_summary: {
    id: `${baseId}.error_loading_summary`,
    defaultMessage: "We can't load the problems list right now",
  },
  retry_button_label: {
    id: `${baseId}.retry_button_label`,
    defaultMessage: 'Retry',
  },
  recent_label: {
    id: `${baseId}.recent_label`,
    defaultMessage: 'Recent',
  },
  recurring_label: {
    id: `${baseId}.recurring_label`,
    defaultMessage: 'Recurring',
  },
  my_consultations_label: {
    id: `${baseId}.my_consultations_label_v2`,
    defaultMessage: 'My consultations',
  },
  no_past_problems: {
    id: `${baseId}.no_past_problems`,
    defaultMessage:
      'No problems found for this patient from the Babylon Clinical Portal.',
  },
  expand_text: {
    id: `${baseId}.expand_text`,
    defaultMessage: 'view {numConsultations} more since {sinceYear}',
  },
  collapse_text: {
    id: `${baseId}.collapse_text`,
    defaultMessage: 'view less',
  },
  show_all_button: {
    id: `${baseId}.show_all_button`,
    defaultMessage: 'Show all ({numProblems})',
  },
  tooltip_incomplete: {
    id: `${baseId}.tooltip_incomplete`,
    defaultMessage:
      'Data from an incomplete consultation, which can still be edited. Click to view the full consultation notes.',
  },
})
