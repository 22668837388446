import cn from 'classnames'
import React, { useEffect, useRef } from 'react'

import styles from './styles.module.scss'

const ListItem = (props) => {
  const listItemRef = useRef(null)
  const {
    option,
    isFocusedByMouse,
    isFocusedByKeyboard,
    enableNoteIcon,
    onFocusListItem,
    onSelect,
    setPosition,
    index,
  } = props

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY })
    onSelect({ index, option })
  }

  useEffect(() => {
    if (isFocusedByKeyboard && !isFocusedByMouse) {
      onFocusListItem(listItemRef.current)
    }
  }, [onFocusListItem, isFocusedByKeyboard, isFocusedByMouse])

  const handleMouseDown = (event) => {
    const { index, option, onMouseDown } = props
    if (onMouseDown && event.button === 0) {
      onMouseDown({ index, option })
    }
    /**
     * TODO: Revisit this.
     * Currently, blocking event is required to keep focus on editor.
     */
    event.preventDefault()
    event.stopPropagation()
  }

  const { iri, hasNotes, preferredLabel } = option

  const className = cn(styles.listItem, {
    [styles.focusedByKeysIri]: isFocusedByKeyboard,
    [styles.focusedByMouseIri]: isFocusedByMouse,
  })

  return (
    <div
      ref={listItemRef}
      data-option={index}
      key={iri}
      role="button"
      tabIndex="0"
      className={className}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      data-testid={hasNotes ? 'clinical-code-with-notes' : 'clinical-code'}
    >
      <div className={styles.label}>{preferredLabel}</div>
      {enableNoteIcon && (
        <div className={styles.icon}>
          {hasNotes && <i className="far fa-sticky-note" />}
        </div>
      )}
    </div>
  )
}

export default ListItem
