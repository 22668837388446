/* eslint-disable camelcase */
import { GetConsumerNetworkServiceTypesQueryResult } from '~/generated'

const mediums = ['physical', 'voice', 'video'] as const
export type ServiceTypeMedium = typeof mediums[number]

export type ServiceType = Omit<
  NonNullable<
    GetConsumerNetworkServiceTypesQueryResult['data']
  >['consumerNetworkServiceTypes'][number],
  'allowed_mediums'
> & {
  id: string
  allowed_mediums: ServiceTypeMedium[]
}

export const isServiceTypeMedium = (
  value?: string | null
): value is ServiceTypeMedium =>
  value ? (mediums as readonly string[]).includes(value) : false
