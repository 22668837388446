import cn from 'classnames'
import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import messages from './NoteAssistantSummary.messages'
import styles from './styles.module.scss'

interface StatusIndicatorProps {
  isActive: boolean
}

const StatusIndicator = ({ isActive }: StatusIndicatorProps) => {
  const fm = useMessages(messages)

  return (
    <div className={styles.statusIndicator}>
      <div
        className={cn(
          styles.statusIndicatorIcon,
          isActive && styles.statusIndicatorIconActive
        )}
      />
      <Text color="light-grey-type" size="small">
        {fm(isActive ? 'status_active' : 'status_inactive')}
      </Text>
    </div>
  )
}

export default StatusIndicator
