import { withApollo } from '@apollo/client/react/hoc'
import { compose, pure } from 'recompose'

import { withBabylonUser } from '@babylon/babylon-user'

import { findBy } from '~/core'
import { withEnhancedState } from '~/core/compose'
import withErrorMessage from '~/core/with-error-message'
import withModal from '~/core/with-modal'
import { withNotify } from '~/ui/Notification'

import withActions from './actions'
import { defaultState, validation } from './constants'
import withData from './data'
import ReferralModal from './ReferralModal'

export default compose(
  withApollo,
  withNotify,
  withModal({ validation }),
  withData,
  withBabylonUser,
  withEnhancedState(({ mode, data, query, specialismsQuery }) => {
    const postcode = data?.consultation?.patient?.address_post_code || ''

    const state = {
      ...defaultState,
      postcode,
    }

    if (mode === 'edit') {
      const referral = findBy(data?.consultation?.referrals, 'id', query.id)
      const { specialismCategoryId, specialismId, comments } = referral
      const { specialismCategories } = specialismsQuery
      state.urgent = referral.urgent
      const category = findBy(specialismCategories, 'id', specialismCategoryId)

      if (category) {
        state.category = category

        const specialism = findBy(category.specialisms, 'id', specialismId)

        if (specialism) {
          state.specialism = specialism
        }
      }

      if (comments && comments.length > 0) {
        state.comments = comments[0].note
      }
    }

    return state
  }),
  withErrorMessage({ name: ['data', 'specialismsQuery'] }),
  withActions,
  pure
)(ReferralModal)
