import { ApolloError } from '@apollo/client'

import { EhrCheckInUsPluginInterface } from '~/core/config/modules/generated/types'
import { logException } from '~/core/sentry'
import { EhrCheckinUsDocument } from '~/generated'

const EhrCheckInUs: EhrCheckInUsPluginInterface = ({
  apolloClient,
  eventBus,
  consultationContext,
}) => {
  eventBus.on('PATIENT_JOINED_CALL', async () => {
    try {
      await apolloClient.mutate({
        mutation: EhrCheckinUsDocument,
        variables: {
          consultationId: consultationContext.get()?.data?.consultation?.id,
        },
      })
      eventBus.emit('PATIENT_CHECKED_IN')
    } catch (err: unknown) {
      if (err instanceof ApolloError) {
        // This is a temporary fix to allow us to group these errors on sentry
        // while we wait for legacy error format to be switched off
        const unprocessableEntityError = err.graphQLErrors.find(
          (v) => v.extensions?.response?.status === 422
        )

        if (unprocessableEntityError) {
          err.message =
            unprocessableEntityError.extensions?.response?.statusText
        }
      }

      logException(err)
    }
  })
}

export default EhrCheckInUs
