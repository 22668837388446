import { defineMessages } from 'react-intl'

const baseId = 'dashboard.navigation'

export default defineMessages({
  prescriptions_text: {
    id: `${baseId}.prescriptions_text`,
    defaultMessage: 'Prescriptions',
  },
})
