import { ApolloError } from '@apollo/client'
import React from 'react'

import { formatReadableErrorList } from './utils'

interface ErrorCodeProps {
  error: Error
}

const ErrorCode = ({ error }: ErrorCodeProps) => {
  if (!(error instanceof ApolloError)) {
    return <>INTERNAL_ERROR</>
  }

  const graphQLErrorData = formatReadableErrorList(error.graphQLErrors)

  return (
    <>
      {graphQLErrorData.map((message) => (
        <div key={message}>{message}</div>
      ))}
      {error.networkError && <div key="network-error">NETWORK_ERROR</div>}
    </>
  )
}

export default ErrorCode
