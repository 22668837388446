import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'

import {
  NOTE_ASSISTANT_CATEGORY,
  NOTE_ASSISTANT_ERROR_ACTION,
  NOTE_ASSISTANT_ERROR_LABEL,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useGlobals } from '~/core/core-modules'
import { EventBusType } from '~/core/event-bus'
import { useNoteAssistantRecordingIdQuery } from '~/generated'

import { SourceType, SPEAKER_TYPE } from './CallRecording'
import {
  DEFAULT_AUDIO_RECORDING_INTERVAL,
  DEFAULT_RECORDING_RESTART_INTERVAL,
  DEFAULT_SEND_AUDIO_ATTEMPTS,
  DEFAULT_SEND_AUDIO_RETRY_DELAY,
} from './constants'
import { logToDevConsole, PostAudioPayload, sendAudioFile } from './utils'

/*
  Sample rate is hardcoded for now but in the
  future this will be provided by the call provider.
 */
const RECORDING_SAMPLE_RATE = '16000'
const RECORDING_ENCODING = 'WEBM_OPUS'

const trackEvent = analytics.trackEventFactory({
  category: NOTE_ASSISTANT_CATEGORY,
})

interface useCallRecordingProps {
  patientMediaStream: MediaStream | null | undefined
  clinicianMediaStream: MediaStream | null | undefined
  consultationUuid: string
  eventBus: EventBusType
  patientId: string
  clinicianId: string
  sourceType: SourceType | null
  recordingEnabled: boolean | undefined
}

const useCallRecording = ({
  patientMediaStream,
  clinicianMediaStream,
  consultationUuid,
  patientId,
  clinicianId,
  eventBus,
  sourceType,
  recordingEnabled,
}: useCallRecordingProps) => {
  const patientMediaRecorder = useRef<MediaRecorder | null>()
  const clinicianMediaRecorder = useRef<MediaRecorder | null>()
  const resetTimeoutId = useRef<NodeJS.Timeout | null>()
  const recordingId = useRef<string | null>(null)
  const { locale } = useIntl()
  const {
    recordingRestartInterval = DEFAULT_RECORDING_RESTART_INTERVAL,
    sendAudioAttempts = DEFAULT_SEND_AUDIO_ATTEMPTS,
    sendAudioRetryDelay = DEFAULT_SEND_AUDIO_RETRY_DELAY,
    audioRecordingInterval = DEFAULT_AUDIO_RECORDING_INTERVAL,
  } = useGlobals().noteAssistant || {}

  const { refetch: refetchRecordingId } = useNoteAssistantRecordingIdQuery({
    variables: {
      input: {
        encoding: RECORDING_ENCODING,
        encounterId: consultationUuid,
        languageTag: locale,
        sampleRate: RECORDING_SAMPLE_RATE,
        sourceType: sourceType || '', // Fallback value won't be used as query is skipped when sourceType is falsy
        speakers: [
          {
            speaker_id: clinicianId,
            speaker_type: SPEAKER_TYPE.CLINICIAN,
          },
          {
            speaker_id: patientId,
            speaker_type: SPEAKER_TYPE.PATIENT,
          },
        ],
      },
    },
    notifyOnNetworkStatusChange: true, // This is needed to make sure the new recordingId is fetched
    skip:
      !recordingEnabled ||
      !patientMediaStream ||
      !clinicianMediaStream ||
      !sourceType,
    onCompleted: (data) => {
      if (!recordingId.current) {
        eventBus.emit('CALL_RECORDING_STARTED')
      }

      const id = data.noteAssistantRecordingId.recording_id
      recordingId.current = id
      startCallRecording()
    },
    onError: (error) => {
      eventBus.emit('CALL_RECORDING_ERROR', error)
      trackEvent({
        action: NOTE_ASSISTANT_ERROR_ACTION,
        label: NOTE_ASSISTANT_ERROR_LABEL,
      })
      stopCallRecording()
    },
  })

  const createMediaRecorder = (
    mediaStream: MediaStream,
    speakerId: string,
    recordingId: string
  ) => {
    let order = 0
    const options: MediaRecorderOptions = {
      mimeType: 'audio/webm;codecs=opus',
    }

    const mediaRecorder = new MediaRecorder(mediaStream, options)

    mediaRecorder.ondataavailable = async (e: BlobEvent) => {
      order++

      const payload: PostAudioPayload = {
        chunk: e.data,
        order,
        speakerId,
        timestamp: new Date().getTime(),
        final: mediaRecorder.state === 'inactive',
      }

      try {
        await sendAudioFile(
          recordingId,
          payload,
          sendAudioAttempts,
          sendAudioRetryDelay
        )
      } catch (error) {
        eventBus.emit('CALL_RECORDING_ERROR', error)
        trackEvent({
          action: NOTE_ASSISTANT_ERROR_ACTION,
          label: NOTE_ASSISTANT_ERROR_LABEL,
        })
        stopCallRecording()
      }
    }

    try {
      mediaRecorder.start(audioRecordingInterval)
    } catch (e) {
      logToDevConsole('error-starting-recorders')
    }

    return mediaRecorder
  }

  const setupMediaRecorders = () => {
    if (patientMediaRecorder.current && clinicianMediaRecorder.current) return

    if (patientMediaStream && clinicianMediaStream && recordingId.current) {
      patientMediaRecorder.current = createMediaRecorder(
        patientMediaStream,
        patientId,
        recordingId.current
      )
      clinicianMediaRecorder.current = createMediaRecorder(
        clinicianMediaStream,
        clinicianId,
        recordingId.current
      )
    }
  }

  const stopAudioRecording = () => {
    if (patientMediaRecorder?.current?.state === 'recording') {
      patientMediaRecorder.current.stop()
      patientMediaRecorder.current = null
    }

    if (clinicianMediaRecorder?.current?.state === 'recording') {
      clinicianMediaRecorder.current.stop()
      clinicianMediaRecorder.current = null
    }
  }

  const resetMediaRecorders = async () => {
    try {
      await refetchRecordingId()
      stopAudioRecording()
    } catch {
      stopCallRecording()
    }
  }

  const startResetTimeout = () => {
    resetTimeoutId.current = setTimeout(
      resetMediaRecorders,
      recordingRestartInterval
    )
  }

  const clearResetTimeout = () => {
    if (resetTimeoutId.current) {
      clearTimeout(resetTimeoutId.current)
      resetTimeoutId.current = null
    }
  }

  const startCallRecording = () => {
    setupMediaRecorders()
    startResetTimeout()
  }

  const stopCallRecording = () => {
    if (
      patientMediaRecorder?.current?.state === 'recording' ||
      clinicianMediaRecorder?.current?.state === 'recording'
    ) {
      eventBus.emit('CALL_RECORDING_STOPPED')
    }
    stopAudioRecording()
    clearResetTimeout()
    recordingId.current = null
  }

  useEffect(() => {
    if (
      recordingEnabled &&
      patientMediaStream &&
      clinicianMediaStream &&
      sourceType
    ) {
      refetchRecordingId()
    }
  }, [
    clinicianMediaStream,
    patientMediaStream,
    recordingEnabled,
    refetchRecordingId,
    sourceType,
  ])

  return { stopCallRecording }
}

export default useCallRecording
