import { faUserFriends } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ComponentType, LazyExoticComponent } from 'react'

import { useFormatMessage } from '@babylon/intl'
import * as Access from '@babylon/web-platform-access'

import {
  AllConsultationsPageInterface,
  AllConsultationsPageNavigationItemInterface,
} from '~/core/config/modules/generated/types'
import { NavigationLink } from '~/features/navigation'

import messages from './Navigation.messages'

export const LazyAllConsultationsPage: LazyExoticComponent<
  ComponentType<AllConsultationsPageInterface>
> = React.lazy(() => import('./AllConsultations'))

export const AllConsultationsPageNavigationItem: AllConsultationsPageNavigationItemInterface = ({
  path,
}) => {
  const fm = useFormatMessage()
  const [granted, error] = Access.useDecide('all-consultation-page')

  if (error) {
    throw error
  }

  if (!granted) {
    return null
  }

  return (
    <NavigationLink
      data-testid="all-consultations-navigation-link"
      to={path}
      icon={
        <FontAwesomeIcon
          icon={faUserFriends}
          style={{ height: 24, width: 24 }}
          color="#6B7785"
        />
      }
    >
      {fm(messages.all_consultations_text)}
    </NavigationLink>
  )
}
