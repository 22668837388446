import { defineMessages } from 'react-intl'

const baseId = 'components.organisms.signer_alert'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Patient {missingString} is missing on profile',
  },
  message: {
    id: `${baseId}.message`,
    defaultMessage: 'Unable to prescribe. {msg}',
  },
})
