import { defineMessages } from 'react-intl'

const baseId = 'consultation.nhs_appointment_modal'

export default defineMessages({
  modal_title: {
    id: `${baseId}.modal_title`,
    defaultMessage: 'Create appointment',
  },
  medium_type_label: {
    id: `${baseId}.medium_type_label`,
    defaultMessage: 'Medium of appointment:',
  },
  medium_option_face_to_face: {
    id: `${baseId}.medium_option_face_to_face`,
    defaultMessage: 'Face-to-face',
  },
  medium_option_digital: {
    id: `${baseId}.medium_option_digital`,
    defaultMessage: 'Digital',
  },
  appointment_type_label: {
    id: `${baseId}.appointment_type_label`,
    defaultMessage: 'Type of appointment:',
  },
  appointment_type_nurse_hca_phleb: {
    id: `${baseId}.appointment_type_nurse_hca_phleb`,
    defaultMessage: 'Nurse/HCA/Phlebotomist',
  },
  appointment_type_gp: {
    id: `${baseId}.appointment_type_gp`,
    defaultMessage: 'GP',
  },
  appointment_type_nurse: {
    id: `${baseId}.appointment_type_nurse`,
    defaultMessage: 'Nurse',
  },
  appointment_type_pharmacist: {
    id: `${baseId}.appointment_type_pharmacist`,
    defaultMessage: 'Prescribing Pharmacist',
  },
  intimate_appointment_label: {
    id: `${baseId}.intimate_appointment_label`,
    defaultMessage: 'Intimacy of appointment:',
  },
  intimate_appointment: {
    id: `${baseId}.intimate_appointment`,
    defaultMessage: 'Recommend chaperone',
  },
  duration_service_label: {
    id: `${baseId}.duration_service_label`,
    defaultMessage: 'Duration:',
  },
  duration_service_dropdown_placeholder: {
    id: `${baseId}.duration_service_dropdown_placeholder`,
    defaultMessage: 'Select duration',
  },
  intimate_appointment_tooltip: {
    id: `${baseId}.face_to_face_intimacy_tooltip`,
    defaultMessage:
      'If you think the appointment is intimate, check the box & the user will be offered a chaperone in the app.',
  },
  duration_tooltip: {
    id: `${baseId}.face_to_face_duration_tooltip`,
    defaultMessage:
      'The default duration has been set by the head office & face-to-face clinicians.',
  },
  appointment_reason_label: {
    id: `${baseId}.appointment_reason_label`,
    defaultMessage: 'Reason for appointment:',
  },
  appointment_reason_helper_text: {
    id: `${baseId}.appointment_reason_helper_text`,
    defaultMessage: 'Please enter a reason to guide the next clinician',
  },
  next_clinician_link: {
    id: `${baseId}.next_clinician_link`,
    defaultMessage: 'What will the next clinician see?',
  },
  send_to_patient_button_label: {
    id: `${baseId}.send_to_patient_button_label`,
    defaultMessage: 'Send to patient',
  },
  send_to_clinops_button_label: {
    id: `${baseId}.send_to_clinops_button_label`,
    defaultMessage: 'Send to ClinOps',
  },
  digital_appointment_message_alert_label: {
    id: `${baseId}.digital_appointment_message_alert_label`,
    defaultMessage: 'By pressing "Send to ClinOps” I confirm I have',
  },
  digital_appointment_message_alert_line_1: {
    id: `${baseId}.digital_appointment_message_alert_line_1`,
    defaultMessage: 'Reviewed the patient data ',
  },
  digital_appointment_message_alert_line_2: {
    id: `${baseId}.digital_appointment_message_alert_line_2`,
    defaultMessage: 'Have advised the patient of the next steps',
  },
  digital_appointment_message_alert_line_3: {
    id: `${baseId}.digital_appointment_message_alert_line_3`,
    defaultMessage:
      'Provided an appropriate level of clinical information to ensure the correct task is performed',
  },
  next_clinician_alert_label: {
    id: `${baseId}.next_clinician_alert_label`,
    defaultMessage: 'What will the next clinician see? ',
  },
  next_clinician_alert_message: {
    id: `${baseId}.next_clinician_alert_message`,
    defaultMessage:
      "They will see the 'Appointment reason' as the presenting complaint. They will also have full access to the consultation notes from this appointment.",
  },
  next_clinician_message: {
    id: `${baseId}.next_clinician_message`,
    defaultMessage: `The next available clinician will see the 'Appointment reason' as the presenting complaint.
    They will also have full access to the consultation notes from this appointment`,
  },
  ice_message: {
    id: `${baseId}.ice_message`,
    defaultMessage:
      'For blood tests please ensure you have also requested this in ICE to avoid delays to patient care.',
  },
  service_types_fetch_error: {
    id: `${baseId}.service_types_fetch_error`,
    defaultMessage: "We can't load service types right now",
  },
  consultation_fetch_error: {
    id: `${baseId}.consultation_fetch_error`,
    defaultMessage: "We can't load consultation data right now",
  },
  urgency_service_label: {
    id: `${baseId}.urgency_service_label`,
    defaultMessage: 'Urgency:',
  },
  urgency_service_placeholder: {
    id: `${baseId}.urgency_service_placeholder`,
    defaultMessage: 'Select urgency:',
  },
  urgency_option_routine: {
    id: `${baseId}.urgency_option_routine_default`,
    defaultMessage: 'Routine (default)',
  },
  urgency_option_urgent: {
    id: `${baseId}.urgency_option_urgent`,
    defaultMessage: 'Urgent',
  },
  available_slot_text: {
    id: `${baseId}.available_slot_text`,
    defaultMessage:
      'First available slot for {serviceName} is at {time} on {date}',
  },
  duration_minutes_label: {
    id: `${baseId}.duration_minutes_label`,
    defaultMessage: '{time} minutes',
  },
  duration_minutes_label_default: {
    id: `${baseId}.duration_minutes_label_default`,
    defaultMessage: '{time} minutes (default)',
  },
  empty_field_validation_message: {
    id: `${baseId}.empty_field_validation_message`,
    defaultMessage: 'Cannot be empty',
  },
  nhs_appointment: {
    id: `${baseId}.nhs_appointment_v2`,
    defaultMessage: 'NHS appointment',
  },
})
