import React from 'react'

import { getFocusedWord } from '../ClinicalCodes'

export const AUTOCOMPLETE_MARK_TYPE = 'AUTOCOMPLETE_MARK_TYPE'
export const AUTOCOMPLETE_MARK_SELECTOR = 'autocomplete-mark-context'

const createDecoration = (value, string) => {
  const { selection } = value

  return {
    anchor: {
      key: selection.start.key,
      offset: selection.start.offset - string.length,
    },
    focus: {
      key: selection.end.key,
      offset: selection.end.offset,
    },
    mark: {
      type: AUTOCOMPLETE_MARK_TYPE,
    },
  }
}

function Autocomplete() {
  let cachedLastWord = null

  return {
    queries: {
      canShowAutocompleteDropdown: (editor) => {
        return (
          /* Has correct decoration */
          editor.hasDecorations(AUTOCOMPLETE_MARK_TYPE) // &&
          /* Do not show menu if last word has inlines in it */
          // !hasInlines(editor.value)
        )
      },
    },
    onChange: (editor, next) => {
      const { value } = editor
      /**
       * Get currently typed word
       */
      const focusedWord = getFocusedWord(value)
      /**
       * If it is the same as cached, leave the function
       */
      if (focusedWord === cachedLastWord) {
        return next()
      }

      cachedLastWord = focusedWord

      let decorations = value.decorations.filter((decoration) => {
        return decoration.mark.type !== AUTOCOMPLETE_MARK_TYPE
      })
      /**
       * Apply decoration to the currently typed word
       */

      if (focusedWord) {
        const decoration = createDecoration(value, focusedWord)
        decorations = decorations.push(decoration)
      }

      editor.withoutSaving(() => {
        editor.setDecorations(decorations)
      })

      return next()
    },
    renderMark: (props, editor, next) => {
      const { attributes, children, mark } = props

      if (mark.type === AUTOCOMPLETE_MARK_TYPE) {
        return (
          <span className={AUTOCOMPLETE_MARK_SELECTOR} {...attributes}>
            {children}
          </span>
        )
      }

      return next()
    },
  }
}

export default Autocomplete
