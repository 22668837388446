import PropTypes from 'prop-types'
import React from 'react'

import { Cell, FormikRadioGroup, Grid, Radio, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ErrorMessage } from '~/ui/ErrorMessage'

import { getServiceType } from '../utils/serviceType'

import messages from '../NhsAppointment.messages'
import styles from '../styles.module.scss'

const columnCount = 4

const AppointmentServiceTypes = ({ serviceTypes, setFieldValue }) => {
  const fm = useFormatMessage()

  const handleChange = (selectedServiceTypeId) => {
    const serviceType = getServiceType(serviceTypes, selectedServiceTypeId)
    const defaultDuration = serviceType
      ? serviceType.default_duration_minutes
      : null

    setFieldValue('duration', defaultDuration)
  }

  const rowCount = serviceTypes.length
    ? Math.ceil(serviceTypes.length / columnCount)
    : 1

  if (!serviceTypes.length) {
    return <ErrorMessage message={fm(messages.service_types_fetch_error)} />
  }
  return (
    <FormikRadioGroup
      name="selectedServiceTypeId"
      id="selectedServiceTypeId"
      onChange={handleChange}
    >
      <Grid
        columns={columnCount}
        className={styles.serviceTypesGrid}
        style={{
          rowGap: 12,
          gridAutoFlow: 'column',
          wordBreak: 'break-word',
          gridTemplateRows: `repeat(${rowCount}, auto)`,
        }}
      >
        {serviceTypes.map((type) => (
          <Cell key={type.id}>
            <Radio value={type.id} className={styles.radio_button}>
              <Text>{type.name}</Text>
            </Radio>
          </Cell>
        ))}
      </Grid>
    </FormikRadioGroup>
  )
}

AppointmentServiceTypes.propTypes = {
  serviceTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default AppointmentServiceTypes
