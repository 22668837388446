/**
 * Format places search results
 *
 * @param {Array} results Places search results
 * @returns {Array} Formatted search results
 *
 * @author Tameem Safi
 */
export const formatPlacesSearchResults = (results) => {
  if (!results) {
    return []
  }

  return results.map(({ name, phone, address }) => {
    const addressLines = address?.line ?? []

    return {
      name,
      phone,
      address_first_line: addressLines?.[0] ?? '',
      address_second_line: addressLines?.[1] ?? '',
      address_third_line: addressLines?.[2] ?? '',
      address_post_code: address?.postalCode ?? '',
      address_string: addressLines.join(', '),
    }
  })
}

export default {
  formatPlacesSearchResults,
}
