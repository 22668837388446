import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history.timeline_workflow_actions'

export default defineMessages({
  workflow_state_ACTIVE: {
    id: `${baseId}.workflow_state_ACTIVE_v2`,
    defaultMessage: 'In progress',
  },
  workflow_state_COMPLETED: {
    id: `${baseId}.workflow_state_COMPLETED`,
    defaultMessage: 'Completed',
  },
  workflow_state_SUSPENDED: {
    id: `${baseId}.workflow_state_SUSPENDED`,
    defaultMessage: 'Suspended',
  },
  workflow_state_INTERNALLY_TERMINATED: {
    id: `${baseId}.workflow_state_INTERNALLY_TERMINATED_v2`,
    defaultMessage: 'Internally terminated',
  },
  workflow_state_EXTERNALLY_TERMINATED: {
    id: `${baseId}.workflow_state_EXTERNALLY_TERMINATED_v2`,
    defaultMessage: 'Externally terminated',
  },
  workflow_state_UNKNOWN: {
    id: `${baseId}.workflow_state_UNKNOWN`,
    defaultMessage: 'Unknown',
  },
  appointment_invites_label: {
    id: `${baseId}.appointment_invites_label`,
    defaultMessage: 'Appointment invites: ',
  },
  no_service_type: {
    id: `${baseId}.no_service_type`,
    defaultMessage: 'No service type',
  },
  workflow_label: {
    id: `${baseId}.workflow_label`,
    defaultMessage: '{label}: ',
  },
})
