import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review'

export default defineMessages({
  error_loading: {
    id: `${baseId}.error_loading`,
    defaultMessage: 'Unable to load consultation notes',
  },
  contact_support: {
    id: `${baseId}.contact_support`,
    defaultMessage:
      'Try closing and signing out of Athena and then refreshing this page. If the error persists, contact support for additional assistance.',
  },
})
