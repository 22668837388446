import React from 'react'

import { Spinner } from '@babylon/core-ui'

import { PrescriptionsSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { usePermissions } from '~/core/permissions'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import Section from '~/features/consultation-summary/ConsultationSummary/Section'
import { usePrescriptionQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import PrescriptionsSummary from './PrescriptionsSummary'

import messages from './PrescriptionsSummary.messages'

const PrescriptionsSummaryModule: PrescriptionsSummaryPluginInterface = () => {
  const PrescriptionsSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)
    const [canReadAnyPrescription] = usePermissions('read_any_prescription')

    const { data, loading, error, refetch } = usePrescriptionQuery({
      variables: {
        id: consultation.id,
      },
      skip: !canReadAnyPrescription,
    })

    const prescriptions =
      data?.consultation?.prescriptions?.filter(
        (index) => index.prescriptionState !== 'REJECTED'
      ) ?? []

    if (!canReadAnyPrescription) {
      return null
    }

    if (loading) {
      return (
        <Section title={f('prescriptions_section_title_private')}>
          <Spinner size="small" />
        </Section>
      )
    }

    if (error) {
      return (
        <Section title={f('prescriptions_section_title_private')}>
          <ErrorPanel
            error={error}
            title={f('prescriptions_loading_error')}
            retry={() => refetch()}
          />
        </Section>
      )
    }

    return <PrescriptionsSummary prescriptions={prescriptions} />
  }

  return PrescriptionsSummaryWrapper
}

export default PrescriptionsSummaryModule
