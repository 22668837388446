import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.preconsultation'

export default defineMessages({
  title_v2: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Pre-consultation questionnaire',
  },
  cant_load_chatbot_flow: {
    id: `${baseId}.cant_load_chatbot_flow`,
    defaultMessage: "We can't load Chatbot Flow",
  },
  cant_load_timestamp_v2: {
    id: `${baseId}.cant_load_timestamp_v2`,
    defaultMessage: "We can't load the timestamp.",
  },
  retry: {
    id: `${baseId}.retry`,
    defaultMessage: 'Retry',
  },
})
