import {
  ApolloQueryResult,
  ErrorPolicy as ErrorPolicyType,
  MutationHookOptions,
  MutationResult as ApolloMutationResult,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { Observable } from 'rxjs'

import apolloClient from '~/core/apollo/client'

import { logException } from '../sentry'
import { replaySubject } from './rxjs-fp'

export type QueryResult<T> = Pick<
  ApolloQueryResult<T | undefined>,
  'loading' | 'data' | 'error' | 'errors' | 'networkStatus'
>

export type MutationResult<T> = Pick<
  ApolloMutationResult<T>,
  'loading' | 'data' | 'error'
>

export enum ErrorPolicy {
  none = 'none',
  ignore = 'ignore',
  all = 'all',
}

/** Source
 * @param errorPolicy - force to set errorPolicy explicitly
 *   errorPolicy: 'all' is useful for displaying partial data
 *   but the 'errors' array is lost when the result is
 *   retrieved from the cache
 */
type Source<TData, TVariables> = Observable<
  QueryHookOptions<TData, TVariables> & {
    errorPolicy: ErrorPolicyType
  }
>

export const watchQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
) => (source: Source<TData, TVariables>): Observable<QueryResult<TData>> => {
  const stream = replaySubject<QueryResult<TData>>(1)

  let subscription: ZenObservable.Subscription

  source.subscribe({
    next: (options) => {
      try {
        subscription?.unsubscribe()

        const observableQuery = apolloClient.watchQuery({
          ...options,
          query,
        })

        subscription = observableQuery.subscribe(
          (result) => {
            if (result.error) {
              logException(result.error)
              stream.error(result.error)
            } else {
              if (result.errors) {
                logException(result.errors)
              }
              stream.next(result)
            }
          },
          (error) => {
            logException(error)
            stream.error(error)
          }
        )
      } catch (error) {
        logException(error)
        stream.error(error)
      }
    },
  })

  return stream
}

export const mutation = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
) => (
  source: Observable<MutationHookOptions<TData, TVariables>>
): Observable<MutationResult<TData>> => {
  const stream = replaySubject<MutationResult<TData>>(1)

  source.subscribe(async (options) => {
    try {
      stream.next({ loading: true })
      const result = await apolloClient.mutate<TData, TVariables>({
        ...options,
        mutation: query,
      })
      if (result.errors) {
        logException(result.errors)
        stream.error(result.errors)
      } else {
        stream.next({ loading: false, ...result })
      }
    } catch (error) {
      logException(error)
      stream.next({ loading: false, error })
    }
  })

  return stream
}
