import debounce from 'lodash/debounce'
import React from 'react'

import { useSearchFastCtConceptsQueryLazyQuery } from '~/generated'
import { errorOptions } from '~/ui/ErrorMessage'
import MedicalSelect from '~/ui/MedicalSelect/MedicalSelect'

export interface IndicationSelectProps {
  placeholder: string
  value: any
  onAddToList: any
  onRemoveFromList: any
  className: string
}

const SEMANTIC_TAGS = ''

export const IndicationSelect = (props: IndicationSelectProps) => {
  const [
    getConditions,
    { error, data },
  ] = useSearchFastCtConceptsQueryLazyQuery()

  const fetchOptions = (term: string) => {
    getConditions({
      variables: { term, semanticTags: SEMANTIC_TAGS, pageSize: 20 },
    })
  }
  const debounceInputChange = debounce(fetchOptions, 420)

  const options = error
    ? errorOptions
    : data?.searchFastCTConcepts.concepts
    ? data.searchFastCTConcepts.concepts.map((opt: any) => ({
        id: opt.prefLabel[0].value,
        iri: opt.iri,
        info: opt.prefLabel[0].value,
      }))
    : []

  return (
    <MedicalSelect
      name="indications"
      {...props}
      onInputChange={debounceInputChange}
      options={options}
    />
  )
}

export default IndicationSelect
