import { ApolloError } from '@apollo/client'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  PAT_MET_OPEN_BLOOD_PRESSURE_MODAL_CARD,
  PAT_MET_OPEN_BMI_MODAL_CARD,
  PAT_MET_OPEN_MODAL,
  PAT_MET_OPEN_SMOKING_STATUS_MODAL_CARD,
  PATIENT_METRICS,
  PATIENT_SUMMARY_SECTION_ERROR_ACTION,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { PatientMetricsFragment } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section, withSectionErrorBoundary } from '~/ui/Section'

import PatientMetrics from './PatientMetrics'

import messages from './PatientMetrics.messages'

const metricMap = {
  bmi: 'BmiInquiry',
  smoking_status: 'SmokingInquiry',
  blood_pressure: 'BloodPressureInquiry',
}

const getLabel = (name?: string) => {
  switch (name) {
    case metricMap.bmi:
      return PAT_MET_OPEN_BMI_MODAL_CARD
    case metricMap.smoking_status:
      return PAT_MET_OPEN_SMOKING_STATUS_MODAL_CARD
    case metricMap.blood_pressure:
      return PAT_MET_OPEN_BLOOD_PRESSURE_MODAL_CARD
    default:
      return PAT_MET_OPEN_MODAL
  }
}

interface PatientMetricsSectionProps {
  patient: PatientMetricsFragment['patient'] | undefined
  error?: ApolloError
  loading?: boolean
  refetch?: () => void
}

const PatientMetricsSection = ({
  patient,
  error,
  loading,
  refetch,
}: PatientMetricsSectionProps) => {
  const fm = useFormatMessage()
  const match = useRouteMatch()
  const history = useHistory()

  const editPatientMetrics = (open?: string) => {
    history.replace(
      `${match.url}/patient-metrics/edit${open ? `?open=${open}` : ''}`
    )
  }

  const launchModal = (name?: string) => {
    analytics.trackEvent({
      label: getLabel(name),
      category: PATIENT_METRICS,
      action: UPDATE_PATIENT_METRICS,
    })
    editPatientMetrics(name)
  }

  if (error) {
    return (
      <Section title={fm(messages.title)}>
        <ErrorPanel
          error={error}
          title={fm(messages.error_panel_loading_metrics)}
          retry={refetch}
        />
      </Section>
    )
  }

  return (
    <Section
      title={fm(messages.title)}
      loading={loading}
      topRight={
        <Button onClick={() => launchModal()} intent="secondary">
          {fm(messages.update_patient_metrics_button_label)}
        </Button>
      }
      dataTestId="patient-metrics-section"
    >
      {patient?.patient_metrics && (
        <PatientMetrics
          patientMetrics={patient.patient_metrics}
          age={patient.age}
          editPatientMetrics={launchModal}
          testid="patient-metrics-grid"
        />
      )}
    </Section>
  )
}

export default withSectionErrorBoundary({
  gaAction: PATIENT_SUMMARY_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(PatientMetricsSection)
