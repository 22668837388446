import React from 'react'

import { validateShallowObject } from './validator'

const DEFAULT_FUNCTION_NAME = 'validate'
const DEFAULT_OVERWRITE_PROP = true

const filterValidationErrors = (errors, { reasons }) =>
  reasons ? errors.filter((v) => reasons.includes(v.reason)) : errors

const validate = (schema) => (object, filters = {}) =>
  filterValidationErrors(validateShallowObject(object, schema), filters)

const createErrorMessage = (name) =>
  `withValidation: prop "${name}" already exists, please use another name`

const withValidator = ({
  scheme,
  name = DEFAULT_FUNCTION_NAME,
  overwriteProp = DEFAULT_OVERWRITE_PROP,
}) => (WrappedComponent) => (wrappedProps) => {
  const props = { ...wrappedProps }

  if (scheme) {
    if (!overwriteProp && !!wrappedProps[name]) {
      throw createErrorMessage(name)
    }

    props[name] = validate(scheme)
  }

  return <WrappedComponent {...props} />
}

export default withValidator
