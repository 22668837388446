import { useEffect, useMemo, useState } from 'react'
import { BehaviorSubject, Observable } from 'rxjs'

type SpecialObservable<T> = Observable<T> & {
  getValue?: BehaviorSubject<T>['getValue']
}

export function useObservable<T>(
  observable: SpecialObservable<T>
): T | undefined

export function useObservable<T>(
  observable: SpecialObservable<T>,
  defaultValue: T
): T

export function useObservable(observable: any, defaultValue?: any) {
  const calculatedDefaultValue = useMemo(() => {
    let value = observable?.getValue?.()

    if (!value) {
      observable
        .subscribe((v: any) => {
          value = v
        })
        .unsubscribe()
    }

    return value
  }, [observable])

  const value = calculatedDefaultValue || defaultValue

  if (!value) {
    throw new Error(
      'Unknown default value. Please provide a default value for asynchronous observables!'
    )
  }

  const [state, setState] = useState(value)

  useEffect(() => {
    const subscription = observable.subscribe(setState)
    return () => subscription.unsubscribe()
  }, [observable, setState])

  return state
}
