import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.referrals'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private referrals',
  },
  tooltip_message: {
    id: `${baseId}.tooltip_message`,
    defaultMessage:
      'Private Referral letters will be available for the patient to download from their app in the Appointments section once you have Completed the consultation. The patient should then speak with their Insurer (or directly with the Specialist if they do not have insurance) for next steps.',
  },
  add_referral_button_label: {
    id: `${baseId}.add_referral_button_label_v2`,
    defaultMessage: 'Add referral',
  },
  referrals_category_key: {
    id: `${baseId}.referrals_category_key`,
    defaultMessage: 'Category',
  },
  referrals_specialism_key: {
    id: `${baseId}.referrals_specialism_key`,
    defaultMessage: 'Specialism',
  },
  referrals_specialist_key: {
    id: `${baseId}.referrals_specialist_key`,
    defaultMessage: 'Specialist',
  },
  referrals_comments_key: {
    id: `${baseId}.referrals_comments_key`,
    defaultMessage: 'Comments',
  },
  delete_referral_confirmation_message: {
    id: `${baseId}.delete_referral_confirmation_message`,
    defaultMessage: 'Are you sure you want to delete the referral?',
  },
  delete_referral_remove_button_label: {
    id: `${baseId}.delete_referral_remove_button_label`,
    defaultMessage: 'Remove',
  },
  delete_referral_cancel_button_label: {
    id: `${baseId}.delete_referral_cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  error_loading_referrals_message: {
    id: `${baseId}.error_loading_referrals_message`,
    defaultMessage: 'Error loading the referrals',
  },
  category_id_name: {
    id: `${baseId}.category_id_name`,
    defaultMessage: 'Category ID: #{id}',
  },
})
