import { defineMessages } from 'react-intl'

const baseId = 'gp_finder_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Update patient GP details',
  },
  gp_practice_postcode_lookup_label: {
    id: `${baseId}.gp_practice_postcode_lookup_label_v2`,
    defaultMessage: 'GP practice lookup',
  },
  gp_practice_postcode_lookup_placeholder: {
    id: `${baseId}.gp_practice_postcode_lookup_placeholder_v2`,
    defaultMessage: 'Enter postcode',
  },
  gp_practice_postcode_lookup_info_text: {
    id: `${baseId}.gp_practice_postcode_lookup_info_text_v2`,
    defaultMessage: 'Closest results shown first',
  },
  gp_practice_postcode_lookup_loading_message: {
    id: `${baseId}.gp_practice_postcode_lookup_loading_message`,
    defaultMessage: 'Loading...',
  },
  gp_practice_postcode_lookup_invalid_postcode_error_message: {
    id: `${baseId}.gp_practice_postcode_lookup_invalid_postcode_error_message`,
    defaultMessage: 'Please enter a valid postcode.',
  },
  gp_practice_postcode_lookup_no_results_message: {
    id: `${baseId}.gp_practice_postcode_lookup_no_results_message`,
    defaultMessage: 'No results were found.',
  },
  gp_name_label: {
    id: `${baseId}.gp_name_label_v2`,
    defaultMessage: 'GP name',
  },
  gp_name_placeholder: {
    id: `${baseId}.gp_name_placeholder`,
    defaultMessage: 'Name',
  },
  gp_consent_label: {
    id: `${baseId}.gp_consent_label_v2`,
    defaultMessage: 'GP consent',
  },
  surgery_name_label: {
    id: `${baseId}.surgery_name_label_v2`,
    defaultMessage: 'Surgery name',
  },
  surgery_name_placeholder: {
    id: `${baseId}.surgery_name_placeholder`,
    defaultMessage: 'Name',
  },
  surgery_phone_number_label: {
    id: `${baseId}.surgery_phone_number_label_v2`,
    defaultMessage: 'Surgery phone number',
  },
  surgery_phone_number_placeholder: {
    id: `${baseId}.surgery_phone_number_placeholder_v2`,
    defaultMessage: 'Phone number',
  },
  address_first_line_label: {
    id: `${baseId}.address_first_line_label_v2`,
    defaultMessage: 'Surgery address',
  },
  address_first_line_placeholder: {
    id: `${baseId}.address_first_line_placeholder`,
    defaultMessage: 'Address line 1',
  },
  address_second_line_placeholder: {
    id: `${baseId}.address_second_line_placeholder`,
    defaultMessage: 'Address line 2',
  },
  address_third_line_placeholder: {
    id: `${baseId}.address_third_line_placeholder`,
    defaultMessage: 'Address line 3',
  },
  address_post_code_label: {
    id: `${baseId}.address_post_code_label`,
    defaultMessage: 'Postcode',
  },
  address_post_code_placeholder: {
    id: `${baseId}.address_post_code_placeholder`,
    defaultMessage: 'Postcode',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  save_button_label: {
    id: `${baseId}.save_button_label`,
    defaultMessage: 'Save',
  },
  error_on_saving_gp_details: {
    id: `${baseId}.error_on_saving_gp_details`,
    defaultMessage: 'Error on saving GP details',
  },
})
