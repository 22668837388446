import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.summary.examination_summary'

export default defineMessages({
  what_you_told_use_section_title: {
    id: `${baseId}.what_you_told_use_section_title`,
    defaultMessage: 'History',
  },
  what_we_explored_section_title: {
    id: `${baseId}.what_we_explored_section_title`,
    defaultMessage: 'Examination',
  },
  examination_summary_loading_error: {
    id: `${baseId}.examination_summary_loading_error`,
    defaultMessage: 'Error loading examination notes',
  },
})
