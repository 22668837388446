import React from 'react'

import { RawErrorBoundary } from '~/core/ErrorBoundary'
import ErrorPage from '~/ui/ErrorPage'

interface GlobalErrorBoundaryProps {
  children: React.ReactNode
}

/**
 * Since this error boundary sits at the top of the app as a last resort,
 * it has no dependencies on react-intl and other contexts
 */

const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
  return (
    <RawErrorBoundary render={() => <ErrorPage title="Something went wrong" />}>
      {children}
    </RawErrorBoundary>
  )
}

export default GlobalErrorBoundary
