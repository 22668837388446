import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useConsultantUser } from '@babylon/babylon-user'
import {
  Label,
  Select,
  SelectOptionType,
  SelectOptionTypeBase,
  SelectValueType,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { findById } from '~/core/array'
import { WorkflowDefinition } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'
import SectionSeparator from '~/ui/SectionSeparator'

import useConsultationWorkflowsV2Query from '../WorkflowActions/useConsultationWorkflowsV2Query'
import { mapWorkflowDefinitionsToOptions } from './mappings'
import useConsultation from './useConsultation'
import WorkflowDefinitionForm from './WorkflowDefinitionForm'

import styles from './styles.module.scss'
import messages from './WorkflowDefinitionModal.messages'

const WorkflowDefinitionsModal = () => {
  const { type, consultationId } = useParams<{
    type: string
    consultationId: string
  }>()
  const user = useConsultantUser()
  const consultantId = user.id
  const consultantName = user.consultant.name

  const fm = useFormatMessage()
  const history = useHistory()

  const {
    data,
    loading: consultationLoading,
    error: consultationError,
    refetch: consultationRefetch,
  } = useConsultation(consultationId)

  const consultation = data?.consultation
  const consumerNetwork = consultation?.consumerNetwork

  const {
    data: definitionsData,
    loading: definitionsLoading,
    error: definitionsError,
    refetch: definitionsRefetch,
  } = useConsultationWorkflowsV2Query({
    consultationId,
    consumerNetwork,
    skip: !consumerNetwork,
  })

  const workflowDefinitions =
    definitionsData?.consultationWorkflowsV2?.workflowDefinitions ?? []

  const [
    workflowDefinition,
    setWorkflowDefinition,
  ] = useState<SelectOptionType | null>(null)

  const workflowDefinitionOptions = mapWorkflowDefinitionsToOptions(
    workflowDefinitions,
    type
  )

  const definitionOfType = workflowDefinitions.find(
    ({ group }) => group.type === type
  )

  const title =
    definitionOfType?.group?.label || fm(messages.start_new_workflow)

  const changeWorkflowDefinition = (definition: SelectValueType) => {
    setWorkflowDefinition(definition as SelectOptionType)
  }

  const getWorkflowDefinitionKey = (
    id: string,
    definitions: WorkflowDefinition[]
  ): string | undefined => {
    return findById(definitions, id)?.key
  }

  const onClose = () => {
    history.replace(`/consultation/${consultationId}`)
  }

  const handleRefetch = consultationError
    ? consultationRefetch
    : definitionsRefetch

  const error = consultationError || definitionsError

  useEffect(() => {
    if (workflowDefinitionOptions.length === 1 && !workflowDefinition) {
      setWorkflowDefinition(workflowDefinitionOptions[0])
    }
  }, [workflowDefinition, workflowDefinitionOptions])

  return (
    <Slideout
      title={title}
      onClose={onClose}
      loading={consultationLoading || definitionsLoading}
    >
      {() =>
        error ? (
          <ErrorPanel
            error={error}
            title={fm(messages.error_message)}
            retry={() => (handleRefetch ? handleRefetch() : null)}
            fill="container"
            center
          />
        ) : (
          <div className={styles.definitionContent}>
            {workflowDefinitionOptions.length !== 1 && (
              <div className={styles.formRow}>
                <div className={styles.formRow}>
                  <Label htmlFor="workflow-definition">
                    {fm(messages.type)}
                  </Label>
                  <Select
                    options={workflowDefinitionOptions}
                    onChange={changeWorkflowDefinition}
                    selectedOption={workflowDefinition}
                    id="workflow-definition"
                    data-testid="workflow-definition"
                    searchable
                    getOptionLabel={(option: SelectOptionTypeBase) =>
                      option.label
                    }
                  />
                </div>
                {workflowDefinition && <SectionSeparator />}
              </div>
            )}
            {workflowDefinition && consultation && (
              <WorkflowDefinitionForm
                workflowDefinitionId={workflowDefinition.value}
                workflowDefinitionKey={getWorkflowDefinitionKey(
                  workflowDefinition.value,
                  workflowDefinitions
                )}
                tenantId={consumerNetwork}
                consultantId={consultantId}
                consultantName={consultantName}
                closeModal={onClose}
                consultation={consultation}
                action={title}
              />
            )}
          </div>
        )
      }
    </Slideout>
  )
}

export default WorkflowDefinitionsModal
