import React from 'react'

import { Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { DIAGNOSIS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useConsultation } from '~/core/config'
import { useDiagnosisQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import Label from '~/ui/Label'
import { Section, withSectionErrorBoundary } from '~/ui/Section'
import Textarea from '~/ui/Textarea'

import { useConsultationNoteTimer, useIsEditDisabled } from '../utils'
import DiagnosisCodeList from './DiagnosisCodeList'
import DiagnosisField from './DiagnosisField'
import {
  DiagnosisStoreConsumer,
  DiagnosisStoreProvider,
} from './DiagnosisStore'

import messages from './Diagnosis.messages'

const Diagnosis = ({ consultationContext, editedConsultationWarningModel }) => {
  const fm = useFormatMessage()
  const consultation = useConsultation(consultationContext)

  const markFinalizedConsultationAsEdited = editedConsultationWarningModel?.useModelContext()
    ?.markFinalizedConsultationAsEdited

  const { data, loading, error, refetch } = useDiagnosisQuery({
    variables: {
      consultationId: consultation.id,
    },
    errorPolicy: 'all',
  })

  const {
    startTimer: startAdviceTimer,
    stopTimer: stopAdviceTimer,
  } = useConsultationNoteTimer('advice')
  const {
    startTimer: startSafetyNettingTimer,
    stopTimer: stopSafetyNettingTimer,
  } = useConsultationNoteTimer('safety netting')

  const editDisabled = useIsEditDisabled(consultation.status)

  if (loading) {
    return <Spinner size="small" />
  }

  // Fail fast if cannot load notes
  if (error && !data?.consultation?.consultationNote) {
    return (
      <ErrorPanel
        error={error}
        fill="container"
        center
        retry={() => refetch()}
        title={fm(messages.cant_load_diagnosis)}
      />
    )
  }

  const { consultationNote } = data.consultation

  return (
    <DiagnosisStoreProvider
      consultationId={consultation.id}
      initialState={{
        assessment: consultationNote.assessment,
        assessmentCodes: consultationNote.assessmentCodes,
        initialAssessment: consultationNote.assessment,
        treatmentPlan: consultationNote.treatmentPlan,
        fallbackPlan: consultationNote.fallbackPlan,
      }}
    >
      <Section dataTestId="diagnosis-section">
        <DiagnosisField
          disabled={editDisabled}
          markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
        />
        <DiagnosisCodeList />
        <DiagnosisStoreConsumer>
          {({ store, onChange }) => (
            <Label value={fm(messages.advice_label)}>
              <Textarea
                placeholder={fm(messages.advice_placeholder)}
                value={store.treatmentPlan}
                disabled={editDisabled}
                onChange={(value) => {
                  onChange({ treatmentPlan: value })
                  if (markFinalizedConsultationAsEdited) {
                    markFinalizedConsultationAsEdited()
                  }
                }}
                onKeyDown={() => {
                  startAdviceTimer()
                  stopAdviceTimer()
                }}
                data-testid="advice"
              />
            </Label>
          )}
        </DiagnosisStoreConsumer>
        <DiagnosisStoreConsumer>
          {({ store, onChange }) => (
            <Label
              value={fm(messages.safety_netting_label)}
              info={fm(messages.safety_netting_info)}
            >
              <Textarea
                placeholder={fm(messages.safety_netting_placeholder)}
                value={store.fallbackPlan}
                disabled={editDisabled}
                onChange={(value) => {
                  onChange({ fallbackPlan: value })
                  if (markFinalizedConsultationAsEdited) {
                    markFinalizedConsultationAsEdited()
                  }
                }}
                onKeyDown={() => {
                  startSafetyNettingTimer()
                  stopSafetyNettingTimer()
                }}
                data-testid="safety-netting"
              />
            </Label>
          )}
        </DiagnosisStoreConsumer>
      </Section>
    </DiagnosisStoreProvider>
  )
}

export default withSectionErrorBoundary({
  gaAction: DIAGNOSIS_SECTION_ERROR_ACTION,
  titleDescriptor: messages.cant_load_diagnosis,
})(Diagnosis)
