import React from 'react'

import { useMessages } from '~/core/hooks'
import {
  isEmptyPrimitive,
  Section,
} from '~/features/consultation-summary/ConsultationSummary'
import ImageGallery from '~/features/image-gallery'
import { BookingReasonQuery } from '~/generated'

import messages from './BookingReasonSummary.messages'

interface BookingReasonSummaryProps {
  consultation: BookingReasonQuery['consultation']
}

const BookingReasonSummary = ({ consultation }: BookingReasonSummaryProps) => {
  const f = useMessages(messages)

  const patientNote = consultation?.patientNote || ''
  const images = consultation?.images || undefined

  if (isEmptyPrimitive(patientNote)) {
    return null
  }

  return (
    <Section title={f('booking_reason')} testid="cr-patient-note">
      {patientNote}
      <ImageGallery images={images} />
    </Section>
  )
}

export default BookingReasonSummary
