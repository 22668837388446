import React from 'react'

const withLabels = (labels = {}) => {
  const mapLabel = (key) => labels[key] || key
  const mapLabels = (keys = []) => keys.map(mapLabel)

  return (WrappedComponent) => {
    return (props) => (
      <WrappedComponent {...props} mapLabel={mapLabel} mapLabels={mapLabels} />
    )
  }
}

export default withLabels
