import React from 'react'
import cn from 'classnames'

import { Text } from '@babylon/core-ui'

import styles from './styles.module.scss'

const UnrecognisedResultWarning = ({ columnLength }) => (
  <div
    className={cn(
      styles.unrecognisedResult,
      styles.unrecognisedResult__warning
    )}
    role="row"
  >
    <div
      className={cn(
        'core-responsive-table__cell',
        styles.unrecognisedResult__cell,
        styles.unrecognisedResult__warning__cell
      )}
      style={{ gridColumn: `1 / ${columnLength + 1}` }}
    >
      <Text bold color="black-type">
        Unrecognised biomarker or value
      </Text>
      <Text>
        The biomarker code or value are unexpected. If you send these results to
        the patient, this biomarker will be hidden. If it should be shown,
        please contact{' '}
        <a href="mailto:test-kits@babylonhealth.com">
          test-kits@babylonhealth.com
        </a>{' '}
        to make the change before your review.
      </Text>
    </div>
  </div>
)

export default UnrecognisedResultWarning
