import restrictedClipboard from '@babylon/restricted-clipboard'

export default {
  async onPaste(event, editor) {
    /**
     * Get pasted data via restricted clipboard cache
     */
    if (restrictedClipboard.shouldUseCustomClipboard(event)) {
      editor.insertText(restrictedClipboard.getValue() || '')

      return
    }
    /**
     * Get data from DOM "paste" event, this should be in try catch to
     * support older/weirder browsers
     */
    try {
      const clipboardData = event.clipboardData || window.clipboardData
      const text = clipboardData.getData('Text') || ''

      editor.insertText(text)
    } catch (exception) {
      console.warn(exception)
    }
  },
}
