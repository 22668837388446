import { Form, Formik, FormikProps } from 'formik'
import memoize from 'lodash/memoize'
import React, { FC, useState } from 'react'

import { FormikDropdownSelect, FormikInput, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { GraphQLErrorWithExtensions } from '~/core/graphql'
import { logException } from '~/core/sentry'
import { Dialog } from '~/ui/Modal'

import styles from './styles.module.scss'
import messages from './VoidPrescriptionDialog.messages'

export interface Props {
  show: boolean
  onOk: (voidReason: string | null) => void
  onClose: () => void
}

interface VoidPrescriptionValues {
  reason: string | null
  other: string
}

interface VoidException {
  graphQLErrors: GraphQLErrorWithExtensions[]
}

const initialValues = {
  reason: null,
  other: '',
}

const VOID_REASONS: string[] = [
  'optionIncorrectQuantityPrescribed',
  'optionIncorrectMedicinePrescribed',
  'optionInappropriateFreeTextPrescription',
  'optionInappropriateQuantityPrescribed',
  'optionDrugUnavailableInPharmacy',
  'optionPotentialDrugSeekingBehaviorFlaggedMember',
  'optionOther',
]

const hasVoidDispensedPrescriptionError = (exception: VoidException) => {
  if (exception?.graphQLErrors && exception?.graphQLErrors?.length > 0) {
    return exception.graphQLErrors.some(
      (e) => e?.extensions?.response?.status === 409
    )
  }

  return false
}

const getVoidReasons = memoize((fm) =>
  VOID_REASONS.map((reason) => {
    const text = fm(messages[reason as keyof typeof messages])

    return {
      key: text,
      value: text,
    }
  })
)

const VoidPrescriptionDialog: FC<Props> = ({ show, onOk, onClose }) => {
  const fm = useFormatMessage()
  // const formRef = useRef<FormikProps<VoidPrescriptionValues>>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const voidOptions = getVoidReasons(fm)

  const isOtherReason = (reason: string | null) => {
    const selectedOption = voidOptions.find((option) => option.key === reason)
    return selectedOption?.value === fm(messages.optionOther)
  }

  // TODO: Re-enable use of ref when we upgrade to Formik > 2.1.4 as 2.1.1 does not support it
  // https://github.com/jaredpalmer/formik/issues/2208
  // const handleOk = () => {
  //   formRef?.current?.handleSubmit(undefined)
  // }

  const handleSubmit = async (values: VoidPrescriptionValues) => {
    setLoading(true)
    setError('')
    const voidReason = isOtherReason(values.reason)
      ? values.other
      : values.reason

    try {
      await onOk(voidReason)
      onClose()
    } catch (exception) {
      logException(exception)

      if (hasVoidDispensedPrescriptionError(exception as VoidException)) {
        setError(fm(messages.void_dispensed_prescription))
      } else {
        setError(fm(messages.request_failed))
      }
    }

    setLoading(false)
  }

  const handleValidation = (values: VoidPrescriptionValues) => {
    if (!values.reason) {
      return {
        reason: fm(messages.errorInvalidReason),
      }
    }

    if (isOtherReason(values.reason) && !values.other) {
      return {
        other: fm(messages.other_required),
      }
    }

    if (
      isOtherReason(values.reason) &&
      values.other &&
      values.other.length < 6
    ) {
      return {
        other: fm(messages.other_min_length),
      }
    }
  }

  return (
    (show && (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={handleValidation}
        // innerRef={formRef}
      >
        {({ values, handleSubmit }: FormikProps<VoidPrescriptionValues>) => {
          const isOther = isOtherReason(values.reason)

          return (
            <Dialog
              className={styles.dialog}
              title={fm(messages.titleVoidPrescription)}
              error={error}
              okLabel={fm(messages.buttonOk)}
              cancelLabel={fm(messages.buttonCancel)}
              okLoading={loading}
              // @ts-expect-error
              onOk={handleSubmit}
              onCancel={onClose}
              onClose={onClose}
              ok
              cancel
            >
              <Form>
                <Text tag="p" className={styles.voidWarning}>
                  {fm(messages.messageAreYouSureYouWantToVoidThisPrescription)}
                </Text>
                <FormikDropdownSelect
                  name="reason"
                  id="reason"
                  label={fm(messages.labelReason)}
                  placeholder={fm(messages.placeholderSelectAnOption)}
                  options={voidOptions}
                />
                {isOther && (
                  <FormikInput
                    name="other"
                    id="other"
                    placeholder={fm(messages.placeholderPleaseEnterReasonHere)}
                    data-testid="reason-other"
                  />
                )}
              </Form>
            </Dialog>
          )
        }}
      </Formik>
    )) ||
    null
  )
}

export default VoidPrescriptionDialog
