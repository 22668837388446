import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.document_repository'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Patient documents',
  },
  show_documents: {
    id: `${baseId}.show_documents`,
    defaultMessage: 'Load documents',
  },
})
