import React, { useState } from 'react'

import { Input } from '@babylon/core-ui'

import {
  CODING_DROPDOWN_SEARCH,
  DROPDOWN_SEARCH_TIMER,
  NOTE_TAKING_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'

import DropdownList from '../DropdownList'

import styles from './styles.module.scss'

const trackEvent = analytics.trackEventFactory({
  category: NOTE_TAKING_CATEGORY,
  action: CODING_DROPDOWN_SEARCH,
  label: DROPDOWN_SEARCH_TIMER,
})

const SelectionMenu = (props) => {
  const [value, setValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [timer, setTimer] = useState(null)

  const handleInputFocus = () => {
    const { sourceValue } = props
    if (!timer) {
      setTimer(Date.now())
    }
    setIsFocused(true)
    setValue(sourceValue)
  }

  const handleInputBlur = () => {
    analyticsOnBlur()

    setIsFocused(false)
  }

  const analyticsOnBlur = () => {
    if (timer) {
      const time = Math.round((Date.now() - timer) / 1000)
      trackEvent({ value: time })
      setTimer(null)
    }
  }

  const handleInputChange = (event) => {
    const { onSearchChange } = props
    const { value: eventValue } = event.target
    if (!timer) {
      setTimer(Date.now())
    }
    setValue(eventValue)

    if (onSearchChange) {
      onSearchChange(eventValue)
    }
  }

  const handleKeyDown = (event) => {
    const {
      incrementKeyboardFocus,
      decrementKeyboardFocus,
      onListItemSelect,
      onEscape,
      focusedByKeyboardIndex,
      options,
    } = props

    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        decrementKeyboardFocus()
        break
      case 'ArrowDown':
        event.preventDefault()
        incrementKeyboardFocus()
        analyticsOnBlur()
        break
      case 'Enter':
        event.preventDefault()
        analyticsOnBlur()
        onListItemSelect({
          index: focusedByKeyboardIndex,
          option: options[focusedByKeyboardIndex],
        })
        break
      case 'Escape':
        analyticsOnBlur()
        event.preventDefault()
        onEscape()
        break
      default:
    }
  }

  const renderEmptyListMessage = () => {
    return <div className={styles.empty}>No suggestions</div>
  }

  const {
    anchor,
    options,
    sourceValue = '',
    onFocus,
    onBlur,
    onMount,
    onListItemFocus,
    onListItemSelect,
    focusedByMouseIndex,
    focusedByKeyboardIndex,
    optionsLoading,
    enableNoteIcon,
    dropdownId,
  } = props

  const inputValue = isFocused ? value : sourceValue

  return (
    <DropdownList
      dropdownId={dropdownId}
      anchor={anchor}
      options={options}
      onFocus={onFocus}
      onBlur={onBlur}
      onMount={onMount}
      onListItemFocus={onListItemFocus}
      onListItemSelect={(props) => {
        analyticsOnBlur()
        onListItemSelect(props)
      }}
      focusedByMouseIndex={focusedByMouseIndex}
      focusedByKeyboardIndex={focusedByKeyboardIndex}
      enableNoteIcon={enableNoteIcon}
      header={
        <div>
          <Input
            fill
            data-testid="dropdown-input"
            value={inputValue}
            loading={optionsLoading}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            placeholder="Search..."
          />
        </div>
      }
      footer={
        !optionsLoading && options.length === 0 && renderEmptyListMessage()
      }
    />
  )
}

export default SelectionMenu
