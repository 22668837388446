import { Field } from 'formik'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { Grid, NumberInput } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { WeightConversion } from '@babylon/shared-utils'

import { usePrevious } from '~/core/hooks'
import Timestamp from '~/features/patient-metrics/utils/Timestamp'

import { usePatientMetricsState } from '../PatientMetricsStateProvider'
import BmiSectionHeader from './BmiSectionHeader'
import { validateWeight } from './utils'

import messages from '../PatientMetricsModal.messages'
import styles from '../styles.module.scss'

type InputEvent = ChangeEvent<HTMLInputElement>

interface ImperialWeightFormParams {
  timestamp?: string
}

const ImperialWeight: React.FC<ImperialWeightFormParams> = ({ timestamp }) => {
  const fm = useFormatMessage()
  const {
    values: { weight },
    displayValues: { displayWeight, performerWeight },
    setFieldValue,
    setFieldTouched,
    errors,
  } = usePatientMetricsState()

  const { st = '', lb = '' } =
    weight == null ? {} : WeightConversion.metric.toUk(weight)
  const [imperialLbs, setImperialLbs] = useState<string>(String(lb))
  const [imperialStone, setImperialStone] = useState(String(st))
  const prevWeightStone = useRef<string | number>(String(st))

  const prevWeight = usePrevious<number | undefined>(weight)

  useEffect(() => {
    if (prevWeight && weight == null) {
      setImperialLbs('')
      setImperialStone('')
    }
  }, [weight, prevWeight])

  const handleStoneChange = ({ target: { value } }: InputEvent) => {
    setImperialStone(value)
  }

  const handleStoneDecimal = (value: string) => {
    const imperialWeight = WeightConversion.uk.normalizeFromStone(
      parseFloat(value)
    )
    const stString = String(imperialWeight.st || '')
    setImperialStone(stString)
    setImperialLbs(String(imperialWeight.lb || ''))
    setFieldValue('weight', WeightConversion.uk.toMetric(imperialWeight))
    prevWeightStone.current = stString
  }

  const handleStoneBlur = ({ target: { value } }: InputEvent) => {
    if (value === '' && imperialLbs === '') {
      setFieldValue('weight', null)
      return
    }

    if (parseFloat(value) % 1) {
      handleStoneDecimal(value)
      return
    }

    setFieldValue(
      'weight',
      WeightConversion.uk.toMetric({
        st: parseInt(value),
        lb: parseFloat(imperialLbs),
      })
    )

    if (value !== prevWeightStone.current) {
      setImperialLbs('')
    }
    prevWeightStone.current = value
    setFieldTouched('weight', true, false)
  }

  const handleLbsChange = ({ target: { value } }: InputEvent) => {
    setImperialLbs(value)
  }

  const handleLbsBlur = ({ target: { value } }: InputEvent) => {
    if (value === '' && imperialStone === '') {
      setFieldValue('weight', null)
      return
    }
    const { st, lb } = WeightConversion.uk.normalizeFromLbs(parseFloat(value))
    const newSt = (parseFloat(imperialStone) || 0) + (st || 0)
    setFieldValue('weight', WeightConversion.uk.toMetric({ st: newSt, lb }))
    setImperialStone(String(newSt.toFixed(1) || ''))
    setImperialLbs(String(lb?.toFixed(1) || ''))
    setFieldTouched('weight', true, false)

    prevWeightStone.current = String(newSt || '')
  }

  const displayImperialWeight = displayWeight
    ? WeightConversion.metric.toUk(displayWeight)
    : null

  const displayImperialWeightLabel =
    displayImperialWeight &&
    `${displayImperialWeight.st} ${fm(
      messages.weight_label_stone_abbreviation
    )}    ${displayImperialWeight.lb} ${fm(
      messages.weight_label_lbs_abbreviation
    )} `

  return (
    <>
      <BmiSectionHeader
        label={fm(messages.weight_section_label)}
        htmlFor="weight"
      >
        {displayImperialWeightLabel}
        <Timestamp
          performer={performerWeight}
          timestamp={timestamp}
          className={styles.timestamp}
        />
      </BmiSectionHeader>
      <Grid columns={5}>
        <Field name="weight" validate={validateWeight(fm)}>
          {() => (
            <>
              <NumberInput
                label={fm(messages.weight_label_stone)}
                id="imperial_weight_stone"
                value={imperialStone}
                onChange={handleStoneChange}
                onBlur={handleStoneBlur}
                placeholder={fm(messages.weight_placeholder_stone)}
                autoComplete="off"
                errorMessage={errors.weight}
                displayError={!!errors.weight}
              />
              <NumberInput
                label={fm(messages.weight_label_lbs)}
                id="imperial_weight_lbs"
                value={imperialLbs}
                data-testid="imperial-lbs-input"
                onChange={handleLbsChange}
                onBlur={handleLbsBlur}
                placeholder={fm(messages.weight_placeholder_lbs)}
                autoComplete="off"
                displayError={!!errors.weight}
              />
            </>
          )}
        </Field>
      </Grid>
    </>
  )
}

export default ImperialWeight
