import { defineMessages } from 'react-intl'

const baseId = 'chatscript_messages_modal.chat_transcript'

export default defineMessages({
  could_not_get_conversation_message_v2: {
    id: `${baseId}.could_not_get_conversation_message_v2`,
    defaultMessage: 'Could not get the transcript',
  },
})
