import { defineMessages } from 'react-intl'

const baseId = 'address_input'

export default defineMessages({
  address_line_1_placeholder: {
    id: `${baseId}.address_line_1_placeholder`,
    defaultMessage: 'Address line 1',
  },
  address_line_2_placeholder: {
    id: `${baseId}.address_line_2_placeholder`,
    defaultMessage: 'Address line 2',
  },
  address_line_3_placeholder: {
    id: `${baseId}.address_line_3_placeholder`,
    defaultMessage: 'Address line 3',
  },
  address_city_placeholder: {
    id: `${baseId}.address_city_placeholder`,
    defaultMessage: 'City',
  },
  address_state_code_placeholder: {
    id: `${baseId}.address_state_code_placeholder_v2`,
    defaultMessage: 'State code',
  },
  postcode_label: {
    id: `${baseId}.postcode_label`,
    defaultMessage: 'Post code',
  },
})
