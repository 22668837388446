import { defineMessages } from 'react-intl'

const baseId = 'routes.gp_details_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'GP details',
  },
  gp_name_label: {
    id: `${baseId}.gp_name_label_v2`,
    defaultMessage: 'GP name',
  },
  gp_name_placeholder: {
    id: `${baseId}.gp_name_placeholder_v2`,
    defaultMessage: 'GP name',
  },

  surgery_name_label: {
    id: `${baseId}.surgery_name_label_v2`,
    defaultMessage: 'Surgery name',
  },
  surgery_name_placeholder: {
    id: `${baseId}.surgery_name_placeholder_v2`,
    defaultMessage: 'Surgery name',
  },

  surgery_phone_number_label: {
    id: `${baseId}.surgery_phone_number_label_v2`,
    defaultMessage: 'Surgery phone number',
  },
  surgery_phone_number_placeholder: {
    id: `${baseId}.surgery_phone_number_placeholder_v2`,
    defaultMessage: 'Phone number',
  },

  address_label: {
    id: `${baseId}.address_label`,
    defaultMessage: 'Address',
  },
  address_first_line_placeholder: {
    id: `${baseId}.address_first_line_placeholder`,
    defaultMessage: 'Address line 1',
  },
  address_second_line_placeholder: {
    id: `${baseId}.address_second_line_placeholder`,
    defaultMessage: 'Address line 2',
  },
  address_third_line_placeholder: {
    id: `${baseId}.address_third_line_placeholder`,
    defaultMessage: 'Address line 3',
  },
  address_post_code_placeholder: {
    id: `${baseId}.address_post_code_placeholder`,
    defaultMessage: 'Postcode',
  },

  share_notes_label: {
    id: `${baseId}.share_notes_label`,
    defaultMessage: 'Share notes with GP',
  },

  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  save_button_label: {
    id: `${baseId}.save_button_label`,
    defaultMessage: 'Save',
  },
})
