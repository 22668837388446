import { isEqual } from 'lodash'
import { MessageDescriptor } from 'react-intl'
import { combineLatest, merge, Observable, Subscription } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  find,
  map,
  skip,
  take,
  takeWhile,
} from 'rxjs/operators'

import {
  CONSULTATION_CATEGORY,
  UPDATE_CONSULTATION_ACTION,
} from '~/constants/analytics'
import { isTruthy } from '~/core'
import analytics from '~/core/analytics'
import { CompleteConsultationModelInterface } from '~/core/config/modules/generated/types'
import {
  behaviorSubject,
  markConsultationAsCompleteMutation,
  observableState,
} from '~/core/reactive-helpers'
import { logException, logMessage } from '~/core/sentry'
import { history } from '~/features/shell/history'
import { ConsultationStatus } from '~/generated'

export enum ExtensionStatus {
  Initializing = 'Initializing',
  Initialized = 'Initialized',
  InitError = 'InitError',
  Submitting = 'Submitting',
  Submitted = 'Submitted',
  SubmitError = 'SubmitError',
}

export enum ParentStatus {
  ExtensionsAreInitializing = 'ExtensionsAreInitializing',
  AllExtensionsAreInitialized = 'AllExtensionsAreInitialized',
  SomeExtensionsFailedInitializing = 'SomeExtensionsFailedInitializing',
  ExtensionsAreSubmitting = 'ExtensionsAreSubmitting',
  AllExtensionsAreSubmitted = 'AllExtensionsAreSubmitted',
  SomeExtensionsFailedSubmitting = 'SomeExtensionsFailedSubmitting',
}

export type CompleteDialogModelStateType = {
  status: ParentStatus
  errors: MessageDescriptor[]
}

export type CompleteConsultationModelType = {
  // state
  isDialogVisible: Observable<{ isDialogVisible: boolean }>
  completeMutationState: Observable<{ error: boolean; loading: boolean }>
  state: Observable<CompleteDialogModelStateType>

  // api
  open: () => void
  complete: () => void
  cancel: () => void
}

export interface ExtensionStateType {
  status: ExtensionStatus
  errorMessage?: MessageDescriptor
}

const computeStatus = (results: ExtensionStateType[]): ParentStatus => {
  const some = (status: ExtensionStatus) =>
    results.some((result) => result.status === status)

  const every = (status: ExtensionStatus) =>
    results.every((result) => result.status === status)

  // the button is disabled
  if (some(ExtensionStatus.InitError)) {
    return ParentStatus.SomeExtensionsFailedInitializing
  }

  // button shows 'Try again'
  if (some(ExtensionStatus.SubmitError)) {
    return ParentStatus.SomeExtensionsFailedSubmitting
  }

  // the button shows a spinner
  if (some(ExtensionStatus.Initializing)) {
    return ParentStatus.ExtensionsAreInitializing
  }

  // the button shows a spinner
  if (some(ExtensionStatus.Submitting)) {
    return ParentStatus.ExtensionsAreSubmitting
  }

  // the button shows a spinner
  if (every(ExtensionStatus.Submitted)) {
    return ParentStatus.AllExtensionsAreSubmitted
  }

  // every extensions are initialized: button is enabled
  return ParentStatus.AllExtensionsAreInitialized
}

// TODO: find a (better) solution for interfaces in the IoC type-system
export const DoNotInstantiate = () => {
  throw new Error('This is an interface, do not instantiate!')
}

export interface CompleteConsultationExtensionType {
  state: Observable<ExtensionStateType>

  // api
  init: () => void
  submit: () => void
}

const defaultExtension = (): CompleteConsultationExtensionType => {
  const state = behaviorSubject<ExtensionStateType>({
    status: ExtensionStatus.Initializing,
  })

  return {
    state,
    init: () => state.next({ status: ExtensionStatus.Initialized }),
    submit: () => state.next({ status: ExtensionStatus.Submitted }),
  }
}

const trackUpdateConsultationEventEffect = analytics.trackEventFactory({
  category: CONSULTATION_CATEGORY,
  action: UPDATE_CONSULTATION_ACTION,
})

const redirectUserEffect = (redirectUrl: string, consultationId: string) => {
  const redirectLocation = redirectUrl.replace(
    ':consultationId',
    consultationId
  )
  history.push(redirectLocation, {
    consultationResult: 'complete',
  })
}

export const createCompleteConsultationModel = (
  markConsultationAsComplete: typeof markConsultationAsCompleteMutation,
  trackUpdateConsultationEvent: typeof trackUpdateConsultationEventEffect,
  redirectUser: typeof redirectUserEffect
): CompleteConsultationModelInterface => ({
  consultationContext,
  extensions,
  redirectUrl = '/',
}) => {
  // TODO: convert this to proper RxJS stream
  let currentConsultationId: string
  let runMutation: boolean

  const [
    completeMutationState,
    setCompleteMutationState,
    resetMutationState,
  ] = observableState({
    error: false,
    loading: false,
  })

  consultationContext.subscribe((consultation) => {
    resetMutationState()
    currentConsultationId = consultation.data.consultation?.id || ''
    runMutation =
      consultation.data.consultation?.status === ConsultationStatus.Paid
  })
  // END of TODO

  const extensionList = extensions.length ? extensions : [defaultExtension()]

  const pluginStates = extensionList.map((p) => p.state)

  const state = combineLatest(pluginStates).pipe(
    // merge states
    map((results) => ({
      status: computeStatus(results),
      errors: results.map((result) => result.errorMessage).filter(isTruthy),
    })),
    distinctUntilChanged(isEqual)
  )

  state.subscribe(({ status }) => {
    if (status !== ParentStatus.AllExtensionsAreSubmitted) {
      resetMutationState()
    }
  })

  const [isDialogVisible, setDialogVisible] = observableState({
    isDialogVisible: false,
  })

  const initializeAllExtensions = () => {
    extensionList.forEach((extension) => extension.init())
  }

  const submitAllExtensions = () => {
    extensionList.forEach((extension) => extension.submit())
  }

  const open = () => {
    resetMutationState()
    initializeAllExtensions()
    setDialogVisible({ isDialogVisible: true })
  }

  let subscription: Subscription | undefined

  const safeUnsubscribe = () => {
    try {
      const prevSubscription = subscription
      subscription = undefined
      prevSubscription?.unsubscribe()
    } catch (err) {
      logMessage(err)
    }
  }

  const complete = () => {
    safeUnsubscribe()

    const stream = merge(
      state.pipe(
        // we need to skip the current state to ignore previous errors
        skip(1),
        find(
          ({ status }) =>
            status === ParentStatus.AllExtensionsAreSubmitted ||
            status === ParentStatus.SomeExtensionsFailedSubmitting
        ),
        takeWhile(
          (state) => state?.status === ParentStatus.AllExtensionsAreSubmitted
        )
      ),
      completeMutationState.pipe(
        take(1),
        filter(({ error }) => error)
      )
    )

    if (runMutation) {
      subscription = stream
        .pipe(
          map(() => ({
            variables: {
              id: currentConsultationId,
            },
          })),
          markConsultationAsComplete()
        )
        .subscribe({
          next: ({ loading, error }) => {
            setCompleteMutationState({ error: Boolean(error), loading })

            if (loading || error) return

            trackUpdateConsultationEvent({
              label: currentConsultationId,
            })
            redirectUser(redirectUrl, currentConsultationId)
            setDialogVisible({ isDialogVisible: false })
          },
          error: (error) => {
            setCompleteMutationState({ error: Boolean(error), loading: false })
            logException(error)
          },
        })
    } else {
      subscription = stream.subscribe({
        next: () => {
          redirectUser('/', currentConsultationId)
          setDialogVisible({ isDialogVisible: false })
        },
        error: (error) => {
          logException(error)
        },
      })
    }

    submitAllExtensions()
  }

  const cancel = () => {
    resetMutationState()
    setDialogVisible({ isDialogVisible: false })
  }

  return {
    isDialogVisible,
    completeMutationState,
    state,

    open,
    complete,
    cancel,
  }
}

export const CompleteConsultationModel = createCompleteConsultationModel(
  markConsultationAsCompleteMutation,
  trackUpdateConsultationEventEffect,
  redirectUserEffect
)
