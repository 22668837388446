import { defineMessages } from 'react-intl'

const baseId = 'patient_details_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient details',
  },
  first_name_label: {
    id: `${baseId}.first_name_label`,
    defaultMessage: 'First name',
  },
  first_name_placeholder: {
    id: `${baseId}.first_name_placeholder`,
    defaultMessage: 'First name',
  },
  last_name_label: {
    id: `${baseId}.last_name_label`,
    defaultMessage: 'Last name',
  },
  last_name_placeholder: {
    id: `${baseId}.last_name_placeholder`,
    defaultMessage: 'Last name',
  },
  gender_label: {
    id: `${baseId}.gender_label`,
    defaultMessage: 'Gender',
  },
  gender_option_female_label: {
    id: `${baseId}.gender_option_female_label`,
    defaultMessage: 'Female',
  },
  gender_option_male_label: {
    id: `${baseId}.gender_option_male_label`,
    defaultMessage: 'Male',
  },
  email_label: {
    id: `${baseId}.email_label_v2`,
    defaultMessage: 'E-mail',
  },
  email_placeholder: {
    id: `${baseId}.email_placeholder_v2`,
    defaultMessage: 'E-mail',
  },
  date_of_birth_label: {
    id: `${baseId}.date_of_birth_label_v2`,
    defaultMessage: 'Date of birth',
  },
  phone_number_label: {
    id: `${baseId}.phone_number_label`,
    defaultMessage: 'Phone number',
  },
  phone_number_placeholder: {
    id: `${baseId}.phone_number_placeholder`,
    defaultMessage: 'Phone number',
  },
  country_code_label: {
    id: `${baseId}.country_code_label`,
    defaultMessage: 'Country code',
  },
  address_label: {
    id: `${baseId}.address_label`,
    defaultMessage: 'Address',
  },
  next_of_kin_label: {
    id: `${baseId}.next_of_kin_label_v2`,
    defaultMessage: 'Next of kin',
  },
  next_of_kin_first_name_label: {
    id: `${baseId}.next_of_kin_first_name_label_v2`,
    defaultMessage: 'Next of kin first name',
  },
  next_of_kin_first_name_placeholder: {
    id: `${baseId}.next_of_kin_first_name_placeholder_v2`,
    defaultMessage: 'First name',
  },
  next_of_kin_last_name_label: {
    id: `${baseId}.next_of_kin_last_name_label_v2`,
    defaultMessage: 'Next of kin last name',
  },
  next_of_kin_last_name_placeholder: {
    id: `${baseId}.next_of_kin_last_name_placeholder_v2`,
    defaultMessage: 'Last name',
  },
  next_of_kin_relation_label: {
    id: `${baseId}.next_of_kin_relation_label_v2`,
    defaultMessage: 'Next of kin relation',
  },
  next_of_kin_relation_placeholder: {
    id: `${baseId}.next_of_kin_relation_placeholder`,
    defaultMessage: 'Relation',
  },
  next_of_kin_phone_number_label: {
    id: `${baseId}.next_of_kin_phone_number_label_v2`,
    defaultMessage: 'Next of kin phone number',
  },
  next_of_kin_phone_number_placeholder: {
    id: `${baseId}.next_of_kin_phone_number_placeholder`,
    defaultMessage: 'Phone number',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  save_button_label: {
    id: `${baseId}.save_button_label`,
    defaultMessage: 'Save',
  },
  error_message: {
    id: `${baseId}.error_message_v2`,
    defaultMessage: 'Patient details modal error',
  },
  error_title: {
    id: `${baseId}.error_title`,
    defaultMessage: 'The patient details modal failed to load',
  },
  invalid_input: {
    id: `${baseId}.invalid_input`,
    defaultMessage: 'Invalid input, please update and try again',
  },
  fatal_error: {
    id: `${baseId}.fatal_error`,
    defaultMessage: 'Something went wrong, please try again',
  },
  identity_label: {
    id: `${baseId}.identity_label`,
    defaultMessage: 'Identity',
  },
  verified_label: {
    id: `${baseId}.verified_label`,
    defaultMessage: 'Verified',
  },
  override_popup_title: {
    id: `${baseId}.override_popup_title`,
    defaultMessage: 'ID Confirmation',
  },
  override_popup_content: {
    id: `${baseId}.override_popup_content`,
    defaultMessage:
      'Please verify you have the appropriate ID to verify this patient. This operation cannot be undone.',
  },
  locked: {
    id: `${baseId}.locked`,
    defaultMessage:
      'Edits are disabled. This member is managed by {lockType} organisation(s).',
  },
})
