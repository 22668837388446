import { ApolloCache } from '@apollo/client'
import React from 'react'

import { Radio, RadioGroup, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import { useUpdateAppointmentMutation } from '~/generated'
import { useNotify } from '~/ui/Notification'

import { GpConsentFragment } from './fragments'

import messages from './GpDetails.messages'
import styles from './styles.module.scss'

export interface ConsentQuickEditProps {
  id: string
  value: boolean | null
}

const getConsentStr = (value: boolean | null): string | undefined => {
  if (value === null || typeof value === 'undefined') return undefined

  return value ? 'yes' : 'no'
}

const ConsentQuickEdit = ({ id, value }: ConsentQuickEditProps) => {
  const f = useMessages(messages)
  const [
    updateAppointmentMutation,
    { loading },
  ] = useUpdateAppointmentMutation()
  const notify = useNotify()
  const consentStr = getConsentStr(value)

  // TODO - add analytics?
  const handleUpdate = async (newValue: string) => {
    try {
      await updateAppointmentMutation({
        variables: {
          id,
          gp_consent: newValue === 'yes',
        },
        update: (cache: ApolloCache<any>) => {
          const consultation = cache.identify({
            __typename: 'Consultation',
            id,
          })
          cache.writeFragment({
            id: consultation,
            fragment: GpConsentFragment,
            data: {
              gpConsent: newValue === 'yes',
            },
          })
        },
      })
    } catch (e) {
      logException(e)
      notify({ title: f('error_updating_gp_consent') })
    }
  }

  return (
    <div className={styles.consentQuickEdit}>
      <Text tag="span" size="medium" color="grey-type">
        {f('consent_to_share_with_gp_given')}
      </Text>

      <RadioGroup
        name="consent"
        value={consentStr}
        onChange={(newValue) => handleUpdate(newValue)}
      >
        <Radio value="yes" disabled={loading}>
          {f('yes')}
        </Radio>
        <Radio value="no" disabled={loading}>
          {f('no')}
        </Radio>
      </RadioGroup>
    </div>
  )
}

export default ConsentQuickEdit
