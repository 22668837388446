import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import messages from './PrescriptionHeader.messages'
import styles from './styles.module.scss'

const PrescriptionHeader = () => {
  const fm = useFormatMessage()

  return (
    <tr className={styles.suggestedPrescriptionHeader}>
      <th>{fm(messages.age_group_title)}</th>
      <th>{fm(messages.name_title)}</th>
      <th>{fm(messages.quantity_title)}</th>
      <th>{fm(messages.duration_title)}</th>
      <th>{fm(messages.guidance_title)}</th>
      <th />
    </tr>
  )
}

export default PrescriptionHeader
