import React, { FC } from 'react'

import { Cell, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './BookingConfirmationDialog.messages'
import nhsAppointmentMessages from './NhsAppointment.messages'

type Props = {
  type: string
  clinician: string
  duration: string
}

const BookingConfirmationDialog: FC<Props> = ({
  type,
  clinician,
  duration,
}) => {
  const fm = useFormatMessage()

  return (
    <div>
      <Grid columns={2}>
        <Cell width={2}>
          <Text tag="p">{fm(messages.message)}</Text>
        </Cell>
        <Text bold color="grey-type">
          {fm(messages.type)}
        </Text>
        <Text>{type}</Text>
        <Text bold color="grey-type">
          {fm(messages.clinician)}
        </Text>
        <Text>{clinician}</Text>
        <Text bold color="grey-type">
          {fm(messages.duration)}
        </Text>
        <Text>
          {fm(nhsAppointmentMessages.duration_minutes_label, {
            time: duration,
          })}
        </Text>
      </Grid>
    </div>
  )
}

export default BookingConfirmationDialog
