import { useMutation } from '@apollo/client'

import {
  ERROR_REMOVE_TEST_ACTION,
  REMOVE_TEST_ACTION,
  TEST_ACTIONS_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import {
  CpGetCachedTestsQuery,
  CpGetCachedTestsQueryVariables,
  CpRemoveTestMutation,
  CpRemoveTestMutationVariables,
} from '~/generated'

import { GET_CACHED_TESTS, REMOVE_TEST_MUTATION } from './operations'

const useRemoveTestMutation = () => {
  const [mutate, result] = useMutation<
    CpRemoveTestMutation,
    CpRemoveTestMutationVariables
  >(REMOVE_TEST_MUTATION, {
    onCompleted: () => {
      analytics.trackEvent({
        category: TEST_ACTIONS_CATEGORY,
        action: REMOVE_TEST_ACTION,
      })
    },

    onError: () => {
      analytics.trackEvent({
        category: TEST_ACTIONS_CATEGORY,
        action: ERROR_REMOVE_TEST_ACTION,
      })
    },
  })

  const performMutation = (variables: CpRemoveTestMutationVariables) => {
    return mutate({
      variables,

      update: (cache, response) => {
        const removeConsultationTest = response.data?.removeConsultationTest

        const queryResponse = cache.readQuery<
          CpGetCachedTestsQuery,
          CpGetCachedTestsQueryVariables
        >({
          query: GET_CACHED_TESTS,
          variables: {
            id: variables?.consultationId,
          },
        })

        const consultation = queryResponse?.consultation

        if (!consultation) {
          return
        }

        const name =
          variables.type === 'pathology' ? 'pathologyTests' : 'diagnosticTests'

        const tests = consultation[name].filter(
          (test) => test.id !== removeConsultationTest
        )

        const data = {
          consultation: {
            ...consultation,
            [name]: tests,
          },
        }

        cache.writeQuery({
          query: GET_CACHED_TESTS,
          variables: {
            id: variables?.consultationId,
          },
          data,
        })
      },
    })
  }

  return [performMutation, result] as const
}

export default useRemoveTestMutation
