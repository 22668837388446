import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import {
  CONSULTATION_CATEGORY,
  ERROR_MARK_AS_NO_SHOW_ACTION,
  MARK_AS_NO_SHOW_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { logException } from '~/core/sentry'
import {
  CpMarkConsultationAsNoShowMutation,
  CpMarkConsultationAsNoShowMutationVariables,
} from '~/generated'

import { MARK_AS_NO_SHOW } from './operations'

const useMarkAsNoShowMutation = () => {
  const [markAsNoShow, result] = useMutation<
    CpMarkConsultationAsNoShowMutation,
    CpMarkConsultationAsNoShowMutationVariables
  >(MARK_AS_NO_SHOW)

  const perform = useCallback(
    async (consultationId: string) => {
      try {
        await markAsNoShow({ variables: { id: consultationId } })
        analytics.trackEvent({
          category: CONSULTATION_CATEGORY,
          action: MARK_AS_NO_SHOW_ACTION,
          label: consultationId,
        })
      } catch (err) {
        logException(err)
        analytics.trackEvent({
          category: CONSULTATION_CATEGORY,
          action: ERROR_MARK_AS_NO_SHOW_ACTION,
          label: consultationId,
        })
        throw err
      }
    },
    [markAsNoShow]
  )

  return [perform, result] as const
}

export default useMarkAsNoShowMutation
