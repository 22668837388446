import { pickBy } from 'lodash'

import { TEALIUM_SCRIPT_URL } from '~/constants'

import {
  clearTealiumField,
  initialiseTealium,
  setTealiumField,
  trackTealiumEvent,
  trackTealiumModal,
  trackTealiumPage,
} from './tealiumAnalytics'
import {
  AnalyticsEventContextConfig,
  IAnalyticsParams,
  ITrackEvent,
} from './types'
import { logAnalyticsDev } from './utils'

export type ParamCallback<T> = () => T

const TEALIUM_CONFIGURATION_ERROR =
  'Tealium is not configured. Using console as output.'

export const TRACK_EVENT_ARGS_ERROR =
  'Event Tracking requires at least a category and action when tracking events'

if (!TEALIUM_SCRIPT_URL) {
  logAnalyticsDev(TEALIUM_CONFIGURATION_ERROR)
}

type TrackEventFactoryCallSignature = (initialParams: IAnalyticsParams) => void

const trackEvent = ({
  category,
  action,
  label,
  value,
  consultationId,
  consultantId,
}: ITrackEvent) => {
  if (!category || !action) {
    logAnalyticsDev(TRACK_EVENT_ARGS_ERROR)
    return
  }

  try {
    const payload = pickBy(
      {
        action,
        category,
        label,
        value,
        dimension1: consultationId,
        dimension2: consultantId,
      },
      Boolean
    )

    logAnalyticsDev('Analytics Event', payload)

    trackTealiumEvent({
      action,
      category,
      label,
      value,
    })
  } catch (error) {
    console.error(error)
  }
}

const trackEventFactory = (
  initialParams: IAnalyticsParams
): TrackEventFactoryCallSignature => (callParams: ITrackEvent) => {
  trackEvent({ ...initialParams, ...callParams })
}

const setPage = (path: string) => {
  setTealiumField({ page: path })
}

const setUser = (consultantId: string) => {
  setTealiumField({ userUuid: consultantId })
}

const setFields = (fields: Partial<AnalyticsEventContextConfig>) => {
  setTealiumField(fields)
}

const clearFields = (fields: string[] | string) => {
  clearTealiumField(fields)
}

const trackPage = (path: string) => {
  trackTealiumPage()
  logAnalyticsDev('PageView', path)
}

const trackModal = (name: string) => {
  trackTealiumModal(name)
  logAnalyticsDev('ModalView', name)
}

export const initialiseAnalytics = () => {
  initialiseTealium()
}

export default {
  trackEventFactory,
  setPage,
  setUser,
  setFields,
  clearFields,
  trackEvent,
  trackModal,
  trackPage,
}
