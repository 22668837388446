export const defaultState = {
  value: null,
  dosage: '',
  packSize: '',
  duration: '',
  quantity: '',
  indications: [],
  directions: '',
  notes: '',
  refills: '',
  focused: false,
  repeatable: false,
  validationPending: true,
  showValidation: false,
  renewalDate: null,
  numRepeats: '',
  checks: '',
}

export const labels = {
  value: 'Drug',
  dosage: 'Dosage',
  duration: 'Duration',
  directions: 'Directions',
  indications: 'Indications',
  quantity: 'Quantity',
  notes: 'Notes',
}

export const validation = {
  value: {
    type: Object,
    required: true,
    empty: false,
  },
  dosage: {
    type: String,
    required: true,
    empty: false,
  },
  duration: {
    type: String,
    required: true,
    empty: false,
  },
  indications: {
    type: Array,
    required: true,
    empty: false,
  },
}
