import React from 'react'

import { Checkbox, Grid, Radio, RadioGroup, Tag, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './MarkAsSensitivePlugin.messages'
import styles from './styles.module.scss'

type MarkAsSensitiveViewProps = {
  // state
  isMinor: boolean
  isVisible: boolean
  sensitive: boolean | null
  error?: boolean

  updateSensitive: (value: boolean) => void
}

export const MarkAsSensitiveView = ({
  isMinor,
  isVisible,
  sensitive,
  error,
  updateSensitive,
}: MarkAsSensitiveViewProps) => {
  const fm = useFormatMessage()

  const handleSensitiveCheckboxChange = () => {
    updateSensitive(!sensitive)
  }

  const handleSensitiveRadioButtonChange = (value: string) => {
    updateSensitive(value === 'sensitive')
  }

  if (!isVisible) {
    return null
  }

  return isMinor ? (
    <Grid gap={16}>
      <Text>
        <Tag color="clinical-orange" className={styles.minorTag}>
          {fm(messages.minor)}
        </Tag>
        {fm(messages.this_patient_is_a_minor)}
      </Text>
      {error && (
        <Text color="error">{fm(messages.error_choose_one_option)}</Text>
      )}
      <RadioGroup
        name="sensitive_summary"
        onChange={handleSensitiveRadioButtonChange}
      >
        <div className={styles.radioButtonContainer}>
          <Radio
            value="not_sensitive"
            checked={sensitive === false}
            intent={error ? 'error' : undefined}
          >
            <Text>{fm(messages.yes_send_summary_to_parents)}</Text>
          </Radio>
          <Radio
            value="sensitive"
            checked={sensitive === true}
            intent={error ? 'error' : undefined}
          >
            <Text>{fm(messages.no_dont_send_summary_to_parents)}</Text>
          </Radio>
        </div>
      </RadioGroup>
    </Grid>
  ) : (
    <Checkbox
      name="sensitive_summary"
      checked={sensitive === false}
      value="not_sensitive"
      onChange={handleSensitiveCheckboxChange}
    >
      <Text size="medium">{fm(messages.send_summary_notes)}</Text>
    </Checkbox>
  )
}
