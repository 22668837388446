import get from 'lodash/fp/get'
import React, { FC } from 'react'
import { Field, FieldProps } from 'formik'
import { format, parse } from 'date-fns'

import DatePicker, { DatePickerProps } from '../../foundation/DatePicker'
import FormField from '../FormField'

import './index.scss'

type SomeDatePickerProps = Omit<DatePickerProps, 'onChange'>

export interface FormikDatePickerProps extends SomeDatePickerProps {
  id: string
  name: string
  label?: string
  placeholder?: string
  validate?: (value: any) => undefined | string | Promise<any>
  stateFormat?: string
  icon?: React.ReactElement
}

const FormikDatePicker: FC<FormikDatePickerProps> = ({
  id,
  name,
  label,
  placeholder,
  validate,
  stateFormat,
  icon,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors, touched, setFieldValue, setFieldTouched },
      field: { value, name },
    }: FieldProps) => {
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)
      let selectedDate = value || null

      if (selectedDate && stateFormat) {
        selectedDate = parse(selectedDate, stateFormat, new Date())
      }

      const handleDateChange = (date: Date | null): void => {
        let formattedDate: Date | null | string = date

        if (formattedDate && stateFormat) {
          formattedDate = format(formattedDate, stateFormat)
        }

        setFieldValue(name, formattedDate, true)
      }

      const handleBlur = (): void => {
        setFieldTouched(name, true, true)
      }

      return (
        <FormField
          id={id}
          label={label}
          errorMessage={errorMessage}
          displayError={displayError}
        >
          <div className="core-ui-formik-date-picker">
            <DatePicker
              selected={selectedDate}
              placeholderText={placeholder}
              displayError={displayError}
              onChange={handleDateChange}
              onBlur={handleBlur}
              id={id}
              icon={icon}
              {...props}
            />
          </div>
        </FormField>
      )
    }}
  </Field>
)

export default FormikDatePicker
