const formatDirections = ({ directions = '' }) => {
  return directions.replace(/ +\${.*?}/g, '') || ''
}

export const formatRequestDrug = (value) => ({
  drugId: value.drugId,
  drugName: value.drugName,
  directions: value.directions.replace(/ +\${.*?}/g, ''),
  dosage: value.dosage,
  duration: value.duration,
  strength: value.strength,
  quantity: value.quantity,
  indications: [value.indication],
})

export const formatSuggestedDrugs = (
  { prescription = {}, ageGroup = '', penicillinAllergicOption },
  indication
) => {
  const formattedDrugs = []
  prescription.forEach((drug) => {
    if (drug.drugName) {
      const directions = formatDirections(drug)
      formattedDrugs.push({
        ageGroup,
        drugName: drug.drugName,
        duration: directions,
        quantity: drug.quantity,
        strength: drug.strength,
        guidance: drug.guidance ? drug.guidance : '',
        penAllergy: !penicillinAllergicOption,
        action: {
          ...drug,
          directions,
          indication,
        },
      })
    }
  })

  return formattedDrugs
}
