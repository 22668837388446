/*
 any env vars you want to load from the .env files or manifests need to be added here even if you dont intend on using them from here
 (i.e. by an external lib etc.), this is due to the below file looking in the code and only adding what is used to the injected env object. (
 there is a list generated and put in the build/envVars file which is used to decide what to load)
 see monoweb/lib/react-scripts/config/ExtractEnvVarsPlugin.js
 */

import { envUrl, envVar } from '@babylon/babylon-env'

import { toBoolean, toNumber } from './utils'

export const APP_NAME = 'clinical-portal'
export const APP_VERSION = process.env.REACT_APP_VERSION || 'unknown'

export const ENVIRONMENT = envVar('ENVIRONMENT') || 'Unknown'
export const COUNTRY_CODE = (ENVIRONMENT.split('-')[1] || 'uk').toLowerCase()
export const ENVIRONMENT_COUNTRY_ISO_CODE = envVar(
  'ENVIRONMENT_COUNTRY_ISO_CODE'
)

const CORE_URL = envUrl('CORE_URL')
export const SERVICE_URL = envUrl('SERVICE_URL') ?? ''
const SERVICE_URL_PREFIX = SERVICE_URL.slice(0, SERVICE_URL.length - 1)
const MIDDLEWARE_URL =
  envVar('MIDDLEWARE_URL') || `${SERVICE_URL_PREFIX}${envVar('GQL_PATH')}`
/** ============
 *     URLs
 * =============
 */
export const ADMIN_PORTAL_URL = `${CORE_URL}/admin`
export const CLINICAL_PORTAL_PARTNER_URL = envUrl('CLINICAL_PARTNER_PORTAL_URL')
export const DOWNLOAD_URL = `${MIDDLEWARE_URL}/download`
export const CONFIG_NAMESPACE = 'clinical_portal'
export const CONFIG_URL = `${MIDDLEWARE_URL}/config/specifier-config`
export const GRAPHQL_URL = envVar('ENABLE_GRAPHQL_PROXY', toBoolean)
  ? '/graphql'
  : `${MIDDLEWARE_URL}/graphql`
export const PLATFORM_GATEWAY_URL = envVar('PLATFORM_GATEWAY_URL')
export const MULTIMEDIA_METRICS_SERVICE_URL = envVar(
  'MULTIMEDIA_METRICS_SERVICE_URL'
)
export const NOTE_ASSISTANT_SERVICE_URL = envVar('NOTE_ASSISTANT_SERVICE_URL')
export const PRESCRIPTION_DOWNLOAD_URL = `${MIDDLEWARE_URL}/download/prescription/`
export const SENTRY_ENVIRONMENT = envVar('SENTRY_ENVIRONMENT')
export const SENTRY_URL = envVar('SENTRY_URL')
export const ZENDESK_URL = envVar('ZENDESK_URL')

export const LOGROCKET_APP_ID = envVar('LOGROCKET_APP_ID')
export const ENABLE_PARTIALLY_SANITIZED_LOGROCKET = envVar(
  'ENABLE_PARTIALLY_SANITIZED_LOGROCKET',
  toBoolean
)

export const ATHENA_CHECK_IN_URL = envVar('ATHENA_CHECK_IN_URL')
export const ATHENA_EXAM_URL = envVar('ATHENA_EXAM_URL')

export const INTERNAL_WEB_URL = envVar('INTERNAL_WEB_URL')

export const STATE_REGULATIONS_DOCUMENT_URL = envVar(
  'STATE_REGULATIONS_DOCUMENT_URL'
)

export const SERVICE_NOW_BASE_URL = envVar('SERVICE_NOW_BASE_URL')

export const SERVICE_NOW_SUPPORT_URL = envVar('SERVICE_NOW_SUPPORT_URL')

/** ============
 * Feature flags
 * =============
 */

/*
  we should remove the ENABLE_NEW_AUTH flag and old auth when migration is complete , see:
  babylonpartners.atlassian.net/wiki/spaces/CIT/pages/4241555535/Portals+Open+ID+switchover
*/
export const ENABLE_NEW_AUTH = envVar('ENABLE_NEW_AUTH', toBoolean)

/*
 these need to stay as they are being used by the new auth module but it doesn't look at it from here
 it will check on the window.envVars object and we cannot get them to appear in the object unless they are
 exported from here.

 see monoweb/lib/react-scripts/config/ExtractEnvVarsPlugin.js

 */
export const AUTH_CLIENT_ID = envVar('AUTH_CLIENT_ID')
export const AUTH_DOMAIN = envVar('AUTH_DOMAIN')
export const AUTH_REDIRECT_URI = envVar('AUTH_REDIRECT_URI')

export const AUTH_SESSION_REFRESH_INTERVAL = envVar(
  'AUTH_SESSION_REFRESH_INTERVAL'
)
export const AUTH_SESSION_LOGOUT_WHEN_INACTIVE = envVar(
  'AUTH_SESSION_LOGOUT_WHEN_INACTIVE'
)
export const AUTH_SESSION_TIMEOUT = envVar('AUTH_SESSION_TIMEOUT')

export const ENABLE_SIGNER = envVar('ENABLE_SIGNER', toBoolean)
export const ENABLE_ISSUED = envVar('ENABLE_ISSUED', toBoolean)
export const ENABLE_TESTS_AND_KITS_V2 = envVar(
  'ENABLE_TESTS_AND_KITS_V2',
  toBoolean
)
export const ENABLE_TRANSLATION_REQUESTED = envVar(
  'ENABLE_TRANSLATION_REQUESTED',
  toBoolean
)

export const ENABLE_GP_FINDER = envVar('GP_FINDER', toBoolean)
export const ENABLE_F2F_BOOKINGS = envVar('ENABLE_F2F_BOOKINGS', toBoolean)

export const ENABLE_CHATSCRIPT_FLOWS = envVar(
  'ENABLE_CHATSCRIPT_FLOWS',
  toBoolean
)

export const ENABLE_MEDICAL_HISTORY = envVar(
  'ENABLE_MEDICAL_HISTORY',
  toBoolean
)

export const ENABLE_PRECONSULTATION_SECTION = envVar(
  'ENABLE_PRECONSULTATION_SECTION',
  toBoolean
)

export const ENABLE_CHATSCRIPT_DISCLAIMER = envVar(
  'ENABLE_CHATSCRIPT_DISCLAIMER',
  toBoolean
)
export const DISABLE_PRE_CONS_COPY = envVar('DISABLE_PRE_CONS_COPY', toBoolean)

export const DISABLE_MEDICAL_HISTORY_FETCH_OPTIONS = envVar(
  'DISABLE_MEDICAL_HISTORY_FETCH_OPTIONS',
  toBoolean
)

export const ENABLE_BLOOD_PRESSURE_CHR = envVar(
  'ENABLE_BLOOD_PRESSURE_CHR',
  toBoolean
)

export const ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE = envVar(
  'ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE',
  toBoolean
)

export const ENABLE_SCHEDULE_METRICS = envVar(
  'ENABLE_SCHEDULE_METRICS',
  toBoolean
)

export const ENABLE_STATE_REGULATIONS_MESSAGE = envVar(
  'ENABLE_STATE_REGULATIONS_MESSAGE',
  toBoolean
)

export const ENABLE_TIMEZONE_ADJUSTED_CALENDAR_SCHEDULE = envVar(
  'ENABLE_TIMEZONE_ADJUSTED_CALENDAR_SCHEDULE',
  toBoolean
)

export const ENABLE_VIDEO_QUALITY_FEEDBACK = envVar(
  'ENABLE_VIDEO_QUALITY_FEEDBACK',
  toBoolean
)

/**
 * This flags enables "selection" adding of clinical codes in
 * "history" and "examination" fields
 */
export const ENABLE_EXAMINATION_CLINICAL_CODES = envVar(
  'ENABLE_EXAMINATION_CLINICAL_CODES',
  toBoolean
)

export const HIDE_PRE_CONS_ONBOARDING_AFTER = envVar(
  'HIDE_PRE_CONS_ONBOARDING_AFTER'
)

export const HIDE_DEEP_LINK_ONBOARDING_AFTER = envVar(
  'HIDE_DEEP_LINK_ONBOARDING_AFTER'
)

// TODO: Remove feature flag after transition to Follow Up Invites
export const HIDE_NHS_APPOINTMENTS = envVar('HIDE_NHS_APPOINTMENTS', toBoolean)

/**
 * This flag enables the local config editor for overwrites
 * DO NOT ENABLE ON PREPROD OR PROD, this is for QA testing only
 */
export const ENABLE_CONFIG_OVERWRITE_EDITOR = envVar(
  'ENABLE_CONFIG_OVERWRITE_EDITOR',
  toBoolean
)

export const ENABLE_VIDEO_ON_VOICE_CONSULTATIONS = envVar(
  'ENABLE_VIDEO_ON_VOICE_CONSULTATIONS',
  toBoolean
)

/** ============
 *     Other
 * =============
 */
export const CLINOPS_CHAT_DEPARTMENT_ID = envVar('CLINOPS_CHAT_DEPARTMENT_ID')

export const CLINOPS_CHAT_DEPARTMENT_NAME = envVar(
  'CLINOPS_CHAT_DEPARTMENT_NAME'
)

export const FALLBACK_LOCALE = envVar('FALLBACK_LOCALE') || 'en-GB'

export const TEALIUM_SCRIPT_URL = envVar('TEALIUM_SCRIPT_URL')

export const ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION = envVar(
  'ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION',
  toBoolean
)

export const NOTE_ASSISTANT_PROGRESS_INTERVAL =
  envVar('NOTE_ASSISTANT_PROGRESS_INTERVAL', toNumber) || 960

export const CLINICAL_PRESENCE_POLLING_INTERVAL =
  envVar('CLINICAL_PRESENCE_POLLING_INTERVAL', toNumber) || 10000
