import cn from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

interface CardProps {
  overflowHidden?: boolean
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

const Card = ({
  overflowHidden,
  children,
  className,
  style,
  ...props
}: CardProps) => (
  <div
    className={cn(styles.card, className, {
      [styles.overflowHidden]: overflowHidden,
    })}
    style={style}
    {...props}
  >
    {children}
  </div>
)

export default Card
