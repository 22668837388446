import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { Pagination } from '@babylon/core-ui'
import { withBabylonUser } from '@babylon/babylon-user'

import { mapPanel } from '../../utils/mapData'
import { eventLogger } from '../../utils'
import ReportTable from './ReportTable'
import { PanelsQuery } from '../../queries'

import styles from '../styles.module.scss'

const API_STATE_MAP = {
  pending: {
    state: 'NEW',
    sort: 'createdOn,asc',
  },
  reviewed: {
    state: 'SENT',
    sort: 'sentOn,desc',
  },
  errored: {
    state: 'ERROR',
    sort: 'reviewedOn,desc',
  },
}

const PAGE_SIZE = 10

const enhance = compose(
  withBabylonUser,
  graphql(PanelsQuery, {
    options: ({ page, activeTab }) => {
      const apiMap = API_STATE_MAP[activeTab]
      return {
        variables: {
          size: PAGE_SIZE, // pagination page size
          page, // pagination page number
          state: apiMap.state,
          sort: apiMap.sort,
        },
        onError: (error) => eventLogger.error('reports fetch failed', error),
        fetchPolicy: 'cache-and-network',
      }
    },
    props: ({ data }) => ({
      handlePaginationClick: (page) => {
        data.refetch({ page })
      },
      data,
    }),
  })
)

const ReportList = ({ data, activeTab, history, handlePaginationClick }) => {
  const response = data.getReports || data.getPanels
  const reports = response ? response.items : []
  const pagination = response
    ? {
        current: response.pageIndex,
        total: response.totalPages,
        pageSize: PAGE_SIZE,
      }
    : {
        total: 0,
      }

  return (
    <div className={styles['tests-and-kits']}>
      <ReportTable
        loading={data.loading}
        reports={reports.map((report) => mapPanel(report, activeTab))}
        onReviewReport={(report) => history.push(`/tests/${report.id}`)}
        error={data.error}
        activeTab={activeTab}
      />
      {pagination.total > 1 && (
        <Pagination
          currentPage={pagination.current + 1}
          totalPages={pagination.total}
          onPrevClick={() => handlePaginationClick(pagination.current - 1)}
          onNextClick={() => handlePaginationClick(pagination.current + 1)}
          onPageClick={(pageNumber) => handlePaginationClick(pageNumber - 1)}
        />
      )}
    </div>
  )
}

export default enhance(ReportList)
