import { defineMessages } from 'react-intl'

const baseId = 'patient_metrics_modal'

export default defineMessages({
  metrics_source_clinician: {
    id: `${baseId}.metrics_source_clinician`,
    defaultMessage: ', via clinician',
  },

  metrics_source_patient: {
    id: `${baseId}.metrics_source_patient`,
    defaultMessage: ', via patient',
  },
})
