import * as Twilio from '@twilio/voice-sdk'

export interface IVoiceCallService {
  setup(token: string): void
  start(
    appointmentId: string,
    consultationId: string,
    patientId: string,
    encryptedVoiceParams: string | null | undefined,
    isVoip: boolean
  ): void
  teardown(): void
}

export class VoiceCallService implements IVoiceCallService {
  device: Twilio.Device | null

  call: Twilio.Call | null

  calling: boolean

  constructor() {
    this.device = null
    this.call = null
    this.calling = false
  }

  setup(token: string) {
    this.teardown()
    this.device = new Twilio.Device(token)
  }

  async start(
    appointmentId: string,
    consultationId: string,
    patientId: string,
    encryptedVoiceParams: string | null | undefined,
    isVoip: boolean
  ) {
    if (!this.device) {
      throw new Error('Cannot start twilio call without device')
    }

    if (this.calling) {
      throw new Error('Twilio call already active')
    }

    const twilioParams: Record<string, string> = {
      AppointmentID: appointmentId,
      ConsultationID: consultationId,
      PatientID: patientId,
      VoIP: isVoip.toString(),
    }
    if (encryptedVoiceParams) {
      twilioParams.EncryptedVoiceParams = encryptedVoiceParams
    }

    this.call = await this.device?.connect({ params: twilioParams })
    this.calling = true
  }

  teardown() {
    this.calling = false
    if (this.device) {
      this.device.disconnectAll()
      this.device.removeAllListeners()
      this.device.destroy()
    }
  }
}
