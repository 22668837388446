import { defineMessages } from 'react-intl'

const baseId = 'patient_metrics_modal'

export default defineMessages({
  patient_metrics_title: {
    id: `${baseId}.patient_metrics_title_v2`,
    defaultMessage: 'Patient metrics',
  },
  smoking_status_label: {
    id: `${baseId}.smoking_status_label_v2`,
    defaultMessage: 'Smoking status',
  },
  smoking_status_updated_just_now: {
    id: `${baseId}.smoking_status_updated_just_now`,
    defaultMessage: 'Updated just now',
  },
  smoking_status_option_smoker_label: {
    id: `${baseId}.smoking_status_option_smoker_label`,
    defaultMessage: 'Smoker',
  },
  smoking_status_option_non_smoker_label: {
    id: `${baseId}.smoking_status_option_non_smoker_label_v2`,
    defaultMessage: 'Never smoked',
  },
  smoking_status_option_ex_smoker_label_v2: {
    id: `${baseId}.smoking_status_option_ex_smoker_label_v2`,
    defaultMessage: 'Ex-smoker',
  },
  smoking_status_option_na_smoker_label: {
    id: `${baseId}.smoking_status_option_na_smoker_label`,
    defaultMessage: 'N/A',
  },
  submit_button_update_label: {
    id: `${baseId}.submit_button_update_label`,
    defaultMessage: 'Update',
  },
  submit_button_save_label: {
    id: `${baseId}.submit_button_save_label`,
    defaultMessage: 'Save',
  },
  bmi_label: {
    id: `${baseId}.bmi_label`,
    defaultMessage: 'BMI',
  },
  bmi_out_of_range: {
    id: `${baseId}.bmi_bmi_out_of_range`,
    defaultMessage: '-out of range-',
  },
  bmi_na: {
    id: `${baseId}.bmi_na`,
    defaultMessage: 'N/A',
  },
  measurement_type_label_imperial: {
    id: `${baseId}.measurement_type_label_imperial`,
    defaultMessage: 'Imperial',
  },
  measurement_type_label_metric: {
    id: `${baseId}.measurement_type_label_metric`,
    defaultMessage: 'Metric',
  },
  measurement_type_label_us: {
    id: `${baseId}.measurement_type_label_us`,
    defaultMessage: 'US',
  },
  height_section_label: {
    id: `${baseId}.height_section_label`,
    defaultMessage: 'Height',
  },
  height_label_cm: {
    id: `${baseId}.height_label_cm`,
    defaultMessage: 'Centimetres',
  },
  height_label_cm_abbreviation: {
    id: `${baseId}.height_label_cm_abbreviation`,
    defaultMessage: 'cm',
  },
  height_placeholder_cm: {
    id: `${baseId}.height_placeholder_cm`,
    defaultMessage: 'e.g. 175',
  },
  height_label_feet: {
    id: `${baseId}.height_label_feet`,
    defaultMessage: 'Feet',
  },
  height_label_feet_abbreviation: {
    id: `${baseId}.height_label_feet_abbreviation`,
    defaultMessage: 'ft',
  },
  height_placeholder_feet: {
    id: `${baseId}.height_placeholder_feet`,
    defaultMessage: 'e.g. 5',
  },
  height_label_inches: {
    id: `${baseId}.height_label_inches`,
    defaultMessage: 'Inches',
  },
  height_label_inches_abbreviation: {
    id: `${baseId}.height_label_inches_abbreviation`,
    defaultMessage: 'in',
  },
  height_placeholder_inches: {
    id: `${baseId}.height_placeholder_inches`,
    defaultMessage: 'e.g. 8',
  },
  height_must_be_number_error_message: {
    id: `${baseId}.height_must_be_number_error_message`,
    defaultMessage: 'Height must be a number',
  },
  weight_section_label: {
    id: `${baseId}.weight_section_label`,
    defaultMessage: 'Weight',
  },
  weight_label_kg: {
    id: `${baseId}.weight_label_kg`,
    defaultMessage: 'Kilograms',
  },
  weight_label_kg_abbreviation: {
    id: `${baseId}.weight_label_kg_abbreviation`,
    defaultMessage: 'kg',
  },
  weight_placeholder_kg: {
    id: `${baseId}.weight_placeholder_kg`,
    defaultMessage: 'e.g. 70',
  },
  weight_label_stone: {
    id: `${baseId}.weight_label_stone`,
    defaultMessage: 'Stone',
  },
  weight_label_stone_abbreviation: {
    id: `${baseId}.weight_label_stone_abbreviation`,
    defaultMessage: 'st',
  },
  weight_placeholder_stone: {
    id: `${baseId}.weight_placeholder_stone`,
    defaultMessage: 'e.g. 6',
  },
  weight_label_lbs: {
    id: `${baseId}.weight_label_lbs`,
    defaultMessage: 'Lbs',
  },
  weight_label_lbs_abbreviation: {
    id: `${baseId}.weight_label_lbs_abbreviation`,
    defaultMessage: 'lbs',
  },
  weight_placeholder_lbs: {
    id: `${baseId}.weight_placeholder_lbs`,
    defaultMessage: 'e.g. 12',
  },
  weight_must_be_number_error_message: {
    id: `${baseId}.weight_must_be_number_error_message`,
    defaultMessage: 'Weight must be a number',
  },
  error_patient_metrics_update: {
    id: `${baseId}.error_patient_metrics_update`,
    defaultMessage:
      'Error updating patient metrics, please try again or reload the page.',
  },
  error_patient_metrics_mutation_failure: {
    id: `${baseId}.error_patient_metrics_mutation_failure`,
    defaultMessage: 'We failed to update this Patient Metric. Please retry.',
  },
  error_max: {
    id: `${baseId}.error_max`,
    defaultMessage: 'Max exceeded',
  },
  error_min: {
    id: `${baseId}.error_min`,
    defaultMessage: 'Min exceeded',
  },
  error_non_zeroes: {
    id: `${baseId}.error_non_zeroes`,
    defaultMessage: 'Positive values',
  },
  error_patient_metrics_load_error: {
    id: `${baseId}.patient_metrics_load_error`,
    defaultMessage:
      'Error loading patient metrics, please try reloading the page',
  },
  patient_metrics_reload_button: {
    id: `${baseId}.patient_metrics_reload_button`,
    defaultMessage: 'Reload page',
  },
  confirmation_dialog_title: {
    id: `${baseId}.confirmation_dialog_title_v2`,
    defaultMessage: 'Close without saving?',
  },
  confirmation_dialog_okLabel: {
    id: `${baseId}.confirmation_dialog_okLabel_v2`,
    defaultMessage: 'Close without saving',
  },
  confirmation_dialog_cancelLabel: {
    id: `${baseId}.confirmation_dialog_cancelLabel_v2`,
    defaultMessage: 'Cancel',
  },
  metrics_source_clinician: {
    id: `${baseId}.metrics_source_clinician`,
    defaultMessage: ', via clinician',
  },
  error_patient_metrics_modal_load_error: {
    id: `${baseId}.patient_metrics_modal_load_error`,
    defaultMessage: 'We can’t load patient metrics right now',
  },
  minor_warning_note: {
    id: `${baseId}.minor_warning_note`,
    defaultMessage: "We don't calculate the BMI of minors.",
  },
  minor_warning_note_v2: {
    id: `${baseId}.minor_warning_note_v2`,
    defaultMessage:
      "We don't calculate the BMI of patients less than 18 years old.",
  },
  blood_pressure_label: {
    id: `${baseId}.blood_pressure_label_v2`,
    defaultMessage: 'Blood pressure',
  },
  bp_not_editable_message: {
    id: `${baseId}.bp_not_editable_message`,
    defaultMessage:
      'The blood pressure field cannot be modified at the moment. This functionality will be added shortly.',
  },
  systolic_label: {
    id: `${baseId}.systolic_label`,
    defaultMessage: 'Systolic (mmHg)',
  },
  diastolic_label: {
    id: `${baseId}.diastolic_label`,
    defaultMessage: 'Diastolic (mmHg)',
  },
  systolic_placeholder: {
    id: `${baseId}.systolic_placeholder`,
    defaultMessage: 'e.g. 138',
  },
  diastolic_placeholder: {
    id: `${baseId}.diastolic_placeholder`,
    defaultMessage: 'e.g. 88',
  },
  info_message_blood_pressure: {
    id: `${baseId}.info_message_blood_pressure`,
    defaultMessage:
      'Please only use these fields to capture resting blood pressure.',
  },
  blood_pressure_updated_just_now: {
    id: `${baseId}.blood_pressure_updated_just_now`,
    defaultMessage: 'Updated just now',
  },
})
