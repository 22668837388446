import { Dispatch } from 'react'

import { ModelProvider } from '~/core/container/provider/useModelProvider'

export enum CallMediumEntitlement {
  Video = 'video',
  Voice = 'voice',
  VoiceAndVideo = 'voice_and_video',
}

export enum CallStatus {
  Disabled = 0,
  CriticallyFailed = 1,
  Loading = 2,
  Ready = 3,
  Connecting = 4,
  Streaming = 5,
  Rejected = 6,
}

export enum ActionType {
  RESET_TO_INITIAL = 'RESET_TO_INITIAL',
  VIDEO_LOADING = 'VIDEO_LOADING',
  VOICE_LOADING = 'VOICE_LOADING',
  VIDEO_READY = 'VIDEO_READY',
  VOICE_READY = 'VOICE_READY',
  VIDEO_CONNECTING = 'VIDEO_CONNECTING',
  VOICE_PSTN_CONNECTING = 'VOICE_PSTN_CONNECTING',
  VOICE_VOIP_CONNECTING = 'VOICE_VOIP_CONNECTING',
  VIDEO_STREAMING = 'VIDEO_STREAMING',
  VOICE_STREAMING = 'VOICE_STREAMING',
  VIDEO_STOP = 'VIDEO_STOP',
  VOICE_STOP = 'VOICE_STOP',
  VIDEO_REJECTED = 'VIDEO_REJECTED',
  CLEAR_CALL_END_REASON = 'CLEAR_CALL_END_REASON',
  COMPLETE_SURVEY = 'COMPLETE_SURVEY',
  MEDIA_ACCESS_DIALOG_OPENED = 'MEDIA_ACCESS_DIALOG_OPENED',
  MEDIA_ACCESS_DIALOG_CLOSED = 'MEDIA_ACCESS_DIALOG_CLOSED',
}

export type CallEndReason =
  | 'patientClientDisconnected'
  | 'patientNetworkDisconnected'
  | 'clinicianNetworkDisconnected'

export enum ErrorActionType {
  CALL_SERVICE_ERROR = 'CALL_SERVICE_ERROR',
  VOICE_LOADING_ERROR = 'VOICE_LOADING_ERROR',
  VIDEO_LOADING_ERROR = 'VIDEO_LOADING_ERROR',
  VOICE_START_ERROR = 'VOICE_START_ERROR',
  VIDEO_START_ERROR = 'VIDEO_START_ERROR',
}

export type CallStatusReducerState = {
  videoStatus: CallStatus
  voiceStatus: CallStatus
  videoError: Error | null
  voiceError: Error | null
  callServiceError: string | null
  isSurveyVisible: boolean
  isAccessDialogOpen: boolean
  isCallActive: boolean
  callEndReason: CallEndReason | null
  isVoipCall: boolean
  isVoiceFallbackVisible: boolean
}

interface CallStatusAction {
  type: ActionType
  payload?: {
    callEndReason?: CallEndReason
  }
}

interface CallErrorAction {
  type: ErrorActionType
  error: Error
}

export type Action = CallStatusAction | CallErrorAction

export interface CallStatusReducerModel {
  state: CallStatusReducerState
  dispatch: Dispatch<Action>
}

export type CallStatusReducerModelProviderType = ModelProvider<CallStatusReducerModel>
