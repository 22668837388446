import { MessageDescriptor } from 'react-intl'

import messages from './bmiUtils.messages'

enum Intervals {
  min = 18.5,
  mid = 25,
  max = 30,
}

export enum MetricRating {
  danger = 'danger',
  normal = 'normal',
  warning = 'warning',
  generic = 'generic',
  na = 'na',
}

export enum NonNumberBmiValues {
  bmi_out_of_range = 'bmi_out_of_range',
  bmi_na = 'bmi_na',
}

type BmiDetails = {
  value: any
  interpretation?: MessageDescriptor
  rating: MetricRating
}

export const MIN_AGE_FOR_BMI = 18

const MAX_BMI = 275

export const getBmiInterpretation = (value: number) => {
  if (value < Intervals.min) {
    return messages.underweight
  }
  if (value >= Intervals.min && value < Intervals.mid) {
    return messages.healthy
  }
  if (value >= Intervals.mid && value < Intervals.max) {
    return messages.overweight
  }
  return messages.obese
}

export const getBmiRating = (value: number): MetricRating => {
  if (value < Intervals.min) {
    return MetricRating.danger
  }
  if (value >= Intervals.min && value < Intervals.mid) {
    return MetricRating.normal
  }
  if (value >= Intervals.mid && value < Intervals.max) {
    return MetricRating.warning
  }
  if (value >= Intervals.max && value < MAX_BMI) {
    return MetricRating.danger
  }
  return MetricRating.generic
}

export const calculateBmi = (height: number, weight: number): number =>
  Math.round((weight / Math.pow(height / 100, 2)) * 10) / 10

export const getBmiDetails = (
  height: number | undefined | null,
  weight: number | undefined | null,
  age: number | string | undefined | null
): BmiDetails => {
  if (age == null) age = 16
  if (age < MIN_AGE_FOR_BMI || height == undefined || weight == undefined) {
    return {
      value: NonNumberBmiValues.bmi_na,
      rating: MetricRating.generic,
    }
  }

  const bmi = calculateBmi(height, weight)

  return bmi < MAX_BMI
    ? {
        value: bmi,
        interpretation: getBmiInterpretation(bmi),
        rating: getBmiRating(bmi),
      }
    : {
        value: NonNumberBmiValues.bmi_out_of_range,
        rating: getBmiRating(bmi),
      }
}
