import { FormikHelpers } from 'formik'
import React, { useState } from 'react'

import { useCreatePatientAlertMutation } from '~/generated'

import { createPatientAlertCache } from '../cache'
import PatientAlertForm from '../PatientAlertForm'
import { PatientAlertFormValues } from '../PatientAlertForm/PatientAlertForm'

interface CreatePatientAlertProps {
  consultationId: string
  patientId: string
  closeModal: () => void
}

const CreatePatientAlert = ({
  consultationId,
  patientId,
  closeModal,
}: CreatePatientAlertProps) => {
  const [submitError, setSubmitError] = useState('')
  const [createPatientAlertMutation] = useCreatePatientAlertMutation()

  const initialValues: PatientAlertFormValues = { message: '' }

  const createPatientAlert = async (
    values: PatientAlertFormValues,
    form: FormikHelpers<PatientAlertFormValues>
  ) => {
    try {
      await createPatientAlertMutation({
        variables: {
          patientId,
          ...values,
        },
        update: createPatientAlertCache(consultationId),
      })

      closeModal()
    } catch (exception) {
      form.setSubmitting(false)
      setSubmitError(exception.toString())
    }
  }

  return (
    <PatientAlertForm
      mode="create"
      initialValues={initialValues}
      onSubmit={createPatientAlert}
      onCancel={closeModal}
      error={submitError}
    />
  )
}

export default CreatePatientAlert
