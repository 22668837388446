import { defineMessages } from 'react-intl'

export default defineMessages({
  show_more_text: {
    id: 'components.section.show_more_text',
    defaultMessage: 'Show more',
  },
  show_less_text: {
    id: 'components.section.show_less_text',
    defaultMessage: 'Show less',
  },
  error_title_default: {
    id: 'components.section.error_title_default',
    defaultMessage: "We can't load this section right now",
  },
  error_title: {
    id: 'components.section.error_title',
    defaultMessage: "We can't load {sectionTitle} right now",
  },
})
