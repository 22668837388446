import { gql } from '@apollo/client'

export const SpecialismCategoriesQuery = gql`
  query Specialisms($consultationId: ID!) {
    specialismCategories(id: $consultationId) {
      name
      id
      specialisms {
        name
        id
      }
    }
  }
`
export const ReferralsQuery = gql`
  query Referrals($consultationId: ID!) {
    consultation(id: $consultationId) {
      id
      patient {
        id
        address_post_code
      }
      region {
        id
        iso_code
      }
      referrals {
        id
        specialismCategoryId
        specialismId
        specialismName
        urgent
        comments {
          id
          note
        }
      }
    }
  }
`
export const ReferralsSpecialistsQuery = gql`
  query ReferralsSpecialistsQuery($input: ReferralResourceInput) {
    referralSearch(input: $input) {
      location {
        name
        address {
          line
          city
          state
          postalCode
          country
        }
      }
      specialty {
        coding {
          system
          display
        }
      }
      telecom {
        system
        use
        value
        rank
      }
      practitioner {
        id
        name
        gender
        language
      }
    }
  }
`
