import React, { useContext, useMemo } from 'react'

import context, { PermissionCallback, Permissions } from './Context'
import { getPermissionTraceContext } from './permissionTrace'

export interface PermissionProviderProps {
  permissions: Permissions | PermissionCallback
  children?: React.ReactNode
}

const PermissionProvider = ({
  permissions,
  children,
}: PermissionProviderProps) => {
  const getDefaultPermission = (key: string) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Unknown permission key: '${key}'`)
    }
    return false
  }

  const getParentPermission = useContext(context) || getDefaultPermission
  const logPermission =
    process.env.NODE_ENV !== 'production' && getPermissionTraceContext()

  const getPermission = useMemo(() => {
    return typeof permissions === 'function'
      ? (key: string) => {
          const value = permissions(key)
          if (value != null) {
            logPermission && logPermission(key)
          }
          return value ?? getParentPermission(key)
        }
      : (key: string) => {
          if (permissions.hasOwnProperty(key)) {
            logPermission && logPermission(key)
            return permissions[key]
          }
          return getParentPermission(key)
        }
  }, [permissions, getParentPermission, logPermission])
  return <context.Provider value={getPermission}>{children}</context.Provider>
}

export default PermissionProvider
