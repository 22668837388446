/* eslint jsx-a11y/click-events-have-key-events: 0 */
import React from 'react'

import { useConsultantUser } from '@babylon/babylon-user'
import { Spinner } from '@babylon/core-ui'

import { CLINICIAN_ACTION_CATEGORY } from '~/constants/analytics'
import analytics from '~/core/analytics/analytics'
import { logout } from '~/core/auth'
import { useMessages, useToggle } from '~/core/hooks'
import { Dropdown, DropdownLink } from '~/ui/Dropdown'
import Toggle from '~/ui/Toggle'

import DropdownLinkLanguage from './DropdownLinkLanguage'
import DropdownUserInfo from './DropdownUserInfo'
import { faSignOutAlt } from './icons'
import ProfileImage from './ProfileImage'
import { useAvailableForNewTherapy } from './useAvailableForNewTherapy'

import messages from './Profile.messages'
import styles from './styles.module.scss'

const trackEvent = analytics.trackEventFactory({
  category: CLINICIAN_ACTION_CATEGORY,
})

const trackToggleChange = (newValue: boolean) =>
  trackEvent({
    action: 'Toggles Accepts New Patient switch',
    label: `toggles from ${newValue ? 'off' : 'on'} to ${
      newValue ? 'on' : 'off'
    }`,
  })

export interface ProfileProps {
  clinicianRatingEnabled: boolean
  languageSelectorEnabled: boolean
  languagesAvailable: string | undefined
}

const Profile = ({
  clinicianRatingEnabled,
  languageSelectorEnabled,
  languagesAvailable,
}: ProfileProps) => {
  const user = useConsultantUser()
  const f = useMessages(messages)
  const [visible, toggleDropdown] = useToggle(false)
  const {
    availableForNewTherapy,
    setAvailableForNewTherapy,
    loading: loadingIsAvailableForNewTherapy,
    featureEnabled: availableForNewTherapyFeatureEnabled,
  } = useAvailableForNewTherapy(user)

  return (
    <div
      className={styles.container}
      data-testid="consultant-profile-container"
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.profile}
        onClick={() => toggleDropdown(true)}
        title={f('profile_title')}
      >
        <ProfileImage
          url={user.consultant.avatar_expiring_url}
          alt={f('profile_image')}
          size={26}
        />
      </div>
      {visible && (
        <Dropdown
          style={{ right: 5 }}
          onClickOutside={() => toggleDropdown(false)}
        >
          <DropdownUserInfo
            avatarUrl={user.consultant.avatar_expiring_url}
            name={user.consultant.name}
            clinicianRatingEnabled={clinicianRatingEnabled}
            consultantId={user.consultant.id}
          />
          {languageSelectorEnabled && (
            <DropdownLinkLanguage languagesAvailable={languagesAvailable} />
          )}
          {availableForNewTherapyFeatureEnabled ? (
            <div className={styles.newTherapyToggleRow}>
              <p>{f('therapy_toggle_title')}</p>
              {loadingIsAvailableForNewTherapy ? (
                <Spinner />
              ) : (
                <Toggle
                  selected={availableForNewTherapy}
                  onChange={(toggled) => {
                    trackToggleChange(toggled)
                    setAvailableForNewTherapy(toggled)
                  }}
                />
              )}
            </div>
          ) : null}
          <DropdownLink
            title={f('logout_text')}
            icon={faSignOutAlt}
            onClick={logout}
          />
        </Dropdown>
      )}
    </div>
  )
}

export default Profile
