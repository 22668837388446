import React from 'react'
import ReactSelect, {
  Creatable as ReactSelectCreatable,
  Value,
} from 'react-select'

import { Tag } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './Select.messages'
import styles from './styles.module.scss'

const MultiSelectValue = ({ children, onRemove, value }) => (
  <Tag
    color={value.tagColor || 'clinical-blue'}
    onClose={() => onRemove(value)}
    className={styles.eventTag}
    closable
    margin
  >
    {children}
  </Tag>
)

const Select = ({
  component: Component = ReactSelect,
  multi = false,
  valueComponent = undefined,
  ...props
}) => {
  const fm = useFormatMessage()
  const defaultValueComponent = multi ? MultiSelectValue : Value

  return (
    <Component
      noResultsText={fm(messages.no_results_text)}
      clearAllText={fm(messages.clear_all_text)}
      clearValueText={fm(messages.clear_value_title_text)}
      searchPromptText={fm(messages.search_prompt_text)}
      multi={multi}
      valueComponent={valueComponent || defaultValueComponent}
      {...props}
    />
  )
}

export default Select

export const Creatable = (props) => (
  <Select component={ReactSelectCreatable} {...props} />
)
