import { gql } from '@apollo/client'

export const PATIENT_DETAILS_FRAGMENT = gql`
  fragment CpPatientDetails on Consultation {
    id
    patient {
      id
      minor
      account_owner_name
      account_owner_contact_details {
        address_first_line
        address_second_line
        address_third_line
        address_post_code
        phone_country_code
        phone_number
      }
      account_owner_dob
      first_name
      last_name
      full_name
      date_of_birth
      email
      gender
      id_verification
      identity_check {
        id
        status
        check_id
        verification_overwritten_at
        verification_overwritten_by {
          id
          fullName
        }
      }
      contact_details {
        address_first_line
        address_second_line
        address_third_line
        address_post_code
        address_state_code
        phone_country_code
        phone_number
      }
      region {
        id
        name
        iso_code
      }
      phone_number
      next_of_kin {
        id
        first_name
        last_name
        phone_number
        relation
      }
      public_healthcare_identifier
      profile_locked
      profile_locks {
        name
        locked_at
      }
    }
  }
`
