import { UTAG } from '@babylon/tracking/tealium'

import { ENVIRONMENT, TEALIUM_SCRIPT_URL } from '~/constants'

// In order to enable console log messages on local environment
// uncomment the TEALIUM_SCRIPT_URL in the local env file

export const logAnalyticsDev =
  !!TEALIUM_SCRIPT_URL && ENVIRONMENT === 'localhost'
    ? // eslint-disable-next-line no-console
      (...args: any) => console.log('Analytics Event:', ...args)
    : () => null

export const injectTealiumScript = (src: string) =>
  new Promise<UTAG>((resolve, reject) => {
    window.utag_cfg_ovrd.noview = true
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.onload = () => {
      resolve(window.utag)
    }
    script.onerror = () => {
      reject()
    }
    script.src = src

    const head = document.getElementsByTagName('head')[0]
    head.appendChild(script)
  })
