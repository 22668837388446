import { isSameDay } from 'date-fns'
import { useEffect } from 'react'

import { useIntl } from '@babylon/intl'

import messages from '../Consultation.messages'

const useConsultationTitle = (dateString: string | undefined) => {
  const intl = useIntl()

  useEffect(() => {
    const now = new Date()

    if (dateString) {
      const date = new Date(dateString)
      const isToday = isSameDay(date, now)
      const formattedTime = intl.formatTime(dateString)

      if (isToday) {
        document.title = intl.formatMessage(messages.today_title, {
          formattedTime,
        })
      } else {
        const formattedDate = intl.formatDate(dateString, { format: 'short' })

        document.title = `${formattedDate} ${formattedTime}`
      }
    }

    return () => {
      document.title = intl.formatMessage(messages.title)
    }
  }, [dateString, intl])
}

export default useConsultationTitle
