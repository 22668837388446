import React from 'react'

import { Button, Grid, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import { Action, ActionType } from '../CallStatusReducerModelProvider'

import styles from './styles.module.scss'
import messages from './VoicePstnFallback.messages'

interface VoicePstnFallbackProps {
  dispatch: React.Dispatch<Action>
}

const VoicePstnFallback = ({ dispatch }: VoicePstnFallbackProps) => {
  const f = useMessages(messages)

  return (
    <div className={styles.wrapper}>
      <Grid columns={1} rowGap={8}>
        <Text align="center" bold tag="div" color="white">
          {f('info')}
        </Text>
        <Text align="center" tag="div" color="white">
          {f('action')}
        </Text>
        <Button
          onClick={() => dispatch({ type: ActionType.VOICE_PSTN_CONNECTING })}
          className={styles.button}
          dataTestId="fallback_call"
        >
          {f('call')}
        </Button>
      </Grid>
    </div>
  )
}

export default VoicePstnFallback
