import { HttpLink } from '@apollo/client'

import { GRAPHQL_URL } from '~/constants'

const customFetch = (uri: string, options?: RequestInit) => {
  const body = options?.body as string
  const { operationName } = JSON.parse(body)

  return fetch(`${uri}?opName=${operationName}`, options)
}

const graphqlMiddlewareHttpLink = new HttpLink({
  uri: `${GRAPHQL_URL}/api`,
  credentials: 'include',
  fetch: customFetch,
})

export default graphqlMiddlewareHttpLink
