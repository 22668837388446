import { defineMessages } from 'react-intl'

const baseId = 'consultation.modal.sick_note'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private sick note',
  },
  reason_field_label: {
    id: `${baseId}.reason_field_label`,
    defaultMessage: 'Reason',
  },
  reason_tooltip_message_html: {
    id: `${baseId}.reason_tooltip_message_html`,
    defaultMessage:
      'Private Sick Notes are not valid for Social Security Purposes. The Sick Note will be available for the patient to download from their app in the Appointments section once you have Completed the consultation.',
  },
  reason_for_sick_note_placeholder: {
    id: `${baseId}.reason_for_sick_note_placeholder`,
    defaultMessage: 'Reason for sick note',
  },
  dates_field_label: {
    id: `${baseId}.dates_field_label`,
    defaultMessage: 'Dates',
  },
  calendar_tooltip_message_html: {
    id: `${baseId}.calendar_tooltip_message_html`,
    defaultMessage:
      'Private sick notes can be issued for up to two weeks. They cannot be backdated.',
  },
  calendar_start_date_placeholder: {
    id: `${baseId}.calendar_start_date_placeholder`,
    defaultMessage: 'Start date',
  },
  calendar_end_date_placeholder: {
    id: `${baseId}.calendar_end_date_placeholder`,
    defaultMessage: 'End date',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  create_button_label: {
    id: `${baseId}.create_button_label`,
    defaultMessage: 'Create',
  },
  update_button_label: {
    id: `${baseId}.update_button_label`,
    defaultMessage: 'Update',
  },
  character_count_positive: {
    id: `${baseId}.character_count_positive`,
    defaultMessage: 'You have {remainingReasonCharacters} characters remaining',
  },
  character_count_negative: {
    id: `${baseId}.character_count_negative`,
    defaultMessage: '{characterOverage} characters over limit',
  },
})
