import React from 'react'

import { useLogException } from '~/core/sentry'

import ErrorCode from '../ErrorCode'
import MiniErrorPanelView, {
  MiniErrorPanelViewProps,
} from '../MiniErrorPanelView'

export interface MiniErrorPanelProps
  extends Pick<
    MiniErrorPanelViewProps,
    'title' | 'retry' | 'description' | 'className' | 'retryAlwaysVisible'
  > {
  error: Error
  startExpanded?: boolean
}

const MiniErrorPanel = ({
  error,
  description,
  title,
  retry,
  className,
  retryAlwaysVisible,
  startExpanded = false,
}: MiniErrorPanelProps) => {
  const errorId = useLogException(error)
  const errorCode = <ErrorCode error={error} />

  return (
    <MiniErrorPanelView
      title={title}
      description={description}
      retry={retry}
      errorCode={errorCode}
      errorId={errorId}
      className={className}
      retryAlwaysVisible={retryAlwaysVisible}
      startExpanded={startExpanded}
    />
  )
}

export default MiniErrorPanel
