import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from '@babylon/core-ui'

import messages from './Section.messages'
import styles from './styles.module.scss'

interface SectionShowMoreButtonProps {
  expanded?: boolean
  onClick?: () => void
  showMoreMessage?: ReactNode
  showLessMessage?: ReactNode
}

const SectionShowMoreButton = ({
  expanded,
  onClick,
  showLessMessage = <FormattedMessage {...messages.show_less_text} />,
  showMoreMessage = <FormattedMessage {...messages.show_more_text} />,
}: SectionShowMoreButtonProps) => (
  <span className={styles.showMoreButton}>
    <Button
      style={{ color: '#2bbbbb', borderColor: '#2bbbbb' }}
      intent="secondary"
      data-testid="more-info-button"
      onClick={onClick}
    >
      {expanded ? showLessMessage : showMoreMessage}
    </Button>
  </span>
)

export default SectionShowMoreButton
