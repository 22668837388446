import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog, DialogProps } from '~/ui/Modal'

import messages from './SickNoteRemoveDialog.messages'

interface SickNoteRemoveDialogProps
  extends Pick<DialogProps, 'onOk' | 'okLoading' | 'onClose'> {}

const SickNoteRemoveDialog = ({
  okLoading,
  onClose,
  onOk,
}: SickNoteRemoveDialogProps) => {
  const format = useMessages(messages)

  return (
    <Dialog
      title={format('title')}
      ok
      okLabel={format('ok_button_label')}
      okLoading={okLoading}
      cancel
      cancelLabel={format('cancel_button_label')}
      type="error"
      onOk={onOk}
      onClose={onClose}
    >
      <Text>{format('message')}</Text>
    </Dialog>
  )
}

export default SickNoteRemoveDialog
