import React, { FC } from 'react'

import { Text } from '@babylon/core-ui'

import styles from './DisplayNamePill.module.scss'

export interface DisplayNamePillProps {
  name: string
}

const DisplayNamePill: FC<DisplayNamePillProps> = ({ name }) => {
  return (
    <Text className={styles.displayNamePill} data-testid="display-name-pill">
      {name}
    </Text>
  )
}

export default DisplayNamePill
