import cn from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

const Paragraph = ({ children, className = null, ...props }) => (
  <span className={cn(styles.paragraph, className)} {...props}>
    {children}
  </span>
)

export default Paragraph
