import { withProps } from 'recompose'

/**
 * Creates ID of format <typename>:<id> which Apollo
 * will use to index data in cache.
 *
 * @param {Object} object
 * @returns {String}
 * @example
 *  const Person = {
 *    __typename: 'Person'
 *    id: 'aaa-bbb-ccc-ddd',
 *    first_name: 'John'
 *  }
 *
 *  const dataId = dataIdFromObject(Person)
 *  // ID: Person:aaa-bbb-ccc-ddd
 */
export const dataIdFromObject = (object) => `${object.__typename}:${object.id}`
/**
 *
 * @param {String|String[]} arg
 */
export const isLoading = (arg = 'data') => (props) => {
  if (Array.isArray(arg)) {
    return arg.some((key) => isLoading(key)(props))
  }

  return props[arg] ? props[arg].loading : false
}

export const withLoading = (arg) =>
  withProps((props) => ({ loading: isLoading(arg)(props) }))

/**
 * Function updates Apollo state based on "fragment" and "dataobjectId".
 *
 *
 * **Updater**:
 *  Should return new state.
 *
 *  function(fragment, response)
 *
 * @param {object} fragment
 * @param {(string | number)} dataObjectId
 * @param {function} updater
 *
 */
export const updateFragment = (fragment, dataObjectId, updater) => (
  proxy,
  response
) => {
  const data = proxy.readFragment({ id: dataObjectId, fragment })
  proxy.writeFragment({
    id: dataObjectId,
    fragment,
    data: updater(data, response),
  })
}
/**
 * Creates an **updateFragment** instance for a specified fragment.
 * @param {object} fragment
 * @param {function} idFactoryFunction
 * @example
 *    const personUpdater = createFragmentUpdater(PERSON_FRAGMENT)
 *    personUpdater(graphQLdata, (cache, response) => {})
 */
export const createFragmentUpdater = (
  fragment,
  idFactoryFunction = dataIdFromObject
) => (dataObjectId, updater) => {
  const id =
    typeof dataObjectId === 'object' ? idFactoryFunction(id) : dataObjectId
  return updateFragment(fragment, id, updater)
}

export const updateQuery = (query, variables, updater) => (proxy, response) => {
  const data = proxy.readQuery({
    query,
    variables,
  })

  proxy.writeQuery({
    query,
    variables,
    data: updater(data, response),
  })
}

export const createQueryUpdater = (query) => (variables, updater) =>
  updateQuery(query, variables, updater)
