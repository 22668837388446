import cn from 'classnames'
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import styles from './styles.module.scss'

type ItemType = {
  component: ReactNode
  id: string
}
interface ScrollMenuProps {
  items: ItemType[]
  highlightedItem: string
}
interface ItemProps {
  item: ItemType
  containerRef: RefObject<HTMLElement>
  highlighted: string
}

const IN_VIEW_THRESHOLD = [0.6, 1]
const DELAY_BEFORE_SHOWING_DATE = 2000
const DELAY_BEFORE_ACTIVATING_ARROWS = 2050

const Item = ({ item, containerRef, highlighted }: ItemProps) => {
  const itemRef = useRef(null)
  const [ranEffect, setRanEffect] = useState(false)
  const [ref, inView] = useInView({
    root: (containerRef && containerRef.current) || null,
    threshold: IN_VIEW_THRESHOLD,
  })

  useEffect(() => {
    if (!inView && highlighted === item.id) {
      if (itemRef && itemRef.current) {
        if (!ranEffect) {
          setTimeout(() => {
            // @ts-expect-error
            itemRef && itemRef.current && itemRef.current.scrollIntoView()
            setRanEffect(true)
          }, DELAY_BEFORE_SHOWING_DATE)
        } else {
          // @ts-expect-error
          itemRef && itemRef.current.scrollIntoView()
        }
      }
    }
  }, [inView, highlighted, item.id, ranEffect])

  return (
    <div className={styles.item} ref={ref}>
      <div ref={itemRef}>{item.component}</div>
    </div>
  )
}

const sideScroll = (
  element: HTMLElement | null,
  direction: string,
  setLeftEnabled: Function,
  setRightEnabled: Function,
  speed: number = 25,
  distance: number = 80,
  step: number = 10
) => {
  let scrollAmount = 0
  const slideTimer = setInterval(() => {
    if (element) {
      element.scrollLeft += direction === 'left' ? -step : step
      if (direction === 'right') {
        setLeftEnabled(true)
        if (element.scrollLeft === element.scrollWidth - element.clientWidth) {
          setRightEnabled(false)
        }
      }
      if (direction === 'left') {
        setRightEnabled(true)
        if (element.scrollLeft === 0) {
          setLeftEnabled(false)
        }
      }
    }
    scrollAmount += step
    if (scrollAmount >= distance) {
      clearInterval(slideTimer)
    }
  }, speed)
}

const ScrollMenu = ({ items, highlightedItem }: ScrollMenuProps) => {
  const containerRef = useRef(null)
  const [leftEnabled, setLeftEnabled] = useState(false)
  const [rightEnabled, setRightEnabled] = useState(false)

  const onMountArrowCheck = (element: HTMLElement | null) => {
    if (element) {
      if (element.scrollLeft < element.scrollWidth - element.clientWidth) {
        setRightEnabled(true)
      }
      if (element.scrollLeft !== 0) {
        setLeftEnabled(true)
      }
    }
  }

  useEffect(() => {
    setTimeout(
      () => onMountArrowCheck(document.getElementById('container')),
      DELAY_BEFORE_ACTIVATING_ARROWS
    )
  }, [])

  const leftHandler = () => {
    const container = document.getElementById('container')
    sideScroll(container, 'left', setLeftEnabled, setRightEnabled)
  }
  const rightHandler = () => {
    const container = document.getElementById('container')
    sideScroll(container, 'right', setLeftEnabled, setRightEnabled)
  }

  return (
    <div className={styles.scrollMenu} data-testid="scroll-menu">
      <div
        className={cn(styles.scrollIcon, leftEnabled && styles.enabled)}
        data-testid="chevron-left"
        onClick={leftHandler}
      >
        <i className="fas fa-chevron-left" />
      </div>

      <div id="container" ref={containerRef} className={styles.container}>
        {items.map((item, idx) => (
          <Item
            key={`${item.id}_${idx}`}
            item={item}
            containerRef={containerRef}
            highlighted={highlightedItem}
          />
        ))}
      </div>

      <div
        className={cn(styles.scrollIcon, rightEnabled && styles.enabled)}
        data-testid="chevron-right"
        onClick={rightHandler}
      >
        <i className="fas fa-chevron-right" />
      </div>
    </div>
  )
}

export default ScrollMenu
