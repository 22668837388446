import defaults from 'lodash/defaults'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import React, { useContext, useEffect, useRef } from 'react'

import { useBeforeMount, useMessages } from '~/core/hooks'
import useSafeSetState from '~/core/hooks/useSafeSetState'
import { logException } from '~/core/sentry'
import { BabylonSerializer } from '~/features/clinical-coding/ClinicalNotesEditor'
import {
  ConsultationNoteCode,
  useUpdateConsultationNoteMutation,
} from '~/generated'
import { notify } from '~/ui/Notification'

import useDebounce from './useDebounce'
import useIsFirstRender from './useIsFirstRender'

import messages from './Diagnosis.messages'

type DiagnosisStoreState = {
  assessment: string
  assessmentCodes: Array<ConsultationNoteCode>
  treatmentPlan: string
  fallbackPlan: string
  initialAssessment: string
}

type DiagnosisProviderProps = {
  initialState: DiagnosisStoreState
  children: React.ReactNode
  consultationId: any
}

type DiagnosisStoreValue = {
  store: DiagnosisStoreState
  onChange: Function
}

const DiagnosisStoreContext = React.createContext<DiagnosisStoreValue | null>(
  null
)

const defaultState: DiagnosisStoreState = {
  assessment: '',
  assessmentCodes: [],
  treatmentPlan: '',
  fallbackPlan: '',
  initialAssessment: '',
}

const mapStateToExport = (state: any, withNotes: boolean) => {
  const [assessment, assessmentCodes]: any = BabylonSerializer.serialize(
    state.assessment,
    withNotes
  )

  return {
    assessment,
    assessmentCodes,
    treatmentPlan: state.treatmentPlan,
    fallbackPlan: state.fallbackPlan,
  }
}

/**
 * Filter out fields that we are not interested in, also make sure to
 * defaults fields that are not present or null
 */
const prepareInitialState = (
  initialState: DiagnosisStoreState
): DiagnosisStoreState => {
  const state = {
    ...initialState,
    assessment: BabylonSerializer.deserialize(
      initialState.assessment,
      initialState.assessmentCodes
    ),
    initialAssessment: BabylonSerializer.deserialize(
      initialState.assessment,
      initialState.assessmentCodes
    ),
  }
  const defaultStateKeys = Object.keys(defaultState)
  /**
   * Remove null fields and filter out only fields that are defined in
   * defaultState
   */
  const filteredInitialState = omitBy(pick(state, defaultStateKeys), isNull)

  return defaults(filteredInitialState, defaultState)
}

const DiagnosisProvider: React.FC<DiagnosisProviderProps> = ({
  initialState,
  children,
  consultationId,
}) => {
  const f = useMessages(messages)
  const isFirstRender = useIsFirstRender()
  const [updateConsultationNoteMutation] = useUpdateConsultationNoteMutation()
  const [store, setStore] = useSafeSetState(() =>
    prepareInitialState(initialState)
  )
  const debouncedState = useDebounce(store, 500)
  const prevInput = useRef<any>(null)
  /**
   * Set default value for memorized prevInput
   */
  useBeforeMount(() => {
    prevInput.current = mapStateToExport(debouncedState, false)
  })

  /**
   * TODO: Loading state
   */
  useEffect(() => {
    if (isFirstRender) {
      return
    }

    const input = mapStateToExport(debouncedState, false)
    /**
     * Send HTTP request only if value different from memorized version
     */
    if (!isEqual(input, prevInput.current)) {
      prevInput.current = input

      updateConsultationNoteMutation({
        variables: {
          consultationId,
          input,
        },
      }).catch((err) => {
        notify({
          title: f('error_saving_diagnosis_title'),
          message: f('error_saving_diagnosis_message'),
          type: 'error',
        })
        logException(err)
      })
    }
  }, [
    isFirstRender,
    consultationId,
    updateConsultationNoteMutation,
    debouncedState,
    f,
  ])

  return (
    <DiagnosisStoreContext.Provider
      value={{
        store,
        onChange: setStore,
      }}
    >
      {children}
    </DiagnosisStoreContext.Provider>
  )
}

export const DiagnosisStoreProvider = DiagnosisProvider

export const DiagnosisStoreConsumer = DiagnosisStoreContext.Consumer

export const useDiagnosisStore = () => {
  const val = useContext(DiagnosisStoreContext)
  if (!val) {
    throw new Error('Diagnosis store not initialised')
  }

  return val
}
