import { defineMessages } from 'react-intl'

const baseId = 'consultation.nhs_appointment_invites_summary'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'NHS appointment',
  },
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: 'We can’t load NHS appointments right now',
  },
})
