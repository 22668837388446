import React from 'react'

import OnboardingSlide1 from '~/assets/pre-consultation/onboarding1.svg'
import OnboardingSlide2 from '~/assets/pre-consultation/onboarding2.svg'
import OnboardingSlide3 from '~/assets/pre-consultation/onboarding3.svg'
import { HIDE_PRE_CONS_ONBOARDING_AFTER } from '~/constants'
import {
  HideAfter,
  Onboarding,
  OnboardingMessage,
  ShowHide,
} from '~/ui/Onboarding'

interface ImgProps {
  src: string
}

const Img: React.FC<ImgProps> = ({ src }) => (
  <div
    style={{
      backgroundImage: `url(${src})`,
    }}
  />
)

const NAME = 'PreCosultationOnboarding1'

const PreCosultationOnboarding = () => (
  <div style={{ paddingBottom: 8 }}>
    <HideAfter date={HIDE_PRE_CONS_ONBOARDING_AFTER}>
      <ShowHide
        name={NAME}
        showButtonLabel="Show introduction to pre-consultation questionnaire"
        hideButtonLabel="Hide introduction to pre-consultation questionnaire"
      >
        <Onboarding name={NAME}>
          <OnboardingMessage
            title="NEW: Pre-consultation questionnaire"
            description={
              <span>
                Patients now answer relevant questions before accessing certain
                types of care. You may use this data to inform clinical decision
                making.
              </span>
            }
            image={<Img src={OnboardingSlide1} />}
            imageCaption={
              <span>
                <strong>Patients</strong> confirm they are providing{' '}
                <strong>accurate information</strong> through agreeing to a
                disclaimer.
              </span>
            }
          />
          <OnboardingMessage
            title="NEW: Pre-consultation questionnaire"
            description={
              <span>
                Patients now answer relevant questions before accessing certain
                types of care. You may use this data to inform clinical decision
                making.
              </span>
            }
            image={<Img src={OnboardingSlide2} />}
            imageCaption={
              <span>
                You have access to the patient responses through the transcript
                that is displayed in the consultation.
              </span>
            }
          />
          <OnboardingMessage
            title="NEW: Pre-consultation questionnaire"
            description={
              <span>
                Patients now answer relevant questions before accessing certain
                types of care. You may use this data to inform clinical decision
                making.
              </span>
            }
            image={<Img src={OnboardingSlide3} />}
            imageCaption={
              <span>
                Once the consultation is complete, other clinicians will have
                access to the full pre-consultation questionnaire from the
                patient history.
              </span>
            }
          />
        </Onboarding>
      </ShowHide>
    </HideAfter>
  </div>
)

export default PreCosultationOnboarding
