import React, { ChangeEvent } from 'react'

import { Button, FormikNumberInput, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  PAT_MET_SAVE_BLOOD_PRESSURE,
  PAT_MET_UPDATE_BLOOD_PRESSURE,
  PATIENT_METRICS,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { PatientMetricsFormTemplate } from '~/features/patient-metrics/PatientMetricFormTemplate'
import { useTimestamp } from '~/features/patient-metrics/utils/Timestamp'
import { PatientMetricObservationType } from '~/generated'

import { constructBloodPressureValue } from '../utils/blood_pressure'
import { usePatientMetricsState } from './PatientMetricsStateProvider'
import { isExpanded, validateDiastolicBP, validateSystolicBP } from './utils'

import messages from './PatientMetricsModal.messages'
import styles from './styles.module.scss'

const BloodPressureForm = () => {
  const startExpanded = isExpanded('BloodPressureInquiry')

  const fm = useFormatMessage()
  const {
    values: { systolic, diastolic },
    displayValues: {
      displaySystolic,
      displayDiastolic,
      performerBloodPressure,
      timestampBloodPressure,
    },
    setFieldValue,
    setFieldTouched,
    handleTaggedSubmit,
    submitInfo,
    errors,
  } = usePatientMetricsState()

  const displayBP = displaySystolic && displayDiastolic
  const mutationError = submitInfo.error.systolic || submitInfo.error.diastolic
  const submitting =
    submitInfo.submitting.systolic || submitInfo.submitting.diastolic

  const [timestamp, setTimestamp] = useTimestamp(timestampBloodPressure)

  const handleSubmit = async () => {
    try {
      await handleTaggedSubmit(
        [
          PatientMetricObservationType.Systolic,
          PatientMetricObservationType.Diastolic,
        ],
        PatientMetricObservationType.BloodPressure
      )
      setFieldValue('systolic', null)
      setFieldTouched('systolic', false)
      setFieldValue('diastolic', null)
      setFieldTouched('diastolic', false)
      setTimestamp(fm(messages.blood_pressure_updated_just_now))

      analytics.trackEvent({
        label: displayBP
          ? PAT_MET_UPDATE_BLOOD_PRESSURE
          : PAT_MET_SAVE_BLOOD_PRESSURE,
        category: PATIENT_METRICS,
        action: UPDATE_PATIENT_METRICS,
      })
    } catch (e) {
      // NOOP
    }
  }

  const handleBPInputBlur = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      name,
      value === '' ? '' : Number(value.replace(/[.,].*/g, ''))
    )
  }

  return (
    <PatientMetricsFormTemplate
      title={fm(messages.blood_pressure_label)}
      value={constructBloodPressureValue(displaySystolic, displayDiastolic, fm)}
      rating="generic"
      startExpanded={startExpanded}
      timestamp={timestamp}
      performer={performerBloodPressure}
      dataTestId="blood-pressure-section"
      sectionName="bloodPressureSection"
    >
      <Grid templateColumns="3fr 1fr" justifyContent="center">
        <Grid columns={3}>
          <FormikNumberInput
            id="systolic"
            label={fm(messages.systolic_label)}
            name="systolic"
            placeholder={fm(messages.systolic_placeholder)}
            validate={validateSystolicBP(fm)}
            autoComplete="off"
            onBlur={handleBPInputBlur}
            data-testid="systolic_bp"
          />
          <FormikNumberInput
            id="diastolic"
            label={fm(messages.diastolic_label)}
            name="diastolic"
            placeholder={fm(messages.diastolic_placeholder)}
            validate={validateDiastolicBP(fm)}
            autoComplete="off"
            onBlur={handleBPInputBlur}
            data-testid="diastolic_bp"
          />
        </Grid>
        <div className={styles.submitButton}>
          <Button
            style={{ minWidth: '90px' }}
            type="button"
            loading={submitting}
            disabled={
              !systolic || !diastolic || !!errors.diastolic || !!errors.systolic
            }
            data-testid="bp-submit-button"
            onClick={handleSubmit}
          >
            {displayBP
              ? fm(messages.submit_button_update_label)
              : fm(messages.submit_button_save_label)}
          </Button>
        </div>
      </Grid>
      <Text color="grey-type">{fm(messages.info_message_blood_pressure)}</Text>
      {mutationError && (
        <div className={styles.mutationErrorMessage}>
          <Text color="error">
            {fm(messages.error_patient_metrics_mutation_failure)}
          </Text>
        </div>
      )}
    </PatientMetricsFormTemplate>
  )
}
export default BloodPressureForm
