import { ChatscriptMessage } from '~/generated'

export const getTextFromMessages = (messages: ChatscriptMessage[]) => {
  let text = ''
  messages.forEach(({ sender, value, input }) => {
    const choices = input?.params?.choices
    const choicesString = choices?.length
      ? `(${choices.map((choice) => choice.label).join(', ')})`
      : ''

    text += `${value?.text || ''} ${choicesString}\n`
    if (sender !== 'babylon') text += '\n'
  })
  return text
}

export const copyMessagesIntoClipboard = (messages: ChatscriptMessage[]) => {
  const copiedText = getTextFromMessages(messages)
  var el = document.createElement('textarea')
  el.value = copiedText
  el.style.width = '1px'
  el.style.height = '1px'

  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
