import {
  ApolloClient as ApolloClientType,
  ApolloError,
  ApolloQueryResult,
  NetworkStatus,
} from '@apollo/client'

import {
  ObservableValue,
  observableValue,
  useObservable,
} from '~/core/container'
import {
  CpConsultationPageDocument,
  CpConsultationPageQuery,
  CpConsultationPageQueryHookResult,
} from '~/generated'

import { ConsultationContextInterface } from './modules/generated/types'

export type ApolloClient = Pick<ApolloClientType<any>, 'watchQuery'>

export interface ConsultationResult
  extends ApolloQueryResult<CpConsultationPageQuery> {
  error?: ApolloError
  refetch: () => void
}

export type ConsultationContextType = ObservableValue<
  ConsultationResult,
  string
> & {
  reset: () => void
  nextResult: (result: CpConsultationPageQueryHookResult) => void
}

type ConsultationContextImplementationInterface = (options: {
  apolloClient: Pick<ApolloClient, 'watchQuery'>
}) => ReturnType<ConsultationContextInterface>

export const ConsultationContext: ConsultationContextImplementationInterface = ({
  apolloClient,
}) => {
  const id = observableValue<string | undefined>(undefined)

  const refetch = () => {
    console.warn('Refetch function is not implemented')
  }

  const defaultResult = {
    refetch,
    data: {},
    loading: true,
    networkStatus: NetworkStatus.loading,
    stale: false,
  }

  const consultation = observableValue<ConsultationResult>({ ...defaultResult })

  let subscription: ZenObservable.Subscription | null = null

  const nextConsultation = (result: ApolloQueryResult<any>) => {
    consultation.next({
      ...defaultResult,
      ...result,
    })
  }

  const onError = (error: any) => {
    consultation.next({
      ...defaultResult,
      errors: [error],
      error,
      loading: false,
    })
  }

  id.subscribe((consultationId) => {
    if (subscription) {
      subscription.unsubscribe()
      subscription = null
    }

    if (!consultationId) {
      resetValue()
      return
    }

    const result = apolloClient.watchQuery({
      query: CpConsultationPageDocument,
      variables: { id: consultationId },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    })

    subscription = result.subscribe(nextConsultation, onError)
  })

  const nextId = (nextId: string) => {
    consultation.next({ ...defaultResult })

    id.next(nextId)
  }

  const nextResult = (result: CpConsultationPageQueryHookResult) => {
    if (consultation.get() !== result && result.data) {
      nextConsultation(result)
    }
  }

  const resetValue = () => {
    consultation.next(defaultResult)
  }

  return {
    get: consultation.get,
    subscribe: consultation.subscribe,
    next: nextId,
    reset: resetValue,
    nextResult,
  }
}

export type PatientContext = ConsultationContextType

export const useConsultationWithGracefulExit = (
  context: ConsultationContextType
) => {
  const { data } = useObservable(context)
  if (!data?.consultation) {
    return
  }

  return data.consultation
}

export const useConsultation = (context: ConsultationContextType) => {
  const { data } = useObservable(context)
  if (!data?.consultation) {
    console.log(
      'useConsultation must be used as a child of the consultation page, after the consultation data has been loaded by consultationContext'
    )
    throw new Error('No consultation available')
  }

  return data.consultation
}

export const usePatient = (context: ConsultationContextType) => {
  const { patient } = useConsultation(context)
  if (!patient) {
    console.log(
      'usePatient must be used as a child of the consultation page, after the consultation data has been loaded by consultationContext'
    )
    throw new Error('No patient available')
  }

  return patient
}
