import cn from 'classnames'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ClinicalPresenceHeartbeat from '~/core/ClinicalPresenceHeartbeat/ClinicalPresenceHeartbeat'
import { LayoutInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import ErrorBoundary from '~/core/ErrorBoundary'

import BrowserVersionWarning from '../BrowserVersionWarning'

import styles from './styles.module.scss'

const Layout: LayoutInterface = ({ header, navigation, routes }) => {
  const { clinicalPresenceHeartbeatEnabled } = useFeatureFlags()
  return (
    <>
      {header}
      <div className={cn(styles.body, styles.hasHeader)}>
        <ErrorBoundary gaAction="app_content" fill="container" center>
          <Switch>
            <Route path="/consultation/:id" />
            <Route>{navigation}</Route>
          </Switch>
          <main className={styles.main}>{routes}</main>
        </ErrorBoundary>
      </div>
      <BrowserVersionWarning />
      {clinicalPresenceHeartbeatEnabled ? <ClinicalPresenceHeartbeat /> : <></>}
    </>
  )
}

export default Layout
