import { round } from '../utils'

import {
  centimeterToWholeFoot,
  centimeterToWholeFootInchRemainder,
  footToCentimeter,
  inchToCentimeter,
  INCHES_PER_FOOT,
  DEFAULT_DECIMALS,
} from '../unit_converters'

type ImperialHeight = {
  ft?: number | null
  inch?: number | null
}

interface ImperialHeightInput extends ImperialHeight {
  decimals: number
}

interface MetricHeightInput {
  cm?: number | null
  decimals: number
}

const heightConversions = {
  metricConversions: {
    imperial: ({ cm, decimals }: MetricHeightInput): ImperialHeight => {
      if (cm == null) {
        return { ft: null, inch: null }
      }

      return {
        ft: round(centimeterToWholeFoot(cm), decimals),
        inch: round(centimeterToWholeFootInchRemainder(cm), decimals),
      }
    },
  },
  imperialConversions: {
    metric: ({ ft, inch, decimals }: ImperialHeightInput): number | null => {
      if (ft == null && inch == null) {
        return null
      }

      return round(
        (ft ? footToCentimeter(ft) : 0) + (inch ? inchToCentimeter(inch) : 0),
        decimals
      )
    },

    imperial: ({ ft, inch, decimals }: ImperialHeightInput): ImperialHeight => {
      if (ft == null && inch == null) {
        return { ft: null, inch: null }
      }

      return { ft: ft || round(0, decimals), inch: inch || round(0, decimals) }
    },

    normalize: {
      inch: ({
        inch,
        decimals,
      }: Pick<ImperialHeightInput, 'inch' | 'decimals'>) => {
        if (inch == null) {
          return { ft: null, inch: null }
        }

        return {
          ft: Math.floor(inch / INCHES_PER_FOOT),
          inch: round(inch % INCHES_PER_FOOT, decimals),
        }
      },

      feet: ({
        ft,
        decimals,
      }: Pick<ImperialHeightInput, 'ft' | 'decimals'>) => {
        if (ft == null) {
          return { ft: null, inch: null }
        }

        return {
          ft: Math.floor(ft),
          inch: round(INCHES_PER_FOOT * (ft % 1), decimals),
        }
      },
    },
  },
}

const heightMap = {
  metric: {
    toUs: (cm?: number | null, decimals = DEFAULT_DECIMALS): ImperialHeight =>
      heightConversions.metricConversions.imperial({ cm, decimals }),

    toUk: (cm?: number | null, decimals = DEFAULT_DECIMALS): ImperialHeight =>
      heightConversions.metricConversions.imperial({ cm, decimals }),
    normalize: (cm: number | null): Error => {
      throw new Error(
        `No normalization for metric, it will simply return ${cm} cm`
      )
    },
  },

  us: {
    toMetric: (
      { ft, inch }: ImperialHeight,
      decimals = DEFAULT_DECIMALS
    ): number | null =>
      heightConversions.imperialConversions.metric({ ft, inch, decimals }),

    toUk: (
      { ft, inch }: ImperialHeight,
      decimals = DEFAULT_DECIMALS
    ): ImperialHeight =>
      heightConversions.imperialConversions.imperial({ ft, inch, decimals }),
    normalizeFromInch: (
      inch: number | null,
      decimals = DEFAULT_DECIMALS
    ): ImperialHeight =>
      heightConversions.imperialConversions.normalize.inch({ inch, decimals }),
    normalizeFormFeet: (
      ft: number | null,
      decimals = DEFAULT_DECIMALS
    ): ImperialHeight =>
      heightConversions.imperialConversions.normalize.feet({ ft, decimals }),
  },

  uk: {
    toMetric: (
      { ft, inch }: ImperialHeight,
      decimals = DEFAULT_DECIMALS
    ): number | null =>
      heightConversions.imperialConversions.metric({ ft, inch, decimals }),

    toUs: (
      { ft, inch }: ImperialHeight,
      decimals = DEFAULT_DECIMALS
    ): ImperialHeight =>
      heightConversions.imperialConversions.imperial({ ft, inch, decimals }),
    normalizeFromInch: (
      inch: number | null,
      decimals = DEFAULT_DECIMALS
    ): ImperialHeight =>
      heightConversions.imperialConversions.normalize.inch({ inch, decimals }),
    normalizeFromFeet: (
      ft: number | null,
      decimals = DEFAULT_DECIMALS
    ): ImperialHeight =>
      heightConversions.imperialConversions.normalize.feet({ ft, decimals }),
  },
}

export default heightMap
