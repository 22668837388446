import React from 'react'
import { Node } from 'slate'
import { Editor, RenderAttributes, RenderInlineProps } from 'slate-react'

import ClinicalCode from '../../components/ClinicalCode'
import {
  replaceLastWordWithClinicalCode,
  wrapSelectionWithClinicalCode,
} from './commands'
import { CLINICAL_CODE } from './constants'

export default {
  commands: {
    replaceLastWordWithClinicalCode,
    wrapSelectionWithClinicalCode,
  },
  renderNode: (props: RenderInlineProps, editor: Editor, next: Function) => {
    const { attributes, node, isFocused } = props
    const { readOnly } = editor

    if (node.type === CLINICAL_CODE) {
      const onRemove = (attrs: RenderAttributes) => {
        const key = attrs['data-key']
        /**
         *  Get clinical code node by key
         */
        const clinicalCodeNode = editor.value.document.getNode(key)
        /**
         * Sometimes node can be already not mounted (for example if user quickly double-clicks
         * the remove button th). Because of this we need to wrap next block in if statement.
         */
        if (clinicalCodeNode) {
          /**
           * When we unwrap the code we are putting caret just after the unwrapped text.
           * This makes sure that editor is focused (which adds event handlers for ESC,
           * Enter, etc)
           */
          editor
            .moveToStartOfNode(clinicalCodeNode as Node)
            .focus()
            .moveAnchorToEndOfInline()
            .unwrapInlineByKey(key, { type: CLINICAL_CODE })
        }
      }

      return (
        <ClinicalCode
          {...attributes}
          isFocused={isFocused}
          onRemove={readOnly ? null : onRemove}
        >
          {node.text}
        </ClinicalCode>
      )
    }

    return next()
  },
}
