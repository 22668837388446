import { BackgroundReplacementFilter } from '@opentok/client'
import { useCallback, useEffect, useRef, useState } from 'react'
import uuid from 'uuid/v4'

import { checkConnectionIsGuest } from '@babylon/connect-client-core'

import { useEffectOnce, useMount, useUnmount, useUpdate } from '~/core/hooks'
import { logException } from '~/core/sentry'

import { PUBLISHER_PROPERTIES, SUBSCRIBER_PROPERTIES } from './constants'
import {
  OTError,
  OTEventHandlerObject,
  OTPublisher,
  OTPublisherProperties,
  OTSession,
  OTStream,
  OTSubscriber,
  OTSubscriberProperties,
  SessionStatus,
} from './types'

const loadOpentok = async () => {
  const opentok = await import(
    /* webpackChunkName: "opentok" */ '@opentok/client'
  )
  return opentok.default || opentok
}

const SUBSCRIBER_PROPERTIES_EXT: OTSubscriberProperties = {
  ...SUBSCRIBER_PROPERTIES,
}
const PUBLISHER_PROPERTIES_EXT: OTPublisherProperties = {
  ...PUBLISHER_PROPERTIES,
}

interface IuseOpentok {
  apiKey: string
  sessionId: string
  token: string
  sessionEventHandlers?: OTEventHandlerObject
  subscriberEventHandlers?: OTEventHandlerObject
  publisherEventHandlers?: OTEventHandlerObject
  onError?: (error: OTError) => void
  isCallActive: boolean
  micEnabled: boolean
  cameraEnabled: boolean
  backgroundEnabled: boolean
  consultantName?: string
  multiwayCallingEnabled?: boolean
  videoStreamWindowImprovementsEnabled?: boolean
}

export type VideoDegradation = 'warning' | 'disabled' | 'none'

const VIRTUAL_BACKGROUND_CONFIG: BackgroundReplacementFilter = {
  backgroundImgUrl: '/images/emed-virtual-background.jpeg',
  type: 'backgroundReplacement',
}

const useOpentok = ({
  apiKey,
  sessionId,
  token,
  sessionEventHandlers,
  subscriberEventHandlers,
  publisherEventHandlers,
  onError,
  isCallActive,
  micEnabled,
  cameraEnabled,
  backgroundEnabled,
  consultantName,
  multiwayCallingEnabled,
  videoStreamWindowImprovementsEnabled,
}: IuseOpentok) => {
  const session = useRef<OTSession>()
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(
    SessionStatus.NOT_ACTIVE
  )
  const [publisher, setPublisher] = useState<OTPublisher>()
  const publisherId = useRef<string | null>()
  const [subscriber, setSubscriber] = useState<OTSubscriber>()
  const [
    subscriberVideoDegradation,
    setSubscriberVideoDegradation,
  ] = useState<VideoDegradation>('none')
  const subscriberElement = useRef<HTMLDivElement>(null)
  const publisherElement = useRef<HTMLDivElement>(null)
  const guestElement = useRef<HTMLDivElement>(null)
  const [streams, setStreams] = useState<OTStream[]>([])
  const [isGuestStreamActive, setIsGuestStreamActive] = useState<Boolean>(false)
  const update = useUpdate()

  if (videoStreamWindowImprovementsEnabled) {
    SUBSCRIBER_PROPERTIES_EXT.fitMode = 'contain'
    PUBLISHER_PROPERTIES_EXT.fitMode = 'contain'
  }

  /**
   * Callbacks
   */
  const handleError = useCallback(
    (error: OTError | undefined) => {
      if (!error) {
        return
      }

      logException(error)
      if (onError) {
        onError(error)
      }
    },
    [onError]
  )

  const initialise = useCallback(async () => {
    const opentok = await loadOpentok()
    if (session.current) {
      session.current.off()
    }

    session.current = session.current || opentok.initSession(apiKey, sessionId)

    setSessionStatus(SessionStatus.ACTIVE)

    if (sessionEventHandlers) {
      session.current.on(sessionEventHandlers)
    }
    /**
     * Add listener to subscribe to the patient's stream when it is created
     */
    session.current.on('streamCreated', (event) => {
      if (!subscriberElement.current) {
        throw new Error('Subscriber element does not exist')
      }

      if (!session.current) {
        throw new Error('Session does not exist')
      }

      let subscriber
      if (multiwayCallingEnabled) {
        SUBSCRIBER_PROPERTIES_EXT.style = {
          ...SUBSCRIBER_PROPERTIES_EXT.style,
          nameDisplayMode: 'on',
        }
      }
      if (checkConnectionIsGuest(event.stream.connection)) {
        setIsGuestStreamActive(true)
        if (!guestElement.current) {
          throw new Error('Guest element does not exist')
        }
        subscriber = session.current.subscribe(
          event.stream,
          guestElement.current,
          SUBSCRIBER_PROPERTIES_EXT,
          (error) => {
            if (error) {
              handleError(error)
            }
          }
        )
      } else {
        subscriber = session.current.subscribe(
          event.stream,
          subscriberElement.current,
          SUBSCRIBER_PROPERTIES_EXT,
          (error) => {
            if (error) {
              handleError(error)
            }
          }
        )
      }

      setStreams((prev) => [...prev, event.stream])

      if (subscriberEventHandlers) {
        subscriber.on(subscriberEventHandlers)
      }

      subscriber.once('videoElementCreated', () => {
        // Rerender when subscriber video element created
        // To check if autoplay is blocked. For some reason the audioBlocked event doesnt work
        update()
      })

      subscriber.on('audioUnblocked', () => {
        update()
      })

      setSubscriberVideoDegradation('none')
      subscriber.on('videoDisableWarning', () => {
        setSubscriberVideoDegradation('warning')
      })
      subscriber.on('videoDisableWarningLifted', () => {
        setSubscriberVideoDegradation('none')
      })
      subscriber.on('videoDisabled', (e) => {
        if (e.reason === 'quality') {
          setSubscriberVideoDegradation('disabled')
        }
      })
      subscriber.on('videoEnabled', () => {
        setSubscriberVideoDegradation('none')
      })

      setSubscriber(subscriber)
    })

    session.current.on('streamDestroyed', (event) => {
      setStreams((prev) =>
        prev.filter((stream) => stream.streamId !== event.stream.streamId)
      )
    })

    session.current.on('streamPropertyChanged', () => {
      // Rerender when stream updates (eg if audio or video prop updates)
      update()
    })

    /**
     * Connect to the session and publish
     */
    session.current.connect(token, (connectError) => {
      if (connectError) {
        handleError(connectError)
        return
      }

      if (!publisherElement.current) {
        throw new Error('Publisher element does not exist')
      }

      publisherId.current = uuid()
      const scopePublisherId = publisherId.current

      const publisher = opentok.initPublisher(
        publisherElement.current,
        {
          ...PUBLISHER_PROPERTIES_EXT,
          mirror: false,
          videoFilter: backgroundEnabled
            ? VIRTUAL_BACKGROUND_CONFIG
            : undefined,
          name: multiwayCallingEnabled ? consultantName : undefined,
        },
        (initPubError) => {
          if (scopePublisherId !== publisherId.current) {
            // Either this publisher has been recreated or destroyed
            // so don't invoke any callbacks
            return
          }

          if (initPubError) {
            handleError(initPubError)
          }
        }
      )

      if (publisherEventHandlers) {
        publisher.on(publisherEventHandlers)
      }

      setPublisher(publisher)

      if (!session.current) {
        throw new Error('Session does not exist')
      }

      session.current.publish(publisher, (publishError) => {
        if (scopePublisherId !== publisherId.current) {
          // Either this publisher has been recreated or destroyed
          // so don't invoke any callbacks
          return
        }

        if (publishError) {
          handleError(publishError)
        }
      })
    })
  }, [
    apiKey,
    sessionId,
    token,
    publisherEventHandlers,
    sessionEventHandlers,
    subscriberEventHandlers,
    handleError,
    update,
    consultantName,
    multiwayCallingEnabled,
    backgroundEnabled,
  ])

  const disconnect = () => {
    const scopeSession = session.current

    // Destroy subscriber
    if (subscriber) {
      subscriber.once('destroyed', () => {
        if (subscriberEventHandlers) {
          subscriber.off()
        }
      })
      if (scopeSession) {
        scopeSession.unsubscribe(subscriber)
      }
    }

    // Destroy publisher
    if (publisher) {
      publisher.once('destroyed', () => {
        if (publisherEventHandlers) {
          publisher.off()
        }
      })
    }
    publisherId.current = null

    // Destroy session
    if (scopeSession) {
      scopeSession.once('sessionDisconnected', () => {
        scopeSession.off()
      })
      setSubscriberVideoDegradation('none')
      setStreams([])
      scopeSession.disconnect()
    }

    setSessionStatus(SessionStatus.NOT_ACTIVE)
  }

  /**
   * Effects
   */
  useEffectOnce(() => {
    loadOpentok().then((opentok) => {
      opentok.on('exception', (event) => {
        logException((event as any).error || new Error(event.message))
      })
    })
    return () => {
      loadOpentok().then((opentok) => {
        opentok.off('exception')
      })
    }
  })

  useMount(() => {
    loadOpentok().then((opentok) => {
      opentok.getDevices((error) => {
        if (error) {
          logException(error)
        }
      })
    })
  })

  useEffect(() => {
    if (isCallActive) {
      initialise()
    } else {
      disconnect()
    }
  }, [isCallActive]) // eslint-disable-line react-hooks/exhaustive-deps

  useUnmount(() => {
    disconnect()
  })

  const isAutoplayBlocked = subscriber?.isAudioBlocked()

  useEffect(() => {
    if (publisher) {
      if (isAutoplayBlocked) {
        publisher.publishAudio(false)
        publisher.publishVideo(false)
      }
      if (isAutoplayBlocked === false) {
        publisher.publishAudio(micEnabled)
        publisher.publishVideo(cameraEnabled)
      }
      if (backgroundEnabled) {
        publisher.applyVideoFilter(VIRTUAL_BACKGROUND_CONFIG)
      } else {
        publisher.clearVideoFilter()
      }
      update()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAutoplayBlocked,
    publisher,
    micEnabled,
    cameraEnabled,
    backgroundEnabled,
  ])

  return {
    subscriberElement,
    publisherElement,
    guestElement,
    initialise,
    disconnect,
    session,
    sessionStatus,
    publisher,
    subscriber,
    streams,
    subscriberVideoDegradation,
    isGuestStreamActive,
    setIsGuestStreamActive,
  }
}

export default useOpentok
