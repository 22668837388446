import React from 'react'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { INTERNAL_WEB_URL } from '~/constants'
import {
  CONSULTATION_PROFILE_CATEGORY,
  OPEN_PATIENT_RECORD_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { PatientRecordDeepLinkPluginInterface } from '~/core/config/modules/generated/types'

import messages from './PatientRecordDeepLink.messages'

export const PatientRecordDeepLink: PatientRecordDeepLinkPluginInterface = ({
  consultationContext,
}) => {
  const trackEvent = analytics.trackEventFactory({
    category: CONSULTATION_PROFILE_CATEGORY,
  })

  const fm = useFormatMessage()
  const { patientId } = useConsultation(consultationContext)
  const link = `${INTERNAL_WEB_URL}/health-graph-viewer/patient/${patientId}`

  const openDeepLink = () => {
    trackEvent({ action: OPEN_PATIENT_RECORD_ACTION })
    if (!INTERNAL_WEB_URL) {
      // eslint-disable-next-line no-console
      console.warn('INTERNAL_WEB_URL is not set')
      return
    }

    window.open(link)
  }

  return (
    <Button
      fill
      intent="secondary"
      onClick={openDeepLink}
      dataTestId="consultation-patient-record-button"
    >
      {fm(messages.open_patient_record_button_label)}
    </Button>
  )
}
