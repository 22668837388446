import React, { useState } from 'react'

import { useGlobals } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'

import browser from './browser'

import messages from './BrowserVersionWarning.messages'
import styles from './styles.module.scss'

const BrowserVersionWarning = () => {
  const format = useMessages(messages)
  const [isClosed, setClosed] = useState(false)

  const { allowedBrowsers } = useGlobals()

  const isAllowedBrowser = Array.isArray(allowedBrowsers)
    ? browser?.type === 'browser' && allowedBrowsers.includes(browser.name)
    : true

  if (isClosed || isAllowedBrowser) {
    return null
  }

  return (
    <div className={styles.notification}>
      <div className={styles.icon}>
        <i className="fas fa-exclamation-triangle" />
      </div>
      <div className={styles.message}>{format('warning_message')}</div>
      <button
        className={styles.closeButton}
        type="button"
        onClick={() => setClosed(true)}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  )
}

export default BrowserVersionWarning
