import { defineMessages } from 'react-intl'

const baseId = 'consultation.prescription'

export default defineMessages({
  duplicate_prescription: {
    id: `${baseId}.duplicate_prescription`,
    defaultMessage: 'Duplicate Prescription',
  },
  void_prescription: {
    id: `${baseId}.void_prescription`,
    defaultMessage: 'Void prescription',
  },
  audit_log: {
    id: `${baseId}.audit_log`,
    defaultMessage: 'Audit log',
  },
})
