import classNamesBind from 'classnames/bind'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { Tag } from '@babylon/core-ui'

import messages from './SelectOption.messages'
import styles from './styles.module.scss'

const cx = classNamesBind.bind(styles)

class SelectOption extends Component {
  handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { option } = this.props

    if (option.className === 'Select-create-option-placeholder') {
      /**
       * Creating custom drug
       */
      this.props.onSelect(
        {
          id: null,
          name: option.name,
          packaging: [],
        },
        event
      )
    } else {
      this.props.onSelect(option, event)
    }
  }

  handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event)
  }

  handleMouseMove = (event) => {
    if (this.props.isFocused) {
      return
    }

    this.props.onFocus(this.props.option, event)
  }

  render() {
    const { props } = this
    const { option, children, intl } = props

    const {
      generic,
      blacklisted,
      expensive,
      controlled,
      drugPreparation,
      activeIngredients,
    } = option

    const className = cx(props.className, 'option', { blacklisted })

    return (
      <div
        role="button"
        tabIndex="-1"
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <div className={styles.titleWrapper}>
          <div>
            <div data-testid="drug-option" className={styles.title}>
              {children}
            </div>
            <div className={styles.activeIngredients}>{activeIngredients}</div>
          </div>
          <div className={styles.tags}>
            {drugPreparation && <Tag margin>{drugPreparation}</Tag>}
            {expensive && (
              <Tag color="white-on-orange" margin>
                {intl.formatMessage(messages.expensive_tag_value)}
              </Tag>
            )}
            {controlled && (
              <Tag color="white-on-red" margin>
                {intl.formatMessage(messages.controlled_tag_value)}
              </Tag>
            )}
            {generic === true && (
              <Tag color="white-on-green" margin>
                {intl.formatMessage(messages.generic_tag_value)}
              </Tag>
            )}
            {generic === false && (
              <Tag margin>{intl.formatMessage(messages.branded_tag_value)}</Tag>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(SelectOption, { forwardRef: true })
