/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from 'classnames'
import React from 'react'
import ReactDOM from 'react-dom'

import styles from './styles.module.scss'

const defaultPosition = {
  left: -10000,
  top: -10000,
}

const DISTANCE_FROM_WINDOW_EDGE = 5

const range = (min, max, value) => {
  return Math.max(min, Math.min(max, value))
}

const px = (number) => {
  return `${number}px`
}

// This is to ensure that the element is in the DOM, and helps with testing
let root = document.getElementById('root')

if (!root) {
  root = document.createElement('div')
  root.setAttribute('id', 'root')
  document.body.appendChild(root)
}

class PositionController extends React.PureComponent {
  ref = React.createRef()

  componentDidMount() {
    this.updatePosition()
    /**
     * This repositions dropdown correctly if window or even parent was scrolled.
     */
    this.interval = setInterval(() => {
      this.updatePosition()
    }, 200)
  }

  componentDidUpdate() {
    this.updatePosition()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updatePosition = () => {
    const { anchor } = this.props
    const anchorElement = window.document.querySelector(anchor)
    const position = { ...defaultPosition }

    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect()

      position.left = Math.round(rect.left)
      position.top = Math.round(rect.bottom)
    }

    if (this.ref && this.ref.current) {
      const refRect = this.ref.current.getBoundingClientRect()

      const minLeft = DISTANCE_FROM_WINDOW_EDGE
      const maxLeft =
        window.innerWidth - refRect.width - DISTANCE_FROM_WINDOW_EDGE

      const left = range(minLeft, maxLeft, position.left)

      this.ref.current.style.left = px(left)
      this.ref.current.style.top = px(position.top)
    }
  }

  render() {
    const { children, className, anchor, ...rest } = this.props

    return ReactDOM.createPortal(
      <div
        {...rest}
        ref={this.ref}
        className={cn(styles.positionController, className)}
      >
        {children}
      </div>,
      root
    )
  }
}

export default PositionController
