import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.review.consultation_review_content'

export default defineMessages({
  message: {
    id: `${baseId}.message`,
    defaultMessage: `Details such as notes, prescriptions and actions may still change. 

Confirm details with the patient or contact the senior clinical team through support before taking clinical decisions.

Inform the senior clinical team when you create actions relating to an incomplete consultation to prevent duplication.`,
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Not marked complete',
  },
})
