import { ApolloError } from '@apollo/client'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import {
  ErrorCode,
  ErrorPanelView,
  ErrorPanelViewProps,
  MiniErrorPanelView,
} from '~/ui/ErrorPanel'

import ClinicalPortalError from '../ClinicalPortalError'
import RawErrorBoundary from '../RawErrorBoundary'

import messages from './ErrorBoundary.messages'

const GENERIC_BOUNDARY_ERROR_MESSAGE = 'BOUNDARY_ERROR'

interface ErrorDetails {
  errorTitle: React.ReactNode
  errorDescription?: string
  errorCode: React.ReactNode
}

const extractErrorDetails = (error?: Error): ErrorDetails => {
  if (!error || !(error instanceof ClinicalPortalError)) {
    return {
      errorTitle: null,
      errorCode: GENERIC_BOUNDARY_ERROR_MESSAGE,
    }
  }

  const defaultErrorCode =
    error.originalError instanceof ApolloError ? (
      <ErrorCode error={error.originalError} />
    ) : (
      GENERIC_BOUNDARY_ERROR_MESSAGE
    )

  return {
    errorTitle: error.title,
    errorDescription: error.description,
    errorCode: error.errorCode || defaultErrorCode,
  }
}

export interface ErrorBoundaryProps
  extends Pick<
    ErrorPanelViewProps,
    | 'title'
    | 'center'
    | 'fill'
    | 'className'
    | 'description'
    | 'retry'
    | 'retryLabel'
    | 'loading'
  > {
  gaAction: string
  mini?: boolean
  retryAlwaysVisible?: boolean
  children: React.ReactNode
}

const ErrorBoundary = ({
  center,
  fill,
  title: boundaryTitle,
  gaAction,
  description: boundaryDescription,
  mini,
  className,
  children,
  retry,
  retryLabel,
  retryAlwaysVisible,
  loading,
}: ErrorBoundaryProps) => {
  const fm = useFormatMessage()

  return (
    <RawErrorBoundary
      gaAction={gaAction}
      render={({ errorId, error, reset }) => {
        const { errorTitle, errorCode, errorDescription } = extractErrorDetails(
          error
        )

        const title = errorTitle || boundaryTitle || fm(messages.fallback_title)
        const description = errorDescription || boundaryDescription

        if (mini) {
          return (
            <MiniErrorPanelView
              description={description}
              title={title}
              retry={retry || reset}
              retryLabel={retryLabel}
              errorId={errorId}
              errorCode={errorCode}
              className={className}
              retryAlwaysVisible={retryAlwaysVisible}
              loading={loading}
            />
          )
        }

        return (
          <ErrorPanelView
            title={title}
            description={description}
            center={center}
            fill={fill}
            retry={retry || reset}
            retryLabel={retryLabel}
            errorId={errorId}
            errorCode={errorCode}
            className={className}
            loading={loading}
          />
        )
      }}
    >
      {children}
    </RawErrorBoundary>
  )
}

export default ErrorBoundary
