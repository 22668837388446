import { gql } from '@apollo/client'

export const REVOKE_RECORDING_CONSENT = gql`
  mutation CpRevokeRecordingConsent($id: ID!) {
    revokeRecordingConsent(id: $id) {
      id
      recordingConsent
    }
  }
`
