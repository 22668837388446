import { defineMessages } from 'react-intl'

const baseId = 'care_plan.care_plan_warning_modal'

export default defineMessages({
  ok_button_label: {
    id: `${baseId}.ok_button_label`,
    defaultMessage: 'Got it',
  },
})
