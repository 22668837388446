/* eslint-disable no-underscore-dangle */
import { DocumentEntry } from '../../types'
import {
  DocumentReferenceByPatientsByIdentifierQuery,
  DocumentReferenceByPatientsByIdentifierQueryVariables,
} from '../../queries/PatientDocumentReference.federated.hooks'

const BABYLON_PATIENT_SYSTEM_URI = 'https://patient.bbl.health/Patient'
export const DEFAULT_PAGE_SIZE = 5

const buildDocumentReferenceByPatientVariables = (
  memberUuid: string,
  pageSize: number
): DocumentReferenceByPatientsByIdentifierQueryVariables => ({
  filter: {
    ONE_OF: [
      {
        system: BABYLON_PATIENT_SYSTEM_URI,
        value: memberUuid,
      },
    ],
  },
  documentSort: [
    {
      field: 'DATE',
      order: 'DESC',
    },
  ],
  documentFilter: {
    status: {
      ONE_OF: 'CURRENT',
    },
  },
  pageSize,
})

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

type PageInfo = {
  endCursor: string
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
}

const getDocumentPaginationInfo = (
  data: DocumentReferenceByPatientsByIdentifierQuery | undefined
): PageInfo | undefined => {
  if (data && data.patientsByIdentifier?.__typename === 'PatientConnection') {
    const paginationInfo =
      data.patientsByIdentifier?.nodes?.[0]?.documents?.pageInfo

    return paginationInfo
  }

  return undefined
}

const transformDocumentReferenceByPatientResult = (
  data: DocumentReferenceByPatientsByIdentifierQuery | undefined
): DocumentEntry[] => {
  if (data && data.patientsByIdentifier?.__typename === 'PatientConnection') {
    const documentReferencesForPatient =
      data.patientsByIdentifier?.nodes?.[0]?.documents?.nodes

    if (documentReferencesForPatient?.length) {
      return documentReferencesForPatient
        .map((documentNode): DocumentEntry | undefined => {
          const content = documentNode?.contents?.nodes[0]?.attachment

          if (content?.__typename === 'Attachment') {
            return {
              id: documentNode!.id,
              title: content.title || 'Unknown Title',
              url: content.url,
              date: documentNode?.date,
              contentType: content.contentType || 'application/octet-stream',
            }
          }

          return undefined
        })
        .filter(notEmpty)
    }
  }

  return []
}

export {
  buildDocumentReferenceByPatientVariables,
  getDocumentPaginationInfo,
  transformDocumentReferenceByPatientResult,
}
