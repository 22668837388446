import { ApolloError, isApolloError } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { differenceInSeconds, format, fromUnixTime, isValid } from 'date-fns'
import { GraphQLError } from 'graphql'

import { autologinInfo, getLastActivityTime } from '@babylon/babylon-auth'

import { GraphQLErrorWithExtensions } from '../graphql'
import { isTruthy } from '../tsUtils'

export const randomAlphaNumeric = (length: number) =>
  Array(length)
    .fill(0)
    .map(() => Math.random().toString(36).charAt(2))
    .join('')

const expiresIn = (tokenExpiry: number): string | null => {
  const expiryTime = fromUnixTime(tokenExpiry)
  const tokenDuration = differenceInSeconds(expiryTime, new Date())

  return `${tokenDuration} seconds`
}

const formatLoggableDate = (date: Date | number | null) => {
  return date && isValid(date) ? format(date, 'dd/MM/yyyy HH:mm:ss') : null
}

export const getAuthDetails = () => {
  const tokenInfo = autologinInfo()
  const kongTokenExpiryTime = fromUnixTime(tokenInfo.kong_token_expiry)
  const refreshTokenExpiryTime = fromUnixTime(tokenInfo.token_expiry)
  const lastActivityTime = getLastActivityTime()

  return {
    expires_in_kong_token: expiresIn(tokenInfo.kong_token_expiry),
    expires_in_refresh_token: expiresIn(tokenInfo.token_expiry),
    time_current: formatLoggableDate(new Date()),
    time_kong_token_expiry: formatLoggableDate(kongTokenExpiryTime),
    time_refresh_token_expiry: formatLoggableDate(refreshTokenExpiryTime),
    time_last_activity: formatLoggableDate(lastActivityTime),
  }
}

const isValidApolloError = (exception: any): exception is ApolloError => {
  return exception instanceof Error && isApolloError(exception)
}

export const getRequestId = (
  exception: string | Error | null | undefined
): string | null => {
  if (!isValidApolloError(exception)) {
    return null
  }

  const requestId = exception?.graphQLErrors?.[0]?.extensions?.babylonRequestId
  return requestId || null
}

const getStatusCodeFromGraphQLError = (
  error: GraphQLErrorWithExtensions
): number | null => {
  if (!error) {
    return null
  }

  return (
    error.extensions?.exception?.statusCode ||
    error.extensions?.response?.status ||
    null
  )
}

export const getStatusCode = (
  exception: string | Error | null | undefined
): number | null => {
  if (!isValidApolloError(exception)) {
    return null
  }

  const firstGraphQLError = exception.graphQLErrors[0] as
    | GraphQLError
    | undefined
  if (!firstGraphQLError) {
    return null
  }

  return getStatusCodeFromGraphQLError(firstGraphQLError)
}

const getNormalisedGraphQLPath = (path: readonly (string | number)[]) => {
  return path.map((v) => (typeof v === 'number' ? '__n__' : v))
}

const getGraphQLErrorFingerprint = (
  error: GraphQLErrorWithExtensions
): string[] | undefined => {
  if (!error.path) {
    return undefined
  }

  const normalisedPath = getNormalisedGraphQLPath(error.path)

  const statusCode = getStatusCodeFromGraphQLError(error)

  const fingerprint = [...normalisedPath, statusCode]
    .filter(isTruthy)
    .map(String)

  return fingerprint.length ? fingerprint : undefined
}

const getApolloErrorFingerprint = (
  error: ApolloError
): string[] | undefined => {
  const firstGraphQLError = error.graphQLErrors[0]

  if (firstGraphQLError) {
    // TODO: to combine fingerprints of multiple graphql-errors
    // instead of just taking the first
    return getGraphQLErrorFingerprint(firstGraphQLError)
  }

  return undefined
}

export const getFingerprint = (
  event: Sentry.Event,
  hint?: Sentry.EventHint | undefined
): string[] | undefined => {
  const exception = hint?.originalException

  if (event.fingerprint) {
    return event.fingerprint
  }

  if (isValidApolloError(exception)) {
    return getApolloErrorFingerprint(exception)
  }

  return event.fingerprint
}
