import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { PanelQuery } from '@babylon/tests-and-kits'

import withModal from '~/core/with-modal'

import TestReportModal from './TestReportModal'

export default compose(
  withModal(),
  graphql(PanelQuery, {
    options: ({ query }) => ({
      variables: {
        id: query.eventId,
      },
    }),
  })
)(TestReportModal)
