import { setContext } from '@apollo/client/link/context'

import { getHeaders } from '@babylon/shared-utils'
import { AuthClient, getAuthClient } from '@babylon/web-platform-utils-auth'

import { APP_NAME, APP_VERSION, ENABLE_NEW_AUTH } from '~/constants'

let authClient: AuthClient

/*
  we should remove the ENABLE_NEW_AUTH flag and old auth when migration is complete , see:
  babylonpartners.atlassian.net/wiki/spaces/CIT/pages/4241555535/Portals+Open+ID+switchover
*/
const headersLink = setContext(async (_, { headers }) => {
  if (!ENABLE_NEW_AUTH) {
    return {
      headers: {
        ...headers,
        ...getHeaders({ appId: APP_NAME, appVersion: APP_VERSION }),
      },
    }
  }

  if (!authClient) {
    authClient = getAuthClient()
  }

  if (!(await authClient.isAuthenticated())) {
    return {
      headers: {
        ...headers,
        ...getHeaders({ appId: APP_NAME, appVersion: APP_VERSION }),
      },
    }
  }

  const token = await authClient.getAuthToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export default headersLink
