export const validation = {
  reason: {
    required: true,
    empty: false,
  },
  startDate: {
    required: true,
    empty: false,
  },
  endDate: {
    required: true,
    empty: false,
  },
}

export const MAX_REASON_LENGTH = 175

export const defaultState = {
  startDate: new Date(),
  endDate: new Date(),
  reason: '',
  warnings: [],
}
