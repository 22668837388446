import { defineMessages } from 'react-intl'

const baseId = 'features.experiments'

export default defineMessages({
  page_title: {
    id: `${baseId}.page_title`,
    defaultMessage: 'Welcome to Experiments',
  },
  instructions: {
    id: `${baseId}.instructions`,
    defaultMessage:
      'Tick the checkbox for the experiments you want to be part of and refresh the page/navigate to the feature. Untick the checkbox if you want to remove yourself from an experiment',
  },
  no_experiments: {
    id: `${baseId}.no_experiments`,
    defaultMessage: 'No Experiments Available',
  },
  experiment_enabled_for_everyone: {
    id: `${baseId}.experiment_enabled_for_everyone`,
    defaultMessage: ' - ENABLED FOR EVERYONE',
  },
})
