import React from 'react'

import { Spinner } from '@babylon/core-ui'

const renderIfExists = (Component, props) => {
  return Component ? <Component {...props} /> : null
}

const Loader = ({ waitFor, render, ...rest }) => {
  return waitFor ? render() : <Spinner centered size="medium" {...rest} />
}

export const withLoader = (fn, Component) => (WrappedComponent) => (props) =>
  fn(props) ? <WrappedComponent {...props} /> : renderIfExists(Component, props)

export default Loader
