import React from 'react'
import { FormattedDate } from 'react-intl'
import { useHistory } from 'react-router'

import { Spinner, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { getTitleForType, isCovidFlow } from '~/features/chatbot-flow/utils'
import { useConsultationId } from '~/features/consultation'
import { useCpChatscriptConversationQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'

import ChatscriptMessages from '../ChatscriptMessages'

import messages from './ChatscriptMessagesModal.messages'
import styles from './styles.module.scss'

interface ChatscriptMessagesModalProps {
  query: {
    patientUuid: string
    eventId: string
  }
}

const ChatscriptMessagesModal = ({ query }: ChatscriptMessagesModalProps) => {
  const fm = useFormatMessage()
  const consultationId = useConsultationId()
  const { eventId, patientUuid } = query
  const history = useHistory()

  const { data, loading, error, refetch } = useCpChatscriptConversationQuery({
    variables: {
      eventId,
      patientUuid,
    },
  })

  const onClose = () => {
    history.replace(`/consultation/${consultationId}`)
  }

  const { result, startedAt: conversationTime, type: conversationType } =
    data?.chatscriptConversation || {}
  const outcome = result?.type

  const typedTitle = getTitleForType(
    isCovidFlow(outcome) ? 'covid-19' : conversationType,
    fm
  )

  return (
    <Slideout
      title={
        <div className={styles.modalTitle}>
          <div className={styles.title}>
            {typedTitle}
            <Text className={styles.title_meta} size="medium" bold={false}>
              {fm(messages.with_chatbot_meta)}
            </Text>
          </div>

          <Text size="medium">
            <FormattedDate format="shortWithTime" value={conversationTime} />
          </Text>
        </div>
      }
      onClose={onClose}
      loading={loading}
    >
      {() => {
        if (loading) {
          return <Spinner color="#87919e" size="medium" centered />
        }
        if (error) {
          return (
            <ErrorPanel
              error={error}
              fill="container"
              center
              retry={() => {
                refetch()
              }}
              title={fm(messages.could_not_get_conversation_message)}
            />
          )
        }

        return (
          <ChatscriptMessages
            eventId={eventId}
            patientUuid={patientUuid}
            conversationPreview={data?.chatscriptConversation || {}}
          />
        )
      }}
    </Slideout>
  )
}

export default ChatscriptMessagesModal
