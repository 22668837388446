import nanoid from 'nanoid'

// adds a unique id to each item
// we use this to avoid react rendering bugs with arrays of elements that don't
// have keys defined without having to enforce passing unique IDs
const generateIDs = (list) => {
  return list.map((item) => ({
    ...item,
    responsiveTableID: nanoid(),
  }))
}

export default generateIDs
