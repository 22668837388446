import React from 'react'
import { Alert } from '@babylon/core-ui'
import { Tick, Close } from '@babylon/icons'

import styles from './styles.module.scss'
import shared from '../styles.module.scss'

import { generateIndex, matchPatient, validateFieldsPresent } from '../../utils'

const reportRows = [
  {
    key: 'labName',
    label: 'Lab:',
    visibleStates: ['NEW', 'SENT', 'ERROR'],
  },
  {
    key: 'createdAt',
    label: 'Received at:',
    visibleStates: ['NEW', 'SENT', 'ERROR'],
  },
  {
    key: 'reviewedAt',
    label: 'Reviewed at:',
    visibleStates: ['SENT', 'ERROR'],
  },
  {
    key: 'reviewer',
    label: 'Reviewed by:',
    visibleStates: ['SENT', 'ERROR'],
  },
  {
    key: 'sentAt',
    label: 'Sent at:',
    visibleStates: ['SENT'],
  },
  {
    key: 'sender',
    label: 'Sent by:',
    visibleStates: ['SENT'],
  },
  {
    key: 'appointmentSuggested',
    label: 'Appointment suggested:',
    visibleStates: ['SENT'],
  },
]

const patientRows = [
  {
    key: 'name',
    label: 'Name:',
    required: true,
    checkMatch: true,
  },
  {
    key: 'gender',
    label: 'Gender:',
    required: true,
    checkMatch: true,
  },
  {
    key: 'dob',
    label: 'DOB:',
    required: true,
    checkMatch: true,
  },
  {
    key: 'age',
    label: 'Age:',
    required: true,
    checkMatch: true,
  },
  {
    key: 'email',
    label: 'Email:',
    required: true,
    checkMatch: false,
  },
  {
    key: 'phone',
    label: 'Phone:',
    required: false,
    checkMatch: false,
  },
]

export default ({ report, loading, patient, labPatient }) => {
  const requiredFields = patientRows.filter((r) => r.required).map((r) => r.key)
  // checks that the fields that should match between lab / babylon, are matched
  const patientMatched = matchPatient(
    patient,
    labPatient,
    patientRows.filter((r) => r.checkMatch).map((r) => r.key)
  )
  // validates that required fields are present in babbylon patient details
  const validateFields = validateFieldsPresent(patient, requiredFields)
  // combines errors from both checks
  const fieldErrors = [
    ...patientMatched.errors.map((e) => e.key),
    ...validateFields.errors,
  ]

  const filteredReportRows = reportRows.filter((r) =>
    r.visibleStates.includes(report.state)
  )

  return (
    <div>
      {!validateFields.valid && !loading && (
        <Alert
          className={styles.report__alert}
          title="Missing fields in babylon patient details"
          intent="warning"
        >
          {`Missing: ${validateFields.errors.join(', ')}`}
        </Alert>
      )}
      {!patientMatched.matched && !loading && (
        <Alert
          className={styles.report__alert}
          title="Check the patient details"
          intent="warning"
        >
          {`${patientMatched.errors
            .map((e) => e.message)
            .join(
              ' '
            )} If you think this is not the same person, contact Clinops to resolve the issue.`}
        </Alert>
      )}
      <div className={styles.report__header}>
        <div className={`${styles.table} ${styles['table--small']}`}>
          <div className={`${styles.table__column} ${styles.table__labels}`}>
            {filteredReportRows.map((row) => (
              <div key={generateIndex()} className={styles.table__row}>
                {row.label}
              </div>
            ))}
          </div>
          <div className={styles.table__column}>
            {filteredReportRows.map((row) => (
              <div key={generateIndex()} className={styles.table__row}>
                {report[row.key]}
              </div>
            ))}
          </div>
        </div>
        <div className={`${styles.table} ${styles['table--small']}`}>
          <div className={`${styles.table__column} ${styles.table__labels}`}>
            <div className={styles.table__row}>Patient ID:</div>
            {patientRows.map((row) => (
              <div key={generateIndex()} className={styles.table__row}>
                {row.label}
              </div>
            ))}
          </div>
          <div className={styles.table__column}>
            <div className={styles.table__row}>{report.patientId}</div>
            {patientRows.map((row) => (
              <div key={generateIndex()} className={styles.table__row}>
                {patient[row.key]}
                {!loading &&
                  requiredFields.includes(row.key) &&
                  (fieldErrors.includes(row.key) ? (
                    <Close className={shared['icon--error']} />
                  ) : (
                    <Tick className={shared['icon--success']} />
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
