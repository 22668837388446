/* eslint-disable react/no-array-index-key */
import React, { ReactNode, ReactNodeArray } from 'react'
import cn from 'classnames'

import './Table.scss'
import TableCell from './TableCell'
import TableRow from './TableRow'

interface Props {
  children?: ReactNode
  fullWidth?: boolean
  headers?: ReactNodeArray
  striped?: boolean
}

const Table = ({ children, fullWidth, headers = [], striped }: Props) => {
  const className = cn('core-table', {
    'core-table--full-width': fullWidth,
    'core-table--striped': striped,
  })

  return (
    <table className={className}>
      {!!headers.length && (
        <thead className="core-table__head">
          <TableRow>
            {headers.map((header, id) => (
              <TableCell isHeader key={id}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </thead>
      )}
      <tbody className="core-table__body">{children}</tbody>
    </table>
  )
}

export default Table
