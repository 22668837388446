import React from 'react'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import { ConsultationTest } from '~/generated'
import DataList from '~/ui/DataList'

import useTestTypes from './useTestTypes'

import messages from './Tests.messages'

interface TestsSummaryProps {
  tests: ConsultationTest[]
}

const TestsSummary = ({ tests }: TestsSummaryProps) => {
  const f = useMessages(messages)
  const { getTestName } = useTestTypes()

  if (!isFilledArray(tests)) {
    return null
  }

  return (
    <Section title={f('tests_section_title_private')} testid="cr-tests">
      {tests.map((test) => {
        const data = [
          {
            key: f('tests_table_name_heading'),
            value: getTestName(test.type, test.testId),
          },
          {
            key: f('tests_table_type_heading'),
            value: test.type,
          },
          {
            key: f('tests_table_reason_heading'),
            value: test.reason,
          },
        ]

        return <DataList key={test.id} data={data} />
      })}
    </Section>
  )
}

export default TestsSummary
