import React from 'react'

import { FormikTextarea } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './NhsAppointment.messages'

const AppointmentReason = () => {
  const fm = useFormatMessage()

  return (
    <FormikTextarea
      name="reason"
      id={fm(messages.appointment_reason_label)}
      label={fm(messages.appointment_reason_label)}
      placeholder={fm(messages.appointment_reason_helper_text)}
      autoresize
    />
  )
}

export default AppointmentReason
