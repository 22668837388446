import React from 'react'

import styles from './styles.module.scss'

type ProgressBarProps = {
  value: number
  title?: string
  showPercentage?: boolean
  dataTestId?: string
  className?: any
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  title,
  value,
  dataTestId,
  showPercentage,
  className,
}) => {
  return (
    <div className={className}>
      {title && <span className={styles.title}>{title}</span>}
      <div className={styles.progressWrapper}>
        <div className={styles.progressRail}>
          <div
            className={styles.progressBar}
            style={{ width: `${value}%` }}
            data-testid={dataTestId}
          />
        </div>
        {showPercentage && (
          <div className={styles.progressPercentage}>{`${value}%`}</div>
        )}
      </div>
    </div>
  )
}

export default ProgressBar
