import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient timeline',
  },
  future_timeline_title: {
    id: `${baseId}.future_timeline_title`,
    defaultMessage: 'Future',
  },
  future_timeline_loading: {
    id: `${baseId}.future_timeline_loading`,
    defaultMessage: 'Loading future timeline...',
  },
  future_timeline_loading_error: {
    id: `${baseId}.future_timeline_loading_error`,
    defaultMessage: 'Error loading future events in the patient timeline',
  },
  current_past_timeline_title: {
    id: `${baseId}.current_past_timeline_title`,
    defaultMessage: 'Past',
  },
  current_past_timeline_loading: {
    id: `${baseId}.current_past_timeline_loading`,
    defaultMessage: 'Loading past timeline...',
  },
  current_past_timeline_loading_error: {
    id: `${baseId}.current_past_timeline_loading_error`,
    defaultMessage: 'Error loading past events in the patient timeline',
  },
  filter_events_placeholder: {
    id: `${baseId}.filter_events_placeholder`,
    defaultMessage: 'Filter events',
  },
  retry_button_label: {
    id: `${baseId}.retry_button_label`,
    defaultMessage: 'Retry',
  },
  error_loading_patient_history_message: {
    id: `${baseId}.error_loading_patient_history_message`,
    defaultMessage: 'Error loading the patient timeline',
  },
  chatbot_event: {
    id: `${baseId}.chatbot_event`,
    defaultMessage: 'Chatbot',
  },
  consultation_event: {
    id: `${baseId}.consultation_event`,
    defaultMessage: 'Consultation',
  },
  test_result_event: {
    id: `${baseId}.test_result_event`,
    defaultMessage: 'Test result',
  },
})
