import { useCpParticipantsQuery } from '~/generated'

import { PARTICIPANT_TYPES } from './utils'

export interface IGuest {
  guestInCall: boolean
  guestDetails: {
    firstName: string
    lastName: string
  } | null
}

export const useParticipants = (
  id: string,
  multiwayCallingEnabled?: boolean
) => {
  const response = useCpParticipantsQuery({
    variables: { id },
    skip: !id || !multiwayCallingEnabled,
  })

  return response.data?.consultation?.participants || []
}

export const useGuestParticipant = (
  consultationId: string,
  multiwayCallingEnabled?: boolean
): IGuest => {
  const participants = useParticipants(consultationId, multiwayCallingEnabled)
  const guest = participants.find((p) => p.type === PARTICIPANT_TYPES.GUEST)

  const guestData: IGuest = {
    guestInCall: !!guest,
    guestDetails: null,
  }

  if (guest?.participant?.first_name && guest?.participant?.last_name) {
    guestData.guestDetails = {
      firstName: guest.participant.first_name,
      lastName: guest.participant.last_name,
    }
  }

  return guestData
}
