import {
  ERROR_START_VOICE_CALL_ACTION,
  MULTIMEDIA_CATEGORY,
  START_VOICE_CALL_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useEventBus } from '~/core/event-bus'
import { CallStep } from '~/generated'

import useReportCallStep from '../useReportCallStep'

const trackEvent = analytics.trackEventFactory({
  category: MULTIMEDIA_CATEGORY,
})

const useStartVoiceCallMutation = (consultationId: string) => {
  const [reportCallStep, result] = useReportCallStep(consultationId)
  const eventBus = useEventBus()

  const performMutation = async () => {
    try {
      await reportCallStep(CallStep.VoiceCallStarted)

      eventBus.emit('AUDIO_CALL_STARTED')
      trackEvent({ action: START_VOICE_CALL_ACTION })

      return true
    } catch (exception) {
      trackEvent({ action: ERROR_START_VOICE_CALL_ACTION })
      throw exception
    }
  }

  return [performMutation, result] as const
}

export default useStartVoiceCallMutation
