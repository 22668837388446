import React from 'react'
import { MessageDescriptor } from 'react-intl'

import { Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { Dialog } from '~/ui/Modal'

import messages from './CompleteDialogPlugin.messages'

export type CompleteDialogViewProps = {
  isDialogVisible: boolean
  completeError: boolean
  loading: boolean
  errors: MessageDescriptor[]
  okError: boolean
  handleOk: () => void
  handleClose: () => void
  okLabel: string
  title: string
  showDefaultMessage?: boolean
  extensions: React.ReactNode[]
}

export const CompleteDialogViewDefaultMessage = () => {
  const fm = useFormatMessage()

  return (
    <div data-testid="complete-dialog-view-default-message">
      {fm(messages.default_complete_message)}
    </div>
  )
}

export const CompleteDialogView = ({
  isDialogVisible,
  completeError,
  loading,
  errors,
  okError,
  handleOk,
  handleClose,
  okLabel,
  title,
  showDefaultMessage,
  extensions,
}: CompleteDialogViewProps) => {
  const fm = useFormatMessage()

  return isDialogVisible ? (
    <Dialog
      title={title}
      ok
      cancel
      okLoading={loading}
      okError={okError}
      okLabel={okLabel}
      onOk={handleOk}
      onClose={handleClose}
    >
      <Grid gap={16}>
        {showDefaultMessage && <CompleteDialogViewDefaultMessage />}
        {extensions}
        {errors?.map((error, idx) => {
          return (
            <Text key={idx} color="error">
              {fm(error)}
            </Text>
          )
        })}
        {completeError && (
          <Text color="error">{fm(messages.error_message)}</Text>
        )}
      </Grid>
    </Dialog>
  ) : null
}
