/* eslint jsx-a11y/click-events-have-key-events: 0 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { COUNTRY_CODE, SERVICE_NOW_SUPPORT_URL } from '~/constants'
import {
  SUPPORT_CATEGORY,
  SUPPORT_COPY_SESSION_ID_ACTION,
  SUPPORT_LIVE_CHAT_ACTION,
  SUPPORT_MEDICAL_SERVICE_DESK_ACTION,
  SUPPORT_RISK_REPORT_ACTION,
  SUPPORT_TECHNOLOGY_HELP_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { ConsultationContextType } from '~/core/config'
import { useFeatureFlags, useGlobals } from '~/core/core-modules'
import { useToggle } from '~/core/hooks'
import { BRAND_COLOR_TURQUOISE } from '~/styles/theme/tokens'
import { Dropdown, DropdownLink, DropdownLinkTitle } from '~/ui/Dropdown'

import CopySessionIdButton from './CopySessionIdButton'
import {
  faHeadphones,
  faQuestionCircle,
  faShieldCheck,
  faStethoscope,
  faTv,
} from './icons'
import { useLoadScript } from './useLoadScript'
import { useServiceNowChatBubble } from './useServiceNowChatBubble'
import * as zendesk from './zendesk'

import styles from './styles.module.scss'
import messages from './Support.messages'

export interface SupportProps {
  options: string[]
  clinOpsChatDepartment: string | undefined
  clinicalServiceDeskUrl: string | undefined
  itServiceDeskUrl: string | undefined
  riskManagementSystemUrl: string | undefined
  consultationContext: ConsultationContextType
}

export const Support = ({
  options = [],
  clinOpsChatDepartment,
  clinicalServiceDeskUrl,
  itServiceDeskUrl,
  riskManagementSystemUrl,
  consultationContext,
}: SupportProps) => {
  const [visible, toggleDropdownVisible] = useToggle(false)

  const {
    serviceNowSupportEnabled,
    serviceNowChatBubbleEnabled,
    salesforceSupportEnabled,
  } = useFeatureFlags()

  const { salesforce } = useGlobals()

  const isZendeskReady = zendesk.useZendeskChat()

  useLoadScript({
    id: 'salesforce-chat-script',
    src: `${salesforce?.endpointUrl}/assets/js/bootstrap.min.js`,
    removeOnUnmount: false,
    onReady: () => {
      if (window.embeddedservice_bootstrap?.settings) {
        window.embeddedservice_bootstrap.settings.language =
          salesforce?.language
        window.embeddedservice_bootstrap.init(
          salesforce?.organizationId,
          salesforce?.deploymentId,
          salesforce?.endpointUrl,
          {
            scrt2URL: salesforce?.scrt2Url,
          }
        )
      }
    },
  })

  // we only need to wait for zendesk to load
  const isChatLoading: boolean = serviceNowSupportEnabled
    ? false
    : !isZendeskReady

  const chat = useServiceNowChatBubble(
    serviceNowSupportEnabled && serviceNowChatBubbleEnabled
  )

  const fm = useFormatMessage()

  const openServiceNowSupport = () => {
    if (chat?.open) {
      chat.open()
      return
    }
    if (typeof window !== 'undefined') {
      window?.open?.(SERVICE_NOW_SUPPORT_URL, '_blank')?.focus?.()
    }
  }

  const updateZendeskDepartment = () => {
    if (clinOpsChatDepartment) {
      zendesk.setDepartment(
        Number(clinOpsChatDepartment) || clinOpsChatDepartment
      )
    }
  }

  const openSupport = () => {
    if (serviceNowSupportEnabled) {
      openServiceNowSupport()
    } else {
      updateZendeskDepartment()
      zendesk.show()
    }
  }

  const showClinopsHelp =
    options.includes('clinops') && !salesforceSupportEnabled

  const showRiskManagement =
    options.includes('risk-management') &&
    riskManagementSystemUrl &&
    COUNTRY_CODE === 'uk' // TODO: Remove COUNTRY_CODE == 'uk' when migrated to product-config in CA

  const showSeniorMedicalTeamHelp =
    options.includes('clinical') &&
    clinicalServiceDeskUrl &&
    COUNTRY_CODE === 'uk' // TODO: Remove COUNTRY_CODE == 'uk' when migrated to product-config in CA

  const showTechnologyHelp =
    options.includes('technology-help') && itServiceDeskUrl

  return (
    <div className={styles.container} data-testid="support-container">
      <div
        role="button"
        tabIndex={0}
        className={styles.profile}
        onClick={() => {
          toggleDropdownVisible(true)
        }}
      >
        <FontAwesomeIcon
          title={fm(messages.header_button_title)}
          fixedWidth
          icon={faQuestionCircle}
        />
      </div>

      {visible && (
        <Dropdown
          width={400}
          onClickOutside={() => toggleDropdownVisible(false)}
          data-testid="support-dropdown"
        >
          {showClinopsHelp && (
            <DropdownLink
              dataTestId="live-chat-option"
              title={
                <DropdownLinkTitle
                  title={fm(messages.live_chat_title)}
                  description={fm(messages.live_chat_description)}
                />
              }
              loading={isChatLoading}
              icon={faHeadphones}
              iconColor={BRAND_COLOR_TURQUOISE}
              onClick={() => {
                analytics.trackEvent({
                  category: SUPPORT_CATEGORY,
                  action: SUPPORT_LIVE_CHAT_ACTION,
                })
                openSupport()
                toggleDropdownVisible(false)
              }}
            />
          )}
          {showSeniorMedicalTeamHelp && (
            <DropdownLink
              dataTestId="senior-medical-team-option"
              title={
                <DropdownLinkTitle
                  title={fm(messages.senior_clinical_team_title)}
                  description={fm(messages.senior_clinical_team_description)}
                />
              }
              icon={faStethoscope}
              iconColor={BRAND_COLOR_TURQUOISE}
              onClick={() => {
                analytics.trackEvent({
                  category: SUPPORT_CATEGORY,
                  action: SUPPORT_MEDICAL_SERVICE_DESK_ACTION,
                })

                window.open(clinicalServiceDeskUrl, '_blank')
              }}
            />
          )}
          {showRiskManagement && (
            <DropdownLink
              dataTestId="ulysses-option"
              title={
                <DropdownLinkTitle
                  title={fm(messages.report_risk_title)}
                  description={fm(messages.report_risk_description)}
                />
              }
              icon={faShieldCheck}
              iconColor={BRAND_COLOR_TURQUOISE}
              onClick={() => {
                analytics.trackEvent({
                  category: SUPPORT_CATEGORY,
                  action: SUPPORT_RISK_REPORT_ACTION,
                })

                window.open(riskManagementSystemUrl, '_blank')
              }}
            />
          )}
          {showTechnologyHelp && (
            <>
              <DropdownLink
                dataTestId="technology-help-option"
                title={
                  <DropdownLinkTitle
                    title={fm(messages.technology_help_title)}
                    description={fm(messages.technology_help_description)}
                  />
                }
                icon={faTv}
                iconColor={BRAND_COLOR_TURQUOISE}
                onClick={() => {
                  analytics.trackEvent({
                    category: SUPPORT_CATEGORY,
                    action: SUPPORT_TECHNOLOGY_HELP_ACTION,
                  })

                  window.open(itServiceDeskUrl, '_blank')
                }}
              />
              <div style={{ padding: 4 }}>
                <CopySessionIdButton
                  onClick={() => {
                    analytics.trackEvent({
                      category: SUPPORT_CATEGORY,
                      action: SUPPORT_COPY_SESSION_ID_ACTION,
                    })
                  }}
                  consultationContext={consultationContext}
                />
              </div>
            </>
          )}
        </Dropdown>
      )}
    </div>
  )
}

const SupportWithConfig = ({
  options,
  clinOpsChatDepartment,
  consultationContext,
}: Pick<
  SupportProps,
  'options' | 'clinOpsChatDepartment' | 'consultationContext'
>) => {
  const { support } = useGlobals()
  const { clinicalServiceDeskUrl, itServiceDeskUrl, riskManagementSystemUrl } =
    support || {}

  return (
    <Support
      options={options}
      clinOpsChatDepartment={clinOpsChatDepartment}
      clinicalServiceDeskUrl={clinicalServiceDeskUrl}
      itServiceDeskUrl={itServiceDeskUrl}
      riskManagementSystemUrl={riskManagementSystemUrl}
      consultationContext={consultationContext}
    />
  )
}

export default SupportWithConfig
