import cn from 'classnames'
import Tooltip from 'rc-tooltip'
import React from 'react'
import { MessageDescriptor } from 'react-intl'

import { presetStyles, Tag } from '@babylon/core-ui'

import { ReactComponent as IconAlert } from '~/assets/alert.svg'
import { ReactComponent as IconAttachment } from '~/assets/attachment.svg'
import { ReactComponent as IconIncomplete } from '~/assets/incomplete_cons.svg'
import { ENABLE_TRANSLATION_REQUESTED } from '~/constants'
import { generateIndex, isFilledArray } from '~/core'
import { PatientAlert } from '~/generated'

import messages from './ConsultationScheduleItem.messages'
import styles from './styles.module.scss'

export enum ConsultationTypes {
  video = 'video',
  text = 'text',
  audio = 'voice',
  f2f = 'physical',
}

export enum ScheduleStatus {
  upcoming = 'upcoming',
  due = 'due',
  incomplete = 'incomplete',
  complete = 'complete',
  noShow = 'noShow',
}

export const leftIconMap = {
  due: styles.leftIcon,
  upcoming: styles.leftIcon,
  incomplete: styles.incompleteLeftIcon,
  complete: styles.completeLeftIcon,
  noShow: styles.noShowLeftIcon,
  live: styles.liveLeftIcon,
}

export const getLeftIconStyle = (scheduleStatus: ScheduleStatus) => {
  return leftIconMap[scheduleStatus]
}

const addTooltipToComponent = (
  tooltipMessage: React.ReactNode,
  component: React.ComponentProps<typeof Tooltip>['children']
) => {
  return tooltipMessage ? (
    <Tooltip
      transitionName="tooltip-fade"
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
      trigger={['hover']}
      overlay={tooltipMessage}
      placement="top"
      key={generateIndex()}
    >
      {component}
    </Tooltip>
  ) : (
    component
  )
}
type AlertListProps = {
  formatMessage: (key: MessageDescriptor) => string
  scheduleStatus: ScheduleStatus
  patientAlerts?: Maybe<PatientAlert>[]
  minor?: Maybe<boolean>
  languageRequested?: Maybe<string>
  hasPatientImage?: boolean | null
  showRecentIncompleteConsultations: boolean
}

type TagPreset = typeof presetStyles[number]
type IconPreset = 'red' | 'grey' | 'light-grey' | 'white' | 'inherit'

type AlertStyles = {
  patientAlerts: IconPreset
  pastIncomplete: IconPreset
  imageAttachment: IconPreset
  minor: TagPreset
  languageRequested: TagPreset
}

export const createAlertList = ({
  formatMessage,
  scheduleStatus,
  patientAlerts,
  minor,
  languageRequested,
  hasPatientImage,
  showRecentIncompleteConsultations,
}: AlertListProps) => {
  const isCompleteOrIncomplete =
    scheduleStatus === ScheduleStatus.incomplete ||
    scheduleStatus === ScheduleStatus.complete
  const isNoShow = scheduleStatus === ScheduleStatus.noShow

  const alertStyles: AlertStyles = isCompleteOrIncomplete
    ? {
        patientAlerts: 'grey',
        pastIncomplete: 'grey',
        imageAttachment: 'grey',
        minor: 'white-on-type-grey',
        languageRequested: 'white-on-type-grey',
      }
    : isNoShow
    ? {
        patientAlerts: 'light-grey',
        pastIncomplete: 'light-grey',
        imageAttachment: 'light-grey',
        minor: 'white-on-light-grey',
        languageRequested: 'white-on-light-grey',
      }
    : {
        patientAlerts: 'red',
        pastIncomplete: 'inherit',
        imageAttachment: 'grey',
        minor: 'clinical-orange',
        languageRequested: 'clinical-orange',
      }

  const alerts = [
    isFilledArray(patientAlerts) &&
      addTooltipToComponent(
        formatMessage(messages.alert_tooltip),
        <IconAlert
          className={cn(styles.alert, styles[alertStyles.patientAlerts])}
        />
      ),
    showRecentIncompleteConsultations &&
      addTooltipToComponent(
        formatMessage(messages.incomplete_icon_tooltip),
        <IconIncomplete
          className={cn(styles.alert, styles[alertStyles.pastIncomplete])}
          data-testid="incomplete-alert-icon"
        />
      ),
    hasPatientImage &&
      addTooltipToComponent(
        formatMessage(messages.attachment_tooltip),
        <IconAttachment
          className={cn(styles.alert, styles[alertStyles.imageAttachment])}
          data-testid="attachment-alert-icon"
        />
      ),
    minor && (
      <Tag
        className={styles.alert}
        key={generateIndex()}
        color={alertStyles.minor}
      >
        {formatMessage(messages.minor)}
      </Tag>
    ),
    ENABLE_TRANSLATION_REQUESTED && languageRequested && (
      <Tag
        className={styles.alert}
        key={generateIndex()}
        color={alertStyles.languageRequested}
      >
        {formatMessage(messages.interpreter)}
      </Tag>
    ),
  ].filter(Boolean)

  return alerts
}
