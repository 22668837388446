import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { TestsSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useTestsQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import TestsSummary from './TestsSummary'

import messages from './Tests.messages'

const TestsSummaryModule: TestsSummaryPluginInterface = () => {
  const TestsSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const fm = useFormatMessage()
    const { data, loading, error, refetch } = useTestsQuery({
      variables: { consultationId: consultation.id },
    })

    if (loading) {
      return null
    }

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={fm(messages.error_loading_tests)}
          retry={() => refetch()}
        />
      )
    }

    const pathologyTests = data?.consultation?.pathologyTests || []
    const diagnosticTests = data?.consultation?.diagnosticTests || []

    const tests = [...pathologyTests, ...diagnosticTests]

    return <TestsSummary tests={tests} />
  }
  return TestsSummaryWrapper
}

export default TestsSummaryModule
