import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.attachments'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Attachments',
  },
  no_attachments_avaliable_message: {
    id: `${baseId}.no_attachments_avaliable_message`,
    defaultMessage: 'No attachments available',
  },
})
