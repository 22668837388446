import * as Sentry from '@sentry/browser'

import { ConsultantUser } from '@babylon/babylon-user'

import { SENTRY_URL } from '~/constants'
import requestIdGenerator from '~/core/request-id-generator'

const whitelistedEmail = (
  email: string | null | undefined
): string | undefined => {
  if (!email) {
    return undefined
  }

  const emailDomainWhitelist = [
    '@babylonpartners.com',
    '@babylonhealth.com',
    '@babylon.com',
  ] as const

  if (emailDomainWhitelist.some((v) => email.endsWith(v))) {
    return email
  }
  return undefined
}

const setSentryUserContext = (user: ConsultantUser): void => {
  if (SENTRY_URL && user) {
    const roles = user.roles.map((v) => v.name).join(', ')
    const sessionToken = requestIdGenerator.sessionToken()
    const userToken = requestIdGenerator.userToken()
    const consultantId = user.consultant?.id

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        email: whitelistedEmail(user.consultant.email),
        consultantId,
        timezone: user.timezone,
        roles,
        userToken,
        sessionToken,
      })

      // Adding separate tags for these values because
      // setting them on the user above is not searchable
      scope.setTag('user_token', userToken)
      scope.setTag('session_token', sessionToken)
      scope.setTag('consultant_id', consultantId)
    })
  }
}

export default setSentryUserContext
