import React from 'react'

import { FormikCheckbox, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import TooltipLabel from '~/ui/Label'

import messages from '../NhsAppointment.messages'

const AppointmentChaperone = () => {
  const fm = useFormatMessage()

  return (
    <div>
      <TooltipLabel
        value={fm(messages.intimate_appointment_label)}
        info={fm(messages.intimate_appointment_tooltip)}
        infoPlacement="right"
        overlayStyle={{ width: 300, whiteSpace: 'normal' }}
      />
      <Text tag="div" style={{ marginTop: 6 }}>
        <FormikCheckbox
          id="intimate"
          name="intimate"
          dataTestId="intimate"
          inline
        >
          {fm(messages.intimate_appointment)}
        </FormikCheckbox>
      </Text>
    </div>
  )
}

export default AppointmentChaperone
