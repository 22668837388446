import { gql } from '@apollo/client'

export const AuditQuery = gql`
  query PrescriptionAuditQuery($prescriptionId: ID!, $eventTypes: [String]) {
    prescriptionAudit(
      prescriptionId: $prescriptionId
      eventTypes: $eventTypes
    ) {
      id
      babylonRequestId
      consumerNetwork
      eventDetails
      eventTimestamp
      loginType
      eventType
      performedByName
    }
  }
`
