export const RESPONSIVE_BEHAVIOUR_OPTIONS = [
  'scroll',
  'collapseRows',
  // 'collapseColumns',
]

export const DEFAULT_COLLAPSE_BREAKPOINT = 400 //pixels

export default {
  RESPONSIVE_BEHAVIOUR_OPTIONS,
  DEFAULT_COLLAPSE_BREAKPOINT,
}
