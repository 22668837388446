import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose, withHandlers, withState } from 'recompose'

import { Alert, Card, PopoverCard, Heading } from '@babylon/core-ui'
import { Back } from '@babylon/icons'

import { PanelQuery } from '../../queries'
import { UpdatePanelMutation } from '../../mutations'
import { mapPatient, mapPanel } from '../../utils/mapData'
import { mapComments, clearComments } from '../../utils/profileComments'
import { eventLogger } from '../../utils'

import styles from './styles.module.scss'
import shared from '../styles.module.scss'

import Header from './Header'
import Results from './Results'
import Actions from './Actions'

const enhance = compose(
  withState('submitting', 'setSubmitting', false),
  withState('submitError', 'setSubmitError', null),
  graphql(PanelQuery, {
    options: (props) => ({
      variables: {
        id: props.match.params.id,
      },
      onError: (error) => eventLogger.error('report fetch failed', error),
      fetchPolicy: 'no-cache',
    }),
  }),
  graphql(UpdatePanelMutation, {
    name: 'updatePanel',
    options: ({ setSubmitting, setSubmitError, onCloseReport, data }) => ({
      onError: (error) => {
        eventLogger.error('report update failed', error)
        setSubmitting(false)
        setSubmitError(error)
      },
      onCompleted: () => {
        data.getPanel ? clearComments(data.getPanel.id) : null
        setSubmitting(false)
        onCloseReport({ snackbar: true })
      },
    }),
  }),
  withHandlers({
    onUpdatePanel: (props) => (event) => {
      props.setSubmitting(true)
      props.setSubmitError(null)

      const id = props.data.getPanel.id

      const version = props.data.getPanel.version

      props.updatePanel({
        variables: {
          id,
          version,
          profileComments: mapComments(id),
          state: event.selectedAction.actionState,
          appointmentSuggested: event.selectedAction.suggestAppointment,
          reviewerComments: event.reviewerComments,
          supportMessage: event.supportMessage,
        },
      })
    },
    clearSubmitError: (props) => () => props.setSubmitError(false),
  })
)

const Report = ({
  data,
  onUpdatePanel,
  onCloseReport,
  submitting,
  submitError,
  clearSubmitError,
}) => {
  const response = data.getReport || data.getPanel
  const report = response || {}

  const showGpComments = !!report.reviewerComments
  const showProfileCommentForm =
    report.state !== 'SENT' && !data.loading && !data.error
  const showActions = report.state !== 'SENT' && !data.loading && !data.error
  const showEmail = report.state === 'ERROR' && !!report.supportMessage

  const { patient, labPatient } = report

  return (
    <div className={styles.report}>
      <a // eslint-disable-line
        href="#"
        className={styles.report__link}
        onClick={onCloseReport}
      >
        <Back className={shared['icon--primary']} />
        {'  '}Back to all reports
      </a>
      {report.name && (
        <>
          <Card padding="lg">
            <Heading>{report.name}</Heading>
            <Header
              loading={data.loading}
              report={mapPanel(report)}
              labPatient={labPatient ? mapPatient(labPatient) : {}}
              patient={patient ? mapPatient(patient) : {}}
            />
          </Card>
        </>
      )}
      {showGpComments && (
        <Card padding="lg">
          <Heading>GP Comments</Heading>
          <Alert
            className={styles[('report__word-break', 'report__alert')]}
            padding={10}
          >
            {report.reviewerComments}
          </Alert>
        </Card>
      )}
      {showEmail && (
        <Card padding="lg">
          <Heading data-testid="email-card">Email sent to ClinOps</Heading>
          <PopoverCard className={styles['report__word-break']}>
            {report.supportMessage}
          </PopoverCard>
        </Card>
      )}
      <Results
        loading={data.loading}
        error={data.error}
        report={report}
        showProfileCommentForm={showProfileCommentForm}
      />
      {showActions && (
        <Card padding="lg">
          <Actions
            reportState={report.state}
            submitting={submitting}
            submitError={submitError}
            onUpdatePanel={onUpdatePanel}
            clearSubmitError={clearSubmitError}
          />
        </Card>
      )}
    </div>
  )
}

export default enhance(Report)
