import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from '~/ui/LegacyModal/styles.module.scss'

const ESC_CODE = 27
class RouteModal extends Component {
  handleModalClick = (event) => {
    event.stopPropagation()
  }

  handleEscKeyPress = (event) => {
    if (event.keyCode === ESC_CODE) {
      this.props.onRequestClose(event)
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleEscKeyPress)
    document.querySelector('body').classList.add(styles.bodyOverlay)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleEscKeyPress)
    document.querySelector('body').classList.remove(styles.bodyOverlay)
  }

  render() {
    const { closeButton, onRequestClose, children } = this.props

    return (
      <div className={styles.overlay} onClick={onRequestClose}>
        <div
          className={styles.modal}
          data-testid="modal"
          onClick={this.handleModalClick}
        >
          {children}
        </div>
        {closeButton && (
          <div
            className={styles.closeButton}
            role="button"
            tabIndex="0"
            onClick={onRequestClose}
          >
            <i className="fas fa-times" />
          </div>
        )}
      </div>
    )
  }
}

RouteModal.defaultProps = {
  closeButton: true,
  onRequestClose: null,
}

RouteModal.propTypes = {
  closeButton: PropTypes.bool,
  onRequestClose: PropTypes.func,
}

export default RouteModal
