import React from 'react'

import { CarePlanSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useConsultationId } from '~/features/consultation'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useCarePlanReviewBaseQuery } from '~/generated'

import CarePlansSummary from './CarePlansSummary'

const CarePlansSummaryModule: CarePlanSummaryPluginInterface = () => {
  const CarePlansSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const {
      carePlanEnabled,
      carePlanInPatientHistoryEnabled,
    } = useFeatureFlags()
    const currentConsultationId = useConsultationId()
    const isCurrentConsultation = currentConsultationId === consultation.id
    const displayCarePlanSummary = isCurrentConsultation
      ? carePlanEnabled
      : carePlanInPatientHistoryEnabled

    const { data, loading, error } = useCarePlanReviewBaseQuery({
      variables: { id: consultation.id },
      skip: !displayCarePlanSummary,
    })

    if (loading || error || !displayCarePlanSummary) {
      return null
    }

    const { status, uuid, patient } = data?.consultation || {}

    const isComplete = status !== 'paid'

    if (!uuid || !patient?.uuid) {
      return null
    }

    return (
      <CarePlansSummary
        isComplete={isComplete}
        consultationUuid={uuid}
        patientUuid={patient.uuid}
      />
    )
  }

  return CarePlansSummaryWrapper
}

export default CarePlansSummaryModule
