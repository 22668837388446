const supportedTranslationLanguages = {
  sq: 'Albanian',
  asl: 'American Sign Language',
  ar: 'Arabic',
  hy: 'Armenian',
  bn: 'Bengali',
  my: 'Burmese',
  yue: 'Cantonese',
  en: 'English',
  fa: 'Farsi',
  fr: 'French',
  de: 'German',
  el: 'Greek',
  ht: 'Haitian Creole',
  he: 'Hebrew',
  hi: 'Hindi',
  hmn: 'Hmong',
  it: 'Italian',
  ja: 'Japanese',
  ksw: 'Karen',
  km: 'Khmer',
  ko: 'Korean',
  lo: 'Laotian',
  lt: 'Lithuanian',
  ms: 'Malay',
  zh: 'Mandarin',
  ne: 'Nepali',
  pl: 'Polish',
  pt: 'Portuguese',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  so: 'Somali',
  es: 'Spanish',
  tl: 'Tagalog',
  th: 'Thai',
  tr: 'Turkish',
  vi: 'Vietnamese',
  und: 'Other',
}

export default supportedTranslationLanguages
