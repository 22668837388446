import React from 'react'

import { Spinner } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import CodedTextWithClinicalCodes from '~/features/clinical-coding/CodedTextWithClinicalCodes'
import {
  isEmptyPrimitive,
  Section,
} from '~/features/consultation-summary/ConsultationSummary'
import { ConsultationNote } from '~/generated'

import messages from './ExaminationSummary.messages'

interface ExaminationSummaryProps {
  consultationNote: Pick<
    ConsultationNote,
    | 'toldByPatient'
    | 'toldByPatientCodes'
    | 'exploredByGp'
    | 'exploredByGpCodes'
  >
  loading?: boolean
}

const ExaminationSummary = ({
  consultationNote,
  loading = false,
}: ExaminationSummaryProps) => {
  const f = useMessages(messages)

  const {
    toldByPatient,
    toldByPatientCodes,
    exploredByGp,
    exploredByGpCodes,
  } = consultationNote

  if (loading) {
    return (
      <>
        <Section title={f('what_you_told_use_section_title')}>
          <Spinner size="small" testid="examination-summary-loading" />
        </Section>
        <Section title={f('what_we_explored_section_title')}>
          <Spinner size="small" testid="examination-summary-loading" />
        </Section>
      </>
    )
  }

  return (
    <>
      {!isEmptyPrimitive(toldByPatient) && (
        <Section
          title={f('what_you_told_use_section_title')}
          testid="cr-told-by-patient"
        >
          <CodedTextWithClinicalCodes
            text={toldByPatient}
            codes={toldByPatientCodes || []}
          />
        </Section>
      )}
      {!isEmptyPrimitive(exploredByGp) && (
        <Section
          title={f('what_we_explored_section_title')}
          testid="cr-explored-by-gp"
        >
          <CodedTextWithClinicalCodes
            text={exploredByGp}
            codes={exploredByGpCodes || []}
          />
        </Section>
      )}
    </>
  )
}

export default ExaminationSummary
