import { useEffect, useState } from 'react'

import { logException } from '../sentry'

const serialize = JSON.stringify
const deserialize = JSON.parse

/**
 * Warning - localStorage is not cleared between sessions so will persist across
 * different user accounts unless cleared/namespaced appropriately
 * TODO: refactor onboarding to prevent bugs cause by this
 */

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    try {
      const localStorageValue = localStorage.getItem(key)
      if (localStorageValue !== null) {
        return deserialize(localStorageValue)
      }

      if (initialValue) {
        localStorage.setItem(key, serialize(initialValue))
      }

      return initialValue
    } catch (e) {
      logException(e)
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue
    }
  })

  useEffect(() => {
    try {
      localStorage.setItem(key, serialize(state))
    } catch (e) {
      logException(e)
      // If user is in private mode or has storage restriction
      // localStorage can throw. Also JSON.stringify can throw.
    }
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  return [state, setState]
}

export default useLocalStorage
