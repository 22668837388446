import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { Tag, Text } from '@babylon/core-ui'

import { scrollTo, useScrollContext } from '~/core/scroll-tracker'
import { Consultation } from '~/generated'

import ScrollMenu from './ScrollMenu'

import messages from './ScrollHeader.messages'
import styles from './styles.module.scss'

interface ScrollHeaderProps {
  consultations: Pick<Consultation, 'id' | 'scheduledTime'>[]
  code?: string
  note?: string
  className?: string
  highlightedEvent: string
}
const ScrollHeader = ({
  consultations,
  code,
  note,
  className,
  highlightedEvent,
}: ScrollHeaderProps) => {
  // @ts-expect-error
  const { activeKey } = useScrollContext()
  const [clicked, setClicked] = useState('')

  useEffect(() => {
    setClicked(highlightedEvent)
  }, [highlightedEvent])

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked('')
      }, 1000)
    }
  }, [clicked])

  return (
    <div
      className={cn(styles.sticky, className)}
      data-testid="problems-list-modal-header"
    >
      <Text tag="div" color="grey-type" className={styles.headerText}>
        <FormattedMessage
          {...messages.info}
          values={{
            numConsults: consultations.length,
            code: code ? (
              <Tag className={styles.clinicalCode} uppercase={false}>
                {code}
              </Tag>
            ) : (
              <div style={{ fontWeight: 900, display: 'inline' }}>
                {`"${note}"`}
              </div>
            ),
          }}
        />
      </Text>

      {consultations.length > 1 && (
        <ScrollMenu
          highlightedItem={clicked || activeKey}
          items={consultations.map((item) => {
            return {
              component: (
                <div
                  className={styles.consultationItem}
                  onClick={() => {
                    scrollTo(item.id)
                    setClicked(item.id)
                  }}
                >
                  <div className={cn(styles.line)} />
                  <Text
                    className={styles.dateText}
                    tag="div"
                    data-testid={`item-text-${item.id}`}
                    color="grey-type"
                  >
                    <FormattedDate value={item.scheduledTime} format="short" />
                  </Text>
                </div>
              ),
              id: item.id,
            }
          })}
        />
      )}
    </div>
  )
}

export default ScrollHeader
