import React from 'react'
import Rater from 'react-rater'

import { useFormatMessage } from '@babylon/intl'

import { useCpConsultantRatingQuery } from '~/generated'
import Tooltip from '~/ui/Tooltip'

import messages from './ClinicianRating.messages'

interface ClinicianRatingProps {
  consultantId: string
}

const ClinicianRating = ({ consultantId }: ClinicianRatingProps) => {
  const fm = useFormatMessage()

  const { data } = useCpConsultantRatingQuery({
    variables: {
      id: consultantId,
    },
  })

  const rating = data?.consultant?.rating?.average_rating

  return (
    <Tooltip message={fm(messages.rating, { rating })} hidden={!rating}>
      <div>
        <Rater
          interactive={false}
          rating={rating || 0}
          data-testid="consultant-rating"
        />
      </div>
    </Tooltip>
  )
}

export default ClinicianRating
