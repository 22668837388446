import * as rules from './rules'

const validators = [
  rules.validateRequired,
  rules.validateSingleType,
  rules.validateEmpty,
  rules.validateCallback,
]

export const validateShallowObject = (object, schema) => {
  if (!schema) {
    throw 'Schema object is required to use "validateShallowObject"'
  }

  const keys = Object.keys(schema)
  const errors = []

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    for (var j = 0; j < validators.length; j++) {
      const error = validators[j](object[key], schema[key])

      if (error) {
        errors.push({ key, ...error })
      }
    }
  }

  return errors
}

export const mapEmptyKeys = (object, mapping) => {
  return validateShallowObject(object)
    .map((v) => v.reason === 'empty')
    .map((v) => mapping[v.key])
}
