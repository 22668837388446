import { Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

import { CONSULTATION_STATUSES } from '~/constants'
import { MarkAsSensitiveModelInterface } from '~/core/config/modules/generated/types'
import {
  markEHRNotesSensitiveMutation,
  observableState,
} from '~/core/reactive-helpers'
import { logException } from '~/core/sentry'

import {
  CompleteConsultationExtensionType,
  ExtensionStateType,
  ExtensionStatus,
} from './CompleteConsultationModel'

import messages from './MarkAsSensitivePlugin/MarkAsSensitivePlugin.messages'

export interface MarkAsSensitiveStateType extends ExtensionStateType {
  isMinor: boolean
  isConsultationIncomplete: boolean
  sensitive: boolean | null
  noSelectionError: boolean
}

export interface MarkAsSensitiveModelType
  extends CompleteConsultationExtensionType {
  state: Observable<MarkAsSensitiveStateType>

  // api
  updateSensitive: (sensitive: boolean) => void
}

const defaultState: MarkAsSensitiveStateType = {
  status: ExtensionStatus.Initializing,
  isMinor: false,
  isConsultationIncomplete: false,
  sensitive: false,
  noSelectionError: false,
  errorMessage: undefined,
}

export const createMarkAsSensitiveModel = (
  markEHRNotesSensitive: typeof markEHRNotesSensitiveMutation
): MarkAsSensitiveModelInterface => ({
  // TODO:  convert it to a model
  consultationContext,
}) => {
  const [
    state,
    updateState,
    resetState,
  ] = observableState<MarkAsSensitiveStateType>(defaultState)

  // TODO: convert this to proper RxJS stream
  let isMinor: boolean
  let consultationId: string

  const reset = () => {
    resetState()
    isMinor = false
    consultationId = ''
  }

  consultationContext.subscribe((result) => {
    const { consultation } = result.data

    if (consultation) {
      isMinor = Boolean(consultation?.patient.minor)
      consultationId = consultation?.id || ''

      updateState({
        isMinor,
        isConsultationIncomplete:
          consultation?.status === CONSULTATION_STATUSES.PAID,
      })
    } else {
      reset()
    }
  })
  // END of TODO

  const init = () => {
    updateState({
      sensitive: isMinor ? null : false,
      status: ExtensionStatus.Initialized,
      errorMessage: undefined,
    })
  }

  const submit = () => {
    state
      .pipe(
        take(1),
        filter(({ sensitive, isConsultationIncomplete }) => {
          if (!isConsultationIncomplete || sensitive === false) {
            updateState({
              status: ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
            return false
          }
          if (sensitive === null) {
            updateState({ noSelectionError: true })
            return false
          }
          return true
        }),
        map(() => ({
          variables: {
            appointmentId: consultationId,
          },
        })),
        markEHRNotesSensitive()
      )
      .subscribe({
        next: ({ error, loading }) => {
          if (error) {
            logException(error)
            updateState({
              status: ExtensionStatus.SubmitError,
              errorMessage: messages.error_marking_as_sensitive,
            })
          } else {
            updateState({
              status: loading
                ? ExtensionStatus.Submitting
                : ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          logException(error)
          updateState({
            status: ExtensionStatus.SubmitError,
            errorMessage: messages.error_marking_as_sensitive,
          })
        },
      })
  }

  const updateSensitive = (sensitive: boolean) => {
    updateState({
      status: ExtensionStatus.Initialized,
      errorMessage: undefined,
      noSelectionError: false,
      sensitive,
    })
  }

  return {
    state,
    init,
    submit,
    updateSensitive,
  }
}

export const MarkAsSensitiveModel = createMarkAsSensitiveModel(
  markEHRNotesSensitiveMutation
)
