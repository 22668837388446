import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.prescriptions'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private prescriptions',
  },
  add_drug_button_label: {
    id: `${baseId}.add_drug_button_label_v2`,
    defaultMessage: 'Add drug',
  },
  suggest_prescription_button_label: {
    id: `${baseId}.suggest_prescription_button_label_v2`,
    defaultMessage: 'Suggest prescription',
  },
  suggest_prescription_tooltip_message: {
    id: `${baseId}.suggest_prescription_tooltip_message`,
    defaultMessage:
      'Based on the clinical code you entered, these prescriptions are suggested',
  },
  confirm_delete_dialog_message: {
    id: `${baseId}.confirm_delete_dialog_message`,
    defaultMessage: 'Are you sure you want to delete the drug?',
  },
  confirm_delete_dialog_remove_button_label: {
    id: `${baseId}.confirm_delete_dialog_remove_button_label`,
    defaultMessage: 'Remove',
  },
  confirm_delete_dialog_cancel_button_label: {
    id: `${baseId}.confirm_delete_dialog_cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  fetch_error: {
    id: `${baseId}.fetch_error`,
    defaultMessage: 'Error fetching prescriptions',
  },
})
