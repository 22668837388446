import React from 'react'

import styles from './styles.module.scss'

interface LayoutProps {
  main: React.ReactNode
}

export const Layout = ({ main }: LayoutProps) => (
  <div className={styles.layout}>{main}</div>
)
