import { defineMessages } from 'react-intl'

const baseId = 'patient_alert_modal'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient alert',
  },
  delete_title: {
    id: `${baseId}.delete_title`,
    defaultMessage: 'Are you sure you want to delete the alert?',
  },
  delete_remove_button_label: {
    id: `${baseId}.delete_remove_button_label`,
    defaultMessage: 'Remove',
  },
  delete_cancel_button_label: {
    id: `${baseId}.delete_cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  no_alert_error: {
    id: `${baseId}.no_alert_error`,
    defaultMessage: 'Member "{patientId}" does not have alert "{alertId}".',
  },
})
