import { defineMessages } from 'react-intl'

const baseId = 'shared.select_dropdown.defaults'

export default defineMessages({
  clear_all_text: {
    id: `${baseId}.clear_all_text`,
    defaultMessage: 'Clear all',
  },
  no_results_text: {
    id: `${baseId}.no_results_text`,
    defaultMessage: 'No results found',
  },
  search_prompt_text: {
    id: `${baseId}.search_prompt_text`,
    defaultMessage: 'Type to search',
  },
  clear_value_title_text: {
    id: `${baseId}.clear_value_title_text`,
    defaultMessage: 'Clear value',
  },
})
