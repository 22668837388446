import get from 'lodash/get'
import pick from 'lodash/pick'

import { SelectOptionType } from '@babylon/core-ui'

import {
  CpWorkflowContextQuery,
  WorkflowDefinition,
  WorkflowDefinitionVariable,
  WorkflowDefinitionVariableMetadataAllowedValuesOption,
  WorkflowDefinitionVariableMetadataSourceType,
  WorkflowDefinitionVariableMetadataType,
  WorkflowVariableInput,
} from '~/generated'

type WorkflowContextData = {
  consultation_id?: string | null
  postcode?: string | null
  address_line_one?: string | null
  address_line_two?: string | null
  admin_area_two?: string | null
  country_code?: string | null
  tenant_name?: string | null
  clinician_id?: string
  contact_number?: string
  email?: string | null
  member_id?: string
  full_name?: string | null
  lower_case_full_name?: string | null
  date_of_birth?: string | null
  member_consumer_network?: number | null
  member_ccg_name?: string | null
  member_ccg_code?: number | null
  nhs_patient_id?: string | null
  clinician_name?: string
}

export const getContextVariableKeys = (
  variables: WorkflowDefinitionVariable[]
): string[] => {
  return variables.reduce((acc: string[], variable) => {
    const valueSource = WorkflowDefinitionVariableMetadataType.Source
    const valueType = WorkflowDefinitionVariableMetadataSourceType.Context
    const source = variable.metadata.find((meta) => meta.type === valueSource)

    return get(source, 'value.type') === valueType
      ? [...acc, variable.name]
      : acc
  }, [])
}

export const mapConsultationDataToWorkflowVariables = (
  consultation: CpWorkflowContextQuery['consultation'],
  consultantId: string,
  consultantName: string,
  definitionVariables: WorkflowDefinitionVariable[]
): WorkflowVariableInput[] => {
  const patient = consultation?.patient
  const consumerNetwork = consultation?.consumerNetwork
  const consumerNetworkName = consultation?.consumerNetworkName || ''

  if (patient) {
    const {
      id,
      email,
      region,
      public_healthcare_identifier: publicHealthcareIdentifier,
      full_name: fullName = '',
      date_of_birth: dateOfBirth,
      contact_details: contactDetails,
      clinical_commissioning_group: ccg,
    } = patient

    const { iso_code: isoCode } = region || {}

    const {
      phone_number: phoneNumber,
      phone_country_code: phoneCountryCode = '',
      address_third_line: addressThirdLine,
      address_post_code: addressPostCode,
      address_second_line: addressSecondLine,
      address_first_line: addressFirstLine,
    } = contactDetails || {}

    const contactNumber = `${phoneCountryCode}${phoneNumber}`
    const fullNameLowercase = fullName?.toLocaleLowerCase()

    const variableKeys = getContextVariableKeys(definitionVariables)

    const data: WorkflowContextData = {
      email,
      consultation_id: consultation?.id,
      postcode: addressPostCode,
      address_line_one: addressFirstLine,
      address_line_two: addressSecondLine,
      admin_area_two: addressThirdLine,
      country_code: isoCode,
      tenant_name: consumerNetworkName,
      clinician_id: consultantId,
      contact_number: contactNumber,
      member_id: id,
      full_name: fullName,
      lower_case_full_name: fullNameLowercase,
      date_of_birth: dateOfBirth,
      member_consumer_network: consumerNetwork,
      member_ccg_name: ccg?.name,
      member_ccg_code: ccg?.id,
      clinician_name: consultantName,
      nhs_patient_id: publicHealthcareIdentifier,
    }

    const contextData = pick(data, variableKeys)

    return mapFormValuesToWorkflowVariables(contextData)
  }

  return []
}

export const mapWorkflowDefinitionsToOptions = (
  definitions: WorkflowDefinition[],
  type: string
): SelectOptionType[] =>
  definitions
    .filter((definition) => definition.group.type === type)
    .map((definition) => ({
      value: definition.id,
      label: definition.workflow_definition_name || definition.key,
    }))

export const mapMetadataToDropDownOptions = (
  options: WorkflowDefinitionVariableMetadataAllowedValuesOption[]
): SelectOptionType[] =>
  options.map((value) => ({
    value: value.key,
    label: value.label,
  }))

export const mapFormValuesToWorkflowVariables = (
  values: any
): WorkflowVariableInput[] =>
  Object.keys(values)
    .filter((field) => values[field] != null)
    .map((field) => ({
      name: field,
      value: values[field],
    }))
