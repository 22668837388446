import React from 'react'

import { Checkbox, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { InsuranceClaimQuery } from '~/generated'

import messages from './InsuranceClaimCheck.messages'

export interface InsuranceClaimCheckViewProps {
  insuranceClaim: NonNullable<
    InsuranceClaimQuery['consultation']
  >['insuranceClaim']
  billable: boolean
  updateBillable: (value: boolean) => void
}

const InsuranceClaimCheckView = ({
  insuranceClaim,
  billable,
  updateBillable,
}: InsuranceClaimCheckViewProps) => {
  const f = useMessages(messages)
  const exempted = insuranceClaim?.state === 'exempted'

  if (!insuranceClaim) {
    return null
  }

  return (
    <div data-testid="insurance-checkbox-section">
      <Checkbox
        onChange={() => updateBillable(!billable)}
        checked={!billable || exempted}
        disabled={exempted}
        value="not_billable"
      >
        <Text size="medium">{f('insurance_claim_not_billable')}</Text>
      </Checkbox>
    </div>
  )
}

export default InsuranceClaimCheckView
