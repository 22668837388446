import { ApolloClient, ApolloProvider } from '@apollo/client'
import React, { ReactNode } from 'react'

import ApolloNetworkWarning from './ApolloNetworkWarning'

interface ApolloProps {
  children: ReactNode
  client: ApolloClient<any>
}

const Apollo = ({ children, client }: ApolloProps) => (
  <ApolloProvider client={client}>
    <ApolloNetworkWarning />
    {children}
  </ApolloProvider>
)

export default Apollo
