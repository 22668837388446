import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.prescriptions.prescription_formulary'

export default defineMessages({
  formulary_advice_title: {
    id: `${baseId}.formulary_advice_title`,
    defaultMessage: 'Formulary Advice',
  },
})
