import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { useConsultation } from '~/core/config'
import { ReferralsPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'
import { useReferralsSectionQuery, useSpecialismsQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import ReferralsView from './ReferralsView'

import messages from './Referrals.messages'

export const ReferralsContainer: ReferralsPluginInterface = ({
  consultationContext,
  editedConsultationWarningModel,
}) => {
  const fm = useFormatMessage()
  const consultation = useConsultation(consultationContext)
  const referralsResponse = useReferralsSectionQuery({
    variables: { id: consultation.id },
  })
  const specialismCategoriesResponse = useSpecialismsQuery({
    variables: {
      consultationId: consultation.id,
    },
  })
  const loading =
    referralsResponse.loading || specialismCategoriesResponse.loading

  const markFinalizedConsultationAsEdited = editedConsultationWarningModel?.useModelContext()
    ?.markFinalizedConsultationAsEdited

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    consultation?.status
  )

  const [canManageReferrals] = usePermissions('manage_referrals')

  const referrals = referralsResponse.data?.consultation?.referrals ?? []

  if (referralsResponse?.error) {
    return (
      <ErrorPanel
        error={referralsResponse.error}
        title={fm(messages.error_loading_referrals_message)}
        retry={() => referralsResponse.refetch()}
      />
    )
  }
  if (specialismCategoriesResponse?.error) {
    return (
      <ErrorPanel
        error={specialismCategoriesResponse.error}
        title={fm(messages.error_loading_referrals_message)}
        retry={() => specialismCategoriesResponse.refetch()}
      />
    )
  }

  const specialismCategories =
    specialismCategoriesResponse?.data?.specialismCategories ?? []

  if (!canManageReferrals) {
    return null
  }

  return (
    <ReferralsView
      referrals={referrals}
      restrictFinalizedConsultationEditing={
        restrictFinalizedConsultationEditing
      }
      markFinalizedConsultationAsEdited={markFinalizedConsultationAsEdited}
      specialismCategories={specialismCategories}
      loading={loading}
    />
  )
}

export default ReferralsContainer
