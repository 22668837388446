import '@babylon/fonts/Inter'

import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import {
  checkConnectionByUserType,
  UserType,
} from '@babylon/connect-client-core'
import { AccessRequest } from '@babylon/connect-client-web'
import { useFormatMessage } from '@babylon/intl'

import {
  DISABLE_VIDEO_ACTION,
  ENABLE_VIDEO_ACTION,
  MAXIMISE_CLINICIAN_VIDEO_ACTION,
  MINIMISE_CLINICIAN_VIDEO_ACTION,
  MULTIMEDIA_CATEGORY,
  MUTE_AUDIO_ACTION,
  UNMUTE_AUDIO_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics/analytics'
import { useFeatureFlags } from '~/core/core-modules'
import { usePrevious, useToggle, useUnmount } from '~/core/hooks'
import { useCallRejection } from '~/features/call/VideoCall/useCallRejection'
import { useGuestParticipant } from '~/features/consultation-profile/hooks'
import { CallStep } from '~/generated'

import {
  Action,
  CallStatus,
  isActive,
  isConnecting,
  isStreaming,
} from '../CallStatusReducerModelProvider'
import InCallButton from '../InCallButton'
import useReportCallStep from '../useReportCallStep'
import DisplayNamePill from './DisplayNamePill'
import DraggableFrame from './DraggableFrame'
import { emitMmsCfs, storeGetter, storeSetter } from './mms'
import useEndVideoCallMutation from './useEndVideoCallMutation'
import useStartVideoCallMutation from './useStartVideoCallMutation'
import useVideoSessionQuery from './useVideoSessionQuery'
import useVideoStreamer from './useVideoStreamer'
import { SendNotifications, SessionStatus } from './useVideoStreamer/useOpentok'
import VideoStatusMessage from './VideoStatusMessage'
import VideoViewFinder from './VideoViewFinder'

import messages from './VideoCall.messages'
import styles from './VideoCall.module.scss'

interface VideoCallProps {
  callStatus: CallStatus
  consultationId: string
  dispatch: React.Dispatch<Action>
  videoSession: {
    sessionId: string
    providerApiKey: string
    providerSessionId: string
    providerToken: string
  }
  guestInCall?: boolean | null
  consultantName?: string
}

const VideoCall = ({
  callStatus,
  consultationId,
  dispatch,
  guestInCall,
  videoSession: { sessionId, providerApiKey, providerSessionId, providerToken },
  consultantName,
}: VideoCallProps) => {
  const isCallStreaming = isStreaming(callStatus)
  const isCallActive = isActive(callStatus)
  const isCallConnecting = isConnecting(callStatus)
  const wasCallActive = usePrevious(isCallActive)
  const fm = useFormatMessage()
  const {
    multiwayCallingEnabled,
    clinicianViewFinderEnabled,
    newMmsCfsEnabled,
    videoStreamWindowImprovementsEnabled,
    showPatientRejectedVideoCall,
    virtualBackgroundEnabled,
  } = useFeatureFlags()

  const [virtualBackgroundValue, setVirtualBackgroundValue] = useToggle(true)

  /**
   * Queries and mutations
   */
  const [endVideoCallMutation] = useEndVideoCallMutation({ consultationId })
  const [reportCallStep] = useReportCallStep(consultationId)

  /**
   * UI Controls
   */
  const [cameraEnabled, toggleCamera] = useToggle(true)
  const [micEnabled, toggleMic] = useToggle(true)
  const [publisherMinimised, setPublisherMinimised] = useState(false)
  const [guestMinimised, setGuestMinimised] = useState(false)
  const clincianLabel = fm(messages.clinician_stream_label)
  const [callStartedAt, setCallStartedAt] = useState<string | null>(null)

  /**
   * Effects reacting to call state
   */
  useEffect(() => {
    if (isCallConnecting) {
      setCallStartedAt(new Date().toISOString())
      reportCallStep(CallStep.CallStarted)
    }
  }, [isCallConnecting]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isCallActive && wasCallActive) {
      endVideoCallMutation(sessionId)
    }
  }, [isCallActive]) // eslint-disable-line react-hooks/exhaustive-deps

  useUnmount(() => {
    if (isCallActive) {
      endVideoCallMutation(sessionId)
    }
  })

  const handleMic = () => {
    toggleMic()
    analytics.trackEvent({
      category: MULTIMEDIA_CATEGORY,
      action: micEnabled ? MUTE_AUDIO_ACTION : UNMUTE_AUDIO_ACTION,
    })
  }

  const handleBackground = () => {
    setVirtualBackgroundValue(!virtualBackgroundValue)
  }

  const handleCamera = () => {
    toggleCamera()
    analytics.trackEvent({
      category: MULTIMEDIA_CATEGORY,
      action: cameraEnabled ? DISABLE_VIDEO_ACTION : ENABLE_VIDEO_ACTION,
    })
  }

  const handlePublisherMinimised = (minimised: boolean) => {
    setPublisherMinimised(minimised)
    analytics.trackEvent({
      category: MULTIMEDIA_CATEGORY,
      action: minimised
        ? MINIMISE_CLINICIAN_VIDEO_ACTION
        : MAXIMISE_CLINICIAN_VIDEO_ACTION,
    })
  }
  const handleGuestMinimised = (minimised: boolean) => {
    setGuestMinimised(minimised)
  }

  /**
   * Opentok, monitoring and conv listener
   */
  const {
    sendNotifications,
    connections,
    subscriberElement,
    publisherElement,
    guestElement,
    streams,
    publisher,
    subscriber,
    subscriberVideoDegradation,
    session,
    sessionStatus,
    isGuestStreamActive,
  } = useVideoStreamer({
    sessionId: providerSessionId,
    apiKey: providerApiKey,
    token: providerToken,
    isCallActive,
    cameraEnabled,
    micEnabled,
    backgroundEnabled:
      (virtualBackgroundEnabled && virtualBackgroundValue) ?? false,
    dispatch,
    consultationId,
    consultantName,
    multiwayCallingEnabled,
    videoStreamWindowImprovementsEnabled,
  })

  const [startVideoCallMutation] = useStartVideoCallMutation({
    dispatch,
    consultationId,
    activeParticipants: connections
      .map((connection) => checkConnectionByUserType(connection))
      .filter((type) => type !== null) as UserType[],
  })

  useEffect(() => {
    if (isCallStreaming) {
      reportCallStep(CallStep.TimerStarted)
    }
  }, [isCallStreaming]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isCallStreaming && sendNotifications === SendNotifications.READY) {
      startVideoCallMutation(sessionId)
    }
  }, [isCallStreaming, sendNotifications]) // eslint-disable-line react-hooks/exhaustive-deps

  useCallRejection({
    appointmentId: consultationId,
    callStartedAt,
    showPatientRejectedVideoCall,
    dispatch,
    streams,
    callStatus,
  })

  /**
   * Classes and styling controls   */ const publisherWrapperClassname = cn(
    styles.publisherWrapper,
    !publisherMinimised && isCallStreaming && styles.visible
  )
  const guestWrapperClassname = cn(
    styles.guestWrapper,
    !guestMinimised && isCallStreaming && isGuestStreamActive && styles.visible
  )

  if (!isCallActive) {
    return null
  }

  return (
    <>
      {!!multiwayCallingEnabled && (
        <AccessRequest
          mmsCfsEnabled={newMmsCfsEnabled}
          mmsCfsConfig={{
            emit: emitMmsCfs,
            storeGetter,
            storeSetter,
            setupData: {
              appointment_id: consultationId,
              consultation_id: consultationId,
            },
          }}
          sessionActive={sessionStatus === SessionStatus.ACTIVE}
          className={styles.accessRequest}
          session={session?.current}
          messages={{
            body: fm(messages.body),
            accept: fm(messages.accept),
            decline: fm(messages.decline),
          }}
        />
      )}

      <div className={styles.container} data-testid="video-call">
        <VideoStatusMessage
          callStatus={callStatus}
          streams={streams}
          publisher={publisher}
          subscriber={subscriber}
          subscriberVideoDegradation={subscriberVideoDegradation}
        />
        <div
          ref={subscriberElement}
          id="subscriber"
          data-testid="subscriber-video-container"
          className={cn(styles.subscriber, '_lr-hide')}
        />
        {isCallActive && (
          <div className={styles.videoHeader}>
            <InCallButton
              iconName={cameraEnabled ? 'videocam' : 'videocam_off'}
              onClick={handleCamera}
              testId="toggle-camera-button"
            />
            <InCallButton
              iconName={micEnabled ? 'mic' : 'mic_off'}
              onClick={handleMic}
              testId="toggle-microphone-button"
            />
            {virtualBackgroundEnabled && (
              <InCallButton
                iconName={virtualBackgroundValue ? 'blur_on' : 'blur_off'}
                onClick={handleBackground}
                testId="toggle-background-button"
              />
            )}
          </div>
        )}
        <DraggableFrame width={guestInCall ? 90 : 130} height={90}>
          <div className={publisherWrapperClassname}>
            <div
              id="publisher"
              data-testid="publisher-video-container"
              ref={publisherElement}
              className={cn(styles.publisher, '_lr-hide')}
            />
            <div className={styles.videoHeader}>
              <InCallButton
                iconName="keyboard_arrow_down"
                onMouseDown={() => handlePublisherMinimised(true)}
              />
            </div>
            <DisplayNamePill name={clincianLabel} />
            {clinicianViewFinderEnabled && <VideoViewFinder />}
          </div>
        </DraggableFrame>
        {guestInCall && (
          <DraggableFrame width={90} height={90} x={-105}>
            <div
              className={guestWrapperClassname}
              data-testid="guest-video-wrapper"
            >
              <div
                id="guest"
                data-testid="guest-video-container"
                ref={guestElement}
                className={cn(styles.guest, '_lr-hide')}
              />
              <div className={styles.videoHeader}>
                <InCallButton
                  iconName="keyboard_arrow_down"
                  onMouseDown={() => handleGuestMinimised(true)}
                />
              </div>
            </div>
          </DraggableFrame>
        )}
        {publisherMinimised && (
          <InCallButton
            iconName="keyboard_arrow_up"
            className={styles.expandPublisher}
            onClick={() => handlePublisherMinimised(false)}
          />
        )}
        {guestMinimised && (
          <InCallButton
            iconName="keyboard_arrow_up"
            className={styles.expandGuest}
            onClick={() => handleGuestMinimised(false)}
          />
        )}
      </div>
    </>
  )
}

const VideoCallWithSession = (props: Omit<VideoCallProps, 'videoSession'>) => {
  const { consultationId, dispatch } = props
  const { multiwayCallingEnabled } = useFeatureFlags()

  const { videoSession, consultant } = useVideoSessionQuery({
    consultationId,
    dispatch,
  })
  const guestResult = useGuestParticipant(
    consultationId,
    multiwayCallingEnabled
  )

  if (!videoSession) {
    return null
  }

  return (
    <VideoCall
      {...props}
      videoSession={videoSession}
      guestInCall={guestResult.guestInCall}
      consultantName={multiwayCallingEnabled ? consultant?.name : undefined}
    />
  )
}

export default VideoCallWithSession
