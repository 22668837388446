import { differenceInSeconds } from 'date-fns'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import React, { useState } from 'react'

import { FormikInput } from '@babylon/core-ui'

import {
  CARE_PLAN_ACTION_FOR_GOAL_NOTES_ACTION,
  CARE_PLANS_CATEGORY,
  NOTE_TAKING_TIMER,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages } from '~/core/hooks'
import { CarePlanDraftQuery } from '~/generated'

import { getEmptyLocalAction } from './LocalDraftUtils'

import styles from '../styles.module.scss'
import messages from './CarePlanModalForm.messages'

const isBlank = (text?: string): boolean => !text?.trim()

const EditAction = ({
  goalIndex,
  goalArrayHelpers,
  goalsCount,
  actionIndex,
  actionArrayHelpers,
  actionsCount,
}: {
  goalIndex: number
  goalArrayHelpers: FieldArrayRenderProps
  goalsCount: number
  actionIndex: number
  actionArrayHelpers: FieldArrayRenderProps
  actionsCount: number
}) => {
  const f = useMessages(messages)
  const formik = useFormikContext<
    Extract<
      CarePlanDraftQuery['carePlanDraft'],
      { __typename: 'CarePlanDraft' }
    >
  >()

  const [startTime, setStartTime] = useState(0)

  const trackNoteTakingTime = () => {
    const time = differenceInSeconds(Date.now(), startTime)
    analytics.trackEvent({
      action: CARE_PLAN_ACTION_FOR_GOAL_NOTES_ACTION,
      category: CARE_PLANS_CATEGORY,
      label: NOTE_TAKING_TIMER,
      value: time,
    })
  }

  return (
    <FormikInput
      name={`goals.${goalIndex}.associatedActionDrafts.${actionIndex}.description`}
      placeholder={f('enter_action_placeholder')}
      outerClassName={styles.actionInput}
      className={styles.fullWidthInput}
      disabled={formik.isSubmitting}
      onChange={(event) => {
        if (
          !isBlank(event.currentTarget.value) &&
          actionIndex === actionsCount - 1
        ) {
          actionArrayHelpers.push(getEmptyLocalAction())
        }
      }}
      onFocus={() => setStartTime(Date.now())}
      onBlur={(event) => {
        const goal = formik.values.goals[goalIndex]
        const populatedActionsCount =
          goal.associatedActionDrafts?.filter(
            (action) => !isBlank(action.description)
          ).length || 0

        const isLastGoalInArray = goalIndex === goalsCount - 1
        const isLastActionInArray = actionIndex === actionsCount - 1
        const allActionsAreBlank = populatedActionsCount === 0
        const currentActionIsBlank = isBlank(event.currentTarget.value)

        if (
          allActionsAreBlank &&
          isBlank(goal.description) &&
          !isLastGoalInArray
        ) {
          goalArrayHelpers.remove(goalIndex)
        } else if (currentActionIsBlank && !isLastActionInArray) {
          actionArrayHelpers.remove(actionIndex)
        }
        trackNoteTakingTime()
      }}
    />
  )
}

export default EditAction
