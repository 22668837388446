import { useMutation } from '@apollo/client'

import {
  ERROR_REMOVE_SICK_NOTE_ACTION,
  REMOVE_SICK_NOTE_ACTION,
  SICK_NOTE_ACTIONS_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { updateFragment } from '~/core/graphql'
import {
  CpRemoveSickNoteMutation,
  CpRemoveSickNoteMutationVariables,
  CpSickNotesFragment,
} from '~/generated'

import { SICK_NOTES_FRAGMENT } from './fragments'
import { REMOVE_SICK_NOTE_MUTATION } from './mutations'

const removeSickNote = (
  fragment: CpSickNotesFragment,
  { data }: { data: CpRemoveSickNoteMutation }
) => {
  const { removeConsultationSickNote } = data

  const sickNotes = fragment.sickNotes.filter(
    (v) => v.id !== removeConsultationSickNote
  )

  return {
    ...fragment,
    sickNotes,
  }
}

const useRemoveSickNoteMutation = () => {
  const [mutate, result] = useMutation<
    CpRemoveSickNoteMutation,
    CpRemoveSickNoteMutationVariables
  >(REMOVE_SICK_NOTE_MUTATION, {
    onCompleted: () => {
      analytics.trackEvent({
        category: SICK_NOTE_ACTIONS_CATEGORY,
        action: REMOVE_SICK_NOTE_ACTION,
      })
    },
    onError: () => {
      analytics.trackEvent({
        category: SICK_NOTE_ACTIONS_CATEGORY,
        action: ERROR_REMOVE_SICK_NOTE_ACTION,
      })
    },
  })

  const performMutation = (variables: CpRemoveSickNoteMutationVariables) => {
    const dataId = `Consultation:${variables.consultationId}`

    return mutate({
      variables,
      update: updateFragment(SICK_NOTES_FRAGMENT, dataId, removeSickNote),
    })
  }

  return [performMutation, result] as const
}

export default useRemoveSickNoteMutation
