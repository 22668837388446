import { defineMessages } from 'react-intl'

const baseId = 'prescriptions.void_prescription'

export default defineMessages({
  titleVoidPrescription: {
    id: `${baseId}.title.void_prescription`,
    defaultMessage: 'Void prescription',
  },
  labelReason: {
    id: `${baseId}.label.reason`,
    defaultMessage: 'Reason',
  },
  messageAreYouSureYouWantToVoidThisPrescription: {
    id: `${baseId}.message.are_you_sure_you_want_to_void_this_prescription`,
    defaultMessage: 'Are you sure you want to void this prescription?',
  },
  placeholderPleaseEnterReasonHere: {
    id: `${baseId}.placeholder.please_enter_reason_here"`,
    defaultMessage: 'Please enter reason here',
  },
  placeholderSelectAnOption: {
    id: `${baseId}.placeholder.select_an_option`,
    defaultMessage: 'Select an option',
  },
  optionIncorrectQuantityPrescribed: {
    id: `${baseId}.option.incorrect_quantity_prescribed`,
    defaultMessage: 'Incorrect quantity prescribed',
  },
  optionIncorrectMedicinePrescribed: {
    id: `${baseId}.option.incorrect_medicine_prescribed`,
    defaultMessage: 'Incorrect medicine prescribed',
  },
  optionInappropriateFreeTextPrescription: {
    id: `${baseId}.option.inappropriate_free_text_prescription`,
    defaultMessage: 'Inappropriate free-text prescription',
  },
  optionInappropriateQuantityPrescribed: {
    id: `${baseId}.option.inappropriate_quantity_prescribed`,
    defaultMessage: 'Inappropriate quantity prescribed',
  },
  optionDrugUnavailableInPharmacy: {
    id: `${baseId}.option.drug_unavailable_in_pharmacy`,
    defaultMessage: 'Drug unavailable in pharmacy',
  },
  optionPotentialDrugSeekingBehaviorFlaggedMember: {
    id: `${baseId}.option.potential_drug_seeking_behavior_flagged_member`,
    defaultMessage: 'Potential drug seeking behavior flagged member',
  },
  optionOther: {
    id: `${baseId}.option.other`,
    defaultMessage: 'Other',
  },
  buttonOk: {
    id: `${baseId}.button.ok`,
    defaultMessage: 'Ok',
  },
  buttonCancel: {
    id: `${baseId}.button.cancel`,
    defaultMessage: 'Cancel',
  },
  errorInvalidReason: {
    id: `${baseId}.invalid_reason`,
    defaultMessage: 'Invalid reason',
  },
  other_required: {
    id: `${baseId}.other_required`,
    defaultMessage: 'Please enter a reason',
  },
  other_min_length: {
    id: `${baseId}.other_min_length`,
    defaultMessage: 'The reason must contain at least 6 characters',
  },
  request_failed: {
    id: `${baseId}.request_failed`,
    defaultMessage: "We couldn't send the request to void the prescription",
  },
  void_dispensed_prescription: {
    id: `${baseId}.void_dispensed_prescription`,
    defaultMessage:
      "We can't void this prescription because the patient has already collected their medications.",
  },
})
