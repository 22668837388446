import React, { useState } from 'react'

import { Radio, RadioGroup, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  PAT_MET_SWITCH_TO_IMPERIAL_MEASUREMENT_TYPE,
  PAT_MET_SWITCH_TO_METRIC_MEASUREMENT_TYPE,
  PAT_MET_SWITCH_TO_US_MEASUREMENT_TYPE,
  PATIENT_METRICS,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { PatientMetricsFormTemplate } from '~/features/patient-metrics/PatientMetricFormTemplate'
import {
  getBmiDetails,
  NonNumberBmiValues,
} from '~/features/patient-metrics/utils/bmi'
import { useTimestamp } from '~/features/patient-metrics/utils/Timestamp'

import { usePatientMetricsState } from '../PatientMetricsStateProvider'
import { isExpanded } from '../utils'
import HeightForm from './HeightForm'
import WeightForm from './WeightForm'

import messages from '../PatientMetricsModal.messages'
import styles from '../styles.module.scss'

export enum MeasurementType {
  metric = 'metric',
  imperial = 'imperial',
  us = 'us',
}

const maxDate = (a?: Date, b?: Date) => {
  return a && b ? (a > b ? a : b) : undefined
}

const BmiMetricForm = () => {
  const startExpanded = isExpanded('BmiInquiry')

  const fm = useFormatMessage()

  const {
    displayValues: {
      displayHeight,
      displayWeight,
      ageInYears,
      timestampHeight,
      timestampWeight,
    },
  } = usePatientMetricsState()

  const lastDate = maxDate(timestampHeight, timestampWeight)

  const [measurementType, setMeasurementType] = useState(MeasurementType.metric)

  const minor = ageInYears ? ageInYears < 18 : false

  const { value, rating, interpretation } = getBmiDetails(
    displayHeight,
    displayWeight,
    ageInYears
  )

  let localizeBmiValue
  switch (value) {
    case NonNumberBmiValues.bmi_na:
      localizeBmiValue = fm(messages.bmi_na)
      break
    case NonNumberBmiValues.bmi_out_of_range:
      localizeBmiValue = fm(messages.bmi_out_of_range)
      break
    default:
      localizeBmiValue = value
  }

  const bmi = `${localizeBmiValue} ${interpretation ? fm(interpretation) : ''}`

  const [timestamp, setTimestamp] = useTimestamp(lastDate)
  const [weightTimestamp, setWeightTimestamp] = useTimestamp(timestampWeight)
  const [heightTimestamp, setHeightTimestamp] = useTimestamp(timestampHeight)

  const handleWeightUpdate = () => {
    setTimestamp(fm(messages.smoking_status_updated_just_now))
    setWeightTimestamp(fm(messages.smoking_status_updated_just_now))
  }

  const handleHeightUpdate = () => {
    setTimestamp(fm(messages.smoking_status_updated_just_now))
    setHeightTimestamp(fm(messages.smoking_status_updated_just_now))
  }

  const getGaLabel = (type: MeasurementType) => {
    switch (type) {
      case MeasurementType.metric:
        return PAT_MET_SWITCH_TO_METRIC_MEASUREMENT_TYPE
      case MeasurementType.imperial:
        return PAT_MET_SWITCH_TO_IMPERIAL_MEASUREMENT_TYPE
      case MeasurementType.us:
        return PAT_MET_SWITCH_TO_US_MEASUREMENT_TYPE
      default:
        return null
    }
  }

  const handleMeasurementTypeChange = (type: MeasurementType) => {
    setMeasurementType(type)
    const label = getGaLabel(type)
    if (label) {
      analytics.trackEvent({
        label,
        category: PATIENT_METRICS,
        action: UPDATE_PATIENT_METRICS,
      })
    }
  }

  return (
    <PatientMetricsFormTemplate
      startExpanded={startExpanded}
      title={fm(messages.bmi_label)}
      value={bmi}
      rating={rating}
      timestamp={lastDate && timestamp}
      dataTestId="bmi-metric-section"
      sectionName="bmiSection"
      sectionHeaderAlert={minor ? fm(messages.minor_warning_note_v2) : null}
      showTimeStamp={!minor}
    >
      <div data-testid="bmi-metrics-form-content">
        <RadioGroup
          name="measurementType"
          onChange={handleMeasurementTypeChange}
        >
          <div className={styles.radioButtonContainer}>
            <Radio
              value={MeasurementType.imperial}
              checked={measurementType === MeasurementType.imperial}
            >
              <Text>{fm(messages.measurement_type_label_imperial)}</Text>
            </Radio>
            <Radio
              value={MeasurementType.metric}
              checked={measurementType === MeasurementType.metric}
            >
              <Text>{fm(messages.measurement_type_label_metric)}</Text>
            </Radio>
            <Radio
              value={MeasurementType.us}
              checked={measurementType === MeasurementType.us}
            >
              <Text>{fm(messages.measurement_type_label_us)}</Text>
            </Radio>
          </div>
        </RadioGroup>
        <WeightForm
          measurementType={measurementType}
          onUpdate={handleWeightUpdate}
          timestamp={timestampWeight && weightTimestamp}
        />
        <HeightForm
          measurementType={measurementType}
          onUpdate={handleHeightUpdate}
          timestamp={timestampHeight && heightTimestamp}
        />
      </div>
    </PatientMetricsFormTemplate>
  )
}

export default BmiMetricForm
