import * as Sentry from '@sentry/browser'
import React from 'react'

import {
  ERROR_BOUNDARY_CATEGORY,
  UNIDENTIFIED_ERROR_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { logException } from '~/core/sentry'

import FallbackErrorComponent from './FallbackErrorComponent'

interface RenderPropInput
  extends Pick<RawErrorBoundaryState, 'error' | 'errorId'> {
  reset: () => void
}

interface RawErrorBoundaryProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onDidCatch?: () => void
  children: React.ReactNode
  render?: (arg: RenderPropInput) => React.ReactNode
}

interface RawErrorBoundaryState {
  error: Error | undefined
  errorId: string | undefined
}

class RawErrorBoundary extends React.Component<
  RawErrorBoundaryProps,
  RawErrorBoundaryState
> {
  static defaultProps = {
    component: FallbackErrorComponent,
  }

  initialState: RawErrorBoundaryState = {
    error: undefined,
    errorId: undefined,
  }

  state: RawErrorBoundaryState = { ...this.initialState }

  static getDerivedStateFromError(error: Error) {
    // For setting state
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // For side effects
    const { onDidCatch } = this.props

    Sentry.withScope((scope) => {
      scope.setExtras({ errorInfo })
      const errorId = logException(error)
      this.setState({ errorId })
    })

    if (typeof onDidCatch === 'function') {
      onDidCatch()
    }
  }

  reset = () => {
    this.setState({ ...this.initialState })
  }

  render() {
    const { reset } = this
    const { error, errorId } = this.state
    const { children, render } = this.props
    if (error) {
      return typeof render === 'function' ? (
        render({ error, errorId, reset })
      ) : (
        <FallbackErrorComponent errorId={errorId} />
      )
    }

    return children
  }
}

interface RawErrorBoundaryWithAnalyticsProps extends RawErrorBoundaryProps {
  gaAction?: string
}

const RawErrorBoundaryWithAnalytics = ({
  gaAction = UNIDENTIFIED_ERROR_ACTION,
  render,
  children,
}: RawErrorBoundaryWithAnalyticsProps) => {
  const handleDidCatch = () => {
    analytics.trackEvent({
      category: ERROR_BOUNDARY_CATEGORY,
      action: gaAction,
    })
  }

  return (
    <RawErrorBoundary onDidCatch={handleDidCatch} render={render}>
      {children}
    </RawErrorBoundary>
  )
}

export default RawErrorBoundaryWithAnalytics
