import { defineMessages } from 'react-intl'

const baseId = 'error_page'

export default defineMessages({
  logout_button: {
    id: `${baseId}.logout_button`,
    defaultMessage: 'Log out',
  },
  support_button: {
    id: `${baseId}.support_button`,
    defaultMessage: 'Contact support',
  },
})
