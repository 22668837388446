import { defineMessages } from 'react-intl'

const baseId = 'consultation.patient_summary_modal'

export default defineMessages({
  consultation_event_title: {
    id: `${baseId}.consultation_event_title`,
    defaultMessage: 'Consultation',
  },
  incomplete_title: {
    id: `${baseId}.incomplete_title_v2`,
    defaultMessage: 'Incomplete consultation',
  },
  error_loading_history: {
    id: `${baseId}.error_loading_history`,
    defaultMessage:
      'Error loading the history of consultations for this diagnosis',
  },
  error_loading_consultations: {
    id: `${baseId}.error_loading_consultations`,
    defaultMessage: 'Error loading the consultation details',
  },
  error_loading_permissions: {
    id: `${baseId}.error_loading_permissions`,
    defaultMessage: 'Error loading the consultation permissions',
  },
})
