import { useCallback, useRef } from 'react'

const useQueue = <T>() => {
  const queue = useRef<T[]>([])
  const pushToQueue = useCallback((item: T) => {
    queue.current = [...queue.current, item]
  }, [])

  const consumeQueue = useCallback(() => {
    const currentQueue = queue.current
    queue.current = []
    return currentQueue
  }, [])

  return { pushToQueue, consumeQueue }
}

export default useQueue
