import { gql } from '@apollo/client'

import { prescriptionFieldsFragment } from './fragments'

export const PRESCRIPTIONS_QUERY = gql`
  query Prescription($id: ID!) {
    consultation(id: $id) {
      id
      region {
        id
        iso_code
      }
      consultationNote {
        id
        assessmentCodes {
          code
          term
          length
          offset
        }
      }
      drugDosageOptions {
        shortcut
        description
      }
      patient {
        id
        canCreatePrescriptionPdf {
          valid
          errorCodes
        }
      }
      prescriptions {
        ...PrescriptionFieldsFragment
      }
    }
  }
  ${prescriptionFieldsFragment}
`

export const GetClinicalNotesForPrescriptionsQuery = gql`
  query GetClinicalNotesForPrescriptionsQuery(
    $iris: [ClinicalCodeInput]!
    $resolveDrugs: Boolean
  ) {
    getClinicalNotes(iris: $iris, resolveDrugs: $resolveDrugs) {
      furtherActionNotes
      managementNotes
      iri
      name
      guidelines
      treatmentPlans {
        ageGroup
        penicillinAllergicOption
        prescription {
          drugId
          drugName
          strength
          duration
          quantity
          dosage
          directions
          guidance
        }
      }
    }
  }
`

export const PrescriptionQuery = gql`
  query GetPrescriptionQuery($consultationId: ID!) {
    consultation(id: $consultationId) {
      id
      patient {
        id
      }
      prescriptions {
        ...PrescriptionFieldsFragment
      }
    }
  }
  ${prescriptionFieldsFragment}
`
