import { defineMessages } from 'react-intl'

const baseId = 'consultation.consultation_profile'

export default defineMessages({
  dob_and_age: {
    id: `${baseId}.dob_and_age`,
    defaultMessage: '{dob} ({age}y)',
  },
  unknown_age: {
    id: `${baseId}.unknown_age`,
    defaultMessage: 'Unknown age',
  },
  unknown_value: {
    id: `${baseId}.unknown_value`,
    defaultMessage: 'Unknown gender',
  },
  minor_tag_label: {
    id: `${baseId}.minor_tag_label`,
    defaultMessage: 'Minor',
  },
  bupa: {
    id: `${baseId}.bupa`,
    defaultMessage: 'Managed by BUPA',
  },
  gender_label: {
    id: `${baseId}.gender_label`,
    defaultMessage: 'Gender: {gender}',
  },
  patient_id_label: {
    id: `${baseId}.patient_id_label`,
    defaultMessage: 'ID',
  },
  public_healthcare_id_label: {
    id: `${baseId}.public_healthcare_id_label`,
    defaultMessage: 'NHS ID',
  },
  consultation_id_label: {
    id: `${baseId}.consultation_id_label`,
    defaultMessage: 'Consult. ID: {id}',
  },
  language_other: {
    id: `${baseId}.language_other`,
    defaultMessage: 'Other',
  },
  error_consumer_network: {
    id: `${baseId}.error_consumer_network`,
    defaultMessage: 'Error loading the consumer network',
  },
  copy_patient_id: {
    id: `${baseId}.copy_patient_id`,
    defaultMessage: 'Copy patient ID',
  },
  copy_public_healthcare_id: {
    id: `${baseId}.copy_public_healthcare_id`,
    defaultMessage: 'Copy public healthcare ID',
  },
  copy_consultation_id: {
    id: `${baseId}.copy_consultation_id`,
    defaultMessage: 'Copy consultation ID',
  },
  interpreter: {
    id: `${baseId}.interpreter`,
    defaultMessage: 'Interpreter',
  },
  language_label: {
    id: `${baseId}.language_label`,
    defaultMessage: 'Language',
  },
  ccg_label: {
    id: `${baseId}.ccg_label`,
    defaultMessage: 'CCG',
  },
  duration_minutes: {
    id: `${baseId}.duration_minutes`,
    defaultMessage: '{minutes} min',
  },
  consultation_type: {
    id: `${baseId}.consultation_type`,
    defaultMessage: '{type} call',
  },
  text_consultation: {
    id: `${baseId}.text_consultation`,
    defaultMessage: 'Text consultation',
  },
  face_to_face_consultation: {
    id: `${baseId}.face_to_face_consultation`,
    defaultMessage: 'Face to face consultation',
  },
  state_regulations_message: {
    id: `${baseId}.state_regulations_message`,
    defaultMessage: 'Review state regulations.',
  },
  state_regulations_link_label: {
    id: `${baseId}.state_regulations_link_label`,
    defaultMessage: 'Read more',
  },
  consultation_caregiver_label: {
    id: `${baseId}.consultation_caregiver_label`,
    defaultMessage: 'Caregiver: {guestFirstName} {guestLastName}',
  },
  multiway_tag_label: {
    id: `${baseId}.multiway_tag_label`,
    defaultMessage: 'multiway',
  },
})
