import React from 'react'
import cn from 'classnames'
import ReactSelect, {
  ActionMeta,
  components,
  FormatOptionLabelMeta,
  GroupType,
  IndicatorProps,
  MultiValueProps,
  OptionTypeBase,
  Props as ReactSelectProps,
  ValueType,
} from 'react-select-latest'

import Tag, { presetStyles } from '../Tag'
import Highlighter from '../Highlighter'
import { Spinner } from '../../foundation'
import { ReactComponent as ClearIcon } from '../../../icons/close-dropdown.svg'
import { ReactComponent as DropdownIcon } from '../../../icons/arrow-down-dropdown.svg'

import './Select.scss'

export type SelectOptionTypeBase = {
  tagColor?: typeof presetStyles[number]
} & OptionTypeBase

export type SelectOptionType =
  | SelectOptionTypeBase
  | GroupType<SelectOptionTypeBase>

export type SelectValueType = ValueType<SelectOptionTypeBase, boolean>

export interface SelectProps<
  OptionType extends OptionTypeBase = SelectOptionType,
  IsMulti extends boolean = false
> extends ReactSelectProps<OptionType, IsMulti> {
  'data-testid'?: string
}

export type SelectActionMeta = ActionMeta<SelectOptionTypeBase>

const emboldenMatchedOptionLabel = (
  getOptionLabel: (option: SelectOptionTypeBase) => string
) => (
  option: SelectOptionType,
  { inputValue }: FormatOptionLabelMeta<SelectOptionType, boolean>
) => {
  const searchWords = inputValue.split(' ')

  return (
    <Highlighter
      content={getOptionLabel(option)}
      searchWords={searchWords}
      emboldenHighlight
    />
  )
}

const MultiValue = ({
  data,
  removeProps,
  children,
}: Partial<MultiValueProps<SelectOptionTypeBase>>) => {
  const onRemove = removeProps?.onClick
  const tagColor = data?.tagColor ?? 'clinical-blue'

  return (
    <Tag
      className="core-select__tag"
      color={tagColor}
      onClose={onRemove}
      uppercase={false}
      closable
      margin
    >
      {children}
    </Tag>
  )
}

const LoadingIndicator = () => (
  <div className="core-select__indicator">
    <Spinner className="core-select__spinner" />
  </div>
)

const ClearIndicator = (
  props: IndicatorProps<SelectOptionTypeBase, boolean>
) => (
  <components.ClearIndicator {...props}>
    <ClearIcon />
  </components.ClearIndicator>
)

const DropdownIndicator = (
  props: IndicatorProps<SelectOptionTypeBase, boolean>
) => (
  <components.DropdownIndicator {...props}>
    <DropdownIcon />
  </components.DropdownIndicator>
)

const Select = ({
  formatOptionLabel,
  id,
  'data-testid': dataTestId,
  className,
  getOptionLabel,
  ...rest
}: SelectProps<SelectOptionType, boolean>) => {
  const getLabel =
    getOptionLabel || ((option: SelectOptionTypeBase) => option.label)

  return (
    <div data-testid={dataTestId}>
      <ReactSelect
        components={{
          MultiValue,
          LoadingIndicator,
          ClearIndicator,
          DropdownIndicator,
        }}
        formatOptionLabel={
          formatOptionLabel || emboldenMatchedOptionLabel(getLabel)
        }
        classNamePrefix="core-select"
        className={cn('core-select', className)}
        inputId={id}
        getOptionLabel={getLabel}
        {...rest}
      />
    </div>
  )
}

export default Select
