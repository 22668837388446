import cn from 'classnames'
import React from 'react'

import { Tag, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ReactComponent as AttachmentIcon } from '~/assets/timeline/attachment.svg'
import Tooltip from '~/ui/Tooltip'

import { isMyConsultation } from './utils'

import messages from './TimelineConsultationTitle.messages'
import styles from './TimelineConsultationTitle.module.scss'

export interface ConsultantMetaIdentity {
  id: string
  uuid?: string
  name?: string
  role?: string | null
  // eslint-disable-next-line camelcase
  profession_name?: string | null
}

export interface TimelineConsultationTitleProps {
  isIncomplete?: boolean
  isNoShow?: boolean
  hasAttachments?: boolean
  consultant?: ConsultantMetaIdentity | null
  currentConsultant?: ConsultantMetaIdentity
  isCurrentConsultation?: boolean
  className?: string
  isFutureEvent?: boolean
}

const TimelineConsultationTitle = ({
  isIncomplete,
  isNoShow,
  consultant,
  currentConsultant,
  isCurrentConsultation,
  hasAttachments,
  className,
  isFutureEvent,
}: TimelineConsultationTitleProps) => {
  const fm = useFormatMessage()

  const myConsultation = isMyConsultation(consultant, currentConsultant)

  const title = (() => {
    if (isCurrentConsultation) {
      return fm(messages.this_consultation_event_title)
    }
    if (isNoShow) {
      return fm(messages.no_show_event_title)
    }
    if (isFutureEvent && isIncomplete) {
      return `${fm(messages.future_event_prefix)} ${fm(
        messages.consultation_event_title
      ).toLowerCase()}`
    }
    return fm(messages.consultation_event_title)
  })()

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingWrapper}>
        <span
          className={cn(
            className,
            styles.heading,
            isCurrentConsultation && styles.currentConsultation,
            isNoShow && styles.noShow
          )}
          data-testid="timeline-consultation-title"
        >
          {!isCurrentConsultation && isIncomplete && !isFutureEvent && (
            <span className={styles.incompleteConsultation}>
              {fm(messages.incomplete_event_title)}
            </span>
          )}{' '}
          {title}{' '}
        </span>

        {consultant && !myConsultation && (
          <Text
            className={styles.nameText}
            bold={false}
            color="grey-type"
            data-testid="consultant-name"
          >
            {fm(messages.consultation_event_with, {
              consultantName: consultant.name,
            })}
          </Text>
        )}

        {consultant && (
          <div className={styles.metaTagContainer}>
            {consultant.profession_name && (
              <Tag
                className={styles.metaTag}
                data-testid="consultant-role"
                color={isNoShow ? 'reverse-grey' : 'black-on-grey'}
                margin={false}
              >
                {consultant.profession_name}
              </Tag>
            )}

            {myConsultation && (
              <Tag
                className={styles.metaTag}
                data-testid="my-consultation"
                color={isNoShow ? 'reverse-grey' : 'highlight-violet'}
                margin={false}
              >
                {fm(messages.my_consultation_label)}
              </Tag>
            )}
          </div>
        )}
      </div>

      {hasAttachments && (
        <Tooltip placement="top" message={fm(messages.attachments)}>
          <AttachmentIcon
            className={styles.attachmentIcon}
            data-testid="attachment-icon"
          />
        </Tooltip>
      )}
    </div>
  )
}

export default TimelineConsultationTitle
