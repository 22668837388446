/* eslint-disable camelcase,consistent-return */
import axios, { CancelToken } from 'axios'
import qs from 'qs'
import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CamelCasedPropertiesDeep } from 'type-fest'

import { ConsultantUser } from '@babylon/babylon-user'

import { APP_NAME, CONFIG_URL } from '~/constants'
import requestIdGenerator from '~/core/request-id-generator'

export type ClinicalCareConfig = CamelCasedPropertiesDeep<{
  service_types?: {
    self_managed_service_types?: string[]
  }
}>

type Response = CamelCasedPropertiesDeep<{
  clinical_care?: ClinicalCareConfig
}>

const fetchClinicalCareConfig = async (
  partners: string[],
  userUuid: string,
  options?: {
    cancelToken?: CancelToken
  }
): Promise<ClinicalCareConfig | undefined> => {
  const query = qs.stringify(
    {
      namespaces: 'clinical_care',
      partners,
      appname: APP_NAME,
    },
    {
      addQueryPrefix: true,
      skipNulls: true,
    }
  )

  const response = await axios.get<Response>(`${CONFIG_URL}${query}`, {
    cancelToken: options?.cancelToken,
    withCredentials: true,
    headers: {
      'X-App-Name': APP_NAME,
      'babylon-request-id': requestIdGenerator.generate(),
      'X-Ab-Identifier': userUuid,
    },
  })

  return response?.data?.clinicalCare
}

type HookReturnType = {
  selfManagedServiceTypeUUID?: string
  loading: boolean
  error?: Error
}

type PromiseOrValue<T> = T | Promise<T>

const tryToUpdateRequestStateWithSuccessResponse = (
  setRequestStatus: (_: HookReturnType) => void
): ((
  config?: ClinicalCareConfig
) => PromiseOrValue<ClinicalCareConfig | undefined>) => {
  return (config) => {
    const selfManagedServiceTypes =
      config?.serviceTypes?.selfManagedServiceTypes ?? []
    if (selfManagedServiceTypes.length === 0) {
      return Promise.reject(
        new Error(
          'selfManagedServiceTypes is not available in the ClinicalCare config'
        )
      )
    }
    setRequestStatus({
      loading: false,
      error: undefined,
      selfManagedServiceTypeUUID: selfManagedServiceTypes[0],
    })
    return config
  }
}

const updateStateWithError = (
  setRequestStatus: (_: HookReturnType) => void
) => (e: any) =>
  setRequestStatus({
    loading: false,
    selfManagedServiceTypeUUID: undefined,
    error: e instanceof Error ? e : new Error(`Unknown error ${e.toString()}`),
  })

export const useFetchSelfManagedServiceTypeUUID = ({
  user,
  skip,
}: {
  user: Pick<ConsultantUser, 'partnerIds' | 'uuid'>
  skip: boolean
}): HookReturnType => {
  const [requestStatus, setRequestStatus] = useState<HookReturnType>({
    loading: false,
  })

  useEffect(() => {
    if (user.partnerIds == null || skip) {
      return
    }
    const cancelToken = axios.CancelToken.source()

    fetchClinicalCareConfig(user.partnerIds, user.uuid, {
      cancelToken: cancelToken.token,
    })
      .then(tryToUpdateRequestStateWithSuccessResponse(setRequestStatus))
      .catch(updateStateWithError(setRequestStatus))

    return () => cancelToken.cancel()
  }, [user.uuid, user.partnerIds, setRequestStatus, skip])

  return requestStatus
}
