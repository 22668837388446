import React from 'react'

import { ConsultationScheduleItemPluginInterface } from '~/core/config/modules/generated/types'
import { useConsultationScheduleItemDetailsQuery } from '~/generated'

import ConsultationScheduleItem, {
  ConsultationScheduleItemProps,
} from './ConsultationScheduleItem'

const ConsultationScheduleItemModule: ConsultationScheduleItemPluginInterface = ({
  hideIncompleteConsultationNotifications,
  CheckIn,
}) => {
  const ConsultationScheduleItemContainer = ({
    scheduleStatus,
    id,
    status,
    statusV2,
    scheduledTime,
    consultationType,
    patientNote,
    languageRequested,
    onClick,
  }: ConsultationScheduleItemProps) => {
    // This will mostly be resolved from the cache, populated by usePrefetchConsultations
    const { data, loading } = useConsultationScheduleItemDetailsQuery({
      variables: { id },
      errorPolicy: 'all',
    })

    const { patient, hasPatientImage, consumerNetwork, appointment } =
      data?.consultation ?? {}

    return (
      <ConsultationScheduleItem
        id={id}
        scheduleStatus={scheduleStatus}
        status={status}
        statusV2={statusV2}
        scheduledTime={scheduledTime}
        consultationType={consultationType}
        patientNote={patientNote}
        languageRequested={languageRequested}
        onClick={onClick}
        hideIncompleteConsultationNotifications={
          hideIncompleteConsultationNotifications
        }
        consumerNetwork={consumerNetwork}
        hasPatientImage={hasPatientImage}
        patient={patient}
        loadingDetails={loading}
        CheckIn={CheckIn}
        appointment={appointment}
      />
    )
  }

  return ConsultationScheduleItemContainer
}

export default ConsultationScheduleItemModule
