import React from 'react'

import { Button, Checkbox, Grid, Input } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { usePermissions } from '~/core/permissions'
import Label from '~/ui/Label'
import { ModalContent, ModalFooter, RouteModal } from '~/ui/LegacyModal'

import messages from './GpDetailsModal.messages'

const withDefault = (value) => value || ''
const withMargin = { marginTop: 4 }
const GP_CONSENT_LABEL = 'gp_consent'

const GpDetailsModal = ({
  state,
  handleInputChange,
  redirectToConsultation,
  handleSave,
  handleGpConsentChange,
  submitting,
}) => {
  const fm = useFormatMessage()
  const [displayGpConsentWarning] = usePermissions('display_gp_consent_warning')

  return (
    <RouteModal onRequestClose={redirectToConsultation}>
      <ModalContent title={fm(messages.title)}>
        <Label value={fm(messages.gp_name_label)}>
          <Input
            fill
            placeholder={fm(messages.gp_name_placeholder)}
            value={withDefault(state.gp_name)}
            onChange={handleInputChange('gp_name')}
          />
        </Label>
        <Label value={fm(messages.surgery_phone_number_label)}>
          <Input
            fill
            placeholder={fm(messages.surgery_phone_number_placeholder)}
            value={withDefault(state.gp_surgery_phone_number)}
            onChange={handleInputChange('gp_surgery_phone_number')}
          />
        </Label>

        <Label value={`${fm(messages.surgery_name_label)}*`}>
          <Grid columns={2} gap={4}>
            <Input
              fill
              placeholder={fm(messages.surgery_name_placeholder)}
              value={withDefault(state.gp_surgery_name)}
              onChange={handleInputChange('gp_surgery_name')}
            />
            {displayGpConsentWarning && (
              <div style={{ paddingTop: 10, fontSize: 14 }}>
                <Checkbox
                  name={GP_CONSENT_LABEL}
                  id={GP_CONSENT_LABEL}
                  checked={state.gpConsent}
                  value={GP_CONSENT_LABEL}
                  onChange={handleGpConsentChange}
                >
                  {fm(messages.share_notes_label)}
                </Checkbox>
              </div>
            )}
          </Grid>
        </Label>

        <Label value={`${fm(messages.address_label)}*`}>
          <Input
            fill
            placeholder={fm(messages.address_first_line_placeholder)}
            value={withDefault(state.gp_address_first_line)}
            onChange={handleInputChange('gp_address_first_line')}
          />
          <Input
            fill
            style={withMargin}
            placeholder={fm(messages.address_second_line_placeholder)}
            value={withDefault(state.gp_address_second_line)}
            onChange={handleInputChange('gp_address_second_line')}
          />
          <Input
            fill
            style={withMargin}
            placeholder={fm(messages.address_third_line_placeholder)}
            value={withDefault(state.gp_address_third_line)}
            onChange={handleInputChange('gp_address_third_line')}
          />
          <Input
            fill
            style={withMargin}
            placeholder={fm(messages.address_post_code_placeholder)}
            value={withDefault(state.gp_address_post_code)}
            onChange={handleInputChange('gp_address_post_code')}
          />
        </Label>
        <ModalFooter>
          <Button inline intent="secondary" onClick={redirectToConsultation}>
            {fm(messages.cancel_button_label)}
          </Button>
          <Button
            inline
            intent="primary"
            onClick={handleSave}
            loading={submitting}
          >
            {fm(messages.save_button_label)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </RouteModal>
  )
}

export default GpDetailsModal
