import React from 'react'

import { useGlobals } from '~/core/core-modules'

import { stringList } from './string'

export enum PatientNameDisplayTypes {
  lastNameFirst = 'last,first',
  firstNameFirst = 'first,last',
}

const patientNameFormatter = (
  firstName: string,
  lastName: string,
  patientNameDisplayType?: string,
  upperCaseLastName: boolean = false
) => {
  const lastNameFormatted = upperCaseLastName
    ? lastName.toUpperCase()
    : lastName
  switch (patientNameDisplayType) {
    case PatientNameDisplayTypes.firstNameFirst:
      return `${firstName} ${lastNameFormatted}`.trim()
    case PatientNameDisplayTypes.lastNameFirst:
      return stringList(lastNameFormatted, firstName)
    default:
      return stringList(lastNameFormatted, firstName)
  }
}

interface PatientNameDisplayProps {
  lastName: string
  firstName: string
  upperCaseLastName?: boolean
  testId?: string
}

const PatientNameDisplay = ({
  firstName,
  lastName,
  upperCaseLastName = false,
  testId,
}: PatientNameDisplayProps) => {
  const { patientNameDisplayType } = useGlobals()

  return (
    <div data-testid={testId}>
      {patientNameFormatter(
        firstName,
        lastName,
        patientNameDisplayType,
        upperCaseLastName
      )}
    </div>
  )
}

export default PatientNameDisplay
