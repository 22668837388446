import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

type UseMediaQueryParams = Parameters<typeof useMediaQuery>

// Breaking apart UseMediaQueryParams so that we can make settings optional.
// Is there a better way?
type TUseMediaQuery = (
  settings?: UseMediaQueryParams[0],
  device?: UseMediaQueryParams[1],
  callback?: UseMediaQueryParams[2]
) => boolean

type MediaQueryComponent = {
  children: ReactNode
}

export const useMobile: TUseMediaQuery = (settings, ...args) => {
  return useMediaQuery({ maxWidth: 649, ...settings }, ...args)
}

export const useAboveMobile: TUseMediaQuery = (settings, ...args) => {
  return useMediaQuery({ minWidth: 650, ...settings }, ...args)
}

export const useTablet: TUseMediaQuery = (settings, ...args) => {
  return useMediaQuery({ minWidth: 650, maxWidth: 999, ...settings }, ...args)
}

export const useBelowDesktop: TUseMediaQuery = (settings, ...args) => {
  return useMediaQuery({ maxWidth: 999, ...settings }, ...args)
}

export const useDesktop: TUseMediaQuery = (settings, ...args) => {
  return useMediaQuery({ minWidth: 1000, ...settings }, ...args)
}

export const Mobile = ({ children }: MediaQueryComponent) => {
  const isMobile = useMobile()
  return isMobile ? <>{children}</> : null
}

export const AboveMobile = ({ children }: MediaQueryComponent) => {
  const isAboveMobile = useAboveMobile()
  return isAboveMobile ? <>{children}</> : null
}

export const Tablet = ({ children }: MediaQueryComponent) => {
  const isTablet = useTablet()
  return isTablet ? <>{children}</> : null
}

export const BelowDesktop = ({ children }: MediaQueryComponent) => {
  const isBelowDesktop = useBelowDesktop()
  return isBelowDesktop ? <>{children}</> : null
}

export const Desktop = ({ children }: MediaQueryComponent) => {
  const isDesktop = useDesktop()
  return isDesktop ? <>{children}</> : null
}
