import { defineMessages } from 'react-intl'

const baseId = 'molecules.error'

export default defineMessages({
  option_renderer: {
    id: `${baseId}.option_renderer`,
    defaultMessage: 'Error loading the options, please try again later',
  },
  loading_content: {
    id: `${baseId}.loading_content`,
    defaultMessage: 'Error loading this page.',
  },
  please_reload: {
    id: `${baseId}.please_reload`,
    defaultMessage: 'Please reload or try another page and come back later.',
  },
  loading_dialog: {
    id: `${baseId}.loading_dialog`,
    defaultMessage: 'Error loading this dialog.',
  },
  try_again: {
    id: `${baseId}.try_again`,
    defaultMessage: 'Please try again later.',
  },
  reload: {
    id: `${baseId}.reload`,
    defaultMessage: 'Reload',
  },
  okay: {
    id: `${baseId}.okay`,
    defaultMessage: 'OK',
  },
  close: {
    id: `${baseId}.close`,
    defaultMessage: 'Close',
  },
})
