import { faCalendarDay } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { NavigationLink } from '~/features/navigation'

import { SchedulePageNavigationItemModel } from './SchedulePageNavigationItemViewModel'

interface SchedulePageNavigationItemViewProps {
  path: string
}

export const SchedulePageNavigationItemView = (
  useModel: () => SchedulePageNavigationItemModel
) => {
  const View = ({ path }: SchedulePageNavigationItemViewProps) => {
    const { granted, label } = useModel()

    if (!granted) {
      return null
    }

    return (
      <React.StrictMode>
        <NavigationLink
          data-testid="schedule-page-navigation-link"
          to={path}
          icon={
            <FontAwesomeIcon
              icon={faCalendarDay}
              style={{ height: 24, width: 24 }}
              color="#6B7785"
            />
          }
        >
          {label}
        </NavigationLink>
      </React.StrictMode>
    )
  }
  return View
}

export default SchedulePageNavigationItemView
