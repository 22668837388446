import React from 'react'

import {
  COMPLETE_REVIEW_ACTION,
  NORMAL_REVIEW_LABEL,
  REVIEW_CATEGORY,
} from '~/constants/analytics'
import { useDurationTracker } from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { CurrentConsultationSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import Card from '~/ui/Card'
import { Section } from '~/ui/Section'

import ConsultationSummary from './ConsultationSummary'

import messages from './ConsultationSummary.messages'

const CurrentConsultationSummary: CurrentConsultationSummaryPluginInterface = ({
  childComponents,
  consultationContext,
  light,
}) => {
  const f = useMessages(messages)
  const consultation = useConsultation(consultationContext)

  useDurationTracker({
    category: REVIEW_CATEGORY,
    label: NORMAL_REVIEW_LABEL,
    action: COMPLETE_REVIEW_ACTION,
  })

  if (!consultation) {
    return null
  }

  return (
    <React.StrictMode>
      <Card>
        <Section title={f('title')}>
          <ConsultationSummary
            scheduledTime={consultation.scheduledTime}
            consultation={consultation}
            childComponents={childComponents}
            light={light}
          />
        </Section>
      </Card>
    </React.StrictMode>
  )
}

export default CurrentConsultationSummary
