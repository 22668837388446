import { defineMessages } from 'react-intl'

const baseId = 'consultation-lite'

export default defineMessages({
  patient_has_been_notified: {
    id: `${baseId}.patient_has_been_notified`,
    defaultMessage: 'The patient has been notified to join the consultation',
  },
  error_loading_consultation: {
    id: `${baseId}.error_loading_consultation`,
    defaultMessage: 'Error loading the consultation',
  },
  consultation_not_found: {
    id: `${baseId}.consultation_not_found`,
    defaultMessage: 'Consultation not found',
  },
  leaving_incomplete_warning: {
    id: `${baseId}.leaving_incomplete_warning`,
    defaultMessage:
      'You are leaving before completing the consultation or marking it as no show. Are you sure you want to continue?',
  },
  dob_and_age: {
    id: `${baseId}.dob_and_age`,
    defaultMessage: '{dob} ({age}y)',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Clinical Portal',
  },
  today_title: {
    id: `${baseId}.today_title`,
    defaultMessage: 'Consultation at {formattedTime}',
  },
})
