import { MessageDescriptor } from 'react-intl'

import messages from './bloodPressureUtils.messages'

export const constructBloodPressureValue = (
  systolic: number | undefined,
  diastolic: number | undefined,
  fm: (key: MessageDescriptor) => string
) => {
  if (systolic && diastolic)
    return `${systolic}/${diastolic} ${fm(messages.blood_pressure_unit_v2)}`
  return fm(messages.na_text)
}
