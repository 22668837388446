import cn from 'classnames'
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { injectIntl } from 'react-intl'

import BabylonInput from '~/ui/BabylonInput'

import messages from './DosageEditor.messages'
import styles from './styles.module.scss'

const DosageEditor = ({ value: initialValue, options, intl, onChange }) => {
  const [selectedOptionIdx, setSelectedOption] = useState(0)
  const [optionsVisible, setOptionsVisible] = useState(false)

  const refInput = useRef(null)
  const refList = useRef(null)

  const hideOptions = useCallback((event) => {
    if (event.target !== refInput.current) {
      setOptionsVisible(false)
      window.removeEventListener('mousedown', hideOptions)
    }
  }, [])

  useEffect(() => {
    return () => {
      return window.removeEventListener('mousedown', hideOptions)
    }
  }, [hideOptions])

  const findLastWord = () => {
    const el = refInput.current
    if (el) {
      const { value } = el
      const start = el.selectionStart
      const end = el.selectionEnd

      if (start === end) {
        const results = value.match(/[^a-zA-Z]?([a-zA-Z][a-zA-Z0-9]*)$/)

        return (results && results[1]) || ''
      }
    }

    return ''
  }

  const insertWord = (word) => {
    const el = refInput.current
    const { value } = el
    const start = el.selectionStart - findLastWord().length
    const end = el.selectionEnd
    const newValue = `${value.substring(0, start)}${word}${value.substring(
      end
    )}`
    refInput.current.value = newValue
    el.focus()
    const pos = start + word.length
    el.setSelectionRange(pos, pos)
    onChange(newValue)
  }

  const filterOptions = (opts) => {
    if (!opts) {
      return []
    }
    const lastWord = findLastWord()
    if (lastWord && lastWord.length) {
      const lastWordLowerCase = lastWord.toLowerCase()
      const filteredOptions = opts.filter(
        (item) => item.shortcut.indexOf(lastWordLowerCase) === 0
      )
      return filteredOptions.length === 1 &&
        filteredOptions[0].shortcut.toUpperCase() === lastWord
        ? []
        : filteredOptions
    }
    return opts
  }

  const filteredOptions = filterOptions(options)
  const refOptions = useRef(filteredOptions.map(() => createRef()))

  useEffect(() => {
    const { current: option } = refOptions.current[selectedOptionIdx]
    const { current: list } = refList

    if (list && option) {
      const { offsetTop: optionOffset, clientHeight: optionHeight } = option
      const { scrollTop: listScrollTop, clientHeight: listHeight } = list
      const viewport = listScrollTop + listHeight
      const optionPosition = optionOffset + optionHeight

      if (optionPosition > viewport) {
        // Scroll the container downwards if the highlighted element is below the container viewport
        list.scrollTop = optionPosition - listHeight
      } else if (optionPosition <= listScrollTop) {
        // Scroll the container upwards if the highlighted element is above the container viewport
        list.scrollTop = optionOffset
      }
    }
  }, [selectedOptionIdx])

  const handleFocus = () => {
    setOptionsVisible(true)
    window.addEventListener('mousedown', hideOptions)
  }

  const handleMouseDown = (event) => {
    return event.stopPropagation()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setSelectedOption(
        Math.min(filteredOptions.length - 1, selectedOptionIdx + 1)
      )
      event.preventDefault()
    } else if (event.key === 'ArrowUp') {
      setSelectedOption(Math.max(0, selectedOptionIdx - 1))
      event.preventDefault()
    } else if (event.key === 'Enter') {
      const option = filteredOptions[selectedOptionIdx]
      if (option) {
        insertWord(option.shortcut.toUpperCase())
      }
    }
  }

  const handleChange = (event) => {
    setSelectedOption(0)
    if (onChange) onChange(event)
  }

  findLastWord()

  return (
    <div className={styles.dosageEditor} data-testid="dosage-editor">
      <BabylonInput
        placeholder={intl.formatMessage(messages.placeholder)}
        value={initialValue}
        onChange={handleChange}
        inputRef={refInput}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        data-testid="dropdown-input"
      />
      <div
        role="button"
        tabIndex={0}
        className={cn(styles.options, {
          [styles.hidden]: !optionsVisible || filteredOptions.length < 1,
        })}
        onMouseDown={handleMouseDown}
        ref={refList}
      >
        {filteredOptions.map(({ shortcut, description }, idx) => {
          const word = shortcut.toUpperCase()
          return (
            <div
              ref={refOptions.current[idx]}
              role="button"
              tabIndex={0}
              key={word}
              onClick={() => insertWord(word)}
              className={cn({
                [styles.selected]: selectedOptionIdx === idx,
              })}
              data-testid="dropdown-option"
            >
              {word} <span className={styles.desc}>- {description}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default injectIntl(DosageEditor)
