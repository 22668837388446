import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { ENABLE_SIGNER } from '~/constants'
import Message from '~/ui/Message'

import intlMessages from './SignerAlert.messages'

const messages = {
  'error.patient.home.address.missing': 'home address',
  'error.patient.first.name.missing': 'first name',
  'error.patient.last.name.missing': 'last name',
  'error.patient.date.of.birth.missing': 'date of birth',
}

const enumerationString = (words) =>
  words.join(', ').replace(/,([^,]*)$/, ' and $1')

const SignerAlert = ({ message = '', valid, errorCodes = [] }) => {
  const fm = useFormatMessage()

  if (!(ENABLE_SIGNER && !valid)) {
    return null
  }

  const missingString = enumerationString(
    errorCodes.map((code) => messages[code])
  )

  return (
    <Message type="error" title={fm(intlMessages.title, { missingString })}>
      {fm(intlMessages.message, { msg: message })}
    </Message>
  )
}

export default SignerAlert
