import { ApolloError } from '@apollo/client'
import { format as formatWithDefaultTimezone } from 'date-fns'
import { addMinutes, addWeeks, isEqual, startOfDay } from 'date-fns/fp'
import { format as formatWithGivenTimezone, toDate } from 'date-fns-tz'
import { isEmpty, xorWith } from 'lodash'
import { filter, flow, map } from 'lodash/fp'
import { useEffect, useRef } from 'react'

import { SelectOptionTypeBase, SelectValueType } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import {
  APPOINTMENT_INVITES_CATEGORY,
  APPOINTMENT_INVITES_CREATE_MODAL_INCOMPLETE_ACTION,
} from '~/constants/analytics'
import { isTruthy } from '~/core'
import analytics from '~/core/analytics'
import { useFeatureFlags } from '~/core/core-modules'
import { logException } from '~/core/sentry'

import { ServiceType } from '../types'
import { ProviderType } from './AppointmentInvitesModalView'
import {
  ConsumerNetworkProfession,
  InviteFormMedium,
  InviteFormValues,
} from './types'

import messages from './AppointmentInvitesModal.messages'

export type FormOption<V> = {
  label: string
  value: V
}

export const useIsRecurrenceInviteBooking = (): ((
  selectedServiceType: InviteFormValues['serviceType'],
  selectedProviderType: InviteFormValues['providerType']
) => boolean) => {
  const { recurringInvitesBookingForPatientEnabled } = useFeatureFlags()

  return (selectedServiceType, selectedProviderType) => {
    const recurrencePatternsAmount =
      selectedServiceType?.service_type_settings?.recurrence_settings
        ?.recurrence_patterns?.length
    return isTruthy(
      recurringInvitesBookingForPatientEnabled &&
        recurrencePatternsAmount &&
        recurrencePatternsAmount > 0 &&
        selectedProviderType === ProviderType.currentConsultant
    )
  }
}

export const getProfessionOptions = (
  professions: ConsumerNetworkProfession[],
  professionName: string,
  provider?: InviteFormValues['providerType']
): FormOption<string>[] => {
  return professions
    .filter((profession) => {
      return provider === ProviderType.currentConsultant
        ? professionName.includes(profession.name)
        : true
    })
    .map((profession) => {
      return {
        label: profession.name,
        value: profession.id,
      }
    })
}

export const getServiceTypeOptions = (
  allServiceTypes: ServiceType[],
  clinicianServiceTypes: ServiceType[],
  provider: InviteFormValues['providerType'],
  selectedMedium: InviteFormValues['medium']
) => {
  const serviceTypes =
    provider === ProviderType.currentConsultant
      ? clinicianServiceTypes
      : allServiceTypes

  let transform: (
    serviceTypes: ServiceType[]
  ) => FormOption<ServiceType>[] = map(
    (s: ServiceType): FormOption<ServiceType> => ({
      value: s,
      label: s.name,
    })
  )

  if (selectedMedium === InviteFormMedium.faceToFace) {
    const isF2FServiceType = (s: ServiceType) =>
      s.allowed_mediums.includes('physical')

    transform = flow(filter(isF2FServiceType), transform)
  } else if (selectedMedium === InviteFormMedium.digital) {
    const isDigitalServiceType = (s: ServiceType) =>
      s.allowed_mediums.includes('video') || s.allowed_mediums.includes('voice')

    transform = flow(filter(isDigitalServiceType), transform)
  }

  return transform(serviceTypes)
}

export const getDurationOptions = (
  professions: ConsumerNetworkProfession[],
  selectedProfessionId: InviteFormValues['profession'],
  f: (key: keyof typeof messages, ...args: any) => string
) => {
  const selectedProfession = professions.find(
    ({ id }) => id === String(selectedProfessionId)
  )

  const defaultDuration = selectedProfession?.defaultSlotDurationMinutes
  const defaultDurationArray = [defaultDuration]

  const filteredDurations = selectedProfession?.alternativeSlotDurationMinutes.filter(
    (duration) => !defaultDurationArray.includes(duration)
  )

  const durationOptions =
    filteredDurations?.map((duration) => ({
      label: f('duration_option', { duration }),
      value: duration,
    })) || []

  if (selectedProfession) {
    return [
      {
        label: f('recommended_duration_option', { defaultDuration }),
        value: defaultDuration || 0,
      },
      ...durationOptions,
    ]
  }
  return []
}

export const getRecurringDatesArray = (
  numberOfAppointments: number,
  interval: number,
  startDate: Date,
  formatFn: (date: Date) => string
) =>
  Array.from({ length: numberOfAppointments * interval }, (_, i) =>
    flow(addWeeks(i), (date) => {
      if (i === 0) {
        return formatFn(date)
      }
      if (interval && i % interval === 0) {
        return formatFn(date)
      }
      return undefined
    })(startDate)
  ).filter((date) => !!date)

export const matchRecommendedDuration = (
  professions: ConsumerNetworkProfession[],
  selectedProfessionId: InviteFormValues['profession'],
  selectedDuration: SelectValueType
) => {
  const selectedProfession = professions.find(
    ({ id }) => id === String(selectedProfessionId)
  )
  const recommendedDuration = selectedProfession?.defaultSlotDurationMinutes
  const duration = (selectedDuration as SelectOptionTypeBase).value

  return recommendedDuration !== duration
}

type TimeOption = {
  label: string
  value: NonNullable<InviteFormValues['time']>
}

export const makeTimeOptions = (
  resolutionInMinutes: number,
  timeFormatter: (date: Date) => string
): TimeOption[] => {
  const addResolution = addMinutes(Math.floor(resolutionInMinutes))
  const midnight = startOfDay(new Date())
  const timeOptions: TimeOption[] = []
  let nextDate = midnight
  while (nextDate.getDay() === midnight.getDay()) {
    timeOptions.push({
      label: timeFormatter(nextDate),
      value: {
        hour: nextDate.getHours(),
        minutes: nextDate.getMinutes(),
      },
    })
    nextDate = addResolution(nextDate)
  }
  return timeOptions
}

const ISO_DATE_TIME_FORMAT: string = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
export function formatToISOZonedDateTime(
  dateTime: Date,
  timezoneId?: Maybe<string>
): string {
  if (!timezoneId) {
    return formatWithDefaultTimezone(dateTime, ISO_DATE_TIME_FORMAT)
  }

  try {
    return formatWithGivenTimezone(toDate(dateTime), ISO_DATE_TIME_FORMAT, {
      timeZone: timezoneId,
    })
  } catch (e) {
    logException(e)
    return formatWithDefaultTimezone(dateTime, ISO_DATE_TIME_FORMAT)
  }
}

export function formatToISOLocalDate(dateTime: Date): string {
  return formatWithDefaultTimezone(dateTime, 'yyyy-MM-dd')
}

const isIncompleteBookingEqual = (
  dates: Date[],
  prevDates: Date[] | undefined
): boolean => {
  if (!prevDates) return false
  return isEmpty(xorWith(dates, prevDates, isEqual))
}

export const useTrackIncompleteBookingsDialog = (
  incompleteBookings: Date[] | undefined
) => {
  const intl = useIntl()
  const incompleteBookingTrackedRef = useRef<Date[] | undefined>(undefined)
  useEffect(() => {
    const formatDate = (date: Date) =>
      intl.formatDate(date, { format: 'shortWithTime' })
    if (
      !!incompleteBookings?.length &&
      !isIncompleteBookingEqual(
        incompleteBookings,
        incompleteBookingTrackedRef.current
      )
    ) {
      incompleteBookingTrackedRef.current = incompleteBookings
      analytics.trackEvent({
        action: APPOINTMENT_INVITES_CREATE_MODAL_INCOMPLETE_ACTION,
        category: APPOINTMENT_INVITES_CATEGORY,
        label: incompleteBookings?.map(formatDate).join('. '),
      })
    }
  }, [incompleteBookings, intl])
}

export const isAvailabilityError = (error: ApolloError): boolean => {
  const unprocessableEntityError = error.graphQLErrors.find(
    (ge) => ge.extensions?.response?.status === 422
  )

  if (unprocessableEntityError) {
    return (
      unprocessableEntityError.extensions?.response?.body?.error ===
      'AVAILABILITY_ISSUE'
    )
  }

  return false
}
