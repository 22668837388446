import { defineMessages } from 'react-intl'

const baseId = 'consultation.complete_dialog'

export default defineMessages({
  complete_title: {
    id: `${baseId}.complete_title`,
    defaultMessage: 'Complete consultation',
  },
  update_title: {
    id: `${baseId}.update_title`,
    defaultMessage: 'Update consultation',
  },
  complete_button_label: {
    id: `${baseId}.complete_button_label`,
    defaultMessage: 'Complete',
  },
  update_button_label: {
    id: `${baseId}.update_button_label`,
    defaultMessage: 'Update',
  },
  try_again_button_label: {
    id: `${baseId}.try_again_button_label`,
    defaultMessage: 'Try again',
  },
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage:
      'Failed to complete consultation.  Please try again or contact support',
  },
  error_loading_us_ehr_notes: {
    id: `${baseId}.error_loading_us_ehr_notes`,
    defaultMessage: 'Failed to load EHR notes.',
  },
  error_loading_prescriptions: {
    id: `${baseId}.error_loading_prescriptions`,
    defaultMessage: 'Failed to load prescriptions.',
  },
  error_promoting_prescriptions: {
    id: `${baseId}.error_promoting_prescriptions`,
    defaultMessage: 'Failed to submit prescriptions.',
  },
  error_validating_secure_pin: {
    id: `${baseId}.error_validating_secure_pin`,
    defaultMessage: "That's not your prescriptions pin code.",
  },
  default_complete_message: {
    id: `${baseId}.default_complete_message`,
    defaultMessage: 'The patient will get a copy of your consultation notes.',
  },
})
