import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik'
import React from 'react'

import { Button, Cell, FormTextarea, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './PatientAlertForm.messages'

// TODO - add type for fm argument
const validate = (fm: any) => (values: PatientAlertFormValues) => {
  const errors: FormikErrors<PatientAlertFormValues> = {}

  if (values.message.trim().length === 0) {
    errors.message = fm(messages.message_validation_required)
  }

  return errors
}

export interface PatientAlertFormValues {
  message: string
}

interface PatientAlertFormProps {
  mode: 'create' | 'edit'
  initialValues: PatientAlertFormValues
  error: string | undefined
  onSubmit: (
    values: PatientAlertFormValues,
    form: FormikHelpers<PatientAlertFormValues>
  ) => Promise<void>
  onRemove?: () => void
  onReset?: () => void
  onCancel: () => void
}

const PatientAlertForm = ({
  mode,
  initialValues,
  error,
  onSubmit,
  onRemove,
  onReset = console.log,
  onCancel,
}: PatientAlertFormProps) => {
  const fm = useFormatMessage()

  return (
    <Formik
      initialValues={initialValues}
      validate={validate(fm)}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {(props) => (
        <Form>
          <Grid columns={6}>
            <Cell width={6}>
              <Field
                id="message"
                type="text"
                name="message"
                placeholder={fm(messages.message_box_placeholder)}
                label={fm(messages.message_box_label)}
                component={FormTextarea}
              />
            </Cell>
            {/* TODO: Remove this with Grid is more flexible */}
            <Cell width={mode === 'edit' ? 3 : 4} />
            <Button
              intent="secondary"
              loading={props.isSubmitting}
              onClick={onCancel}
              fill
            >
              {fm(messages.cancel_button_label)}
            </Button>
            {mode === 'create' && (
              <Button
                type="submit"
                intent="primary"
                loading={props.isSubmitting}
                fill
              >
                {fm(messages.create_button_label)}
              </Button>
            )}
            {mode === 'edit' && (
              <>
                <Button
                  type="submit"
                  intent="primary"
                  loading={props.isSubmitting}
                  fill
                >
                  {fm(messages.update_button_label)}
                </Button>

                <Button
                  intent="error"
                  loading={props.isSubmitting}
                  onClick={onRemove}
                  fill
                >
                  {fm(messages.remove_button_label)}
                </Button>
              </>
            )}
            <Cell width={6}>{error && <Text color="error">{error}</Text>}</Cell>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default PatientAlertForm
