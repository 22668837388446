import { useMutation } from '@apollo/client'
import set from 'lodash/fp/set'
import { useState } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { ValueEntry } from '~/features/patient-metrics/PatientMetrics/PatientMetrics'
import { MIN_AGE_FOR_BMI } from '~/features/patient-metrics/utils/bmi/bmiUtils'
import {
  ADD_PATIENT_METRICS_MUTATION,
  CONSULTATION_PATIENT_METRICS_QUERY,
  queryVariableConstants,
  usePatientMetricsQuery,
} from '~/features/patient-metrics/utils/dataManagement'
import { extractTimestamps } from '~/features/patient-metrics/utils/Timestamp'
import { PatientMetricObservationType, PatientMetricSource } from '~/generated'

import { PatientMetricsState } from './PatientMetricsStateProvider'

export enum BloodPressureCodes {
  SYSTOLIC = 'systolic',
  DIASTOLIC = 'diastolic',
}

const useSubmitInfo = () => {
  const [submitting, setSubmitting] = useState<BooleanRecord>({})
  const [submitError, setSubmitError] = useState<BooleanRecord>({})
  return { submitting, setSubmitting, submitError, setSubmitError }
}

type BooleanRecord = Record<string, boolean>

export interface SubmitInfo {
  submitting: BooleanRecord
  error: BooleanRecord
}

export interface OnSubmitType {
  (
    tag: PatientMetricObservationType[],
    values: PatientMetricsState,
    parentTag: PatientMetricObservationType
  ): void
  (tag: PatientMetricObservationType, values: PatientMetricsState): void
}
interface UsePatientMetricsDataParams {
  loading: boolean
  error?: Error
  refetch: () => void
  initialValues?: PatientMetricsState
  displayValues?: PatientMetricsState
  onSubmit: OnSubmitType
  submitInfo: SubmitInfo
}

const usePatientMetricsData = (
  consultationId: string
): UsePatientMetricsDataParams => {
  const variables = {
    id: consultationId,
    patientMetricsCount: 1,
    ...queryVariableConstants,
  }

  const { data, error, refetch, loading } = usePatientMetricsQuery({
    id: consultationId,
  })

  const user = useConsultantUser()

  const [addPatientMetric] = useMutation(ADD_PATIENT_METRICS_MUTATION)

  const {
    submitting,
    setSubmitting,
    submitError,
    setSubmitError,
  } = useSubmitInfo()

  const mutationFilter = PatientMetricSource.ChrPrimaryStore

  const clinicianUuid = user?.consultant?.uuid
  const clinicianId = user?.consultant?.id

  const patientUuid = data?.consultation?.patient?.uuid
  const patientId = data?.consultation?.patient?.id
  const consultationUuid = data?.consultation?.uuid
  const consultationType = data?.consultation?.consultationType

  const initialValues = loading
    ? undefined
    : {
        height: undefined,
        weight: undefined,
        smoking_status: undefined,
        systolic: undefined,
        diastolic: undefined,
      }

  const updateSubmitInfo = (
    observationType:
      | PatientMetricObservationType
      | PatientMetricObservationType[],
    submittingValue: boolean,
    submitErrorValue: boolean
  ) => {
    const formatObservationsWithValue = (value: boolean) => {
      return Array.isArray(observationType)
        ? observationType.reduce((acc: any, obs: string) => {
            acc[obs] = value
            return acc
          }, {})
        : { [observationType]: value }
    }

    setSubmitting((submitting) => {
      return {
        ...submitting,
        ...formatObservationsWithValue(submittingValue),
      }
    })

    setSubmitError((submitError) => {
      return {
        ...submitError,
        ...formatObservationsWithValue(submitErrorValue),
      }
    })
  }

  const onSubmit = (
    observationType:
      | PatientMetricObservationType
      | PatientMetricObservationType[],
    values: PatientMetricsState,
    parentObservationType?: PatientMetricObservationType
  ) => {
    updateSubmitInfo(observationType, true, false)

    return new Promise((resolve, reject) => {
      const mutationVariables = {
        patientUuid,
        patientId,
        consultationId,
        clinicianId,
        clinicianUuid,
        observationType: Array.isArray(observationType)
          ? parentObservationType
          : observationType,
        input: Array.isArray(observationType)
          ? observationType.reduce(
              (acc: any, curr: PatientMetricObservationType) => {
                acc[curr] = values[curr]
                return acc
              },
              {}
            )
          : values[observationType],
        patientMetricsFilter: mutationFilter,
        consultationType,
        consultationUuid,
      }

      addPatientMetric({
        variables: mutationVariables,
        context: { headers: { 'Time-Zone-Offset': 'Z' } },
        update: (proxy, { data: { addPatientMetric } }) => {
          const updatedData = {
            ...addPatientMetric,
            value: !Array.isArray(observationType) && values[observationType],
            values:
              Array.isArray(observationType) &&
              observationType.reduce(
                (acc: any, curr: PatientMetricObservationType) => {
                  acc.push({ code: curr, value: values[curr] })
                  return acc
                },
                []
              ),
          }
          try {
            // get current data from the cache
            const cacheData: any = proxy.readQuery({
              query: CONSULTATION_PATIENT_METRICS_QUERY,
              variables,
            })
            // write the new data
            const data: any = set(
              `consultation.patient.patient_metrics.${
                parentObservationType || observationType
              }`,
              [updatedData],
              cacheData
            )

            proxy.writeQuery({
              query: CONSULTATION_PATIENT_METRICS_QUERY,
              variables,
              data,
            })
            updateSubmitInfo(observationType, false, false)
            resolve(null)
          } catch (e) {
            updateSubmitInfo(observationType, false, true)
            reject()
          }
        },
      }).catch(() => {
        updateSubmitInfo(observationType, false, true)
        reject()
      })
    })
  }

  const metrics = data?.consultation?.patient?.patient_metrics
  const displayHeight = metrics?.height?.[0]?.value
  const displayWeight = metrics?.weight?.[0]?.value
  const performerHeight = metrics?.height?.[0]?.performer
  const performerWeight = metrics?.weight?.[0]?.performer
  const performerSmoking = metrics?.smoking_status?.[0]?.performer
  const performerBloodPressure = metrics?.blood_pressure?.[0]?.performer
  const displaySmokingStatus = metrics?.smoking_status?.[0]?.value

  const bloodPressureValues = metrics?.blood_pressure?.[0]?.values

  const displaySystolic = bloodPressureValues?.find(
    // @ts-expect-error
    (val: ValueEntry) => val.code === BloodPressureCodes.SYSTOLIC
  )?.value

  const displayDiastolic = bloodPressureValues?.find(
    // @ts-expect-error
    (val: ValueEntry) => val.code === BloodPressureCodes.DIASTOLIC
  )?.value

  const ageInYears = data?.consultation?.patient?.age ?? MIN_AGE_FOR_BMI

  const displayValues = loading
    ? undefined
    : {
        displayHeight,
        displayWeight,
        displaySmokingStatus,
        displaySystolic,
        displayDiastolic,
        ageInYears,
        performerBloodPressure,
        performerHeight,
        performerWeight,
        performerSmoking,
        ...extractTimestamps(metrics),
      }

  return {
    loading,
    error,
    initialValues,
    displayValues,
    onSubmit,
    refetch,
    submitInfo: {
      submitting,
      error: submitError,
    },
  }
}

export default usePatientMetricsData
