import { ApolloError } from '@apollo/client'
import { isAfter } from 'date-fns'

import { SelectOptionTypeBase } from '@babylon/core-ui'

import {
  CHATBOT_FLOWS_ACTION,
  PATIENT_TIMELINE_CATEGORY,
} from '~/constants/analytics'
import { isFilledArray, isTruthy } from '~/core'
import analytics from '~/core/analytics/analytics'
import { TimelineEventType } from '~/generated'

import { DEFAULT_OPTIONS } from './constants'
import { TimelineError } from './usePatientTimelineQuery'

const eventsGaActions: Partial<Record<TimelineEventType, string>> = {
  [TimelineEventType.ChatscriptConversation]: CHATBOT_FLOWS_ACTION,
}

type EventTypesConfig = {
  consultationsEnabled?: boolean
  chatscriptConversationsEnabled?: boolean
}

export const eventTypesMap: {
  [key in keyof EventTypesConfig]: TimelineEventType
} = {
  consultationsEnabled: TimelineEventType.Consultation,
  chatscriptConversationsEnabled: TimelineEventType.ChatscriptConversation,
}

export const getEventTypeOptionsFromConfig = (
  eventTypes: EventTypesConfig
): SelectOptionTypeBase[] =>
  Object.entries(eventTypes)
    .map(([key, value]) =>
      value
        ? DEFAULT_OPTIONS.find(
            (option) =>
              option.value === eventTypesMap[key as keyof EventTypesConfig]
          )
        : null
    )
    .filter(isTruthy)

export const getEventTypeOptions = (
  eventTypes: EventTypesConfig,
  canSeeFullHistory: boolean,
  isFutureEvents?: boolean
): SelectOptionTypeBase[] => {
  const configEventTypes = getEventTypeOptionsFromConfig(eventTypes)

  // we dont want to show filter select if section is displaying future events
  return isFutureEvents
    ? []
    : canSeeFullHistory
    ? configEventTypes
    : configEventTypes.filter(
        (option) => option.value === TimelineEventType.Consultation
      )
}

export const getRequestError = (
  message: string,
  responseErrors: TimelineError[] | undefined
) => {
  const formattedMessage = `${message}: ${responseErrors?.map((error) => {
    try {
      const parsedError = JSON.parse(error.fullError)?.extensions
      return `${error.type}(${
        parsedError?.response?.status || parsedError?.code || 'no error code'
      }); `
    } catch (e) {
      return `${error.type} INTERNAL_ERROR `
    }
  })}`

  return isFilledArray(responseErrors)
    ? new ApolloError({
        errorMessage: formattedMessage,
        graphQLErrors: responseErrors.map((error) => {
          try {
            return JSON.parse(error.fullError)
          } catch (e) {
            return 'INTERNAL_ERROR'
          }
        }),
      })
    : null
}

export const trackTimelineEvents = <
  T extends { eventType?: TimelineEventType | null; eventTime?: string }
>(
  events: T[],
  type: TimelineEventType
) => {
  const trackedEvents = events.filter((event) => event.eventType === type)
  const eventAction = eventsGaActions[type]

  if (trackedEvents?.length && eventAction) {
    analytics.trackEvent({
      label: trackedEvents[0].eventTime,
      category: PATIENT_TIMELINE_CATEGORY,
      action: eventAction,
      value: trackedEvents?.length,
    })
  }
}

export const mapColoredOptions = (
  types: TimelineEventType[],
  options: SelectOptionTypeBase[],
  errors: TimelineError[] | undefined
) => {
  return types.map((type) => {
    const selectOption = options.find((option) => option.value === type)

    return {
      ...selectOption,
      tagColor: errors?.some((error) => error.type === type)
        ? 'clinical-red'
        : 'clinical-blue',
    }
  })
}

export const isEventInFuture = (eventTime: string): boolean =>
  isAfter(new Date(eventTime), new Date())
