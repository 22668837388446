import { useCallback, useRef, useState } from 'react'

import { useHasMounted, useMount } from '~/core/hooks'

import { OTEventHandlerObject, PushEventHandlers } from './types'

const devLog =
  process.env.NODE_ENV === 'development'
    ? (...args: any) => console.log(...args)
    : () => null

type CreateEventHandlerObject = (
  eventNames: string[],
  ...args: (OTEventHandlerObject | undefined)[]
) => OTEventHandlerObject

const createEventHandlerObject: CreateEventHandlerObject = (
  eventNames,
  ...args
) =>
  eventNames.reduce<OTEventHandlerObject>((eventHandlers, eventName) => {
    if (eventName === 'audioLevelUpdated') {
      return eventHandlers
    }

    return {
      ...eventHandlers,
      [eventName]: (event) => {
        devLog(`opentok event: ${event?.type}`, event)
        args.forEach((handlerObject) => {
          const handler = handlerObject?.[eventName]
          if (handler) {
            handler(event)
          }
        })
      },
    }
  }, {})

type UseMergeEventHandlers = (
  eventNames: string[],
  initialEventHandlers?: OTEventHandlerObject
) => [OTEventHandlerObject | undefined, PushEventHandlers]

const useMergeEventHandlers: UseMergeEventHandlers = (
  eventNames,
  initialEventHandlers
) => {
  const additionalEventHandlers = useRef<OTEventHandlerObject[]>([])
  const [eventHandlers, setEventHandlers] = useState<OTEventHandlerObject>()
  const hasMounted = useHasMounted()

  const pushEventHandlers: PushEventHandlers = useCallback(
    (newEventHandlers) => {
      if (hasMounted()) {
        console.error('Cannot set opentok event handlers after mounting')
        return
      }
      additionalEventHandlers.current.push(newEventHandlers)
    },
    [hasMounted]
  )

  useMount(() => {
    const mergedEventHandlers = createEventHandlerObject(
      eventNames,
      initialEventHandlers,
      ...additionalEventHandlers.current
    )
    setEventHandlers(mergedEventHandlers)
  })

  return [eventHandlers, pushEventHandlers]
}

export default useMergeEventHandlers
