import { defineMessages } from 'react-intl'

const baseId = 'resources_and_links'

export default defineMessages({
  button_title: {
    id: `${baseId}.release_notes_title_v2`,
    defaultMessage: 'Links and resources',
  },
})
