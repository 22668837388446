import React, { useEffect } from 'react'

import { User } from '@babylon/babylon-user'
import { ResponsiveTable, Tag, Text } from '@babylon/core-ui'

import {
  PBL_CODES_TOTAL,
  PBL_EXPANDED_CATEGORY,
  PBL_EXPANDED_TOTAL,
  PBL_MODAL_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'

import ProblemGroup from './ProblemGroup'
import { ProblemRecord } from './utils/types'

import styles from './styles.module.scss'

const formattedTableData = (
  visibleProblemsList: Array<Array<ProblemRecord>>,
  consultant: User['consultant'],
  consultationId: string,
  handleConsultationClick: (record: ProblemRecord) => void
) =>
  visibleProblemsList.map((problemGroup: Array<ProblemRecord>) => {
    return {
      entries: problemGroup,
      diagnosis: (
        <div className={styles.problem} data-testid="problem-tag">
          {problemGroup[0].code ? (
            <Tag
              className={styles.clinicalCode}
              uppercase={false}
              margin={false}
            >
              {problemGroup[0].code}
            </Tag>
          ) : (
            <Text tag="div" className={styles.clinicalNote} color="grey-type">
              {problemGroup[0].note}
            </Text>
          )}
          {problemGroup.length > 1 && (
            <Text size="small" color="grey-type" style={{ marginLeft: '10px' }}>
              {`(${problemGroup.length})`}
            </Text>
          )}
        </div>
      ),
      dates: (
        <ProblemGroup
          consultationId={consultationId}
          problemGroup={problemGroup}
          consultant={consultant}
          handleConsultationClick={handleConsultationClick}
        />
      ),
    }
  })

const columns = [
  {
    accessor: 'diagnosis',
    size: 'minmax(300px, 300px)',
  },
  {
    accessor: 'dates',
    size: 'minmax(290px, auto)',
  },
]

interface ProblemsTableProps {
  problemsList: Array<Array<ProblemRecord>>
  visibleCount: number
  handleRowClick: (row: any) => any
  consultant: User['consultant']
  handleConsultationClick: (record: ProblemRecord) => void
  consultationId: string
}

const ProblemsTable = ({
  problemsList,
  visibleCount,
  handleRowClick,
  consultant,
  handleConsultationClick,
  consultationId,
}: ProblemsTableProps) => {
  const expandableProblemsListLength = problemsList.filter(
    (problemGroup: Array<ProblemRecord>) => problemGroup.length > 2
  ).length
  useEffect(() => {
    // Tracks if this consultation has problems with more than two event
    analytics.trackEvent({
      category: PBL_EXPANDED_CATEGORY,
      action: consultationId,
      label: PBL_EXPANDED_TOTAL,
      value: expandableProblemsListLength,
    })
    // Tracks the total number of problems for this consultation
    analytics.trackEvent({
      category: PBL_MODAL_CATEGORY,
      action: consultationId,
      label: PBL_CODES_TOTAL,
      value: problemsList.length,
    })
  }, [consultationId, problemsList.length, expandableProblemsListLength])

  const visibleProblemsList = problemsList.slice(0, visibleCount)

  return (
    <ResponsiveTable
      fullWidth
      showRowDividers={false}
      showHeader={false}
      collapseBreakpoint={1100}
      responsiveBehaviour="collapseRows"
      rowClickHandler={handleRowClick}
      columns={columns}
      cellPadding="small"
      data={formattedTableData(
        visibleProblemsList,
        consultant,
        consultationId,
        handleConsultationClick
      )}
    />
  )
}

export default ProblemsTable
