import { gql } from '@apollo/client'

export const GpDetailsFragment = gql`
  fragment GpDetailsFragment on Consultation {
    id
    gpConsent
    patient {
      id
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
      gp_full_address
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
      region {
        id
        name
      }
    }
  }
`
export const GpConsentFragment = gql`
  fragment GpConsentFragment on Consultation {
    gpConsent
  }
`
