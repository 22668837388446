import { CarePlanDraftQuery } from '~/generated'

import {
  LocalActionDraft,
  LocalCarePlanDraft,
  LocalGoalDraft,
} from '../../CarePlan.types'

let nextLocalId = 0

export const addLocalIdsToTransformedCarePlanDraft = (
  carePlanDraft: Extract<
    CarePlanDraftQuery['carePlanDraft'],
    { __typename: 'CarePlanDraft' }
  >
): LocalCarePlanDraft => ({
  ...carePlanDraft,
  goals: carePlanDraft.goals.map((goal) => ({
    ...goal,
    localId: nextLocalId++,
    associatedActionDrafts:
      goal.associatedActionDrafts?.map((action) => ({
        ...action,
        localId: nextLocalId++,
      })) || [],
  })),
})

export const getEmptyLocalGoal: () => LocalGoalDraft = () => ({
  localId: nextLocalId++,
  associatedActionDrafts: [],
})

export const getEmptyLocalAction: () => LocalActionDraft = () => ({
  localId: nextLocalId++,
})
