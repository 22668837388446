import React, { useState, useEffect } from 'react'
import { Button, Heading, Textarea, Text } from '@babylon/core-ui'
import cx from 'classnames'

import styles from '../styles.module.scss'

const MAX_SUPPORT_MESSAGE_LENGTH = 2000

const isTextWithinMaxLength = (message, length) =>
  !message || message.length <= length

const getErrorMessage = ({ showEmailRequiredError, showLengthError }) => {
  if (showEmailRequiredError) {
    return 'Required'
  } else if (showLengthError) {
    return 'Max number of characters reached'
  }

  return ''
}

export default ({ submitting, onSubmit }) => {
  const [supportMessage, setSupportMessage] = useState(null)
  const [showEmailRequiredError, setshowEmailRequiredError] = useState(false)
  const [showLengthError, setShowLengthError] = useState(false)
  const hasVisibleError = showEmailRequiredError || showLengthError

  const textAreaClass = cx(styles['report__decorated-textarea__input'], {
    [styles['report__decorated-textarea__error-border']]: hasVisibleError,
  })

  const handleButtonClick = () => {
    const messageIsTooLong = !isTextWithinMaxLength(
      supportMessage,
      MAX_SUPPORT_MESSAGE_LENGTH
    )
    const hasError = messageIsTooLong || !supportMessage

    setshowEmailRequiredError(!supportMessage)
    setShowLengthError(messageIsTooLong)

    if (!hasError) {
      onSubmit({ supportMessage })
    }
  }

  useEffect(() => {
    if (
      showLengthError &&
      isTextWithinMaxLength(supportMessage, MAX_SUPPORT_MESSAGE_LENGTH)
    ) {
      setShowLengthError(false)
    }

    if (supportMessage) {
      setshowEmailRequiredError(false)
    }
  }, [showLengthError, supportMessage])

  return (
    <div data-testid="reviewer-email-form">
      <Heading level="h3">Send email to ClinOps</Heading>
      <div className={styles['report__decorated-textarea']}>
        <Textarea
          placeholder="Write a comment to ClinOps describing the issue. The patient ID, the test name and the date received will be automatically included in the email."
          className={textAreaClass}
          onChange={(event) => setSupportMessage(event.target.value)}
          data-testid="support-message-textarea"
        />
        {hasVisibleError && (
          <Text
            color="error"
            className={styles['report__decorated-textarea__left']}
            size="small"
          >
            {getErrorMessage({ showEmailRequiredError, showLengthError })}
          </Text>
        )}
        <Text
          className={styles['report__decorated-textarea__right']}
          color={hasVisibleError ? 'error' : 'grey-type'}
          size="small"
        >
          ({(supportMessage || '').length}/2000)
        </Text>
      </div>
      <div className={styles['report__button-wrapper']}>
        <Button
          intent="error"
          loading={submitting}
          disabled={submitting}
          onClick={handleButtonClick}
          testId="send-email-button"
          className={styles['report__submit-button']}
        >
          Send email to ClinOps
        </Button>
      </div>
    </div>
  )
}
