import cn from 'classnames'
import React, { ChangeEvent } from 'react'

import { Input } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { isUSRegion } from '~/core/region'

import messages from './AddressInput.messages'
import styles from './styles.module.scss'

export interface AddressInputProps {
  values: Record<
    | 'address_first_line'
    | 'address_second_line'
    | 'address_third_line'
    | 'address_state_code'
    | 'address_post_code',
    string
  >
  regionName: string
  onAddressChange: (
    key: string
  ) => (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
  disabled?: boolean
}

const AddressInput = ({
  values,
  regionName,
  onAddressChange,
  className,
  disabled,
}: AddressInputProps) => {
  const f = useMessages(messages)

  const commonFields = [
    {
      placeholder: f('address_line_1_placeholder'),
      onChange: onAddressChange('address_first_line'),
      value: values.address_first_line || '',
      'aria-label': f('address_line_1_placeholder'),
    },
    {
      placeholder: f('address_line_2_placeholder'),
      onChange: onAddressChange('address_second_line'),
      value: values.address_second_line || '',
      'aria-label': f('address_line_2_placeholder'),
    },
  ]

  const usaFormatting = isUSRegion(regionName)

  const fields = usaFormatting
    ? [
        ...commonFields,
        {
          placeholder: f('address_city_placeholder'),
          onChange: onAddressChange('address_third_line'),
          value: values.address_third_line || '',
          'aria-label': f('address_city_placeholder'),
        },
        {
          placeholder: f('address_state_code_placeholder'),
          onChange: onAddressChange('address_state_code'),
          value: values.address_state_code || '',
          className: styles.half,
          'aria-label': f('address_state_code_placeholder'),
        },
        {
          placeholder: f('postcode_label'),
          onChange: onAddressChange('address_post_code'),
          value: values.address_post_code || '',
          className: styles.half,
          'aria-label': f('postcode_label'),
        },
      ]
    : [
        ...commonFields,
        {
          placeholder: f('address_line_3_placeholder'),
          onChange: onAddressChange('address_third_line'),
          value: values.address_third_line || '',
          'aria-label': f('address_line_3_placeholder'),
        },
        {
          placeholder: f('postcode_label'),
          onChange: onAddressChange('address_post_code'),
          value: values.address_post_code || '',
          'aria-label': f('postcode_label'),
        },
      ]

  return (
    <div className={cn(styles.fieldset, className)}>
      {fields.map((field) => (
        <Input fill {...field} key={field.placeholder} disabled={disabled} />
      ))}
    </div>
  )
}

export default AddressInput
