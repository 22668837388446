import { sanitizeUrl } from '@braintree/sanitize-url'
import partition from 'lodash/partition'
import React from 'react'

import { isFilledArray } from '~/core'
import { AttachmentsSummaryQueryResult } from '~/generated'
import LightboxGallery from '~/ui/LightboxGallery'

import styles from './styles.module.scss'

export type Attachment = NonNullable<
  NonNullable<
    NonNullable<AttachmentsSummaryQueryResult['data']>['consultation']
  >['testResults']
>[number]

interface DefaultPdfComponentProps {
  url?: string
  fileName?: string | null
}

export interface ConsultationAttachmentsProps {
  attachments: Attachment[]
  renderPdfComponent?: (
    attachment: DefaultPdfComponentProps
  ) => JSX.Element | null
}

const DefaultPdfComponent = ({ url, fileName }: DefaultPdfComponentProps) => {
  if (!url) {
    return null
  }

  return (
    <div>
      <a
        className={styles.pdfLink}
        target="_blank"
        rel="noopener noreferrer"
        href={sanitizeUrl(url)}
      >
        <i className="fas fa-download" />
        {fileName}
      </a>
    </div>
  )
}

const ConsultationAttachments = ({
  attachments,
  renderPdfComponent = DefaultPdfComponent,
}: ConsultationAttachmentsProps) => {
  const [pdfs = [], images = []] = partition(
    attachments,
    (attachment) => attachment?.type === 'pdf'
  )
  const lightboxImages = images.map((image) => ({
    ...image,
    title: image?.fileName ?? '',
  }))

  return (
    <div className={styles.attachmentsLayout}>
      {isFilledArray(pdfs) && (
        <div>
          {pdfs.map((pdf: Attachment, i) => {
            return pdf ? (
              <div key={pdf.id || i}>{renderPdfComponent(pdf)}</div>
            ) : null
          })}
        </div>
      )}
      {isFilledArray(lightboxImages) && (
        <LightboxGallery images={lightboxImages} />
      )}
    </div>
  )
}

export default ConsultationAttachments
