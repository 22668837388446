import React from 'react'

import Spinner from '../Spinner'
import './SpinnerLoader.scss'

const SpinnerLoader = () => (
  <div className="core-spinner-loader">
    <Spinner centered />
  </div>
)

// Use this to always render the same SpinnerLoader to keep the animation smooth
export const spinnerLoaderInstance = <SpinnerLoader />

export default SpinnerLoader
