import { useCpWorkflowContextQuery } from '~/generated'

const useConsultation = (id: string) => {
  return useCpWorkflowContextQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
  })
}

export default useConsultation
