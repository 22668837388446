import React from 'react'

import { useIntl } from '@babylon/intl'

import { WorkflowActionsSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { ConsultationWorkflowV2 } from '~/features/workflows/WorkflowActions/useConsultationWorkflowsV2Query'
import {
  ConsultationSummaryBaseQuery,
  useCpConsultationWorkflowsV2Query,
} from '~/generated'

import WorkflowActionsSummaryView from './WorkflowActionsSummaryView'

export type WorkflowGroup = {
  type?: string | null
  label?: string | null
  workflows: ConsultationWorkflowV2[]
}

interface WorkflowActionsSummaryRuntimeProps {
  consultation: Pick<
    NonNullable<ConsultationSummaryBaseQuery['consultation']>,
    'id' | 'consumerNetworkId'
  >
}

const WorkflowActionsSummaryModule: WorkflowActionsSummaryPluginInterface = () => {
  const WorkflowActionsSummaryContainer = ({
    consultation,
  }: WorkflowActionsSummaryRuntimeProps) => {
    const { locale } = useIntl()

    const { id: consultationId, consumerNetworkId } = consultation

    const { data, error, refetch } = useCpConsultationWorkflowsV2Query({
      variables: {
        locale,
        tags: 'clinical-portal',
        consumerNetwork: String(consumerNetworkId),
        consultationId,
        includeDefinitions: Boolean(consumerNetworkId),
      },
      notifyOnNetworkStatusChange: true,
      skip: !consumerNetworkId,
    })
    const workflows = data?.consultationWorkflowsV2?.workflows?.results ?? []
    const workflowGroups = workflows.reduce(
      (groups: WorkflowGroup[], workflow) => {
        if (
          workflow?.workflowDefinition?.group.type === 'appointment' ||
          !workflow?.workflowDefinition?.group.label
        ) {
          return groups
        }

        const existingGroup = groups.find(
          (group) => group.type === workflow?.workflowDefinition?.group.type
        )

        if (existingGroup) {
          existingGroup.workflows.push(workflow)

          return groups
        }

        return [
          ...groups,
          {
            type: workflow?.workflowDefinition?.group.type,
            label: workflow?.workflowDefinition?.group.label,
            workflows: [workflow],
          },
        ]
      },
      []
    )

    return (
      <WorkflowActionsSummaryView
        workflowGroups={workflowGroups}
        refetch={refetch}
        error={error}
      />
    )
  }

  return WorkflowActionsSummaryContainer
}

export default WorkflowActionsSummaryModule
