import 'react-rater/lib/react-rater.css'

import React, { useState } from 'react'
import Rater from 'react-rater'

import Question from './Question'

interface StarRatingProps {
  questionText: string
  onSubmit: (rating: number) => void
  onDismiss: () => void
}

const StarRating = ({ questionText, onSubmit, onDismiss }: StarRatingProps) => {
  const [rating, setRating] = useState<number>()

  const handleSubmit = () => {
    if (rating) {
      onSubmit(rating)
    }
  }

  return (
    <Question
      questionText={questionText}
      onSubmit={handleSubmit}
      onDismiss={onDismiss}
      disableSubmit={rating == null}
    >
      <Rater
        rating={rating}
        onRate={(event) => {
          setRating(event.rating)
        }}
      />
    </Question>
  )
}

export default StarRating
