import React from 'react'
import cn from 'classnames'
import AsyncSelect, { AsyncProps, Props } from 'react-select-latest/async'
import {
  components,
  FormatOptionLabelMeta,
  IndicatorProps,
  MultiValueProps,
  OptionTypeBase,
} from 'react-select-latest'

import Tag from '../Tag'
import Highlighter from '../Highlighter'
import { Spinner } from '../../foundation'
import { ReactComponent as ClearIcon } from '../../../icons/close-dropdown.svg'
import { ReactComponent as DropdownIcon } from '../../../icons/arrow-down-dropdown.svg'

import './SelectAsync.scss'
import { SelectOptionType, SelectOptionTypeBase } from '../Select/Select'

export interface SelectAsyncProps<
  OptionType extends OptionTypeBase = SelectOptionType,
  IsMulti extends boolean = false
> extends AsyncProps<OptionType>,
    Props<OptionType, IsMulti> {
  'data-testid'?: string
}

const emboldenMatchedOptionLabel = (
  getOptionLabel: (option: SelectOptionTypeBase) => string
) => (
  option: SelectOptionType,
  { inputValue }: FormatOptionLabelMeta<SelectOptionType, boolean>
) => {
  const searchWords = inputValue.split(' ')

  return (
    <Highlighter
      content={getOptionLabel(option)}
      searchWords={searchWords}
      emboldenHighlight
    />
  )
}

const MultiValue = ({
  data,
  removeProps,
  children,
}: Partial<MultiValueProps<SelectOptionTypeBase>>) => {
  const onRemove = removeProps?.onClick
  const tagColor = data?.tagColor ?? 'clinical-blue'

  return (
    <Tag
      className="core-select__tag"
      color={tagColor}
      onClose={onRemove}
      uppercase={false}
      closable
      margin
    >
      {children}
    </Tag>
  )
}

const LoadingIndicator = () => (
  <div className="core-select__indicator">
    <Spinner className="core-select__spinner" />
  </div>
)

const ClearIndicator = (
  props: IndicatorProps<SelectOptionTypeBase, boolean>
) => (
  <components.ClearIndicator {...props}>
    <ClearIcon />
  </components.ClearIndicator>
)

const DropdownIndicator = (
  props: IndicatorProps<SelectOptionTypeBase, boolean>
) => (
  <components.DropdownIndicator {...props}>
    <DropdownIcon />
  </components.DropdownIndicator>
)

const SelectAsync = ({
  formatOptionLabel,
  id,
  'data-testid': dataTestId,
  className,
  getOptionLabel,
  ...rest
}: SelectAsyncProps<SelectOptionType, boolean>) => {
  const getLabel =
    getOptionLabel || ((option: SelectOptionTypeBase) => option.label)

  return (
    <div data-testid={dataTestId}>
      <AsyncSelect
        components={{
          MultiValue,
          LoadingIndicator,
          ClearIndicator,
          DropdownIndicator,
        }}
        formatOptionLabel={
          formatOptionLabel || emboldenMatchedOptionLabel(getLabel)
        }
        classNamePrefix="core-select"
        className={cn('core-select', className)}
        inputId={id}
        getOptionLabel={getLabel}
        {...rest}
      />
    </div>
  )
}

export default SelectAsync
