import { graphql } from '@apollo/client/react/hoc'
import { branch, compose, renderNothing } from 'recompose'

import { isLoading } from '~/core/graphql'

import { CreateReferralMutation, UpdateReferralMutation } from './mutations'
import { ReferralsQuery, SpecialismCategoriesQuery } from './queries'

export default compose(
  graphql(SpecialismCategoriesQuery, {
    name: 'specialismsQuery',
  }),
  graphql(CreateReferralMutation, {
    name: 'createReferralMutation',
    options: {
      ignoreResults: false,
    },
  }),
  graphql(UpdateReferralMutation, {
    name: 'updateReferralMutation',
    options: {
      ignoreResults: false,
    },
  }),
  graphql(ReferralsQuery, {
    options: ({ match }) => ({
      variables: {
        consultationId: match.params.consultationId,
      },
    }),
  }),
  branch(isLoading(['specialismsQuery', 'data']), renderNothing)
)
