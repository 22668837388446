import { useGlobals } from '~/core/core-modules'
import { useLocalStorage, useMount } from '~/core/hooks'

import { experimentNameToKey } from './utils'

export const useExperiment = (experimentName: string): boolean => {
  const { experiments } = useGlobals()
  const [isEnabled, setIsEnabled] = useLocalStorage<boolean>(
    experimentNameToKey(experimentName),
    false
  )

  const experiment = experiments?.find((exp) => exp.title === experimentName)

  useMount(() => {
    if (!experiment) {
      setIsEnabled(false)
    }
  })

  return isEnabled || Boolean(experiment?.enabledForEveryone)
}
