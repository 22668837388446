import groupBy from 'lodash/groupBy'

import { ServiceType } from '~/generated'

import { generateDurations } from './duration'

export interface AugmentedServiceType extends ServiceType {
  duration_options: number[]
}

export const getServiceType = (
  serviceTypes: AugmentedServiceType[],
  serviceTypeId: string
): AugmentedServiceType | null => {
  const serviceType =
    serviceTypes && serviceTypes.find((type) => type.id === serviceTypeId)

  return serviceType || null
}

interface ServiceTypeGroupings {
  [key: string]: AugmentedServiceType[]
}

const serviceTypeGroupIdentity = ({
  grouping,
}: ServiceType): string | null | undefined => grouping

export const prepareServiceTypes = (
  serviceTypes: ServiceType[]
): ServiceTypeGroupings => {
  const augmentedServiceTypes = serviceTypes
    .filter(serviceTypeGroupIdentity)
    .map(
      (serviceType: ServiceType): AugmentedServiceType => ({
        ...serviceType,
        duration_options: serviceType.default_duration_minutes
          ? generateDurations(serviceType.default_duration_minutes)
          : [],
      })
    )

  return groupBy(augmentedServiceTypes, serviceTypeGroupIdentity)
}
