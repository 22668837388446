/* eslint-disable camelcase */
import { ProviderType } from '~/features/appointment-invites/AppointmentInvitesModal/AppointmentInvitesModalView'
import { GetProfessionsQueryResult, RecurrencePatterns } from '~/generated'

import { ServiceType } from '../types'

export type ConsumerNetworkProfession = NonNullable<
  GetProfessionsQueryResult['data']
>['consumerNetworkProfessions'][number]

export enum InviteFormMedium {
  faceToFace = 'faceToFace',
  digital = 'digital',
}

export type InviteFormValues = {
  startDate: Date | null
  medium: InviteFormMedium | null
  time: { hour: number; minutes: number } | null
  cadence: RecurrencePatterns | null
  appointmentReason: string
  recurrence: number
  serviceType: ServiceType | null
  providerType: ProviderType | null
  duration: number | null
  profession: string | null
}
