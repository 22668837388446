import { defineMessages } from 'react-intl'

const baseId = 'dashboard.navigation'

export default defineMessages({
  tests_text: {
    id: `${baseId}.tests_text_v2`,
    defaultMessage: 'Private tests',
  },
})
