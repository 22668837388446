import React from 'react'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import Section from '~/features/consultation-summary/ConsultationSummary/Section'
import { AttachmentsSummaryQueryResult } from '~/generated'

import ConsultationAttachments from '../ConsultationAttachments'

import messages from './AttachmentsSummary.messages'

interface AttachmentProps {
  attachments: NonNullable<
    NonNullable<
      NonNullable<AttachmentsSummaryQueryResult['data']>['consultation']
    >['testResults']
  >[number][]
}

const AttachmentsSummary = ({ attachments }: AttachmentProps) => {
  const f = useMessages(messages)

  if (!isFilledArray(attachments)) {
    return null
  }

  return (
    <Section title={f('attachments_section_title')} testid="cr-attachments">
      <ConsultationAttachments attachments={attachments} />
    </Section>
  )
}

export default AttachmentsSummary
