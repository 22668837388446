import { useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
  NOTE_TAKING_TIMER,
  NOTE_TAKING_V2_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'

const DEBOUNCE_DELAY = 1000

type ConsultationNoteField =
  | 'history'
  | 'examination'
  | 'diagnosis'
  | 'advice'
  | 'safety netting'
  | 'further information'

const useConsultationNoteTimer = (fieldName: ConsultationNoteField) => {
  const timer = useRef<number>()

  const startTimer = () => {
    if (timer.current) return

    timer.current = Date.now()
  }

  const [stopTimer] = useDebouncedCallback(() => {
    if (!timer.current) return

    const durationInSeconds =
      (Date.now() - timer.current - DEBOUNCE_DELAY) / 1000

    analytics.trackEvent({
      category: NOTE_TAKING_V2_CATEGORY,
      label: NOTE_TAKING_TIMER,
      action: fieldName,
      value: durationInSeconds,
    })

    timer.current = undefined
  }, DEBOUNCE_DELAY)

  return { startTimer, stopTimer }
}

export default useConsultationNoteTimer
