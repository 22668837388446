import * as Sentry from '@sentry/browser'

import {
  APP_NAME,
  APP_VERSION,
  ENVIRONMENT,
  SENTRY_ENVIRONMENT,
  SENTRY_URL,
} from '~/constants'

import {
  getAuthDetails,
  getFingerprint,
  getRequestId,
  getStatusCode,
} from './utils'

const getHintInput = <T>(hint: any, index: number): T | null => {
  try {
    return hint.input[index] || null
  } catch (error) {
    return null
  }
}

const safeParseJSON = (json: string) => {
  try {
    return JSON.parse(json) || null
  } catch (err) {
    return null
  }
}

const initialiseSentry = () => {
  if (!SENTRY_URL) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV !== 'test') {
      console.log('Sentry is not configured.')
    }
    return
  }

  Sentry.init({
    dsn: SENTRY_URL,
    environment: SENTRY_ENVIRONMENT || ENVIRONMENT,
    release: `${APP_NAME}@${APP_VERSION}`,
    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: any) {
      const { category } = breadcrumb

      if (category === 'fetch' || category === 'xhr') {
        const url = breadcrumb?.data?.url || getHintInput<string>(hint, 0)

        if (url?.includes('/graphql/api')) {
          const request = getHintInput<object>(hint, 1)

          if (request) {
            const { body, headers = {} } = request as any
            const requestId = headers['babylon-request-id'] || null

            const graphqlBody = safeParseJSON(body)
            const queries = Array.isArray(graphqlBody)
              ? graphqlBody.map((v) => v.operationName || null).join(', ')
              : []

            breadcrumb.data = {
              ...breadcrumb.data,
              requestId,
              queries,
            }
          }
        }
      }

      return breadcrumb
    },
    beforeSend: (event, hint) => {
      const originalException = hint?.originalException
      return {
        ...event,
        extra: {
          ...event.extra,
          auth_info: getAuthDetails(),
        },
        tags: {
          ...event.tags,
          http_response_status: getStatusCode(originalException),
          babylon_request_id: getRequestId(originalException),
        },
        fingerprint: getFingerprint(event, hint),
      }
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  })
}

export default initialiseSentry
