import React from 'react'

import { useConsultation } from '~/core/config'
import { CallPluginInterface } from '~/core/config/modules/generated/types'
import { useCallPlayerQuery } from '~/generated'
import { useDesktop } from '~/ui/Responsive'

import {
  CallMediumEntitlement,
  CallStatusReducerModel,
} from '../CallStatusReducerModelProvider'
import CallSectionView from './CallSectionView'

const CallSection: CallPluginInterface = ({
  callStatusReducerModelProvider,
  consultationContext,
  callMedium,
  callQualityFeedbackEnabled,
  recordingEnabled,
  // resizerEnabled prop can be deleted once legacy consultation is no longer used
  resizerEnabled,
}) => {
  // convert to use config to enable callQualityFeedbackSurvey
  const callStatusReducer: CallStatusReducerModel = callStatusReducerModelProvider.useModelContext()

  const { id } = useConsultation(consultationContext)

  const { data, loading, error, refetch } = useCallPlayerQuery({
    variables: { id },
  })

  const isDesktop = useDesktop()

  const consultationType = data?.consultation?.consultationType
  const recordingConsent = data?.consultation?.recordingConsent

  return (
    <CallSectionView
      consultationId={id}
      recordingConsent={recordingConsent}
      recordingEnabled={recordingEnabled}
      // option is a string we need to ensure its of the correct type, not sure how to pass enum as option
      callMediumEntitlement={callMedium as CallMediumEntitlement}
      consultationType={consultationType}
      isDesktop={isDesktop}
      error={error}
      loading={loading}
      callQualityFeedbackEnabled={callQualityFeedbackEnabled}
      refetch={refetch}
      resizerEnabled={resizerEnabled}
      callStatusReducer={callStatusReducer}
    />
  )
}

export default CallSection
