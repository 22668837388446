import React from 'react'

import { useIntl } from '@babylon/intl'

import { useConsultation } from '~/core/config'
import { NhsAppointmentInvitesPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { usePermissions } from '~/core/permissions'
import { useUpdateQueryContext } from '~/core/update-query-provider/UpdateQueryProvider'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'
import { filterWorkflowsV2 } from '~/features/workflows/WorkflowActions/utils'
import {
  useAppointmentInvitesQuery,
  useCpConsultationWorkflowsV2Query,
  WorkflowType,
} from '~/generated'

import NhsAppointmentInvitesView from './NhsAppointmentInvitesView'

const NhsAppointmentInvitesContainer: NhsAppointmentInvitesPluginInterface = ({
  consultationContext,
  faceToFaceInvitesEnabled,
  digitalInvitesEnabled,
}) => {
  const { locale } = useIntl()
  const [canManageWorkflows, canManageNhsAppointmentInvites] = usePermissions(
    'enable_workflow_triggers',
    'enable_appointment_invites'
  )

  const updateQueryMap = useUpdateQueryContext()

  const consultation = useConsultation(consultationContext)
  const { id: consultationId, consumerNetwork } = consultation
  const {
    f2FMediumServiceTypeInvitesSupported: globalInvitesEnabled,
  } = useFeatureFlags()

  const invitesDisabled =
    globalInvitesEnabled ||
    (!faceToFaceInvitesEnabled && !digitalInvitesEnabled)

  const {
    data: invitesData,
    loading: invitesLoading,
    error: invitesError,
    refetch: invitesRefetch,
  } = useAppointmentInvitesQuery({
    variables: { id: consultationId, f2fOn: faceToFaceInvitesEnabled },
    skip: invitesDisabled,
  })
  const appointmentInvites = invitesData?.consultation?.appointmentInvites ?? []

  const {
    data: workflowsData,
    loading: workflowsLoading,
    error: workflowsError,
    refetch: workflowsRefetch,
    updateQuery,
  } = useCpConsultationWorkflowsV2Query({
    variables: {
      locale,
      tags: 'clinical-portal',
      consumerNetwork: String(consumerNetwork),
      consultationId,
      includeDefinitions: Boolean(consumerNetwork),
    },
    notifyOnNetworkStatusChange: true,
    skip: !consumerNetwork || !digitalInvitesEnabled,
  })
  const workflows =
    workflowsData?.consultationWorkflowsV2?.workflows?.results ?? []
  const appointmentWorkflows = filterWorkflowsV2(
    workflows,
    WorkflowType.Appointment
  )

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    consultation?.status
  )

  updateQueryMap.updateWorkflowsV2Query = updateQuery

  const refetch = () => {
    if (invitesError) invitesRefetch()
    if (workflowsError) workflowsRefetch()
  }

  if (
    invitesDisabled ||
    !canManageWorkflows ||
    !canManageNhsAppointmentInvites
  ) {
    return null
  }

  return (
    <NhsAppointmentInvitesView
      appointmentInvites={appointmentInvites}
      appointmentWorkflows={appointmentWorkflows}
      loading={invitesLoading || workflowsLoading}
      refetch={refetch}
      error={invitesError || workflowsError}
      restrictFinalizedConsultationEditing={
        restrictFinalizedConsultationEditing
      }
      faceToFaceInvitesEnabled={faceToFaceInvitesEnabled}
      digitalInvitesEnabled={digitalInvitesEnabled}
    />
  )
}

export default NhsAppointmentInvitesContainer
