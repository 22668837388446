import React, { useState } from 'react'

import { useMessages } from '~/core/hooks'

import Question from './Question'

import messages from './CallQualityFeedback.messages'
import styles from './styles.module.scss'

interface FreeTextProps {
  questionText: string
  onSubmit: (comment: string) => void
  onDismiss: () => void
}

const FreeText = ({ questionText, onSubmit, onDismiss }: FreeTextProps) => {
  const f = useMessages(messages)
  const [comment, setComment] = useState<string>('')

  return (
    <Question
      questionText={questionText}
      onSubmit={() => onSubmit(comment)}
      onDismiss={onDismiss}
    >
      <textarea
        className={styles.freeText}
        placeholder={f('comment_placeholder_and_warning')}
        rows={6}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
    </Question>
  )
}

export default FreeText
