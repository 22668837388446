/**
 *  Checks if variable is an array and if it has at least one item
 * @param {any} array
 * @returns {boolean}
 */
export function isFilledArray<T extends any>(array: any): array is T[] {
  return Array.isArray(array) && array.length > 0
}
/**
 * Find value in the array of objects
 */
export function findBy<T extends Record<string, any>>(
  array: T[] = [],
  key: string,
  value: any
): T | null {
  return array.find((v: T) => typeof v === 'object' && v[key] === value) || null
}
/**
 * Find value by id in array
 * Common use-case for findBy function
 */
export function findById<T extends Record<string, any>>(
  array: T[] = [],
  id: string
): T | null {
  return findBy(array, 'id', id)
}
