import React from 'react'

import { COMPLAINT_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useConsultation } from '~/core/config'
import { BookingReasonPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { PresentingComplaintFragment, useBookingReasonQuery } from '~/generated'
import { withSectionErrorBoundary } from '~/ui/Section'

import BookingReasonSectionView from './BookingReasonSectionView'

import messages from './BookingReason.messages'

const BookingReason: BookingReasonPluginInterface = ({
  consultationContext,
}) => {
  const { id } = useConsultation(consultationContext)

  const result = useBookingReasonQuery({
    variables: { id },
  })

  const f = useMessages(messages)
  const consultation = result.data?.consultation

  const images = consultation?.images
  const patientNote = consultation?.patientNote
  const category = consultation?.category
  const serviceType =
    consultation?.appointment?.serviceType?.memberFacingName ||
    consultation?.appointment?.serviceType?.name ||
    f('no_service_type_available')

  return (
    <BookingReasonSectionView
      images={images as PresentingComplaintFragment['images']}
      patientNote={patientNote}
      category={category}
      serviceType={serviceType}
      loading={result.loading}
      error={result.error}
    />
  )
}

export default withSectionErrorBoundary({
  gaAction: COMPLAINT_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title_new,
})(BookingReason)
