import * as auth from '@babylon/babylon-auth'
import { AuthClient, getAuthClient } from '@babylon/web-platform-utils-auth'

import { ENABLE_NEW_AUTH } from '~/constants'
import { LOGOUT_ACTION, LOGOUT_CATEGORY } from '~/constants/analytics'
import analytics from '~/core/analytics'

const newLogout = async () => {
  const authClient: AuthClient = getAuthClient()
  await authClient.logout()
}

const logout = async () => {
  analytics.trackEvent({
    action: LOGOUT_ACTION,
    category: LOGOUT_CATEGORY,
  })

  /*
   we should remove the ENABLE_NEW_AUTH flag and old auth when migration is complete , see:
   babylonpartners.atlassian.net/wiki/spaces/CIT/pages/4241555535/Portals+Open+ID+switchover
  */
  try {
    if (ENABLE_NEW_AUTH) {
      await newLogout()
    } else {
      await auth.logout()
    }
  } catch (exception) {
    console.warn(exception)
  }
}

export default logout
