import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '@babylon/intl'

import { ENABLE_MEDICAL_HISTORY } from '~/constants'
import {
  ADD_ALLERGY_ACTION,
  ADD_CONDITION_ACTION,
  ADD_MEDICATION_ACTION,
  ERROR_ADD_ALLERGY_ACTION,
  ERROR_ADD_CONDITION_ACTION,
  ERROR_ADD_MEDICATION_ACTION,
  ERROR_REMOVE_ALLERGY_ACTION,
  ERROR_REMOVE_CONDITION_ACTION,
  ERROR_REMOVE_MEDICATION_ACTION,
  MEDICAL_TIMELINE_CATEGORY,
  REMOVE_ALLERGY_ACTION,
  REMOVE_CONDITION_ACTION,
  REMOVE_MEDICATION_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation } from '~/core/config'
import { MedicalHistoryPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import MedicalTagSelectFactory from '~/ui/MedicalSelect'
import { Section } from '~/ui/Section'
import Tooltip from '~/ui/Tooltip'

import {
  ADD_TO_ALLERGIES_MUTATION,
  ADD_TO_CONDITIONS_MUTATION,
  ADD_TO_MEDICATIONS_MUTATION,
  REMOVE_FROM_ALLERGIES_MUTATION,
  REMOVE_FROM_CONDITIONS_MUTATION,
  REMOVE_FROM_MEDICATIONS_MUTATION,
} from '../mutations'
import {
  GET_ALLERGIES_QUERY,
  GET_CONDITIONS_QUERY,
  GET_MEDICATIONS_QUERY,
  SUGGEST_ALLERGIES_QUERY,
  SUGGEST_CONDITIONS_QUERY,
  SUGGEST_MEDICATIONS_QUERY,
} from '../queries'

import messages from '../Examination.messages'
import styles from '../styles.module.scss'

const AllergyTagSelect = MedicalTagSelectFactory({
  name: 'allergies',
  gql: {
    Query: GET_ALLERGIES_QUERY,
    Add: ADD_TO_ALLERGIES_MUTATION,
    Remove: REMOVE_FROM_ALLERGIES_MUTATION,
    Options: SUGGEST_ALLERGIES_QUERY,
  },
  gaActions: {
    addSuccess: ADD_ALLERGY_ACTION,
    addFailure: ERROR_ADD_ALLERGY_ACTION,
    removeSuccess: REMOVE_ALLERGY_ACTION,
    removeFailuire: ERROR_REMOVE_ALLERGY_ACTION,
  },
})

const MedicationTagSelect = MedicalTagSelectFactory({
  name: 'medications',
  gql: {
    Query: GET_MEDICATIONS_QUERY,
    Add: ADD_TO_MEDICATIONS_MUTATION,
    Remove: REMOVE_FROM_MEDICATIONS_MUTATION,
    Options: SUGGEST_MEDICATIONS_QUERY,
  },
  gaActions: {
    addSuccess: ADD_MEDICATION_ACTION,
    addFailure: ERROR_ADD_MEDICATION_ACTION,
    removeSuccess: REMOVE_MEDICATION_ACTION,
    removeFailuire: ERROR_REMOVE_MEDICATION_ACTION,
  },
})

const ConditionTagSelect = MedicalTagSelectFactory({
  name: 'conditions',
  gql: {
    Query: GET_CONDITIONS_QUERY,
    Add: ADD_TO_CONDITIONS_MUTATION,
    Remove: REMOVE_FROM_CONDITIONS_MUTATION,
    Options: SUGGEST_CONDITIONS_QUERY,
  },
  gaActions: {
    addSuccess: ADD_CONDITION_ACTION,
    addFailure: ERROR_ADD_CONDITION_ACTION,
    removeSuccess: REMOVE_CONDITION_ACTION,
    removeFailuire: ERROR_REMOVE_CONDITION_ACTION,
  },
})

const trackEvent = analytics.trackEventFactory({
  category: MEDICAL_TIMELINE_CATEGORY,
})

const MedicalHistory: MedicalHistoryPluginInterface = ({
  consultationContext,
}) => {
  const fm = useFormatMessage()
  const consultation = useConsultation(consultationContext)
  const [
    canManagePatientAllergy,
    canManagePatientMedication,
    canManagePatientCondition,
  ] = usePermissions(
    'manage_patient_allergy',
    'manage_patient_medication',
    'manage_patient_condition'
  )
  const managePatient = usePermissions(
    'manage_patient_allergy',
    'manage_patient_medication',
    'manage_patient_condition'
  ).includes(true)

  return ENABLE_MEDICAL_HISTORY && managePatient ? (
    <Section style={{ paddingTop: 0 }}>
      <fieldset className={styles.field}>
        <label>
          <FormattedMessage
            {...messages.medical_history_label}
            values={{
              tooltip: (
                <Tooltip info={fm(messages.medical_history_tooltip_message)} />
              ),
            }}
          />
        </label>
        {canManagePatientAllergy && (
          <div className={styles.selectField} data-testid="allergies-list">
            <label className={styles.labelSmall}>
              {fm(messages.allergies_label)}
            </label>
            <AllergyTagSelect
              trackMedicalHistory={trackEvent}
              patientId={consultation?.patient.id}
            />
          </div>
        )}
        {canManagePatientMedication && (
          <div className={styles.selectField} data-testid="medications-list">
            <label className={styles.labelSmall}>
              {fm(messages.medications_label)}
            </label>
            <MedicationTagSelect
              trackMedicalHistory={trackEvent}
              patientId={consultation?.patient.id}
            />
          </div>
        )}
        {canManagePatientCondition && (
          <div className={styles.selectField} data-testid="conditions-list">
            <label className={styles.labelSmall}>
              {fm(messages.conditions_label)}
            </label>
            <ConditionTagSelect
              trackMedicalHistory={trackEvent}
              patientId={consultation?.patient.id}
            />
          </div>
        )}
      </fieldset>
    </Section>
  ) : null
}

export default MedicalHistory
