import { gql } from '@apollo/client'

export const PatientAlertQuery = gql`
  query PatientAlert($id: ID!, $patientId: ID) {
    patient_alert(patientId: $patientId, id: $id) {
      id
      message
      updatedAt
    }
  }
`

export const CpConsultationAlertsQuery = gql`
  query ConsultationAlerts($consultationId: ID!) {
    consultation(id: $consultationId) {
      id
      promoCodeMessageForGp
      membershipCodeMessageForGp
      patient {
        id
        uuid
        patient_alerts {
          id
          message
          updatedAt
        }
        canCreatePrescriptionPdf {
          valid
          errorCodes
        }
      }
    }
  }
`
