import React from 'react'
import cn from 'classnames'

import { Tag } from '@babylon/core-ui'

import styles from './styles.module.scss'
import sharedStyles from '../styles.module.scss'

const FailedResult = ({
  name,
  failureReason,
  rowClassName,
  cellClassName,
  columnLength,
}) => {
  const tagClass = cn(sharedStyles.tag, sharedStyles.tag__failed)
  return (
    <div className={cn(styles.failedResult, rowClassName)} role="row">
      <span
        className={cn(
          'core-responsive-table__cell',
          styles.failedResult__cell,
          cellClassName
        )}
        style={{ gridColumn: '1 / 2' }}
      >
        {name}
      </span>
      <div
        className={cn(
          'core-responsive-table__cell',
          styles.failedResult__cell,
          cellClassName
        )}
        style={{ gridColumn: `2 / ${columnLength + 1}` }}
      >
        <Tag className={tagClass}>Failed</Tag>
        <span>{failureReason}</span>
      </div>
    </div>
  )
}

export default FailedResult
