import pickBy from 'lodash/pickBy'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import { User } from '@babylon/babylon-user/src'

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

export const isTruthy = <T>(value: T): value is Truthy<T> => Boolean(value)

export const initialiseLogRocket = (
  appID: string,
  appVersion: string,
  partiallySanitised: boolean = false
) => {
  LogRocket.init(appID, {
    release: appVersion,
    ...(partiallySanitised
      ? {
          dom: {
            inputSanitizer: true,
            textSanitizer: true,
          },
          network: {
            responseSanitizer: (request: any) => {
              request.body = null

              return request
            },
            requestSanitizer: () => null,
          },
        }
      : {
          console: {
            isEnabled: false,
          },
          dom: {
            isEnabled: false,
          },
          network: {
            isEnabled: false,
          },
        }),
  })

  setupLogRocketReact(LogRocket)
}

export const setLogRocketUser = (user: User): void => {
  if (!user) {
    return
  }

  const userFields = pickBy(
    {
      partners: user.partnerIds?.join(', '),
      roles: user.roles?.map((role) => role?.name).join(', '),
      ...(user.consultant
        ? {
            email: user.consultant.email,
            professionName: user.consultant.profession_name,
          }
        : {}),
    },
    isTruthy
  )

  LogRocket.identify(user.id, userFields)
}
