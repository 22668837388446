import React, { useEffect } from 'react'

import { CALL_ERROR_BOUNDARY_ACTION } from '~/constants/analytics'
import { useFeatureFlags } from '~/core/core-modules'
import withErrorBoundary from '~/core/ErrorBoundary/ErrorBoundary/withErrorBoundary'
import Card from '~/ui/Card'

import CallControls from '../CallControls'
import CallQualityFeedback from '../CallQualityFeedback'
import CallServiceError from '../CallServiceError'
import {
  ActionType,
  CallMediumEntitlement,
  CallStatusReducerModel,
  isActive,
  isCriticallyFailed,
  isEnabled,
} from '../CallStatusReducerModelProvider'
import MediaAccessDialog from '../MediaAccessDialog'
import RecordingAlert from '../RecordingAlert'
import StatusIndicator from '../StatusIndicator'
import VideoCall from '../VideoCall'
import VoiceCall from '../VoiceCall'
import VoicePstnFallback from '../VoicePstnFallback'
import useAuthPollingEffect from './useAuthPollingEffect'
import useCallStatusToSentry from './useCallStatusToSentry'

import styles from './styles.module.scss'

export type PreferredCallMedium = 'video' | 'voice'
export interface CallPlayerProps {
  consultationId: string
  isRecording: boolean
  callMediumEntitlement: CallMediumEntitlement
  preferredCallMedium: PreferredCallMedium
  callQualitySurveyEnabled: boolean
  aspectRatio?: number
  callStatusReducer: CallStatusReducerModel
}

const CallPlayer = ({
  consultationId,
  isRecording,
  callMediumEntitlement,
  preferredCallMedium,
  callQualitySurveyEnabled,
  aspectRatio,
  callStatusReducer,
}: CallPlayerProps) => {
  const { voiceVoipEnabled } = useFeatureFlags()

  const {
    state: {
      videoStatus,
      voiceStatus,
      callServiceError,
      isSurveyVisible,
      voiceError,
      videoError,
      isAccessDialogOpen,
      callEndReason,
      isVoipCall,
      isVoiceFallbackVisible,
    },
    dispatch,
  } = callStatusReducer

  const isVideoCallCriticallyFailed = isCriticallyFailed(videoStatus)
  const isVoiceCallCriticallyFailed = isCriticallyFailed(voiceStatus)

  const isVideoCallActive = isActive(videoStatus)
  const isVoiceCallActive = isActive(voiceStatus)
  const isCallActive = isVideoCallActive || isVoiceCallActive

  // Ensure kong token does not expire while call is active
  useAuthPollingEffect(isCallActive)
  // Tag sentry events if a video or voice call is active
  useCallStatusToSentry(isVideoCallActive, isVoiceCallActive)

  const handleCompleteSurvey = () => {
    dispatch({ type: ActionType.COMPLETE_SURVEY })
  }

  useEffect(() => {
    return () => {
      dispatch({ type: ActionType.RESET_TO_INITIAL }) // clean up reducer which holds call state when call player is unmounted
    }
  }, [dispatch])

  return (
    <>
      <Card className={styles.card}>
        <div
          data-testid="multimedia-component"
          className={styles.canvas}
          style={{
            // @ts-expect-error
            '--aspect-ratio': aspectRatio || undefined,
          }}
        >
          {isRecording && isCallActive && <RecordingAlert />}
          <StatusIndicator
            voiceStatus={voiceStatus}
            voiceError={voiceError}
            videoStatus={videoStatus}
            videoError={videoError}
            dispatch={dispatch}
            callEndReason={callEndReason}
            isVoiceFallbackVisible={isVoiceFallbackVisible}
          />
          {isEnabled(voiceStatus) && !isVoiceCallCriticallyFailed && (
            <VoiceCall
              consultationId={consultationId}
              isCallActive={isVoiceCallActive}
              isVoip={isVoipCall}
              dispatch={dispatch}
            />
          )}
          {isEnabled(videoStatus) && !isVideoCallCriticallyFailed && (
            <VideoCall
              consultationId={consultationId}
              callStatus={videoStatus}
              dispatch={dispatch}
            />
          )}
          {callQualitySurveyEnabled && !isVoiceFallbackVisible && (
            <CallQualityFeedback
              onCompleted={handleCompleteSurvey}
              consultationId={consultationId}
              visible={isSurveyVisible && !callEndReason}
            />
          )}
          {voiceVoipEnabled && isVoiceFallbackVisible && (
            <VoicePstnFallback dispatch={dispatch} />
          )}
        </div>
        {callServiceError && (
          <CallServiceError errorMessage={callServiceError} />
        )}
        <CallControls
          videoStatus={videoStatus}
          voiceStatus={voiceStatus}
          callMediumEntitlement={callMediumEntitlement}
          preferredCallMedium={preferredCallMedium}
          dispatch={dispatch}
        />
      </Card>
      <MediaAccessDialog isAccessDialogOpen={isAccessDialogOpen} />
    </>
  )
}

export default withErrorBoundary({
  gaAction: CALL_ERROR_BOUNDARY_ACTION,
  mini: true,
  retryAlwaysVisible: true,
  className: styles.errorBoundary,
})(CallPlayer)
