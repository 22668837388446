import { branch, compose, pure, renderNothing } from 'recompose'

import { withEnhancedState } from '~/core/compose'
import { isLoading } from '~/core/graphql'
import withErrorMessage from '~/core/with-error-message'
import withModal from '~/core/with-modal'
import { withNotify } from '~/ui/Notification'

import withActions from './actions'
import withData from './data'
import GpDetailsModal from './GpDetailsModal'

export default compose(
  withNotify,
  withModal(),
  withData,
  branch(isLoading('data'), renderNothing),
  withEnhancedState(({ data }) => {
    const { patient, gpConsent } = data.consultation
    return { ...patient, gpConsent }
  }),
  withErrorMessage(),
  withActions,
  pure
)(GpDetailsModal)
