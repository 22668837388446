import { defineMessages } from 'react-intl'

const baseId = 'consultation.voip_fallback'

export default defineMessages({
  call: {
    id: `${baseId}.call`,
    defaultMessage: 'Call',
  },
  info: {
    id: `${baseId}.info`,
    defaultMessage: 'Issues with the voice call?',
  },
  action: {
    id: `${baseId}.action`,
    defaultMessage: 'Try calling patient directly on their mobile phone.',
  },
})
