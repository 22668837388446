import { graphql } from '@apollo/client/react/hoc'
import { compose, withHandlers } from 'recompose'

import { updateQuery } from '~/core/graphql'

import { CreateSickNoteMutation, UpdateSickNoteMutation } from './mutations'
import { SickNotesQuery } from './queries'

const addSickNoteToCache = (cache, { data }) => {
  const note = data.createConsultationSickNote

  return {
    ...cache,
    consultation: {
      ...cache.consultation,
      sickNotes: [...cache.consultation.sickNotes, note],
    },
  }
}

export const createSickNote = ({ consultationId, createSickNoteMutation }) => (
  input
) =>
  createSickNoteMutation({
    variables: {
      consultationId,
      input,
    },
    update: updateQuery(SickNotesQuery, { consultationId }, addSickNoteToCache),
  })

const updateSickNoteInCache = (cache, { data }) => {
  const note = data.updateConsultationSickNote
  const sickNotes = [...cache.consultation.sickNotes]
  const index = sickNotes.findIndex((v) => v.id === note.id)

  if (index === -1) {
    sickNotes.push(note)
  } else {
    sickNotes[index] = note
  }

  return {
    ...cache,
    consultation: {
      ...cache.consultation,
      sickNotes,
    },
  }
}

export const updateSickNote = ({ consultationId, updateSickNoteMutation }) => (
  sickNoteId,
  input
) =>
  updateSickNoteMutation({
    variables: {
      consultationId,
      sickNoteId,
      input,
    },
    update: updateQuery(
      SickNotesQuery,
      { consultationId },
      updateSickNoteInCache
    ),
  })

export default compose(
  graphql(SickNotesQuery, {
    options: ({ consultationId }) => {
      return {
        variables: {
          consultationId,
        },
      }
    },
  }),
  graphql(CreateSickNoteMutation, {
    name: 'createSickNoteMutation',
    options: {
      ignoreResults: false,
    },
  }),
  graphql(UpdateSickNoteMutation, {
    name: 'updateSickNoteMutation',
    options: {
      ignoreResults: false,
    },
  }),
  withHandlers({
    createSickNote,
    updateSickNote,
  })
)
