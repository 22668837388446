import React from 'react'

import { Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import messages from './CallActiveModal.messages'
import styles from './styles.module.scss'

interface CallActiveModalViewProps {
  onStayInCall: () => void
  onHangUp: () => void
  onClose: () => void
  dialogVisible: boolean
}

export const CallActiveModalView = ({
  onStayInCall,
  onHangUp,
  dialogVisible,
  onClose,
}: CallActiveModalViewProps) => {
  const f = useMessages(messages)

  if (!dialogVisible) {
    return null
  }

  return (
    <Dialog
      title={f('dialog_title')}
      ok
      cancel
      cancelLabel={f('hang_up_button_label')}
      cancelIcon={
        <i className={`${styles.callRejectIcon} fas fa-phone-slash`} />
      }
      okLabel={f('stay_in_call_button_label')}
      onOk={onStayInCall}
      onClose={onClose}
      onCancel={onHangUp}
    >
      <Text>{f('dialog_body')}</Text>
    </Dialog>
  )
}
