import React from 'react'

import { ENABLE_F2F_BOOKINGS } from '~/constants'
import { usePermissions } from '~/core/permissions'
import { useTestTypes } from '~/features/tests'
import {
  useAppointmentInvitesQuery,
  useTimelineConsultationActionsQuery,
} from '~/generated'

import TimelineConsultationActionsView from './TimelineConsultationActionsView'

interface TimelineConsultationActionsProps {
  consultationId: string
  isIncomplete: boolean
}

const TimelineConsultationActions = ({
  consultationId,
  isIncomplete,
}: TimelineConsultationActionsProps) => {
  const { getTestName } = useTestTypes()
  const [readAnyPrescription] = usePermissions('read_any_prescription')

  const { data, loading, error } = useTimelineConsultationActionsQuery({
    variables: {
      consultationId,
      prescriptionNotAllowed: !readAnyPrescription,
    },
  })
  const consultationActions = data?.consultation

  const {
    data: invitesData,
    loading: invitesLoading,
  } = useAppointmentInvitesQuery({
    variables: { id: consultationId, f2fOn: ENABLE_F2F_BOOKINGS },
  })
  const appointmentInvites = invitesData?.consultation?.appointmentInvites ?? []

  return (
    <TimelineConsultationActionsView
      consultationActions={consultationActions}
      appointmentInvites={appointmentInvites}
      isIncomplete={isIncomplete}
      getTestName={getTestName}
      loading={loading || invitesLoading}
      error={error}
    />
  )
}

export default TimelineConsultationActions
