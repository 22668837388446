import matchPatient from './matchPatient'
import eventLogger from './eventLogger'
/**
 * Generated numeric ID
 */
export const generateIndex = ((index) => () => index++)(0) // eslint-disable-line no-plusplus
/**
 *  Checks if variable is an array and if it has at least one item
 * @param {Array} array
 */
export const isFilledArray = (array) => Array.isArray(array) && array.length > 0

export const scrollToTopOfLayout = () => {
  const view = document.getElementById('tests-and-kits__view')

  if (view) {
    view.scrollTop = 0
  }
}

// for a given 'object', checks all given 'fields' are present
export const validateFieldsPresent = (object, fields) => ({
  valid: fields.every((field) => !!object[field]),
  errors: fields.filter((field) => !object[field]),
})

export { matchPatient, eventLogger }
