import { withApollo } from '@apollo/client/react/hoc'
import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { DEFAULT_INPUT_DEBOUNCE } from '~/constants'
import { errorOptionRenderer, errorOptions } from '~/ui/ErrorMessage'
import { Creatable } from '~/ui/Select'

import { SuggestedDrugsQuery } from './queries'
import SelectOption from './SelectOption'
import SelectValue from './SelectValue'

import messages from './DrugSelect.messages'

class DrugSelect extends Component {
  state = {
    options: [],
  }

  debounceFetchSuggestions = debounce(async (query) => {
    const { props } = this

    try {
      const { data } = await props.client.query({
        query: SuggestedDrugsQuery,
        variables: {
          region: props.region,
          query,
        },
      })

      this.setState({
        options: data.drugs,
      })
    } catch (exception) {
      console.warn(exception)
      this.setState({
        options: errorOptions,
      })
    }
  }, DEFAULT_INPUT_DEBOUNCE)

  handleInputChange = (value) => {
    this.debounceFetchSuggestions(value)
    return value
  }

  filterCreatableOptions = (options) => {
    return options.filter((v) => {
      return v.className !== 'Select-create-option-placeholder'
    })
  }

  renderOption = (option) => {
    const { intl } = this.props

    if (option.className === 'Select-create-option-placeholder') {
      return intl.formatMessage(messages.create_option_label, {
        option: option.name,
      })
    }

    return option.name
  }

  render() {
    const { state } = this
    const { intl } = this.props
    const options = [...(state.options || [])]

    return (
      <div data-testid="drugs-search">
        <Creatable
          className={this.props.className}
          labelKey="name"
          valueKey="id"
          multi={false}
          arrowRenderer={null}
          clearable={false}
          removeSelected={false}
          filterOptions={this.filterCreatableOptions}
          placeholder={intl.formatMessage(messages.placeholder)}
          options={options}
          optionComponent={SelectOption}
          value={this.props.value}
          valueComponent={SelectValue}
          onChange={this.props.onChange}
          onInputChange={this.handleInputChange}
          promptTextCreator={(label) => label}
          optionRenderer={errorOptionRenderer(this.renderOption)}
        />
      </div>
    )
  }
}

export default compose(injectIntl, withApollo)(DrugSelect)
