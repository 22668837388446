import React, { ReactElement } from 'react'

import Resizer, { useWidthResizeHandlers } from '~/ui/Resizer'

import styles from './styles.module.scss'

interface CallSectionResizerProps {
  children: ReactElement
  resizerEnabled: boolean
  isDesktop: boolean
}

const MIN_CALL_PLAYER_WIDTH = 275
const MAX_CALL_PLAYER_WIDTH = 550
const CALL_PLAYER_FULL_WIDTH = '100%'

const constrainWidth = (min: number, max: number) => (newWidthValue: number) =>
  Math.min(max, Math.max(min, newWidthValue))

const constrainCallPlayerWidth = constrainWidth(
  MIN_CALL_PLAYER_WIDTH,
  MAX_CALL_PLAYER_WIDTH
)

const CallSectionResizer = ({
  children,
  resizerEnabled,
  isDesktop,
}: CallSectionResizerProps) => {
  const {
    ref,
    handleResizeStart,
    handleResizeEnd,
    handleResize,
  } = useWidthResizeHandlers(constrainCallPlayerWidth)

  const resizerDisabled = !(isDesktop && resizerEnabled)

  return (
    <div className={styles.callWrapper}>
      <Resizer
        onResizeStart={handleResizeStart}
        onResizeEnd={handleResizeEnd}
        onResize={handleResize}
        disabled={resizerDisabled}
      >
        <div
          ref={ref}
          style={{
            width: resizerDisabled
              ? CALL_PLAYER_FULL_WIDTH
              : MIN_CALL_PLAYER_WIDTH,
          }}
        >
          {children}
        </div>
      </Resizer>
    </div>
  )
}

export default CallSectionResizer
