import { addMinutes, isAfter, isBefore, subMinutes } from 'date-fns'
import { useRef } from 'react'

import { useInterval } from '~/core/hooks'
import {
  ConsultationStatusV2,
  useAppointmentCheckInDetailsQuery,
} from '~/generated'

interface Params {
  consultationId: string
  scheduledTime: string
  consultationStatus: ConsultationStatusV2
}

export const CHECK_IN_REFRESH_TIMEOUT = 60 * 1000 // 1 minute
const CHECK_IN_WINDOW_BEFORE = 10 // 10 minutes
const CHECK_IN_WINDOW_AFTER = 5 // 5 minutes

const isInsideCheckInWindow = (now: Date, appointmentTime: Date) => {
  const beforeWindow = subMinutes(appointmentTime, CHECK_IN_WINDOW_BEFORE)
  const afterWindow = addMinutes(appointmentTime, CHECK_IN_WINDOW_AFTER)

  return isAfter(now, beforeWindow) && isBefore(now, afterWindow)
}

const useConsultationCheckInQuery = ({
  consultationId,
  scheduledTime,
  consultationStatus,
}: Params) => {
  const nowDate = useRef<Date>(new Date())

  const appointmentTime = new Date(scheduledTime)
  const isFinalised =
    consultationStatus === ConsultationStatusV2.Completed ||
    consultationStatus === ConsultationStatusV2.Cancelled ||
    consultationStatus === ConsultationStatusV2.NoShow

  const { data, error, refetch } = useAppointmentCheckInDetailsQuery({
    variables: {
      appointmentId: consultationId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: isFinalised,
  })

  useInterval(() => {
    nowDate.current = new Date()

    // Using the query result's `refetch()` instead of `startPolling()` because
    // polling only needs to happen within the `isInsideCheckInWindow()` time window
    // regardless of re-renders
    if (
      isInsideCheckInWindow(nowDate.current, appointmentTime) &&
      !isFinalised
    ) {
      refetch()
    }
  }, CHECK_IN_REFRESH_TIMEOUT)

  return {
    checkIn: data?.getAppointmentCheckIn ?? null,
    error,
  }
}

export default useConsultationCheckInQuery
