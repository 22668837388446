export const toArray = (separator = ',') => (string: string): string[] => {
  return (string || '')
    .split(separator)
    .map((v) => v.trim())
    .filter((v) => v.length !== 0)
}

export const toBoolean = (string: string): boolean => {
  return string === 'true'
}

export const toNumber = (string: string): number => {
  return Number(string)
}
