import { isAfter, max } from 'date-fns'
import React from 'react'

import { Grid } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ENABLE_BLOOD_PRESSURE_CHR } from '~/constants'
import PatientMetricContainer from '~/features/patient-metrics/PatientMetricContainer'
import { constructBloodPressureValue } from '~/features/patient-metrics/utils/blood_pressure'
import {
  getBmiDetails,
  MetricRating,
  NonNumberBmiValues,
} from '~/features/patient-metrics/utils/bmi'
import { getSmokingDetails } from '~/features/patient-metrics/utils/smoking'
import { extractTimestamps } from '~/features/patient-metrics/utils/Timestamp'
import { PatientMetricsFragment } from '~/generated'

import messages from './PatientMetrics.messages'
import styles from './styles.module.scss'

const MIN_AGE_FOR_BMI = 18

export interface PatientMetric {
  panelName: string
  label: string
  value: string | number
  rating: MetricRating
  sideNote?: React.ReactNode
  testId?: string
  timestamp?: Date
  performer?: string
}

export interface ValueEntry {
  code: string
  value: number
}

export interface PatientMetricsProps {
  patientMetrics: NonNullable<
    PatientMetricsFragment['patient']['patient_metrics']
  >
  age?: number | null
  editPatientMetrics: (name?: string) => void
  testid?: string
}

const metricMap = {
  bmi: 'BmiInquiry',
  smoking_status: 'SmokingInquiry',
  blood_pressure: 'BloodPressureInquiry',
}

const PatientMetrics = ({
  patientMetrics,
  age = MIN_AGE_FOR_BMI,
  editPatientMetrics,
  testid,
}: PatientMetricsProps) => {
  const fm = useFormatMessage()

  const timestamps = extractTimestamps(patientMetrics)

  const [heightDetails] = patientMetrics.height
  const [weightDetails] = patientMetrics.weight
  const [smokingStatusDetails] = patientMetrics.smoking_status
  const [bpDetails] = patientMetrics.blood_pressure
    ? patientMetrics.blood_pressure
    : []

  const height = heightDetails != null ? heightDetails.value : undefined
  const weight = weightDetails != null ? weightDetails.value : undefined
  const smokingStatus =
    smokingStatusDetails != null ? smokingStatusDetails.value : undefined

  const bmiDetails = getBmiDetails(height, weight, age)
  const smokingDetails = getSmokingDetails(smokingStatus || undefined)

  const getHeightWeightSideNote = () => (
    <div className={styles.heightWeightMetricContainer}>
      <div className={styles.heightWeightMetric}>
        {height
          ? `${fm(messages.height_label)}: ${height}${fm(messages.height_unit)}`
          : `${fm(messages.height_label)}: ${fm(messages.na_text)}`}
      </div>
      <div className={styles.heightWeightMetric}>
        {weight
          ? `${fm(messages.weight_label)}: ${weight}${fm(messages.weight_unit)}`
          : `${fm(messages.weight_label)}: ${fm(messages.na_text)}`}
      </div>
    </div>
  )

  const getBmiPerformer = () => {
    if (!weightDetails?.timestamp) {
      return heightDetails?.performer
    }
    if (!heightDetails?.timestamp) {
      return weightDetails?.performer
    }

    const weightDate = new Date(weightDetails?.timestamp)
    const heightDate = new Date(heightDetails?.timestamp)

    if (isAfter(weightDate, heightDate)) {
      return weightDetails?.performer
    }
    return heightDetails?.performer
  }

  const getBmiDisplayValue = (value: NonNumberBmiValues | number) => {
    if (value === NonNumberBmiValues.bmi_out_of_range) {
      return fm(messages.bmi_out_of_range)
    }

    if (value === NonNumberBmiValues.bmi_na) {
      return fm(messages.bmi_na)
    }

    return value
  }

  const getBloodPressureDisplayValue = (values: any) => {
    if (!values) return fm(messages.na_text)
    const { systolic, diastolic } = values?.reduce(
      (acc: any, valueEntry: ValueEntry) => {
        if (valueEntry && valueEntry.code) {
          acc[valueEntry.code] = valueEntry.value
        }
        return acc
      },
      {}
    )

    return constructBloodPressureValue(systolic, diastolic, fm)
  }

  const getLatest = (...dates: (Date | undefined)[]) => {
    const validDates = dates.filter(Boolean) as Date[]
    return validDates.length ? max(validDates) : undefined
  }

  const patientMetricsData: PatientMetric[] = [
    {
      panelName: metricMap.bmi,
      label: fm(messages.bmi_label),
      value: getBmiDisplayValue(bmiDetails.value),
      rating: bmiDetails.rating,
      sideNote: getHeightWeightSideNote(),
      testId: 'bmi-metric',
      timestamp: getLatest(
        timestamps.timestampHeight,
        timestamps.timestampWeight
      ),
      performer: getBmiPerformer(),
    },
    {
      panelName: metricMap.smoking_status,
      label: fm(messages.smoking_status_label),
      value: fm(smokingDetails.value),
      rating: smokingDetails.rating,
      testId: 'smoking-metric',
      timestamp: timestamps.timestampSmokingStatus,
      performer: smokingStatusDetails?.performer,
    },
  ].concat(
    (ENABLE_BLOOD_PRESSURE_CHR && {
      panelName: metricMap.blood_pressure,
      label: fm(messages.blood_pressure_label),
      value: getBloodPressureDisplayValue(bpDetails?.values),
      rating: MetricRating.generic,
      testId: 'blood-pressure',
      timestamp: timestamps.timestampBloodPressure,
      performer: bpDetails?.performer,
    }) ||
      []
  )

  return (
    <div data-testid={testid}>
      <Grid className={styles.moreInfo} columns={4}>
        {patientMetricsData.map((metric) => (
          <PatientMetricContainer
            metric={metric}
            key={metric.label}
            onClick={() => editPatientMetrics(metric.panelName)}
            testId={metric.testId}
          />
        ))}
      </Grid>
    </div>
  )
}

export default PatientMetrics
