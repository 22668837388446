import React, { ReactNode } from 'react'
import { FieldValidator, useField } from 'formik'

import FormField from '../FormField'
import Select, {
  SelectOptionTypeBase,
  SelectOptionType,
  SelectProps,
  SelectValueType,
  SelectActionMeta,
} from '../../candidates/Select'

export interface FormikSelectProps
  extends SelectProps<SelectOptionType, boolean> {
  name: string
  label?: ReactNode
  onChange?: (value: SelectValueType, action?: SelectActionMeta) => void
  validate?: FieldValidator
}

const FormikSelect = ({
  id,
  name,
  label,
  options = [],
  onChange,
  getOptionValue = (option) => option.value,
  validate,
  className,
  'data-testid': dataTestId,
  ...rest
}: FormikSelectProps) => {
  const [{ value: formValue }, { touched, error }, { setValue }] = useField({
    name,
    validate,
  })

  const displayError = touched && !!error

  const onChangeHandler = (
    selected: SelectValueType,
    action: SelectActionMeta
  ) => {
    const optionValue = selected
      ? Array.isArray(selected)
        ? selected.map((option: SelectOptionTypeBase) => getOptionValue(option))
        : getOptionValue(selected)
      : null

    setValue(optionValue)

    if (onChange) {
      onChange(selected, action)
    }
  }

  const value = (options as SelectOptionType[])
    .flatMap((option: SelectOptionType) => {
      return option.options || option
    })
    .filter((option: SelectOptionType) => {
      const optionValue = getOptionValue(option)

      return Array.isArray(formValue)
        ? formValue.includes(optionValue)
        : formValue === optionValue
    })

  return (
    <FormField
      id={id}
      label={label}
      errorMessage={error}
      displayError={displayError}
      data-testid={dataTestId}
      className={className}
    >
      <Select
        id={id}
        value={value}
        options={options}
        onChange={onChangeHandler}
        getOptionValue={getOptionValue}
        {...rest}
      />
    </FormField>
  )
}

export default FormikSelect
