import cx from 'classnames'
import React, { useState } from 'react'

import Lightbox from './Lightbox'

import styles from './styles.module.scss'

type Image = {
  id?: string
  url?: string
  thumbUrl?: string | null
  title?: string
  caption?: string
}

interface LightboxGalleryProps {
  images: Image[]
  className?: string
  topMargin?: boolean
}

const LightboxGallery = ({
  images,
  topMargin,
  className,
}: LightboxGalleryProps) => {
  const [isLightboxVisible, setIsLightboxVisible] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const showLightbox = (index: number) => () => {
    setIsLightboxVisible(true)
    setImageIndex(index)
  }

  const hideLightbox = () => setIsLightboxVisible(false)
  return (
    <>
      <div
        className={cx(
          styles.thumbnailGrid,
          topMargin && styles.topMargin,
          className
        )}
      >
        {images.map(({ id, thumbUrl, url, title, caption }, idx) => {
          const imageUrl = thumbUrl || url

          return imageUrl ? (
            <div
              className={styles.thumbnail}
              key={id || idx}
              role="button"
              tabIndex={0}
              onClick={showLightbox(idx)}
            >
              <img alt={title || caption} src={imageUrl} />
            </div>
          ) : null
        })}
      </div>
      {isLightboxVisible ? (
        <Lightbox
          images={images}
          onClose={hideLightbox}
          startIndex={imageIndex}
        />
      ) : null}
    </>
  )
}

export default LightboxGallery
