// This method extracts columns based on an array of data

// This is not an efficient method. It's not meant to be. If you're looking
// for performance, explicitily pass the columns you want to ResponsiveTable so
// that this isn't necessary

const extractColumns = (data, ignoreColumns = []) => {
  const columnsToIgnore = [...ignoreColumns, 'responsiveTableID']

  if (!data) throw new Error('data is a required prop')

  const allKeys = [].concat.apply(
    [],
    data.map((row) => Object.keys(row))
  )
  const uniqueKeys = [...Array.from(new Set(allKeys))]
  const relevantKeys = uniqueKeys.filter(
    (key) => !columnsToIgnore.includes(key)
  )
  const keysWithValues = relevantKeys.filter((key) => {
    const firstExisting = data.find((row) => !!row[key])
    if (!firstExisting) return false
    if (['array', 'object'].includes(typeof firstExisting[key])) return false
    return true
  })

  return keysWithValues.map((key) => {
    return {
      label: humanizeKey(key),
      accessor: key,
    }
  })
}

const humanizeKey = (key) => {
  const camelCased = toCamelCase(key)
  const sentenceCased = camelCased.replace(/([A-Z])/g, ' $1')
  return capitalize(sentenceCased)
}

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1)

const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace(/[-_]/g, '')
  })
}

export default extractColumns
