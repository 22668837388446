import { graphql } from '@apollo/client/react/hoc'
import debounce from 'lodash/debounce'
import React, { Component } from 'react'

import { SUGGEST_CONDITIONS_QUERY } from '~/features/consultation/Examination/queries'
import { errorOptions } from '~/ui/ErrorMessage'
import MedicalSelect from '~/ui/MedicalSelect/MedicalSelect'

const enhance = graphql(SUGGEST_CONDITIONS_QUERY, {
  name: 'optionsMutation',
})

class IndicationSelect extends Component {
  state = {
    options: [],
  }

  debouncedInputChange = debounce((value) => this.fetchOptions(value), 420)

  componentWillUnmount() {
    this.debouncedInputChange.cancel()
  }

  handleInputChange = (query) => {
    this.debouncedInputChange(query)
    return query
  }

  fetchOptions = async (query) => {
    try {
      const response = await this.props.optionsMutation({
        variables: {
          query,
        },
      })
      const options = response.data.options || []
      const formattedOptions = options.map((v) => ({
        id: v.suggestedLabel,
        iri: v.iri,
        info: v.suggestedLabel,
      }))
      this.setState({
        options: formattedOptions,
      })
    } catch (err) {
      console.warn(err)
      this.setState({
        options: errorOptions,
      })
    }

    return query
  }

  render() {
    return (
      <MedicalSelect
        name="indications"
        {...this.props}
        onInputChange={this.handleInputChange}
        options={this.state.options}
      />
    )
  }
}

export default enhance(IndicationSelect)
