import { useCallback } from 'react'

import useLocalStorage from './useLocalStorage'

const LOCAL_STORAGE_KEY = 'cp3-onboarding'

interface IuseHideOnboarding {
  (onboardingKey: string): [boolean, (hidden: boolean) => void]
}

const useHideOnboarding: IuseHideOnboarding = (onboardingKey) => {
  const prefixedKey = `hideOnboarding${onboardingKey}`

  const [onboardingStore, setOnboadingStore] = useLocalStorage<
    Dictionary<boolean>
  >(LOCAL_STORAGE_KEY, {})

  const setHidden = useCallback((hidden: boolean) => {
    setOnboadingStore((store) => ({ ...store, [prefixedKey]: hidden }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const hidden = Boolean(onboardingStore[prefixedKey])

  return [hidden, setHidden]
}

export default useHideOnboarding
