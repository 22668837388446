const extractCells = (row, columns) => {
  if (!row || !columns)
    throw new Error('missing required props (row or columns)')

  return columns.map((col) => {
    if (!col.accessor) throw new Error(`missing accessor for ${col.label}`)
    const accessorType = typeof col.accessor

    if (!['string', 'function'].includes(accessorType))
      throw new Error(`invalid accessor for ${col.label}`)

    const cellData =
      accessorType === 'string' ? row[col.accessor] : col.accessor(row)

    return {
      label: col.label,
      data: cellData,
    }
  })
}

export default extractCells
