import { graphql, withApollo } from '@apollo/client/react/hoc'
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  withHandlers,
  withState,
} from 'recompose'

import { isLoading } from '~/core/graphql'
import { logException } from '~/core/sentry'
import withModal from '~/core/with-modal'

import { defaultFormValues } from './constants'
import GpFinderModal from './GpFinderModal'
import { UpdatePatientGpDetailsMutation } from './mutations'
import { ConsultationGpDetailsQuery, PlacesSearchQuery } from './queries'
import { formatPlacesSearchResults } from './utils'

const enhance = compose(
  withModal(),

  withApollo,

  graphql(ConsultationGpDetailsQuery, {
    options: ({ match: { params: { consultationId } = {} } = {} }) => ({
      variables: {
        consultationId,
      },
    }),
  }),

  graphql(UpdatePatientGpDetailsMutation, {
    name: 'updatePatientGpDetailsMutation',
  }),

  branch(isLoading('data'), renderNothing),

  mapProps((props) => {
    const {
      data: {
        consultation: {
          gpConsent,
          patient: {
            gp_name,
            gp_surgery_name,
            gp_surgery_phone_number,
            gp_address_first_line,
            gp_address_post_code,
            gp_address_second_line,
            gp_address_third_line,
          } = {},
        } = {},
      },
    } = props

    const gpDetails = {
      gp_name,
      surgery_name: gp_surgery_name,
      surgery_phone_number: gp_surgery_phone_number,
      address_first_line: gp_address_first_line,
      address_second_line: gp_address_second_line,
      address_third_line: gp_address_third_line,
      address_post_code: gp_address_post_code,
    }

    return {
      ...props,
      initialFormValues: {
        ...defaultFormValues,
        ...gpDetails,
        gp_consent: gpConsent,
      },
    }
  }),

  withState('error', 'setError'),

  withHandlers({
    handleFormSubmit: ({
      data: { consultation: { patient } = {}, refetch },
      match: { params: { consultationId } = {} },
      redirectToConsultation,
      updatePatientGpDetailsMutation,
      setError,
    }) => async (
      {
        gp_name,
        gp_consent,
        surgery_name,
        surgery_phone_number,
        address_first_line,
        address_second_line,
        address_third_line,
        address_post_code,
      },
      { setSubmitting }
    ) => {
      if (!patient || !patient.id) {
        throw new Error('Unknown patient')
      }
      setError()
      try {
        await updatePatientGpDetailsMutation({
          variables: {
            consultationId,
            patientId: patient.id,
            gpConsent: gp_consent,
            formData: {
              gp_name,
              gp_address_first_line: address_first_line,
              gp_address_second_line: address_second_line,
              gp_address_third_line: address_third_line,
              gp_address_post_code: address_post_code,
              gp_surgery_name: surgery_name,
              gp_surgery_phone_number: surgery_phone_number,
            },
          },
        })
        await refetch()
        await redirectToConsultation()
        await setSubmitting(false)
      } catch (error) {
        setError(error)
        logException(error)
      }
    },

    fetchPostcodeResults: ({ client: { query } }) => async (postcode) => {
      try {
        const {
          data: { placesSearch },
        } = await query({
          query: PlacesSearchQuery,
          fetchPolicy: 'cache-first',
          variables: {
            input: {
              postalCode: postcode,
              countryCode: 'GB',
              type: 'gppractices',
              radiusMeters: '3218',
            },
          },
        })
        const formattedResults = formatPlacesSearchResults(placesSearch)
        return formattedResults
      } catch (error) {
        logException(error)
        return []
      }
    },
  })
)

export default enhance(GpFinderModal)
