import { defineMessages } from 'react-intl'

const baseId = 'chatscript_messages_modal'

export default defineMessages({
  with_chatbot_meta: {
    id: `${baseId}.with_chatbot_meta`,
    defaultMessage: 'with Chatbot',
  },

  could_not_get_conversation_message: {
    id: `${baseId}.could_not_get_conversation_message`,
    defaultMessage: 'Could not get the conversation',
  },
})
