import cx from 'classnames'
import React, { MouseEvent, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'

import { Button, Text } from '@babylon/core-ui'

import messages from './error.messages'
import styles from './styles.module.scss'

interface ErrorMessageProps {
  message: string | ReactNode
  buttonLabel?: string | ReactNode
  onButtonClick?: (event: MouseEvent) => void
  centerOnScreen?: boolean
  className?: any
}

export const errorOptions = [
  {
    $ERROR: true,
    disabled: true,
  },
]

export const errorOptionRenderer = (renderOption: any, message: any) => (
  option: any
) =>
  option.$ERROR ? (
    <Text color="error">
      {message || <FormattedMessage {...messages.option_renderer} />}
    </Text>
  ) : (
    renderOption(option)
  )

export const ErrorMessage = ({
  message,
  buttonLabel,
  onButtonClick,
  centerOnScreen,
  className,
}: ErrorMessageProps) => (
  <div
    className={
      centerOnScreen ? styles.errorMessageWrapAbs : styles.errorMessageWrap
    }
  >
    <div className={cx(styles.errorMessage, className)}>
      <p>{message}</p>
      {onButtonClick && (
        <Button inline onClick={onButtonClick}>
          {buttonLabel || <FormattedMessage {...messages.okay} />}
        </Button>
      )}
    </div>
  </div>
)

const reloadPage = () => {
  window.location.reload()
}

export const ContentErrorMessage = ({
  message,
  buttonLabel,
  onButtonClick,
  ...rest
}: ErrorMessageProps) => (
  <ErrorMessage
    message={
      message || (
        <span>
          <FormattedMessage {...messages.loading_content} />
          <br />
          <FormattedMessage {...messages.please_reload} />
        </span>
      )
    }
    onButtonClick={onButtonClick || reloadPage}
    buttonLabel={buttonLabel || <FormattedMessage {...messages.reload} />}
    centerOnScreen
    {...rest}
  />
)

export const DialogErrorMessage = withRouter((props) => {
  const { message, buttonLabel, onButtonClick, history, ...rest }: any = props
  return (
    <ErrorMessage
      message={
        message || (
          <span>
            <FormattedMessage {...messages.loading_dialog} />
            <br />
            <FormattedMessage {...messages.try_again} />
          </span>
        )
      }
      onButtonClick={
        onButtonClick ||
        (() => {
          history.goBack()
        })
      }
      buttonLabel={buttonLabel || <FormattedMessage {...messages.close} />}
      {...rest}
    />
  )
})
