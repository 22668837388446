import * as Access from '@babylon/web-platform-access'

import { CONSULTATION_STATUSES } from '~/constants'
import { useFeatureFlags } from '~/core/core-modules'

const useIsEditDisabled = (consultationStatus: string): boolean => {
  /* TODO - Use Permissions service to check if user can edit complete
  consultations instead of arbitrarily checking if user is a super consultant.
  This will require an update to Permissions service.
  */
  const [canEditCompletedConsultation, error] = Access.useDecide(
    'update-completed-consultation'
  )

  const { readOnlyCompleteConsultationNotesEnabled } = useFeatureFlags()

  if (error) {
    throw error
  }

  const isCompleted = consultationStatus !== CONSULTATION_STATUSES.PAID

  return (
    !canEditCompletedConsultation &&
    !!readOnlyCompleteConsultationNotesEnabled &&
    isCompleted
  )
}

export default useIsEditDisabled
