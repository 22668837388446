import React, { ReactNode } from 'react'

import { Text } from '../../foundation'

export interface HighlighterProps {
  content: string
  searchWords: string[]
  emboldenHighlight?: boolean
}

interface HighlightFragmentProps {
  bold: boolean
  children: ReactNode
}

type HighlightedWord = {
  id: number
  match: string
  rest: string
}

// TODO: Expand this to support highlighting characters in the middle or at the end and repeating sets of characters
const highlightWords = (searchWords: string[]) => (
  contentWord: string,
  index: number
): HighlightedWord => {
  const matchedWord = searchWords.find((searchWord: string) =>
    contentWord.toLowerCase().startsWith(searchWord.toLowerCase())
  )
  const match = matchedWord ? contentWord.slice(0, matchedWord.length) : ''
  const rest = matchedWord
    ? contentWord.slice(matchedWord.length, contentWord.length)
    : contentWord

  return {
    id: index,
    match,
    rest,
  }
}

// TODO: Expand this to support highlighting with background color
const HighlightFragment = ({ bold, children }: HighlightFragmentProps) => (
  <Text tag="span" bold={bold}>
    {children}
  </Text>
)

const Highlighter = ({
  content,
  searchWords,
  emboldenHighlight = false,
}: HighlighterProps) => {
  const contentWords = content.split(' ')
  const highlightedWords = contentWords.map(highlightWords(searchWords))

  return (
    <Text tag="span">
      {highlightedWords.map(({ id, match, rest }, index: number) => (
        <Text tag="span" key={`${match}${rest}${id}`}>
          {match && (
            <HighlightFragment bold={emboldenHighlight}>
              {match}
            </HighlightFragment>
          )}
          {rest}
          {index < highlightedWords.length - 1 && ' '}
        </Text>
      ))}
    </Text>
  )
}

export default Highlighter
