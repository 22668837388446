import React from 'react'

import { ResponsiveTable } from '@babylon/core-ui'

import styles from './styles.module.scss'

import FailedResult from './FailedResult'
import UnrecognisedResultWarning from './UnrecognisedResultWarning'

const resultColumns = [
  {
    accessor: 'name',
    label: 'Biomarker',
  },
  {
    accessor: 'value',
    label: 'Value',
  },
  {
    accessor: 'referenceRange',
    label: 'Range',
  },
  {
    accessor: 'units',
    label: 'Units',
  },
  {
    accessor: 'flag',
    label: 'Flag',
  },
  {
    accessor: 'notes',
    label: 'Comment',
    size: 'minmax(min-content, auto)',
  },
]

export default ({ biomarkers = {} }) => {
  const resultsWithUnrecognised = biomarkers
    ? biomarkers.map((result) =>
        result.recognised
          ? result
          : {
              ...result,
              rowClassName: styles.unrecognisedResult,
              cellClassName: styles.unrecognisedResult__cell,
            }
      )
    : []

  const resultsWithWarnings = []
  resultsWithUnrecognised.forEach((result) => {
    resultsWithWarnings.push(result)

    if (!result.recognised) {
      resultsWithWarnings.push(
        <UnrecognisedResultWarning columnLength={resultColumns.length} />
      )
    }
  })

  const results = resultsWithWarnings.map((result) =>
    result.failed ? (
      <FailedResult {...result} columnLength={resultColumns.length} />
    ) : (
      result
    )
  )

  return (
    <div className={styles.results}>
      <ResponsiveTable
        label="Test results table"
        columns={resultColumns}
        data={results}
        data-testid="result-table"
        fullWidth
      />
    </div>
  )
}
