import { defineMessages } from 'react-intl'

const baseId = 'bmi_interpretations'

export default defineMessages({
  bmi_na: {
    id: `${baseId}.bmi_na`,
    defaultMessage: 'N/A',
  },
  healthy: {
    id: `${baseId}.healthy`,
    defaultMessage: ' - healthy',
  },
  underweight: {
    id: `${baseId}.underweight`,
    defaultMessage: ' - underweight',
  },
  overweight: {
    id: `${baseId}.overweight`,
    defaultMessage: ' - overweight',
  },
  obese: {
    id: `${baseId}.obese`,
    defaultMessage: ' - obese',
  },
  bmi_out_of_range: {
    id: `${baseId}.bmi_bmi_out_of_range`,
    defaultMessage: '-out of range-',
  },
})
