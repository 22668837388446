import { UpdateQueryOptions } from '@apollo/client'
import React, { createContext, ReactNode, useContext } from 'react'

export interface UpdateQueryMap {
  [key: string]: (
    mapFn: (previousQueryResult: any, options: UpdateQueryOptions<any>) => any
  ) => void
}

export interface UpdateQueryProviderProps {
  updateMap: UpdateQueryMap
  children?: ReactNode
}

const UpdateQueryContext = createContext<UpdateQueryMap>({})

export const useUpdateQueryContext = () => {
  return useContext(UpdateQueryContext)
}

const UpdateQueryProvider = ({
  updateMap,
  children,
}: UpdateQueryProviderProps) => (
  <UpdateQueryContext.Provider value={updateMap}>
    {children}
  </UpdateQueryContext.Provider>
)

export default UpdateQueryProvider
