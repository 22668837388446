import { defineMessages } from 'react-intl'

const baseId = 'consultation.call_active_modal'

export default defineMessages({
  hang_up_button_label: {
    id: `${baseId}.hang_up_button_label`,
    defaultMessage: 'Hang up and proceed',
  },
  stay_in_call_button_label: {
    id: `${baseId}.stay_in_call_button_label`,
    defaultMessage: 'Stay in call',
  },
  dialog_title: {
    id: `${baseId}.dialog_title`,
    defaultMessage: 'Do you want to hang up?',
  },
  dialog_body: {
    id: `${baseId}.dialog_body`,
    defaultMessage:
      'You are still in call with the patient, proceeding will terminate the call.',
  },
})
