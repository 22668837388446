import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { UsEhrNotesCompletionModelInterface } from '~/core/config/modules/generated/types'
import {
  ErrorPolicy,
  observableState,
  watchUsEhrNotesQuery,
} from '~/core/reactive-helpers'
import { logException } from '~/core/sentry'

import {
  CompleteConsultationExtensionType,
  ExtensionStateType,
  ExtensionStatus,
} from '../CompleteConsultationModel'

import messages from './UsEhrNotesCompletetionModel.messages'

export interface UsEhrNotesStateType extends ExtensionStateType {
  // Add additional state fields here
}

export interface UsEhrNotesCompletionModelType
  extends CompleteConsultationExtensionType {
  state: Observable<UsEhrNotesStateType>
}

const defaultState: UsEhrNotesStateType = {
  status: ExtensionStatus.Initializing,
  errorMessage: undefined,
}

export const createUsEhrNotesCompletionModel = (
  watchUsEhrNotes: typeof watchUsEhrNotesQuery
): UsEhrNotesCompletionModelInterface => ({
  // TODO:  convert it to a model
  consultationContext,
}) => {
  const [state, updateState] = observableState<UsEhrNotesStateType>(
    defaultState
  )

  // TODO: convert this to proper RxJS stream
  let consultationId: string

  consultationContext.subscribe((consultation) => {
    consultationId = consultation.data.consultation?.id || ''
  })

  // END of TODO

  const init = () => {
    if (!consultationId) {
      throw Error('Invalid situation!')
    }

    updateState(defaultState)

    of(consultationId)
      .pipe(
        map((id) => ({
          variables: { id },
          errorPolicy: ErrorPolicy.none,
        })),
        watchUsEhrNotes()
      )
      .subscribe({
        next: ({ loading, error }) => {
          if (loading) {
            updateState({
              status: ExtensionStatus.Initializing,
              errorMessage: undefined,
            })
          } else if (error) {
            logException(error)
            updateState({
              status: ExtensionStatus.InitError,
              errorMessage: messages.error_loading_notes,
            })
          } else {
            updateState({
              status: ExtensionStatus.Initialized,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          logException(error)
          updateState({
            status: ExtensionStatus.InitError,
            errorMessage: messages.error_loading_notes,
          })
        },
      })
  }

  const submit = () => {
    updateState({ status: ExtensionStatus.Submitted, errorMessage: undefined })
  }

  return {
    state,
    init,
    submit,
  }
}

export const UsEhrNotesCompletionModel = createUsEhrNotesCompletionModel(
  watchUsEhrNotesQuery
)
