import React from 'react'

import { DialogProvider } from '~/ui/Dialog'
import { NotificationProvider } from '~/ui/Notification'

const Overlays = () => (
  <>
    <DialogProvider />
    <NotificationProvider />
  </>
)

export default Overlays
