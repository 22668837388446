import { defineMessages } from 'react-intl'

const baseId = 'header.profile.rating'

export default defineMessages({
  rating: {
    id: `${baseId}.rating`,
    defaultMessage: 'Average rating: {rating}/5',
  },
})
