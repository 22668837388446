import { withHandlers } from 'recompose'

import { mapStateToDrug, mapWarnings } from './mappings'
import { validate } from './utils'

const EMPTY = []

const handleClose = ({ redirectToConsultation }) => () => {
  redirectToConsultation()
}

const validateDrugs = async (
  state,
  validateDrugMutation,
  drugId,
  iso_code,
  consultationId,
  patientId
) => {
  let warnings = []
  const { isValid: isValidLocal } = validate(state)
  let isValid = false
  if (isValidLocal) {
    const response = await validateDrugMutation({
      variables: {
        region: iso_code,
        consultationId,
        patientId,
        drug: mapStateToDrug(state),
        ...(drugId ? { prescriptionDrugId: drugId } : null),
      },
    })
    warnings = mapWarnings(response.data.validateDrug.warnings)
    isValid = warnings.length === 0
  }
  return {
    isValidLocal,
    isValid,
    isBlocking: warnings.some((v) => v.type === 'error'),
    warnings,
  }
}

const handleSave = ({
  errorAlert,
  mode,
  state,
  data,
  validateDrugMutation,
  setWarnings,
  updateState,
  createDrug,
  updateDrug,
  redirectToConsultation,
  query,
  markFinalizedConsultationAsEdited,
}) => async ({ consultationId, patientId, consumerNetwork }) => {
  const { drugId } = query
  const { validationPending } = state
  try {
    const { isValidLocal, isValid, isBlocking, warnings } = await validateDrugs(
      state,
      validateDrugMutation,
      drugId,
      data.consultation.region.iso_code,
      consultationId,
      patientId
    )

    const canSave = isValid || (!isBlocking && !validationPending)
    if (canSave) {
      if (mode === 'create') {
        await createDrug(
          mapStateToDrug(state),
          patientId,
          consumerNetwork,
          errorAlert
        )
      } else {
        await updateDrug(mapStateToDrug(state), errorAlert)
      }
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      redirectToConsultation()
    } else {
      setWarnings(warnings)
      updateState({
        validationPending: isBlocking || !isValidLocal,
        showValidation: true,
      })
    }
  } catch (err) {
    errorAlert({ logMessage: err })
  }
}

const handleSuggest = ({
  errorAlert,
  setWarnings,
  data,
  state,
  updateState,
  suggestDrugMutation,
}) => (selector) => async () => {
  const { dosage, duration, value } = state

  if (value && dosage && duration) {
    try {
      const response = await suggestDrugMutation({
        variables: {
          drugId: value.id,
          drugName: value.name,
          dosage,
          duration,
          region: data.consultation.region.iso_code,
        },
      })

      const suggestion = response.data.suggestDrugDirections[selector]

      if (suggestion != null) {
        updateState({ [selector]: suggestion })
      }

      setWarnings(EMPTY)
    } catch (err) {
      errorAlert({ logMessage: err })
    }
  }
}

const handleInputChange = ({ updateState, setWarnings }) => (name) => (
  event
) => {
  updateState({
    [name]: typeof event === 'object' ? event.target.value : event,
    validationPending: true,
  })

  setWarnings(EMPTY)
}

const handleRefillsChange = ({ updateState, setWarnings }) => (option) => {
  updateState({
    refills: option.value,
  })

  setWarnings(EMPTY)
}

const handlePackageChange = ({ updateState }) => (option) => {
  updateState({
    packSize: option?.value ?? '',
  })
}

const handleRepeatChange = ({ updateState }) => (values) =>
  updateState({
    repeatable: values.length > 0,
  })

const handleDateFocusChange = ({ updateState }) => (event) => {
  updateState({ focused: event.focused })
}

const handleDateChangeRepeat = ({ updateState }) => (date) => {
  updateState({ renewalDate: date })
}
const handleDrugListChange = ({ state, updateState, setWarnings }) => (
  option
) => {
  if (option) {
    const value =
      option.className === 'Select-create-option-placeholder'
        ? {
            id: null,
            name: option.name,
            packaging: [],
          }
        : option
    updateState({
      value,
      dosage: state.dosage == null ? value.drugPreparation : state.dosage,
      validationPending: true,
    })
  } else {
    updateState({
      value: null,
    })
  }

  setWarnings(EMPTY)
}

const addIndication = ({ state, updateState, setWarnings }) => (_, object) => {
  updateState({
    indications: [...state.indications, object],
    validationPending: true,
  })

  setWarnings(EMPTY)
}

const removeIndication = ({ state, updateState, setWarnings }) => (id) => {
  updateState({
    indications: state.indications.filter((v) => v.id !== id),
    validationPending: true,
  })

  setWarnings(EMPTY)
}

export default withHandlers({
  handleClose,
  handleSave,
  handleSuggest,
  handleInputChange,
  handleRefillsChange,
  handlePackageChange,
  handleRepeatChange,
  handleDrugListChange,
  addIndication,
  removeIndication,
  handleDateFocusChange,
  handleDateChangeRepeat,
})
