import React from 'react'
import { FormattedMessage } from 'react-intl'

import { SelectOptionTypeBase } from '@babylon/core-ui/src'

import { TimelineEventType } from '~/generated'

import messages from './PatientTimeline.messages'

export const DEFAULT_OPTIONS: SelectOptionTypeBase[] = [
  {
    label: <FormattedMessage {...messages.chatbot_event} />,
    value: TimelineEventType.ChatscriptConversation,
    tagColor: 'clinical-blue',
  },
  {
    label: <FormattedMessage {...messages.consultation_event} />,
    value: TimelineEventType.Consultation,
    tagColor: 'clinical-blue',
  },
  {
    label: <FormattedMessage {...messages.test_result_event} />,
    value: TimelineEventType.TestReport,
    tagColor: 'clinical-blue',
  },
]

export const FILTER_DEBOUNCE = 800
export const KEYDOWN_TRACKING_DEBOUNCE = 800
export const INITIAL_EVENT_COUNT = 3
export const EVENT_COUNT_INCREMENT = 3
