import React, { useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'

import {
  TimelineEventInterface,
  TimelineEventType,
} from '@babylon/babylon-user/src/types'
import {
  SelectActionMeta,
  SelectOptionTypeBase,
  SelectValueType,
  Spinner,
  Text,
} from '@babylon/core-ui'

import {
  PATIENT_TIMELINE_ADD_FILTER_ACTION,
  PATIENT_TIMELINE_CATEGORY,
  PATIENT_TIMELINE_CLEAR_ALL_FILTERS_ACTION,
  PATIENT_TIMELINE_CLOSE_FILTER_ACTION,
  PATIENT_TIMELINE_TYPE_IN_FILTER_BAR_ACTION,
  SECTION_CATEGORY,
  SECTION_PATIENT_TIMELINE_LABEL,
  SECTION_SHOW_LESS_BUTTON_ACTION,
  SECTION_SHOW_MORE_BUTTON_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { TimelineConsultationEventPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages, useMount } from '~/core/hooks'
import { usePermissions } from '~/core/permissions'
import { scrollTo } from '~/core/scroll-tracker'

import {
  EVENT_COUNT_INCREMENT,
  INITIAL_EVENT_COUNT,
  KEYDOWN_TRACKING_DEBOUNCE,
} from '../../constants'
import usePatientTimelineQuery from '../../usePatientTimelineQuery'
import {
  getEventTypeOptions,
  getRequestError,
  mapColoredOptions,
} from '../../utils'
import TimelineSectionView from './TimelineSectionView'

import messages from '../../PatientTimeline.messages'
import styles from './styles.module.scss'

interface TimelineSectionProps {
  eventTypes: {
    consultationsEnabled?: boolean
    chatscriptConversationsEnabled?: boolean
  }
  patientId: string
  patientUuid: string
  timelineConsultationEvent?: ReturnType<TimelineConsultationEventPluginInterface>
  eventCategory: 'future' | 'current_and_past'
}

export default (props: TimelineSectionProps) => {
  const {
    eventTypes,
    patientId,
    patientUuid,
    timelineConsultationEvent,
    eventCategory,
  } = props

  const f = useMessages(messages)
  const isFutureEvents = eventCategory === 'future'

  const trackEvent = analytics.trackEventFactory({
    category: PATIENT_TIMELINE_CATEGORY,
  })

  const [visibleEventsCount, setVisibleEventsCount] = useState<number>(
    INITIAL_EVENT_COUNT
  )

  const [managePatientMedicalHistory] = usePermissions(
    'manage_patient_medical_history'
  )

  const options = useMemo(
    () =>
      getEventTypeOptions(
        eventTypes,
        managePatientMedicalHistory,
        isFutureEvents
      ),
    [eventTypes, managePatientMedicalHistory, isFutureEvents]
  )

  const [selectedTypes, setSelectedTypes] = useState<TimelineEventType[]>(
    options.map((option) => option.value)
  )

  const {
    queryResult: { data, error: queryError, refetch },
    filterEvents,
    debouncedFilterEvents,
    loading,
    setLoading,
  } = usePatientTimelineQuery(patientId, selectedTypes)

  const dynamicErrors = data?.patient?.historyV2?.errors

  const timelineEvents = isFutureEvents
    ? (data?.patient?.futureConsultations as TimelineEventInterface[]) ?? []
    : (data?.patient?.historyV2?.events as TimelineEventInterface[]) ?? []

  const timelineError = useMemo(
    () =>
      queryError ||
      getRequestError('Patient timeline dynamic error', dynamicErrors),
    [queryError, dynamicErrors]
  )
  const visibleEvents = timelineEvents.slice(0, visibleEventsCount)
  const allEventsVisible = visibleEventsCount === timelineEvents.length

  const handleShowMore = () => {
    const visibleElementsCount = Math.min(
      visibleEventsCount + EVENT_COUNT_INCREMENT,
      timelineEvents.length
    )
    setVisibleEventsCount(visibleElementsCount)
    trackEvent({
      category: SECTION_CATEGORY,
      action: SECTION_SHOW_MORE_BUTTON_ACTION,
      label: SECTION_PATIENT_TIMELINE_LABEL,
    })
  }

  const handleShowLess = () => {
    setVisibleEventsCount(INITIAL_EVENT_COUNT)
    scrollTo('history-section')
    trackEvent({
      category: SECTION_CATEGORY,
      action: SECTION_SHOW_LESS_BUTTON_ACTION,
      label: SECTION_PATIENT_TIMELINE_LABEL,
    })
  }

  const [debouncedTrackKeydownEvent] = useDebouncedCallback(() => {
    trackEvent({
      action: PATIENT_TIMELINE_TYPE_IN_FILTER_BAR_ACTION,
    })
  }, KEYDOWN_TRACKING_DEBOUNCE)

  const selectedOptions = mapColoredOptions(
    selectedTypes,
    options,
    dynamicErrors
  )

  const handleSelectChange = (
    value: SelectValueType,
    action: SelectActionMeta
  ) => {
    setLoading(true)
    setSelectedTypes(
      value?.map((option: SelectOptionTypeBase) => option.value) ?? []
    )
    debouncedFilterEvents()

    if (action.action === 'select-option') {
      trackEvent({
        action: PATIENT_TIMELINE_ADD_FILTER_ACTION,
        label: action.option?.value,
      })
    }

    if (action.removedValue) {
      trackEvent({
        action: PATIENT_TIMELINE_CLOSE_FILTER_ACTION,
        label: action.removedValue.value,
      })
    }

    if (action.action === 'clear') {
      trackEvent({
        action: PATIENT_TIMELINE_CLEAR_ALL_FILTERS_ACTION,
      })
    }
  }

  const handleRefetch = () => {
    setLoading(true)
    if (refetch) {
      refetch()
    }
  }

  const onKeyDown = () => {
    debouncedTrackKeydownEvent()
  }

  useMount(() => {
    filterEvents()
  })

  if (loading) {
    const loadingMessage = isFutureEvents
      ? f('future_timeline_loading')
      : f('current_past_timeline_loading')
    return (
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <Text>{loadingMessage}</Text>
        <div style={{ marginTop: 8 }}>
          <Spinner size="small" />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.timelineSection}>
      <TimelineSectionView
        loading={loading}
        timelineError={timelineError}
        options={options}
        handleSelectChange={handleSelectChange}
        onKeyDown={onKeyDown}
        selectedOptions={selectedOptions}
        handleRefetch={handleRefetch}
        visibleEvents={visibleEvents}
        patientId={patientId}
        patientUuid={patientUuid}
        timelineConsultationEvent={timelineConsultationEvent}
        timelineEvents={timelineEvents}
        allEventsVisible={allEventsVisible}
        handleShowLess={handleShowLess}
        handleShowMore={handleShowMore}
        isFuture={isFutureEvents}
      />
    </div>
  )
}
