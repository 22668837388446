import { gql } from '@apollo/client'

import { PATIENT_METRICS_FRAGMENT } from './fragments'

export const CONSULTATION_PATIENT_METRICS_QUERY = gql`
  query ConsultationPatientMetrics(
    $id: ID!
    $patientMetricsFilter: [String]
    $patientMetricsCount: Int
    $bloodPressureOn: Boolean!
  ) {
    consultation(id: $id) {
      ...PatientMetrics
    }
  }
  ${PATIENT_METRICS_FRAGMENT}
`

export const ADD_PATIENT_METRICS_MUTATION = gql`
  mutation addPatientMetric(
    $patientUuid: ID!
    $patientId: ID!
    $clinicianUuid: ID
    $clinicianId: ID
    $consultationId: ID!
    $clinicianRole: String
    $observationType: PatientMetricObservationType!
    $input: JSON!
    $patientMetricsFilter: PatientMetricSource!
    $consultationType: String
    $consultationUuid: String!
  ) {
    addPatientMetric(
      patientUuid: $patientUuid
      patientId: $patientId
      clinicianUuid: $clinicianUuid
      clinicianId: $clinicianId
      consultationId: $consultationId
      clinicianRole: $clinicianRole
      observationType: $observationType
      input: $input
      filter: $patientMetricsFilter
      consultationType: $consultationType
      consultationUuid: $consultationUuid
    ) {
      id
      value
      values {
        code
        value
      }
      timestamp
      performer
    }
  }
`
