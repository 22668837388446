import { useEffect } from 'react'

import {
  ERROR_LOAD_VIDEO_SESSION_ACTION,
  MULTIMEDIA_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useCpVideoSessionQuery } from '~/generated'

import {
  Action,
  ActionType,
  ErrorActionType,
} from '../CallStatusReducerModelProvider'

interface IuseVideoSessionQuery {
  consultationId: string
  dispatch: React.Dispatch<Action>
}

const useVideoSessionQuery = ({
  consultationId,
  dispatch,
}: IuseVideoSessionQuery) => {
  const { data, ...result } = useCpVideoSessionQuery({
    variables: { id: consultationId },
    context: { batch: 'call-session-query' },
  })

  const { loading, error } = result

  useEffect(() => {
    if (!loading) {
      dispatch({ type: ActionType.VIDEO_READY })
    } else {
      dispatch({ type: ActionType.VIDEO_LOADING })
    }
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      analytics.trackEvent({
        category: MULTIMEDIA_CATEGORY,
        action: ERROR_LOAD_VIDEO_SESSION_ACTION,
      })
      dispatch({
        type: ErrorActionType.VIDEO_LOADING_ERROR,
        error,
      })
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const videoSession = data?.consultation?.videoSession
  const consultant = data?.consultation?.consultant

  return { videoSession, consultant, ...result }
}

export default useVideoSessionQuery
