export const mapStateToReferral = (state) => {
  const referral = {
    specialismId: state.specialism.id,
    urgent: state.urgent,
  }

  if (state.comments) {
    referral.comments = {
      note: state.comments,
    }
  }

  const { specialist } = state

  if (specialist) {
    referral.externalSpecialist = {
      name: specialist.practitionerName,
      externalReference: specialist.practitionerId,
    }

    const { telecom } = specialist
    const fax = telecom && telecom.find((v) => v.system === 'fax')

    if (fax) {
      referral.fax = {
        destinationNumber: fax.value,
        destinationName: fax.use,
      }
    }
  }

  return referral
}
