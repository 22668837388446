import { defineMessages } from 'react-intl'

const baseId = 'bp_utils'

export default defineMessages({
  na_text: {
    id: `${baseId}.na_text`,
    defaultMessage: 'N/A',
  },
  blood_pressure_unit_v2: {
    id: `${baseId}.blood_pressure_unit_v2`,
    defaultMessage: 'mmHg',
  },
})
