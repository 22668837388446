import { defineMessages } from 'react-intl'

export default defineMessages({
  preparation_section_group_title: {
    id: 'consultation.preparation_section_group_title',
    defaultMessage: 'Preparation',
  },
  examination_section_group_title: {
    id: 'consultation.examination_section_group_title',
    defaultMessage: 'History And Examination',
  },
  diagnosis_section_group_title: {
    id: 'consultation.diagnosis_section_group_title',
    defaultMessage: 'Diagnosis And Plan',
  },
  actions_section_group_title: {
    id: 'consultation.actions_section_group_title',
    defaultMessage: 'Actions',
  },
  care_plan_section_group_title: {
    id: 'consulation.care_plan_section_group_title',
    defaultMessage: 'Care plan',
  },
  actions_section_nhs_actions_subtitle: {
    id: 'consulation.actions_section_nhs_actions_subtitle',
    defaultMessage: 'NHS actions',
  },
})
