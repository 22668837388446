import React, { ReactNode } from 'react'

import styles from './styles.module.scss'

type RatingType = 'normal' | 'warning' | 'danger' | 'na' | 'generic'

type TrafficLightDataProps = {
  value: ReactNode
  rating: RatingType
  sideNote?: any
  style?: any
  addSpacing?: boolean
  testId?: string
}

const rightMargin = (spacing: boolean) => {
  return {
    marginRight: spacing ? '8px' : '0px',
  }
}

const TrafficLightDataPoint: React.FC<TrafficLightDataProps> = ({
  sideNote,
  value = 'N/A',
  rating = 'na',
  style,
  addSpacing = false,
  testId,
}) => (
  <div className={styles.metric} style={style} data-testid={testId}>
    <span
      className={styles[rating]}
      style={rightMargin(addSpacing)}
      data-testid={`${testId}-highlight`}
    >
      {value}
    </span>
    {sideNote}
  </div>
)

export default TrafficLightDataPoint
