/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEvent } from 'react'

import { Heading } from '@babylon/core-ui'

import { PatientMetric } from '~/features/patient-metrics/PatientMetrics/PatientMetrics'
import TrafficLightDataPoint from '~/ui/TrafficLightDataPoint'

import Timestamp, { useTimestampFormatter } from '../utils/Timestamp'

import styles from './styles.module.scss'

type PatientMetricProps = {
  metric: PatientMetric
  onClick?: (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
  testId?: string
}

const PatientMetricContainer = ({
  metric,
  onClick,
  testId,
}: PatientMetricProps) => {
  const { label, value, rating, sideNote, performer } = metric
  const formatTimestamp = useTimestampFormatter()
  const timestamp = formatTimestamp(metric.timestamp)

  return (
    <>
      <div className={styles.container} onClick={onClick}>
        <Heading level="h3" className={styles.heading}>
          {label}
        </Heading>
        <TrafficLightDataPoint
          value={value}
          rating={rating}
          sideNote={sideNote}
          addSpacing
          testId={testId}
        />
        <div>
          <Timestamp
            timestamp={timestamp}
            performer={performer}
            className={styles.timestamp}
          />
        </div>
      </div>
    </>
  )
}

export default PatientMetricContainer
