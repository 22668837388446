import { defineMessages } from 'react-intl'

const baseId = 'apollo_network_connection'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'No internet connection',
  },
  message: {
    id: `${baseId}.message`,
    defaultMessage: 'You are offline, please check your Internet connection.',
  },
})
