import React from 'react'

import { useMessages } from '~/core/hooks'
import { TaintedInfo } from '~/core/hooks/useTaintedInfo'

import messages from './ExternalTabsWarning.messages'
import styles from './styles.module.scss'

type ExternalTabsWarningPresentationProps = {
  taintedInfo: TaintedInfo
}

const classNames = {
  [TaintedInfo.Pending]: null,
  [TaintedInfo.Safe]: null,
  [TaintedInfo.SafeButMirrored]: 'safeButMirrored',
  [TaintedInfo.Tainted]: 'tainted',
  [TaintedInfo.TaintedButRefreshable]: 'taintedButRefreshable',
} as const

export const ExternalTabsWarningPresentation = ({
  taintedInfo,
}: ExternalTabsWarningPresentationProps) => {
  const fm = useMessages(messages)
  const alertClass = classNames[taintedInfo]

  return !alertClass ? (
    <></>
  ) : (
    <div role="alert" className={styles[alertClass]}>
      <span>{fm(alertClass)}</span>
      {taintedInfo === TaintedInfo.TaintedButRefreshable && (
        <button
          type="button"
          className={styles.reloadButton}
          onClick={() => window.location.reload()}
        >
          {fm('refresh')}
        </button>
      )}
    </div>
  )
}

export default ExternalTabsWarningPresentation
