import { defineMessages } from 'react-intl'

const baseId = 'prescriptions_table'

export default defineMessages({
  table_label: {
    id: `${baseId}.table_label`,
    defaultMessage: 'Prescription',
  },
  drug_name: {
    id: `${baseId}.drug_name`,
    defaultMessage: 'Drug name',
  },
  drug_quantity: {
    id: `${baseId}.drug_quantity`,
    defaultMessage: 'Quantity',
  },
  drug_directions: {
    id: `${baseId}.drug_directions`,
    defaultMessage: 'Directions',
  },
  repeatable: {
    id: `${baseId}.repeatable`,
    defaultMessage: 'Repeatable',
  },
  repeats: {
    id: `${baseId}.repeats`,
    defaultMessage: 'Repeats: {value}',
  },
  review_date: {
    id: `${baseId}.review_date`,
    defaultMessage: 'Review Date',
  },
})
