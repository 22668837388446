import { defineMessages } from 'react-intl'

const baseId = 'consultation.modal.test'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private`,
    defaultMessage: 'Private Test',
  },
  test_type_options_placeholder: {
    id: `${baseId}.testTypeOptions.placeholder`,
    defaultMessage: 'Pathology or Diagnostic imaging',
  },
  test_type_options_label: {
    id: `${baseId}.testTypeOptions.label`,
    defaultMessage: 'Type *',
  },
  test_type_option_pathology: {
    id: `${baseId}.test_type_options.pathology`,
    defaultMessage: 'Pathology',
  },
  test_type_option_diagnostic: {
    id: `${baseId}.test_type_options.diagnostic`,
    defaultMessage: 'Diagnostic',
  },
  test_options_placeholder: {
    id: `${baseId}.testOptions.placeholder`,
    defaultMessage: 'Search and select test',
  },
  test_options_label: {
    id: `${baseId}.testOptions.label`,
    defaultMessage: 'Test *',
  },
  reason_tooltip: {
    id: `${baseId}.reason.tooltip`,
    defaultMessage: `Please ensure you include sufficient
clinical detail for the request to
be processed by the provider`,
  },
  reason_label: {
    id: `${baseId}.reason.label`,
    defaultMessage: 'Reason',
  },
  reason_placeholder: {
    id: `${baseId}.reason.placeholder`,
    defaultMessage: 'Reason for test',
  },
  create_button_text: {
    id: `${baseId}.create_button_text`,
    defaultMessage: 'Create',
  },
  update_button_text: {
    id: `${baseId}.update_button_text`,
    defaultMessage: 'Update',
  },
  cancel_button_text: {
    id: `${baseId}.cancel_button_text`,
    defaultMessage: 'Cancel',
  },
  test_type_validation: {
    id: `${baseId}.test_type_validation`,
    defaultMessage: 'Please select a test',
  },
  test_validation: {
    id: `${baseId}.test_validation`,
    defaultMessage: 'Please select a type',
  },
  submit_error: {
    id: `${baseId}.submit_error`,
    defaultMessage: 'This test could not be saved. Please try again',
  },
})
