import React from 'react'
import { format, isDate } from 'date-fns'
import TimeAgo from 'timeago-react'

import useIntl from '../useIntl'

const BabylonTimeAgo = ({ date, live = true, prefix, suffix }) => {
  const { locale } = useIntl()
  const formattedDate = format(
    isDate(date) ? date : new Date(date),
    'yyyy-MM-dd HH:mm:ss'
  )

  return (
    <>
      {prefix && `${prefix} `}
      <TimeAgo datetime={formattedDate} locale={locale} live={live} />
      {suffix && ` ${suffix}`}
    </>
  )
}

export default BabylonTimeAgo
