import { defineMessages } from 'react-intl'

const baseId = 'consultation.booking_reason_summary'

export default defineMessages({
  booking_reason: {
    id: `${baseId}.booking_reason`,
    defaultMessage: 'Booking reason',
  },
})
