import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import clipboardCopy from 'clipboard-copy'
import React, { useEffect, useRef, useState } from 'react'

import { useFormatMessage } from '@babylon/intl'
import clipboard from '@babylon/restricted-clipboard'

import { ReactComponent as CopyToClipboardIcon } from '~/assets/copy-to-clipboard.svg'
import Tooltip, { TooltipProps } from '~/ui/Tooltip'

import messages from './CopyButton.messages'
import styles from './CopyButton.module.scss'

interface CopyButtonProps {
  onCopy?: () => void
  clipboardData?: string
  restrictedClipboardData?: React.RefObject<HTMLElement>
  className?: string
  tooltipMessage?: string
  ['data-testid']?: string
  tooltipProps?: TooltipProps
}

const CopyButton = ({
  onCopy,
  clipboardData,
  restrictedClipboardData,
  className,
  tooltipMessage,
  'data-testid': dataTestId,
  tooltipProps,
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false)
  const [failed, setFailed] = useState(false)
  const timeout = useRef<number>()
  const fm = useFormatMessage()

  const handleClick = async (event: React.MouseEvent) => {
    event.stopPropagation()

    if (!clipboardData && !restrictedClipboardData?.current) {
      return
    }

    try {
      if (restrictedClipboardData?.current) {
        if (process.env.NODE_ENV === 'development') {
          clipboardCopy(restrictedClipboardData.current.innerText)
        } else {
          clipboard.copyToRestrictedClipboard(restrictedClipboardData.current)
        }
      }

      if (clipboardData) {
        await clipboardCopy(clipboardData)
      }

      setCopied(true)
    } catch (err) {
      setFailed(true)
    }

    revertAfterTimeout()

    if (typeof onCopy === 'function') {
      onCopy()
    }
  }

  const revertAfterTimeout = async () => {
    if (timeout.current) {
      clearInterval(timeout.current)
    }
    timeout.current = window.setTimeout(() => {
      setCopied(false)
      setFailed(false)
    }, 3000)
  }

  useEffect(() => {
    return () => clearInterval(timeout.current)
  }, [])

  const resolveTooltipValue = () => {
    if (copied || failed) {
      return failed ? fm(messages.failed_label) : fm(messages.copied_label)
    }

    return tooltipMessage || fm(messages.default_tooltip_message)
  }

  const resolveIcon = () => {
    if (copied) {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          className={styles.success}
          style={{ height: '13px', width: '14px' }}
          data-testid="copy-button-success-icon"
        />
      )
    }

    if (failed) {
      return (
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.error}
          style={{ height: '13px', width: '14px' }}
          data-testid="copy-button-failure-icon"
        />
      )
    }

    return (
      <CopyToClipboardIcon
        width="20"
        height="100%"
        viewBox="0 0 24 24"
        className={styles.copyIcon}
        data-testid="copy-button-icon"
      />
    )
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      <Tooltip message={resolveTooltipValue()} {...tooltipProps}>
        <button
          className={styles.copyButton}
          type="button"
          onClick={handleClick}
          data-testid={dataTestId || 'copy-button'}
        >
          {resolveIcon()}
        </button>
      </Tooltip>
    </div>
  )
}

export default CopyButton
