import { registerLocale as registerCountriesLocale } from 'i18n-iso-countries'
import React, { useState } from 'react'
import { registerLocale as registerDatePickerLocale } from 'react-datepicker'
import { IntlShape } from 'react-intl'

import { spinnerLoaderInstance } from '@babylon/core-ui'
import { BabylonIntl } from '@babylon/intl'

import { logException } from '~/core/sentry'
import ErrorPage from '~/ui/ErrorPage'

import { useMessages } from '../hooks'
import formats from './formats'
import loadPackageData from './loadPackageData'
import { getInitialLocale, TEST_LOCALE } from './locale'
import OnIntlReady from './OnIntlReady'

import messages from './I18n.messages'

const I18nErrorPage = () => {
  const f = useMessages(messages)

  return <ErrorPage title={f('error_title')} />
}

interface I18nProps {
  children: React.ReactNode
  onIntlReady: (intl: IntlShape) => void
}

const I18n = ({ children, onIntlReady }: I18nProps) => {
  const [hasError, setHasError] = useState(false)

  const getMessagesOnLocaleChange = async (
    locale: string
  ): Promise<Dictionary<string>> => {
    if (locale === TEST_LOCALE) {
      // If test locale, use the default messages
      return {}
    }

    try {
      const [messagesData, packageData] = await Promise.all([
        import(
          /* webpackChunkName: "messages.[request]" */
          `../../messages/${locale.replace('-', '_')}.json`
        ),
        loadPackageData(locale),
      ])

      if (packageData.dateFns?.default) {
        registerDatePickerLocale(locale, packageData.dateFns?.default)
      }

      if (packageData.i18nIsoCountries) {
        registerCountriesLocale(packageData.i18nIsoCountries)
      }

      if (messagesData?.default) {
        return messagesData.default
      }

      return {}
    } catch (error) {
      logException(error)
      setHasError(true)
    }

    return {}
  }

  return (
    <BabylonIntl
      textComponent="span"
      initialLocale={getInitialLocale()}
      getMessagesOnLocaleChange={getMessagesOnLocaleChange}
      formats={formats}
      loadingComponent={() => spinnerLoaderInstance}
      onError={() => {}}
    >
      {hasError ? (
        <I18nErrorPage />
      ) : (
        <>
          <OnIntlReady onIntlReady={onIntlReady} />
          {children}
        </>
      )}
    </BabylonIntl>
  )
}

export default I18n
