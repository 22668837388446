import cn from 'classnames'
import React, { CSSProperties } from 'react'

import { Chevron, Heading, Text, useAccordion } from '@babylon/core-ui'

import {
  PAT_MET_COLLAPSE_BMI,
  PAT_MET_COLLAPSE_SMOKING_STATUS,
  PAT_MET_EXPAND_BMI,
  PAT_MET_EXPAND_SMOKING_STATUS,
  PATIENT_METRICS,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import TrafficLightDataPoint from '~/ui/TrafficLightDataPoint'

import Timestamp from '../utils/Timestamp'

import styles from './styles.module.scss'

type PatientMetricSectionName =
  | 'bmiSection'
  | 'smokingStatusSection'
  | 'bloodPressureSection'

interface PatientMetricsFormTemplateProps {
  title: string
  children: React.ReactNode
  value: string | number | React.ReactNode
  rating: 'normal' | 'warning' | 'danger' | 'na' | 'generic'
  sideNote?: React.ReactNode
  style?: CSSProperties
  startExpanded?: boolean
  timestamp?: string
  dataTestId?: string
  sectionName: PatientMetricSectionName
  sectionHeaderAlert?: string | null
  showTimeStamp?: boolean
  performer?: string
}

const getExpandGaLabel = (sectionName: PatientMetricSectionName) => {
  switch (sectionName) {
    case 'bmiSection':
      return PAT_MET_EXPAND_BMI
    case 'smokingStatusSection':
      return PAT_MET_EXPAND_SMOKING_STATUS
    default:
      return null
  }
}

const getCollapseGaLabel = (sectionName: PatientMetricSectionName) => {
  switch (sectionName) {
    case 'bmiSection':
      return PAT_MET_COLLAPSE_BMI
    case 'smokingStatusSection':
      return PAT_MET_COLLAPSE_SMOKING_STATUS
    default:
      return null
  }
}

const PatientMetricsFormTemplate: React.FC<PatientMetricsFormTemplateProps> = ({
  title,
  children,
  value,
  rating,
  sideNote,
  style,
  startExpanded = true,
  timestamp,
  dataTestId,
  sectionName,
  sectionHeaderAlert,
  showTimeStamp = true,
  performer,
}) => {
  const { wrapper, accordionHeight, handleClick } = useAccordion(startExpanded)
  const isExpanded = accordionHeight !== 0

  const handleAccordianClick = () => {
    const label = isExpanded
      ? getCollapseGaLabel(sectionName)
      : getExpandGaLabel(sectionName)

    if (label) {
      analytics.trackEvent({
        label,
        category: PATIENT_METRICS,
        action: UPDATE_PATIENT_METRICS,
      })
    }
    handleClick()
  }

  return (
    <div style={style} data-testid={dataTestId}>
      <div
        onClick={handleAccordianClick}
        data-testid="patient-metrics-form-header"
      >
        <Heading level="h3" className={styles.title}>
          <div className={styles.flex}>
            <Chevron
              data-testid="chevron"
              direction="down"
              className={cn(
                styles.chevronIcon,
                isExpanded && styles.chevronIcon_expanded
              )}
            />
            {title}
            <div className={styles.middle}>
              <TrafficLightDataPoint
                value={value}
                rating={rating}
                sideNote={sideNote}
                testId="metric-value"
              />
              {sectionHeaderAlert && (
                <Text
                  color="grey-type"
                  size="small"
                  style={{ marginLeft: '10px' }}
                >
                  {sectionHeaderAlert}
                </Text>
              )}
            </div>
            <Text color="grey-type" size="small">
              {showTimeStamp && (
                <Timestamp timestamp={timestamp} performer={performer} />
              )}
            </Text>
          </div>
        </Heading>
      </div>
      <div
        className={styles.content}
        style={{
          overflow: 'hidden',
          maxHeight: accordionHeight,
        }}
      >
        <div ref={wrapper}>
          <div className={styles.contentWrap}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default PatientMetricsFormTemplate
