import {
  preprocessTimeline,
  reducerByProblems,
} from '~/features/problems-list/utils/grouping'
import { Consultation } from '~/generated'

import { ProblemRecord } from './types'

/**
 * order the subgroups by time
 */
const getSortedGroups = (
  history: Consultation[],
  consultationId: string
): Array<Array<ProblemRecord>> => {
  const groupedByProblemsList = preprocessTimeline(
    history,
    consultationId
  ).reduce(reducerByProblems, {})

  return Object.values(groupedByProblemsList).map((list: any) =>
    list.sort(
      (a: ProblemRecord, b: ProblemRecord) =>
        new Date(b.scheduledTime).getTime() -
        new Date(a.scheduledTime).getTime()
    )
  )
}

/**
 * check records that match current consultantId or current consultantUuid
 */
const hasRecordsByConsultantId = (
  records: ProblemRecord[],
  consultantId: string,
  consultantUuid: string
) => {
  return records.some((record) => {
    return (
      record?.consultantId === consultantUuid ||
      record?.consultantId === consultantId
    )
  })
}

/**
 * puts at the top most recent diagnosis
 * ordered by time within the group
 */
export const mapHistoryToRecentEpisodes = (
  history: Consultation[],
  consultationId: string
): Array<Array<ProblemRecord>> => {
  const sortedGroups = getSortedGroups(history, consultationId)

  return sortedGroups.sort(
    (a: Array<ProblemRecord>, b: Array<ProblemRecord>) =>
      new Date(b[0].scheduledTime).getTime() -
      new Date(a[0].scheduledTime).getTime()
  )
}

/**
 * puts at the top most frequent diagnosis codes
 * order by time within the group
 */
export const mapHistoryToMostFrequent = (
  history: Consultation[],
  consultationId: string
): Array<Array<ProblemRecord>> => {
  const sortedGroups = getSortedGroups(history, consultationId)

  return sortedGroups.sort(
    (a: Array<ProblemRecord>, b: Array<ProblemRecord>) => b.length - a.length
  )
}

/**
 * Puts a group with a consultation the belongs to the current consultant at the top
 * within the group order by time
 */
export const mapHistoryToMyConsultations = (
  history: Consultation[],
  consultationId: string,
  consultantUuid: string,
  consultantId: string
): Array<Array<ProblemRecord>> => {
  const sortedGroups = getSortedGroups(history, consultationId)

  return sortedGroups.sort(
    (a: Array<ProblemRecord>, b: Array<ProblemRecord>) => {
      const foundA = hasRecordsByConsultantId(a, consultantId, consultantUuid)
      const foundB = hasRecordsByConsultantId(b, consultantId, consultantUuid)

      if (foundB && !foundA) return 1
      if (foundA && !foundB) return -1

      return (
        new Date(b[0].scheduledTime).getTime() -
        new Date(a[0].scheduledTime).getTime()
      )
    }
  )
}
