import { useMemo } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { usePermissions } from '~/core/permissions'

const useModulePermission = (moduleName: string) => {
  const user = useConsultantUser()

  const moduleList = useMemo(
    () => user.roles.map(({ name: role }) => `${moduleName}:${role}`),
    [moduleName, user.roles]
  )

  return usePermissions(...moduleList).includes(true)
}

export default useModulePermission
