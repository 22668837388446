import { useCpMultimediaFeedbackQuestionQuery } from '~/generated'

const useFeedbackQuestionQuery = (consultationId: string) => {
  return useCpMultimediaFeedbackQuestionQuery({
    variables: {
      consultationId,
    },
    fetchPolicy: 'network-only',
    context: {
      batch: 'call-quality-feedback',
    },
  })
}

export default useFeedbackQuestionQuery
