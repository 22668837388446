import { gql } from '@apollo/client'

export default gql`
  mutation UpdatePatientGpDetailsMutation(
    $patientId: Int!
    $consultationId: ID!
    $gpConsent: Boolean
    $formData: GPInformation!
  ) {
    updateGP(id: $patientId, formData: $formData) {
      id
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
    }
    updateConsultationGpConsent(id: $consultationId, gpConsent: $gpConsent) {
      id
      gpConsent
    }
  }
`
