import { differenceInSeconds } from 'date-fns'
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik'
import React, { useState } from 'react'

import { FormikInput, Heading } from '@babylon/core-ui'

import {
  CARE_PLAN_GOAL_NOTES_ACTION,
  CARE_PLANS_CATEGORY,
  NOTE_TAKING_TIMER,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages } from '~/core/hooks'

import { LocalActionDraft, LocalCarePlanDraft } from '../../CarePlan.types'
import EditAction from './EditAction'
import { getEmptyLocalAction, getEmptyLocalGoal } from './LocalDraftUtils'

import styles from '../styles.module.scss'
import messages from './CarePlanModalForm.messages'

const isBlank = (text?: string): boolean => !text?.trim()
const areNoActionsWithDescriptions = (actions: LocalActionDraft[]) =>
  actions.length === 0 ||
  (actions.length === 1 && isBlank(actions[0].description))

const EditGoalAndActions = ({
  index: goalIndex,
  length: goalsCount,
  arrayHelpers: goalArrayHelpers,
}: {
  index: number
  length: number
  arrayHelpers: FieldArrayRenderProps
}) => {
  const f = useMessages(messages)
  const formik = useFormikContext<LocalCarePlanDraft>()
  const [startTime, setStartTime] = useState(0)

  const trackNoteTakingTime = () => {
    const time = differenceInSeconds(Date.now(), startTime)
    analytics.trackEvent({
      action: CARE_PLAN_GOAL_NOTES_ACTION,
      category: CARE_PLANS_CATEGORY,
      label: NOTE_TAKING_TIMER,
      value: time,
    })
  }

  return (
    <>
      <Heading
        level="h2"
        color="grey-type"
        className={styles.healthGoalHeading}
      >
        {f('goal_heading')}
      </Heading>
      <div className={styles.contentWrap}>
        <FormikInput
          name={`goals.${goalIndex}.description`}
          placeholder={f('enter_health_goal_placeholder')}
          className={styles.fullWidthInput}
          disabled={formik.isSubmitting}
          onFocus={() => setStartTime(Date.now())}
          onBlur={(event) => {
            if (
              isBlank(event.currentTarget.value) &&
              goalIndex !== goalsCount - 1 &&
              areNoActionsWithDescriptions(
                formik.values.goals[goalIndex].associatedActionDrafts
              )
            ) {
              goalArrayHelpers.remove(goalIndex)
            }
            trackNoteTakingTime()
          }}
          onChange={(event) => {
            const emptyAction = getEmptyLocalAction()
            const emptyGoal = getEmptyLocalGoal()

            if (!isBlank(event.currentTarget.value)) {
              if (goalIndex === goalsCount - 1) {
                goalArrayHelpers.push(emptyGoal)
              }

              const originalGoal = formik.values.goals[goalIndex]

              if (originalGoal.associatedActionDrafts.length === 0) {
                const updatedGoal = {
                  ...originalGoal,
                  description: event.currentTarget.value,
                  associatedActionDrafts: [
                    ...originalGoal.associatedActionDrafts,
                    emptyAction,
                  ],
                }

                goalArrayHelpers.replace(goalIndex, updatedGoal)
              }
            }
          }}
        />
        {formik.values.goals[goalIndex].associatedActionDrafts.length !== 0 && (
          <>
            <Heading
              level="h3"
              color="grey-type"
              className={styles.healthActionHeading}
            >
              {f('actions_heading')}
            </Heading>
            <FieldArray
              name={`goals.${goalIndex}.associatedActionDrafts`}
              render={(actionArrayHelpers) => (
                <>
                  {formik.values.goals[goalIndex].associatedActionDrafts.map(
                    (action, actionIndex, actions) => (
                      <EditAction
                        key={action.localId}
                        goalIndex={goalIndex}
                        goalArrayHelpers={goalArrayHelpers}
                        goalsCount={goalsCount}
                        actionIndex={actionIndex}
                        actionArrayHelpers={actionArrayHelpers}
                        actionsCount={actions.length}
                      />
                    )
                  )}
                </>
              )}
            />
          </>
        )}
      </div>
    </>
  )
}

export default EditGoalAndActions
