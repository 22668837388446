import { defineMessages } from 'react-intl'

const baseId = 'consultation.workflow_actions_summary'

export default defineMessages({
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: 'We can’t load ClinOps actions right now',
  },
})
