import React from 'react'
import { Alert } from '@babylon/core-ui'

import styles from '../styles.module.scss'

export default ({ info }) => (
  <Alert className={styles.report__labInfo} title={info.title} intent="warning">
    {info.value && <div>{info.value}</div>}
    {info.comments && <div>{info.comments}</div>}
  </Alert>
)
