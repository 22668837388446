import React from 'react'

import { Alert } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import messages from '../NhsAppointment.messages'

type Props = {
  serviceName: string
  slot: string
}

const FirstAvailableSlot: React.FC<Props> = ({ serviceName, slot }) => {
  const { formatDate, formatMessage, formatTime } = useIntl()
  const message = formatMessage(messages.available_slot_text, {
    serviceName,
    time: formatTime(slot),
    date: formatDate(slot),
  })

  return <Alert intent="primary">{message}</Alert>
}

export default FirstAvailableSlot
