import classnames from 'classnames'
import React from 'react'

import { Anchor, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  CLICK_RESOURCE_LINK,
  RESOURCE_NAVIGATION_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'

import { LinksAndResourcesItem } from '../useLinksAndResources'

import messages from './LinksAndResourcesModal.messages'
import styles from './styles.module.scss'

interface LinkListProps {
  links: LinksAndResourcesItem[]
  testid?: string
}

const handleClick = (label: string) => {
  analytics.trackEvent({
    category: RESOURCE_NAVIGATION_CATEGORY,
    action: CLICK_RESOURCE_LINK,
    label,
  })
}

const LinkList = ({ links, testid }: LinkListProps) => {
  const fm = useFormatMessage()

  if (!links.length) {
    return <div data-testid={testid}>{fm(messages.no_links)}</div>
  }

  return (
    <ul className={styles.links} data-testid={testid}>
      {links.map((link: LinksAndResourcesItem) => (
        <li key={link.url + link.title}>
          <Text size="large" color="grey-type">
            <i className={classnames(link.icon, styles.linkIcon)} />
            <Anchor
              href={link.url}
              target="blank"
              onClick={() => handleClick(link.title)}
            >
              {link.title}
            </Anchor>
          </Text>
        </li>
      ))}
    </ul>
  )
}

export default LinkList
