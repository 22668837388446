import React from 'react'
import { FormattedDate } from 'react-intl'

import { useFormatMessage } from '@babylon/intl'

import { ErrorPanel } from '~/ui/ErrorPanel'
import { ModalContent, RouteModal } from '~/ui/LegacyModal'

import messages from './PrescriptionAuditModal.messages'
import styles from './styles.module.scss'

const PrescriptionAuditModal = ({ data, redirectToConsultation }) => {
  const fm = useFormatMessage()

  const audit = data?.prescriptionAudit ?? []

  return (
    <RouteModal onRequestClose={redirectToConsultation}>
      <ModalContent title={fm(messages.title)}>
        {data?.error ? (
          <ErrorPanel
            error={data.error}
            title={fm(messages.error_title)}
            retry={() => data.refetch()}
            fill="container"
            center
          />
        ) : (
          audit.map((value) => (
            <div className={styles.item} key={value.id}>
              <div className={styles.row}>
                <div className={styles.time}>
                  <FormattedDate
                    value={value.eventTimestamp}
                    format="shortWithTime"
                  />
                </div>
                <div className={styles.time}>
                  {value.eventType.replace(/_+/g, ' ').toLowerCase()}
                </div>
              </div>
              <div className={styles.row}>
                <div>{value.eventDetails}</div>
                <div>{value.performedByName}</div>
              </div>
            </div>
          ))
        )}
      </ModalContent>
    </RouteModal>
  )
}

export default PrescriptionAuditModal
