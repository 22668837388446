import React from 'react'

import styles from './styles.module.scss'

const PrescriptionTitle = () => (
  <tr className={styles.suggestedPrescriptionSeparator}>
    <td colSpan="7">
      <hr />
    </td>
  </tr>
)

export default PrescriptionTitle
