import { gql } from '@apollo/client'

export default gql`
  query ConsultationGpDetailsQuery($consultationId: ID!) {
    consultation(id: $consultationId) {
      id
      gpConsent
      patient {
        id
        gp_name
        gp_surgery_name
        gp_surgery_phone_number
        gp_address_first_line
        gp_address_post_code
        gp_address_second_line
        gp_address_third_line
      }
    }
  }
`
