import { useContext } from 'react'

import BabylonLocaleContext from '../BabylonLocaleContext'

const useChangeLocale = () => {
  const { changeLocale } = useContext(BabylonLocaleContext)

  return changeLocale
}

export default useChangeLocale
