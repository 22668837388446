import { sanitizeUrl } from '@braintree/sanitize-url'
import React from 'react'
import { NavLink as ReactRouterLink } from 'react-router-dom'

import {
  APP_NAVIGATION_CLICK_ACTION,
  NAVIGATION_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { BelowDesktop, Desktop } from '~/ui/Responsive'
import Tooltip from '~/ui/Tooltip'

import styles from './styles.module.scss'

const trackNavigationClick = (label: string) => () => {
  analytics.trackEvent({
    category: NAVIGATION_CATEGORY,
    action: APP_NAVIGATION_CLICK_ACTION,
    label,
  })
}

interface NavigationLinkProps {
  children?: React.ReactNode
  to?: string
  exact?: boolean
  external?: boolean
  icon: React.ReactNode
  className?: string
  onClick?: () => void
  badge?: React.ReactNode
}

const NavigationLink = ({
  children,
  to,
  external = false,
  icon,
  onClick,
  badge,
  ...props
}: NavigationLinkProps) => {
  const handleClick = () => {
    if (to) {
      trackNavigationClick(to)
    }
    if (onClick) {
      onClick()
    }
  }

  const link = (
    <>
      <BelowDesktop>
        <Tooltip message={children} placement="right">
          <span className={styles.icon}>
            {icon}
            {badge}
          </span>
        </Tooltip>
      </BelowDesktop>

      <Desktop>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.text}>
          {children}
          {badge}
        </span>
      </Desktop>
    </>
  )

  return (
    <>
      {external || !to ? (
        <a
          href={to ? sanitizeUrl(to) : undefined}
          className={styles.link}
          target={to}
          onClick={handleClick}
        >
          {link}
        </a>
      ) : (
        <ReactRouterLink
          className={styles.link}
          activeClassName={styles.active}
          to={to}
          onClick={handleClick}
          {...props}
        >
          {link}
        </ReactRouterLink>
      )}
    </>
  )
}

export default NavigationLink
