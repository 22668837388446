import { ApolloError } from '@apollo/client'
import { Observable, of } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

import { PrescriptionPromotionModelInterface } from '~/core/config/modules/generated/types'
import {
  ErrorPolicy,
  observableState,
  promotePrescriptionMutation,
  watchPrescriptionStateQuery,
} from '~/core/reactive-helpers'
import { logException } from '~/core/sentry'
import { PrescriptionStateActionType } from '~/generated'

import {
  CompleteConsultationExtensionType,
  ExtensionStateType,
  ExtensionStatus,
} from './CompleteConsultationModel'

import messages from './CompleteDialogPlugin/CompleteDialogPlugin.messages'

export interface PrescriptionPromotionStateType extends ExtensionStateType {
  pin: string
  // Add additional state fields here
}

export interface PrescriptionPromotionModelType
  extends CompleteConsultationExtensionType {
  updatePin: (newPin: string) => void
  state: Observable<PrescriptionPromotionStateType>
}

const defaultState: PrescriptionPromotionStateType = {
  pin: '',
  status: ExtensionStatus.Initializing,
  errorMessage: undefined,
}

// Checks if one of the errors is Invalid clinician pin
const checkErrorForPinValidation = (error: ApolloError) => {
  return error?.graphQLErrors?.some((innerError) => {
    return (
      innerError?.extensions?.response?.body?.message ===
      'Invalid clinician pin'
    )
  })
}

export const createPrescriptionPromotionModel = (
  watchPrescriptionState: typeof watchPrescriptionStateQuery,
  promotePrescription: typeof promotePrescriptionMutation
): PrescriptionPromotionModelInterface => ({
  // TODO:  convert it to a model
  consultationContext,
}) => {
  const [
    state,
    updateState,
    resetState,
  ] = observableState<PrescriptionPromotionStateType>(defaultState)

  // TODO: convert this to proper RxJS stream
  let consultationId: string
  let region: string
  let canReadAnyPrescription: boolean
  let prescriptionId: string

  const reset = () => {
    consultationId = ''
    region = ''
    canReadAnyPrescription = false
    prescriptionId = ''
    resetState()
  }

  consultationContext.subscribe((result) => {
    const { consultation } = result.data

    if (consultation) {
      consultationId = consultation.id
      prescriptionId = ''
      region = consultation.region?.iso_code || ''
      canReadAnyPrescription = Boolean(
        consultation.permissions?.find(
          (v) => v.name === 'read_any_prescription'
        )?.value
      )
    } else {
      reset()
    }
  })

  // END of TODO

  const init = () => {
    if (!consultationId) {
      throw Error('Cannot init model without consultationId!')
    }

    updateState(defaultState)

    of(consultationId)
      .pipe(
        filter(() => {
          if (!canReadAnyPrescription) {
            updateState({
              status: ExtensionStatus.Initialized,
              errorMessage: undefined,
            })
            return false
          }
          return true
        }),
        map((id) => ({
          errorPolicy: ErrorPolicy.none,
          variables: { id },
        })),
        watchPrescriptionState()
      )
      .subscribe({
        next: ({ loading, error, data }) => {
          prescriptionId = ''
          if (loading) {
            updateState({
              status: ExtensionStatus.Initializing,
              errorMessage: undefined,
            })
          } else if (error) {
            updateState({
              status: ExtensionStatus.InitError,
              errorMessage: messages.error_loading_prescriptions,
            })
            logException(error)
          } else {
            const prescriptions = data?.consultation?.prescriptions ?? []
            const prescription = prescriptions.find(
              (p) => p.prescriptionState === 'DRAFT'
            )
            prescriptionId = prescription?.id || ''

            updateState({
              status: ExtensionStatus.Initialized,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          prescriptionId = ''
          updateState({
            status: ExtensionStatus.InitError,
            errorMessage: messages.error_loading_prescriptions,
          })
          logException(error)
        },
      })
  }

  const submit = () => {
    state
      .pipe(
        take(1),
        filter(() => {
          if (!prescriptionId || !canReadAnyPrescription) {
            updateState({
              status: ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
            return false
          }
          return true
        }),
        map(({ pin }) => {
          return {
            variables: {
              id: prescriptionId,
              action: PrescriptionStateActionType.Create,
              region,
              pin,
            },
          }
        }),
        promotePrescription()
      )
      .subscribe({
        next: ({ error, loading }) => {
          if (error) {
            logException(error)
            updateState({
              status: ExtensionStatus.SubmitError,
              errorMessage: checkErrorForPinValidation(error)
                ? messages.error_validating_secure_pin
                : messages.error_promoting_prescriptions,
            })
          } else {
            updateState({
              status: loading
                ? ExtensionStatus.Submitting
                : ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          logException(error)
          updateState({
            status: ExtensionStatus.SubmitError,
            errorMessage: checkErrorForPinValidation(error)
              ? messages.error_validating_secure_pin
              : messages.error_promoting_prescriptions,
          })
        },
      })
  }

  const updatePin = (newPin: string) => {
    updateState({
      pin: newPin,
    })
  }

  return {
    updatePin,

    state,
    init,
    submit,
  }
}

export const PrescriptionPromotionModel = createPrescriptionPromotionModel(
  watchPrescriptionStateQuery,
  promotePrescriptionMutation
)
