import { gql } from '@apollo/client'

export const CreateSickNoteMutation = gql`
  mutation CreateSickNoteMutation(
    $consultationId: ID!
    $input: ConsultationSickNoteInput!
  ) {
    createConsultationSickNote(consultationId: $consultationId, input: $input) {
      id
      reason
      startDate
      endDate
      reason
    }
  }
`

export const UpdateSickNoteMutation = gql`
  mutation UpdateSickNoteMutation(
    $consultationId: ID!
    $sickNoteId: ID!
    $input: ConsultationSickNoteInput!
  ) {
    updateConsultationSickNote(
      consultationId: $consultationId
      sickNoteId: $sickNoteId
      input: $input
    ) {
      id
      reason
      startDate
      endDate
      reason
    }
  }
`
