import { IntlShape } from 'react-intl'

import { useIntl } from '@babylon/intl'

import { useMount } from '~/core/hooks'

import usePseudotranslate from './usePseudotranslate'

interface OnIntlReadyProps {
  onIntlReady: (intl: IntlShape) => void
}

const OnIntlReady = ({ onIntlReady }: OnIntlReadyProps) => {
  const intl = useIntl()

  usePseudotranslate()

  useMount(() => {
    if (onIntlReady) {
      onIntlReady(intl)
    }
  })

  return null
}

export default OnIntlReady
