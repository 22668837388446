import { useMutation } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'

import { useConsultantUser } from '@babylon/babylon-user'
import { Cell, Grid, Label, PillContainer } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { useUpdateQueryContext } from '~/core/update-query-provider/UpdateQueryProvider'
import TriggerWorkflowDialog from '~/features/workflows/WorkflowDefinitionsModal/WorkflowDefinitionForm/TriggerWorkflowDialog'

import { handleSubmitForm } from './actions'
import DigitalAppointmentForm from './DigitalAppointmentForm'
import F2FAppointmentForm from './F2FAppointmentForm'
import { formikInitialState } from './formikInitialState'
import {
  CreateAppointmentInviteMutation,
  CreateAppointmentWorkflowMutation,
} from './mutations'
import {
  mediumTypePills,
  serviceTypesForDigitalPills,
  serviceTypesForF2FPills,
} from './utils/PillTypes'

import messages from './NhsAppointment.messages'

const validate = (fm) => (values) => {
  const errors = {}
  const emptyFieldMessage = fm(messages.empty_field_validation_message)

  if (!values.reason) {
    errors.reason = emptyFieldMessage
  }

  if (values.mediumType === 'physical' && !values.selectedServiceTypeId) {
    errors.selectedServiceTypeId = emptyFieldMessage
  }

  if (values.mediumType === 'physical' && !values.duration) {
    errors.duration = emptyFieldMessage
  }

  if (values.mediumType === 'digital' && !values.urgency) {
    errors.urgency = emptyFieldMessage
  }

  return errors
}

const NhsAppointment = ({
  consultation,
  serviceTypes,
  closeModal,
  faceToFaceInvitesEnabled,
  digitalInvitesEnabled,
}) => {
  const fm = useFormatMessage()
  const user = useConsultantUser()
  const { updateWorkflowsV2Query } = useUpdateQueryContext()
  const [showDialog, setShowDialog] = useState(false)

  const [createAppointmentInviteMutation] = useMutation(
    CreateAppointmentInviteMutation
  )
  const [
    createAppointmentWorkflowMutation,
    createAppointmentWorkflowMutationResult,
  ] = useMutation(CreateAppointmentWorkflowMutation, {
    ignoreResults: false,
  })

  const nhsAppointmentLabel = fm(messages.nhs_appointment)
  const mediumPills = []
    .concat(faceToFaceInvitesEnabled ? mediumTypePills[0] : [])
    .concat(digitalInvitesEnabled ? mediumTypePills[1] : [])

  const resetFormFieldsAndErrors = (mediumType, resetForm) => {
    const serviceTypeCategory = mediumType === 'digital' ? 'GP' : 'NURSE'
    resetForm({
      values: { ...formikInitialState, mediumType, serviceTypeCategory },
    })
  }

  const resetServiceTypeCategory = (
    serviceTypeCategory,
    resetForm,
    reason,
    intimate,
    mediumType,
    urgency
  ) => {
    resetForm({
      values: {
        ...formikInitialState,
        serviceTypeCategory,
        mediumType,
        reason,
        intimate,
        urgency,
      },
    })
  }

  const getServiceTypeCategoryPills = (mediumType) => {
    return mediumType === 'physical'
      ? serviceTypesForF2FPills
      : serviceTypesForDigitalPills
  }

  return (
    <Formik
      initialValues={{
        ...formikInitialState,
        mediumType: faceToFaceInvitesEnabled ? 'physical' : 'digital',
      }}
      onSubmit={handleSubmitForm({
        consultation,
        user,
        createAppointmentWorkflowMutation,
        createAppointmentInviteMutation,
        closeModal,
        serviceTypes,
        updateWorkflowsV2Query,
        nhsAppointmentLabel,
        setShowDialog,
      })}
      validate={validate(fm)}
    >
      {({ handleSubmit, values, resetForm, setFieldValue, setValues }) => {
        const { mediumType, serviceTypeCategory, urgency } = values
        return (
          <div style={{ marginTop: 12 }}>
            <TriggerWorkflowDialog
              show={showDialog}
              onClose={closeModal}
              groupName={fm(messages.nhs_appointment)}
              mode="successful"
            />
            <Form onSubmit={handleSubmit}>
              <Grid>
                <Cell>
                  <Label htmlFor="form-medium-type">
                    {fm(messages.medium_type_label)}
                  </Label>
                  <Field name="mediumType">
                    {(props) => (
                      <PillContainer
                        {...props}
                        id="form-medium-type"
                        pills={mediumPills}
                        selected={props.field.value}
                        onSelectPill={(newMediumType) =>
                          resetFormFieldsAndErrors(
                            newMediumType,
                            resetForm,
                            setValues
                          )
                        }
                      />
                    )}
                  </Field>
                </Cell>
                <Cell>
                  <Label htmlFor="form-service-type-category">
                    {fm(messages.appointment_type_label)}
                  </Label>
                  <Field name="serviceTypeCategory">
                    {(props) => (
                      <PillContainer
                        {...props}
                        id="form-service-type-category"
                        pills={getServiceTypeCategoryPills(mediumType)}
                        pillsDataTestId="service-group"
                        selected={props.field.value}
                        onSelectPill={(newServiceTypeCategory) =>
                          resetServiceTypeCategory(
                            newServiceTypeCategory,
                            resetForm,
                            values.reason,
                            values.intimate,
                            mediumType,
                            urgency
                          )
                        }
                      />
                    )}
                  </Field>
                </Cell>
                <Cell>
                  {mediumType === 'physical' ? (
                    <F2FAppointmentForm
                      serviceTypes={serviceTypes[serviceTypeCategory]}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <DigitalAppointmentForm
                      setFieldValue={setFieldValue}
                      loading={createAppointmentWorkflowMutationResult.loading}
                    />
                  )}
                </Cell>
              </Grid>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default NhsAppointment
