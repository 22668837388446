import { isValid, parseISO } from 'date-fns'

const toDate = (dateISO) => {
  const parsedDate = parseISO(dateISO)
  return isValid(parsedDate) ? parsedDate : null
}

export const mapSickNoteToState = (note) => {
  return {
    startDate: toDate(note.startDate),
    endDate: toDate(note.endDate),
    reason: note.reason,
  }
}

export const mapStateToSickNote = (state) => {
  return {
    reason: state.reason,
    startDate: state.startDate.toISOString(),
    endDate: state.endDate.toISOString(),
  }
}

export default { mapSickNoteToState, mapStateToSickNote }
