import { defineMessages } from 'react-intl'

const baseId = 'consultation.tabs_warning'

export default defineMessages({
  safeButMirrored: {
    id: `${baseId}.safeButMirrored`,
    defaultMessage:
      'This page has since been opened in an external tab or window, which is not advised.',
  },
  tainted: {
    id: `${baseId}.tainted`,
    defaultMessage:
      'This page was already open in an external tab or window. Using this window may lead to data loss. Please close this window immediately.',
  },
  taintedButRefreshable: {
    id: `${baseId}.taintedButRefreshable`,
    defaultMessage:
      "This page was already open in an external tab or window. Click 'Refresh' to load latest data.",
  },
  refresh: {
    id: `${baseId}.refresh`,
    defaultMessage: 'Refresh',
  },
})
