import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.patient_details'

export default defineMessages({
  title: {
    id: `${baseId}.title_v2`,
    defaultMessage: 'Patient details',
  },
  minor: {
    id: `${baseId}.minor`,
    defaultMessage: 'Minor',
  },
  update_patient_details_button_label: {
    id: `${baseId}.update_patient_details_button_label_v2`,
    defaultMessage: 'Update patient details',
  },
  tool_tip_message: {
    id: `${baseId}.tool_tip_message`,
    defaultMessage:
      'Please ensure you carry out a three point identity check for all patients',
  },
  tool_tip_message_minor: {
    id: `${baseId}.tool_tip_message_minor`,
    defaultMessage:
      "Please ensure you verify the Guardian's details as well as the patient, and record in the notes that you have done so",
  },
  phone_number_section_title: {
    id: `${baseId}.phone_number_section_title`,
    defaultMessage: 'Phone number',
  },
  email_section_title: {
    id: `${baseId}.email_section_title_v2`,
    defaultMessage: 'E-mail',
  },
  gender_section_title: {
    id: `${baseId}.gender_section_title`,
    defaultMessage: 'Gender',
  },
  gender_section_male_value: {
    id: `${baseId}.gender_section_male_value`,
    defaultMessage: 'Male',
  },
  gender_section_female_value: {
    id: `${baseId}.gender_section_female_value`,
    defaultMessage: 'Female',
  },
  guardian_phone_number_title: {
    id: `${baseId}.guardian_phone_number_title_v2`,
    defaultMessage: 'Guardian phone number',
  },
  next_of_kin_section_name_title: {
    id: `${baseId}.next_of_kin_section_name_title_v2`,
    defaultMessage: 'Next of kin name',
  },
  next_of_kin_section_relation_title: {
    id: `${baseId}.next_of_kin_section_relation_title_v2`,
    defaultMessage: 'Next of kin relation',
  },
  next_of_kin_section_phone_number_title: {
    id: `${baseId}.next_of_kin_section_phone_number_title_v2`,
    defaultMessage: 'Next of kin phone number',
  },
  patient_name_section_title: {
    id: `${baseId}.patient_name_section_title`,
    defaultMessage: 'Patient name',
  },
  id_verification_status_verified_message: {
    id: `${baseId}.id_verification_status_verified_message`,
    defaultMessage: 'ID verified',
  },
  id_verification_status_not_verified_message: {
    id: `${baseId}.id_verification_status_not_verified_message`,
    defaultMessage: 'CHECK ID STATUS WITH SUPPORT',
  },
  date_of_birth_section_title: {
    id: `${baseId}.date_of_birth_section_title`,
    defaultMessage: 'Date of birth',
  },
  address_section_title: {
    id: `${baseId}.address_section_title`,
    defaultMessage: 'Address',
  },
  guardian_name_section_title: {
    id: `${baseId}.guardian_name_section_title_v2`,
    defaultMessage: 'Guardian name',
  },
  guardian_date_of_birth_section_title: {
    id: `${baseId}.guardian_date_of_birth_section_title_v2`,
    defaultMessage: 'Guardian date of birth',
  },
  guardian_address_section_title: {
    id: `${baseId}.guardian_address_section_title_v2`,
    defaultMessage: 'Guardian address',
  },
  error_title: {
    id: `${baseId}.error_title_v2`,
    defaultMessage: "We couldn't load patient details",
  },
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: 'The patient details section failed to load',
  },
  interpreter: {
    id: `${baseId}.interpreter`,
    defaultMessage: 'Interpreter',
  },
  verification_audit_trail: {
    id: `${baseId}.verification_audit_trail`,
    defaultMessage:
      'Verified by {consultantName} on {date} (User ID: {consultantId})',
  },
})
