import React, { useState, useEffect } from 'react'
import { Text, Textarea } from '@babylon/core-ui'
import cx from 'classnames'

import styles from './styles.module.scss'

export default ({ value, placeholder, onChange, charLimit }) => {
  const [showLengthError, setShowLengthError] = useState(false)

  useEffect(() => {
    !value || value.length <= charLimit
      ? setShowLengthError(false)
      : setShowLengthError(true)
  }, [value, charLimit])

  const textAreaClass = cx(styles['report__decorated-textarea__input'], {
    [styles['report__decorated-textarea__error-border']]: showLengthError,
  })

  return (
    <div className={styles['report__decorated-textarea']}>
      <Textarea
        value={value}
        placeholder={placeholder}
        className={textAreaClass}
        onChange={onChange}
      />
      {showLengthError && (
        <Text
          color="error"
          className={styles['report__decorated-textarea__left']}
          size="small"
        >
          Maximum number of characters reached
        </Text>
      )}
      <Text
        color={showLengthError ? 'error' : 'grey-type'}
        size="small"
        className={styles['report__decorated-textarea__right']}
      >
        ({(value || '').length}/{charLimit})
      </Text>
    </div>
  )
}
