import { useEffect } from 'react'

type UseLoadScript = {
  id: string
  onReady?(): void
  removeOnUnmount?: boolean
} & ({ src: string } | { text: string })

// https://github.com/artsy/force/blob/main/src/Utils/Hooks/useLoadScript.ts
export const useLoadScript = ({
  id,
  onReady,
  removeOnUnmount = false,
  ...rest
}: UseLoadScript) => {
  useEffect(() => {
    if (document.getElementById(id)) {
      onReady?.()
      return
    }

    const script = document.createElement('script')

    script.id = id
    script.async = true
    script.onload = () => onReady?.()

    if ('src' in rest) {
      script.src = rest.src
    }

    if ('text' in rest) {
      script.text = rest.text
    }

    document.body.appendChild(script)

    // eslint-disable-next-line consistent-return
    return () => {
      if (removeOnUnmount) {
        const scriptToRemove = document.getElementById(id)

        if (scriptToRemove) {
          document.body.removeChild(scriptToRemove)
        }
      }
    }
  }, [id, onReady, removeOnUnmount, rest])
}
