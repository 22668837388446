import { defineMessages } from 'react-intl'

const baseId = 'components.appointment_invites_summary'

export default defineMessages({
  invites_error: {
    id: `${baseId}.invites_error`,
    defaultMessage: "We can't load appointment invites right now",
  },
  invites_title: {
    id: `${baseId}.invites_title`,
    defaultMessage: 'Appointment Invites',
  },
})
