import { defineMessages } from 'react-intl'

const baseId = 'gp_consent_warning'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Consent to share with GP not given',
  },
  message: {
    id: `${baseId}.message`,
    defaultMessage:
      'Please discuss with patient. Please do not prescribe any long term medicines unless this is provided.',
  },
  message_without_prescription_consent: {
    id: `${baseId}.message_without_prescription_consent`,
    defaultMessage: 'Please discuss with patient.',
  },
})
