import { useEffect, useState } from 'react'

import { ObservableValue, Subscribable } from './observable'

export const usePromise = <T extends any>(
  promise: Promise<T>
): T | undefined => {
  const [value, setValue] = useState<T>()

  useEffect(() => {
    promise.then(setValue)
  }, [promise])

  return value
}

export function useObservable<T>(observable: Subscribable<T>): T {
  const [value, setValue] = useState<T>(
    (observable as ObservableValue<T>)?.get() as T
  )

  useEffect(() => {
    return observable?.subscribe(setValue).unsubscribe
  }, [observable])

  return value
}
