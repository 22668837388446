import { useEffect, useState } from 'react'

import { usePrevious, useTimeout } from '~/core/hooks'

import { CallStatus } from '../CallStatusReducerModelProvider'

const useCooldown = (status: CallStatus, duration: number) => {
  const [isCoolingDown, setIsCoolingDown] = useState(false)
  const prevStatus = usePrevious(status)

  const shouldCooldown =
    prevStatus === CallStatus.Streaming && status === CallStatus.Ready

  useEffect(() => {
    if (shouldCooldown) {
      setIsCoolingDown(true)
    }
  }, [shouldCooldown])

  useTimeout(
    () => {
      setIsCoolingDown(false)
    },
    isCoolingDown ? duration : null
  )

  return isCoolingDown
}

export default useCooldown
