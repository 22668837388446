import React from 'react'
import { Link } from 'react-router-dom'

import emedLogoAsset from '~/assets/logo.png'
import telusLogoAsset from '~/assets/telusheart.png'

import styles from './styles.module.scss'

export interface HeaderLogoProps {
  telusLogo: boolean
}

const HeaderLogo = ({ telusLogo }: HeaderLogoProps) => (
  <div className={styles.headerLogo}>
    <Link to="/" className={styles.logoLink}>
      <img
        className={styles.image}
        src={telusLogo ? telusLogoAsset : emedLogoAsset}
        alt="Logo"
      />
    </Link>
  </div>
)

export default HeaderLogo
