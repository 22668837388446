import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { Text } from '@babylon/core-ui'

import { useMessages, useMount } from '~/core/hooks'
import { Dialog } from '~/ui/Modal'

import useMarkAsNoShowMutation from './useMarkAsNoShowMutation'

import messages from './MarkAsNoShow.messages'

interface MarkAsNoShowDialogProps {
  consultationId: string
  onClose: () => void
}

const MarkAsNoShowDialog = ({
  consultationId,
  onClose,
}: MarkAsNoShowDialogProps) => {
  const f = useMessages(messages)
  const history = useHistory<{ consultationResult: 'no-show' }>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>()

  const [markAsNoShowMutation] = useMarkAsNoShowMutation()

  useMount(() => {
    setError(null)
  })

  /**
   * This is only called if/when dialog closing animation completes
   */
  const handleClose = () => {
    onClose()
  }

  const handleOk = async () => {
    try {
      setLoading(true)
      setError(null)
      await markAsNoShowMutation(consultationId)
      history.push('/', {
        consultationResult: 'no-show',
      })
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  return (
    <Dialog
      title={f('dialog_title')}
      ok
      cancel
      cancelLabel={f('cancel_button_label')}
      okLoading={loading}
      okLabel={f(error ? 'try_again_button_label' : 'ok_button_label')}
      onOk={handleOk}
      onClose={handleClose}
    >
      <Text>{f('dialog_body')}</Text>
      {error ? (
        <Text color="error">{f('operation_failed_message')}</Text>
      ) : null}
    </Dialog>
  )
}

export default MarkAsNoShowDialog
