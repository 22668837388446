import React from 'react'

import {
  CLINOPS_CHAT_DEPARTMENT_ID,
  CLINOPS_CHAT_DEPARTMENT_NAME,
} from '~/constants'
import { isFilledArray } from '~/core'
import { HeaderInterface } from '~/core/config/modules/generated/types'
import { useGlobals } from '~/core/core-modules'

import HeaderBackButton from './HeaderBackButton'
import HeaderLogo from './HeaderLogo'
import HeaderSchedule from './HeaderSchedule'
import Profile from './Profile'
import Support from './Support'

import styles from './styles.module.scss'

const Header: HeaderInterface = ({
  scheduleEnabled = false,
  clinicianRatingEnabled = false,
  languageSelectorEnabled = false,
  telusLogo = false,
  ConsultationScheduleItem,
  supportOptions = [],
  consultationContext,
}) => {
  const { languagesAvailable } = useGlobals()

  return (
    <header className={styles.header}>
      <HeaderBackButton />
      <HeaderLogo telusLogo={telusLogo} />
      {scheduleEnabled && (
        <HeaderSchedule ConsultationScheduleItem={ConsultationScheduleItem} />
      )}
      {isFilledArray(supportOptions) && (
        <Support
          options={supportOptions}
          clinOpsChatDepartment={
            CLINOPS_CHAT_DEPARTMENT_NAME || CLINOPS_CHAT_DEPARTMENT_ID
          }
          consultationContext={consultationContext}
        />
      )}
      <Profile
        clinicianRatingEnabled={clinicianRatingEnabled}
        languageSelectorEnabled={languageSelectorEnabled}
        languagesAvailable={languagesAvailable}
      />
    </header>
  )
}

export default Header
