import { defineMessages } from 'react-intl'

const baseId = 'prescription_modal.drug_select'

export default defineMessages({
  create_option_label: {
    id: `${baseId}.create_option_label`,
    defaultMessage: 'Create {option}',
  },
  placeholder: {
    id: `${baseId}.placeholder`,
    defaultMessage: 'Search by name or active ingredient, e.g. Paracetamol',
  },
})
