import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { FormikDropdownSelect, Label } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from '../NhsAppointment.messages'

const urgencyDropdownId = 'digital-appointment-urgency-dropdown'

const urgencyOptions = [
  {
    key: 'routine',
    value: <FormattedMessage {...messages.urgency_option_routine} />,
  },
  {
    key: 'urgent',
    value: <FormattedMessage {...messages.urgency_option_urgent} />,
  },
]

export interface IProps {
  setFieldValue(field: string, value: any): void
}

const AppointmentUrgency: React.FC<IProps> = ({ setFieldValue }) => {
  const fm = useFormatMessage()

  useEffect(() => {
    setFieldValue('urgency', urgencyOptions[0].key)
    return () => setFieldValue('urgency', null)
  }, [setFieldValue])

  return (
    <>
      <Label htmlFor={urgencyDropdownId}>
        {fm(messages.urgency_service_label)}
      </Label>
      <FormikDropdownSelect
        id={urgencyDropdownId}
        name="urgency"
        options={urgencyOptions}
        placeholder={fm(messages.urgency_service_placeholder)}
        data-testid="appointment-urgency"
      />
    </>
  )
}

export default AppointmentUrgency
