import md5 from 'md5'

import { ProblemsListQueryResult } from '~/generated'

import { GroupedProblems } from './types'

type PatientTimeline = NonNullable<
  NonNullable<
    NonNullable<ProblemsListQueryResult['data']>['patient']
  >['history']
>

export const preprocessTimeline = (
  history: PatientTimeline,
  currentConsultationId: string
) =>
  history.filter((entry) => {
    return (
      entry.__typename === 'Consultation' && entry.id !== currentConsultationId
    )
  })

/**
 * create the problems list grouped by diagnosis code
 * where no diagnosis code, assessment note will be used as key
 */
export const reducerByProblems = (
  recentEpisodes: GroupedProblems,
  curr: PatientTimeline[number]
): GroupedProblems => {
  if (curr.__typename !== 'Consultation') {
    throw new Error('ProblemsList only supports Consultation results')
  }
  const { id, consultationNote, consultantId, scheduledTime, status } = curr
  const { assessment, assessmentCodes } = consultationNote || {}

  if (assessmentCodes && assessmentCodes.length) {
    assessmentCodes.forEach((code) => {
      const splitIri = code.code.split('/')
      const iriId = splitIri.pop()

      if (iriId) {
        if (!recentEpisodes[iriId]) {
          recentEpisodes[iriId] = []
        }

        recentEpisodes[iriId].push({
          code: code.term,
          codeId: code.code,
          scheduledTime,
          status: status || '',
          consultantId,
          consultationId: id,
        })
      }
    })
  } else if (assessment) {
    const hashAssessment = md5(assessment)

    if (!recentEpisodes[hashAssessment]) {
      recentEpisodes[hashAssessment] = []
    }

    recentEpisodes[hashAssessment].push({
      note: assessment,
      hashNote: hashAssessment,
      status: status || '',
      scheduledTime,
      consultantId,
      consultationId: id,
    })
  }
  return recentEpisodes
}
