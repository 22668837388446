import React, { useEffect, useRef } from 'react'
import { Route } from 'react-router-dom'

import {
  CLOSE_CONSULTATION_SURVEY_ACTION,
  CONSULTATION_SURVEY_CATEGORY,
  DISMISS_CONSULTATION_SURVEY_ACTION,
  SKIP_CONSULTATION_SURVEY_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { ConsultationContextType, useConsultation } from '~/core/config'
import { PostConsultationFormModalInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags, useGlobals } from '~/core/core-modules'
import { useConsultationId } from '~/features/consultation'
import { useExperiment } from '~/features/experiments/useExperiment'
import { history } from '~/features/shell/history'

import { GoogleFormDialogView } from './GoogleFormDialogView'

const trackEvent = analytics.trackEventFactory({
  category: CONSULTATION_SURVEY_CATEGORY,
})

interface GoogleFormDialogModuleProps {
  consultationContext: ConsultationContextType
}

export const GoogleFormDialogModule = ({
  consultationContext,
}: GoogleFormDialogModuleProps) => {
  const consultationId = useConsultationId()
  const { uuid } = useConsultation(consultationContext)
  const noteAssistantExperimentEnabled = useExperiment('Note Assistant')
  const { noteAssistantSummaryEnabled } = useFeatureFlags()
  const enablePostConsultationSurvey =
    noteAssistantExperimentEnabled && noteAssistantSummaryEnabled

  const startTime = useRef<number>(new Date().getTime())
  const durationInSeconds = () => {
    const currentTime = new Date().getTime()
    return Math.floor((currentTime - startTime.current) / 1000)
  }
  const completeForm = (type: string) => {
    trackEvent({
      action:
        type === 'complete'
          ? CLOSE_CONSULTATION_SURVEY_ACTION
          : SKIP_CONSULTATION_SURVEY_ACTION,
      label: 'timer',
      value: durationInSeconds(),
    })
    history.replace('/')
  }

  const closeDialog = () => {
    trackEvent({
      action: DISMISS_CONSULTATION_SURVEY_ACTION,
      label: 'timer',
      value: durationInSeconds(),
    })
    history.replace(`/consultation/${consultationId}/review`, {
      consultationResult: 'complete',
    })
  }

  const { postConsultationFormLink } = useGlobals()

  useEffect(() => {
    if (!postConsultationFormLink || !enablePostConsultationSurvey) {
      history.replace('/')
    }
  }, [postConsultationFormLink, enablePostConsultationSurvey])

  if (!postConsultationFormLink) {
    return null
  }

  return (
    <GoogleFormDialogView
      link={postConsultationFormLink.replace('{consultationUuid}', uuid)}
      onComplete={completeForm}
      onClose={closeDialog}
    />
  )
}

export const GoogleFormDialog: PostConsultationFormModalInterface = ({
  consultationContext,
}) => (
  <Route path="/consultation/:id/review/form">
    <GoogleFormDialogModule consultationContext={consultationContext} />
  </Route>
)
