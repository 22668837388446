import { defineMessages } from 'react-intl'

const baseId = 'video_call'

export default defineMessages({
  connecting: {
    id: `${baseId}.connecting`,
    defaultMessage: 'Connecting...',
  },
  waiting_for_patient: {
    id: `${baseId}.waiting_for_patient`,
    defaultMessage: 'Waiting for patient...',
  },
  patients_camera_and_mic_off: {
    id: `${baseId}.patients_camera_and_mic_off`,
    defaultMessage: "Patient's camera and mic not enabled",
  },
  patients_camera_off: {
    id: `${baseId}.patients_camera_off`,
    defaultMessage: "Patient's camera not enabled",
  },
  patients_microphone_off: {
    id: `${baseId}.patients_microphone_off`,
    defaultMessage: "Patient's mic not enabled",
  },
  clinicians_camera_and_mic_off: {
    id: `${baseId}.clinicians_camera_and_mic_off`,
    defaultMessage: 'Your camera and mic are not enabled',
  },
  clinicians_camera_off: {
    id: `${baseId}.clinicians_camera_off`,
    defaultMessage: 'Your camera is not enabled',
  },
  clinicians_microphone_off: {
    id: `${baseId}.clinicians_microphone_off`,
    defaultMessage: 'Your mic is not enabled',
  },
  autoplay_blocked: {
    id: `${baseId}.autoplay_blocked`,
    defaultMessage:
      'Your browser blocked autoplay.\nClick to unblock.\n{icon}\nChange your browser settings for this site to avoid this in the future.',
  },
  video_disable_warning: {
    id: `${baseId}.video_disable_warning`,
    defaultMessage: 'Stream quality is poor.\nVideo may be disabled',
  },
  video_disabled: {
    id: `${baseId}.video_disabled`,
    defaultMessage: 'Video has been disabled due to poor stream quality.',
  },
  video_call_rejected: {
    id: `${baseId}.video_call_rejected`,
    defaultMessage: 'The call has been rejected by the patient.',
  },
})
