import { defineMessages } from 'react-intl'

const baseId = 'error_panel'

export default defineMessages({
  refresh_section: {
    id: `${baseId}.refresh_section`,
    defaultMessage: 'Refresh section',
  },
  reload_page: {
    id: `${baseId}.reload_page`,
    defaultMessage: 'Reload page',
  },
  notify_support: {
    id: `${baseId}.notify_support`,
    defaultMessage: 'Send report',
  },
  error_code: {
    id: `${baseId}.error_code`,
    defaultMessage: 'Error Code',
  },
  error_id: {
    id: `${baseId}.error_id`,
    defaultMessage: 'Error ID',
  },
  copy_button_tooltip_message: {
    id: `${baseId}.copy_button_tooltip_message`,
    defaultMessage: 'Copy error details',
  },
})
