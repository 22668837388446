import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.examination'

export default defineMessages({
  medical_history_label: {
    id: `${baseId}.medical_history_label`,
    defaultMessage: 'Medical history (as entered by patient) {tooltip}',
  },
  medical_history_tooltip_message: {
    id: `${baseId}.medical_history_tooltip_message`,
    defaultMessage:
      'These fields are editable by the patient, so please ensure you check any information added here',
  },
  allergies_label: {
    id: `${baseId}.allergies_label`,
    defaultMessage: 'Allergies',
  },
  medications_label: {
    id: `${baseId}.medications_label`,
    defaultMessage: 'Medications',
  },
  conditions_label: {
    id: `${baseId}.conditions_label`,
    defaultMessage: 'Conditions',
  },
  cant_load_examination: {
    id: `${baseId}.cant_load_examination`,
    defaultMessage: "We can't load Examination right now",
  },
  error_saving_examination_title: {
    id: `${baseId}.error_saving_examination_title`,
    defaultMessage: 'Examination not saved',
  },
  error_saving_examination_message: {
    id: `${baseId}.error_saving_examination_message`,
    defaultMessage: 'There was an error updating the examination',
  },
})
