import { defineMessages } from 'react-intl'

const baseId = 'call_player.pre_call_check_results_dialog'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'How were the setup check results?',
  },
  description: {
    id: `${baseId}.description`,
    defaultMessage:
      "If your pre-call check results were all good, you are ready to consult. If you found some problems we'll offer some troubleshooting.",
  },
  good_button: {
    id: `${baseId}.good_button`,
    defaultMessage: 'All good!',
  },
  not_good_button: {
    id: `${baseId}.not_good_button`,
    defaultMessage: 'Not so good',
  },
})
