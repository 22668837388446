// Since React overrides the '.value=' setter, we need to get the native
// setters for HTMLInputElement and HTMLTextAreaElement

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLInputElement.prototype,
  'value'
).set
const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(
  window.HTMLTextAreaElement.prototype,
  'value'
).set

const getValueSetter = (element) => {
  if (element instanceof HTMLInputElement) {
    return nativeInputValueSetter
  } else if (element instanceof HTMLTextAreaElement) {
    return nativeTextAreaValueSetter
  } else {
    return null
  }
}

export default getValueSetter
