import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

export const useDragAndDrop = (x: number, y: number) => {
  const [pos, setPos] = useState({ x, y, offsetX: 0, offsetY: 0 })

  const handleMouseMove = useCallback((event: MouseEvent) => {
    setPos((pos) => ({
      ...pos,
      x: event.screenX - pos.offsetX,
      y: event.screenY - pos.offsetY,
    }))
    event.stopPropagation()
    event.preventDefault()
  }, [])

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseMove)
      setPos((pos) => ({
        ...pos,
        x: event.screenX - pos.offsetX,
        y: event.screenY - pos.offsetY,
      }))
      event.stopPropagation()
      event.preventDefault()
    },
    [handleMouseMove]
  )

  const handleMouseDown = useCallback(
    (event: ReactMouseEvent) => {
      const { x, y } = pos
      setPos({ x, y, offsetX: event.screenX - x, offsetY: event.screenY - y })
      event.stopPropagation()
      event.preventDefault()
      window.addEventListener('mouseup', handleMouseUp)
      window.addEventListener('mousemove', handleMouseMove)
    },
    [pos, handleMouseMove, handleMouseUp]
  )

  return [pos.x, pos.y, handleMouseDown] as const
}

interface UseResizeProps {
  onUpdate?: (x: number, y: number, width: number, height: number) => void
}

export const useResize = ({ onUpdate }: UseResizeProps) => {
  const ref: any = useRef()
  const [offset, updateOffset] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [wX, wY, handleResizeMouseDown] = useDragAndDrop(0, 0)
  const [pX, pY, handleRepositionMouseDown] = useDragAndDrop(0, 0)

  const el = ref.current

  useEffect(() => {
    if (el) {
      updateOffset({
        x: el.offsetLeft,
        y: el.offsetTop,
        width: el.clientWidth,
        height: el.clientHeight,
      })
    }
  }, [el])

  useEffect(() => {
    if (el) {
      const x = offset.x + pX
      const y = offset.y + pY
      const width = offset.width + wX
      const height = offset.height + wY
      el.style.left = `${x}px`
      el.style.top = `${y}px`
      el.style.width = `${width}px`
      el.style.height = `${height}px`
      onUpdate?.(x, y, width, height)
    }
  }, [
    el,
    offset.height,
    offset.width,
    offset.x,
    offset.y,
    onUpdate,
    pX,
    pY,
    wX,
    wY,
  ])

  return [ref, handleResizeMouseDown, handleRepositionMouseDown] as const
}
