import React, { ReactNode, CSSProperties } from 'react'
import cn from 'classnames'
import pickBy from 'lodash/pickBy'

import './Grid.scss'

interface Props {
  alignContent?: string
  alignItems?: 'start' | 'end' | 'center' | 'stretch'
  areas?: string[]
  children?: ReactNode
  className?: string
  center?: boolean
  columnGap?: number | string
  columns?: number | string
  flow?: 'row' | 'column' | 'dense' | string
  gap?: number | string
  height?: number | string
  justifyContent?: string
  justifyItems?: 'start' | 'end' | 'center' | 'stretch'
  margin?: boolean
  minRowHeight?: number | string
  responsive?: boolean
  rowGap?: number | string
  rows?: number
  style?: object
  templateColumns?: string
  templateRows?: string
  dataTestId?: string
}

const frGetter = (value: number) =>
  typeof value === 'number' ? `repeat(${value}, 1fr)` : null

const formatAreas = (areas: string[]) =>
  areas.map((area) => `"${area}"`).join(' ')

const defaultToPixels = (value: string | number): string | number =>
  typeof value === 'string' && /^\d+$/.test(value) ? `${value}px` : value

const isTruthyOrZero = (value?: string | number): boolean =>
  !!value || value === 0

const Grid = ({
  columns,
  gap,
  columnGap,
  rowGap,
  minRowHeight,
  height,
  flow,
  rows,
  templateRows,
  templateColumns,
  center,
  areas,
  justifyContent,
  justifyItems,
  alignContent,
  alignItems,
  margin,
  className,
  style,
  responsive,
  children,
  dataTestId,
}: Props) => {
  const mergedStyles = pickBy(
    {
      gridAutoRows:
        minRowHeight && `minmax(${defaultToPixels(minRowHeight)}, auto)`,
      gridGap: gap && defaultToPixels(gap),
      columnGap: columnGap && defaultToPixels(columnGap),
      rowGap: rowGap && defaultToPixels(rowGap),
      height: height && defaultToPixels(height),
      gridAutoFlow: flow,
      gridTemplateRows: (rows && frGetter(rows)) || templateRows,
      gridTemplateColumns: templateColumns,
      gridTemplateAreas: areas && formatAreas(areas),
      justifyContent,
      justifyItems,
      alignContent,
      alignItems,
      ...style,
    },
    isTruthyOrZero
  ) as CSSProperties

  return (
    <div
      className={cn(
        'core-ui-grid',
        columns && `core-ui-grid--template-columns-${columns}`,
        margin && 'core-ui-grid--margin',
        responsive && 'core-ui-grid--responsive',
        center && 'core-ui-grid--center',
        className
      )}
      style={mergedStyles}
      data-testid={dataTestId}
    >
      {children}
    </div>
  )
}

export default Grid
