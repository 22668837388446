import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ATHENA_CHECK_IN_URL, ATHENA_EXAM_URL } from '~/constants'
import { useConsultation } from '~/core/config'
import { USEhrDeepLinkPluginInterface } from '~/core/config/modules/generated/types'
import { useEhrConsultationQuery } from '~/generated'
import { MiniErrorPanel } from '~/ui/ErrorPanel'
import { useMobile } from '~/ui/Responsive'

import styles from '../styles.module.scss'
import messages from './USEhrDeepLink.messages'

export const EHR_CONSULTATION_POLL_INTERVAL = 1000 * 60 // 1 minute

export const USEhrDeepLink: USEhrDeepLinkPluginInterface = ({
  consultationContext,
  eventBus,
  isMobileHeadingButton,
}) => {
  const fm = useFormatMessage()
  const isMobileView = useMobile()
  const { id: consultationId } = useConsultation(consultationContext)

  const { data, error, refetch } = useEhrConsultationQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: consultationId,
    },
  })

  const usEhrConsultation = data?.consultation?.usEhrConsultation

  const [encounterId, setEncounterId] = useState<string | null>(null)

  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    setButtonDisabled(!usEhrConsultation)
    setEncounterId(
      usEhrConsultation && usEhrConsultation.externalEncounterId
        ? usEhrConsultation.externalEncounterId
        : null
    )
  }, [usEhrConsultation])

  if (error) {
    return !isMobileHeadingButton ? (
      <MiniErrorPanel
        error={error}
        title={fm(messages.error_loading)}
        description={fm(messages.error_workaround)}
        data-testid="review-error"
        retry={() => refetch()}
      />
    ) : (
      <></>
    )
  }

  // encounter id will be available after check-in
  eventBus.on('PATIENT_CHECKED_IN', async () => {
    refetch()
    setButtonDisabled(false)
  })

  const handleOpenEhrChart = () => {
    const athenaCheckinUrl =
      usEhrConsultation?.externalAppointmentId && ATHENA_CHECK_IN_URL
        ? ATHENA_CHECK_IN_URL.replace(
            '<appointmentid>',
            usEhrConsultation.externalAppointmentId
          )
        : null

    const athenaExamUrl =
      encounterId && usEhrConsultation?.ehrDepartmentId && ATHENA_EXAM_URL
        ? ATHENA_EXAM_URL.replace('<encounterid>', encounterId).replace(
            '<departmentid>',
            usEhrConsultation.ehrDepartmentId
          )
        : null

    const athenaUrl = encounterId ? athenaExamUrl : athenaCheckinUrl

    if (athenaUrl) {
      const newWindow = window.open(athenaUrl, 'athena_deep_link')
      const onNewWindowClose = setInterval(() => {
        if (newWindow?.closed) {
          clearInterval(onNewWindowClose)
          setButtonDisabled(false)
        }
      }, 1000)
      setButtonDisabled(true)
    }
  }

  // this module is shown twice on the same page when in mobile view  so in one of the views this button will be hidden
  return isMobileView === isMobileHeadingButton ? (
    <Button
      fill
      intent="secondary"
      onClick={() => handleOpenEhrChart()}
      className={cn((encounterId && styles.examButton) || styles.checkInButton)}
      dataTestId="consultation-ehr-chart-button"
      disabled={buttonDisabled}
    >
      {fm(
        encounterId
          ? messages.open_ehr_chart_button_label
          : messages.open_ehr_checkin_button_label
      )}
    </Button>
  ) : (
    <></>
  )
}
