import React from 'react'

import { useConsultation, usePatient } from '~/core/config'
import { CarePlanSectionPluginInterface } from '~/core/config/modules/generated/types'

import CarePlanSection from './CarePlanSection'

export const CarePlanSectionContainer: CarePlanSectionPluginInterface = ({
  consultationContext,
}) => {
  const consultation = useConsultation(consultationContext)
  const { uuid } = usePatient(consultationContext)

  return <CarePlanSection consultation={consultation} patientUuid={uuid} />
}

export default CarePlanSectionContainer
