import axios from 'axios'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'

import { AuthClient, getAuthClient } from '@babylon/web-platform-utils-auth'

import {
  APP_NAME,
  CONFIG_NAMESPACE,
  CONFIG_URL,
  ENABLE_NEW_AUTH,
} from '~/constants'
import { Config, Container, initContainer } from '~/core/container/container'
import ReactTransform from '~/core/container/react-transform'
import { Globals } from '~/core/core-modules'
import requestIdGenerator from '~/core/request-id-generator'
import { experimentNameToKey } from '~/features/experiments/utils'

import { observableValue } from '../container'
import variants from './loadLocalVariants'
import { modules } from './modules/generated/registry'
import servicesConfig from './services'

let authClient: AuthClient

const fetchRemoteConfig = async (partners: string[], userUuid?: string) => {
  if (!partners.length) {
    throw new Error('Cannot fetch config, this user has no partners')
  }

  const query = qs.stringify(
    { namespaces: CONFIG_NAMESPACE, partners, appname: APP_NAME },
    { addQueryPrefix: true, skipNulls: true }
  )

  const headers: Record<string, any> = {
    'X-App-Name': APP_NAME,
    'babylon-request-id': requestIdGenerator.generate(),
    'X-Ab-Identifier': userUuid,
  }

  /*
    we should remove the ENABLE_NEW_AUTH flag and old auth when migration is complete , see:
    babylonpartners.atlassian.net/wiki/spaces/CIT/pages/4241555535/Portals+Open+ID+switchover
  */
  if (ENABLE_NEW_AUTH) {
    if (!authClient) {
      authClient = getAuthClient()
    }

    const token = await authClient.getAuthToken()
    headers.Authorization = token ? `Bearer ${token}` : ''
  }

  const response = await axios.get(`${CONFIG_URL}${query}`, {
    withCredentials: true,
    headers,
  })

  const config = response?.data?.clinicalPortal

  if (!config.variant) {
    throw new Error(
      `Config for partners ${JSON.stringify(partners)} is not valid`
    )
  }

  return config
}

const removeDefault = (module: any) => {
  const { default: _, ...rest } = module
  return rest
}

const fetchLocalConfig = async (localConfig: string) =>
  removeDefault(await import(`./local/${localConfig}.json`)).productConfig

export const loadVariant = async (url: string) => {
  const [protocol, fileName] = url.split(/:\/\//)

  const tsFileName = fileName.replace(/(\.[a-zA-Z]*)?$/, '.ts')

  if (protocol === 'local') {
    return removeDefault(variants[tsFileName])
  }

  throw new Error('Only local config variants are supported')
}

let container: Container | undefined

export const configData = observableValue<Config>({})

export const initConfig = async (
  partners: string[],
  userUuid?: string,
  localConfig?: string
) => {
  const options = localConfig
    ? await fetchLocalConfig(localConfig)
    : await fetchRemoteConfig(partners, userUuid)

  const showExperimentalVariant = window.localStorage.getItem(
    experimentNameToKey('New SaaS Clinical Portal')
  )

  const appConfig = await loadVariant(
    showExperimentalVariant === 'true' && options.experimentalVariant
      ? options.experimentalVariant
      : options.variant
  )

  // TODO: remove with experiment; displays experimental_variant property being used for QA
  if (showExperimentalVariant === 'true' && options.experimentalVariant) {
    // eslint-disable-next-line no-console
    console.log(`Using experimental variant: ${options.experimentalVariant}`)
  }

  const config: Config = {
    ...servicesConfig,
    ...appConfig,
    global: {
      module: 'Global',
      options,
    },
  }

  configData.next(config)

  container = initContainer(config, modules, {
    transforms: [ReactTransform],
  })
}

interface Resolve {
  (id: 'global'): Promise<Globals>

  (id: string): Promise<any>
}

export const resolve: Resolve = async (id: string) => {
  if (!container) {
    throw new Error(
      'Modules cannot be resolved before the container is initialised'
    )
  }

  return container.resolve(id)
}

export const useModule = <T = any>(moduleName: string): T | undefined => {
  const [module, setModule] = useState<T>()

  useEffect(() => {
    container?.resolve(moduleName).then(setModule)
  }, [moduleName])

  return module
}

export const useModuleContext = <T = any>(moduleName: string): T | null => {
  const defaultContext = React.createContext<T | null>(null)
  const [module, setModule] = useState<{ context: React.Context<T | null> }>()

  useEffect(() => {
    container?.resolve(moduleName).then(setModule)
  }, [moduleName])

  return useContext(module?.context ?? defaultContext)
}
