import React from 'react'

import { ConsultationContextType, useConsultation } from '~/core/config'
import useTaintedInfo from '~/core/hooks/useTaintedInfo'

import ExternalTabsWarningPresentation from './ExternalTabsWarningPresentation'

interface ExternalTabsWarningContainerProps {
  route: string
  consultationContext: ConsultationContextType
}

const ExternalTabsWarningContainer = ({
  route,
  consultationContext,
}: ExternalTabsWarningContainerProps) => {
  const consultation = useConsultation(consultationContext)
  const { id } = consultation
  const taintedInfo = useTaintedInfo(route.replace(':id', id))

  return <ExternalTabsWarningPresentation taintedInfo={taintedInfo} />
}

export default ExternalTabsWarningContainer
