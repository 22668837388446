import { parsePhoneNumber } from 'libphonenumber-js'

/**
 *
 * @param {string} firstPart - the first part of a phone number. Can be just the country code with preceding plus (i.e. "+44")
 *   or a full phone number ("+15125551234").
 * @param {string} secondPart - the second part of a phone number, often the national phone number digits. Can be omitted if
 *   the first argument is the full phone number to display.
 */
export const displayPhoneNumber = (...args: (string | null | undefined)[]) => {
  const joinedPhoneNumber = args
    .filter((v) => v != null)
    .join(' ')
    .trim()

  try {
    const phoneNumber = parsePhoneNumber(joinedPhoneNumber)
    return phoneNumber.formatInternational()
  } catch (error) {
    return joinedPhoneNumber
  }
}
