import { defineMessages } from 'react-intl'

const baseId = 'dashboard.navigation'

export default defineMessages({
  consultations_text: {
    id: `${baseId}.consultations_text`,
    defaultMessage: 'Consultations',
  },
})
