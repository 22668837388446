import React from 'react'

import { useMessages } from '~/core/hooks'
import { ChatscriptMessages } from '~/features/chatbot-flow'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import { PreconsultationSummaryQuery } from '~/generated'

import messages from './PreconsultationSummary.messages'

interface PreconsultationSummaryProps {
  consultation: PreconsultationSummaryQuery['consultation']
}

const PreconsultationSummary = ({
  consultation,
}: PreconsultationSummaryProps) => {
  const f = useMessages(messages)

  if (!consultation?.preConsultationFlowId) {
    return null
  }

  return (
    <Section
      title={f('questionnaire_title')}
      testid="pre-consultation"
      collapsible
      tooltip={f('questionnaire_tooltip')}
    >
      <ChatscriptMessages
        patientUuid={consultation?.patient.uuid || ''}
        eventId={consultation?.preConsultationFlowId || ''}
        showDisclaimer={false}
        showTranscriptTitle={false}
        copyFunctionEnabled={false}
        noBorder
      />
    </Section>
  )
}

export default PreconsultationSummary
