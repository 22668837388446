import { graphql } from '@apollo/client/react/hoc'
import { branch, compose, renderNothing } from 'recompose'

import { isLoading } from '~/core/graphql'

import { PrescriptionAuditQuery } from './queries'

export default compose(
  graphql(PrescriptionAuditQuery, {
    options: ({ query }) => ({
      variables: {
        prescriptionId: query.prescription,
      },
      fetchPolicy: 'network-only',
    }),
  }),

  branch(isLoading('data'), renderNothing)
)
