import cn from 'classnames'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { Editor as SlateEditor } from 'slate-react'

import {
  AUTOCOMPLETE_ADD_CODE_LABEL,
  NOTE_TAKING_CATEGORY,
  NOTE_TAKING_TIMER,
  SELECTION_ADD_CODE_LABEL,
} from '~/constants/analytics'
import { generateIndex } from '~/core'
import analytics from '~/core/analytics'

import { withDropdown } from './components/DropdownList'
import SelectionMenu from './components/SelectionMenu'
import defaultValue from './defaultValue'
import Autocomplete, {
  AUTOCOMPLETE_MARK_SELECTOR,
  AUTOCOMPLETE_MARK_TYPE,
} from './plugins/Autocomplete'
import ClinicalCodes, { getFocusedWord } from './plugins/ClinicalCodes'
import Decorations from './plugins/Decorations'
import Highlight from './plugins/Highlight'
import InlineSearch from './plugins/InlineSearch'
import Paragraphs from './plugins/Paragraphs'
import Paste from './plugins/Paste'
import Selection, {
  SELECTION_MARK_SELECTOR,
  SELECTION_MARK_TYPE,
} from './plugins/Selection'
import schema from './schema'

import styles from './styles.module.scss'

class Editor extends Component {
  constructor(props) {
    super(props)

    const { autocomplete, selection, enableHighlight } = props

    this.id = `sl-editor-${generateIndex()}`
    /**
     * Initialize Slate Editor plugins
     */
    this.plugins = [
      Decorations,
      ClinicalCodes,
      enableHighlight ? Highlight : null,
      Paragraphs,
      Paste,
      props.enableInlineSearch ? new InlineSearch() : null,
      props.enableSelection ? new Selection() : null,
      props.enableAutocomplete ? new Autocomplete() : null,
      autocomplete.slate({
        enabled: () => this.isAutocompleteDropdownVisible(this.editor),
      }),
      selection.slate({
        enabled: () => this.isSelectionDropdownVisible(this.editor),
      }),
    ].filter(Boolean)

    this.state = {
      timer: null,
    }
    this.editor = null
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown)
  }

  getOptions = (value) => {
    const { getOptions } = this.props

    if (getOptions) {
      getOptions(value)
    }
  }

  setEditor = (editor) => {
    this.editor = editor
  }

  isNodeRelated = (node, selector) => {
    return node.matches(selector) || node.closest(selector)
  }

  isNodeWithinEditor = (node) => {
    return (
      this.isNodeRelated(node, `#${this.id}`) ||
      this.isNodeRelated(node, `#dropdown-${this.id}`)
    )
  }

  /**
   * TODO: Revisit this.
   *
   * Add global mouse down event to close all dropdowns/popus when clicked
   * outside the editor area.
   */
  handleDocumentMouseDown = ({ target }) => {
    /**
     * This is a very quick fix to make sure that SlateJS dropdown(s) are closed
     * when clicked outside editor and/or dropdown
     */
    const isMouseDownWithinEditor = this.isNodeWithinEditor(target)
    const { timer } = this.state
    const { name } = this.props

    const onDocumentMouseUp = () => {
      if (this.editor) {
        const hasCustomDecorations = this.editor.hasDecorations(
          AUTOCOMPLETE_MARK_TYPE,
          SELECTION_MARK_TYPE
        )

        if (!isMouseDownWithinEditor && hasCustomDecorations) {
          this.editor.setDecorations([])
        }
      }
    }
    if (isMouseDownWithinEditor && !timer) {
      this.setState({ timer: Date.now() })
    }

    if (!isMouseDownWithinEditor && timer) {
      const time = Math.round((Date.now() - timer) / 1000)
      this.setState({ timer: null })

      analytics.trackEvent({
        action: name,
        category: NOTE_TAKING_CATEGORY,
        value: time,
        label: NOTE_TAKING_TIMER,
      })
    }
    document.addEventListener('mouseup', onDocumentMouseUp, { once: true })
  }

  onEnter = (event, editor, next) => {
    if (editor) {
      const { options = [], selection, autocomplete, name } = this.props
      if (editor.hasDecorations(AUTOCOMPLETE_MARK_TYPE)) {
        if (event.shiftKey) {
          return next()
        }

        const index = autocomplete.attributes.focusedByKeyboardIndex
        const option = options[index] || null

        if (option) {
          analytics.trackEvent({
            action: name,
            category: NOTE_TAKING_CATEGORY,
            label: AUTOCOMPLETE_ADD_CODE_LABEL,
            value: index + 1,
          })
          editor.replaceLastWordWithClinicalCode(option)

          event.preventDefault()
          return null
        }

        return next()
      }

      if (editor.hasDecorations(SELECTION_MARK_TYPE)) {
        const index = selection.attributes.focusedByKeyboardIndex
        const option = options[index] || null

        if (option) {
          const { preferredLabel, iri, hasNotes } = option

          analytics.trackEvent({
            action: name,
            category: NOTE_TAKING_CATEGORY,
            label: SELECTION_MARK_SELECTOR,
            value: index + 1,
          })
          editor.wrapSelectionWithClinicalCode(preferredLabel, iri, hasNotes)
        }

        event.preventDefault()
        return null
      }
    }

    return next()
  }

  onEscape = (event, editor, next) => {
    if (this.editor) {
      editor.moveToEnd()
      const hasCustomDecorations = this.editor.hasDecorations(
        AUTOCOMPLETE_MARK_TYPE,
        SELECTION_MARK_TYPE
      )

      if (hasCustomDecorations) {
        this.editor.setDecorations([])
      }
    }
    next()
  }

  onKeyDown = (event, editor, next) => {
    const { onKeyDown } = this.props

    if (onKeyDown) {
      onKeyDown(event, editor)
    }

    switch (event.key) {
      case 'Escape':
        return this.onEscape(event, editor, next)
      case 'Enter':
        return this.onEnter(event, editor, next)

      default:
        return next()
    }
  }

  onChange = ({ value }) => {
    if (this.editor) {
      const { enableAutocomplete, onChange, enableSelection } = this.props
      const { fragment } = value

      if (onChange) {
        onChange(value, this.editor)
      }

      if (enableSelection) {
        const hasSelectionDecoration = this.editor.hasDecorations(
          SELECTION_MARK_TYPE
        )

        if (hasSelectionDecoration) {
          this.getOptions(fragment.text)
        }
      }

      if (enableAutocomplete) {
        const hasAutocompleteDecoration = this.editor.hasDecorations(
          AUTOCOMPLETE_MARK_TYPE
        )
        const focusedWord = getFocusedWord(value)

        if (hasAutocompleteDecoration && focusedWord) {
          this.getOptions(focusedWord)
        }
      }
    }
  }

  handleDropdownEscape = () => {
    if (this.editor) {
      this.editor.moveToEnd()

      const hasCustomDecorations = this.editor.hasDecorations(
        AUTOCOMPLETE_MARK_TYPE,
        SELECTION_MARK_TYPE
      )

      if (hasCustomDecorations) {
        this.editor.setDecorations([])
      }
    }
  }

  renderAutocompleteMenu = () => {
    const {
      autocomplete,
      optionsLoading,
      options,
      enableNoteIcon,
      name,
    } = this.props
    const focusedWord = getFocusedWord(this.editor?.value)

    return (
      <SelectionMenu
        {...autocomplete.attributes}
        key={`selection-${this.id}`}
        dropdownId={`dropdown-${this.id}`}
        anchor={`.${AUTOCOMPLETE_MARK_SELECTOR}`}
        enableNoteIcon={enableNoteIcon}
        options={options}
        optionsLoading={optionsLoading}
        sourceValue={focusedWord}
        onSearchChange={this.getOptions}
        onEscape={this.handleDropdownEscape}
        onListItemSelect={({ index, option }) => {
          if (option) {
            this.editor?.replaceLastWordWithClinicalCode(option)

            analytics.trackEvent({
              action: name,
              category: NOTE_TAKING_CATEGORY,
              label: AUTOCOMPLETE_ADD_CODE_LABEL,
              value: index + 1,
            })
          }
        }}
      />
    )
  }

  renderSelectionMenu = () => {
    const { editor } = this
    const {
      selection,
      options = [],
      optionsLoading,
      enableNoteIcon,
      name,
    } = this.props
    /**
     * Source value - is a selected fragment of text in text editor.
     * After focusing on Hover Menu, this will become a default value for
     * search input within Hover Menu
     */
    const sourceValue = editor?.value
      ? editor?.value.fragment.text.trim()
      : null

    return (
      <SelectionMenu
        {...selection.attributes}
        key={`autocomplete-${this.id}`}
        dropdownId={`dropdown-${this.id}`}
        anchor={`.${SELECTION_MARK_SELECTOR}`}
        enableNoteIcon={enableNoteIcon}
        options={options}
        optionsLoading={optionsLoading}
        sourceValue={sourceValue}
        onSearchChange={this.getOptions}
        onEscape={this.handleDropdownEscape}
        onListItemSelect={({ index, option }) => {
          if (option) {
            const { preferredLabel, iri, hasNotes } = option
            analytics.trackEvent({
              action: name,
              category: NOTE_TAKING_CATEGORY,
              label: SELECTION_ADD_CODE_LABEL,
              value: index + 1,
            })
            editor?.wrapSelectionWithClinicalCode(preferredLabel, iri, hasNotes)
          }
        }}
      />
    )
  }

  isAutocompleteDropdownVisible = (editor) => {
    if (!editor) {
      return false
    }

    const { /* options = [], */ enableAutocomplete } = this.props

    return (
      enableAutocomplete && editor.canShowAutocompleteDropdown() // &&
      /* Show menu only when there are options for autocomplete */
      // options.length > 0
    )
  }

  isSelectionDropdownVisible = (editor = this.editor) => {
    if (!editor) {
      return false
    }

    const { enableSelection } = this.props

    return (
      enableSelection &&
      /* Show menu only after selection process has finished */
      // !this.isMouseDown &&
      editor.canShowSelectionDropdown()
    )
  }

  renderEditor = (props, editor, next) => {
    const children = next()

    return (
      <div className={styles.container} data-testid={props.dataTestId}>
        {children}
        {this.isAutocompleteDropdownVisible(editor)
          ? this.renderAutocompleteMenu()
          : null}
        {this.isSelectionDropdownVisible(editor)
          ? this.renderSelectionMenu()
          : null}
      </div>
    )
  }

  render() {
    const {
      placeholder,
      value,
      enableNoteIcon,
      dataTestId,
      readOnly,
    } = this.props

    return (
      <SlateEditor
        id={this.id}
        ref={this.setEditor}
        className={cn(styles.editor, readOnly && styles.readOnly)}
        placeholder={placeholder}
        value={value || defaultValue}
        plugins={this.plugins}
        schema={schema}
        readOnly={readOnly}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        renderEditor={this.renderEditor}
        enableNoteIcon={enableNoteIcon}
        dataTestId={dataTestId}
      />
    )
  }
}

Editor.defaultProps = {
  enableAutocomplete: false,
  enableSelection: false,
  enableInlineSearch: false,
  enableHighlight: false,
  enableNoteIcon: false,
  options: [],
  optionsLoading: false,
  readOnly: false,
}

export default compose(
  withDropdown({ name: 'autocomplete' }),
  withDropdown({ name: 'selection' })
)(Editor)
