export const isEmpty = (value) => {
  switch (typeof value) {
    case 'object':
      return !(value instanceof Date) && Object.keys(value || {}).length === 0
    case 'string':
      return value.length == 0
    case 'number':
      return isNaN(value)
    default:
      return value == null
  }
}

export const isUndefined = (value) => value == null

export const isInstanceOf = (object, Class) =>
  !isUndefined(object) &&
  !isUndefined(Class) &&
  object.constructor.prototype == Class.prototype
