import cn from 'classnames'
import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import RoundButton from '~/ui/RoundButton'

import { CallStatus } from '../CallStatusReducerModelProvider'
import { COOLDOWN_DURATION } from './constants'
import useCooldown from './useCooldown'

import messages from './CallButton.messages'
import styles from './CallButton.module.scss'

type CallButtonType = 'video' | 'voice'

export interface CallButtonProps {
  primary?: boolean
  className?: string
  onClick: () => void
  status: CallStatus
  type: CallButtonType
}

const videoMessageMap = {
  [CallStatus.CriticallyFailed]: messages.video_critically_failed,
  [CallStatus.Loading]: messages.video_loading,
  [CallStatus.Ready]: messages.video_ready,
  [CallStatus.Connecting]: messages.video_active,
  [CallStatus.Streaming]: messages.video_active,
  [CallStatus.Rejected]: messages.video_active,
  [CallStatus.Disabled]: null,
}

const voiceMessageMap = {
  [CallStatus.CriticallyFailed]: messages.voice_critically_failed,
  [CallStatus.Loading]: messages.voice_loading,
  [CallStatus.Ready]: messages.voice_ready,
  [CallStatus.Connecting]: messages.voice_active,
  [CallStatus.Streaming]: messages.voice_active,
  [CallStatus.Rejected]: messages.voice_active,
  [CallStatus.Disabled]: null,
}

const classNameMap = {
  [CallStatus.CriticallyFailed]: styles.criticallyFailed,
  [CallStatus.Loading]: styles.loading,
  [CallStatus.Ready]: styles.ready,
  [CallStatus.Connecting]: styles.active,
  [CallStatus.Streaming]: styles.active,
  [CallStatus.Rejected]: styles.active,
  [CallStatus.Disabled]: styles.disabled,
}

const testIdMap = {
  [CallStatus.CriticallyFailed]: 'call-button-critically-failed',
  [CallStatus.Loading]: 'call-button-loading',
  [CallStatus.Ready]: 'call-button-ready',
  [CallStatus.Connecting]: 'call-button-connecting',
  [CallStatus.Streaming]: 'call-button-streaming',
  [CallStatus.Rejected]: 'call-button-rejected',
  [CallStatus.Disabled]: null,
}

const getButtonProps = (
  type: CallButtonType,
  callStatus: CallStatus,
  isCoolingDown: boolean,
  primary: boolean | undefined
) => {
  const isActive = callStatus > CallStatus.Ready
  const buttonStatus = isCoolingDown ? CallStatus.Loading : callStatus

  const commonProps = {
    className: cn(
      styles.callButton,
      primary && styles.primary,
      classNameMap[buttonStatus]
    ),
    disableButton: buttonStatus < CallStatus.Ready,
  }

  if (type === 'video') {
    return {
      ...commonProps,
      title: videoMessageMap[buttonStatus],
      icon: isActive ? 'videocam_off' : 'videocam',
      testId: `video-${testIdMap[buttonStatus]}${primary ? '-primary' : ''}`,
    }
  }

  if (type === 'voice') {
    return {
      ...commonProps,
      title: voiceMessageMap[buttonStatus],
      icon: isActive ? 'phone_disabled' : 'phone_enabled',
      testId: `voice-${testIdMap[buttonStatus]}${primary ? '-primary' : ''}`,
    }
  }

  throw new Error(`Unknown call '${type}' passed to CallButton`)
}

const CallButton = ({
  primary,
  className: propClassName,
  status,
  onClick,
  type,
}: CallButtonProps) => {
  const fm = useFormatMessage()

  const isCoolingDown = useCooldown(status, COOLDOWN_DURATION)

  const { title, icon, className, testId, disableButton } = getButtonProps(
    type,
    status,
    isCoolingDown,
    primary
  )

  const formattedTitle = title ? fm(title) : ''

  return (
    <RoundButton
      tooltipMessage={formattedTitle}
      icon={icon}
      className={cn(className, propClassName)}
      disabled={disableButton}
      onClick={onClick}
      testId={testId}
    />
  )
}

export default CallButton
