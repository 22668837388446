import React from 'react'

import Tooltip from '~/ui/Tooltip'

import styles from './styles.module.scss'

const InfoTooltip = ({ info, ...rest }) => (
  <div className={styles.infoTooltipWrap}>
    <Tooltip
      info={<div className={styles.tooltipMessage}>{info}</div>}
      {...rest}
      className={styles.infoTooltip}
    />
  </div>
)

export default InfoTooltip
