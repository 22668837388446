import { useContext } from 'react'

import { EventBusContext, EventBusType } from './EventBusProvider'

const useEventBus = (): EventBusType => {
  const eventBus = useContext(EventBusContext)

  if (!eventBus) {
    throw new Error('useEventBus must be used inside EventBusProvider')
  }

  return eventBus
}

export default useEventBus
