import React from 'react'

import { ExaminationSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useConsultationNotesSummaryQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import ExaminationSummary from './ExaminationSummary'

import messages from './ExaminationSummary.messages'

const ExaminationSummaryModule: ExaminationSummaryPluginInterface = () => {
  const ExaminationSummaryWrapper = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)
    const { data, loading, error, refetch } = useConsultationNotesSummaryQuery({
      variables: { consultationId: consultation.id },
    })

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={f('examination_summary_loading_error')}
          retry={() => refetch()}
        />
      )
    }

    const consultationNote = data?.consultation?.consultationNote

    if (!consultationNote) {
      return null
    }

    return (
      <ExaminationSummary
        consultationNote={consultationNote}
        loading={loading}
      />
    )
  }

  return ExaminationSummaryWrapper
}

export default ExaminationSummaryModule
