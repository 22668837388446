import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.workflow_actions.workflow_action'

export default defineMessages({
  create_button_label: {
    id: `${baseId}.create_button_label`,
    defaultMessage: 'Create {name}',
  },
})
