import './material-design/material-icons.css'

import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

interface IconProps {
  className?: string
  name: string
  type?: 'button'
  fixed?: boolean
  size?: number
}

const Icon = ({ className, name, type, fixed, size, ...rest }: IconProps) => {
  const mergedClassName = classNames(
    'material-icons',
    styles.icon,
    type && styles[type],
    fixed && styles.fixed,
    className
  )

  return (
    <i className={mergedClassName} style={{ fontSize: size }} {...rest}>
      {name}
    </i>
  )
}

export default Icon
