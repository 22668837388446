import React from 'react'

import { Anchor, Spinner } from '@babylon/core-ui'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import CodedTextWithClinicalCodes, {
  ClinicalCodes,
} from '~/features/clinical-coding/CodedTextWithClinicalCodes'
import {
  isEmptyPrimitive,
  Section,
} from '~/features/consultation-summary/ConsultationSummary'
import { ConsultationFurtherInfo, ConsultationNote } from '~/generated'

import messages from './DiagnosisSummary.messages'

interface DiagnosisSummaryProps {
  consultationNote: Pick<
    ConsultationNote,
    'assessment' | 'assessmentCodes' | 'treatmentPlan' | 'fallbackPlan'
  >
  furtherInformation: ConsultationFurtherInfo[]
  loading?: boolean
}

const DiagnosisSummary = ({
  consultationNote,
  furtherInformation,
  loading = false,
}: DiagnosisSummaryProps) => {
  const f = useMessages(messages)

  if (loading) {
    return (
      <>
        <Section title={f('our_assessment_section_title')}>
          <Spinner size="small" testid="diagnosis-summary-loading" />
        </Section>
        <Section title={f('treatment_section_title')}>
          <Spinner size="small" testid="diagnosis-summary-loading" />
        </Section>
        <Section title={f('fallback_section_title')}>
          <Spinner size="small" testid="diagnosis-summary-loading" />
        </Section>
        <Section title={f('further_information_section_title')}>
          <Spinner size="small" testid="diagnosis-summary-loading" />
        </Section>
      </>
    )
  }

  const {
    assessment,
    assessmentCodes,
    treatmentPlan,
    fallbackPlan,
  } = consultationNote

  return (
    <>
      {!isEmptyPrimitive(assessment) && (
        <Section
          title={f('our_assessment_section_title')}
          testid="cr-assessment"
        >
          <CodedTextWithClinicalCodes
            text={assessment}
            codes={assessmentCodes || []}
          />
          <ClinicalCodes codes={assessmentCodes || []} />
        </Section>
      )}
      {!isEmptyPrimitive(treatmentPlan) && (
        <Section
          title={f('treatment_section_title')}
          testid="cr-treatment-plan"
        >
          {treatmentPlan}
        </Section>
      )}
      {!isEmptyPrimitive(fallbackPlan) && (
        <Section title={f('fallback_section_title')} testid="cr-fallback-plan">
          {fallbackPlan}
        </Section>
      )}
      {isFilledArray(furtherInformation) && (
        <Section
          title={f('further_information_section_title')}
          testid="cr-further-info"
        >
          {furtherInformation.map((info) => (
            <div key={info.id}>
              <Anchor href={info.url} target="_blank">
                {info.name || info.url}
              </Anchor>
            </div>
          ))}
        </Section>
      )}
    </>
  )
}

export default DiagnosisSummary
