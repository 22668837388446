import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.history.timeline'

export default defineMessages({
  no_history_events_found: {
    id: `${baseId}.no_history_events_found`,
    defaultMessage: 'No history events of the selected type(s) were found.',
  },
  no_future_events_found: {
    id: `${baseId}.no_future_events`,
    defaultMessage: 'Patient has no future consultations scheduled',
  },
})
