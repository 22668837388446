import { useConsultantUser } from '@babylon/babylon-user'

import {
  ConsultationScheduleQuery,
  useConsultationScheduleQuery,
} from '~/generated'

import { getScheduleDateTimeRange } from './utils'

export const CONSULTATIONS_POLL_INTERVAL = 1000 * 60 // 1 minute

export type Consultation = ConsultationScheduleQuery['consultations'][number]

type UseConsultationScheduleProps = {
  date: Date
  poll: boolean
}

const useConsultationSchedule = ({
  date,
  poll,
}: UseConsultationScheduleProps) => {
  const user = useConsultantUser()
  const { fromDate, toDate } = getScheduleDateTimeRange(date)

  return useConsultationScheduleQuery({
    // Note: notifyOnNetworkStatusChange must be true or the variables will not update when polling
    // See https://github.com/apollographql/apollo-client/issues/3053#issuecomment-734269818
    notifyOnNetworkStatusChange: true,
    variables: {
      consultants: [user.id],
      fromDate,
      toDate,
    },
    pollInterval: poll ? CONSULTATIONS_POLL_INTERVAL : undefined,
  })
}

export default useConsultationSchedule
