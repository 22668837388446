import { defineMessages } from 'react-intl'

const baseId = 'drug_table'

export default defineMessages({
  buttonEdit: {
    id: `${baseId}.button.edit`,
    defaultMessage: 'Edit',
  },
  buttonRemove: {
    id: `${baseId}.button.remove`,
    defaultMessage: 'Remove',
  },
  labelRepeats: {
    id: `${baseId}.label.Repeats`,
    defaultMessage: 'Repeats:',
  },
  labelReviewDate: {
    id: `${baseId}.label.review_date`,
    defaultMessage: 'Review date:',
  },
  headerDrug: {
    id: `${baseId}.header.drug`,
    defaultMessage: 'Drug',
  },
  headerQuantity: {
    id: `${baseId}.header.quantity`,
    defaultMessage: 'Quantity',
  },
  headerDirections: {
    id: `${baseId}.header.directions`,
    defaultMessage: 'Directions',
  },
  headerRepeatable: {
    id: `${baseId}.header.repeatable`,
    defaultMessage: 'Repeatable',
  },
})
