import React from 'react'

import { ReactComponent as IconPhysical } from '~/assets/physical.svg'
import { ReactComponent as IconText } from '~/assets/text.svg'
import { ReactComponent as IconPhone } from '~/assets/timeline/appointment.svg'
import { ReactComponent as IconChatbot } from '~/assets/timeline/chatbot.svg'
import { ReactComponent as IconTest } from '~/assets/timeline/test.svg'
import { ReactComponent as IconVideo } from '~/assets/timeline/video.svg'
import { TimelineEventType } from '~/generated'

import { ConsultationTypes } from '../../../../schedule/ConsultationScheduleItem/utils'

interface TimelineIconProps {
  consultationType: string
  timelineEventType: Maybe<TimelineEventType> | undefined
}

const TimelineIcon = ({
  consultationType,
  timelineEventType,
}: TimelineIconProps) => {
  if (timelineEventType === TimelineEventType.Consultation) {
    switch (consultationType) {
      case ConsultationTypes.video: {
        return <IconVideo data-testid="video-icon" />
      }
      case ConsultationTypes.audio: {
        return <IconPhone data-testid="audio-icon" />
      }
      case ConsultationTypes.f2f: {
        return <IconPhysical data-testid="f2f-icon" />
      }
      case ConsultationTypes.text: {
        return <IconText width="16" height="16" data-testid="text-icon" />
      }
      default:
        return <IconPhone />
    }
  }

  switch (timelineEventType) {
    case TimelineEventType.ChatscriptConversation: {
      return <IconChatbot />
    }
    case TimelineEventType.TestReport: {
      return <IconTest />
    }
    default:
      return <IconPhone />
  }
}

export default TimelineIcon
