// Implements NHS CUI (Common User Interface) compliant date display.
// For details, look at the documents at https://webarchive.nationalarchives.gov.uk/20160921150545/http://systems.digital.nhs.uk/data/cui/uig

import moment from 'moment-timezone'

// function takes two inputs, the date (in string format) and an object of options
//
//   date: (string): ISO 8601 or RFC 2822 formats accepted, new Date(string) used as fallback
//        see moment.js docs for more details
//   options:
//        format (string): "short" or "long" - see docs linked above for details
//        showDayOfWeek (boolean): whether to show the day of week
//        showTime (boolean): whether to show the time (e.g. "15:34")
//        showSeconds (boolean): whether to show the seconds (e.g. "15:34:15")
//        ordinal (boolean): show day value as 1st instead of 01
//        ordinalSuperscript (boolean): add <sup> tag around ordinal (to be fully CUI compliant)
//        timezone (string): a valid timezone string, see moment.js docs for details
export const formatDate = (
  date,
  {
    format = 'short',
    showDayOfWeek = false,
    showTime = false,
    showSeconds = false,
    ordinal = false,
    ordinalSuperscript = false,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ||
      'Europe/London',
  } = {}
) => {
  if (!date) {
    return ''
  }

  if (!['short', 'long'].includes(format)) {
    throw new Error('Invalid format')
  }

  let dowString
  let mainString
  let timeString

  if (format === 'short') {
    dowString = showDayOfWeek ? 'ddd ' : ''
    mainString = 'DD-MMM-YYYY'
  } else {
    dowString = showDayOfWeek ? 'dddd ' : ''
    mainString = `${ordinal ? 'Do' : 'DD'} MMMM YYYY`
  }

  timeString = showTime ? ` HH:mm${showSeconds ? ':ss' : ''}` : ''

  const formatString = `${dowString}${mainString}${timeString}`

  let formattedDate = moment(date).tz(timezone).format(formatString)

  if (ordinalSuperscript) {
    formattedDate = formattedDate.replace(
      /(\d)(st|nd|rd|th)/g,
      '$1<sup>$2</sup>'
    )
  }

  return formattedDate
}

export const defaultShortFormat = 'DD-MMM-YYYY'
export const defaultLongFormat = 'DD MMMM YYYY'
