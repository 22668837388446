import React from 'react'
import { FormattedMessage } from 'react-intl'

import messages from '../NhsAppointment.messages'

const mediumTypePills = [
  {
    name: <FormattedMessage {...messages.medium_option_face_to_face} />,
    id: 'physical',
  },
  {
    name: <FormattedMessage {...messages.medium_option_digital} />,
    id: 'digital',
  },
]

const serviceTypesForF2FPills = [
  {
    name: <FormattedMessage {...messages.appointment_type_nurse_hca_phleb} />,
    id: 'NURSE',
  },
  {
    name: <FormattedMessage {...messages.appointment_type_gp} />,
    id: 'GP',
  },
]

const serviceTypesForDigitalPills = [
  {
    name: <FormattedMessage {...messages.appointment_type_gp} />,
    id: 'GP',
  },
  {
    name: <FormattedMessage {...messages.appointment_type_nurse} />,
    id: 'NURSE',
  },
  {
    name: <FormattedMessage {...messages.appointment_type_pharmacist} />,
    id: 'PHARMACIST',
  },
]

export { mediumTypePills, serviceTypesForDigitalPills, serviceTypesForF2FPills }
