import cn from 'classnames'
import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import styles from './styles.module.scss'

const ESC_CODE = 27
const DEFAULT_TRANSITION = 'overlay-transition'

export type ModalProps = {
  visible: boolean
  onVisibleChange: Function
  style?: any
  onTransitionExited?: Function
  onTransitionEntered?: Function
  // TODO: convert this to functional component and correct the typing of children to:
  //  children?: React.ReactNode | ((context: ModalContext) => React.ReactNode)
  children?: React.ReactNode
  className?: string
  transition?: string
  closeOnBackgroundClick?: boolean
  testId?: string
}

type KeyboardEventListener = (event: KeyboardEvent) => void

export interface ModalContext {
  openModal: () => void
  closeModal: () => void
}

// This is to ensure that the element is in the DOM, and helps with testing
let modalRoot = document.getElementById('root') as HTMLElement

if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'root')
  document.body.appendChild(modalRoot)
}

class Modal extends React.Component<ModalProps, {}> {
  static defaultProps = {
    closeOnBackgroundClick: true,
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleEscKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleEscKeyPress)
  }

  handleEscKeyPress: KeyboardEventListener = (event) => {
    const { closeOnBackgroundClick } = this.props

    if (closeOnBackgroundClick && event.keyCode === ESC_CODE) {
      this.closeModal()
    }
  }

  handleModalClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()
  }

  handleOverlayClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const { closeOnBackgroundClick } = this.props

    event.stopPropagation()

    if (closeOnBackgroundClick) {
      this.closeModal()
    }
  }

  openModal = () => {
    const { onVisibleChange } = this.props

    onVisibleChange(true)
  }

  closeModal = () => {
    const { onVisibleChange } = this.props

    onVisibleChange(false)
  }

  render() {
    const {
      children,
      className,
      style,
      onTransitionExited,
      onTransitionEntered,
      testId,
      transition,
      visible,
    } = this.props

    const context: ModalContext = {
      closeModal: this.closeModal,
      openModal: this.openModal,
    }

    const content =
      typeof children === 'function' ? children(context) : children

    return ReactDOM.createPortal(
      <div
        className={styles.container}
        role="dialog"
        aria-modal="true"
        data-testid={testId || 'modal'}
      >
        <CSSTransition
          key="overlay"
          appear
          in={visible}
          classNames={DEFAULT_TRANSITION}
          timeout={200}
        >
          <div
            aria-hidden="true"
            className={styles.overlay}
            onClick={this.handleOverlayClick}
            data-testid="modal-overlay"
          />
        </CSSTransition>
        <CSSTransition
          key="modal"
          appear
          in={visible}
          classNames={transition || DEFAULT_TRANSITION}
          timeout={200}
          onEntered={() => onTransitionEntered && onTransitionEntered()}
          onExited={() => onTransitionExited && onTransitionExited()}
        >
          <div
            role="presentation"
            className={cn(styles.modal, className)}
            style={style}
            onClick={this.handleModalClick}
          >
            {content}
          </div>
        </CSSTransition>
      </div>,
      modalRoot
    )
  }
}

export default Modal
