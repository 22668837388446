import { defineMessages } from 'react-intl'

const baseId = 'release_notes'

export default defineMessages({
  release_notes_button_text: {
    id: `${baseId}.release_notes_button_text_v2`,
    defaultMessage: 'Release notes',
  },
})
