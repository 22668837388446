import React from 'react'

import { Button, Cell, Grid, Text } from '@babylon/core-ui'

import { useMessages } from '~/core/hooks'

import messages from './CallQualityFeedback.messages'
import styles from './styles.module.scss'

interface QuestionProps {
  questionText: string
  onSubmit?: () => void
  onDismiss?: () => void
  disableSubmit?: boolean
  children: React.ReactNode
}

const Question = ({
  questionText,
  children,
  onSubmit,
  onDismiss,
  disableSubmit,
}: QuestionProps) => {
  const f = useMessages(messages)

  return (
    <div className={styles.wrapper} data-testid="call-quality-feedback-form">
      <Grid
        templateRows="1fr auto"
        gap={24}
        style={{ height: '100%' }}
        className={styles.grid}
      >
        <Cell middle style={{ alignItems: 'center' }}>
          <Text tag="div" size="large" className={styles.questionText}>
            {questionText}
          </Text>
          {children}
        </Cell>
        {onSubmit && onDismiss && (
          <Cell>
            <div className={styles.submitAndDismissWrapper}>
              <Button
                intent="primary"
                className={styles.button}
                onClick={() => onSubmit()}
                disabled={disableSubmit}
              >
                {f('submit')}
              </Button>
              <Button
                intent="secondary"
                className={styles.button}
                onClick={() => onDismiss()}
              >
                {f('dismiss')}
              </Button>
            </div>
          </Cell>
        )}
      </Grid>
    </div>
  )
}

export default Question
