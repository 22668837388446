import React from 'react'

import { CONSULTATION_STATUSES } from '~/constants'
import { ConsultationContextType, useConsultation } from '~/core/config'
import { AttachmentsPluginInterface } from '~/core/config/modules/generated/types'
import { useAttachmentsQuery } from '~/generated'

import AttachmentsView from './AttachmentsView'

interface AttachmentsContainerProps {
  consultationContext: ConsultationContextType
}

export const AttachmentsContainer: AttachmentsPluginInterface = ({
  consultationContext,
}: AttachmentsContainerProps) => {
  const consultation = useConsultation(consultationContext)
  const { data, loading, error } = useAttachmentsQuery({
    variables: { id: consultation.id },
  })
  const attachments = data?.consultation?.testResults ?? []

  const isCompleted = consultation.status !== CONSULTATION_STATUSES.PAID

  if (!isCompleted || loading || error) {
    return null
  }

  return <AttachmentsView attachments={attachments} />
}

export default AttachmentsContainer
