import React from 'react'
import { Route, useHistory } from 'react-router'

import { PATIENT_DETAILS_SECTION_ERROR_ACTION } from '~/constants/analytics'
import { useConsultation, usePatient } from '~/core/config'
import { PatientDetailsPluginInterface } from '~/core/config/modules/generated/types'
import { usePermissions } from '~/core/permissions'
import { useRedirectToConsultation } from '~/features/consultation/utils'
import { usePatientDetailsQuery } from '~/generated'
import { withSectionErrorBoundary } from '~/ui/Section'

import PatientDetailsModal from '../PatientDetailsModal'
import PatientDetailsSectionView from './PatientDetailsSectionView'

import messages from './PatientDetailsSection.messages'

const PatientDetailsSection: PatientDetailsPluginInterface = ({
  consultationContext,
  hideIdVerificationEnabled,
}) => {
  const history = useHistory()

  const { pathname } = history.location

  const openModal = () => {
    history.replace(`${pathname}/patient-details/edit`)
  }

  const { languageRequested } = useConsultation(consultationContext)
  const basePatient = usePatient(consultationContext)

  const redirectToConsultation = useRedirectToConsultation()

  const [canUpdatePatientDetails] = usePermissions('can_update_patient_details')

  const { data, loading, error } = usePatientDetailsQuery({
    variables: { id: basePatient.id },
  })

  const patient = {
    ...basePatient,
    ...data?.patient,
  }

  return (
    <>
      <PatientDetailsSectionView
        editButtonHidden={!canUpdatePatientDetails}
        openModal={openModal}
        loading={loading}
        languageRequested={languageRequested}
        error={error}
        patient={patient}
        hideIdVerificationEnabled={hideIdVerificationEnabled}
      />
      {canUpdatePatientDetails && !loading && !error && (
        // TODO - decouple from consultation path - reach router?
        <Route path="/consultation/:consultationId/patient-details/edit">
          <PatientDetailsModal
            onClose={redirectToConsultation}
            patient={patient}
          />
        </Route>
      )}
    </>
  )
}

export default withSectionErrorBoundary({
  gaAction: PATIENT_DETAILS_SECTION_ERROR_ACTION,
  sectionTitleDescriptor: messages.title,
})(PatientDetailsSection)
