import React from 'react'

import { AttachmentsSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useMessages } from '~/core/hooks'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useAttachmentsSummaryQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import AttachmentsSummary from './AttachmentsSummary'

import messages from './AttachmentsSummary.messages'

const AttachmentsSummaryPlugin: AttachmentsSummaryPluginInterface = () => {
  const AttachmentsSummaryComponent = ({
    consultation,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)

    const { data, loading, error, refetch } = useAttachmentsSummaryQuery({
      variables: { id: consultation.id },
    })
    const attachments = data?.consultation?.testResults ?? []

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={f('attachments_loading_error')}
          retry={() => refetch()}
        />
      )
    }

    if (loading) {
      return null
    }

    return <AttachmentsSummary attachments={attachments} />
  }

  return AttachmentsSummaryComponent
}

export default AttachmentsSummaryPlugin
