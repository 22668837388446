import React from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { isFilledArray } from '~/core'
import { AppointmentInvitesSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { BaseConsultationSummaryProps } from '~/features/consultation-summary/ConsultationSummary'
import { useGetAppointmentInvitesQuery } from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'

import AppointmentInvitesSummary from './AppointmentInvitesSummary'

import messages from './AppointmentInvitesSummary.messages'
import styles from './styles.module.scss'

const AppointmentInvitesSummaryModule: AppointmentInvitesSummaryPluginInterface = ({
  enableOnlyForGlobalInvites,
}) => {
  const AppointmentInvitesSummaryWrapper = ({
    consultation,
    light,
  }: BaseConsultationSummaryProps) => {
    const f = useMessages(messages)
    const { appointmentInvitesEnabled } = useFeatureFlags()
    const {
      f2FMediumServiceTypeInvitesSupported: globalInvitesEnabled,
    } = useFeatureFlags()
    const { consultant: currentConsultant } = useConsultantUser()

    const invitesDisabled = (() => {
      if (enableOnlyForGlobalInvites && !globalInvitesEnabled) {
        return true
      }
      return !appointmentInvitesEnabled
    })()

    const { data, loading, error, refetch } = useGetAppointmentInvitesQuery({
      variables: { id: consultation.id },
      skip: invitesDisabled,
    })
    const appointmentInvites = data?.appointmentInvites ?? []

    if (invitesDisabled) {
      return null
    }

    if (error) {
      return (
        <ErrorPanel
          error={error}
          title={f('invites_error')}
          retry={() => refetch()}
          className={styles.invitesError}
        />
      )
    }

    if (
      !isFilledArray(appointmentInvites) ||
      !appointmentInvitesEnabled ||
      loading
    ) {
      return null
    }

    return (
      <AppointmentInvitesSummary
        appointmentInvites={appointmentInvites}
        currentConsultant={currentConsultant}
        light={light}
      />
    )
  }
  return AppointmentInvitesSummaryWrapper
}

export default AppointmentInvitesSummaryModule
