import { ApolloError } from '@apollo/client'
import { set } from 'date-fns/fp'
import { Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

import { Text } from '@babylon/core-ui'

import {
  APPOINTMENT_INVITES_CATEGORY,
  APPOINTMENT_INVITES_CREATE_MODAL_LABEL,
  APPOINTMENT_INVITES_CREATE_MODAL_LOADED_ACTION,
  APPOINTMENT_INVITES_OK_ACTION,
  APPOINTMENT_INVITES_OK_LABEL,
  APPOINTMENT_INVITES_SEND_ACTION,
  APPOINTMENT_INVITES_SEND_LABEL,
  APPOINTMENT_INVITES_SEND_MODAL_LABEL,
  APPOINTMENT_INVITES_SEND_MODAL_LOADED_ACTION,
} from '~/constants/analytics'
import { isTruthy } from '~/core'
import analytics from '~/core/analytics'
import { FeatureFlags, useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import {
  ConsumerNetworkProfession,
  InviteFormMedium,
  InviteFormValues,
} from '~/features/appointment-invites/AppointmentInvitesModal/types'
import {
  AppointmentInviteFeatures,
  AppointmentInviteInput,
  CreateInviteV3Mutation,
  CreateInviteV3MutationFn,
  GetAppointmentInvitesQueryResult,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Dialog, Slideout } from '~/ui/Modal'

import { ServiceType } from '../types'
import AppointmentInvitesForm from './AppointmentInvitesForm'
import AppointmentInvitesRecurrenceDialog from './AppointmentInvitesRecurrenceDialog'
import {
  formatToISOLocalDate,
  formatToISOZonedDateTime,
  useIsRecurrenceInviteBooking,
} from './utils'

import messages from './AppointmentInvitesModal.messages'
import styles from './styles.module.scss'

export interface AppointmentInvitesModalViewProps {
  professions: ConsumerNetworkProfession[]
  serviceTypes: ServiceType[]
  clinicianServiceTypes: ServiceType[]
  professionName: string
  consultantUuid: string
  consultantTimezoneId?: Maybe<string>
  consultationId: string
  patientId: string
  createInviteMutation: CreateInviteV3MutationFn
  queryLoading: boolean
  inviteLoading: boolean
  queryError?: ApolloError
  inviteError?: ApolloError
  refetch: () => void
  updateInvites: GetAppointmentInvitesQueryResult['updateQuery']
}

export enum ProviderType {
  anyProvider = 'Any available provider',
  currentConsultant = 'Myself as a provider',
}

const validationSchema = (
  {
    recurringInvitesEnabled,
    recurringInvitesBookingForPatientEnabled,
    recurringInvitesBiweeklyBookingEnabled,
    serviceTypesInvitesEnabled,
  }: FeatureFlags,
  isMediumFieldEnabled: boolean,
  isRecurrenceInviteBooking: (
    selectedServiceType: InviteFormValues['serviceType'],
    selectedProviderType: InviteFormValues['providerType']
  ) => boolean
) => {
  return Yup.object().shape({
    medium: isMediumFieldEnabled
      ? Yup.mixed<InviteFormMedium>().required()
      : Yup.mixed().notRequired(),
    duration: Yup.number().nullable(),
    profession: serviceTypesInvitesEnabled
      ? Yup.string().nullable()
      : Yup.string().required().nullable(),
    recurrence: Yup.number().required(),
    cadence:
      recurringInvitesBookingForPatientEnabled &&
      recurringInvitesBiweeklyBookingEnabled
        ? Yup.object().when(['providerType', 'serviceType'], {
            is: (
              providerType: InviteFormValues['providerType'],
              serviceType: InviteFormValues['serviceType']
            ) => isRecurrenceInviteBooking(serviceType, providerType),
            then: Yup.object().required().nullable(),
            otherwise: Yup.object().nullable(),
          })
        : Yup.object().nullable(),
    startDate: Yup.date().required().nullable(),
    appointmentReason: Yup.string().required(),
    // @ts-ignore
    serviceType: serviceTypesInvitesEnabled
      ? Yup.object().required().nullable()
      : Yup.object().nullable(),
    providerType: recurringInvitesEnabled
      ? Yup.mixed<ProviderType>().when('medium', {
          is: (medium: InviteFormMedium) =>
            medium !== InviteFormMedium.faceToFace,
          then: Yup.mixed<ProviderType>().required(),
          otherwise: Yup.mixed<ProviderType>().nullable(),
        })
      : Yup.mixed<ProviderType>().nullable(),
    time: recurringInvitesBookingForPatientEnabled
      ? Yup.object().when(['providerType', 'serviceType'], {
          is: (
            providerType: InviteFormValues['providerType'],
            serviceType: InviteFormValues['serviceType']
          ) => isRecurrenceInviteBooking(serviceType, providerType),
          then: Yup.object().required().nullable(),
          otherwise: Yup.object().nullable(),
        })
      : Yup.object().nullable(),
  })
}

const initialValues: InviteFormValues = {
  medium: null,
  duration: null,
  profession: null,
  providerType: null,
  serviceType: null,
  time: null,
  appointmentReason: '',
  startDate: new Date(),
  recurrence: 1,
  cadence: null,
}

const AppointmentInvitesModalView = ({
  professions,
  serviceTypes,
  clinicianServiceTypes,
  professionName,
  consultantUuid,
  consultantTimezoneId,
  consultationId,
  patientId,
  createInviteMutation,
  queryLoading,
  inviteLoading,
  queryError,
  inviteError,
  refetch,
  updateInvites,
}: AppointmentInvitesModalViewProps) => {
  const featureFlags = useFeatureFlags()
  const {
    recurringInvitesEnabled,
    recurringInvitesMultipleInvitesEnabled,
    recurringInvitesBiweeklyBookingEnabled,
    serviceTypesInvitesEnabled,
  } = featureFlags
  const history = useHistory()
  const f = useMessages(messages)
  const [createdInviteResult, setCreatedInviteResult] = useState<
    CreateInviteV3Mutation['createAppointmentInviteV3']
  >()

  const [showDialogRecurrence, setShowDialogRecurrence] = useState(false)
  const [dialogMounted, setDialogMounted] = useState<boolean>(false)

  const { f2FMediumServiceTypeInvitesSupported = false } = useFeatureFlags()

  const isThereAtLeastOneServiceTypeWithFace2Face = useMemo(
    () =>
      serviceTypes.find((s) => s.allowed_mediums.includes('physical')) != null,
    [serviceTypes]
  )

  const isMediumRequired =
    f2FMediumServiceTypeInvitesSupported &&
    isThereAtLeastOneServiceTypeWithFace2Face

  useEffect(() => {
    if (inviteError) {
      setShowDialogRecurrence(false)
    }
  }, [inviteError])

  const trackEvent = analytics.trackEventFactory({
    category: APPOINTMENT_INVITES_CATEGORY,
  })

  const onOpen = () => {
    trackEvent({
      action: APPOINTMENT_INVITES_CREATE_MODAL_LOADED_ACTION,
      label: APPOINTMENT_INVITES_CREATE_MODAL_LABEL,
    })
  }

  const onCloseDrawer = () => history.replace(`/consultation/${consultationId}`)

  const onOk = () => {
    onCloseDrawer()

    trackEvent({
      action: APPOINTMENT_INVITES_OK_ACTION,
      label: APPOINTMENT_INVITES_OK_LABEL,
    })
  }

  const isRecurrenceInviteBooking = useIsRecurrenceInviteBooking()

  const getStartDateTime = (values: InviteFormValues) =>
    set(
      {
        hours: values?.time?.hour,
        minutes: values?.time?.minutes,
        seconds: 0,
        milliseconds: 0,
      },
      values.startDate!
    )

  const onSubmit = async (values: InviteFormValues) => {
    try {
      const isRecurrenceBooking = isRecurrenceInviteBooking(
        values.serviceType,
        values.providerType
      )

      if (isRecurrenceBooking && !showDialogRecurrence) {
        setShowDialogRecurrence(true)
        return
      }

      const allowedMediums = values.serviceType?.allowed_mediums.filter(
        (medium) => {
          if (values.medium === InviteFormMedium.faceToFace) {
            return medium === 'physical'
          }
          if (values.medium === InviteFormMedium.digital) {
            return medium === 'video' || medium === 'voice'
          }
          return false
        }
      )

      const selectedDate = values.startDate!
      const preferredDateTime = getStartDateTime(values)

      const input: AppointmentInviteInput = {
        member_uuid: patientId,
        source_appointment_id: consultationId,
        earliest_booking_date: formatToISOLocalDate(selectedDate),
        ...(isRecurrenceBooking && {
          preferred_date_time: formatToISOZonedDateTime(
            preferredDateTime,
            consultantTimezoneId
          ),
          timezone_id: consultantTimezoneId,
          appointment_invite_features: [
            AppointmentInviteFeatures.CreateInviteWithBookings,
          ],
        }),
        intimate: false,
        notes_for_member: values.appointmentReason,
        ...(!serviceTypesInvitesEnabled && {
          duration_minutes: values.duration,
          preferred_profession: values.profession,
        }),
        ...(serviceTypesInvitesEnabled && {
          service_type_uuid: values.serviceType?.id,
        }),
        ...(recurringInvitesMultipleInvitesEnabled &&
          values.providerType === ProviderType.currentConsultant && {
            recurrence: {
              count: values.recurrence,
              ...(recurringInvitesBiweeklyBookingEnabled
                ? {
                    frequency: values.cadence?.frequency,
                    interval: values.cadence?.interval,
                  }
                : {}),
            },
          }),
        ...(recurringInvitesEnabled &&
          values.providerType === ProviderType.currentConsultant && {
            consultant_uuid: consultantUuid,
          }),
        ...(isMediumRequired && {
          allowed_mediums: allowedMediums,
          preferred_medium: allowedMediums?.[0],
        }),
      }

      const result = await createInviteMutation({ variables: { input } })

      if (!isRecurrenceBooking) {
        setDialogMounted(true)
      } else {
        setCreatedInviteResult(result.data?.createAppointmentInviteV3)
      }

      trackEvent({
        action: APPOINTMENT_INVITES_SEND_MODAL_LOADED_ACTION,
        label: APPOINTMENT_INVITES_SEND_MODAL_LABEL,
      })

      updateInvites((prev) => {
        const previousInvites = prev?.appointmentInvites

        return {
          ...prev,
          appointmentInvites: [
            result?.data?.createAppointmentInviteV3.invite,
            ...previousInvites,
          ].filter(isTruthy),
        }
      })
    } catch (err) {
      logException(err)
    }

    trackEvent({
      action: APPOINTMENT_INVITES_SEND_ACTION,
      label: APPOINTMENT_INVITES_SEND_LABEL,
    })
  }

  return (
    <Slideout
      title={f('modal_title')}
      onClose={onCloseDrawer}
      loading={queryLoading}
      onOpen={onOpen}
    >
      {dialogMounted && (
        <Dialog
          title={f('dialog_sent_title')}
          onOk={onOk}
          okLabel={f('dialog_sent_ok_label')}
          ok
          className={styles.dialog}
        >
          <Text>{f('dialog_sent_message')}</Text>
        </Dialog>
      )}
      {queryError ? (
        <ErrorPanel
          error={queryError}
          title={f('error_message')}
          retry={() => refetch()}
          fill="container"
          center
        />
      ) : (
        <Formik<InviteFormValues>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(
            featureFlags,
            isMediumRequired,
            isRecurrenceInviteBooking
          )}
        >
          {({ handleSubmit, values }) => (
            <>
              <AppointmentInvitesForm
                professions={professions}
                serviceTypes={serviceTypes}
                isMediumFieldVisible={isMediumRequired}
                clinicianServiceTypes={clinicianServiceTypes}
                loading={inviteLoading}
                error={inviteError}
                professionName={professionName}
              />

              {showDialogRecurrence && (
                <AppointmentInvitesRecurrenceDialog
                  onFinish={() => onOk()}
                  onClose={() => setShowDialogRecurrence(false)}
                  handleSubmit={() => handleSubmit()}
                  recurrenceCount={values.recurrence}
                  cadenceValue={values.cadence}
                  startDateTime={getStartDateTime(values)}
                  loading={inviteLoading}
                  inviteResult={createdInviteResult}
                />
              )}
            </>
          )}
        </Formik>
      )}
    </Slideout>
  )
}
export default AppointmentInvitesModalView
