import { defineMessages } from 'react-intl'

const baseId = 'appointment_booking_confirmation_dialog'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Are you happy with the following information?',
  },
  message: {
    id: `${baseId}.message`,
    defaultMessage: 'Once it has been sent you will not be able to edit it.',
  },
  type: {
    id: `${baseId}.type`,
    defaultMessage: 'Type:',
  },
  clinician: {
    id: `${baseId}.clinician`,
    defaultMessage: 'Clinician:',
  },
  duration: {
    id: `${baseId}.duration`,
    defaultMessage: 'Duration:',
  },
  ok_label: {
    id: `${baseId}.ok_label`,
    defaultMessage: 'Yes, send details',
  },
  cancel_label: {
    id: `${baseId}.cancel_label`,
    defaultMessage: 'Cancel',
  },
  error_title: {
    id: `${baseId}.error_title`,
    defaultMessage: 'Something went wrong',
  },
  error_message: {
    id: `${baseId}.error_message`,
    defaultMessage: 'We are unable to send this appointment, please try again.',
  },
})
