import { defineMessages } from 'react-intl'

const baseId = 'shared.chatbot.conversation_type'

export default defineMessages({
  covid_flow_title: {
    id: `${baseId}.covid_flow_title`,
    defaultMessage: 'Covid-19 flow',
  },
  pre_consultation_flow_title: {
    id: `${baseId}.pre_consultation_flow_title`,
    defaultMessage: 'Pre-consultation flow',
  },
  chatflow_flow_title: {
    id: `${baseId}.chatflow_flow_title`,
    defaultMessage: 'Chatbot flow',
  },
})
