import React, { ChangeEvent } from 'react'

import { Button, FormikNumberInput, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  PAT_MET_SAVE_WEIGHT,
  PAT_MET_UPDATE_WEIGHT,
  PATIENT_METRICS,
  UPDATE_PATIENT_METRICS,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import Timestamp from '~/features/patient-metrics/utils/Timestamp'
import { PatientMetricObservationType } from '~/generated'

import {
  MeasurementType,
  usePatientMetricsState,
} from '../PatientMetricsStateProvider'
import BmiSectionHeader from './BmiSectionHeader'
import ImperialWeight from './ImperialWeight'
import UsWeight from './UsWeight'
import { validateWeight } from './utils'

import messages from '../PatientMetricsModal.messages'
import styles from '../styles.module.scss'

interface WeightFormParams {
  measurementType: MeasurementType
  onUpdate: () => void
  timestamp?: string
}

const WeightForm: React.FC<WeightFormParams> = ({
  measurementType,
  onUpdate,
  timestamp,
}) => {
  const fm = useFormatMessage()

  const {
    values: { weight },
    displayValues: { displayWeight, performerWeight },
    handleTaggedSubmit,
    setFieldValue,
    submitInfo,
    errors,
  } = usePatientMetricsState()

  const submitting = submitInfo.submitting.weight
  const mutationError = submitInfo.error.weight

  const displayWeightLabel =
    displayWeight &&
    `${displayWeight} ${fm(messages.weight_label_kg_abbreviation)}`

  const handleClick = async () => {
    try {
      await handleTaggedSubmit(PatientMetricObservationType.Weight)
      onUpdate()
      setFieldValue('weight', null)

      analytics.trackEvent({
        label: displayWeight ? PAT_MET_UPDATE_WEIGHT : PAT_MET_SAVE_WEIGHT,
        category: PATIENT_METRICS,
        action: UPDATE_PATIENT_METRICS,
      })
    } catch (e) {
      // NOOP
    }
  }

  return (
    <div data-testid="patient-metrics-weight-form">
      <Grid
        templateColumns="3fr 1fr"
        justifyContent="center"
        className={styles.topMargin}
      >
        <Grid columns={1} templateRows="30px 65px" flow="column" columnGap={40}>
          {measurementType === MeasurementType.metric && (
            <>
              <BmiSectionHeader
                label={fm(messages.weight_section_label)}
                htmlFor="weight"
              >
                {displayWeightLabel}
                <Timestamp
                  timestamp={timestamp}
                  performer={performerWeight}
                  className={styles.timestamp}
                />
              </BmiSectionHeader>
              <Grid columns={5}>
                <FormikNumberInput
                  id="weight"
                  label={fm(messages.weight_label_kg)}
                  name="weight"
                  placeholder={fm(messages.weight_placeholder_kg)}
                  validate={validateWeight(fm)}
                  autoComplete="off"
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    const eventValue = event.target.value

                    setFieldValue(
                      event.target.name,
                      eventValue === ''
                        ? undefined
                        : Number(eventValue).toFixed(1)
                    )
                  }}
                />
              </Grid>
            </>
          )}
          {measurementType === MeasurementType.us && (
            <UsWeight timestamp={timestamp} />
          )}
          {measurementType === MeasurementType.imperial && (
            <ImperialWeight timestamp={timestamp} />
          )}
        </Grid>
        <div className={styles.submitButton}>
          <Button
            style={{ minWidth: '90px' }}
            type="button"
            loading={submitting}
            disabled={!weight || !!errors.weight}
            data-testid="weight-submit-button"
            onClick={handleClick}
          >
            {displayWeight
              ? fm(messages.submit_button_update_label)
              : fm(messages.submit_button_save_label)}
          </Button>
        </div>
      </Grid>
      {mutationError && (
        <div className={styles.mutationErrorMessage}>
          <Text color="error">
            {fm(messages.error_patient_metrics_mutation_failure)}
          </Text>
        </div>
      )}
    </div>
  )
}

export default WeightForm
