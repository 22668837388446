import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './styles.module.scss'
import messages from './UnknownWorkflows.messages'

const UnknownWorkflows = ({ count }) => {
  return (
    <div className={styles.unknownWorkflows}>
      <div className={styles.top}>
        <FormattedMessage
          {...messages.message_count}
          values={{
            count,
            displayCount: <span className={styles.count}>{count}</span>,
          }}
        />
      </div>
      <div className={styles.bottom}>
        <FormattedMessage
          {...messages.details_not_available}
          values={{ count }}
        />
      </div>
    </div>
  )
}

export default UnknownWorkflows
