import React, { useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import {
  SECTION_CATEGORY,
  SECTION_SHOW_LESS_BUTTON_ACTION,
  SECTION_SHOW_MORE_BUTTON_ACTION,
  SECTION_SHOW_MORE_UNKNOWN_LABEL,
} from '~/constants/analytics'
import analytics from '~/core/analytics'

import SectionShowMoreButton from './SectionShowMoreButton'

import styles from './styles.module.scss'

interface SectionExpanderProps {
  title: React.ReactNode
  children: React.ReactNode
  startExpanded?: boolean
  showMoreMessage?: React.ReactNode
  showLessMessage?: React.ReactNode
}

const SectionExpander = ({
  title,
  children,
  startExpanded = false,
  showMoreMessage,
  showLessMessage,
}: SectionExpanderProps) => {
  const [expanded, setExpanded] = useState(startExpanded)
  const { height, ref } = useResizeObserver<HTMLDivElement>()

  const toggleExpanded = () => {
    analytics.trackEvent({
      category: SECTION_CATEGORY,
      action: expanded
        ? SECTION_SHOW_LESS_BUTTON_ACTION
        : SECTION_SHOW_MORE_BUTTON_ACTION,
      label:
        typeof title === 'string' ? title : SECTION_SHOW_MORE_UNKNOWN_LABEL,
    })
    setExpanded(!expanded)
  }

  return (
    <>
      {children && (
        <div
          data-testid="more-info"
          className={styles.moreInfo}
          style={{
            maxHeight: expanded ? height : 0,
            visibility: expanded ? 'visible' : 'hidden',
          }}
        >
          <div ref={ref}>{children}</div>
        </div>
      )}

      <SectionShowMoreButton
        expanded={expanded}
        onClick={() => toggleExpanded()}
        showMoreMessage={showMoreMessage}
        showLessMessage={showLessMessage}
      />
    </>
  )
}

export default SectionExpander
