import React from 'react'

import sanitize from '~/core/sanitize'
import { EhrConsultationNotesQueryResult } from '~/generated'
import { Section } from '~/ui/Section'

export interface USEhrSummaryProps {
  usEhrNote: NonNullable<
    NonNullable<
      NonNullable<EhrConsultationNotesQueryResult['data']>['consultation']
    >['usEhrNote']
  >
}

const USEhrSummary = ({ usEhrNote }: USEhrSummaryProps) => {
  return (
    <Section dataTestId="cr-us-ehr">
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(usEhrNote.htmlSummary),
        }}
      />
    </Section>
  )
}

export default USEhrSummary
