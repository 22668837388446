import { defineMessages } from 'react-intl'

const baseId = 'prescription_modal.drug_select.select_value'

export default defineMessages({
  blacklisted_tag_value: {
    id: `${baseId}.blacklisted_label`,
    defaultMessage: 'blacklisted',
  },
  expensive_tag_value: {
    id: `${baseId}.expensive_label`,
    defaultMessage: 'expensive',
  },
  controlled_tag_value: {
    id: `${baseId}.controlled_tag_value`,
    defaultMessage: 'CD',
  },
  generic_tag_value: {
    id: `${baseId}.generic_tag_value`,
    defaultMessage: 'generic',
  },
  branded_tag_value: {
    id: `${baseId}.branded_tag_value`,
    defaultMessage: 'branded',
  },
})
