import React from 'react'

import { Button, ResponsiveTable } from '@babylon/core-ui'
import EmptyState from './EmptyState'
import LoadingState from './LoadingState'
import ErrorState from './ErrorState'

import { isFilledArray } from '../../utils'

const columns = ({ activeTab, onReviewReport }) => {
  const linkLabel = activeTab === 'pending' ? 'Review' : 'View'

  const defaultColumns = [
    {
      accessor: 'name',
      label: 'Test name',
      show: () => true,
    },
    {
      accessor: 'patientId',
      label: 'Patient ID',
      show: () => true,
    },
    {
      accessor: 'createdAt',
      label: 'Received at',
      show: () => true,
    },
    {
      accessor: 'reviewedAt',
      label: 'Reviewed at',
      show: (tab) => tab === 'errored',
    },
    {
      accessor: 'sentAt',
      label: 'Sent at',
      show: (tab) => tab === 'reviewed',
    },
    {
      accessor: 'appointmentSuggested',
      label: 'Appointment suggested',
      show: (tab) => tab === 'reviewed',
    },
    {
      accessor: (report) => (
        <Button onClick={(e) => onReviewReport(report, e)}>{linkLabel}</Button>
      ),
      label: linkLabel,
      show: () => true,
    },
  ]
  return defaultColumns.filter((col) => col.show(activeTab))
}

export default ({
  reports = [],
  loading = false,
  onReviewReport = null,
  error = null,
  activeTab = 'pending',
}) => {
  switch (true) {
    case !loading && isFilledArray(reports):
      return (
        <ResponsiveTable
          label="Test reports table"
          columns={columns({ activeTab, onReviewReport })}
          data={reports}
        />
      )
    case loading:
      return <LoadingState />
    case !!error:
      return <ErrorState />
    default:
      return <EmptyState />
  }
}
