import { defineMessages } from 'react-intl'

const baseId = 'header'

export default defineMessages({
  logo_alt_text: {
    id: `${baseId}.logo_alt_text`,
    defaultMessage: 'babylon',
  },
  main_loading_error: {
    id: `${baseId}.main_loading_error`,
    defaultMessage: 'Error loading the navigation bar',
  },
  up_next_text: {
    id: `${baseId}.up_next_text_v2`,
    defaultMessage: 'Up next: {date}',
  },
  new_at_text: {
    id: `${baseId}.new_at_text`,
    defaultMessage: 'New at: {date}',
  },
  error_loading: {
    id: `${baseId}.error_loading`,
    defaultMessage: 'Error loading',
  },
  consultations: {
    id: `${baseId}.consultations`,
    defaultMessage: 'Consultations',
  },
  connection_quality_icon_title: {
    id: `${baseId}.connection_quality_icon_title`,
    defaultMessage: 'Connection quality indicator',
  },
  connection_quality_poor_connection_alt_text: {
    id: `${baseId}.connection_quality_poor_connection_alt_text`,
    defaultMessage: 'Poor Connection',
  },
  cant_show_upcoming_consultations: {
    id: `${baseId}.cant_show_upcoming_consultations`,
    defaultMessage: "Can't show upcoming consultations right now",
  },
  opt_in_notification_text: {
    id: `${baseId}.opt_in_notification_text`,
    defaultMessage: 'Notify me of last minute bookings',
  },
})
