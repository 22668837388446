import { CLINICAL_CODE } from './constants'
import { getFocusedWord, validateClinicalCode } from './utils'

export const insertClinicalCode = (
  editor,
  { term, code, hasNotes = false }
) => {
  editor.insertInline({
    type: CLINICAL_CODE,
    data: {
      term,
      code,
      hasNotes,
    },
    nodes: [
      {
        object: 'text',
        leaves: [
          {
            text: term,
          },
        ],
      },
    ],
  })
}

export const replaceLastWordWithClinicalCode = (editor, option) => {
  const { preferredLabel: term, iri: code, hasNotes } = option

  const isValid = validateClinicalCode(term, code)
  if (!isValid) return

  const lastWord = getFocusedWord(editor.value)

  editor.deleteBackward(lastWord.length)

  insertClinicalCode(editor, {
    term,
    code,
    hasNotes,
  })

  editor.moveToEndOfInline().moveForward()
}

export const wrapSelectionWithClinicalCode = (editor, term, code, hasNotes) => {
  const isValid = validateClinicalCode(term, code)
  if (!isValid) return

  editor
    .unwrapInlineAtRange(editor.value.selection, CLINICAL_CODE)
    .wrapInlineAtRange(editor.value.selection, {
      type: CLINICAL_CODE,
      data: {
        term,
        code,
        hasNotes,
      },
    })
    .moveToEndOfInline()
    .focus()
}
