import './dotenv'
import './zendesk'
import './icons'
import './styles/globalStyles'

import React from 'react'
import ReactDOM from 'react-dom'

import { initialiseLogRocket } from '@babylon/tracking/logrocket'

import {
  APP_VERSION,
  ENABLE_PARTIALLY_SANITIZED_LOGROCKET,
  LOGROCKET_APP_ID,
} from '~/constants'

import Shell from './features/shell'

if (LOGROCKET_APP_ID) {
  initialiseLogRocket(
    LOGROCKET_APP_ID,
    APP_VERSION,
    ENABLE_PARTIALLY_SANITIZED_LOGROCKET
  )
}

const initialiseApp = async () => {
  ReactDOM.render(<Shell />, document.getElementById('root'), () => {
    const appLoaderElement = document.querySelector('#app-loader')
    appLoaderElement.parentNode.removeChild(appLoaderElement)
  })
}

initialiseApp()
