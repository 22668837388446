import React from 'react'

import { AuthClient, getAuthClient } from '@babylon/web-platform-utils-auth'
import { AuthProvider } from '@babylon/web-platform-utils-auth-react'
import { getEnvVars } from '@babylon/web-platform-utils-env-vars'
import { EnvVarProvider } from '@babylon/web-platform-utils-env-vars-react'

interface AuthenticationProps {
  children: React.ReactNode
}
let authClient: AuthClient

export const OpenIDAuthProvider = ({ children }: AuthenticationProps) => {
  const envVars = getEnvVars(undefined, undefined, true)

  if (!authClient) {
    authClient = getAuthClient(true)
  }

  return (
    <EnvVarProvider envVars={envVars}>
      <AuthProvider authClient={authClient}>{children}</AuthProvider>
    </EnvVarProvider>
  )
}
