import React, { Component } from 'react'

import { generateIndex } from '~/core'

import DialogComponent from './DialogComponent'
import DialogPortal from './DialogPortal'

const Dialog = {
  // Initialized properties to stop Typescript from complaining
  // TODO: Refactor with Typescript
  show: (args) => args,
  hide: (id) => id,
}

const constructDialogHelper = (component) => {
  if (Dialog.initialized) {
    return
  }

  Dialog.show = (args) =>
    component.pushToQueue({
      id: generateIndex(),
      ...args,
    })

  Dialog.hide = (id) => {
    component.removeFromQueue(id)
  }
}

class DialogProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      queue: [],
    }

    constructDialogHelper(this)
  }

  pushToQueue = (dialog) => {
    const { state } = this
    const { id } = dialog

    this.setState({
      queue: [...state.queue, dialog],
    })

    return {
      id,
      hide: () => this.removeFromQueue(id),
    }
  }

  removeFromQueue = (id) => {
    const { queue } = this.state

    this.setState({
      queue: queue.filter((v) => v.id !== id),
    })
  }

  render() {
    const { queue } = this.state
    const last = queue[queue.length - 1]

    if (last) {
      const Component = last.component || DialogComponent

      return (
        <DialogPortal>
          <Component {...last} onHideRequest={this.removeFromQueue} />
        </DialogPortal>
      )
    }

    return null
  }
}

export { Dialog, DialogProvider, DialogComponent }
