import { gql } from '@apollo/client'

export const PanelFragment = gql`
  fragment PanelFragment on TestReport {
    id
    name
    version
    createdOn
    reviewedOn
    sentOn
    patientId
    appointmentSuggested
    labName
    state
  }
`

export const ProfilesFragment = gql`
  fragment ProfilesFragment on TestReport {
    profiles {
      code
      name
      reviewerComments
      additionalInfo {
        title
        value
        comments
        labNotes
      }
      biomarkers {
        code
        description
        name
        valueNumeric
        valuePercentage
        valueString
        failed
        failureReason
        units
        flag
        notes
        recognisedBiomarker
        recognisedValue
        ranges {
          upper
          lower
          labRange
        }
      }
    }
  }
`

export const PanelsQuery = gql`
  query PanelsQuery($size: Int, $page: Int, $state: String!, $sort: String) {
    getPanels(size: $size, page: $page, state: $state, sort: $sort) {
      pageIndex
      totalPages
      itemsPerPage
      items {
        ...PanelFragment
      }
    }
  }
  ${PanelFragment}
`

export const PanelQuery = gql`
  query PanelQuery($id: String) {
    getPanel(id: $id) {
      ...PanelFragment
      ...ProfilesFragment
      patient {
        firstName
        lastName
        gender
        dateOfBirth
        age
        email
        phoneNumber
        phoneCountryCode
      }
      labPatient {
        firstName
        lastName
        gender
        dateOfBirth
        age
      }
      sender {
        fullName
      }
      reviewer {
        fullName
      }
      supportMessage
      reviewerComments
    }
  }
  ${PanelFragment}
  ${ProfilesFragment}
`
