import { defineMessages } from 'react-intl'

const baseId = 'workflow_definition_field'

export default defineMessages({
  validation_required: {
    id: `${baseId}.validation_required`,
    defaultMessage: 'This field is required',
  },
  validation_numeric: {
    id: `${baseId}.validation_numeric`,
    defaultMessage: 'This field can only contain numbers',
  },
  validation_decimal: {
    id: `${baseId}.validation_decimal`,
    defaultMessage: 'This field can only contain numbers and decimals',
  },
  validation_incorrect_value: {
    id: `${baseId}.validation_incorrect_value`,
    defaultMessage: 'The value entered for this field is incorrect',
  },
  validation_multiline_charLimit: {
    id: `${baseId}.validation_multiline_charLimit`,
    defaultMessage:
      'The character limit of 4000 characters for this field has been exceeded',
  },
  boolean_yes: {
    id: `${baseId}.boolean_yes`,
    defaultMessage: 'Yes',
  },
  boolean_no: {
    id: `${baseId}.boolean_no`,
    defaultMessage: 'No',
  },
  load_options_error: {
    id: `${baseId}.load_options_error`,
    defaultMessage: 'Unable to load dropdown options',
  },
  coding_system_error_message: {
    id: `${baseId}.coding_system_error_message`,
    defaultMessage: 'Invalid coding system {codingSystem}',
  },
})
