import React from 'react'

import { Button, Spinner, Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import {
  APPOINTMENT_INVITES_CATEGORY,
  APPOINTMENT_INVITES_CREATE_MODAL_CANCEL_ACTION,
  APPOINTMENT_INVITES_CREATE_MODAL_CANCEL_LABEL,
  APPOINTMENT_INVITES_CREATE_MODAL_CONFIRM_ACTION,
  APPOINTMENT_INVITES_CREATE_MODAL_CONFIRM_LABEL,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useMessages } from '~/core/hooks'
import { CreateInviteV3Mutation, RecurrencePatterns } from '~/generated'
import { Dialog } from '~/ui/Modal'

import {
  getRecurringDatesArray,
  useTrackIncompleteBookingsDialog,
} from './utils'

import messages from './AppointmentInvitesModal.messages'
import styles from './styles.module.scss'

type AppointmentInvitesRecurrenceDialogProps = {
  onClose: () => void
  onFinish: () => void
  handleSubmit: () => void
  recurrenceCount: number
  cadenceValue: RecurrencePatterns | null
  startDateTime: Date
  loading?: boolean
  inviteResult?: CreateInviteV3Mutation['createAppointmentInviteV3']
}

type RecurrenceDialogFooterProps = Partial<
  Pick<AppointmentInvitesRecurrenceDialogProps, 'onClose' | 'handleSubmit'>
> & {
  ok?: boolean
  cancel?: boolean
  onOK?: () => void
  onCancel?: () => void
}

const RecurrenceDialogFooter = ({
  cancel,
  ok,
  handleSubmit,
  onClose,
  onOK,
  onCancel,
}: RecurrenceDialogFooterProps) => {
  const f = useMessages(messages)

  return (
    <div className={styles.footer}>
      <div className={styles.controls}>
        {cancel && (
          <Button
            intent="secondary"
            className={styles.button}
            onClick={() => {
              onClose?.()
              onCancel?.()
            }}
          >
            {f('dialog_recurring_cancel_label')}
          </Button>
        )}
        {ok && (
          <Button intent="primary" onClick={onClose}>
            {f('dialog_recurring_ok_label')}
          </Button>
        )}
        {handleSubmit && (
          <Button
            onClick={() => {
              handleSubmit()
              onOK?.()
            }}
            intent="primary"
          >
            {f('dialog_recurring_submit_label')}
          </Button>
        )}
      </div>
    </div>
  )
}

const RecurrenceDialogResult = ({
  onClose,
  inviteResult,
}: Pick<
  AppointmentInvitesRecurrenceDialogProps,
  'onClose' | 'inviteResult'
>) => {
  const f = useMessages(messages)
  const intl = useIntl()
  const incompleteBookings = inviteResult?.errors?.map((x) => new Date(x.date))
  useTrackIncompleteBookingsDialog(incompleteBookings)

  return (
    <>
      <Text className={styles.dialogRecurrenceMessage}>
        {f(
          !incompleteBookings?.length
            ? 'dialog_recurring_message_confirm'
            : 'dialog_recurring_message_incomplete'
        )}
      </Text>

      {incompleteBookings?.map((x) => (
        <Text key={intl.formatDate(x)} className={styles.dialogDateTime}>
          {intl.formatDate(x, { format: 'shortWithTime' })}
        </Text>
      ))}

      <RecurrenceDialogFooter onClose={onClose} ok />
    </>
  )
}

const RecurrenceDialogPreview = ({
  recurrenceCount,
  cadenceValue,
  startDateTime,
  onClose,
  handleSubmit,
  loading,
}: Pick<
  AppointmentInvitesRecurrenceDialogProps,
  | 'recurrenceCount'
  | 'cadenceValue'
  | 'startDateTime'
  | 'onClose'
  | 'handleSubmit'
  | 'loading'
>) => {
  const intl = useIntl()
  const f = useMessages(messages)
  if (loading) {
    return <Spinner size="medium" centered />
  }
  const formatDateTime = (date: Date) =>
    intl.formatDate(date, { format: 'shortWithTime' })
  const intervalValue = cadenceValue?.interval ?? 1
  const recurringDates = getRecurringDatesArray(
    recurrenceCount,
    intervalValue,
    startDateTime,
    formatDateTime
  )

  const onOK = () =>
    analytics.trackEvent({
      category: APPOINTMENT_INVITES_CATEGORY,
      action: APPOINTMENT_INVITES_CREATE_MODAL_CONFIRM_ACTION,
      label: `${APPOINTMENT_INVITES_CREATE_MODAL_CONFIRM_LABEL}${recurringDates.join(
        '. '
      )}`,
    })

  const onCancel = () =>
    analytics.trackEvent({
      category: APPOINTMENT_INVITES_CATEGORY,
      action: APPOINTMENT_INVITES_CREATE_MODAL_CANCEL_ACTION,
      label: `${APPOINTMENT_INVITES_CREATE_MODAL_CANCEL_LABEL}${recurringDates.join(
        '. '
      )}`,
    })

  return (
    <>
      <Text className={styles.dialogRecurrenceMessage}>
        {f('dialog_recurring_message')}
      </Text>

      {recurringDates.map((appointmentDateTime) => (
        <Text key={appointmentDateTime} className={styles.dialogDateTime}>
          {appointmentDateTime}
        </Text>
      ))}

      <RecurrenceDialogFooter
        onClose={onClose}
        onOK={onOK}
        onCancel={onCancel}
        handleSubmit={handleSubmit}
        cancel
      />
    </>
  )
}

const AppointmentInvitesRecurrenceDialog = ({
  onClose,
  onFinish,
  handleSubmit,
  recurrenceCount,
  cadenceValue,
  startDateTime,
  inviteResult,
  loading,
}: AppointmentInvitesRecurrenceDialogProps) => {
  const f = useMessages(messages)

  const titleResult = inviteResult?.errors?.length
    ? 'dialog_recurring_title_incomplete'
    : 'dialog_recurring_title_confirm'

  return (
    <Dialog
      onClose={() => {
        onClose()
        if (inviteResult) {
          onFinish()
        }
      }}
      title={f(inviteResult ? titleResult : 'dialog_recurring_title')}
      className={styles.dialog}
    >
      {!inviteResult && (
        <RecurrenceDialogPreview
          recurrenceCount={recurrenceCount}
          cadenceValue={cadenceValue}
          startDateTime={startDateTime}
          onClose={onClose}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}
      {inviteResult && (
        <RecurrenceDialogResult
          inviteResult={inviteResult}
          onClose={() => {
            onClose()
            onFinish()
          }}
        />
      )}
    </Dialog>
  )
}

export default AppointmentInvitesRecurrenceDialog
