import { useState, useEffect } from 'react'

const getSize = () => ({
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
})

const useWindowSize = () => {
  let [windowSize, setWindowSize] = useState(getSize())
  let timeout // https://gomakethings.com/debouncing-events-with-requestanimationframe-for-better-performance/

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resize = () => {
    setWindowSize(getSize())

    if (timeout) {
      window.cancelAnimationFrame(timeout)
    }

    timeout = window.requestAnimationFrame(() => {})
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [resize])

  return windowSize
}

export default useWindowSize
