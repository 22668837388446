import { useCallback } from 'react'

import { useBeforeMount } from '~/core/hooks'

import { OTEvent, OTEventHandlerObject } from '../useOpentok'
import useQueue from './useQueue'
import { ConnectionEvent, formatCallEvent } from './utils'

interface IuseEventMonitorQueue {
  addSessionEventHandlers: (eventHandlers: OTEventHandlerObject) => void
  addSubscriberEventHandlers: (eventHandlers: OTEventHandlerObject) => void
}

const useEventMonitorQueue = ({
  addSessionEventHandlers,
  addSubscriberEventHandlers,
}: IuseEventMonitorQueue) => {
  const { pushToQueue, consumeQueue } = useQueue<ConnectionEvent>()

  const pushEventToQueue = useCallback(
    (event?: OTEvent) => {
      const formattedEvent = formatCallEvent(event)
      pushToQueue(formattedEvent)
    },
    [pushToQueue]
  )

  useBeforeMount(() => {
    addSessionEventHandlers({
      sessionConnected: pushEventToQueue,
      sessionDisconnected: pushEventToQueue,
      streamCreated: pushEventToQueue,
      streamDestroyed: pushEventToQueue,
    })
    addSubscriberEventHandlers({
      videoDisableWarning: pushEventToQueue,
      videoDisableWarningLifted: pushEventToQueue,
      videoDisabled: pushEventToQueue,
      videoEnabled: pushEventToQueue,
    })
  })

  return consumeQueue
}

export default useEventMonitorQueue
