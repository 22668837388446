import get from 'lodash/get'

import { SpecialismCategory, useSpecialismsQuery } from '~/generated'

const useSpecialisms = (consultationId: string) => {
  const { loading, error, data } = useSpecialismsQuery({
    variables: {
      consultationId,
    },
  })

  // @ts-ignore
  const categories: SpecialismCategory[] = get(data, 'specialismCategories', [])

  const getCategoryName = (id: string) => {
    return categories?.length && id
      ? categories.find((category) => category.id.toString() === id.toString())
          ?.name
      : null
  }

  return {
    loading,
    error,
    categories,
    getCategoryName,
  }
}

export default useSpecialisms
