import { defineMessages } from 'react-intl'

const baseId = 'components.appointment_invites'

export default defineMessages({
  section_title: {
    id: `${baseId}.section_title`,
    defaultMessage: 'Appointments',
  },
  create_button_label: {
    id: `${baseId}.create_button_label`,
    defaultMessage: 'Create',
  },
  invites_error: {
    id: `${baseId}.invites_error`,
    defaultMessage: "We can't load appointments right now",
  },
})
