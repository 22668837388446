import qs from 'qs'
import { withRouter } from 'react-router'
import { compose, shallowEqual, shouldUpdate, withProps } from 'recompose'

export const shouldRouteUpdate = shouldUpdate(
  (props, nextProps) =>
    !shallowEqual(props.match.params, nextProps.match.params) ||
    props.match.url !== nextProps.match.url
)

const withEnhancedRouter = compose(
  withRouter,
  withProps((props) => {
    const { search } = props.location
    const query = search.charAt(0) === '?' ? search.slice(1) : search

    return {
      query: qs.parse(query),
    }
  })
)

export default withEnhancedRouter
