import cn from 'classnames'
import qs from 'qs'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'

import { Spinner } from '@babylon/core-ui'
import { BabylonTimeAgo } from '@babylon/intl'
import { pickTruthyValues } from '@babylon/shared-utils'

import { ReactComponent as NoShowIcon } from '~/assets/noshow.svg'
import { ReactComponent as IncompleteIcon } from '~/assets/timeline/incomplete_cons.svg'
import {
  PATIENT_TIMELINE_CATEGORY,
  SEE_TIMELINE_EVENT_ACTION,
} from '~/constants/analytics'
import { camelToKebab } from '~/core'
import analytics from '~/core/analytics'
import {
  ChatscriptConversation,
  PatientTimelineConsultationFragmentFragment,
  TestReport,
} from '~/generated'
import Link from '~/ui/Link'

import { isEventInFuture } from '../../utils'
import TimelineIcon from './TimelineIcon'

import styles from './styles.module.scss'

const getLinkURL = (
  eventType: Event['eventType'],
  url: string,
  queryParams: {
    eventId: string
    patientId?: string
    patientUuid?: string
  }
) => {
  if (!eventType) return ''
  const query = qs.stringify(pickTruthyValues(queryParams), {
    addQueryPrefix: true,
  })
  return `${url}/history/${camelToKebab(eventType)}${query}`
}

const EventTitle = ({
  title,
  loading,
}: {
  title: React.ReactNode
  loading?: boolean
}) => (
  <div>
    <span data-testid="event-title" className={styles.title}>
      {title}
    </span>
    {loading && <Spinner size="small" testid="timeline-event-loading" />}
  </div>
)

const EventDate = ({
  className,
  eventTime,
  includeRelativeDate = true,
}: {
  className: string
  eventTime: Event['eventTime']
  includeRelativeDate?: boolean
}) => {
  const isFutureEvent = isEventInFuture(eventTime)
  return (
    <div
      className={cn(styles.date, className)}
      data-testid="consultation-event-date"
    >
      <div
        className={cn(styles.dateFormatted, className, {
          [styles.future]: isFutureEvent,
        })}
        data-testid="date"
      >
        <FormattedDate value={eventTime} format="short" />
      </div>
      {includeRelativeDate && (
        <div
          className={cn(styles.dateFromNow, className)}
          data-testid="relative-date"
        >
          <BabylonTimeAgo date={eventTime} />
        </div>
      )}
    </div>
  )
}

export type Event =
  | ChatscriptConversation
  | PatientTimelineConsultationFragmentFragment
  | TestReport

export interface TimelineEventProps {
  loading?: boolean
  title: React.ReactNode
  event: Event
  patientId?: string
  patientUuid?: string
  children: React.ReactNode
  url?: string
  className?: string
  dataTestId?: string
  isCurrentConsultation?: boolean
  isIncomplete?: boolean
  isNoShow?: boolean
}

const TimelineEvent = ({
  loading,
  title,
  event,
  patientId,
  patientUuid,
  children,
  url: urlProp,
  className,
  dataTestId,
  isCurrentConsultation,
  isIncomplete,
  isNoShow,
}: TimelineEventProps) => {
  const match = useRouteMatch()
  const url =
    urlProp ||
    getLinkURL(event.eventType, match.url, {
      eventId: event.id,
      patientId,
      patientUuid,
    })
  const trackEvent = analytics.trackEventFactory({
    category: PATIENT_TIMELINE_CATEGORY,
  })

  const eventClassname = cn(
    styles.event,
    styles[
      event.__typename === 'Consultation'
        ? event.consultationType
        : event.eventType || ''
    ]
  )

  const handleLinkClick = ({ id, eventType }: Event) => {
    trackEvent({
      action: `${SEE_TIMELINE_EVENT_ACTION}_${eventType}${
        eventType === 'Consultation'
          ? `_${
              (event as PatientTimelineConsultationFragmentFragment)
                .consultationType
            }`
          : ''
      }`,

      label: id,
    })
  }

  return isCurrentConsultation ? (
    <div>
      <EventDate
        eventTime={event.eventTime}
        includeRelativeDate={false}
        className={styles.currentConsultationDate}
      />
      <div className={cn(eventClassname, styles.currentConsultation)}>
        {isIncomplete ? (
          <IncompleteIcon data-testid="incomplete-icon" />
        ) : isNoShow ? (
          <NoShowIcon
            width="16"
            height="16"
            className={styles.noShowIcon}
            data-testid="consultation-no-show-icon"
          />
        ) : (
          <TimelineIcon
            consultationType={
              event.__typename === 'Consultation' ? event.consultationType : ''
            }
            timelineEventType={event.eventType}
          />
        )}
        <div className={styles.eventHeader}>
          <EventTitle title={title} loading={loading} />
        </div>
      </div>
    </div>
  ) : (
    <Link
      to={url}
      target="_blank"
      className={styles.link}
      onClick={() => handleLinkClick(event)}
      dataTestId={dataTestId}
    >
      <EventDate
        eventTime={event.eventTime}
        className={cn(styles.consultationDate, isNoShow && styles.noShowDate)}
      />
      <div
        className={cn(
          eventClassname,
          className,
          isNoShow && styles.noShow,
          isIncomplete && styles.incomplete
        )}
      >
        {isIncomplete ? (
          <IncompleteIcon data-testid="incomplete-icon" />
        ) : isNoShow ? (
          <NoShowIcon
            width="16"
            height="16"
            className={styles.noShowIcon}
            data-testid="consultation-no-show-icon"
          />
        ) : (
          <TimelineIcon
            consultationType={
              event.__typename === 'Consultation' ? event.consultationType : ''
            }
            timelineEventType={event.eventType}
          />
        )}
        <div>
          <EventTitle title={title} loading={loading} />
          {children}
        </div>
      </div>
    </Link>
  )
}

export default TimelineEvent
