import React, { useEffect, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useScrollContext } from './ScrollProvider'

const DEFAULT_THRESHOLD = [0.6, 0.65, 1]

const ScrollElement = ({
  name,
  children,
  threshold = DEFAULT_THRESHOLD,
  ...restOfProps
}) => {
  const { setActiveKey, scrollablePanelRef } = useScrollContext()

  const memoizedThreshold = useMemo(() => threshold, [threshold])

  const [ref, inView, entry] = useInView({
    root: (scrollablePanelRef && scrollablePanelRef.current) || null,
    threshold: memoizedThreshold,
  })
  const intersectionRatio = entry ? entry.intersectionRatio : 0
  const intersectionRatioRef = useRef(intersectionRatio)

  useEffect(() => {
    if (typeof setActiveKey !== 'function') {
      throw new Error('setActiveKey is not a function')
    }

    if (inView && intersectionRatio > intersectionRatioRef.current) {
      setActiveKey(name)
    }

    intersectionRatioRef.current = intersectionRatio
  }, [intersectionRatio, inView, name, setActiveKey])

  return (
    <div {...restOfProps} ref={ref} data-scroll-element={name}>
      {children}
    </div>
  )
}

export default ScrollElement
