import { useQuery } from '@apollo/client'
import React from 'react'

import { BabylonUserProvider } from '@babylon/babylon-user'
import { spinnerLoaderInstance } from '@babylon/core-ui'
import * as Access from '@babylon/web-platform-access'
import { keepAliveAuthSession } from '@babylon/web-platform-utils-auth-session'

import {
  ENABLE_NEW_AUTH,
  ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION,
} from '~/constants'
import { client as apolloClient } from '~/core/apollo'
import { useMessages } from '~/core/hooks'
import ErrorPage from '~/ui/ErrorPage'
import UserAuthorizationError from '~/ui/UserAuthorizationError'

import { CoreRubyBasedAuthProvider } from './CoreRubyBasedAuthProvider'
import { OpenIDAuthProvider } from './OpenIDAuthProvider'

import messages from './Authentication.messages'

interface AuthenticationProps {
  children: React.ReactNode
}

type ApplicationAuthorizationProps = {
  children: React.ReactNode
}

export const ApplicationAuthorization = ({
  children,
}: ApplicationAuthorizationProps) => {
  const f = useMessages(messages)

  const [canAccessClinicalPortal, error, loading] = Access.useDecide(
    'clinical-portal'
  )

  if (loading) {
    return null
  }

  if (error) {
    return <ErrorPage title={f('authorization_failed')} />
  }

  return canAccessClinicalPortal ? (
    <>{children}</>
  ) : (
    <UserAuthorizationError redirectTo="admin_portal" />
  )
}

const Authentication = ({ children }: AuthenticationProps) => {
  /*
   we should remove the ENABLE_NEW_AUTH flag and old auth when migration is complete , see:
   babylonpartners.atlassian.net/wiki/spaces/CIT/pages/4241555535/Portals+Open+ID+switchover
  */
  const AuthProvider = ENABLE_NEW_AUTH
    ? OpenIDAuthProvider
    : CoreRubyBasedAuthProvider

  const f = useMessages(messages)

  return (
    <AuthProvider>
      <BabylonUserProvider consultant useQuery={useQuery}>
        {({ loading, error, user }) => {
          if (loading) {
            return spinnerLoaderInstance
          }

          if (error || !user) {
            return <ErrorPage title={f('authentication_failed')} />
          }

          // this needs to be called only after we are authenticated as would otherwise cause a failure in the redirect flow.
          if (ENABLE_NEW_AUTH) {
            keepAliveAuthSession(true)
          }
          const roles = user.roles.map(({ name }) => name)

          return (
            <Access.AccessProvider
              roles={roles}
              resourcePrefix="rn:babylon:app:portal:"
              defaultAction="view"
              apolloClient={apolloClient}
              useLegacyContext={
                !ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION
              }
            >
              <ApplicationAuthorization>{children}</ApplicationAuthorization>
            </Access.AccessProvider>
          )
        }}
      </BabylonUserProvider>
    </AuthProvider>
  )
}

export default Authentication
