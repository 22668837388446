import { zip } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

import { InsuranceClaimCheckModelInterface } from '~/core/config/modules/generated/types'
import {
  ErrorPolicy,
  Observable,
  observableState,
  replaySubject,
  setInsuranceClaimExemptedMutation,
  watchInsuranceClaimQuery,
} from '~/core/reactive-helpers'
import { logException } from '~/core/sentry'
import {
  CompleteConsultationExtensionType,
  ExtensionStateType,
  ExtensionStatus,
} from '~/features/complete-consultation'
import { ConsultationPermission, InsuranceClaimQuery } from '~/generated'

import messages from './InsuranceClaimCheckPlugin/InsuranceClaimCheck.messages'

export interface InsuranceClaimStateType extends ExtensionStateType {
  billable: boolean
  insuranceClaim: NonNullable<
    InsuranceClaimQuery['consultation']
  >['insuranceClaim']
}

export interface InsuranceClaimCheckModelType
  extends CompleteConsultationExtensionType {
  state: Observable<InsuranceClaimStateType>

  // api
  updateBillable: (value: boolean) => void
}

type QueryVariablesType = {
  appointmentId: string
  permissions: Array<ConsultationPermission>
}

const defaultState: InsuranceClaimStateType = {
  status: ExtensionStatus.Initializing,
  billable: true,
  insuranceClaim: undefined,
  errorMessage: undefined,
}

export const createInsuranceClaimCheckModel = (
  watchInsuranceClaim: typeof watchInsuranceClaimQuery,
  setInsuranceClaimExempted: typeof setInsuranceClaimExemptedMutation
): InsuranceClaimCheckModelInterface => ({
  // TODO:  convert it to a model
  consultationContext,
}) => {
  const [
    state,
    updateState,
    resetState,
  ] = observableState<InsuranceClaimStateType>(defaultState)

  const queryVariables = replaySubject<QueryVariablesType>(1)

  const reset = () => {
    resetState()
    queryVariables.next({
      appointmentId: '',
      permissions: [],
    })
  }

  consultationContext.subscribe((result) => {
    const { consultation } = result.data

    if (consultation) {
      const appointmentId = consultation?.id ?? ''
      const permissions = consultation?.permissions ?? []
      queryVariables.next({
        appointmentId,
        permissions,
      })
    } else {
      reset()
    }
  })

  let lastInitStatus: ExtensionStatus

  const init = () => {
    updateState(defaultState)

    queryVariables
      .pipe(
        take(1),
        map(({ appointmentId, permissions }) => {
          // TODO: Create permissions helper for observable streams
          const claimExemptButtonPermission = permissions.find(
            (permission) =>
              permission.name ===
              'show_claim_exempt_button_in_clinical_portal_v3'
          )

          return {
            errorPolicy: ErrorPolicy.none,
            variables: { id: appointmentId },
            skip: !claimExemptButtonPermission?.value,
          }
        }),
        watchInsuranceClaim()
      )
      .subscribe({
        next: ({ data, loading, error }) => {
          if (loading) {
            updateState({
              status: ExtensionStatus.Initializing,
              errorMessage: undefined,
            })
          } else if (error) {
            lastInitStatus = ExtensionStatus.InitError
            logException(error)
            updateState({
              status: ExtensionStatus.InitError,
              errorMessage: messages.insurance_claim_fetch_error,
            })
          } else {
            const insuranceClaim = data?.consultation?.insuranceClaim

            lastInitStatus = ExtensionStatus.Initialized
            updateState({
              status: ExtensionStatus.Initialized,
              insuranceClaim,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          logException(error)
          lastInitStatus = ExtensionStatus.InitError
          updateState({
            status: ExtensionStatus.InitError,
            errorMessage: messages.insurance_claim_fetch_error,
          })
        },
      })
  }

  const submit = () => {
    zip(state, queryVariables)
      .pipe(
        take(1),
        map(([state, queryVariables]) => ({ ...state, ...queryVariables })),
        filter(({ billable, insuranceClaim }) => {
          if (
            billable ||
            !insuranceClaim ||
            insuranceClaim.state === 'exempted'
          ) {
            updateState({
              status: ExtensionStatus.Submitted,
              errorMessage: undefined,
            })

            return false
          }

          return true
        }),
        map(({ appointmentId }) => ({
          variables: {
            id: appointmentId,
          },
        })),
        setInsuranceClaimExempted()
      )
      .subscribe({
        next: ({ error, loading }) => {
          // TODO - remove this hack by fetching the current insurance claim status
          const alreadyMarkedAsExempt = Boolean(
            error?.graphQLErrors.find(
              (err) =>
                err.extensions?.response.body.error.code ===
                'claim_state_change_invalid'
            )
          )

          if (error && !alreadyMarkedAsExempt) {
            logException(error)
            updateState({
              status: ExtensionStatus.SubmitError,
              errorMessage: messages.insurance_claim_submission_error,
            })
          } else {
            updateState({
              status: loading
                ? ExtensionStatus.Submitting
                : ExtensionStatus.Submitted,
              errorMessage: undefined,
            })
          }
        },
        error: (error) => {
          logException(error)
          updateState({
            status: ExtensionStatus.SubmitError,
            errorMessage: messages.insurance_claim_submission_error,
          })
        },
      })
  }

  const updateBillable = (billable: boolean) => {
    updateState({
      status: lastInitStatus,
      errorMessage: undefined,
      billable,
    })
  }

  return {
    state,
    init,
    submit,
    updateBillable,
  }
}

export const InsuranceClaimCheckModel = createInsuranceClaimCheckModel(
  watchInsuranceClaimQuery,
  setInsuranceClaimExemptedMutation
)
