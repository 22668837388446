import { useFormikContext } from 'formik'
import {
  AsYouType,
  CountryCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js'
import get from 'lodash/fp/get'
import React, { ChangeEvent, useState } from 'react'

import { Grid, Input, Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

export interface PhoneNumberInputProps {
  value: string
  fieldName?: string
  countryCodeFieldName?: string
  placeholder?: string
  countryCode?: string
  countryCodePlaceholder?: string
  onChange?: (value: String) => void
  onCountryCodeChange?: (value: String) => void
  className?: string
  disabled?: boolean
}

const PhoneNumberInput = ({
  value,
  fieldName = '',
  countryCodeFieldName = '',
  placeholder,
  countryCode,
  countryCodePlaceholder,
  onChange,
  onCountryCodeChange,
  className,
  disabled,
}: PhoneNumberInputProps) => {
  const { locale } = useIntl()
  const { setFieldValue, errors, touched } = useFormikContext()

  const phoneNumberField = get(fieldName)
  const phoneNumberError = phoneNumberField(errors)
  const displayPhoneNumberError =
    phoneNumberField(touched) && !!phoneNumberError
  const phoneNumberIntent = displayPhoneNumberError ? 'error' : undefined
  const countryCodeField = get(countryCodeFieldName)
  const countryCodeError = countryCodeField(errors)
  const displayCountryCodeError =
    countryCodeField(touched) && !!countryCodeError
  const countryCodeIntent = displayCountryCodeError ? 'error' : undefined

  const country = locale.split('-').pop() as CountryCode

  const formatPhoneNumber = (value: string) => {
    if (/\(.{3}\) $/.test(value)) {
      return value.replace(/[(,), ]/gi, '')
    }

    return new AsYouType(country).input(value)
  }

  const formattedValue = formatPhoneNumber(value)
  const [phoneNumber, setPhoneNumber] = useState(formattedValue)

  const getRawPhoneNumber = (number: string) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(number, country)

    if (parsedPhoneNumber) {
      return parsedPhoneNumber.nationalNumber
    }

    return number
  }

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    const formattedPhoneNumber = formatPhoneNumber(input)
    const rawPhoneNumber = getRawPhoneNumber(input)

    setPhoneNumber(formattedPhoneNumber)

    if (onChange) {
      onChange(rawPhoneNumber)
    }

    if (fieldName && setFieldValue) {
      setFieldValue(fieldName, rawPhoneNumber)
    }
  }

  const handleCountryCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (onCountryCodeChange) {
      onCountryCodeChange(input)
    }

    if (countryCodeFieldName && setFieldValue) {
      setFieldValue(countryCodeFieldName, input)
    }
  }

  return (
    <div className={className}>
      <Grid columns={1} alignItems="start">
        <Input
          placeholder={countryCodePlaceholder}
          value={countryCode || ''}
          onChange={handleCountryCodeChange}
          intent={countryCodeIntent}
          disabled={disabled}
        />
        {countryCodeError && displayCountryCodeError && (
          <Text tag="div" size="medium" color="error">
            {countryCodeError}
          </Text>
        )}
      </Grid>
      <Grid columns={1} alignItems="start">
        <Input
          placeholder={placeholder}
          value={phoneNumber || ''}
          onChange={handlePhoneNumberChange}
          intent={phoneNumberIntent}
          disabled={disabled}
        />
        {phoneNumberError && displayPhoneNumberError && (
          <Text tag="div" size="medium" color="error">
            {phoneNumberError}
          </Text>
        )}
      </Grid>
    </div>
  )
}

export default PhoneNumberInput
