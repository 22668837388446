const FIRST_CHARACTER_REGEXP = /^\w/

export const capitalize = (string: string) =>
  string
    ? string.replace(FIRST_CHARACTER_REGEXP, (char) => char.toUpperCase())
    : string

/**
 *  Checks if variable is an array and if it has at least one item
 * @param {any[] | null | undefined} array
 * @returns {boolean}
 */
export const camelToKebab = (value: string) => {
  var upperChars = value.match(/([A-Z])/g)

  if (!upperChars) {
    return value
  }

  let str = value.toString()

  for (let i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(
      new RegExp(upperChars[i]),
      `-${upperChars[i].toLowerCase()}`
    )
  }

  if (str.slice(0, 1) === '-') {
    str = str.slice(1)
  }

  return str
}

/**
 *  Concatenates list of string args with commas inbetween, filtering out null
 *  values to avoid leading/trailing commas
 */
export const stringList = (...arr: (string | null | undefined)[]): string =>
  arr.filter(Boolean).join(', ')
