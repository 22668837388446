import { graphql } from '@apollo/client/react/hoc'

import { AuditQuery } from './queries'

export default graphql(AuditQuery, {
  options: ({ prescriptionId }) => ({
    variables: {
      prescriptionId,
      eventTypes: [
        'PRESCRIPTION_VOIDED',
        'PRESCRIPTION_APPROVED',
        'PRESCRIPTION_DRAFTED',
      ],
    },
  }),
})
