import React from 'react'

import { MarkAsSensitivePluginInterface } from '~/core/config/modules/generated/types'
import { useObservable } from '~/core/reactive-helpers'

import { MarkAsSensitiveView } from './MarkAsSensitiveView'

export const MarkAsSensitiveViewModel: MarkAsSensitivePluginInterface = ({
  markAsSensitiveModel,
}) => {
  const {
    isMinor,
    sensitive,
    isConsultationIncomplete,
    noSelectionError,
  } = useObservable(markAsSensitiveModel.state, {} as any)

  return (
    // this is a vew model, there should be only one view
    // rendered here, no other Markup!!!
    <MarkAsSensitiveView
      error={noSelectionError}
      isMinor={isMinor}
      isVisible={isConsultationIncomplete}
      sensitive={sensitive}
      updateSensitive={markAsSensitiveModel.updateSensitive}
    />
  )
}
