import {
  ApolloClientInterface,
  EventBusInterface,
  InfoInterface,
  GlobalInterface,
  ConsultationContextInterface,
  AbstractModelProviderInterface,
  MainInterface,
  AdminPortalPageInterface,
  AdminPortalPageNavigationItemInterface,
  ScheduleCheckInPluginInterface,
  ConsultationCheckInPluginInterface,
  AppointmentInvitesPluginInterface,
  AppointmentInvitesSummaryPluginInterface,
  AttachmentsPluginInterface,
  AttachmentsSummaryPluginInterface,
  BookingReasonSummaryPluginInterface,
  BookingReasonPluginInterface,
  CallStatusReducerModelProviderInterface,
  CallPluginInterface,
  CarePlanSummaryPluginInterface,
  CarePlanSectionPluginInterface,
  AbstractCompleteConsultationExtensionInterface,
  CompleteConsultationModelInterface,
  CompleteDialogPluginInterface,
  EditedConsultationWarningModelProviderInterface,
  PostConsultationFormModalInterface,
  CallActiveModalPluginInterface,
  ConsultationActionsPluginInterface,
  MarkAsSensitiveModelInterface,
  MarkAsSensitivePluginInterface,
  UsEhrNotesCompletionModelInterface,
  PrescriptionPromotionModelInterface,
  InsuranceClaimCheckModelInterface,
  InsuranceClaimCheckPluginInterface,
  CarePlansCompletionModelInterface,
  MandatoryDiagnosticCodesModelInterface,
  ConsultationLitePageInterface,
  DiagnosisPluginInterface,
  DiagnosisSummaryPluginInterface,
  ExaminationSummaryPluginInterface,
  ExaminationNotesPluginInterface,
  MedicalHistoryPluginInterface,
  ExternalTabsWarningBannerPluginInterface,
  IncompleteConsultationWarningModalPluginInterface,
  ConsultationProfilePluginInterface,
  AllConsultationsPageInterface,
  AllConsultationsPageNavigationItemInterface,
  PastConsultationSummaryPluginInterface,
  CurrentConsultationSummaryPluginInterface,
  DocumentRepositoryPluginInterface,
  EhrCheckInUsPluginInterface,
  USEhrSummaryPluginInterface,
  ExperimentsPageInterface,
  GpDetailsPluginInterface,
  HeaderInterface,
  LayoutInterface,
  LinksAndResourcesPluginInterface,
  NavigationInterface,
  NhsAppointmentInvitesPluginInterface,
  NhsAppointmentInvitesSummaryPluginInterface,
  NoteAssistantSummaryPluginInterface,
  CallRecordingPluginInterface,
  PatientAlertsPluginInterface,
  PatientDetailsPluginInterface,
  PatientMetricsPluginInterface,
  PatientRecordDeepLinkPluginInterface,
  PatientSummaryPluginInterface,
  TimelineConsultationEventPluginInterface,
  PatientTimelinePluginInterface,
  PreconsultationSummaryPluginInterface,
  PreConsultationFlowPluginInterface,
  PrescriptionsPageInterface,
  PrescriptionsPageNavigationItemInterface,
  PrescriptionsPluginInterface,
  PrescriptionsSummaryPluginInterface,
  SignatureRxPinPluginInterface,
  ReferralsPluginInterface,
  ReferralsSummaryPluginInterface,
  ReleaseNotesPluginInterface,
  SchedulePageInterface,
  SchedulePageNavigationItemInterface,
  ConsultationScheduleItemPluginInterface,
  SectionGroupPluginInterface,
  RoutesInterface,
  SickNotesSummaryPluginInterface,
  SickNotesPluginInterface,
  TestsKitsPageInterface,
  TestsKitsPageNavigationItemInterface,
  TestsSectionPluginInterface,
  TestsSummaryPluginInterface,
  USEhrDeepLinkPluginInterface,
  WorkflowActionsPluginInterface,
  WorkflowActionsSummaryPluginInterface
} from '../../modules/generated/types'

type Modules = {
  ApolloClient: ApolloClientInterface
  EventBus: EventBusInterface
  Info: InfoInterface
  Global: GlobalInterface
  ConsultationContext: ConsultationContextInterface
  AbstractModelProvider: AbstractModelProviderInterface
  Main: MainInterface
  AdminPortalPage: AdminPortalPageInterface
  AdminPortalPageNavigationItem: AdminPortalPageNavigationItemInterface
  ScheduleCheckInPlugin: ScheduleCheckInPluginInterface
  ConsultationCheckInPlugin: ConsultationCheckInPluginInterface
  AppointmentInvitesPlugin: AppointmentInvitesPluginInterface
  AppointmentInvitesSummaryPlugin: AppointmentInvitesSummaryPluginInterface
  AttachmentsPlugin: AttachmentsPluginInterface
  AttachmentsSummaryPlugin: AttachmentsSummaryPluginInterface
  BookingReasonSummaryPlugin: BookingReasonSummaryPluginInterface
  BookingReasonPlugin: BookingReasonPluginInterface
  CallStatusReducerModelProvider: CallStatusReducerModelProviderInterface
  CallPlugin: CallPluginInterface
  CarePlanSummaryPlugin: CarePlanSummaryPluginInterface
  CarePlanSectionPlugin: CarePlanSectionPluginInterface
  AbstractCompleteConsultationExtension: AbstractCompleteConsultationExtensionInterface
  CompleteConsultationModel: CompleteConsultationModelInterface
  CompleteDialogPlugin: CompleteDialogPluginInterface
  EditedConsultationWarningModelProvider: EditedConsultationWarningModelProviderInterface
  PostConsultationFormModal: PostConsultationFormModalInterface
  CallActiveModalPlugin: CallActiveModalPluginInterface
  ConsultationActionsPlugin: ConsultationActionsPluginInterface
  MarkAsSensitiveModel: MarkAsSensitiveModelInterface
  MarkAsSensitivePlugin: MarkAsSensitivePluginInterface
  UsEhrNotesCompletionModel: UsEhrNotesCompletionModelInterface
  PrescriptionPromotionModel: PrescriptionPromotionModelInterface
  InsuranceClaimCheckModel: InsuranceClaimCheckModelInterface
  InsuranceClaimCheckPlugin: InsuranceClaimCheckPluginInterface
  CarePlansCompletionModel: CarePlansCompletionModelInterface
  MandatoryDiagnosticCodesModel: MandatoryDiagnosticCodesModelInterface
  ConsultationLitePage: ConsultationLitePageInterface
  DiagnosisPlugin: DiagnosisPluginInterface
  DiagnosisSummaryPlugin: DiagnosisSummaryPluginInterface
  ExaminationSummaryPlugin: ExaminationSummaryPluginInterface
  ExaminationNotesPlugin: ExaminationNotesPluginInterface
  MedicalHistoryPlugin: MedicalHistoryPluginInterface
  ExternalTabsWarningBannerPlugin: ExternalTabsWarningBannerPluginInterface
  IncompleteConsultationWarningModalPlugin: IncompleteConsultationWarningModalPluginInterface
  ConsultationProfilePlugin: ConsultationProfilePluginInterface
  AllConsultationsPage: AllConsultationsPageInterface
  AllConsultationsPageNavigationItem: AllConsultationsPageNavigationItemInterface
  PastConsultationSummaryPlugin: PastConsultationSummaryPluginInterface
  CurrentConsultationSummaryPlugin: CurrentConsultationSummaryPluginInterface
  DocumentRepositoryPlugin: DocumentRepositoryPluginInterface
  EhrCheckInUsPlugin: EhrCheckInUsPluginInterface
  USEhrSummaryPlugin: USEhrSummaryPluginInterface
  ExperimentsPage: ExperimentsPageInterface
  GpDetailsPlugin: GpDetailsPluginInterface
  Header: HeaderInterface
  Layout: LayoutInterface
  LinksAndResourcesPlugin: LinksAndResourcesPluginInterface
  Navigation: NavigationInterface
  NhsAppointmentInvitesPlugin: NhsAppointmentInvitesPluginInterface
  NhsAppointmentInvitesSummaryPlugin: NhsAppointmentInvitesSummaryPluginInterface
  NoteAssistantSummaryPlugin: NoteAssistantSummaryPluginInterface
  CallRecordingPlugin: CallRecordingPluginInterface
  PatientAlertsPlugin: PatientAlertsPluginInterface
  PatientDetailsPlugin: PatientDetailsPluginInterface
  PatientMetricsPlugin: PatientMetricsPluginInterface
  PatientRecordDeepLinkPlugin: PatientRecordDeepLinkPluginInterface
  PatientSummaryPlugin: PatientSummaryPluginInterface
  TimelineConsultationEventPlugin: TimelineConsultationEventPluginInterface
  PatientTimelinePlugin: PatientTimelinePluginInterface
  PreconsultationSummaryPlugin: PreconsultationSummaryPluginInterface
  PreConsultationFlowPlugin: PreConsultationFlowPluginInterface
  PrescriptionsPage: PrescriptionsPageInterface
  PrescriptionsPageNavigationItem: PrescriptionsPageNavigationItemInterface
  PrescriptionsPlugin: PrescriptionsPluginInterface
  PrescriptionsSummaryPlugin: PrescriptionsSummaryPluginInterface
  SignatureRxPinPlugin: SignatureRxPinPluginInterface
  ReferralsPlugin: ReferralsPluginInterface
  ReferralsSummaryPlugin: ReferralsSummaryPluginInterface
  ReleaseNotesPlugin: ReleaseNotesPluginInterface
  SchedulePage: SchedulePageInterface
  SchedulePageNavigationItem: SchedulePageNavigationItemInterface
  ConsultationScheduleItemPlugin: ConsultationScheduleItemPluginInterface
  SectionGroupPlugin: SectionGroupPluginInterface
  Routes: RoutesInterface
  SickNotesSummaryPlugin: SickNotesSummaryPluginInterface
  SickNotesPlugin: SickNotesPluginInterface
  TestsKitsPage: TestsKitsPageInterface
  TestsKitsPageNavigationItem: TestsKitsPageNavigationItemInterface
  TestsSectionPlugin: TestsSectionPluginInterface
  TestsSummaryPlugin: TestsSummaryPluginInterface
  USEhrDeepLinkPlugin: USEhrDeepLinkPluginInterface
  WorkflowActionsPlugin: WorkflowActionsPluginInterface
  WorkflowActionsSummaryPlugin: WorkflowActionsSummaryPluginInterface
}

type ModuleNames = keyof Modules

type ModuleReturnType<Name extends ModuleNames> = Parameters<Modules[Name]>[0]

type ProviderProps = {
  children: React.ReactNode
  value?: any
}

interface ModelProvider<T = any> {
  (): T
  Provider: (props: ProviderProps) => JSX.Element
}

type ModuleConfig<Name extends ModuleNames> = {
  module: Name
  options?: ModuleReturnType<Name>
  providers?: ModelProvider[]
  reactElement?: boolean
  reactComponent?: boolean
}

type ModuleConfigMap = { [Name in ModuleNames]: ModuleConfig<Name> }

type ModuleConfigUnion = ModuleConfigMap[keyof ModuleConfigMap]

export type Config = Record<string, ModuleConfigUnion>

// NOTE: this bit is dynamically generated (requires config keys -> module name):
type ConfigReferences = {
  global: ReturnType<GlobalInterface>,
  eventBus: ReturnType<EventBusInterface>,
  apolloClient: ReturnType<ApolloClientInterface>,
  consultationContext: ReturnType<ConsultationContextInterface>,
  main: ReturnType<MainInterface>,
  layout: ReturnType<LayoutInterface>,
  routes: ReturnType<RoutesInterface>,
  header: ReturnType<HeaderInterface>,
  headerScheduleItem: ReturnType<ConsultationScheduleItemPluginInterface>,
  navigation: ReturnType<NavigationInterface>,
  schedulePage: ReturnType<SchedulePageInterface>,
  experimentsPage: ReturnType<ExperimentsPageInterface>,
  consultationScheduleItem: ReturnType<ConsultationScheduleItemPluginInterface>,
  consultationScheduleItemAppointmentCheckIn: ReturnType<ScheduleCheckInPluginInterface>,
  schedulePageNavigationItem: ReturnType<SchedulePageNavigationItemInterface>,
  allConsultationsPage: ReturnType<AllConsultationsPageInterface>,
  allConsultationsPageNavigationItem: ReturnType<AllConsultationsPageNavigationItemInterface>,
  prescriptionsPage: ReturnType<PrescriptionsPageInterface>,
  prescriptionsPageNavigationItem: ReturnType<PrescriptionsPageNavigationItemInterface>,
  testsKitsPage: ReturnType<TestsKitsPageInterface>,
  testsKitsPageNavigationItem: ReturnType<TestsKitsPageNavigationItemInterface>,
  adminPortalPage: ReturnType<AdminPortalPageInterface>,
  adminPortalPageNavigationItem: ReturnType<AdminPortalPageNavigationItemInterface>,
  editedConsultationWarningModelProvider: ReturnType<EditedConsultationWarningModelProviderInterface>,
  consultationPage: ReturnType<ConsultationLitePageInterface>,
  callActiveModalPlugin: ReturnType<CallActiveModalPluginInterface>,
  consultationActionsPlugin: ReturnType<ConsultationActionsPluginInterface>,
  postConsultationFormModal: ReturnType<PostConsultationFormModalInterface>,
  callStatusReducerModelProvider: ReturnType<CallStatusReducerModelProviderInterface>,
  call: ReturnType<CallPluginInterface>,
  consultationProfile: ReturnType<ConsultationProfilePluginInterface>,
  patientRecordDeepLink: ReturnType<PatientRecordDeepLinkPluginInterface>,
  consultationProfileAppointmentCheckIn: ReturnType<ConsultationCheckInPluginInterface>,
  noteAssistantSummary: ReturnType<NoteAssistantSummaryPluginInterface>,
  incompleteConsultationWarningModal: ReturnType<IncompleteConsultationWarningModalPluginInterface>,
  externalTabsWarningBanner: ReturnType<ExternalTabsWarningBannerPluginInterface>,
  carePlanSectionGroup: ReturnType<SectionGroupPluginInterface>,
  carePlanSection: ReturnType<CarePlanSectionPluginInterface>,
  preparationSectionGroup: ReturnType<SectionGroupPluginInterface>,
  bookingReason: ReturnType<BookingReasonPluginInterface>,
  patientAlerts: ReturnType<PatientAlertsPluginInterface>,
  patientSummary: ReturnType<PatientSummaryPluginInterface>,
  documentRepository: ReturnType<DocumentRepositoryPluginInterface>,
  patientTimelineConsultationEvent: ReturnType<TimelineConsultationEventPluginInterface>,
  patientTimeline: ReturnType<PatientTimelinePluginInterface>,
  patientDetails: ReturnType<PatientDetailsPluginInterface>,
  preConsultationFlow: ReturnType<PreConsultationFlowPluginInterface>,
  examinationSectionGroup: ReturnType<SectionGroupPluginInterface>,
  examinationNotes: ReturnType<ExaminationNotesPluginInterface>,
  patientMetrics: ReturnType<PatientMetricsPluginInterface>,
  medicalHistory: ReturnType<MedicalHistoryPluginInterface>,
  diagnosisSectionGroup: ReturnType<SectionGroupPluginInterface>,
  actionsSectionGroup: ReturnType<SectionGroupPluginInterface>,
  appointmentInvites: ReturnType<AppointmentInvitesPluginInterface>,
  diagnosis: ReturnType<DiagnosisPluginInterface>,
  privateReferrals: ReturnType<ReferralsPluginInterface>,
  nhsAppointmentInvites: ReturnType<NhsAppointmentInvitesPluginInterface>,
  workflowActions: ReturnType<WorkflowActionsPluginInterface>,
  prescriptions: ReturnType<PrescriptionsPluginInterface>,
  privateTests: ReturnType<TestsSectionPluginInterface>,
  gpDetails: ReturnType<GpDetailsPluginInterface>,
  sickNotes: ReturnType<SickNotesPluginInterface>,
  attachments: ReturnType<AttachmentsPluginInterface>,
  callRecording: ReturnType<CallRecordingPluginInterface>,
  currentConsultationSummary: ReturnType<CurrentConsultationSummaryPluginInterface>,
  pastConsultationSummary: ReturnType<PastConsultationSummaryPluginInterface>,
  preconsultationSummary: ReturnType<PreconsultationSummaryPluginInterface>,
  bookingReasonSummary: ReturnType<BookingReasonSummaryPluginInterface>,
  testSummary: ReturnType<TestsSummaryPluginInterface>,
  sickNotesSummary: ReturnType<SickNotesSummaryPluginInterface>,
  prescriptionsSummary: ReturnType<PrescriptionsSummaryPluginInterface>,
  attachmentsSummary: ReturnType<AttachmentsSummaryPluginInterface>,
  examinationSummary: ReturnType<ExaminationSummaryPluginInterface>,
  diagnosisSummary: ReturnType<DiagnosisSummaryPluginInterface>,
  referralsSummary: ReturnType<ReferralsSummaryPluginInterface>,
  carePlanSummary: ReturnType<CarePlanSummaryPluginInterface>,
  nhsAppointmentInvitesSummary: ReturnType<NhsAppointmentInvitesSummaryPluginInterface>,
  workflowActionsSummary: ReturnType<WorkflowActionsSummaryPluginInterface>,
  releaseNotesPlugin: ReturnType<ReleaseNotesPluginInterface>,
  linksAndResourcesPlugin: ReturnType<LinksAndResourcesPluginInterface>,
  carePlansCompletionModel: ReturnType<CarePlansCompletionModelInterface>,
  prescriptionPromotionModel: ReturnType<PrescriptionPromotionModelInterface>,
  signatureRxPinPlugin: ReturnType<SignatureRxPinPluginInterface>,
  completeConsultationModel: ReturnType<CompleteConsultationModelInterface>,
  completeDialogPlugin: ReturnType<CompleteDialogPluginInterface>
}

export const $ref = <R extends keyof ConfigReferences>(
  ref: R
): ConfigReferences[R] =>
  ({
    $ref: ref,
  } as any)
