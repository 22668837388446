import { graphql, withApollo } from '@apollo/client/react/hoc'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { compose } from 'recompose'

import { Button } from '@babylon/core-ui'

import {
  ACTIONS_PRESCRIPTIONS_SECTION_ERROR_ACTION,
  DRUG_ACTIONS_CATEGORY,
  ERROR_REMOVE_DRUG_ACTION,
  REMOVE_DRUG_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useConsultation, usePatient } from '~/core/config'
import { useMessages, usePrevious } from '~/core/hooks'
import { usePermissions } from '~/core/permissions'
import { logException } from '~/core/sentry'
import { errorAlert } from '~/core/with-error-message'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'
import PrescriptionModal from '~/features/prescriptions/ConsultationPrescriptionModal'
import Prescription from '~/features/prescriptions/Prescription'
import { usePrescriptionQuery } from '~/generated'
import { Dialog } from '~/ui/Dialog'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { withNotify } from '~/ui/Notification'
import { Section, withSectionErrorBoundary } from '~/ui/Section'
import Tooltip from '~/ui/Tooltip'

import PrescriptionSuggestions from './components/PrescriptionSuggestions'
import {
  DuplicatePrescriptionMutation,
  PrescriptionChangeStateMutation,
  RemoveDrugFromPrescriptionMutation,
  RemovePrescriptionMutation,
} from './mutations'
import {
  GetClinicalNotesForPrescriptionsQuery,
  PRESCRIPTIONS_QUERY,
} from './queries'

import messages from './PrescriptionsSection.messages'
import styles from './styles.module.scss'

const isVoidedOrRejected = ({ prescriptionState }) =>
  prescriptionState === 'VOIDED' || prescriptionState === 'REJECTED'

const enhance = compose(
  withSectionErrorBoundary({
    gaAction: ACTIONS_PRESCRIPTIONS_SECTION_ERROR_ACTION,
    sectionTitleDescriptor: messages.title_private,
  }),
  withApollo,
  withNotify,
  graphql(RemovePrescriptionMutation, {
    name: 'removePrescriptionMutation',
  }),
  graphql(PrescriptionChangeStateMutation, {
    name: 'prescriptionChangeStateMutation',
  }),
  graphql(RemoveDrugFromPrescriptionMutation, {
    name: 'removeDrugMutation',
  }),
  graphql(DuplicatePrescriptionMutation, {
    name: 'duplicatePrescriptionMutation',
  })
)

const trackSuccess = (prescriptionDrugId) =>
  analytics.trackEvent({
    action: REMOVE_DRUG_ACTION,
    category: DRUG_ACTIONS_CATEGORY,
    label: prescriptionDrugId,
  })

const trackError = (prescriptionDrugId) =>
  analytics.trackEvent({
    action: ERROR_REMOVE_DRUG_ACTION,
    category: DRUG_ACTIONS_CATEGORY,
    label: prescriptionDrugId,
  })

const PrescriptionsSection = ({
  client,
  submittingDuplication,
  removeDrugMutation,
  removePrescriptionMutation,
  duplicatePrescriptionMutation,
  prescriptionChangeStateMutation,
  consultationContext,
  editedConsultationWarningModel,
}) => {
  const consultation = useConsultation(consultationContext)

  const {
    consultationNote,
    consumerNetwork,
    id: consultationId,
    region,
    status,
  } = consultation

  const { id: patientId } = usePatient(consultationContext)

  const {
    data: prescriptionsData,
    error: prescriptionsError,
    isLoading: prescriptionsLoading,
    refetch: prescriptionsRefetch,
  } = usePrescriptionQuery({
    variables: {
      id: consultationId,
    },
  })

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    status
  )

  const markFinalizedConsultationAsEdited = editedConsultationWarningModel?.useModelContext()
    ?.markFinalizedConsultationAsEdited

  const [showPopover, setShowPopover] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [suggestionsError, setSuggestionsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const prevCodes = usePrevious(consultationNote?.assessmentCodes)
  const history = useHistory()
  const match = useRouteMatch()
  const f = useMessages(messages)
  const [
    isSuggestedPrescriptionAllowed,
    resolveDrugs,
    canCreatePrescription,
  ] = usePermissions(
    'enable_suggested_prescriptions_in_clinical_portal_v3',
    'enable_suggested_prescriptions_in_clinical_portal_v3',
    'create_prescription_for_appointment'
  )

  const removePrescription = (id) => {
    return removePrescriptionMutation({
      variables: {
        id,
      },
      update: (proxy, { data: { removePrescription } }) => {
        const variables = {
          id: consultationId,
        }
        const cachedData = proxy.readQuery({
          query: PRESCRIPTIONS_QUERY,
          variables,
        })
        const updatedCache = removePrescriptionFromCache(
          cachedData,
          removePrescription
        )

        proxy.writeQuery({
          query: PRESCRIPTIONS_QUERY,
          data: updatedCache,
          variables,
        })
      },
    })
  }

  const handleDrugRemove = async (id, prescriptionDrugId) => {
    Dialog.show({
      message: f('confirm_delete_dialog_message'),
      cancelLabel: f('confirm_delete_dialog_cancel_button_label'),
      okLabel: f('confirm_delete_dialog_remove_button_label'),
      onOkStyle: 'negative',
      centerContent: true,
      onOk: async () => {
        const { prescriptions } = prescriptionsData.consultation
        const prescription = prescriptions.find((v) => v.id === id)

        /**
         * Should remove prescription if it will be empty after removing
         * a drug.
         */
        if (prescription.drugs.length === 1) {
          try {
            await removePrescription(id)
            if (markFinalizedConsultationAsEdited) {
              markFinalizedConsultationAsEdited()
            }
            trackSuccess(prescriptionDrugId)
          } catch (error) {
            trackError(prescriptionDrugId)
            errorAlert({ logMessage: error })
            logException(error)
          }
        } else {
          try {
            await removeDrugMutation({
              variables: {
                id,
                prescriptionDrugId,
              },
            })
            if (markFinalizedConsultationAsEdited) {
              markFinalizedConsultationAsEdited()
            }
            trackSuccess(prescriptionDrugId)
          } catch (error) {
            trackError(prescriptionDrugId)
            errorAlert({ logMessage: error })
            logException(error)
          }
        }
      },
    })
  }

  const handleDuplicate = async (prescriptionId) => {
    try {
      await duplicatePrescriptionMutation({
        variables: {
          prescriptionId,
        },
        update: (proxy, { data: { duplicatePrescription: prescription } }) => {
          const variables = {
            id: consultationId,
          }

          const cachedData = proxy.readQuery({
            query: PRESCRIPTIONS_QUERY,
            variables,
          })

          const { consultation } = cachedData

          proxy.writeQuery({
            query: PRESCRIPTIONS_QUERY,
            data: {
              ...cachedData,
              consultation: {
                ...consultation,
                prescriptions: [prescription, ...consultation.prescriptions],
              },
            },
            variables,
          })
        },
      })
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      trackSuccess(prescriptionId)
    } catch (exception) {
      trackError(prescriptionId)
      errorAlert({ logMessage: exception })
      logException(exception)
    }
  }

  const handleDrugEdit = (prescriptionId, prescriptionDrugId) => {
    history.replace(
      `${match.url}/prescription/edit?prescriptionId=${prescriptionId}&drugId=${prescriptionDrugId}`
    )
  }

  const removePrescriptionFromCache = (cache, id) => {
    const { consultation } = cache

    return {
      ...cache,
      consultation: {
        ...consultation,
        prescriptions: consultation.prescriptions.filter((v) => v.id !== id),
      },
    }
  }

  const handleVoidPrescription = async (id, reason) => {
    return prescriptionChangeStateMutation({
      variables: {
        action: 'VOID',
        region: region.iso_code,
        id,
        reason,
      },
    })
  }

  const queryNotes = async (iris) => {
    setSuggestionsError(false)
    setIsLoading(true)

    try {
      const { data } = await client.query({
        query: GetClinicalNotesForPrescriptionsQuery,
        variables: {
          iris,
          resolveDrugs,
        },
      })

      setSuggestions([
        ...data.getClinicalNotes
          .filter((note) => note.treatmentPlans.length)
          .map((note) => ({
            ...note,
            indication: {
              name: note.name,
              iri: note.iri,
            },
          })),
      ])
      setIsLoading(false)
    } catch (exception) {
      setSuggestionsError(true)
      setIsLoading(false)
      logException(exception)
    }
  }

  const filterUniqueAssessmentCodes = (item, index, self) => {
    return (
      self.findIndex(
        (other) => other.iri === item.iri && other.term === item.term
      ) === index
    )
  }

  const getSuggestedPrescriptions = (codes) => {
    const assessmentCodes = codes
      ? codes.filter(filterUniqueAssessmentCodes)
      : []

    setSuggestions([])
    setShowPopover(true)
    setIsLoading(assessmentCodes.length > 0)

    const codesInput = assessmentCodes.map((assessmentCode) => ({
      iri: assessmentCode.code,
      name: assessmentCode.term,
    }))

    queryNotes(codesInput)
  }

  const hasDraft = (prescriptions) => {
    return prescriptions.some(
      (prescription) => prescription.prescriptionState === 'DRAFT'
    )
  }

  const hasOnlyVoidedAndRejected = (prescriptions) => {
    return prescriptions.every(
      (prescription) =>
        prescription.prescriptionState === 'VOIDED' ||
        prescription.prescriptionState === 'REJECTED'
    )
  }

  useEffect(() => {
    if (isSuggestedPrescriptionAllowed && consultationNote) {
      const currentCodes = consultationNote.assessmentCodes

      if (!isEqual(currentCodes, prevCodes)) {
        getSuggestedPrescriptions(currentCodes)
      }
    }
  })

  if (prescriptionsError) {
    return (
      <Section type="secondary" icon="receipt" title={f('title_private')}>
        <ErrorPanel
          error={prescriptionsError}
          title={f('fetch_error')}
          retry={() => prescriptionsRefetch()}
        />
      </Section>
    )
  }

  const prescriptions = prescriptionsData?.consultation?.prescriptions
  const { assessmentCodes } = consultationNote

  const isAllowedToCreate =
    canCreatePrescription &&
    prescriptions &&
    (hasDraft(prescriptions) || hasOnlyVoidedAndRejected(prescriptions)) &&
    !restrictFinalizedConsultationEditing

  const canDuplicatePrescription =
    prescriptions && prescriptions.every(isVoidedOrRejected)

  return (
    <>
      <Section
        type="secondary"
        title={f('title_private')}
        loading={prescriptionsLoading}
        topRight={
          <Button
            disabled={!isAllowedToCreate}
            intent="secondary"
            onClick={() => {
              history.replace(`${match.url}/prescription/create`)
            }}
            testId="prescriptions-section-add-drug-button"
          >
            {f('add_drug_button_label')}
          </Button>
        }
        dataTestId="prescriptions"
      >
        {isSuggestedPrescriptionAllowed &&
          isAllowedToCreate &&
          assessmentCodes && (
            <div className={styles.suggestionsButton}>
              <a
                role="button"
                tabIndex={0}
                onClick={() => getSuggestedPrescriptions(assessmentCodes)}
              >
                {f('suggest_prescription_button_label')}
              </a>
              <Tooltip
                info={f('suggest_prescription_tooltip_message')}
                testId="prescriptions-section-drug-suggestion-tooltip"
              />
            </div>
          )}

        {isSuggestedPrescriptionAllowed &&
          isAllowedToCreate &&
          showPopover &&
          assessmentCodes && (
            <div className={styles.popover}>
              <i
                role="button"
                tabIndex="0"
                className={classnames('fas fa-times', styles.closeButton)}
                onClick={() => setShowPopover(false)}
              />
              <PrescriptionSuggestions
                suggestionsError={suggestionsError}
                suggestions={suggestions}
                isLoading={isLoading}
                prescriptions={prescriptions}
                consultationId={consultationId}
                consumerNetwork={consumerNetwork}
                patientId={patientId}
                region={region}
                retry={getSuggestedPrescriptions}
              />
            </div>
          )}

        {prescriptions &&
          prescriptions.map((prescription) => (
            <Prescription
              key={prescription.id}
              {...prescription}
              onPrescriptionVoid={handleVoidPrescription}
              onDrugRemove={handleDrugRemove}
              onDrugEdit={handleDrugEdit}
              consultationStatus={status}
              canDuplicatePrescription={canDuplicatePrescription}
              onDuplicate={handleDuplicate}
              submittingDuplication={submittingDuplication}
              restrictFinalizedConsultationEditing={
                restrictFinalizedConsultationEditing
              }
              markFinalizedConsultationAsEdited={
                markFinalizedConsultationAsEdited
              }
            />
          ))}
      </Section>
      {!restrictFinalizedConsultationEditing && (
        <Route path="/consultation/:consultationId/prescription/:mode(create|edit)">
          <PrescriptionModal
            consultation={consultation}
            markFinalizedConsultationAsEdited={
              markFinalizedConsultationAsEdited
            }
          />
        </Route>
      )}
    </>
  )
}

export default enhance(PrescriptionsSection)
