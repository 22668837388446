import { defineMessages } from 'react-intl'

const baseId = 'consultation.patient_summary_modal.scroll_header'

export default defineMessages({
  info: {
    id: `${baseId}.info`,
    defaultMessage: `{numConsults, plural,
      one {{numConsults} Babylon consultation including {code} for this patient}
      other {{numConsults} Babylon consultations including {code} for this patient}
    }`,
  },
})
