import { useContext } from 'react'
import { BabylonUserContext, User } from './BabylonUser'

const useBabylonUser = (): User => {
  const user = useContext(BabylonUserContext)

  if (!user) {
    throw new Error(
      'useBabylonUser must be used inside BabylonUserProvider, after the user has loaded'
    )
  }

  return user
}

export default useBabylonUser
