import React, { useRef, ReactNode } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { Portal } from 'react-portal'

import CloseSVG from '../../../icons/CloseSVG'

import './Snackbar.scss'

export type SnackbarIntent =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'

export interface SnackbarProps {
  anchorOrigin?: {
    horizontal?: 'left' | 'center' | 'right'
    vertical?: 'top' | 'bottom'
  }
  autoHideDuration?: number
  children?: ReactNode
  className?: string
  'data-testid'?: string
  fill?: boolean
  fixed?: boolean
  icon?: ReactNode
  intent?: SnackbarIntent
  message?: string | null
  onClose?: () => void
  open?: boolean
}

const getClassNames = ({
  anchorOrigin: { horizontal, vertical } = {},
  className,
  intent,
  fill,
  fixed,
}: SnackbarProps) =>
  classNames(
    className,
    'core-snackbar',
    `core-snackbar--${intent}`,
    `core-snackbar--${horizontal}`,
    `core-snackbar--${vertical}`,
    {
      'core-snackbar--fill': fill,
      'core-snackbar--fixed': fixed,
    }
  )

const Snackbar = ({
  children,
  anchorOrigin = {
    horizontal: 'center',
    vertical: 'top',
  },
  'data-testid': testId,
  fill = false,
  fixed = true,
  intent = 'primary',
  icon,
  message = '',
  autoHideDuration,
  onClose,
  open = true,
  className,
}: SnackbarProps) => {
  const timeoutDuration = useRef<number>()

  if (timeoutDuration.current) {
    window.clearTimeout(timeoutDuration.current)
  }

  if (open && autoHideDuration && onClose) {
    timeoutDuration.current = window.setTimeout(onClose, autoHideDuration)
  }

  return (
    <Portal>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="core-snackbar-transition-"
        unmountOnExit
      >
        <div
          data-testid={testId}
          className={getClassNames({
            anchorOrigin,
            className,
            fill,
            fixed,
            intent,
          })}
        >
          {icon && <div className="core-snackbar__icon">{icon}</div>}
          {message && <div className="core-snackbar__message">{message}</div>}
          {children && (
            <div className="core-snackbar__children">{children}</div>
          )}
          {onClose && (
            <button
              onClick={() => {
                window.clearTimeout(timeoutDuration.current)
                onClose()
              }}
              className="core-snackbar__close-button"
              type="button"
            >
              <CloseSVG className="core-snackbar__close-svg" />
            </button>
          )}
        </div>
      </CSSTransition>
    </Portal>
  )
}

export default Snackbar
