import { defineMessages } from 'react-intl'

const baseId = 'error_panel.copy_button'

export default defineMessages({
  default_tooltip_message: {
    id: `${baseId}.default_tooltip_message`,
    defaultMessage: 'Copy',
  },
  copied_label: {
    id: `${baseId}.copied_label`,
    defaultMessage: 'Copied',
  },
  failed_label: {
    id: `${baseId}.failed_label`,
    defaultMessage: 'Failed to copy',
  },
})
