import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.examination.explored_by_gp_editor'

export default defineMessages({
  label: {
    id: `${baseId}.label`,
    defaultMessage: 'Examination',
  },
  placeholder: {
    id: `${baseId}.placeholder`,
    defaultMessage: 'Examination',
  },
  error_loading_notes: {
    id: `${baseId}.error_loading_notes`,
    defaultMessage: 'Error loading examination notes.',
  },
})
