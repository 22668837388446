import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  isHeader?: boolean
  width?: string
}

const TableCell = ({ children, isHeader = false, width }: Props) => {
  const Element = isHeader ? 'th' : 'td'
  return (
    <Element className="core-table__cell" style={{ width }}>
      {children}
    </Element>
  )
}

export default TableCell
