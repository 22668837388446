import { useEffect, useRef } from 'react'

const useTimeout = (callback: Function, delay?: number | null) => {
  const savedCallback = useRef<Function>(() => {})

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (delay !== null) {
      const interval = setTimeout(() => savedCallback.current(), delay || 0)
      return () => clearTimeout(interval)
    }

    return undefined
  }, [delay])
}

export default useTimeout
