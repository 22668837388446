import React from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { useConsultation } from '~/core/config'
import { AppointmentInvitesPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { useRestrictConsultationEditing } from '~/features/consultation/utils'
import { useGetAppointmentInvitesQuery } from '~/generated'

import AppointmentInvitesView from './AppointmentInvitesView'

export const AppointmentInvitesContainer: AppointmentInvitesPluginInterface = ({
  consultationContext,
}) => {
  const consultation = useConsultation(consultationContext)
  const { appointmentInvitesEnabled } = useFeatureFlags()
  const { consultant: currentConsultant } = useConsultantUser()

  const restrictFinalizedConsultationEditing = useRestrictConsultationEditing(
    consultation?.status
  )

  const { data, loading, error, refetch } = useGetAppointmentInvitesQuery({
    variables: { id: consultation.id },
    skip: !appointmentInvitesEnabled,
  })
  const appointmentInvites = data?.appointmentInvites ?? []

  const {
    id: consultationId,
    patientId,
    consumerNetworkId,
    tenancyContractId,
  } = consultation
  const consultationTenancyContractId = tenancyContractId || undefined

  return (
    <AppointmentInvitesView
      appointmentInvites={appointmentInvites}
      patientId={patientId}
      consumerNetworkId={consumerNetworkId}
      consultationTenancyContractId={consultationTenancyContractId}
      consultationId={consultationId}
      currentConsultant={currentConsultant}
      loading={loading}
      error={error}
      refetch={refetch}
      restrictFinalizedConsultationEditing={
        restrictFinalizedConsultationEditing
      }
    />
  )
}

export default AppointmentInvitesContainer
