import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { useConsultantUser } from '@babylon/babylon-user'
import {
  ButtonOption,
  ButtonSwitch,
  Heading,
  Spinner,
  Text,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { PBL_FILTERS_CATEGORY, PBL_MODAL_CATEGORY } from '~/constants/analytics'
import analytics from '~/core/analytics'
import { ConsultationContextType, useConsultation } from '~/core/config'
import { scrollTo } from '~/core/scroll-tracker'
import PatientSummaryInfo from '~/features/patient-summary/PatientSummary/components/PatientSummaryInfo'
import messages from '~/features/patient-summary/PatientSummary/PatientSummary.messages'
import { useProblemsListQuery } from '~/generated'
import { ErrorMessage } from '~/ui/ErrorMessage'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { SectionShowMoreButton } from '~/ui/Section'

import ProblemsTable from './ProblemsTable'
import {
  mapHistoryToMostFrequent,
  mapHistoryToMyConsultations,
  mapHistoryToRecentEpisodes,
} from './utils/mappings'
import { ProblemRecord } from './utils/types'
import { isMyConsultation } from './utils/utils'

import styles from './styles.module.scss'

const DEFAULT_VISIBLE = 10

interface OrderOption extends ButtonOption {
  orderFunction: Function
}

interface ProblemsListProps {
  consultationContext: ConsultationContextType
}

const ProblemsList = ({ consultationContext }: ProblemsListProps) => {
  const fm = useFormatMessage()
  const { consultant } = useConsultantUser()
  const consultation = useConsultation(consultationContext)
  const history = useHistory()
  const match = useRouteMatch()
  const [visibleCount, setVisibleCount] = useState(DEFAULT_VISIBLE)

  const { data, loading, error, refetch } = useProblemsListQuery({
    variables: {
      patientId: consultation.patient.id,
    },
  })

  const consultationId = consultation.id
  const consultantId = consultant?.uuid

  const orderOptions: Record<string, OrderOption> = {
    recent: {
      label: fm(messages.recent_label),
      id: 'recent',
      orderFunction: mapHistoryToRecentEpisodes,
    },
    recurring: {
      label: fm(messages.recurring_label),
      id: 'recurring',
      orderFunction: mapHistoryToMostFrequent,
    },
    my_consultations: {
      label: fm(messages.my_consultations_label),
      id: 'my_consultations',
      orderFunction: mapHistoryToMyConsultations,
    },
  }
  const [selectedOrder, setSelectedOrder] = useState(orderOptions.recent)

  useEffect(() => {
    analytics.trackEvent({
      category: PBL_FILTERS_CATEGORY,
      action: consultationId,
      label: 'recent',
      consultationId,
      consultantId,
    })
  }, [consultationId, consultantId])

  const handleOrderButton = (option: OrderOption) => {
    analytics.trackEvent({
      category: PBL_FILTERS_CATEGORY,
      action: consultation.id,
      label: option.id,
      consultationId,
      consultantId,
    })

    setSelectedOrder(option)
  }

  const createModalUrl = ({
    consultationId,
    codeId,
    hashNote,
  }: ProblemRecord) => {
    let url = `${match.url}/patient-summary?patientId=${consultation.patient.id}&&eventId=${consultationId}`
    if (codeId) {
      const splitIri = codeId.split('/')
      const iriId = splitIri.pop()
      url += `&&code=${iriId}`
    } else {
      url += `&&note=${hashNote}`
    }

    return url
  }

  const handleRowClick = (row: any) => {
    const firstEntry = row.entries[0]
    analytics.trackEvent({
      category: PBL_MODAL_CATEGORY,
      action: consultation.id,
      label: firstEntry.consultationId,
      consultationId,
      consultantId,
    })
    const url = createModalUrl(firstEntry)
    history.replace(url)
  }

  const handleConsultationClick = (record: ProblemRecord) => {
    analytics.trackEvent({
      category: PBL_MODAL_CATEGORY,
      action: consultation.id,
      label: record.consultationId,
      consultationId,
      consultantId,
    })
    const url = createModalUrl(record)
    history.replace(url)
  }

  const showAll = (length: number) => {
    setVisibleCount(length)
  }

  const showLess = () => {
    setVisibleCount(DEFAULT_VISIBLE)
    scrollTo('patient-summary-section')
  }

  if (loading) {
    return <Spinner size="small" testid="problems-list-loading-spinner" />
  }

  if (error) {
    return (
      <ErrorPanel
        error={error}
        fill="container"
        retry={() => refetch()}
        title={fm(messages.error_loading_summary)}
      />
    )
  }

  if (!data?.patient || !data?.patient.history) {
    return <ErrorMessage message={fm(messages.error_loading_summary)} />
  }

  const problemsList: Array<Array<ProblemRecord>> = selectedOrder.orderFunction(
    data.patient.history,
    consultation.id,
    consultant?.uuid,
    consultant?.id
  )

  if (!problemsList.length) {
    return (
      <div>
        <Heading level="h3" margin>
          {`${fm(messages.problems_list)} (0)`}
        </Heading>
        <Text color="light-grey-type">{fm(messages.no_past_problems)}</Text>
      </div>
    )
  }

  const summaryContainsMyConsultation: boolean = problemsList.some(
    (problemGroup: Array<ProblemRecord>) =>
      problemGroup.find((record: ProblemRecord) => {
        return isMyConsultation(consultant, record)
      })
  )

  const summaryContainsRecurringProblem = problemsList.some(
    (problemGroup: Array<ProblemRecord>) => problemGroup.length > 1
  )
  const allVisible = visibleCount === problemsList.length

  orderOptions.recurring.disabled = !summaryContainsRecurringProblem
  orderOptions.my_consultations.disabled = !summaryContainsMyConsultation

  return (
    <>
      <PatientSummaryInfo
        summaryContainsMyConsultation={summaryContainsMyConsultation}
        problemListLength={problemsList.length}
      />
      <ButtonSwitch
        options={Object.values(orderOptions)}
        selected={selectedOrder}
        onSelect={handleOrderButton}
        className={styles.buttonSwitch}
      />
      <ProblemsTable
        problemsList={problemsList}
        handleRowClick={handleRowClick}
        consultant={consultant}
        handleConsultationClick={handleConsultationClick}
        visibleCount={visibleCount}
        consultationId={consultation.id}
      />

      {problemsList.length > DEFAULT_VISIBLE && (
        <SectionShowMoreButton
          expanded={allVisible}
          showMoreMessage={fm(messages.show_all_button, {
            numProblems: problemsList.length,
          })}
          onClick={allVisible ? showLess : () => showAll(problemsList.length)}
        />
      )}
    </>
  )
}

export default ProblemsList
