import React, { useCallback, useLayoutEffect, useMemo } from 'react'

import { ModuleConfig, ObservableValue, useObservable } from '~/core/container'

import { Box } from './Box'

import styles from './styles.module.scss'

export interface ConfigEditorProps {
  configDataContext: ObservableValue<Record<string, ModuleConfig>>
}

function extractDependencies(config: ModuleConfig) {
  const deps: string[] = []

  const parseNode = (node: any) => {
    if (node != null) {
      if (Array.isArray(node)) {
        node.forEach(parseNode)
      } else if (typeof node === 'object') {
        if (node.hasOwnProperty('$ref')) {
          deps.push(node.$ref)
        } else {
          Object.values(node).forEach(parseNode)
        }
      }
    }
  }

  parseNode(config.options)

  config.__deps__ = deps
}

export type OptionsProps = Pick<ModuleConfig, 'options'>

export const ConfigEditor = ({ configDataContext }: ConfigEditorProps) => {
  const config = useObservable(configDataContext)

  const [modules, maxBucket] = useMemo(() => {
    if (!config) {
      return [[], 0]
    }

    let maxBucket = 0

    const modules = Object.entries(config).map(([id, module]) => {
      module.id = id
      return module
    })

    modules.forEach(extractDependencies)

    modules.forEach(() => {
      modules.forEach((module) => {
        const getBucketId = (dep: string) => config[dep].__bucket__ || 0
        const depBuckets = module.__deps__.map(getBucketId)
        module.__bucket__ = Math.max(...[-1, ...depBuckets]) + 1
        maxBucket = Math.max(maxBucket, module.__bucket__)
      })
    })

    // push up modules
    modules.forEach((module) => {
      while (
        module.__bucket__ < maxBucket &&
        !modules
          .filter((m) => m.__bucket__ <= module.__bucket__ + 1)
          .flatMap((m) => m.__deps__)
          .includes(module.id)
      )
        module.__bucket__++
    })

    const getRankAverage = (module: ModuleConfig) =>
      modules
        .filter((m) => m.__deps__.includes(module.id))
        .map((m) => modules.indexOf(m))
        .reduce((sum, idx) => sum + idx, 0)

    modules.sort((a, b) => getRankAverage(a) - getRankAverage(b))

    return [modules, maxBucket]
  }, [config])

  const curves = modules.flatMap(({ id: left, __deps__ }) =>
    __deps__.map((right: string) => `${left}:${right}`)
  )

  const updateCurves = useCallback(() => {
    const boxEl: any = document.getElementsByClassName(styles.boxes).item(0)
    const baseBox = boxEl?.getBoundingClientRect()

    curves.forEach((id) => {
      const [left, right] = id.split(/:/)
      const leftEl = document.getElementById(`mod-${left}`)
      const rightEl = document.getElementById(`mod-${right}`)
      const curveEl = document.getElementById(`curve-${id}`)
      if (leftEl && rightEl && curveEl) {
        const leftBox = leftEl?.getBoundingClientRect()
        const rightBox = rightEl?.getBoundingClientRect()

        curveEl.setAttribute(
          'd',
          `M${leftBox.right - baseBox.left},${
            (leftBox.top + leftBox.bottom) / 2 - baseBox.top
          } C${leftBox.right + 50 - baseBox.left},${
            (leftBox.top + leftBox.bottom) / 2 - baseBox.top
          } ${rightBox.left - 50 - baseBox.left},${
            (rightBox.top + rightBox.bottom) / 2 - baseBox.top
          } ${rightBox.left - baseBox.left},${
            (rightBox.top + rightBox.bottom) / 2 - baseBox.top
          }`
        )
      }
    })
  }, [curves])

  const boxes = useMemo(
    () =>
      modules.map((module) => (
        <Box key={module.id} {...module} updateCurves={updateCurves} />
      )),
    [modules, updateCurves]
  )

  const update = useCallback(() => {
    let left = 20
    let maxTop = 0

    for (let i = maxBucket; i >= 0; i--) {
      let top = 20
      let maxWidth = 0
      modules
        .filter((module) => module.__bucket__ === i)
        .reverse()
        // eslint-disable-next-line no-loop-func
        .forEach(({ id }) => {
          const el = document.getElementById(`mod-${id}`)
          maxWidth = Math.max(maxWidth, el?.clientWidth || 0)
          if (el) {
            el.style.left = `${left}px`
            el.style.top = `${top}px`
            top += 30 + el?.clientHeight
          }
        })
      left += maxWidth ? 100 + maxWidth : 0
      maxTop = Math.max(maxTop, top)
    }

    const svgEl: any = document.getElementsByClassName(styles.curves).item(0)

    if (svgEl) {
      svgEl.style.width = `${left - 200}px`
      svgEl.style.height = `${maxTop + 20}px`
    }

    updateCurves()
  }, [maxBucket, modules, updateCurves])

  useLayoutEffect(update, [config, update])

  return (
    <div className={styles.graphEditor}>
      <div className={styles.boxes}>
        <svg className={styles.curves}>
          {curves.map((id) => (
            <path key={id} id={`curve-${id}`} className={styles.curve} />
          ))}
        </svg>
        {boxes}
      </div>
    </div>
  )
}
