import { round } from '../utils'

import {
  kilogramToLbs,
  kilogramToWholeStone,
  kilogramToWholeStoneLbsRemainder,
  lbsToKg,
  lbsToWholeStone,
  lbsToWholeStoneLbsRemainder,
  stoneToKilogram,
  stoneToLbs,
  LBS_PER_STONE,
  DEFAULT_DECIMALS,
} from '../unit_converters'

type UsWeight = number | null

interface UsWeightInput {
  lb?: UsWeight
  decimals: number
}

type UkWeight = {
  st?: number | null
  lb?: number | null
}

interface UkWeightInput extends UkWeight {
  decimals: number
}

type MetricWeight = number | null

interface MetricWeightInput {
  kg?: number | null
  decimals: number
}

const weightConversions = {
  metricConversions: {
    uk: ({ kg, decimals }: MetricWeightInput): UkWeight => {
      if (kg == null) {
        return { st: null, lb: null }
      }

      return {
        st: kilogramToWholeStone(kg),
        lb: round(kilogramToWholeStoneLbsRemainder(kg), decimals),
      }
    },
    us: ({ kg, decimals }: MetricWeightInput): UsWeight => {
      if (kg == null) {
        return null
      }

      return round(kilogramToLbs(kg), decimals)
    },
  },

  usConversions: {
    metric: ({ lb, decimals }: UsWeightInput): MetricWeight => {
      if (lb == null) {
        return null
      }

      return round(lbsToKg(lb), decimals)
    },
    uk: ({ lb, decimals }: UsWeightInput): UkWeight => {
      if (lb == null) {
        return { st: null, lb: null }
      }

      return {
        st: lbsToWholeStone(lb),
        lb: round(lbsToWholeStoneLbsRemainder(lb), decimals),
      }
    },
  },

  ukConversions: {
    metric: ({ st, lb, decimals }: UkWeightInput): MetricWeight => {
      if (st == null && lb == null) {
        return null
      }

      return round((st ? stoneToKilogram(st) : 0) + lbsToKg(lb || 0), decimals)
    },
    us: ({ st, lb, decimals }: UkWeightInput): UsWeight => {
      if (st == null && lb == null) {
        return null
      }

      return round((st ? stoneToLbs(st) : 0) + (lb || 0), decimals)
    },
    normalize: {
      lbs: ({
        lb,
        decimals,
      }: Pick<UkWeightInput, 'lb' | 'decimals'>): UkWeight => {
        if (lb == null) {
          return { st: null, lb: null }
        }

        return {
          st: Math.floor(lb / LBS_PER_STONE),
          lb: round(lb, decimals) % LBS_PER_STONE,
        }
      },
      st: ({
        st,
        decimals,
      }: Pick<UkWeightInput, 'st' | 'decimals'>): UkWeight => {
        if (st == null) {
          return { st: null, lb: null }
        }

        return {
          st: Math.floor(st),
          lb: round(LBS_PER_STONE * (st % 1), decimals),
        }
      },
    },
  },
}

const weightMap = {
  metric: {
    toUk: (kg?: MetricWeight, decimals = DEFAULT_DECIMALS): UkWeight =>
      weightConversions.metricConversions.uk({ kg, decimals }),
    toUs: (kg?: MetricWeight, decimals = DEFAULT_DECIMALS): UsWeight =>
      weightConversions.metricConversions.us({ kg, decimals }),
    normalize: (kg: number | null): Error => {
      throw new Error(
        `No normalization for metric, it will simply return ${kg} kg`
      )
    },
  },
  us: {
    toMetric: (lbs?: UsWeight, decimals = DEFAULT_DECIMALS): MetricWeight =>
      weightConversions.usConversions.metric({ lb: lbs, decimals }),
    toUk: (lbs?: UsWeight, decimals = DEFAULT_DECIMALS): UkWeight =>
      weightConversions.usConversions.uk({ lb: lbs, decimals }),
    normalize: (lbs?: UsWeight): Error => {
      throw new Error(
        `No normalization for metric, it will simply return ${lbs} lbs`
      )
    },
  },
  uk: {
    toMetric: (
      { st, lb }: UkWeight,
      decimals = DEFAULT_DECIMALS
    ): number | null =>
      weightConversions.ukConversions.metric({ st, lb, decimals }),
    toUs: ({ st, lb }: UkWeight, decimals = DEFAULT_DECIMALS): UsWeight =>
      weightConversions.ukConversions.us({ st, lb, decimals }),
    normalizeFromLbs: (
      lb?: number | null,
      decimals = DEFAULT_DECIMALS
    ): UkWeight =>
      weightConversions.ukConversions.normalize.lbs({ lb, decimals }),
    normalizeFromStone: (
      st?: number | null,
      decimals = DEFAULT_DECIMALS
    ): UkWeight =>
      weightConversions.ukConversions.normalize.st({ st, decimals }),
  },
}

export default weightMap
