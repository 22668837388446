import React, { useContext, useRef, useState } from 'react'

const ScrollContext = React.createContext({})

export const useScrollContext = () => {
  return useContext(ScrollContext)
}

const ScrollProvider = ({ children, ...props }) => {
  const [activeKey, setActiveKey] = useState(null)
  const scrollablePanelRef = useRef(null)

  return (
    <ScrollContext.Provider
      value={{
        activeKey,
        setActiveKey,
        scrollablePanelRef,
      }}
    >
      <div {...props}>{children}</div>
    </ScrollContext.Provider>
  )
}

export default ScrollProvider
