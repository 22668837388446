import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useCodingRankingList } from '~/features/clinical-coding/ranking'

import { SUGGESTED_CLINICAL_DOES_QUERY } from './queries'

const useClinicalCodingOptions = () => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const client = useApolloClient()

  const rankCodes = useCodingRankingList()

  const [debouncedFetchOptions, cancel] = useDebouncedCallback(async (val) => {
    const query = typeof val === 'string' ? val.trim() : ''

    if (query.length > 0) {
      setLoading(true)
      try {
        const { data } = await client.query({
          query: SUGGESTED_CLINICAL_DOES_QUERY,
          variables: {
            query,
          },
        })

        const optionsResult = data.searchCodingDiagnosis
          ? rankCodes(data.searchCodingDiagnosis)
          : []

        setOptions(optionsResult)
      } catch (error) {
        setOptions([])
        console.warn(error)
      }
    }
    setLoading(false)
  }, 500)

  useEffect(() => {
    return () => cancel()
  }, [cancel])

  const fetchOptions = (val) => {
    setOptions([])
    debouncedFetchOptions(val)
  }

  return { fetchOptions, loading, options }
}

export default useClinicalCodingOptions
