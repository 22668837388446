import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.alerts'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Alerts',
  },
  promo_code_label: {
    id: `${baseId}.promo_code_label_v2`,
    defaultMessage: 'Promo code',
  },
  membership_code_label: {
    id: `${baseId}.membership_code_label_v2`,
    defaultMessage: 'Membership code',
  },
  update_patient_details_instructions: {
    id: `${baseId}.update_patient_details_instructions_v2`,
    defaultMessage:
      'Please go to the "Update patient details" section and add any missing information. Actions such as prescribing rely on this information.',
  },
  create_alert_button_label: {
    id: `${baseId}.create_alert_button_label_v2`,
    defaultMessage: 'Create alert',
  },
  last_updated: {
    id: `${baseId}.last_updated`,
    defaultMessage: 'Last updated',
  },
  no_alerts_message: {
    id: `${baseId}.no_alerts_message`,
    defaultMessage: 'This patient does not have any alerts.',
  },
  error_loading_alerts: {
    id: `${baseId}.error_loading_alerts`,
    defaultMessage: 'There was an error loading alerts',
  },
})
