import React from 'react'

import styles from './styles.module.scss'

interface InputSearchInlineProps {
  isFocused: boolean
  children: React.ReactNode
}

const InputSearchInline: React.FC<InputSearchInlineProps> = ({
  children,
  isFocused,
  ...data
}) => (
  <span {...data} className="inline-search-context">
    <span>{children}</span>
    {!isFocused && (
      <span className={styles.placeholder}>
        <div className={styles.pie} />
      </span>
    )}
  </span>
)

export default InputSearchInline
