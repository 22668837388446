import { defineMessages } from 'react-intl'

const baseId = 'trigger_workflow_dialog'

export default defineMessages({
  ok: {
    id: `${baseId}.ok`,
    defaultMessage: 'OK',
  },
  sent_to_clinops: {
    id: `${baseId}.sent_to_clinops`,
    defaultMessage: 'Sent to ClinOps',
  },
  check_systmone: {
    id: `${baseId}.check_systmone`,
    defaultMessage: 'Check SystmOne for updates',
  },
  workflow_already_created: {
    id: `${baseId}.workflow_already_created`,
    defaultMessage: 'This {groupName} has already been created',
  },
  duplicate_workflow: {
    id: `${baseId}.duplicate_workflow`,
    defaultMessage: 'Create a different {groupName}.',
  },
})
