import { defineMessages } from 'react-intl'

const baseId = 'call_player.pre_call_check_troubleshooting_dialog'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Troubleshooting for your setup performance',
  },
  checklist: {
    id: `${baseId}.checklist`,
    defaultMessage:
      '<li>Make sure your router is nearby</li>' +
      '<li>Clear your browser cache <a>by following these steps</a></li>' +
      '<li>Stop other tabs or services that may be using the internet</li>' +
      '<li>Try a different browser or incognito mode (Chrome or Firefox)</li>' +
      '<li>Restart the router for 30 seconds</li>' +
      '<li>Restart your computer</li>',
  },
  contact_support: {
    id: `${baseId}.contact_support`,
    defaultMessage:
      "None of these work? <a>Contact us and we'll help find a solution</a>",
  },
  close_button: {
    id: `${baseId}.close_button`,
    defaultMessage: 'Close',
  },
})
