import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.sick_note'

export default defineMessages({
  reason_key: {
    id: `${baseId}.reason_key`,
    defaultMessage: 'Reason',
  },
  start_key: {
    id: `${baseId}.start_key`,
    defaultMessage: 'Start',
  },
  end_key: {
    id: `${baseId}.end_key`,
    defaultMessage: 'End',
  },
})
