import React from 'react'

import Paragraph from '../../components/Paragraph'
import { PARAGRAPH } from './constants'

export default {
  renderNode: (props, editor, next) => {
    const { attributes, children, node } = props

    if (node.type === PARAGRAPH) {
      return <Paragraph {...attributes}>{children}</Paragraph>
    }

    return next()
  },
}
