import { defineMessages } from 'react-intl'

const baseId = 'mini_error_panel'

export default defineMessages({
  default_title: {
    id: `${baseId}.default_title`,
    defaultMessage: 'Something went wrong',
  },
  expand_label: {
    id: `${baseId}.expand_label`,
    defaultMessage: 'Show details',
  },
  collapse_label: {
    id: `${baseId}.collapse_label`,
    defaultMessage: 'Hide details',
  },
  code_label: {
    id: `${baseId}.error_code_label`,
    defaultMessage: 'Error Code',
  },
  id_label: {
    id: `${baseId}.error_id_label`,
    defaultMessage: 'Error ID',
  },
  send_report: {
    id: `${baseId}.send_report`,
    defaultMessage: 'Send report',
  },
  try_again: {
    id: `${baseId}.try_again`,
    defaultMessage: 'Try again',
  },
  copy_button_tooltip_message: {
    id: `${baseId}.copy_button_tooltip_message`,
    defaultMessage: 'Copy error details',
  },
})
