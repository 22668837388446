const calculateGridTemplate = (columns, collapsed) => {
  // TODO: validate custom "size" fields are valid

  if (collapsed) return 'none'

  const defaultGridTemplateColumns = `repeat(${columns.length}, minmax(auto, max-content))`

  if (!columns || !columns.find((col) => col.size))
    return defaultGridTemplateColumns

  return columns
    .map((col) => (col.size ? col.size : 'minmax(min-content, max-content)'))
    .join(' ')
}

export default calculateGridTemplate
