import axios from 'axios'

import { getHeaders } from '@babylon/shared-utils'

import { APP_NAME, APP_VERSION } from '~/constants'

const instance = axios.create({
  withCredentials: true,
  headers: {
    ...getHeaders({ appId: APP_NAME, appVersion: APP_VERSION }),
  },
})

export default instance
