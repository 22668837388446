import cn from 'classnames'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Checkbox, Grid, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE } from '~/constants'
import { generateIndex, isFilledArray } from '~/core'
import { useFeatureFlags } from '~/core/core-modules'
import { usePermissions } from '~/core/permissions'
import GpConsentWarning from '~/features/gp-details/GpConsentWarning'
import SignerAlert from '~/features/prescriptions/SignerAlert'
import BabylonInput from '~/ui/BabylonInput'
import Info from '~/ui/InfoTooltip'
import Label from '~/ui/Label'
import { ModalContent, ModalFooter, RouteModal } from '~/ui/LegacyModal'
import Message from '~/ui/Message'
import ReactSelect from '~/ui/Select'

import DosageEditor from './components/DosageEditor'
import DrugSelect from './components/DrugSelect'
import IndicationSelect from './components/IndicationSelect'
import IndicationSelectOld from './components/IndicationSelect/IndicationSelectOld'
import { createRefillOptions, validate } from './utils'

import messages from './PrescriptionModal.messages'
import styles from './styles.module.scss'

const refillOptions = createRefillOptions(0, 18)

const PrescriptionModal = ({
  mode,
  data,
  state,
  warnings,
  addIndication,
  removeIndication,
  handleInputChange,
  handleRefillsChange,
  handlePackageChange,
  handleDrugListChange,
  handleClose,
  handleSave,
  handleSuggest,
  redirectToConsultation,
  createPrescriptionMutationResult: { loading: createPrescriptionLoading },
  updatePrescriptionMutationResult: { loading: updatePrescriptionLoading },
  validateDrugMutationResult: { loading: validateDrugLoading },
  consultation,
}) => {
  const fm = useFormatMessage()
  const { fastCtIndicationsEnabled } = useFeatureFlags()
  const [
    isRefillVisible,
    displayGpConsentWarning,
    hideActionToolTips,
  ] = usePermissions(
    'enable_prescription_refills_in_clinical_portal_v3',
    'display_gp_consent_warning',
    'hide_action_tooltips'
  )
  const [showConfirmationWaring, updateShowConfirmationWaring] = useState(false)
  const [confirmed, updateConfirmed] = useState(false)
  const { validationPending } = state
  const { activeIngredients, packaging = [] } = state.value || {
    packaging: [],
  }

  const {
    gpConsent,
    id: consultationId,
    patient,
    consumerNetwork,
  } = consultation

  const notConfirmed = showConfirmationWaring && !confirmed
  const showConsentCheckbox =
    ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE && !gpConsent
  const canSubmit =
    gpConsent || confirmed || !ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE
  const showSuggest =
    state.value &&
    state.dosage &&
    state.duration &&
    state.dosage.trim() &&
    state.duration.trim()

  const {
    drugValidation,
    quantityValidation,
    directionValidation,
    indicationValidations,
  } = validate(state)

  const options = packaging
    .filter((v) => v.packageSize)
    .map((v) => ({
      label: v.packageSize,
      value: v.packageSize,
    }))

  if (options.length) {
    options.unshift({
      value: null,
      label: fm(messages.remove_package_size_option_label),
    })
  }

  const loading =
    createPrescriptionLoading ||
    updatePrescriptionLoading ||
    validateDrugLoading

  const okLabel =
    mode === 'create'
      ? fm(messages.submit_button_create_label)
      : fm(messages.submit_button_save_label)

  const submitLabel = validationPending
    ? okLabel
    : fm(messages.submit_button_override_label)

  const handleSubmit = () => {
    if (canSubmit) {
      handleSave({
        consultationId,
        patientId: patient.id,
        consumerNetwork,
      })
    } else {
      updateShowConfirmationWaring(true)
    }
  }

  return (
    <RouteModal onRequestClose={redirectToConsultation}>
      <ModalContent title={fm(messages.title_private)}>
        <SignerAlert
          {...data.consultation.patient.canCreatePrescriptionPdf}
          message={fm(messages.update_patient_details_alert_message)}
        />
        {ENABLE_PRESCRIPTIONS_CONSENT_TO_SHARE &&
          displayGpConsentWarning &&
          !gpConsent && <GpConsentWarning />}
        {isFilledArray(warnings) &&
          warnings.map((warning) => (
            <Message
              type={warning.type}
              title={warning.title}
              key={generateIndex()}
            >
              {warning.message}
            </Message>
          ))}
        <Label value={fm(messages.drug_select_label)}>
          <DrugSelect
            region={data.consultation.region.iso_code}
            className={drugValidation ? '' : styles.selectError}
            value={state.value}
            onChange={handleDrugListChange}
          />
          <sub
            data-testid="drug-form-validation-message"
            className={drugValidation ? styles.hidden : styles.errorMessage}
          >
            {fm(messages.drug_select_must_select_error_message)}
          </sub>
          <div className={styles.drugInfo}>
            {activeIngredients && (
              <div>
                {fm(messages.drug_select_active_ingredients_message, {
                  ingredients: activeIngredients,
                })}
              </div>
            )}
          </div>
        </Label>
        <Label
          value={fm(messages.package_size_label)}
          dataTestId="package-size"
        >
          <ReactSelect
            options={
              options.length
                ? options
                : [
                    {
                      label: fm(
                        messages.package_size_option_none_avaliable_label
                      ),
                    },
                  ]
            }
            disabled={!options.length}
            clearable={false}
            placeholder={
              options.length
                ? fm(messages.package_size_placeholder)
                : fm(messages.package_size_placeholder_no_sizes_available)
            }
            value={state.packSize}
            onChange={handlePackageChange}
          />
        </Label>
        <Grid columns={2} gap={8}>
          <div>
            {!hideActionToolTips && (
              <Info
                info={
                  <span>
                    <FormattedMessage
                      {...messages.dose_select_tooltip_message}
                    />
                  </span>
                }
              />
            )}
            <Label value={fm(messages.dose_select_label)}>
              <DosageEditor
                value={state.dosage}
                onChange={handleInputChange('dosage')}
                options={data.consultation.drugDosageOptions}
              />
            </Label>
          </div>
          <Label value={fm(messages.duration_field_label)}>
            <BabylonInput
              placeholder={fm(messages.duration_field_placeholder)}
              value={state.duration}
              onChange={handleInputChange('duration')}
              data-testid="prescription-duration"
            />
          </Label>
        </Grid>
        <div>
          <Label value={fm(messages.directions_for_patient_label)}>
            <BabylonInput
              placeholder={fm(messages.directions_for_patient_placeholder)}
              value={state.directions}
              onChange={handleInputChange('directions')}
              buttonLabel={
                showSuggest
                  ? fm(messages.directions_for_patient_generate_button_label)
                  : null
              }
              buttonClassName={styles.buttonLabel}
              onButtonClick={handleSuggest('directions')}
              wrapperClassName={directionValidation ? '' : styles.error}
              data-testid="prescription-directions"
            />
            <sub
              className={
                directionValidation ? styles.hidden : styles.errorMessage
              }
              data-testid="drug-form-validation-message"
            >
              {fm(messages.directions_for_patient_required_error_message)}
            </sub>
          </Label>
        </div>
        <Grid columns={1} gap={8}>
          <Label value={fm(messages.quantity_label)}>
            <BabylonInput
              placeholder={fm(messages.quantity_placeholder)}
              value={String(state.quantity)}
              onChange={handleInputChange('quantity')}
              buttonLabel={
                showSuggest ? fm(messages.quanity_calculate_button_label) : null
              }
              buttonClassName={styles.buttonLabel}
              onButtonClick={handleSuggest('quantity')}
              wrapperClassName={quantityValidation ? '' : styles.error}
              data-testid="prescription-quantity"
            />
            <sub
              className={
                quantityValidation ? styles.hidden : styles.errorMessage
              }
              data-testid="drug-form-validation-message"
            >
              {fm(messages.quantity_must_be_above_zero_error_message)}
            </sub>
          </Label>
        </Grid>
        {isRefillVisible && (
          <Grid columns={1}>
            <Label value={fm(messages.refills_label)}>
              <ReactSelect
                options={refillOptions}
                clearable={false}
                value={state.refills}
                onChange={handleRefillsChange}
              />
            </Label>
          </Grid>
        )}
        <div>
          {!hideActionToolTips && (
            <Info
              placement="left"
              info={
                <FormattedMessage {...messages.indication_tooltip_message} />
              }
            />
          )}
          <Label value={fm(messages.indication_label)}>
            {/* This feature flag has been added as approvals have not yet been granted in CA */}
            {/* More details - https://babylonpartners.atlassian.net/browse/CI-43?focusedCommentId=666029 */}
            {fastCtIndicationsEnabled ? (
              <IndicationSelect
                placeholder={fm(messages.indication_placeholder)}
                value={state.indications}
                onAddToList={addIndication}
                onRemoveFromList={removeIndication}
                className={indicationValidations ? '' : styles.selectError}
              />
            ) : (
              // TODO - Remove this when fastCtIndicationsEnabled feature flag has been enabled in all regions
              <IndicationSelectOld
                placeholder={fm(messages.indication_placeholder)}
                value={state.indications}
                onAddToList={addIndication}
                onRemoveFromList={removeIndication}
                className={indicationValidations ? '' : styles.selectError}
              />
            )}
            <sub
              className={
                indicationValidations ? styles.hidden : styles.errorMessage
              }
              data-testid="drug-form-validation-message"
            >
              {fm(messages.indication_must_select_one_error_message)}
            </sub>
          </Label>
        </div>
      </ModalContent>
      <ModalFooter
        className={cn(styles.footer, {
          [styles.notConfirmed]: notConfirmed,
        })}
      >
        {showConsentCheckbox ? (
          <div
            className={styles.consentConfirm}
            data-testid="consent-confirm-checkbox"
          >
            <Checkbox
              checked={confirmed}
              onChange={() => {
                updateShowConfirmationWaring(false)
                updateConfirmed(!confirmed)
              }}
            />{' '}
            <Text size="medium" color="black-type">
              {fm(messages.missing_consent_warning)}
            </Text>
          </div>
        ) : (
          <div />
        )}
        <div>
          <div className={styles.buttons}>
            <Button inline intent="secondary" onClick={handleClose}>
              {fm(messages.cancel_button_label)}
            </Button>
            <Button
              inline
              intent="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              {submitLabel}
            </Button>
          </div>
          {notConfirmed && (
            <Text size="medium" color="error">
              {fm(messages.missing_consent_alert)}
            </Text>
          )}
        </div>
      </ModalFooter>
    </RouteModal>
  )
}

export default PrescriptionModal
