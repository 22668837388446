import classnames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

interface ModalFooterProps {
  className?: string
  children?: React.ReactNode
  rest?: any
}

const ModalFooter = ({ className, children, ...rest }: ModalFooterProps) => (
  <div className={classnames(styles.modalFooter, className)} {...rest}>
    {children}
  </div>
)

export default ModalFooter
