import { CONSULTATION_STATUSES } from '~/constants'
import { ScheduleStatus } from '~/features/schedule/ConsultationScheduleItem/utils'

export const getStatus = (status: string): ScheduleStatus => {
  if (status === CONSULTATION_STATUSES.NO_SHOW) {
    return ScheduleStatus.noShow
  }
  if (status !== CONSULTATION_STATUSES.PAID) {
    return ScheduleStatus.complete
  }
  return ScheduleStatus.upcoming
}
