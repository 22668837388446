import { defineMessages } from 'react-intl'

const baseId = 'dashboard.consultation_schedule_item'

export default defineMessages({
  age_unit: {
    id: `${baseId}.age_unit`,
    defaultMessage: '{ageInYears}y',
  },
  minor: {
    id: `${baseId}.minor`,
    defaultMessage: 'minor',
  },
  no_rec_consent: {
    id: `${baseId}.no_rec_consent`,
    defaultMessage: 'no rec consent',
  },
  interpreter: {
    id: `${baseId}.interpreter`,
    defaultMessage: 'interpreter',
  },
  consumer_network_not_available: {
    id: `${baseId}.consumer_network_not_available`,
    defaultMessage: 'Not available',
  },
  attachment_tooltip: {
    id: `${baseId}.attachment_tooltip`,
    defaultMessage: 'Image Attachment',
  },
  incomplete_icon_tooltip: {
    id: `${baseId}.incomplete_icon_tooltip`,
    defaultMessage: 'Recent incomplete consultation in patient history',
  },
  alert_tooltip: {
    id: `${baseId}.alert_tooltip_v2`,
    defaultMessage: 'Patient alerts',
  },
  no_show_tooltip: {
    id: `${baseId}.no_show_tooltip`,
    defaultMessage: 'Marked as no show',
  },
  completed_tooltip: {
    id: `${baseId}.completed_tooltip`,
    defaultMessage: 'Completed',
  },
  failed_to_load_patient: {
    id: `${baseId}.failed_to_load_patient`,
    defaultMessage: "We couldn't load this patient's information",
  },
  duration: {
    id: `${baseId}duration`,
    defaultMessage: '{min} min',
  },
})
