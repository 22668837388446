import { sanitizeUrl } from '@braintree/sanitize-url'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface LinkProps {
  to?: string
  href?: string
  target?: string
  rel?: any
  children?: React.ReactNode
  dataTestId?: string
  onClick?: () => void
  className?: string
}
const Link = ({
  to,
  href,
  target,
  rel,
  children,
  dataTestId,
  onClick,
  className,
  ...props
}: LinkProps) => {
  const safeUrl = sanitizeUrl(href || to || '')
  const internal = /^\/(?!\/)/.test(safeUrl)

  if (internal) {
    return (
      <RouterLink
        className={className}
        onClick={onClick}
        to={safeUrl}
        data-testid={dataTestId}
        {...props}
      >
        {children}
      </RouterLink>
    )
  }
  /**
   * Set "rel" to secure value when "target" is _blank
   */
  const relationship = target === '_blank' && !rel ? 'noopener' : undefined

  return (
    <a
      {...props}
      className={className}
      onClick={onClick}
      href={safeUrl}
      target={target}
      rel={relationship}
      data-testid={dataTestId}
    >
      {children}
    </a>
  )
}

export default Link
