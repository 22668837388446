import React, { Suspense } from 'react'

export const suspense = <T extends React.LazyExoticComponent<any>>(
  Component: T,
  loading: React.ReactNode = null
) => (props: Parameters<T>[0]) => (
  <Suspense fallback={loading}>
    <Component {...props} />
  </Suspense>
)
