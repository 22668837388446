import { InMemoryCacheConfig } from '@apollo/client/cache'

import generatedIntrospection from '~/generated/introspection-result'

import typePolicies from './typePolicies'

const config: InMemoryCacheConfig = {
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies,
}

export default config
