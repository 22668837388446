import { $ref, Config } from './generated/uk'

import messages from '../messages/config.messages'

const consultationSummaryChildren = [
  $ref('preconsultationSummary'),
  $ref('bookingReasonSummary'),
  $ref('attachmentsSummary'),
  $ref('carePlanSummary'),
  $ref('examinationSummary'),
  $ref('diagnosisSummary'),
  $ref('prescriptionsSummary'),
  $ref('testSummary'),
  $ref('sickNotesSummary'),
  $ref('referralsSummary'),
  $ref('appointmentInvitesSummary'),
  $ref('nhsAppointmentInvitesSummary'),
  $ref('workflowActionsSummary'),
]

const config: Config = {
  main: {
    module: 'Main',
    options: {
      layout: $ref('layout'),
    },
  },

  layout: {
    module: 'Layout',
    reactElement: true,
    options: {
      header: $ref('header'),
      navigation: $ref('navigation'),
      routes: $ref('routes'),
    },
  },

  routes: {
    module: 'Routes',
    reactElement: true,
    options: {
      defaultRoute: '/consultations',
      routes: [
        {
          path: '/consultations/:date?/:status?',
          route: $ref('schedulePage'),
        },
        {
          path: '/consultation/:id',
          route: $ref('consultationPage'),
        },
        {
          path: '/all-consultations',
          route: $ref('allConsultationsPage'),
        },
        {
          path: '/prescriptions',
          route: $ref('prescriptionsPage'),
        },
        {
          path: '/tests',
          route: $ref('testsKitsPage'),
        },
        {
          path: '/admin/consultants/:userid/availability',
          route: $ref('adminPortalPage'),
        },
        {
          path: '/experiments',
          route: $ref('experimentsPage'),
        },
      ],
    },
  },

  header: {
    module: 'Header',
    reactElement: true,
    options: {
      scheduleEnabled: true,
      clinicianRatingEnabled: true,
      languageSelectorEnabled: false,
      telusLogo: false,
      ConsultationScheduleItem: $ref('headerScheduleItem'),
      supportOptions: [
        'clinops',
        'clinical',
        'risk-management',
        'technology-help',
      ],
      consultationContext: $ref('consultationContext'),
    },
  },

  headerScheduleItem: {
    module: 'ConsultationScheduleItemPlugin',
    options: {
      hideIncompleteConsultationNotifications: false,
    },
  },

  navigation: {
    module: 'Navigation',
    options: {
      items: [
        $ref('schedulePageNavigationItem'),
        $ref('allConsultationsPageNavigationItem'),
        $ref('prescriptionsPageNavigationItem'),
        $ref('testsKitsPageNavigationItem'),
        $ref('adminPortalPageNavigationItem'),
        '---DIVIDER---',
        $ref('linksAndResourcesPlugin'),
        $ref('releaseNotesPlugin'),
      ],
    },
  },

  schedulePage: {
    module: 'SchedulePage',
    options: {
      ConsultationScheduleItem: $ref('consultationScheduleItem'),
    },
    reactElement: true,
  },

  experimentsPage: {
    module: 'ExperimentsPage',
    reactElement: true,
  },

  consultationScheduleItem: {
    module: 'ConsultationScheduleItemPlugin',
    options: {
      hideIncompleteConsultationNotifications: false,
      CheckIn: $ref('consultationScheduleItemAppointmentCheckIn'),
    },
  },

  consultationScheduleItemAppointmentCheckIn: {
    module: 'ScheduleCheckInPlugin',
    options: {
      displayLocation: false,
      displayDetailedError: false,
    },
  },

  schedulePageNavigationItem: {
    module: 'SchedulePageNavigationItem',
    options: {
      path: '/consultations',
    },
    reactElement: true,
  },

  allConsultationsPage: {
    module: 'AllConsultationsPage',
    reactElement: true,
  },

  allConsultationsPageNavigationItem: {
    module: 'AllConsultationsPageNavigationItem',
    options: {
      path: '/all-consultations',
    },
    reactElement: true,
  },

  prescriptionsPage: {
    module: 'PrescriptionsPage',
    reactElement: true,
  },

  prescriptionsPageNavigationItem: {
    module: 'PrescriptionsPageNavigationItem',
    options: {
      path: '/prescriptions',
    },
    reactElement: true,
  },

  testsKitsPage: {
    module: 'TestsKitsPage',
    reactElement: true,
  },

  testsKitsPageNavigationItem: {
    module: 'TestsKitsPageNavigationItem',
    options: {
      path: '/tests',
    },
    reactElement: true,
  },

  adminPortalPage: {
    module: 'AdminPortalPage',
    reactElement: true,
  },

  adminPortalPageNavigationItem: {
    module: 'AdminPortalPageNavigationItem',
    options: {
      path: '/admin/consultants',
    },
    reactElement: true,
  },

  editedConsultationWarningModelProvider: {
    module: 'EditedConsultationWarningModelProvider',
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  consultationPage: {
    module: 'ConsultationLitePage',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      main: [
        $ref('preparationSectionGroup'),
        $ref('carePlanSectionGroup'),
        $ref('examinationSectionGroup'),
        $ref('diagnosisSectionGroup'),
        $ref('actionsSectionGroup'),
      ],
      providers: [
        $ref('editedConsultationWarningModelProvider'),
        $ref('callStatusReducerModelProvider'),
      ],
      call: $ref('call'),
      context: $ref('consultationProfile'),
      review: $ref('currentConsultationSummary'),
      actions: $ref('consultationActionsPlugin'),
      completeDialog: $ref('completeDialogPlugin'),
      postConsultationFormModal: $ref('postConsultationFormModal'),
      noteAssistantSummary: $ref('noteAssistantSummary'),
      callRecording: $ref('callRecording'),
      eventBus: $ref('eventBus'),
      incompleteConsultationWarningModal: $ref(
        'incompleteConsultationWarningModal'
      ),
      externalTabsWarningBanner: $ref('externalTabsWarningModal'),
    },
  },

  callActiveModalPlugin: {
    module: 'CallActiveModalPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      redirectUrl: '/consultation/:id/review',
      stayOnPageUrl: '/consultation/:id',
    },
  },

  consultationActionsPlugin: {
    module: 'ConsultationActionsPlugin',
    reactElement: true,
    options: {
      reviewEnabled: true,
      completeConsultationModel: $ref('completeConsultationModel'),
      consultationContext: $ref('consultationContext'),
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
      callStatusReducerModelProvider: $ref('callStatusReducerModelProvider'),
      callActiveModal: $ref('callActiveModalPlugin'),
    },
  },

  postConsultationFormModal: {
    module: 'PostConsultationFormModal',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  callStatusReducerModelProvider: {
    module: 'CallStatusReducerModelProvider',
    options: {
      callMedium: 'voice_and_video',
    },
  },

  call: {
    module: 'CallPlugin',
    reactElement: true,
    options: {
      callStatusReducerModelProvider: $ref('callStatusReducerModelProvider'),
      consultationContext: $ref('consultationContext'),
      callQualityFeedbackEnabled: true,
      callMedium: 'voice_and_video',
      recordingEnabled: true,
      eventBus: $ref('eventBus'),
    },
  },

  consultationProfile: {
    module: 'ConsultationProfilePlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      requestedTranslationEnabled: false,
      checkIn: $ref('consultationProfileAppointmentCheckIn'),
      patientRecordDeepLink: $ref('patientRecordDeepLink'),
    },
  },

  patientRecordDeepLink: {
    module: 'PatientRecordDeepLinkPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  consultationProfileAppointmentCheckIn: {
    module: 'ConsultationCheckInPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      displayLocation: true,
      displayDetailedError: true,
    },
  },

  noteAssistantSummary: {
    module: 'NoteAssistantSummaryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
    },
  },

  incompleteConsultationWarningModal: {
    module: 'IncompleteConsultationWarningModalPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      hideIncompleteConsultationModal: false,
    },
  },

  externalTabsWarningModal: {
    module: 'ExternalTabsWarningBannerPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      route: '/consultation/:id',
    },
  },

  carePlanSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.care_plan_section_group_title,
      titleColor: 'var(--brand-color-dark-blue)',
      borderColor: 'var(--brand-color-blue)',
      children: [$ref('carePlanSection')],
    },
  },

  carePlanSection: {
    module: 'CarePlanSectionPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  preparationSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.preparation_section_group_title,
      titleColor: 'var(--brand-color-purple)',
      borderColor: 'var(--brand-color-purple)',
      children: [
        $ref('patientAlerts'),
        $ref('bookingReason'),
        $ref('preConsultationFlow'),
        $ref('patientSummary'),
        $ref('patientTimeline'),
        $ref('patientDetails'),
        $ref('gpDetails'),
      ],
    },
  },

  bookingReason: {
    module: 'BookingReasonPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  patientAlerts: {
    module: 'PatientAlertsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  patientSummary: {
    module: 'PatientSummaryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      consultationSummary: $ref('pastConsultationSummary'),
    },
  },

  documentRepository: {
    module: 'DocumentRepositoryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  patientTimelineConsultationEvent: {
    module: 'TimelineConsultationEventPlugin',
    options: {
      includeActions: false,
    },
  },

  patientTimeline: {
    module: 'PatientTimelinePlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      consultationSummary: $ref('pastConsultationSummary'),
      timelineConsultationEvent: $ref('patientTimelineConsultationEvent'),
      eventTypes: {
        consultationsEnabled: true,
        chatscriptConversationsEnabled: true,
      },
      enableFutureConsultations: true,
    },
  },

  patientDetails: {
    module: 'PatientDetailsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      hideIdVerificationEnabled: false,
    },
  },

  preConsultationFlow: {
    module: 'PreConsultationFlowPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  examinationSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.examination_section_group_title,
      titleColor: 'var(--brand-color-dark-turquoise)',
      borderColor: 'var(--brand-color-turquoise)',
      children: [
        $ref('examinationNotes'),
        $ref('patientMetrics'),
        $ref('medicalHistory'),
      ],
    },
  },

  examinationNotes: {
    module: 'ExaminationNotesPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
    },
  },

  patientMetrics: {
    module: 'PatientMetricsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  medicalHistory: {
    module: 'MedicalHistoryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  diagnosisSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.diagnosis_section_group_title,
      titleColor: 'var(--brand-color-dark-pink)',
      borderColor: 'var(--brand-color-pink)',
      children: [$ref('diagnosis')],
    },
  },

  actionsSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.actions_section_group_title,
      titleColor: 'var(--brand-color-orange)',
      borderColor: 'var(--brand-color-orange)',
      children: [
        $ref('prescriptions'),
        $ref('privateReferrals'),
        $ref('privateTests'),
        $ref('sickNotes'),
        $ref('attachments'),
        $ref('appointmentInvites'),
        $ref('nhsAppointmentInvites'),
        $ref('workflowActions'),
      ],
    },
  },

  appointmentInvites: {
    module: 'AppointmentInvitesPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  diagnosis: {
    module: 'DiagnosisPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
    },
  },

  privateReferrals: {
    module: 'ReferralsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
    },
  },

  nhsAppointmentInvites: {
    module: 'NhsAppointmentInvitesPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      faceToFaceInvitesEnabled: true,
      digitalInvitesEnabled: true,
    },
  },

  workflowActions: {
    module: 'WorkflowActionsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  prescriptions: {
    module: 'PrescriptionsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
    },
  },

  privateTests: {
    module: 'TestsSectionPlugin',
    reactElement: true,
    options: {
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
    },
  },

  gpDetails: {
    module: 'GpDetailsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  sickNotes: {
    module: 'SickNotesPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      editedConsultationWarningModel: $ref(
        'editedConsultationWarningModelProvider'
      ),
    },
  },

  attachments: {
    module: 'AttachmentsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  callRecording: {
    module: 'CallRecordingPlugin',
    reactElement: true,
    options: {
      eventBus: $ref('eventBus'),
      consultationContext: $ref('consultationContext'),
    },
  },

  currentConsultationSummary: {
    module: 'CurrentConsultationSummaryPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      childComponents: consultationSummaryChildren,
    },
  },

  pastConsultationSummary: {
    module: 'PastConsultationSummaryPlugin',
    options: {
      childComponents: consultationSummaryChildren,
    },
  },

  preconsultationSummary: {
    module: 'PreconsultationSummaryPlugin',
  },

  bookingReasonSummary: {
    module: 'BookingReasonSummaryPlugin',
  },

  testSummary: {
    module: 'TestsSummaryPlugin',
  },

  sickNotesSummary: {
    module: 'SickNotesSummaryPlugin',
  },

  prescriptionsSummary: {
    module: 'PrescriptionsSummaryPlugin',
  },

  attachmentsSummary: {
    module: 'AttachmentsSummaryPlugin',
  },

  examinationSummary: {
    module: 'ExaminationSummaryPlugin',
  },

  diagnosisSummary: {
    module: 'DiagnosisSummaryPlugin',
  },

  referralsSummary: {
    module: 'ReferralsSummaryPlugin',
  },

  carePlanSummary: {
    module: 'CarePlanSummaryPlugin',
  },

  signatureRxPinPlugin: {
    module: 'SignatureRxPinPlugin',
    options: {
      consultationContext: $ref('consultationContext'),
      prescriptionPromotionModel: $ref('prescriptionPromotionModel'),
    },
    reactElement: true,
  },

  appointmentInvitesSummary: {
    module: 'AppointmentInvitesSummaryPlugin',
    options: {
      enableOnlyForGlobalInvites: true,
    },
  },

  nhsAppointmentInvitesSummary: {
    module: 'NhsAppointmentInvitesSummaryPlugin',
    options: {
      faceToFaceInvitesEnabled: true,
      digitalInvitesEnabled: true,
    },
  },

  workflowActionsSummary: {
    module: 'WorkflowActionsSummaryPlugin',
  },

  releaseNotesPlugin: {
    module: 'ReleaseNotesPlugin',
    options: {
      releaseNoteCountryCode: undefined,
    },
    reactElement: true,
  },

  linksAndResourcesPlugin: {
    module: 'LinksAndResourcesPlugin',
    reactElement: true,
  },

  carePlansCompletionModel: {
    module: 'CarePlansCompletionModel',
    options: {
      consultationContext: $ref('consultationContext'),
      global: $ref('global'),
    },
  },

  prescriptionPromotionModel: {
    module: 'PrescriptionPromotionModel',
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  completeConsultationModel: {
    module: 'CompleteConsultationModel',
    options: {
      consultationContext: $ref('consultationContext'),
      extensions: [
        $ref('prescriptionPromotionModel'),
        $ref('carePlansCompletionModel'),
      ],
      redirectUrl: '/consultation/:consultationId/review/form',
    },
  },

  completeDialogPlugin: {
    module: 'CompleteDialogPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      completeConsultationModel: $ref('completeConsultationModel'),
      showDefaultMessage: false,
      extensions: [$ref('signatureRxPinPlugin')],
    },
  },
}

export default config
