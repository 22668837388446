import React, { ReactNode, CSSProperties } from 'react'
import cn from 'classnames'
import pickBy from 'lodash/pickBy'

import './Cell.scss'

interface Props {
  width?: number | string
  height?: number | string
  left?: number
  top?: number
  middle?: boolean
  center?: boolean
  area?: string
  className?: string
  children?: ReactNode
  style?: CSSProperties
  forwardedRef?: React.Ref<HTMLDivElement>
}

const isTruthyOrZero = (value?: string | number): boolean =>
  !!value || value === 0

const Cell = ({
  width,
  height,
  left,
  top,
  middle,
  center,
  area,
  className,
  children,
  style,
  forwardedRef,
  ...rest
}: Props) => {
  const mergedStyles = pickBy(
    {
      gridArea: area,
      gridColumnStart: left,
      gridRowStart: top,
      gridColumnEnd: width && `span ${width}`,
      gridRowEnd: height && `span ${height}`,
      ...style,
    },
    isTruthyOrZero
  ) as CSSProperties

  return (
    <div
      className={cn(
        'core-ui-cell',
        center && `core-ui-cell--center`,
        middle && `core-ui-cell--middle`,
        className
      )}
      style={mergedStyles}
      ref={forwardedRef}
      {...rest}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(
  (props: Props, ref?: React.Ref<HTMLDivElement>) => (
    <Cell forwardedRef={ref} {...props} />
  )
)
