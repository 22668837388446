import React, { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useConsultantUser } from '@babylon/babylon-user'
import { Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  NORMAL_REVIEW_LABEL,
  PROBLEMS_LIST_MODAL,
  PROBLEMS_LIST_REVIEW_ACTION,
  REVIEW_CATEGORY,
} from '~/constants/analytics'
import { useModalViewAndDurationTracker } from '~/core/analytics'
import {
  ScrollablePanel,
  ScrollElement,
  ScrollProvider,
  scrollTo,
} from '~/core/scroll-tracker'
import { useUrlSearchParams } from '~/core/url'
import { useConsultationId } from '~/features/consultation'
import PastConsultationSummaryModalTitle from '~/features/consultation-summary/ConsultationSummary/PastConsultationSummaryModalTitle'
import {
  preprocessTimeline,
  reducerByProblems,
} from '~/features/problems-list/utils/grouping'
import { GroupedProblems, Problem } from '~/features/problems-list/utils/types'
import {
  ProblemsListQueryResult,
  useConsultationsForProblemsListQuery,
  useProblemsListQuery,
} from '~/generated'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Slideout } from '~/ui/Modal'

import ScrollHeader from './scrollHeader/ScrollHeader'

import messages from './ProblemsListSummaryModal.messages'
import styles from './styles.module.scss'

interface ProblemsListSummaryModalProps {
  ConsultationSummary: ({
    consultationId,
  }: {
    consultationId: string
  }) => ReactElement | null
}

const getConsIdsFromProblList = (
  problemListData: ProblemsListQueryResult['data'],
  consultationId: string,
  code: string,
  note: string
): Problem[] => {
  if (problemListData?.patient?.history) {
    const groupedProblemList: GroupedProblems = preprocessTimeline(
      problemListData.patient.history,
      consultationId
    ).reduce(reducerByProblems, {})

    const dataForProblem = code
      ? groupedProblemList[code]
      : groupedProblemList[note]

    return dataForProblem || []
  }
  return []
}

const ProblemsListSummaryModal = ({
  ConsultationSummary,
}: ProblemsListSummaryModalProps) => {
  const fm = useFormatMessage()
  const { consultant: currentConsultant } = useConsultantUser()
  const history = useHistory()
  const consultationId = useConsultationId()
  const query = useUrlSearchParams()
  const patientId = query.get('patientId') || ''
  const code = query.get('code') || ''
  const note = query.get('note') || ''
  const eventId = query.get('eventId') || ''

  useModalViewAndDurationTracker({
    category: REVIEW_CATEGORY,
    label: NORMAL_REVIEW_LABEL,
    action: PROBLEMS_LIST_REVIEW_ACTION,
    modalName: PROBLEMS_LIST_MODAL,
  })

  const {
    loading: problemListLoading,
    error: problemListError,
    data: problemListData,
    refetch: problemListRefetch,
  } = useProblemsListQuery({
    variables: {
      patientId,
    },
  })

  useEffect(() => {
    if (eventId && document.getElementById(eventId)) {
      scrollTo(eventId)
    }
  }, [eventId])

  const onClose = () => {
    history.replace(`/consultation/${consultationId}`)
  }

  const dataForProblem: Problem[] = getConsIdsFromProblList(
    problemListData,
    consultationId,
    code,
    note
  )

  const currentCode = dataForProblem.length ? dataForProblem[0].code : undefined

  const currentNote =
    !currentCode && dataForProblem.length ? dataForProblem[0].note : 'N/A'

  const consultationIds: string[] = dataForProblem.map(
    (entry: Problem) => entry && entry.consultationId
  )

  const skip = !problemListData || problemListLoading

  const {
    loading: consultationsLoading,
    error: consultationsError,
    data: consultationsData,
    refetch: consultationsRefetch,
  } = useConsultationsForProblemsListQuery({
    variables: { searchCriteria: { consultationIds } },
    skip,
  })

  return (
    <Slideout onClose={onClose} loading={consultationsLoading}>
      {() => {
        if (problemListLoading) {
          return (
            <Spinner
              color="#87919e"
              size="medium"
              centered
              className={styles.spinner}
            />
          )
        }

        if (problemListError) {
          return (
            <ErrorPanel
              error={problemListError}
              fill="container"
              center
              retry={() => problemListRefetch()}
              title={fm(messages.error_loading_history)}
            />
          )
        }

        if (consultationsError) {
          return (
            <ErrorPanel
              error={consultationsError}
              fill="container"
              center
              retry={() => consultationsRefetch()}
              title={fm(messages.error_loading_consultations)}
            />
          )
        }

        if (consultationsData?.consultationsv2) {
          const consultations = consultationsData?.consultationsv2?.items

          return (
            <ScrollProvider data-testid="problems-list-modal">
              <div className={styles.container}>
                <ScrollHeader
                  consultations={consultations}
                  code={currentCode}
                  note={currentNote}
                  className={styles.stickyHeader}
                  highlightedEvent={eventId}
                />
                <ScrollablePanel
                  className={styles.scrollContainer}
                  data-testid="problems-list-modal-consultation-summaries"
                >
                  {consultations.map((consultation) => {
                    return (
                      <ScrollElement
                        threshold={[0.2, 0.3]}
                        name={consultation.id}
                        key={consultation.id}
                      >
                        <div
                          className={styles.consultationReview}
                          id={consultation.id}
                        >
                          <div data-testid="problems-list-modal-consultation-summary-title">
                            <PastConsultationSummaryModalTitle
                              className={styles.title}
                              status={consultation.status}
                              consultant={consultation.consultant}
                              scheduledTime={consultation.scheduledTime}
                              currentConsultant={currentConsultant}
                            />
                          </div>
                          <div data-testid="problems-list-modal-consultation-summary">
                            <ConsultationSummary
                              consultationId={consultation.id}
                            />
                          </div>
                        </div>
                      </ScrollElement>
                    )
                  })}
                </ScrollablePanel>
              </div>
            </ScrollProvider>
          )
        }
      }}
    </Slideout>
  )
}

export default ProblemsListSummaryModal
