import { gql } from '@apollo/client'

export const addWorkflowsToCache = (cache: any, { data }: any) => {
  cache.consultation.workflows = data.createWorkflow
  return cache
}

export const WorkflowSubTypesQuery = gql`
  query WorkflowSubTypes($type: WorkflowType!) {
    workflowSubTypes(type: $type) {
      value
      label
    }
  }
`

export const WorkflowSubSpecialtiesQuery = gql`
  query WorkflowSubSpecialties($type: WorkflowType!, $subType: String!) {
    workflowSubSpecialties(type: $type, subType: $subType)
  }
`

export const WorkflowConsultationQuery = gql`
  query WorkflowConsultation($consultationId: ID!) {
    consultation(id: $consultationId) {
      id
      consumerNetwork
      patient {
        id
        full_name
        email
        date_of_birth
        public_healthcare_identifier
        clinical_commissioning_group {
          id
          code
          name
        }
        contact_details {
          address_first_line
          address_second_line
          address_third_line
          address_post_code
          phone_country_code
          phone_number
        }
        region {
          id
          iso_code
        }
      }
    }
  }
`
