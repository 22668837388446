import get from 'lodash/get'

import { presetStyles } from '@babylon/core-ui'

import { Consultation } from '~/generated'

export const mapStateToInvestigationWorkflow = (state: any) => ({
  type: 'investigation',
  sub_type: state.testType,
  notes: state.notes,
  urgency: state.urgency,
})

export const mapConsultationToWorkflowData = (
  consultation: Consultation,
  consultantId: string,
  consultantName: string
): any => {
  const { patient, consumerNetwork = null } = consultation
  const {
    contact_details: {
      address_first_line = '',
      address_second_line = '',
      address_third_line = '',
      address_post_code,
      phone_country_code = '',
      phone_number = '',
    },
    public_healthcare_identifier = null,
    clinical_commissioning_group: ccg = null,
  }: any = patient

  const data: any = {
    consultation_id: consultation.id,
    member_address: {
      address_line_1: address_first_line,
      address_line_2: address_second_line,
      admin_area_2: address_third_line,
      postal_code: address_post_code,
      country_code: get(patient, 'region.iso_code'),
    },
    clinician_id: consultantId,
    member_contact_number: phone_country_code + phone_number,
    member_date_of_birth: get(patient, 'date_of_birth'),
    member_email: get(patient, 'email'),
    member_id: get(patient, 'id'),
    member_name: get(patient, 'full_name'),
    member_consumer_network: String(consumerNetwork),
    member_ccg_name: ccg ? ccg.name : null,
    member_ccg_code: ccg ? ccg.code : null,
  }

  if (public_healthcare_identifier) {
    data.nhs_number = public_healthcare_identifier
  }

  if (consultantName) {
    data.clinician_name = consultantName
  }

  return data
}

export const workflowStatusColors: {
  [key: string]: typeof presetStyles[number]
} = {
  submitted: 'reverse-grey',
  in_progress: 'clinical-blue',
  completed: 'clinical-green',
  'in progress': 'clinical-blue',
}

export const workflowStateColours: {
  [key: string]: typeof presetStyles[number]
} = {
  COMPLETED: 'clinical-green',
  SUSPENDED: 'reverse-grey',
  ACTIVE: 'clinical-blue',
  INTERNALLY_TERMINATED: 'reverse-grey',
  EXTERNALLY_TERMINATED: 'reverse-grey',
  UNKNOWN: 'reverse-grey',
}
