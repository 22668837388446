import React from 'react'

import restrictedClipboard from '@babylon/restricted-clipboard'

import { HIGHLIGHT } from './constants'

export default {
  onPaste(event, editor, next) {
    /**
     * Get pasted data via restricted clipboard cache
     */
    if (restrictedClipboard.shouldUseCustomClipboard(event)) {
      return editor.insertInline({
        type: HIGHLIGHT,
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: restrictedClipboard.getValue(),
              },
            ],
          },
        ],
      })
    }

    return next()
  },
  renderNode: (props, editor, next) => {
    const { attributes, children, node } = props

    if (node.type === HIGHLIGHT) {
      return <mark {...attributes}>{children}</mark>
    }

    return next()
  },
}
