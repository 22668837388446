import { useMutation } from '@apollo/client'
import React, { useState } from 'react'

import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { logException } from '~/core/sentry'
import EmptyMessage from '~/ui/EmptyMessage'
import { useNotify } from '~/ui/Notification'

import { REVOKE_RECORDING_CONSENT } from './mutations'

import messages from './RecordingConsent.messages'
import styles from './styles.module.scss'

interface RecordingConsentProps {
  isRevokedOrRefused: boolean
  consultationId: string
}

const RecordingConsent = ({
  isRevokedOrRefused,
  consultationId,
}: RecordingConsentProps) => {
  const fm = useFormatMessage()
  const notify = useNotify()
  const [revokeRecordingConsentMutation] = useMutation(REVOKE_RECORDING_CONSENT)
  const [loading, setLoading] = useState(false)

  const handleRevokeRecordingConsent = async () => {
    try {
      setLoading(true)
      await revokeRecordingConsentMutation({
        variables: {
          id: consultationId,
        },
      })
    } catch (exception) {
      logException(exception)

      notify({
        title: fm(messages.revoke_recording_title),
        message: fm(messages.revoke_recording_message),
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div data-testid="recording-consent">
      {isRevokedOrRefused ? (
        <EmptyMessage className={styles.recordingConsentEmpty}>
          {fm(messages.consent_revoked)}
        </EmptyMessage>
      ) : (
        <Button
          loading={loading}
          fill
          intent="secondary"
          onClick={handleRevokeRecordingConsent}
        >
          {fm(messages.revoke_consent)}
        </Button>
      )}
    </div>
  )
}

export default RecordingConsent
