import { defineMessages } from 'react-intl'

const baseId = 'prescriptions_states'

export default defineMessages({
  DRAFT: {
    id: `${baseId}.state.draft`,
    defaultMessage: 'Draft',
  },
  PRESCRIPTION_REQUESTED: {
    id: `${baseId}.state.prescription_requested`,
    defaultMessage: 'Requested',
  },
  APPROVAL_PENDING: {
    id: `${baseId}.state.approval_pending`,
    defaultMessage: 'Approval Needed',
  },
  REJECTED: {
    id: `${baseId}.state.rejected`,
    defaultMessage: 'Rejected',
  },
  APPROVED: {
    id: `${baseId}.state.approved`,
    defaultMessage: 'Approved',
  },
  PATIENT_DECISION_PENDING: {
    id: `${baseId}.state.patient_decision_pending`,
    defaultMessage: 'Patient Decision Pending',
  },
  PRESCRIPTION_CREATED: {
    id: `${baseId}.state.prescription_created`,
    defaultMessage: 'Prescription PDF Created',
  },
  FAILED_CREATING_PRESCRIPTION_PDF: {
    id: `${baseId}.state.failed_creating_prescription_pdf`,
    defaultMessage: 'Failed Creating Prescription PDF',
  },
  PHARMACY_SELECTED: {
    id: `${baseId}.state.pharmacy_selected`,
    defaultMessage: 'Pharmacy Selected',
  },
  PHARMACY_SUPPLIER_SELECTED: {
    id: `${baseId}.state.pharmacy_supplier_selected`,
    defaultMessage: 'Pharmacy Supplier Selected',
  },
  DELIVERY_PENDING: {
    id: `${baseId}.state.delivery_pending`,
    defaultMessage: 'Delivery Pending',
  },
  FAX_FAILED_WILL_RETRY: {
    id: `${baseId}.state.fax_failed_will_retry`,
    defaultMessage: 'Fax failed. Retrying...',
  },
  FAX_PERMANENTLY_FAILED: {
    id: `${baseId}.state.fax_permanently_failed`,
    defaultMessage: 'Fax failed',
  },
  FAX_SENDING: {
    id: `${baseId}.state.fax_sending`,
    defaultMessage: 'Fax sending',
  },
  FAXED: {
    id: `${baseId}.state.faxed`,
    defaultMessage: 'Fax sent',
  },
  FAX_NUMBER_MISSING: {
    id: `${baseId}.state.fax_number_missing`,
    defaultMessage: 'Fax number missing',
  },
  E_PRESCRIPTION_CODE_SENT: {
    id: `${baseId}.state.e_prescription_code_sent`,
    defaultMessage: 'e-prescription code sent',
  },
  DISPENSED: {
    id: `${baseId}.state.dispensed`,
    defaultMessage: 'Dispensed',
  },
  CONFIRMED_RECEIVED: {
    id: `${baseId}.state.confirmed_received`,
    defaultMessage: 'Confirm Received',
  },
  VOIDED: {
    id: `${baseId}.state.voided`,
    defaultMessage: 'Voided',
  },
  PRINTED: {
    id: `${baseId}.state.printed`,
    defaultMessage: 'Printed',
  },
  ISSUED: {
    id: `${baseId}.state.issued`,
    defaultMessage: `Issued`,
  },
  EXPIRED: {
    id: `${baseId}.state.expired`,
    defaultMessage: 'Expired',
  },
  unknown_state: {
    id: `${baseId}.state.unknown`,
    defaultMessage: 'State unknown',
  },
})
