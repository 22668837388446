import analytics from '~/core/analytics'
import { useDurationTimer, useMount, useUnmount } from '~/core/hooks'

export interface DurationTrackerProps {
  label?: string
  action: string
  category: string
}

const useDurationTracker = ({
  category,
  action,
  label,
}: DurationTrackerProps) => {
  const { startTimer, duration } = useDurationTimer()

  useMount(() => {
    startTimer()
  })

  useUnmount(() => {
    analytics.trackEvent({
      category,
      action,
      label,
      value: duration,
    })
  })
}

export default useDurationTracker
