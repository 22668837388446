import React, { useEffect, useRef, useState } from 'react'

import ListItem from '../ListItem'
import Position from '../Position'

import styles from './styles.module.scss'

const DropdownList = (props) => {
  const { onMount, options, header, footer, anchor, dropdownId } = props
  const listRef = useRef(null)

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, []) // eslint-disable-line

  const handleListItemMouseEnter = ({ index, option }) => {
    const { focusedByMouseIndex } = props

    const { onListItemFocus } = props

    if (onListItemFocus && index !== focusedByMouseIndex) {
      onListItemFocus({ index, option })
    }
  }

  const handleListItemMouseDown = ({ index, option }) => {
    const { onListItemSelect } = props

    if (onListItemSelect) {
      onListItemSelect({ index, option })
    }
  }

  const getFocusedByMouseItem = () => {
    const { focusedByMouseIndex, focusedByKeyboardIndex, options } = props
    return focusedByMouseIndex === focusedByKeyboardIndex
      ? options[focusedByMouseIndex] || null
      : null
  }

  const getFocusedByKeyboardItem = () => {
    const { focusedByKeyboardIndex, options } = props
    return options[focusedByKeyboardIndex] || null
  }

  const scrollToListItem = (element) => {
    if (
      listRef &&
      listRef.current.scrollHeight > listRef.current.clientHeight
    ) {
      const scrollBottom =
        listRef.current.clientHeight + listRef.current.scrollTop
      const elementBottom = element.offsetTop + element.offsetHeight
      if (elementBottom > scrollBottom) {
        listRef.current.scrollTop = elementBottom - listRef.current.clientHeight
      } else if (element.offsetTop < listRef.current.scrollTop) {
        listRef.current.scrollTop = element.offsetTop
      }
    }
  }

  const [position, setPosition] = useState()

  const renderOptions = () => {
    const { options, enableNoteIcon } = props

    const focusedByMouseItem = getFocusedByMouseItem()
    const focusedByKeyboardItem = getFocusedByKeyboardItem()

    return options.map((option, index) => (
      <ListItem
        key={option.iri}
        enableNoteIcon={enableNoteIcon}
        isFocusedByMouse={
          focusedByMouseItem && focusedByMouseItem.iri === option.iri
        }
        isFocusedByKeyboard={
          focusedByKeyboardItem && focusedByKeyboardItem.iri === option.iri
        }
        onFocusListItem={scrollToListItem}
        index={index}
        option={option}
        setPosition={setPosition}
        onSelect={handleListItemMouseEnter}
        onMouseDown={handleListItemMouseDown}
      />
    ))
  }

  const handleScroll = () => {
    if (position) {
      let el = document.elementFromPoint(position.x, position.y)
      let pos
      while (el) {
        pos = el.getAttribute('data-option')
        if (pos) break
        el = el.parent
      }
      if (pos) {
        const index = parseInt(pos)
        handleListItemMouseEnter({ index, option: options[index] })
      }
    }
  }

  return (
    <Position anchor={anchor} className="slate-js-dropdown" id={dropdownId}>
      {header}
      <div className={styles.menu} ref={listRef} onScroll={handleScroll}>
        {options.length > 0 ? renderOptions() : null}
      </div>
      {footer}
    </Position>
  )
}

DropdownList.defaultProps = {
  emptyMessage: null,
  header: null,
  footer: null,
  options: [],
}

export default DropdownList
