import { defineMessages } from 'react-intl'

const baseId = 'consultation.preconsultation_summary'

export default defineMessages({
  questionnaire_title: {
    id: `${baseId}.questionnaire_title`,
    defaultMessage: 'Pre-consultation questionnaire',
  },
  questionnaire_tooltip: {
    id: `${baseId}.questionnaire_tooltip`,
    defaultMessage:
      'Patients are asked to answer a list of questions before accessing certain types of care.',
  },
  error_title: {
    id: `${baseId}.error title`,
    defaultMessage: 'Error loading the pre-consultation summary',
  },
})
