import { ConsultantMetaIdentity } from './TimelineConsultationTitle'

export const isMyConsultation = (
  consultant?: ConsultantMetaIdentity | null,
  currentConsultant?: ConsultantMetaIdentity
): boolean => {
  if (!currentConsultant || !consultant) {
    return false
  }

  if (currentConsultant.id === consultant.id) {
    return currentConsultant.id != null
  }

  if (currentConsultant.uuid === consultant.uuid) {
    return currentConsultant.uuid != null
  }

  return false
}
