import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { envVar } from '@babylon/babylon-env'
import { Snackbar, SnackbarIntent } from '@babylon/core-ui'
import { DocumentTable, ErrorView } from '../../components'
import {
  DeleteDocumentMutationVariables,
  useDeleteDocumentMutation,
  useDocumentReferenceByPatientsByIdentifierQuery,
} from '../../queries/PatientDocumentReference.federated.hooks'
import {
  DEFAULT_PAGE_SIZE,
  buildDocumentReferenceByPatientVariables,
  getDocumentPaginationInfo,
  transformDocumentReferenceByPatientResult,
} from './DocumentRepository.utils'
import { DocumentEntry } from '../../types'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import { UploadResponse } from '../../components/StyledDropzone/StyledDropzone'

export interface DocumentRepositoryProps {
  memberId: string
  memberName?: string
}

type SnackbarMessage = {
  intent: SnackbarIntent
  message: string
}

export function DocumentRepository({
  memberId,
  memberName,
}: DocumentRepositoryProps) {
  const [documents, setDocuments] = useState<DocumentEntry[]>([])
  const [pageCursor, setPageCursor] = useState<string>('')
  const [endReached, setEndReached] = useState<boolean>(false)
  const customPageSize = Number(envVar('DOCUMENT_REPOSITORY_PAGE_SIZE'))
  const pageSize =
    Number.isNaN(customPageSize) || customPageSize === 0
      ? DEFAULT_PAGE_SIZE
      : customPageSize
  const variables = useMemo(
    () => buildDocumentReferenceByPatientVariables(memberId, pageSize),
    [memberId, pageSize]
  )

  const {
    data,
    error,
    fetchMore,
    loading,
  } = useDocumentReferenceByPatientsByIdentifierQuery({
    variables,
    context: {
      clientName: 'platform-gateway',
    },
    skip: !memberId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [deleteFunction] = useDeleteDocumentMutation({
    context: {
      clientName: 'platform-gateway',
    },
  })

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({
    intent: 'success',
    message: '',
  })

  const deleteDocument = async (id) => {
    try {
      const response = await deleteFunction({
        variables: {
          input: { id },
        } as DeleteDocumentMutationVariables,
      })
      if (response.data?.deleteDocument?.isSuccess) {
        let newArray = documents.filter((d) => d.id !== id)
        setDocuments(newArray)
        setSnackbarMessage({ intent: 'success', message: 'Document deleted' })
      } else {
        setSnackbarMessage({
          intent: 'error',
          message: 'Could not delete document',
        })
      }
    } catch (error) {
      setSnackbarMessage({
        intent: 'error',
        message: 'Error deleting document',
      })
      console.error(`Document Deletion Error: ${error}`)
    }
  }

  useEffect(() => {
    // initial load
    if (!pageCursor) {
      const latestDocuments = transformDocumentReferenceByPatientResult(data)
      const pageInfo = getDocumentPaginationInfo(data)

      if (pageInfo) {
        setPageCursor(pageInfo.endCursor)
        setEndReached(!pageInfo.hasNextPage)
      }

      setDocuments(latestDocuments)
    }
  }, [data, documents, pageCursor, endReached])

  const loadNextPage = useCallback(async () => {
    if (pageCursor && fetchMore) {
      const response = await fetchMore({
        variables: {
          ...variables,
          after: pageCursor,
        },
      })
      const { data: nextData } = response
      const latestDocuments = transformDocumentReferenceByPatientResult(
        nextData
      )
      const pageInfo = getDocumentPaginationInfo(nextData)

      if (pageInfo && pageInfo.endCursor !== pageCursor) {
        setPageCursor(pageInfo.endCursor)
        setEndReached(!pageInfo.hasNextPage)
        const newDocuments = [...documents, ...latestDocuments]
        setDocuments(newDocuments)
      }
    }
  }, [fetchMore, variables, pageCursor, documents])

  const onUploadSuccess = (uploadedFile: UploadResponse) => {
    const newDocument: DocumentEntry = {
      id: uploadedFile.id,
      date: uploadedFile.created_at,
      title: uploadedFile.content.title,
      url: uploadedFile.content.url,
      contentType: uploadedFile.content.content_type,
    }

    const newDocuments = [newDocument, ...documents]
    setDocuments(newDocuments)
  }

  if (!data && loading) {
    return <LoadingIndicator loading />
  }

  if (error || !data) {
    return <ErrorView />
  }

  return (
    <>
      {snackbarMessage?.message && (
        <Snackbar
          onClose={() => setSnackbarMessage({ intent: 'success', message: '' })}
          autoHideDuration={2500}
          intent={snackbarMessage.intent ? snackbarMessage.intent : 'success'}
          message={snackbarMessage.message}
        />
      )}
      <DocumentTable
        onEdit={() => {}}
        onDelete={deleteDocument}
        onLoadMore={loadNextPage}
        documents={documents}
        memberId={memberId}
        memberName={memberName}
        endReached={endReached}
        loading={loading}
        onUploadSuccess={onUploadSuccess}
      />
    </>
  )
}
