import { defineMessages } from 'react-intl'

const baseId = 'consultation.sections.workflow_actions'

export default defineMessages({
  workflow_actions_section_title: {
    id: `${baseId}.workflow_actions_section_title_v2`,
    defaultMessage: 'ClinOps actions',
  },

  error_loading_past_nhs_actions_message: {
    id: `${baseId}.error_loading_past_nhs_actions_message_v2`,
    defaultMessage: 'Error loading past NHS actions. Please try again later.',
  },
  error_loading_past_nhs_actions_retry_button_label: {
    id: `${baseId}.error_loading_past_nhs_actions_retry_button_label`,
    defaultMessage: 'Retry',
  },
  workflow_action_nhs_investigation_name: {
    id: `${baseId}.workflow_action_nhs_investigation_name`,
    defaultMessage: 'NHS Investigation',
  },
  workflow_action_nhs_referral_name: {
    id: `${baseId}.workflow_action_nhs_referral_name_v2`,
    defaultMessage: 'NHS referral',
  },
  workflow_action_nhs_letter_name: {
    id: `${baseId}.workflow_action_nhs_letter_name_v2`,
    defaultMessage: 'NHS letter',
  },
  workflow_action_nhs_generic_name: {
    id: `${baseId}.workflow_action_nhs_generic_name_v2`,
    defaultMessage: 'ClinOps tasks',
  },
  workflow_definitions_error_message: {
    id: `${baseId}.workflow_definitions_error_message`,
    defaultMessage: 'We can’t load ClinOps actions right now',
  },
})
