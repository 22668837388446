import { MutableRefObject, useCallback, useState } from 'react'

import { checkConnectionIsGuest } from '@babylon/connect-client-core'
import { useFormatMessage } from '@babylon/intl'

import { useEventBus } from '~/core/event-bus'

import {
  Action,
  ActionType,
  CallEndReason,
  ErrorActionType,
} from '../../CallStatusReducerModelProvider'
import { setDelay } from './setDelay'
import useCallMonitor from './useCallMonitor'
import {
  OTConnection,
  OTError,
  OTEventHandlerObject,
  OTPublisher,
  OTStream,
  OTSubscriber,
  SendNotifications,
  SessionStatus,
  useOpentok,
  useOpentokEventHandlers,
  VideoDegradation,
} from './useOpentok'
import { getActionableErrorMessage } from './useOpentok/utils'

type UseVideoStreamer = (input: {
  apiKey: string
  sessionId: string
  token: string
  isCallActive: boolean
  cameraEnabled: boolean
  micEnabled: boolean
  backgroundEnabled: boolean
  dispatch: React.Dispatch<Action>
  consultationId: string
  consultantName?: string
  multiwayCallingEnabled?: boolean
  videoStreamWindowImprovementsEnabled?: boolean
}) => {
  subscriberElement: MutableRefObject<HTMLDivElement | null>
  publisherElement: MutableRefObject<HTMLDivElement | null>
  guestElement?: MutableRefObject<HTMLDivElement | null>
  streams: OTStream[]
  publisher: OTPublisher | undefined
  subscriber: OTSubscriber | undefined
  subscriberVideoDegradation: VideoDegradation | undefined
  session: MutableRefObject<OT.Session | undefined>
  sessionStatus: SessionStatus
  isGuestStreamActive: Boolean
  connections: OT.Connection[]
  sendNotifications: SendNotifications
}

const useVideoStreamer: UseVideoStreamer = ({
  apiKey,
  sessionId,
  token,
  isCallActive,
  cameraEnabled,
  micEnabled,
  backgroundEnabled,
  dispatch,
  consultationId,
  consultantName,
  multiwayCallingEnabled,
  videoStreamWindowImprovementsEnabled,
}) => {
  const fm = useFormatMessage()
  const eventBus = useEventBus()

  const [sendNotifications, setSendNotifications] = useState<SendNotifications>(
    SendNotifications.NOT_READY
  )
  const [connections, setConnections] = useState<OT.Connection[]>([])
  const isGuest = (connection?: OTConnection) => {
    return !!connection && checkConnectionIsGuest(connection)
  }
  const initialSessionEventHandlers: OTEventHandlerObject = {
    streamCreated: () => {
      eventBus.emit('PATIENT_JOINED_CALL')
    },
    sessionConnected: () => {
      dispatch({
        type: ActionType.VIDEO_STREAMING,
      })

      // wait half a second after connecting to the session before sending notifications, to allow exsiting connections to come through.
      setDelay(() => setSendNotifications(SendNotifications.READY), 500)
    },
    connectionCreated: (event) => {
      const connection = event?.connection
      if (connection) {
        setConnections((connections) => [...connections, connection])
      }
      dispatch({
        type: ActionType.VIDEO_STREAMING,
      })
      // wait half a second after connecting to the session before sending notifications, to allow exsiting connections to come through.
      setDelay(() => setSendNotifications(SendNotifications.READY), 500)
    },
    streamDestroyed: (event) => {
      const reasonMap: Record<string, CallEndReason> = {
        clientDisconnected: 'patientClientDisconnected',
        networkDisconnected: 'patientNetworkDisconnected',
      }
      const callEndReason = event?.reason ? reasonMap[event.reason] : undefined
      // to prevent call being ended if the guest stops streaming
      if (isGuest(event?.stream?.connection)) {
        setIsGuestStreamActive(false)
      } else {
        dispatch({ type: ActionType.VIDEO_STOP, payload: { callEndReason } })
      }
    },
    connectionDestroyed: (event) => {
      // to prevent call being ended if the guest loses connection
      if (!isGuest(event?.connection)) dispatch({ type: ActionType.VIDEO_STOP })
      const connectionEvent = event?.connection
      if (connectionEvent) {
        setConnections((connections) =>
          connections.filter(
            (connection) =>
              connection.connectionId !== connectionEvent.connectionId
          )
        )
      }
    },
    sessionDisconnected: () => {
      dispatch({ type: ActionType.VIDEO_STOP })
      setConnections([])
      setSendNotifications(SendNotifications.NOT_READY)
      setIsGuestStreamActive(false)
      eventBus.emit('VIDEO_CALL_ENDED')
    },
  }

  const initialPublisherEventHandlers: OTEventHandlerObject = {
    accessDialogOpened: () => {
      dispatch({ type: ActionType.MEDIA_ACCESS_DIALOG_OPENED })
    },
    accessDialogClosed: () => {
      dispatch({ type: ActionType.MEDIA_ACCESS_DIALOG_CLOSED })
    },
    streamDestroyed: (event) => {
      const reasonMap: Record<string, CallEndReason> = {
        networkDisconnected: 'clinicianNetworkDisconnected',
      }
      const callEndReason = event?.reason ? reasonMap[event.reason] : undefined

      dispatch({ type: ActionType.VIDEO_STOP, payload: { callEndReason } })
    },
    videoElementCreated: (event) => {
      eventBus.emit('CLINICIAN_VIDEO_CREATED', event?.element)
    },
  }

  const initialSubscriberEventHandlers: OTEventHandlerObject = {
    videoElementCreated: (event) => {
      eventBus.emit('PATIENT_VIDEO_CREATED', event?.element)
    },
  }

  const {
    sessionEventHandlers,
    addSessionEventHandlers,
    subscriberEventHandlers,
    addSubscriberEventHandlers,
    publisherEventHandlers,
  } = useOpentokEventHandlers({
    initialSessionEventHandlers,
    initialSubscriberEventHandlers,
    initialPublisherEventHandlers,
  })

  const onError = useCallback(
    (error: OTError) => {
      dispatch({
        type: ErrorActionType.CALL_SERVICE_ERROR,
        error: {
          name: error.name,
          message: fm(getActionableErrorMessage(error)),
        },
      })
    },
    [dispatch, fm]
  )

  const {
    subscriberElement,
    publisherElement,
    guestElement,
    subscriber,
    publisher,
    streams,
    subscriberVideoDegradation,
    session,
    sessionStatus,
    isGuestStreamActive,
    setIsGuestStreamActive,
  } = useOpentok({
    apiKey,
    sessionId,
    token,
    sessionEventHandlers,
    subscriberEventHandlers,
    publisherEventHandlers,
    isCallActive,
    onError,
    micEnabled,
    cameraEnabled,
    backgroundEnabled,
    consultantName,
    multiwayCallingEnabled,
    videoStreamWindowImprovementsEnabled,
  })

  useCallMonitor({
    consultationId,
    sessionId,
    isCallActive,
    addSessionEventHandlers,
    addSubscriberEventHandlers,
    getSubscriberStats: subscriber?.getStats,
    getPublisherStats: publisher?.getStats,
  })

  return {
    connections,
    sendNotifications,
    subscriberElement,
    publisherElement,
    guestElement,
    streams,
    publisher,
    subscriber,
    subscriberVideoDegradation,
    session,
    sessionStatus,
    isGuestStreamActive,
  }
}

export default useVideoStreamer
