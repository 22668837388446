import React from 'react'

import { useFormatMessage } from '@babylon/intl'

import { CONSULTATION_STATUSES } from '~/constants'
import { CompleteDialogPluginInterface } from '~/core/config/modules/generated/types'
import { useObservable as useLegacyContext } from '~/core/container'
import { useObservable } from '~/core/reactive-helpers'

import { ParentStatus } from '../CompleteConsultationModel'
import { CompleteDialogView } from './CompleteDialogView'

import messages from './CompleteDialogPlugin.messages'

export const CompleteDialogViewModel: CompleteDialogPluginInterface = ({
  consultationContext,
  completeConsultationModel,
  showDefaultMessage,
  extensions,
}) => {
  const fm = useFormatMessage()

  const {
    loading: loadingConsultation,
    data,
    error: consultationError,
  } = useLegacyContext(consultationContext)

  const consultation = data?.consultation

  const { isDialogVisible } = useObservable(
    completeConsultationModel.isDialogVisible,
    {
      isDialogVisible: false,
    }
  )

  const {
    error: completeMutationError,
    loading: completeMutationLoading,
  } = useObservable(completeConsultationModel.completeMutationState, {
    error: false,
    loading: false,
  })

  const { status, errors } = useObservable(completeConsultationModel.state, {
    status: ParentStatus.ExtensionsAreInitializing,
    errors: [],
  })

  const surfacedErrors = errors

  if (consultationError) {
    surfacedErrors.push(messages.error_message)
  }

  const consultationIncomplete =
    consultation?.status !== CONSULTATION_STATUSES.COMPLETED

  const title = consultationIncomplete
    ? fm(messages.complete_title)
    : fm(messages.update_title)

  const loading =
    loadingConsultation ||
    completeMutationLoading ||
    status === ParentStatus.ExtensionsAreInitializing ||
    status === ParentStatus.ExtensionsAreSubmitting

  let okLabel = consultationIncomplete
    ? fm(messages.complete_button_label)
    : fm(messages.update_button_label)

  if (
    status === ParentStatus.SomeExtensionsFailedSubmitting ||
    completeMutationError
  ) {
    okLabel = fm(messages.try_again_button_label)
  }

  const okButtonIsDisabled =
    status === ParentStatus.SomeExtensionsFailedInitializing

  return (
    // this is a vew model, there should be only one view
    // rendered here, no other Markup!!!
    <CompleteDialogView
      isDialogVisible={isDialogVisible}
      completeError={completeMutationError}
      loading={loading}
      title={title}
      okLabel={okLabel}
      okError={okButtonIsDisabled}
      errors={surfacedErrors}
      showDefaultMessage={showDefaultMessage}
      handleOk={completeConsultationModel.complete}
      handleClose={completeConsultationModel.cancel}
      extensions={extensions}
    />
  )
}
