import React from 'react'
import { FormattedDate } from 'react-intl'

import { ResponsiveTable, ResponsiveTableColumn } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { isFilledArray } from '~/core'
import { PermissionProvider, usePermissions } from '~/core/permissions'

import messages from './PrescriptionsTable.messages'

interface Drug {
  drugName: string
  quantity: number
  directions: string
  reviewDate?: string | null
  repeatable?: boolean | null
  repeats?: number | null
}

interface Prescription {
  prescriptionState: string
  drugs: Drug[]
}

interface PrescriptionsTableProps {
  list: Prescription[] | undefined
}

const PrescriptionsTable: React.FC<PrescriptionsTableProps> = ({
  list = [],
}) => {
  const fm = useFormatMessage()
  const activePrescriptions = list.filter(
    (v) => v.prescriptionState !== 'VOIDED'
  )

  const [isRepeatEnabled] = usePermissions('enable_repeat_prescriptions')

  if (!isFilledArray(activePrescriptions)) {
    return null
  }

  const drugs = activePrescriptions
    .reduce(
      (drugsAcc, prescription) => [...drugsAcc, ...prescription.drugs],
      [] as Drug[]
    )
    .map((drug) => {
      if (drug.repeatable) {
        return {
          ...drug,
          repeatableCell: (
            <RepeatableCell
              repeats={drug.repeats}
              reviewDate={drug.reviewDate}
            />
          ),
        }
      }

      return drug
    })

  const columns = [
    {
      label: fm(messages.drug_name),
      accessor: 'drugName',
      size: '1fr',
    },
    {
      label: fm(messages.drug_quantity),
      accessor: 'quantity',
      size: '1fr',
    },
    {
      label: fm(messages.drug_directions),
      accessor: 'directions',
      size: '1fr',
    },
    isRepeatEnabled && {
      label: fm(messages.repeatable),
      accessor: 'repeatableCell',
      size: '1fr',
    },
  ].filter(Boolean) as ResponsiveTableColumn[]

  return (
    <ResponsiveTable
      label={fm(messages.table_label)}
      collapseBreakpoint={200}
      fullWidth
      columns={columns}
      data={drugs}
    />
  )
}

const RepeatableCell = ({
  repeats,
  reviewDate,
}: Pick<Drug, 'repeats' | 'reviewDate'>) => {
  const fm = useFormatMessage()

  return (
    <>
      <div>{fm(messages.repeats, { value: repeats })}</div>
      <div>
        {fm(messages.review_date)}:{' '}
        <FormattedDate value={reviewDate || undefined} format="short" />
      </div>
    </>
  )
}

export default (props: PrescriptionsTableProps) => (
  <PermissionProvider permissions={() => undefined}>
    <PrescriptionsTable {...props} />
  </PermissionProvider>
)
