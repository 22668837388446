import { gql, useApolloClient } from '@apollo/client'
import { useEffect, useState } from 'react'

import {
  checkConnectionByUserType,
  UserType,
} from '@babylon/connect-client-core'

import {
  Action,
  ActionType,
  CallStatus,
  isActive,
  isRejected,
} from '~/features/call/CallStatusReducerModelProvider'

interface useCallFlowStepsQueryProps3 {
  appointmentId: string
  callStartedAt: string | null
  showPatientRejectedVideoCall: boolean | undefined
  streams: OT.Stream[]
  callStatus: CallStatus
  dispatch: React.Dispatch<Action>
}

// The wait time increases each loop,
// ending up in [10s, 30s, 60s] intervals
const BASE_WAIT_TIME = 10000
const MAX_ATTEMPTS = 3
const query = gql`
  query CpRawCallFlowSteps($appointmentId: ID!) {
    getRawCallFlowSteps(appointmentId: $appointmentId) {
      call_flow_steps {
        triggered_at
        flow_step_type
      }
    }
  }
`

const delay = (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration))
}

export const useCallRejection = ({
  appointmentId,
  callStartedAt,
  showPatientRejectedVideoCall,
  streams,
  callStatus,
  dispatch,
}: useCallFlowStepsQueryProps3) => {
  const client = useApolloClient()
  const isCallRejectedStatus = isRejected(callStatus)
  const isCallActive = isActive(callStatus)

  const [checkingRejectionStatus, setCheckingRejectionStatus] = useState(false)

  const hasRejectedCallFlowStep = async () => {
    const startTime = new Date(callStartedAt!)
    const { data } = await client.query({
      query,
      variables: { appointmentId },
      fetchPolicy: 'network-only',
    })

    return data?.getRawCallFlowSteps?.call_flow_steps
      .filter(
        (step: any) => step.flow_step_type === 'video_call_rejected_by_patient'
      )
      .some(
        (step: any) =>
          new Date(step?.triggered_at).getTime() > startTime.getTime()
      )
  }

  const checkRejectedStatus = async (attempts = 1) => {
    const waitingForPatients = !streams
      .map((s) => checkConnectionByUserType(s.connection))
      .includes(UserType.PATIENT)
    if (!waitingForPatients) return

    if (attempts > MAX_ATTEMPTS) {
      setCheckingRejectionStatus(false)
      return
    }

    await delay(BASE_WAIT_TIME * attempts)
    const hasRejectedStep = await hasRejectedCallFlowStep()
    if (hasRejectedStep) {
      dispatch({ type: ActionType.VIDEO_REJECTED })
      setCheckingRejectionStatus(false)
    } else {
      await checkRejectedStatus(attempts + 1)
    }
  }

  useEffect(() => {
    if (!showPatientRejectedVideoCall) return
    if (!callStartedAt) return
    if (!isCallActive) return
    if (isCallRejectedStatus) return
    if (checkingRejectionStatus) return

    checkRejectedStatus()
    setCheckingRejectionStatus(true)
  }, [callStartedAt, callStatus, streams]) // eslint-disable-line react-hooks/exhaustive-deps
}
