import React from 'react'
import cn from 'classnames'

import './index.scss'

interface BadgeProps {
  value: string | number
  title?: string
  className?: string
  style?: object
}

const Badge = ({ style, className, value, title }: BadgeProps) => (
  <sup className={cn('core-badge', className)} title={title} style={style}>
    {value}
  </sup>
)

export default Badge
