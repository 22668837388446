import { defineMessages } from 'react-intl'

const baseId = 'consultation.recording_alert'

export default defineMessages({
  tooltip_text: {
    id: `${baseId}.tooltip_text`,
    defaultMessage: 'You are being recorded',
  },
  indicator_text: {
    id: `${baseId}.indicator_text`,
    defaultMessage: 'REC',
  },
})
