import { gql, useMutation } from '@apollo/client'

import {
  END_VIDEO_SESSION_ACTION,
  ERROR_END_VIDEO_SESSION_ACTION,
  MULTIMEDIA_CATEGORY,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { logException } from '~/core/sentry'
import useReportCallStep from '~/features/call/useReportCallStep'
import {
  CallStep,
  CpStartVideoCallMutation,
  CpStartVideoCallMutationVariables,
} from '~/generated'

export const END_VIDEO_CALL = gql`
  mutation CpEndVideoCall($consultationId: ID!, $sessionId: ID!) {
    endVideoCall(consultationId: $consultationId, sessionId: $sessionId)
  }
`
type UseStartVideoCallMutation = {
  consultationId: string
}

const trackEvent = analytics.trackEventFactory({
  category: MULTIMEDIA_CATEGORY,
})

const useEndVideoCallMutation = ({
  consultationId,
}: UseStartVideoCallMutation) => {
  const [stopVideoCall, result] = useMutation<
    CpStartVideoCallMutation,
    Omit<CpStartVideoCallMutationVariables, 'activeParticipants'>
  >(END_VIDEO_CALL)
  const [reportCallStep] = useReportCallStep(consultationId)

  const performMutation = async (sessionId: string) => {
    try {
      reportCallStep(CallStep.VideoCallEndedByClinician)

      await stopVideoCall({
        variables: {
          consultationId,
          sessionId,
        },
      })
      trackEvent({
        action: END_VIDEO_SESSION_ACTION,
        label: sessionId,
      })
    } catch (err) {
      trackEvent({
        action: ERROR_END_VIDEO_SESSION_ACTION,
        label: sessionId,
      })
      logException(err)
    }
  }

  return [performMutation, result] as const
}

export default useEndVideoCallMutation
