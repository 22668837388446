import { ApolloError } from '@apollo/client'
import cn from 'classnames'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Spinner, Tag, Text } from '@babylon/core-ui'

import { generateIndex, isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import {
  filterWorkflowsV2,
  getGroupsFromWorkflows,
  isInvalidWorkflow,
} from '~/features/workflows/WorkflowActions/utils'
import {
  AppointmentInvitesQueryResult,
  TimelineConsultationActionsQueryResult,
} from '~/generated'

import TimelineFollowUpActions from './TimelineFollowUpActions'

import styles from './styles.module.scss'
import messages from './TimelineConsultationActionsView.messages'

export interface TimelineConsultationActionsViewProps {
  consultationActions: NonNullable<
    TimelineConsultationActionsQueryResult['data']
  >['consultation']
  appointmentInvites: NonNullable<
    NonNullable<AppointmentInvitesQueryResult['data']>['consultation']
  >['appointmentInvites']
  isIncomplete: boolean
  getTestName: (type: string, id: string) => string | null
  loading: boolean
  error?: ApolloError
}

interface TimelineTimelineActionTagProps {
  children: React.ReactNode
}

const TimelineActionTag = ({ children }: TimelineTimelineActionTagProps) => (
  <Tag className={styles.clinicalCode}>{children}</Tag>
)

const TimelineConsultationActionsView = ({
  consultationActions,
  appointmentInvites,
  isIncomplete,
  getTestName,
  loading,
  error,
}: TimelineConsultationActionsViewProps) => {
  const f = useMessages(messages)
  const { formatNumber } = useIntl()

  if (loading) {
    return (
      <Spinner size="small" testid="timeline-consultation-actions-loading" />
    )
  }

  if (error) {
    return <Text>{f('error_title')}</Text>
  }

  const prescriptions = consultationActions?.prescriptions ?? []
  const diagnosticTests = consultationActions?.diagnosticTests ?? []
  const pathologyTests = consultationActions?.pathologyTests ?? []
  const testResults = consultationActions?.testResults ?? []
  const workflowsV2 = consultationActions?.workflowsV2 ?? []
  const sickNotes = consultationActions?.sickNotes ?? []
  const referrals = consultationActions?.referrals ?? []
  const tests = [...(diagnosticTests || []), ...(pathologyTests || [])].map(
    (test) => ({
      ...test,
      name: test.type && getTestName(test.type, test.testId),
    })
  )

  const knownWorkflows =
    workflowsV2.filter((workflow) => !isInvalidWorkflow(workflow)) ?? []
  const workflowGroups = getGroupsFromWorkflows(knownWorkflows)
  const hasPrescriptions = isFilledArray(prescriptions)
  const hasTests = isFilledArray(tests)
  const hasTestResults = isFilledArray(testResults)
  const hasWorkflows = isFilledArray(knownWorkflows)
  const hasSickNotes = isFilledArray(sickNotes)
  const hasReferrals = isFilledArray(referrals)
  const hasAppointmentInvites = isFilledArray(appointmentInvites)
  const hasNoValidWorkflows = !isFilledArray(knownWorkflows)
  const hasNoValidCompletedActions =
    !hasPrescriptions && !hasTests && !hasReferrals && hasNoValidWorkflows
  const hasNoValidIncompleteActions =
    hasNoValidCompletedActions && !hasSickNotes

  if (isIncomplete && hasNoValidIncompleteActions) return null

  return isIncomplete ? (
    <div
      data-testid="timeline-incomplete-consultation-actions"
      className={styles.incompleteConsultationActions}
    >
      <Text className={styles.possibleAction}>{f('possible_actions')}:</Text>
      {hasPrescriptions && (
        <div className={styles.workflowGroup}>
          <Text className={styles.incompleteTimelineActionLabel}>
            {f('prescriptions_label')}
          </Text>
          {prescriptions.map(({ id, drugs }) => (
            <span key={id}>
              {drugs.map((drug) => (
                <TimelineActionTag key={drug.prescriptionDrugId}>
                  {`${drug.drugName} x ${drug.quantity}`}
                </TimelineActionTag>
              ))}
            </span>
          ))}
        </div>
      )}
      {hasTests && (
        <div className={styles.workflowGroup}>
          <Text className={styles.incompleteTimelineActionLabel}>
            {f('tests_label')}
          </Text>
          {tests.map((test) => (
            <TimelineActionTag key={test.id}>
              {test.name || f('unknown_test_tag')}
            </TimelineActionTag>
          ))}
        </div>
      )}
      {hasReferrals && (
        <div className={styles.workflowGroup}>
          <Text className={styles.incompleteTimelineActionLabel}>
            {f('referrals_label')}
          </Text>
          {referrals.map((referral) =>
            referral ? (
              <TimelineActionTag key={referral.id}>
                {referral.specialismName}
              </TimelineActionTag>
            ) : null
          )}
        </div>
      )}
      {hasSickNotes && (
        <div className={cn(styles.workflowGroup)}>
          <Text className={styles.incompleteTimelineActionLabel}>
            {f('sick_notes_actions_tag_label')}
          </Text>
        </div>
      )}
      {hasWorkflows &&
        workflowGroups.map((workflowGroup) => {
          const { type, label } = workflowGroup
          const workflowsInGroup = filterWorkflowsV2(knownWorkflows, type)

          return (
            <div key={type} className={styles.workflowGroup}>
              <Text className={styles.incompleteTimelineActionLabel}>
                {f('workflow_label', { label })}
              </Text>
              {workflowsInGroup.map((workflow) => {
                return (
                  <TimelineActionTag key={workflow.id}>
                    {workflow.workflowDefinition?.workflow_definition_name}
                  </TimelineActionTag>
                )
              })}
            </div>
          )
        })}
      {hasAppointmentInvites && (
        <div className={styles.workflowGroup}>
          <Text className={styles.completeTimelineActionLabel}>
            {f('appointment_invites_label')}
          </Text>
          {appointmentInvites?.map(({ id, service_type: serviceType }) => (
            <TimelineActionTag key={id}>
              {serviceType?.name ?? f('no_service_type')}
            </TimelineActionTag>
          ))}
        </div>
      )}
    </div>
  ) : !hasNoValidCompletedActions ? (
    <div
      data-testid="timeline-complete-consultation-actions"
      className={styles.completeConsultationActions}
    >
      {hasPrescriptions && (
        <div className={styles.workflowGroup}>
          <Text className={styles.completeTimelineActionLabel}>
            {f('prescriptions_label')}
          </Text>
          {prescriptions.map(({ id, drugs }) => (
            <span key={id}>
              {drugs.map((drug) => (
                <Tag
                  key={generateIndex()}
                  className={styles.clinicalCode}
                  margin
                  uppercase={false}
                >
                  {`${drug.drugName} x ${drug.quantity}`}
                </Tag>
              ))}
            </span>
          ))}
        </div>
      )}
      {hasTests && (
        <div className={styles.workflowGroup}>
          <Text className={styles.completeTimelineActionLabel}>
            {f('tests_label')}
          </Text>
          {tests.map((test) => (
            <Tag
              key={generateIndex()}
              margin
              className={styles.clinicalCode}
              uppercase={false}
            >
              {test.name || f('unknown_test_tag')}
            </Tag>
          ))}
        </div>
      )}
      {hasTestResults && (
        <div className={styles.workflowGroup}>
          <Text className={styles.completeTimelineActionLabel}>
            {f('timeline_test_results_label')}
          </Text>
          <FormattedMessage
            {...messages.timeline_test_results_content}
            values={{
              testResultCount: formatNumber(testResults.length),
            }}
          />
        </div>
      )}
      {hasReferrals && (
        <div className={styles.workflowGroup}>
          <Text className={styles.completeTimelineActionLabel}>
            {f('referrals_label')}
          </Text>
          {referrals.map((referral) =>
            referral ? (
              <Tag
                key={generateIndex()}
                className={styles.clinicalCode}
                uppercase={false}
              >
                {referral.specialismName}
              </Tag>
            ) : null
          )}
        </div>
      )}
      {(hasWorkflows || hasAppointmentInvites) && (
        <TimelineFollowUpActions
          workflows={knownWorkflows}
          appointmentInvites={appointmentInvites}
        />
      )}
    </div>
  ) : null
}

export default TimelineConsultationActionsView
