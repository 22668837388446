import { MessageDescriptor } from 'react-intl'

import messages from '../PatientMetricsModal.messages'

const MIN_CM = 0
const MAX_CM = 274

export const validateHeight = (fm: (key: MessageDescriptor) => string) => (
  value: string
) => {
  if (parseFloat(value) <= MIN_CM) {
    return fm(messages.error_non_zeroes)
  }
  if (parseFloat(value) > MAX_CM) {
    return fm(messages.error_max)
  }
  return null
}

const MIN_KG = 0
const MAX_KG = 634

export const validateWeight = (fm: (key: MessageDescriptor) => string) => (
  value: string
) => {
  if (parseFloat(value) <= MIN_KG) {
    return fm(messages.error_non_zeroes)
  }
  if (parseFloat(value) > MAX_KG) {
    return fm(messages.error_max)
  }
  return null
}
