import { ApolloError } from '@apollo/client'
import cn from 'classnames'
import React from 'react'

import { ConsultantUser } from '@babylon/babylon-user'
import { Text } from '@babylon/core-ui'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import CodedTextWithClinicalCodes from '~/features/clinical-coding/CodedTextWithClinicalCodes'
import styles from '~/features/patient-timeline/Timeline/components/TimelineConsultationEvent/styles.module.scss'
import { TimelineConsultation } from '~/features/patient-timeline/Timeline/components/TimelineConsultationEvent/TimelineConsultationEvent'
import TimelineConsultationTitle from '~/features/patient-timeline/Timeline/components/TimelineConsultationTitle'
import {
  CurrentConsultationQueryResult,
  PatientTimelineConsultationFragmentFragment,
  TimelineConsultationQueryResult,
} from '~/generated'

import { isEventInFuture } from '../../utils'
import TimelineEvent from '../TimelineEvent'

import messages from './TimelineConsultationEventView.messages'

type CurrentConsultation = NonNullable<
  CurrentConsultationQueryResult['data']
>['consultation']

type CurrentConsultationConsultant = NonNullable<CurrentConsultation>['consultant']

interface TimelineConsultationEventWrapperProps {
  event: PatientTimelineConsultationFragmentFragment
  patientId: string
  consultation?: TimelineConsultation | CurrentConsultation
  consultant?: CurrentConsultationConsultant
  currentUser?: ConsultantUser['consultant']
  isCurrentConsultation?: boolean
  loading?: boolean
  children?: React.ReactNode
}

export interface TimelineConsultationEventViewProps {
  patientId: string
  currentConsultation: CurrentConsultation
  consultation: NonNullable<
    TimelineConsultationQueryResult['data']
  >['consultation']
  event: PatientTimelineConsultationFragmentFragment
  isCurrentConsultation: boolean
  consultationActions: React.ReactNode | null
  currentUser: ConsultantUser['consultant']
  loading: boolean
  error?: ApolloError
}

const TimelineConsultationEventWrapper = ({
  consultation,
  consultant,
  currentUser,
  isCurrentConsultation,
  children,
  event,
  ...rest
}: TimelineConsultationEventWrapperProps) => {
  const isFutureEvent = isEventInFuture(event.eventTime)
  const isIncomplete = consultation?.status === 'paid'
  const isNoShow = consultation?.status === 'no_show'
  const hasAttachments =
    consultation &&
    'images' in consultation &&
    'items' in consultation.images &&
    isFilledArray(consultation?.images?.items)

  return (
    <TimelineEvent
      className={cn({
        [styles.incomplete]: isIncomplete,
        [styles.noShow]: isNoShow,
      })}
      dataTestId="consultation-event"
      isCurrentConsultation={isCurrentConsultation}
      isIncomplete={isIncomplete}
      isNoShow={isNoShow}
      title={
        <TimelineConsultationTitle
          className={styles.heading}
          consultant={consultant}
          currentConsultant={currentUser}
          isIncomplete={isIncomplete}
          isNoShow={isNoShow}
          hasAttachments={!!hasAttachments}
          isCurrentConsultation={isCurrentConsultation}
          isFutureEvent={isFutureEvent}
        />
      }
      event={event}
      {...rest}
    >
      {children}
    </TimelineEvent>
  )
}

const TimelineConsultationEventView = ({
  patientId,
  currentConsultation,
  consultation,
  event,
  isCurrentConsultation,
  consultationActions,
  currentUser,
  loading,
  error,
}: TimelineConsultationEventViewProps) => {
  const f = useMessages(messages)

  if (loading) {
    return (
      <TimelineConsultationEventWrapper
        event={event}
        patientId={patientId}
        loading
      />
    )
  }

  if (error) {
    return (
      <TimelineConsultationEventWrapper event={event} patientId={patientId}>
        {f('error_loading_consultation_details_message')}
      </TimelineConsultationEventWrapper>
    )
  }

  if (isCurrentConsultation) {
    return (
      <TimelineConsultationEventWrapper
        consultation={currentConsultation}
        event={event}
        consultant={currentConsultation?.consultant}
        patientId={patientId}
        currentUser={currentUser}
        isCurrentConsultation={isCurrentConsultation}
      />
    )
  }

  const assessment = consultation?.consultationNote?.assessment
  const assessmentCodes = consultation?.consultationNote?.assessmentCodes ?? []
  const isFutureEvent = isEventInFuture(event.eventTime)
  const isIncomplete = consultation?.status === 'paid'
  const isNoShow = consultation?.status === 'no_show'
  const patientNote = consultation?.patientNote

  if (!consultation) return null

  return (
    <TimelineConsultationEventWrapper
      consultation={consultation}
      event={event}
      consultant={consultation.consultant}
      patientId={patientId}
      currentUser={currentUser}
      isCurrentConsultation={isCurrentConsultation}
    >
      <div data-testid={isIncomplete ? 'incomplete' : 'complete'}>
        {isIncomplete && !isNoShow && !isFutureEvent ? (
          <Text className={styles.incompleteConsultationDisclaimer}>
            {f('incomplete_consultation_disclaimer')}
          </Text>
        ) : isNoShow || isFutureEvent ? (
          <Text italic className={styles.noShowPatientNote}>
            {patientNote}
          </Text>
        ) : (
          assessment && (
            <CodedTextWithClinicalCodes
              className={styles.completeAssessment}
              codes={assessmentCodes}
              text={assessment}
            />
          )
        )}
        {!isNoShow && consultationActions}
      </div>
    </TimelineConsultationEventWrapper>
  )
}

export default TimelineConsultationEventView
