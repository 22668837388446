import React from 'react'

import { useIntl } from '@babylon/intl'

import { NhsAppointmentInvitesSummaryPluginInterface } from '~/core/config/modules/generated/types'
import { useFeatureFlags } from '~/core/core-modules'
import { filterWorkflowsV2 } from '~/features/workflows/WorkflowActions/utils'
import {
  ConsultationSummaryBaseQuery,
  useAppointmentInvitesQuery,
  useCpConsultationWorkflowsV2Query,
  WorkflowType,
} from '~/generated'

import NhsAppointmentInvitesSummaryView from './NhsAppointmentInvitesSummaryView'

interface NhsAppointmentInvitesSummaryRuntimeProps {
  consultation: Pick<
    NonNullable<ConsultationSummaryBaseQuery['consultation']>,
    'id' | 'consumerNetworkId'
  >
}

const NhsAppointmentInvitesModule: NhsAppointmentInvitesSummaryPluginInterface = ({
  faceToFaceInvitesEnabled,
  digitalInvitesEnabled,
}) => {
  const NhsAppointmentInvitesContainer = ({
    consultation,
  }: NhsAppointmentInvitesSummaryRuntimeProps) => {
    const { locale } = useIntl()

    const { id: consultationId, consumerNetworkId } = consultation

    const {
      f2FMediumServiceTypeInvitesSupported: globalInvitesEnabled,
    } = useFeatureFlags()
    const invitesDisabled =
      globalInvitesEnabled ||
      (!faceToFaceInvitesEnabled && !digitalInvitesEnabled)

    const {
      data: invitesData,
      error: invitesError,
      refetch: invitesRefetch,
    } = useAppointmentInvitesQuery({
      variables: { id: consultationId, f2fOn: faceToFaceInvitesEnabled },
      skip: !faceToFaceInvitesEnabled || globalInvitesEnabled,
    })
    const appointmentInvites =
      invitesData?.consultation?.appointmentInvites ?? []

    const {
      data: workflowsData,
      error: workflowsError,
      refetch: workflowsRefetch,
    } = useCpConsultationWorkflowsV2Query({
      variables: {
        locale,
        tags: 'clinical-portal',
        consumerNetwork: String(consumerNetworkId),
        consultationId,
        includeDefinitions: Boolean(consumerNetworkId),
      },
      notifyOnNetworkStatusChange: true,
      skip:
        !consumerNetworkId || !digitalInvitesEnabled || globalInvitesEnabled,
    })

    const workflows =
      workflowsData?.consultationWorkflowsV2?.workflows?.results ?? []
    const appointmentWorkflows = filterWorkflowsV2(
      workflows,
      WorkflowType.Appointment
    )

    const refetch = () => {
      if (invitesError) invitesRefetch()
      if (workflowsError) workflowsRefetch()
    }

    if (invitesDisabled) {
      return null
    }

    return (
      <NhsAppointmentInvitesSummaryView
        appointmentInvites={appointmentInvites}
        appointmentWorkflows={appointmentWorkflows}
        refetch={refetch}
        error={invitesError || workflowsError}
        faceToFaceInvitesEnabled={faceToFaceInvitesEnabled}
        digitalInvitesEnabled={digitalInvitesEnabled}
      />
    )
  }

  return NhsAppointmentInvitesContainer
}

export default NhsAppointmentInvitesModule
