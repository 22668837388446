import React from 'react'

import { useConsultantUser } from '@babylon/babylon-user'

import { useFeatureFlags } from '~/core/core-modules'
import useGetServiceTypes from '~/features/appointment-invites/AppointmentInvitesModal/hooks/useGetServiceTypes'
import {
  useCreateInviteV3Mutation,
  useGetAppointmentInvitesQuery,
  useGetProfessionsQuery,
} from '~/generated'

import AppointmentInvitesModalView from './AppointmentInvitesModalView'

interface AppointmentInvitesModalContainerProps {
  patientId: string
  consumerNetworkId: number
  consultationId: string
  clinicianId: string
}

const AppointmentInvitesModalContainer = ({
  patientId,
  consumerNetworkId,
  consultationId,
  clinicianId,
}: AppointmentInvitesModalContainerProps) => {
  const featureFlags = useFeatureFlags()
  const { serviceTypesInvitesEnabled } = featureFlags
  const {
    data: professionsData,
    loading: professionsLoading,
    error: professionsError,
    refetch: professionsRefetch,
  } = useGetProfessionsQuery({
    variables: { id: String(consumerNetworkId) },
    skip: serviceTypesInvitesEnabled,
  })

  const {
    serviceTypes,
    clinicianServiceTypes,
    loading: serviceTypesLoading,
    error: serviceTypesError,
    refetch: refetchServiceTypes,
  } = useGetServiceTypes({
    consumerNetworkId,
    clinicianId,
  })

  const [
    createInviteMutation,
    { loading: createInviteLoading, error: createInviteError },
  ] = useCreateInviteV3Mutation()
  const { updateQuery } = useGetAppointmentInvitesQuery({
    variables: { id: consultationId },
  })
  const professions = professionsData?.consumerNetworkProfessions ?? []

  const consultant = useConsultantUser()
  const consultantUuid = consultant.uuid
  const consultantTimezoneId = consultant.timezone
  const professionName = consultant.consultant.profession_name ?? ''

  const refetch = () => {
    if (professionsError && !serviceTypesInvitesEnabled) professionsRefetch()
    if (serviceTypesError) refetchServiceTypes()
  }

  return (
    <AppointmentInvitesModalView
      createInviteMutation={createInviteMutation}
      professions={professions}
      serviceTypes={serviceTypes}
      clinicianServiceTypes={clinicianServiceTypes}
      professionName={professionName}
      consultantUuid={consultantUuid}
      consultantTimezoneId={consultantTimezoneId}
      consultationId={consultationId}
      patientId={patientId}
      queryLoading={professionsLoading || serviceTypesLoading}
      queryError={professionsError || serviceTypesError}
      inviteLoading={createInviteLoading}
      inviteError={createInviteError}
      refetch={refetch}
      updateInvites={updateQuery}
    />
  )
}

export default AppointmentInvitesModalContainer
