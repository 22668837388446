// This timeline event displays blood tests from the post-2019 test and kit system

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '@babylon/intl'

import { TestReport } from '~/generated'
import Label from '~/ui/Label'

import TimelineEvent from '../TimelineEvent'

import styles from '../shared.module.scss'
import messages from './TimelineTestResultEvent.messages'

interface TimelineTestResultEventProps {
  patientId: string
  event: TestReport
}

const TimelineTestResultEvent = ({
  event,
  patientId,
}: TimelineTestResultEventProps) => {
  const fm = useFormatMessage()

  return (
    <TimelineEvent
      title={
        <>
          {fm(messages.title)}
          {event.sender && (
            <span className={styles.titleMeta}>
              <FormattedMessage
                {...messages.title_reviewer}
                values={{
                  name: event.sender.fullName,
                }}
              />
            </span>
          )}
        </>
      }
      patientId={patientId}
      event={event}
    >
      <div>
        <Label className={styles.topLabel} value="Test:">
          <span>{` ${event.name}`}</span>
        </Label>
        <div className={styles.description}>{fm(messages.view_result)}</div>
      </div>
    </TimelineEvent>
  )
}

export default TimelineTestResultEvent
