import React from 'react'

import { Heading, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './Shell.messages'
import styles from './styles.module.scss'

const Page404 = () => {
  const fm = useFormatMessage()

  return (
    <div className={styles.page404}>
      <Heading>{fm(messages.page404Title)}</Heading>
      <p>
        <Text>{fm(messages.page404Content)}</Text>
      </p>
    </div>
  )
}

export default Page404
