import classnames from 'classnames'
import React from 'react'

import Tooltip from '~/ui/Tooltip'

import styles from './styles.module.scss'

const SectionItem = ({
  icon = undefined,
  title,
  description = undefined,
  info = undefined,
  className = undefined,
  children = null,
}) => (
  <div
    className={classnames(styles.sectionItem, className)}
    data-testid="section"
  >
    {icon && (
      <div className={styles.icon}>
        <i className="material-icons">{icon}</i>
      </div>
    )}
    <div className={styles.content}>
      <div className={styles.title} data-testid="section-title">
        {title}
        {info ? <Tooltip info={info} className={styles.tooltip} /> : null}
      </div>
      <div className={styles.description} data-testid="section-description">
        {description || children}
      </div>
    </div>
  </div>
)

export default SectionItem
