import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Card, Divider, Grid, Heading, Text } from '@babylon/core-ui'

import { NOTE_ASSISTANT_PROGRESS_INTERVAL } from '~/constants'
import {
  NOTE_ASSISTANT_CATEGORY,
  NOTE_ASSISTANT_COPY_ALL_ACTION,
  NOTE_ASSISTANT_COPY_SEGMENT_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { useFeatureFlags } from '~/core/core-modules'
import { useMessages } from '~/core/hooks'
import { logException } from '~/core/sentry'
import CopyButton from '~/ui/CopyButton'
import ProgressBar from '~/ui/ProgressBar'
import { useMobile } from '~/ui/Responsive'

import StatusIndicator from './StatusIndicator'

import messages from './NoteAssistantSummary.messages'
import styles from './styles.module.scss'

const trackEvent = analytics.trackEventFactory({
  category: NOTE_ASSISTANT_CATEGORY,
})

export interface NoteAssistantSummaryViewProps {
  notes: string[]
  error: boolean
  warning?: string
  isActive: boolean
  speechProcessingEnabled: boolean | undefined
}
const NoteAssistantSummaryView = ({
  notes,
  error,
  warning,
  isActive,
  speechProcessingEnabled,
}: NoteAssistantSummaryViewProps) => {
  const fm = useMessages(messages)
  const isMobile = useMobile()
  const { noteAssistantExternalCopyEnabled } = useFeatureFlags()
  const [progress, setProgress] = useState<number>(0)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const notesRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const progressRef = useRef<NodeJS.Timeout | null>(null)

  // TODO - can remove this when the backend has filtered out empty strings
  const filteredNotes = useMemo(() => notes.filter(Boolean), [notes])

  const startInterval = useCallback(() => {
    progressRef.current = setInterval(
      () => setProgress((p) => (p >= 99 ? 99 : p + 1)),
      NOTE_ASSISTANT_PROGRESS_INTERVAL
    )
  }, [progressRef])

  const clearTimer = () => {
    if (progressRef.current) clearInterval(progressRef.current)
    setProgress(0)
  }

  const scrollToBottom = () => {
    if (filteredNotes.length && contentRef.current && scrollRef.current) {
      contentRef.current.scrollTo({
        top: scrollRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const handleCopySegment = async () => {
    const copiedText = window.getSelection()?.toString()
    const copiedTextLength = copiedText?.replaceAll('\n', '').length

    if (noteAssistantExternalCopyEnabled && copiedText) {
      try {
        await navigator.clipboard.writeText(copiedText)
      } catch (err) {
        // navigator.clipboard.writeText can throw an error if the caller does not have permission to write to the clipboard
        logException(err)
      }
    }

    if (copiedTextLength) {
      trackEvent({
        action: NOTE_ASSISTANT_COPY_SEGMENT_ACTION,
        label: copiedTextLength.toString(),
      })
    }
  }

  const handleCopyAll = () => {
    const copiedTextLength = filteredNotes.reduce(
      (total, note) => total + note.length,
      0
    )

    trackEvent({
      action: NOTE_ASSISTANT_COPY_ALL_ACTION,
      label: copiedTextLength.toString(),
    })
  }

  const copyButton = () => (
    <CopyButton
      clipboardData={
        noteAssistantExternalCopyEnabled
          ? notesRef.current?.innerText
          : undefined
      }
      restrictedClipboardData={
        noteAssistantExternalCopyEnabled ? undefined : notesRef
      }
      tooltipMessage={fm('copy_all')}
      className={styles.copyButton}
      tooltipProps={{ placement: 'left' }}
      onCopy={handleCopyAll}
    />
  )

  useEffect(scrollToBottom, [filteredNotes, error])

  useEffect(() => {
    if (!filteredNotes.length && isActive) {
      startInterval()
      setShowLoading(true)
    } else if (filteredNotes.length) {
      setProgress(100)
      setTimeout(() => setShowLoading(false), 500)
    } else {
      setShowLoading(false)
      setProgress(0)
    }
    return () => clearTimer()
  }, [isActive, filteredNotes.length, startInterval])

  return (
    <Card className={styles.container} padding="none">
      {!isMobile && (
        <>
          <div data-testid="header" className={styles.header}>
            <Heading level="h2">{fm('title')}</Heading>
            <StatusIndicator isActive={isActive} />
          </div>
          <Divider />
        </>
      )}
      <div className={styles.content} ref={contentRef}>
        {showLoading ? (
          <Grid height="100%" alignItems="center">
            <ProgressBar
              value={Math.floor(progress)}
              title={fm('generating_notes')}
              showPercentage
            />
          </Grid>
        ) : (
          <div
            className={styles.notes}
            onCopy={handleCopySegment}
            ref={notesRef}
          >
            {filteredNotes.map((note, i) => (
              <div key={i} className={styles.note}>
                <Text>{note}</Text>
              </div>
            ))}
          </div>
        )}
        {error && (
          <Text color="error" bold tag="div" className={styles.error}>
            {fm('error')}
          </Text>
        )}
        {warning && (
          <Text color="grey-type" bold tag="div">
            {warning}
          </Text>
        )}
        {speechProcessingEnabled === false && (
          <Text color="grey-type" bold tag="div">
            {fm('speech_processing_disabled')}
          </Text>
        )}
        <div ref={scrollRef} />
      </div>
      {!isMobile ? (
        <div data-testid="footer" className={styles.footer}>
          {copyButton()}
        </div>
      ) : (
        <div data-testid="sidebar" className={styles.sidebar}>
          <StatusIndicator isActive={isActive} />
          {copyButton()}
        </div>
      )}
    </Card>
  )
}

export default NoteAssistantSummaryView
