import React from 'react'

import { useIntl } from '@babylon/intl'

import useMessages from '~/core/hooks/useMessages'
import { CpSickNotesFragment } from '~/generated'
import DataList from '~/ui/DataList'

import messages from './SickNote.messages'

type SickNoteData = Omit<CpSickNotesFragment['sickNotes'][number], 'id'>

interface SickNoteProps extends SickNoteData {
  onUpdate?: () => void
  onRemove?: () => void
}

const SickNote = ({
  startDate,
  endDate,
  reason,
  onUpdate,
  onRemove,
}: SickNoteProps) => {
  const format = useMessages(messages)
  const { formatDate } = useIntl()

  return (
    <DataList
      data={[
        {
          key: format('reason_key'),
          value: reason,
        },
        {
          key: format('start_key'),
          value: startDate ? formatDate(startDate, { format: 'short' }) : null,
        },
        {
          key: format('end_key'),
          value: endDate ? formatDate(endDate, { format: 'short' }) : null,
        },
      ]}
      onUpdate={onUpdate}
      onRemove={onRemove}
    />
  )
}

export default SickNote
