import { useCallback, useState } from 'react'

// Use remainder operator so that we dont hit max possible integer
const incrementParameter = (num: number): number => ++num % 1000000

const useUpdate = () => {
  const [, setState] = useState(0)
  // useCallback with empty deps as we only want to define updateCb once
  return useCallback(() => setState(incrementParameter), [])
}

export default useUpdate
