import { ApolloError } from '@apollo/client'
import React from 'react'

import { isFilledArray } from '~/core'
import { useMessages } from '~/core/hooks'
import { Section } from '~/features/consultation-summary/ConsultationSummary'
import { ErrorPanel } from '~/ui/ErrorPanel'

import Workflow from '../WorkflowActions/components/Workflow'
import { WorkflowGroup } from './WorkflowActionsSummaryContainer'

import messages from './WorkflowActionsSummary.messages'

export interface WorkflowActionsSummaryViewProps {
  workflowGroups: WorkflowGroup[]
  refetch: () => void
  error?: ApolloError
}

const WorkflowActionsSummaryView = ({
  workflowGroups,
  refetch,
  error,
}: WorkflowActionsSummaryViewProps) => {
  const f = useMessages(messages)

  if (error) {
    return (
      <ErrorPanel
        title={f('error_message')}
        error={error}
        retry={() => refetch()}
      />
    )
  }

  if (!isFilledArray(workflowGroups)) {
    return null
  }

  return (
    <div data-testid="cr-nhs-actions">
      {workflowGroups.map((workflowGroup) => {
        const title = workflowGroup?.label ?? ''
        const testId = `cr-workflow-${workflowGroup?.type}`

        return (
          <Section title={title} testid={testId} key={workflowGroup.type}>
            {workflowGroup.workflows.map((workflow) => (
              <Workflow key={workflow.id} workflow={workflow} />
            ))}
          </Section>
        )
      })}
    </div>
  )
}

export default WorkflowActionsSummaryView
