import parser from 'accept-language-parser'

import { FALLBACK_LOCALE } from '~/constants'

import { logMessage } from '../sentry'
import languages from './languages'

export const LOCALE_STORAGE_KEY = 'cp3_locale'
export const TEST_LOCALE = 'en-TEST'

const hyphenate = (string: string): string => string.replace('_', '-')

export const languageFromLocale = (locale: string): string => {
  return hyphenate(locale).split('-')[0].toLowerCase()
}

export const regionFromLocale = (locale: string): string | null => {
  const code = hyphenate(locale).split('-')[1]
  return code ? code.toUpperCase() : null
}

const getLocalStorageLocale = (): string | null => {
  return localStorage.getItem(LOCALE_STORAGE_KEY)
}

const removeLocalStorageLanguage = (): void => {
  localStorage.removeItem(LOCALE_STORAGE_KEY)
}

export const setLocale = (locale: string): void => {
  if (!locale) {
    throw Error('setLocale must be called with a locale value')
  }
  localStorage.setItem(LOCALE_STORAGE_KEY, hyphenate(locale))
  window.location.reload()
}

const getNavigatorLocale = (): string | undefined => {
  return navigator.languages?.[0] || navigator.language
}

const languageSupported = (code: string) => {
  return languages().some((v) => v.code === code)
}

export const getInitialLocale = (): string => {
  const localStorageVal = getLocalStorageLocale()
  if (localStorageVal) {
    if (languageSupported(localStorageVal)) {
      return localStorageVal
    }
    logMessage(
      `getInitialLocale: localStorage value "${localStorageVal}" is not a supported language`
    )
    removeLocalStorageLanguage()
  }

  const navigatorVal = getNavigatorLocale()
  if (navigatorVal && languageSupported(navigatorVal)) {
    return navigatorVal
  }

  return FALLBACK_LOCALE
}

export const resolveLocale = (
  entitledLanguages: string | undefined
): string => {
  if (!entitledLanguages) {
    logMessage('resolveLocale: no entitled languages configured')
    return FALLBACK_LOCALE
  }

  const localStorageVal = getLocalStorageLocale()
  if (localStorageVal) {
    if (
      parser.pick([localStorageVal], entitledLanguages) &&
      languageSupported(localStorageVal)
    ) {
      return localStorageVal
    }
    logMessage(
      `resolveLocale: localStorage language "${localStorageVal}" was not resolved`
    )
    removeLocalStorageLanguage()
  }

  const navigatorVal = getNavigatorLocale()
  if (
    navigatorVal &&
    parser.pick([navigatorVal], entitledLanguages) &&
    languageSupported(navigatorVal)
  ) {
    return navigatorVal
  }

  const match = parser.pick(
    languages().map((lang) => lang.code),
    entitledLanguages
  )

  if (match) {
    return match
  }

  throw new Error(
    `Locale could be resolved for configured languages: ${entitledLanguages}`
  )
}
