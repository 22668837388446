import { ApolloLink } from '@apollo/client'

import { APP_VERSION } from '~/constants'

const graphqlMiddlewareHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'apollographql-client-name': 'Clinical Portal',
      'apollographql-client-version': APP_VERSION,
    },
  }))

  return forward(operation)
})

export default graphqlMiddlewareHeadersLink
