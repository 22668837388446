import { defineMessages } from 'react-intl'

const baseId = 'components.care_plan_review_section'

export default defineMessages({
  cant_load_care_plan_draft: {
    id: `${baseId}.cant_load_care_plan_draft`,
    defaultMessage: "We can't load the care plan draft right now",
  },
  cant_load_care_plan: {
    id: `${baseId}.cant_load_care_plan`,
    defaultMessage: "We can't load the care plan right now",
  },
  tag_published: {
    id: `${baseId}.tag_published`,
    defaultMessage: 'published',
  },
  tag_draft: {
    id: `${baseId}.tag_draft`,
    defaultMessage: 'draft',
  },
  section_title: {
    id: `${baseId}.section_title`,
    defaultMessage: 'Care Plan',
  },
})
