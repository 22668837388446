import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  WorkflowDefinitionParentMapping: any;
  JSON: any;
  Void: any;
};


export type ActionDataPatch = {
  description?: Maybe<CarePlanValuePatchString>;
};

export type ActionDescriptionEmpty = {
  __typename?: 'ActionDescriptionEmpty';
  actionDraftId: Scalars['ID'];
};

export type ActionDraftDoesNotExistError = {
  __typename?: 'ActionDraftDoesNotExistError';
  actionDraftId: Scalars['ID'];
};

export type ActionWithoutGoal = {
  __typename?: 'ActionWithoutGoal';
  actionDraftId: Scalars['ID'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  reportParameters?: Maybe<Scalars['String']>;
};

export type AddActionDraftResult = AddActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type AddActionDraftSuccess = {
  __typename?: 'AddActionDraftSuccess';
  actionDraft: CarePlanActionDraft;
};

export type AddGoalDraftSuccess = {
  __typename?: 'AddGoalDraftSuccess';
  goalDraft: CarePlanGoalDraft;
};

export type Address = {
  __typename?: 'Address';
  id?: Maybe<Scalars['ID']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  premises?: Maybe<Array<Maybe<Scalars['String']>>>;
  first_line?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  third_line?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  town: Scalars['String'];
  county: Scalars['String'];
  postcode: Scalars['String'];
};

export type AdminActiveIngredient = Paging & {
  __typename?: 'AdminActiveIngredient';
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  activeIngredientId: Scalars['ID'];
  name: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
};

export type AdminActiveIngredientInput = {
  activeIngredientId: Scalars['ID'];
  name: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
};

export type AdminActiveIngredients = Pagination & {
  __typename?: 'AdminActiveIngredients';
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<AdminActiveIngredient>>;
};

export type AdminDrug = {
  __typename?: 'AdminDrug';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<Array<Maybe<AdminActiveIngredient>>>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  drugPreparation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  drugRegions?: Maybe<Array<Maybe<AdminDrugRegionFlag>>>;
  vmpDmdId?: Maybe<Scalars['String']>;
  vtmId?: Maybe<Scalars['String']>;
  expensive?: Maybe<Scalars['Boolean']>;
  generic?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type AdminDrugAlert = Paging & {
  __typename?: 'AdminDrugAlert';
  id: Scalars['ID'];
  activeIngredient?: Maybe<AdminActiveIngredient>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  prescriptionLimit?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  allowPrescribe?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type AdminDrugAlertInput = {
  id?: Maybe<Scalars['ID']>;
  activeIngredient?: Maybe<AdminActiveIngredientInput>;
  activeIngredientId?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  prescriptionLimit?: Maybe<Scalars['Int']>;
  timePeriod?: Maybe<Scalars['String']>;
  allowPrescribe?: Maybe<Scalars['Boolean']>;
};

export type AdminDrugAlerts = Pagination & {
  __typename?: 'AdminDrugAlerts';
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<AdminDrugAlert>>;
};

export type AdminDrugAudit = {
  __typename?: 'AdminDrugAudit';
  id: Scalars['ID'];
  auditableId?: Maybe<Scalars['String']>;
  babylonRequestId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventReason?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  performedById?: Maybe<Scalars['String']>;
  performedByName?: Maybe<Scalars['String']>;
};

export type AdminDrugInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  activeIngredients: Array<Maybe<AdminActiveIngredientInput>>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  drugPreparation?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  drugRegions: Array<Maybe<AdminDrugRegionFlagInput>>;
  vmpDmdId?: Maybe<Scalars['String']>;
  vtmId?: Maybe<Scalars['String']>;
  generic: Scalars['Boolean'];
};

export type AdminDrugPackaging = {
  __typename?: 'AdminDrugPackaging';
  id: Scalars['ID'];
  drugId: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  decimalPrice?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  legalCategory?: Maybe<Scalars['String']>;
  ampDmdId?: Maybe<Scalars['String']>;
};

export type AdminDrugRegion = {
  __typename?: 'AdminDrugRegion';
  id: Scalars['ID'];
  regionCode: Scalars['String'];
  name: Scalars['String'];
  regionIri?: Maybe<Scalars['String']>;
};

export type AdminDrugRegionFlag = {
  __typename?: 'AdminDrugRegionFlag';
  id: Scalars['ID'];
  region: AdminDrugRegion;
  controlled: Scalars['Boolean'];
  blacklisted: Scalars['Boolean'];
  packaging: Array<Maybe<AdminDrugPackaging>>;
};

export type AdminDrugRegionFlagInput = {
  id?: Maybe<Scalars['ID']>;
  region: Scalars['String'];
  controlled: Scalars['Boolean'];
  blacklisted: Scalars['Boolean'];
  blacklistReason?: Maybe<Scalars['String']>;
  packaging: Array<Maybe<DrugPackagingInput>>;
  _isRemoved?: Maybe<Scalars['Boolean']>;
  _isNew?: Maybe<Scalars['Boolean']>;
};

export type AdminDrugResult = {
  __typename?: 'AdminDrugResult';
  totalElements: Scalars['Int'];
  items: Array<Maybe<AdminDrug>>;
};

export type Allergy = {
  __typename?: 'Allergy';
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type AnswerTemplate = {
  __typename?: 'AnswerTemplate';
  id: Scalars['ID'];
  tag: Tag;
  text: Scalars['String'];
};

export type ApplyRefundInput = {
  patientId: Scalars['ID'];
  transactionId: Scalars['ID'];
  reasonId: Scalars['ID'];
};

export enum AppName {
  BabylonUs = 'BABYLON_US'
}

export type Appointment = {
  __typename?: 'Appointment';
  id?: Maybe<Scalars['Int']>;
  ask_for_gp_details: Scalars['Boolean'];
  call_id?: Maybe<Scalars['Int']>;
  cancel_appointment?: Maybe<Scalars['String']>;
  checkIn?: Maybe<CheckIn>;
  clinic?: Maybe<Practice>;
  clinical_note_annotations?: Maybe<Array<Maybe<ClinicalNoteAnnotation>>>;
  completed: Scalars['Boolean'];
  consultant?: Maybe<Consultant>;
  consumer_network?: Maybe<ConsumerNetwork>;
  currency?: Maybe<CurrencyCode>;
  consultant_specialism?: Maybe<Scalars['String']>;
  diagnosis_codes?: Maybe<Array<Maybe<Read2Code>>>;
  diagnosis_notes?: Maybe<Scalars['String']>;
  drugsList?: Maybe<Array<Maybe<Drug>>>;
  examination_codes?: Maybe<Array<Maybe<Read2Code>>>;
  examination_notes?: Maybe<Scalars['String']>;
  further_action_codes?: Maybe<Array<Maybe<Read2Code>>>;
  further_action_notes?: Maybe<Scalars['String']>;
  further_information_urls?: Maybe<Array<Maybe<ConsultationFurtherInfo>>>;
  gp_consent?: Maybe<Scalars['Boolean']>;
  history_codes?: Maybe<Array<Maybe<Read2Code>>>;
  history_notes?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_full_url?: Maybe<Scalars['String']>;
  is_using_promotion: Scalars['Boolean'];
  management_codes?: Maybe<Array<Maybe<Read2Code>>>;
  management_notes?: Maybe<Scalars['String']>;
  medium?: Maybe<AppointmentMedium>;
  membership_code_message_for_gp?: Maybe<Scalars['String']>;
  next_appointment_start_time?: Maybe<Scalars['String']>;
  nlp_review?: Maybe<NlpReview>;
  original_image?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  patient_note?: Maybe<Scalars['String']>;
  pre_auth_number?: Maybe<Scalars['String']>;
  prescription_uploaded?: Maybe<Scalars['Boolean']>;
  prescriptions?: Maybe<Array<Maybe<Prescription>>>;
  prescription_id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  price_fractional?: Maybe<Scalars['Int']>;
  promo_code_message_for_gp?: Maybe<Scalars['String']>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  sick_note?: Maybe<SickNote>;
  show_gp_consent_option: Scalars['Boolean'];
  skip_plans_screen_go_to_payment?: Maybe<Scalars['Boolean']>;
  slot_size?: Maybe<Scalars['Int']>;
  specialism_categories?: Maybe<Array<Maybe<SpecialismCategory>>>;
  state?: Maybe<AppointmentBookingStatus>;
  time?: Maybe<Scalars['String']>;
  review?: Maybe<Review>;
  is_rated?: Maybe<Scalars['Boolean']>;
  consultation?: Maybe<Consultation>;
  /** @deprecated Field no longer supported */
  member_id?: Maybe<Scalars['ID']>;
  babylon_id?: Maybe<Scalars['String']>;
  recording_consent?: Maybe<Scalars['Boolean']>;
  consumer_network_id?: Maybe<Scalars['Int']>;
  call_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  duration?: Maybe<Scalars['Int']>;
  language_requested?: Maybe<Scalars['String']>;
  service_type_uuid?: Maybe<Scalars['ID']>;
  receipt?: Maybe<Receipt>;
  length_in_minutes?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  multimedia?: Maybe<Multimedia>;
  timezone_id?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  timezone?: Maybe<Scalars['String']>;
  listing_details?: Maybe<ListingDetails>;
  service_type?: Maybe<ServiceType>;
  consultation_type?: Maybe<AppointmentMedium>;
  duration_minutes: Scalars['Int'];
  booking_type?: Maybe<Scalars['String']>;
};

export type AppointmentAvailabilitySlot = {
  __typename?: 'AppointmentAvailabilitySlot';
  id: Scalars['ID'];
  time: Scalars['Date'];
  duration_minutes: Scalars['Int'];
  slot_medium: SlotMedium;
  clinic_id?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<AppointmentAvailabilitySlotAppointment>;
};

export type AppointmentAvailabilitySlotAppointment = {
  __typename?: 'AppointmentAvailabilitySlotAppointment';
  id: Scalars['ID'];
  medium: AppointmentMedium;
  image_attached?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  language_requested?: Maybe<Scalars['String']>;
  status?: Maybe<ConsultationStatus>;
  consumer_network?: Maybe<ConsumerNetwork>;
  patient?: Maybe<Patient>;
};

export type AppointmentAvailabilitySlotsFilter = {
  consultantUUID?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  clientCompatibleFlows?: Maybe<Scalars['String']>;
};

export enum AppointmentBookingStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  TimedOut = 'timed_out',
  NoShow = 'no_show',
  Paid = 'paid',
  Completed = 'completed'
}

export type AppointmentCategory = {
  __typename?: 'AppointmentCategory';
  uuid: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  highlighted?: Maybe<Scalars['Boolean']>;
  icon_url?: Maybe<Scalars['String']>;
  consultation_types: Array<AppointmentMedium>;
  order?: Maybe<Scalars['Int']>;
  service_types: Array<ServiceType>;
  service_type_uuids: Array<Scalars['String']>;
};

export type AppointmentCategoryDetailsInput = {
  uuid: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  icon_url: Scalars['String'];
  consultation_types: Array<AppointmentMediumForConfigCategory>;
  serviceTypes: Array<ServiceTypeForConfigCategory>;
};

export type AppointmentConfigurations = {
  __typename?: 'AppointmentConfigurations';
  appointment_configurations: Array<Maybe<ConfigurationTenancies>>;
};

export type AppointmentCredit = {
  __typename?: 'AppointmentCredit';
  id: Scalars['ID'];
  is_redeemed?: Maybe<Scalars['Boolean']>;
  consumer_network_id?: Maybe<Scalars['String']>;
  consumer_network_name?: Maybe<Scalars['String']>;
  reason_id?: Maybe<Scalars['String']>;
  show_reason?: Maybe<Scalars['String']>;
  professions?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<PortalUser>;
};

export type AppointmentCreditReason = {
  __typename?: 'AppointmentCreditReason';
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export enum AppointmentFeatures {
  ServiceTypeBooking = 'SERVICE_TYPE_BOOKING'
}

export enum AppointmentFeaturesParams {
  ServiceTypeBooking = 'service_type_booking'
}

/** Ids of the images to be added to the appointment */
export type AppointmentImageInput = {
  id?: Maybe<Scalars['ID']>;
};

export type AppointmentInvite = {
  __typename?: 'AppointmentInvite';
  id: Scalars['ID'];
  member_uuid: Scalars['ID'];
  member?: Maybe<Patient>;
  /** @deprecated Field no longer supported */
  service_type_uuid: Scalars['ID'];
  nullable_service_type_uuid?: Maybe<Scalars['ID']>;
  duration_minutes: Scalars['Int'];
  consultation_type: Scalars['String'];
  created_at: Scalars['String'];
  earliest_booking_date: Scalars['String'];
  recurrence?: Maybe<Recurrence>;
  booking_agent_uuid: Scalars['String'];
  notes_for_member?: Maybe<Scalars['String']>;
  service_type?: Maybe<ServiceType>;
  intimate: Scalars['Boolean'];
  appointment_id?: Maybe<Scalars['ID']>;
  preferred_medium?: Maybe<Scalars['String']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_profession?: Maybe<Scalars['String']>;
  allowed_professions?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_profession_name?: Maybe<Scalars['String']>;
  consultant_uuid?: Maybe<Scalars['String']>;
  group_correlation_id?: Maybe<Scalars['String']>;
  consultant?: Maybe<Consultant>;
  associated_appointments?: Maybe<Array<Appointment>>;
  expired?: Maybe<Scalars['Boolean']>;
};

export enum AppointmentInviteFeatures {
  CreateInviteWithBookings = 'create_invite_with_bookings'
}

export type AppointmentInviteFilter = {
  source_appointment_id?: Maybe<Scalars['ID']>;
  member_uuid?: Maybe<Scalars['ID']>;
  including_digital?: Maybe<Scalars['Boolean']>;
  unused?: Maybe<Scalars['Boolean']>;
};

export type AppointmentInviteInput = {
  member_uuid: Scalars['ID'];
  service_type_uuid?: Maybe<Scalars['ID']>;
  source_appointment_id?: Maybe<Scalars['ID']>;
  consultation_type?: Maybe<Scalars['String']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_medium?: Maybe<Scalars['String']>;
  earliest_booking_date: Scalars['String'];
  recurrence?: Maybe<RecurrenceInput>;
  notes_for_member: Scalars['String'];
  duration_minutes?: Maybe<Scalars['Int']>;
  intimate: Scalars['Boolean'];
  preferred_profession?: Maybe<Scalars['String']>;
  allowed_professions?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultant_uuid?: Maybe<Scalars['String']>;
  preferred_date_time?: Maybe<Scalars['Date']>;
  timezone_id?: Maybe<Scalars['String']>;
  appointment_invite_features?: Maybe<Array<AppointmentInviteFeatures>>;
};

export enum AppointmentMedium {
  Voice = 'voice',
  Video = 'video',
  Physical = 'physical',
  Text = 'text'
}

export enum AppointmentMediumForConfigCategory {
  Voice = 'voice',
  Video = 'video',
  Physical = 'physical',
  Text = 'text'
}

export type AppointmentRequestInput = {
  image?: Maybe<Scalars['String']>;
  image_filename?: Maybe<Scalars['String']>;
  medium: AppointmentMedium;
  patient_id: Scalars['Int'];
  patient_note?: Maybe<Scalars['String']>;
  preferred_date_time?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  specialist_id?: Maybe<Scalars['Int']>;
};

export type AppointmentRequestInputForMicroservice = {
  appointment_features?: Maybe<Array<Maybe<AppointmentFeaturesParams>>>;
  appointment_invite_id?: Maybe<Scalars['String']>;
  appointment_reason?: Maybe<Scalars['String']>;
  chaperone_gender?: Maybe<Scalars['String']>;
  chaperone_reason?: Maybe<Scalars['String']>;
  chaperone_required?: Maybe<Scalars['Boolean']>;
  clinic_id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['String']>;
  consultant_type?: Maybe<Scalars['String']>;
  consultation_type?: Maybe<AppointmentMedium>;
  timezone_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  member_id: Scalars['Int'];
  preferred_date_time?: Maybe<Scalars['String']>;
  recording_consent?: Maybe<Scalars['Boolean']>;
  duration_minutes?: Maybe<Scalars['Int']>;
  language_requested?: Maybe<Scalars['String']>;
  service_type_uuid?: Maybe<Scalars['ID']>;
  service_types?: Maybe<Array<Maybe<Scalars['ID']>>>;
  needs_payment?: Maybe<Scalars['Boolean']>;
  client_compatible_flows?: Maybe<Array<ClientCompatibleFlows>>;
};

export type AppointmentSlot = {
  __typename?: 'AppointmentSlot';
  time?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  clinic_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  consultants_availability?: Maybe<Array<Maybe<ConsultantAvailability>>>;
};

export type AppointmentSlots = {
  __typename?: 'AppointmentSlots';
  appointment_slots?: Maybe<Array<Maybe<AppointmentSlot>>>;
};

export type AppointmentSlotsRequest = {
  memberId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  consultationType?: Maybe<AppointmentMedium>;
  consultantType?: Maybe<Scalars['String']>;
  consultantId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  serviceTypeUuid?: Maybe<Scalars['ID']>;
  availableOnly?: Maybe<Scalars['Boolean']>;
  include?: Maybe<Scalars['String']>;
  appointmentFeatures?: Maybe<AppointmentFeatures>;
};

export type AppointmentV2 = {
  __typename?: 'AppointmentV2';
  id?: Maybe<Scalars['Int']>;
  member_id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['ID']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
  expected_consultant_profession_name?: Maybe<Scalars['String']>;
  state?: Maybe<AppointmentBookingStatus>;
  consultation_type?: Maybe<AppointmentMedium>;
  appointment_time?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  paid_at?: Maybe<Scalars['Date']>;
  timezone_id?: Maybe<Scalars['String']>;
  duration_minutes: Scalars['Int'];
  appointment_reason?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  confirmed?: Maybe<Scalars['Boolean']>;
  refundable?: Maybe<Scalars['Boolean']>;
  image_attached?: Maybe<Scalars['Boolean']>;
  gp_consent?: Maybe<Scalars['Boolean']>;
  recording_consent?: Maybe<Scalars['Boolean']>;
  booking_type?: Maybe<Scalars['String']>;
  tenancy_contract_id?: Maybe<Scalars['ID']>;
  tenancy_plan_id?: Maybe<Scalars['ID']>;
  tenancy_partner_id?: Maybe<Scalars['ID']>;
  tenancy_region_id?: Maybe<Scalars['ID']>;
};

export type AppointmentWaitTime = {
  __typename?: 'AppointmentWaitTime';
  medium: Scalars['String'];
  status: Scalars['Int'];
  nextAppointmentTimes: Array<Scalars['String']>;
  consumerNetwork: ConsumerNetwork;
  serviceType: ServiceType;
  timeUpdated: Scalars['String'];
};

export type AppointmentWaitTimeConfig = {
  __typename?: 'AppointmentWaitTimeConfig';
  consumerNetwork: ConsumerNetwork;
  serviceType?: Maybe<ServiceType>;
  waitTimeStatusThresholds: Array<AppointmentWaitTimeThreshold>;
};

export type AppointmentWaitTimeConfigs = {
  __typename?: 'AppointmentWaitTimeConfigs';
  waitTimeConfigs: Array<AppointmentWaitTimeConfig>;
  total: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type AppointmentWaitTimeConfigsInput = {
  consumer_network_id: Scalars['Int'];
  service_type_uuid?: Maybe<Scalars['String']>;
  wait_time_status_thresholds: Array<AppointmentWaitTimeThresholdInput>;
};

export type AppointmentWaitTimes = {
  __typename?: 'AppointmentWaitTimes';
  waitTimes: Array<AppointmentWaitTime>;
  total: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export enum AppointmentWaitTimeStatusType {
  Critical = 'critical',
  Overloaded = 'overloaded',
  Busy = 'busy'
}

export type AppointmentWaitTimeThreshold = {
  __typename?: 'AppointmentWaitTimeThreshold';
  type: Scalars['String'];
  value: Scalars['Int'];
};

export type AppointmentWaitTimeThresholdInput = {
  type: AppointmentWaitTimeStatusType;
  value: Scalars['Int'];
};

export type AssociateGoalAndActionDraftResult = AssociateGoalAndActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | ActionDraftDoesNotExistError | GoalActionDraftAssociationAlreadyExistError | CarePlanAuthorizationError;

export type AssociateGoalAndActionDraftSuccess = {
  __typename?: 'AssociateGoalAndActionDraftSuccess';
  goalDraft: CarePlanGoalDraft;
  actionDraft: CarePlanActionDraft;
};

export type Author = {
  __typename?: 'Author';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  portal_loginable_type?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  user_id?: Maybe<Scalars['ID']>;
};

export type AvailabilitySlot = {
  __typename?: 'AvailabilitySlot';
  id: Scalars['ID'];
  slot_time: Scalars['String'];
  slot_size: Scalars['Int'];
  physical: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  digital_bookable: Scalars['Boolean'];
  physical_bookable: Scalars['Boolean'];
};

export type AvailabilitySlots = {
  __typename?: 'AvailabilitySlots';
  availability_slots: Array<AvailabilitySlot>;
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type AvailabilitySlotsInput = {
  clinicianId: Scalars['ID'];
  slotTimes: Array<Scalars['Date']>;
  slotsSize: Scalars['Int'];
  digitalBookable?: Maybe<Scalars['Boolean']>;
  physicalBookable?: Maybe<Scalars['Boolean']>;
  textBookable?: Maybe<Scalars['Boolean']>;
};

export type AvailabilitySlotsRequest = {
  clinician_id?: Maybe<Array<Scalars['ID']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  slot_size?: Maybe<Array<Scalars['Int']>>;
  slot_time_from: Scalars['String'];
  slot_time_to: Scalars['String'];
};

export type BirthDetailInput = {
  country: Scalars['String'];
  town: Scalars['String'];
};

export type BirthDetails = {
  __typename?: 'BirthDetails';
  country: Scalars['String'];
  town: Scalars['String'];
};

export type BloodTest = TimelineEventInterface & {
  __typename?: 'BloodTest';
  id: Scalars['ID'];
  url: Scalars['String'];
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export type BookingRequestResult = {
  __typename?: 'BookingRequestResult';
  appointment?: Maybe<Appointment>;
  availability?: Maybe<Array<Maybe<ClinicianAvailability>>>;
};

export type BookingRestricted = {
  __typename?: 'BookingRestricted';
  duration_unit?: Maybe<Scalars['String']>;
  duration_value?: Maybe<Scalars['Int']>;
};

export type BookingRestrictedInput = {
  duration_unit?: Maybe<Scalars['String']>;
  duration_value?: Maybe<Scalars['Int']>;
};

export enum BookingType {
  Fixed = 'fixed',
  Rtm = 'rtm',
  Queued = 'queued'
}

export type Call = {
  __typename?: 'Call';
  id?: Maybe<Scalars['ID']>;
  expiry?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CallFlowStatus = {
  __typename?: 'CallFlowStatus';
  call_flow_steps: Array<Maybe<CallFlowStepItem>>;
  missed_by?: Maybe<MultimediaFeedbackClientType>;
};

export type CallFlowStepItem = {
  __typename?: 'CallFlowStepItem';
  appointment_id: Scalars['ID'];
  flow_step_type: CallStep;
  app_name: Scalars['String'];
  app_version: Scalars['String'];
  platform_name: Scalars['String'];
  platform_version: Scalars['String'];
  triggered_at: Scalars['Date'];
  user_agent: Scalars['String'];
  duration_in_ms?: Maybe<Scalars['Int']>;
};

export enum CallStep {
  CallStarted = 'call_started',
  MultimediaCallServiceInvoked = 'multimedia_call_service_invoked',
  NotificationAccepted = 'notification_accepted',
  NotificationReceived = 'notification_received',
  NotificationsServiceInvoked = 'notifications_service_invoked',
  TimerStarted = 'timer_started',
  TwilioVoiceCallAnswered = 'twilio_voice_call_answered',
  TwilioVoiceCallBusy = 'twilio_voice_call_busy',
  TwilioVoiceCallCancelled = 'twilio_voice_call_cancelled',
  TwilioVoiceCallCompleted = 'twilio_voice_call_completed',
  TwilioVoiceCallFailed = 'twilio_voice_call_failed',
  TwilioVoiceCallInitiated = 'twilio_voice_call_initiated',
  TwilioVoiceCallNoAnswer = 'twilio_voice_call_no_answer',
  TwilioVoiceCallRinging = 'twilio_voice_call_ringing',
  VendorNotificationsProviderInvoked = 'vendor_notifications_provider_invoked',
  VideoCallEndedByClinician = 'video_call_ended_by_clinician',
  VideoCallEndedByPatient = 'video_call_ended_by_patient',
  VideoCallRejectedByPatient = 'video_call_rejected_by_patient',
  VoiceCallEndedByClinician = 'voice_call_ended_by_clinician',
  VoiceCallStarted = 'voice_call_started'
}

export type CancelledAppointment = {
  __typename?: 'CancelledAppointment';
  id?: Maybe<Scalars['Int']>;
};

export type CancelReason = {
  __typename?: 'CancelReason';
  id: Scalars['ID'];
  cancel_reason_type?: Maybe<Scalars['String']>;
  consultant_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  show_reason?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type CarePlan = {
  __typename?: 'CarePlan';
  id: Scalars['ID'];
  entityCreatedAt: Scalars['String'];
  revisionCreatedAt: Scalars['String'];
  revisionId: Scalars['String'];
  goals: Array<CarePlanGoal>;
  actions: Array<CarePlanAction>;
  goalActionAssociations: Array<CarePlanGoalActionAssociation>;
};

export type CarePlanAction = {
  __typename?: 'CarePlanAction';
  id: Scalars['ID'];
  description: Scalars['String'];
  entityCreatedAt: Scalars['String'];
  revisionCreatedAt: Scalars['String'];
};

export type CarePlanActionDraft = {
  __typename?: 'CarePlanActionDraft';
  draftId: Scalars['ID'];
  description: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  parentCarePlanDraft?: Maybe<CarePlanDraft>;
};

export type CarePlanAddActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  actionDraftId?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
};

export type CarePlanAddGoalDraftData = {
  __typename?: 'CarePlanAddGoalDraftData';
  addGoalDraft: CarePlanAddGoalDraftResult;
};

export type CarePlanAddGoalDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  description: Scalars['String'];
};

export type CarePlanAddGoalDraftResult = AddGoalDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanAddGoalDraftVariables = {
  input?: Maybe<CarePlanAddGoalDraftInput>;
};

export type CarePlanAssociateGoalAndActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export enum CarePlanAuthorizationAction {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE',
  Publish = 'PUBLISH'
}

export type CarePlanAuthorizationError = {
  __typename?: 'CarePlanAuthorizationError';
  action: CarePlanAuthorizationAction;
  resource: CarePlanAuthorizationResource;
};

export enum CarePlanAuthorizationResource {
  CarePlan = 'CARE_PLAN',
  CarePlanDraft = 'CARE_PLAN_DRAFT'
}

export type CarePlanDeleteActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  actionDraftId: Scalars['ID'];
};

export type CarePlanDeleteActionDraftResult = DeleteActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | ActionDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanDeleteGoalDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
};

export type CarePlanDeleteGoalDraftResult = DeleteGoalDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanDissociateGoalAndActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type CarePlanDissociateGoalAndActionDraftResult = DissociateGoalAndActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | ActionDraftDoesNotExistError | GoalActionDraftAssociationDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanDraft = {
  __typename?: 'CarePlanDraft';
  draftId: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  upstreamRevisionId: Scalars['String'];
  goals: Array<CarePlanGoalDraft>;
  actions: Array<CarePlanActionDraft>;
  goalActionAssociations: Array<CarePlanGoalActionAssociationDraft>;
  metadata: DraftMetadata;
};

export type CarePlanDraftContext = {
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
  carePlanDraftId: Scalars['ID'];
  numberOfEdits: Scalars['Int'];
};

export type CarePlanDraftDoesNotExistError = {
  __typename?: 'CarePlanDraftDoesNotExistError';
  draftId: Scalars['ID'];
};

export type CarePlanDraftResult = CarePlanDraft | None | CarePlanAuthorizationError;

export type CarePlanDraftWithoutGoalsAndActions = {
  __typename?: 'CarePlanDraftWithoutGoalsAndActions';
  _?: Maybe<Scalars['Boolean']>;
};

export type CarePlanEditActionDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  actionDraftId: Scalars['ID'];
  actionDataPatch: ActionDataPatch;
};

export type CarePlanEditActionDraftResult = EditActionDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanEditGoalDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
  goalDraftId: Scalars['ID'];
  goalDataPatch: CarePlanGoalDataPatch;
};

export type CarePlanEditGoalDraftResult = EditGoalDraftSuccess | InvalidCarePlanDraftNumbersOfEditsError | CarePlanDraftDoesNotExistError | GoalDraftDoesNotExistError | CarePlanAuthorizationError;

export type CarePlanGoal = {
  __typename?: 'CarePlanGoal';
  id: Scalars['ID'];
  description: Scalars['String'];
  entityCreatedAt: Scalars['String'];
  revisionCreatedAt: Scalars['String'];
  associatedActions: Array<CarePlanAction>;
};

export type CarePlanGoalActionAssociation = {
  __typename?: 'CarePlanGoalActionAssociation';
  actionId: Scalars['ID'];
  goalId: Scalars['ID'];
  createdAt: Scalars['String'];
};

export type CarePlanGoalActionAssociationDraft = {
  __typename?: 'CarePlanGoalActionAssociationDraft';
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type CarePlanGoalDataPatch = {
  description: CarePlanValuePatchString;
};

export type CarePlanGoalDraft = {
  __typename?: 'CarePlanGoalDraft';
  draftId: Scalars['ID'];
  description: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  associatedActionDrafts?: Maybe<Array<CarePlanActionDraft>>;
  parentCarePlanDraft?: Maybe<CarePlanDraft>;
};

export type CarePlanPublishedInConsultationResult = CarePlan | None | CarePlanAuthorizationError;

export type CarePlanResult = CarePlan | None | CarePlanAuthorizationError;

export type CarePlanValuePatchString = {
  newValue: Scalars['String'];
};

export type Carer = {
  __typename?: 'Carer';
  id: Scalars['ID'];
  type: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  profile: Profile;
};

export type CategoryServiceTypeMapping = {
  __typename?: 'CategoryServiceTypeMapping';
  id?: Maybe<Scalars['ID']>;
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  serviceTypes?: Maybe<Array<Maybe<ServiceTypeForConfig>>>;
  icon_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  consultation_types?: Maybe<Array<Maybe<AppointmentMedium>>>;
};

export type ChatacriptMessageInputParamsChoice = {
  __typename?: 'ChatacriptMessageInputParamsChoice';
  id: Scalars['String'];
  label: Scalars['String'];
  multiselect?: Maybe<Scalars['Boolean']>;
  preselected?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type ChatscriptConversation = TimelineEventInterface & {
  __typename?: 'ChatscriptConversation';
  id: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  result?: Maybe<ChatscriptConversationResult>;
  startedAt: Scalars['String'];
  status?: Maybe<ChatscriptConversationStatus>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
  patientUuid?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};

export enum ChatscriptConversationOutcome {
  Success = 'success',
  Failure = 'failure',
  Undefined = 'undefined'
}

export type ChatscriptConversationResult = {
  __typename?: 'ChatscriptConversationResult';
  outcome?: Maybe<ChatscriptConversationOutcome>;
  type?: Maybe<Scalars['String']>;
};

export type ChatscriptConversationsResponse = {
  __typename?: 'ChatscriptConversationsResponse';
  more?: Maybe<Scalars['Boolean']>;
  conversations: Array<ChatscriptConversation>;
};

export enum ChatscriptConversationStatus {
  Started = 'started',
  Finished = 'finished'
}

export type ChatscriptMessage = {
  __typename?: 'ChatscriptMessage';
  id: Scalars['ID'];
  author?: Maybe<ChatscriptMessageAuthor>;
  input?: Maybe<ChatscriptMessageInput>;
  rateable?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<ChatscriptMessageRating>;
  style?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  undoable?: Maybe<Scalars['Boolean']>;
  value?: Maybe<ChatscriptMessageValue>;
  sender?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
};

export type ChatscriptMessageAuthor = {
  __typename?: 'ChatscriptMessageAuthor';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChatscriptMessageInput = {
  __typename?: 'ChatscriptMessageInput';
  type?: Maybe<Scalars['String']>;
  params?: Maybe<ChatscriptMessageInputParams>;
};

export type ChatscriptMessageInputParams = {
  __typename?: 'ChatscriptMessageInputParams';
  choices?: Maybe<Array<ChatacriptMessageInputParamsChoice>>;
  suggest?: Maybe<Scalars['Boolean']>;
};

export type ChatscriptMessageRating = {
  __typename?: 'ChatscriptMessageRating';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ChatscriptMessagesResponse = {
  __typename?: 'ChatscriptMessagesResponse';
  messages: Array<ChatscriptMessage>;
  more?: Maybe<Scalars['Boolean']>;
};

export type ChatscriptMessageValue = {
  __typename?: 'ChatscriptMessageValue';
  helpText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type CheckIn = {
  __typename?: 'CheckIn';
  check_in_flow_support?: Maybe<Scalars['String']>;
  check_in_data?: Maybe<CheckInData>;
};

export type CheckInData = {
  __typename?: 'CheckInData';
  check_in_open_before_appointment_minutes?: Maybe<Scalars['Int']>;
  check_in_open_at?: Maybe<Scalars['String']>;
  check_in_close_at?: Maybe<Scalars['String']>;
  check_in_display_close_at?: Maybe<Scalars['String']>;
  checked_in_at?: Maybe<Scalars['String']>;
  check_in_status?: Maybe<Scalars['String']>;
};

export type CheckInDetails = {
  __typename?: 'CheckInDetails';
  status: CheckInStatus;
  checkedInAt?: Maybe<Scalars['String']>;
  location?: Maybe<CheckInLocation>;
};

export type CheckInLocation = {
  __typename?: 'CheckInLocation';
  useRegisteredLocation: Scalars['Boolean'];
  temporaryLocation?: Maybe<CheckInTemporaryLocation>;
};

export enum CheckInStatus {
  NotOpened = 'NOT_OPENED',
  Open = 'OPEN',
  CheckedIn = 'CHECKED_IN',
  Missed = 'MISSED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export type CheckInTemporaryLocation = {
  __typename?: 'CheckInTemporaryLocation';
  countryIsoCode: Scalars['String'];
  address: Scalars['String'];
};

export enum ClientCompatibleFlows {
  CheckIn = 'CHECK_IN'
}

export type ClinicalCode = {
  __typename?: 'ClinicalCode';
  code?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
};

export type ClinicalCodeInput = {
  iri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicalComissioningGroup = {
  __typename?: 'ClinicalComissioningGroup';
  code?: Maybe<Scalars['String']>;
  gss_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ClinicalKnowledgeEntity = {
  __typename?: 'ClinicalKnowledgeEntity';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  altLabels: Array<Scalars['String']>;
  semanticType?: Maybe<SemanticType>;
  definitions: Array<Scalars['String']>;
  externalOntologies: Array<ExternalOntology>;
  parents: Array<ClinicalKnowledgeEntity>;
  children: Array<ClinicalKnowledgeEntity>;
  outgoingRelations: Array<ClinicalKnowledgeRelation>;
  incomingRelations: Array<ClinicalKnowledgeRelation>;
  domains?: Maybe<Array<SemanticType>>;
  ranges?: Maybe<Array<SemanticType>>;
  events: Array<ClinicalKnowledgeProvenanceEvent>;
  type: Scalars['String'];
  legacyIri?: Maybe<Scalars['String']>;
};

export type ClinicalKnowledgeLabel = {
  __typename?: 'ClinicalKnowledgeLabel';
  id?: Maybe<Scalars['ID']>;
  iri?: Maybe<Scalars['String']>;
  suggestedLabel?: Maybe<Scalars['String']>;
  matchLabel?: Maybe<Scalars['String']>;
  matchLabelLanguage?: Maybe<Scalars['String']>;
  preferredLabel?: Maybe<Scalars['String']>;
  preferredLabelLanguage?: Maybe<Scalars['String']>;
  hasNotes?: Maybe<Scalars['Boolean']>;
};

export type ClinicalKnowledgeProvenanceEvent = {
  __typename?: 'ClinicalKnowledgeProvenanceEvent';
  id: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  dataset: Scalars['String'];
  time: Scalars['Date'];
  vote: Vote;
  datasetChangeVersion: Scalars['String'];
  statement: ProvenanceEventStatement;
};

export type ClinicalKnowledgeRelation = {
  __typename?: 'ClinicalKnowledgeRelation';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  entities: Array<ClinicalKnowledgeRelationEntity>;
};

export type ClinicalKnowledgeRelationEntity = {
  __typename?: 'ClinicalKnowledgeRelationEntity';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
};

export type ClinicalKnowledgeView = {
  __typename?: 'ClinicalKnowledgeView';
  name: Scalars['String'];
  populated: Scalars['Boolean'];
};

export type ClinicalNote = {
  __typename?: 'ClinicalNote';
  iri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  furtherActionNotes?: Maybe<Scalars['String']>;
  managementNotes?: Maybe<Scalars['String']>;
  treatmentPlans?: Maybe<Array<Maybe<TreatmentPlan>>>;
  guidelines?: Maybe<Scalars['String']>;
};

export type ClinicalNoteAnnotation = {
  __typename?: 'ClinicalNoteAnnotation';
  id?: Maybe<Scalars['ID']>;
};

export type ClinicalQueryInput = {
  preferred_date_time?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  specialist_id?: Maybe<Scalars['Int']>;
  medium?: Maybe<AppointmentMedium>;
};

export type ClinicalTerminologyAttribute = {
  __typename?: 'ClinicalTerminologyAttribute';
  iri: Scalars['String'];
  snomedCode?: Maybe<Scalars['String']>;
  preferredLabel: Scalars['String'];
};

export type ClinicalTerminologyAttributeComplete = {
  __typename?: 'ClinicalTerminologyAttributeComplete';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  domains: Array<ClinicalTerminologyDomain>;
  ranges: Array<ClinicalTerminologyRange>;
};

export type ClinicalTerminologyBaseEntity = {
  __typename?: 'ClinicalTerminologyBaseEntity';
  preferredLabel: Scalars['String'];
  iri: Scalars['String'];
  isContribution?: Maybe<Scalars['Boolean']>;
};

export type ClinicalTerminologyCardinality = {
  __typename?: 'ClinicalTerminologyCardinality';
  min: Scalars['String'];
  max: Scalars['String'];
};

export type ClinicalTerminologyContribution = {
  __typename?: 'ClinicalTerminologyContribution';
  id: Scalars['String'];
  path: Scalars['String'];
  author: Scalars['String'];
  reviews: Array<Maybe<ContributionReview>>;
  status: Scalars['String'];
  time: Scalars['Int'];
  ticket?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ClinicalTerminologyContributionList = {
  __typename?: 'ClinicalTerminologyContributionList';
  contributions: Array<ClinicalTerminologyContribution>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type ClinicalTerminologyDomain = {
  __typename?: 'ClinicalTerminologyDomain';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
  cardinality: ClinicalTerminologyCardinality;
};

export type ClinicalTerminologyEntity = {
  __typename?: 'ClinicalTerminologyEntity';
  iri: Scalars['String'];
  legacyIri?: Maybe<Scalars['String']>;
  preferredLabel: Scalars['String'];
  semanticTag: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  altLabels: Array<Maybe<Scalars['String']>>;
  parents: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  children: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  outgoingRelations: Array<Maybe<ClinicalTerminologyExternalRelation>>;
  incomingRelations: Array<Maybe<ClinicalTerminologyExternalRelation>>;
  inheritedParents: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  inheritedChildren: Array<Maybe<ClinicalTerminologyBaseEntity>>;
  code: SystemCode;
  isDefined: Scalars['Boolean'];
};

export type ClinicalTerminologyEntityBaseLabel = {
  __typename?: 'ClinicalTerminologyEntityBaseLabel';
  value: Scalars['String'];
  lang: Scalars['String'];
};

export type ClinicalTerminologyEvents = {
  __typename?: 'ClinicalTerminologyEvents';
  iri: Scalars['String'];
  events: Array<ClinicalKnowledgeProvenanceEvent>;
};

export type ClinicalTerminologyExternalRelation = {
  __typename?: 'ClinicalTerminologyExternalRelation';
  preferredLabel: Scalars['String'];
  iri: Scalars['String'];
  isContribution: Scalars['Boolean'];
  entities?: Maybe<Array<ClinicalTerminologyBaseEntity>>;
};

export type ClinicalTerminologyLabelsEntity = {
  __typename?: 'ClinicalTerminologyLabelsEntity';
  iri: Scalars['String'];
  snomedCode?: Maybe<Scalars['String']>;
  preferredLabel: Scalars['String'];
  semanticTag: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  altLabels: Array<Maybe<Scalars['String']>>;
};

export type ClinicalTerminologyRange = {
  __typename?: 'ClinicalTerminologyRange';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
};

export type ClinicalTerminologySearchEntity = {
  __typename?: 'ClinicalTerminologySearchEntity';
  concepts: Array<Maybe<ClinicalTerminologyLabelsEntity>>;
  total: Scalars['Int'];
  pageCursor: Scalars['String'];
};

export type ClinicianAvailability = {
  __typename?: 'ClinicianAvailability';
  appointment_id?: Maybe<Scalars['Int']>;
  available_for_booking?: Maybe<Scalars['Boolean']>;
  category_id: Scalars['Int'];
  consultant_id: Scalars['Int'];
  diff_to_preferred_time: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  priority: Scalars['Int'];
  recurrent: Scalars['Boolean'];
  slot_size: Scalars['Int'];
  supply_network_id?: Maybe<Scalars['Int']>;
  time: Scalars['String'];
};

export type ClinicianAvailabilityShift = {
  __typename?: 'ClinicianAvailabilityShift';
  id: Scalars['ID'];
  shift_start_time: Scalars['String'];
  shift_end_time: Scalars['String'];
  shift_type: ClinicianAvailabilityShiftType;
};

export type ClinicianAvailabilityShifts = {
  __typename?: 'ClinicianAvailabilityShifts';
  items: Array<ClinicianAvailabilityShift>;
};

export enum ClinicianAvailabilityShiftType {
  Rtm = 'RTM',
  Fixed = 'FIXED',
  Hybrid = 'HYBRID'
}

export type ClinicianVisibility = {
  __typename?: 'ClinicianVisibility';
  clinician_slots?: Maybe<Array<Maybe<ClinicianVisibilityClinicianSlots>>>;
};

export type ClinicianVisibilityClinicianSlots = {
  __typename?: 'ClinicianVisibilityClinicianSlots';
  clinician?: Maybe<Consultant>;
  slots?: Maybe<Array<Maybe<ClinicianVisibilitySlots>>>;
};

export type ClinicianVisibilitySlot = {
  __typename?: 'ClinicianVisibilitySlot';
  id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['ID']>;
  appointment?: Maybe<Appointment>;
  slot_time: Scalars['String'];
  slot_size: Scalars['Int'];
  physical: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  digital_bookable: Scalars['Boolean'];
  physical_bookable: Scalars['Boolean'];
  clinic_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  rtm_slot: Scalars['Boolean'];
};

export type ClinicianVisibilitySlots = {
  __typename?: 'ClinicianVisibilitySlots';
  visibility_slots?: Maybe<Array<Maybe<ClinicianVisibilitySlot>>>;
  clinic?: Maybe<Practice>;
};

export type CloneAppointmentConfiguration = {
  __typename?: 'CloneAppointmentConfiguration';
  name?: Maybe<Scalars['String']>;
};

export type Code = {
  __typename?: 'Code';
  id: Scalars['Int'];
  promotion?: Maybe<Promotion>;
  membership?: Maybe<Membership>;
};

export type CodeAndDisplayInput = {
  code: Scalars['String'];
  display: Scalars['String'];
};

export type CodeTranslation = {
  __typename?: 'CodeTranslation';
  name: Scalars['String'];
  valueCoding: ValueCoding;
};

export type Comment = Paging & {
  __typename?: 'Comment';
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Author>;
  type?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
};

export type Comments = Pagination & {
  __typename?: 'Comments';
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<Comment>>;
};

export type Concept = {
  __typename?: 'Concept';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export type Condition = {
  __typename?: 'Condition';
  id?: Maybe<Scalars['ID']>;
  info?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
};

export type ConfigurationByContract = {
  __typename?: 'ConfigurationByContract';
  contractId?: Maybe<Scalars['ID']>;
  configuration?: Maybe<Configurations>;
};

export type ConfigurationDetailsType = {
  __typename?: 'ConfigurationDetailsType';
  serviceTypesForConfiguration?: Maybe<Array<Maybe<ServiceTypeForConfig>>>;
  categoryServiceTypeMapping?: Maybe<Array<Maybe<CategoryServiceTypeMapping>>>;
};

export type Configurations = {
  __typename?: 'Configurations';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  medium_configuration?: Maybe<Array<Maybe<Medium>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  service_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['String']>;
};

export type ConfigurationTenancies = {
  __typename?: 'ConfigurationTenancies';
  configuration: Configurations;
  tenancies: Array<Maybe<Tenancies>>;
};

export type Consultant = Subject & {
  __typename?: 'Consultant';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  avatar?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  full_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  digital_signature_file_name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  bupa_provider_number?: Maybe<Scalars['String']>;
  /** @deprecated Use `avatar_expiring_url`. */
  avatar_full_url?: Maybe<Scalars['String']>;
  avatar_expiring_url?: Maybe<Scalars['String']>;
  nhs?: Maybe<Scalars['Boolean']>;
  profession_name?: Maybe<Scalars['String']>;
  country_of_residence?: Maybe<Scalars['String']>;
  medical_identifier?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  rating?: Maybe<ConsultantRating>;
  supply_networks?: Maybe<Array<Maybe<SupplyNetwork>>>;
  license_number?: Maybe<Scalars['String']>;
  specialism?: Maybe<Scalars['String']>;
};

export type ConsultantAvailability = {
  __typename?: 'ConsultantAvailability';
  consultant_id?: Maybe<Scalars['ID']>;
  consultant?: Maybe<Consultant>;
  available?: Maybe<Scalars['Boolean']>;
  clinic_id?: Maybe<Scalars['Int']>;
};

export type ConsultantRating = {
  __typename?: 'ConsultantRating';
  average_rating?: Maybe<Scalars['Float']>;
  number_of_ratings?: Maybe<Scalars['Int']>;
};

export type ConsultantRtmLastRequest = {
  __typename?: 'ConsultantRTMLastRequest';
  status: ConsultantRtmRequestStatus;
  assignedAppointmentId?: Maybe<Scalars['ID']>;
};

export enum ConsultantRtmRequestStatus {
  New = 'NEW',
  Assigned = 'ASSIGNED',
  Failed = 'FAILED',
  TimedOut = 'TIMED_OUT',
  Cancelled = 'CANCELLED',
  NoShow = 'NO_SHOW',
  Completed = 'COMPLETED'
}

export type ConsultantRtmStatus = {
  __typename?: 'ConsultantRTMStatus';
  canRequestNextPatient: Scalars['Boolean'];
  available: Scalars['Boolean'];
  lastRequest?: Maybe<ConsultantRtmLastRequest>;
};

export type ConsultantSearchFilters = {
  id?: Maybe<Scalars['ID']>;
  full_name?: Maybe<Scalars['String']>;
};

export type Consultation = Paging & TimelineEventInterface & {
  __typename?: 'Consultation';
  id: Scalars['ID'];
  consultationId: Scalars['ID'];
  consultationType: Scalars['String'];
  createdAt: Scalars['String'];
  consultationNote?: Maybe<ConsultationNote>;
  usEhrConsultation?: Maybe<UsEhrConsultation>;
  usEhrNote?: Maybe<UsEhrNote>;
  furtherInfo?: Maybe<Array<Maybe<ConsultationFurtherInfo>>>;
  patientId: Scalars['ID'];
  patient: Patient;
  carePlan?: Maybe<CarePlan>;
  patientV2: PatientResult;
  patientNote?: Maybe<Scalars['String']>;
  consultantId?: Maybe<Scalars['ID']>;
  consultant?: Maybe<Consultant>;
  scheduledTime: Scalars['String'];
  /** @deprecated Field no longer supported */
  consumerNetwork: Scalars['Int'];
  consumerNetworkId: Scalars['Int'];
  consumerNetworkName?: Maybe<Scalars['String']>;
  tenancyContractId?: Maybe<Scalars['ID']>;
  consumerNetworkV2: ConsumerNetworkResult;
  region?: Maybe<Region>;
  files: Array<Maybe<Scalars['String']>>;
  status: Scalars['String'];
  statusV2: ConsultationStatusV2;
  /** @deprecated Field no longer supported */
  images: Array<Scalars['String']>;
  imagesV2: ConsultationImagesResult;
  hasPatientImage?: Maybe<Scalars['Boolean']>;
  consultationFiles?: Maybe<Array<Maybe<ConsultationFile>>>;
  permissions?: Maybe<Array<ConsultationPermission>>;
  drugDosageOptions?: Maybe<Array<Maybe<DrugDosage>>>;
  medium: Scalars['String'];
  coreAppointmentId?: Maybe<Scalars['Int']>;
  appointment?: Maybe<Appointment>;
  appointmentInvites?: Maybe<Array<AppointmentInvite>>;
  cancelledAt?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
  category?: Maybe<Scalars['String']>;
  preConsultationFlowId?: Maybe<Scalars['String']>;
  bookingType: BookingType;
  promoCodeMessageForGp?: Maybe<Scalars['String']>;
  membershipCodeMessageForGp?: Maybe<Scalars['String']>;
  diagnosticTests: Array<ConsultationTest>;
  pathologyTests: Array<ConsultationTest>;
  prescriptions: Array<Prescription>;
  referrals?: Maybe<Array<Maybe<ConsultationReferral>>>;
  sickNotes: Array<ConsultationSickNote>;
  workflows?: Maybe<Array<Workflow>>;
  workflowsV2?: Maybe<Array<WorkflowV2>>;
  workflowDefinitions?: Maybe<Array<WorkflowDefinition>>;
  gpConsent?: Maybe<Scalars['Boolean']>;
  sentToGp?: Maybe<Scalars['Boolean']>;
  physicalAppointmentAdvised?: Maybe<Scalars['Boolean']>;
  testResults?: Maybe<Array<Maybe<ConsultationTestResult>>>;
  recordingConsent?: Maybe<Scalars['String']>;
  languageRequested?: Maybe<Scalars['String']>;
  audioSession?: Maybe<ConsultationAudioSession>;
  videoSession?: Maybe<ConsultationVideoSession>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
  hasInsuranceClaim?: Maybe<Scalars['Boolean']>;
  insuranceClaim?: Maybe<InsuranceClaim>;
  participants: Array<Participant>;
};


export type ConsultationWorkflowDefinitionsArgs = {
  locale?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};

export type ConsultationAudioSession = {
  __typename?: 'ConsultationAudioSession';
  token?: Maybe<Scalars['String']>;
  encryptedVoiceParams?: Maybe<Scalars['String']>;
  to_number?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

export type ConsultationConsultantSearchCriteria = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ConsultationDiagnosis = {
  __typename?: 'ConsultationDiagnosis';
  asessment?: Maybe<Scalars['String']>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};

export type ConsultationDoesNotExistError = {
  __typename?: 'ConsultationDoesNotExistError';
  consultationId: Scalars['ID'];
};

export type ConsultationFile = {
  __typename?: 'ConsultationFile';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Scalars['String']>;
  uploaded_at?: Maybe<Scalars['String']>;
};

export type ConsultationFurtherInfo = {
  __typename?: 'ConsultationFurtherInfo';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ConsultationHistory = {
  __typename?: 'ConsultationHistory';
  consultation: Consultation;
  notes?: Maybe<Array<Maybe<ConsultationNote>>>;
  diagnosis?: Maybe<ConsultationDiagnosis>;
};

export type ConsultationImages = {
  __typename?: 'ConsultationImages';
  items: Array<Scalars['String']>;
};

export type ConsultationImagesResult = ConsultationImages | RetrievalError;

export type ConsultationInfo = {
  __typename?: 'ConsultationInfo';
  date: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type ConsultationInput = {
  gpConsent?: Maybe<Scalars['Boolean']>;
  sentToGp?: Maybe<Scalars['Boolean']>;
  physicalAppointmentAdvised?: Maybe<Scalars['Boolean']>;
};

export type ConsultationNote = {
  __typename?: 'ConsultationNote';
  id: Scalars['ID'];
  toldByPatient?: Maybe<Scalars['String']>;
  toldByPatientCodes?: Maybe<Array<ConsultationNoteCode>>;
  exploredByGp?: Maybe<Scalars['String']>;
  exploredByGpCodes?: Maybe<Array<ConsultationNoteCode>>;
  assessment?: Maybe<Scalars['String']>;
  assessmentCodes?: Maybe<Array<ConsultationNoteCode>>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};

export type ConsultationNoteCode = {
  __typename?: 'ConsultationNoteCode';
  code: Scalars['String'];
  term: Scalars['String'];
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ConsultationNoteCodeInput = {
  code: Scalars['String'];
  term: Scalars['String'];
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ConsultationNoteInput = {
  toldByPatient?: Maybe<Scalars['String']>;
  toldByPatientCodes?: Maybe<Array<Maybe<ConsultationNoteCodeInput>>>;
  exploredByGp?: Maybe<Scalars['String']>;
  exploredByGpCodes?: Maybe<Array<Maybe<ConsultationNoteCodeInput>>>;
  assessment?: Maybe<Scalars['String']>;
  assessmentCodes?: Maybe<Array<Maybe<ConsultationNoteCodeInput>>>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};

export type ConsultationPatientSearchCriteria = {
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gp_address_present?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Gender>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ConsultationPermission = {
  __typename?: 'ConsultationPermission';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type ConsultationReferral = Paging & {
  __typename?: 'ConsultationReferral';
  id: Scalars['ID'];
  consultationId?: Maybe<Scalars['ID']>;
  specialismId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
  referralPdf?: Maybe<Scalars['String']>;
  specialismName?: Maybe<Scalars['String']>;
  specialismCategoryId?: Maybe<Scalars['ID']>;
  externalSpecialist?: Maybe<ReferralExternalSpecialist>;
  /** @deprecated unused field */
  fax?: Maybe<ReferralFax>;
  comments?: Maybe<Array<Maybe<ReferralComment>>>;
  urgent?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patient>;
  consultant?: Maybe<Consultant>;
};

export type ConsultationReferralInput = {
  specialismId?: Maybe<Scalars['ID']>;
  externalSpecialist?: Maybe<ReferralExternalSpecialistInput>;
  fax?: Maybe<ReferralFaxInput>;
  comments?: Maybe<ReferralCommentsInput>;
  urgent?: Maybe<Scalars['Boolean']>;
};

export type ConsultationResults = Pagination & {
  __typename?: 'ConsultationResults';
  pageIndex: Scalars['Int'];
  totalItems: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
  items: Array<Consultation>;
  showBroadWarning?: Maybe<Scalars['String']>;
};

export type ConsultationSearchCriteria = {
  consultationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateRange?: Maybe<DateRange>;
  status?: Maybe<ConsultationStatus>;
  statuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  gpConsent?: Maybe<Scalars['Boolean']>;
  markedAsSent?: Maybe<Scalars['Boolean']>;
  consumerNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  patientSearchCriteria?: Maybe<ConsultationPatientSearchCriteria>;
  consultantSearchCriteria?: Maybe<ConsultationConsultantSearchCriteria>;
};

export type ConsultationSickNote = {
  __typename?: 'ConsultationSickNote';
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type ConsultationSickNoteInput = {
  reason: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type ConsultationSortCriteria = {
  field?: Maybe<ConsultationSortField>;
  order?: Maybe<SortOrder>;
};

export enum ConsultationSortField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Time = 'time',
  ConsumerNetwork = 'consumerNetwork',
  PracticeName = 'practiceName',
  PatientsGpAddressPresent = 'patientsGpAddressPresent'
}

export enum ConsultationStatus {
  Created = 'created',
  Completed = 'completed',
  Complete = 'complete',
  Confirmed = 'confirmed',
  NoShow = 'no_show',
  Cancelled = 'cancelled',
  Paid = 'paid'
}

export enum ConsultationStatusV2 {
  Created = 'created',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Completed = 'completed',
  NoShow = 'no_show'
}

export type ConsultationTest = {
  __typename?: 'ConsultationTest';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  testId: Scalars['String'];
  reason: Scalars['String'];
  created_at?: Maybe<Scalars['String']>;
};

export type ConsultationTestInput = {
  id?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  testId: Scalars['ID'];
  reason: Scalars['String'];
};

export type ConsultationTestResult = {
  __typename?: 'ConsultationTestResult';
  id: Scalars['ID'];
  appointmentPathologyTestId: Scalars['ID'];
  createdAt: Scalars['Date'];
  url: Scalars['String'];
  thumbUrl?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ConsultationTestType {
  Diagnostic = 'diagnostic',
  Pathology = 'pathology'
}

export type ConsultationVideoSession = {
  __typename?: 'ConsultationVideoSession';
  id: Scalars['ID'];
  sessionId: Scalars['String'];
  consultationId: Scalars['ID'];
  provider: Scalars['String'];
  providerApiKey: Scalars['String'];
  providerSessionId: Scalars['String'];
  providerToken: Scalars['String'];
};

export type ConsultationWorkflowsResponse = {
  __typename?: 'ConsultationWorkflowsResponse';
  workflows: WorkflowsResponse;
  workflowDefinitions: Array<WorkflowDefinition>;
};

export type ConsumerNetwork = {
  __typename?: 'ConsumerNetwork';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  business_type?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  enable_prescriptions?: Maybe<Scalars['Boolean']>;
  enable_referrals?: Maybe<Scalars['Boolean']>;
  show_gp_consent?: Maybe<Scalars['Boolean']>;
  enable_voice_calls?: Maybe<Scalars['Boolean']>;
  enable_video_calls?: Maybe<Scalars['Boolean']>;
  enable_nhs?: Maybe<Scalars['Boolean']>;
  faq_url?: Maybe<Scalars['String']>;
  insurer_name?: Maybe<Scalars['String']>;
  insurer_logo_file_name?: Maybe<Scalars['String']>;
  make_gp_consent_mandatory?: Maybe<Scalars['Boolean']>;
  partner?: Maybe<Scalars['String']>;
  prescription_template?: Maybe<Scalars['String']>;
  terms_and_conditions_url?: Maybe<Scalars['String']>;
  consultant_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultation_types?: Maybe<Array<Maybe<AppointmentMedium>>>;
  enable_recording_consent?: Maybe<Scalars['Boolean']>;
  language_requested?: Maybe<Scalars['Boolean']>;
};

export type ConsumerNetworkName = {
  __typename?: 'ConsumerNetworkName';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ConsumerNetworkProfession = {
  __typename?: 'ConsumerNetworkProfession';
  id: Scalars['ID'];
  name: Scalars['String'];
  alternative_slot_durations_minutes: Array<Scalars['Int']>;
  default_slot_duration_minutes: Scalars['Int'];
};

export type ConsumerNetworkResult = ConsumerNetwork | RetrievalError;

export type ContactDetails = {
  __typename?: 'ContactDetails';
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['ID'];
  regionId: Scalars['String'];
  region: TenancyRegion;
  fullRegionPath: Scalars['String'];
  partnerId: Scalars['String'];
  partner: TenancyPartner;
  fullPartnerPath: Scalars['String'];
  createdBy?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  plan: MembershipPlan;
  consumerNetwork?: Maybe<Partner>;
  membersCount: Scalars['Int'];
};

export type ContractInput = {
  id?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  partnerId: Scalars['ID'];
  planId: Scalars['ID'];
};

export type ContractPlan = {
  __typename?: 'ContractPlan';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  partner_id: Scalars['String'];
  regions_ids: Array<Scalars['String']>;
  partner_path: Scalars['String'];
  created_by: Scalars['String'];
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  available_regions: Array<Scalars['String']>;
  full_partner_path: Scalars['String'];
};

export type ContractsByPlan = {
  __typename?: 'ContractsByPlan';
  plan: MembershipPlan;
  items: Array<Contract>;
};

export type ContractsPaginated = {
  __typename?: 'ContractsPaginated';
  items: Array<Contract>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  /** @deprecated use 'total' instead */
  totalItems: Scalars['Int'];
  total: Scalars['Int'];
};

export type ContributionReview = {
  __typename?: 'ContributionReview';
  comment?: Maybe<Scalars['String']>;
  reviewer: Scalars['String'];
  time: Scalars['Int'];
  vote: Scalars['String'];
};

export type CoverageEligibility = {
  __typename?: 'CoverageEligibility';
  id: Scalars['ID'];
  validationKey: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  memberType?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CoverageEligibilityCreateInput = {
  validationKey: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  expiry: Scalars['String'];
  removed: Scalars['Boolean'];
  memberType?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
};

export type CoverageEligibilityError = {
  __typename?: 'CoverageEligibilityError';
  severity: CoverageEligibilityErrorSeverityEnum;
  code: Scalars['String'];
  details?: Maybe<Scalars['String']>;
};

export enum CoverageEligibilityErrorSeverityEnum {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type CoverageEligibilityList = {
  __typename?: 'CoverageEligibilityList';
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<CoverageEligibility>;
};

export type CoverageEligibilityUpdateInput = {
  validationKey?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  memberType?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
};

export type CoverageEligibilityUpdateResult = {
  __typename?: 'CoverageEligibilityUpdateResult';
  items: Array<CoverageEligibility>;
  errors?: Maybe<Array<CoverageEligibilityError>>;
};

export type Cp3ClinicalCodeWeightingsResult = {
  __typename?: 'Cp3ClinicalCodeWeightingsResult';
  id: Scalars['ID'];
  countryCode: KenticoCountryCode;
  rankings: Scalars['String'];
};

export type Cp3LinksAndResourcesResult = {
  __typename?: 'Cp3LinksAndResourcesResult';
  id: Scalars['ID'];
  countryCode: KenticoCountryCode;
  title: Scalars['String'];
  url: Scalars['String'];
  icon: Scalars['String'];
  dashboardLinkTypes: Cp3LinksAndResourcesType;
};

export type Cp3LinksAndResourcesType = {
  __typename?: 'Cp3LinksAndResourcesType';
  name: Scalars['String'];
  codename: Scalars['String'];
};

export type Cp3ReleaseNotesResult = {
  __typename?: 'Cp3ReleaseNotesResult';
  id: Scalars['ID'];
  countryCode: KenticoCountryCode;
  versionNumber: Scalars['String'];
  releaseDate: Scalars['Date'];
  changes: Scalars['String'];
  title: Scalars['String'];
};

export type CreateAppointmentCategoryOutput = {
  __typename?: 'CreateAppointmentCategoryOutput';
  uuid: Scalars['String'];
};

export type CreateAppointmentErrors = {
  __typename?: 'CreateAppointmentErrors';
  date?: Maybe<Scalars['Date']>;
};

export type CreateAppointmentInviteResponse = {
  __typename?: 'CreateAppointmentInviteResponse';
  invite: AppointmentInvite;
  errors?: Maybe<Array<CreateAppointmentErrors>>;
};

export type CreateCarePlanDraftInput = {
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type CreateCarePlanDraftResult = CreateCarePlanDraftSuccess | DraftInFlightAlreadyError | RevisionAlreadyPublishedInConsultationError | ConsultationDoesNotExistError | CarePlanAuthorizationError;

export type CreateCarePlanDraftSuccess = {
  __typename?: 'CreateCarePlanDraftSuccess';
  carePlanDraft: CarePlanDraft;
};

/** Input for creating a new ghost appointment */
export type CreateGhostConsultationInput = {
  /** Uuid of patient to create the ghost appointment for */
  patientId: Scalars['ID'];
  /** ID of consultant to book the appointment for */
  consultantUuid: Scalars['ID'];
  /** Notes to add to consultation */
  consultationNotes: CreateGhostConsultationNotesInput;
  /** Consumer network to temporarily switch the patient to */
  consumerNetwork: Scalars['ID'];
  /** Service type(s) to use for creating the availability */
  allowedServiceTypes: Array<Scalars['String']>;
  /** Reason for the Ghost Consultation */
  appointmentReason: Scalars['String'];
  /** Whether or not the patient has given consent to share appointment details with GP */
  consentToShareWithGP: Scalars['Boolean'];
};

/** Diagnosis code for Ghost Appointment Consultation Notes */
export type CreateGhostConsultationNotesDiagnosisCodeInput = {
  code: Scalars['String'];
  term: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

/** Notes to add to the consultation, these fields can be found on the consultation view in clinical portal */
export type CreateGhostConsultationNotesInput = {
  toldByPatient: Scalars['String'];
  exploredByClinician: Scalars['String'];
  diagnosis: Scalars['String'];
  /**
   * The diagnosis code input fields are checked against the diagnosis itself. If
   * it is invalid, then the ghost consultation workflow will fail.
   * For example, an invalid term-offset duo is provided or non-existent or non-configured code is provided.
   */
  diagnosisCodes?: Maybe<Array<CreateGhostConsultationNotesDiagnosisCodeInput>>;
  adviceToPatient: Scalars['String'];
  safetyNetting: Scalars['String'];
};

/** Input for booking the next available appointment */
export type CreateNextAvailableAppointmentInput = {
  memberId: Scalars['ID'];
  serviceTypeUuid: Scalars['ID'];
  appointmentReason: Scalars['String'];
  consentToShareWithGP: Scalars['Boolean'];
  consumerNetwork: Scalars['ID'];
  consultationNotes?: Maybe<CreateGhostConsultationNotesInput>;
  appointmentImages?: Maybe<Array<Maybe<AppointmentImageInput>>>;
};

export type CreatePartnerMembershipCodeInput = {
  partnerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  validationKey?: Maybe<Scalars['String']>;
  validationProvider?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  messageClinician?: Maybe<Scalars['String']>;
};

export type CreatePlanFeatureStateInput = {
  feature: FeatureInput;
  state: FeatureStateValueInput;
};

export type CreatePlanInput = {
  partner_id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  region_ids: Array<Scalars['String']>;
  feature_states?: Maybe<Array<CreatePlanFeatureStateInput>>;
};

export type CreateProfessionInput = {
  name: Scalars['String'];
  alternative_slot_durations_minutes?: Maybe<Array<Scalars['Int']>>;
  default_slot_duration_minutes?: Maybe<Scalars['Int']>;
  default_appointment_price_cents?: Maybe<Scalars['Int']>;
  medical_identifier_label?: Maybe<Scalars['String']>;
};

export type CreateServiceTypeInput = {
  name: Scalars['String'];
  member_facing_name?: Maybe<Scalars['String']>;
  default_duration_minutes?: Maybe<Scalars['Int']>;
  intimate?: Maybe<Scalars['Boolean']>;
  member_description?: Maybe<Scalars['String']>;
  member_instructions?: Maybe<Scalars['String']>;
  us_ehr_appointment_type?: Maybe<Scalars['String']>;
  grouping?: Maybe<Scalars['String']>;
  common?: Maybe<Scalars['Boolean']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_medium?: Maybe<Scalars['String']>;
  service_type_categories?: Maybe<Array<Maybe<ServiceTypeCategoryInput>>>;
};

export enum CtSearchSystemType {
  Bbl = 'bbl',
  Ecl = 'ecl'
}

export type Currency = {
  __typename?: 'Currency';
  id?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbol_first?: Maybe<Scalars['Boolean']>;
  decimal_mark?: Maybe<Scalars['String']>;
  thousands_separator?: Maybe<Scalars['String']>;
};

export enum CurrencyCode {
  Gbp = 'gbp',
  Usd = 'usd',
  Eur = 'eur',
  Cad = 'cad'
}


export type DateRange = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type DateWithShiftInfo = {
  __typename?: 'DateWithShiftInfo';
  date: Scalars['String'];
  hasIncompleteConsultations: Scalars['Boolean'];
  hasShift: Scalars['Boolean'];
};

export type DecryptedPatientsSearchFilters = {
  __typename?: 'DecryptedPatientsSearchFilters';
  id?: Maybe<Scalars['Int']>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type DeleteActionDraftSuccess = {
  __typename?: 'DeleteActionDraftSuccess';
  parentCarePlanDraft: CarePlanDraft;
};

export type DeleteAppointmentWaitTimeConfigsInput = {
  consumer_network_id: Scalars['Int'];
  service_type_uuid?: Maybe<Scalars['String']>;
};

export type DeleteCarePlanDraftInput = {
  carePlanDraftId: Scalars['ID'];
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type DeleteCarePlanDraftResult = DeleteCarePlanDraftSuccess | ConsultationDoesNotExistError | CarePlanDraftDoesNotExistError | CarePlanAuthorizationError;

export type DeleteCarePlanDraftSuccess = {
  __typename?: 'DeleteCarePlanDraftSuccess';
  /** @deprecated A pointless field used to work around GraphQL's disallowance of empty object types. */
  _: Scalars['Void'];
};

export type DeleteGoalDraftSuccess = {
  __typename?: 'DeleteGoalDraftSuccess';
  parentCarePlanDraft: CarePlanDraft;
};

export type DeletePatientReport = {
  __typename?: 'DeletePatientReport';
  babylonRequestId?: Maybe<Scalars['String']>;
  patient: Patient;
  servicesCalled?: Maybe<Array<Maybe<ServiceCall>>>;
};

export type DemographicInformation = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  date_of_birth?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
};

export type Demographics = {
  __typename?: 'Demographics';
  uuid: Scalars['ID'];
  status?: Maybe<DemographicStatus>;
  created_at: Scalars['String'];
  updated_at: Scalars['String'];
  lookup_retry_attempts_left?: Maybe<Scalars['Int']>;
};

export enum DemographicStatus {
  Accepted = 'accepted',
  Errored = 'errored',
  Failed = 'failed',
  Incomplete = 'incomplete',
  NoResults = 'no_results',
  Pending = 'pending',
  RateLimitReached = 'rate_limit_reached',
  Redacted = 'redacted',
  Rejected = 'rejected',
  Retrieved = 'retrieved',
  UnderReview = 'under_review'
}

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  platform?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  dateLastUsed?: Maybe<Scalars['String']>;
};

export type DiagnosticTest = {
  __typename?: 'DiagnosticTest';
  id?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  diagnostic_test_type_id?: Maybe<Scalars['Int']>;
};

export type DissociateGoalAndActionDraftSuccess = {
  __typename?: 'DissociateGoalAndActionDraftSuccess';
  goalDraft?: Maybe<CarePlanGoalDraft>;
  actionDraft?: Maybe<CarePlanActionDraft>;
};

export type DraftInFlightAlreadyError = {
  __typename?: 'DraftInFlightAlreadyError';
  draftId: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  consultationId: Scalars['ID'];
};

export type DraftMetadata = {
  __typename?: 'DraftMetadata';
  numberOfEdits: Scalars['Int'];
  warnings: Array<DraftMetadataWarning>;
};

export type DraftMetadataWarning = GoalDescriptionEmpty | ActionDescriptionEmpty | GoalWithoutAction | ActionWithoutGoal | CarePlanDraftWithoutGoalsAndActions;

export type Drug = {
  __typename?: 'Drug';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  activeIngredients?: Maybe<Scalars['String']>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  drugPreparation?: Maybe<Scalars['String']>;
  vmpDmdId?: Maybe<Scalars['String']>;
  vtmId?: Maybe<Scalars['String']>;
  expensive?: Maybe<Scalars['Boolean']>;
  controlled?: Maybe<Scalars['Boolean']>;
  blacklisted?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  generic?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  packaging?: Maybe<Array<Maybe<DrugPackaging>>>;
};

export type DrugDirections = {
  __typename?: 'DrugDirections';
  drugId?: Maybe<Scalars['ID']>;
  drugName?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  dosage?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  errorInDirections?: Maybe<Scalars['Boolean']>;
  errorInDosage?: Maybe<Scalars['Boolean']>;
  errorInDuration?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type DrugDosage = {
  __typename?: 'DrugDosage';
  shortcut?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DrugPackaging = {
  __typename?: 'DrugPackaging';
  id: Scalars['ID'];
  packagingId: Scalars['ID'];
  drugId: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  decimalPrice?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  packageDuration?: Maybe<Scalars['String']>;
  legalCategory?: Maybe<Scalars['String']>;
  ampDmdId?: Maybe<Scalars['String']>;
  expensive?: Maybe<Scalars['Boolean']>;
};

export type DrugPackagingInput = {
  id?: Maybe<Scalars['ID']>;
  drugId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  packageSize?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  legalCategory: Scalars['String'];
  ampDmdId?: Maybe<Scalars['String']>;
};

export type EditActionDraftSuccess = {
  __typename?: 'EditActionDraftSuccess';
  actionDraft: CarePlanActionDraft;
};

export type EditGoalDraftSuccess = {
  __typename?: 'EditGoalDraftSuccess';
  goalDraft: CarePlanGoalDraft;
};

export type EhrPatient = {
  __typename?: 'EHRPatient';
  externalEhrId?: Maybe<Scalars['ID']>;
  babylonPatientId?: Maybe<Scalars['ID']>;
  patientExist?: Maybe<Scalars['Boolean']>;
};

export type EmergencyContactDetails = {
  id?: Maybe<Scalars['ID']>;
  phone_country_code?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
};

export type EncryptedSearchCriteria = {
  iv?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  mop_search_using_profiles_service?: Maybe<Scalars['Boolean']>;
  mop_search_with_user_access_token?: Maybe<Scalars['Boolean']>;
};

export type EncryptedSearchFilters = {
  __typename?: 'EncryptedSearchFilters';
  iv: Scalars['String'];
  q: Scalars['String'];
  page?: Maybe<Scalars['String']>;
  per_page?: Maybe<Scalars['String']>;
};

export enum EntryState {
  Active = 'active',
  Deleted = 'deleted'
}

export type Error = {
  message: Scalars['String'];
};

export type ExemptedConsultation = {
  __typename?: 'ExemptedConsultation';
  id: Scalars['ID'];
  state: Scalars['String'];
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
};

export type ExternalOntology = {
  __typename?: 'ExternalOntology';
  source: Scalars['String'];
  code: Scalars['String'];
};

export type FamiliesOwnershipTransferral = {
  __typename?: 'FamiliesOwnershipTransferral';
  new_owner_id: Scalars['ID'];
  old_owner_id: Scalars['ID'];
};

export type FamilyAccount = {
  __typename?: 'FamilyAccount';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  members?: Maybe<Array<Maybe<FamilyAccountMembership>>>;
};

export type FamilyAccountMembership = {
  __typename?: 'FamilyAccountMembership';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  minor?: Maybe<Scalars['Boolean']>;
  avatar_full_url?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invitation_sent?: Maybe<Scalars['Boolean']>;
  invitation_accepted?: Maybe<Scalars['Boolean']>;
  deletable?: Maybe<Scalars['Boolean']>;
  removable?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  can_add_subscription?: Maybe<Scalars['Boolean']>;
  can_cancel_subscription?: Maybe<Scalars['Boolean']>;
};

export type FamilyMember = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  date_of_birth: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gender: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  redemptions_attributes?: Maybe<RedemptionsAttributes>;
};

/** A feature, uniquely idetified by name, is used by other services to identify which code paths to follow */
export type Feature = {
  __typename?: 'Feature';
  /** The unique id of the feature */
  id: Scalars['ID'];
  /** The unique name of the feature */
  name: Scalars['String'];
  /** The human readable name of the feature */
  displayName: Scalars['String'];
  /** The human readable description of what the feature is used for */
  description: Scalars['String'];
  /** The list of tags used for grouping features, e.g., plan-default */
  tags?: Maybe<Array<Scalars['String']>>;
};

/** A feature, uniquely idetified by name, is used by other services to identify which code paths to follow */
export type FeatureInput = {
  name: Scalars['String'];
};

/** A scope used to identify a cohort of users or devices to which a feature could be applied */
export type FeatureScope = {
  __typename?: 'FeatureScope';
  /** The type of scope, e.g., plan */
  type: FeatureScopeType;
  /** The value of the feature scope */
  value: Scalars['String'];
};

/** A scope used to identify a cohort of users or devices to which a feature could be applied */
export type FeatureScopeInput = {
  /** The type of scope, e.g., plan */
  type: FeatureScopeType;
  /** The value of the feature scope */
  value: Scalars['String'];
};

/** The accepted values for the types of scopes that can be associated to features */
export enum FeatureScopeType {
  Plan = 'plan',
  Region = 'region'
}

/** A feature and its state for a particular scope */
export type FeatureState = {
  __typename?: 'FeatureState';
  /** The feature used by other services to identify which code paths to follow */
  feature: Feature;
  /** The state of the feature for a particular scope */
  state: FeatureStateValue;
  /** The scope used to identify a cohort of users or devices to which a feature could be applied */
  scope: FeatureScope;
};

/** A feature and its state for a particular scope */
export type FeatureStateInput = {
  feature: FeatureInput;
  scope: FeatureScopeInput;
  state: FeatureStateValueInput;
};

/** The state of a feature for a particular scope */
export type FeatureStateValue = {
  __typename?: 'FeatureStateValue';
  /** The type of value of a feature for a particular scope, e.g., boolean */
  type: FeatureStateValueType;
  /** The value of a feature for a particular scope */
  value: Scalars['Boolean'];
};

/** The state of a feature for a particular scope */
export type FeatureStateValueInput = {
  /** The type of value of a feature for a particular scope, e.g., boolean */
  type: FeatureStateValueType;
  /** The value of a feature for a particular scope */
  value: Scalars['Boolean'];
};

/** The accepted values for the types of states that can be associated to features */
export enum FeatureStateValueType {
  Boolean = 'boolean'
}

export type FeatureSwitch = {
  __typename?: 'FeatureSwitch';
  enable_adding_adults?: Maybe<Scalars['Boolean']>;
  enable_adding_children?: Maybe<Scalars['Boolean']>;
  enable_appointments?: Maybe<Scalars['Boolean']>;
  enable_changes_to_medical_history?: Maybe<Scalars['Boolean']>;
  enable_clinical_coding?: Maybe<Scalars['Boolean']>;
  enable_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_consultant_booking_on_behalf_of_patient?: Maybe<Scalars['Boolean']>;
  enable_dynamic_mailer?: Maybe<Scalars['Boolean']>;
  enable_family_accounts?: Maybe<Scalars['Boolean']>;
  enable_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  enable_home_prescription_delivery?: Maybe<Scalars['Boolean']>;
  enable_kits?: Maybe<Scalars['Boolean']>;
  enable_membership_type?: Maybe<Scalars['Boolean']>;
  enable_monitor_me?: Maybe<Scalars['Boolean']>;
  enable_nhs?: Maybe<Scalars['Boolean']>;
  enable_patient_ccg_update?: Maybe<Scalars['Boolean']>;
  enable_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_prescriptions?: Maybe<Scalars['Boolean']>;
  enable_print_prescriptions?: Maybe<Scalars['Boolean']>;
  enable_public_healthcare_registration?: Maybe<Scalars['Boolean']>;
  enable_referral_code?: Maybe<Scalars['Boolean']>;
  enable_referrals?: Maybe<Scalars['Boolean']>;
  enable_registration_queue?: Maybe<Scalars['Boolean']>;
  enable_restricted_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_prescription_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_your_details?: Maybe<Scalars['Boolean']>;
  enable_see_pharmacies?: Maybe<Scalars['Boolean']>;
  enable_update_gp_details?: Maybe<Scalars['Boolean']>;
  enable_update_patient_details?: Maybe<Scalars['Boolean']>;
  enable_video_calls?: Maybe<Scalars['Boolean']>;
  enable_voice_calls?: Maybe<Scalars['Boolean']>;
  free_subscription?: Maybe<Scalars['Boolean']>;
  id_verification_required?: Maybe<Scalars['Boolean']>;
  id_verification_required_for_appointments?: Maybe<Scalars['Boolean']>;
  inhibit_warnings?: Maybe<Scalars['Boolean']>;
  make_gp_consent_mandatory?: Maybe<Scalars['Boolean']>;
  nhs_gp_consumer_network?: Maybe<Scalars['Boolean']>;
  nida?: Maybe<Scalars['Boolean']>;
  refer_a_friend?: Maybe<Scalars['Boolean']>;
  show_ask?: Maybe<Scalars['Boolean']>;
  show_billing_history?: Maybe<Scalars['Boolean']>;
  show_billing_overview?: Maybe<Scalars['Boolean']>;
  show_check?: Maybe<Scalars['Boolean']>;
  show_family_accounts?: Maybe<Scalars['Boolean']>;
  show_gp_consent?: Maybe<Scalars['Boolean']>;
  show_gp_details?: Maybe<Scalars['Boolean']>;
  show_insurance?: Maybe<Scalars['Boolean']>;
  show_kit?: Maybe<Scalars['Boolean']>;
  show_medical_history?: Maybe<Scalars['Boolean']>;
  show_membership_codes?: Maybe<Scalars['Boolean']>;
  show_monitor?: Maybe<Scalars['Boolean']>;
  show_payment_details?: Maybe<Scalars['Boolean']>;
  show_pharmacy?: Maybe<Scalars['Boolean']>;
  show_questions?: Maybe<Scalars['Boolean']>;
  show_test_and_kits_not_covered_warning?: Maybe<Scalars['Boolean']>;
  show_upgrade_subscription_button?: Maybe<Scalars['Boolean']>;
  enable_continuity_of_care_for_referral?: Maybe<Scalars['Boolean']>;
};

export type FhirCodeableConcept = {
  __typename?: 'FhirCodeableConcept';
  coding?: Maybe<Array<FhirCoding>>;
};

export type FhirCoding = {
  __typename?: 'FhirCoding';
  system?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type FhirContextUsage = {
  __typename?: 'FhirContextUsage';
  count: Scalars['Int'];
  url: Scalars['String'];
};

export type FhirElementBinding = {
  __typename?: 'FhirElementBinding';
  strength: Scalars['String'];
  valueSet?: Maybe<Scalars['String']>;
};

export type FhirElementType = {
  __typename?: 'FhirElementType';
  code?: Maybe<Scalars['String']>;
  profile?: Maybe<Array<Scalars['String']>>;
  targetProfile?: Maybe<Array<Scalars['String']>>;
};

export type FhirProfile = {
  __typename?: 'FhirProfile';
  id: Scalars['ID'];
  meta: FhirProfileMeta;
  url: Scalars['String'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  fhirVersion: Scalars['String'];
  kind: Scalars['String'];
  abstract: Scalars['Boolean'];
  type: Scalars['String'];
  baseDefinition?: Maybe<Scalars['String']>;
  derivation?: Maybe<Scalars['String']>;
  snapshot: FhirProfileSnapshot;
  differential?: Maybe<FhirProfileSnapshot>;
};

export type FhirProfileMeta = {
  __typename?: 'FhirProfileMeta';
  versionId: Scalars['Int'];
  lastUpdated: Scalars['String'];
};

export type FhirProfileSnapshot = {
  __typename?: 'FhirProfileSnapshot';
  element: Array<FhirSnapshotElement>;
};

export type FhirSnapshotElement = {
  __typename?: 'FhirSnapshotElement';
  id: Scalars['String'];
  sliceName?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['String']>;
  short?: Maybe<Scalars['String']>;
  contentReference?: Maybe<Scalars['String']>;
  type?: Maybe<Array<FhirElementType>>;
  fixedCode?: Maybe<Scalars['String']>;
  fixedUri?: Maybe<Scalars['String']>;
  fixedString?: Maybe<Scalars['String']>;
  fixedCoding?: Maybe<FhirCoding>;
  fixedCodeableConcept?: Maybe<FhirCodeableConcept>;
  patternCoding?: Maybe<FhirCoding>;
  patternCodeableConcept?: Maybe<FhirCodeableConcept>;
  binding?: Maybe<FhirElementBinding>;
};

export type FhirValueSet = {
  __typename?: 'FhirValueSet';
  id: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  inclusions: Array<FhirValueSetInclusion>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rawJSON: Scalars['String'];
};

export type FhirValueSetConcept = {
  __typename?: 'FhirValueSetConcept';
  code: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
};

export type FhirValueSetConceptInclusion = FhirValueSetInclusion & {
  __typename?: 'FhirValueSetConceptInclusion';
  system: Scalars['String'];
  concepts: Array<FhirValueSetConcept>;
};

export type FhirValueSetExternalValueSetInclusion = FhirValueSetInclusion & {
  __typename?: 'FhirValueSetExternalValueSetInclusion';
  system: Scalars['String'];
  valueSets: Array<Scalars['String']>;
};

export type FhirValueSetFilter = {
  __typename?: 'FhirValueSetFilter';
  code: Scalars['String'];
  property: Scalars['String'];
  op: Scalars['String'];
};

export type FhirValueSetFilterInclusion = FhirValueSetInclusion & {
  __typename?: 'FhirValueSetFilterInclusion';
  system: Scalars['String'];
  filters: Array<FhirValueSetFilter>;
};

export type FhirValueSetInclusion = {
  system: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  value: Scalars['String'];
};

export type FileUpload = {
  __typename?: 'FileUpload';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Flag = {
  __typename?: 'Flag';
  id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  appointment?: Maybe<Appointment>;
};

export enum Gender {
  Male = 'male',
  Female = 'female'
}

export type GoalActionDraftAssociation = {
  __typename?: 'GoalActionDraftAssociation';
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
  createdAt: Scalars['String'];
};

export type GoalActionDraftAssociationAlreadyExistError = {
  __typename?: 'GoalActionDraftAssociationAlreadyExistError';
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type GoalActionDraftAssociationDoesNotExistError = {
  __typename?: 'GoalActionDraftAssociationDoesNotExistError';
  goalDraftId: Scalars['ID'];
  actionDraftId: Scalars['ID'];
};

export type GoalDescriptionEmpty = {
  __typename?: 'GoalDescriptionEmpty';
  goalDraftId: Scalars['ID'];
};

export type GoalDraftDoesNotExistError = {
  __typename?: 'GoalDraftDoesNotExistError';
  goalDraftId: Scalars['ID'];
};

export type GoalWithoutAction = {
  __typename?: 'GoalWithoutAction';
  goalDraftId: Scalars['ID'];
};

export type GpAtHandActionResult = {
  __typename?: 'GPAtHandActionResult';
  message?: Maybe<Scalars['String']>;
};

export type GpDetails = {
  __typename?: 'GPDetails';
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_address_state_code?: Maybe<Scalars['String']>;
  gp_details_id?: Maybe<Scalars['ID']>;
  gp_full_address?: Maybe<Scalars['String']>;
  gp_name?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
};

export type GpDetailsInput = {
  gp_name?: Maybe<Scalars['String']>;
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
};

export type GpInformation = {
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_name?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  updating_gp_details?: Maybe<Scalars['Boolean']>;
};

export type GpSurgeryFax = {
  __typename?: 'GPSurgeryFax';
  id: Scalars['ID'];
  name: Scalars['String'];
  faxNumber: Scalars['String'];
};

export type HealthcareIdentifier = {
  __typename?: 'HealthcareIdentifier';
  public_identifier?: Maybe<Scalars['String']>;
  private_identifier?: Maybe<Scalars['String']>;
  unconfirmed_public_identifier?: Maybe<Scalars['String']>;
};

export type HealthcareIdentifierInput = {
  public_identifier?: Maybe<Scalars['String']>;
  private_identifier?: Maybe<Scalars['String']>;
  unconfirmed_public_identifier?: Maybe<Scalars['String']>;
};

export type HealthCheckAboutSection = {
  __typename?: 'HealthCheckAboutSection';
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export enum HealthCheckActionType {
  Maintain = 'maintain',
  Change = 'change'
}

export type HealthCheckAdviceItem = {
  __typename?: 'HealthCheckAdviceItem';
  content: Scalars['String'];
  header: Scalars['String'];
  suggested_asset?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  action_type?: Maybe<HealthCheckActionType>;
};

export type HealthCheckAssessmentCallToAction = {
  __typename?: 'HealthCheckAssessmentCallToAction';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentContent = {
  __typename?: 'HealthCheckAssessmentContent';
  body?: Maybe<Scalars['String']>;
  breakdown?: Maybe<HealthCheckAssessmentContentBreakdown>;
  header: Scalars['String'];
  image?: Maybe<HealthCheckAssessmentContentImage>;
};

export type HealthCheckAssessmentContentBreakdown = {
  __typename?: 'HealthCheckAssessmentContentBreakdown';
  body?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentContentImage = {
  __typename?: 'HealthCheckAssessmentContentImage';
  accessibilityLabel?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlow = {
  __typename?: 'HealthCheckAssessmentFlow';
  durationMinutes?: Maybe<Scalars['Int']>;
  durationMinutesText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status: HealthCheckAssessmentFlowStatus;
  scores: Array<Maybe<HealthCheckAssessmentScores>>;
};

export type HealthCheckAssessmentFlowConversation = {
  __typename?: 'HealthCheckAssessmentFlowConversation';
  completedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  startedAt?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowDataPoint = {
  __typename?: 'HealthCheckAssessmentFlowDataPoint';
  dateAdded?: Maybe<Scalars['String']>;
  dateCaptured?: Maybe<Scalars['String']>;
  displayValue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowDataPointCompleteness = {
  __typename?: 'HealthCheckAssessmentFlowDataPointCompleteness';
  associatedDataPointDefinitions: Array<HealthCheckAssessmentFlowDataPointDefinition>;
  completedDataPoints: Array<HealthCheckAssessmentFlowDataPoint>;
  percentageComplete?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowDataPointDefinition = {
  __typename?: 'HealthCheckAssessmentFlowDataPointDefinition';
  description?: Maybe<Scalars['String']>;
  displayKey?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentFlowStatus = {
  __typename?: 'HealthCheckAssessmentFlowStatus';
  conversationCompletedAt?: Maybe<Scalars['String']>;
  conversationState?: Maybe<Scalars['String']>;
  conversationStateText?: Maybe<Scalars['String']>;
  conversations: Array<HealthCheckAssessmentFlowConversation>;
  dataPointCollectionState?: Maybe<Scalars['String']>;
  dataPointCollectionStateText?: Maybe<Scalars['String']>;
  dataPointCompleteness?: Maybe<HealthCheckAssessmentFlowDataPointCompleteness>;
};

export type HealthCheckAssessmentScoreResult = {
  __typename?: 'HealthCheckAssessmentScoreResult';
  score: Scalars['String'];
  severityText: Scalars['String'];
  ragStatus?: Maybe<Scalars['String']>;
};

export type HealthCheckAssessmentScores = {
  __typename?: 'HealthCheckAssessmentScores';
  id: Scalars['ID'];
  name: Scalars['String'];
  result?: Maybe<HealthCheckAssessmentScoreResult>;
};

export type HealthCheckAssessmentStage = {
  __typename?: 'HealthCheckAssessmentStage';
  scores: Array<Maybe<HealthCheckAssessmentScores>>;
  callToActions: Array<HealthCheckAssessmentCallToAction>;
  content: HealthCheckAssessmentContent;
  nextFlows: Array<HealthCheckAssessmentFlow>;
};

export type HealthCheckCategory = {
  __typename?: 'HealthCheckCategory';
  key: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  actions?: Maybe<HealthCheckCategoryAction>;
  about: HealthCheckAboutSection;
  overview: HealthCheckCategoryOverview;
  medical_history: HealthCheckMedicalHistory;
  health_metrics?: Maybe<Array<HealthCheckMetrics>>;
};

export type HealthCheckCategoryAction = {
  __typename?: 'HealthCheckCategoryAction';
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  items?: Maybe<Array<HealthCheckAdviceItem>>;
};

export type HealthCheckCategoryIndicator = {
  __typename?: 'HealthCheckCategoryIndicator';
  key: Scalars['String'];
  title: Scalars['String'];
  suggested_asset: Scalars['String'];
  suggested_colour: Scalars['String'];
};

export type HealthCheckCategoryOverview = {
  __typename?: 'HealthCheckCategoryOverview';
  value?: Maybe<Scalars['Float']>;
  value_label?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  data_available?: Maybe<Scalars['Boolean']>;
  indicators?: Maybe<Array<Maybe<HealthCheckCategoryIndicator>>>;
  suggested_asset?: Maybe<Scalars['String']>;
  suggested_colour?: Maybe<Scalars['String']>;
};

export type HealthCheckDetail = {
  __typename?: 'HealthCheckDetail';
  about?: Maybe<HealthCheckAboutSection>;
  interval_picker?: Maybe<HealthCheckIntervalPicker>;
  overview?: Maybe<HealthCheckDetailOverview>;
  risk?: Maybe<HealthCheckRisk>;
  risk_influence?: Maybe<HealthCheckRiskInfluences>;
  actions?: Maybe<HealthCheckDetailActions>;
};

export type HealthCheckDetailActions = {
  __typename?: 'HealthCheckDetailActions';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  thresholds?: Maybe<Array<Maybe<HealthCheckThreshold>>>;
  items?: Maybe<Array<Maybe<HealthCheckAdviceItem>>>;
};

export type HealthCheckDetailOverview = {
  __typename?: 'HealthCheckDetailOverview';
  body?: Maybe<Scalars['String']>;
};

export enum HealthCheckDisplayType {
  Continuous = 'continuous',
  Simple = 'simple',
  Segmented = 'segmented',
  Status = 'status',
  Time = 'time',
  TrafficLight = 'traffic_light'
}

export type HealthCheckInfluence = {
  __typename?: 'HealthCheckInfluence';
  asset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HealthCheckInterval = {
  __typename?: 'HealthCheckInterval';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type HealthCheckIntervalPicker = {
  __typename?: 'HealthCheckIntervalPicker';
  selected_id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckInterval>>>;
};

export type HealthCheckMedicalHistory = {
  __typename?: 'HealthCheckMedicalHistory';
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckMedicalHistoryItem>>>;
};

export type HealthCheckMedicalHistoryItem = {
  __typename?: 'HealthCheckMedicalHistoryItem';
  content?: Maybe<Scalars['String']>;
  suggested_asset?: Maybe<Scalars['String']>;
};

export type HealthCheckMetric = {
  __typename?: 'HealthCheckMetric';
  key: Scalars['String'];
  data_available?: Maybe<Scalars['Boolean']>;
  detail?: Maybe<HealthCheckDetail>;
  display_type?: Maybe<HealthCheckDisplayType>;
  title?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_label?: Maybe<Scalars['String']>;
  thresholds?: Maybe<Array<HealthCheckThreshold>>;
};

export type HealthCheckMetrics = {
  __typename?: 'HealthCheckMetrics';
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckMetric>>>;
};

export type HealthCheckMetricSummary = {
  __typename?: 'HealthCheckMetricSummary';
  total: Scalars['Int'];
  totalWithData: Scalars['Int'];
  metrics?: Maybe<Array<Maybe<HealthCheckSeverityValuePair>>>;
};

export type HealthCheckRisk = {
  __typename?: 'HealthCheckRisk';
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type HealthCheckRiskInfluences = {
  __typename?: 'HealthCheckRiskInfluences';
  title?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<HealthCheckInfluence>>>;
};

export type HealthCheckSeverityMetric = {
  __typename?: 'HealthCheckSeverityMetric';
  colour?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
  primary: Scalars['Boolean'];
  severity?: Maybe<Scalars['Int']>;
  icon: Scalars['String'];
  id: Scalars['String'];
};

export type HealthCheckSeverityValuePair = {
  __typename?: 'HealthCheckSeverityValuePair';
  severity?: Maybe<Scalars['Int']>;
  value: Scalars['Int'];
  icon: Scalars['String'];
};

export type HealthCheckSummary = {
  __typename?: 'HealthCheckSummary';
  severities: Array<HealthCheckSeverityMetric>;
  diseaseRisks?: Maybe<HealthCheckMetricSummary>;
  organHealth?: Maybe<HealthCheckMetricSummary>;
};

export type HealthCheckThreshold = {
  __typename?: 'HealthCheckThreshold';
  min: Scalars['Float'];
  max?: Maybe<Scalars['Float']>;
  severity: Scalars['Float'];
  suggested_colour: Scalars['String'];
  title: Scalars['String'];
};

export type HumanitySync = {
  __typename?: 'HumanitySync';
  humanity_sync_id: Scalars['ID'];
};

export type HumanitySyncAllowed = {
  __typename?: 'HumanitySyncAllowed';
  sync_allowed: Scalars['Boolean'];
};

export type HumanitySyncShift = {
  __typename?: 'HumanitySyncShift';
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_id: Scalars['ID'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  consultant_id: Scalars['ID'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  consultant_name?: Maybe<Scalars['String']>;
  /** @deprecated replaced by HumanitySyncShiftItem */
  status: HumanitySyncShiftStatusEnum;
  /** @deprecated replaced by HumanitySyncShiftItem */
  errors: Array<Maybe<Scalars['String']>>;
  /** @deprecated replaced by HumanitySyncShiftItem */
  warnings: Array<Maybe<Scalars['String']>>;
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_pattern: Scalars['String'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_start: Scalars['Date'];
  /** @deprecated replaced by HumanitySyncShiftItem */
  shift_end: Scalars['Date'];
};

export type HumanitySyncShiftItem = {
  __typename?: 'HumanitySyncShiftItem';
  shift_id: Scalars['ID'];
  consultant_id?: Maybe<Scalars['ID']>;
  consultant_name?: Maybe<Scalars['String']>;
  status?: Maybe<HumanitySyncShiftStatusEnum>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>;
  shift_pattern?: Maybe<Scalars['String']>;
  shift_start?: Maybe<Scalars['Date']>;
  shift_end?: Maybe<Scalars['Date']>;
};

export type HumanitySyncShiftItemFilters = {
  consultantId?: Maybe<Scalars['ID']>;
  consultantName?: Maybe<Scalars['String']>;
  shiftStartDate?: Maybe<Scalars['String']>;
  shiftEndDate?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  warnings?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  warning?: Maybe<Scalars['String']>;
};

export type HumanitySyncShiftItems = {
  __typename?: 'HumanitySyncShiftItems';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  shifts: Array<HumanitySyncShiftItem>;
};

export type HumanitySyncShifts = {
  __typename?: 'HumanitySyncShifts';
  /** @deprecated replaced by HumanitySyncShiftItems */
  total: Scalars['Int'];
  /** @deprecated replaced by HumanitySyncShiftItems */
  limit: Scalars['Int'];
  /** @deprecated replaced by HumanitySyncShiftItems */
  offset: Scalars['Int'];
  /** @deprecated replaced by HumanitySyncShiftItems */
  shifts: Array<Maybe<HumanitySyncShift>>;
};

export enum HumanitySyncShiftStatusEnum {
  Cancelled = 'cancelled',
  Ignored = 'ignored',
  Imported = 'imported',
  InvalidData = 'invalid_data',
  Pending = 'pending'
}

export type HumanitySyncStatus = {
  __typename?: 'HumanitySyncStatus';
  status: HumanitySyncStatusEnum;
  orphan_count?: Maybe<Scalars['Int']>;
};

export enum HumanitySyncStatusEnum {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Unchanged = 'unchanged'
}

export type IdentityCheck = {
  __typename?: 'IdentityCheck';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  check_id?: Maybe<Scalars['String']>;
  verification_overwritten_by?: Maybe<PortalUser>;
  verification_overwritten_at?: Maybe<Scalars['String']>;
};

export type IdValidationResult = {
  __typename?: 'IdValidationResult';
  isValid: Scalars['Boolean'];
  message: Scalars['String'];
};

export type IdVerification = {
  __typename?: 'idVerification';
  external_reference: Scalars['String'];
  external_sdk_token: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  patient_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type InsuranceClaim = {
  __typename?: 'InsuranceClaim';
  id: Scalars['ID'];
  state: Scalars['String'];
  updatedAt: Scalars['String'];
  createdAt: Scalars['String'];
};

export type InsuranceCompany = {
  __typename?: 'InsuranceCompany';
  id: Scalars['Int'];
  created_at: Scalars['String'];
  name: Scalars['String'];
  take_payment: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['String']>;
  memberships?: Maybe<Array<Maybe<InsuranceMembership>>>;
};

export type InsuranceInformation = {
  date_of_birth?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address_post_code?: Maybe<Scalars['String']>;
  insurance_membership_number?: Maybe<Scalars['String']>;
  insurance_company_id?: Maybe<Scalars['Int']>;
};

export type InsuranceMembership = {
  __typename?: 'InsuranceMembership';
  id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  insurance_company_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type InvalidCarePlanDraftNumbersOfEditsError = {
  __typename?: 'InvalidCarePlanDraftNumbersOfEditsError';
  draftId: Scalars['ID'];
  currentNumberOfEdits: Scalars['Int'];
  providedNumberOfEdits: Scalars['Int'];
};


export type KenticoCountryCode = {
  __typename?: 'KenticoCountryCode';
  name: Scalars['String'];
  codename: Scalars['String'];
};

export type KongToken = {
  __typename?: 'KongToken';
  access_token: Scalars['String'];
  expires_in?: Maybe<Scalars['Int']>;
  refresh_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export type Leaflet = {
  __typename?: 'Leaflet';
  content?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ListingDetails = {
  __typename?: 'ListingDetails';
  id: Scalars['ID'];
  duration_minutes?: Maybe<Scalars['Int']>;
  clinic_id?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['ID']>;
  consultant_id?: Maybe<Scalars['ID']>;
  appointment_time?: Maybe<Scalars['Date']>;
  state?: Maybe<Scalars['String']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
  consultation_type?: Maybe<Scalars['String']>;
  appointment_reason?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  image_attached?: Maybe<Scalars['Boolean']>;
  public_healthcare_identifier?: Maybe<Scalars['ID']>;
  appointment_invite_id?: Maybe<Scalars['ID']>;
};

export type MedicalHistory = {
  __typename?: 'MedicalHistory';
  medical_history?: Maybe<Scalars['String']>;
};

export type Medication = {
  __typename?: 'Medication';
  id: Scalars['ID'];
  info?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type Medium = {
  __typename?: 'Medium';
  medium?: Maybe<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
};

export type MediumInput = {
  medium?: Maybe<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
};

export type MemberDecisionForNoticeInput = {
  patientUUID: Scalars['ID'];
  noticeEnum: NoticeEnum;
  appName: AppName;
};

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['Int'];
  code: Scalars['String'];
  description: Scalars['String'];
  consumer_network?: Maybe<ConsumerNetwork>;
};

export type MembershipContract = {
  __typename?: 'MembershipContract';
  partner_id: Scalars['ID'];
  id: Scalars['ID'];
  state?: Maybe<EntryState>;
  region_id: Scalars['String'];
  region_path: Scalars['String'];
  partner_path: Scalars['String'];
  member_count: Scalars['Int'];
  created_by: Scalars['String'];
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  full_partner_path: Scalars['String'];
  full_region_path: Scalars['String'];
  plan?: Maybe<ContractPlan>;
};

export type MembershipPlan = {
  __typename?: 'MembershipPlan';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  partnerId: Scalars['String'];
  partner?: Maybe<TenancyPartner>;
  /** @deprecated Field no longer supported */
  availableRegions: Array<Scalars['String']>;
  regions: Array<TenancyRegion>;
  fullPartnerPath: Scalars['String'];
  createdAt: Scalars['Date'];
  featureStates: Array<FeatureState>;
};

export type MembershipPlansPaginated = {
  __typename?: 'MembershipPlansPaginated';
  items: Array<MembershipPlan>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
};

export type MemberTask = {
  __typename?: 'MemberTask';
  id: Scalars['ID'];
  thread: MemberThread;
  createdAt: Scalars['String'];
  logEntry?: Maybe<Scalars['String']>;
  state: MemberWorkflowState;
};

export type MemberTaskFilter = {
  uuid: Scalars['ID'];
};

export type MemberTasksFilter = {
  threadUuid: Scalars['ID'];
};

export type MemberThread = {
  __typename?: 'MemberThread';
  id: Scalars['ID'];
  currentTask: MemberTask;
  tasks: Array<MemberTask>;
  memberId: Scalars['ID'];
  state: MemberWorkflowState;
  createdAt: Scalars['String'];
  type: MemberThreadType;
  subType: MemberThreadSubType;
};

export type MemberThreadFilter = {
  uuid: Scalars['ID'];
};

export type MemberThreadsFilter = {
  memberId: Array<Scalars['ID']>;
  type?: Maybe<Array<MemberThreadType>>;
};

export enum MemberThreadSubType {
  BloodTest = 'BLOOD_TEST'
}

export enum MemberThreadType {
  Test = 'TEST',
  Referral = 'REFERRAL',
  Prescription = 'PRESCRIPTION'
}

export enum MemberWorkflowState {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

export type Message = {
  __typename?: 'Message';
  message?: Maybe<Scalars['String']>;
};

export type MonthlyCalendarSchedule = {
  __typename?: 'MonthlyCalendarSchedule';
  incompleteConsultations: Array<Scalars['String']>;
  shifts: Array<Scalars['String']>;
};

export type MopPatient = Subject & {
  __typename?: 'MopPatient';
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  consumer_networks?: Maybe<Array<Maybe<PatientConsumerNetwork>>>;
  consumer_network_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_accounts_count?: Maybe<Scalars['Int']>;
  family_accounts_member_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  family_accounts_owner?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  healthcare_identifier?: Maybe<HealthcareIdentifier>;
  identity_check?: Maybe<IdentityCheck>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  minor?: Maybe<Scalars['Boolean']>;
  owner_id?: Maybe<Scalars['ID']>;
  patient_membership_link?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  total_appointments_count?: Maybe<Scalars['Int']>;
  verification_status?: Maybe<Scalars['String']>;
  invitation_was_sent_but_not_accepted?: Maybe<Scalars['Boolean']>;
  public_healthcare_admission?: Maybe<PublicHealthcareAdmission>;
  queued_for_activation?: Maybe<Scalars['Boolean']>;
};

export type MopPatientInput = {
  id?: Maybe<Scalars['ID']>;
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  birth_details?: Maybe<BirthDetailInput>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergency_contact_details?: Maybe<Array<Maybe<EmergencyContactDetails>>>;
  first_name?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  gp_details?: Maybe<GpDetailsInput>;
  healthcare_identifier?: Maybe<HealthcareIdentifierInput>;
  last_name?: Maybe<Scalars['String']>;
  previous_names?: Maybe<Scalars['String']>;
  previous_practice_name?: Maybe<Scalars['String']>;
  public_healthcare_details?: Maybe<PublicHealthcareDetailsInput>;
  region?: Maybe<RegionInput>;
  uuid?: Maybe<Scalars['ID']>;
};

export type MopPatientSearchCriteria = {
  id?: Maybe<Scalars['Int']>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  mop_search_using_profiles_service?: Maybe<Scalars['Boolean']>;
  mop_search_with_user_access_token?: Maybe<Scalars['Boolean']>;
};

export type MopSearchPatients = {
  __typename?: 'MopSearchPatients';
  encryptedSearchFilters?: Maybe<EncryptedSearchFilters>;
  decryptedPatientsSearchFilters?: Maybe<DecryptedPatientsSearchFilters>;
  pagination?: Maybe<SearchPagination>;
  patients: Array<Maybe<MopPatient>>;
};

export type Multimedia = {
  __typename?: 'Multimedia';
  call_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  video_session_id?: Maybe<Scalars['Int']>;
};

export enum MultimediaFeedbackClientType {
  Clinician = 'clinician',
  Member = 'member'
}

export type MultimediaFeedbackInput = {
  consultation_id: Scalars['ID'];
  question_id: Scalars['ID'];
  client_type: Scalars['String'];
  client_id: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
  answer_ids?: Maybe<Array<Scalars['ID']>>;
  comments?: Maybe<Array<Scalars['String']>>;
};

export type MultimediaFeedbackQuestion = {
  __typename?: 'MultimediaFeedbackQuestion';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  question: Scalars['String'];
  question_type: Scalars['String'];
  position: Scalars['Int'];
  total: Scalars['Int'];
  suggested_answers?: Maybe<Array<SuggestedAnswer>>;
};

export enum MultimediaFeedbackQuestionType {
  StarRating = 'star_rating',
  FreeText = 'free_text',
  SingleSelect = 'single_select',
  MultiSelect = 'multi_select',
  MultiSelectWithFreeText = 'multi_select_with_free_text'
}

export type MultimediaFeedbackResult = {
  __typename?: 'MultimediaFeedbackResult';
  id: Scalars['ID'];
  next_question?: Maybe<MultimediaFeedbackQuestion>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActionDraft: AddActionDraftResult;
  addAdminDrug?: Maybe<AdminDrug>;
  addAdminDrugAlert?: Maybe<AdminDrugAlert>;
  addAlert?: Maybe<Message>;
  addAvailabilitySlots?: Maybe<Scalars['Void']>;
  addCarer: Scalars['String'];
  addCliniciansToServiceType?: Maybe<Scalars['Boolean']>;
  addClinicsToServiceType?: Maybe<Scalars['Boolean']>;
  addComment?: Maybe<Message>;
  addCommentToPrescription?: Maybe<Prescription>;
  addConcept: NewContributionResponse;
  addConsumerNetworksToServiceType?: Maybe<Scalars['Boolean']>;
  addDrugToPrescription?: Maybe<Prescription>;
  addFamilyMember?: Maybe<Patient>;
  addGoalDraft: CarePlanAddGoalDraftResult;
  addPatientMetric?: Maybe<PatientMetric>;
  addPatientToConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  addRelationships: NewContributionResponse;
  addToAllergies: Array<Maybe<Allergy>>;
  addToConditions: Array<Maybe<Condition>>;
  addToMedications: Array<Maybe<Medication>>;
  adminActiveIngredient?: Maybe<Array<AdminActiveIngredient>>;
  applyCode: Code;
  applyRedemptionV1: Scalars['Int'];
  applyRefund: PortalTransaction;
  assignAppointmentCategoryToAppointmentConfiguration?: Maybe<Scalars['Void']>;
  assignContractToAppointmentConfiguration?: Maybe<Tenancies>;
  assignServiceTypesToAppointmentConfiguration?: Maybe<Scalars['Void']>;
  associateGoalAndActionDraft: AssociateGoalAndActionDraftResult;
  blacklistDrug?: Maybe<AdminDrug>;
  bookAppointment?: Maybe<BookingRequestResult>;
  bookAppointmentWithMicroservice?: Maybe<Appointment>;
  cancelAppointment: CancelledAppointment;
  cancelAppointmentWithMicroservice?: Maybe<Appointment>;
  cancelGPAtHand?: Maybe<GpAtHandActionResult>;
  cancelHumanitySync?: Maybe<HumanitySyncStatus>;
  cancelPatientSubscription?: Maybe<Subscription>;
  changePassword?: Maybe<Message>;
  checkInEhr?: Maybe<Scalars['Boolean']>;
  clinicalPresence?: Maybe<Scalars['Void']>;
  cloneAppointmentConfiguration?: Maybe<CloneAppointmentConfiguration>;
  completeConsultation?: Maybe<Consultation>;
  coverageEligibilityCreate: CoverageEligibility;
  coverageEligibilityUpdate: CoverageEligibilityUpdateResult;
  createAddress?: Maybe<Address>;
  createAppointmentCategory?: Maybe<CreateAppointmentCategoryOutput>;
  createAppointmentCredit: AppointmentCredit;
  /** @deprecated Deprecated in favour of createAppointmentInviteV2 */
  createAppointmentInvite: AppointmentInvite;
  /** @deprecated Deprecated in favour of createAppointmentInviteV3 */
  createAppointmentInviteV2?: Maybe<Array<AppointmentInvite>>;
  createAppointmentInviteV3: CreateAppointmentInviteResponse;
  createAppointmentMediumConfiguration?: Maybe<Configurations>;
  createCarePlanDraft: CreateCarePlanDraftResult;
  createConsultationAudioSession: ConsultationVideoSession;
  createConsultationFurtherInfo: ConsultationFurtherInfo;
  createConsultationReferral?: Maybe<ConsultationReferral>;
  createConsultationSickNote?: Maybe<ConsultationSickNote>;
  createConsultationTest: ConsultationTest;
  createConsumerNetworkAndContract?: Maybe<Contract>;
  /**
   * Creates a Ghost Appointment & Consultation. Patients do NOT attend these
   * appointments. Clinicians use it to record some patent-related activities.
   */
  createGhostConsultation?: Maybe<Appointment>;
  /** Books the next available appointment (within 2 days) for a patient & updates consultation with notes (if exists) */
  createNextAvailableAppointment?: Maybe<Appointment>;
  createPartnerMembershipCode?: Maybe<PartnerMembershipCode>;
  createPatientAlert?: Maybe<PatientAlert>;
  createPlan?: Maybe<MembershipPlan>;
  createPortalUser?: Maybe<PortalUser>;
  createPrescription?: Maybe<Prescription>;
  createProfession?: Maybe<Profession>;
  createServiceType?: Maybe<ServiceType>;
  createTenancyPartner?: Maybe<TenancyPartner>;
  createWorkflow: Array<Maybe<Workflow>>;
  deleteActionDraft: CarePlanDeleteActionDraftResult;
  deleteAdminDrugAlert: Scalars['Boolean'];
  deleteAppointmentCategory?: Maybe<Scalars['Void']>;
  deleteAppointmentWaitTimeConfigs?: Maybe<Scalars['Void']>;
  deleteCarePlanDraft: DeleteCarePlanDraftResult;
  deleteConsumerNetworkAndContract?: Maybe<Scalars['Boolean']>;
  deleteGoalDraft: CarePlanDeleteGoalDraftResult;
  deletePartner?: Maybe<Scalars['Boolean']>;
  deletePartnerBranding?: Maybe<PartnerBranding>;
  deletePartnerMembershipCode?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  deletePatient?: Maybe<Message>;
  deletePlan?: Maybe<Scalars['Boolean']>;
  deleteServiceType?: Maybe<Scalars['Boolean']>;
  deleteThePatient?: Maybe<DeletePatientReport>;
  deregisterGPAtHand?: Maybe<GpAtHandActionResult>;
  dissociateGoalAndActionDraft: CarePlanDissociateGoalAndActionDraftResult;
  duplicatePrescription: Prescription;
  editActionDraft: CarePlanEditActionDraftResult;
  editFamilyMember?: Maybe<Patient>;
  editGoalDraft: CarePlanEditGoalDraftResult;
  endAudioCall?: Maybe<Scalars['Boolean']>;
  endVideoCall?: Maybe<Scalars['Boolean']>;
  gpSurgerySendFax: Scalars['Boolean'];
  healthCheckInvalidateAnswers?: Maybe<Scalars['Boolean']>;
  healthCheckSubmitAssessment?: Maybe<Scalars['Boolean']>;
  linkPatientToTransaction: Scalars['Boolean'];
  lookupDemographics?: Maybe<Demographics>;
  markEHRNotesSensitive?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field no longer supported */
  markPrescriptionPrinted?: Maybe<Scalars['ID']>;
  markRepeatPrescriptionAsStopped?: Maybe<Scalars['Void']>;
  overrideIdentityCheck?: Maybe<IdentityCheck>;
  payAppointment: Appointment;
  postPatientDemographic?: Maybe<Demographics>;
  postcodeLookup?: Maybe<Address>;
  prescriptionChangeState?: Maybe<Prescription>;
  prescriptionMarkDrugsDispatched: Prescription;
  prescriptionPdfFile?: Maybe<File>;
  prescriptionRejectPharmacySupplier: Prescription;
  prescriptionRetryFax?: Maybe<Scalars['ID']>;
  prescriptionRetryPdf?: Maybe<Scalars['ID']>;
  privacyPolicyDecision?: Maybe<PrivacyNotice>;
  promoteCarePlanDraft: PromoteCarePlanDraftResult;
  rateAppointment: Appointment;
  rateConsultation: Review;
  reactivateRedemption: Scalars['Boolean'];
  reallocateShift?: Maybe<ReallocatedAppointments>;
  regenerateClinicianPin: Scalars['Boolean'];
  registerNhsMinor: Patient;
  registerNhsPatient: Patient;
  rejoinConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  removeAppointmentCredit: Scalars['Boolean'];
  removeCarer: Scalars['String'];
  removeClinicFromServiceType?: Maybe<Scalars['Boolean']>;
  removeClinicianFromServiceType?: Maybe<Scalars['Boolean']>;
  removeConsultationFurtherInfo?: Maybe<Scalars['ID']>;
  removeConsultationRecordings?: Maybe<Consultation>;
  removeConsultationReferral?: Maybe<Scalars['ID']>;
  removeConsultationSickNote?: Maybe<Scalars['ID']>;
  removeConsultationTest?: Maybe<Scalars['ID']>;
  removeConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  removeConsumerNetworkFromServiceType?: Maybe<Scalars['Boolean']>;
  removeDrugFromPrescription?: Maybe<Prescription>;
  removeFromAllergies: Array<Maybe<Allergy>>;
  removeFromConditions: Array<Maybe<Condition>>;
  removeFromMedications: Array<Maybe<Medication>>;
  removePatientAlert?: Maybe<Scalars['ID']>;
  removePrescription?: Maybe<Scalars['ID']>;
  removeRedemption: Scalars['Boolean'];
  removeReferral?: Maybe<Scalars['ID']>;
  reportCallStep?: Maybe<Scalars['ID']>;
  requestPrescriptionDelivery?: Maybe<PrescriptionDeliveryConfirmation>;
  requestSignerToken?: Maybe<TokenValidity>;
  requestSyncShiftsReport: ShiftsCsvDownload;
  resendInvitation?: Maybe<PatientResentInvitation>;
  resetPassword?: Maybe<Message>;
  resetPasswordById?: Maybe<Message>;
  reviewContribution: ReviewContributionResponse;
  revokeRecordingConsent?: Maybe<Consultation>;
  saveAppointmentWaitTimeConfigs?: Maybe<Scalars['Void']>;
  saveServiceType?: Maybe<ServiceType>;
  selectConsumerNetwork?: Maybe<PatientConsumerNetwork>;
  sendAudioStream?: Maybe<Scalars['String']>;
  sendPatientReferral?: Maybe<Scalars['ID']>;
  sendPrescriptionToHome: Prescription;
  sendPrescriptionToPharmacy: Prescription;
  sendPrescriptionToPharmacyByReference: Prescription;
  sendReferralEmail?: Maybe<Scalars['ID']>;
  sendSlackMessage?: Maybe<SlackMessage>;
  setConsultantRTMAvailability: Scalars['String'];
  setConsultationExempted: ExemptedConsultation;
  setInsuranceClaimExempted: InsuranceClaim;
  signPrescription: Prescription;
  startAudioCall?: Maybe<Scalars['Boolean']>;
  startIdVerification?: Maybe<Patient>;
  startVideoCall?: Maybe<Scalars['Boolean']>;
  submitMultimediaFeedback: MultimediaFeedbackResult;
  suggestAllergies: Array<Maybe<ClinicalKnowledgeLabel>>;
  suggestConditions: Array<Maybe<ClinicalKnowledgeLabel>>;
  suggestDrugDirections?: Maybe<DrugDirections>;
  suggestLabels: Array<Maybe<ClinicalKnowledgeLabel>>;
  suggestMedications: Array<Maybe<ClinicalKnowledgeLabel>>;
  syncHumanity?: Maybe<HumanitySync>;
  transferOwnership?: Maybe<FamiliesOwnershipTransferral>;
  transferPatientToNhs: Patient;
  triggerWorkflow: WorkflowV2;
  unAssignAppointmentCategoryFromAppointmentConfiguration?: Maybe<Scalars['Void']>;
  unAssignServiceTypesToAppointmentConfiguration?: Maybe<Scalars['Void']>;
  unAssignTenancyFromAppointmentConfiguration?: Maybe<Scalars['Void']>;
  updateAdminDrug?: Maybe<AdminDrug>;
  updateAdminDrugAlert?: Maybe<AdminDrugAlert>;
  updateAppointment: Appointment;
  updateAppointmentGpConsent: AppointmentV2;
  updateAppointmentMediumConfiguration?: Maybe<Configurations>;
  updateAppointmentWaitTimeConfigs?: Maybe<Scalars['Void']>;
  updateConsultation?: Maybe<Consultation>;
  updateConsultationDiagnosis?: Maybe<ConsultationDiagnosis>;
  /** @deprecated Field no longer supported */
  updateConsultationGpConsent?: Maybe<Consultation>;
  updateConsultationNote?: Maybe<ConsultationNote>;
  updateConsultationReferral: ConsultationReferral;
  updateConsultationSickNote?: Maybe<ConsultationSickNote>;
  updateConsultationStatus?: Maybe<Consultation>;
  updateConsultationTest: ConsultationTest;
  updateConsultations: Array<Maybe<Consultation>>;
  updateDrugInPrescription?: Maybe<Prescription>;
  /** Create and/or update feature states given a list of feature names, scopes and states */
  updateFeatureStates: Array<FeatureState>;
  updateFlag?: Maybe<Flag>;
  updateGP?: Maybe<Patient>;
  updateGpInAppointment?: Maybe<Patient>;
  updateInsuranceCompany?: Maybe<Patient>;
  updateMarkedAsSent?: Maybe<Scalars['Boolean']>;
  updateMedicalHistory?: Maybe<MedicalHistory>;
  updateMopPatient: Patient;
  updatePanel?: Maybe<TestReport>;
  updatePartnerBranding?: Maybe<PartnerBranding>;
  updatePartnerMembershipCode?: Maybe<PartnerMembershipCode>;
  updatePatient: Patient;
  updatePatientAlert?: Maybe<PatientAlert>;
  updatePatientDetails: Patient;
  updatePatientName?: Maybe<Patient>;
  updatePortalUser?: Maybe<PortalUser>;
  updatePrimaryGuardian: Scalars['String'];
  updateReferral: ConsultationReferral;
  updateReportState?: Maybe<TestReport>;
  updateTenancyPartner?: Maybe<TenancyPartner>;
  updateTenancyPartnerParent?: Maybe<TenancyPartner>;
  uploadConsultationFile?: Maybe<Scalars['Boolean']>;
  uploadMembers?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  validateDrug?: Maybe<PrescriptionValidation>;
  validatePatientDetails?: Maybe<Scalars['ID']>;
  validateToken?: Maybe<TokenValidity>;
  /** @deprecated Field no longer supported */
  voidPrescription?: Maybe<Scalars['ID']>;
};


export type MutationAddActionDraftArgs = {
  input: CarePlanAddActionDraftInput;
};


export type MutationAddAdminDrugArgs = {
  adminDrug: AdminDrugInput;
};


export type MutationAddAdminDrugAlertArgs = {
  adminDrugAlert: AdminDrugAlertInput;
};


export type MutationAddAlertArgs = {
  patientId: Scalars['ID'];
  description: Scalars['String'];
};


export type MutationAddAvailabilitySlotsArgs = {
  input?: Maybe<AvailabilitySlotsInput>;
};


export type MutationAddCarerArgs = {
  carerId: Scalars['String'];
  dependantId: Scalars['String'];
};


export type MutationAddCliniciansToServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinician_ids: Array<Scalars['ID']>;
};


export type MutationAddClinicsToServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinic_ids: Array<Scalars['ID']>;
};


export type MutationAddCommentArgs = {
  patient_id: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationAddCommentToPrescriptionArgs = {
  prescriptionId: Scalars['ID'];
  comment: Scalars['String'];
};


export type MutationAddConceptArgs = {
  isContribution: Scalars['Boolean'];
  parentConcept: Scalars['String'];
  preferredLabel: Scalars['String'];
  ticket: Scalars['String'];
  definition?: Maybe<Scalars['String']>;
  relationships?: Maybe<Array<Maybe<RelationshipIris>>>;
};


export type MutationAddConsumerNetworksToServiceTypeArgs = {
  service_type_uuid?: Maybe<Scalars['ID']>;
  consumer_network_ids: Array<Scalars['ID']>;
};


export type MutationAddDrugToPrescriptionArgs = {
  id: Scalars['ID'];
  drug: PrescriptionDrugInput;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationAddFamilyMemberArgs = {
  input: FamilyMember;
  id: Scalars['ID'];
};


export type MutationAddGoalDraftArgs = {
  input: CarePlanAddGoalDraftInput;
};


export type MutationAddPatientMetricArgs = {
  patientUuid: Scalars['ID'];
  patientId: Scalars['ID'];
  clinicianId?: Maybe<Scalars['ID']>;
  clinicianUuid?: Maybe<Scalars['ID']>;
  consultationId: Scalars['ID'];
  clinicianRole?: Maybe<Scalars['String']>;
  observationType: PatientMetricObservationType;
  input: Scalars['JSON'];
  filter: PatientMetricSource;
  consultationType?: Maybe<Scalars['String']>;
  consultationUuid?: Maybe<Scalars['String']>;
};


export type MutationAddPatientToConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationAddRelationshipsArgs = {
  relationships?: Maybe<Array<Maybe<RelationshipIris>>>;
  originalConceptIri: Scalars['String'];
  ticket: Scalars['String'];
  isContribution: Scalars['Boolean'];
};


export type MutationAddToAllergiesArgs = {
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
};


export type MutationAddToConditionsArgs = {
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
};


export type MutationAddToMedicationsArgs = {
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
};


export type MutationAdminActiveIngredientArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type MutationApplyCodeArgs = {
  code: Scalars['String'];
  patient_id: Scalars['Int'];
  redemption_type: Scalars['String'];
};


export type MutationApplyRedemptionV1Args = {
  code: Scalars['String'];
  patientId: Scalars['Int'];
  redemptionType: RedemptionType;
};


export type MutationApplyRefundArgs = {
  input: ApplyRefundInput;
};


export type MutationAssignAppointmentCategoryToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  categoryIds: Array<Scalars['String']>;
};


export type MutationAssignContractToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  tenantId: Scalars['String'];
  tenantType: Scalars['String'];
};


export type MutationAssignServiceTypesToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  serviceTypeAssignments: Array<Maybe<ServiceTypeAssignment>>;
};


export type MutationAssociateGoalAndActionDraftArgs = {
  input: CarePlanAssociateGoalAndActionDraftInput;
};


export type MutationBlacklistDrugArgs = {
  id: Scalars['ID'];
  region: Scalars['String'];
  value: Scalars['Boolean'];
  reason: Scalars['String'];
};


export type MutationBookAppointmentArgs = {
  input: AppointmentRequestInput;
};


export type MutationBookAppointmentWithMicroserviceArgs = {
  input?: Maybe<AppointmentRequestInputForMicroservice>;
};


export type MutationCancelAppointmentArgs = {
  id: Scalars['Int'];
  cancel_reason_id?: Maybe<Scalars['Int']>;
  cancel_reason_comment?: Maybe<Scalars['String']>;
};


export type MutationCancelAppointmentWithMicroserviceArgs = {
  appointmentId: Scalars['ID'];
  cancelReasonId?: Maybe<Scalars['Int']>;
};


export type MutationCancelGpAtHandArgs = {
  patientId: Scalars['ID'];
};


export type MutationCancelHumanitySyncArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationCancelPatientSubscriptionArgs = {
  patientId: Scalars['ID'];
  subscriptionId: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckInEhrArgs = {
  consultationId: Scalars['ID'];
};


export type MutationCloneAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCompleteConsultationArgs = {
  id: Scalars['ID'];
};


export type MutationCoverageEligibilityCreateArgs = {
  partnerId: Scalars['String'];
  input: CoverageEligibilityCreateInput;
};


export type MutationCoverageEligibilityUpdateArgs = {
  input: CoverageEligibilityUpdateInput;
  partnerId: Scalars['String'];
  members: Array<Scalars['String']>;
};


export type MutationCreateAddressArgs = {
  address: AddressInput;
  patient_id: Scalars['ID'];
};


export type MutationCreateAppointmentCategoryArgs = {
  categoryDetails: AppointmentCategoryDetailsInput;
};


export type MutationCreateAppointmentCreditArgs = {
  patientId: Scalars['String'];
  consumerNetworkId: Scalars['String'];
  reasonId: Scalars['String'];
  professions?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateAppointmentInviteArgs = {
  input: AppointmentInviteInput;
};


export type MutationCreateAppointmentInviteV2Args = {
  input: AppointmentInviteInput;
};


export type MutationCreateAppointmentInviteV3Args = {
  input: AppointmentInviteInput;
};


export type MutationCreateAppointmentMediumConfigurationArgs = {
  configurationId: Scalars['String'];
  mediums?: Maybe<Array<Maybe<MediumInput>>>;
};


export type MutationCreateCarePlanDraftArgs = {
  input: CreateCarePlanDraftInput;
};


export type MutationCreateConsultationAudioSessionArgs = {
  consultationId: Scalars['ID'];
  consultantId: Scalars['ID'];
  patientId: Scalars['ID'];
};


export type MutationCreateConsultationFurtherInfoArgs = {
  consultationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};


export type MutationCreateConsultationReferralArgs = {
  consultationId: Scalars['ID'];
  input: ConsultationReferralInput;
};


export type MutationCreateConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
  input: ConsultationSickNoteInput;
};


export type MutationCreateConsultationTestArgs = {
  consultationId: Scalars['ID'];
  input: ConsultationTestInput;
};


export type MutationCreateConsumerNetworkAndContractArgs = {
  contract: ContractInput;
};


export type MutationCreateGhostConsultationArgs = {
  input: CreateGhostConsultationInput;
};


export type MutationCreateNextAvailableAppointmentArgs = {
  input: CreateNextAvailableAppointmentInput;
};


export type MutationCreatePartnerMembershipCodeArgs = {
  membershipCode: CreatePartnerMembershipCodeInput;
};


export type MutationCreatePatientAlertArgs = {
  patientId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationCreatePlanArgs = {
  plan: CreatePlanInput;
};


export type MutationCreatePortalUserArgs = {
  portalUser: PortalUserInput;
};


export type MutationCreatePrescriptionArgs = {
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
  drugs?: Maybe<Array<Maybe<PrescriptionDrugInput>>>;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationCreateProfessionArgs = {
  input?: Maybe<CreateProfessionInput>;
};


export type MutationCreateServiceTypeArgs = {
  input?: Maybe<CreateServiceTypeInput>;
};


export type MutationCreateTenancyPartnerArgs = {
  partner: TenancyPartnerInput;
};


export type MutationCreateWorkflowArgs = {
  appointment?: Maybe<WorkflowAppointmentActionInput>;
  letter?: Maybe<WorkflowLetterActionInput>;
  investigation?: Maybe<WorkflowInvestigationActionInput>;
  referral?: Maybe<WorkflowReferralActionInput>;
  other?: Maybe<WorkflowOtherActionInput>;
  data: WorkflowInput;
};


export type MutationDeleteActionDraftArgs = {
  input: CarePlanDeleteActionDraftInput;
};


export type MutationDeleteAdminDrugAlertArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAppointmentCategoryArgs = {
  categoryId: Scalars['String'];
};


export type MutationDeleteAppointmentWaitTimeConfigsArgs = {
  input?: Maybe<DeleteAppointmentWaitTimeConfigsInput>;
};


export type MutationDeleteCarePlanDraftArgs = {
  input: DeleteCarePlanDraftInput;
};


export type MutationDeleteConsumerNetworkAndContractArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGoalDraftArgs = {
  input: CarePlanDeleteGoalDraftInput;
};


export type MutationDeletePartnerArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationDeletePartnerBrandingArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationDeletePartnerMembershipCodeArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePatientArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePlanArgs = {
  planId: Scalars['ID'];
};


export type MutationDeleteServiceTypeArgs = {
  uuid: Scalars['ID'];
};


export type MutationDeleteThePatientArgs = {
  id: Scalars['Int'];
};


export type MutationDeregisterGpAtHandArgs = {
  patientId: Scalars['ID'];
};


export type MutationDissociateGoalAndActionDraftArgs = {
  input: CarePlanDissociateGoalAndActionDraftInput;
};


export type MutationDuplicatePrescriptionArgs = {
  prescriptionId: Scalars['ID'];
};


export type MutationEditActionDraftArgs = {
  input: CarePlanEditActionDraftInput;
};


export type MutationEditFamilyMemberArgs = {
  id: Scalars['Int'];
  input: FamilyMember;
};


export type MutationEditGoalDraftArgs = {
  input: CarePlanEditGoalDraftInput;
};


export type MutationEndAudioCallArgs = {
  consultationId: Scalars['ID'];
};


export type MutationEndVideoCallArgs = {
  consultationId: Scalars['ID'];
  sessionId: Scalars['ID'];
};


export type MutationGpSurgerySendFaxArgs = {
  id: Scalars['ID'];
  faxNumberId: Scalars['String'];
};


export type MutationHealthCheckInvalidateAnswersArgs = {
  locale: Scalars['String'];
  memberId: Scalars['ID'];
  timeZoneOffset: Scalars['String'];
};


export type MutationHealthCheckSubmitAssessmentArgs = {
  locale: Scalars['String'];
  memberId: Scalars['ID'];
  reason: Scalars['String'];
  riskLevel: Scalars['String'];
  termsAndConditionsAccepted?: Maybe<Scalars['Boolean']>;
  termsAndConditionsText?: Maybe<Scalars['String']>;
  timeZoneOffset: Scalars['String'];
};


export type MutationLinkPatientToTransactionArgs = {
  patientUuid: Scalars['ID'];
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
};


export type MutationLookupDemographicsArgs = {
  patientId: Scalars['ID'];
};


export type MutationMarkEhrNotesSensitiveArgs = {
  appointmentId: Scalars['ID'];
};


export type MutationMarkPrescriptionPrintedArgs = {
  id: Scalars['ID'];
};


export type MutationMarkRepeatPrescriptionAsStoppedArgs = {
  templateId: Scalars['ID'];
};


export type MutationOverrideIdentityCheckArgs = {
  patientId: Scalars['ID'];
  identityCheckId?: Maybe<Scalars['ID']>;
};


export type MutationPayAppointmentArgs = {
  id: Scalars['Int'];
  credit_card_id: Scalars['Int'];
  patient_id?: Maybe<Scalars['Int']>;
};


export type MutationPostPatientDemographicArgs = {
  id: Scalars['Int'];
  demographic?: Maybe<DemographicInformation>;
};


export type MutationPostcodeLookupArgs = {
  postcode: Scalars['String'];
};


export type MutationPrescriptionChangeStateArgs = {
  id: Scalars['ID'];
  action: PrescriptionStateActionType;
  reason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
};


export type MutationPrescriptionMarkDrugsDispatchedArgs = {
  id: Scalars['ID'];
};


export type MutationPrescriptionPdfFileArgs = {
  id: Scalars['ID'];
};


export type MutationPrescriptionRejectPharmacySupplierArgs = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationPrescriptionRetryFaxArgs = {
  id: Scalars['ID'];
};


export type MutationPrescriptionRetryPdfArgs = {
  id: Scalars['ID'];
};


export type MutationPrivacyPolicyDecisionArgs = {
  decision: Scalars['Boolean'];
  noticeVersionId: Scalars['String'];
};


export type MutationPromoteCarePlanDraftArgs = {
  input: PromoteCarePlanDraftInput;
};


export type MutationRateAppointmentArgs = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationRateConsultationArgs = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationReactivateRedemptionArgs = {
  patientId: Scalars['ID'];
  redemptionId: Scalars['ID'];
};


export type MutationReallocateShiftArgs = {
  filters?: Maybe<ReallocateShiftFilters>;
};


export type MutationRegenerateClinicianPinArgs = {
  clinicianId: Scalars['ID'];
};


export type MutationRegisterNhsMinorArgs = {
  patient?: Maybe<NhsPatient>;
};


export type MutationRegisterNhsPatientArgs = {
  patient?: Maybe<NhsPatient>;
};


export type MutationRejoinConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationRemoveAppointmentCreditArgs = {
  patientId: Scalars['ID'];
  appointmentCreditId: Scalars['ID'];
};


export type MutationRemoveCarerArgs = {
  relationshipId: Scalars['String'];
};


export type MutationRemoveClinicFromServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinic_id: Scalars['ID'];
};


export type MutationRemoveClinicianFromServiceTypeArgs = {
  service_type_uuid: Scalars['ID'];
  clinician_id: Scalars['ID'];
};


export type MutationRemoveConsultationFurtherInfoArgs = {
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationRemoveConsultationRecordingsArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveConsultationReferralArgs = {
  consultationId: Scalars['ID'];
  referralId: Scalars['ID'];
};


export type MutationRemoveConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationRemoveConsultationTestArgs = {
  consultationId: Scalars['ID'];
  type: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationRemoveConsumerNetworkFromServiceTypeArgs = {
  service_type_uuid?: Maybe<Scalars['ID']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
};


export type MutationRemoveDrugFromPrescriptionArgs = {
  id?: Maybe<Scalars['ID']>;
  prescriptionDrugId?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationRemoveFromAllergiesArgs = {
  id: Scalars['Int'];
  patient_id: Scalars['Int'];
};


export type MutationRemoveFromConditionsArgs = {
  id: Scalars['Int'];
  patient_id: Scalars['Int'];
};


export type MutationRemoveFromMedicationsArgs = {
  id: Scalars['Int'];
  patient_id: Scalars['Int'];
};


export type MutationRemovePatientAlertArgs = {
  patientId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationRemovePrescriptionArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveRedemptionArgs = {
  patientId: Scalars['ID'];
  redemptionId: Scalars['ID'];
};


export type MutationRemoveReferralArgs = {
  referralId: Scalars['ID'];
};


export type MutationReportCallStepArgs = {
  consultationId: Scalars['String'];
  step: CallStep;
  appName: Scalars['String'];
  appVersion: Scalars['String'];
};


export type MutationRequestPrescriptionDeliveryArgs = {
  id: Scalars['ID'];
  addressId: Scalars['ID'];
  supplierId: Scalars['ID'];
};


export type MutationRequestSignerTokenArgs = {
  input: SignerTokenInput;
};


export type MutationRequestSyncShiftsReportArgs = {
  input: RequestSyncShiftsReportInput;
};


export type MutationResendInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordByIdArgs = {
  id: Scalars['ID'];
};


export type MutationReviewContributionArgs = {
  vote: Scalars['String'];
  comment: Scalars['String'];
  contributionId: Scalars['String'];
};


export type MutationRevokeRecordingConsentArgs = {
  id: Scalars['ID'];
};


export type MutationSaveAppointmentWaitTimeConfigsArgs = {
  input?: Maybe<AppointmentWaitTimeConfigsInput>;
};


export type MutationSaveServiceTypeArgs = {
  input?: Maybe<SaveServiceTypeInput>;
};


export type MutationSelectConsumerNetworkArgs = {
  input: PatientConsumerNetworkPayload;
};


export type MutationSendAudioStreamArgs = {
  audioStream: Scalars['String'];
  consultationId: Scalars['String'];
};


export type MutationSendPatientReferralArgs = {
  input: SendPatientReferralInput;
};


export type MutationSendPrescriptionToHomeArgs = {
  id: Scalars['ID'];
};


export type MutationSendPrescriptionToPharmacyArgs = {
  id: Scalars['ID'];
  pharmacy_id: Scalars['ID'];
};


export type MutationSendPrescriptionToPharmacyByReferenceArgs = {
  prescriptionId: Scalars['ID'];
  pharmacyReference: Scalars['String'];
};


export type MutationSendReferralEmailArgs = {
  patientId: Scalars['ID'];
  referralId: Scalars['ID'];
};


export type MutationSendSlackMessageArgs = {
  body: SlackMessageInput;
};


export type MutationSetConsultantRtmAvailabilityArgs = {
  consultantId: Scalars['ID'];
  available: Scalars['Boolean'];
};


export type MutationSetConsultationExemptedArgs = {
  id: Scalars['ID'];
};


export type MutationSetInsuranceClaimExemptedArgs = {
  id: Scalars['ID'];
};


export type MutationSignPrescriptionArgs = {
  id: Scalars['ID'];
  input: PrescriptionSignatureRequestInput;
};


export type MutationStartAudioCallArgs = {
  consultationId: Scalars['ID'];
};


export type MutationStartIdVerificationArgs = {
  external_reference: Scalars['String'];
};


export type MutationStartVideoCallArgs = {
  consultationId: Scalars['ID'];
  sessionId: Scalars['ID'];
  activeParticipants?: Maybe<Array<VideoSessionUserTypes>>;
};


export type MutationSubmitMultimediaFeedbackArgs = {
  input: MultimediaFeedbackInput;
};


export type MutationSuggestAllergiesArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSuggestConditionsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSuggestDrugDirectionsArgs = {
  drugId?: Maybe<Scalars['ID']>;
  drugName: Scalars['String'];
  dosage: Scalars['String'];
  duration: Scalars['String'];
  region: Scalars['String'];
};


export type MutationSuggestLabelsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSuggestMedicationsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationSyncHumanityArgs = {
  filters?: Maybe<SyncHumanityFilters>;
};


export type MutationTransferOwnershipArgs = {
  new_owner_id: Scalars['ID'];
  old_owner_id: Scalars['ID'];
};


export type MutationTransferPatientToNhsArgs = {
  id: Scalars['Int'];
  patient?: Maybe<NhsPatient>;
};


export type MutationTriggerWorkflowArgs = {
  idempotency_key: Scalars['String'];
  workflow_definition_key: Scalars['String'];
  tenant_id: Scalars['ID'];
  variables: Array<WorkflowVariableInput>;
};


export type MutationUnAssignAppointmentCategoryFromAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  categoryId: Scalars['String'];
};


export type MutationUnAssignServiceTypesToAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  serviceIds: Array<Maybe<Scalars['String']>>;
};


export type MutationUnAssignTenancyFromAppointmentConfigurationArgs = {
  configurationId: Scalars['String'];
  assignmentIds: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateAdminDrugArgs = {
  adminDrug: AdminDrugInput;
};


export type MutationUpdateAdminDrugAlertArgs = {
  adminDrugAlert: AdminDrugAlertInput;
};


export type MutationUpdateAppointmentArgs = {
  id: Scalars['Int'];
  gp_consent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAppointmentGpConsentArgs = {
  id: Scalars['ID'];
  gp_consent: Scalars['Boolean'];
};


export type MutationUpdateAppointmentMediumConfigurationArgs = {
  configurationId: Scalars['String'];
  mediums?: Maybe<Array<Maybe<MediumInput>>>;
};


export type MutationUpdateAppointmentWaitTimeConfigsArgs = {
  input?: Maybe<AppointmentWaitTimeConfigsInput>;
};


export type MutationUpdateConsultationArgs = {
  id: Scalars['ID'];
  input?: Maybe<ConsultationInput>;
};


export type MutationUpdateConsultationDiagnosisArgs = {
  consultationId: Scalars['String'];
  asessment?: Maybe<Scalars['String']>;
  treatmentPlan?: Maybe<Scalars['String']>;
  fallbackPlan?: Maybe<Scalars['String']>;
};


export type MutationUpdateConsultationGpConsentArgs = {
  id: Scalars['ID'];
  gpConsent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateConsultationNoteArgs = {
  consultationId: Scalars['ID'];
  input?: Maybe<ConsultationNoteInput>;
};


export type MutationUpdateConsultationReferralArgs = {
  consultationId: Scalars['ID'];
  referralId: Scalars['ID'];
  input: ConsultationReferralInput;
};


export type MutationUpdateConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
  sickNoteId: Scalars['ID'];
  input: ConsultationSickNoteInput;
};


export type MutationUpdateConsultationStatusArgs = {
  id: Scalars['ID'];
  status?: Maybe<ConsultationStatus>;
  exempted?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateConsultationTestArgs = {
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
  type: Scalars['String'];
  input: ConsultationTestInput;
};


export type MutationUpdateConsultationsArgs = {
  ids: Array<Scalars['ID']>;
  changes: Array<ConsultationInput>;
};


export type MutationUpdateDrugInPrescriptionArgs = {
  id?: Maybe<Scalars['ID']>;
  prescriptionDrugId?: Maybe<Scalars['ID']>;
  drug: PrescriptionDrugInput;
  region?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
};


export type MutationUpdateFeatureStatesArgs = {
  featureStates: Array<FeatureStateInput>;
};


export type MutationUpdateFlagArgs = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateGpArgs = {
  id: Scalars['Int'];
  formData: GpInformation;
};


export type MutationUpdateGpInAppointmentArgs = {
  appointmentId: Scalars['Int'];
  formData: GpInformation;
};


export type MutationUpdateInsuranceCompanyArgs = {
  id: Scalars['Int'];
  formData: InsuranceInformation;
};


export type MutationUpdateMarkedAsSentArgs = {
  id: Scalars['ID'];
  markedAsSent?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMedicalHistoryArgs = {
  patient_id: Scalars['Int'];
  medical_history: Scalars['String'];
};


export type MutationUpdateMopPatientArgs = {
  id?: Maybe<Scalars['ID']>;
  patient: MopPatientInput;
};


export type MutationUpdatePanelArgs = {
  state: Scalars['String'];
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  appointmentSuggested: Scalars['Boolean'];
  reviewerComments?: Maybe<Scalars['String']>;
  profileComments?: Maybe<Array<Maybe<TestReportProfileComment>>>;
  supportMessage?: Maybe<Scalars['String']>;
};


export type MutationUpdatePartnerBrandingArgs = {
  partnerId?: Maybe<Scalars['ID']>;
  branding?: Maybe<PartnerBrandingInput>;
};


export type MutationUpdatePartnerMembershipCodeArgs = {
  membershipCode: PartnerMembershipCodeInput;
};


export type MutationUpdatePatientArgs = {
  id: Scalars['Int'];
  patient: ProfileInformation;
};


export type MutationUpdatePatientAlertArgs = {
  patientId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationUpdatePatientDetailsArgs = {
  id: Scalars['Int'];
  patient: ProfileInformation;
};


export type MutationUpdatePatientNameArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdatePortalUserArgs = {
  portalUser: PortalUserUpdateInput;
};


export type MutationUpdatePrimaryGuardianArgs = {
  dependantId: Scalars['String'];
  dependantUuid: Scalars['String'];
  carerUuid: Scalars['String'];
};


export type MutationUpdateReferralArgs = {
  referralId: Scalars['ID'];
  input: ConsultationReferralInput;
};


export type MutationUpdateReportStateArgs = {
  state: Scalars['String'];
  id: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  appointmentSuggested: Scalars['Boolean'];
  reviewerComments?: Maybe<Scalars['String']>;
  supportMessage?: Maybe<Scalars['String']>;
};


export type MutationUpdateTenancyPartnerArgs = {
  partner: TenancyPartnerUpdateInput;
};


export type MutationUpdateTenancyPartnerParentArgs = {
  partnerId: Scalars['String'];
  newParentId: Scalars['String'];
};


export type MutationUploadConsultationFileArgs = {
  id: Scalars['ID'];
  fileAsBase64String: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationUploadMembersArgs = {
  members: PartnerMembersInput;
};


export type MutationValidateDrugArgs = {
  region: Scalars['String'];
  patientId: Scalars['ID'];
  drug: PrescriptionDrugInput;
  consultationId?: Maybe<Scalars['ID']>;
  prescriptionDrugId?: Maybe<Scalars['ID']>;
};


export type MutationValidatePatientDetailsArgs = {
  patient?: Maybe<NhsPatient>;
};


export type MutationValidateTokenArgs = {
  input: OtpCheckRequest;
};


export type MutationVoidPrescriptionArgs = {
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type NewConceptResource = {
  __typename?: 'NewConceptResource';
  parentConcept: Scalars['String'];
  preferredLabel: Scalars['String'];
  ticket: Scalars['String'];
};

export type NewContributionResponse = {
  __typename?: 'NewContributionResponse';
  preferredLabel: Scalars['String'];
  iri: Scalars['String'];
  contributionId?: Maybe<Scalars['String']>;
};

export type NextOfKin = {
  __typename?: 'NextOfKin';
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
};

export type NextOfKinInput = {
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
};

export type NhsPatient = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  previous_names?: Maybe<Scalars['String']>;
  previous_last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  emergency_contact_details?: Maybe<Array<Maybe<EmergencyContactDetails>>>;
  date_of_birth?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_first_line?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  previous_practice_name?: Maybe<Scalars['String']>;
  birth_details?: Maybe<BirthDetailInput>;
  public_healthcare_details?: Maybe<PublicHealthcareDetailsInput>;
  previous_addresses?: Maybe<Array<Maybe<PreviousAddressesInput>>>;
  accepted_notice_versions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NlpReview = {
  __typename?: 'NLPReview';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<NlpReviewStatus>;
};

export enum NlpReviewStatus {
  NotApplicable = 'not_applicable',
  NotReviewed = 'not_reviewed',
  Reviewed = 'reviewed'
}

export type None = {
  __typename?: 'None';
  /** @deprecated A pointless field used to work around GraphQL's disallowance of empty object types. */
  _: Scalars['Void'];
};

export type NoteAssistantConsent = {
  __typename?: 'NoteAssistantConsent';
  decisions: Array<NoteAssistantConsentDecision>;
  permission_granted: Scalars['Boolean'];
};

export type NoteAssistantConsentDecision = {
  __typename?: 'NoteAssistantConsentDecision';
  decision: Scalars['Boolean'];
  decline_reason?: Maybe<Scalars['String']>;
  speaker_id: Scalars['String'];
};

export type NoteAssistantNote = {
  __typename?: 'NoteAssistantNote';
  notes: Array<Scalars['String']>;
  final?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type NoteAssistantRecordingId = {
  __typename?: 'NoteAssistantRecordingId';
  recording_id: Scalars['String'];
};

export type NoteAssistantRecordingIdInput = {
  encoding: Scalars['String'];
  encounterId: Scalars['String'];
  encounterType?: Maybe<Scalars['String']>;
  languageTag?: Maybe<Scalars['String']>;
  sampleRate: Scalars['String'];
  sourceType: Scalars['String'];
  speakers: Array<NoteAssistantSpeaker>;
};

export type NoteAssistantSpeaker = {
  speaker_id: Scalars['String'];
  speaker_type: Scalars['String'];
};

export type Notice = {
  __typename?: 'Notice';
  noticeId: Scalars['String'];
  name: Scalars['String'];
  noticeEnum?: Maybe<Scalars['String']>;
  mandatory: Scalars['Boolean'];
  defaultDecision: Scalars['Boolean'];
  ordering?: Maybe<Scalars['Int']>;
  defaultLocale?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  noticeType?: Maybe<Scalars['String']>;
  noticeVersions?: Maybe<Array<NoticeVersion>>;
};

export type NoticeDecision = {
  __typename?: 'NoticeDecision';
  decisionId: Scalars['ID'];
  userId: Scalars['ID'];
  noticeVersionId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  expired?: Maybe<Scalars['Boolean']>;
  date: Scalars['Date'];
};

export enum NoticeEnum {
  InformedConsent = 'INFORMED_CONSENT',
  MedicationHistoryAuthorization = 'MEDICATION_HISTORY_AUTHORIZATION'
}

export type NoticeVersion = {
  __typename?: 'NoticeVersion';
  noticeVersionId: Scalars['String'];
  title: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  noticeId: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  longText?: Maybe<Scalars['String']>;
  acceptButtonText?: Maybe<Scalars['String']>;
  declineButtonText?: Maybe<Scalars['String']>;
  readMoreButtonText?: Maybe<Scalars['String']>;
  popupTitle?: Maybe<Scalars['String']>;
  popupText?: Maybe<Scalars['String']>;
  chatbotText?: Maybe<Scalars['String']>;
  updateTitle?: Maybe<Scalars['String']>;
  updateText?: Maybe<Scalars['String']>;
  onboardingText?: Maybe<Scalars['String']>;
  settingsText?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  errorTitle?: Maybe<Scalars['String']>;
  errorText?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type OrphanedSlot = {
  __typename?: 'OrphanedSlot';
  consultant_id?: Maybe<Scalars['ID']>;
  consultant_name?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Date']>;
  appointment_id?: Maybe<Scalars['ID']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['Int']>;
  slot_size?: Maybe<Scalars['Int']>;
  supply_network_id?: Maybe<Scalars['ID']>;
  category_id?: Maybe<Scalars['ID']>;
  recurrent?: Maybe<Scalars['Boolean']>;
  marked_for_removal?: Maybe<Scalars['Boolean']>;
  physical?: Maybe<Scalars['Boolean']>;
  digital_bookable?: Maybe<Scalars['Boolean']>;
  physical_bookable?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['ID']>;
  rtm?: Maybe<Scalars['Boolean']>;
  availability_upload_id?: Maybe<Scalars['ID']>;
  shift_id?: Maybe<Scalars['ID']>;
  text_bookable?: Maybe<Scalars['Boolean']>;
  local_time?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type OrphanedSlots = {
  __typename?: 'OrphanedSlots';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  slots: Array<OrphanedSlot>;
};

export type OtpCheckRequest = {
  otpPassword: Scalars['String'];
};

export type PagedTimelineEvent = {
  __typename?: 'PagedTimelineEvent';
  totalEvents?: Maybe<Scalars['Int']>;
  hasMore?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Array<Maybe<TimelineEvent>>>;
};

export type PaginatedMembershipPlan = {
  __typename?: 'PaginatedMembershipPlan';
  more: Scalars['Boolean'];
  limit: Scalars['Int'];
  total: Scalars['Int'];
  offset: Scalars['Int'];
  memberships: Array<Maybe<PartnerMembershipPlan>>;
};

export type Pagination = {
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<Paging>>;
};

export type PaginationCriteria = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Paging = {
  id: Scalars['ID'];
};

export type Participant = {
  __typename?: 'Participant';
  type: Scalars['String'];
  userId: Scalars['String'];
  participant?: Maybe<Patient>;
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  status: PartnerStatus;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  supplyNetworkIds?: Maybe<Array<Scalars['ID']>>;
  supplyNetworks?: Maybe<Array<PartnerSupplyNetwork>>;
  membersCount?: Maybe<Scalars['Int']>;
  features?: Maybe<Array<PartnerFeature>>;
  membershipCodesCount?: Maybe<Scalars['Int']>;
  membershipCodes?: Maybe<Array<PartnerMembershipCode>>;
  pricing?: Maybe<Array<PartnerPricing>>;
  branding?: Maybe<PartnerBranding>;
  plans?: Maybe<Array<PartnerPlan>>;
};

export type PartnerBranding = {
  __typename?: 'PartnerBranding';
  id?: Maybe<Scalars['ID']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoCaption?: Maybe<Scalars['String']>;
  welcomeLogoUrl?: Maybe<Scalars['String']>;
  contentIconUrl?: Maybe<Scalars['String']>;
  contentTitle?: Maybe<Scalars['String']>;
  contentUrl?: Maybe<Scalars['String']>;
};

export type PartnerBrandingInput = {
  logoUrl?: Maybe<Scalars['String']>;
  logoCaption?: Maybe<Scalars['String']>;
  contentTitle?: Maybe<Scalars['String']>;
  contentUrl?: Maybe<Scalars['String']>;
};

export type PartnerFeature = {
  __typename?: 'PartnerFeature';
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
};

export type PartnerMembershipCode = {
  __typename?: 'PartnerMembershipCode';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  validationKey?: Maybe<Scalars['String']>;
  validationProvider?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messageClinician?: Maybe<Scalars['String']>;
};

export type PartnerMembershipCodeInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  validationKey?: Maybe<Scalars['String']>;
  validationProvider?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  messageClinician?: Maybe<Scalars['String']>;
};

export type PartnerMembershipPlan = {
  __typename?: 'PartnerMembershipPlan';
  membership_id: Scalars['ID'];
  member_id: Scalars['ID'];
  state?: Maybe<EntryState>;
  contract?: Maybe<MembershipContract>;
  created_by: Scalars['String'];
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
};

export type PartnerMembersInput = {
  csv: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
};

export type PartnerPlan = {
  __typename?: 'PartnerPlan';
  id: Scalars['ID'];
  partnerId: Scalars['ID'];
  partner: Partner;
  isDefault: Scalars['Boolean'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  braintreeToken?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Boolean']>;
  membersCount?: Maybe<Scalars['Int']>;
  billingCycle?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  minorPrice?: Maybe<Scalars['Float']>;
  gpAppointmentPrice?: Maybe<Scalars['Float']>;
  dietitianAppointmentPrice?: Maybe<Scalars['Float']>;
  seniorNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  specialistAppointmentPrice?: Maybe<Scalars['Float']>;
  therapistAppointmentPrice?: Maybe<Scalars['Float']>;
  triageNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  advancedNursePractitionerAppointmentPrice?: Maybe<Scalars['Float']>;
  dermatologyGpAppointmentPrice?: Maybe<Scalars['Float']>;
  diabeticSpecialistNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  physiotherapistAppointmentPrice?: Maybe<Scalars['Float']>;
  sexualHealthSpecialistNurseAppointmentPrice?: Maybe<Scalars['Float']>;
  travelHealthNurseAppointmentPrice?: Maybe<Scalars['Float']>;
};

export type PartnerPlanDefaults = {
  __typename?: 'PartnerPlanDefaults';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  supplyNetworks: Array<PartnerSupplyNetwork>;
  features: Array<PartnerFeature>;
  pricing: Array<PartnerPricingDefaults>;
  membershipPlan: MembershipPlan;
};

export type PartnerPricing = {
  __typename?: 'PartnerPricing';
  profession: Scalars['String'];
  price: Scalars['Float'];
  currency: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type PartnerPricingDefaults = {
  __typename?: 'PartnerPricingDefaults';
  profession: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  price: Scalars['Float'];
  waitTime?: Maybe<Scalars['Int']>;
  showInPortal: Scalars['Boolean'];
};

export enum PartnerStatus {
  Active = 'active',
  Retired = 'retired'
}

export type PartnerSupplyNetwork = {
  __typename?: 'PartnerSupplyNetwork';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
};

export type PastHumanitySync = {
  __typename?: 'PastHumanitySync';
  id: Scalars['ID'];
  created_at: Scalars['Date'];
  user_uuid?: Maybe<Scalars['ID']>;
  user_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['Date']>;
  clinician_count?: Maybe<Scalars['Int']>;
  status: HumanitySyncStatusEnum;
  last_synced_at?: Maybe<Scalars['Date']>;
  is_auto_sync?: Maybe<Scalars['Boolean']>;
  errors: Array<Maybe<Scalars['String']>>;
};

export type PastHumanitySyncs = {
  __typename?: 'PastHumanitySyncs';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  syncs: Array<Maybe<PastHumanitySync>>;
};

export type PathologyTest = {
  __typename?: 'PathologyTest';
  id?: Maybe<Scalars['Int']>;
  tdl_test_id?: Maybe<Scalars['Int']>;
  tdl_test_name?: Maybe<Scalars['String']>;
};

export type Patient = Subject & {
  __typename?: 'Patient';
  account_owner?: Maybe<Patient>;
  /** @deprecated use 'account_owner.contact_details' instead */
  account_owner_contact_details?: Maybe<ContactDetails>;
  /** @deprecated use 'account_owner.date_of_birth' instead */
  account_owner_dob?: Maybe<Scalars['Date']>;
  account_owner_id?: Maybe<Scalars['ID']>;
  /** @deprecated use 'account_owner.full_name' instead */
  account_owner_name?: Maybe<Scalars['String']>;
  account_state?: Maybe<Scalars['String']>;
  accounts_count?: Maybe<Scalars['Int']>;
  addressBasedTimezone?: Maybe<Scalars['String']>;
  address_first_line?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  allergies?: Maybe<Array<Maybe<Allergy>>>;
  api_key?: Maybe<Scalars['String']>;
  ask_for_gp_details?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_image_path?: Maybe<Scalars['String']>;
  birth_details?: Maybe<BirthDetails>;
  canCreatePrescriptionPdf?: Maybe<Validity>;
  cancelled_appointments_count?: Maybe<Scalars['Int']>;
  carePlan?: Maybe<CarePlan>;
  clinical_api_key?: Maybe<Scalars['String']>;
  clinical_api_key_expires?: Maybe<Scalars['Int']>;
  clinical_commissioning_group?: Maybe<ClinicalComissioningGroup>;
  completed_appointments_count?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  consumer_network?: Maybe<ConsumerNetwork>;
  consumer_networks?: Maybe<Array<Maybe<ConsumerNetwork>>>;
  contact_details?: Maybe<ContactDetails>;
  created_at?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  demographics?: Maybe<Demographics>;
  email?: Maybe<Scalars['String']>;
  emergency_contact_details?: Maybe<Array<Maybe<PatientEmergencyContactDetails>>>;
  exact_match?: Maybe<Scalars['Boolean']>;
  family_accounts?: Maybe<Array<Maybe<FamilyAccount>>>;
  family_accounts_count?: Maybe<Scalars['Int']>;
  family_accounts_owner?: Maybe<Scalars['Boolean']>;
  first_name: Scalars['String'];
  flags?: Maybe<Array<Maybe<Flag>>>;
  full_address?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  full_phone_number?: Maybe<Scalars['String']>;
  futureConsultations?: Maybe<Array<TimelineEvent>>;
  gender?: Maybe<Gender>;
  gp_address_first_line?: Maybe<Scalars['String']>;
  gp_address_post_code?: Maybe<Scalars['String']>;
  gp_address_second_line?: Maybe<Scalars['String']>;
  gp_address_third_line?: Maybe<Scalars['String']>;
  gp_details?: Maybe<GpDetails>;
  gp_details_id?: Maybe<Scalars['Int']>;
  gp_full_address?: Maybe<Scalars['String']>;
  gp_name?: Maybe<Scalars['String']>;
  gp_surgery_name?: Maybe<Scalars['String']>;
  gp_surgery_phone_number?: Maybe<Scalars['String']>;
  healthcare_identifier?: Maybe<HealthcareIdentifier>;
  height?: Maybe<Scalars['String']>;
  history?: Maybe<Array<TimelineEvent>>;
  historyV2: PatientHistory;
  id: Scalars['ID'];
  id_verification?: Maybe<VerificationStatus>;
  id_verification_status?: Maybe<VerificationStatus>;
  identity_check?: Maybe<IdentityCheck>;
  image?: Maybe<Scalars['String']>;
  insurance_company_id?: Maybe<Scalars['Int']>;
  insurance_membership_id?: Maybe<Scalars['Int']>;
  insurance_membership_number?: Maybe<Scalars['String']>;
  is_password_present?: Maybe<Scalars['Boolean']>;
  just_in_time_notices?: Maybe<Array<Maybe<PrivacyNotice>>>;
  kong?: Maybe<KongToken>;
  last_name: Scalars['String'];
  medical_history?: Maybe<Scalars['String']>;
  medications?: Maybe<Array<Maybe<Medication>>>;
  minor?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  next_of_kin?: Maybe<NextOfKin>;
  no_show_appointments_count?: Maybe<Scalars['Int']>;
  pagedHistory?: Maybe<PagedTimelineEvent>;
  password?: Maybe<Scalars['String']>;
  patient_alerts?: Maybe<Array<PatientAlert>>;
  patient_consultations?: Maybe<Array<Maybe<Consultation>>>;
  patient_metrics?: Maybe<PatientMetrics>;
  pendingInvites?: Maybe<Array<Maybe<AppointmentInvite>>>;
  pending_appointments_count?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  preferred_consumer_network?: Maybe<ConsumerNetwork>;
  preferred_consumer_network_id?: Maybe<Scalars['Int']>;
  previous_addresses?: Maybe<Array<Maybe<PreviousAddresses>>>;
  previous_names?: Maybe<Scalars['String']>;
  previous_practice_name?: Maybe<Scalars['String']>;
  privacy_notices?: Maybe<Array<Maybe<PrivacyNotice>>>;
  privacy_settings?: Maybe<Array<Maybe<PrivacyNotice>>>;
  profile_locked?: Maybe<Scalars['Boolean']>;
  profile_locks: Array<ProfileLock>;
  promotion?: Maybe<Promotion>;
  public_healthcare_admission?: Maybe<PublicHealthcareAdmission>;
  public_healthcare_details?: Maybe<PublicHealthcareDetails>;
  public_healthcare_identifier?: Maybe<Scalars['String']>;
  queued_for_activation?: Maybe<Scalars['Boolean']>;
  redeemedCodes: Array<Maybe<RedeemedCode>>;
  region?: Maybe<Region>;
  region_id?: Maybe<Scalars['Int']>;
  service_types?: Maybe<Array<ServiceType>>;
  shipping_addresses?: Maybe<Array<Maybe<ShippingAddress>>>;
  smoking_status?: Maybe<SmokingStatus>;
  /** @deprecated Field no longer supported */
  timezone?: Maybe<Scalars['String']>;
  timezone_id?: Maybe<Scalars['String']>;
  total_appointments_count?: Maybe<Scalars['Int']>;
  upcoming_appointments_count?: Maybe<Scalars['Int']>;
  uuid: Scalars['ID'];
  /** @deprecated Field no longer supported */
  verified?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['String']>;
};


export type PatientFutureConsultationsArgs = {
  dangerousConsultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientHistoryArgs = {
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  dangerousConsultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientHistoryV2Args = {
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  dangerousConsultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientPagedHistoryArgs = {
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type PatientPatient_MetricsArgs = {
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
};


export type PatientPendingInvitesArgs = {
  includingDigital?: Maybe<Scalars['Boolean']>;
};


export type PatientRedeemedCodesArgs = {
  patientId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type PatientService_TypesArgs = {
  include?: Maybe<Array<Scalars['String']>>;
  including_digital?: Maybe<Scalars['Boolean']>;
};

export type PatientAlert = {
  __typename?: 'PatientAlert';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  createdByName?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedByName?: Maybe<Scalars['String']>;
  viewer?: Maybe<Viewer>;
};

export enum PatientAlertsSortBy {
  Created = 'CREATED',
  Updated = 'UPDATED'
}

export type PatientAlertsSortOptions = {
  by?: Maybe<PatientAlertsSortBy>;
  order?: Maybe<PatientAlertsSortOrder>;
};

export enum PatientAlertsSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PatientAnswer = {
  __typename?: 'PatientAnswer';
  id: Scalars['ID'];
  dateAnswered: Scalars['Float'];
  answerText: Scalars['String'];
};

export type PatientConsumerNetwork = {
  __typename?: 'PatientConsumerNetwork';
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  can_be_cancelled?: Maybe<Scalars['Boolean']>;
  can_be_deregistered?: Maybe<Scalars['Boolean']>;
  consumer_network?: Maybe<ConsumerNetwork>;
  gp_at_hand?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<PatientConsumerNetworkHistory>>>;
  is_default?: Maybe<Scalars['Boolean']>;
  is_preferred?: Maybe<Scalars['Boolean']>;
  is_retired?: Maybe<Scalars['Boolean']>;
  started_on?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  plan?: Maybe<SubscriptionPlan>;
  credit_card?: Maybe<PortalCreditCard>;
  subscription?: Maybe<Subscription>;
};

export type PatientConsumerNetworkHistory = {
  __typename?: 'PatientConsumerNetworkHistory';
  id: Scalars['ID'];
  created_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
};

export type PatientConsumerNetworkPayload = {
  networkId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
};

export type PatientEmergencyContactDetails = {
  __typename?: 'PatientEmergencyContactDetails';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type PatientHistory = {
  __typename?: 'PatientHistory';
  events: Array<TimelineEvent>;
  errors: Array<PatientHistoryError>;
};

export type PatientHistoryError = {
  __typename?: 'PatientHistoryError';
  type: TimelineEventType;
  message: Scalars['String'];
  fullError: Scalars['String'];
};

export type PatientMetric = {
  __typename?: 'PatientMetric';
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  source: PatientMetricSource;
  timestamp?: Maybe<Scalars['Date']>;
  observationType?: Maybe<PatientMetricObservationType>;
  value?: Maybe<Scalars['JSON']>;
  values?: Maybe<Array<Maybe<PatientMetricValue>>>;
  performer: Scalars['String'];
};

export enum PatientMetricObservationType {
  Height = 'height',
  Weight = 'weight',
  SmokingStatus = 'smoking_status',
  BloodPressure = 'blood_pressure',
  Diastolic = 'diastolic',
  Systolic = 'systolic'
}

export type PatientMetrics = {
  __typename?: 'PatientMetrics';
  id: Scalars['ID'];
  height: Array<PatientMetric>;
  weight: Array<PatientMetric>;
  smoking_status: Array<PatientMetric>;
  blood_pressure: Array<PatientMetric>;
};

export enum PatientMetricSource {
  LegacyCore = 'legacy_core',
  ChrPrimaryStore = 'chr_primary_store',
  ChrCore = 'chr_core'
}

export type PatientMetricValue = {
  __typename?: 'PatientMetricValue';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type PatientQuestion = TimelineEventInterface & {
  __typename?: 'PatientQuestion';
  id: Scalars['ID'];
  patient: Patient;
  dateAsked: Scalars['Float'];
  text: Scalars['String'];
  fileCount: Scalars['Int'];
  files: Array<FileUpload>;
  state: PatientQuestionState;
  assignedTo?: Maybe<Consultant>;
  suggestedTags: Array<Tag>;
  answer?: Maybe<PatientAnswer>;
  eventType?: Maybe<TimelineEventType>;
  eventTime?: Maybe<Scalars['Date']>;
};

export enum PatientQuestionState {
  Open = 'Open',
  Assigned = 'Assigned',
  Done = 'Done'
}

export type PatientReferral = {
  __typename?: 'PatientReferral';
  patient_id: Scalars['ID'];
  contract_uuid: Scalars['ID'];
  referred_by_portal_login_id?: Maybe<Scalars['String']>;
  patient_email_sent_at: Scalars['String'];
  patient_email_reminder_scheduled_for: Scalars['String'];
  patient_email_reminder_sent_at?: Maybe<Scalars['String']>;
  partner_email_sent_at?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
};

export type PatientResentInvitation = {
  __typename?: 'PatientResentInvitation';
  id?: Maybe<Scalars['ID']>;
};

export type PatientResult = Patient | RetrievalError;

export type PatientSearchCriteria = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Payer = {
  __typename?: 'Payer';
  name?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  total?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  payer?: Maybe<Payer>;
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

export type PgmConcept = {
  __typename?: 'PgmConcept';
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  absent?: Maybe<Scalars['Boolean']>;
  probability?: Maybe<Scalars['Float']>;
};

export type PgmDiagnosis = {
  __typename?: 'PgmDiagnosis';
  id: Scalars['ID'];
  conversationId?: Maybe<Scalars['String']>;
  pgmUserId?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Scalars['String']>;
  evidence?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PgmQuestion = {
  __typename?: 'PgmQuestion';
  answers?: Maybe<Array<Maybe<Scalars['String']>>>;
  question?: Maybe<Scalars['String']>;
};

export type PgmReportCase = {
  __typename?: 'PgmReportCase';
  age?: Maybe<Scalars['Int']>;
  completed_at?: Maybe<Scalars['Date']>;
  conversation_id?: Maybe<Scalars['ID']>;
  created_at?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  sex?: Maybe<Gender>;
  status: PgmReportStatus;
  user_id: Scalars['ID'];
};

export type PgmReportConditionFull = {
  __typename?: 'PgmReportConditionFull';
  certainty_gauge: Scalars['Float'];
  common_treatment: PgmReportTreatment;
  extra_overview?: Maybe<Array<Maybe<PgmReportExtra>>>;
  icd_10?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is_top_differential: Scalars['Boolean'];
  layman_name: Scalars['String'];
  might_cause_distress: Scalars['Boolean'];
  name: Scalars['String'];
  nonpositive_related_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  positive_related_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  probability: Scalars['Float'];
  probability_label: Scalars['String'];
  probability_text: Scalars['String'];
  short_description: Scalars['String'];
  short_overview: Scalars['String'];
};

export type PgmReportConditionSummary = {
  __typename?: 'PgmReportConditionSummary';
  common_treatment: PgmReportTreatment;
  extra_overview: Array<Maybe<PgmReportExtra>>;
  id: Scalars['ID'];
  layman_name: Scalars['String'];
  leaflet_link?: Maybe<Scalars['String']>;
  might_cause_distress: Scalars['Boolean'];
  positive_related_symptoms: Array<Maybe<PgmReportSymptom>>;
  probability_layman_label: Scalars['String'];
  probability_level: Scalars['Int'];
  short_overview: Scalars['String'];
  triage?: Maybe<PgmReportTriage>;
};

export enum PgmReportDecision {
  SelfCare = 'SELF_CARE',
  Pharmacy = 'PHARMACY',
  Gp = 'GP',
  GpUrgent = 'GP_URGENT',
  Hospital = 'HOSPITAL',
  HospitalUrgent = 'HOSPITAL_URGENT'
}

export type PgmReportDiagnosisLegacy = {
  __typename?: 'PgmReportDiagnosisLegacy';
  conditions?: Maybe<Array<Maybe<PgmReportConditionFull>>>;
  unexplained_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
};

export type PgmReportDiagnosisSummary = {
  __typename?: 'PgmReportDiagnosisSummary';
  conditions?: Maybe<Array<Maybe<PgmReportConditionSummary>>>;
  might_cause_distress?: Maybe<Scalars['Boolean']>;
  might_cause_distress_text?: Maybe<Scalars['String']>;
};

export type PgmReportEvidence = {
  __typename?: 'PgmReportEvidence';
  id: Scalars['String'];
  layman_name: Scalars['String'];
  name: Scalars['String'];
  inferred: Scalars['Boolean'];
  state: Scalars['String'];
};

export type PgmReportExtra = {
  __typename?: 'PgmReportExtra';
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type PgmReportFailure = {
  __typename?: 'PgmReportFailure';
  code: Scalars['String'];
  message: Scalars['String'];
  unsupported_case_type?: Maybe<Scalars['String']>;
};

export type PgmReportLegacy = {
  __typename?: 'PgmReportLegacy';
  diagnosis: PgmReportDiagnosisLegacy;
  triage?: Maybe<PgmReportTriage>;
  triage_enabled: Scalars['Boolean'];
};

export type PgmReportOutcome = {
  __typename?: 'PgmReportOutcome';
  answered_all_questions: Scalars['Boolean'];
  case: PgmReportCase;
  conditions?: Maybe<Array<Maybe<PgmReportConditionFull>>>;
  evidence?: Maybe<Array<Maybe<PgmReportEvidence>>>;
  failure_reason?: Maybe<PgmReportFailure>;
  id: Scalars['String'];
  status: PgmReportStatus;
  triage: PgmReportTriage;
  triage_enabled: Scalars['Boolean'];
  unexplained_red_flag_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  unexplained_symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
};

export enum PgmReportStatus {
  Scheduled = 'scheduled',
  Started = 'started',
  Completed = 'completed'
}

export type PgmReportSummary = {
  __typename?: 'PgmReportSummary';
  case?: Maybe<PgmReportCase>;
  created_at?: Maybe<Scalars['Date']>;
  diagnosis?: Maybe<PgmReportDiagnosisSummary>;
  id?: Maybe<Scalars['ID']>;
  nonpositive_symptons?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  positive_symptons?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  triage?: Maybe<PgmReportTriage>;
  triage_enabled?: Maybe<Scalars['Boolean']>;
};

export type PgmReportSymptom = {
  __typename?: 'PgmReportSymptom';
  id: Scalars['ID'];
  layman_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type PgmReportTreatment = {
  __typename?: 'PgmReportTreatment';
  description?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['Int']>;
};

export type PgmReportTriage = {
  __typename?: 'PgmReportTriage';
  decision: PgmReportDecision;
  diseases?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  explanation?: Maybe<Scalars['String']>;
  rule_name?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Array<Maybe<PgmReportSymptom>>>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  soft_triage_text?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type PharmacyAddress = {
  __typename?: 'PharmacyAddress';
  addressLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export enum PlaceEnum {
  Gppractices = 'gppractices',
  Pharmacies = 'pharmacies',
  AccidentAndEmergency = 'accident_and_emergency',
  SexualHealthServices = 'sexual_health_services'
}

export type PlaceResource = {
  __typename?: 'PlaceResource';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<PlaceResourceAddress>;
};

export type PlaceResourceAddress = {
  __typename?: 'PlaceResourceAddress';
  line?: Maybe<Array<Maybe<Scalars['String']>>>;
  postalCode?: Maybe<Scalars['String']>;
};

export type PlaceResourceInput = {
  type?: Maybe<PlaceEnum>;
  postalCode: Scalars['String'];
  countryCode: Scalars['String'];
  radiusMeters: Scalars['String'];
};

export type PortalCreditCard = {
  __typename?: 'PortalCreditCard';
  cardType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last4?: Maybe<Scalars['String']>;
};

export type PortalTransaction = {
  __typename?: 'PortalTransaction';
  braintreeStatus?: Maybe<Scalars['String']>;
  braintreeToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  creditCard?: Maybe<PortalCreditCard>;
  currencySymbol: Scalars['String'];
  id: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  purchaseType?: Maybe<Scalars['String']>;
  refundable?: Maybe<Scalars['Boolean']>;
  refundedAt?: Maybe<Scalars['Date']>;
  showReason?: Maybe<Scalars['String']>;
};

export type PortalUser = Paging & {
  __typename?: 'PortalUser';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  timezone: Scalars['String'];
  securityPhoneNumber?: Maybe<Scalars['String']>;
  twoFactorAuthActive: Scalars['Boolean'];
  active: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  roles?: Maybe<Array<PortalUserRole>>;
  partnerIds?: Maybe<Array<Scalars['ID']>>;
  partners?: Maybe<Array<TenancyPartner>>;
  filteredPartners?: Maybe<Array<TenancyPartner>>;
};

export type PortalUserInput = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  securityPhoneNumber?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  twoFactorAuthActive: Scalars['Boolean'];
  active: Scalars['Boolean'];
  roleIds: Array<Scalars['ID']>;
  partnerIds?: Maybe<Array<Scalars['ID']>>;
};

export type PortalUserResults = Pagination & {
  __typename?: 'PortalUserResults';
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<PortalUser>>;
};

export type PortalUserRole = {
  __typename?: 'PortalUserRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  key: Scalars['String'];
};

export type PortalUsersList = {
  __typename?: 'PortalUsersList';
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<PortalUser>;
};

export type PortalUserSortCriteria = {
  field?: Maybe<PortalUserSortField>;
  order?: Maybe<SortOrder>;
};

export enum PortalUserSortField {
  FullName = 'fullName',
  Email = 'email',
  Timezone = 'timezone',
  TwoFactorAuthActive = 'twoFactorAuthActive',
  CreatedAt = 'createdAt',
  Active = 'active'
}

export type PortalUserUpdateInput = {
  id: Scalars['ID'];
  fullName: Scalars['String'];
  securityPhoneNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  timezone: Scalars['String'];
  twoFactorAuthActive: Scalars['Boolean'];
  active: Scalars['Boolean'];
  roleIds?: Maybe<Array<Scalars['ID']>>;
  partnerIds?: Maybe<Array<Scalars['ID']>>;
};

export type Practice = {
  __typename?: 'Practice';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  full_address?: Maybe<PracticeAddress>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  arrival_instructions?: Maybe<Scalars['String']>;
  timezone_id?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  timezone?: Maybe<Scalars['String']>;
};

export type PracticeAddress = {
  __typename?: 'PracticeAddress';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
};

export type Prescription = Paging & {
  __typename?: 'Prescription';
  id: Scalars['ID'];
  consultationId?: Maybe<Scalars['ID']>;
  consultation?: Maybe<Consultation>;
  appointment?: Maybe<Appointment>;
  consultant?: Maybe<Consultant>;
  patientId?: Maybe<Scalars['ID']>;
  patient?: Maybe<Patient>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  drugs: Array<PrescriptionDrug>;
  prescriptionState: Scalars['String'];
  signatureState?: Maybe<Scalars['String']>;
  consumerNetwork?: Maybe<Scalars['String']>;
  deliveryMethod?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<Scalars['String']>;
  pharmacyId?: Maybe<Scalars['String']>;
  pharmacySupplierId?: Maybe<Scalars['String']>;
  pharmacyDetails?: Maybe<Scalars['String']>;
  finalized?: Maybe<Scalars['Boolean']>;
  draft?: Maybe<Scalars['Boolean']>;
  canNotBeVoided?: Maybe<Scalars['Boolean']>;
  canBeMarkedAsPrinted?: Maybe<Scalars['Boolean']>;
  canBeConfirmedReceived?: Maybe<Scalars['Boolean']>;
  canBeApproved?: Maybe<Scalars['Boolean']>;
  canBeFaxed?: Maybe<Scalars['Boolean']>;
  approvalNeeded?: Maybe<Scalars['Boolean']>;
  canPharmacyBeRejected?: Maybe<Scalars['Boolean']>;
  latestComment?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<Maybe<Scalars['String']>>>;
  audit?: Maybe<Array<Maybe<PrescriptionAudit>>>;
  homeDeliveryAvailable?: Maybe<Scalars['Boolean']>;
  deliveryMethods?: Maybe<PrescriptionDeliveryMethods>;
  deliveryAddress?: Maybe<PrescriptionDeliveryAddress>;
  formattedAddress?: Maybe<Scalars['String']>;
  sendTo?: Maybe<Scalars['String']>;
};


export type PrescriptionAuditArgs = {
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PrescriptionAudit = {
  __typename?: 'PrescriptionAudit';
  id: Scalars['ID'];
  auditId: Scalars['ID'];
  babylonRequestId?: Maybe<Scalars['ID']>;
  consumerNetwork?: Maybe<Scalars['Int']>;
  eventDetails?: Maybe<Scalars['String']>;
  eventTimestamp?: Maybe<Scalars['String']>;
  loginType?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  performedBy?: Maybe<Scalars['String']>;
  performedByName?: Maybe<Scalars['String']>;
  prescriptionId?: Maybe<Scalars['ID']>;
  prescription?: Maybe<Prescription>;
};

export type PrescriptionDeliveryAddress = {
  __typename?: 'PrescriptionDeliveryAddress';
  id?: Maybe<Scalars['ID']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
};

export type PrescriptionDeliveryConfirmation = {
  __typename?: 'PrescriptionDeliveryConfirmation';
  message: Scalars['String'];
};

export type PrescriptionDeliveryMethods = {
  __typename?: 'PrescriptionDeliveryMethods';
  homeDelivery?: Maybe<Scalars['Boolean']>;
  homeDrugDelivery?: Maybe<Scalars['Boolean']>;
  pharmacyDelivery?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionDrug = {
  __typename?: 'PrescriptionDrug';
  drugId?: Maybe<Scalars['ID']>;
  prescriptionDrugId: Scalars['ID'];
  drugName: Scalars['String'];
  patient?: Maybe<Patient>;
  dosage?: Maybe<Scalars['String']>;
  packagingSize?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  refills?: Maybe<Scalars['Int']>;
  directions: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<Maybe<PrescriptionDrugIndication>>>;
  repeatable?: Maybe<Scalars['Boolean']>;
  repeats?: Maybe<Scalars['Int']>;
  reviewDate?: Maybe<Scalars['Date']>;
};

export type PrescriptionDrugIndication = {
  __typename?: 'PrescriptionDrugIndication';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
};

export type PrescriptionDrugIndicationInput = {
  name?: Maybe<Scalars['String']>;
  iri?: Maybe<Scalars['String']>;
};

export type PrescriptionDrugInput = {
  drugId?: Maybe<Scalars['ID']>;
  drugName: Scalars['String'];
  dosage?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  refills?: Maybe<Scalars['Int']>;
  directions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  indications?: Maybe<Array<Maybe<PrescriptionDrugIndicationInput>>>;
  pharmaceuticalForm?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  packagingSize?: Maybe<Scalars['String']>;
  repeatableDrug?: Maybe<Scalars['Boolean']>;
  repeatable?: Maybe<Scalars['Boolean']>;
  repeats?: Maybe<Scalars['Int']>;
  reviewDate?: Maybe<Scalars['Date']>;
};

export type PrescriptionFaxStatus = {
  __typename?: 'PrescriptionFaxStatus';
  id: Scalars['ID'];
  prescription?: Maybe<Prescription>;
  pharmacyId?: Maybe<Scalars['String']>;
  faxableId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  errorId?: Maybe<Scalars['Int']>;
  errorType?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  failedAt?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type PrescriptionPharmacySupplier = {
  __typename?: 'PrescriptionPharmacySupplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type PrescriptionRegion = {
  __typename?: 'PrescriptionRegion';
  babylonRegionId: Scalars['ID'];
  regionCode: Scalars['String'];
  name: Scalars['String'];
  regionIri?: Maybe<Scalars['String']>;
};

export type Prescriptions = Pagination & {
  __typename?: 'Prescriptions';
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<Prescription>>;
};

export type PrescriptionSignatureRequestInput = {
  signingToken?: Maybe<Scalars['String']>;
  base64Image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export enum PrescriptionStateActionType {
  AcceptEpsCode = 'ACCEPT_EPS_CODE',
  ApprovePrescription = 'APPROVE_PRESCRIPTION',
  Create = 'CREATE',
  MarkAsConfirmedReceived = 'MARK_AS_CONFIRMED_RECEIVED',
  MarkAsPrinted = 'MARK_AS_PRINTED',
  MarkDrugsDispatched = 'MARK_DRUGS_DISPATCHED',
  MarkAsDispensed = 'MARK_AS_DISPENSED',
  MarkPrinted = 'MARK_PRINTED',
  RejectPharmacySelection = 'REJECT_PHARMACY_SELECTION',
  RejectPrescription = 'REJECT_PRESCRIPTION',
  RejectPrescriptionDispensing = 'REJECT_PRESCRIPTION_DISPENSING',
  ResendEpsCode = 'RESEND_EPS_CODE',
  Void = 'VOID'
}

export type PrescriptionStatistics = {
  __typename?: 'PrescriptionStatistics';
  id?: Maybe<Scalars['ID']>;
  toBePrintedCount?: Maybe<Scalars['Int']>;
  toBeSignedCount?: Maybe<Scalars['Int']>;
  toBeReviewedCount?: Maybe<Scalars['Int']>;
};

export type PrescriptionValidation = {
  __typename?: 'PrescriptionValidation';
  drugName: Scalars['String'];
  alternativeDrugNote?: Maybe<Scalars['String']>;
  alternativeDrug?: Maybe<Scalars['String']>;
  warnings?: Maybe<Array<Maybe<PrescriptionValidationWarning>>>;
};

export type PrescriptionValidationWarning = {
  __typename?: 'PrescriptionValidationWarning';
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  allowPrescribing?: Maybe<Scalars['Boolean']>;
};

export type PreviousAddresses = {
  __typename?: 'PreviousAddresses';
  first_line?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  third_line?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
};

export type PreviousAddressesInput = {
  first_line?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
};

export type PrivacyApp = {
  __typename?: 'PrivacyApp';
  appId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
};

export type PrivacyNotice = {
  __typename?: 'PrivacyNotice';
  accepted?: Maybe<Scalars['Boolean']>;
  noticeVersionId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  noticeId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  longText?: Maybe<Scalars['String']>;
  acceptButtonText?: Maybe<Scalars['String']>;
  declineButtonText?: Maybe<Scalars['String']>;
  readMoreButtonText?: Maybe<Scalars['String']>;
  popupTitle?: Maybe<Scalars['String']>;
  popupText?: Maybe<Scalars['String']>;
  chatbotText?: Maybe<Scalars['String']>;
  updateTitle?: Maybe<Scalars['String']>;
  updateText?: Maybe<Scalars['String']>;
  onboardingText?: Maybe<Scalars['String']>;
  settingsText?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  errorTitle?: Maybe<Scalars['String']>;
  errorText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  mandatory?: Maybe<Scalars['Boolean']>;
};

export type Profession = {
  __typename?: 'Profession';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  key: Scalars['String'];
  alternative_slot_durations_minutes?: Maybe<Array<Scalars['Int']>>;
  default_slot_duration_minutes?: Maybe<Scalars['Int']>;
  default_appointment_price_cents?: Maybe<Scalars['Int']>;
  medical_identifier_label?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type ProfileInformation = {
  avatar?: Maybe<Scalars['String']>;
  avatar_image_path?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  password?: Maybe<Scalars['String']>;
  preferred_consumer_network_id?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  smoking_status?: Maybe<SmokingStatus>;
  address_first_line?: Maybe<Scalars['String']>;
  address_second_line?: Maybe<Scalars['String']>;
  address_third_line?: Maybe<Scalars['String']>;
  address_state_code?: Maybe<Scalars['String']>;
  address_post_code?: Maybe<Scalars['String']>;
  is_password_present?: Maybe<Scalars['Boolean']>;
  next_of_kin?: Maybe<NextOfKinInput>;
};

export type ProfileLock = {
  __typename?: 'ProfileLock';
  name: Scalars['String'];
  locked_at: Scalars['String'];
};

export type PromoteCarePlanDraftInput = {
  carePlanDraftContext: CarePlanDraftContext;
};

export type PromoteCarePlanDraftResult = PromoteCarePlanDraftSuccess | CarePlanDraftDoesNotExistError | UnresolvedWarningsError | ConsultationDoesNotExistError | InvalidCarePlanDraftNumbersOfEditsError | CarePlanAuthorizationError;

export type PromoteCarePlanDraftSuccess = {
  __typename?: 'PromoteCarePlanDraftSuccess';
  carePlan: CarePlan;
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['Int'];
  appointment_count?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  code_prefix?: Maybe<Scalars['String']>;
  credit_card_required_text?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  duration: Scalars['Int'];
  expires_on?: Maybe<Scalars['String']>;
  is_credit_card_required: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  percent_off?: Maybe<Scalars['Int']>;
  promotion_type?: Maybe<Scalars['String']>;
};

export type ProvenanceEventStatement = {
  __typename?: 'ProvenanceEventStatement';
  subject: Scalars['String'];
  predicate: Scalars['String'];
  object: Scalars['String'];
};

export type PublicHealthcareAdmission = {
  __typename?: 'PublicHealthcareAdmission';
  id?: Maybe<Scalars['ID']>;
  location_checked?: Maybe<Scalars['Boolean']>;
  pds_trace_status?: Maybe<Scalars['String']>;
  verification_overwritten_by_ops?: Maybe<Scalars['Boolean']>;
  consumer_network_id?: Maybe<Scalars['ID']>;
};

export type PublicHealthcareDetails = {
  __typename?: 'PublicHealthcareDetails';
  id?: Maybe<Scalars['ID']>;
  armed_forces_enlisting_date?: Maybe<Scalars['String']>;
  arrival_date?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['String']>;
};

export type PublicHealthcareDetailsInput = {
  id?: Maybe<Scalars['ID']>;
  arrival_date?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['String']>;
  armed_forces_enlisting_date?: Maybe<Scalars['String']>;
};

export type Purchase = {
  __typename?: 'Purchase';
  id: Scalars['Int'];
  activity_id: Scalars['Int'];
  cancelled_at?: Maybe<Scalars['String']>;
  consultant_completed_at?: Maybe<Scalars['String']>;
  consultant_ended_at?: Maybe<Scalars['String']>;
  consultant_id: Scalars['Int'];
  consultant_switched_to_voice_at?: Maybe<Scalars['String']>;
  consultant_type: Scalars['String'];
  consumer_network_id: Scalars['Int'];
  created_at: Scalars['String'];
  diagnosis_codes?: Maybe<Scalars['String']>;
  diagnosis_notes?: Maybe<Scalars['String']>;
  encrypted_diagnosis_notes?: Maybe<Scalars['String']>;
  encrypted_examination_notes?: Maybe<Scalars['String']>;
  encrypted_further_action_notes?: Maybe<Scalars['String']>;
  encrypted_history_notes?: Maybe<Scalars['String']>;
  encrypted_management_notes?: Maybe<Scalars['String']>;
  encrypted_patient_note?: Maybe<Scalars['String']>;
  encrypted_pre_auth_number?: Maybe<Scalars['Int']>;
  examination_codes?: Maybe<Scalars['String']>;
  examination_notes?: Maybe<Scalars['String']>;
  fax_number_id?: Maybe<Scalars['Int']>;
  faxed: Scalars['Boolean'];
  further_action_codes?: Maybe<Scalars['String']>;
  further_action_notes?: Maybe<Scalars['String']>;
  gp_consent: Scalars['Boolean'];
  history_codes?: Maybe<Scalars['String']>;
  history_notes?: Maybe<Scalars['String']>;
  image_content_type?: Maybe<Scalars['String']>;
  image_file_name?: Maybe<Scalars['String']>;
  image_file_size?: Maybe<Scalars['String']>;
  image_updated_at?: Maybe<Scalars['String']>;
  management_codes?: Maybe<Scalars['String']>;
  management_notes?: Maybe<Scalars['String']>;
  medium: Scalars['String'];
  paid: Scalars['Boolean'];
  paid_at?: Maybe<Scalars['String']>;
  patient_id: Scalars['Int'];
  patient_note?: Maybe<Scalars['String']>;
  pre_auth_number?: Maybe<Scalars['Int']>;
  prescription_upload_content_type?: Maybe<Scalars['String']>;
  prescription_upload_file_name?: Maybe<Scalars['String']>;
  prescription_upload_file_size?: Maybe<Scalars['String']>;
  prescription_upload_updated_at?: Maybe<Scalars['String']>;
  redemption_id?: Maybe<Scalars['Int']>;
  sent_to_gp: Scalars['Boolean'];
  state: Scalars['String'];
  time?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activityLog?: Maybe<ActivityLog>;
  adminActiveIngredient?: Maybe<Array<AdminActiveIngredient>>;
  adminActiveIngredients?: Maybe<AdminActiveIngredients>;
  adminCancelReasons?: Maybe<Array<Maybe<CancelReason>>>;
  adminDrug?: Maybe<AdminDrug>;
  adminDrugAlert?: Maybe<AdminDrugAlert>;
  adminDrugAlerts?: Maybe<AdminDrugAlerts>;
  adminDrugAudits?: Maybe<Array<Maybe<AdminDrugAudit>>>;
  adminDrugs?: Maybe<AdminDrugResult>;
  allergies: Array<Maybe<Allergy>>;
  appNoticeVersion: NoticeVersion;
  appNotices?: Maybe<Array<Notice>>;
  appointment?: Maybe<Appointment>;
  appointmentAvailabilitySlots?: Maybe<Array<Maybe<AppointmentAvailabilitySlot>>>;
  appointmentClinicians: Array<Consultant>;
  appointmentClinics: Array<Practice>;
  appointmentConfigurationNameExists: Scalars['Boolean'];
  appointmentConfigurations?: Maybe<Array<Maybe<ConfigurationByContract>>>;
  appointmentCredit?: Maybe<AppointmentCredit>;
  appointmentCreditReasons: Array<AppointmentCreditReason>;
  appointmentCredits: Array<AppointmentCredit>;
  appointmentInvite?: Maybe<AppointmentInvite>;
  appointmentInvites: Array<AppointmentInvite>;
  appointmentSlots?: Maybe<Array<Maybe<AppointmentSlot>>>;
  appointmentSlotsV2?: Maybe<AppointmentSlots>;
  appointmentV2?: Maybe<Appointment>;
  appointmentWaitTimeConfigs: AppointmentWaitTimeConfigs;
  appointmentWaitTimes: AppointmentWaitTimes;
  appointments: Array<Maybe<Appointment>>;
  availability: Array<Maybe<ClinicianAvailability>>;
  availabilitySlots: AvailabilitySlots;
  availableConsultants: Array<Maybe<Consultant>>;
  availablePharmacySuppliers: Array<Maybe<PrescriptionPharmacySupplier>>;
  cancelReasons?: Maybe<Array<Maybe<CancelReason>>>;
  carePlan: CarePlanResult;
  carePlanDraft: CarePlanDraftResult;
  carePlanPublishedInConsultation: CarePlanPublishedInConsultationResult;
  carers: Array<Carer>;
  chatscriptConversation?: Maybe<ChatscriptConversation>;
  chatscriptConversations?: Maybe<ChatscriptConversationsResponse>;
  chatscriptMessages?: Maybe<ChatscriptMessagesResponse>;
  clinicalKnowledgeEntity?: Maybe<ClinicalKnowledgeEntity>;
  clinicalKnowledgeViews: Array<ClinicalKnowledgeView>;
  clinicalUserInformation?: Maybe<Patient>;
  clinicianAvailabilityShifts: ClinicianAvailabilityShifts;
  clinicianServiceTypes: Array<ServiceType>;
  comment: Comment;
  comments?: Maybe<Comments>;
  conditions: Array<Maybe<Condition>>;
  configurationDetails?: Maybe<ConfigurationDetailsType>;
  consultant?: Maybe<Consultant>;
  consultantMetrics?: Maybe<Array<Maybe<ScheduleDateInfo>>>;
  consultantRTMStatus: ConsultantRtmStatus;
  consultantTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  consultation?: Maybe<Consultation>;
  consultationByAppointmentId?: Maybe<Consultation>;
  consultationHistory: Array<Maybe<ConsultationHistory>>;
  consultationNote?: Maybe<ConsultationNote>;
  consultationPermissions?: Maybe<Array<ConsultationPermission>>;
  consultationSickNote?: Maybe<ConsultationSickNote>;
  consultationTypes?: Maybe<Array<Maybe<AppointmentMedium>>>;
  consultationWorkflowsV2: ConsultationWorkflowsResponse;
  consultations: Array<Consultation>;
  consultationsv2: ConsultationResults;
  consumerNetworkAppointmentCategories: Array<AppointmentCategory>;
  consumerNetworkProfessions: Array<ConsumerNetworkProfession>;
  consumerNetworkServiceTypes: Array<ServiceType>;
  consumerNetworks: Array<Maybe<ConsumerNetwork>>;
  contract?: Maybe<Contract>;
  coverageEligibilityList?: Maybe<CoverageEligibilityList>;
  coverageEligibilityRead: CoverageEligibility;
  cp3ClinicalCodeWeightings: Array<Cp3ClinicalCodeWeightingsResult>;
  cp3LinksAndResources: Array<Cp3LinksAndResourcesResult>;
  cp3ReleaseNotes: Array<Cp3ReleaseNotesResult>;
  currentPortalUser?: Maybe<PortalUser>;
  diagnosticTestTypes: Array<Maybe<TestType>>;
  drug?: Maybe<Drug>;
  drugDosages?: Maybe<Array<Maybe<DrugDosage>>>;
  drugForms?: Maybe<Array<Maybe<Scalars['String']>>>;
  drugUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  drugs: Array<Maybe<Drug>>;
  encryptedSearchPatients?: Maybe<MopSearchPatients>;
  extensionalValueSet: Scalars['String'];
  familyAccount?: Maybe<FamilyAccount>;
  familyMembers: Array<Maybe<Patient>>;
  /** Get a list of feature states given a list of tags and a scope */
  featureStates: Array<FeatureState>;
  featureSwitches?: Maybe<FeatureSwitch>;
  fhirProfile?: Maybe<FhirProfile>;
  fhirProfileExample: Scalars['String'];
  fhirProfiles: Array<FhirProfile>;
  fhirValueSet?: Maybe<FhirValueSet>;
  fhirValueSets: Array<FhirValueSet>;
  findConsumerNetworks: Array<Maybe<ConsumerNetwork>>;
  flags: Array<Maybe<Flag>>;
  getAllAppointmentConfigurations?: Maybe<AppointmentConfigurations>;
  getAppointmentCheckIn?: Maybe<CheckInDetails>;
  getAppointmentConfigurationById?: Maybe<Configurations>;
  getAppointmentConfigurationByName?: Maybe<Configurations>;
  getCallEndedCallFlowSteps: CallFlowStatus;
  getCallFlowSteps: CallFlowStatus;
  getClinicalNotes: Array<Maybe<ClinicalNote>>;
  getClinicalTerminologyAttribute: ClinicalTerminologyAttributeComplete;
  getClinicalTerminologyAttributes: Array<Maybe<ClinicalTerminologyAttribute>>;
  getClinicalTerminologyEntity: ClinicalTerminologyEntity;
  getClinicalTerminologyEvents: ClinicalTerminologyEvents;
  getClinicalTerminologyLabels: ClinicalTerminologyLabelsEntity;
  getClinicianVisibility?: Maybe<ClinicianVisibility>;
  getConcepts?: Maybe<Array<Maybe<Concept>>>;
  getConsultants?: Maybe<Array<Maybe<Consultant>>>;
  getConsultantsPerId?: Maybe<Array<Maybe<Consultant>>>;
  getContribution: ClinicalTerminologyContribution;
  getContributionsByUser: ClinicalTerminologyContributionList;
  getDevices: Array<DeviceInfo>;
  getEHRPatient: EhrPatient;
  getEligibleConsultants?: Maybe<Array<Maybe<Consultant>>>;
  getFhirUsage: FhirContextUsage;
  getMembershipPlans?: Maybe<PaginatedMembershipPlan>;
  getPanel?: Maybe<TestReport>;
  getPanels?: Maybe<TestReports>;
  getPatientConsultations: ConsultationResults;
  getPatientReferrals: Array<PatientReferral>;
  getPatientSubscriptions: Array<Maybe<Subscription>>;
  getRawCallFlowSteps: CallFlowStatus;
  getReport?: Maybe<TestReport>;
  getReports?: Maybe<TestReports>;
  getSickNotes: Array<Maybe<SickNote>>;
  getTenancyRegion: TenancyRegion;
  getTenancyRegions?: Maybe<Array<TenancyRegion>>;
  getTimelineEventsByPatient?: Maybe<PagedTimelineEvent>;
  getTransactionReasons: Array<Reason>;
  getTransactions: Array<PortalTransaction>;
  getUserRoles: UserRoles;
  gpSurgeryFaxes: Array<GpSurgeryFax>;
  gpUserInformation?: Maybe<Patient>;
  gpahConsumerNetworks: Array<Maybe<ConsumerNetworkName>>;
  healthCheckAssessmentStage?: Maybe<HealthCheckAssessmentStage>;
  healthCheckReport?: Maybe<Array<Maybe<HealthCheckCategory>>>;
  healthCheckSummary: HealthCheckSummary;
  humanitySyncAllowed?: Maybe<HumanitySyncAllowed>;
  humanitySyncShiftItems: HumanitySyncShiftItems;
  /** @deprecated replaced by humanitySyncShiftItems */
  humanitySyncShifts: HumanitySyncShifts;
  humanitySyncStatus?: Maybe<HumanitySyncStatus>;
  humanitySyncs: PastHumanitySyncs;
  idVerification: IdVerification;
  identify?: Maybe<Patient>;
  incompleteConsultationsInfo: Array<ConsultationInfo>;
  insuranceCompanies: Array<Maybe<InsuranceCompany>>;
  intensionalValueSet: Scalars['String'];
  isNewValueSetId?: Maybe<IdValidationResult>;
  listTenancyPartners: TenancyPartnersList;
  medicalHistory?: Maybe<MedicalHistory>;
  medications: Array<Maybe<Medication>>;
  memberDecisionForNotice?: Maybe<NoticeDecision>;
  memberTask: MemberTask;
  memberTasks: Array<MemberTask>;
  memberThread: MemberThread;
  memberThreads: Array<MemberThread>;
  monthlyCalendarSchedule: Array<DateWithShiftInfo>;
  monthlyCalendarScheduleV2: MonthlyCalendarSchedule;
  multimediaFeedbackQuestion?: Maybe<MultimediaFeedbackQuestion>;
  multimediaFeedbackQuestionTemp?: Maybe<MultimediaFeedbackQuestion>;
  newAppointment?: Maybe<Appointment>;
  noteAssistantConsent: NoteAssistantConsent;
  noteAssistantNotes: NoteAssistantNote;
  noteAssistantRecordingId: NoteAssistantRecordingId;
  orphanedSlots: OrphanedSlots;
  partnerPlanDefaults?: Maybe<PartnerPlanDefaults>;
  pathologyTestTypes: Array<Maybe<TestType>>;
  patient?: Maybe<Patient>;
  patientConsumerNetworks: Array<Maybe<PatientConsumerNetwork>>;
  patient_alert?: Maybe<PatientAlert>;
  patient_alerts: Array<Maybe<PatientAlert>>;
  patients: Array<Patient>;
  patientsByIds: Array<Maybe<MopPatient>>;
  permittedRoles?: Maybe<Array<PortalUserRole>>;
  pgmReport?: Maybe<PgmReportOutcome>;
  pgmReportLegacy?: Maybe<PgmReportLegacy>;
  pgmReportSummary?: Maybe<PgmReportSummary>;
  pharmacyPrescriptions?: Maybe<Prescriptions>;
  placesSearch?: Maybe<Array<Maybe<PlaceResource>>>;
  portalUser?: Maybe<PortalUser>;
  portalUserPartners?: Maybe<Array<TenancyPartner>>;
  /** @deprecated Field no longer supported */
  portalUsers?: Maybe<PortalUserResults>;
  portalUsersList?: Maybe<PortalUsersList>;
  practices: Array<Practice>;
  practicesByFilter: Array<Practice>;
  practicesByIds: Array<Maybe<Practice>>;
  prescription: Prescription;
  prescriptionAudit?: Maybe<Array<Maybe<PrescriptionAudit>>>;
  prescriptionFaxStatus?: Maybe<Array<Maybe<PrescriptionFaxStatus>>>;
  prescriptionFetchFailed?: Maybe<Prescriptions>;
  prescriptionRegions?: Maybe<Array<Maybe<PrescriptionRegion>>>;
  prescriptionStatistics?: Maybe<PrescriptionStatistics>;
  prescriptions?: Maybe<Prescriptions>;
  privacyApps?: Maybe<Array<PrivacyApp>>;
  privacyNotice?: Maybe<PrivacyNotice>;
  profession?: Maybe<Profession>;
  professions: Array<Profession>;
  profile: Profile;
  redeemedCodes: Array<Maybe<RedeemedCode>>;
  referralSearch?: Maybe<Array<Maybe<ReferralResource>>>;
  region: Region;
  regionByCode: Region;
  regionStates?: Maybe<Array<Maybe<State>>>;
  regions: Array<Region>;
  relationships: Array<Relationship>;
  repeatTemplates?: Maybe<RepeatTemplateResults>;
  reportQuery: Array<Maybe<ReportQuery>>;
  rootAndTopLevelTenancyPartners?: Maybe<TenancyPartnersList>;
  searchClinicalCodes: Array<Maybe<ClinicalCode>>;
  searchClinicalKnowledgeEntities: Array<ClinicalKnowledgeEntity>;
  searchClinicalTerminologyEntities: ClinicalTerminologySearchEntity;
  searchCodingAll: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingAllergies: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingConditions: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingDiagnosis: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchCodingMedications: Array<Maybe<ClinicalKnowledgeLabel>>;
  searchFastCTConcepts: SearchFastCtConceptsEntity;
  searchLeaflets?: Maybe<Array<Maybe<Leaflet>>>;
  searchPatients?: Maybe<MopSearchPatients>;
  searchPointOfCareEntity: SearchPointOfCareEntity;
  serviceType?: Maybe<ServiceType>;
  serviceTypeCategories?: Maybe<Array<Maybe<ServiceTypeCategory>>>;
  serviceTypes: Array<ServiceType>;
  serviceTypesForConfiguration?: Maybe<Array<Maybe<ServiceTypeForConfig>>>;
  signupNotices?: Maybe<SignUpNotices>;
  specialismCategories: Array<Maybe<SpecialismCategory>>;
  specialist?: Maybe<Specialist>;
  specialists: Array<Maybe<Specialist>>;
  specialistsForPatient: Array<Maybe<Specialist>>;
  supplyNetworks?: Maybe<Array<SupplyNetwork>>;
  supportInteraction?: Maybe<SupportInteraction>;
  tags: Array<Maybe<Tag>>;
  tenancyPartner?: Maybe<TenancyPartner>;
  tenancyPartners?: Maybe<TenancyPartnersList>;
  tenancyPartnersFlatList: Array<TenancyPartner>;
  tenancyTypeLabels: Array<Maybe<Scalars['String']>>;
  therapists: Array<Maybe<Specialist>>;
  translateCode: CodeTranslation;
  translationLanguages?: Maybe<TranslationLanguages>;
  user?: Maybe<Patient>;
  userAppointments: Array<Maybe<Appointment>>;
  userConsumerNetworks: Array<Maybe<ConsumerNetwork>>;
  validateConcept: ValidationResult;
  viewer: Viewer;
  webPlatformAccessDecide?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
  workflowDefinitions?: Maybe<Array<Maybe<WorkflowDefinition>>>;
  workflowSubSpecialties?: Maybe<Array<Maybe<Scalars['String']>>>;
  workflowSubTypes?: Maybe<Array<Maybe<WorkflowSubType>>>;
  workflowTasks: Array<Maybe<WorkflowTask>>;
  workflows: Array<Maybe<Workflow>>;
  workflowsV2: WorkflowsResponse;
};


export type QueryActivityLogArgs = {
  patientId: Scalars['ID'];
};


export type QueryAdminActiveIngredientArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAdminActiveIngredientsArgs = {
  query: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAdminDrugArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAdminDrugAlertArgs = {
  id: Scalars['ID'];
};


export type QueryAdminDrugAlertsArgs = {
  query: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAdminDrugAuditsArgs = {
  auditableId: Scalars['ID'];
};


export type QueryAdminDrugsArgs = {
  query?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryAllergiesArgs = {
  id: Scalars['Int'];
};


export type QueryAppNoticeVersionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAppNoticesArgs = {
  app?: Maybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['Int'];
};


export type QueryAppointmentAvailabilitySlotsArgs = {
  filter?: Maybe<AppointmentAvailabilitySlotsFilter>;
};


export type QueryAppointmentCliniciansArgs = {
  consultantType?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  serviceTypeUuid?: Maybe<Scalars['ID']>;
};


export type QueryAppointmentClinicsArgs = {
  consultantType?: Maybe<Scalars['String']>;
  consultant?: Maybe<Scalars['ID']>;
  consultationType?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  serviceTypeUuid?: Maybe<Scalars['ID']>;
};


export type QueryAppointmentConfigurationNameExistsArgs = {
  configurationName: Scalars['String'];
};


export type QueryAppointmentConfigurationsArgs = {
  contractIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAppointmentCreditArgs = {
  patientId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryAppointmentCreditsArgs = {
  patientId?: Maybe<Scalars['String']>;
};


export type QueryAppointmentInviteArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryAppointmentInvitesArgs = {
  filter?: Maybe<AppointmentInviteFilter>;
};


export type QueryAppointmentSlotsArgs = {
  request?: Maybe<AppointmentSlotsRequest>;
};


export type QueryAppointmentSlotsV2Args = {
  request?: Maybe<AppointmentSlotsRequest>;
};


export type QueryAppointmentV2Args = {
  id: Scalars['Int'];
};


export type QueryAppointmentWaitTimeConfigsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  consumerNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceTypeUuids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAppointmentWaitTimesArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  consumerNetworkIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceTypeUuids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAvailabilityArgs = {
  input?: Maybe<ClinicalQueryInput>;
};


export type QueryAvailabilitySlotsArgs = {
  request: AvailabilitySlotsRequest;
};


export type QueryAvailableConsultantsArgs = {
  memberId?: Maybe<Scalars['ID']>;
  consultantType?: Maybe<Scalars['String']>;
  consultationType?: Maybe<Scalars['String']>;
};


export type QueryAvailablePharmacySuppliersArgs = {
  prescriptionId: Scalars['ID'];
  postcode?: Maybe<Scalars['String']>;
};


export type QueryCarePlanArgs = {
  patientId: Scalars['ID'];
};


export type QueryCarePlanDraftArgs = {
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
};


export type QueryCarePlanPublishedInConsultationArgs = {
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
};


export type QueryCarersArgs = {
  id: Scalars['String'];
};


export type QueryChatscriptConversationArgs = {
  userId?: Maybe<Scalars['ID']>;
  conversationId?: Maybe<Scalars['ID']>;
};


export type QueryChatscriptConversationsArgs = {
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  outcome?: Maybe<ChatscriptConversationOutcome>;
  status?: Maybe<ChatscriptConversationStatus>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  resultTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  covidOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryChatscriptMessagesArgs = {
  userId?: Maybe<Scalars['ID']>;
  conversationId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryClinicalKnowledgeEntityArgs = {
  iri: Scalars['String'];
  view?: Maybe<Scalars['String']>;
};


export type QueryClinicalUserInformationArgs = {
  id: Scalars['Int'];
};


export type QueryClinicianAvailabilityShiftsArgs = {
  consultantId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryClinicianServiceTypesArgs = {
  clinician_ids: Scalars['ID'];
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  patient_id?: Maybe<Scalars['ID']>;
  pageIndex?: Maybe<Scalars['Int']>;
};


export type QueryConditionsArgs = {
  id: Scalars['Int'];
};


export type QueryConfigurationDetailsArgs = {
  serviceTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  configurationId?: Maybe<Scalars['String']>;
};


export type QueryConsultantArgs = {
  id: Scalars['ID'];
};


export type QueryConsultantMetricsArgs = {
  id: Scalars['ID'];
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};


export type QueryConsultantRtmStatusArgs = {
  consultantId: Scalars['ID'];
};


export type QueryConsultantTypesArgs = {
  memberId: Scalars['ID'];
};


export type QueryConsultationArgs = {
  id: Scalars['ID'];
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryConsultationByAppointmentIdArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryConsultationHistoryArgs = {
  patientId: Scalars['ID'];
};


export type QueryConsultationNoteArgs = {
  consultationId: Scalars['ID'];
};


export type QueryConsultationPermissionsArgs = {
  id: Scalars['ID'];
};


export type QueryConsultationSickNoteArgs = {
  consultationId: Scalars['ID'];
};


export type QueryConsultationTypesArgs = {
  memberId: Scalars['ID'];
};


export type QueryConsultationWorkflowsV2Args = {
  locale: Scalars['String'];
  tags: Scalars['String'];
  consumerNetwork: Scalars['ID'];
  consultationId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryConsultationsArgs = {
  consultations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  consultants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  patients?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};


export type QueryConsultationsv2Args = {
  searchCriteria?: Maybe<ConsultationSearchCriteria>;
  sortCriteria?: Maybe<ConsultationSortCriteria>;
  paginationCriteria?: Maybe<PaginationCriteria>;
};


export type QueryConsumerNetworkAppointmentCategoriesArgs = {
  consumerNetworkUuid: Scalars['ID'];
  appointmentFeatures?: Maybe<Array<AppointmentFeaturesParams>>;
};


export type QueryConsumerNetworkProfessionsArgs = {
  id: Scalars['ID'];
};


export type QueryConsumerNetworkServiceTypesArgs = {
  consumerNetworkId: Scalars['ID'];
};


export type QueryContractArgs = {
  id: Scalars['ID'];
};


export type QueryCoverageEligibilityListArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sort: Scalars['String'];
  query: Scalars['String'];
  partnerId: Scalars['String'];
};


export type QueryCoverageEligibilityReadArgs = {
  id: Scalars['ID'];
  partnerId: Scalars['String'];
};


export type QueryCp3ClinicalCodeWeightingsArgs = {
  countryCode: Scalars['String'];
};


export type QueryCp3LinksAndResourcesArgs = {
  countryCode: Scalars['String'];
};


export type QueryCp3ReleaseNotesArgs = {
  countryCode: Scalars['String'];
};


export type QueryDrugArgs = {
  id: Scalars['ID'];
  region?: Maybe<Scalars['String']>;
};


export type QueryDrugDosagesArgs = {
  regionId: Scalars['String'];
};


export type QueryDrugsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
};


export type QueryEncryptedSearchPatientsArgs = {
  encryptedSearchCriteria?: Maybe<EncryptedSearchCriteria>;
};


export type QueryExtensionalValueSetArgs = {
  id: Scalars['String'];
  system: Scalars['String'];
  codeAndDisplayList: Array<Maybe<CodeAndDisplayInput>>;
};


export type QueryFamilyAccountArgs = {
  uuid: Scalars['ID'];
};


export type QueryFeatureStatesArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
  scope: FeatureScopeInput;
};


export type QueryFeatureSwitchesArgs = {
  patientId: Scalars['Int'];
};


export type QueryFhirProfileArgs = {
  id: Scalars['ID'];
};


export type QueryFhirProfileExampleArgs = {
  id: Scalars['ID'];
};


export type QueryFhirValueSetArgs = {
  id: Scalars['String'];
};


export type QueryFindConsumerNetworksArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryFlagsArgs = {
  appointmentId?: Maybe<Scalars['Int']>;
};


export type QueryGetAllAppointmentConfigurationsArgs = {
  contractIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetAppointmentCheckInArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetAppointmentConfigurationByIdArgs = {
  configurationId: Scalars['String'];
};


export type QueryGetAppointmentConfigurationByNameArgs = {
  configurationName: Scalars['String'];
};


export type QueryGetCallEndedCallFlowStepsArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetCallFlowStepsArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetClinicalNotesArgs = {
  iris: Array<Maybe<ClinicalCodeInput>>;
  resolveDrugs?: Maybe<Scalars['Boolean']>;
};


export type QueryGetClinicalTerminologyAttributeArgs = {
  iri: Scalars['String'];
};


export type QueryGetClinicalTerminologyAttributesArgs = {
  subject?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
};


export type QueryGetClinicalTerminologyEntityArgs = {
  iri: Scalars['String'];
  contributionId?: Maybe<Scalars['String']>;
};


export type QueryGetClinicalTerminologyEventsArgs = {
  iri: Scalars['String'];
};


export type QueryGetClinicalTerminologyLabelsArgs = {
  iri: Scalars['String'];
};


export type QueryGetClinicianVisibilityArgs = {
  clinician_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clinic_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  service_type_uuid?: Maybe<Array<Maybe<Scalars['ID']>>>;
  profession?: Maybe<Array<Maybe<Scalars['String']>>>;
  tenancy_plan_id?: Maybe<Array<Maybe<Scalars['ID']>>>;
  slot_time_from: Scalars['String'];
  slot_time_to: Scalars['String'];
};


export type QueryGetConceptsArgs = {
  consultationId: Scalars['ID'];
};


export type QueryGetConsultantsArgs = {
  filters: ConsultantSearchFilters;
};


export type QueryGetConsultantsPerIdArgs = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryGetContributionArgs = {
  contributionId: Scalars['String'];
};


export type QueryGetContributionsByUserArgs = {
  author?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetDevicesArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetEhrPatientArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetEligibleConsultantsArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetFhirUsageArgs = {
  iri: Scalars['String'];
};


export type QueryGetMembershipPlansArgs = {
  member_uuid: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetPanelArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetPanelsArgs = {
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Scalars['String']>;
};


export type QueryGetPatientConsultationsArgs = {
  patientUuid: Scalars['ID'];
  dateRange?: Maybe<DateRange>;
  status?: Maybe<ConsultationStatus>;
};


export type QueryGetPatientReferralsArgs = {
  id: Scalars['ID'];
};


export type QueryGetPatientSubscriptionsArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetRawCallFlowStepsArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryGetReportArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetReportsArgs = {
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Scalars['String']>;
};


export type QueryGetSickNotesArgs = {
  patientId: Scalars['ID'];
};


export type QueryGetTenancyRegionArgs = {
  id: Scalars['ID'];
};


export type QueryGetTimelineEventsByPatientArgs = {
  patientId: Scalars['ID'];
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  pagination?: Maybe<PaginationCriteria>;
};


export type QueryGetTransactionsArgs = {
  patientId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGpUserInformationArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryHealthCheckAssessmentStageArgs = {
  locale: Scalars['String'];
  memberId: Scalars['ID'];
  includeDataPointCompleteness?: Maybe<Scalars['String']>;
};


export type QueryHealthCheckReportArgs = {
  userId: Scalars['ID'];
};


export type QueryHealthCheckSummaryArgs = {
  userId: Scalars['ID'];
};


export type QueryHumanitySyncShiftItemsArgs = {
  id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  filters?: Maybe<HumanitySyncShiftItemFilters>;
};


export type QueryHumanitySyncShiftsArgs = {
  id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryHumanitySyncStatusArgs = {
  id: Scalars['ID'];
};


export type QueryHumanitySyncsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryIdVerificationArgs = {
  referer: Scalars['String'];
};


export type QueryIdentifyArgs = {
  id: Scalars['Int'];
};


export type QueryIncompleteConsultationsInfoArgs = {
  consultantId: Scalars['ID'];
  timezoneOffset?: Maybe<Scalars['Int']>;
};


export type QueryIntensionalValueSetArgs = {
  id: Scalars['String'];
  system: Scalars['String'];
  code: Scalars['String'];
};


export type QueryIsNewValueSetIdArgs = {
  id: Scalars['String'];
};


export type QueryListTenancyPartnersArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryMedicalHistoryArgs = {
  patient_id: Scalars['Int'];
};


export type QueryMedicationsArgs = {
  id: Scalars['Int'];
};


export type QueryMemberDecisionForNoticeArgs = {
  input: MemberDecisionForNoticeInput;
};


export type QueryMemberTaskArgs = {
  filter: MemberTaskFilter;
};


export type QueryMemberTasksArgs = {
  filter: MemberTasksFilter;
};


export type QueryMemberThreadArgs = {
  filter: MemberThreadFilter;
};


export type QueryMemberThreadsArgs = {
  filter: MemberThreadsFilter;
};


export type QueryMonthlyCalendarScheduleArgs = {
  consultantId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
};


export type QueryMonthlyCalendarScheduleV2Args = {
  consultantId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};


export type QueryMultimediaFeedbackQuestionArgs = {
  client_type: MultimediaFeedbackClientType;
  consultation_id: Scalars['ID'];
};


export type QueryMultimediaFeedbackQuestionTempArgs = {
  client_type: MultimediaFeedbackClientType;
  consultation_id: Scalars['ID'];
};


export type QueryNewAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryNoteAssistantConsentArgs = {
  id: Scalars['ID'];
  encounterType?: Maybe<Scalars['String']>;
  speakers: Array<NoteAssistantSpeaker>;
};


export type QueryNoteAssistantNotesArgs = {
  id: Scalars['ID'];
};


export type QueryNoteAssistantRecordingIdArgs = {
  input: NoteAssistantRecordingIdInput;
};


export type QueryOrphanedSlotsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPartnerPlanDefaultsArgs = {
  id: Scalars['ID'];
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryPatientConsumerNetworksArgs = {
  id: Scalars['ID'];
};


export type QueryPatient_AlertArgs = {
  patientId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type QueryPatient_AlertsArgs = {
  patientId: Scalars['ID'];
  sortOptions?: Maybe<PatientAlertsSortOptions>;
};


export type QueryPatientsArgs = {
  searchCriteria?: Maybe<PatientSearchCriteria>;
};


export type QueryPatientsByIdsArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type QueryPgmReportArgs = {
  reportId: Scalars['ID'];
  appName?: Maybe<Scalars['String']>;
};


export type QueryPgmReportLegacyArgs = {
  reportId: Scalars['ID'];
  appName?: Maybe<Scalars['String']>;
};


export type QueryPgmReportSummaryArgs = {
  reportId: Scalars['ID'];
  appName?: Maybe<Scalars['String']>;
};


export type QueryPharmacyPrescriptionsArgs = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  states?: Maybe<Scalars['String']>;
};


export type QueryPlacesSearchArgs = {
  input: PlaceResourceInput;
};


export type QueryPortalUserArgs = {
  id: Scalars['ID'];
};


export type QueryPortalUserPartnersArgs = {
  id: Scalars['ID'];
};


export type QueryPortalUsersArgs = {
  paginationCriteria?: Maybe<PaginationCriteria>;
  sortCriteria?: Maybe<PortalUserSortCriteria>;
};


export type QueryPortalUsersListArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sort: Scalars['String'];
  query: Scalars['String'];
};


export type QueryPracticesArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryPracticesByFilterArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryPracticesByIdsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryPrescriptionArgs = {
  id: Scalars['ID'];
};


export type QueryPrescriptionAuditArgs = {
  prescriptionId: Scalars['ID'];
  dateAfter?: Maybe<Scalars['String']>;
  dateBefore?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
};


export type QueryPrescriptionFaxStatusArgs = {
  prescriptionId: Scalars['ID'];
};


export type QueryPrescriptionFetchFailedArgs = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};


export type QueryPrescriptionStatisticsArgs = {
  consultantId?: Maybe<Scalars['ID']>;
};


export type QueryPrescriptionsArgs = {
  consultantId?: Maybe<Scalars['ID']>;
  consultationId?: Maybe<Scalars['ID']>;
  prescriptionId?: Maybe<Scalars['ID']>;
  patientId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  signatureState?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pharmacyDetails?: Maybe<Scalars['String']>;
  consultantName?: Maybe<Scalars['String']>;
};


export type QueryPrivacyNoticeArgs = {
  notice_id: Scalars['ID'];
};


export type QueryProfessionArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryProfileArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryRedeemedCodesArgs = {
  patientId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReferralSearchArgs = {
  input?: Maybe<ReferralResourceInput>;
};


export type QueryRegionArgs = {
  id: Scalars['Int'];
};


export type QueryRegionByCodeArgs = {
  code: Scalars['String'];
};


export type QueryRegionStatesArgs = {
  regionIso: Scalars['String'];
};


export type QueryRelationshipsArgs = {
  id: Scalars['String'];
};


export type QueryRepeatTemplatesArgs = {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  reviewDateMin?: Maybe<Scalars['Date']>;
  reviewDateMax?: Maybe<Scalars['Date']>;
  startDateMin?: Maybe<Scalars['Date']>;
  startDateMax?: Maybe<Scalars['Date']>;
  states?: Maybe<Array<Maybe<RepeatTemplateState>>>;
  lastChangeDateMin?: Maybe<Scalars['Date']>;
  lastChangeDateMax?: Maybe<Scalars['Date']>;
  userStates?: Maybe<Array<Maybe<UserTemplateState>>>;
  userStatesFilter?: Maybe<Array<Maybe<UserStateFilter>>>;
};


export type QueryReportQueryArgs = {
  type: Scalars['String'];
};


export type QueryRootAndTopLevelTenancyPartnersArgs = {
  ids: Array<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchClinicalCodesArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QuerySearchClinicalKnowledgeEntitiesArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type QuerySearchClinicalTerminologyEntitiesArgs = {
  system: CtSearchSystemType;
  query: Scalars['String'];
  semanticTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  pageCursor?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingAllArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingAllergiesArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingConditionsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingDiagnosisArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};


export type QuerySearchCodingMedicationsArgs = {
  query: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


export type QuerySearchFastCtConceptsArgs = {
  language?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  semanticTags?: Maybe<Scalars['String']>;
  term: Scalars['String'];
};


export type QuerySearchLeafletsArgs = {
  text: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};


export type QuerySearchPatientsArgs = {
  searchCriteria?: Maybe<MopPatientSearchCriteria>;
};


export type QuerySearchPointOfCareEntityArgs = {
  index?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  useCases?: Maybe<Scalars['String']>;
  term: Scalars['String'];
};


export type QueryServiceTypeArgs = {
  uuid: Scalars['ID'];
};


export type QueryServiceTypesArgs = {
  include?: Maybe<Array<Scalars['String']>>;
  member_uuid?: Maybe<Scalars['ID']>;
  including_digital?: Maybe<Scalars['Boolean']>;
};


export type QueryServiceTypesForConfigurationArgs = {
  serviceTypeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySpecialismCategoriesArgs = {
  id: Scalars['ID'];
};


export type QuerySpecialistArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySpecialistsForPatientArgs = {
  patientId?: Maybe<Scalars['ID']>;
};


export type QuerySupportInteractionArgs = {
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
};


export type QueryTenancyPartnerArgs = {
  id: Scalars['ID'];
  depth?: Maybe<Scalars['String']>;
};


export type QueryTenancyPartnersArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTenancyPartnersFlatListArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryTranslateCodeArgs = {
  code: Scalars['String'];
  system: Scalars['String'];
  targetsystem: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserAppointmentsArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserConsumerNetworksArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryValidateConceptArgs = {
  semanticTag?: Maybe<Scalars['String']>;
  preferredLabel?: Maybe<Scalars['String']>;
};


export type QueryWebPlatformAccessDecideArgs = {
  resources: Array<Maybe<WebPlatformAccessResourceInput>>;
};


export type QueryWorkflowDefinitionArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryWorkflowDefinitionsArgs = {
  tenantId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryWorkflowSubSpecialtiesArgs = {
  type: WorkflowType;
  subType: Scalars['String'];
};


export type QueryWorkflowSubTypesArgs = {
  type: WorkflowType;
};


export type QueryWorkflowTasksArgs = {
  memberId?: Maybe<Scalars['ID']>;
  searchCriteria?: Maybe<WorkflowSearchCriteria>;
};


export type QueryWorkflowsArgs = {
  consultationId: Scalars['ID'];
};


export type QueryWorkflowsV2Args = {
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<WorkflowState>;
  tenant_id?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type Read2Code = {
  __typename?: 'Read2Code';
  code?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type ReallocatedAppointments = {
  __typename?: 'ReallocatedAppointments';
  orphan_appointments: Array<Maybe<Scalars['Int']>>;
};

export type ReallocateShiftFilters = {
  from_clinician_id: Scalars['Int'];
  to_clinician_id: Scalars['Int'];
  start_date_time: Scalars['Date'];
  end_date_time: Scalars['Date'];
  slots?: Maybe<Array<Maybe<SlotAppointmentMap>>>;
};

export type Reason = {
  __typename?: 'Reason';
  id?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
};

export type Receipt = {
  __typename?: 'Receipt';
  pdf_path?: Maybe<Scalars['String']>;
  appointment?: Maybe<Appointment>;
  patient?: Maybe<Patient>;
  consultant?: Maybe<Consultant>;
  payment?: Maybe<Payment>;
  provider?: Maybe<PaymentProvider>;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  frequency?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export enum RecurrenceFrequency {
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY'
}

export type RecurrenceInput = {
  frequency?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type RecurrencePatterns = {
  __typename?: 'RecurrencePatterns';
  frequency: RecurrenceFrequency;
  interval: Scalars['Int'];
  max_sessions: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
};

export type RecurrenceSettings = {
  __typename?: 'RecurrenceSettings';
  recurrence_patterns: Array<RecurrencePatterns>;
};

export type RedeemedCode = {
  __typename?: 'RedeemedCode';
  activated_at?: Maybe<Scalars['Date']>;
  appointments_booked?: Maybe<Scalars['String']>;
  appointments_left?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_reactivatable: Scalars['Boolean'];
  plan_title?: Maybe<Scalars['String']>;
  promotion_name?: Maybe<Scalars['String']>;
  promotion_type?: Maybe<Scalars['String']>;
  redemption_end_date?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
};

export type RedemptionsAttributes = {
  patient_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  redemption_type?: Maybe<Scalars['String']>;
};

export enum RedemptionType {
  Promotion = 'promotion',
  Membership = 'membership'
}

export type Referral = {
  __typename?: 'Referral';
  id?: Maybe<Scalars['ID']>;
  appointment?: Maybe<Appointment>;
  referral_pdf?: Maybe<Scalars['String']>;
  specialism_category?: Maybe<SpecialismCategory>;
  specialism?: Maybe<Specialism>;
  urgent?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  formatted_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ReferralComment = {
  __typename?: 'ReferralComment';
  id?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['ID']>;
  viewer?: Maybe<Viewer>;
};

export type ReferralCommentsInput = {
  note?: Maybe<Scalars['String']>;
};

export type ReferralExternalSpecialist = {
  __typename?: 'ReferralExternalSpecialist';
  id?: Maybe<Scalars['ID']>;
  externalReference?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReferralExternalSpecialistInput = {
  externalReference?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReferralFax = {
  __typename?: 'ReferralFax';
  id?: Maybe<Scalars['ID']>;
  destinationNumber?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
};

export type ReferralFaxInput = {
  destinationNumber?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
};

export type ReferralLocation = {
  __typename?: 'ReferralLocation';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<ReferralLocationAddress>;
};

export type ReferralLocationAddress = {
  __typename?: 'ReferralLocationAddress';
  line?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type ReferralPractitioner = {
  __typename?: 'ReferralPractitioner';
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
};

export type ReferralResource = {
  __typename?: 'ReferralResource';
  practitioner?: Maybe<ReferralPractitioner>;
  specialty?: Maybe<Array<Maybe<ReferralSpecialty>>>;
  location?: Maybe<Array<Maybe<ReferralLocation>>>;
  telecom?: Maybe<Array<Maybe<ReferralTelecom>>>;
};

export type ReferralResourceCodingInput = {
  system?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type ReferralResourceInput = {
  practitioner?: Maybe<ReferralResourcePractitionerInput>;
  location?: Maybe<ReferralResourceLocationInput>;
  coding?: Maybe<ReferralResourceCodingInput>;
};

export type ReferralResourceLocationInput = {
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type ReferralResourcePractitionerInput = {
  language?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ReferralSpecialty = {
  __typename?: 'ReferralSpecialty';
  coding?: Maybe<Array<Maybe<ReferralSpecialtyCoding>>>;
};

export type ReferralSpecialtyCoding = {
  __typename?: 'ReferralSpecialtyCoding';
  system?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type ReferralTelecom = {
  __typename?: 'ReferralTelecom';
  system?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  use?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
};

export type Region = {
  __typename?: 'Region';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  age_of_consent?: Maybe<Scalars['Int']>;
  iso_code?: Maybe<Scalars['String']>;
  appointment_one_off_cost?: Maybe<Scalars['String']>;
  appointment_specialist_cost?: Maybe<Scalars['String']>;
  free_subscription?: Maybe<Scalars['Boolean']>;
  use_babylon_practice?: Maybe<Scalars['Boolean']>;
  show_gp_consent?: Maybe<Scalars['Boolean']>;
  enable_voice_calls?: Maybe<Scalars['Boolean']>;
  enable_video_calls?: Maybe<Scalars['Boolean']>;
  allow_select_gp?: Maybe<Scalars['Boolean']>;
  enable_monitor_me?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Int']>;
  support_number?: Maybe<Scalars['String']>;
  support_email?: Maybe<Scalars['String']>;
  default_phone_country_code?: Maybe<Scalars['String']>;
  doctor_medical_identifier_label?: Maybe<Scalars['String']>;
  nurse_medical_identifier_label?: Maybe<Scalars['String']>;
  merchant_account_id?: Maybe<Scalars['String']>;
  show_in_app?: Maybe<Scalars['Boolean']>;
  twilio_phone?: Maybe<Scalars['String']>;
  sms_number?: Maybe<Scalars['String']>;
  inhibit_warnings?: Maybe<Scalars['Boolean']>;
  enable_apple_pay?: Maybe<Scalars['Boolean']>;
  default_consumer_network?: Maybe<Partner>;
  sick_notes?: Maybe<Scalars['Boolean']>;
  uses_postcode?: Maybe<Scalars['Boolean']>;
  enable_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  show_family_accounts?: Maybe<Scalars['Boolean']>;
  show_monitor?: Maybe<Scalars['Boolean']>;
  show_kit?: Maybe<Scalars['Boolean']>;
  show_pharmacy?: Maybe<Scalars['Boolean']>;
  show_membership_codes?: Maybe<Scalars['Boolean']>;
  show_insurance?: Maybe<Scalars['Boolean']>;
  enable_home_delivery?: Maybe<Scalars['Boolean']>;
  enable_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_home_drug_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_pharmacy_delivery?: Maybe<Scalars['Boolean']>;
  enable_restricted_collection_with_code?: Maybe<Scalars['Boolean']>;
  enable_consultant_booking_on_behalf_of_patient?: Maybe<Scalars['Boolean']>;
  triage_nurse_medical_identifier_label?: Maybe<Scalars['String']>;
  appointment_timeout_seconds?: Maybe<Scalars['Int']>;
  show_ask?: Maybe<Scalars['Boolean']>;
  show_check?: Maybe<Scalars['Boolean']>;
  show_gp_details?: Maybe<Scalars['Boolean']>;
  show_questions?: Maybe<Scalars['Boolean']>;
  senior_nurse_medical_identifier_label?: Maybe<Scalars['String']>;
  senior_nurse_can_prescribe?: Maybe<Scalars['Boolean']>;
  enable_sms_notifications?: Maybe<Scalars['Boolean']>;
  enable_referral_code?: Maybe<Scalars['Boolean']>;
  sms_provider?: Maybe<Scalars['String']>;
  voice_call_provider?: Maybe<Scalars['String']>;
  nida?: Maybe<Scalars['Boolean']>;
  video_provider?: Maybe<Scalars['String']>;
  enable_clinical_coding?: Maybe<Scalars['Boolean']>;
  public_healthcare_registration_text?: Maybe<Scalars['String']>;
  public_healthcare_registration_subtext?: Maybe<Scalars['String']>;
  public_healthcare_registration_help_url?: Maybe<Scalars['String']>;
};

export type RegionInput = {
  iso_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Relationship = {
  __typename?: 'Relationship';
  id: Scalars['ID'];
  dependant: Scalars['String'];
  type: Scalars['String'];
  profile: Profile;
};

export type RelationshipIris = {
  subject: Scalars['String'];
  attribute: Scalars['String'];
  object: Scalars['String'];
};

export type RepeatIssue = {
  __typename?: 'RepeatIssue';
  id: Scalars['ID'];
  durationDays?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  issuedAt?: Maybe<Scalars['Date']>;
  repeatsIssued?: Maybe<Scalars['Int']>;
  sourceSystem?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  template?: Maybe<RepeatTemplate>;
};

export type RepeatRequest = {
  __typename?: 'RepeatRequest';
  id: Scalars['ID'];
  daysEarly?: Maybe<Scalars['Int']>;
  denialReason?: Maybe<Scalars['String']>;
  patientReason?: Maybe<Scalars['String']>;
  repeatsRequested?: Maybe<Scalars['Int']>;
  state?: Maybe<RepeatRequestState>;
  requestedAt?: Maybe<Scalars['Date']>;
  template?: Maybe<RepeatTemplate>;
};

export enum RepeatRequestState {
  Requested = 'REQUESTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Issued = 'ISSUED'
}

export type RepeatTemplate = Paging & {
  __typename?: 'RepeatTemplate';
  id: Scalars['ID'];
  patient: Patient;
  daysToReorder?: Maybe<Scalars['Int']>;
  drugId?: Maybe<Scalars['String']>;
  drugName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  reviewDate?: Maybe<Scalars['Date']>;
  lastAvailabilityDateTime?: Maybe<Scalars['Date']>;
  lastUserChange?: Maybe<Scalars['Date']>;
  state?: Maybe<RepeatTemplateState>;
  latestRequest?: Maybe<RepeatRequest>;
  latestIssue?: Maybe<RepeatIssue>;
  latestPharmacy?: Maybe<RepeatTemplatePharmacy>;
  userState: UserTemplateState;
};

export type RepeatTemplatePharmacy = {
  __typename?: 'RepeatTemplatePharmacy';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<PharmacyAddress>;
};

export type RepeatTemplateResults = Pagination & {
  __typename?: 'RepeatTemplateResults';
  pageIndex?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<RepeatTemplate>>;
};

export enum RepeatTemplateState {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  PendingOnGp = 'PENDING_ON_GP',
  PendingOnPrescribingPharmacist = 'PENDING_ON_PRESCRIBING_PHARMACIST',
  Archived = 'ARCHIVED',
  Error = 'ERROR',
  Stopped = 'STOPPED'
}

export type ReportQuery = {
  __typename?: 'ReportQuery';
  key?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type RequestSyncShiftsReportInput = {
  syncId: Scalars['ID'];
};

export type RetrievalError = Error & {
  __typename?: 'RetrievalError';
  message: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  appointment?: Maybe<Appointment>;
  appointment_id?: Maybe<Scalars['Int']>;
  reason_id?: Maybe<Scalars['Int']>;
};

export type ReviewContributionResponse = {
  __typename?: 'ReviewContributionResponse';
  status: Scalars['String'];
};

export type RevisionAlreadyPublishedInConsultationError = {
  __typename?: 'RevisionAlreadyPublishedInConsultationError';
  carePlanRevisionId: Scalars['ID'];
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SaveServiceTypeInput = {
  uuid: Scalars['ID'];
  name: Scalars['String'];
  member_facing_name?: Maybe<Scalars['String']>;
  default_duration_minutes?: Maybe<Scalars['Int']>;
  intimate?: Maybe<Scalars['Boolean']>;
  member_description?: Maybe<Scalars['String']>;
  member_instructions?: Maybe<Scalars['String']>;
  us_ehr_appointment_type?: Maybe<Scalars['String']>;
  grouping?: Maybe<Scalars['String']>;
  common?: Maybe<Scalars['Boolean']>;
  allowed_mediums?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred_medium?: Maybe<Scalars['String']>;
  service_type_categories?: Maybe<Array<Maybe<ServiceTypeCategoryInput>>>;
};

export type ScheduleDateInfo = {
  __typename?: 'ScheduleDateInfo';
  date?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Int']>;
};

export type SearchFastCtConceptEntity = {
  __typename?: 'SearchFastCTConceptEntity';
  value: Scalars['String'];
  lang: Scalars['String'];
};

export type SearchFastCtConcepts = {
  __typename?: 'SearchFastCTConcepts';
  iri: Scalars['String'];
  semanticTag: Array<Maybe<SearchFastCtConceptEntity>>;
  prefLabel: Array<Maybe<SearchFastCtConceptEntity>>;
  altLabel: Array<Maybe<SearchFastCtConceptEntity>>;
};

export type SearchFastCtConceptsEntity = {
  __typename?: 'SearchFastCTConceptsEntity';
  concepts: Array<Maybe<SearchFastCtConcepts>>;
  pageCursor: Scalars['Int'];
  total: Scalars['Int'];
};

export type SearchPagination = {
  __typename?: 'SearchPagination';
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type SearchPointOfCareCodeDetailsEntity = {
  __typename?: 'SearchPointOfCareCodeDetailsEntity';
  code: Scalars['String'];
  system: Scalars['String'];
  display: Scalars['String'];
};

export type SearchPointOfCareCodeEntity = {
  __typename?: 'SearchPointOfCareCodeEntity';
  code: SearchPointOfCareCodeDetailsEntity;
};

export type SearchPointOfCareEntity = {
  __typename?: 'SearchPointOfCareEntity';
  concepts: Array<Maybe<SearchPointOfCareCodeEntity>>;
  total: Scalars['Int'];
};

export type SemanticType = {
  __typename?: 'SemanticType';
  iri: Scalars['String'];
  preferredLabel: Scalars['String'];
};

export type SendPatientReferralInput = {
  id: Scalars['ID'];
  contract_uuid: Scalars['String'];
};

export type ServiceCall = {
  __typename?: 'ServiceCall';
  service?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type ServiceType = {
  __typename?: 'ServiceType';
  id?: Maybe<Scalars['ID']>;
  uuid: Scalars['ID'];
  name: Scalars['String'];
  member_facing_name?: Maybe<Scalars['String']>;
  default_duration_minutes?: Maybe<Scalars['Int']>;
  intimate?: Maybe<Scalars['Boolean']>;
  member_description?: Maybe<Scalars['String']>;
  member_instructions?: Maybe<Scalars['String']>;
  us_ehr_appointment_type?: Maybe<Scalars['String']>;
  clinicians: Array<Consultant>;
  clinics: Array<Practice>;
  consumer_networks: Array<ConsumerNetwork>;
  grouping?: Maybe<Scalars['String']>;
  first_available_slot?: Maybe<ServiceTypeFirstAvailableSlot>;
  preferred_medium: Scalars['String'];
  allowed_mediums: Array<Scalars['String']>;
  common?: Maybe<Scalars['Boolean']>;
  categories: Array<ServiceTypeCategory>;
  service_type_settings?: Maybe<ServiceTypeSettings>;
};

export type ServiceTypeAssignment = {
  id: Scalars['String'];
  minimum_wait_time_in_minute?: Maybe<Scalars['Int']>;
  refund_exclusion_time_in_minutes?: Maybe<Scalars['Int']>;
  booking_restricted?: Maybe<BookingRestrictedInput>;
};

export type ServiceTypeCategory = {
  __typename?: 'ServiceTypeCategory';
  id: Scalars['String'];
  uuid: Scalars['String'];
  name: Scalars['String'];
};

export type ServiceTypeCategoryInput = {
  uuid: Scalars['String'];
  name: Scalars['String'];
};

export type ServiceTypeFirstAvailableSlot = {
  __typename?: 'ServiceTypeFirstAvailableSlot';
  time: Scalars['String'];
  clinician_id: Scalars['ID'];
  clinic_id: Scalars['ID'];
};

export type ServiceTypeForConfig = {
  __typename?: 'ServiceTypeForConfig';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  recommended?: Maybe<Scalars['Boolean']>;
  medium?: Maybe<Array<Maybe<Scalars['String']>>>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
  minimum_wait_time_in_minute?: Maybe<Scalars['Int']>;
  refund_exclusion_time_in_minutes?: Maybe<Scalars['Int']>;
  booking_restricted?: Maybe<BookingRestricted>;
};

export type ServiceTypeForConfigCategory = {
  id: Scalars['String'];
  name: Scalars['String'];
  medium: Array<Scalars['String']>;
  min_age?: Maybe<Scalars['Int']>;
  max_age?: Maybe<Scalars['Int']>;
  recommended?: Maybe<Scalars['Boolean']>;
};

export type ServiceTypeSettings = {
  __typename?: 'ServiceTypeSettings';
  recurrence_settings?: Maybe<RecurrenceSettings>;
};

export type SessionFile = {
  __typename?: 'SessionFile';
  fileId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type ShiftsCsvDownload = {
  __typename?: 'ShiftsCsvDownload';
  reportDownloadUrl: Scalars['String'];
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  first_line?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  third_line?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
};

export type SickNote = {
  __typename?: 'SickNote';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  noteContent?: Maybe<Scalars['String']>;
  note_content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SignerTokenInput = {
  channel: Scalars['String'];
};

export type SignUpNotices = {
  __typename?: 'SignUpNotices';
  terms?: Maybe<PrivacyNotice>;
  privacyPolicy?: Maybe<PrivacyNotice>;
};

export type SlackMessage = {
  __typename?: 'SlackMessage';
  blocks: Array<SlackMessageBlock>;
};

export type SlackMessageBlock = {
  __typename?: 'SlackMessageBlock';
  type: Scalars['String'];
  fields?: Maybe<Array<SlackMessageField>>;
};

export type SlackMessageBlockInput = {
  type: Scalars['String'];
  fields?: Maybe<Array<SlackMessageFieldInput>>;
};

export type SlackMessageField = {
  __typename?: 'SlackMessageField';
  type: Scalars['String'];
  text: Scalars['String'];
};

export type SlackMessageFieldInput = {
  type: Scalars['String'];
  text: Scalars['String'];
};

export type SlackMessageInput = {
  blocks: Array<SlackMessageBlockInput>;
};

export type SlotAppointmentMap = {
  slot_id: Scalars['Int'];
  appointment_id?: Maybe<Scalars['Int']>;
};

export enum SlotMedium {
  Digital = 'digital',
  Physical = 'physical',
  DigitalOrPhysical = 'digital_or_physical'
}

export enum SmokingStatus {
  Smoker = 'smoker',
  NonSmoker = 'non_smoker',
  ExSmoker = 'ex_smoker'
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending'
}

export type Specialism = {
  __typename?: 'Specialism';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SpecialismCategory = {
  __typename?: 'SpecialismCategory';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  specialisms: Array<Maybe<Specialism>>;
  appointment?: Maybe<Appointment>;
};

export type Specialist = {
  __typename?: 'Specialist';
  id: Scalars['ID'];
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  specialism: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Subject = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};

export type Subpartners = {
  __typename?: 'Subpartners';
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<TenancyPartner>;
};

export type Subscription = {
  __typename?: 'Subscription';
  id?: Maybe<Scalars['ID']>;
  patient_id: Scalars['ID'];
  plan_id: Scalars['ID'];
  state: SubscriptionStatus;
  started_on?: Maybe<Scalars['String']>;
  ended_on?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency_symbol?: Maybe<Scalars['String']>;
  consumer_network_name?: Maybe<Scalars['String']>;
  is_preferred: Scalars['Boolean'];
  can_be_cancelled: Scalars['Boolean'];
  plan: SubscriptionPlan;
  credit_card?: Maybe<PortalCreditCard>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  id: Scalars['ID'];
  title: Scalars['String'];
  default: Scalars['Boolean'];
};

export enum SubscriptionStatus {
  Pending = 'pending',
  Active = 'active',
  Expired = 'expired',
  Cancelled = 'cancelled',
  PastDue = 'past_due'
}

export type SuggestedAnswer = {
  __typename?: 'SuggestedAnswer';
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SuggestedPrescription = {
  __typename?: 'SuggestedPrescription';
  drugId?: Maybe<Scalars['String']>;
  drugName?: Maybe<Scalars['String']>;
  strength?: Maybe<Scalars['String']>;
  dosage?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  guidance?: Maybe<Scalars['String']>;
};

export type SupplyNetwork = {
  __typename?: 'SupplyNetwork';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
};

export type SupportInteraction = {
  __typename?: 'SupportInteraction';
  transactionId: Scalars['ID'];
  transactionType: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SyncHumanityFilterItem = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SyncHumanityFilters = {
  userId?: Maybe<Scalars['ID']>;
  fromDate?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  clinicians?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
  supplyNetworks?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
  professions?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
  practices?: Maybe<Array<Maybe<SyncHumanityFilterItem>>>;
};

export type SystemCode = {
  __typename?: 'SystemCode';
  value: Scalars['String'];
  type: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  associatedAnswer: AnswerTemplate;
  highPriority: Scalars['Boolean'];
};

export type Tenancies = {
  __typename?: 'Tenancies';
  configuration_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  tenant_type?: Maybe<Scalars['String']>;
};

export type TenancyPartner = {
  __typename?: 'TenancyPartner';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  fullPath: Scalars['String'];
  parents: Array<TenancyPartner>;
  defaultChildType: Scalars['String'];
  type: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  parent?: Maybe<TenancyPartner>;
  subpartners?: Maybe<Array<Maybe<TenancyPartner>>>;
  subpartnersPaginated: Subpartners;
  plans?: Maybe<MembershipPlansPaginated>;
  contracts: ContractsPaginated;
  contractsByPlan: Array<ContractsByPlan>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  memberCount?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};


export type TenancyPartnerSubpartnersPaginatedArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type TenancyPartnerPlansArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  hasParents?: Maybe<Scalars['Boolean']>;
  regionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeSubRegions?: Maybe<Scalars['Boolean']>;
};


export type TenancyPartnerContractsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  includeSubpartners?: Maybe<Scalars['Boolean']>;
};


export type TenancyPartnerContractsByPlanArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  includeSubpartners?: Maybe<Scalars['Boolean']>;
};

export type TenancyPartnerInput = {
  id: Scalars['ID'];
  parentId: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  defaultChildType: Scalars['String'];
};

export type TenancyPartnersList = {
  __typename?: 'TenancyPartnersList';
  total: Scalars['Int'];
  totalItems: Scalars['Int'];
  items: Array<TenancyPartner>;
};

export type TenancyPartnerUpdateInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  defaultChildType?: Maybe<Scalars['String']>;
};

export type TenancyRegion = {
  __typename?: 'TenancyRegion';
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  parent?: Maybe<Region>;
  parentRegion?: Maybe<TenancyRegion>;
  country?: Maybe<TenancyRegion>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  subregions: Array<TenancyRegion>;
  level: Scalars['Int'];
  isCountry: Scalars['Boolean'];
  countryId?: Maybe<Scalars['ID']>;
  coreRubyRegion?: Maybe<Region>;
};

export type TestReport = Paging & TimelineEventInterface & {
  __typename?: 'TestReport';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  createdOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  reviewedOn?: Maybe<Scalars['String']>;
  sentOn?: Maybe<Scalars['String']>;
  appointmentSuggested?: Maybe<Scalars['Boolean']>;
  reviewerComments?: Maybe<Scalars['String']>;
  supportMessage?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  measures?: Maybe<Array<Maybe<TestReportComponent>>>;
  profiles?: Maybe<Array<Maybe<TestReportProfile>>>;
  code?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patient?: Maybe<TestReportPatient>;
  labPatient?: Maybe<TestReportPatient>;
  labName?: Maybe<Scalars['String']>;
  labNotes?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  additionalInfo?: Maybe<TestReportLabInfo>;
  sender?: Maybe<TestReportClinician>;
  reviewer?: Maybe<TestReportClinician>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export type TestReportClinician = {
  __typename?: 'TestReportClinician';
  fullName?: Maybe<Scalars['String']>;
};

export type TestReportComponent = {
  __typename?: 'TestReportComponent';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  valueNumeric?: Maybe<Scalars['Float']>;
  valuePercentage?: Maybe<Scalars['Float']>;
  valueString?: Maybe<Scalars['String']>;
  failed?: Maybe<Scalars['Boolean']>;
  failureReason?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  recognisedBiomarker?: Maybe<Scalars['Boolean']>;
  recognisedValue?: Maybe<Scalars['Boolean']>;
  ranges?: Maybe<Array<Maybe<TestReportRange>>>;
};

export type TestReportLabInfo = {
  __typename?: 'TestReportLabInfo';
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  labNotes?: Maybe<Scalars['String']>;
};

export type TestReportPatient = {
  __typename?: 'TestReportPatient';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type TestReportProfile = {
  __typename?: 'TestReportProfile';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  labNotes?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<TestReportLabInfo>;
  setId?: Maybe<Scalars['Int']>;
  reviewerComments?: Maybe<Scalars['String']>;
  biomarkers?: Maybe<Array<Maybe<TestReportComponent>>>;
};

export type TestReportProfileComment = {
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type TestReportRange = {
  __typename?: 'TestReportRange';
  lower?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
  labRange?: Maybe<Scalars['String']>;
};

export type TestReports = Pagination & {
  __typename?: 'TestReports';
  pageIndex?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  items: Array<Maybe<TestReport>>;
};

export type TestResult = {
  __typename?: 'TestResult';
  id?: Maybe<Scalars['ID']>;
};

export type TestType = {
  __typename?: 'TestType';
  id: Scalars['ID'];
  testType?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TimelineChatConversation = TimelineEventInterface & {
  __typename?: 'TimelineChatConversation';
  id: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
  concepts?: Maybe<Array<Maybe<PgmConcept>>>;
  conversation?: Maybe<Array<Maybe<PgmQuestion>>>;
  triageOutcome?: Maybe<Scalars['String']>;
};

export type TimelineEvent = Consultation | PatientQuestion | ChatscriptConversation | BloodTest | TestReport | WorkflowV2 | RepeatTemplate | TimelineChatConversation;

export type TimelineEventInterface = {
  id: Scalars['ID'];
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export enum TimelineEventType {
  Consultation = 'Consultation',
  PatientQuestion = 'PatientQuestion',
  ChatscriptConversation = 'ChatscriptConversation',
  BloodTest = 'BloodTest',
  TestReport = 'TestReport',
  WorkflowV2 = 'WorkflowV2',
  RepeatTemplate = 'RepeatTemplate',
  TimelineChatConversation = 'TimelineChatConversation'
}

export type TokenValidity = {
  __typename?: 'TokenValidity';
  status?: Maybe<Scalars['Int']>;
  expiryTime?: Maybe<Scalars['String']>;
  expiresInMs?: Maybe<Scalars['Int']>;
};

export type TranslationLanguages = {
  __typename?: 'TranslationLanguages';
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TreatmentPlan = {
  __typename?: 'TreatmentPlan';
  ageGroup?: Maybe<Scalars['String']>;
  penicillinAllergicOption?: Maybe<Scalars['Boolean']>;
  prescription?: Maybe<Array<Maybe<SuggestedPrescription>>>;
};

export type UnresolvedWarningsError = {
  __typename?: 'UnresolvedWarningsError';
  carePlanDraftId: Scalars['ID'];
  warnings: Array<DraftMetadataWarning>;
};

export type UsEhrConsultation = {
  __typename?: 'UsEhrConsultation';
  externalAppointmentId?: Maybe<Scalars['String']>;
  ehrDepartmentId?: Maybe<Scalars['String']>;
  externalEncounterId?: Maybe<Scalars['String']>;
};

export type UsEhrNote = {
  __typename?: 'UsEhrNote';
  htmlSummary: Scalars['String'];
};

export type UserRoles = {
  __typename?: 'UserRoles';
  roles: Array<Scalars['String']>;
  user: Scalars['String'];
};

export type UserStateFilter = {
  userState?: Maybe<UserTemplateState>;
  lastChangeDateMin?: Maybe<Scalars['Date']>;
  lastChangeDateMax?: Maybe<Scalars['Date']>;
};

export enum UserTemplateState {
  Collected = 'COLLECTED',
  ReadyToCollect = 'READY_TO_COLLECT',
  ReorderSoon = 'REORDER_SOON',
  ReorderNow = 'REORDER_NOW',
  CheckingReorder = 'CHECKING_REORDER',
  FollowUpNeeded = 'FOLLOW_UP_NEEDED',
  OrderNow = 'ORDER_NOW',
  Archived = 'ARCHIVED',
  Stopped = 'STOPPED'
}

export type ValidationResult = {
  __typename?: 'ValidationResult';
  isDuplicate: Scalars['Boolean'];
};

export type Validity = {
  __typename?: 'Validity';
  valid: Scalars['Boolean'];
  errorCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ValueCoding = {
  __typename?: 'ValueCoding';
  system: Scalars['String'];
  code: Scalars['String'];
};

export enum VerificationStatus {
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Stale = 'stale',
  NotSubmitted = 'not_submitted',
  New = 'new'
}

export enum VideoSessionUserTypes {
  Guest = 'GUEST',
  Patient = 'PATIENT',
  Consultant = 'CONSULTANT'
}

export type Viewer = {
  __typename?: 'Viewer';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  type: Scalars['String'];
  roles: Array<Role>;
  patient?: Maybe<Patient>;
  consultant?: Maybe<Consultant>;
  timezone?: Maybe<Scalars['String']>;
  partnerIds?: Maybe<Array<Scalars['String']>>;
  full_name?: Maybe<Scalars['String']>;
};


export enum Vote {
  Up = 'UP',
  Down = 'DOWN',
  Neutral = 'NEUTRAL'
}

export type WebPlatformAccessResourceInput = {
  resource: Scalars['String'];
  action: Scalars['String'];
};

export type Workflow = {
  __typename?: 'Workflow';
  id: Scalars['ID'];
  status?: Maybe<WorkflowStatus>;
  type?: Maybe<Scalars['String']>;
  subType?: Maybe<WorkflowSubType>;
};

export type WorkflowAddressInput = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  admin_area_1?: Maybe<Scalars['String']>;
  admin_area_2?: Maybe<Scalars['String']>;
  country_code: Scalars['String'];
  postal_code: Scalars['String'];
};

export type WorkflowAppointmentActionInput = {
  type?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
  mobility?: Maybe<Scalars['String']>;
  follow_up_date?: Maybe<Scalars['String']>;
  gp_nurse_pharmacist?: Maybe<Scalars['String']>;
};

export type WorkflowDefinition = {
  __typename?: 'WorkflowDefinition';
  id: Scalars['ID'];
  key: Scalars['String'];
  version: Scalars['String'];
  tenant_id?: Maybe<Scalars['ID']>;
  workflow_definition_name?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<WorkflowDefinitionVariable>>;
  group: WorkflowDefinitionGroup;
  roles: Array<Scalars['String']>;
};

export type WorkflowDefinitionGroup = {
  __typename?: 'WorkflowDefinitionGroup';
  type: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  assistive_texts?: Maybe<Array<WorkflowDefinitionGroupAssistiveText>>;
};

export type WorkflowDefinitionGroupAssistiveText = {
  __typename?: 'WorkflowDefinitionGroupAssistiveText';
  instruction: Scalars['String'];
};


export type WorkflowDefinitionVariable = {
  __typename?: 'WorkflowDefinitionVariable';
  name: Scalars['String'];
  type: WorkflowDefinitionVariableType;
  label: Scalars['String'];
  is_required: Scalars['Boolean'];
  metadata: Array<WorkflowDefinitionVariableMetadata>;
};

export type WorkflowDefinitionVariableMetadata = {
  __typename?: 'WorkflowDefinitionVariableMetadata';
  type: WorkflowDefinitionVariableMetadataType;
  value?: Maybe<WorkflowDefinitionVariableMetadataObject>;
};

export type WorkflowDefinitionVariableMetadataAllowedValue = {
  __typename?: 'WorkflowDefinitionVariableMetadataAllowedValue';
  values: Array<WorkflowDefinitionVariableMetadataAllowedValuesOption>;
};

export type WorkflowDefinitionVariableMetadataAllowedValuesOption = {
  __typename?: 'WorkflowDefinitionVariableMetadataAllowedValuesOption';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataAsyncCodeSearchValue = {
  __typename?: 'WorkflowDefinitionVariableMetadataAsyncCodeSearchValue';
  coding_system: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataChildAllowedValue = {
  __typename?: 'WorkflowDefinitionVariableMetadataChildAllowedValue';
  parent_variable: Scalars['String'];
  parent_mappings: Scalars['WorkflowDefinitionParentMapping'];
};

export type WorkflowDefinitionVariableMetadataDateFormat = {
  __typename?: 'WorkflowDefinitionVariableMetadataDateFormat';
  format: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataDefaultValue = {
  __typename?: 'WorkflowDefinitionVariableMetadataDefaultValue';
  default_value: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataObject = WorkflowDefinitionVariableMetadataChildAllowedValue | WorkflowDefinitionVariableMetadataAllowedValue | WorkflowDefinitionVariableMetadataAsyncCodeSearchValue | WorkflowDefinitionVariableMetadataDateFormat | WorkflowDefinitionVariableMetadataRegexValidation | WorkflowDefinitionVariableMetadataSource | WorkflowDefinitionVariableMetadataDefaultValue;

export type WorkflowDefinitionVariableMetadataPlaceholder = {
  __typename?: 'WorkflowDefinitionVariableMetadataPlaceholder';
  placeholder: Scalars['String'];
  default_value?: Maybe<Scalars['String']>;
};

export type WorkflowDefinitionVariableMetadataRegexValidation = {
  __typename?: 'WorkflowDefinitionVariableMetadataRegexValidation';
  regex: Scalars['String'];
};

export type WorkflowDefinitionVariableMetadataSource = {
  __typename?: 'WorkflowDefinitionVariableMetadataSource';
  type: WorkflowDefinitionVariableMetadataSourceType;
  source_info?: Maybe<WorkflowDefinitionVariableMetadataPlaceholder>;
};

export enum WorkflowDefinitionVariableMetadataSourceType {
  User = 'USER',
  Context = 'CONTEXT'
}

export enum WorkflowDefinitionVariableMetadataType {
  AsyncCodeSearch = 'ASYNC_CODE_SEARCH',
  AllowedValues = 'ALLOWED_VALUES',
  ChildAllowedValues = 'CHILD_ALLOWED_VALUES',
  DateFormat = 'DATE_FORMAT',
  RegexValidation = 'REGEX_VALIDATION',
  Source = 'SOURCE',
  DefaultValue = 'DEFAULT_VALUE'
}

export enum WorkflowDefinitionVariableType {
  String = 'STRING',
  Long = 'LONG',
  Double = 'DOUBLE',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateTimeTz = 'DATE_TIME_TZ',
  MultiLine = 'MULTI_LINE'
}

export type WorkflowEngineMetadata = {
  __typename?: 'WorkflowEngineMetadata';
  page_number?: Maybe<Scalars['Int']>;
  page_size?: Maybe<Scalars['Int']>;
  number_of_pages?: Maybe<Scalars['Int']>;
  number_of_results?: Maybe<Scalars['Int']>;
};

export type WorkflowEngineResponse = {
  metadata?: Maybe<WorkflowEngineMetadata>;
};

export type WorkflowInput = {
  member_address?: Maybe<WorkflowAddressInput>;
  clinician_id: Scalars['ID'];
  clinician_name?: Maybe<Scalars['String']>;
  consultation_id?: Maybe<Scalars['ID']>;
  member_contact_number?: Maybe<Scalars['String']>;
  member_date_of_birth?: Maybe<Scalars['String']>;
  member_email?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['ID']>;
  member_name?: Maybe<Scalars['String']>;
  member_consumer_network?: Maybe<Scalars['String']>;
  member_ccg_name?: Maybe<Scalars['String']>;
  member_ccg_code?: Maybe<Scalars['String']>;
  nhs_number?: Maybe<Scalars['String']>;
};

export type WorkflowInvestigationActionInput = {
  type: Scalars['String'];
  sub_type: Scalars['String'];
  notes: Scalars['String'];
  urgency: Scalars['String'];
};

export type WorkflowLetterActionInput = {
  type: Scalars['String'];
  sub_type: Scalars['String'];
  notes: Scalars['String'];
  urgency: Scalars['String'];
  content: Scalars['String'];
};

export type WorkflowOtherActionInput = {
  type?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type WorkflowReferralActionInput = {
  type?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  urgency?: Maybe<Scalars['String']>;
  interpreter?: Maybe<Scalars['String']>;
  mobility?: Maybe<Scalars['String']>;
  sub_speciality?: Maybe<Scalars['String']>;
};

export type WorkflowSearchCriteria = {
  state?: Maybe<WorkflowState>;
};

export type WorkflowsResponse = WorkflowEngineResponse & {
  __typename?: 'WorkflowsResponse';
  metadata?: Maybe<WorkflowEngineMetadata>;
  results: Array<WorkflowV2>;
};

export enum WorkflowState {
  Completed = 'COMPLETED',
  Suspended = 'SUSPENDED',
  Active = 'ACTIVE',
  InternallyTerminated = 'INTERNALLY_TERMINATED',
  ExternallyTerminated = 'EXTERNALLY_TERMINATED',
  Unknown = 'UNKNOWN'
}

export enum WorkflowStatus {
  Submitted = 'submitted',
  InProgress = 'in_progress',
  Completed = 'completed'
}

export type WorkflowSubType = {
  __typename?: 'WorkflowSubType';
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type WorkflowTask = {
  __typename?: 'WorkflowTask';
  id: Scalars['ID'];
  assignee?: Maybe<Scalars['String']>;
  clinician?: Maybe<Consultant>;
  consumerNetwork?: Maybe<ConsumerNetwork>;
  delete_reason?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
  task_definition_key?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['ID']>;
  workflow?: Maybe<WorkflowV2>;
  workflow_id?: Maybe<Scalars['ID']>;
  variables?: Maybe<Array<Maybe<WorkflowTaskVariable>>>;
};

export type WorkflowTasksResponse = WorkflowEngineResponse & {
  __typename?: 'WorkflowTasksResponse';
  metadata?: Maybe<WorkflowEngineMetadata>;
  results?: Maybe<Array<Maybe<WorkflowTask>>>;
};

export type WorkflowTaskVariable = {
  __typename?: 'WorkflowTaskVariable';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum WorkflowType {
  Appointment = 'appointment',
  Investigation = 'investigation',
  Letter = 'letter',
  Referral = 'referral',
  Other = 'other'
}

export type WorkflowV2 = TimelineEventInterface & {
  __typename?: 'WorkflowV2';
  id: Scalars['ID'];
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  state: WorkflowState;
  idempotency_key: Scalars['String'];
  workflow_definition_key: Scalars['String'];
  workflow_definition_id: Scalars['String'];
  tenant_id?: Maybe<Scalars['ID']>;
  variables: Array<WorkflowVariable>;
  tasks?: Maybe<WorkflowTasksResponse>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
  /** @deprecated Field no longer supported */
  workflow_definition?: Maybe<WorkflowDefinition>;
  consumerNetwork?: Maybe<ConsumerNetwork>;
  eventTime?: Maybe<Scalars['Date']>;
  eventType?: Maybe<TimelineEventType>;
};

export type WorkflowVariable = {
  __typename?: 'WorkflowVariable';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type WorkflowVariableInput = {
  name: Scalars['String'];
  value?: Maybe<Scalars['WorkflowDefinitionParentMapping']>;
};

export type ClinicalPresenceMutationVariables = Exact<{ [key: string]: never; }>;


export type ClinicalPresenceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clinicalPresence'>
);

export type AppointmentCheckInDetailsQueryVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;


export type AppointmentCheckInDetailsQuery = (
  { __typename?: 'Query' }
  & { getAppointmentCheckIn?: Maybe<(
    { __typename?: 'CheckInDetails' }
    & Pick<CheckInDetails, 'status' | 'checkedInAt'>
    & { location?: Maybe<(
      { __typename?: 'CheckInLocation' }
      & Pick<CheckInLocation, 'useRegisteredLocation'>
      & { temporaryLocation?: Maybe<(
        { __typename?: 'CheckInTemporaryLocation' }
        & Pick<CheckInTemporaryLocation, 'countryIsoCode' | 'address'>
      )> }
    )> }
  )> }
);

export type GetProfessionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProfessionsQuery = (
  { __typename?: 'Query' }
  & { consumerNetworkProfessions: Array<(
    { __typename?: 'ConsumerNetworkProfession' }
    & Pick<ConsumerNetworkProfession, 'id' | 'name'>
    & { alternativeSlotDurationMinutes: ConsumerNetworkProfession['alternative_slot_durations_minutes'], defaultSlotDurationMinutes: ConsumerNetworkProfession['default_slot_duration_minutes'] }
  )> }
);

export type GetServiceTypesQueryVariables = Exact<{
  memberUuid: Scalars['ID'];
}>;


export type GetServiceTypesQuery = (
  { __typename?: 'Query' }
  & { serviceTypes: Array<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name' | 'allowed_mediums' | 'member_instructions'>
    & { service_type_settings?: Maybe<(
      { __typename?: 'ServiceTypeSettings' }
      & { recurrence_settings?: Maybe<(
        { __typename?: 'RecurrenceSettings' }
        & { recurrence_patterns: Array<(
          { __typename?: 'RecurrencePatterns' }
          & Pick<RecurrencePatterns, 'frequency' | 'interval' | 'max_sessions' | 'label'>
        )> }
      )> }
    )> }
  )> }
);

export type GetConsumerNetworkServiceTypesQueryVariables = Exact<{
  consumerNetworkId: Scalars['ID'];
}>;


export type GetConsumerNetworkServiceTypesQuery = (
  { __typename?: 'Query' }
  & { consumerNetworkServiceTypes: Array<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'uuid' | 'name' | 'allowed_mediums' | 'member_instructions'>
    & { service_type_settings?: Maybe<(
      { __typename?: 'ServiceTypeSettings' }
      & { recurrence_settings?: Maybe<(
        { __typename?: 'RecurrenceSettings' }
        & { recurrence_patterns: Array<(
          { __typename?: 'RecurrencePatterns' }
          & Pick<RecurrencePatterns, 'frequency' | 'interval' | 'max_sessions' | 'label'>
        )> }
      )> }
    )> }
  )> }
);

export type GetClinicianServiceTypesQueryVariables = Exact<{
  clinician_id: Scalars['ID'];
}>;


export type GetClinicianServiceTypesQuery = (
  { __typename?: 'Query' }
  & { clinicianServiceTypes: Array<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name'>
  )> }
);

export type InvitePartFragment = (
  { __typename?: 'AppointmentInvite' }
  & Pick<AppointmentInvite, 'id' | 'allowed_mediums' | 'notes_for_member' | 'preferred_profession' | 'duration_minutes' | 'preferred_profession_name' | 'group_correlation_id' | 'earliest_booking_date' | 'consultant_uuid'>
  & { service_type?: Maybe<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name'>
  )>, consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name'>
  )>, associated_appointments?: Maybe<Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'time'>
  )>>, recurrence?: Maybe<(
    { __typename?: 'Recurrence' }
    & Pick<Recurrence, 'frequency' | 'interval' | 'count'>
  )> }
);

export type GetAppointmentInvitesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAppointmentInvitesQuery = (
  { __typename?: 'Query' }
  & { appointmentInvites: Array<(
    { __typename?: 'AppointmentInvite' }
    & InvitePartFragment
  )> }
);

export type CreateInviteMutationVariables = Exact<{
  input: AppointmentInviteInput;
}>;


export type CreateInviteMutation = (
  { __typename?: 'Mutation' }
  & { createAppointmentInvite: (
    { __typename?: 'AppointmentInvite' }
    & Pick<AppointmentInvite, 'intimate'>
    & { service_type_uuid: AppointmentInvite['nullable_service_type_uuid'] }
    & { recurrence?: Maybe<(
      { __typename?: 'Recurrence' }
      & Pick<Recurrence, 'count'>
    )> }
    & InvitePartFragment
  ) }
);

export type CreateInviteV3MutationVariables = Exact<{
  input: AppointmentInviteInput;
}>;


export type CreateInviteV3Mutation = (
  { __typename?: 'Mutation' }
  & { createAppointmentInviteV3: (
    { __typename?: 'CreateAppointmentInviteResponse' }
    & { invite: (
      { __typename?: 'AppointmentInvite' }
      & Pick<AppointmentInvite, 'intimate'>
      & { service_type_uuid: AppointmentInvite['nullable_service_type_uuid'] }
      & { recurrence?: Maybe<(
        { __typename?: 'Recurrence' }
        & Pick<Recurrence, 'count'>
      )> }
      & InvitePartFragment
    ), errors?: Maybe<Array<(
      { __typename?: 'CreateAppointmentErrors' }
      & Pick<CreateAppointmentErrors, 'date'>
    )>> }
  ) }
);

export type AttachmentsSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AttachmentsSummaryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { testResults?: Maybe<Array<Maybe<(
      { __typename?: 'ConsultationTestResult' }
      & Pick<ConsultationTestResult, 'id' | 'url' | 'thumbUrl' | 'fileName' | 'type'>
    )>>> }
  )> }
);

export type AttachmentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AttachmentsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { testResults?: Maybe<Array<Maybe<(
      { __typename?: 'ConsultationTestResult' }
      & Pick<ConsultationTestResult, 'id' | 'url' | 'thumbUrl' | 'fileName' | 'type'>
    )>>> }
  )> }
);

export type BookingReasonQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BookingReasonQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & PresentingComplaintFragment
  )> }
);

export type PresentingComplaintFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'category' | 'patientNote'>
  & { images: (
    { __typename: 'ConsultationImages' }
    & Pick<ConsultationImages, 'items'>
  ) | (
    { __typename: 'RetrievalError' }
    & Pick<RetrievalError, 'message'>
  ), appointment?: Maybe<(
    { __typename: 'Appointment' }
    & { serviceType?: Maybe<(
      { __typename: 'ServiceType' }
      & Pick<ServiceType, 'name'>
      & { memberFacingName: ServiceType['member_facing_name'] }
    )> }
  )> }
);

export type FeedbackQuestionFragmentFragment = (
  { __typename?: 'MultimediaFeedbackQuestion' }
  & Pick<MultimediaFeedbackQuestion, 'id' | 'position' | 'question_type' | 'question'>
  & { suggested_answers?: Maybe<Array<(
    { __typename?: 'SuggestedAnswer' }
    & Pick<SuggestedAnswer, 'id' | 'answer'>
  )>> }
);

export type CpMultimediaFeedbackQuestionQueryVariables = Exact<{
  consultationId: Scalars['ID'];
  client_type?: Maybe<MultimediaFeedbackClientType>;
}>;


export type CpMultimediaFeedbackQuestionQuery = (
  { __typename?: 'Query' }
  & { multimediaFeedbackQuestion?: Maybe<(
    { __typename?: 'MultimediaFeedbackQuestion' }
    & FeedbackQuestionFragmentFragment
  )> }
);

export type CpSubmitMultimediaFeedbackMutationVariables = Exact<{
  input: MultimediaFeedbackInput;
}>;


export type CpSubmitMultimediaFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { submitMultimediaFeedback: (
    { __typename?: 'MultimediaFeedbackResult' }
    & { next_question?: Maybe<(
      { __typename?: 'MultimediaFeedbackQuestion' }
      & FeedbackQuestionFragmentFragment
    )> }
  ) }
);

export type CpRevokeRecordingConsentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpRevokeRecordingConsentMutation = (
  { __typename?: 'Mutation' }
  & { revokeRecordingConsent?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'recordingConsent'>
  )> }
);

export type CallPlayerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CallPlayerQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'recordingConsent' | 'consultationType'>
  )> }
);

export type CpVideoSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpVideoSessionQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { videoSession?: Maybe<(
      { __typename?: 'ConsultationVideoSession' }
      & Pick<ConsultationVideoSession, 'id' | 'sessionId' | 'providerApiKey' | 'providerSessionId' | 'providerToken'>
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'first_name' | 'last_name'>
    ), consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'name'>
    )> }
  )> }
);

export type CpRawCallFlowStepsQueryVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;


export type CpRawCallFlowStepsQuery = (
  { __typename?: 'Query' }
  & { getRawCallFlowSteps: (
    { __typename?: 'CallFlowStatus' }
    & { call_flow_steps: Array<Maybe<(
      { __typename?: 'CallFlowStepItem' }
      & Pick<CallFlowStepItem, 'triggered_at' | 'flow_step_type'>
    )>> }
  ) }
);

export type CpEndVideoCallMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type CpEndVideoCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'endVideoCall'>
);

export type CpCallFlowStepsQueryVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;


export type CpCallFlowStepsQuery = (
  { __typename?: 'Query' }
  & { getCallFlowSteps: (
    { __typename?: 'CallFlowStatus' }
    & { call_flow_steps: Array<Maybe<(
      { __typename?: 'CallFlowStepItem' }
      & Pick<CallFlowStepItem, 'triggered_at' | 'flow_step_type'>
    )>> }
  ) }
);

export type CpStartVideoCallMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  sessionId: Scalars['ID'];
  activeParticipants: Array<VideoSessionUserTypes>;
}>;


export type CpStartVideoCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'startVideoCall'>
);

export type CpAudioSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpAudioSessionQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consultationId' | 'patientId'>
    & { audioSession?: Maybe<(
      { __typename?: 'ConsultationAudioSession' }
      & Pick<ConsultationAudioSession, 'token' | 'encryptedVoiceParams'>
    )> }
  )> }
);

export type ReportCallStepMutationVariables = Exact<{
  consultationId: Scalars['String'];
  step: CallStep;
  appName: Scalars['String'];
  appVersion: Scalars['String'];
}>;


export type ReportCallStepMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportCallStep'>
);

export type CreateCarePlanDraftMutationVariables = Exact<{
  input: CreateCarePlanDraftInput;
}>;


export type CreateCarePlanDraftMutation = (
  { __typename?: 'Mutation' }
  & { createCarePlanDraft: (
    { __typename: 'CreateCarePlanDraftSuccess' }
    & { carePlanDraft: (
      { __typename?: 'CarePlanDraft' }
      & Pick<CarePlanDraft, 'draftId' | 'createdAt' | 'updatedAt' | 'upstreamRevisionId'>
      & { metadata: (
        { __typename?: 'DraftMetadata' }
        & Pick<DraftMetadata, 'numberOfEdits'>
        & { warnings: Array<(
          { __typename: 'GoalDescriptionEmpty' }
          & Pick<GoalDescriptionEmpty, 'goalDraftId'>
        ) | (
          { __typename: 'ActionDescriptionEmpty' }
          & Pick<ActionDescriptionEmpty, 'actionDraftId'>
        ) | (
          { __typename: 'GoalWithoutAction' }
          & Pick<GoalWithoutAction, 'goalDraftId'>
        ) | (
          { __typename: 'ActionWithoutGoal' }
          & Pick<ActionWithoutGoal, 'actionDraftId'>
        ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
      ), goals: Array<(
        { __typename: 'CarePlanGoalDraft' }
        & Pick<CarePlanGoalDraft, 'draftId' | 'createdAt' | 'updatedAt' | 'description'>
        & { associatedActionDrafts?: Maybe<Array<(
          { __typename: 'CarePlanActionDraft' }
          & Pick<CarePlanActionDraft, 'draftId' | 'createdAt' | 'updatedAt' | 'description'>
        )>> }
      )> }
    ) }
  ) | (
    { __typename: 'DraftInFlightAlreadyError' }
    & Pick<DraftInFlightAlreadyError, 'consultationId' | 'draftId' | 'createdAt' | 'updatedAt'>
  ) | (
    { __typename: 'RevisionAlreadyPublishedInConsultationError' }
    & Pick<RevisionAlreadyPublishedInConsultationError, 'carePlanRevisionId' | 'consultationId' | 'patientId'>
  ) | (
    { __typename: 'ConsultationDoesNotExistError' }
    & Pick<ConsultationDoesNotExistError, 'consultationId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type DeleteCarePlanDraftMutationVariables = Exact<{
  input: DeleteCarePlanDraftInput;
}>;


export type DeleteCarePlanDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteCarePlanDraft: (
    { __typename: 'DeleteCarePlanDraftSuccess' }
    & Pick<DeleteCarePlanDraftSuccess, '_'>
  ) | (
    { __typename: 'ConsultationDoesNotExistError' }
    & Pick<ConsultationDoesNotExistError, 'consultationId'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'CarePlanAuthorizationError' }
    & Pick<CarePlanAuthorizationError, 'action' | 'resource'>
  ) }
);

export type AddGoalDraftMutationVariables = Exact<{
  input: CarePlanAddGoalDraftInput;
}>;


export type AddGoalDraftMutation = (
  { __typename?: 'Mutation' }
  & { addGoalDraft: (
    { __typename: 'AddGoalDraftSuccess' }
    & { goalDraft: (
      { __typename?: 'CarePlanGoalDraft' }
      & Pick<CarePlanGoalDraft, 'draftId' | 'updatedAt' | 'createdAt' | 'description'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type EditGoalDraftMutationVariables = Exact<{
  input: CarePlanEditGoalDraftInput;
}>;


export type EditGoalDraftMutation = (
  { __typename?: 'Mutation' }
  & { editGoalDraft: (
    { __typename: 'EditGoalDraftSuccess' }
    & { goalDraft: (
      { __typename?: 'CarePlanGoalDraft' }
      & Pick<CarePlanGoalDraft, 'draftId' | 'updatedAt' | 'createdAt' | 'description'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | { __typename: 'GoalDraftDoesNotExistError' } | { __typename: 'CarePlanAuthorizationError' } }
);

export type DeleteGoalDraftMutationVariables = Exact<{
  input: CarePlanDeleteGoalDraftInput;
}>;


export type DeleteGoalDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteGoalDraft: (
    { __typename: 'DeleteGoalDraftSuccess' }
    & { parentCarePlanDraft: (
      { __typename?: 'CarePlanDraft' }
      & Pick<CarePlanDraft, 'draftId'>
      & { metadata: (
        { __typename?: 'DraftMetadata' }
        & Pick<DraftMetadata, 'numberOfEdits'>
        & { warnings: Array<(
          { __typename: 'GoalDescriptionEmpty' }
          & Pick<GoalDescriptionEmpty, 'goalDraftId'>
        ) | (
          { __typename: 'ActionDescriptionEmpty' }
          & Pick<ActionDescriptionEmpty, 'actionDraftId'>
        ) | (
          { __typename: 'GoalWithoutAction' }
          & Pick<GoalWithoutAction, 'goalDraftId'>
        ) | (
          { __typename: 'ActionWithoutGoal' }
          & Pick<ActionWithoutGoal, 'actionDraftId'>
        ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
      ) }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'GoalDraftDoesNotExistError' }
    & Pick<GoalDraftDoesNotExistError, 'goalDraftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type AddActionDraftMutationVariables = Exact<{
  input: CarePlanAddActionDraftInput;
}>;


export type AddActionDraftMutation = (
  { __typename?: 'Mutation' }
  & { addActionDraft: (
    { __typename: 'AddActionDraftSuccess' }
    & { actionDraft: (
      { __typename?: 'CarePlanActionDraft' }
      & Pick<CarePlanActionDraft, 'draftId' | 'description' | 'createdAt' | 'updatedAt'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type AssociateGoalAndActionDraftMutationVariables = Exact<{
  input: CarePlanAssociateGoalAndActionDraftInput;
}>;


export type AssociateGoalAndActionDraftMutation = (
  { __typename?: 'Mutation' }
  & { associateGoalAndActionDraft: (
    { __typename: 'AssociateGoalAndActionDraftSuccess' }
    & { goalDraft: (
      { __typename?: 'CarePlanGoalDraft' }
      & Pick<CarePlanGoalDraft, 'draftId'>
    ), actionDraft: (
      { __typename?: 'CarePlanActionDraft' }
      & Pick<CarePlanActionDraft, 'draftId' | 'createdAt'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'GoalDraftDoesNotExistError' }
    & Pick<GoalDraftDoesNotExistError, 'goalDraftId'>
  ) | (
    { __typename: 'ActionDraftDoesNotExistError' }
    & Pick<ActionDraftDoesNotExistError, 'actionDraftId'>
  ) | (
    { __typename: 'GoalActionDraftAssociationAlreadyExistError' }
    & Pick<GoalActionDraftAssociationAlreadyExistError, 'goalDraftId' | 'actionDraftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type AddAndAssociateActionDraftMutationVariables = Exact<{
  addActionInput: CarePlanAddActionDraftInput;
  associateInput: CarePlanAssociateGoalAndActionDraftInput;
}>;


export type AddAndAssociateActionDraftMutation = (
  { __typename?: 'Mutation' }
  & { addActionDraft: (
    { __typename: 'AddActionDraftSuccess' }
    & { actionDraft: (
      { __typename?: 'CarePlanActionDraft' }
      & Pick<CarePlanActionDraft, 'draftId' | 'description' | 'createdAt' | 'updatedAt'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | { __typename: 'CarePlanAuthorizationError' }, associateGoalAndActionDraft: (
    { __typename: 'AssociateGoalAndActionDraftSuccess' }
    & { goalDraft: (
      { __typename?: 'CarePlanGoalDraft' }
      & Pick<CarePlanGoalDraft, 'draftId'>
    ), actionDraft: (
      { __typename?: 'CarePlanActionDraft' }
      & Pick<CarePlanActionDraft, 'draftId' | 'createdAt'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'GoalDraftDoesNotExistError' }
    & Pick<GoalDraftDoesNotExistError, 'goalDraftId'>
  ) | (
    { __typename: 'ActionDraftDoesNotExistError' }
    & Pick<ActionDraftDoesNotExistError, 'actionDraftId'>
  ) | (
    { __typename: 'GoalActionDraftAssociationAlreadyExistError' }
    & Pick<GoalActionDraftAssociationAlreadyExistError, 'goalDraftId' | 'actionDraftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type EditActionDraftMutationVariables = Exact<{
  input: CarePlanEditActionDraftInput;
}>;


export type EditActionDraftMutation = (
  { __typename?: 'Mutation' }
  & { editActionDraft: (
    { __typename: 'EditActionDraftSuccess' }
    & { actionDraft: (
      { __typename?: 'CarePlanActionDraft' }
      & Pick<CarePlanActionDraft, 'draftId' | 'updatedAt' | 'createdAt' | 'description'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type DeleteActionDraftMutationVariables = Exact<{
  input: CarePlanDeleteActionDraftInput;
}>;


export type DeleteActionDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteActionDraft: (
    { __typename: 'DeleteActionDraftSuccess' }
    & { parentCarePlanDraft: (
      { __typename?: 'CarePlanDraft' }
      & Pick<CarePlanDraft, 'draftId'>
      & { metadata: (
        { __typename?: 'DraftMetadata' }
        & Pick<DraftMetadata, 'numberOfEdits'>
        & { warnings: Array<(
          { __typename: 'GoalDescriptionEmpty' }
          & Pick<GoalDescriptionEmpty, 'goalDraftId'>
        ) | (
          { __typename: 'ActionDescriptionEmpty' }
          & Pick<ActionDescriptionEmpty, 'actionDraftId'>
        ) | (
          { __typename: 'GoalWithoutAction' }
          & Pick<GoalWithoutAction, 'goalDraftId'>
        ) | (
          { __typename: 'ActionWithoutGoal' }
          & Pick<ActionWithoutGoal, 'actionDraftId'>
        ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
      ) }
    ) }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'ActionDraftDoesNotExistError' }
    & Pick<ActionDraftDoesNotExistError, 'actionDraftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type DissociateGoalAndActionDraftMutationVariables = Exact<{
  input: CarePlanDissociateGoalAndActionDraftInput;
}>;


export type DissociateGoalAndActionDraftMutation = (
  { __typename?: 'Mutation' }
  & { dissociateGoalAndActionDraft: (
    { __typename: 'DissociateGoalAndActionDraftSuccess' }
    & { actionDraft?: Maybe<(
      { __typename?: 'CarePlanActionDraft' }
      & Pick<CarePlanActionDraft, 'draftId'>
      & { parentCarePlanDraft?: Maybe<(
        { __typename?: 'CarePlanDraft' }
        & Pick<CarePlanDraft, 'draftId'>
        & { metadata: (
          { __typename?: 'DraftMetadata' }
          & Pick<DraftMetadata, 'numberOfEdits'>
          & { warnings: Array<(
            { __typename: 'GoalDescriptionEmpty' }
            & Pick<GoalDescriptionEmpty, 'goalDraftId'>
          ) | (
            { __typename: 'ActionDescriptionEmpty' }
            & Pick<ActionDescriptionEmpty, 'actionDraftId'>
          ) | (
            { __typename: 'GoalWithoutAction' }
            & Pick<GoalWithoutAction, 'goalDraftId'>
          ) | (
            { __typename: 'ActionWithoutGoal' }
            & Pick<ActionWithoutGoal, 'actionDraftId'>
          ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
        ) }
      )> }
    )> }
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'GoalDraftDoesNotExistError' }
    & Pick<GoalDraftDoesNotExistError, 'goalDraftId'>
  ) | (
    { __typename: 'ActionDraftDoesNotExistError' }
    & Pick<ActionDraftDoesNotExistError, 'actionDraftId'>
  ) | (
    { __typename: 'GoalActionDraftAssociationDoesNotExistError' }
    & Pick<GoalActionDraftAssociationDoesNotExistError, 'goalDraftId' | 'actionDraftId'>
  ) | { __typename: 'CarePlanAuthorizationError' } }
);

export type CarePlanQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type CarePlanQuery = (
  { __typename?: 'Query' }
  & { carePlan: (
    { __typename: 'CarePlan' }
    & Pick<CarePlan, 'id' | 'entityCreatedAt' | 'revisionCreatedAt' | 'revisionId'>
    & { goals: Array<(
      { __typename?: 'CarePlanGoal' }
      & Pick<CarePlanGoal, 'id' | 'description' | 'entityCreatedAt' | 'revisionCreatedAt'>
      & { associatedActions: Array<(
        { __typename?: 'CarePlanAction' }
        & Pick<CarePlanAction, 'id' | 'description' | 'entityCreatedAt' | 'revisionCreatedAt'>
      )> }
    )> }
  ) | { __typename: 'None' } | (
    { __typename: 'CarePlanAuthorizationError' }
    & Pick<CarePlanAuthorizationError, 'action' | 'resource'>
  ) }
);

export type CarePlanPublishedInConsultationQueryVariables = Exact<{
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
}>;


export type CarePlanPublishedInConsultationQuery = (
  { __typename?: 'Query' }
  & { carePlanPublishedInConsultation: (
    { __typename: 'CarePlan' }
    & Pick<CarePlan, 'id' | 'entityCreatedAt' | 'revisionCreatedAt' | 'revisionId'>
    & { goals: Array<(
      { __typename?: 'CarePlanGoal' }
      & Pick<CarePlanGoal, 'id' | 'description' | 'entityCreatedAt' | 'revisionCreatedAt'>
      & { associatedActions: Array<(
        { __typename?: 'CarePlanAction' }
        & Pick<CarePlanAction, 'id' | 'description' | 'entityCreatedAt' | 'revisionCreatedAt'>
      )> }
    )> }
  ) | { __typename: 'None' } | (
    { __typename: 'CarePlanAuthorizationError' }
    & Pick<CarePlanAuthorizationError, 'action' | 'resource'>
  ) }
);

export type CarePlanDraftQueryVariables = Exact<{
  patientId: Scalars['ID'];
  consultationId: Scalars['ID'];
}>;


export type CarePlanDraftQuery = (
  { __typename?: 'Query' }
  & { carePlanDraft: (
    { __typename: 'CarePlanDraft' }
    & Pick<CarePlanDraft, 'draftId' | 'createdAt' | 'updatedAt'>
    & { goals: Array<(
      { __typename?: 'CarePlanGoalDraft' }
      & Pick<CarePlanGoalDraft, 'draftId' | 'description' | 'createdAt' | 'updatedAt'>
      & { associatedActionDrafts?: Maybe<Array<(
        { __typename?: 'CarePlanActionDraft' }
        & Pick<CarePlanActionDraft, 'draftId' | 'description' | 'createdAt' | 'updatedAt'>
      )>> }
    )>, metadata: (
      { __typename?: 'DraftMetadata' }
      & Pick<DraftMetadata, 'numberOfEdits'>
      & { warnings: Array<{ __typename: 'GoalDescriptionEmpty' } | { __typename: 'ActionDescriptionEmpty' } | { __typename: 'GoalWithoutAction' } | { __typename: 'ActionWithoutGoal' } | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
    ) }
  ) | { __typename: 'None' } | (
    { __typename: 'CarePlanAuthorizationError' }
    & Pick<CarePlanAuthorizationError, 'action' | 'resource'>
  ) }
);

export type CarePlanReviewBaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CarePlanReviewBaseQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
    & { uuid: Consultation['consultationId'] }
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'uuid'>
    ) }
  )> }
);

export type CpLiteChatscriptMessagesQueryVariables = Exact<{
  eventId: Scalars['ID'];
  patientUuid: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type CpLiteChatscriptMessagesQuery = (
  { __typename?: 'Query' }
  & { chatscriptMessages?: Maybe<(
    { __typename?: 'ChatscriptMessagesResponse' }
    & Pick<ChatscriptMessagesResponse, 'more'>
    & { messages: Array<(
      { __typename?: 'ChatscriptMessage' }
      & Pick<ChatscriptMessage, 'id' | 'sender'>
      & { input?: Maybe<(
        { __typename?: 'ChatscriptMessageInput' }
        & { params?: Maybe<(
          { __typename?: 'ChatscriptMessageInputParams' }
          & { choices?: Maybe<Array<(
            { __typename?: 'ChatacriptMessageInputParamsChoice' }
            & Pick<ChatacriptMessageInputParamsChoice, 'id' | 'label'>
          )>> }
        )> }
      )>, value?: Maybe<(
        { __typename?: 'ChatscriptMessageValue' }
        & Pick<ChatscriptMessageValue, 'text'>
      )> }
    )> }
  )> }
);

export type CpChatscriptConversationQueryVariables = Exact<{
  eventId: Scalars['ID'];
  patientUuid: Scalars['ID'];
}>;


export type CpChatscriptConversationQuery = (
  { __typename?: 'Query' }
  & { chatscriptConversation?: Maybe<(
    { __typename?: 'ChatscriptConversation' }
    & Pick<ChatscriptConversation, 'id' | 'startedAt' | 'preview' | 'type' | 'status' | 'patientUuid' | 'userUuid'>
    & { result?: Maybe<(
      { __typename?: 'ChatscriptConversationResult' }
      & Pick<ChatscriptConversationResult, 'outcome' | 'type'>
    )> }
  )> }
);

export type ClinicalCodeWeightingsQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;


export type ClinicalCodeWeightingsQuery = (
  { __typename?: 'Query' }
  & { cp3ClinicalCodeWeightings: Array<(
    { __typename?: 'Cp3ClinicalCodeWeightingsResult' }
    & Pick<Cp3ClinicalCodeWeightingsResult, 'id' | 'rankings'>
    & { countryCode: (
      { __typename?: 'KenticoCountryCode' }
      & Pick<KenticoCountryCode, 'name' | 'codename'>
    ) }
  )> }
);

export type SuggestedClinicalCodesQueryQueryVariables = Exact<{
  query: Scalars['String'];
  region?: Maybe<Scalars['String']>;
}>;


export type SuggestedClinicalCodesQueryQuery = (
  { __typename?: 'Query' }
  & { searchCodingDiagnosis: Array<Maybe<(
    { __typename?: 'ClinicalKnowledgeLabel' }
    & Pick<ClinicalKnowledgeLabel, 'id' | 'preferredLabel' | 'iri' | 'hasNotes'>
  )>> }
);

export type CpMarkConsultationAsCompleteMutationVariables = Exact<{
  id: Scalars['ID'];
  exempted?: Maybe<Scalars['Boolean']>;
}>;


export type CpMarkConsultationAsCompleteMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationStatus?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
  )> }
);

export type CpPrescriptionChangeStateMutationVariables = Exact<{
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  action: PrescriptionStateActionType;
  region: Scalars['String'];
}>;


export type CpPrescriptionChangeStateMutation = (
  { __typename?: 'Mutation' }
  & { prescriptionChangeState?: Maybe<(
    { __typename?: 'Prescription' }
    & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState' | 'actions'>
  )> }
);

export type PromoteCarePlanDraftMutationVariables = Exact<{
  input: PromoteCarePlanDraftInput;
}>;


export type PromoteCarePlanDraftMutation = (
  { __typename?: 'Mutation' }
  & { promoteCarePlanDraft: (
    { __typename: 'PromoteCarePlanDraftSuccess' }
    & { carePlan: (
      { __typename?: 'CarePlan' }
      & Pick<CarePlan, 'id' | 'entityCreatedAt' | 'revisionCreatedAt' | 'revisionId'>
      & { goals: Array<(
        { __typename?: 'CarePlanGoal' }
        & Pick<CarePlanGoal, 'id' | 'description' | 'entityCreatedAt' | 'revisionCreatedAt'>
        & { associatedActions: Array<(
          { __typename?: 'CarePlanAction' }
          & Pick<CarePlanAction, 'id' | 'description' | 'entityCreatedAt' | 'revisionCreatedAt'>
        )> }
      )> }
    ) }
  ) | (
    { __typename: 'CarePlanDraftDoesNotExistError' }
    & Pick<CarePlanDraftDoesNotExistError, 'draftId'>
  ) | (
    { __typename: 'UnresolvedWarningsError' }
    & Pick<UnresolvedWarningsError, 'carePlanDraftId'>
    & { warnings: Array<(
      { __typename: 'GoalDescriptionEmpty' }
      & Pick<GoalDescriptionEmpty, 'goalDraftId'>
    ) | (
      { __typename: 'ActionDescriptionEmpty' }
      & Pick<ActionDescriptionEmpty, 'actionDraftId'>
    ) | (
      { __typename: 'GoalWithoutAction' }
      & Pick<GoalWithoutAction, 'goalDraftId'>
    ) | (
      { __typename: 'ActionWithoutGoal' }
      & Pick<ActionWithoutGoal, 'actionDraftId'>
    ) | { __typename: 'CarePlanDraftWithoutGoalsAndActions' }> }
  ) | (
    { __typename: 'ConsultationDoesNotExistError' }
    & Pick<ConsultationDoesNotExistError, 'consultationId'>
  ) | (
    { __typename: 'InvalidCarePlanDraftNumbersOfEditsError' }
    & Pick<InvalidCarePlanDraftNumbersOfEditsError, 'draftId' | 'currentNumberOfEdits' | 'providedNumberOfEdits'>
  ) | (
    { __typename: 'CarePlanAuthorizationError' }
    & Pick<CarePlanAuthorizationError, 'action' | 'resource'>
  ) }
);

export type SetConsultationExemptedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetConsultationExemptedMutation = (
  { __typename?: 'Mutation' }
  & { setConsultationExempted: (
    { __typename?: 'ExemptedConsultation' }
    & Pick<ExemptedConsultation, 'id' | 'state'>
  ) }
);

export type PrescriptionStateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PrescriptionStateQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { prescriptions: Array<(
      { __typename?: 'Prescription' }
      & Pick<Prescription, 'id' | 'prescriptionState'>
    )> }
  )> }
);

export type PromotePrescriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  action: PrescriptionStateActionType;
  region: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
}>;


export type PromotePrescriptionMutation = (
  { __typename?: 'Mutation' }
  & { prescriptionChangeState?: Maybe<(
    { __typename?: 'Prescription' }
    & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState' | 'actions'>
  )> }
);

export type SetInsuranceClaimExemptedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetInsuranceClaimExemptedMutation = (
  { __typename?: 'Mutation' }
  & { setInsuranceClaimExempted: (
    { __typename?: 'InsuranceClaim' }
    & Pick<InsuranceClaim, 'id' | 'state' | 'createdAt' | 'updatedAt'>
  ) }
);

export type RegenerateClinicianPinMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RegenerateClinicianPinMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'regenerateClinicianPin'>
);

export type ConsultationProfileFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'scheduledTime' | 'languageRequested' | 'consumerNetworkName' | 'statusV2'>
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name' | 'profession_name'>
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'first_name' | 'last_name' | 'date_of_birth' | 'age' | 'gender' | 'minor' | 'public_healthcare_identifier' | 'email' | 'full_phone_number' | 'profile_locked'>
    & { contact_details?: Maybe<(
      { __typename?: 'ContactDetails' }
      & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'address_state_code'>
    )>, clinical_commissioning_group?: Maybe<(
      { __typename?: 'ClinicalComissioningGroup' }
      & Pick<ClinicalComissioningGroup, 'id' | 'name'>
    )>, profile_locks: Array<(
      { __typename?: 'ProfileLock' }
      & Pick<ProfileLock, 'name' | 'locked_at'>
    )> }
  ) }
);

export type CpParticipantsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpParticipantsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & { participants: Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'type' | 'userId'>
      & { participant?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'first_name' | 'last_name'>
      )> }
    )> }
  )> }
);

export type ConsultationsQueryQueryVariables = Exact<{
  searchCriteria?: Maybe<ConsultationSearchCriteria>;
  sortCriteria?: Maybe<ConsultationSortCriteria>;
  paginationCriteria?: Maybe<PaginationCriteria>;
}>;


export type ConsultationsQueryQuery = (
  { __typename?: 'Query' }
  & { consultationsv2: (
    { __typename?: 'ConsultationResults' }
    & Pick<ConsultationResults, 'totalItems' | 'pageIndex' | 'itemsPerPage' | 'showBroadWarning'>
    & { items: Array<(
      { __typename?: 'Consultation' }
      & Pick<Consultation, 'id' | 'consultationId' | 'scheduledTime' | 'sentToGp' | 'consumerNetworkName' | 'consultationType' | 'gpConsent' | 'medium' | 'status'>
      & { consultant?: Maybe<(
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'id' | 'name' | 'role' | 'profession_name'>
      )>, patient: (
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'uuid' | 'first_name' | 'last_name' | 'gender' | 'public_healthcare_identifier' | 'date_of_birth' | 'age' | 'email' | 'phone_number' | 'address_post_code' | 'id_verification' | 'minor'>
        & { patient_alerts?: Maybe<Array<(
          { __typename?: 'PatientAlert' }
          & Pick<PatientAlert, 'id'>
        )>> }
      ) | (
        { __typename: 'RetrievalError' }
        & Pick<RetrievalError, 'message'>
      ) }
    )> }
  ) }
);

export type ConsumerNetworksQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsumerNetworksQuery = (
  { __typename?: 'Query' }
  & { consumerNetworks: Array<Maybe<(
    { __typename?: 'ConsumerNetwork' }
    & Pick<ConsumerNetwork, 'id' | 'name'>
  )>> }
);

export type SearchCliniciansByNameQueryVariables = Exact<{
  searchValue?: Maybe<Scalars['String']>;
}>;


export type SearchCliniciansByNameQuery = (
  { __typename?: 'Query' }
  & { getConsultants?: Maybe<Array<Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'full_name'>
  )>>> }
);

export type ConsultationSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConsultationSummaryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consultationId' | 'gpConsent' | 'consumerNetwork' | 'consumerNetworkId' | 'consumerNetworkName' | 'preConsultationFlowId' | 'status' | 'consultationType' | 'scheduledTime' | 'languageRequested' | 'recordingConsent'>
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name' | 'role' | 'profession_name'>
    )>, permissions?: Maybe<Array<(
      { __typename?: 'ConsultationPermission' }
      & Pick<ConsultationPermission, 'id' | 'name' | 'value'>
    )>>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'iso_code'>
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'uuid'>
      & { patient_alerts?: Maybe<Array<(
        { __typename?: 'PatientAlert' }
        & Pick<PatientAlert, 'id'>
      )>> }
    ) }
    & ReviewFragmentFragment
    & GpDetailsFragmentFragment
    & CpPatientDetailsFragment
    & PresentingComplaintFragment
    & ExaminationFragmentFragment
    & CpSickNotesFragment
    & TestsFragmentFragment
    & ReferralsFragmentFragment
    & DiagnosisFragmentFragment
  )> }
);

export type ConsultationSummaryBaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConsultationSummaryBaseQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status' | 'scheduledTime' | 'consumerNetworkId'>
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'uuid' | 'name' | 'role' | 'profession_name'>
    )> }
  )> }
);

export type FurtherInfoFragmentFragment = (
  { __typename?: 'Consultation' }
  & { furtherInfo?: Maybe<Array<Maybe<(
    { __typename?: 'ConsultationFurtherInfo' }
    & Pick<ConsultationFurtherInfo, 'id' | 'name' | 'url'>
  )>>> }
);

export type ConsultationNotesSummaryQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ConsultationNotesSummaryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { consultationNote?: Maybe<(
      { __typename?: 'ConsultationNote' }
      & Pick<ConsultationNote, 'id' | 'exploredByGp' | 'toldByPatient' | 'assessment' | 'treatmentPlan' | 'fallbackPlan'>
      & { exploredByGpCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>>, toldByPatientCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>>, assessmentCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>> }
    )> }
    & FurtherInfoFragmentFragment
  )> }
);

export type InsuranceClaimQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InsuranceClaimQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { insuranceClaim?: Maybe<(
      { __typename?: 'InsuranceClaim' }
      & Pick<InsuranceClaim, 'id' | 'state'>
    )> }
  )> }
);

export type HasInsuranceClaimQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HasInsuranceClaimQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'hasInsuranceClaim'>
  )> }
);

export type ReviewFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'patientNote' | 'status'>
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'uuid' | 'name' | 'role' | 'profession_name'>
  )>, furtherInfo?: Maybe<Array<Maybe<(
    { __typename?: 'ConsultationFurtherInfo' }
    & Pick<ConsultationFurtherInfo, 'id' | 'name' | 'url'>
  )>>>, consultationNote?: Maybe<(
    { __typename?: 'ConsultationNote' }
    & Pick<ConsultationNote, 'id' | 'exploredByGp' | 'toldByPatient' | 'assessment' | 'treatmentPlan' | 'fallbackPlan'>
    & { exploredByGpCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>>, toldByPatientCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>>, assessmentCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>> }
  )>, images: (
    { __typename?: 'ConsultationImages' }
    & Pick<ConsultationImages, 'items'>
  ) | { __typename?: 'RetrievalError' }, region?: Maybe<(
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'iso_code'>
  )>, referrals?: Maybe<Array<Maybe<(
    { __typename?: 'ConsultationReferral' }
    & Pick<ConsultationReferral, 'id' | 'specialismCategoryId' | 'specialismId' | 'specialismName'>
  )>>>, testResults?: Maybe<Array<Maybe<(
    { __typename?: 'ConsultationTestResult' }
    & Pick<ConsultationTestResult, 'id' | 'url' | 'thumbUrl' | 'fileName' | 'type'>
  )>>>, sickNotes: Array<(
    { __typename?: 'ConsultationSickNote' }
    & Pick<ConsultationSickNote, 'id' | 'reason' | 'startDate' | 'endDate'>
  )> }
);

export type DiagnosisFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ), region?: Maybe<(
    { __typename?: 'Region' }
    & Pick<Region, 'id' | 'iso_code'>
  )>, consultationNote?: Maybe<(
    { __typename?: 'ConsultationNote' }
    & Pick<ConsultationNote, 'id' | 'assessment' | 'treatmentPlan' | 'fallbackPlan'>
    & { assessmentCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>> }
  )> }
);

export type ClinicalNotesQueryVariables = Exact<{
  iris: Array<Maybe<ClinicalCodeInput>>;
  resolveDrugs?: Maybe<Scalars['Boolean']>;
}>;


export type ClinicalNotesQuery = (
  { __typename?: 'Query' }
  & { getClinicalNotes: Array<Maybe<(
    { __typename?: 'ClinicalNote' }
    & Pick<ClinicalNote, 'furtherActionNotes' | 'managementNotes' | 'iri'>
  )>> }
);

export type UpdateConsultationNoteMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  input: ConsultationNoteInput;
}>;


export type UpdateConsultationNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationNote?: Maybe<(
    { __typename?: 'ConsultationNote' }
    & Pick<ConsultationNote, 'id' | 'toldByPatient' | 'exploredByGp' | 'assessment' | 'treatmentPlan' | 'fallbackPlan'>
    & { toldByPatientCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>>, exploredByGpCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>>, assessmentCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>> }
  )> }
);

export type DiagnosisQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type DiagnosisQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { consultationNote?: Maybe<(
      { __typename?: 'ConsultationNote' }
      & Pick<ConsultationNote, 'id' | 'assessment' | 'treatmentPlan' | 'fallbackPlan'>
      & { assessmentCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>> }
    )> }
  )> }
);

export type ExaminationNotesQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ExaminationNotesQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { consultationNote?: Maybe<(
      { __typename?: 'ConsultationNote' }
      & Pick<ConsultationNote, 'id' | 'toldByPatient' | 'exploredByGp'>
      & { toldByPatientCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>>, exploredByGpCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>> }
    )> }
  )> }
);

export type ExaminationFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { conditions?: Maybe<Array<Maybe<(
      { __typename?: 'Condition' }
      & Pick<Condition, 'id' | 'iri' | 'info'>
    )>>>, medications?: Maybe<Array<Maybe<(
      { __typename?: 'Medication' }
      & Pick<Medication, 'id' | 'iri' | 'info'>
    )>>>, allergies?: Maybe<Array<Maybe<(
      { __typename?: 'Allergy' }
      & Pick<Allergy, 'id' | 'iri' | 'info'>
    )>>> }
  ), consultationNote?: Maybe<(
    { __typename?: 'ConsultationNote' }
    & Pick<ConsultationNote, 'id' | 'toldByPatient' | 'exploredByGp'>
    & { toldByPatientCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>>, exploredByGpCodes?: Maybe<Array<(
      { __typename?: 'ConsultationNoteCode' }
      & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
    )>> }
  )> }
);

export type RemoveRemoveFromMedicationsMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type RemoveRemoveFromMedicationsMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeFromMedications: Array<Maybe<(
    { __typename?: 'Medication' }
    & Pick<Medication, 'id' | 'iri' | 'info'>
  )>> }
);

export type AddAddToMedicationsMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
}>;


export type AddAddToMedicationsMutationMutation = (
  { __typename?: 'Mutation' }
  & { addToMedications: Array<Maybe<(
    { __typename?: 'Medication' }
    & Pick<Medication, 'id' | 'iri' | 'info'>
  )>> }
);

export type RemoveRemoveFromConditionsMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type RemoveRemoveFromConditionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeFromConditions: Array<Maybe<(
    { __typename?: 'Condition' }
    & Pick<Condition, 'id' | 'iri' | 'info'>
  )>> }
);

export type AddAddToConditionsMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
}>;


export type AddAddToConditionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { addToConditions: Array<Maybe<(
    { __typename?: 'Condition' }
    & Pick<Condition, 'id' | 'iri' | 'info'>
  )>> }
);

export type RemoveRemoveFromAllergiesMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type RemoveRemoveFromAllergiesMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeFromAllergies: Array<Maybe<(
    { __typename?: 'Allergy' }
    & Pick<Allergy, 'id' | 'iri' | 'info'>
  )>> }
);

export type AddAddToAllergiesMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  info: Scalars['String'];
  iri?: Maybe<Scalars['String']>;
}>;


export type AddAddToAllergiesMutationMutation = (
  { __typename?: 'Mutation' }
  & { addToAllergies: Array<Maybe<(
    { __typename?: 'Allergy' }
    & Pick<Allergy, 'id' | 'iri' | 'info'>
  )>> }
);

export type SearchCodingDiagnosisQueryQueryVariables = Exact<{
  query: Scalars['String'];
  region?: Maybe<Scalars['String']>;
}>;


export type SearchCodingDiagnosisQueryQuery = (
  { __typename?: 'Query' }
  & { searchCodingDiagnosis: Array<Maybe<(
    { __typename?: 'ClinicalKnowledgeLabel' }
    & Pick<ClinicalKnowledgeLabel, 'preferredLabel' | 'iri' | 'hasNotes'>
  )>> }
);

export type GetAllergiesQueryQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetAllergiesQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { allergies?: Maybe<Array<Maybe<(
      { __typename?: 'Allergy' }
      & Pick<Allergy, 'id' | 'iri' | 'info'>
    )>>> }
  )> }
);

export type SuggestAllergiesQueryMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type SuggestAllergiesQueryMutation = (
  { __typename?: 'Mutation' }
  & { options: Array<Maybe<(
    { __typename?: 'ClinicalKnowledgeLabel' }
    & Pick<ClinicalKnowledgeLabel, 'iri' | 'suggestedLabel'>
  )>> }
);

export type GetMedicationsQueryQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetMedicationsQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { medications?: Maybe<Array<Maybe<(
      { __typename?: 'Medication' }
      & Pick<Medication, 'id' | 'iri' | 'info'>
    )>>> }
  )> }
);

export type SuggestMedicationsQueryMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type SuggestMedicationsQueryMutation = (
  { __typename?: 'Mutation' }
  & { options: Array<Maybe<(
    { __typename?: 'ClinicalKnowledgeLabel' }
    & Pick<ClinicalKnowledgeLabel, 'iri' | 'suggestedLabel'>
  )>> }
);

export type GetConditionsQueryQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type GetConditionsQueryQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { conditions?: Maybe<Array<Maybe<(
      { __typename?: 'Condition' }
      & Pick<Condition, 'id' | 'iri' | 'info'>
    )>>> }
  )> }
);

export type SuggestConditionsQueryMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type SuggestConditionsQueryMutation = (
  { __typename?: 'Mutation' }
  & { options: Array<Maybe<(
    { __typename?: 'ClinicalKnowledgeLabel' }
    & Pick<ClinicalKnowledgeLabel, 'iri' | 'suggestedLabel'>
  )>> }
);

export type SearchFastCtConceptsQueryQueryVariables = Exact<{
  term: Scalars['String'];
  semanticTags: Scalars['String'];
  pageSize: Scalars['Int'];
}>;


export type SearchFastCtConceptsQueryQuery = (
  { __typename?: 'Query' }
  & { searchFastCTConcepts: (
    { __typename?: 'SearchFastCTConceptsEntity' }
    & { concepts: Array<Maybe<(
      { __typename?: 'SearchFastCTConcepts' }
      & Pick<SearchFastCtConcepts, 'iri'>
      & { prefLabel: Array<Maybe<(
        { __typename?: 'SearchFastCTConceptEntity' }
        & Pick<SearchFastCtConceptEntity, 'value'>
      )>> }
    )>> }
  ) }
);

export type CpIncompleteConsultationWarningQueryVariables = Exact<{
  searchCriteria?: Maybe<ConsultationSearchCriteria>;
}>;


export type CpIncompleteConsultationWarningQuery = (
  { __typename?: 'Query' }
  & { consultationsv2: (
    { __typename?: 'ConsultationResults' }
    & Pick<ConsultationResults, 'totalItems'>
  ) }
);

export type CpIncompleteConsultationWarningFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'status' | 'scheduledTime' | 'patientId'>
);

export type CpMarkConsultationAsNoShowMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpMarkConsultationAsNoShowMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationStatus?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
  )> }
);

export type ConsultationLiteModuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConsultationLiteModuleQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consultationType' | 'status' | 'category' | 'patientNote' | 'scheduledTime' | 'languageRequested' | 'recordingConsent'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'first_name' | 'last_name' | 'date_of_birth' | 'email' | 'gender' | 'id_verification' | 'minor' | 'region_id' | 'account_owner_name' | 'account_owner_dob'>
    ), consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name' | 'profession_name'>
    )> }
  )> }
);

export type ConsultationStatusQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ConsultationStatusQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
  )> }
);

export type CpConsultationPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpConsultationPageQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consultationId' | 'consumerNetwork' | 'consumerNetworkId' | 'tenancyContractId' | 'consumerNetworkName' | 'gpConsent' | 'status' | 'consultationType' | 'scheduledTime' | 'languageRequested' | 'recordingConsent'>
    & { uuid: Consultation['consultationId'] }
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name' | 'role' | 'profession_name'>
    )>, permissions?: Maybe<Array<(
      { __typename?: 'ConsultationPermission' }
      & Pick<ConsultationPermission, 'id' | 'name' | 'value'>
    )>>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'iso_code'>
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'uuid'>
      & { patient_alerts?: Maybe<Array<(
        { __typename?: 'PatientAlert' }
        & Pick<PatientAlert, 'id'>
      )>> }
    ), appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & { durationMinutes: Appointment['duration_minutes'] }
    )> }
    & DiagnosisFragmentFragment
    & GpDetailsFragmentFragment
    & CpPatientDetailsFragment
    & PresentingComplaintFragment
    & ExaminationFragmentFragment
    & ReviewFragmentFragment
    & CpSickNotesFragment
    & TestsFragmentFragment
    & ReferralsFragmentFragment
    & ConsultationProfileFragment
    & StatusFragment
    & CpIncompleteConsultationWarningFragment
    & CpPreConsultationSectionFragment
  )> }
);

export type StatusFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'status' | 'scheduledTime'>
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'name' | 'profession_name'>
  )> }
);

export type EhrCheckinUsMutationVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type EhrCheckinUsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkInEhr'>
);

export type GpDetailsFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'gpConsent'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'gp_address_first_line' | 'gp_address_post_code' | 'gp_address_second_line' | 'gp_address_third_line' | 'gp_full_address' | 'gp_name' | 'gp_surgery_name' | 'gp_surgery_phone_number'>
    & { region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name'>
    )> }
  ) }
);

export type GpConsentFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'gpConsent'>
);

export type UpdateAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  gp_consent: Scalars['Boolean'];
}>;


export type UpdateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointmentGpConsent: (
    { __typename?: 'AppointmentV2' }
    & Pick<AppointmentV2, 'id' | 'gp_consent'>
  ) }
);

export type UpdateGpInAppointmentMutationMutationVariables = Exact<{
  appointmentId: Scalars['Int'];
  formData: GpInformation;
}>;


export type UpdateGpInAppointmentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateGpInAppointment?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'gp_address_first_line' | 'gp_address_post_code' | 'gp_address_second_line' | 'gp_address_third_line' | 'gp_full_address' | 'gp_name' | 'gp_surgery_name' | 'gp_surgery_phone_number'>
  )> }
);

export type UpdateAppointmentMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  gp_consent: Scalars['Boolean'];
}>;


export type UpdateAppointmentMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointmentGpConsent: (
    { __typename?: 'AppointmentV2' }
    & Pick<AppointmentV2, 'id' | 'gp_consent'>
  ) }
);

export type ConsultationGpDetailsQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ConsultationGpDetailsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'gpConsent'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'gp_address_first_line' | 'gp_address_post_code' | 'gp_address_second_line' | 'gp_address_third_line' | 'gp_full_address' | 'gp_name' | 'gp_surgery_name' | 'gp_surgery_phone_number'>
    ) }
  )> }
);

export type UpdatePatientGpDetailsMutationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  consultationId: Scalars['ID'];
  gpConsent?: Maybe<Scalars['Boolean']>;
  formData: GpInformation;
}>;


export type UpdatePatientGpDetailsMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateGP?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'gp_name' | 'gp_surgery_name' | 'gp_surgery_phone_number' | 'gp_address_first_line' | 'gp_address_post_code' | 'gp_address_second_line' | 'gp_address_third_line'>
  )>, updateConsultationGpConsent?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'gpConsent'>
  )> }
);

export type ConsultationGpDetailsQueryQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ConsultationGpDetailsQueryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'gpConsent'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'gp_name' | 'gp_surgery_name' | 'gp_surgery_phone_number' | 'gp_address_first_line' | 'gp_address_post_code' | 'gp_address_second_line' | 'gp_address_third_line'>
    ) }
  )> }
);

export type PlacesSearchQueryQueryVariables = Exact<{
  input: PlaceResourceInput;
}>;


export type PlacesSearchQueryQuery = (
  { __typename?: 'Query' }
  & { placesSearch?: Maybe<Array<Maybe<(
    { __typename?: 'PlaceResource' }
    & Pick<PlaceResource, 'name' | 'phone'>
    & { address?: Maybe<(
      { __typename?: 'PlaceResourceAddress' }
      & Pick<PlaceResourceAddress, 'line' | 'postalCode'>
    )> }
  )>>> }
);

export type CpConsultantRatingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpConsultantRatingQuery = (
  { __typename?: 'Query' }
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id'>
    & { rating?: Maybe<(
      { __typename?: 'ConsultantRating' }
      & Pick<ConsultantRating, 'average_rating' | 'number_of_ratings'>
    )> }
  )> }
);

export type AddClinicianToSelfManagedServiceTypeMutationVariables = Exact<{
  clinician_id: Scalars['ID'];
  service_type_uuid: Scalars['ID'];
}>;


export type AddClinicianToSelfManagedServiceTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCliniciansToServiceType'>
);

export type RemoveClinicianFromSelfManagedServiceTypeMutationVariables = Exact<{
  clinician_id: Scalars['ID'];
  service_type_uuid: Scalars['ID'];
}>;


export type RemoveClinicianFromSelfManagedServiceTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeClinicianFromServiceType'>
);

export type LinksAndResourcesQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;


export type LinksAndResourcesQuery = (
  { __typename?: 'Query' }
  & { cp3LinksAndResources: Array<(
    { __typename?: 'Cp3LinksAndResourcesResult' }
    & Pick<Cp3LinksAndResourcesResult, 'id' | 'title' | 'url' | 'icon'>
    & { countryCode: (
      { __typename?: 'KenticoCountryCode' }
      & Pick<KenticoCountryCode, 'name' | 'codename'>
    ), dashboardLinkTypes: (
      { __typename?: 'Cp3LinksAndResourcesType' }
      & Pick<Cp3LinksAndResourcesType, 'name' | 'codename'>
    ) }
  )> }
);

export type AppointmentInvitesFragment = (
  { __typename?: 'Consultation' }
  & { appointmentInvites?: Maybe<Array<(
    { __typename?: 'AppointmentInvite' }
    & Pick<AppointmentInvite, 'id' | 'notes_for_member'>
    & { preferredMedium: AppointmentInvite['preferred_medium'] }
    & { service_type?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'grouping'>
    )> }
  )>> }
);

export type AppointmentInvitesQueryVariables = Exact<{
  id: Scalars['ID'];
  f2fOn: Scalars['Boolean'];
}>;


export type AppointmentInvitesQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & AppointmentInvitesFragment
  )> }
);

export type CreateAppointmentInviteMutationVariables = Exact<{
  input: AppointmentInviteInput;
}>;


export type CreateAppointmentInviteMutation = (
  { __typename?: 'Mutation' }
  & { createAppointmentInvite: (
    { __typename?: 'AppointmentInvite' }
    & Pick<AppointmentInvite, 'id' | 'notes_for_member'>
    & { preferredMedium: AppointmentInvite['preferred_medium'] }
    & { service_type?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'id' | 'name' | 'grouping'>
    )> }
  ) }
);

export type CreateAppointmentWorkflowMutationVariables = Exact<{
  appointment: WorkflowAppointmentActionInput;
  data: WorkflowInput;
}>;


export type CreateAppointmentWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { createWorkflow: Array<Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'status' | 'type'>
    & { subType?: Maybe<(
      { __typename?: 'WorkflowSubType' }
      & Pick<WorkflowSubType, 'value' | 'label'>
    )> }
  )>> }
);

export type NhsAppointmentServiceTypesQueryVariables = Exact<{
  memberId?: Maybe<Scalars['ID']>;
}>;


export type NhsAppointmentServiceTypesQuery = (
  { __typename?: 'Query' }
  & { serviceTypes: Array<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'id' | 'name' | 'intimate' | 'default_duration_minutes' | 'grouping'>
    & { first_available_slot?: Maybe<(
      { __typename?: 'ServiceTypeFirstAvailableSlot' }
      & Pick<ServiceTypeFirstAvailableSlot, 'time' | 'clinician_id' | 'clinic_id'>
    )> }
  )> }
);

export type NhsAppointmentConsultationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NhsAppointmentConsultationQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consumerNetwork'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'uuid' | 'email' | 'full_name' | 'date_of_birth' | 'public_healthcare_identifier'>
      & { region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'iso_code'>
      )>, contact_details?: Maybe<(
        { __typename?: 'ContactDetails' }
        & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'address_state_code' | 'phone_country_code' | 'phone_number'>
      )>, clinical_commissioning_group?: Maybe<(
        { __typename?: 'ClinicalComissioningGroup' }
        & Pick<ClinicalComissioningGroup, 'id' | 'name' | 'code'>
      )> }
    ) }
  )> }
);

export type NoteAssistantConsentQueryVariables = Exact<{
  id: Scalars['ID'];
  speakers: Array<NoteAssistantSpeaker>;
}>;


export type NoteAssistantConsentQuery = (
  { __typename?: 'Query' }
  & { noteAssistantConsent: (
    { __typename?: 'NoteAssistantConsent' }
    & Pick<NoteAssistantConsent, 'permission_granted'>
    & { decisions: Array<(
      { __typename?: 'NoteAssistantConsentDecision' }
      & Pick<NoteAssistantConsentDecision, 'decision' | 'decline_reason' | 'speaker_id'>
    )> }
  ) }
);

export type NoteAssistantRecordingIdQueryVariables = Exact<{
  input: NoteAssistantRecordingIdInput;
}>;


export type NoteAssistantRecordingIdQuery = (
  { __typename?: 'Query' }
  & { noteAssistantRecordingId: (
    { __typename?: 'NoteAssistantRecordingId' }
    & Pick<NoteAssistantRecordingId, 'recording_id'>
  ) }
);

export type NoteAssistantNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NoteAssistantNotesQuery = (
  { __typename?: 'Query' }
  & { noteAssistantNotes: (
    { __typename?: 'NoteAssistantNote' }
    & Pick<NoteAssistantNote, 'notes' | 'final' | 'timestamp' | 'error'>
  ) }
);

export type CreatePatientAlertMutationVariables = Exact<{
  patientId: Scalars['ID'];
  message: Scalars['String'];
}>;


export type CreatePatientAlertMutation = (
  { __typename?: 'Mutation' }
  & { createPatientAlert?: Maybe<(
    { __typename?: 'PatientAlert' }
    & Pick<PatientAlert, 'id' | 'message' | 'updatedAt'>
  )> }
);

export type RemovePatientAlertMutationVariables = Exact<{
  id: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
}>;


export type RemovePatientAlertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePatientAlert'>
);

export type UpdatePatientAlertMutationVariables = Exact<{
  id: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
}>;


export type UpdatePatientAlertMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientAlert?: Maybe<(
    { __typename?: 'PatientAlert' }
    & Pick<PatientAlert, 'id' | 'message' | 'updatedAt'>
  )> }
);

export type PatientAlertQueryVariables = Exact<{
  id: Scalars['ID'];
  patientId?: Maybe<Scalars['ID']>;
}>;


export type PatientAlertQuery = (
  { __typename?: 'Query' }
  & { patient_alert?: Maybe<(
    { __typename?: 'PatientAlert' }
    & Pick<PatientAlert, 'id' | 'message' | 'updatedAt'>
  )> }
);

export type ConsultationAlertsQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ConsultationAlertsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'promoCodeMessageForGp' | 'membershipCodeMessageForGp'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'uuid'>
      & { patient_alerts?: Maybe<Array<(
        { __typename?: 'PatientAlert' }
        & Pick<PatientAlert, 'id' | 'message' | 'updatedAt'>
      )>>, canCreatePrescriptionPdf?: Maybe<(
        { __typename?: 'Validity' }
        & Pick<Validity, 'valid' | 'errorCodes'>
      )> }
    ) }
  )> }
);

export type UpdatePatientDetailsMutationVariables = Exact<{
  patientId: Scalars['Int'];
  input: ProfileInformation;
}>;


export type UpdatePatientDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updatePatientDetails: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'first_name' | 'last_name' | 'gender' | 'email' | 'date_of_birth' | 'id_verification'>
    & { contact_details?: Maybe<(
      { __typename?: 'ContactDetails' }
      & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_state_code' | 'address_post_code' | 'phone_country_code' | 'phone_number'>
    )>, next_of_kin?: Maybe<(
      { __typename?: 'NextOfKin' }
      & Pick<NextOfKin, 'id' | 'first_name' | 'last_name' | 'phone_number' | 'relation'>
    )>, identity_check?: Maybe<(
      { __typename?: 'IdentityCheck' }
      & Pick<IdentityCheck, 'id' | 'status' | 'check_id' | 'verification_overwritten_at'>
      & { verification_overwritten_by?: Maybe<(
        { __typename?: 'PortalUser' }
        & Pick<PortalUser, 'id' | 'fullName'>
      )> }
    )> }
  ) }
);

export type OverrideIdentityCheckMutationVariables = Exact<{
  patientId: Scalars['ID'];
  identityCheckId?: Maybe<Scalars['ID']>;
}>;


export type OverrideIdentityCheckMutation = (
  { __typename?: 'Mutation' }
  & { overrideIdentityCheck?: Maybe<(
    { __typename?: 'IdentityCheck' }
    & Pick<IdentityCheck, 'id' | 'status' | 'check_id'>
  )> }
);

export type PatientDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientDetailsQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name' | 'iso_code'>
    )>, contact_details?: Maybe<(
      { __typename?: 'ContactDetails' }
      & Pick<ContactDetails, 'address_first_line' | 'address_post_code' | 'address_second_line' | 'address_state_code' | 'address_third_line' | 'phone_country_code' | 'phone_number'>
    )>, next_of_kin?: Maybe<(
      { __typename?: 'NextOfKin' }
      & Pick<NextOfKin, 'id' | 'first_name' | 'last_name' | 'phone_number' | 'relation'>
    )>, account_owner_contact_details?: Maybe<(
      { __typename?: 'ContactDetails' }
      & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'phone_country_code' | 'phone_number'>
    )> }
  )> }
);

export type CpPatientDetailsFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'minor' | 'account_owner_name' | 'account_owner_dob' | 'first_name' | 'last_name' | 'full_name' | 'date_of_birth' | 'email' | 'gender' | 'id_verification' | 'phone_number' | 'public_healthcare_identifier' | 'profile_locked'>
    & { account_owner_contact_details?: Maybe<(
      { __typename?: 'ContactDetails' }
      & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'phone_country_code' | 'phone_number'>
    )>, identity_check?: Maybe<(
      { __typename?: 'IdentityCheck' }
      & Pick<IdentityCheck, 'id' | 'status' | 'check_id' | 'verification_overwritten_at'>
      & { verification_overwritten_by?: Maybe<(
        { __typename?: 'PortalUser' }
        & Pick<PortalUser, 'id' | 'fullName'>
      )> }
    )>, contact_details?: Maybe<(
      { __typename?: 'ContactDetails' }
      & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'address_state_code' | 'phone_country_code' | 'phone_number'>
    )>, region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'name' | 'iso_code'>
    )>, next_of_kin?: Maybe<(
      { __typename?: 'NextOfKin' }
      & Pick<NextOfKin, 'id' | 'first_name' | 'last_name' | 'phone_number' | 'relation'>
    )>, profile_locks: Array<(
      { __typename?: 'ProfileLock' }
      & Pick<ProfileLock, 'name' | 'locked_at'>
    )> }
  ) }
);

export type PatientMetricsFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'consultationType'>
  & { uuid: Consultation['consultationId'] }
  & { consultant?: Maybe<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'id' | 'uuid' | 'role'>
  )>, patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'uuid' | 'age' | 'date_of_birth'>
    & { patient_metrics?: Maybe<(
      { __typename?: 'PatientMetrics' }
      & Pick<PatientMetrics, 'id'>
      & { height: Array<(
        { __typename?: 'PatientMetric' }
        & Pick<PatientMetric, 'id' | 'value' | 'timestamp' | 'performer'>
      )>, weight: Array<(
        { __typename?: 'PatientMetric' }
        & Pick<PatientMetric, 'id' | 'value' | 'timestamp' | 'performer'>
      )>, smoking_status: Array<(
        { __typename?: 'PatientMetric' }
        & Pick<PatientMetric, 'id' | 'value' | 'timestamp' | 'performer'>
      )>, blood_pressure: Array<(
        { __typename?: 'PatientMetric' }
        & Pick<PatientMetric, 'id' | 'performer' | 'timestamp'>
        & { values?: Maybe<Array<Maybe<(
          { __typename?: 'PatientMetricValue' }
          & Pick<PatientMetricValue, 'code' | 'value'>
        )>>> }
      )> }
    )> }
  ) }
);

export type ConsultationPatientMetricsQueryVariables = Exact<{
  id: Scalars['ID'];
  patientMetricsFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  patientMetricsCount?: Maybe<Scalars['Int']>;
  bloodPressureOn: Scalars['Boolean'];
}>;


export type ConsultationPatientMetricsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & PatientMetricsFragment
  )> }
);

export type AddPatientMetricMutationVariables = Exact<{
  patientUuid: Scalars['ID'];
  patientId: Scalars['ID'];
  clinicianUuid?: Maybe<Scalars['ID']>;
  clinicianId?: Maybe<Scalars['ID']>;
  consultationId: Scalars['ID'];
  clinicianRole?: Maybe<Scalars['String']>;
  observationType: PatientMetricObservationType;
  input: Scalars['JSON'];
  patientMetricsFilter: PatientMetricSource;
  consultationType?: Maybe<Scalars['String']>;
  consultationUuid: Scalars['String'];
}>;


export type AddPatientMetricMutation = (
  { __typename?: 'Mutation' }
  & { addPatientMetric?: Maybe<(
    { __typename?: 'PatientMetric' }
    & Pick<PatientMetric, 'id' | 'value' | 'timestamp' | 'performer'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'PatientMetricValue' }
      & Pick<PatientMetricValue, 'code' | 'value'>
    )>>> }
  )> }
);

export type ConsultationsForProblemsListQueryVariables = Exact<{
  searchCriteria?: Maybe<ConsultationSearchCriteria>;
}>;


export type ConsultationsForProblemsListQuery = (
  { __typename?: 'Query' }
  & { consultationsv2: (
    { __typename?: 'ConsultationResults' }
    & Pick<ConsultationResults, 'totalItems'>
    & { items: Array<(
      { __typename?: 'Consultation' }
      & Pick<Consultation, 'id' | 'status' | 'scheduledTime'>
      & { consultant?: Maybe<(
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'id' | 'uuid' | 'name' | 'role' | 'profession_name'>
      )> }
    )> }
  ) }
);

export type TimelineConsultationQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type TimelineConsultationQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status' | 'patientNote'>
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'uuid' | 'name' | 'role' | 'profession_name'>
    )>, consultationNote?: Maybe<(
      { __typename?: 'ConsultationNote' }
      & Pick<ConsultationNote, 'id' | 'assessment'>
      & { assessmentCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>> }
    )>, images: (
      { __typename?: 'ConsultationImages' }
      & Pick<ConsultationImages, 'items'>
    ) | { __typename?: 'RetrievalError' } }
  )> }
);

export type TimelineConsultationActionsQueryVariables = Exact<{
  consultationId: Scalars['ID'];
  prescriptionNotAllowed: Scalars['Boolean'];
}>;


export type TimelineConsultationActionsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & { prescriptions: Array<(
      { __typename?: 'Prescription' }
      & Pick<Prescription, 'id'>
      & { drugs: Array<(
        { __typename?: 'PrescriptionDrug' }
        & Pick<PrescriptionDrug, 'prescriptionDrugId' | 'drugName' | 'directions' | 'quantity'>
      )> }
    )>, diagnosticTests: Array<(
      { __typename?: 'ConsultationTest' }
      & Pick<ConsultationTest, 'id' | 'type' | 'testId'>
    )>, pathologyTests: Array<(
      { __typename?: 'ConsultationTest' }
      & Pick<ConsultationTest, 'id' | 'type' | 'testId'>
    )>, referrals?: Maybe<Array<Maybe<(
      { __typename?: 'ConsultationReferral' }
      & Pick<ConsultationReferral, 'id' | 'specialismName'>
    )>>>, testResults?: Maybe<Array<Maybe<(
      { __typename?: 'ConsultationTestResult' }
      & Pick<ConsultationTestResult, 'id'>
    )>>>, workflowsV2?: Maybe<Array<(
      { __typename?: 'WorkflowV2' }
      & Pick<WorkflowV2, 'id' | 'state'>
      & { variables: Array<(
        { __typename?: 'WorkflowVariable' }
        & Pick<WorkflowVariable, 'name' | 'value'>
      )>, workflowDefinition?: Maybe<(
        { __typename?: 'WorkflowDefinition' }
        & Pick<WorkflowDefinition, 'id' | 'roles' | 'workflow_definition_name'>
        & { group: (
          { __typename?: 'WorkflowDefinitionGroup' }
          & Pick<WorkflowDefinitionGroup, 'type' | 'label'>
        ) }
      )> }
    )>>, sickNotes: Array<(
      { __typename?: 'ConsultationSickNote' }
      & Pick<ConsultationSickNote, 'id'>
    )> }
  )> }
);

export type CurrentConsultationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CurrentConsultationQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
    & { consultant?: Maybe<(
      { __typename?: 'Consultant' }
      & Pick<Consultant, 'id' | 'name' | 'role'>
    )> }
  )> }
);

export type PatientTimelineTestReportFragmentFragment = (
  { __typename?: 'TestReport' }
  & Pick<TestReport, 'id' | 'name' | 'eventTime' | 'eventType'>
  & { sender?: Maybe<(
    { __typename?: 'TestReportClinician' }
    & Pick<TestReportClinician, 'fullName'>
  )> }
);

export type PatientTimelineConsultationFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'eventType' | 'eventTime' | 'consultationType'>
);

export type PatientTimelineChatscriptConversationFragmentFragment = (
  { __typename?: 'ChatscriptConversation' }
  & Pick<ChatscriptConversation, 'id' | 'preview' | 'eventTime' | 'eventType' | 'status' | 'type'>
  & { result?: Maybe<(
    { __typename?: 'ChatscriptConversationResult' }
    & Pick<ChatscriptConversationResult, 'type' | 'outcome'>
  )> }
);

export type PatientTimelineQueryVariables = Exact<{
  patientId: Scalars['ID'];
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  chatscriptOn: Scalars['Boolean'];
}>;


export type PatientTimelineQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { futureConsultations?: Maybe<Array<(
      { __typename?: 'Consultation' }
      & PatientTimelineConsultationFragmentFragment
    ) | { __typename?: 'PatientQuestion' } | { __typename?: 'ChatscriptConversation' } | { __typename?: 'BloodTest' } | { __typename?: 'TestReport' } | { __typename?: 'WorkflowV2' } | { __typename?: 'RepeatTemplate' } | { __typename?: 'TimelineChatConversation' }>>, historyV2: (
      { __typename?: 'PatientHistory' }
      & { errors: Array<(
        { __typename?: 'PatientHistoryError' }
        & Pick<PatientHistoryError, 'type' | 'message' | 'fullError'>
      )>, events: Array<(
        { __typename?: 'Consultation' }
        & PatientTimelineConsultationFragmentFragment
      ) | { __typename?: 'PatientQuestion' } | (
        { __typename?: 'ChatscriptConversation' }
        & PatientTimelineChatscriptConversationFragmentFragment
      ) | { __typename?: 'BloodTest' } | (
        { __typename?: 'TestReport' }
        & PatientTimelineTestReportFragmentFragment
      ) | { __typename?: 'WorkflowV2' } | { __typename?: 'RepeatTemplate' } | { __typename?: 'TimelineChatConversation' }> }
    ) }
  )> }
);

export type CpPreConsultationSectionFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'preConsultationFlowId'>
  & { patient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'uuid'>
  ) }
);

export type CpPreConsConversationQueryVariables = Exact<{
  eventId: Scalars['ID'];
  patientUuid: Scalars['ID'];
}>;


export type CpPreConsConversationQuery = (
  { __typename?: 'Query' }
  & { chatscriptConversation?: Maybe<(
    { __typename?: 'ChatscriptConversation' }
    & Pick<ChatscriptConversation, 'id' | 'startedAt'>
  )> }
);

export type PreconsultationSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PreconsultationSummaryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'preConsultationFlowId'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'uuid'>
    ) }
  )> }
);

export type SignPrescriptionMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PrescriptionSignatureRequestInput;
}>;


export type SignPrescriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { signPrescription: (
    { __typename?: 'Prescription' }
    & Pick<Prescription, 'id'>
  ) }
);

export type ConfirmPrescriptionModalQueryVariables = Exact<{
  prescriptionId?: Maybe<Scalars['ID']>;
}>;


export type ConfirmPrescriptionModalQuery = (
  { __typename?: 'Query' }
  & { prescriptions?: Maybe<(
    { __typename?: 'Prescriptions' }
    & { items: Array<Maybe<(
      { __typename?: 'Prescription' }
      & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState' | 'actions'>
      & { patient?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'first_name' | 'last_name' | 'date_of_birth' | 'age' | 'gender'>
        & { canCreatePrescriptionPdf?: Maybe<(
          { __typename?: 'Validity' }
          & Pick<Validity, 'valid' | 'errorCodes'>
        )> }
      )>, drugs: Array<(
        { __typename?: 'PrescriptionDrug' }
        & Pick<PrescriptionDrug, 'prescriptionDrugId' | 'drugId' | 'drugName' | 'dosage' | 'duration' | 'pharmaceuticalForm' | 'strength' | 'quantity' | 'packagingSize' | 'directions' | 'notes' | 'repeatable' | 'repeats' | 'reviewDate'>
      )> }
    )>> }
  )> }
);

export type SuggestedDrugsQueryQueryVariables = Exact<{
  query: Scalars['String'];
  region: Scalars['String'];
}>;


export type SuggestedDrugsQueryQuery = (
  { __typename?: 'Query' }
  & { drugs: Array<Maybe<(
    { __typename?: 'Drug' }
    & Pick<Drug, 'id' | 'name' | 'generic' | 'expensive' | 'strength' | 'controlled' | 'blacklisted' | 'drugPreparation' | 'activeIngredients' | 'pharmaceuticalForm'>
    & { packaging?: Maybe<Array<Maybe<(
      { __typename?: 'DrugPackaging' }
      & Pick<DrugPackaging, 'packagingId' | 'packageSize'>
    )>>> }
  )>> }
);

export type SuggestDrugDirectionsMutationMutationVariables = Exact<{
  drugId?: Maybe<Scalars['ID']>;
  drugName: Scalars['String'];
  dosage: Scalars['String'];
  duration: Scalars['String'];
  region: Scalars['String'];
}>;


export type SuggestDrugDirectionsMutationMutation = (
  { __typename?: 'Mutation' }
  & { suggestDrugDirections?: Maybe<(
    { __typename?: 'DrugDirections' }
    & Pick<DrugDirections, 'drugId' | 'drugName' | 'directions' | 'dosage' | 'duration' | 'quantity' | 'errorInDosage' | 'errorInDuration' | 'errorInDirections'>
  )> }
);

export type ValidateDrugMutationVariables = Exact<{
  patientId: Scalars['ID'];
  drug: PrescriptionDrugInput;
  consultationId?: Maybe<Scalars['ID']>;
  region: Scalars['String'];
  prescriptionDrugId?: Maybe<Scalars['ID']>;
}>;


export type ValidateDrugMutation = (
  { __typename?: 'Mutation' }
  & { validateDrug?: Maybe<(
    { __typename?: 'PrescriptionValidation' }
    & { warnings?: Maybe<Array<Maybe<(
      { __typename?: 'PrescriptionValidationWarning' }
      & Pick<PrescriptionValidationWarning, 'action' | 'message' | 'allowPrescribing'>
    )>>> }
  )> }
);

export type PrescriptionFieldsFragmentModalFragment = (
  { __typename?: 'Prescription' }
  & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState'>
  & { drugs: Array<(
    { __typename?: 'PrescriptionDrug' }
    & Pick<PrescriptionDrug, 'prescriptionDrugId' | 'drugId' | 'drugName' | 'dosage' | 'duration' | 'packagingSize' | 'pharmaceuticalForm' | 'strength' | 'refills' | 'quantity' | 'directions' | 'notes' | 'repeatable' | 'reviewDate' | 'repeats'>
    & { indications?: Maybe<Array<Maybe<(
      { __typename?: 'PrescriptionDrugIndication' }
      & Pick<PrescriptionDrugIndication, 'iri' | 'id' | 'name'>
    )>>> }
  )> }
);

export type CreatePrescriptionMutationMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
  drugs?: Maybe<Array<Maybe<PrescriptionDrugInput>>>;
  consumerNetwork: Scalars['String'];
  region: Scalars['String'];
}>;


export type CreatePrescriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { createPrescription?: Maybe<(
    { __typename?: 'Prescription' }
    & PrescriptionFieldsFragmentModalFragment
  )> }
);

export type UpdateDrugInPrescriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  prescriptionDrugId: Scalars['ID'];
  drug: PrescriptionDrugInput;
  region: Scalars['String'];
}>;


export type UpdateDrugInPrescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateDrugInPrescription?: Maybe<(
    { __typename?: 'Prescription' }
    & PrescriptionFieldsFragmentModalFragment
  )> }
);

export type AddDrugToPrescriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  drug: PrescriptionDrugInput;
  region: Scalars['String'];
}>;


export type AddDrugToPrescriptionMutation = (
  { __typename?: 'Mutation' }
  & { addDrugToPrescription?: Maybe<(
    { __typename?: 'Prescription' }
    & PrescriptionFieldsFragmentModalFragment
  )> }
);

export type DrugQueryQueryVariables = Exact<{
  drugId: Scalars['ID'];
  region: Scalars['String'];
}>;


export type DrugQueryQuery = (
  { __typename?: 'Query' }
  & { drug?: Maybe<(
    { __typename?: 'Drug' }
    & Pick<Drug, 'id' | 'name' | 'generic' | 'expensive' | 'strength' | 'controlled' | 'blacklisted' | 'drugPreparation' | 'activeIngredients' | 'pharmaceuticalForm'>
    & { packaging?: Maybe<Array<Maybe<(
      { __typename?: 'DrugPackaging' }
      & Pick<DrugPackaging, 'packagingId' | 'packageSize'>
    )>>> }
  )> }
);

export type PrescriptionAuditQueryQueryVariables = Exact<{
  prescriptionId: Scalars['ID'];
  eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type PrescriptionAuditQueryQuery = (
  { __typename?: 'Query' }
  & { prescriptionAudit?: Maybe<Array<Maybe<(
    { __typename?: 'PrescriptionAudit' }
    & Pick<PrescriptionAudit, 'id' | 'babylonRequestId' | 'consumerNetwork' | 'eventDetails' | 'eventTimestamp' | 'loginType' | 'eventType' | 'performedByName'>
  )>>> }
);

export type PrescriptionAuditQueryVariables = Exact<{
  prescriptionId: Scalars['ID'];
}>;


export type PrescriptionAuditQuery = (
  { __typename?: 'Query' }
  & { prescriptionAudit?: Maybe<Array<Maybe<(
    { __typename?: 'PrescriptionAudit' }
    & Pick<PrescriptionAudit, 'id' | 'babylonRequestId' | 'consumerNetwork' | 'eventDetails' | 'eventTimestamp' | 'loginType' | 'eventType' | 'performedByName'>
  )>>> }
);

export type VoidPrescriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  reason: Scalars['String'];
}>;


export type VoidPrescriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'voidPrescription'>
);

export type MarkPrescriptionPrintedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MarkPrescriptionPrintedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markPrescriptionPrinted'>
);

export type CpPrescriptionsQueryVariables = Exact<{
  consultantId: Scalars['ID'];
  page: Scalars['Int'];
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  signatureState?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['String']>;
}>;


export type CpPrescriptionsQuery = (
  { __typename?: 'Query' }
  & { prescriptions?: Maybe<(
    { __typename?: 'Prescriptions' }
    & Pick<Prescriptions, 'totalItems' | 'itemsPerPage' | 'pageIndex'>
    & { items: Array<Maybe<(
      { __typename?: 'Prescription' }
      & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState' | 'actions' | 'pharmacyDetails'>
      & { consultation?: Maybe<(
        { __typename?: 'Consultation' }
        & Pick<Consultation, 'id'>
      )>, patient?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'first_name' | 'last_name'>
      )>, drugs: Array<(
        { __typename?: 'PrescriptionDrug' }
        & Pick<PrescriptionDrug, 'drugName' | 'packagingSize' | 'directions' | 'quantity'>
      )> }
    )>> }
  )> }
);

export type PrescriptionsStatsQueryVariables = Exact<{
  consultantId: Scalars['ID'];
}>;


export type PrescriptionsStatsQuery = (
  { __typename?: 'Query' }
  & { prescriptionStatistics?: Maybe<(
    { __typename?: 'PrescriptionStatistics' }
    & Pick<PrescriptionStatistics, 'id' | 'toBePrintedCount' | 'toBeSignedCount'>
  )> }
);

export type PrescriptionsQueryQueryVariables = Exact<{
  consultantId: Scalars['ID'];
  page: Scalars['Int'];
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  signatureState?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['String']>;
}>;


export type PrescriptionsQueryQuery = (
  { __typename?: 'Query' }
  & { prescriptionStatistics?: Maybe<(
    { __typename?: 'PrescriptionStatistics' }
    & Pick<PrescriptionStatistics, 'id' | 'toBePrintedCount' | 'toBeSignedCount'>
  )>, prescriptions?: Maybe<(
    { __typename?: 'Prescriptions' }
    & Pick<Prescriptions, 'totalItems' | 'itemsPerPage' | 'pageIndex'>
    & { items: Array<Maybe<(
      { __typename?: 'Prescription' }
      & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState' | 'actions' | 'pharmacyDetails'>
      & { consultation?: Maybe<(
        { __typename?: 'Consultation' }
        & Pick<Consultation, 'id'>
      )>, patient?: Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'first_name' | 'last_name'>
      )>, drugs: Array<(
        { __typename?: 'PrescriptionDrug' }
        & Pick<PrescriptionDrug, 'drugName' | 'packagingSize' | 'directions' | 'quantity'>
      )> }
    )>> }
  )> }
);

export type PrescriptionsInfoQueryVariables = Exact<{
  consultantId: Scalars['ID'];
}>;


export type PrescriptionsInfoQuery = (
  { __typename?: 'Query' }
  & { prescriptionStatistics?: Maybe<(
    { __typename?: 'PrescriptionStatistics' }
    & Pick<PrescriptionStatistics, 'id' | 'toBePrintedCount' | 'toBeSignedCount'>
  )> }
);

export type PrescriptionFieldsFragmentFragment = (
  { __typename?: 'Prescription' }
  & Pick<Prescription, 'id' | 'actions' | 'createdAt' | 'prescriptionState'>
  & { drugs: Array<(
    { __typename?: 'PrescriptionDrug' }
    & Pick<PrescriptionDrug, 'prescriptionDrugId' | 'drugId' | 'drugName' | 'dosage' | 'duration' | 'pharmaceuticalForm' | 'strength' | 'quantity' | 'packagingSize' | 'directions' | 'notes' | 'repeatable' | 'repeats' | 'refills' | 'reviewDate'>
    & { indications?: Maybe<Array<Maybe<(
      { __typename?: 'PrescriptionDrugIndication' }
      & Pick<PrescriptionDrugIndication, 'id' | 'name' | 'iri'>
    )>>> }
  )> }
);

export type RemovePrescriptionMutationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemovePrescriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePrescription'>
);

export type RemoveDrugFromPrescriptionMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  prescriptionDrugId: Scalars['ID'];
}>;


export type RemoveDrugFromPrescriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { removeDrugFromPrescription?: Maybe<(
    { __typename?: 'Prescription' }
    & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState'>
    & { drugs: Array<(
      { __typename?: 'PrescriptionDrug' }
      & Pick<PrescriptionDrug, 'prescriptionDrugId' | 'drugId' | 'drugName' | 'dosage' | 'duration' | 'pharmaceuticalForm' | 'strength' | 'quantity' | 'directions' | 'notes'>
    )> }
  )> }
);

export type PrescriptionChangeStateFromConsultationMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  action: PrescriptionStateActionType;
  region: Scalars['String'];
}>;


export type PrescriptionChangeStateFromConsultationMutationMutation = (
  { __typename?: 'Mutation' }
  & { prescriptionChangeState?: Maybe<(
    { __typename?: 'Prescription' }
    & Pick<Prescription, 'id' | 'createdAt' | 'prescriptionState' | 'actions'>
  )> }
);

export type ValidateDrugMutationMutationVariables = Exact<{
  patientId: Scalars['ID'];
  drug: PrescriptionDrugInput;
  consultationId?: Maybe<Scalars['ID']>;
  region: Scalars['String'];
}>;


export type ValidateDrugMutationMutation = (
  { __typename?: 'Mutation' }
  & { validateDrug?: Maybe<(
    { __typename?: 'PrescriptionValidation' }
    & { warnings?: Maybe<Array<Maybe<(
      { __typename?: 'PrescriptionValidationWarning' }
      & Pick<PrescriptionValidationWarning, 'action' | 'message' | 'allowPrescribing'>
    )>>> }
  )> }
);

export type AddDrugToPrescriptionMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  drug: PrescriptionDrugInput;
  region: Scalars['String'];
}>;


export type AddDrugToPrescriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { addDrugToPrescription?: Maybe<(
    { __typename?: 'Prescription' }
    & PrescriptionFieldsFragmentFragment
  )> }
);

export type CreatePrescriptionFromConsultationMutationMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  patientId: Scalars['ID'];
  drugs?: Maybe<Array<Maybe<PrescriptionDrugInput>>>;
  consumerNetwork: Scalars['String'];
  region: Scalars['String'];
}>;


export type CreatePrescriptionFromConsultationMutationMutation = (
  { __typename?: 'Mutation' }
  & { createPrescription?: Maybe<(
    { __typename?: 'Prescription' }
    & PrescriptionFieldsFragmentFragment
  )> }
);

export type DuplicatePrescriptionMutationMutationVariables = Exact<{
  prescriptionId: Scalars['ID'];
}>;


export type DuplicatePrescriptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { duplicatePrescription: (
    { __typename?: 'Prescription' }
    & PrescriptionFieldsFragmentFragment
  ) }
);

export type PrescriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PrescriptionQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'iso_code'>
    )>, consultationNote?: Maybe<(
      { __typename?: 'ConsultationNote' }
      & Pick<ConsultationNote, 'id'>
      & { assessmentCodes?: Maybe<Array<(
        { __typename?: 'ConsultationNoteCode' }
        & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
      )>> }
    )>, drugDosageOptions?: Maybe<Array<Maybe<(
      { __typename?: 'DrugDosage' }
      & Pick<DrugDosage, 'shortcut' | 'description'>
    )>>>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { canCreatePrescriptionPdf?: Maybe<(
        { __typename?: 'Validity' }
        & Pick<Validity, 'valid' | 'errorCodes'>
      )> }
    ), prescriptions: Array<(
      { __typename?: 'Prescription' }
      & PrescriptionFieldsFragmentFragment
    )> }
  )> }
);

export type GetClinicalNotesForPrescriptionsQueryQueryVariables = Exact<{
  iris: Array<Maybe<ClinicalCodeInput>>;
  resolveDrugs?: Maybe<Scalars['Boolean']>;
}>;


export type GetClinicalNotesForPrescriptionsQueryQuery = (
  { __typename?: 'Query' }
  & { getClinicalNotes: Array<Maybe<(
    { __typename?: 'ClinicalNote' }
    & Pick<ClinicalNote, 'furtherActionNotes' | 'managementNotes' | 'iri' | 'name' | 'guidelines'>
    & { treatmentPlans?: Maybe<Array<Maybe<(
      { __typename?: 'TreatmentPlan' }
      & Pick<TreatmentPlan, 'ageGroup' | 'penicillinAllergicOption'>
      & { prescription?: Maybe<Array<Maybe<(
        { __typename?: 'SuggestedPrescription' }
        & Pick<SuggestedPrescription, 'drugId' | 'drugName' | 'strength' | 'duration' | 'quantity' | 'dosage' | 'directions' | 'guidance'>
      )>>> }
    )>>> }
  )>> }
);

export type GetPrescriptionQueryQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type GetPrescriptionQueryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
    ), prescriptions: Array<(
      { __typename?: 'Prescription' }
      & PrescriptionFieldsFragmentFragment
    )> }
  )> }
);

export type ProblemsListQueryVariables = Exact<{
  patientId: Scalars['ID'];
  eventTypes?: Maybe<Array<Maybe<TimelineEventType>>>;
  consultationStatuses?: Maybe<Array<Maybe<ConsultationStatus>>>;
}>;


export type ProblemsListQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { history?: Maybe<Array<(
      { __typename?: 'Consultation' }
      & Pick<Consultation, 'id' | 'eventType' | 'status' | 'scheduledTime' | 'consultantId'>
      & { consultationNote?: Maybe<(
        { __typename?: 'ConsultationNote' }
        & Pick<ConsultationNote, 'id' | 'assessment'>
        & { assessmentCodes?: Maybe<Array<(
          { __typename?: 'ConsultationNoteCode' }
          & Pick<ConsultationNoteCode, 'code' | 'term' | 'length' | 'offset'>
        )>> }
      )> }
    ) | { __typename?: 'PatientQuestion' } | { __typename?: 'ChatscriptConversation' } | { __typename?: 'BloodTest' } | { __typename?: 'TestReport' } | { __typename?: 'WorkflowV2' } | { __typename?: 'RepeatTemplate' } | { __typename?: 'TimelineChatConversation' }>> }
  )> }
);

export type CreateReferralMutationMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  input: ConsultationReferralInput;
}>;


export type CreateReferralMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultationReferral?: Maybe<(
    { __typename?: 'ConsultationReferral' }
    & Pick<ConsultationReferral, 'id' | 'consultationId' | 'specialismId' | 'specialismCategoryId' | 'specialismName' | 'updatedAt' | 'urgent'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'ReferralComment' }
      & Pick<ReferralComment, 'id' | 'note'>
    )>>>, externalSpecialist?: Maybe<(
      { __typename?: 'ReferralExternalSpecialist' }
      & Pick<ReferralExternalSpecialist, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateReferralMutationMutationVariables = Exact<{
  referralId: Scalars['ID'];
  input: ConsultationReferralInput;
}>;


export type UpdateReferralMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateReferral: (
    { __typename?: 'ConsultationReferral' }
    & Pick<ConsultationReferral, 'id' | 'consultationId' | 'specialismId' | 'specialismCategoryId' | 'specialismName' | 'updatedAt' | 'urgent'>
  ) }
);

export type SpecialismsQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type SpecialismsQuery = (
  { __typename?: 'Query' }
  & { specialismCategories: Array<Maybe<(
    { __typename?: 'SpecialismCategory' }
    & Pick<SpecialismCategory, 'name' | 'id'>
    & { specialisms: Array<Maybe<(
      { __typename?: 'Specialism' }
      & Pick<Specialism, 'name' | 'id'>
    )>> }
  )>> }
);

export type ReferralsQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type ReferralsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'address_post_code'>
    ), region?: Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'id' | 'iso_code'>
    )>, referrals?: Maybe<Array<Maybe<(
      { __typename?: 'ConsultationReferral' }
      & Pick<ConsultationReferral, 'id' | 'specialismCategoryId' | 'specialismId' | 'specialismName' | 'urgent'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'ReferralComment' }
        & Pick<ReferralComment, 'id' | 'note'>
      )>>> }
    )>>> }
  )> }
);

export type ReferralsSpecialistsQueryQueryVariables = Exact<{
  input?: Maybe<ReferralResourceInput>;
}>;


export type ReferralsSpecialistsQueryQuery = (
  { __typename?: 'Query' }
  & { referralSearch?: Maybe<Array<Maybe<(
    { __typename?: 'ReferralResource' }
    & { location?: Maybe<Array<Maybe<(
      { __typename?: 'ReferralLocation' }
      & Pick<ReferralLocation, 'name'>
      & { address?: Maybe<(
        { __typename?: 'ReferralLocationAddress' }
        & Pick<ReferralLocationAddress, 'line' | 'city' | 'state' | 'postalCode' | 'country'>
      )> }
    )>>>, specialty?: Maybe<Array<Maybe<(
      { __typename?: 'ReferralSpecialty' }
      & { coding?: Maybe<Array<Maybe<(
        { __typename?: 'ReferralSpecialtyCoding' }
        & Pick<ReferralSpecialtyCoding, 'system' | 'display'>
      )>>> }
    )>>>, telecom?: Maybe<Array<Maybe<(
      { __typename?: 'ReferralTelecom' }
      & Pick<ReferralTelecom, 'system' | 'use' | 'value' | 'rank'>
    )>>>, practitioner?: Maybe<(
      { __typename?: 'ReferralPractitioner' }
      & Pick<ReferralPractitioner, 'id' | 'name' | 'gender' | 'language'>
    )> }
  )>>> }
);

export type ReferralsFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id'>
  & { referrals?: Maybe<Array<Maybe<(
    { __typename?: 'ConsultationReferral' }
    & Pick<ConsultationReferral, 'id' | 'specialismCategoryId' | 'specialismId' | 'specialismName'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'ReferralComment' }
      & Pick<ReferralComment, 'id' | 'note'>
    )>>>, externalSpecialist?: Maybe<(
      { __typename?: 'ReferralExternalSpecialist' }
      & Pick<ReferralExternalSpecialist, 'id' | 'name'>
    )> }
  )>>> }
);

export type RemoveReferralMutationVariables = Exact<{
  referralId: Scalars['ID'];
}>;


export type RemoveReferralMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeReferral'>
);

export type ReferralsSectionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReferralsSectionQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & ReferralsFragmentFragment
  )> }
);

export type ReferralsSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReferralsSummaryQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & ReferralsFragmentFragment
  )> }
);

export type ReleaseNotesQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;


export type ReleaseNotesQuery = (
  { __typename?: 'Query' }
  & { cp3ReleaseNotes: Array<(
    { __typename?: 'Cp3ReleaseNotesResult' }
    & Pick<Cp3ReleaseNotesResult, 'id' | 'versionNumber' | 'releaseDate' | 'changes' | 'title'>
    & { countryCode: (
      { __typename?: 'KenticoCountryCode' }
      & Pick<KenticoCountryCode, 'name' | 'codename'>
    ) }
  )> }
);

export type IncompleteConsultationsQueryVariables = Exact<{
  searchCriteria?: Maybe<ConsultationSearchCriteria>;
}>;


export type IncompleteConsultationsQuery = (
  { __typename?: 'Query' }
  & { consultationsv2: (
    { __typename?: 'ConsultationResults' }
    & Pick<ConsultationResults, 'totalItems'>
    & { items: Array<(
      { __typename?: 'Consultation' }
      & Pick<Consultation, 'id'>
    )> }
  ) }
);

export type ConsultationScheduleItemDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConsultationScheduleItemDetailsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & ConsultationScheduleItemDetailsFragment
  )> }
);

export type ClinicianAvailabilityShiftsQueryVariables = Exact<{
  consultantId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type ClinicianAvailabilityShiftsQuery = (
  { __typename?: 'Query' }
  & { clinicianAvailabilityShifts: (
    { __typename?: 'ClinicianAvailabilityShifts' }
    & { items: Array<(
      { __typename?: 'ClinicianAvailabilityShift' }
      & Pick<ClinicianAvailabilityShift, 'id' | 'shift_type' | 'shift_start_time' | 'shift_end_time'>
    )> }
  ) }
);

export type MonthlyCalendarScheduleQueryVariables = Exact<{
  consultantId: Scalars['ID'];
  date?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
}>;


export type MonthlyCalendarScheduleQuery = (
  { __typename?: 'Query' }
  & { monthlyCalendarScheduleV2: (
    { __typename?: 'MonthlyCalendarSchedule' }
    & Pick<MonthlyCalendarSchedule, 'shifts' | 'incompleteConsultations'>
  ) }
);

export type ConsultationScheduleQueryVariables = Exact<{
  consultants: Array<Scalars['ID']>;
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
}>;


export type ConsultationScheduleQuery = (
  { __typename?: 'Query' }
  & { consultations: Array<(
    { __typename?: 'Consultation' }
    & ConsultationScheduleItemBaseFragment
  )> }
);

export type FullConsultationScheduleQueryVariables = Exact<{
  consultants: Array<Scalars['ID']>;
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
}>;


export type FullConsultationScheduleQuery = (
  { __typename?: 'Query' }
  & { consultations: Array<(
    { __typename?: 'Consultation' }
    & ScheduleConsultationFragment
  )> }
);

export type ScheduleConsultationFragment = (
  { __typename?: 'Consultation' }
  & ConsultationScheduleItemBaseFragment
  & ConsultationScheduleItemDetailsFragment
);

export type ConsultationScheduleItemBaseFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'scheduledTime' | 'consultationType' | 'createdAt' | 'status' | 'statusV2' | 'patientNote' | 'languageRequested'>
);

export type ConsultationScheduleItemDetailsFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id' | 'hasPatientImage'>
  & { consumerNetwork: (
    { __typename: 'ConsumerNetwork' }
    & Pick<ConsumerNetwork, 'id' | 'name'>
  ) | (
    { __typename: 'RetrievalError' }
    & Pick<RetrievalError, 'message'>
  ), appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'duration_minutes'>
  )>, patient: (
    { __typename: 'Patient' }
    & Pick<Patient, 'id' | 'minor' | 'age' | 'gender'>
    & { firstName: Patient['first_name'], lastName: Patient['last_name'], dob: Patient['date_of_birth'] }
    & { alerts?: Maybe<Array<(
      { __typename?: 'PatientAlert' }
      & Pick<PatientAlert, 'id'>
    )>> }
  ) | (
    { __typename: 'RetrievalError' }
    & Pick<RetrievalError, 'message'>
  ) }
);

export type CreateSickNoteMutationMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  input: ConsultationSickNoteInput;
}>;


export type CreateSickNoteMutationMutation = (
  { __typename?: 'Mutation' }
  & { createConsultationSickNote?: Maybe<(
    { __typename?: 'ConsultationSickNote' }
    & Pick<ConsultationSickNote, 'id' | 'reason' | 'startDate' | 'endDate'>
  )> }
);

export type UpdateSickNoteMutationMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  sickNoteId: Scalars['ID'];
  input: ConsultationSickNoteInput;
}>;


export type UpdateSickNoteMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationSickNote?: Maybe<(
    { __typename?: 'ConsultationSickNote' }
    & Pick<ConsultationSickNote, 'id' | 'reason' | 'startDate' | 'endDate'>
  )> }
);

export type SickNoteQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type SickNoteQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { sickNotes: Array<(
      { __typename?: 'ConsultationSickNote' }
      & Pick<ConsultationSickNote, 'id' | 'reason' | 'startDate' | 'endDate'>
    )> }
  )> }
);

export type CpSickNotesFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id'>
  & { sickNotes: Array<(
    { __typename?: 'ConsultationSickNote' }
    & Pick<ConsultationSickNote, 'id' | 'reason' | 'startDate' | 'endDate'>
  )> }
);

export type CpRemoveSickNoteMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  sickNoteId: Scalars['ID'];
}>;


export type CpRemoveSickNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeConsultationSickNote'>
);

export type SickNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SickNotesQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & CpSickNotesFragment
  )> }
);

export type CreateTestMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  input: ConsultationTestInput;
}>;


export type CreateTestMutation = (
  { __typename?: 'Mutation' }
  & { createConsultationTest: (
    { __typename?: 'ConsultationTest' }
    & Pick<ConsultationTest, 'id' | 'reason' | 'type' | 'testId'>
  ) }
);

export type UpdateTestMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  id: Scalars['ID'];
  type: Scalars['String'];
  input: ConsultationTestInput;
}>;


export type UpdateTestMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationTest: (
    { __typename?: 'ConsultationTest' }
    & Pick<ConsultationTest, 'id' | 'reason' | 'type' | 'testId'>
  ) }
);

export type ModalTestTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ModalTestTypesQuery = (
  { __typename?: 'Query' }
  & { diagnosticTestTypes: Array<Maybe<(
    { __typename?: 'TestType' }
    & Pick<TestType, 'id' | 'testType' | 'name'>
  )>>, pathologyTestTypes: Array<Maybe<(
    { __typename?: 'TestType' }
    & Pick<TestType, 'id' | 'testType' | 'name'>
  )>> }
);

export type CpGetCachedTestsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpGetCachedTestsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { pathologyTests: Array<(
      { __typename?: 'ConsultationTest' }
      & Pick<ConsultationTest, 'id'>
    )>, diagnosticTests: Array<(
      { __typename?: 'ConsultationTest' }
      & Pick<ConsultationTest, 'id'>
    )> }
  )> }
);

export type CpRemoveTestMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  type: Scalars['String'];
  id: Scalars['String'];
}>;


export type CpRemoveTestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeConsultationTest'>
);

export type TestTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type TestTypesQuery = (
  { __typename?: 'Query' }
  & { diagnosticTestTypes: Array<Maybe<(
    { __typename?: 'TestType' }
    & Pick<TestType, 'id' | 'testType' | 'name'>
  )>>, pathologyTestTypes: Array<Maybe<(
    { __typename?: 'TestType' }
    & Pick<TestType, 'id' | 'testType' | 'name'>
  )>> }
);

export type TestsFragmentFragment = (
  { __typename?: 'Consultation' }
  & Pick<Consultation, 'id'>
  & { pathologyTests: Array<(
    { __typename?: 'ConsultationTest' }
    & Pick<ConsultationTest, 'id' | 'reason' | 'type' | 'testId'>
  )>, diagnosticTests: Array<(
    { __typename?: 'ConsultationTest' }
    & Pick<ConsultationTest, 'id' | 'reason' | 'type' | 'testId'>
  )> }
);

export type TestsQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type TestsQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id'>
    & { pathologyTests: Array<(
      { __typename?: 'ConsultationTest' }
      & Pick<ConsultationTest, 'id' | 'reason' | 'type' | 'testId'>
    )>, diagnosticTests: Array<(
      { __typename?: 'ConsultationTest' }
      & Pick<ConsultationTest, 'id' | 'reason' | 'type' | 'testId'>
    )> }
  )> }
);

export type EhrConsultationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EhrConsultationQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'date_of_birth'>
    ), usEhrConsultation?: Maybe<(
      { __typename?: 'UsEhrConsultation' }
      & Pick<UsEhrConsultation, 'externalAppointmentId' | 'ehrDepartmentId' | 'externalEncounterId'>
    )> }
  )> }
);

export type EhrConsultationNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EhrConsultationNotesQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'date_of_birth'>
    ), usEhrNote?: Maybe<(
      { __typename?: 'UsEhrNote' }
      & Pick<UsEhrNote, 'htmlSummary'>
    )> }
  )> }
);

export type UpdateEhrConsultationStatusMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateEhrConsultationStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationStatus?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'status'>
  )> }
);

export type UpdateEhrConsultationNotesMutationVariables = Exact<{
  consultationId: Scalars['ID'];
  input: ConsultationNoteInput;
}>;


export type UpdateEhrConsultationNotesMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultationNote?: Maybe<(
    { __typename?: 'ConsultationNote' }
    & Pick<ConsultationNote, 'id' | 'toldByPatient' | 'exploredByGp' | 'assessment' | 'treatmentPlan' | 'fallbackPlan'>
  )> }
);

export type MarkEhrNotesSensitiveMutationVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;


export type MarkEhrNotesSensitiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markEHRNotesSensitive'>
);

export type CpConsultationWorkflowsV2QueryVariables = Exact<{
  locale: Scalars['String'];
  tags: Scalars['String'];
  consumerNetwork: Scalars['ID'];
  consultationId: Scalars['ID'];
  includeDefinitions: Scalars['Boolean'];
}>;


export type CpConsultationWorkflowsV2Query = (
  { __typename?: 'Query' }
  & { consultationWorkflowsV2: (
    { __typename?: 'ConsultationWorkflowsResponse' }
    & { workflows: (
      { __typename?: 'WorkflowsResponse' }
      & { results: Array<(
        { __typename?: 'WorkflowV2' }
        & Pick<WorkflowV2, 'id' | 'state'>
        & { variables: Array<(
          { __typename?: 'WorkflowVariable' }
          & Pick<WorkflowVariable, 'name' | 'value'>
        )>, workflowDefinition?: Maybe<(
          { __typename?: 'WorkflowDefinition' }
          & Pick<WorkflowDefinition, 'id' | 'roles' | 'workflow_definition_name'>
          & { group: (
            { __typename?: 'WorkflowDefinitionGroup' }
            & Pick<WorkflowDefinitionGroup, 'type' | 'label'>
          ) }
        )> }
      )> }
    ), workflowDefinitions: Array<(
      { __typename?: 'WorkflowDefinition' }
      & Pick<WorkflowDefinition, 'id' | 'key' | 'version' | 'tenant_id' | 'workflow_definition_name' | 'roles'>
      & { group: (
        { __typename?: 'WorkflowDefinitionGroup' }
        & Pick<WorkflowDefinitionGroup, 'type' | 'label'>
      ) }
    )> }
  ) }
);

export type TriggerWorkflowMutationVariables = Exact<{
  idempotency_key: Scalars['String'];
  workflow_definition_key: Scalars['String'];
  tenant_id: Scalars['ID'];
  variables: Array<WorkflowVariableInput>;
}>;


export type TriggerWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { triggerWorkflow: (
    { __typename?: 'WorkflowV2' }
    & Pick<WorkflowV2, 'id' | 'start_time' | 'end_time' | 'state' | 'idempotency_key' | 'workflow_definition_key' | 'workflow_definition_id' | 'tenant_id'>
    & { workflowDefinition?: Maybe<(
      { __typename?: 'WorkflowDefinition' }
      & Pick<WorkflowDefinition, 'id' | 'key' | 'workflow_definition_name' | 'roles'>
      & { group: (
        { __typename?: 'WorkflowDefinitionGroup' }
        & Pick<WorkflowDefinitionGroup, 'type' | 'label'>
      ) }
    )>, variables: Array<(
      { __typename?: 'WorkflowVariable' }
      & Pick<WorkflowVariable, 'name' | 'value'>
    )> }
  ) }
);

export type WorkflowDefinitionQueryVariables = Exact<{
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type WorkflowDefinitionQuery = (
  { __typename?: 'Query' }
  & { workflowDefinition?: Maybe<(
    { __typename?: 'WorkflowDefinition' }
    & Pick<WorkflowDefinition, 'id' | 'key' | 'version' | 'tenant_id'>
    & { group: (
      { __typename?: 'WorkflowDefinitionGroup' }
      & Pick<WorkflowDefinitionGroup, 'type' | 'label'>
      & { assistive_texts?: Maybe<Array<(
        { __typename?: 'WorkflowDefinitionGroupAssistiveText' }
        & Pick<WorkflowDefinitionGroupAssistiveText, 'instruction'>
      )>> }
    ), variables?: Maybe<Array<(
      { __typename?: 'WorkflowDefinitionVariable' }
      & Pick<WorkflowDefinitionVariable, 'name' | 'type' | 'label' | 'is_required'>
      & { metadata: Array<(
        { __typename?: 'WorkflowDefinitionVariableMetadata' }
        & Pick<WorkflowDefinitionVariableMetadata, 'type'>
        & { value?: Maybe<(
          { __typename: 'WorkflowDefinitionVariableMetadataChildAllowedValue' }
          & Pick<WorkflowDefinitionVariableMetadataChildAllowedValue, 'parent_variable' | 'parent_mappings'>
        ) | (
          { __typename: 'WorkflowDefinitionVariableMetadataAllowedValue' }
          & { values: Array<(
            { __typename?: 'WorkflowDefinitionVariableMetadataAllowedValuesOption' }
            & Pick<WorkflowDefinitionVariableMetadataAllowedValuesOption, 'key' | 'label'>
          )> }
        ) | (
          { __typename: 'WorkflowDefinitionVariableMetadataAsyncCodeSearchValue' }
          & Pick<WorkflowDefinitionVariableMetadataAsyncCodeSearchValue, 'coding_system'>
        ) | (
          { __typename: 'WorkflowDefinitionVariableMetadataDateFormat' }
          & Pick<WorkflowDefinitionVariableMetadataDateFormat, 'format'>
        ) | (
          { __typename: 'WorkflowDefinitionVariableMetadataRegexValidation' }
          & Pick<WorkflowDefinitionVariableMetadataRegexValidation, 'regex'>
        ) | (
          { __typename: 'WorkflowDefinitionVariableMetadataSource' }
          & Pick<WorkflowDefinitionVariableMetadataSource, 'type'>
          & { source_info?: Maybe<(
            { __typename?: 'WorkflowDefinitionVariableMetadataPlaceholder' }
            & Pick<WorkflowDefinitionVariableMetadataPlaceholder, 'placeholder' | 'default_value'>
          )> }
        ) | (
          { __typename: 'WorkflowDefinitionVariableMetadataDefaultValue' }
          & Pick<WorkflowDefinitionVariableMetadataDefaultValue, 'default_value'>
        )> }
      )> }
    )>> }
  )> }
);

export type SearchPointOfCareQueryVariables = Exact<{
  term: Scalars['String'];
  index?: Maybe<Scalars['String']>;
}>;


export type SearchPointOfCareQuery = (
  { __typename?: 'Query' }
  & { searchPointOfCareEntity: (
    { __typename: 'SearchPointOfCareEntity' }
    & Pick<SearchPointOfCareEntity, 'total'>
    & { concepts: Array<Maybe<(
      { __typename?: 'SearchPointOfCareCodeEntity' }
      & { code: (
        { __typename?: 'SearchPointOfCareCodeDetailsEntity' }
        & Pick<SearchPointOfCareCodeDetailsEntity, 'code' | 'system' | 'display'>
      ) }
    )>> }
  ) }
);

export type CpWorkflowContextQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CpWorkflowContextQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consumerNetwork' | 'consumerNetworkName'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'email' | 'date_of_birth' | 'full_name' | 'public_healthcare_identifier'>
      & { region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'iso_code'>
      )>, clinical_commissioning_group?: Maybe<(
        { __typename?: 'ClinicalComissioningGroup' }
        & Pick<ClinicalComissioningGroup, 'id' | 'name'>
      )>, contact_details?: Maybe<(
        { __typename?: 'ContactDetails' }
        & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'phone_country_code' | 'phone_number'>
      )> }
    ) }
  )> }
);

export type WorkflowSubTypesQueryVariables = Exact<{
  type: WorkflowType;
}>;


export type WorkflowSubTypesQuery = (
  { __typename?: 'Query' }
  & { workflowSubTypes?: Maybe<Array<Maybe<(
    { __typename?: 'WorkflowSubType' }
    & Pick<WorkflowSubType, 'value' | 'label'>
  )>>> }
);

export type WorkflowSubSpecialtiesQueryVariables = Exact<{
  type: WorkflowType;
  subType: Scalars['String'];
}>;


export type WorkflowSubSpecialtiesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'workflowSubSpecialties'>
);

export type WorkflowConsultationQueryVariables = Exact<{
  consultationId: Scalars['ID'];
}>;


export type WorkflowConsultationQuery = (
  { __typename?: 'Query' }
  & { consultation?: Maybe<(
    { __typename?: 'Consultation' }
    & Pick<Consultation, 'id' | 'consumerNetwork'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'full_name' | 'email' | 'date_of_birth' | 'public_healthcare_identifier'>
      & { clinical_commissioning_group?: Maybe<(
        { __typename?: 'ClinicalComissioningGroup' }
        & Pick<ClinicalComissioningGroup, 'id' | 'code' | 'name'>
      )>, contact_details?: Maybe<(
        { __typename?: 'ContactDetails' }
        & Pick<ContactDetails, 'address_first_line' | 'address_second_line' | 'address_third_line' | 'address_post_code' | 'phone_country_code' | 'phone_number'>
      )>, region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'id' | 'iso_code'>
      )> }
    ) }
  )> }
);

export const InvitePartFragmentDoc = gql`
    fragment InvitePart on AppointmentInvite {
  id
  allowed_mediums
  notes_for_member
  preferred_profession
  duration_minutes
  preferred_profession_name
  group_correlation_id
  service_type {
    id
    name
  }
  earliest_booking_date
  consultant_uuid
  consultant {
    id
    name
  }
  associated_appointments {
    id
    time
  }
  recurrence {
    frequency
    interval
    count
  }
}
    `;
export const PresentingComplaintFragmentDoc = gql`
    fragment PresentingComplaint on Consultation {
  category
  images: imagesV2 {
    ... on ConsultationImages {
      __typename
      items
    }
    ... on RetrievalError {
      __typename
      message
    }
  }
  patientNote
  appointment {
    __typename
    serviceType: service_type {
      __typename
      memberFacingName: member_facing_name
      name
    }
  }
}
    `;
export const FeedbackQuestionFragmentFragmentDoc = gql`
    fragment FeedbackQuestionFragment on MultimediaFeedbackQuestion {
  id
  position
  question_type
  question
  suggested_answers {
    id
    answer
  }
}
    `;
export const ConsultationProfileFragmentDoc = gql`
    fragment ConsultationProfile on Consultation {
  id
  scheduledTime
  languageRequested
  consumerNetworkName
  statusV2
  consultant {
    id
    name
    profession_name
  }
  patient {
    id
    first_name
    last_name
    date_of_birth
    age @client
    gender
    minor
    public_healthcare_identifier
    email
    full_phone_number
    contact_details {
      address_first_line
      address_second_line
      address_third_line
      address_post_code
      address_state_code
    }
    clinical_commissioning_group {
      id
      name
    }
    profile_locked
    profile_locks {
      name
      locked_at
    }
  }
}
    `;
export const FurtherInfoFragmentFragmentDoc = gql`
    fragment FurtherInfoFragment on Consultation {
  furtherInfo {
    id
    name
    url
  }
}
    `;
export const ReviewFragmentFragmentDoc = gql`
    fragment ReviewFragment on Consultation {
  id
  patientNote
  status
  consultant {
    id
    uuid
    name
    role
    profession_name
  }
  furtherInfo {
    id
    name
    url
  }
  consultationNote {
    id
    exploredByGp
    exploredByGpCodes {
      code
      term
      length
      offset
    }
    toldByPatient
    toldByPatientCodes {
      code
      term
      length
      offset
    }
    assessment
    assessmentCodes {
      code
      term
      length
      offset
    }
    treatmentPlan
    fallbackPlan
  }
  images: imagesV2 {
    ... on ConsultationImages {
      items
    }
  }
  region {
    id
    iso_code
  }
  referrals {
    id
    specialismCategoryId
    specialismId
    specialismName
  }
  testResults {
    id
    url
    thumbUrl
    fileName
    type
  }
  sickNotes {
    id
    reason
    startDate
    endDate
  }
}
    `;
export const DiagnosisFragmentFragmentDoc = gql`
    fragment DiagnosisFragment on Consultation {
  id
  patient {
    id
  }
  region {
    id
    iso_code
  }
  consultationNote {
    id
    assessment
    assessmentCodes {
      code
      term
      length
      offset
    }
    treatmentPlan
    fallbackPlan
  }
}
    `;
export const ExaminationFragmentFragmentDoc = gql`
    fragment ExaminationFragment on Consultation {
  id
  patient {
    id
    conditions {
      id
      iri
      info
    }
    medications {
      id
      iri
      info
    }
    allergies {
      id
      iri
      info
    }
  }
  consultationNote {
    id
    toldByPatient
    exploredByGp
    toldByPatientCodes {
      code
      term
      length
      offset
    }
    exploredByGpCodes {
      code
      term
      length
      offset
    }
  }
}
    `;
export const CpIncompleteConsultationWarningFragmentDoc = gql`
    fragment CpIncompleteConsultationWarning on Consultation {
  id
  status
  scheduledTime
  patientId
}
    `;
export const StatusFragmentDoc = gql`
    fragment Status on Consultation {
  id
  status
  scheduledTime
  consultant {
    id
    name
    profession_name
  }
}
    `;
export const GpDetailsFragmentFragmentDoc = gql`
    fragment GpDetailsFragment on Consultation {
  id
  gpConsent
  patient {
    id
    gp_address_first_line
    gp_address_post_code
    gp_address_second_line
    gp_address_third_line
    gp_full_address
    gp_name
    gp_surgery_name
    gp_surgery_phone_number
    region {
      id
      name
    }
  }
}
    `;
export const GpConsentFragmentFragmentDoc = gql`
    fragment GpConsentFragment on Consultation {
  gpConsent
}
    `;
export const AppointmentInvitesFragmentDoc = gql`
    fragment AppointmentInvites on Consultation {
  appointmentInvites @include(if: $f2fOn) {
    id
    notes_for_member
    preferredMedium: preferred_medium
    service_type {
      id
      name
      grouping
    }
  }
}
    `;
export const CpPatientDetailsFragmentDoc = gql`
    fragment CpPatientDetails on Consultation {
  id
  patient {
    id
    minor
    account_owner_name
    account_owner_contact_details {
      address_first_line
      address_second_line
      address_third_line
      address_post_code
      phone_country_code
      phone_number
    }
    account_owner_dob
    first_name
    last_name
    full_name
    date_of_birth
    email
    gender
    id_verification
    identity_check {
      id
      status
      check_id
      verification_overwritten_at
      verification_overwritten_by {
        id
        fullName
      }
    }
    contact_details {
      address_first_line
      address_second_line
      address_third_line
      address_post_code
      address_state_code
      phone_country_code
      phone_number
    }
    region {
      id
      name
      iso_code
    }
    phone_number
    next_of_kin {
      id
      first_name
      last_name
      phone_number
      relation
    }
    public_healthcare_identifier
    profile_locked
    profile_locks {
      name
      locked_at
    }
  }
}
    `;
export const PatientMetricsFragmentDoc = gql`
    fragment PatientMetrics on Consultation {
  id
  uuid: consultationId
  consultant {
    id
    uuid
    role
  }
  consultationType
  patient {
    id
    uuid
    age @client
    date_of_birth
    patient_metrics(filter: $patientMetricsFilter, count: $patientMetricsCount) {
      id
      height {
        id
        value
        timestamp
        performer
      }
      weight {
        id
        value
        timestamp
        performer
      }
      smoking_status {
        id
        value
        timestamp
        performer
      }
      blood_pressure @include(if: $bloodPressureOn) {
        id
        values {
          code
          value
        }
        performer
        timestamp
      }
    }
  }
}
    `;
export const PatientTimelineTestReportFragmentFragmentDoc = gql`
    fragment PatientTimelineTestReportFragment on TestReport {
  id
  name
  eventTime
  eventType
  sender {
    fullName
  }
}
    `;
export const PatientTimelineConsultationFragmentFragmentDoc = gql`
    fragment PatientTimelineConsultationFragment on Consultation {
  id
  eventType
  eventTime
  consultationType
}
    `;
export const PatientTimelineChatscriptConversationFragmentFragmentDoc = gql`
    fragment PatientTimelineChatscriptConversationFragment on ChatscriptConversation {
  id
  preview
  eventTime
  eventType
  status
  type
  result {
    type
    outcome
  }
}
    `;
export const CpPreConsultationSectionFragmentDoc = gql`
    fragment CpPreConsultationSection on Consultation {
  patient {
    uuid
  }
  preConsultationFlowId
}
    `;
export const PrescriptionFieldsFragmentModalFragmentDoc = gql`
    fragment PrescriptionFieldsFragmentModal on Prescription {
  id
  createdAt
  prescriptionState
  drugs {
    prescriptionDrugId
    drugId
    drugName
    dosage
    duration
    packagingSize
    pharmaceuticalForm
    strength
    refills
    quantity
    directions
    notes
    repeatable
    reviewDate
    repeats
    indications {
      iri
      id
      name
    }
  }
}
    `;
export const PrescriptionFieldsFragmentFragmentDoc = gql`
    fragment PrescriptionFieldsFragment on Prescription {
  id
  actions
  createdAt
  prescriptionState
  drugs {
    prescriptionDrugId
    drugId
    drugName
    dosage
    duration
    pharmaceuticalForm
    strength
    quantity
    packagingSize
    directions
    notes
    repeatable
    repeats
    refills
    reviewDate
    indications {
      id
      name
      iri
    }
  }
}
    `;
export const ReferralsFragmentFragmentDoc = gql`
    fragment ReferralsFragment on Consultation {
  id
  referrals {
    id
    specialismCategoryId
    specialismId
    specialismName
    comments {
      id
      note
    }
    externalSpecialist {
      id
      name
    }
  }
}
    `;
export const ConsultationScheduleItemBaseFragmentDoc = gql`
    fragment ConsultationScheduleItemBase on Consultation {
  id
  scheduledTime
  consultationType
  createdAt
  status
  statusV2
  patientNote
  languageRequested
}
    `;
export const ConsultationScheduleItemDetailsFragmentDoc = gql`
    fragment ConsultationScheduleItemDetails on Consultation {
  id
  consumerNetwork: consumerNetworkV2 {
    ... on ConsumerNetwork {
      __typename
      id
      name
    }
    ... on RetrievalError {
      __typename
      message
    }
  }
  appointment {
    id
    duration_minutes
  }
  hasPatientImage
  patient: patientV2 {
    ... on Patient {
      __typename
      id
      minor
      firstName: first_name
      lastName: last_name
      dob: date_of_birth
      age @client
      gender
      alerts: patient_alerts {
        id
      }
    }
    ... on RetrievalError {
      __typename
      message
    }
  }
}
    `;
export const ScheduleConsultationFragmentDoc = gql`
    fragment ScheduleConsultation on Consultation {
  ...ConsultationScheduleItemBase
  ...ConsultationScheduleItemDetails
}
    ${ConsultationScheduleItemBaseFragmentDoc}
${ConsultationScheduleItemDetailsFragmentDoc}`;
export const CpSickNotesFragmentDoc = gql`
    fragment CpSickNotes on Consultation {
  id
  sickNotes {
    id
    reason
    startDate
    endDate
  }
}
    `;
export const TestsFragmentFragmentDoc = gql`
    fragment TestsFragment on Consultation {
  id
  pathologyTests {
    id
    reason
    type
    testId
  }
  diagnosticTests {
    id
    reason
    type
    testId
  }
}
    `;
export const ClinicalPresenceDocument = gql`
    mutation ClinicalPresence {
  clinicalPresence
}
    `;
export type ClinicalPresenceMutationFn = Apollo.MutationFunction<ClinicalPresenceMutation, ClinicalPresenceMutationVariables>;

/**
 * __useClinicalPresenceMutation__
 *
 * To run a mutation, you first call `useClinicalPresenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClinicalPresenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clinicalPresenceMutation, { data, loading, error }] = useClinicalPresenceMutation({
 *   variables: {
 *   },
 * });
 */
export function useClinicalPresenceMutation(baseOptions?: Apollo.MutationHookOptions<ClinicalPresenceMutation, ClinicalPresenceMutationVariables>) {
        return Apollo.useMutation<ClinicalPresenceMutation, ClinicalPresenceMutationVariables>(ClinicalPresenceDocument, baseOptions);
      }
export type ClinicalPresenceMutationHookResult = ReturnType<typeof useClinicalPresenceMutation>;
export type ClinicalPresenceMutationResult = Apollo.MutationResult<ClinicalPresenceMutation>;
export type ClinicalPresenceMutationOptions = Apollo.BaseMutationOptions<ClinicalPresenceMutation, ClinicalPresenceMutationVariables>;
export const AppointmentCheckInDetailsDocument = gql`
    query AppointmentCheckInDetails($appointmentId: ID!) {
  getAppointmentCheckIn(appointmentId: $appointmentId) {
    status
    checkedInAt
    location {
      useRegisteredLocation
      temporaryLocation {
        countryIsoCode
        address
      }
    }
  }
}
    `;

/**
 * __useAppointmentCheckInDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentCheckInDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCheckInDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCheckInDetailsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentCheckInDetailsQuery(baseOptions: Apollo.QueryHookOptions<AppointmentCheckInDetailsQuery, AppointmentCheckInDetailsQueryVariables>) {
        return Apollo.useQuery<AppointmentCheckInDetailsQuery, AppointmentCheckInDetailsQueryVariables>(AppointmentCheckInDetailsDocument, baseOptions);
      }
export function useAppointmentCheckInDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentCheckInDetailsQuery, AppointmentCheckInDetailsQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentCheckInDetailsQuery, AppointmentCheckInDetailsQueryVariables>(AppointmentCheckInDetailsDocument, baseOptions);
        }
export type AppointmentCheckInDetailsQueryHookResult = ReturnType<typeof useAppointmentCheckInDetailsQuery>;
export type AppointmentCheckInDetailsLazyQueryHookResult = ReturnType<typeof useAppointmentCheckInDetailsLazyQuery>;
export type AppointmentCheckInDetailsQueryResult = Apollo.QueryResult<AppointmentCheckInDetailsQuery, AppointmentCheckInDetailsQueryVariables>;
export const GetProfessionsDocument = gql`
    query getProfessions($id: ID!) {
  consumerNetworkProfessions(id: $id) {
    id
    name
    alternativeSlotDurationMinutes: alternative_slot_durations_minutes
    defaultSlotDurationMinutes: default_slot_duration_minutes
  }
}
    `;

/**
 * __useGetProfessionsQuery__
 *
 * To run a query within a React component, call `useGetProfessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfessionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfessionsQuery(baseOptions: Apollo.QueryHookOptions<GetProfessionsQuery, GetProfessionsQueryVariables>) {
        return Apollo.useQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(GetProfessionsDocument, baseOptions);
      }
export function useGetProfessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfessionsQuery, GetProfessionsQueryVariables>) {
          return Apollo.useLazyQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(GetProfessionsDocument, baseOptions);
        }
export type GetProfessionsQueryHookResult = ReturnType<typeof useGetProfessionsQuery>;
export type GetProfessionsLazyQueryHookResult = ReturnType<typeof useGetProfessionsLazyQuery>;
export type GetProfessionsQueryResult = Apollo.QueryResult<GetProfessionsQuery, GetProfessionsQueryVariables>;
export const GetServiceTypesDocument = gql`
    query getServiceTypes($memberUuid: ID!) {
  serviceTypes(member_uuid: $memberUuid, including_digital: true) {
    id
    name
    allowed_mediums
    member_instructions
    service_type_settings {
      recurrence_settings {
        recurrence_patterns {
          frequency
          interval
          max_sessions
          label
        }
      }
    }
  }
}
    `;

/**
 * __useGetServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceTypesQuery({
 *   variables: {
 *      memberUuid: // value for 'memberUuid'
 *   },
 * });
 */
export function useGetServiceTypesQuery(baseOptions: Apollo.QueryHookOptions<GetServiceTypesQuery, GetServiceTypesQueryVariables>) {
        return Apollo.useQuery<GetServiceTypesQuery, GetServiceTypesQueryVariables>(GetServiceTypesDocument, baseOptions);
      }
export function useGetServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceTypesQuery, GetServiceTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetServiceTypesQuery, GetServiceTypesQueryVariables>(GetServiceTypesDocument, baseOptions);
        }
export type GetServiceTypesQueryHookResult = ReturnType<typeof useGetServiceTypesQuery>;
export type GetServiceTypesLazyQueryHookResult = ReturnType<typeof useGetServiceTypesLazyQuery>;
export type GetServiceTypesQueryResult = Apollo.QueryResult<GetServiceTypesQuery, GetServiceTypesQueryVariables>;
export const GetConsumerNetworkServiceTypesDocument = gql`
    query getConsumerNetworkServiceTypes($consumerNetworkId: ID!) {
  consumerNetworkServiceTypes(consumerNetworkId: $consumerNetworkId) {
    uuid
    name
    allowed_mediums
    member_instructions
    service_type_settings {
      recurrence_settings {
        recurrence_patterns {
          frequency
          interval
          max_sessions
          label
        }
      }
    }
  }
}
    `;

/**
 * __useGetConsumerNetworkServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetConsumerNetworkServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumerNetworkServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumerNetworkServiceTypesQuery({
 *   variables: {
 *      consumerNetworkId: // value for 'consumerNetworkId'
 *   },
 * });
 */
export function useGetConsumerNetworkServiceTypesQuery(baseOptions: Apollo.QueryHookOptions<GetConsumerNetworkServiceTypesQuery, GetConsumerNetworkServiceTypesQueryVariables>) {
        return Apollo.useQuery<GetConsumerNetworkServiceTypesQuery, GetConsumerNetworkServiceTypesQueryVariables>(GetConsumerNetworkServiceTypesDocument, baseOptions);
      }
export function useGetConsumerNetworkServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsumerNetworkServiceTypesQuery, GetConsumerNetworkServiceTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetConsumerNetworkServiceTypesQuery, GetConsumerNetworkServiceTypesQueryVariables>(GetConsumerNetworkServiceTypesDocument, baseOptions);
        }
export type GetConsumerNetworkServiceTypesQueryHookResult = ReturnType<typeof useGetConsumerNetworkServiceTypesQuery>;
export type GetConsumerNetworkServiceTypesLazyQueryHookResult = ReturnType<typeof useGetConsumerNetworkServiceTypesLazyQuery>;
export type GetConsumerNetworkServiceTypesQueryResult = Apollo.QueryResult<GetConsumerNetworkServiceTypesQuery, GetConsumerNetworkServiceTypesQueryVariables>;
export const GetClinicianServiceTypesDocument = gql`
    query getClinicianServiceTypes($clinician_id: ID!) {
  clinicianServiceTypes(clinician_ids: $clinician_id) {
    id
    name
  }
}
    `;

/**
 * __useGetClinicianServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetClinicianServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicianServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicianServiceTypesQuery({
 *   variables: {
 *      clinician_id: // value for 'clinician_id'
 *   },
 * });
 */
export function useGetClinicianServiceTypesQuery(baseOptions: Apollo.QueryHookOptions<GetClinicianServiceTypesQuery, GetClinicianServiceTypesQueryVariables>) {
        return Apollo.useQuery<GetClinicianServiceTypesQuery, GetClinicianServiceTypesQueryVariables>(GetClinicianServiceTypesDocument, baseOptions);
      }
export function useGetClinicianServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicianServiceTypesQuery, GetClinicianServiceTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetClinicianServiceTypesQuery, GetClinicianServiceTypesQueryVariables>(GetClinicianServiceTypesDocument, baseOptions);
        }
export type GetClinicianServiceTypesQueryHookResult = ReturnType<typeof useGetClinicianServiceTypesQuery>;
export type GetClinicianServiceTypesLazyQueryHookResult = ReturnType<typeof useGetClinicianServiceTypesLazyQuery>;
export type GetClinicianServiceTypesQueryResult = Apollo.QueryResult<GetClinicianServiceTypesQuery, GetClinicianServiceTypesQueryVariables>;
export const GetAppointmentInvitesDocument = gql`
    query getAppointmentInvites($id: ID!) {
  appointmentInvites(filter: {source_appointment_id: $id, including_digital: true}) {
    ...InvitePart
  }
}
    ${InvitePartFragmentDoc}`;

/**
 * __useGetAppointmentInvitesQuery__
 *
 * To run a query within a React component, call `useGetAppointmentInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentInvitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppointmentInvitesQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentInvitesQuery, GetAppointmentInvitesQueryVariables>) {
        return Apollo.useQuery<GetAppointmentInvitesQuery, GetAppointmentInvitesQueryVariables>(GetAppointmentInvitesDocument, baseOptions);
      }
export function useGetAppointmentInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentInvitesQuery, GetAppointmentInvitesQueryVariables>) {
          return Apollo.useLazyQuery<GetAppointmentInvitesQuery, GetAppointmentInvitesQueryVariables>(GetAppointmentInvitesDocument, baseOptions);
        }
export type GetAppointmentInvitesQueryHookResult = ReturnType<typeof useGetAppointmentInvitesQuery>;
export type GetAppointmentInvitesLazyQueryHookResult = ReturnType<typeof useGetAppointmentInvitesLazyQuery>;
export type GetAppointmentInvitesQueryResult = Apollo.QueryResult<GetAppointmentInvitesQuery, GetAppointmentInvitesQueryVariables>;
export const CreateInviteDocument = gql`
    mutation createInvite($input: AppointmentInviteInput!) {
  createAppointmentInvite(input: $input) {
    ...InvitePart
    intimate
    recurrence {
      count
    }
    service_type_uuid: nullable_service_type_uuid
  }
}
    ${InvitePartFragmentDoc}`;
export type CreateInviteMutationFn = Apollo.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        return Apollo.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, baseOptions);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = Apollo.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = Apollo.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const CreateInviteV3Document = gql`
    mutation createInviteV3($input: AppointmentInviteInput!) {
  createAppointmentInviteV3(input: $input) {
    invite {
      ...InvitePart
      intimate
      recurrence {
        count
      }
      service_type_uuid: nullable_service_type_uuid
    }
    errors {
      date
    }
  }
}
    ${InvitePartFragmentDoc}`;
export type CreateInviteV3MutationFn = Apollo.MutationFunction<CreateInviteV3Mutation, CreateInviteV3MutationVariables>;

/**
 * __useCreateInviteV3Mutation__
 *
 * To run a mutation, you first call `useCreateInviteV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteV3Mutation, { data, loading, error }] = useCreateInviteV3Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInviteV3Mutation(baseOptions?: Apollo.MutationHookOptions<CreateInviteV3Mutation, CreateInviteV3MutationVariables>) {
        return Apollo.useMutation<CreateInviteV3Mutation, CreateInviteV3MutationVariables>(CreateInviteV3Document, baseOptions);
      }
export type CreateInviteV3MutationHookResult = ReturnType<typeof useCreateInviteV3Mutation>;
export type CreateInviteV3MutationResult = Apollo.MutationResult<CreateInviteV3Mutation>;
export type CreateInviteV3MutationOptions = Apollo.BaseMutationOptions<CreateInviteV3Mutation, CreateInviteV3MutationVariables>;
export const AttachmentsSummaryDocument = gql`
    query AttachmentsSummary($id: ID!) {
  consultation(id: $id) {
    id
    testResults {
      id
      url
      thumbUrl
      fileName
      type
    }
  }
}
    `;

/**
 * __useAttachmentsSummaryQuery__
 *
 * To run a query within a React component, call `useAttachmentsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttachmentsSummaryQuery(baseOptions: Apollo.QueryHookOptions<AttachmentsSummaryQuery, AttachmentsSummaryQueryVariables>) {
        return Apollo.useQuery<AttachmentsSummaryQuery, AttachmentsSummaryQueryVariables>(AttachmentsSummaryDocument, baseOptions);
      }
export function useAttachmentsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttachmentsSummaryQuery, AttachmentsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<AttachmentsSummaryQuery, AttachmentsSummaryQueryVariables>(AttachmentsSummaryDocument, baseOptions);
        }
export type AttachmentsSummaryQueryHookResult = ReturnType<typeof useAttachmentsSummaryQuery>;
export type AttachmentsSummaryLazyQueryHookResult = ReturnType<typeof useAttachmentsSummaryLazyQuery>;
export type AttachmentsSummaryQueryResult = Apollo.QueryResult<AttachmentsSummaryQuery, AttachmentsSummaryQueryVariables>;
export const AttachmentsDocument = gql`
    query Attachments($id: ID!) {
  consultation(id: $id) {
    id
    testResults {
      id
      url
      thumbUrl
      fileName
      type
    }
  }
}
    `;

/**
 * __useAttachmentsQuery__
 *
 * To run a query within a React component, call `useAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<AttachmentsQuery, AttachmentsQueryVariables>) {
        return Apollo.useQuery<AttachmentsQuery, AttachmentsQueryVariables>(AttachmentsDocument, baseOptions);
      }
export function useAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttachmentsQuery, AttachmentsQueryVariables>) {
          return Apollo.useLazyQuery<AttachmentsQuery, AttachmentsQueryVariables>(AttachmentsDocument, baseOptions);
        }
export type AttachmentsQueryHookResult = ReturnType<typeof useAttachmentsQuery>;
export type AttachmentsLazyQueryHookResult = ReturnType<typeof useAttachmentsLazyQuery>;
export type AttachmentsQueryResult = Apollo.QueryResult<AttachmentsQuery, AttachmentsQueryVariables>;
export const BookingReasonDocument = gql`
    query BookingReason($id: ID!) {
  consultation(id: $id) {
    id
    ...PresentingComplaint
  }
}
    ${PresentingComplaintFragmentDoc}`;

/**
 * __useBookingReasonQuery__
 *
 * To run a query within a React component, call `useBookingReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingReasonQuery(baseOptions: Apollo.QueryHookOptions<BookingReasonQuery, BookingReasonQueryVariables>) {
        return Apollo.useQuery<BookingReasonQuery, BookingReasonQueryVariables>(BookingReasonDocument, baseOptions);
      }
export function useBookingReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingReasonQuery, BookingReasonQueryVariables>) {
          return Apollo.useLazyQuery<BookingReasonQuery, BookingReasonQueryVariables>(BookingReasonDocument, baseOptions);
        }
export type BookingReasonQueryHookResult = ReturnType<typeof useBookingReasonQuery>;
export type BookingReasonLazyQueryHookResult = ReturnType<typeof useBookingReasonLazyQuery>;
export type BookingReasonQueryResult = Apollo.QueryResult<BookingReasonQuery, BookingReasonQueryVariables>;
export const CpMultimediaFeedbackQuestionDocument = gql`
    query CpMultimediaFeedbackQuestion($consultationId: ID!, $client_type: MultimediaFeedbackClientType = clinician) {
  multimediaFeedbackQuestion(consultation_id: $consultationId, client_type: $client_type) {
    ...FeedbackQuestionFragment
  }
}
    ${FeedbackQuestionFragmentFragmentDoc}`;

/**
 * __useCpMultimediaFeedbackQuestionQuery__
 *
 * To run a query within a React component, call `useCpMultimediaFeedbackQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpMultimediaFeedbackQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpMultimediaFeedbackQuestionQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      client_type: // value for 'client_type'
 *   },
 * });
 */
export function useCpMultimediaFeedbackQuestionQuery(baseOptions: Apollo.QueryHookOptions<CpMultimediaFeedbackQuestionQuery, CpMultimediaFeedbackQuestionQueryVariables>) {
        return Apollo.useQuery<CpMultimediaFeedbackQuestionQuery, CpMultimediaFeedbackQuestionQueryVariables>(CpMultimediaFeedbackQuestionDocument, baseOptions);
      }
export function useCpMultimediaFeedbackQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpMultimediaFeedbackQuestionQuery, CpMultimediaFeedbackQuestionQueryVariables>) {
          return Apollo.useLazyQuery<CpMultimediaFeedbackQuestionQuery, CpMultimediaFeedbackQuestionQueryVariables>(CpMultimediaFeedbackQuestionDocument, baseOptions);
        }
export type CpMultimediaFeedbackQuestionQueryHookResult = ReturnType<typeof useCpMultimediaFeedbackQuestionQuery>;
export type CpMultimediaFeedbackQuestionLazyQueryHookResult = ReturnType<typeof useCpMultimediaFeedbackQuestionLazyQuery>;
export type CpMultimediaFeedbackQuestionQueryResult = Apollo.QueryResult<CpMultimediaFeedbackQuestionQuery, CpMultimediaFeedbackQuestionQueryVariables>;
export const CpSubmitMultimediaFeedbackDocument = gql`
    mutation CpSubmitMultimediaFeedback($input: MultimediaFeedbackInput!) {
  submitMultimediaFeedback(input: $input) {
    next_question {
      ...FeedbackQuestionFragment
    }
  }
}
    ${FeedbackQuestionFragmentFragmentDoc}`;
export type CpSubmitMultimediaFeedbackMutationFn = Apollo.MutationFunction<CpSubmitMultimediaFeedbackMutation, CpSubmitMultimediaFeedbackMutationVariables>;

/**
 * __useCpSubmitMultimediaFeedbackMutation__
 *
 * To run a mutation, you first call `useCpSubmitMultimediaFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpSubmitMultimediaFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpSubmitMultimediaFeedbackMutation, { data, loading, error }] = useCpSubmitMultimediaFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCpSubmitMultimediaFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CpSubmitMultimediaFeedbackMutation, CpSubmitMultimediaFeedbackMutationVariables>) {
        return Apollo.useMutation<CpSubmitMultimediaFeedbackMutation, CpSubmitMultimediaFeedbackMutationVariables>(CpSubmitMultimediaFeedbackDocument, baseOptions);
      }
export type CpSubmitMultimediaFeedbackMutationHookResult = ReturnType<typeof useCpSubmitMultimediaFeedbackMutation>;
export type CpSubmitMultimediaFeedbackMutationResult = Apollo.MutationResult<CpSubmitMultimediaFeedbackMutation>;
export type CpSubmitMultimediaFeedbackMutationOptions = Apollo.BaseMutationOptions<CpSubmitMultimediaFeedbackMutation, CpSubmitMultimediaFeedbackMutationVariables>;
export const CpRevokeRecordingConsentDocument = gql`
    mutation CpRevokeRecordingConsent($id: ID!) {
  revokeRecordingConsent(id: $id) {
    id
    recordingConsent
  }
}
    `;
export type CpRevokeRecordingConsentMutationFn = Apollo.MutationFunction<CpRevokeRecordingConsentMutation, CpRevokeRecordingConsentMutationVariables>;

/**
 * __useCpRevokeRecordingConsentMutation__
 *
 * To run a mutation, you first call `useCpRevokeRecordingConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpRevokeRecordingConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpRevokeRecordingConsentMutation, { data, loading, error }] = useCpRevokeRecordingConsentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpRevokeRecordingConsentMutation(baseOptions?: Apollo.MutationHookOptions<CpRevokeRecordingConsentMutation, CpRevokeRecordingConsentMutationVariables>) {
        return Apollo.useMutation<CpRevokeRecordingConsentMutation, CpRevokeRecordingConsentMutationVariables>(CpRevokeRecordingConsentDocument, baseOptions);
      }
export type CpRevokeRecordingConsentMutationHookResult = ReturnType<typeof useCpRevokeRecordingConsentMutation>;
export type CpRevokeRecordingConsentMutationResult = Apollo.MutationResult<CpRevokeRecordingConsentMutation>;
export type CpRevokeRecordingConsentMutationOptions = Apollo.BaseMutationOptions<CpRevokeRecordingConsentMutation, CpRevokeRecordingConsentMutationVariables>;
export const CallPlayerDocument = gql`
    query CallPlayer($id: ID!) {
  consultation(id: $id) {
    id
    recordingConsent
    consultationType
  }
}
    `;

/**
 * __useCallPlayerQuery__
 *
 * To run a query within a React component, call `useCallPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCallPlayerQuery(baseOptions: Apollo.QueryHookOptions<CallPlayerQuery, CallPlayerQueryVariables>) {
        return Apollo.useQuery<CallPlayerQuery, CallPlayerQueryVariables>(CallPlayerDocument, baseOptions);
      }
export function useCallPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallPlayerQuery, CallPlayerQueryVariables>) {
          return Apollo.useLazyQuery<CallPlayerQuery, CallPlayerQueryVariables>(CallPlayerDocument, baseOptions);
        }
export type CallPlayerQueryHookResult = ReturnType<typeof useCallPlayerQuery>;
export type CallPlayerLazyQueryHookResult = ReturnType<typeof useCallPlayerLazyQuery>;
export type CallPlayerQueryResult = Apollo.QueryResult<CallPlayerQuery, CallPlayerQueryVariables>;
export const CpVideoSessionDocument = gql`
    query CpVideoSession($id: ID!) {
  consultation(id: $id) {
    id
    videoSession {
      id
      sessionId
      providerApiKey
      providerSessionId
      providerToken
    }
    patient {
      first_name
      last_name
    }
    consultant {
      name
    }
  }
}
    `;

/**
 * __useCpVideoSessionQuery__
 *
 * To run a query within a React component, call `useCpVideoSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpVideoSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpVideoSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpVideoSessionQuery(baseOptions: Apollo.QueryHookOptions<CpVideoSessionQuery, CpVideoSessionQueryVariables>) {
        return Apollo.useQuery<CpVideoSessionQuery, CpVideoSessionQueryVariables>(CpVideoSessionDocument, baseOptions);
      }
export function useCpVideoSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpVideoSessionQuery, CpVideoSessionQueryVariables>) {
          return Apollo.useLazyQuery<CpVideoSessionQuery, CpVideoSessionQueryVariables>(CpVideoSessionDocument, baseOptions);
        }
export type CpVideoSessionQueryHookResult = ReturnType<typeof useCpVideoSessionQuery>;
export type CpVideoSessionLazyQueryHookResult = ReturnType<typeof useCpVideoSessionLazyQuery>;
export type CpVideoSessionQueryResult = Apollo.QueryResult<CpVideoSessionQuery, CpVideoSessionQueryVariables>;
export const CpRawCallFlowStepsDocument = gql`
    query CpRawCallFlowSteps($appointmentId: ID!) {
  getRawCallFlowSteps(appointmentId: $appointmentId) {
    call_flow_steps {
      triggered_at
      flow_step_type
    }
  }
}
    `;

/**
 * __useCpRawCallFlowStepsQuery__
 *
 * To run a query within a React component, call `useCpRawCallFlowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpRawCallFlowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpRawCallFlowStepsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCpRawCallFlowStepsQuery(baseOptions: Apollo.QueryHookOptions<CpRawCallFlowStepsQuery, CpRawCallFlowStepsQueryVariables>) {
        return Apollo.useQuery<CpRawCallFlowStepsQuery, CpRawCallFlowStepsQueryVariables>(CpRawCallFlowStepsDocument, baseOptions);
      }
export function useCpRawCallFlowStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpRawCallFlowStepsQuery, CpRawCallFlowStepsQueryVariables>) {
          return Apollo.useLazyQuery<CpRawCallFlowStepsQuery, CpRawCallFlowStepsQueryVariables>(CpRawCallFlowStepsDocument, baseOptions);
        }
export type CpRawCallFlowStepsQueryHookResult = ReturnType<typeof useCpRawCallFlowStepsQuery>;
export type CpRawCallFlowStepsLazyQueryHookResult = ReturnType<typeof useCpRawCallFlowStepsLazyQuery>;
export type CpRawCallFlowStepsQueryResult = Apollo.QueryResult<CpRawCallFlowStepsQuery, CpRawCallFlowStepsQueryVariables>;
export const CpEndVideoCallDocument = gql`
    mutation CpEndVideoCall($consultationId: ID!, $sessionId: ID!) {
  endVideoCall(consultationId: $consultationId, sessionId: $sessionId)
}
    `;
export type CpEndVideoCallMutationFn = Apollo.MutationFunction<CpEndVideoCallMutation, CpEndVideoCallMutationVariables>;

/**
 * __useCpEndVideoCallMutation__
 *
 * To run a mutation, you first call `useCpEndVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpEndVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpEndVideoCallMutation, { data, loading, error }] = useCpEndVideoCallMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCpEndVideoCallMutation(baseOptions?: Apollo.MutationHookOptions<CpEndVideoCallMutation, CpEndVideoCallMutationVariables>) {
        return Apollo.useMutation<CpEndVideoCallMutation, CpEndVideoCallMutationVariables>(CpEndVideoCallDocument, baseOptions);
      }
export type CpEndVideoCallMutationHookResult = ReturnType<typeof useCpEndVideoCallMutation>;
export type CpEndVideoCallMutationResult = Apollo.MutationResult<CpEndVideoCallMutation>;
export type CpEndVideoCallMutationOptions = Apollo.BaseMutationOptions<CpEndVideoCallMutation, CpEndVideoCallMutationVariables>;
export const CpCallFlowStepsDocument = gql`
    query CpCallFlowSteps($appointmentId: ID!) {
  getCallFlowSteps(appointmentId: $appointmentId) {
    call_flow_steps {
      triggered_at
      flow_step_type
    }
  }
}
    `;

/**
 * __useCpCallFlowStepsQuery__
 *
 * To run a query within a React component, call `useCpCallFlowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpCallFlowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpCallFlowStepsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCpCallFlowStepsQuery(baseOptions: Apollo.QueryHookOptions<CpCallFlowStepsQuery, CpCallFlowStepsQueryVariables>) {
        return Apollo.useQuery<CpCallFlowStepsQuery, CpCallFlowStepsQueryVariables>(CpCallFlowStepsDocument, baseOptions);
      }
export function useCpCallFlowStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpCallFlowStepsQuery, CpCallFlowStepsQueryVariables>) {
          return Apollo.useLazyQuery<CpCallFlowStepsQuery, CpCallFlowStepsQueryVariables>(CpCallFlowStepsDocument, baseOptions);
        }
export type CpCallFlowStepsQueryHookResult = ReturnType<typeof useCpCallFlowStepsQuery>;
export type CpCallFlowStepsLazyQueryHookResult = ReturnType<typeof useCpCallFlowStepsLazyQuery>;
export type CpCallFlowStepsQueryResult = Apollo.QueryResult<CpCallFlowStepsQuery, CpCallFlowStepsQueryVariables>;
export const CpStartVideoCallDocument = gql`
    mutation CpStartVideoCall($consultationId: ID!, $sessionId: ID!, $activeParticipants: [VideoSessionUserTypes!]!) {
  startVideoCall(consultationId: $consultationId, sessionId: $sessionId, activeParticipants: $activeParticipants)
}
    `;
export type CpStartVideoCallMutationFn = Apollo.MutationFunction<CpStartVideoCallMutation, CpStartVideoCallMutationVariables>;

/**
 * __useCpStartVideoCallMutation__
 *
 * To run a mutation, you first call `useCpStartVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpStartVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpStartVideoCallMutation, { data, loading, error }] = useCpStartVideoCallMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      sessionId: // value for 'sessionId'
 *      activeParticipants: // value for 'activeParticipants'
 *   },
 * });
 */
export function useCpStartVideoCallMutation(baseOptions?: Apollo.MutationHookOptions<CpStartVideoCallMutation, CpStartVideoCallMutationVariables>) {
        return Apollo.useMutation<CpStartVideoCallMutation, CpStartVideoCallMutationVariables>(CpStartVideoCallDocument, baseOptions);
      }
export type CpStartVideoCallMutationHookResult = ReturnType<typeof useCpStartVideoCallMutation>;
export type CpStartVideoCallMutationResult = Apollo.MutationResult<CpStartVideoCallMutation>;
export type CpStartVideoCallMutationOptions = Apollo.BaseMutationOptions<CpStartVideoCallMutation, CpStartVideoCallMutationVariables>;
export const CpAudioSessionDocument = gql`
    query CpAudioSession($id: ID!) {
  consultation(id: $id) {
    id
    consultationId
    patientId
    audioSession {
      token
      encryptedVoiceParams
    }
  }
}
    `;

/**
 * __useCpAudioSessionQuery__
 *
 * To run a query within a React component, call `useCpAudioSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpAudioSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpAudioSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpAudioSessionQuery(baseOptions: Apollo.QueryHookOptions<CpAudioSessionQuery, CpAudioSessionQueryVariables>) {
        return Apollo.useQuery<CpAudioSessionQuery, CpAudioSessionQueryVariables>(CpAudioSessionDocument, baseOptions);
      }
export function useCpAudioSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpAudioSessionQuery, CpAudioSessionQueryVariables>) {
          return Apollo.useLazyQuery<CpAudioSessionQuery, CpAudioSessionQueryVariables>(CpAudioSessionDocument, baseOptions);
        }
export type CpAudioSessionQueryHookResult = ReturnType<typeof useCpAudioSessionQuery>;
export type CpAudioSessionLazyQueryHookResult = ReturnType<typeof useCpAudioSessionLazyQuery>;
export type CpAudioSessionQueryResult = Apollo.QueryResult<CpAudioSessionQuery, CpAudioSessionQueryVariables>;
export const ReportCallStepDocument = gql`
    mutation ReportCallStep($consultationId: String!, $step: CallStep!, $appName: String!, $appVersion: String!) {
  reportCallStep(consultationId: $consultationId, step: $step, appName: $appName, appVersion: $appVersion)
}
    `;
export type ReportCallStepMutationFn = Apollo.MutationFunction<ReportCallStepMutation, ReportCallStepMutationVariables>;

/**
 * __useReportCallStepMutation__
 *
 * To run a mutation, you first call `useReportCallStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCallStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCallStepMutation, { data, loading, error }] = useReportCallStepMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      step: // value for 'step'
 *      appName: // value for 'appName'
 *      appVersion: // value for 'appVersion'
 *   },
 * });
 */
export function useReportCallStepMutation(baseOptions?: Apollo.MutationHookOptions<ReportCallStepMutation, ReportCallStepMutationVariables>) {
        return Apollo.useMutation<ReportCallStepMutation, ReportCallStepMutationVariables>(ReportCallStepDocument, baseOptions);
      }
export type ReportCallStepMutationHookResult = ReturnType<typeof useReportCallStepMutation>;
export type ReportCallStepMutationResult = Apollo.MutationResult<ReportCallStepMutation>;
export type ReportCallStepMutationOptions = Apollo.BaseMutationOptions<ReportCallStepMutation, ReportCallStepMutationVariables>;
export const CreateCarePlanDraftDocument = gql`
    mutation CreateCarePlanDraft($input: CreateCarePlanDraftInput!) {
  createCarePlanDraft(input: $input) {
    __typename
    ... on CreateCarePlanDraftSuccess {
      carePlanDraft {
        draftId
        createdAt
        updatedAt
        upstreamRevisionId
        metadata {
          numberOfEdits
          warnings {
            __typename
            ... on GoalWithoutAction {
              goalDraftId
            }
            ... on ActionWithoutGoal {
              actionDraftId
            }
            ... on GoalDescriptionEmpty {
              goalDraftId
            }
            ... on ActionDescriptionEmpty {
              actionDraftId
            }
          }
        }
        goals {
          __typename
          draftId
          createdAt
          updatedAt
          description
          associatedActionDrafts {
            __typename
            draftId
            createdAt
            updatedAt
            description
          }
        }
        metadata {
          warnings {
            __typename
            ... on GoalWithoutAction {
              goalDraftId
            }
            ... on ActionWithoutGoal {
              actionDraftId
            }
            ... on GoalDescriptionEmpty {
              goalDraftId
            }
            ... on ActionDescriptionEmpty {
              actionDraftId
            }
          }
        }
      }
    }
    ... on DraftInFlightAlreadyError {
      __typename
      consultationId
      draftId
      createdAt
      updatedAt
    }
    ... on RevisionAlreadyPublishedInConsultationError {
      __typename
      carePlanRevisionId
      consultationId
      patientId
    }
    ... on ConsultationDoesNotExistError {
      __typename
      consultationId
    }
  }
}
    `;
export type CreateCarePlanDraftMutationFn = Apollo.MutationFunction<CreateCarePlanDraftMutation, CreateCarePlanDraftMutationVariables>;

/**
 * __useCreateCarePlanDraftMutation__
 *
 * To run a mutation, you first call `useCreateCarePlanDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarePlanDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarePlanDraftMutation, { data, loading, error }] = useCreateCarePlanDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarePlanDraftMutation(baseOptions?: Apollo.MutationHookOptions<CreateCarePlanDraftMutation, CreateCarePlanDraftMutationVariables>) {
        return Apollo.useMutation<CreateCarePlanDraftMutation, CreateCarePlanDraftMutationVariables>(CreateCarePlanDraftDocument, baseOptions);
      }
export type CreateCarePlanDraftMutationHookResult = ReturnType<typeof useCreateCarePlanDraftMutation>;
export type CreateCarePlanDraftMutationResult = Apollo.MutationResult<CreateCarePlanDraftMutation>;
export type CreateCarePlanDraftMutationOptions = Apollo.BaseMutationOptions<CreateCarePlanDraftMutation, CreateCarePlanDraftMutationVariables>;
export const DeleteCarePlanDraftDocument = gql`
    mutation DeleteCarePlanDraft($input: DeleteCarePlanDraftInput!) {
  deleteCarePlanDraft(input: $input) {
    __typename
    ... on DeleteCarePlanDraftSuccess {
      _
    }
    ... on ConsultationDoesNotExistError {
      consultationId
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on CarePlanAuthorizationError {
      action
      resource
    }
  }
}
    `;
export type DeleteCarePlanDraftMutationFn = Apollo.MutationFunction<DeleteCarePlanDraftMutation, DeleteCarePlanDraftMutationVariables>;

/**
 * __useDeleteCarePlanDraftMutation__
 *
 * To run a mutation, you first call `useDeleteCarePlanDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarePlanDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarePlanDraftMutation, { data, loading, error }] = useDeleteCarePlanDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCarePlanDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCarePlanDraftMutation, DeleteCarePlanDraftMutationVariables>) {
        return Apollo.useMutation<DeleteCarePlanDraftMutation, DeleteCarePlanDraftMutationVariables>(DeleteCarePlanDraftDocument, baseOptions);
      }
export type DeleteCarePlanDraftMutationHookResult = ReturnType<typeof useDeleteCarePlanDraftMutation>;
export type DeleteCarePlanDraftMutationResult = Apollo.MutationResult<DeleteCarePlanDraftMutation>;
export type DeleteCarePlanDraftMutationOptions = Apollo.BaseMutationOptions<DeleteCarePlanDraftMutation, DeleteCarePlanDraftMutationVariables>;
export const AddGoalDraftDocument = gql`
    mutation AddGoalDraft($input: CarePlanAddGoalDraftInput!) {
  addGoalDraft(input: $input) {
    __typename
    ... on AddGoalDraftSuccess {
      goalDraft {
        draftId
        updatedAt
        createdAt
        description
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
  }
}
    `;
export type AddGoalDraftMutationFn = Apollo.MutationFunction<AddGoalDraftMutation, AddGoalDraftMutationVariables>;

/**
 * __useAddGoalDraftMutation__
 *
 * To run a mutation, you first call `useAddGoalDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGoalDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoalDraftMutation, { data, loading, error }] = useAddGoalDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGoalDraftMutation(baseOptions?: Apollo.MutationHookOptions<AddGoalDraftMutation, AddGoalDraftMutationVariables>) {
        return Apollo.useMutation<AddGoalDraftMutation, AddGoalDraftMutationVariables>(AddGoalDraftDocument, baseOptions);
      }
export type AddGoalDraftMutationHookResult = ReturnType<typeof useAddGoalDraftMutation>;
export type AddGoalDraftMutationResult = Apollo.MutationResult<AddGoalDraftMutation>;
export type AddGoalDraftMutationOptions = Apollo.BaseMutationOptions<AddGoalDraftMutation, AddGoalDraftMutationVariables>;
export const EditGoalDraftDocument = gql`
    mutation EditGoalDraft($input: CarePlanEditGoalDraftInput!) {
  editGoalDraft(input: $input) {
    __typename
    ... on EditGoalDraftSuccess {
      goalDraft {
        draftId
        updatedAt
        createdAt
        description
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
  }
}
    `;
export type EditGoalDraftMutationFn = Apollo.MutationFunction<EditGoalDraftMutation, EditGoalDraftMutationVariables>;

/**
 * __useEditGoalDraftMutation__
 *
 * To run a mutation, you first call `useEditGoalDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGoalDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGoalDraftMutation, { data, loading, error }] = useEditGoalDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGoalDraftMutation(baseOptions?: Apollo.MutationHookOptions<EditGoalDraftMutation, EditGoalDraftMutationVariables>) {
        return Apollo.useMutation<EditGoalDraftMutation, EditGoalDraftMutationVariables>(EditGoalDraftDocument, baseOptions);
      }
export type EditGoalDraftMutationHookResult = ReturnType<typeof useEditGoalDraftMutation>;
export type EditGoalDraftMutationResult = Apollo.MutationResult<EditGoalDraftMutation>;
export type EditGoalDraftMutationOptions = Apollo.BaseMutationOptions<EditGoalDraftMutation, EditGoalDraftMutationVariables>;
export const DeleteGoalDraftDocument = gql`
    mutation DeleteGoalDraft($input: CarePlanDeleteGoalDraftInput!) {
  deleteGoalDraft(input: $input) {
    __typename
    ... on DeleteGoalDraftSuccess {
      parentCarePlanDraft {
        draftId
        metadata {
          numberOfEdits
          warnings {
            __typename
            ... on GoalDescriptionEmpty {
              goalDraftId
            }
            ... on ActionDescriptionEmpty {
              actionDraftId
            }
            ... on GoalWithoutAction {
              goalDraftId
            }
            ... on ActionWithoutGoal {
              actionDraftId
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on GoalDraftDoesNotExistError {
      goalDraftId
    }
  }
}
    `;
export type DeleteGoalDraftMutationFn = Apollo.MutationFunction<DeleteGoalDraftMutation, DeleteGoalDraftMutationVariables>;

/**
 * __useDeleteGoalDraftMutation__
 *
 * To run a mutation, you first call `useDeleteGoalDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoalDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoalDraftMutation, { data, loading, error }] = useDeleteGoalDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGoalDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGoalDraftMutation, DeleteGoalDraftMutationVariables>) {
        return Apollo.useMutation<DeleteGoalDraftMutation, DeleteGoalDraftMutationVariables>(DeleteGoalDraftDocument, baseOptions);
      }
export type DeleteGoalDraftMutationHookResult = ReturnType<typeof useDeleteGoalDraftMutation>;
export type DeleteGoalDraftMutationResult = Apollo.MutationResult<DeleteGoalDraftMutation>;
export type DeleteGoalDraftMutationOptions = Apollo.BaseMutationOptions<DeleteGoalDraftMutation, DeleteGoalDraftMutationVariables>;
export const AddActionDraftDocument = gql`
    mutation AddActionDraft($input: CarePlanAddActionDraftInput!) {
  addActionDraft(input: $input) {
    __typename
    ... on AddActionDraftSuccess {
      actionDraft {
        draftId
        description
        createdAt
        updatedAt
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
  }
}
    `;
export type AddActionDraftMutationFn = Apollo.MutationFunction<AddActionDraftMutation, AddActionDraftMutationVariables>;

/**
 * __useAddActionDraftMutation__
 *
 * To run a mutation, you first call `useAddActionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActionDraftMutation, { data, loading, error }] = useAddActionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddActionDraftMutation(baseOptions?: Apollo.MutationHookOptions<AddActionDraftMutation, AddActionDraftMutationVariables>) {
        return Apollo.useMutation<AddActionDraftMutation, AddActionDraftMutationVariables>(AddActionDraftDocument, baseOptions);
      }
export type AddActionDraftMutationHookResult = ReturnType<typeof useAddActionDraftMutation>;
export type AddActionDraftMutationResult = Apollo.MutationResult<AddActionDraftMutation>;
export type AddActionDraftMutationOptions = Apollo.BaseMutationOptions<AddActionDraftMutation, AddActionDraftMutationVariables>;
export const AssociateGoalAndActionDraftDocument = gql`
    mutation AssociateGoalAndActionDraft($input: CarePlanAssociateGoalAndActionDraftInput!) {
  associateGoalAndActionDraft(input: $input) {
    __typename
    ... on AssociateGoalAndActionDraftSuccess {
      goalDraft {
        draftId
      }
      actionDraft {
        draftId
        createdAt
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on GoalDraftDoesNotExistError {
      goalDraftId
    }
    ... on ActionDraftDoesNotExistError {
      actionDraftId
    }
    ... on GoalActionDraftAssociationAlreadyExistError {
      goalDraftId
      actionDraftId
    }
  }
}
    `;
export type AssociateGoalAndActionDraftMutationFn = Apollo.MutationFunction<AssociateGoalAndActionDraftMutation, AssociateGoalAndActionDraftMutationVariables>;

/**
 * __useAssociateGoalAndActionDraftMutation__
 *
 * To run a mutation, you first call `useAssociateGoalAndActionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateGoalAndActionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateGoalAndActionDraftMutation, { data, loading, error }] = useAssociateGoalAndActionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateGoalAndActionDraftMutation(baseOptions?: Apollo.MutationHookOptions<AssociateGoalAndActionDraftMutation, AssociateGoalAndActionDraftMutationVariables>) {
        return Apollo.useMutation<AssociateGoalAndActionDraftMutation, AssociateGoalAndActionDraftMutationVariables>(AssociateGoalAndActionDraftDocument, baseOptions);
      }
export type AssociateGoalAndActionDraftMutationHookResult = ReturnType<typeof useAssociateGoalAndActionDraftMutation>;
export type AssociateGoalAndActionDraftMutationResult = Apollo.MutationResult<AssociateGoalAndActionDraftMutation>;
export type AssociateGoalAndActionDraftMutationOptions = Apollo.BaseMutationOptions<AssociateGoalAndActionDraftMutation, AssociateGoalAndActionDraftMutationVariables>;
export const AddAndAssociateActionDraftDocument = gql`
    mutation AddAndAssociateActionDraft($addActionInput: CarePlanAddActionDraftInput!, $associateInput: CarePlanAssociateGoalAndActionDraftInput!) {
  addActionDraft(input: $addActionInput) {
    __typename
    ... on AddActionDraftSuccess {
      actionDraft {
        draftId
        description
        createdAt
        updatedAt
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
  }
  associateGoalAndActionDraft(input: $associateInput) {
    __typename
    ... on AssociateGoalAndActionDraftSuccess {
      goalDraft {
        draftId
      }
      actionDraft {
        draftId
        createdAt
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on GoalDraftDoesNotExistError {
      goalDraftId
    }
    ... on ActionDraftDoesNotExistError {
      actionDraftId
    }
    ... on GoalActionDraftAssociationAlreadyExistError {
      goalDraftId
      actionDraftId
    }
  }
}
    `;
export type AddAndAssociateActionDraftMutationFn = Apollo.MutationFunction<AddAndAssociateActionDraftMutation, AddAndAssociateActionDraftMutationVariables>;

/**
 * __useAddAndAssociateActionDraftMutation__
 *
 * To run a mutation, you first call `useAddAndAssociateActionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAndAssociateActionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAndAssociateActionDraftMutation, { data, loading, error }] = useAddAndAssociateActionDraftMutation({
 *   variables: {
 *      addActionInput: // value for 'addActionInput'
 *      associateInput: // value for 'associateInput'
 *   },
 * });
 */
export function useAddAndAssociateActionDraftMutation(baseOptions?: Apollo.MutationHookOptions<AddAndAssociateActionDraftMutation, AddAndAssociateActionDraftMutationVariables>) {
        return Apollo.useMutation<AddAndAssociateActionDraftMutation, AddAndAssociateActionDraftMutationVariables>(AddAndAssociateActionDraftDocument, baseOptions);
      }
export type AddAndAssociateActionDraftMutationHookResult = ReturnType<typeof useAddAndAssociateActionDraftMutation>;
export type AddAndAssociateActionDraftMutationResult = Apollo.MutationResult<AddAndAssociateActionDraftMutation>;
export type AddAndAssociateActionDraftMutationOptions = Apollo.BaseMutationOptions<AddAndAssociateActionDraftMutation, AddAndAssociateActionDraftMutationVariables>;
export const EditActionDraftDocument = gql`
    mutation EditActionDraft($input: CarePlanEditActionDraftInput!) {
  editActionDraft(input: $input) {
    __typename
    ... on EditActionDraftSuccess {
      actionDraft {
        draftId
        updatedAt
        createdAt
        description
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
  }
}
    `;
export type EditActionDraftMutationFn = Apollo.MutationFunction<EditActionDraftMutation, EditActionDraftMutationVariables>;

/**
 * __useEditActionDraftMutation__
 *
 * To run a mutation, you first call `useEditActionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActionDraftMutation, { data, loading, error }] = useEditActionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActionDraftMutation(baseOptions?: Apollo.MutationHookOptions<EditActionDraftMutation, EditActionDraftMutationVariables>) {
        return Apollo.useMutation<EditActionDraftMutation, EditActionDraftMutationVariables>(EditActionDraftDocument, baseOptions);
      }
export type EditActionDraftMutationHookResult = ReturnType<typeof useEditActionDraftMutation>;
export type EditActionDraftMutationResult = Apollo.MutationResult<EditActionDraftMutation>;
export type EditActionDraftMutationOptions = Apollo.BaseMutationOptions<EditActionDraftMutation, EditActionDraftMutationVariables>;
export const DeleteActionDraftDocument = gql`
    mutation DeleteActionDraft($input: CarePlanDeleteActionDraftInput!) {
  deleteActionDraft(input: $input) {
    __typename
    ... on DeleteActionDraftSuccess {
      parentCarePlanDraft {
        draftId
        metadata {
          numberOfEdits
          warnings {
            __typename
            ... on GoalDescriptionEmpty {
              goalDraftId
            }
            ... on ActionDescriptionEmpty {
              actionDraftId
            }
            ... on GoalWithoutAction {
              goalDraftId
            }
            ... on ActionWithoutGoal {
              actionDraftId
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on ActionDraftDoesNotExistError {
      actionDraftId
    }
  }
}
    `;
export type DeleteActionDraftMutationFn = Apollo.MutationFunction<DeleteActionDraftMutation, DeleteActionDraftMutationVariables>;

/**
 * __useDeleteActionDraftMutation__
 *
 * To run a mutation, you first call `useDeleteActionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionDraftMutation, { data, loading, error }] = useDeleteActionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActionDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActionDraftMutation, DeleteActionDraftMutationVariables>) {
        return Apollo.useMutation<DeleteActionDraftMutation, DeleteActionDraftMutationVariables>(DeleteActionDraftDocument, baseOptions);
      }
export type DeleteActionDraftMutationHookResult = ReturnType<typeof useDeleteActionDraftMutation>;
export type DeleteActionDraftMutationResult = Apollo.MutationResult<DeleteActionDraftMutation>;
export type DeleteActionDraftMutationOptions = Apollo.BaseMutationOptions<DeleteActionDraftMutation, DeleteActionDraftMutationVariables>;
export const DissociateGoalAndActionDraftDocument = gql`
    mutation dissociateGoalAndActionDraft($input: CarePlanDissociateGoalAndActionDraftInput!) {
  dissociateGoalAndActionDraft(input: $input) {
    __typename
    ... on DissociateGoalAndActionDraftSuccess {
      actionDraft {
        draftId
        parentCarePlanDraft {
          draftId
          metadata {
            numberOfEdits
            warnings {
              __typename
              ... on GoalDescriptionEmpty {
                goalDraftId
              }
              ... on ActionDescriptionEmpty {
                actionDraftId
              }
              ... on GoalWithoutAction {
                goalDraftId
              }
              ... on ActionWithoutGoal {
                actionDraftId
              }
            }
          }
        }
      }
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on ActionDraftDoesNotExistError {
      actionDraftId
    }
    ... on GoalDraftDoesNotExistError {
      goalDraftId
    }
    ... on GoalActionDraftAssociationDoesNotExistError {
      goalDraftId
      actionDraftId
    }
  }
}
    `;
export type DissociateGoalAndActionDraftMutationFn = Apollo.MutationFunction<DissociateGoalAndActionDraftMutation, DissociateGoalAndActionDraftMutationVariables>;

/**
 * __useDissociateGoalAndActionDraftMutation__
 *
 * To run a mutation, you first call `useDissociateGoalAndActionDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDissociateGoalAndActionDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dissociateGoalAndActionDraftMutation, { data, loading, error }] = useDissociateGoalAndActionDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDissociateGoalAndActionDraftMutation(baseOptions?: Apollo.MutationHookOptions<DissociateGoalAndActionDraftMutation, DissociateGoalAndActionDraftMutationVariables>) {
        return Apollo.useMutation<DissociateGoalAndActionDraftMutation, DissociateGoalAndActionDraftMutationVariables>(DissociateGoalAndActionDraftDocument, baseOptions);
      }
export type DissociateGoalAndActionDraftMutationHookResult = ReturnType<typeof useDissociateGoalAndActionDraftMutation>;
export type DissociateGoalAndActionDraftMutationResult = Apollo.MutationResult<DissociateGoalAndActionDraftMutation>;
export type DissociateGoalAndActionDraftMutationOptions = Apollo.BaseMutationOptions<DissociateGoalAndActionDraftMutation, DissociateGoalAndActionDraftMutationVariables>;
export const CarePlanDocument = gql`
    query CarePlan($patientId: ID!) {
  carePlan(patientId: $patientId) {
    __typename
    ... on CarePlan {
      id
      entityCreatedAt
      revisionCreatedAt
      revisionId
      goals {
        id
        description
        entityCreatedAt
        revisionCreatedAt
        associatedActions {
          id
          description
          entityCreatedAt
          revisionCreatedAt
        }
      }
    }
    ... on CarePlanAuthorizationError {
      action
      resource
    }
  }
}
    `;

/**
 * __useCarePlanQuery__
 *
 * To run a query within a React component, call `useCarePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCarePlanQuery(baseOptions: Apollo.QueryHookOptions<CarePlanQuery, CarePlanQueryVariables>) {
        return Apollo.useQuery<CarePlanQuery, CarePlanQueryVariables>(CarePlanDocument, baseOptions);
      }
export function useCarePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarePlanQuery, CarePlanQueryVariables>) {
          return Apollo.useLazyQuery<CarePlanQuery, CarePlanQueryVariables>(CarePlanDocument, baseOptions);
        }
export type CarePlanQueryHookResult = ReturnType<typeof useCarePlanQuery>;
export type CarePlanLazyQueryHookResult = ReturnType<typeof useCarePlanLazyQuery>;
export type CarePlanQueryResult = Apollo.QueryResult<CarePlanQuery, CarePlanQueryVariables>;
export const CarePlanPublishedInConsultationDocument = gql`
    query CarePlanPublishedInConsultation($patientId: ID!, $consultationId: ID!) {
  carePlanPublishedInConsultation(patientId: $patientId, consultationId: $consultationId) {
    __typename
    ... on CarePlan {
      id
      entityCreatedAt
      revisionCreatedAt
      revisionId
      goals {
        id
        description
        entityCreatedAt
        revisionCreatedAt
        associatedActions {
          id
          description
          entityCreatedAt
          revisionCreatedAt
        }
      }
    }
    ... on CarePlanAuthorizationError {
      action
      resource
    }
  }
}
    `;

/**
 * __useCarePlanPublishedInConsultationQuery__
 *
 * To run a query within a React component, call `useCarePlanPublishedInConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanPublishedInConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanPublishedInConsultationQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useCarePlanPublishedInConsultationQuery(baseOptions: Apollo.QueryHookOptions<CarePlanPublishedInConsultationQuery, CarePlanPublishedInConsultationQueryVariables>) {
        return Apollo.useQuery<CarePlanPublishedInConsultationQuery, CarePlanPublishedInConsultationQueryVariables>(CarePlanPublishedInConsultationDocument, baseOptions);
      }
export function useCarePlanPublishedInConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarePlanPublishedInConsultationQuery, CarePlanPublishedInConsultationQueryVariables>) {
          return Apollo.useLazyQuery<CarePlanPublishedInConsultationQuery, CarePlanPublishedInConsultationQueryVariables>(CarePlanPublishedInConsultationDocument, baseOptions);
        }
export type CarePlanPublishedInConsultationQueryHookResult = ReturnType<typeof useCarePlanPublishedInConsultationQuery>;
export type CarePlanPublishedInConsultationLazyQueryHookResult = ReturnType<typeof useCarePlanPublishedInConsultationLazyQuery>;
export type CarePlanPublishedInConsultationQueryResult = Apollo.QueryResult<CarePlanPublishedInConsultationQuery, CarePlanPublishedInConsultationQueryVariables>;
export const CarePlanDraftDocument = gql`
    query CarePlanDraft($patientId: ID!, $consultationId: ID!) {
  carePlanDraft(patientId: $patientId, consultationId: $consultationId) {
    __typename
    ... on CarePlanDraft {
      __typename
      draftId
      createdAt
      updatedAt
      goals {
        draftId
        description
        createdAt
        updatedAt
        associatedActionDrafts {
          draftId
          description
          createdAt
          updatedAt
        }
      }
      metadata {
        numberOfEdits
        warnings {
          __typename
        }
      }
    }
    ... on CarePlanAuthorizationError {
      action
      resource
    }
  }
}
    `;

/**
 * __useCarePlanDraftQuery__
 *
 * To run a query within a React component, call `useCarePlanDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanDraftQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useCarePlanDraftQuery(baseOptions: Apollo.QueryHookOptions<CarePlanDraftQuery, CarePlanDraftQueryVariables>) {
        return Apollo.useQuery<CarePlanDraftQuery, CarePlanDraftQueryVariables>(CarePlanDraftDocument, baseOptions);
      }
export function useCarePlanDraftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarePlanDraftQuery, CarePlanDraftQueryVariables>) {
          return Apollo.useLazyQuery<CarePlanDraftQuery, CarePlanDraftQueryVariables>(CarePlanDraftDocument, baseOptions);
        }
export type CarePlanDraftQueryHookResult = ReturnType<typeof useCarePlanDraftQuery>;
export type CarePlanDraftLazyQueryHookResult = ReturnType<typeof useCarePlanDraftLazyQuery>;
export type CarePlanDraftQueryResult = Apollo.QueryResult<CarePlanDraftQuery, CarePlanDraftQueryVariables>;
export const CarePlanReviewBaseDocument = gql`
    query CarePlanReviewBase($id: ID!) {
  consultation(id: $id) {
    id
    uuid: consultationId
    status
    patient {
      uuid
    }
  }
}
    `;

/**
 * __useCarePlanReviewBaseQuery__
 *
 * To run a query within a React component, call `useCarePlanReviewBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarePlanReviewBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarePlanReviewBaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCarePlanReviewBaseQuery(baseOptions: Apollo.QueryHookOptions<CarePlanReviewBaseQuery, CarePlanReviewBaseQueryVariables>) {
        return Apollo.useQuery<CarePlanReviewBaseQuery, CarePlanReviewBaseQueryVariables>(CarePlanReviewBaseDocument, baseOptions);
      }
export function useCarePlanReviewBaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarePlanReviewBaseQuery, CarePlanReviewBaseQueryVariables>) {
          return Apollo.useLazyQuery<CarePlanReviewBaseQuery, CarePlanReviewBaseQueryVariables>(CarePlanReviewBaseDocument, baseOptions);
        }
export type CarePlanReviewBaseQueryHookResult = ReturnType<typeof useCarePlanReviewBaseQuery>;
export type CarePlanReviewBaseLazyQueryHookResult = ReturnType<typeof useCarePlanReviewBaseLazyQuery>;
export type CarePlanReviewBaseQueryResult = Apollo.QueryResult<CarePlanReviewBaseQuery, CarePlanReviewBaseQueryVariables>;
export const CpLiteChatscriptMessagesDocument = gql`
    query CpLiteChatscriptMessages($eventId: ID!, $patientUuid: ID!, $limit: Int!, $offset: Int!) {
  chatscriptMessages(userId: $patientUuid, conversationId: $eventId, limit: $limit, offset: $offset) {
    more
    messages {
      id
      input {
        params {
          choices {
            id
            label
          }
        }
      }
      value {
        text
      }
      sender
    }
  }
}
    `;

/**
 * __useCpLiteChatscriptMessagesQuery__
 *
 * To run a query within a React component, call `useCpLiteChatscriptMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpLiteChatscriptMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpLiteChatscriptMessagesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      patientUuid: // value for 'patientUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCpLiteChatscriptMessagesQuery(baseOptions: Apollo.QueryHookOptions<CpLiteChatscriptMessagesQuery, CpLiteChatscriptMessagesQueryVariables>) {
        return Apollo.useQuery<CpLiteChatscriptMessagesQuery, CpLiteChatscriptMessagesQueryVariables>(CpLiteChatscriptMessagesDocument, baseOptions);
      }
export function useCpLiteChatscriptMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpLiteChatscriptMessagesQuery, CpLiteChatscriptMessagesQueryVariables>) {
          return Apollo.useLazyQuery<CpLiteChatscriptMessagesQuery, CpLiteChatscriptMessagesQueryVariables>(CpLiteChatscriptMessagesDocument, baseOptions);
        }
export type CpLiteChatscriptMessagesQueryHookResult = ReturnType<typeof useCpLiteChatscriptMessagesQuery>;
export type CpLiteChatscriptMessagesLazyQueryHookResult = ReturnType<typeof useCpLiteChatscriptMessagesLazyQuery>;
export type CpLiteChatscriptMessagesQueryResult = Apollo.QueryResult<CpLiteChatscriptMessagesQuery, CpLiteChatscriptMessagesQueryVariables>;
export const CpChatscriptConversationDocument = gql`
    query CpChatscriptConversation($eventId: ID!, $patientUuid: ID!) {
  chatscriptConversation(userId: $patientUuid, conversationId: $eventId) {
    id
    startedAt
    preview
    type
    status
    patientUuid
    userUuid
    result {
      outcome
      type
    }
  }
}
    `;

/**
 * __useCpChatscriptConversationQuery__
 *
 * To run a query within a React component, call `useCpChatscriptConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpChatscriptConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpChatscriptConversationQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      patientUuid: // value for 'patientUuid'
 *   },
 * });
 */
export function useCpChatscriptConversationQuery(baseOptions: Apollo.QueryHookOptions<CpChatscriptConversationQuery, CpChatscriptConversationQueryVariables>) {
        return Apollo.useQuery<CpChatscriptConversationQuery, CpChatscriptConversationQueryVariables>(CpChatscriptConversationDocument, baseOptions);
      }
export function useCpChatscriptConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpChatscriptConversationQuery, CpChatscriptConversationQueryVariables>) {
          return Apollo.useLazyQuery<CpChatscriptConversationQuery, CpChatscriptConversationQueryVariables>(CpChatscriptConversationDocument, baseOptions);
        }
export type CpChatscriptConversationQueryHookResult = ReturnType<typeof useCpChatscriptConversationQuery>;
export type CpChatscriptConversationLazyQueryHookResult = ReturnType<typeof useCpChatscriptConversationLazyQuery>;
export type CpChatscriptConversationQueryResult = Apollo.QueryResult<CpChatscriptConversationQuery, CpChatscriptConversationQueryVariables>;
export const ClinicalCodeWeightingsDocument = gql`
    query ClinicalCodeWeightings($countryCode: String!) {
  cp3ClinicalCodeWeightings(countryCode: $countryCode) {
    id
    countryCode {
      name
      codename
    }
    rankings
  }
}
    `;

/**
 * __useClinicalCodeWeightingsQuery__
 *
 * To run a query within a React component, call `useClinicalCodeWeightingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicalCodeWeightingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicalCodeWeightingsQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useClinicalCodeWeightingsQuery(baseOptions: Apollo.QueryHookOptions<ClinicalCodeWeightingsQuery, ClinicalCodeWeightingsQueryVariables>) {
        return Apollo.useQuery<ClinicalCodeWeightingsQuery, ClinicalCodeWeightingsQueryVariables>(ClinicalCodeWeightingsDocument, baseOptions);
      }
export function useClinicalCodeWeightingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClinicalCodeWeightingsQuery, ClinicalCodeWeightingsQueryVariables>) {
          return Apollo.useLazyQuery<ClinicalCodeWeightingsQuery, ClinicalCodeWeightingsQueryVariables>(ClinicalCodeWeightingsDocument, baseOptions);
        }
export type ClinicalCodeWeightingsQueryHookResult = ReturnType<typeof useClinicalCodeWeightingsQuery>;
export type ClinicalCodeWeightingsLazyQueryHookResult = ReturnType<typeof useClinicalCodeWeightingsLazyQuery>;
export type ClinicalCodeWeightingsQueryResult = Apollo.QueryResult<ClinicalCodeWeightingsQuery, ClinicalCodeWeightingsQueryVariables>;
export const SuggestedClinicalCodesQueryDocument = gql`
    query SuggestedClinicalCodesQuery($query: String!, $region: String) {
  searchCodingDiagnosis(query: $query, limit: 50, region: $region) {
    id
    preferredLabel
    iri
    hasNotes
  }
}
    `;

/**
 * __useSuggestedClinicalCodesQueryQuery__
 *
 * To run a query within a React component, call `useSuggestedClinicalCodesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedClinicalCodesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedClinicalCodesQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useSuggestedClinicalCodesQueryQuery(baseOptions: Apollo.QueryHookOptions<SuggestedClinicalCodesQueryQuery, SuggestedClinicalCodesQueryQueryVariables>) {
        return Apollo.useQuery<SuggestedClinicalCodesQueryQuery, SuggestedClinicalCodesQueryQueryVariables>(SuggestedClinicalCodesQueryDocument, baseOptions);
      }
export function useSuggestedClinicalCodesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedClinicalCodesQueryQuery, SuggestedClinicalCodesQueryQueryVariables>) {
          return Apollo.useLazyQuery<SuggestedClinicalCodesQueryQuery, SuggestedClinicalCodesQueryQueryVariables>(SuggestedClinicalCodesQueryDocument, baseOptions);
        }
export type SuggestedClinicalCodesQueryQueryHookResult = ReturnType<typeof useSuggestedClinicalCodesQueryQuery>;
export type SuggestedClinicalCodesQueryLazyQueryHookResult = ReturnType<typeof useSuggestedClinicalCodesQueryLazyQuery>;
export type SuggestedClinicalCodesQueryQueryResult = Apollo.QueryResult<SuggestedClinicalCodesQueryQuery, SuggestedClinicalCodesQueryQueryVariables>;
export const CpMarkConsultationAsCompleteDocument = gql`
    mutation CpMarkConsultationAsComplete($id: ID!, $exempted: Boolean) {
  updateConsultationStatus(id: $id, status: complete, exempted: $exempted) {
    id
    status
  }
}
    `;
export type CpMarkConsultationAsCompleteMutationFn = Apollo.MutationFunction<CpMarkConsultationAsCompleteMutation, CpMarkConsultationAsCompleteMutationVariables>;

/**
 * __useCpMarkConsultationAsCompleteMutation__
 *
 * To run a mutation, you first call `useCpMarkConsultationAsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpMarkConsultationAsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpMarkConsultationAsCompleteMutation, { data, loading, error }] = useCpMarkConsultationAsCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exempted: // value for 'exempted'
 *   },
 * });
 */
export function useCpMarkConsultationAsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<CpMarkConsultationAsCompleteMutation, CpMarkConsultationAsCompleteMutationVariables>) {
        return Apollo.useMutation<CpMarkConsultationAsCompleteMutation, CpMarkConsultationAsCompleteMutationVariables>(CpMarkConsultationAsCompleteDocument, baseOptions);
      }
export type CpMarkConsultationAsCompleteMutationHookResult = ReturnType<typeof useCpMarkConsultationAsCompleteMutation>;
export type CpMarkConsultationAsCompleteMutationResult = Apollo.MutationResult<CpMarkConsultationAsCompleteMutation>;
export type CpMarkConsultationAsCompleteMutationOptions = Apollo.BaseMutationOptions<CpMarkConsultationAsCompleteMutation, CpMarkConsultationAsCompleteMutationVariables>;
export const CpPrescriptionChangeStateDocument = gql`
    mutation CpPrescriptionChangeState($id: ID!, $reason: String, $action: PrescriptionStateActionType!, $region: String!) {
  prescriptionChangeState(id: $id, reason: $reason, action: $action, region: $region) {
    id
    createdAt
    prescriptionState
    actions
  }
}
    `;
export type CpPrescriptionChangeStateMutationFn = Apollo.MutationFunction<CpPrescriptionChangeStateMutation, CpPrescriptionChangeStateMutationVariables>;

/**
 * __useCpPrescriptionChangeStateMutation__
 *
 * To run a mutation, you first call `useCpPrescriptionChangeStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpPrescriptionChangeStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpPrescriptionChangeStateMutation, { data, loading, error }] = useCpPrescriptionChangeStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      action: // value for 'action'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCpPrescriptionChangeStateMutation(baseOptions?: Apollo.MutationHookOptions<CpPrescriptionChangeStateMutation, CpPrescriptionChangeStateMutationVariables>) {
        return Apollo.useMutation<CpPrescriptionChangeStateMutation, CpPrescriptionChangeStateMutationVariables>(CpPrescriptionChangeStateDocument, baseOptions);
      }
export type CpPrescriptionChangeStateMutationHookResult = ReturnType<typeof useCpPrescriptionChangeStateMutation>;
export type CpPrescriptionChangeStateMutationResult = Apollo.MutationResult<CpPrescriptionChangeStateMutation>;
export type CpPrescriptionChangeStateMutationOptions = Apollo.BaseMutationOptions<CpPrescriptionChangeStateMutation, CpPrescriptionChangeStateMutationVariables>;
export const PromoteCarePlanDraftDocument = gql`
    mutation PromoteCarePlanDraft($input: PromoteCarePlanDraftInput!) {
  promoteCarePlanDraft(input: $input) {
    __typename
    ... on PromoteCarePlanDraftSuccess {
      carePlan {
        id
        entityCreatedAt
        revisionCreatedAt
        revisionId
        goals {
          id
          description
          entityCreatedAt
          revisionCreatedAt
          associatedActions {
            id
            description
            entityCreatedAt
            revisionCreatedAt
          }
        }
      }
    }
    ... on CarePlanDraftDoesNotExistError {
      draftId
    }
    ... on UnresolvedWarningsError {
      carePlanDraftId
      warnings {
        __typename
        ... on GoalDescriptionEmpty {
          goalDraftId
        }
        ... on ActionDescriptionEmpty {
          actionDraftId
        }
        ... on GoalWithoutAction {
          goalDraftId
        }
        ... on ActionWithoutGoal {
          actionDraftId
        }
      }
    }
    ... on ConsultationDoesNotExistError {
      consultationId
    }
    ... on InvalidCarePlanDraftNumbersOfEditsError {
      draftId
      currentNumberOfEdits
      providedNumberOfEdits
    }
    ... on CarePlanAuthorizationError {
      action
      resource
    }
  }
}
    `;
export type PromoteCarePlanDraftMutationFn = Apollo.MutationFunction<PromoteCarePlanDraftMutation, PromoteCarePlanDraftMutationVariables>;

/**
 * __usePromoteCarePlanDraftMutation__
 *
 * To run a mutation, you first call `usePromoteCarePlanDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteCarePlanDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteCarePlanDraftMutation, { data, loading, error }] = usePromoteCarePlanDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoteCarePlanDraftMutation(baseOptions?: Apollo.MutationHookOptions<PromoteCarePlanDraftMutation, PromoteCarePlanDraftMutationVariables>) {
        return Apollo.useMutation<PromoteCarePlanDraftMutation, PromoteCarePlanDraftMutationVariables>(PromoteCarePlanDraftDocument, baseOptions);
      }
export type PromoteCarePlanDraftMutationHookResult = ReturnType<typeof usePromoteCarePlanDraftMutation>;
export type PromoteCarePlanDraftMutationResult = Apollo.MutationResult<PromoteCarePlanDraftMutation>;
export type PromoteCarePlanDraftMutationOptions = Apollo.BaseMutationOptions<PromoteCarePlanDraftMutation, PromoteCarePlanDraftMutationVariables>;
export const SetConsultationExemptedDocument = gql`
    mutation SetConsultationExempted($id: ID!) {
  setConsultationExempted(id: $id) {
    id
    state
  }
}
    `;
export type SetConsultationExemptedMutationFn = Apollo.MutationFunction<SetConsultationExemptedMutation, SetConsultationExemptedMutationVariables>;

/**
 * __useSetConsultationExemptedMutation__
 *
 * To run a mutation, you first call `useSetConsultationExemptedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConsultationExemptedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConsultationExemptedMutation, { data, loading, error }] = useSetConsultationExemptedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetConsultationExemptedMutation(baseOptions?: Apollo.MutationHookOptions<SetConsultationExemptedMutation, SetConsultationExemptedMutationVariables>) {
        return Apollo.useMutation<SetConsultationExemptedMutation, SetConsultationExemptedMutationVariables>(SetConsultationExemptedDocument, baseOptions);
      }
export type SetConsultationExemptedMutationHookResult = ReturnType<typeof useSetConsultationExemptedMutation>;
export type SetConsultationExemptedMutationResult = Apollo.MutationResult<SetConsultationExemptedMutation>;
export type SetConsultationExemptedMutationOptions = Apollo.BaseMutationOptions<SetConsultationExemptedMutation, SetConsultationExemptedMutationVariables>;
export const PrescriptionStateDocument = gql`
    query PrescriptionState($id: ID!) {
  consultation(id: $id) {
    id
    prescriptions {
      id
      prescriptionState
    }
  }
}
    `;

/**
 * __usePrescriptionStateQuery__
 *
 * To run a query within a React component, call `usePrescriptionStateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrescriptionStateQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionStateQuery, PrescriptionStateQueryVariables>) {
        return Apollo.useQuery<PrescriptionStateQuery, PrescriptionStateQueryVariables>(PrescriptionStateDocument, baseOptions);
      }
export function usePrescriptionStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionStateQuery, PrescriptionStateQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionStateQuery, PrescriptionStateQueryVariables>(PrescriptionStateDocument, baseOptions);
        }
export type PrescriptionStateQueryHookResult = ReturnType<typeof usePrescriptionStateQuery>;
export type PrescriptionStateLazyQueryHookResult = ReturnType<typeof usePrescriptionStateLazyQuery>;
export type PrescriptionStateQueryResult = Apollo.QueryResult<PrescriptionStateQuery, PrescriptionStateQueryVariables>;
export const PromotePrescriptionDocument = gql`
    mutation PromotePrescription($id: ID!, $reason: String, $action: PrescriptionStateActionType!, $region: String!, $pin: String) {
  prescriptionChangeState(id: $id, reason: $reason, action: $action, region: $region, pin: $pin) {
    id
    createdAt
    prescriptionState
    actions
  }
}
    `;
export type PromotePrescriptionMutationFn = Apollo.MutationFunction<PromotePrescriptionMutation, PromotePrescriptionMutationVariables>;

/**
 * __usePromotePrescriptionMutation__
 *
 * To run a mutation, you first call `usePromotePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotePrescriptionMutation, { data, loading, error }] = usePromotePrescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      action: // value for 'action'
 *      region: // value for 'region'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function usePromotePrescriptionMutation(baseOptions?: Apollo.MutationHookOptions<PromotePrescriptionMutation, PromotePrescriptionMutationVariables>) {
        return Apollo.useMutation<PromotePrescriptionMutation, PromotePrescriptionMutationVariables>(PromotePrescriptionDocument, baseOptions);
      }
export type PromotePrescriptionMutationHookResult = ReturnType<typeof usePromotePrescriptionMutation>;
export type PromotePrescriptionMutationResult = Apollo.MutationResult<PromotePrescriptionMutation>;
export type PromotePrescriptionMutationOptions = Apollo.BaseMutationOptions<PromotePrescriptionMutation, PromotePrescriptionMutationVariables>;
export const SetInsuranceClaimExemptedDocument = gql`
    mutation SetInsuranceClaimExempted($id: ID!) {
  setInsuranceClaimExempted(id: $id) {
    id
    state
    createdAt
    updatedAt
  }
}
    `;
export type SetInsuranceClaimExemptedMutationFn = Apollo.MutationFunction<SetInsuranceClaimExemptedMutation, SetInsuranceClaimExemptedMutationVariables>;

/**
 * __useSetInsuranceClaimExemptedMutation__
 *
 * To run a mutation, you first call `useSetInsuranceClaimExemptedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInsuranceClaimExemptedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInsuranceClaimExemptedMutation, { data, loading, error }] = useSetInsuranceClaimExemptedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetInsuranceClaimExemptedMutation(baseOptions?: Apollo.MutationHookOptions<SetInsuranceClaimExemptedMutation, SetInsuranceClaimExemptedMutationVariables>) {
        return Apollo.useMutation<SetInsuranceClaimExemptedMutation, SetInsuranceClaimExemptedMutationVariables>(SetInsuranceClaimExemptedDocument, baseOptions);
      }
export type SetInsuranceClaimExemptedMutationHookResult = ReturnType<typeof useSetInsuranceClaimExemptedMutation>;
export type SetInsuranceClaimExemptedMutationResult = Apollo.MutationResult<SetInsuranceClaimExemptedMutation>;
export type SetInsuranceClaimExemptedMutationOptions = Apollo.BaseMutationOptions<SetInsuranceClaimExemptedMutation, SetInsuranceClaimExemptedMutationVariables>;
export const RegenerateClinicianPinDocument = gql`
    mutation RegenerateClinicianPin($id: ID!) {
  regenerateClinicianPin(clinicianId: $id)
}
    `;
export type RegenerateClinicianPinMutationFn = Apollo.MutationFunction<RegenerateClinicianPinMutation, RegenerateClinicianPinMutationVariables>;

/**
 * __useRegenerateClinicianPinMutation__
 *
 * To run a mutation, you first call `useRegenerateClinicianPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateClinicianPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateClinicianPinMutation, { data, loading, error }] = useRegenerateClinicianPinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegenerateClinicianPinMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateClinicianPinMutation, RegenerateClinicianPinMutationVariables>) {
        return Apollo.useMutation<RegenerateClinicianPinMutation, RegenerateClinicianPinMutationVariables>(RegenerateClinicianPinDocument, baseOptions);
      }
export type RegenerateClinicianPinMutationHookResult = ReturnType<typeof useRegenerateClinicianPinMutation>;
export type RegenerateClinicianPinMutationResult = Apollo.MutationResult<RegenerateClinicianPinMutation>;
export type RegenerateClinicianPinMutationOptions = Apollo.BaseMutationOptions<RegenerateClinicianPinMutation, RegenerateClinicianPinMutationVariables>;
export const CpParticipantsDocument = gql`
    query CpParticipants($id: ID!) {
  consultation(id: $id) {
    participants {
      type
      userId
      participant {
        first_name
        last_name
      }
    }
  }
}
    `;

/**
 * __useCpParticipantsQuery__
 *
 * To run a query within a React component, call `useCpParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpParticipantsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpParticipantsQuery(baseOptions: Apollo.QueryHookOptions<CpParticipantsQuery, CpParticipantsQueryVariables>) {
        return Apollo.useQuery<CpParticipantsQuery, CpParticipantsQueryVariables>(CpParticipantsDocument, baseOptions);
      }
export function useCpParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpParticipantsQuery, CpParticipantsQueryVariables>) {
          return Apollo.useLazyQuery<CpParticipantsQuery, CpParticipantsQueryVariables>(CpParticipantsDocument, baseOptions);
        }
export type CpParticipantsQueryHookResult = ReturnType<typeof useCpParticipantsQuery>;
export type CpParticipantsLazyQueryHookResult = ReturnType<typeof useCpParticipantsLazyQuery>;
export type CpParticipantsQueryResult = Apollo.QueryResult<CpParticipantsQuery, CpParticipantsQueryVariables>;
export const ConsultationsQueryDocument = gql`
    query consultationsQuery($searchCriteria: ConsultationSearchCriteria, $sortCriteria: ConsultationSortCriteria, $paginationCriteria: PaginationCriteria) {
  consultationsv2(searchCriteria: $searchCriteria, sortCriteria: $sortCriteria, paginationCriteria: $paginationCriteria) {
    totalItems
    pageIndex
    itemsPerPage
    showBroadWarning
    items {
      id
      consultationId
      scheduledTime
      sentToGp
      consumerNetworkName
      consultationType
      gpConsent
      medium
      status
      consultant {
        id
        name
        role
        profession_name
      }
      patient: patientV2 {
        ... on Patient {
          id
          uuid
          first_name
          last_name
          gender
          public_healthcare_identifier
          date_of_birth
          age @client
          email
          phone_number
          address_post_code
          id_verification
          minor
          patient_alerts {
            id
          }
        }
        ... on RetrievalError {
          __typename
          message
        }
      }
    }
  }
}
    `;

/**
 * __useConsultationsQueryQuery__
 *
 * To run a query within a React component, call `useConsultationsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationsQueryQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *      sortCriteria: // value for 'sortCriteria'
 *      paginationCriteria: // value for 'paginationCriteria'
 *   },
 * });
 */
export function useConsultationsQueryQuery(baseOptions?: Apollo.QueryHookOptions<ConsultationsQueryQuery, ConsultationsQueryQueryVariables>) {
        return Apollo.useQuery<ConsultationsQueryQuery, ConsultationsQueryQueryVariables>(ConsultationsQueryDocument, baseOptions);
      }
export function useConsultationsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationsQueryQuery, ConsultationsQueryQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationsQueryQuery, ConsultationsQueryQueryVariables>(ConsultationsQueryDocument, baseOptions);
        }
export type ConsultationsQueryQueryHookResult = ReturnType<typeof useConsultationsQueryQuery>;
export type ConsultationsQueryLazyQueryHookResult = ReturnType<typeof useConsultationsQueryLazyQuery>;
export type ConsultationsQueryQueryResult = Apollo.QueryResult<ConsultationsQueryQuery, ConsultationsQueryQueryVariables>;
export const ConsumerNetworksDocument = gql`
    query consumerNetworks {
  consumerNetworks {
    id
    name
  }
}
    `;

/**
 * __useConsumerNetworksQuery__
 *
 * To run a query within a React component, call `useConsumerNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsumerNetworksQuery(baseOptions?: Apollo.QueryHookOptions<ConsumerNetworksQuery, ConsumerNetworksQueryVariables>) {
        return Apollo.useQuery<ConsumerNetworksQuery, ConsumerNetworksQueryVariables>(ConsumerNetworksDocument, baseOptions);
      }
export function useConsumerNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerNetworksQuery, ConsumerNetworksQueryVariables>) {
          return Apollo.useLazyQuery<ConsumerNetworksQuery, ConsumerNetworksQueryVariables>(ConsumerNetworksDocument, baseOptions);
        }
export type ConsumerNetworksQueryHookResult = ReturnType<typeof useConsumerNetworksQuery>;
export type ConsumerNetworksLazyQueryHookResult = ReturnType<typeof useConsumerNetworksLazyQuery>;
export type ConsumerNetworksQueryResult = Apollo.QueryResult<ConsumerNetworksQuery, ConsumerNetworksQueryVariables>;
export const SearchCliniciansByNameDocument = gql`
    query searchCliniciansByName($searchValue: String) {
  getConsultants(filters: {full_name: $searchValue}) {
    full_name
  }
}
    `;

/**
 * __useSearchCliniciansByNameQuery__
 *
 * To run a query within a React component, call `useSearchCliniciansByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCliniciansByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCliniciansByNameQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useSearchCliniciansByNameQuery(baseOptions?: Apollo.QueryHookOptions<SearchCliniciansByNameQuery, SearchCliniciansByNameQueryVariables>) {
        return Apollo.useQuery<SearchCliniciansByNameQuery, SearchCliniciansByNameQueryVariables>(SearchCliniciansByNameDocument, baseOptions);
      }
export function useSearchCliniciansByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCliniciansByNameQuery, SearchCliniciansByNameQueryVariables>) {
          return Apollo.useLazyQuery<SearchCliniciansByNameQuery, SearchCliniciansByNameQueryVariables>(SearchCliniciansByNameDocument, baseOptions);
        }
export type SearchCliniciansByNameQueryHookResult = ReturnType<typeof useSearchCliniciansByNameQuery>;
export type SearchCliniciansByNameLazyQueryHookResult = ReturnType<typeof useSearchCliniciansByNameLazyQuery>;
export type SearchCliniciansByNameQueryResult = Apollo.QueryResult<SearchCliniciansByNameQuery, SearchCliniciansByNameQueryVariables>;
export const ConsultationSummaryDocument = gql`
    query ConsultationSummary($id: ID!) {
  consultation(id: $id) {
    id
    consultationId
    gpConsent
    consumerNetwork
    consumerNetworkId
    consumerNetworkName
    preConsultationFlowId
    status
    consultant {
      id
      name
      role
      profession_name
    }
    permissions {
      id
      name
      value
    }
    consultationType
    scheduledTime
    region {
      id
      iso_code
    }
    languageRequested
    recordingConsent
    patient {
      id
      uuid
      patient_alerts {
        id
      }
    }
    ...ReviewFragment
    ...GpDetailsFragment
    ...CpPatientDetails
    ...PresentingComplaint
    ...ExaminationFragment
    ...CpSickNotes
    ...TestsFragment
    ...ReferralsFragment
    ...DiagnosisFragment
  }
}
    ${ReviewFragmentFragmentDoc}
${GpDetailsFragmentFragmentDoc}
${CpPatientDetailsFragmentDoc}
${PresentingComplaintFragmentDoc}
${ExaminationFragmentFragmentDoc}
${CpSickNotesFragmentDoc}
${TestsFragmentFragmentDoc}
${ReferralsFragmentFragmentDoc}
${DiagnosisFragmentFragmentDoc}`;

/**
 * __useConsultationSummaryQuery__
 *
 * To run a query within a React component, call `useConsultationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultationSummaryQuery(baseOptions: Apollo.QueryHookOptions<ConsultationSummaryQuery, ConsultationSummaryQueryVariables>) {
        return Apollo.useQuery<ConsultationSummaryQuery, ConsultationSummaryQueryVariables>(ConsultationSummaryDocument, baseOptions);
      }
export function useConsultationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationSummaryQuery, ConsultationSummaryQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationSummaryQuery, ConsultationSummaryQueryVariables>(ConsultationSummaryDocument, baseOptions);
        }
export type ConsultationSummaryQueryHookResult = ReturnType<typeof useConsultationSummaryQuery>;
export type ConsultationSummaryLazyQueryHookResult = ReturnType<typeof useConsultationSummaryLazyQuery>;
export type ConsultationSummaryQueryResult = Apollo.QueryResult<ConsultationSummaryQuery, ConsultationSummaryQueryVariables>;
export const ConsultationSummaryBaseDocument = gql`
    query ConsultationSummaryBase($id: ID!) {
  consultation(id: $id) {
    id
    status
    consultant {
      id
      uuid
      name
      role
      profession_name
    }
    scheduledTime
    consumerNetworkId
  }
}
    `;

/**
 * __useConsultationSummaryBaseQuery__
 *
 * To run a query within a React component, call `useConsultationSummaryBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationSummaryBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationSummaryBaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultationSummaryBaseQuery(baseOptions: Apollo.QueryHookOptions<ConsultationSummaryBaseQuery, ConsultationSummaryBaseQueryVariables>) {
        return Apollo.useQuery<ConsultationSummaryBaseQuery, ConsultationSummaryBaseQueryVariables>(ConsultationSummaryBaseDocument, baseOptions);
      }
export function useConsultationSummaryBaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationSummaryBaseQuery, ConsultationSummaryBaseQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationSummaryBaseQuery, ConsultationSummaryBaseQueryVariables>(ConsultationSummaryBaseDocument, baseOptions);
        }
export type ConsultationSummaryBaseQueryHookResult = ReturnType<typeof useConsultationSummaryBaseQuery>;
export type ConsultationSummaryBaseLazyQueryHookResult = ReturnType<typeof useConsultationSummaryBaseLazyQuery>;
export type ConsultationSummaryBaseQueryResult = Apollo.QueryResult<ConsultationSummaryBaseQuery, ConsultationSummaryBaseQueryVariables>;
export const ConsultationNotesSummaryDocument = gql`
    query ConsultationNotesSummary($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    consultationNote {
      id
      exploredByGp
      exploredByGpCodes {
        code
        term
        length
        offset
      }
      toldByPatient
      toldByPatientCodes {
        code
        term
        length
        offset
      }
      assessment
      assessmentCodes {
        code
        term
        length
        offset
      }
      treatmentPlan
      fallbackPlan
    }
    ...FurtherInfoFragment
  }
}
    ${FurtherInfoFragmentFragmentDoc}`;

/**
 * __useConsultationNotesSummaryQuery__
 *
 * To run a query within a React component, call `useConsultationNotesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationNotesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationNotesSummaryQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useConsultationNotesSummaryQuery(baseOptions: Apollo.QueryHookOptions<ConsultationNotesSummaryQuery, ConsultationNotesSummaryQueryVariables>) {
        return Apollo.useQuery<ConsultationNotesSummaryQuery, ConsultationNotesSummaryQueryVariables>(ConsultationNotesSummaryDocument, baseOptions);
      }
export function useConsultationNotesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationNotesSummaryQuery, ConsultationNotesSummaryQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationNotesSummaryQuery, ConsultationNotesSummaryQueryVariables>(ConsultationNotesSummaryDocument, baseOptions);
        }
export type ConsultationNotesSummaryQueryHookResult = ReturnType<typeof useConsultationNotesSummaryQuery>;
export type ConsultationNotesSummaryLazyQueryHookResult = ReturnType<typeof useConsultationNotesSummaryLazyQuery>;
export type ConsultationNotesSummaryQueryResult = Apollo.QueryResult<ConsultationNotesSummaryQuery, ConsultationNotesSummaryQueryVariables>;
export const InsuranceClaimDocument = gql`
    query InsuranceClaim($id: ID!) {
  consultation(id: $id) {
    id
    insuranceClaim {
      id
      state
    }
  }
}
    `;

/**
 * __useInsuranceClaimQuery__
 *
 * To run a query within a React component, call `useInsuranceClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceClaimQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuranceClaimQuery(baseOptions: Apollo.QueryHookOptions<InsuranceClaimQuery, InsuranceClaimQueryVariables>) {
        return Apollo.useQuery<InsuranceClaimQuery, InsuranceClaimQueryVariables>(InsuranceClaimDocument, baseOptions);
      }
export function useInsuranceClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceClaimQuery, InsuranceClaimQueryVariables>) {
          return Apollo.useLazyQuery<InsuranceClaimQuery, InsuranceClaimQueryVariables>(InsuranceClaimDocument, baseOptions);
        }
export type InsuranceClaimQueryHookResult = ReturnType<typeof useInsuranceClaimQuery>;
export type InsuranceClaimLazyQueryHookResult = ReturnType<typeof useInsuranceClaimLazyQuery>;
export type InsuranceClaimQueryResult = Apollo.QueryResult<InsuranceClaimQuery, InsuranceClaimQueryVariables>;
export const HasInsuranceClaimDocument = gql`
    query HasInsuranceClaim($id: ID!) {
  consultation(id: $id) {
    id
    hasInsuranceClaim
  }
}
    `;

/**
 * __useHasInsuranceClaimQuery__
 *
 * To run a query within a React component, call `useHasInsuranceClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasInsuranceClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasInsuranceClaimQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHasInsuranceClaimQuery(baseOptions: Apollo.QueryHookOptions<HasInsuranceClaimQuery, HasInsuranceClaimQueryVariables>) {
        return Apollo.useQuery<HasInsuranceClaimQuery, HasInsuranceClaimQueryVariables>(HasInsuranceClaimDocument, baseOptions);
      }
export function useHasInsuranceClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasInsuranceClaimQuery, HasInsuranceClaimQueryVariables>) {
          return Apollo.useLazyQuery<HasInsuranceClaimQuery, HasInsuranceClaimQueryVariables>(HasInsuranceClaimDocument, baseOptions);
        }
export type HasInsuranceClaimQueryHookResult = ReturnType<typeof useHasInsuranceClaimQuery>;
export type HasInsuranceClaimLazyQueryHookResult = ReturnType<typeof useHasInsuranceClaimLazyQuery>;
export type HasInsuranceClaimQueryResult = Apollo.QueryResult<HasInsuranceClaimQuery, HasInsuranceClaimQueryVariables>;
export const ClinicalNotesDocument = gql`
    query ClinicalNotes($iris: [ClinicalCodeInput]!, $resolveDrugs: Boolean) {
  getClinicalNotes(iris: $iris, resolveDrugs: $resolveDrugs) {
    furtherActionNotes
    managementNotes
    iri
  }
}
    `;

/**
 * __useClinicalNotesQuery__
 *
 * To run a query within a React component, call `useClinicalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicalNotesQuery({
 *   variables: {
 *      iris: // value for 'iris'
 *      resolveDrugs: // value for 'resolveDrugs'
 *   },
 * });
 */
export function useClinicalNotesQuery(baseOptions: Apollo.QueryHookOptions<ClinicalNotesQuery, ClinicalNotesQueryVariables>) {
        return Apollo.useQuery<ClinicalNotesQuery, ClinicalNotesQueryVariables>(ClinicalNotesDocument, baseOptions);
      }
export function useClinicalNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClinicalNotesQuery, ClinicalNotesQueryVariables>) {
          return Apollo.useLazyQuery<ClinicalNotesQuery, ClinicalNotesQueryVariables>(ClinicalNotesDocument, baseOptions);
        }
export type ClinicalNotesQueryHookResult = ReturnType<typeof useClinicalNotesQuery>;
export type ClinicalNotesLazyQueryHookResult = ReturnType<typeof useClinicalNotesLazyQuery>;
export type ClinicalNotesQueryResult = Apollo.QueryResult<ClinicalNotesQuery, ClinicalNotesQueryVariables>;
export const UpdateConsultationNoteDocument = gql`
    mutation UpdateConsultationNote($consultationId: ID!, $input: ConsultationNoteInput!) {
  updateConsultationNote(consultationId: $consultationId, input: $input) {
    id
    toldByPatient
    toldByPatientCodes {
      code
      term
      length
      offset
    }
    exploredByGp
    exploredByGpCodes {
      code
      term
      length
      offset
    }
    assessment
    assessmentCodes {
      code
      term
      length
      offset
    }
    treatmentPlan
    fallbackPlan
  }
}
    `;
export type UpdateConsultationNoteMutationFn = Apollo.MutationFunction<UpdateConsultationNoteMutation, UpdateConsultationNoteMutationVariables>;

/**
 * __useUpdateConsultationNoteMutation__
 *
 * To run a mutation, you first call `useUpdateConsultationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultationNoteMutation, { data, loading, error }] = useUpdateConsultationNoteMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsultationNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultationNoteMutation, UpdateConsultationNoteMutationVariables>) {
        return Apollo.useMutation<UpdateConsultationNoteMutation, UpdateConsultationNoteMutationVariables>(UpdateConsultationNoteDocument, baseOptions);
      }
export type UpdateConsultationNoteMutationHookResult = ReturnType<typeof useUpdateConsultationNoteMutation>;
export type UpdateConsultationNoteMutationResult = Apollo.MutationResult<UpdateConsultationNoteMutation>;
export type UpdateConsultationNoteMutationOptions = Apollo.BaseMutationOptions<UpdateConsultationNoteMutation, UpdateConsultationNoteMutationVariables>;
export const DiagnosisDocument = gql`
    query Diagnosis($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    consultationNote {
      id
      assessment
      assessmentCodes {
        code
        term
        length
        offset
      }
      treatmentPlan
      fallbackPlan
    }
  }
}
    `;

/**
 * __useDiagnosisQuery__
 *
 * To run a query within a React component, call `useDiagnosisQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosisQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useDiagnosisQuery(baseOptions: Apollo.QueryHookOptions<DiagnosisQuery, DiagnosisQueryVariables>) {
        return Apollo.useQuery<DiagnosisQuery, DiagnosisQueryVariables>(DiagnosisDocument, baseOptions);
      }
export function useDiagnosisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagnosisQuery, DiagnosisQueryVariables>) {
          return Apollo.useLazyQuery<DiagnosisQuery, DiagnosisQueryVariables>(DiagnosisDocument, baseOptions);
        }
export type DiagnosisQueryHookResult = ReturnType<typeof useDiagnosisQuery>;
export type DiagnosisLazyQueryHookResult = ReturnType<typeof useDiagnosisLazyQuery>;
export type DiagnosisQueryResult = Apollo.QueryResult<DiagnosisQuery, DiagnosisQueryVariables>;
export const ExaminationNotesDocument = gql`
    query ExaminationNotes($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    consultationNote {
      id
      toldByPatient
      exploredByGp
      toldByPatientCodes {
        code
        term
        length
        offset
      }
      exploredByGpCodes {
        code
        term
        length
        offset
      }
    }
  }
}
    `;

/**
 * __useExaminationNotesQuery__
 *
 * To run a query within a React component, call `useExaminationNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExaminationNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExaminationNotesQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useExaminationNotesQuery(baseOptions: Apollo.QueryHookOptions<ExaminationNotesQuery, ExaminationNotesQueryVariables>) {
        return Apollo.useQuery<ExaminationNotesQuery, ExaminationNotesQueryVariables>(ExaminationNotesDocument, baseOptions);
      }
export function useExaminationNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExaminationNotesQuery, ExaminationNotesQueryVariables>) {
          return Apollo.useLazyQuery<ExaminationNotesQuery, ExaminationNotesQueryVariables>(ExaminationNotesDocument, baseOptions);
        }
export type ExaminationNotesQueryHookResult = ReturnType<typeof useExaminationNotesQuery>;
export type ExaminationNotesLazyQueryHookResult = ReturnType<typeof useExaminationNotesLazyQuery>;
export type ExaminationNotesQueryResult = Apollo.QueryResult<ExaminationNotesQuery, ExaminationNotesQueryVariables>;
export const RemoveRemoveFromMedicationsMutationDocument = gql`
    mutation removeRemoveFromMedicationsMutation($patientId: Int!, $id: Int!) {
  removeFromMedications(patient_id: $patientId, id: $id) {
    id
    iri
    info
  }
}
    `;
export type RemoveRemoveFromMedicationsMutationMutationFn = Apollo.MutationFunction<RemoveRemoveFromMedicationsMutationMutation, RemoveRemoveFromMedicationsMutationMutationVariables>;

/**
 * __useRemoveRemoveFromMedicationsMutationMutation__
 *
 * To run a mutation, you first call `useRemoveRemoveFromMedicationsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRemoveFromMedicationsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRemoveFromMedicationsMutationMutation, { data, loading, error }] = useRemoveRemoveFromMedicationsMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRemoveFromMedicationsMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRemoveFromMedicationsMutationMutation, RemoveRemoveFromMedicationsMutationMutationVariables>) {
        return Apollo.useMutation<RemoveRemoveFromMedicationsMutationMutation, RemoveRemoveFromMedicationsMutationMutationVariables>(RemoveRemoveFromMedicationsMutationDocument, baseOptions);
      }
export type RemoveRemoveFromMedicationsMutationMutationHookResult = ReturnType<typeof useRemoveRemoveFromMedicationsMutationMutation>;
export type RemoveRemoveFromMedicationsMutationMutationResult = Apollo.MutationResult<RemoveRemoveFromMedicationsMutationMutation>;
export type RemoveRemoveFromMedicationsMutationMutationOptions = Apollo.BaseMutationOptions<RemoveRemoveFromMedicationsMutationMutation, RemoveRemoveFromMedicationsMutationMutationVariables>;
export const AddAddToMedicationsMutationDocument = gql`
    mutation addAddToMedicationsMutation($patientId: Int!, $info: String!, $iri: String) {
  addToMedications(patient_id: $patientId, info: $info, iri: $iri) {
    id
    iri
    info
  }
}
    `;
export type AddAddToMedicationsMutationMutationFn = Apollo.MutationFunction<AddAddToMedicationsMutationMutation, AddAddToMedicationsMutationMutationVariables>;

/**
 * __useAddAddToMedicationsMutationMutation__
 *
 * To run a mutation, you first call `useAddAddToMedicationsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddToMedicationsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddToMedicationsMutationMutation, { data, loading, error }] = useAddAddToMedicationsMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      info: // value for 'info'
 *      iri: // value for 'iri'
 *   },
 * });
 */
export function useAddAddToMedicationsMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddAddToMedicationsMutationMutation, AddAddToMedicationsMutationMutationVariables>) {
        return Apollo.useMutation<AddAddToMedicationsMutationMutation, AddAddToMedicationsMutationMutationVariables>(AddAddToMedicationsMutationDocument, baseOptions);
      }
export type AddAddToMedicationsMutationMutationHookResult = ReturnType<typeof useAddAddToMedicationsMutationMutation>;
export type AddAddToMedicationsMutationMutationResult = Apollo.MutationResult<AddAddToMedicationsMutationMutation>;
export type AddAddToMedicationsMutationMutationOptions = Apollo.BaseMutationOptions<AddAddToMedicationsMutationMutation, AddAddToMedicationsMutationMutationVariables>;
export const RemoveRemoveFromConditionsMutationDocument = gql`
    mutation removeRemoveFromConditionsMutation($patientId: Int!, $id: Int!) {
  removeFromConditions(patient_id: $patientId, id: $id) {
    id
    iri
    info
  }
}
    `;
export type RemoveRemoveFromConditionsMutationMutationFn = Apollo.MutationFunction<RemoveRemoveFromConditionsMutationMutation, RemoveRemoveFromConditionsMutationMutationVariables>;

/**
 * __useRemoveRemoveFromConditionsMutationMutation__
 *
 * To run a mutation, you first call `useRemoveRemoveFromConditionsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRemoveFromConditionsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRemoveFromConditionsMutationMutation, { data, loading, error }] = useRemoveRemoveFromConditionsMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRemoveFromConditionsMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRemoveFromConditionsMutationMutation, RemoveRemoveFromConditionsMutationMutationVariables>) {
        return Apollo.useMutation<RemoveRemoveFromConditionsMutationMutation, RemoveRemoveFromConditionsMutationMutationVariables>(RemoveRemoveFromConditionsMutationDocument, baseOptions);
      }
export type RemoveRemoveFromConditionsMutationMutationHookResult = ReturnType<typeof useRemoveRemoveFromConditionsMutationMutation>;
export type RemoveRemoveFromConditionsMutationMutationResult = Apollo.MutationResult<RemoveRemoveFromConditionsMutationMutation>;
export type RemoveRemoveFromConditionsMutationMutationOptions = Apollo.BaseMutationOptions<RemoveRemoveFromConditionsMutationMutation, RemoveRemoveFromConditionsMutationMutationVariables>;
export const AddAddToConditionsMutationDocument = gql`
    mutation addAddToConditionsMutation($patientId: Int!, $info: String!, $iri: String) {
  addToConditions(patient_id: $patientId, info: $info, iri: $iri) {
    id
    iri
    info
  }
}
    `;
export type AddAddToConditionsMutationMutationFn = Apollo.MutationFunction<AddAddToConditionsMutationMutation, AddAddToConditionsMutationMutationVariables>;

/**
 * __useAddAddToConditionsMutationMutation__
 *
 * To run a mutation, you first call `useAddAddToConditionsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddToConditionsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddToConditionsMutationMutation, { data, loading, error }] = useAddAddToConditionsMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      info: // value for 'info'
 *      iri: // value for 'iri'
 *   },
 * });
 */
export function useAddAddToConditionsMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddAddToConditionsMutationMutation, AddAddToConditionsMutationMutationVariables>) {
        return Apollo.useMutation<AddAddToConditionsMutationMutation, AddAddToConditionsMutationMutationVariables>(AddAddToConditionsMutationDocument, baseOptions);
      }
export type AddAddToConditionsMutationMutationHookResult = ReturnType<typeof useAddAddToConditionsMutationMutation>;
export type AddAddToConditionsMutationMutationResult = Apollo.MutationResult<AddAddToConditionsMutationMutation>;
export type AddAddToConditionsMutationMutationOptions = Apollo.BaseMutationOptions<AddAddToConditionsMutationMutation, AddAddToConditionsMutationMutationVariables>;
export const RemoveRemoveFromAllergiesMutationDocument = gql`
    mutation removeRemoveFromAllergiesMutation($patientId: Int!, $id: Int!) {
  removeFromAllergies(patient_id: $patientId, id: $id) {
    id
    iri
    info
  }
}
    `;
export type RemoveRemoveFromAllergiesMutationMutationFn = Apollo.MutationFunction<RemoveRemoveFromAllergiesMutationMutation, RemoveRemoveFromAllergiesMutationMutationVariables>;

/**
 * __useRemoveRemoveFromAllergiesMutationMutation__
 *
 * To run a mutation, you first call `useRemoveRemoveFromAllergiesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRemoveFromAllergiesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRemoveFromAllergiesMutationMutation, { data, loading, error }] = useRemoveRemoveFromAllergiesMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRemoveFromAllergiesMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRemoveFromAllergiesMutationMutation, RemoveRemoveFromAllergiesMutationMutationVariables>) {
        return Apollo.useMutation<RemoveRemoveFromAllergiesMutationMutation, RemoveRemoveFromAllergiesMutationMutationVariables>(RemoveRemoveFromAllergiesMutationDocument, baseOptions);
      }
export type RemoveRemoveFromAllergiesMutationMutationHookResult = ReturnType<typeof useRemoveRemoveFromAllergiesMutationMutation>;
export type RemoveRemoveFromAllergiesMutationMutationResult = Apollo.MutationResult<RemoveRemoveFromAllergiesMutationMutation>;
export type RemoveRemoveFromAllergiesMutationMutationOptions = Apollo.BaseMutationOptions<RemoveRemoveFromAllergiesMutationMutation, RemoveRemoveFromAllergiesMutationMutationVariables>;
export const AddAddToAllergiesMutationDocument = gql`
    mutation addAddToAllergiesMutation($patientId: Int!, $info: String!, $iri: String) {
  addToAllergies(patient_id: $patientId, info: $info, iri: $iri) {
    id
    iri
    info
  }
}
    `;
export type AddAddToAllergiesMutationMutationFn = Apollo.MutationFunction<AddAddToAllergiesMutationMutation, AddAddToAllergiesMutationMutationVariables>;

/**
 * __useAddAddToAllergiesMutationMutation__
 *
 * To run a mutation, you first call `useAddAddToAllergiesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddToAllergiesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddToAllergiesMutationMutation, { data, loading, error }] = useAddAddToAllergiesMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      info: // value for 'info'
 *      iri: // value for 'iri'
 *   },
 * });
 */
export function useAddAddToAllergiesMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddAddToAllergiesMutationMutation, AddAddToAllergiesMutationMutationVariables>) {
        return Apollo.useMutation<AddAddToAllergiesMutationMutation, AddAddToAllergiesMutationMutationVariables>(AddAddToAllergiesMutationDocument, baseOptions);
      }
export type AddAddToAllergiesMutationMutationHookResult = ReturnType<typeof useAddAddToAllergiesMutationMutation>;
export type AddAddToAllergiesMutationMutationResult = Apollo.MutationResult<AddAddToAllergiesMutationMutation>;
export type AddAddToAllergiesMutationMutationOptions = Apollo.BaseMutationOptions<AddAddToAllergiesMutationMutation, AddAddToAllergiesMutationMutationVariables>;
export const SearchCodingDiagnosisQueryDocument = gql`
    query searchCodingDiagnosisQuery($query: String!, $region: String) {
  searchCodingDiagnosis(query: $query, limit: 50, region: $region) {
    preferredLabel
    iri
    hasNotes
  }
}
    `;

/**
 * __useSearchCodingDiagnosisQueryQuery__
 *
 * To run a query within a React component, call `useSearchCodingDiagnosisQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCodingDiagnosisQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCodingDiagnosisQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useSearchCodingDiagnosisQueryQuery(baseOptions: Apollo.QueryHookOptions<SearchCodingDiagnosisQueryQuery, SearchCodingDiagnosisQueryQueryVariables>) {
        return Apollo.useQuery<SearchCodingDiagnosisQueryQuery, SearchCodingDiagnosisQueryQueryVariables>(SearchCodingDiagnosisQueryDocument, baseOptions);
      }
export function useSearchCodingDiagnosisQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCodingDiagnosisQueryQuery, SearchCodingDiagnosisQueryQueryVariables>) {
          return Apollo.useLazyQuery<SearchCodingDiagnosisQueryQuery, SearchCodingDiagnosisQueryQueryVariables>(SearchCodingDiagnosisQueryDocument, baseOptions);
        }
export type SearchCodingDiagnosisQueryQueryHookResult = ReturnType<typeof useSearchCodingDiagnosisQueryQuery>;
export type SearchCodingDiagnosisQueryLazyQueryHookResult = ReturnType<typeof useSearchCodingDiagnosisQueryLazyQuery>;
export type SearchCodingDiagnosisQueryQueryResult = Apollo.QueryResult<SearchCodingDiagnosisQueryQuery, SearchCodingDiagnosisQueryQueryVariables>;
export const GetAllergiesQueryDocument = gql`
    query getAllergiesQuery($patientId: ID!) {
  patient(id: $patientId) {
    id
    allergies {
      id
      iri
      info
    }
  }
}
    `;

/**
 * __useGetAllergiesQueryQuery__
 *
 * To run a query within a React component, call `useGetAllergiesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergiesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergiesQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetAllergiesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetAllergiesQueryQuery, GetAllergiesQueryQueryVariables>) {
        return Apollo.useQuery<GetAllergiesQueryQuery, GetAllergiesQueryQueryVariables>(GetAllergiesQueryDocument, baseOptions);
      }
export function useGetAllergiesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllergiesQueryQuery, GetAllergiesQueryQueryVariables>) {
          return Apollo.useLazyQuery<GetAllergiesQueryQuery, GetAllergiesQueryQueryVariables>(GetAllergiesQueryDocument, baseOptions);
        }
export type GetAllergiesQueryQueryHookResult = ReturnType<typeof useGetAllergiesQueryQuery>;
export type GetAllergiesQueryLazyQueryHookResult = ReturnType<typeof useGetAllergiesQueryLazyQuery>;
export type GetAllergiesQueryQueryResult = Apollo.QueryResult<GetAllergiesQueryQuery, GetAllergiesQueryQueryVariables>;
export const SuggestAllergiesQueryDocument = gql`
    mutation suggestAllergiesQuery($query: String!) {
  options: suggestAllergies(query: $query, limit: 20) {
    iri
    suggestedLabel
  }
}
    `;
export type SuggestAllergiesQueryMutationFn = Apollo.MutationFunction<SuggestAllergiesQueryMutation, SuggestAllergiesQueryMutationVariables>;

/**
 * __useSuggestAllergiesQueryMutation__
 *
 * To run a mutation, you first call `useSuggestAllergiesQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestAllergiesQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestAllergiesQueryMutation, { data, loading, error }] = useSuggestAllergiesQueryMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSuggestAllergiesQueryMutation(baseOptions?: Apollo.MutationHookOptions<SuggestAllergiesQueryMutation, SuggestAllergiesQueryMutationVariables>) {
        return Apollo.useMutation<SuggestAllergiesQueryMutation, SuggestAllergiesQueryMutationVariables>(SuggestAllergiesQueryDocument, baseOptions);
      }
export type SuggestAllergiesQueryMutationHookResult = ReturnType<typeof useSuggestAllergiesQueryMutation>;
export type SuggestAllergiesQueryMutationResult = Apollo.MutationResult<SuggestAllergiesQueryMutation>;
export type SuggestAllergiesQueryMutationOptions = Apollo.BaseMutationOptions<SuggestAllergiesQueryMutation, SuggestAllergiesQueryMutationVariables>;
export const GetMedicationsQueryDocument = gql`
    query getMedicationsQuery($patientId: ID!) {
  patient(id: $patientId) {
    id
    medications {
      id
      iri
      info
    }
  }
}
    `;

/**
 * __useGetMedicationsQueryQuery__
 *
 * To run a query within a React component, call `useGetMedicationsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicationsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicationsQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetMedicationsQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMedicationsQueryQuery, GetMedicationsQueryQueryVariables>) {
        return Apollo.useQuery<GetMedicationsQueryQuery, GetMedicationsQueryQueryVariables>(GetMedicationsQueryDocument, baseOptions);
      }
export function useGetMedicationsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMedicationsQueryQuery, GetMedicationsQueryQueryVariables>) {
          return Apollo.useLazyQuery<GetMedicationsQueryQuery, GetMedicationsQueryQueryVariables>(GetMedicationsQueryDocument, baseOptions);
        }
export type GetMedicationsQueryQueryHookResult = ReturnType<typeof useGetMedicationsQueryQuery>;
export type GetMedicationsQueryLazyQueryHookResult = ReturnType<typeof useGetMedicationsQueryLazyQuery>;
export type GetMedicationsQueryQueryResult = Apollo.QueryResult<GetMedicationsQueryQuery, GetMedicationsQueryQueryVariables>;
export const SuggestMedicationsQueryDocument = gql`
    mutation suggestMedicationsQuery($query: String!) {
  options: suggestMedications(query: $query, limit: 20) {
    iri
    suggestedLabel
  }
}
    `;
export type SuggestMedicationsQueryMutationFn = Apollo.MutationFunction<SuggestMedicationsQueryMutation, SuggestMedicationsQueryMutationVariables>;

/**
 * __useSuggestMedicationsQueryMutation__
 *
 * To run a mutation, you first call `useSuggestMedicationsQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestMedicationsQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestMedicationsQueryMutation, { data, loading, error }] = useSuggestMedicationsQueryMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSuggestMedicationsQueryMutation(baseOptions?: Apollo.MutationHookOptions<SuggestMedicationsQueryMutation, SuggestMedicationsQueryMutationVariables>) {
        return Apollo.useMutation<SuggestMedicationsQueryMutation, SuggestMedicationsQueryMutationVariables>(SuggestMedicationsQueryDocument, baseOptions);
      }
export type SuggestMedicationsQueryMutationHookResult = ReturnType<typeof useSuggestMedicationsQueryMutation>;
export type SuggestMedicationsQueryMutationResult = Apollo.MutationResult<SuggestMedicationsQueryMutation>;
export type SuggestMedicationsQueryMutationOptions = Apollo.BaseMutationOptions<SuggestMedicationsQueryMutation, SuggestMedicationsQueryMutationVariables>;
export const GetConditionsQueryDocument = gql`
    query getConditionsQuery($patientId: ID!) {
  patient(id: $patientId) {
    id
    conditions {
      id
      iri
      info
    }
  }
}
    `;

/**
 * __useGetConditionsQueryQuery__
 *
 * To run a query within a React component, call `useGetConditionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionsQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetConditionsQueryQuery(baseOptions: Apollo.QueryHookOptions<GetConditionsQueryQuery, GetConditionsQueryQueryVariables>) {
        return Apollo.useQuery<GetConditionsQueryQuery, GetConditionsQueryQueryVariables>(GetConditionsQueryDocument, baseOptions);
      }
export function useGetConditionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConditionsQueryQuery, GetConditionsQueryQueryVariables>) {
          return Apollo.useLazyQuery<GetConditionsQueryQuery, GetConditionsQueryQueryVariables>(GetConditionsQueryDocument, baseOptions);
        }
export type GetConditionsQueryQueryHookResult = ReturnType<typeof useGetConditionsQueryQuery>;
export type GetConditionsQueryLazyQueryHookResult = ReturnType<typeof useGetConditionsQueryLazyQuery>;
export type GetConditionsQueryQueryResult = Apollo.QueryResult<GetConditionsQueryQuery, GetConditionsQueryQueryVariables>;
export const SuggestConditionsQueryDocument = gql`
    mutation suggestConditionsQuery($query: String!) {
  options: suggestConditions(query: $query, limit: 20) {
    iri
    suggestedLabel
  }
}
    `;
export type SuggestConditionsQueryMutationFn = Apollo.MutationFunction<SuggestConditionsQueryMutation, SuggestConditionsQueryMutationVariables>;

/**
 * __useSuggestConditionsQueryMutation__
 *
 * To run a mutation, you first call `useSuggestConditionsQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestConditionsQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestConditionsQueryMutation, { data, loading, error }] = useSuggestConditionsQueryMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSuggestConditionsQueryMutation(baseOptions?: Apollo.MutationHookOptions<SuggestConditionsQueryMutation, SuggestConditionsQueryMutationVariables>) {
        return Apollo.useMutation<SuggestConditionsQueryMutation, SuggestConditionsQueryMutationVariables>(SuggestConditionsQueryDocument, baseOptions);
      }
export type SuggestConditionsQueryMutationHookResult = ReturnType<typeof useSuggestConditionsQueryMutation>;
export type SuggestConditionsQueryMutationResult = Apollo.MutationResult<SuggestConditionsQueryMutation>;
export type SuggestConditionsQueryMutationOptions = Apollo.BaseMutationOptions<SuggestConditionsQueryMutation, SuggestConditionsQueryMutationVariables>;
export const SearchFastCtConceptsQueryDocument = gql`
    query searchFastCTConceptsQuery($term: String!, $semanticTags: String!, $pageSize: Int!) {
  searchFastCTConcepts(language: "en", pageSize: $pageSize, semanticTags: $semanticTags, term: $term) {
    concepts {
      iri
      prefLabel {
        value
      }
    }
  }
}
    `;

/**
 * __useSearchFastCtConceptsQueryQuery__
 *
 * To run a query within a React component, call `useSearchFastCtConceptsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFastCtConceptsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFastCtConceptsQueryQuery({
 *   variables: {
 *      term: // value for 'term'
 *      semanticTags: // value for 'semanticTags'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSearchFastCtConceptsQueryQuery(baseOptions: Apollo.QueryHookOptions<SearchFastCtConceptsQueryQuery, SearchFastCtConceptsQueryQueryVariables>) {
        return Apollo.useQuery<SearchFastCtConceptsQueryQuery, SearchFastCtConceptsQueryQueryVariables>(SearchFastCtConceptsQueryDocument, baseOptions);
      }
export function useSearchFastCtConceptsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFastCtConceptsQueryQuery, SearchFastCtConceptsQueryQueryVariables>) {
          return Apollo.useLazyQuery<SearchFastCtConceptsQueryQuery, SearchFastCtConceptsQueryQueryVariables>(SearchFastCtConceptsQueryDocument, baseOptions);
        }
export type SearchFastCtConceptsQueryQueryHookResult = ReturnType<typeof useSearchFastCtConceptsQueryQuery>;
export type SearchFastCtConceptsQueryLazyQueryHookResult = ReturnType<typeof useSearchFastCtConceptsQueryLazyQuery>;
export type SearchFastCtConceptsQueryQueryResult = Apollo.QueryResult<SearchFastCtConceptsQueryQuery, SearchFastCtConceptsQueryQueryVariables>;
export const CpIncompleteConsultationWarningDocument = gql`
    query CpIncompleteConsultationWarning($searchCriteria: ConsultationSearchCriteria) {
  consultationsv2(searchCriteria: $searchCriteria) {
    totalItems
  }
}
    `;

/**
 * __useCpIncompleteConsultationWarningQuery__
 *
 * To run a query within a React component, call `useCpIncompleteConsultationWarningQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpIncompleteConsultationWarningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpIncompleteConsultationWarningQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useCpIncompleteConsultationWarningQuery(baseOptions?: Apollo.QueryHookOptions<CpIncompleteConsultationWarningQuery, CpIncompleteConsultationWarningQueryVariables>) {
        return Apollo.useQuery<CpIncompleteConsultationWarningQuery, CpIncompleteConsultationWarningQueryVariables>(CpIncompleteConsultationWarningDocument, baseOptions);
      }
export function useCpIncompleteConsultationWarningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpIncompleteConsultationWarningQuery, CpIncompleteConsultationWarningQueryVariables>) {
          return Apollo.useLazyQuery<CpIncompleteConsultationWarningQuery, CpIncompleteConsultationWarningQueryVariables>(CpIncompleteConsultationWarningDocument, baseOptions);
        }
export type CpIncompleteConsultationWarningQueryHookResult = ReturnType<typeof useCpIncompleteConsultationWarningQuery>;
export type CpIncompleteConsultationWarningLazyQueryHookResult = ReturnType<typeof useCpIncompleteConsultationWarningLazyQuery>;
export type CpIncompleteConsultationWarningQueryResult = Apollo.QueryResult<CpIncompleteConsultationWarningQuery, CpIncompleteConsultationWarningQueryVariables>;
export const CpMarkConsultationAsNoShowDocument = gql`
    mutation CpMarkConsultationAsNoShow($id: ID!) {
  updateConsultationStatus(id: $id, status: no_show) {
    id
    status
  }
}
    `;
export type CpMarkConsultationAsNoShowMutationFn = Apollo.MutationFunction<CpMarkConsultationAsNoShowMutation, CpMarkConsultationAsNoShowMutationVariables>;

/**
 * __useCpMarkConsultationAsNoShowMutation__
 *
 * To run a mutation, you first call `useCpMarkConsultationAsNoShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpMarkConsultationAsNoShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpMarkConsultationAsNoShowMutation, { data, loading, error }] = useCpMarkConsultationAsNoShowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpMarkConsultationAsNoShowMutation(baseOptions?: Apollo.MutationHookOptions<CpMarkConsultationAsNoShowMutation, CpMarkConsultationAsNoShowMutationVariables>) {
        return Apollo.useMutation<CpMarkConsultationAsNoShowMutation, CpMarkConsultationAsNoShowMutationVariables>(CpMarkConsultationAsNoShowDocument, baseOptions);
      }
export type CpMarkConsultationAsNoShowMutationHookResult = ReturnType<typeof useCpMarkConsultationAsNoShowMutation>;
export type CpMarkConsultationAsNoShowMutationResult = Apollo.MutationResult<CpMarkConsultationAsNoShowMutation>;
export type CpMarkConsultationAsNoShowMutationOptions = Apollo.BaseMutationOptions<CpMarkConsultationAsNoShowMutation, CpMarkConsultationAsNoShowMutationVariables>;
export const ConsultationLiteModuleDocument = gql`
    query ConsultationLiteModule($id: ID!) {
  consultation(id: $id) {
    id
    consultationType
    status
    category
    patientNote
    scheduledTime
    languageRequested
    recordingConsent
    patient {
      id
      first_name
      last_name
      date_of_birth
      email
      gender
      id_verification
      minor
      region_id
      account_owner_name
      account_owner_dob
    }
    consultant {
      id
      name
      profession_name
    }
  }
}
    `;

/**
 * __useConsultationLiteModuleQuery__
 *
 * To run a query within a React component, call `useConsultationLiteModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationLiteModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationLiteModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultationLiteModuleQuery(baseOptions: Apollo.QueryHookOptions<ConsultationLiteModuleQuery, ConsultationLiteModuleQueryVariables>) {
        return Apollo.useQuery<ConsultationLiteModuleQuery, ConsultationLiteModuleQueryVariables>(ConsultationLiteModuleDocument, baseOptions);
      }
export function useConsultationLiteModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationLiteModuleQuery, ConsultationLiteModuleQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationLiteModuleQuery, ConsultationLiteModuleQueryVariables>(ConsultationLiteModuleDocument, baseOptions);
        }
export type ConsultationLiteModuleQueryHookResult = ReturnType<typeof useConsultationLiteModuleQuery>;
export type ConsultationLiteModuleLazyQueryHookResult = ReturnType<typeof useConsultationLiteModuleLazyQuery>;
export type ConsultationLiteModuleQueryResult = Apollo.QueryResult<ConsultationLiteModuleQuery, ConsultationLiteModuleQueryVariables>;
export const ConsultationStatusDocument = gql`
    query ConsultationStatus($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    status
  }
}
    `;

/**
 * __useConsultationStatusQuery__
 *
 * To run a query within a React component, call `useConsultationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationStatusQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useConsultationStatusQuery(baseOptions: Apollo.QueryHookOptions<ConsultationStatusQuery, ConsultationStatusQueryVariables>) {
        return Apollo.useQuery<ConsultationStatusQuery, ConsultationStatusQueryVariables>(ConsultationStatusDocument, baseOptions);
      }
export function useConsultationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationStatusQuery, ConsultationStatusQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationStatusQuery, ConsultationStatusQueryVariables>(ConsultationStatusDocument, baseOptions);
        }
export type ConsultationStatusQueryHookResult = ReturnType<typeof useConsultationStatusQuery>;
export type ConsultationStatusLazyQueryHookResult = ReturnType<typeof useConsultationStatusLazyQuery>;
export type ConsultationStatusQueryResult = Apollo.QueryResult<ConsultationStatusQuery, ConsultationStatusQueryVariables>;
export const CpConsultationPageDocument = gql`
    query CpConsultationPage($id: ID!) {
  consultation(id: $id) {
    id
    consultationId
    uuid: consultationId
    consumerNetwork
    consumerNetworkId
    tenancyContractId
    consumerNetworkName
    gpConsent
    consultant {
      id
      name
      role
      profession_name
    }
    permissions {
      id
      name
      value
    }
    status
    consultationType
    scheduledTime
    region {
      id
      iso_code
    }
    languageRequested
    recordingConsent
    patient {
      id
      uuid
      patient_alerts {
        id
      }
    }
    appointment {
      durationMinutes: duration_minutes
    }
    ...DiagnosisFragment
    ...GpDetailsFragment
    ...CpPatientDetails
    ...PresentingComplaint
    ...ExaminationFragment
    ...ReviewFragment
    ...CpSickNotes
    ...TestsFragment
    ...ReferralsFragment
    ...ConsultationProfile
    ...Status
    ...CpIncompleteConsultationWarning
    ...CpPreConsultationSection
  }
}
    ${DiagnosisFragmentFragmentDoc}
${GpDetailsFragmentFragmentDoc}
${CpPatientDetailsFragmentDoc}
${PresentingComplaintFragmentDoc}
${ExaminationFragmentFragmentDoc}
${ReviewFragmentFragmentDoc}
${CpSickNotesFragmentDoc}
${TestsFragmentFragmentDoc}
${ReferralsFragmentFragmentDoc}
${ConsultationProfileFragmentDoc}
${StatusFragmentDoc}
${CpIncompleteConsultationWarningFragmentDoc}
${CpPreConsultationSectionFragmentDoc}`;

/**
 * __useCpConsultationPageQuery__
 *
 * To run a query within a React component, call `useCpConsultationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpConsultationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpConsultationPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpConsultationPageQuery(baseOptions: Apollo.QueryHookOptions<CpConsultationPageQuery, CpConsultationPageQueryVariables>) {
        return Apollo.useQuery<CpConsultationPageQuery, CpConsultationPageQueryVariables>(CpConsultationPageDocument, baseOptions);
      }
export function useCpConsultationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpConsultationPageQuery, CpConsultationPageQueryVariables>) {
          return Apollo.useLazyQuery<CpConsultationPageQuery, CpConsultationPageQueryVariables>(CpConsultationPageDocument, baseOptions);
        }
export type CpConsultationPageQueryHookResult = ReturnType<typeof useCpConsultationPageQuery>;
export type CpConsultationPageLazyQueryHookResult = ReturnType<typeof useCpConsultationPageLazyQuery>;
export type CpConsultationPageQueryResult = Apollo.QueryResult<CpConsultationPageQuery, CpConsultationPageQueryVariables>;
export const EhrCheckinUsDocument = gql`
    mutation EhrCheckinUs($consultationId: ID!) {
  checkInEhr(consultationId: $consultationId)
}
    `;
export type EhrCheckinUsMutationFn = Apollo.MutationFunction<EhrCheckinUsMutation, EhrCheckinUsMutationVariables>;

/**
 * __useEhrCheckinUsMutation__
 *
 * To run a mutation, you first call `useEhrCheckinUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEhrCheckinUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ehrCheckinUsMutation, { data, loading, error }] = useEhrCheckinUsMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useEhrCheckinUsMutation(baseOptions?: Apollo.MutationHookOptions<EhrCheckinUsMutation, EhrCheckinUsMutationVariables>) {
        return Apollo.useMutation<EhrCheckinUsMutation, EhrCheckinUsMutationVariables>(EhrCheckinUsDocument, baseOptions);
      }
export type EhrCheckinUsMutationHookResult = ReturnType<typeof useEhrCheckinUsMutation>;
export type EhrCheckinUsMutationResult = Apollo.MutationResult<EhrCheckinUsMutation>;
export type EhrCheckinUsMutationOptions = Apollo.BaseMutationOptions<EhrCheckinUsMutation, EhrCheckinUsMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation updateAppointment($id: ID!, $gp_consent: Boolean!) {
  updateAppointmentGpConsent(id: $id, gp_consent: $gp_consent) {
    id
    gp_consent
  }
}
    `;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      gp_consent: // value for 'gp_consent'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, baseOptions);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const UpdateGpInAppointmentMutationDocument = gql`
    mutation updateGpInAppointmentMutation($appointmentId: Int!, $formData: GPInformation!) {
  updateGpInAppointment(appointmentId: $appointmentId, formData: $formData) {
    id
    gp_address_first_line
    gp_address_post_code
    gp_address_second_line
    gp_address_third_line
    gp_full_address
    gp_name
    gp_surgery_name
    gp_surgery_phone_number
  }
}
    `;
export type UpdateGpInAppointmentMutationMutationFn = Apollo.MutationFunction<UpdateGpInAppointmentMutationMutation, UpdateGpInAppointmentMutationMutationVariables>;

/**
 * __useUpdateGpInAppointmentMutationMutation__
 *
 * To run a mutation, you first call `useUpdateGpInAppointmentMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGpInAppointmentMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGpInAppointmentMutationMutation, { data, loading, error }] = useUpdateGpInAppointmentMutationMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useUpdateGpInAppointmentMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGpInAppointmentMutationMutation, UpdateGpInAppointmentMutationMutationVariables>) {
        return Apollo.useMutation<UpdateGpInAppointmentMutationMutation, UpdateGpInAppointmentMutationMutationVariables>(UpdateGpInAppointmentMutationDocument, baseOptions);
      }
export type UpdateGpInAppointmentMutationMutationHookResult = ReturnType<typeof useUpdateGpInAppointmentMutationMutation>;
export type UpdateGpInAppointmentMutationMutationResult = Apollo.MutationResult<UpdateGpInAppointmentMutationMutation>;
export type UpdateGpInAppointmentMutationMutationOptions = Apollo.BaseMutationOptions<UpdateGpInAppointmentMutationMutation, UpdateGpInAppointmentMutationMutationVariables>;
export const UpdateAppointmentMutationDocument = gql`
    mutation updateAppointmentMutation($id: ID!, $gp_consent: Boolean!) {
  updateAppointmentGpConsent(id: $id, gp_consent: $gp_consent) {
    id
    gp_consent
  }
}
    `;
export type UpdateAppointmentMutationMutationFn = Apollo.MutationFunction<UpdateAppointmentMutationMutation, UpdateAppointmentMutationMutationVariables>;

/**
 * __useUpdateAppointmentMutationMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutationMutation, { data, loading, error }] = useUpdateAppointmentMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      gp_consent: // value for 'gp_consent'
 *   },
 * });
 */
export function useUpdateAppointmentMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutationMutation, UpdateAppointmentMutationMutationVariables>) {
        return Apollo.useMutation<UpdateAppointmentMutationMutation, UpdateAppointmentMutationMutationVariables>(UpdateAppointmentMutationDocument, baseOptions);
      }
export type UpdateAppointmentMutationMutationHookResult = ReturnType<typeof useUpdateAppointmentMutationMutation>;
export type UpdateAppointmentMutationMutationResult = Apollo.MutationResult<UpdateAppointmentMutationMutation>;
export type UpdateAppointmentMutationMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutationMutation, UpdateAppointmentMutationMutationVariables>;
export const ConsultationGpDetailsDocument = gql`
    query ConsultationGpDetails($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    gpConsent
    patient {
      id
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
      gp_full_address
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
    }
  }
}
    `;

/**
 * __useConsultationGpDetailsQuery__
 *
 * To run a query within a React component, call `useConsultationGpDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationGpDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationGpDetailsQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useConsultationGpDetailsQuery(baseOptions: Apollo.QueryHookOptions<ConsultationGpDetailsQuery, ConsultationGpDetailsQueryVariables>) {
        return Apollo.useQuery<ConsultationGpDetailsQuery, ConsultationGpDetailsQueryVariables>(ConsultationGpDetailsDocument, baseOptions);
      }
export function useConsultationGpDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationGpDetailsQuery, ConsultationGpDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationGpDetailsQuery, ConsultationGpDetailsQueryVariables>(ConsultationGpDetailsDocument, baseOptions);
        }
export type ConsultationGpDetailsQueryHookResult = ReturnType<typeof useConsultationGpDetailsQuery>;
export type ConsultationGpDetailsLazyQueryHookResult = ReturnType<typeof useConsultationGpDetailsLazyQuery>;
export type ConsultationGpDetailsQueryResult = Apollo.QueryResult<ConsultationGpDetailsQuery, ConsultationGpDetailsQueryVariables>;
export const UpdatePatientGpDetailsMutationDocument = gql`
    mutation UpdatePatientGpDetailsMutation($patientId: Int!, $consultationId: ID!, $gpConsent: Boolean, $formData: GPInformation!) {
  updateGP(id: $patientId, formData: $formData) {
    id
    gp_name
    gp_surgery_name
    gp_surgery_phone_number
    gp_address_first_line
    gp_address_post_code
    gp_address_second_line
    gp_address_third_line
  }
  updateConsultationGpConsent(id: $consultationId, gpConsent: $gpConsent) {
    id
    gpConsent
  }
}
    `;
export type UpdatePatientGpDetailsMutationMutationFn = Apollo.MutationFunction<UpdatePatientGpDetailsMutationMutation, UpdatePatientGpDetailsMutationMutationVariables>;

/**
 * __useUpdatePatientGpDetailsMutationMutation__
 *
 * To run a mutation, you first call `useUpdatePatientGpDetailsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientGpDetailsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientGpDetailsMutationMutation, { data, loading, error }] = useUpdatePatientGpDetailsMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      consultationId: // value for 'consultationId'
 *      gpConsent: // value for 'gpConsent'
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useUpdatePatientGpDetailsMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientGpDetailsMutationMutation, UpdatePatientGpDetailsMutationMutationVariables>) {
        return Apollo.useMutation<UpdatePatientGpDetailsMutationMutation, UpdatePatientGpDetailsMutationMutationVariables>(UpdatePatientGpDetailsMutationDocument, baseOptions);
      }
export type UpdatePatientGpDetailsMutationMutationHookResult = ReturnType<typeof useUpdatePatientGpDetailsMutationMutation>;
export type UpdatePatientGpDetailsMutationMutationResult = Apollo.MutationResult<UpdatePatientGpDetailsMutationMutation>;
export type UpdatePatientGpDetailsMutationMutationOptions = Apollo.BaseMutationOptions<UpdatePatientGpDetailsMutationMutation, UpdatePatientGpDetailsMutationMutationVariables>;
export const ConsultationGpDetailsQueryDocument = gql`
    query ConsultationGpDetailsQuery($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    gpConsent
    patient {
      id
      gp_name
      gp_surgery_name
      gp_surgery_phone_number
      gp_address_first_line
      gp_address_post_code
      gp_address_second_line
      gp_address_third_line
    }
  }
}
    `;

/**
 * __useConsultationGpDetailsQueryQuery__
 *
 * To run a query within a React component, call `useConsultationGpDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationGpDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationGpDetailsQueryQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useConsultationGpDetailsQueryQuery(baseOptions: Apollo.QueryHookOptions<ConsultationGpDetailsQueryQuery, ConsultationGpDetailsQueryQueryVariables>) {
        return Apollo.useQuery<ConsultationGpDetailsQueryQuery, ConsultationGpDetailsQueryQueryVariables>(ConsultationGpDetailsQueryDocument, baseOptions);
      }
export function useConsultationGpDetailsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationGpDetailsQueryQuery, ConsultationGpDetailsQueryQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationGpDetailsQueryQuery, ConsultationGpDetailsQueryQueryVariables>(ConsultationGpDetailsQueryDocument, baseOptions);
        }
export type ConsultationGpDetailsQueryQueryHookResult = ReturnType<typeof useConsultationGpDetailsQueryQuery>;
export type ConsultationGpDetailsQueryLazyQueryHookResult = ReturnType<typeof useConsultationGpDetailsQueryLazyQuery>;
export type ConsultationGpDetailsQueryQueryResult = Apollo.QueryResult<ConsultationGpDetailsQueryQuery, ConsultationGpDetailsQueryQueryVariables>;
export const PlacesSearchQueryDocument = gql`
    query PlacesSearchQuery($input: PlaceResourceInput!) {
  placesSearch(input: $input) {
    name
    phone
    address {
      line
      postalCode
    }
  }
}
    `;

/**
 * __usePlacesSearchQueryQuery__
 *
 * To run a query within a React component, call `usePlacesSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacesSearchQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlacesSearchQueryQuery(baseOptions: Apollo.QueryHookOptions<PlacesSearchQueryQuery, PlacesSearchQueryQueryVariables>) {
        return Apollo.useQuery<PlacesSearchQueryQuery, PlacesSearchQueryQueryVariables>(PlacesSearchQueryDocument, baseOptions);
      }
export function usePlacesSearchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacesSearchQueryQuery, PlacesSearchQueryQueryVariables>) {
          return Apollo.useLazyQuery<PlacesSearchQueryQuery, PlacesSearchQueryQueryVariables>(PlacesSearchQueryDocument, baseOptions);
        }
export type PlacesSearchQueryQueryHookResult = ReturnType<typeof usePlacesSearchQueryQuery>;
export type PlacesSearchQueryLazyQueryHookResult = ReturnType<typeof usePlacesSearchQueryLazyQuery>;
export type PlacesSearchQueryQueryResult = Apollo.QueryResult<PlacesSearchQueryQuery, PlacesSearchQueryQueryVariables>;
export const CpConsultantRatingDocument = gql`
    query CpConsultantRating($id: ID!) {
  consultant(id: $id) {
    id
    rating {
      average_rating
      number_of_ratings
    }
  }
}
    `;

/**
 * __useCpConsultantRatingQuery__
 *
 * To run a query within a React component, call `useCpConsultantRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpConsultantRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpConsultantRatingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpConsultantRatingQuery(baseOptions: Apollo.QueryHookOptions<CpConsultantRatingQuery, CpConsultantRatingQueryVariables>) {
        return Apollo.useQuery<CpConsultantRatingQuery, CpConsultantRatingQueryVariables>(CpConsultantRatingDocument, baseOptions);
      }
export function useCpConsultantRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpConsultantRatingQuery, CpConsultantRatingQueryVariables>) {
          return Apollo.useLazyQuery<CpConsultantRatingQuery, CpConsultantRatingQueryVariables>(CpConsultantRatingDocument, baseOptions);
        }
export type CpConsultantRatingQueryHookResult = ReturnType<typeof useCpConsultantRatingQuery>;
export type CpConsultantRatingLazyQueryHookResult = ReturnType<typeof useCpConsultantRatingLazyQuery>;
export type CpConsultantRatingQueryResult = Apollo.QueryResult<CpConsultantRatingQuery, CpConsultantRatingQueryVariables>;
export const AddClinicianToSelfManagedServiceTypeDocument = gql`
    mutation addClinicianToSelfManagedServiceType($clinician_id: ID!, $service_type_uuid: ID!) {
  addCliniciansToServiceType(service_type_uuid: $service_type_uuid, clinician_ids: [$clinician_id])
}
    `;
export type AddClinicianToSelfManagedServiceTypeMutationFn = Apollo.MutationFunction<AddClinicianToSelfManagedServiceTypeMutation, AddClinicianToSelfManagedServiceTypeMutationVariables>;

/**
 * __useAddClinicianToSelfManagedServiceTypeMutation__
 *
 * To run a mutation, you first call `useAddClinicianToSelfManagedServiceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClinicianToSelfManagedServiceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClinicianToSelfManagedServiceTypeMutation, { data, loading, error }] = useAddClinicianToSelfManagedServiceTypeMutation({
 *   variables: {
 *      clinician_id: // value for 'clinician_id'
 *      service_type_uuid: // value for 'service_type_uuid'
 *   },
 * });
 */
export function useAddClinicianToSelfManagedServiceTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddClinicianToSelfManagedServiceTypeMutation, AddClinicianToSelfManagedServiceTypeMutationVariables>) {
        return Apollo.useMutation<AddClinicianToSelfManagedServiceTypeMutation, AddClinicianToSelfManagedServiceTypeMutationVariables>(AddClinicianToSelfManagedServiceTypeDocument, baseOptions);
      }
export type AddClinicianToSelfManagedServiceTypeMutationHookResult = ReturnType<typeof useAddClinicianToSelfManagedServiceTypeMutation>;
export type AddClinicianToSelfManagedServiceTypeMutationResult = Apollo.MutationResult<AddClinicianToSelfManagedServiceTypeMutation>;
export type AddClinicianToSelfManagedServiceTypeMutationOptions = Apollo.BaseMutationOptions<AddClinicianToSelfManagedServiceTypeMutation, AddClinicianToSelfManagedServiceTypeMutationVariables>;
export const RemoveClinicianFromSelfManagedServiceTypeDocument = gql`
    mutation removeClinicianFromSelfManagedServiceType($clinician_id: ID!, $service_type_uuid: ID!) {
  removeClinicianFromServiceType(clinician_id: $clinician_id, service_type_uuid: $service_type_uuid)
}
    `;
export type RemoveClinicianFromSelfManagedServiceTypeMutationFn = Apollo.MutationFunction<RemoveClinicianFromSelfManagedServiceTypeMutation, RemoveClinicianFromSelfManagedServiceTypeMutationVariables>;

/**
 * __useRemoveClinicianFromSelfManagedServiceTypeMutation__
 *
 * To run a mutation, you first call `useRemoveClinicianFromSelfManagedServiceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClinicianFromSelfManagedServiceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClinicianFromSelfManagedServiceTypeMutation, { data, loading, error }] = useRemoveClinicianFromSelfManagedServiceTypeMutation({
 *   variables: {
 *      clinician_id: // value for 'clinician_id'
 *      service_type_uuid: // value for 'service_type_uuid'
 *   },
 * });
 */
export function useRemoveClinicianFromSelfManagedServiceTypeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClinicianFromSelfManagedServiceTypeMutation, RemoveClinicianFromSelfManagedServiceTypeMutationVariables>) {
        return Apollo.useMutation<RemoveClinicianFromSelfManagedServiceTypeMutation, RemoveClinicianFromSelfManagedServiceTypeMutationVariables>(RemoveClinicianFromSelfManagedServiceTypeDocument, baseOptions);
      }
export type RemoveClinicianFromSelfManagedServiceTypeMutationHookResult = ReturnType<typeof useRemoveClinicianFromSelfManagedServiceTypeMutation>;
export type RemoveClinicianFromSelfManagedServiceTypeMutationResult = Apollo.MutationResult<RemoveClinicianFromSelfManagedServiceTypeMutation>;
export type RemoveClinicianFromSelfManagedServiceTypeMutationOptions = Apollo.BaseMutationOptions<RemoveClinicianFromSelfManagedServiceTypeMutation, RemoveClinicianFromSelfManagedServiceTypeMutationVariables>;
export const LinksAndResourcesDocument = gql`
    query LinksAndResources($countryCode: String!) {
  cp3LinksAndResources(countryCode: $countryCode) {
    id
    countryCode {
      name
      codename
    }
    title
    url
    icon
    dashboardLinkTypes {
      name
      codename
    }
  }
}
    `;

/**
 * __useLinksAndResourcesQuery__
 *
 * To run a query within a React component, call `useLinksAndResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksAndResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksAndResourcesQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useLinksAndResourcesQuery(baseOptions: Apollo.QueryHookOptions<LinksAndResourcesQuery, LinksAndResourcesQueryVariables>) {
        return Apollo.useQuery<LinksAndResourcesQuery, LinksAndResourcesQueryVariables>(LinksAndResourcesDocument, baseOptions);
      }
export function useLinksAndResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinksAndResourcesQuery, LinksAndResourcesQueryVariables>) {
          return Apollo.useLazyQuery<LinksAndResourcesQuery, LinksAndResourcesQueryVariables>(LinksAndResourcesDocument, baseOptions);
        }
export type LinksAndResourcesQueryHookResult = ReturnType<typeof useLinksAndResourcesQuery>;
export type LinksAndResourcesLazyQueryHookResult = ReturnType<typeof useLinksAndResourcesLazyQuery>;
export type LinksAndResourcesQueryResult = Apollo.QueryResult<LinksAndResourcesQuery, LinksAndResourcesQueryVariables>;
export const AppointmentInvitesDocument = gql`
    query AppointmentInvites($id: ID!, $f2fOn: Boolean!) {
  consultation(id: $id) {
    id
    ...AppointmentInvites
  }
}
    ${AppointmentInvitesFragmentDoc}`;

/**
 * __useAppointmentInvitesQuery__
 *
 * To run a query within a React component, call `useAppointmentInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentInvitesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      f2fOn: // value for 'f2fOn'
 *   },
 * });
 */
export function useAppointmentInvitesQuery(baseOptions: Apollo.QueryHookOptions<AppointmentInvitesQuery, AppointmentInvitesQueryVariables>) {
        return Apollo.useQuery<AppointmentInvitesQuery, AppointmentInvitesQueryVariables>(AppointmentInvitesDocument, baseOptions);
      }
export function useAppointmentInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentInvitesQuery, AppointmentInvitesQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentInvitesQuery, AppointmentInvitesQueryVariables>(AppointmentInvitesDocument, baseOptions);
        }
export type AppointmentInvitesQueryHookResult = ReturnType<typeof useAppointmentInvitesQuery>;
export type AppointmentInvitesLazyQueryHookResult = ReturnType<typeof useAppointmentInvitesLazyQuery>;
export type AppointmentInvitesQueryResult = Apollo.QueryResult<AppointmentInvitesQuery, AppointmentInvitesQueryVariables>;
export const CreateAppointmentInviteDocument = gql`
    mutation CreateAppointmentInvite($input: AppointmentInviteInput!) {
  createAppointmentInvite(input: $input) {
    id
    notes_for_member
    preferredMedium: preferred_medium
    service_type {
      id
      name
      grouping
    }
  }
}
    `;
export type CreateAppointmentInviteMutationFn = Apollo.MutationFunction<CreateAppointmentInviteMutation, CreateAppointmentInviteMutationVariables>;

/**
 * __useCreateAppointmentInviteMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentInviteMutation, { data, loading, error }] = useCreateAppointmentInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentInviteMutation, CreateAppointmentInviteMutationVariables>) {
        return Apollo.useMutation<CreateAppointmentInviteMutation, CreateAppointmentInviteMutationVariables>(CreateAppointmentInviteDocument, baseOptions);
      }
export type CreateAppointmentInviteMutationHookResult = ReturnType<typeof useCreateAppointmentInviteMutation>;
export type CreateAppointmentInviteMutationResult = Apollo.MutationResult<CreateAppointmentInviteMutation>;
export type CreateAppointmentInviteMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentInviteMutation, CreateAppointmentInviteMutationVariables>;
export const CreateAppointmentWorkflowDocument = gql`
    mutation CreateAppointmentWorkflow($appointment: WorkflowAppointmentActionInput!, $data: WorkflowInput!) {
  createWorkflow(appointment: $appointment, data: $data) {
    id
    status
    type
    subType {
      value
      label
    }
  }
}
    `;
export type CreateAppointmentWorkflowMutationFn = Apollo.MutationFunction<CreateAppointmentWorkflowMutation, CreateAppointmentWorkflowMutationVariables>;

/**
 * __useCreateAppointmentWorkflowMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentWorkflowMutation, { data, loading, error }] = useCreateAppointmentWorkflowMutation({
 *   variables: {
 *      appointment: // value for 'appointment'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAppointmentWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentWorkflowMutation, CreateAppointmentWorkflowMutationVariables>) {
        return Apollo.useMutation<CreateAppointmentWorkflowMutation, CreateAppointmentWorkflowMutationVariables>(CreateAppointmentWorkflowDocument, baseOptions);
      }
export type CreateAppointmentWorkflowMutationHookResult = ReturnType<typeof useCreateAppointmentWorkflowMutation>;
export type CreateAppointmentWorkflowMutationResult = Apollo.MutationResult<CreateAppointmentWorkflowMutation>;
export type CreateAppointmentWorkflowMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentWorkflowMutation, CreateAppointmentWorkflowMutationVariables>;
export const NhsAppointmentServiceTypesDocument = gql`
    query NhsAppointmentServiceTypes($memberId: ID) {
  serviceTypes(member_uuid: $memberId) {
    id
    name
    intimate
    default_duration_minutes
    grouping
    first_available_slot {
      time
      clinician_id
      clinic_id
    }
  }
}
    `;

/**
 * __useNhsAppointmentServiceTypesQuery__
 *
 * To run a query within a React component, call `useNhsAppointmentServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNhsAppointmentServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNhsAppointmentServiceTypesQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useNhsAppointmentServiceTypesQuery(baseOptions?: Apollo.QueryHookOptions<NhsAppointmentServiceTypesQuery, NhsAppointmentServiceTypesQueryVariables>) {
        return Apollo.useQuery<NhsAppointmentServiceTypesQuery, NhsAppointmentServiceTypesQueryVariables>(NhsAppointmentServiceTypesDocument, baseOptions);
      }
export function useNhsAppointmentServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NhsAppointmentServiceTypesQuery, NhsAppointmentServiceTypesQueryVariables>) {
          return Apollo.useLazyQuery<NhsAppointmentServiceTypesQuery, NhsAppointmentServiceTypesQueryVariables>(NhsAppointmentServiceTypesDocument, baseOptions);
        }
export type NhsAppointmentServiceTypesQueryHookResult = ReturnType<typeof useNhsAppointmentServiceTypesQuery>;
export type NhsAppointmentServiceTypesLazyQueryHookResult = ReturnType<typeof useNhsAppointmentServiceTypesLazyQuery>;
export type NhsAppointmentServiceTypesQueryResult = Apollo.QueryResult<NhsAppointmentServiceTypesQuery, NhsAppointmentServiceTypesQueryVariables>;
export const NhsAppointmentConsultationDocument = gql`
    query NhsAppointmentConsultation($id: ID!) {
  consultation(id: $id) {
    id
    consumerNetwork
    patient {
      id
      uuid
      email
      region {
        id
        iso_code
      }
      full_name
      date_of_birth
      contact_details {
        address_first_line
        address_second_line
        address_third_line
        address_post_code
        address_state_code
        phone_country_code
        phone_number
      }
      public_healthcare_identifier
      clinical_commissioning_group {
        id
        name
        code
      }
    }
  }
}
    `;

/**
 * __useNhsAppointmentConsultationQuery__
 *
 * To run a query within a React component, call `useNhsAppointmentConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNhsAppointmentConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNhsAppointmentConsultationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNhsAppointmentConsultationQuery(baseOptions: Apollo.QueryHookOptions<NhsAppointmentConsultationQuery, NhsAppointmentConsultationQueryVariables>) {
        return Apollo.useQuery<NhsAppointmentConsultationQuery, NhsAppointmentConsultationQueryVariables>(NhsAppointmentConsultationDocument, baseOptions);
      }
export function useNhsAppointmentConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NhsAppointmentConsultationQuery, NhsAppointmentConsultationQueryVariables>) {
          return Apollo.useLazyQuery<NhsAppointmentConsultationQuery, NhsAppointmentConsultationQueryVariables>(NhsAppointmentConsultationDocument, baseOptions);
        }
export type NhsAppointmentConsultationQueryHookResult = ReturnType<typeof useNhsAppointmentConsultationQuery>;
export type NhsAppointmentConsultationLazyQueryHookResult = ReturnType<typeof useNhsAppointmentConsultationLazyQuery>;
export type NhsAppointmentConsultationQueryResult = Apollo.QueryResult<NhsAppointmentConsultationQuery, NhsAppointmentConsultationQueryVariables>;
export const NoteAssistantConsentDocument = gql`
    query NoteAssistantConsent($id: ID!, $speakers: [NoteAssistantSpeaker!]!) {
  noteAssistantConsent(id: $id, speakers: $speakers) {
    decisions {
      decision
      decline_reason
      speaker_id
    }
    permission_granted
  }
}
    `;

/**
 * __useNoteAssistantConsentQuery__
 *
 * To run a query within a React component, call `useNoteAssistantConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAssistantConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAssistantConsentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      speakers: // value for 'speakers'
 *   },
 * });
 */
export function useNoteAssistantConsentQuery(baseOptions: Apollo.QueryHookOptions<NoteAssistantConsentQuery, NoteAssistantConsentQueryVariables>) {
        return Apollo.useQuery<NoteAssistantConsentQuery, NoteAssistantConsentQueryVariables>(NoteAssistantConsentDocument, baseOptions);
      }
export function useNoteAssistantConsentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteAssistantConsentQuery, NoteAssistantConsentQueryVariables>) {
          return Apollo.useLazyQuery<NoteAssistantConsentQuery, NoteAssistantConsentQueryVariables>(NoteAssistantConsentDocument, baseOptions);
        }
export type NoteAssistantConsentQueryHookResult = ReturnType<typeof useNoteAssistantConsentQuery>;
export type NoteAssistantConsentLazyQueryHookResult = ReturnType<typeof useNoteAssistantConsentLazyQuery>;
export type NoteAssistantConsentQueryResult = Apollo.QueryResult<NoteAssistantConsentQuery, NoteAssistantConsentQueryVariables>;
export const NoteAssistantRecordingIdDocument = gql`
    query NoteAssistantRecordingId($input: NoteAssistantRecordingIdInput!) {
  noteAssistantRecordingId(input: $input) {
    recording_id
  }
}
    `;

/**
 * __useNoteAssistantRecordingIdQuery__
 *
 * To run a query within a React component, call `useNoteAssistantRecordingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAssistantRecordingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAssistantRecordingIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteAssistantRecordingIdQuery(baseOptions: Apollo.QueryHookOptions<NoteAssistantRecordingIdQuery, NoteAssistantRecordingIdQueryVariables>) {
        return Apollo.useQuery<NoteAssistantRecordingIdQuery, NoteAssistantRecordingIdQueryVariables>(NoteAssistantRecordingIdDocument, baseOptions);
      }
export function useNoteAssistantRecordingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteAssistantRecordingIdQuery, NoteAssistantRecordingIdQueryVariables>) {
          return Apollo.useLazyQuery<NoteAssistantRecordingIdQuery, NoteAssistantRecordingIdQueryVariables>(NoteAssistantRecordingIdDocument, baseOptions);
        }
export type NoteAssistantRecordingIdQueryHookResult = ReturnType<typeof useNoteAssistantRecordingIdQuery>;
export type NoteAssistantRecordingIdLazyQueryHookResult = ReturnType<typeof useNoteAssistantRecordingIdLazyQuery>;
export type NoteAssistantRecordingIdQueryResult = Apollo.QueryResult<NoteAssistantRecordingIdQuery, NoteAssistantRecordingIdQueryVariables>;
export const NoteAssistantNotesDocument = gql`
    query NoteAssistantNotes($id: ID!) {
  noteAssistantNotes(id: $id) {
    notes
    final
    timestamp
    error
  }
}
    `;

/**
 * __useNoteAssistantNotesQuery__
 *
 * To run a query within a React component, call `useNoteAssistantNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAssistantNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAssistantNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteAssistantNotesQuery(baseOptions: Apollo.QueryHookOptions<NoteAssistantNotesQuery, NoteAssistantNotesQueryVariables>) {
        return Apollo.useQuery<NoteAssistantNotesQuery, NoteAssistantNotesQueryVariables>(NoteAssistantNotesDocument, baseOptions);
      }
export function useNoteAssistantNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteAssistantNotesQuery, NoteAssistantNotesQueryVariables>) {
          return Apollo.useLazyQuery<NoteAssistantNotesQuery, NoteAssistantNotesQueryVariables>(NoteAssistantNotesDocument, baseOptions);
        }
export type NoteAssistantNotesQueryHookResult = ReturnType<typeof useNoteAssistantNotesQuery>;
export type NoteAssistantNotesLazyQueryHookResult = ReturnType<typeof useNoteAssistantNotesLazyQuery>;
export type NoteAssistantNotesQueryResult = Apollo.QueryResult<NoteAssistantNotesQuery, NoteAssistantNotesQueryVariables>;
export const CreatePatientAlertDocument = gql`
    mutation CreatePatientAlert($patientId: ID!, $message: String!) {
  createPatientAlert(patientId: $patientId, message: $message) {
    id
    message
    updatedAt
  }
}
    `;
export type CreatePatientAlertMutationFn = Apollo.MutationFunction<CreatePatientAlertMutation, CreatePatientAlertMutationVariables>;

/**
 * __useCreatePatientAlertMutation__
 *
 * To run a mutation, you first call `useCreatePatientAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientAlertMutation, { data, loading, error }] = useCreatePatientAlertMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreatePatientAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientAlertMutation, CreatePatientAlertMutationVariables>) {
        return Apollo.useMutation<CreatePatientAlertMutation, CreatePatientAlertMutationVariables>(CreatePatientAlertDocument, baseOptions);
      }
export type CreatePatientAlertMutationHookResult = ReturnType<typeof useCreatePatientAlertMutation>;
export type CreatePatientAlertMutationResult = Apollo.MutationResult<CreatePatientAlertMutation>;
export type CreatePatientAlertMutationOptions = Apollo.BaseMutationOptions<CreatePatientAlertMutation, CreatePatientAlertMutationVariables>;
export const RemovePatientAlertDocument = gql`
    mutation RemovePatientAlert($id: ID!, $patientId: ID) {
  removePatientAlert(id: $id, patientId: $patientId)
}
    `;
export type RemovePatientAlertMutationFn = Apollo.MutationFunction<RemovePatientAlertMutation, RemovePatientAlertMutationVariables>;

/**
 * __useRemovePatientAlertMutation__
 *
 * To run a mutation, you first call `useRemovePatientAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientAlertMutation, { data, loading, error }] = useRemovePatientAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useRemovePatientAlertMutation(baseOptions?: Apollo.MutationHookOptions<RemovePatientAlertMutation, RemovePatientAlertMutationVariables>) {
        return Apollo.useMutation<RemovePatientAlertMutation, RemovePatientAlertMutationVariables>(RemovePatientAlertDocument, baseOptions);
      }
export type RemovePatientAlertMutationHookResult = ReturnType<typeof useRemovePatientAlertMutation>;
export type RemovePatientAlertMutationResult = Apollo.MutationResult<RemovePatientAlertMutation>;
export type RemovePatientAlertMutationOptions = Apollo.BaseMutationOptions<RemovePatientAlertMutation, RemovePatientAlertMutationVariables>;
export const UpdatePatientAlertDocument = gql`
    mutation UpdatePatientAlert($id: ID!, $patientId: ID, $message: String!) {
  updatePatientAlert(id: $id, patientId: $patientId, message: $message) {
    id
    message
    updatedAt
  }
}
    `;
export type UpdatePatientAlertMutationFn = Apollo.MutationFunction<UpdatePatientAlertMutation, UpdatePatientAlertMutationVariables>;

/**
 * __useUpdatePatientAlertMutation__
 *
 * To run a mutation, you first call `useUpdatePatientAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientAlertMutation, { data, loading, error }] = useUpdatePatientAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patientId: // value for 'patientId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdatePatientAlertMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientAlertMutation, UpdatePatientAlertMutationVariables>) {
        return Apollo.useMutation<UpdatePatientAlertMutation, UpdatePatientAlertMutationVariables>(UpdatePatientAlertDocument, baseOptions);
      }
export type UpdatePatientAlertMutationHookResult = ReturnType<typeof useUpdatePatientAlertMutation>;
export type UpdatePatientAlertMutationResult = Apollo.MutationResult<UpdatePatientAlertMutation>;
export type UpdatePatientAlertMutationOptions = Apollo.BaseMutationOptions<UpdatePatientAlertMutation, UpdatePatientAlertMutationVariables>;
export const PatientAlertDocument = gql`
    query PatientAlert($id: ID!, $patientId: ID) {
  patient_alert(patientId: $patientId, id: $id) {
    id
    message
    updatedAt
  }
}
    `;

/**
 * __usePatientAlertQuery__
 *
 * To run a query within a React component, call `usePatientAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAlertQuery({
 *   variables: {
 *      id: // value for 'id'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientAlertQuery(baseOptions: Apollo.QueryHookOptions<PatientAlertQuery, PatientAlertQueryVariables>) {
        return Apollo.useQuery<PatientAlertQuery, PatientAlertQueryVariables>(PatientAlertDocument, baseOptions);
      }
export function usePatientAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientAlertQuery, PatientAlertQueryVariables>) {
          return Apollo.useLazyQuery<PatientAlertQuery, PatientAlertQueryVariables>(PatientAlertDocument, baseOptions);
        }
export type PatientAlertQueryHookResult = ReturnType<typeof usePatientAlertQuery>;
export type PatientAlertLazyQueryHookResult = ReturnType<typeof usePatientAlertLazyQuery>;
export type PatientAlertQueryResult = Apollo.QueryResult<PatientAlertQuery, PatientAlertQueryVariables>;
export const ConsultationAlertsDocument = gql`
    query ConsultationAlerts($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    promoCodeMessageForGp
    membershipCodeMessageForGp
    patient {
      id
      uuid
      patient_alerts {
        id
        message
        updatedAt
      }
      canCreatePrescriptionPdf {
        valid
        errorCodes
      }
    }
  }
}
    `;

/**
 * __useConsultationAlertsQuery__
 *
 * To run a query within a React component, call `useConsultationAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationAlertsQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useConsultationAlertsQuery(baseOptions: Apollo.QueryHookOptions<ConsultationAlertsQuery, ConsultationAlertsQueryVariables>) {
        return Apollo.useQuery<ConsultationAlertsQuery, ConsultationAlertsQueryVariables>(ConsultationAlertsDocument, baseOptions);
      }
export function useConsultationAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationAlertsQuery, ConsultationAlertsQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationAlertsQuery, ConsultationAlertsQueryVariables>(ConsultationAlertsDocument, baseOptions);
        }
export type ConsultationAlertsQueryHookResult = ReturnType<typeof useConsultationAlertsQuery>;
export type ConsultationAlertsLazyQueryHookResult = ReturnType<typeof useConsultationAlertsLazyQuery>;
export type ConsultationAlertsQueryResult = Apollo.QueryResult<ConsultationAlertsQuery, ConsultationAlertsQueryVariables>;
export const UpdatePatientDetailsDocument = gql`
    mutation updatePatientDetails($patientId: Int!, $input: ProfileInformation!) {
  updatePatientDetails(id: $patientId, patient: $input) {
    id
    first_name
    last_name
    gender
    email
    date_of_birth
    contact_details {
      address_first_line
      address_second_line
      address_third_line
      address_state_code
      address_post_code
      phone_country_code
      phone_number
    }
    next_of_kin {
      id
      first_name
      last_name
      phone_number
      relation
    }
    id_verification
    identity_check {
      id
      status
      check_id
      verification_overwritten_at
      verification_overwritten_by {
        id
        fullName
      }
    }
  }
}
    `;
export type UpdatePatientDetailsMutationFn = Apollo.MutationFunction<UpdatePatientDetailsMutation, UpdatePatientDetailsMutationVariables>;

/**
 * __useUpdatePatientDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePatientDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientDetailsMutation, { data, loading, error }] = useUpdatePatientDetailsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientDetailsMutation, UpdatePatientDetailsMutationVariables>) {
        return Apollo.useMutation<UpdatePatientDetailsMutation, UpdatePatientDetailsMutationVariables>(UpdatePatientDetailsDocument, baseOptions);
      }
export type UpdatePatientDetailsMutationHookResult = ReturnType<typeof useUpdatePatientDetailsMutation>;
export type UpdatePatientDetailsMutationResult = Apollo.MutationResult<UpdatePatientDetailsMutation>;
export type UpdatePatientDetailsMutationOptions = Apollo.BaseMutationOptions<UpdatePatientDetailsMutation, UpdatePatientDetailsMutationVariables>;
export const OverrideIdentityCheckDocument = gql`
    mutation overrideIdentityCheck($patientId: ID!, $identityCheckId: ID) {
  overrideIdentityCheck(patientId: $patientId, identityCheckId: $identityCheckId) {
    id
    status
    check_id
  }
}
    `;
export type OverrideIdentityCheckMutationFn = Apollo.MutationFunction<OverrideIdentityCheckMutation, OverrideIdentityCheckMutationVariables>;

/**
 * __useOverrideIdentityCheckMutation__
 *
 * To run a mutation, you first call `useOverrideIdentityCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverrideIdentityCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overrideIdentityCheckMutation, { data, loading, error }] = useOverrideIdentityCheckMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      identityCheckId: // value for 'identityCheckId'
 *   },
 * });
 */
export function useOverrideIdentityCheckMutation(baseOptions?: Apollo.MutationHookOptions<OverrideIdentityCheckMutation, OverrideIdentityCheckMutationVariables>) {
        return Apollo.useMutation<OverrideIdentityCheckMutation, OverrideIdentityCheckMutationVariables>(OverrideIdentityCheckDocument, baseOptions);
      }
export type OverrideIdentityCheckMutationHookResult = ReturnType<typeof useOverrideIdentityCheckMutation>;
export type OverrideIdentityCheckMutationResult = Apollo.MutationResult<OverrideIdentityCheckMutation>;
export type OverrideIdentityCheckMutationOptions = Apollo.BaseMutationOptions<OverrideIdentityCheckMutation, OverrideIdentityCheckMutationVariables>;
export const PatientDetailsDocument = gql`
    query PatientDetails($id: ID!) {
  patient(id: $id) {
    id
    region {
      id
      name
      iso_code
    }
    contact_details {
      address_first_line
      address_post_code
      address_second_line
      address_state_code
      address_third_line
      phone_country_code
      phone_number
    }
    next_of_kin {
      id
      first_name
      last_name
      phone_number
      relation
    }
    account_owner_contact_details {
      address_first_line
      address_second_line
      address_third_line
      address_post_code
      phone_country_code
      phone_number
    }
  }
}
    `;

/**
 * __usePatientDetailsQuery__
 *
 * To run a query within a React component, call `usePatientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientDetailsQuery(baseOptions: Apollo.QueryHookOptions<PatientDetailsQuery, PatientDetailsQueryVariables>) {
        return Apollo.useQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(PatientDetailsDocument, baseOptions);
      }
export function usePatientDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientDetailsQuery, PatientDetailsQueryVariables>) {
          return Apollo.useLazyQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(PatientDetailsDocument, baseOptions);
        }
export type PatientDetailsQueryHookResult = ReturnType<typeof usePatientDetailsQuery>;
export type PatientDetailsLazyQueryHookResult = ReturnType<typeof usePatientDetailsLazyQuery>;
export type PatientDetailsQueryResult = Apollo.QueryResult<PatientDetailsQuery, PatientDetailsQueryVariables>;
export const ConsultationPatientMetricsDocument = gql`
    query ConsultationPatientMetrics($id: ID!, $patientMetricsFilter: [String], $patientMetricsCount: Int, $bloodPressureOn: Boolean!) {
  consultation(id: $id) {
    ...PatientMetrics
  }
}
    ${PatientMetricsFragmentDoc}`;

/**
 * __useConsultationPatientMetricsQuery__
 *
 * To run a query within a React component, call `useConsultationPatientMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationPatientMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationPatientMetricsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      patientMetricsFilter: // value for 'patientMetricsFilter'
 *      patientMetricsCount: // value for 'patientMetricsCount'
 *      bloodPressureOn: // value for 'bloodPressureOn'
 *   },
 * });
 */
export function useConsultationPatientMetricsQuery(baseOptions: Apollo.QueryHookOptions<ConsultationPatientMetricsQuery, ConsultationPatientMetricsQueryVariables>) {
        return Apollo.useQuery<ConsultationPatientMetricsQuery, ConsultationPatientMetricsQueryVariables>(ConsultationPatientMetricsDocument, baseOptions);
      }
export function useConsultationPatientMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationPatientMetricsQuery, ConsultationPatientMetricsQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationPatientMetricsQuery, ConsultationPatientMetricsQueryVariables>(ConsultationPatientMetricsDocument, baseOptions);
        }
export type ConsultationPatientMetricsQueryHookResult = ReturnType<typeof useConsultationPatientMetricsQuery>;
export type ConsultationPatientMetricsLazyQueryHookResult = ReturnType<typeof useConsultationPatientMetricsLazyQuery>;
export type ConsultationPatientMetricsQueryResult = Apollo.QueryResult<ConsultationPatientMetricsQuery, ConsultationPatientMetricsQueryVariables>;
export const AddPatientMetricDocument = gql`
    mutation addPatientMetric($patientUuid: ID!, $patientId: ID!, $clinicianUuid: ID, $clinicianId: ID, $consultationId: ID!, $clinicianRole: String, $observationType: PatientMetricObservationType!, $input: JSON!, $patientMetricsFilter: PatientMetricSource!, $consultationType: String, $consultationUuid: String!) {
  addPatientMetric(patientUuid: $patientUuid, patientId: $patientId, clinicianUuid: $clinicianUuid, clinicianId: $clinicianId, consultationId: $consultationId, clinicianRole: $clinicianRole, observationType: $observationType, input: $input, filter: $patientMetricsFilter, consultationType: $consultationType, consultationUuid: $consultationUuid) {
    id
    value
    values {
      code
      value
    }
    timestamp
    performer
  }
}
    `;
export type AddPatientMetricMutationFn = Apollo.MutationFunction<AddPatientMetricMutation, AddPatientMetricMutationVariables>;

/**
 * __useAddPatientMetricMutation__
 *
 * To run a mutation, you first call `useAddPatientMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientMetricMutation, { data, loading, error }] = useAddPatientMetricMutation({
 *   variables: {
 *      patientUuid: // value for 'patientUuid'
 *      patientId: // value for 'patientId'
 *      clinicianUuid: // value for 'clinicianUuid'
 *      clinicianId: // value for 'clinicianId'
 *      consultationId: // value for 'consultationId'
 *      clinicianRole: // value for 'clinicianRole'
 *      observationType: // value for 'observationType'
 *      input: // value for 'input'
 *      patientMetricsFilter: // value for 'patientMetricsFilter'
 *      consultationType: // value for 'consultationType'
 *      consultationUuid: // value for 'consultationUuid'
 *   },
 * });
 */
export function useAddPatientMetricMutation(baseOptions?: Apollo.MutationHookOptions<AddPatientMetricMutation, AddPatientMetricMutationVariables>) {
        return Apollo.useMutation<AddPatientMetricMutation, AddPatientMetricMutationVariables>(AddPatientMetricDocument, baseOptions);
      }
export type AddPatientMetricMutationHookResult = ReturnType<typeof useAddPatientMetricMutation>;
export type AddPatientMetricMutationResult = Apollo.MutationResult<AddPatientMetricMutation>;
export type AddPatientMetricMutationOptions = Apollo.BaseMutationOptions<AddPatientMetricMutation, AddPatientMetricMutationVariables>;
export const ConsultationsForProblemsListDocument = gql`
    query ConsultationsForProblemsList($searchCriteria: ConsultationSearchCriteria) {
  consultationsv2(searchCriteria: $searchCriteria) {
    totalItems
    items {
      id
      status
      consultant {
        id
        uuid
        name
        role
        profession_name
      }
      scheduledTime
    }
  }
}
    `;

/**
 * __useConsultationsForProblemsListQuery__
 *
 * To run a query within a React component, call `useConsultationsForProblemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationsForProblemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationsForProblemsListQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useConsultationsForProblemsListQuery(baseOptions?: Apollo.QueryHookOptions<ConsultationsForProblemsListQuery, ConsultationsForProblemsListQueryVariables>) {
        return Apollo.useQuery<ConsultationsForProblemsListQuery, ConsultationsForProblemsListQueryVariables>(ConsultationsForProblemsListDocument, baseOptions);
      }
export function useConsultationsForProblemsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationsForProblemsListQuery, ConsultationsForProblemsListQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationsForProblemsListQuery, ConsultationsForProblemsListQueryVariables>(ConsultationsForProblemsListDocument, baseOptions);
        }
export type ConsultationsForProblemsListQueryHookResult = ReturnType<typeof useConsultationsForProblemsListQuery>;
export type ConsultationsForProblemsListLazyQueryHookResult = ReturnType<typeof useConsultationsForProblemsListLazyQuery>;
export type ConsultationsForProblemsListQueryResult = Apollo.QueryResult<ConsultationsForProblemsListQuery, ConsultationsForProblemsListQueryVariables>;
export const TimelineConsultationDocument = gql`
    query TimelineConsultation($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    status
    patientNote
    consultant {
      id
      uuid
      name
      role
      profession_name
    }
    consultationNote {
      id
      assessment
      assessmentCodes {
        code
        term
        length
        offset
      }
    }
    images: imagesV2 {
      ... on ConsultationImages {
        items
      }
    }
  }
}
    `;

/**
 * __useTimelineConsultationQuery__
 *
 * To run a query within a React component, call `useTimelineConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineConsultationQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useTimelineConsultationQuery(baseOptions: Apollo.QueryHookOptions<TimelineConsultationQuery, TimelineConsultationQueryVariables>) {
        return Apollo.useQuery<TimelineConsultationQuery, TimelineConsultationQueryVariables>(TimelineConsultationDocument, baseOptions);
      }
export function useTimelineConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineConsultationQuery, TimelineConsultationQueryVariables>) {
          return Apollo.useLazyQuery<TimelineConsultationQuery, TimelineConsultationQueryVariables>(TimelineConsultationDocument, baseOptions);
        }
export type TimelineConsultationQueryHookResult = ReturnType<typeof useTimelineConsultationQuery>;
export type TimelineConsultationLazyQueryHookResult = ReturnType<typeof useTimelineConsultationLazyQuery>;
export type TimelineConsultationQueryResult = Apollo.QueryResult<TimelineConsultationQuery, TimelineConsultationQueryVariables>;
export const TimelineConsultationActionsDocument = gql`
    query TimelineConsultationActions($consultationId: ID!, $prescriptionNotAllowed: Boolean!) {
  consultation(id: $consultationId) {
    prescriptions @skip(if: $prescriptionNotAllowed) {
      id
      drugs {
        prescriptionDrugId
        drugName
        directions
        quantity
      }
    }
    diagnosticTests {
      id
      type
      testId
    }
    pathologyTests {
      id
      type
      testId
    }
    referrals {
      id
      specialismName
    }
    testResults {
      id
    }
    workflowsV2 {
      id
      state
      variables {
        name
        value
      }
      workflowDefinition {
        id
        group {
          type
          label
        }
        roles
        workflow_definition_name
      }
    }
    sickNotes {
      id
    }
  }
}
    `;

/**
 * __useTimelineConsultationActionsQuery__
 *
 * To run a query within a React component, call `useTimelineConsultationActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineConsultationActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineConsultationActionsQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      prescriptionNotAllowed: // value for 'prescriptionNotAllowed'
 *   },
 * });
 */
export function useTimelineConsultationActionsQuery(baseOptions: Apollo.QueryHookOptions<TimelineConsultationActionsQuery, TimelineConsultationActionsQueryVariables>) {
        return Apollo.useQuery<TimelineConsultationActionsQuery, TimelineConsultationActionsQueryVariables>(TimelineConsultationActionsDocument, baseOptions);
      }
export function useTimelineConsultationActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineConsultationActionsQuery, TimelineConsultationActionsQueryVariables>) {
          return Apollo.useLazyQuery<TimelineConsultationActionsQuery, TimelineConsultationActionsQueryVariables>(TimelineConsultationActionsDocument, baseOptions);
        }
export type TimelineConsultationActionsQueryHookResult = ReturnType<typeof useTimelineConsultationActionsQuery>;
export type TimelineConsultationActionsLazyQueryHookResult = ReturnType<typeof useTimelineConsultationActionsLazyQuery>;
export type TimelineConsultationActionsQueryResult = Apollo.QueryResult<TimelineConsultationActionsQuery, TimelineConsultationActionsQueryVariables>;
export const CurrentConsultationDocument = gql`
    query CurrentConsultation($id: ID!) {
  consultation(id: $id) {
    id
    consultant {
      id
      name
      role
    }
    status
  }
}
    `;

/**
 * __useCurrentConsultationQuery__
 *
 * To run a query within a React component, call `useCurrentConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentConsultationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurrentConsultationQuery(baseOptions: Apollo.QueryHookOptions<CurrentConsultationQuery, CurrentConsultationQueryVariables>) {
        return Apollo.useQuery<CurrentConsultationQuery, CurrentConsultationQueryVariables>(CurrentConsultationDocument, baseOptions);
      }
export function useCurrentConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentConsultationQuery, CurrentConsultationQueryVariables>) {
          return Apollo.useLazyQuery<CurrentConsultationQuery, CurrentConsultationQueryVariables>(CurrentConsultationDocument, baseOptions);
        }
export type CurrentConsultationQueryHookResult = ReturnType<typeof useCurrentConsultationQuery>;
export type CurrentConsultationLazyQueryHookResult = ReturnType<typeof useCurrentConsultationLazyQuery>;
export type CurrentConsultationQueryResult = Apollo.QueryResult<CurrentConsultationQuery, CurrentConsultationQueryVariables>;
export const PatientTimelineDocument = gql`
    query PatientTimeline($patientId: ID!, $eventTypes: [TimelineEventType], $chatscriptOn: Boolean!) {
  patient(id: $patientId) {
    id
    futureConsultations(dangerousConsultationStatuses: [confirmed, completed, no_show]) {
      ...PatientTimelineConsultationFragment
    }
    historyV2(eventTypes: $eventTypes, dangerousConsultationStatuses: [confirmed, completed, no_show]) {
      errors {
        type
        message
        fullError
      }
      events {
        ...PatientTimelineTestReportFragment
        ...PatientTimelineConsultationFragment
        ...PatientTimelineChatscriptConversationFragment @include(if: $chatscriptOn)
      }
    }
  }
}
    ${PatientTimelineConsultationFragmentFragmentDoc}
${PatientTimelineTestReportFragmentFragmentDoc}
${PatientTimelineChatscriptConversationFragmentFragmentDoc}`;

/**
 * __usePatientTimelineQuery__
 *
 * To run a query within a React component, call `usePatientTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTimelineQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      eventTypes: // value for 'eventTypes'
 *      chatscriptOn: // value for 'chatscriptOn'
 *   },
 * });
 */
export function usePatientTimelineQuery(baseOptions: Apollo.QueryHookOptions<PatientTimelineQuery, PatientTimelineQueryVariables>) {
        return Apollo.useQuery<PatientTimelineQuery, PatientTimelineQueryVariables>(PatientTimelineDocument, baseOptions);
      }
export function usePatientTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientTimelineQuery, PatientTimelineQueryVariables>) {
          return Apollo.useLazyQuery<PatientTimelineQuery, PatientTimelineQueryVariables>(PatientTimelineDocument, baseOptions);
        }
export type PatientTimelineQueryHookResult = ReturnType<typeof usePatientTimelineQuery>;
export type PatientTimelineLazyQueryHookResult = ReturnType<typeof usePatientTimelineLazyQuery>;
export type PatientTimelineQueryResult = Apollo.QueryResult<PatientTimelineQuery, PatientTimelineQueryVariables>;
export const CpPreConsConversationDocument = gql`
    query CpPreConsConversation($eventId: ID!, $patientUuid: ID!) {
  chatscriptConversation(userId: $patientUuid, conversationId: $eventId) {
    id
    startedAt
  }
}
    `;

/**
 * __useCpPreConsConversationQuery__
 *
 * To run a query within a React component, call `useCpPreConsConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpPreConsConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpPreConsConversationQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      patientUuid: // value for 'patientUuid'
 *   },
 * });
 */
export function useCpPreConsConversationQuery(baseOptions: Apollo.QueryHookOptions<CpPreConsConversationQuery, CpPreConsConversationQueryVariables>) {
        return Apollo.useQuery<CpPreConsConversationQuery, CpPreConsConversationQueryVariables>(CpPreConsConversationDocument, baseOptions);
      }
export function useCpPreConsConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpPreConsConversationQuery, CpPreConsConversationQueryVariables>) {
          return Apollo.useLazyQuery<CpPreConsConversationQuery, CpPreConsConversationQueryVariables>(CpPreConsConversationDocument, baseOptions);
        }
export type CpPreConsConversationQueryHookResult = ReturnType<typeof useCpPreConsConversationQuery>;
export type CpPreConsConversationLazyQueryHookResult = ReturnType<typeof useCpPreConsConversationLazyQuery>;
export type CpPreConsConversationQueryResult = Apollo.QueryResult<CpPreConsConversationQuery, CpPreConsConversationQueryVariables>;
export const PreconsultationSummaryDocument = gql`
    query PreconsultationSummary($id: ID!) {
  consultation(id: $id) {
    id
    preConsultationFlowId
    patient {
      uuid
    }
  }
}
    `;

/**
 * __usePreconsultationSummaryQuery__
 *
 * To run a query within a React component, call `usePreconsultationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreconsultationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreconsultationSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreconsultationSummaryQuery(baseOptions: Apollo.QueryHookOptions<PreconsultationSummaryQuery, PreconsultationSummaryQueryVariables>) {
        return Apollo.useQuery<PreconsultationSummaryQuery, PreconsultationSummaryQueryVariables>(PreconsultationSummaryDocument, baseOptions);
      }
export function usePreconsultationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreconsultationSummaryQuery, PreconsultationSummaryQueryVariables>) {
          return Apollo.useLazyQuery<PreconsultationSummaryQuery, PreconsultationSummaryQueryVariables>(PreconsultationSummaryDocument, baseOptions);
        }
export type PreconsultationSummaryQueryHookResult = ReturnType<typeof usePreconsultationSummaryQuery>;
export type PreconsultationSummaryLazyQueryHookResult = ReturnType<typeof usePreconsultationSummaryLazyQuery>;
export type PreconsultationSummaryQueryResult = Apollo.QueryResult<PreconsultationSummaryQuery, PreconsultationSummaryQueryVariables>;
export const SignPrescriptionMutationDocument = gql`
    mutation SignPrescriptionMutation($id: ID!, $input: PrescriptionSignatureRequestInput!) {
  signPrescription(id: $id, input: $input) {
    id
  }
}
    `;
export type SignPrescriptionMutationMutationFn = Apollo.MutationFunction<SignPrescriptionMutationMutation, SignPrescriptionMutationMutationVariables>;

/**
 * __useSignPrescriptionMutationMutation__
 *
 * To run a mutation, you first call `useSignPrescriptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPrescriptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPrescriptionMutationMutation, { data, loading, error }] = useSignPrescriptionMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignPrescriptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<SignPrescriptionMutationMutation, SignPrescriptionMutationMutationVariables>) {
        return Apollo.useMutation<SignPrescriptionMutationMutation, SignPrescriptionMutationMutationVariables>(SignPrescriptionMutationDocument, baseOptions);
      }
export type SignPrescriptionMutationMutationHookResult = ReturnType<typeof useSignPrescriptionMutationMutation>;
export type SignPrescriptionMutationMutationResult = Apollo.MutationResult<SignPrescriptionMutationMutation>;
export type SignPrescriptionMutationMutationOptions = Apollo.BaseMutationOptions<SignPrescriptionMutationMutation, SignPrescriptionMutationMutationVariables>;
export const ConfirmPrescriptionModalDocument = gql`
    query ConfirmPrescriptionModal($prescriptionId: ID) {
  prescriptions(prescriptionId: $prescriptionId) {
    items {
      id
      createdAt
      prescriptionState
      actions
      patient {
        id
        first_name
        last_name
        date_of_birth
        age @client
        gender
        canCreatePrescriptionPdf {
          valid
          errorCodes
        }
      }
      drugs {
        prescriptionDrugId
        drugId
        drugName
        dosage
        duration
        pharmaceuticalForm
        strength
        quantity
        packagingSize
        directions
        notes
        repeatable
        repeats
        reviewDate
      }
    }
  }
}
    `;

/**
 * __useConfirmPrescriptionModalQuery__
 *
 * To run a query within a React component, call `useConfirmPrescriptionModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmPrescriptionModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmPrescriptionModalQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function useConfirmPrescriptionModalQuery(baseOptions?: Apollo.QueryHookOptions<ConfirmPrescriptionModalQuery, ConfirmPrescriptionModalQueryVariables>) {
        return Apollo.useQuery<ConfirmPrescriptionModalQuery, ConfirmPrescriptionModalQueryVariables>(ConfirmPrescriptionModalDocument, baseOptions);
      }
export function useConfirmPrescriptionModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmPrescriptionModalQuery, ConfirmPrescriptionModalQueryVariables>) {
          return Apollo.useLazyQuery<ConfirmPrescriptionModalQuery, ConfirmPrescriptionModalQueryVariables>(ConfirmPrescriptionModalDocument, baseOptions);
        }
export type ConfirmPrescriptionModalQueryHookResult = ReturnType<typeof useConfirmPrescriptionModalQuery>;
export type ConfirmPrescriptionModalLazyQueryHookResult = ReturnType<typeof useConfirmPrescriptionModalLazyQuery>;
export type ConfirmPrescriptionModalQueryResult = Apollo.QueryResult<ConfirmPrescriptionModalQuery, ConfirmPrescriptionModalQueryVariables>;
export const SuggestedDrugsQueryDocument = gql`
    query SuggestedDrugsQuery($query: String!, $region: String!) {
  drugs(query: $query, region: $region, limit: 40) {
    id
    name
    generic
    expensive
    strength
    controlled
    blacklisted
    drugPreparation
    activeIngredients
    pharmaceuticalForm
    packaging {
      packagingId
      packageSize
    }
  }
}
    `;

/**
 * __useSuggestedDrugsQueryQuery__
 *
 * To run a query within a React component, call `useSuggestedDrugsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedDrugsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedDrugsQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useSuggestedDrugsQueryQuery(baseOptions: Apollo.QueryHookOptions<SuggestedDrugsQueryQuery, SuggestedDrugsQueryQueryVariables>) {
        return Apollo.useQuery<SuggestedDrugsQueryQuery, SuggestedDrugsQueryQueryVariables>(SuggestedDrugsQueryDocument, baseOptions);
      }
export function useSuggestedDrugsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedDrugsQueryQuery, SuggestedDrugsQueryQueryVariables>) {
          return Apollo.useLazyQuery<SuggestedDrugsQueryQuery, SuggestedDrugsQueryQueryVariables>(SuggestedDrugsQueryDocument, baseOptions);
        }
export type SuggestedDrugsQueryQueryHookResult = ReturnType<typeof useSuggestedDrugsQueryQuery>;
export type SuggestedDrugsQueryLazyQueryHookResult = ReturnType<typeof useSuggestedDrugsQueryLazyQuery>;
export type SuggestedDrugsQueryQueryResult = Apollo.QueryResult<SuggestedDrugsQueryQuery, SuggestedDrugsQueryQueryVariables>;
export const SuggestDrugDirectionsMutationDocument = gql`
    mutation SuggestDrugDirectionsMutation($drugId: ID, $drugName: String!, $dosage: String!, $duration: String!, $region: String!) {
  suggestDrugDirections(drugId: $drugId, drugName: $drugName, dosage: $dosage, duration: $duration, region: $region) {
    drugId
    drugName
    directions
    dosage
    duration
    quantity
    errorInDosage
    errorInDuration
    errorInDirections
  }
}
    `;
export type SuggestDrugDirectionsMutationMutationFn = Apollo.MutationFunction<SuggestDrugDirectionsMutationMutation, SuggestDrugDirectionsMutationMutationVariables>;

/**
 * __useSuggestDrugDirectionsMutationMutation__
 *
 * To run a mutation, you first call `useSuggestDrugDirectionsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestDrugDirectionsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestDrugDirectionsMutationMutation, { data, loading, error }] = useSuggestDrugDirectionsMutationMutation({
 *   variables: {
 *      drugId: // value for 'drugId'
 *      drugName: // value for 'drugName'
 *      dosage: // value for 'dosage'
 *      duration: // value for 'duration'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useSuggestDrugDirectionsMutationMutation(baseOptions?: Apollo.MutationHookOptions<SuggestDrugDirectionsMutationMutation, SuggestDrugDirectionsMutationMutationVariables>) {
        return Apollo.useMutation<SuggestDrugDirectionsMutationMutation, SuggestDrugDirectionsMutationMutationVariables>(SuggestDrugDirectionsMutationDocument, baseOptions);
      }
export type SuggestDrugDirectionsMutationMutationHookResult = ReturnType<typeof useSuggestDrugDirectionsMutationMutation>;
export type SuggestDrugDirectionsMutationMutationResult = Apollo.MutationResult<SuggestDrugDirectionsMutationMutation>;
export type SuggestDrugDirectionsMutationMutationOptions = Apollo.BaseMutationOptions<SuggestDrugDirectionsMutationMutation, SuggestDrugDirectionsMutationMutationVariables>;
export const ValidateDrugDocument = gql`
    mutation ValidateDrug($patientId: ID!, $drug: PrescriptionDrugInput!, $consultationId: ID, $region: String!, $prescriptionDrugId: ID) {
  validateDrug(patientId: $patientId, drug: $drug, consultationId: $consultationId, region: $region, prescriptionDrugId: $prescriptionDrugId) {
    warnings {
      action
      message
      allowPrescribing
    }
  }
}
    `;
export type ValidateDrugMutationFn = Apollo.MutationFunction<ValidateDrugMutation, ValidateDrugMutationVariables>;

/**
 * __useValidateDrugMutation__
 *
 * To run a mutation, you first call `useValidateDrugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateDrugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateDrugMutation, { data, loading, error }] = useValidateDrugMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      drug: // value for 'drug'
 *      consultationId: // value for 'consultationId'
 *      region: // value for 'region'
 *      prescriptionDrugId: // value for 'prescriptionDrugId'
 *   },
 * });
 */
export function useValidateDrugMutation(baseOptions?: Apollo.MutationHookOptions<ValidateDrugMutation, ValidateDrugMutationVariables>) {
        return Apollo.useMutation<ValidateDrugMutation, ValidateDrugMutationVariables>(ValidateDrugDocument, baseOptions);
      }
export type ValidateDrugMutationHookResult = ReturnType<typeof useValidateDrugMutation>;
export type ValidateDrugMutationResult = Apollo.MutationResult<ValidateDrugMutation>;
export type ValidateDrugMutationOptions = Apollo.BaseMutationOptions<ValidateDrugMutation, ValidateDrugMutationVariables>;
export const CreatePrescriptionMutationDocument = gql`
    mutation CreatePrescriptionMutation($consultationId: ID!, $patientId: ID!, $drugs: [PrescriptionDrugInput], $consumerNetwork: String!, $region: String!) {
  createPrescription(region: $region, consultationId: $consultationId, patientId: $patientId, drugs: $drugs, consumerNetwork: $consumerNetwork) {
    ...PrescriptionFieldsFragmentModal
  }
}
    ${PrescriptionFieldsFragmentModalFragmentDoc}`;
export type CreatePrescriptionMutationMutationFn = Apollo.MutationFunction<CreatePrescriptionMutationMutation, CreatePrescriptionMutationMutationVariables>;

/**
 * __useCreatePrescriptionMutationMutation__
 *
 * To run a mutation, you first call `useCreatePrescriptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrescriptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrescriptionMutationMutation, { data, loading, error }] = useCreatePrescriptionMutationMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      patientId: // value for 'patientId'
 *      drugs: // value for 'drugs'
 *      consumerNetwork: // value for 'consumerNetwork'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCreatePrescriptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrescriptionMutationMutation, CreatePrescriptionMutationMutationVariables>) {
        return Apollo.useMutation<CreatePrescriptionMutationMutation, CreatePrescriptionMutationMutationVariables>(CreatePrescriptionMutationDocument, baseOptions);
      }
export type CreatePrescriptionMutationMutationHookResult = ReturnType<typeof useCreatePrescriptionMutationMutation>;
export type CreatePrescriptionMutationMutationResult = Apollo.MutationResult<CreatePrescriptionMutationMutation>;
export type CreatePrescriptionMutationMutationOptions = Apollo.BaseMutationOptions<CreatePrescriptionMutationMutation, CreatePrescriptionMutationMutationVariables>;
export const UpdateDrugInPrescriptionDocument = gql`
    mutation UpdateDrugInPrescription($id: ID!, $prescriptionDrugId: ID!, $drug: PrescriptionDrugInput!, $region: String!) {
  updateDrugInPrescription(id: $id, prescriptionDrugId: $prescriptionDrugId, drug: $drug, region: $region) {
    ...PrescriptionFieldsFragmentModal
  }
}
    ${PrescriptionFieldsFragmentModalFragmentDoc}`;
export type UpdateDrugInPrescriptionMutationFn = Apollo.MutationFunction<UpdateDrugInPrescriptionMutation, UpdateDrugInPrescriptionMutationVariables>;

/**
 * __useUpdateDrugInPrescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateDrugInPrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDrugInPrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDrugInPrescriptionMutation, { data, loading, error }] = useUpdateDrugInPrescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      prescriptionDrugId: // value for 'prescriptionDrugId'
 *      drug: // value for 'drug'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useUpdateDrugInPrescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDrugInPrescriptionMutation, UpdateDrugInPrescriptionMutationVariables>) {
        return Apollo.useMutation<UpdateDrugInPrescriptionMutation, UpdateDrugInPrescriptionMutationVariables>(UpdateDrugInPrescriptionDocument, baseOptions);
      }
export type UpdateDrugInPrescriptionMutationHookResult = ReturnType<typeof useUpdateDrugInPrescriptionMutation>;
export type UpdateDrugInPrescriptionMutationResult = Apollo.MutationResult<UpdateDrugInPrescriptionMutation>;
export type UpdateDrugInPrescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateDrugInPrescriptionMutation, UpdateDrugInPrescriptionMutationVariables>;
export const AddDrugToPrescriptionDocument = gql`
    mutation addDrugToPrescription($id: ID!, $drug: PrescriptionDrugInput!, $region: String!) {
  addDrugToPrescription(id: $id, drug: $drug, region: $region) {
    ...PrescriptionFieldsFragmentModal
  }
}
    ${PrescriptionFieldsFragmentModalFragmentDoc}`;
export type AddDrugToPrescriptionMutationFn = Apollo.MutationFunction<AddDrugToPrescriptionMutation, AddDrugToPrescriptionMutationVariables>;

/**
 * __useAddDrugToPrescriptionMutation__
 *
 * To run a mutation, you first call `useAddDrugToPrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDrugToPrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDrugToPrescriptionMutation, { data, loading, error }] = useAddDrugToPrescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      drug: // value for 'drug'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useAddDrugToPrescriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddDrugToPrescriptionMutation, AddDrugToPrescriptionMutationVariables>) {
        return Apollo.useMutation<AddDrugToPrescriptionMutation, AddDrugToPrescriptionMutationVariables>(AddDrugToPrescriptionDocument, baseOptions);
      }
export type AddDrugToPrescriptionMutationHookResult = ReturnType<typeof useAddDrugToPrescriptionMutation>;
export type AddDrugToPrescriptionMutationResult = Apollo.MutationResult<AddDrugToPrescriptionMutation>;
export type AddDrugToPrescriptionMutationOptions = Apollo.BaseMutationOptions<AddDrugToPrescriptionMutation, AddDrugToPrescriptionMutationVariables>;
export const DrugQueryDocument = gql`
    query DrugQuery($drugId: ID!, $region: String!) {
  drug(id: $drugId, region: $region) {
    id
    name
    generic
    expensive
    strength
    controlled
    blacklisted
    drugPreparation
    activeIngredients
    pharmaceuticalForm
    packaging {
      packagingId
      packageSize
    }
  }
}
    `;

/**
 * __useDrugQueryQuery__
 *
 * To run a query within a React component, call `useDrugQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrugQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrugQueryQuery({
 *   variables: {
 *      drugId: // value for 'drugId'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useDrugQueryQuery(baseOptions: Apollo.QueryHookOptions<DrugQueryQuery, DrugQueryQueryVariables>) {
        return Apollo.useQuery<DrugQueryQuery, DrugQueryQueryVariables>(DrugQueryDocument, baseOptions);
      }
export function useDrugQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DrugQueryQuery, DrugQueryQueryVariables>) {
          return Apollo.useLazyQuery<DrugQueryQuery, DrugQueryQueryVariables>(DrugQueryDocument, baseOptions);
        }
export type DrugQueryQueryHookResult = ReturnType<typeof useDrugQueryQuery>;
export type DrugQueryLazyQueryHookResult = ReturnType<typeof useDrugQueryLazyQuery>;
export type DrugQueryQueryResult = Apollo.QueryResult<DrugQueryQuery, DrugQueryQueryVariables>;
export const PrescriptionAuditQueryDocument = gql`
    query PrescriptionAuditQuery($prescriptionId: ID!, $eventTypes: [String]) {
  prescriptionAudit(prescriptionId: $prescriptionId, eventTypes: $eventTypes) {
    id
    babylonRequestId
    consumerNetwork
    eventDetails
    eventTimestamp
    loginType
    eventType
    performedByName
  }
}
    `;

/**
 * __usePrescriptionAuditQueryQuery__
 *
 * To run a query within a React component, call `usePrescriptionAuditQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionAuditQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionAuditQueryQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *      eventTypes: // value for 'eventTypes'
 *   },
 * });
 */
export function usePrescriptionAuditQueryQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionAuditQueryQuery, PrescriptionAuditQueryQueryVariables>) {
        return Apollo.useQuery<PrescriptionAuditQueryQuery, PrescriptionAuditQueryQueryVariables>(PrescriptionAuditQueryDocument, baseOptions);
      }
export function usePrescriptionAuditQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionAuditQueryQuery, PrescriptionAuditQueryQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionAuditQueryQuery, PrescriptionAuditQueryQueryVariables>(PrescriptionAuditQueryDocument, baseOptions);
        }
export type PrescriptionAuditQueryQueryHookResult = ReturnType<typeof usePrescriptionAuditQueryQuery>;
export type PrescriptionAuditQueryLazyQueryHookResult = ReturnType<typeof usePrescriptionAuditQueryLazyQuery>;
export type PrescriptionAuditQueryQueryResult = Apollo.QueryResult<PrescriptionAuditQueryQuery, PrescriptionAuditQueryQueryVariables>;
export const PrescriptionAuditDocument = gql`
    query prescriptionAudit($prescriptionId: ID!) {
  prescriptionAudit(prescriptionId: $prescriptionId, filter: ["PRESCRIPTION_VIEWED"]) {
    id
    babylonRequestId
    consumerNetwork
    eventDetails
    eventTimestamp
    loginType
    eventType
    performedByName
  }
}
    `;

/**
 * __usePrescriptionAuditQuery__
 *
 * To run a query within a React component, call `usePrescriptionAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionAuditQuery({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function usePrescriptionAuditQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionAuditQuery, PrescriptionAuditQueryVariables>) {
        return Apollo.useQuery<PrescriptionAuditQuery, PrescriptionAuditQueryVariables>(PrescriptionAuditDocument, baseOptions);
      }
export function usePrescriptionAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionAuditQuery, PrescriptionAuditQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionAuditQuery, PrescriptionAuditQueryVariables>(PrescriptionAuditDocument, baseOptions);
        }
export type PrescriptionAuditQueryHookResult = ReturnType<typeof usePrescriptionAuditQuery>;
export type PrescriptionAuditLazyQueryHookResult = ReturnType<typeof usePrescriptionAuditLazyQuery>;
export type PrescriptionAuditQueryResult = Apollo.QueryResult<PrescriptionAuditQuery, PrescriptionAuditQueryVariables>;
export const VoidPrescriptionDocument = gql`
    mutation VoidPrescription($id: ID!, $reason: String!) {
  voidPrescription(id: $id, reason: $reason)
}
    `;
export type VoidPrescriptionMutationFn = Apollo.MutationFunction<VoidPrescriptionMutation, VoidPrescriptionMutationVariables>;

/**
 * __useVoidPrescriptionMutation__
 *
 * To run a mutation, you first call `useVoidPrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidPrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidPrescriptionMutation, { data, loading, error }] = useVoidPrescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useVoidPrescriptionMutation(baseOptions?: Apollo.MutationHookOptions<VoidPrescriptionMutation, VoidPrescriptionMutationVariables>) {
        return Apollo.useMutation<VoidPrescriptionMutation, VoidPrescriptionMutationVariables>(VoidPrescriptionDocument, baseOptions);
      }
export type VoidPrescriptionMutationHookResult = ReturnType<typeof useVoidPrescriptionMutation>;
export type VoidPrescriptionMutationResult = Apollo.MutationResult<VoidPrescriptionMutation>;
export type VoidPrescriptionMutationOptions = Apollo.BaseMutationOptions<VoidPrescriptionMutation, VoidPrescriptionMutationVariables>;
export const MarkPrescriptionPrintedDocument = gql`
    mutation markPrescriptionPrinted($id: ID!) {
  markPrescriptionPrinted(id: $id)
}
    `;
export type MarkPrescriptionPrintedMutationFn = Apollo.MutationFunction<MarkPrescriptionPrintedMutation, MarkPrescriptionPrintedMutationVariables>;

/**
 * __useMarkPrescriptionPrintedMutation__
 *
 * To run a mutation, you first call `useMarkPrescriptionPrintedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPrescriptionPrintedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPrescriptionPrintedMutation, { data, loading, error }] = useMarkPrescriptionPrintedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkPrescriptionPrintedMutation(baseOptions?: Apollo.MutationHookOptions<MarkPrescriptionPrintedMutation, MarkPrescriptionPrintedMutationVariables>) {
        return Apollo.useMutation<MarkPrescriptionPrintedMutation, MarkPrescriptionPrintedMutationVariables>(MarkPrescriptionPrintedDocument, baseOptions);
      }
export type MarkPrescriptionPrintedMutationHookResult = ReturnType<typeof useMarkPrescriptionPrintedMutation>;
export type MarkPrescriptionPrintedMutationResult = Apollo.MutationResult<MarkPrescriptionPrintedMutation>;
export type MarkPrescriptionPrintedMutationOptions = Apollo.BaseMutationOptions<MarkPrescriptionPrintedMutation, MarkPrescriptionPrintedMutationVariables>;
export const CpPrescriptionsDocument = gql`
    query CpPrescriptions($consultantId: ID!, $page: Int!, $statuses: [String], $signatureState: [String], $sort: String) {
  prescriptions(consultantId: $consultantId, page: $page, statuses: $statuses, signatureState: $signatureState, sort: $sort) {
    totalItems
    itemsPerPage
    pageIndex
    items {
      id
      consultation {
        id
      }
      createdAt
      prescriptionState
      actions
      patient {
        id
        first_name
        last_name
      }
      drugs {
        drugName
        packagingSize
        directions
        quantity
      }
      pharmacyDetails
    }
  }
}
    `;

/**
 * __useCpPrescriptionsQuery__
 *
 * To run a query within a React component, call `useCpPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpPrescriptionsQuery({
 *   variables: {
 *      consultantId: // value for 'consultantId'
 *      page: // value for 'page'
 *      statuses: // value for 'statuses'
 *      signatureState: // value for 'signatureState'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCpPrescriptionsQuery(baseOptions: Apollo.QueryHookOptions<CpPrescriptionsQuery, CpPrescriptionsQueryVariables>) {
        return Apollo.useQuery<CpPrescriptionsQuery, CpPrescriptionsQueryVariables>(CpPrescriptionsDocument, baseOptions);
      }
export function useCpPrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpPrescriptionsQuery, CpPrescriptionsQueryVariables>) {
          return Apollo.useLazyQuery<CpPrescriptionsQuery, CpPrescriptionsQueryVariables>(CpPrescriptionsDocument, baseOptions);
        }
export type CpPrescriptionsQueryHookResult = ReturnType<typeof useCpPrescriptionsQuery>;
export type CpPrescriptionsLazyQueryHookResult = ReturnType<typeof useCpPrescriptionsLazyQuery>;
export type CpPrescriptionsQueryResult = Apollo.QueryResult<CpPrescriptionsQuery, CpPrescriptionsQueryVariables>;
export const PrescriptionsStatsDocument = gql`
    query PrescriptionsStats($consultantId: ID!) {
  prescriptionStatistics(consultantId: $consultantId) {
    id
    toBePrintedCount
    toBeSignedCount
  }
}
    `;

/**
 * __usePrescriptionsStatsQuery__
 *
 * To run a query within a React component, call `usePrescriptionsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionsStatsQuery({
 *   variables: {
 *      consultantId: // value for 'consultantId'
 *   },
 * });
 */
export function usePrescriptionsStatsQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionsStatsQuery, PrescriptionsStatsQueryVariables>) {
        return Apollo.useQuery<PrescriptionsStatsQuery, PrescriptionsStatsQueryVariables>(PrescriptionsStatsDocument, baseOptions);
      }
export function usePrescriptionsStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionsStatsQuery, PrescriptionsStatsQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionsStatsQuery, PrescriptionsStatsQueryVariables>(PrescriptionsStatsDocument, baseOptions);
        }
export type PrescriptionsStatsQueryHookResult = ReturnType<typeof usePrescriptionsStatsQuery>;
export type PrescriptionsStatsLazyQueryHookResult = ReturnType<typeof usePrescriptionsStatsLazyQuery>;
export type PrescriptionsStatsQueryResult = Apollo.QueryResult<PrescriptionsStatsQuery, PrescriptionsStatsQueryVariables>;
export const PrescriptionsQueryDocument = gql`
    query PrescriptionsQuery($consultantId: ID!, $page: Int!, $statuses: [String], $signatureState: [String], $sort: String) {
  prescriptionStatistics(consultantId: $consultantId) {
    id
    toBePrintedCount
    toBeSignedCount
  }
  prescriptions(consultantId: $consultantId, page: $page, statuses: $statuses, signatureState: $signatureState, sort: $sort) {
    totalItems
    itemsPerPage
    pageIndex
    items {
      id
      consultation {
        id
      }
      createdAt
      prescriptionState
      actions
      patient {
        id
        first_name
        last_name
      }
      drugs {
        drugName
        packagingSize
        directions
        quantity
      }
      pharmacyDetails
    }
  }
}
    `;

/**
 * __usePrescriptionsQueryQuery__
 *
 * To run a query within a React component, call `usePrescriptionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionsQueryQuery({
 *   variables: {
 *      consultantId: // value for 'consultantId'
 *      page: // value for 'page'
 *      statuses: // value for 'statuses'
 *      signatureState: // value for 'signatureState'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePrescriptionsQueryQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionsQueryQuery, PrescriptionsQueryQueryVariables>) {
        return Apollo.useQuery<PrescriptionsQueryQuery, PrescriptionsQueryQueryVariables>(PrescriptionsQueryDocument, baseOptions);
      }
export function usePrescriptionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionsQueryQuery, PrescriptionsQueryQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionsQueryQuery, PrescriptionsQueryQueryVariables>(PrescriptionsQueryDocument, baseOptions);
        }
export type PrescriptionsQueryQueryHookResult = ReturnType<typeof usePrescriptionsQueryQuery>;
export type PrescriptionsQueryLazyQueryHookResult = ReturnType<typeof usePrescriptionsQueryLazyQuery>;
export type PrescriptionsQueryQueryResult = Apollo.QueryResult<PrescriptionsQueryQuery, PrescriptionsQueryQueryVariables>;
export const PrescriptionsInfoDocument = gql`
    query PrescriptionsInfo($consultantId: ID!) {
  prescriptionStatistics(consultantId: $consultantId) {
    id
    toBePrintedCount
    toBeSignedCount
  }
}
    `;

/**
 * __usePrescriptionsInfoQuery__
 *
 * To run a query within a React component, call `usePrescriptionsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionsInfoQuery({
 *   variables: {
 *      consultantId: // value for 'consultantId'
 *   },
 * });
 */
export function usePrescriptionsInfoQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionsInfoQuery, PrescriptionsInfoQueryVariables>) {
        return Apollo.useQuery<PrescriptionsInfoQuery, PrescriptionsInfoQueryVariables>(PrescriptionsInfoDocument, baseOptions);
      }
export function usePrescriptionsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionsInfoQuery, PrescriptionsInfoQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionsInfoQuery, PrescriptionsInfoQueryVariables>(PrescriptionsInfoDocument, baseOptions);
        }
export type PrescriptionsInfoQueryHookResult = ReturnType<typeof usePrescriptionsInfoQuery>;
export type PrescriptionsInfoLazyQueryHookResult = ReturnType<typeof usePrescriptionsInfoLazyQuery>;
export type PrescriptionsInfoQueryResult = Apollo.QueryResult<PrescriptionsInfoQuery, PrescriptionsInfoQueryVariables>;
export const RemovePrescriptionMutationDocument = gql`
    mutation RemovePrescriptionMutation($id: ID!) {
  removePrescription(id: $id)
}
    `;
export type RemovePrescriptionMutationMutationFn = Apollo.MutationFunction<RemovePrescriptionMutationMutation, RemovePrescriptionMutationMutationVariables>;

/**
 * __useRemovePrescriptionMutationMutation__
 *
 * To run a mutation, you first call `useRemovePrescriptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePrescriptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePrescriptionMutationMutation, { data, loading, error }] = useRemovePrescriptionMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePrescriptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemovePrescriptionMutationMutation, RemovePrescriptionMutationMutationVariables>) {
        return Apollo.useMutation<RemovePrescriptionMutationMutation, RemovePrescriptionMutationMutationVariables>(RemovePrescriptionMutationDocument, baseOptions);
      }
export type RemovePrescriptionMutationMutationHookResult = ReturnType<typeof useRemovePrescriptionMutationMutation>;
export type RemovePrescriptionMutationMutationResult = Apollo.MutationResult<RemovePrescriptionMutationMutation>;
export type RemovePrescriptionMutationMutationOptions = Apollo.BaseMutationOptions<RemovePrescriptionMutationMutation, RemovePrescriptionMutationMutationVariables>;
export const RemoveDrugFromPrescriptionMutationDocument = gql`
    mutation RemoveDrugFromPrescriptionMutation($id: ID!, $prescriptionDrugId: ID!) {
  removeDrugFromPrescription(id: $id, prescriptionDrugId: $prescriptionDrugId) {
    id
    createdAt
    prescriptionState
    drugs {
      prescriptionDrugId
      drugId
      drugName
      dosage
      duration
      pharmaceuticalForm
      strength
      quantity
      directions
      notes
    }
  }
}
    `;
export type RemoveDrugFromPrescriptionMutationMutationFn = Apollo.MutationFunction<RemoveDrugFromPrescriptionMutationMutation, RemoveDrugFromPrescriptionMutationMutationVariables>;

/**
 * __useRemoveDrugFromPrescriptionMutationMutation__
 *
 * To run a mutation, you first call `useRemoveDrugFromPrescriptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDrugFromPrescriptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDrugFromPrescriptionMutationMutation, { data, loading, error }] = useRemoveDrugFromPrescriptionMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      prescriptionDrugId: // value for 'prescriptionDrugId'
 *   },
 * });
 */
export function useRemoveDrugFromPrescriptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDrugFromPrescriptionMutationMutation, RemoveDrugFromPrescriptionMutationMutationVariables>) {
        return Apollo.useMutation<RemoveDrugFromPrescriptionMutationMutation, RemoveDrugFromPrescriptionMutationMutationVariables>(RemoveDrugFromPrescriptionMutationDocument, baseOptions);
      }
export type RemoveDrugFromPrescriptionMutationMutationHookResult = ReturnType<typeof useRemoveDrugFromPrescriptionMutationMutation>;
export type RemoveDrugFromPrescriptionMutationMutationResult = Apollo.MutationResult<RemoveDrugFromPrescriptionMutationMutation>;
export type RemoveDrugFromPrescriptionMutationMutationOptions = Apollo.BaseMutationOptions<RemoveDrugFromPrescriptionMutationMutation, RemoveDrugFromPrescriptionMutationMutationVariables>;
export const PrescriptionChangeStateFromConsultationMutationDocument = gql`
    mutation PrescriptionChangeStateFromConsultationMutation($id: ID!, $reason: String, $action: PrescriptionStateActionType!, $region: String!) {
  prescriptionChangeState(id: $id, reason: $reason, action: $action, region: $region) {
    id
    createdAt
    prescriptionState
    actions
  }
}
    `;
export type PrescriptionChangeStateFromConsultationMutationMutationFn = Apollo.MutationFunction<PrescriptionChangeStateFromConsultationMutationMutation, PrescriptionChangeStateFromConsultationMutationMutationVariables>;

/**
 * __usePrescriptionChangeStateFromConsultationMutationMutation__
 *
 * To run a mutation, you first call `usePrescriptionChangeStateFromConsultationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionChangeStateFromConsultationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prescriptionChangeStateFromConsultationMutationMutation, { data, loading, error }] = usePrescriptionChangeStateFromConsultationMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      action: // value for 'action'
 *      region: // value for 'region'
 *   },
 * });
 */
export function usePrescriptionChangeStateFromConsultationMutationMutation(baseOptions?: Apollo.MutationHookOptions<PrescriptionChangeStateFromConsultationMutationMutation, PrescriptionChangeStateFromConsultationMutationMutationVariables>) {
        return Apollo.useMutation<PrescriptionChangeStateFromConsultationMutationMutation, PrescriptionChangeStateFromConsultationMutationMutationVariables>(PrescriptionChangeStateFromConsultationMutationDocument, baseOptions);
      }
export type PrescriptionChangeStateFromConsultationMutationMutationHookResult = ReturnType<typeof usePrescriptionChangeStateFromConsultationMutationMutation>;
export type PrescriptionChangeStateFromConsultationMutationMutationResult = Apollo.MutationResult<PrescriptionChangeStateFromConsultationMutationMutation>;
export type PrescriptionChangeStateFromConsultationMutationMutationOptions = Apollo.BaseMutationOptions<PrescriptionChangeStateFromConsultationMutationMutation, PrescriptionChangeStateFromConsultationMutationMutationVariables>;
export const ValidateDrugMutationDocument = gql`
    mutation ValidateDrugMutation($patientId: ID!, $drug: PrescriptionDrugInput!, $consultationId: ID, $region: String!) {
  validateDrug(patientId: $patientId, drug: $drug, consultationId: $consultationId, region: $region) {
    warnings {
      action
      message
      allowPrescribing
    }
  }
}
    `;
export type ValidateDrugMutationMutationFn = Apollo.MutationFunction<ValidateDrugMutationMutation, ValidateDrugMutationMutationVariables>;

/**
 * __useValidateDrugMutationMutation__
 *
 * To run a mutation, you first call `useValidateDrugMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateDrugMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateDrugMutationMutation, { data, loading, error }] = useValidateDrugMutationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      drug: // value for 'drug'
 *      consultationId: // value for 'consultationId'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useValidateDrugMutationMutation(baseOptions?: Apollo.MutationHookOptions<ValidateDrugMutationMutation, ValidateDrugMutationMutationVariables>) {
        return Apollo.useMutation<ValidateDrugMutationMutation, ValidateDrugMutationMutationVariables>(ValidateDrugMutationDocument, baseOptions);
      }
export type ValidateDrugMutationMutationHookResult = ReturnType<typeof useValidateDrugMutationMutation>;
export type ValidateDrugMutationMutationResult = Apollo.MutationResult<ValidateDrugMutationMutation>;
export type ValidateDrugMutationMutationOptions = Apollo.BaseMutationOptions<ValidateDrugMutationMutation, ValidateDrugMutationMutationVariables>;
export const AddDrugToPrescriptionMutationDocument = gql`
    mutation AddDrugToPrescriptionMutation($id: ID!, $drug: PrescriptionDrugInput!, $region: String!) {
  addDrugToPrescription(id: $id, drug: $drug, region: $region) {
    ...PrescriptionFieldsFragment
  }
}
    ${PrescriptionFieldsFragmentFragmentDoc}`;
export type AddDrugToPrescriptionMutationMutationFn = Apollo.MutationFunction<AddDrugToPrescriptionMutationMutation, AddDrugToPrescriptionMutationMutationVariables>;

/**
 * __useAddDrugToPrescriptionMutationMutation__
 *
 * To run a mutation, you first call `useAddDrugToPrescriptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDrugToPrescriptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDrugToPrescriptionMutationMutation, { data, loading, error }] = useAddDrugToPrescriptionMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      drug: // value for 'drug'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useAddDrugToPrescriptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddDrugToPrescriptionMutationMutation, AddDrugToPrescriptionMutationMutationVariables>) {
        return Apollo.useMutation<AddDrugToPrescriptionMutationMutation, AddDrugToPrescriptionMutationMutationVariables>(AddDrugToPrescriptionMutationDocument, baseOptions);
      }
export type AddDrugToPrescriptionMutationMutationHookResult = ReturnType<typeof useAddDrugToPrescriptionMutationMutation>;
export type AddDrugToPrescriptionMutationMutationResult = Apollo.MutationResult<AddDrugToPrescriptionMutationMutation>;
export type AddDrugToPrescriptionMutationMutationOptions = Apollo.BaseMutationOptions<AddDrugToPrescriptionMutationMutation, AddDrugToPrescriptionMutationMutationVariables>;
export const CreatePrescriptionFromConsultationMutationDocument = gql`
    mutation CreatePrescriptionFromConsultationMutation($consultationId: ID!, $patientId: ID!, $drugs: [PrescriptionDrugInput], $consumerNetwork: String!, $region: String!) {
  createPrescription(consultationId: $consultationId, patientId: $patientId, drugs: $drugs, consumerNetwork: $consumerNetwork, region: $region) {
    ...PrescriptionFieldsFragment
  }
}
    ${PrescriptionFieldsFragmentFragmentDoc}`;
export type CreatePrescriptionFromConsultationMutationMutationFn = Apollo.MutationFunction<CreatePrescriptionFromConsultationMutationMutation, CreatePrescriptionFromConsultationMutationMutationVariables>;

/**
 * __useCreatePrescriptionFromConsultationMutationMutation__
 *
 * To run a mutation, you first call `useCreatePrescriptionFromConsultationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrescriptionFromConsultationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrescriptionFromConsultationMutationMutation, { data, loading, error }] = useCreatePrescriptionFromConsultationMutationMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      patientId: // value for 'patientId'
 *      drugs: // value for 'drugs'
 *      consumerNetwork: // value for 'consumerNetwork'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCreatePrescriptionFromConsultationMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrescriptionFromConsultationMutationMutation, CreatePrescriptionFromConsultationMutationMutationVariables>) {
        return Apollo.useMutation<CreatePrescriptionFromConsultationMutationMutation, CreatePrescriptionFromConsultationMutationMutationVariables>(CreatePrescriptionFromConsultationMutationDocument, baseOptions);
      }
export type CreatePrescriptionFromConsultationMutationMutationHookResult = ReturnType<typeof useCreatePrescriptionFromConsultationMutationMutation>;
export type CreatePrescriptionFromConsultationMutationMutationResult = Apollo.MutationResult<CreatePrescriptionFromConsultationMutationMutation>;
export type CreatePrescriptionFromConsultationMutationMutationOptions = Apollo.BaseMutationOptions<CreatePrescriptionFromConsultationMutationMutation, CreatePrescriptionFromConsultationMutationMutationVariables>;
export const DuplicatePrescriptionMutationDocument = gql`
    mutation DuplicatePrescriptionMutation($prescriptionId: ID!) {
  duplicatePrescription(prescriptionId: $prescriptionId) {
    ...PrescriptionFieldsFragment
  }
}
    ${PrescriptionFieldsFragmentFragmentDoc}`;
export type DuplicatePrescriptionMutationMutationFn = Apollo.MutationFunction<DuplicatePrescriptionMutationMutation, DuplicatePrescriptionMutationMutationVariables>;

/**
 * __useDuplicatePrescriptionMutationMutation__
 *
 * To run a mutation, you first call `useDuplicatePrescriptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePrescriptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePrescriptionMutationMutation, { data, loading, error }] = useDuplicatePrescriptionMutationMutation({
 *   variables: {
 *      prescriptionId: // value for 'prescriptionId'
 *   },
 * });
 */
export function useDuplicatePrescriptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePrescriptionMutationMutation, DuplicatePrescriptionMutationMutationVariables>) {
        return Apollo.useMutation<DuplicatePrescriptionMutationMutation, DuplicatePrescriptionMutationMutationVariables>(DuplicatePrescriptionMutationDocument, baseOptions);
      }
export type DuplicatePrescriptionMutationMutationHookResult = ReturnType<typeof useDuplicatePrescriptionMutationMutation>;
export type DuplicatePrescriptionMutationMutationResult = Apollo.MutationResult<DuplicatePrescriptionMutationMutation>;
export type DuplicatePrescriptionMutationMutationOptions = Apollo.BaseMutationOptions<DuplicatePrescriptionMutationMutation, DuplicatePrescriptionMutationMutationVariables>;
export const PrescriptionDocument = gql`
    query Prescription($id: ID!) {
  consultation(id: $id) {
    id
    region {
      id
      iso_code
    }
    consultationNote {
      id
      assessmentCodes {
        code
        term
        length
        offset
      }
    }
    drugDosageOptions {
      shortcut
      description
    }
    patient {
      id
      canCreatePrescriptionPdf {
        valid
        errorCodes
      }
    }
    prescriptions {
      ...PrescriptionFieldsFragment
    }
  }
}
    ${PrescriptionFieldsFragmentFragmentDoc}`;

/**
 * __usePrescriptionQuery__
 *
 * To run a query within a React component, call `usePrescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrescriptionQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionQuery, PrescriptionQueryVariables>) {
        return Apollo.useQuery<PrescriptionQuery, PrescriptionQueryVariables>(PrescriptionDocument, baseOptions);
      }
export function usePrescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionQuery, PrescriptionQueryVariables>) {
          return Apollo.useLazyQuery<PrescriptionQuery, PrescriptionQueryVariables>(PrescriptionDocument, baseOptions);
        }
export type PrescriptionQueryHookResult = ReturnType<typeof usePrescriptionQuery>;
export type PrescriptionLazyQueryHookResult = ReturnType<typeof usePrescriptionLazyQuery>;
export type PrescriptionQueryResult = Apollo.QueryResult<PrescriptionQuery, PrescriptionQueryVariables>;
export const GetClinicalNotesForPrescriptionsQueryDocument = gql`
    query GetClinicalNotesForPrescriptionsQuery($iris: [ClinicalCodeInput]!, $resolveDrugs: Boolean) {
  getClinicalNotes(iris: $iris, resolveDrugs: $resolveDrugs) {
    furtherActionNotes
    managementNotes
    iri
    name
    guidelines
    treatmentPlans {
      ageGroup
      penicillinAllergicOption
      prescription {
        drugId
        drugName
        strength
        duration
        quantity
        dosage
        directions
        guidance
      }
    }
  }
}
    `;

/**
 * __useGetClinicalNotesForPrescriptionsQueryQuery__
 *
 * To run a query within a React component, call `useGetClinicalNotesForPrescriptionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicalNotesForPrescriptionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicalNotesForPrescriptionsQueryQuery({
 *   variables: {
 *      iris: // value for 'iris'
 *      resolveDrugs: // value for 'resolveDrugs'
 *   },
 * });
 */
export function useGetClinicalNotesForPrescriptionsQueryQuery(baseOptions: Apollo.QueryHookOptions<GetClinicalNotesForPrescriptionsQueryQuery, GetClinicalNotesForPrescriptionsQueryQueryVariables>) {
        return Apollo.useQuery<GetClinicalNotesForPrescriptionsQueryQuery, GetClinicalNotesForPrescriptionsQueryQueryVariables>(GetClinicalNotesForPrescriptionsQueryDocument, baseOptions);
      }
export function useGetClinicalNotesForPrescriptionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicalNotesForPrescriptionsQueryQuery, GetClinicalNotesForPrescriptionsQueryQueryVariables>) {
          return Apollo.useLazyQuery<GetClinicalNotesForPrescriptionsQueryQuery, GetClinicalNotesForPrescriptionsQueryQueryVariables>(GetClinicalNotesForPrescriptionsQueryDocument, baseOptions);
        }
export type GetClinicalNotesForPrescriptionsQueryQueryHookResult = ReturnType<typeof useGetClinicalNotesForPrescriptionsQueryQuery>;
export type GetClinicalNotesForPrescriptionsQueryLazyQueryHookResult = ReturnType<typeof useGetClinicalNotesForPrescriptionsQueryLazyQuery>;
export type GetClinicalNotesForPrescriptionsQueryQueryResult = Apollo.QueryResult<GetClinicalNotesForPrescriptionsQueryQuery, GetClinicalNotesForPrescriptionsQueryQueryVariables>;
export const GetPrescriptionQueryDocument = gql`
    query GetPrescriptionQuery($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    patient {
      id
    }
    prescriptions {
      ...PrescriptionFieldsFragment
    }
  }
}
    ${PrescriptionFieldsFragmentFragmentDoc}`;

/**
 * __useGetPrescriptionQueryQuery__
 *
 * To run a query within a React component, call `useGetPrescriptionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrescriptionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrescriptionQueryQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useGetPrescriptionQueryQuery(baseOptions: Apollo.QueryHookOptions<GetPrescriptionQueryQuery, GetPrescriptionQueryQueryVariables>) {
        return Apollo.useQuery<GetPrescriptionQueryQuery, GetPrescriptionQueryQueryVariables>(GetPrescriptionQueryDocument, baseOptions);
      }
export function useGetPrescriptionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrescriptionQueryQuery, GetPrescriptionQueryQueryVariables>) {
          return Apollo.useLazyQuery<GetPrescriptionQueryQuery, GetPrescriptionQueryQueryVariables>(GetPrescriptionQueryDocument, baseOptions);
        }
export type GetPrescriptionQueryQueryHookResult = ReturnType<typeof useGetPrescriptionQueryQuery>;
export type GetPrescriptionQueryLazyQueryHookResult = ReturnType<typeof useGetPrescriptionQueryLazyQuery>;
export type GetPrescriptionQueryQueryResult = Apollo.QueryResult<GetPrescriptionQueryQuery, GetPrescriptionQueryQueryVariables>;
export const ProblemsListDocument = gql`
    query ProblemsList($patientId: ID!, $eventTypes: [TimelineEventType] = [Consultation], $consultationStatuses: [ConsultationStatus] = [confirmed, completed]) {
  patient(id: $patientId) {
    id
    history(eventTypes: $eventTypes, dangerousConsultationStatuses: $consultationStatuses) {
      ... on Consultation {
        id
        eventType
        status
        scheduledTime
        consultantId
        consultationNote {
          id
          assessment
          assessmentCodes {
            code
            term
            length
            offset
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProblemsListQuery__
 *
 * To run a query within a React component, call `useProblemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemsListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      eventTypes: // value for 'eventTypes'
 *      consultationStatuses: // value for 'consultationStatuses'
 *   },
 * });
 */
export function useProblemsListQuery(baseOptions: Apollo.QueryHookOptions<ProblemsListQuery, ProblemsListQueryVariables>) {
        return Apollo.useQuery<ProblemsListQuery, ProblemsListQueryVariables>(ProblemsListDocument, baseOptions);
      }
export function useProblemsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProblemsListQuery, ProblemsListQueryVariables>) {
          return Apollo.useLazyQuery<ProblemsListQuery, ProblemsListQueryVariables>(ProblemsListDocument, baseOptions);
        }
export type ProblemsListQueryHookResult = ReturnType<typeof useProblemsListQuery>;
export type ProblemsListLazyQueryHookResult = ReturnType<typeof useProblemsListLazyQuery>;
export type ProblemsListQueryResult = Apollo.QueryResult<ProblemsListQuery, ProblemsListQueryVariables>;
export const CreateReferralMutationDocument = gql`
    mutation CreateReferralMutation($consultationId: ID!, $input: ConsultationReferralInput!) {
  createConsultationReferral(consultationId: $consultationId, input: $input) {
    id
    consultationId
    specialismId
    specialismCategoryId
    specialismName
    updatedAt
    urgent
    comments {
      id
      note
    }
    externalSpecialist {
      id
      name
    }
  }
}
    `;
export type CreateReferralMutationMutationFn = Apollo.MutationFunction<CreateReferralMutationMutation, CreateReferralMutationMutationVariables>;

/**
 * __useCreateReferralMutationMutation__
 *
 * To run a mutation, you first call `useCreateReferralMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralMutationMutation, { data, loading, error }] = useCreateReferralMutationMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferralMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReferralMutationMutation, CreateReferralMutationMutationVariables>) {
        return Apollo.useMutation<CreateReferralMutationMutation, CreateReferralMutationMutationVariables>(CreateReferralMutationDocument, baseOptions);
      }
export type CreateReferralMutationMutationHookResult = ReturnType<typeof useCreateReferralMutationMutation>;
export type CreateReferralMutationMutationResult = Apollo.MutationResult<CreateReferralMutationMutation>;
export type CreateReferralMutationMutationOptions = Apollo.BaseMutationOptions<CreateReferralMutationMutation, CreateReferralMutationMutationVariables>;
export const UpdateReferralMutationDocument = gql`
    mutation UpdateReferralMutation($referralId: ID!, $input: ConsultationReferralInput!) {
  updateReferral(referralId: $referralId, input: $input) {
    id
    consultationId
    specialismId
    specialismCategoryId
    specialismName
    updatedAt
    urgent
  }
}
    `;
export type UpdateReferralMutationMutationFn = Apollo.MutationFunction<UpdateReferralMutationMutation, UpdateReferralMutationMutationVariables>;

/**
 * __useUpdateReferralMutationMutation__
 *
 * To run a mutation, you first call `useUpdateReferralMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralMutationMutation, { data, loading, error }] = useUpdateReferralMutationMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReferralMutationMutation, UpdateReferralMutationMutationVariables>) {
        return Apollo.useMutation<UpdateReferralMutationMutation, UpdateReferralMutationMutationVariables>(UpdateReferralMutationDocument, baseOptions);
      }
export type UpdateReferralMutationMutationHookResult = ReturnType<typeof useUpdateReferralMutationMutation>;
export type UpdateReferralMutationMutationResult = Apollo.MutationResult<UpdateReferralMutationMutation>;
export type UpdateReferralMutationMutationOptions = Apollo.BaseMutationOptions<UpdateReferralMutationMutation, UpdateReferralMutationMutationVariables>;
export const SpecialismsDocument = gql`
    query Specialisms($consultationId: ID!) {
  specialismCategories(id: $consultationId) {
    name
    id
    specialisms {
      name
      id
    }
  }
}
    `;

/**
 * __useSpecialismsQuery__
 *
 * To run a query within a React component, call `useSpecialismsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialismsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialismsQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useSpecialismsQuery(baseOptions: Apollo.QueryHookOptions<SpecialismsQuery, SpecialismsQueryVariables>) {
        return Apollo.useQuery<SpecialismsQuery, SpecialismsQueryVariables>(SpecialismsDocument, baseOptions);
      }
export function useSpecialismsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialismsQuery, SpecialismsQueryVariables>) {
          return Apollo.useLazyQuery<SpecialismsQuery, SpecialismsQueryVariables>(SpecialismsDocument, baseOptions);
        }
export type SpecialismsQueryHookResult = ReturnType<typeof useSpecialismsQuery>;
export type SpecialismsLazyQueryHookResult = ReturnType<typeof useSpecialismsLazyQuery>;
export type SpecialismsQueryResult = Apollo.QueryResult<SpecialismsQuery, SpecialismsQueryVariables>;
export const ReferralsDocument = gql`
    query Referrals($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    patient {
      id
      address_post_code
    }
    region {
      id
      iso_code
    }
    referrals {
      id
      specialismCategoryId
      specialismId
      specialismName
      urgent
      comments {
        id
        note
      }
    }
  }
}
    `;

/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useReferralsQuery(baseOptions: Apollo.QueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
        return Apollo.useQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, baseOptions);
      }
export function useReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
          return Apollo.useLazyQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, baseOptions);
        }
export type ReferralsQueryHookResult = ReturnType<typeof useReferralsQuery>;
export type ReferralsLazyQueryHookResult = ReturnType<typeof useReferralsLazyQuery>;
export type ReferralsQueryResult = Apollo.QueryResult<ReferralsQuery, ReferralsQueryVariables>;
export const ReferralsSpecialistsQueryDocument = gql`
    query ReferralsSpecialistsQuery($input: ReferralResourceInput) {
  referralSearch(input: $input) {
    location {
      name
      address {
        line
        city
        state
        postalCode
        country
      }
    }
    specialty {
      coding {
        system
        display
      }
    }
    telecom {
      system
      use
      value
      rank
    }
    practitioner {
      id
      name
      gender
      language
    }
  }
}
    `;

/**
 * __useReferralsSpecialistsQueryQuery__
 *
 * To run a query within a React component, call `useReferralsSpecialistsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsSpecialistsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsSpecialistsQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReferralsSpecialistsQueryQuery(baseOptions?: Apollo.QueryHookOptions<ReferralsSpecialistsQueryQuery, ReferralsSpecialistsQueryQueryVariables>) {
        return Apollo.useQuery<ReferralsSpecialistsQueryQuery, ReferralsSpecialistsQueryQueryVariables>(ReferralsSpecialistsQueryDocument, baseOptions);
      }
export function useReferralsSpecialistsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsSpecialistsQueryQuery, ReferralsSpecialistsQueryQueryVariables>) {
          return Apollo.useLazyQuery<ReferralsSpecialistsQueryQuery, ReferralsSpecialistsQueryQueryVariables>(ReferralsSpecialistsQueryDocument, baseOptions);
        }
export type ReferralsSpecialistsQueryQueryHookResult = ReturnType<typeof useReferralsSpecialistsQueryQuery>;
export type ReferralsSpecialistsQueryLazyQueryHookResult = ReturnType<typeof useReferralsSpecialistsQueryLazyQuery>;
export type ReferralsSpecialistsQueryQueryResult = Apollo.QueryResult<ReferralsSpecialistsQueryQuery, ReferralsSpecialistsQueryQueryVariables>;
export const RemoveReferralDocument = gql`
    mutation RemoveReferral($referralId: ID!) {
  removeReferral(referralId: $referralId)
}
    `;
export type RemoveReferralMutationFn = Apollo.MutationFunction<RemoveReferralMutation, RemoveReferralMutationVariables>;

/**
 * __useRemoveReferralMutation__
 *
 * To run a mutation, you first call `useRemoveReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReferralMutation, { data, loading, error }] = useRemoveReferralMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useRemoveReferralMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReferralMutation, RemoveReferralMutationVariables>) {
        return Apollo.useMutation<RemoveReferralMutation, RemoveReferralMutationVariables>(RemoveReferralDocument, baseOptions);
      }
export type RemoveReferralMutationHookResult = ReturnType<typeof useRemoveReferralMutation>;
export type RemoveReferralMutationResult = Apollo.MutationResult<RemoveReferralMutation>;
export type RemoveReferralMutationOptions = Apollo.BaseMutationOptions<RemoveReferralMutation, RemoveReferralMutationVariables>;
export const ReferralsSectionDocument = gql`
    query ReferralsSection($id: ID!) {
  consultation(id: $id) {
    id
    ...ReferralsFragment
  }
}
    ${ReferralsFragmentFragmentDoc}`;

/**
 * __useReferralsSectionQuery__
 *
 * To run a query within a React component, call `useReferralsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferralsSectionQuery(baseOptions: Apollo.QueryHookOptions<ReferralsSectionQuery, ReferralsSectionQueryVariables>) {
        return Apollo.useQuery<ReferralsSectionQuery, ReferralsSectionQueryVariables>(ReferralsSectionDocument, baseOptions);
      }
export function useReferralsSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsSectionQuery, ReferralsSectionQueryVariables>) {
          return Apollo.useLazyQuery<ReferralsSectionQuery, ReferralsSectionQueryVariables>(ReferralsSectionDocument, baseOptions);
        }
export type ReferralsSectionQueryHookResult = ReturnType<typeof useReferralsSectionQuery>;
export type ReferralsSectionLazyQueryHookResult = ReturnType<typeof useReferralsSectionLazyQuery>;
export type ReferralsSectionQueryResult = Apollo.QueryResult<ReferralsSectionQuery, ReferralsSectionQueryVariables>;
export const ReferralsSummaryDocument = gql`
    query ReferralsSummary($id: ID!) {
  consultation(id: $id) {
    id
    ...ReferralsFragment
  }
}
    ${ReferralsFragmentFragmentDoc}`;

/**
 * __useReferralsSummaryQuery__
 *
 * To run a query within a React component, call `useReferralsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferralsSummaryQuery(baseOptions: Apollo.QueryHookOptions<ReferralsSummaryQuery, ReferralsSummaryQueryVariables>) {
        return Apollo.useQuery<ReferralsSummaryQuery, ReferralsSummaryQueryVariables>(ReferralsSummaryDocument, baseOptions);
      }
export function useReferralsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsSummaryQuery, ReferralsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<ReferralsSummaryQuery, ReferralsSummaryQueryVariables>(ReferralsSummaryDocument, baseOptions);
        }
export type ReferralsSummaryQueryHookResult = ReturnType<typeof useReferralsSummaryQuery>;
export type ReferralsSummaryLazyQueryHookResult = ReturnType<typeof useReferralsSummaryLazyQuery>;
export type ReferralsSummaryQueryResult = Apollo.QueryResult<ReferralsSummaryQuery, ReferralsSummaryQueryVariables>;
export const ReleaseNotesDocument = gql`
    query ReleaseNotes($countryCode: String!) {
  cp3ReleaseNotes(countryCode: $countryCode) {
    id
    countryCode {
      name
      codename
    }
    versionNumber
    releaseDate
    changes
    title
  }
}
    `;

/**
 * __useReleaseNotesQuery__
 *
 * To run a query within a React component, call `useReleaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseNotesQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useReleaseNotesQuery(baseOptions: Apollo.QueryHookOptions<ReleaseNotesQuery, ReleaseNotesQueryVariables>) {
        return Apollo.useQuery<ReleaseNotesQuery, ReleaseNotesQueryVariables>(ReleaseNotesDocument, baseOptions);
      }
export function useReleaseNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleaseNotesQuery, ReleaseNotesQueryVariables>) {
          return Apollo.useLazyQuery<ReleaseNotesQuery, ReleaseNotesQueryVariables>(ReleaseNotesDocument, baseOptions);
        }
export type ReleaseNotesQueryHookResult = ReturnType<typeof useReleaseNotesQuery>;
export type ReleaseNotesLazyQueryHookResult = ReturnType<typeof useReleaseNotesLazyQuery>;
export type ReleaseNotesQueryResult = Apollo.QueryResult<ReleaseNotesQuery, ReleaseNotesQueryVariables>;
export const IncompleteConsultationsDocument = gql`
    query IncompleteConsultations($searchCriteria: ConsultationSearchCriteria) {
  consultationsv2(searchCriteria: $searchCriteria) {
    totalItems
    items {
      id
    }
  }
}
    `;

/**
 * __useIncompleteConsultationsQuery__
 *
 * To run a query within a React component, call `useIncompleteConsultationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncompleteConsultationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncompleteConsultationsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useIncompleteConsultationsQuery(baseOptions?: Apollo.QueryHookOptions<IncompleteConsultationsQuery, IncompleteConsultationsQueryVariables>) {
        return Apollo.useQuery<IncompleteConsultationsQuery, IncompleteConsultationsQueryVariables>(IncompleteConsultationsDocument, baseOptions);
      }
export function useIncompleteConsultationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncompleteConsultationsQuery, IncompleteConsultationsQueryVariables>) {
          return Apollo.useLazyQuery<IncompleteConsultationsQuery, IncompleteConsultationsQueryVariables>(IncompleteConsultationsDocument, baseOptions);
        }
export type IncompleteConsultationsQueryHookResult = ReturnType<typeof useIncompleteConsultationsQuery>;
export type IncompleteConsultationsLazyQueryHookResult = ReturnType<typeof useIncompleteConsultationsLazyQuery>;
export type IncompleteConsultationsQueryResult = Apollo.QueryResult<IncompleteConsultationsQuery, IncompleteConsultationsQueryVariables>;
export const ConsultationScheduleItemDetailsDocument = gql`
    query ConsultationScheduleItemDetails($id: ID!) {
  consultation(id: $id) {
    ...ConsultationScheduleItemDetails
  }
}
    ${ConsultationScheduleItemDetailsFragmentDoc}`;

/**
 * __useConsultationScheduleItemDetailsQuery__
 *
 * To run a query within a React component, call `useConsultationScheduleItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationScheduleItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationScheduleItemDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultationScheduleItemDetailsQuery(baseOptions: Apollo.QueryHookOptions<ConsultationScheduleItemDetailsQuery, ConsultationScheduleItemDetailsQueryVariables>) {
        return Apollo.useQuery<ConsultationScheduleItemDetailsQuery, ConsultationScheduleItemDetailsQueryVariables>(ConsultationScheduleItemDetailsDocument, baseOptions);
      }
export function useConsultationScheduleItemDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationScheduleItemDetailsQuery, ConsultationScheduleItemDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationScheduleItemDetailsQuery, ConsultationScheduleItemDetailsQueryVariables>(ConsultationScheduleItemDetailsDocument, baseOptions);
        }
export type ConsultationScheduleItemDetailsQueryHookResult = ReturnType<typeof useConsultationScheduleItemDetailsQuery>;
export type ConsultationScheduleItemDetailsLazyQueryHookResult = ReturnType<typeof useConsultationScheduleItemDetailsLazyQuery>;
export type ConsultationScheduleItemDetailsQueryResult = Apollo.QueryResult<ConsultationScheduleItemDetailsQuery, ConsultationScheduleItemDetailsQueryVariables>;
export const ClinicianAvailabilityShiftsDocument = gql`
    query ClinicianAvailabilityShifts($consultantId: ID!, $startDate: Date!, $endDate: Date!) {
  clinicianAvailabilityShifts(consultantId: $consultantId, startDate: $startDate, endDate: $endDate) {
    items {
      id
      shift_type
      shift_start_time
      shift_end_time
    }
  }
}
    `;

/**
 * __useClinicianAvailabilityShiftsQuery__
 *
 * To run a query within a React component, call `useClinicianAvailabilityShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicianAvailabilityShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicianAvailabilityShiftsQuery({
 *   variables: {
 *      consultantId: // value for 'consultantId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useClinicianAvailabilityShiftsQuery(baseOptions: Apollo.QueryHookOptions<ClinicianAvailabilityShiftsQuery, ClinicianAvailabilityShiftsQueryVariables>) {
        return Apollo.useQuery<ClinicianAvailabilityShiftsQuery, ClinicianAvailabilityShiftsQueryVariables>(ClinicianAvailabilityShiftsDocument, baseOptions);
      }
export function useClinicianAvailabilityShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClinicianAvailabilityShiftsQuery, ClinicianAvailabilityShiftsQueryVariables>) {
          return Apollo.useLazyQuery<ClinicianAvailabilityShiftsQuery, ClinicianAvailabilityShiftsQueryVariables>(ClinicianAvailabilityShiftsDocument, baseOptions);
        }
export type ClinicianAvailabilityShiftsQueryHookResult = ReturnType<typeof useClinicianAvailabilityShiftsQuery>;
export type ClinicianAvailabilityShiftsLazyQueryHookResult = ReturnType<typeof useClinicianAvailabilityShiftsLazyQuery>;
export type ClinicianAvailabilityShiftsQueryResult = Apollo.QueryResult<ClinicianAvailabilityShiftsQuery, ClinicianAvailabilityShiftsQueryVariables>;
export const MonthlyCalendarScheduleDocument = gql`
    query MonthlyCalendarSchedule($consultantId: ID!, $date: String, $timezone: String) {
  monthlyCalendarScheduleV2(consultantId: $consultantId, date: $date, timezone: $timezone) {
    shifts
    incompleteConsultations
  }
}
    `;

/**
 * __useMonthlyCalendarScheduleQuery__
 *
 * To run a query within a React component, call `useMonthlyCalendarScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyCalendarScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyCalendarScheduleQuery({
 *   variables: {
 *      consultantId: // value for 'consultantId'
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useMonthlyCalendarScheduleQuery(baseOptions: Apollo.QueryHookOptions<MonthlyCalendarScheduleQuery, MonthlyCalendarScheduleQueryVariables>) {
        return Apollo.useQuery<MonthlyCalendarScheduleQuery, MonthlyCalendarScheduleQueryVariables>(MonthlyCalendarScheduleDocument, baseOptions);
      }
export function useMonthlyCalendarScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyCalendarScheduleQuery, MonthlyCalendarScheduleQueryVariables>) {
          return Apollo.useLazyQuery<MonthlyCalendarScheduleQuery, MonthlyCalendarScheduleQueryVariables>(MonthlyCalendarScheduleDocument, baseOptions);
        }
export type MonthlyCalendarScheduleQueryHookResult = ReturnType<typeof useMonthlyCalendarScheduleQuery>;
export type MonthlyCalendarScheduleLazyQueryHookResult = ReturnType<typeof useMonthlyCalendarScheduleLazyQuery>;
export type MonthlyCalendarScheduleQueryResult = Apollo.QueryResult<MonthlyCalendarScheduleQuery, MonthlyCalendarScheduleQueryVariables>;
export const ConsultationScheduleDocument = gql`
    query ConsultationSchedule($consultants: [ID!]!, $fromDate: String!, $toDate: String!) {
  consultations(consultants: $consultants, fromDate: $fromDate, toDate: $toDate) {
    ...ConsultationScheduleItemBase
  }
}
    ${ConsultationScheduleItemBaseFragmentDoc}`;

/**
 * __useConsultationScheduleQuery__
 *
 * To run a query within a React component, call `useConsultationScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultationScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultationScheduleQuery({
 *   variables: {
 *      consultants: // value for 'consultants'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useConsultationScheduleQuery(baseOptions: Apollo.QueryHookOptions<ConsultationScheduleQuery, ConsultationScheduleQueryVariables>) {
        return Apollo.useQuery<ConsultationScheduleQuery, ConsultationScheduleQueryVariables>(ConsultationScheduleDocument, baseOptions);
      }
export function useConsultationScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultationScheduleQuery, ConsultationScheduleQueryVariables>) {
          return Apollo.useLazyQuery<ConsultationScheduleQuery, ConsultationScheduleQueryVariables>(ConsultationScheduleDocument, baseOptions);
        }
export type ConsultationScheduleQueryHookResult = ReturnType<typeof useConsultationScheduleQuery>;
export type ConsultationScheduleLazyQueryHookResult = ReturnType<typeof useConsultationScheduleLazyQuery>;
export type ConsultationScheduleQueryResult = Apollo.QueryResult<ConsultationScheduleQuery, ConsultationScheduleQueryVariables>;
export const FullConsultationScheduleDocument = gql`
    query FullConsultationSchedule($consultants: [ID!]!, $fromDate: String!, $toDate: String!) {
  consultations(consultants: $consultants, fromDate: $fromDate, toDate: $toDate) {
    ...ScheduleConsultation
  }
}
    ${ScheduleConsultationFragmentDoc}`;

/**
 * __useFullConsultationScheduleQuery__
 *
 * To run a query within a React component, call `useFullConsultationScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullConsultationScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullConsultationScheduleQuery({
 *   variables: {
 *      consultants: // value for 'consultants'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useFullConsultationScheduleQuery(baseOptions: Apollo.QueryHookOptions<FullConsultationScheduleQuery, FullConsultationScheduleQueryVariables>) {
        return Apollo.useQuery<FullConsultationScheduleQuery, FullConsultationScheduleQueryVariables>(FullConsultationScheduleDocument, baseOptions);
      }
export function useFullConsultationScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullConsultationScheduleQuery, FullConsultationScheduleQueryVariables>) {
          return Apollo.useLazyQuery<FullConsultationScheduleQuery, FullConsultationScheduleQueryVariables>(FullConsultationScheduleDocument, baseOptions);
        }
export type FullConsultationScheduleQueryHookResult = ReturnType<typeof useFullConsultationScheduleQuery>;
export type FullConsultationScheduleLazyQueryHookResult = ReturnType<typeof useFullConsultationScheduleLazyQuery>;
export type FullConsultationScheduleQueryResult = Apollo.QueryResult<FullConsultationScheduleQuery, FullConsultationScheduleQueryVariables>;
export const CreateSickNoteMutationDocument = gql`
    mutation CreateSickNoteMutation($consultationId: ID!, $input: ConsultationSickNoteInput!) {
  createConsultationSickNote(consultationId: $consultationId, input: $input) {
    id
    reason
    startDate
    endDate
    reason
  }
}
    `;
export type CreateSickNoteMutationMutationFn = Apollo.MutationFunction<CreateSickNoteMutationMutation, CreateSickNoteMutationMutationVariables>;

/**
 * __useCreateSickNoteMutationMutation__
 *
 * To run a mutation, you first call `useCreateSickNoteMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSickNoteMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSickNoteMutationMutation, { data, loading, error }] = useCreateSickNoteMutationMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSickNoteMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSickNoteMutationMutation, CreateSickNoteMutationMutationVariables>) {
        return Apollo.useMutation<CreateSickNoteMutationMutation, CreateSickNoteMutationMutationVariables>(CreateSickNoteMutationDocument, baseOptions);
      }
export type CreateSickNoteMutationMutationHookResult = ReturnType<typeof useCreateSickNoteMutationMutation>;
export type CreateSickNoteMutationMutationResult = Apollo.MutationResult<CreateSickNoteMutationMutation>;
export type CreateSickNoteMutationMutationOptions = Apollo.BaseMutationOptions<CreateSickNoteMutationMutation, CreateSickNoteMutationMutationVariables>;
export const UpdateSickNoteMutationDocument = gql`
    mutation UpdateSickNoteMutation($consultationId: ID!, $sickNoteId: ID!, $input: ConsultationSickNoteInput!) {
  updateConsultationSickNote(consultationId: $consultationId, sickNoteId: $sickNoteId, input: $input) {
    id
    reason
    startDate
    endDate
    reason
  }
}
    `;
export type UpdateSickNoteMutationMutationFn = Apollo.MutationFunction<UpdateSickNoteMutationMutation, UpdateSickNoteMutationMutationVariables>;

/**
 * __useUpdateSickNoteMutationMutation__
 *
 * To run a mutation, you first call `useUpdateSickNoteMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSickNoteMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSickNoteMutationMutation, { data, loading, error }] = useUpdateSickNoteMutationMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      sickNoteId: // value for 'sickNoteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSickNoteMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSickNoteMutationMutation, UpdateSickNoteMutationMutationVariables>) {
        return Apollo.useMutation<UpdateSickNoteMutationMutation, UpdateSickNoteMutationMutationVariables>(UpdateSickNoteMutationDocument, baseOptions);
      }
export type UpdateSickNoteMutationMutationHookResult = ReturnType<typeof useUpdateSickNoteMutationMutation>;
export type UpdateSickNoteMutationMutationResult = Apollo.MutationResult<UpdateSickNoteMutationMutation>;
export type UpdateSickNoteMutationMutationOptions = Apollo.BaseMutationOptions<UpdateSickNoteMutationMutation, UpdateSickNoteMutationMutationVariables>;
export const SickNoteDocument = gql`
    query SickNote($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    sickNotes {
      id
      reason
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useSickNoteQuery__
 *
 * To run a query within a React component, call `useSickNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSickNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSickNoteQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useSickNoteQuery(baseOptions: Apollo.QueryHookOptions<SickNoteQuery, SickNoteQueryVariables>) {
        return Apollo.useQuery<SickNoteQuery, SickNoteQueryVariables>(SickNoteDocument, baseOptions);
      }
export function useSickNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SickNoteQuery, SickNoteQueryVariables>) {
          return Apollo.useLazyQuery<SickNoteQuery, SickNoteQueryVariables>(SickNoteDocument, baseOptions);
        }
export type SickNoteQueryHookResult = ReturnType<typeof useSickNoteQuery>;
export type SickNoteLazyQueryHookResult = ReturnType<typeof useSickNoteLazyQuery>;
export type SickNoteQueryResult = Apollo.QueryResult<SickNoteQuery, SickNoteQueryVariables>;
export const CpRemoveSickNoteDocument = gql`
    mutation CpRemoveSickNote($consultationId: ID!, $sickNoteId: ID!) {
  removeConsultationSickNote(consultationId: $consultationId, id: $sickNoteId)
}
    `;
export type CpRemoveSickNoteMutationFn = Apollo.MutationFunction<CpRemoveSickNoteMutation, CpRemoveSickNoteMutationVariables>;

/**
 * __useCpRemoveSickNoteMutation__
 *
 * To run a mutation, you first call `useCpRemoveSickNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpRemoveSickNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpRemoveSickNoteMutation, { data, loading, error }] = useCpRemoveSickNoteMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      sickNoteId: // value for 'sickNoteId'
 *   },
 * });
 */
export function useCpRemoveSickNoteMutation(baseOptions?: Apollo.MutationHookOptions<CpRemoveSickNoteMutation, CpRemoveSickNoteMutationVariables>) {
        return Apollo.useMutation<CpRemoveSickNoteMutation, CpRemoveSickNoteMutationVariables>(CpRemoveSickNoteDocument, baseOptions);
      }
export type CpRemoveSickNoteMutationHookResult = ReturnType<typeof useCpRemoveSickNoteMutation>;
export type CpRemoveSickNoteMutationResult = Apollo.MutationResult<CpRemoveSickNoteMutation>;
export type CpRemoveSickNoteMutationOptions = Apollo.BaseMutationOptions<CpRemoveSickNoteMutation, CpRemoveSickNoteMutationVariables>;
export const SickNotesDocument = gql`
    query SickNotes($id: ID!) {
  consultation(id: $id) {
    id
    ...CpSickNotes
  }
}
    ${CpSickNotesFragmentDoc}`;

/**
 * __useSickNotesQuery__
 *
 * To run a query within a React component, call `useSickNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSickNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSickNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSickNotesQuery(baseOptions: Apollo.QueryHookOptions<SickNotesQuery, SickNotesQueryVariables>) {
        return Apollo.useQuery<SickNotesQuery, SickNotesQueryVariables>(SickNotesDocument, baseOptions);
      }
export function useSickNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SickNotesQuery, SickNotesQueryVariables>) {
          return Apollo.useLazyQuery<SickNotesQuery, SickNotesQueryVariables>(SickNotesDocument, baseOptions);
        }
export type SickNotesQueryHookResult = ReturnType<typeof useSickNotesQuery>;
export type SickNotesLazyQueryHookResult = ReturnType<typeof useSickNotesLazyQuery>;
export type SickNotesQueryResult = Apollo.QueryResult<SickNotesQuery, SickNotesQueryVariables>;
export const CreateTestDocument = gql`
    mutation CreateTest($consultationId: ID!, $input: ConsultationTestInput!) {
  createConsultationTest(consultationId: $consultationId, input: $input) {
    id
    reason
    type
    testId
  }
}
    `;
export type CreateTestMutationFn = Apollo.MutationFunction<CreateTestMutation, CreateTestMutationVariables>;

/**
 * __useCreateTestMutation__
 *
 * To run a mutation, you first call `useCreateTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestMutation, { data, loading, error }] = useCreateTestMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestMutation(baseOptions?: Apollo.MutationHookOptions<CreateTestMutation, CreateTestMutationVariables>) {
        return Apollo.useMutation<CreateTestMutation, CreateTestMutationVariables>(CreateTestDocument, baseOptions);
      }
export type CreateTestMutationHookResult = ReturnType<typeof useCreateTestMutation>;
export type CreateTestMutationResult = Apollo.MutationResult<CreateTestMutation>;
export type CreateTestMutationOptions = Apollo.BaseMutationOptions<CreateTestMutation, CreateTestMutationVariables>;
export const UpdateTestDocument = gql`
    mutation UpdateTest($consultationId: ID!, $id: ID!, $type: String!, $input: ConsultationTestInput!) {
  updateConsultationTest(consultationId: $consultationId, id: $id, type: $type, input: $input) {
    id
    reason
    type
    testId
  }
}
    `;
export type UpdateTestMutationFn = Apollo.MutationFunction<UpdateTestMutation, UpdateTestMutationVariables>;

/**
 * __useUpdateTestMutation__
 *
 * To run a mutation, you first call `useUpdateTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestMutation, { data, loading, error }] = useUpdateTestMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTestMutation, UpdateTestMutationVariables>) {
        return Apollo.useMutation<UpdateTestMutation, UpdateTestMutationVariables>(UpdateTestDocument, baseOptions);
      }
export type UpdateTestMutationHookResult = ReturnType<typeof useUpdateTestMutation>;
export type UpdateTestMutationResult = Apollo.MutationResult<UpdateTestMutation>;
export type UpdateTestMutationOptions = Apollo.BaseMutationOptions<UpdateTestMutation, UpdateTestMutationVariables>;
export const ModalTestTypesDocument = gql`
    query ModalTestTypes {
  diagnosticTestTypes {
    id
    testType
    name
  }
  pathologyTestTypes {
    id
    testType
    name
  }
}
    `;

/**
 * __useModalTestTypesQuery__
 *
 * To run a query within a React component, call `useModalTestTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalTestTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalTestTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useModalTestTypesQuery(baseOptions?: Apollo.QueryHookOptions<ModalTestTypesQuery, ModalTestTypesQueryVariables>) {
        return Apollo.useQuery<ModalTestTypesQuery, ModalTestTypesQueryVariables>(ModalTestTypesDocument, baseOptions);
      }
export function useModalTestTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalTestTypesQuery, ModalTestTypesQueryVariables>) {
          return Apollo.useLazyQuery<ModalTestTypesQuery, ModalTestTypesQueryVariables>(ModalTestTypesDocument, baseOptions);
        }
export type ModalTestTypesQueryHookResult = ReturnType<typeof useModalTestTypesQuery>;
export type ModalTestTypesLazyQueryHookResult = ReturnType<typeof useModalTestTypesLazyQuery>;
export type ModalTestTypesQueryResult = Apollo.QueryResult<ModalTestTypesQuery, ModalTestTypesQueryVariables>;
export const CpGetCachedTestsDocument = gql`
    query CPGetCachedTests($id: ID!) {
  consultation(id: $id) {
    id
    pathologyTests {
      id
    }
    diagnosticTests {
      id
    }
  }
}
    `;

/**
 * __useCpGetCachedTestsQuery__
 *
 * To run a query within a React component, call `useCpGetCachedTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpGetCachedTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpGetCachedTestsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpGetCachedTestsQuery(baseOptions: Apollo.QueryHookOptions<CpGetCachedTestsQuery, CpGetCachedTestsQueryVariables>) {
        return Apollo.useQuery<CpGetCachedTestsQuery, CpGetCachedTestsQueryVariables>(CpGetCachedTestsDocument, baseOptions);
      }
export function useCpGetCachedTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpGetCachedTestsQuery, CpGetCachedTestsQueryVariables>) {
          return Apollo.useLazyQuery<CpGetCachedTestsQuery, CpGetCachedTestsQueryVariables>(CpGetCachedTestsDocument, baseOptions);
        }
export type CpGetCachedTestsQueryHookResult = ReturnType<typeof useCpGetCachedTestsQuery>;
export type CpGetCachedTestsLazyQueryHookResult = ReturnType<typeof useCpGetCachedTestsLazyQuery>;
export type CpGetCachedTestsQueryResult = Apollo.QueryResult<CpGetCachedTestsQuery, CpGetCachedTestsQueryVariables>;
export const CpRemoveTestDocument = gql`
    mutation CPRemoveTest($consultationId: ID!, $type: String!, $id: String!) {
  removeConsultationTest(consultationId: $consultationId, type: $type, id: $id)
}
    `;
export type CpRemoveTestMutationFn = Apollo.MutationFunction<CpRemoveTestMutation, CpRemoveTestMutationVariables>;

/**
 * __useCpRemoveTestMutation__
 *
 * To run a mutation, you first call `useCpRemoveTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCpRemoveTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cpRemoveTestMutation, { data, loading, error }] = useCpRemoveTestMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      type: // value for 'type'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpRemoveTestMutation(baseOptions?: Apollo.MutationHookOptions<CpRemoveTestMutation, CpRemoveTestMutationVariables>) {
        return Apollo.useMutation<CpRemoveTestMutation, CpRemoveTestMutationVariables>(CpRemoveTestDocument, baseOptions);
      }
export type CpRemoveTestMutationHookResult = ReturnType<typeof useCpRemoveTestMutation>;
export type CpRemoveTestMutationResult = Apollo.MutationResult<CpRemoveTestMutation>;
export type CpRemoveTestMutationOptions = Apollo.BaseMutationOptions<CpRemoveTestMutation, CpRemoveTestMutationVariables>;
export const TestTypesDocument = gql`
    query TestTypes {
  diagnosticTestTypes {
    id
    testType
    name
  }
  pathologyTestTypes {
    id
    testType
    name
  }
}
    `;

/**
 * __useTestTypesQuery__
 *
 * To run a query within a React component, call `useTestTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestTypesQuery(baseOptions?: Apollo.QueryHookOptions<TestTypesQuery, TestTypesQueryVariables>) {
        return Apollo.useQuery<TestTypesQuery, TestTypesQueryVariables>(TestTypesDocument, baseOptions);
      }
export function useTestTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestTypesQuery, TestTypesQueryVariables>) {
          return Apollo.useLazyQuery<TestTypesQuery, TestTypesQueryVariables>(TestTypesDocument, baseOptions);
        }
export type TestTypesQueryHookResult = ReturnType<typeof useTestTypesQuery>;
export type TestTypesLazyQueryHookResult = ReturnType<typeof useTestTypesLazyQuery>;
export type TestTypesQueryResult = Apollo.QueryResult<TestTypesQuery, TestTypesQueryVariables>;
export const TestsDocument = gql`
    query Tests($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    pathologyTests {
      id
      reason
      type
      testId
    }
    diagnosticTests {
      id
      reason
      type
      testId
    }
  }
}
    `;

/**
 * __useTestsQuery__
 *
 * To run a query within a React component, call `useTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestsQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useTestsQuery(baseOptions: Apollo.QueryHookOptions<TestsQuery, TestsQueryVariables>) {
        return Apollo.useQuery<TestsQuery, TestsQueryVariables>(TestsDocument, baseOptions);
      }
export function useTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestsQuery, TestsQueryVariables>) {
          return Apollo.useLazyQuery<TestsQuery, TestsQueryVariables>(TestsDocument, baseOptions);
        }
export type TestsQueryHookResult = ReturnType<typeof useTestsQuery>;
export type TestsLazyQueryHookResult = ReturnType<typeof useTestsLazyQuery>;
export type TestsQueryResult = Apollo.QueryResult<TestsQuery, TestsQueryVariables>;
export const EhrConsultationDocument = gql`
    query EHRConsultation($id: ID!) {
  consultation(id: $id) {
    id
    status
    patient {
      id
      date_of_birth
    }
    usEhrConsultation {
      externalAppointmentId
      ehrDepartmentId
      externalEncounterId
    }
  }
}
    `;

/**
 * __useEhrConsultationQuery__
 *
 * To run a query within a React component, call `useEhrConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEhrConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEhrConsultationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEhrConsultationQuery(baseOptions: Apollo.QueryHookOptions<EhrConsultationQuery, EhrConsultationQueryVariables>) {
        return Apollo.useQuery<EhrConsultationQuery, EhrConsultationQueryVariables>(EhrConsultationDocument, baseOptions);
      }
export function useEhrConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EhrConsultationQuery, EhrConsultationQueryVariables>) {
          return Apollo.useLazyQuery<EhrConsultationQuery, EhrConsultationQueryVariables>(EhrConsultationDocument, baseOptions);
        }
export type EhrConsultationQueryHookResult = ReturnType<typeof useEhrConsultationQuery>;
export type EhrConsultationLazyQueryHookResult = ReturnType<typeof useEhrConsultationLazyQuery>;
export type EhrConsultationQueryResult = Apollo.QueryResult<EhrConsultationQuery, EhrConsultationQueryVariables>;
export const EhrConsultationNotesDocument = gql`
    query EHRConsultationNotes($id: ID!) {
  consultation(id: $id) {
    id
    status
    patient {
      id
      date_of_birth
    }
    usEhrNote {
      htmlSummary
    }
  }
}
    `;

/**
 * __useEhrConsultationNotesQuery__
 *
 * To run a query within a React component, call `useEhrConsultationNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEhrConsultationNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEhrConsultationNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEhrConsultationNotesQuery(baseOptions: Apollo.QueryHookOptions<EhrConsultationNotesQuery, EhrConsultationNotesQueryVariables>) {
        return Apollo.useQuery<EhrConsultationNotesQuery, EhrConsultationNotesQueryVariables>(EhrConsultationNotesDocument, baseOptions);
      }
export function useEhrConsultationNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EhrConsultationNotesQuery, EhrConsultationNotesQueryVariables>) {
          return Apollo.useLazyQuery<EhrConsultationNotesQuery, EhrConsultationNotesQueryVariables>(EhrConsultationNotesDocument, baseOptions);
        }
export type EhrConsultationNotesQueryHookResult = ReturnType<typeof useEhrConsultationNotesQuery>;
export type EhrConsultationNotesLazyQueryHookResult = ReturnType<typeof useEhrConsultationNotesLazyQuery>;
export type EhrConsultationNotesQueryResult = Apollo.QueryResult<EhrConsultationNotesQuery, EhrConsultationNotesQueryVariables>;
export const UpdateEhrConsultationStatusDocument = gql`
    mutation UpdateEHRConsultationStatus($id: ID!) {
  updateConsultationStatus(id: $id, status: complete, exempted: false) {
    id
    status
  }
}
    `;
export type UpdateEhrConsultationStatusMutationFn = Apollo.MutationFunction<UpdateEhrConsultationStatusMutation, UpdateEhrConsultationStatusMutationVariables>;

/**
 * __useUpdateEhrConsultationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEhrConsultationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEhrConsultationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEhrConsultationStatusMutation, { data, loading, error }] = useUpdateEhrConsultationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEhrConsultationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEhrConsultationStatusMutation, UpdateEhrConsultationStatusMutationVariables>) {
        return Apollo.useMutation<UpdateEhrConsultationStatusMutation, UpdateEhrConsultationStatusMutationVariables>(UpdateEhrConsultationStatusDocument, baseOptions);
      }
export type UpdateEhrConsultationStatusMutationHookResult = ReturnType<typeof useUpdateEhrConsultationStatusMutation>;
export type UpdateEhrConsultationStatusMutationResult = Apollo.MutationResult<UpdateEhrConsultationStatusMutation>;
export type UpdateEhrConsultationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEhrConsultationStatusMutation, UpdateEhrConsultationStatusMutationVariables>;
export const UpdateEhrConsultationNotesDocument = gql`
    mutation UpdateEHRConsultationNotes($consultationId: ID!, $input: ConsultationNoteInput!) {
  updateConsultationNote(consultationId: $consultationId, input: $input) {
    id
    toldByPatient
    exploredByGp
    assessment
    treatmentPlan
    fallbackPlan
  }
}
    `;
export type UpdateEhrConsultationNotesMutationFn = Apollo.MutationFunction<UpdateEhrConsultationNotesMutation, UpdateEhrConsultationNotesMutationVariables>;

/**
 * __useUpdateEhrConsultationNotesMutation__
 *
 * To run a mutation, you first call `useUpdateEhrConsultationNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEhrConsultationNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEhrConsultationNotesMutation, { data, loading, error }] = useUpdateEhrConsultationNotesMutation({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEhrConsultationNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEhrConsultationNotesMutation, UpdateEhrConsultationNotesMutationVariables>) {
        return Apollo.useMutation<UpdateEhrConsultationNotesMutation, UpdateEhrConsultationNotesMutationVariables>(UpdateEhrConsultationNotesDocument, baseOptions);
      }
export type UpdateEhrConsultationNotesMutationHookResult = ReturnType<typeof useUpdateEhrConsultationNotesMutation>;
export type UpdateEhrConsultationNotesMutationResult = Apollo.MutationResult<UpdateEhrConsultationNotesMutation>;
export type UpdateEhrConsultationNotesMutationOptions = Apollo.BaseMutationOptions<UpdateEhrConsultationNotesMutation, UpdateEhrConsultationNotesMutationVariables>;
export const MarkEhrNotesSensitiveDocument = gql`
    mutation MarkEHRNotesSensitive($appointmentId: ID!) {
  markEHRNotesSensitive(appointmentId: $appointmentId)
}
    `;
export type MarkEhrNotesSensitiveMutationFn = Apollo.MutationFunction<MarkEhrNotesSensitiveMutation, MarkEhrNotesSensitiveMutationVariables>;

/**
 * __useMarkEhrNotesSensitiveMutation__
 *
 * To run a mutation, you first call `useMarkEhrNotesSensitiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkEhrNotesSensitiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markEhrNotesSensitiveMutation, { data, loading, error }] = useMarkEhrNotesSensitiveMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useMarkEhrNotesSensitiveMutation(baseOptions?: Apollo.MutationHookOptions<MarkEhrNotesSensitiveMutation, MarkEhrNotesSensitiveMutationVariables>) {
        return Apollo.useMutation<MarkEhrNotesSensitiveMutation, MarkEhrNotesSensitiveMutationVariables>(MarkEhrNotesSensitiveDocument, baseOptions);
      }
export type MarkEhrNotesSensitiveMutationHookResult = ReturnType<typeof useMarkEhrNotesSensitiveMutation>;
export type MarkEhrNotesSensitiveMutationResult = Apollo.MutationResult<MarkEhrNotesSensitiveMutation>;
export type MarkEhrNotesSensitiveMutationOptions = Apollo.BaseMutationOptions<MarkEhrNotesSensitiveMutation, MarkEhrNotesSensitiveMutationVariables>;
export const CpConsultationWorkflowsV2Document = gql`
    query CpConsultationWorkflowsV2($locale: String!, $tags: String!, $consumerNetwork: ID!, $consultationId: ID!, $includeDefinitions: Boolean!) {
  consultationWorkflowsV2(locale: $locale, tags: $tags, consumerNetwork: $consumerNetwork, consultationId: $consultationId) {
    workflows {
      results {
        id
        state
        variables {
          name
          value
        }
        workflowDefinition {
          id
          group {
            type
            label
          }
          roles
          workflow_definition_name
        }
      }
    }
    workflowDefinitions @include(if: $includeDefinitions) {
      id
      key
      version
      tenant_id
      workflow_definition_name
      group {
        type
        label
      }
      roles
    }
  }
}
    `;

/**
 * __useCpConsultationWorkflowsV2Query__
 *
 * To run a query within a React component, call `useCpConsultationWorkflowsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCpConsultationWorkflowsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpConsultationWorkflowsV2Query({
 *   variables: {
 *      locale: // value for 'locale'
 *      tags: // value for 'tags'
 *      consumerNetwork: // value for 'consumerNetwork'
 *      consultationId: // value for 'consultationId'
 *      includeDefinitions: // value for 'includeDefinitions'
 *   },
 * });
 */
export function useCpConsultationWorkflowsV2Query(baseOptions: Apollo.QueryHookOptions<CpConsultationWorkflowsV2Query, CpConsultationWorkflowsV2QueryVariables>) {
        return Apollo.useQuery<CpConsultationWorkflowsV2Query, CpConsultationWorkflowsV2QueryVariables>(CpConsultationWorkflowsV2Document, baseOptions);
      }
export function useCpConsultationWorkflowsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpConsultationWorkflowsV2Query, CpConsultationWorkflowsV2QueryVariables>) {
          return Apollo.useLazyQuery<CpConsultationWorkflowsV2Query, CpConsultationWorkflowsV2QueryVariables>(CpConsultationWorkflowsV2Document, baseOptions);
        }
export type CpConsultationWorkflowsV2QueryHookResult = ReturnType<typeof useCpConsultationWorkflowsV2Query>;
export type CpConsultationWorkflowsV2LazyQueryHookResult = ReturnType<typeof useCpConsultationWorkflowsV2LazyQuery>;
export type CpConsultationWorkflowsV2QueryResult = Apollo.QueryResult<CpConsultationWorkflowsV2Query, CpConsultationWorkflowsV2QueryVariables>;
export const TriggerWorkflowDocument = gql`
    mutation TriggerWorkflow($idempotency_key: String!, $workflow_definition_key: String!, $tenant_id: ID!, $variables: [WorkflowVariableInput!]!) {
  triggerWorkflow(idempotency_key: $idempotency_key, workflow_definition_key: $workflow_definition_key, tenant_id: $tenant_id, variables: $variables) {
    id
    start_time
    end_time
    state
    idempotency_key
    workflow_definition_key
    workflow_definition_id
    workflowDefinition {
      id
      key
      workflow_definition_name
      group {
        type
        label
      }
      roles
    }
    tenant_id
    variables {
      name
      value
    }
  }
}
    `;
export type TriggerWorkflowMutationFn = Apollo.MutationFunction<TriggerWorkflowMutation, TriggerWorkflowMutationVariables>;

/**
 * __useTriggerWorkflowMutation__
 *
 * To run a mutation, you first call `useTriggerWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerWorkflowMutation, { data, loading, error }] = useTriggerWorkflowMutation({
 *   variables: {
 *      idempotency_key: // value for 'idempotency_key'
 *      workflow_definition_key: // value for 'workflow_definition_key'
 *      tenant_id: // value for 'tenant_id'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useTriggerWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<TriggerWorkflowMutation, TriggerWorkflowMutationVariables>) {
        return Apollo.useMutation<TriggerWorkflowMutation, TriggerWorkflowMutationVariables>(TriggerWorkflowDocument, baseOptions);
      }
export type TriggerWorkflowMutationHookResult = ReturnType<typeof useTriggerWorkflowMutation>;
export type TriggerWorkflowMutationResult = Apollo.MutationResult<TriggerWorkflowMutation>;
export type TriggerWorkflowMutationOptions = Apollo.BaseMutationOptions<TriggerWorkflowMutation, TriggerWorkflowMutationVariables>;
export const WorkflowDefinitionDocument = gql`
    query WorkflowDefinition($id: ID!, $locale: String) {
  workflowDefinition(id: $id, locale: $locale) {
    id
    key
    version
    tenant_id
    group {
      type
      label
      assistive_texts {
        instruction
      }
    }
    variables {
      name
      type
      label
      is_required
      metadata {
        type
        value {
          __typename
          ... on WorkflowDefinitionVariableMetadataChildAllowedValue {
            parent_variable
            parent_mappings
          }
          ... on WorkflowDefinitionVariableMetadataAllowedValue {
            values {
              key
              label
            }
          }
          ... on WorkflowDefinitionVariableMetadataAsyncCodeSearchValue {
            coding_system
          }
          ... on WorkflowDefinitionVariableMetadataDateFormat {
            format
          }
          ... on WorkflowDefinitionVariableMetadataRegexValidation {
            regex
          }
          ... on WorkflowDefinitionVariableMetadataSource {
            type
            source_info {
              placeholder
              default_value
            }
          }
          ... on WorkflowDefinitionVariableMetadataDefaultValue {
            default_value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWorkflowDefinitionQuery__
 *
 * To run a query within a React component, call `useWorkflowDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowDefinitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useWorkflowDefinitionQuery(baseOptions: Apollo.QueryHookOptions<WorkflowDefinitionQuery, WorkflowDefinitionQueryVariables>) {
        return Apollo.useQuery<WorkflowDefinitionQuery, WorkflowDefinitionQueryVariables>(WorkflowDefinitionDocument, baseOptions);
      }
export function useWorkflowDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowDefinitionQuery, WorkflowDefinitionQueryVariables>) {
          return Apollo.useLazyQuery<WorkflowDefinitionQuery, WorkflowDefinitionQueryVariables>(WorkflowDefinitionDocument, baseOptions);
        }
export type WorkflowDefinitionQueryHookResult = ReturnType<typeof useWorkflowDefinitionQuery>;
export type WorkflowDefinitionLazyQueryHookResult = ReturnType<typeof useWorkflowDefinitionLazyQuery>;
export type WorkflowDefinitionQueryResult = Apollo.QueryResult<WorkflowDefinitionQuery, WorkflowDefinitionQueryVariables>;
export const SearchPointOfCareDocument = gql`
    query SearchPointOfCare($term: String!, $index: String) {
  searchPointOfCareEntity(term: $term, index: $index) {
    __typename
    total
    concepts {
      code {
        code
        system
        display
      }
    }
  }
}
    `;

/**
 * __useSearchPointOfCareQuery__
 *
 * To run a query within a React component, call `useSearchPointOfCareQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPointOfCareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPointOfCareQuery({
 *   variables: {
 *      term: // value for 'term'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useSearchPointOfCareQuery(baseOptions: Apollo.QueryHookOptions<SearchPointOfCareQuery, SearchPointOfCareQueryVariables>) {
        return Apollo.useQuery<SearchPointOfCareQuery, SearchPointOfCareQueryVariables>(SearchPointOfCareDocument, baseOptions);
      }
export function useSearchPointOfCareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPointOfCareQuery, SearchPointOfCareQueryVariables>) {
          return Apollo.useLazyQuery<SearchPointOfCareQuery, SearchPointOfCareQueryVariables>(SearchPointOfCareDocument, baseOptions);
        }
export type SearchPointOfCareQueryHookResult = ReturnType<typeof useSearchPointOfCareQuery>;
export type SearchPointOfCareLazyQueryHookResult = ReturnType<typeof useSearchPointOfCareLazyQuery>;
export type SearchPointOfCareQueryResult = Apollo.QueryResult<SearchPointOfCareQuery, SearchPointOfCareQueryVariables>;
export const CpWorkflowContextDocument = gql`
    query CpWorkflowContext($id: ID!) {
  consultation(id: $id) {
    id
    consumerNetwork
    consumerNetworkName
    patient {
      id
      email
      date_of_birth
      full_name
      public_healthcare_identifier
      region {
        iso_code
      }
      clinical_commissioning_group {
        id
        name
      }
      contact_details {
        address_first_line
        address_second_line
        address_third_line
        address_post_code
        phone_country_code
        phone_number
      }
    }
  }
}
    `;

/**
 * __useCpWorkflowContextQuery__
 *
 * To run a query within a React component, call `useCpWorkflowContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCpWorkflowContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCpWorkflowContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCpWorkflowContextQuery(baseOptions: Apollo.QueryHookOptions<CpWorkflowContextQuery, CpWorkflowContextQueryVariables>) {
        return Apollo.useQuery<CpWorkflowContextQuery, CpWorkflowContextQueryVariables>(CpWorkflowContextDocument, baseOptions);
      }
export function useCpWorkflowContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CpWorkflowContextQuery, CpWorkflowContextQueryVariables>) {
          return Apollo.useLazyQuery<CpWorkflowContextQuery, CpWorkflowContextQueryVariables>(CpWorkflowContextDocument, baseOptions);
        }
export type CpWorkflowContextQueryHookResult = ReturnType<typeof useCpWorkflowContextQuery>;
export type CpWorkflowContextLazyQueryHookResult = ReturnType<typeof useCpWorkflowContextLazyQuery>;
export type CpWorkflowContextQueryResult = Apollo.QueryResult<CpWorkflowContextQuery, CpWorkflowContextQueryVariables>;
export const WorkflowSubTypesDocument = gql`
    query WorkflowSubTypes($type: WorkflowType!) {
  workflowSubTypes(type: $type) {
    value
    label
  }
}
    `;

/**
 * __useWorkflowSubTypesQuery__
 *
 * To run a query within a React component, call `useWorkflowSubTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowSubTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowSubTypesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useWorkflowSubTypesQuery(baseOptions: Apollo.QueryHookOptions<WorkflowSubTypesQuery, WorkflowSubTypesQueryVariables>) {
        return Apollo.useQuery<WorkflowSubTypesQuery, WorkflowSubTypesQueryVariables>(WorkflowSubTypesDocument, baseOptions);
      }
export function useWorkflowSubTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowSubTypesQuery, WorkflowSubTypesQueryVariables>) {
          return Apollo.useLazyQuery<WorkflowSubTypesQuery, WorkflowSubTypesQueryVariables>(WorkflowSubTypesDocument, baseOptions);
        }
export type WorkflowSubTypesQueryHookResult = ReturnType<typeof useWorkflowSubTypesQuery>;
export type WorkflowSubTypesLazyQueryHookResult = ReturnType<typeof useWorkflowSubTypesLazyQuery>;
export type WorkflowSubTypesQueryResult = Apollo.QueryResult<WorkflowSubTypesQuery, WorkflowSubTypesQueryVariables>;
export const WorkflowSubSpecialtiesDocument = gql`
    query WorkflowSubSpecialties($type: WorkflowType!, $subType: String!) {
  workflowSubSpecialties(type: $type, subType: $subType)
}
    `;

/**
 * __useWorkflowSubSpecialtiesQuery__
 *
 * To run a query within a React component, call `useWorkflowSubSpecialtiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowSubSpecialtiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowSubSpecialtiesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *   },
 * });
 */
export function useWorkflowSubSpecialtiesQuery(baseOptions: Apollo.QueryHookOptions<WorkflowSubSpecialtiesQuery, WorkflowSubSpecialtiesQueryVariables>) {
        return Apollo.useQuery<WorkflowSubSpecialtiesQuery, WorkflowSubSpecialtiesQueryVariables>(WorkflowSubSpecialtiesDocument, baseOptions);
      }
export function useWorkflowSubSpecialtiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowSubSpecialtiesQuery, WorkflowSubSpecialtiesQueryVariables>) {
          return Apollo.useLazyQuery<WorkflowSubSpecialtiesQuery, WorkflowSubSpecialtiesQueryVariables>(WorkflowSubSpecialtiesDocument, baseOptions);
        }
export type WorkflowSubSpecialtiesQueryHookResult = ReturnType<typeof useWorkflowSubSpecialtiesQuery>;
export type WorkflowSubSpecialtiesLazyQueryHookResult = ReturnType<typeof useWorkflowSubSpecialtiesLazyQuery>;
export type WorkflowSubSpecialtiesQueryResult = Apollo.QueryResult<WorkflowSubSpecialtiesQuery, WorkflowSubSpecialtiesQueryVariables>;
export const WorkflowConsultationDocument = gql`
    query WorkflowConsultation($consultationId: ID!) {
  consultation(id: $consultationId) {
    id
    consumerNetwork
    patient {
      id
      full_name
      email
      date_of_birth
      public_healthcare_identifier
      clinical_commissioning_group {
        id
        code
        name
      }
      contact_details {
        address_first_line
        address_second_line
        address_third_line
        address_post_code
        phone_country_code
        phone_number
      }
      region {
        id
        iso_code
      }
    }
  }
}
    `;

/**
 * __useWorkflowConsultationQuery__
 *
 * To run a query within a React component, call `useWorkflowConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowConsultationQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useWorkflowConsultationQuery(baseOptions: Apollo.QueryHookOptions<WorkflowConsultationQuery, WorkflowConsultationQueryVariables>) {
        return Apollo.useQuery<WorkflowConsultationQuery, WorkflowConsultationQueryVariables>(WorkflowConsultationDocument, baseOptions);
      }
export function useWorkflowConsultationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowConsultationQuery, WorkflowConsultationQueryVariables>) {
          return Apollo.useLazyQuery<WorkflowConsultationQuery, WorkflowConsultationQueryVariables>(WorkflowConsultationDocument, baseOptions);
        }
export type WorkflowConsultationQueryHookResult = ReturnType<typeof useWorkflowConsultationQuery>;
export type WorkflowConsultationLazyQueryHookResult = ReturnType<typeof useWorkflowConsultationLazyQuery>;
export type WorkflowConsultationQueryResult = Apollo.QueryResult<WorkflowConsultationQuery, WorkflowConsultationQueryVariables>;