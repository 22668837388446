import { ApolloError } from '@apollo/client'
import React from 'react'

import { Button, Grid, Tag } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'

import { useMessages } from '~/core/hooks'
import PatientNameDisplay from '~/core/patientNameFormatter'
import { displayPhoneNumber } from '~/core/phone'
import { VerificationStatus } from '~/generated'
import Address from '~/ui/Address'
import { ErrorPanel } from '~/ui/ErrorPanel'
import { Section, SectionExpander } from '~/ui/Section'
import SectionItem from '~/ui/SectionItem'
import Tooltip from '~/ui/Tooltip'

import { PatientDetails } from '../types'

import messages from './PatientDetailsSection.messages'

interface PatientDetailsSectionViewProps {
  editButtonHidden: boolean
  openModal: () => void
  loading: boolean
  error: ApolloError | undefined
  patient: PatientDetails | undefined
  languageRequested?: string | null
  hideIdVerificationEnabled?: boolean
}

const PatientDetailsSectionView = ({
  editButtonHidden,
  openModal,
  loading,
  error,
  patient,
  languageRequested,
  hideIdVerificationEnabled,
}: PatientDetailsSectionViewProps) => {
  const f = useMessages(messages)
  const intl = useIntl()

  if (loading) {
    return <Section title={f('title')} loading />
  }

  if (error || !patient) {
    return (
      <Section title={f('title')}>
        <ErrorPanel
          title={f('error_title')}
          error={error || new Error('No patient')}
        />
      </Section>
    )
  }

  const {
    region,
    contact_details: contactDetails,
    account_owner_contact_details: guardianContactDetails,
    next_of_kin: nextOfKin,
    identity_check: identityCheck,
  } = patient

  const hideIdVerificationMessage =
    identityCheck?.status !== VerificationStatus.Succeeded ||
    !identityCheck.verification_overwritten_at ||
    !identityCheck.verification_overwritten_by

  const idVerificationMessage = f('verification_audit_trail', {
    consultantName: identityCheck?.verification_overwritten_by?.fullName,
    date: intl.formatDate(identityCheck?.verification_overwritten_at!, {
      format: 'shortWithTime',
    }),
    consultantId: identityCheck?.verification_overwritten_by?.id,
  })

  return (
    <Section
      title={f('title')}
      topRight={
        !editButtonHidden && (
          <Button onClick={openModal} intent="secondary">
            {f('update_patient_details_button_label')}
          </Button>
        )
      }
      tooltip={
        patient.minor ? f('tool_tip_message_minor') : f('tool_tip_message')
      }
      dataTestId="patient-details-section"
    >
      <Grid templateColumns="repeat(auto-fit, minmax(150px, 1fr))">
        <div>
          <SectionItem
            title={f('patient_name_section_title')}
            icon="account_circle"
            description={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <PatientNameDisplay
                  firstName={patient.first_name}
                  lastName={patient.last_name}
                />
                {languageRequested && (
                  <Tag color="clinical-orange" margin style={{ marginTop: 2 }}>
                    {f('interpreter')}
                  </Tag>
                )}
                {hideIdVerificationEnabled ? null : (
                  <Tooltip
                    overlay={idVerificationMessage}
                    hidden={hideIdVerificationMessage}
                  >
                    <div style={{ width: 'max-content' }}>
                      <Tag
                        color={
                          patient.id_verification === 'succeeded'
                            ? 'white-on-green'
                            : 'white-on-red'
                        }
                        style={{ marginTop: 2 }}
                        margin
                      >
                        {patient.id_verification === 'succeeded'
                          ? f('id_verification_status_verified_message')
                          : f('id_verification_status_not_verified_message')}
                      </Tag>
                    </div>
                  </Tooltip>
                )}
              </div>
            }
          />
          <SectionItem
            title={f('date_of_birth_section_title')}
            icon="date_range"
            description={
              <span>
                <span>
                  {patient.date_of_birth
                    ? intl.formatDate(patient.date_of_birth, {
                        format: 'short',
                        timeZone: 'UTC',
                      })
                    : '-'}
                </span>{' '}
                {patient.minor && (
                  <Tag color="white-on-orange" style={{ marginLeft: 4 }} margin>
                    {f('minor')}
                  </Tag>
                )}
              </span>
            }
          />
          <SectionItem
            title={f('address_section_title')}
            icon="room"
            description={
              <Address contactDetails={contactDetails} region={region?.name} />
            }
          />
        </div>
        {(guardianContactDetails?.address_first_line ||
          guardianContactDetails?.address_second_line ||
          guardianContactDetails?.address_third_line ||
          guardianContactDetails?.address_post_code ||
          patient.account_owner_name ||
          patient.account_owner_dob) && (
          <div>
            {patient.account_owner_name && (
              <SectionItem
                title={f('guardian_name_section_title')}
                icon="account_circle"
                description={patient.account_owner_name}
              />
            )}
            {patient.account_owner_dob && (
              <SectionItem
                title={f('guardian_date_of_birth_section_title')}
                icon="date_range"
                description={intl.formatDate(patient.account_owner_dob, {
                  format: 'short',
                  timeZone: 'UTC',
                })}
              />
            )}
            {(guardianContactDetails?.address_first_line ||
              guardianContactDetails?.address_second_line ||
              guardianContactDetails?.address_third_line ||
              guardianContactDetails?.address_post_code) && (
              <SectionItem
                title={f('guardian_address_section_title')}
                icon="room"
                description={
                  <Address contactDetails={guardianContactDetails} />
                }
              />
            )}
          </div>
        )}
      </Grid>
      <SectionExpander title={f('title')}>
        <Grid templateColumns="repeat(auto-fit, minmax(150px, 1fr))">
          <div>
            <SectionItem
              title={f('phone_number_section_title')}
              icon="perm_phone_msg"
            >
              {displayPhoneNumber(
                contactDetails?.phone_country_code,
                contactDetails?.phone_number
              )}
            </SectionItem>

            <SectionItem
              title={f('email_section_title')}
              icon="email"
              description={patient.email}
            />

            <SectionItem
              title={f('gender_section_title')}
              icon="supervisor_account"
              description={
                patient.gender === 'male'
                  ? f('gender_section_male_value')
                  : patient.gender === 'female'
                  ? f('gender_section_female_value')
                  : '-'
              }
            />
          </div>
          {(guardianContactDetails?.phone_number ||
            nextOfKin?.first_name ||
            nextOfKin?.last_name ||
            nextOfKin?.relation ||
            nextOfKin?.phone_number) && (
            <div>
              {guardianContactDetails?.phone_number && (
                <SectionItem
                  title={f('guardian_phone_number_title')}
                  icon="perm_phone_msg"
                  description={displayPhoneNumber(
                    guardianContactDetails.phone_country_code || '',
                    guardianContactDetails.phone_number || ''
                  )}
                />
              )}
              {(nextOfKin?.first_name ||
                nextOfKin?.last_name ||
                nextOfKin?.relation ||
                nextOfKin?.phone_number) && (
                <span>
                  <SectionItem
                    title={f('next_of_kin_section_name_title')}
                    icon="account_circle"
                    description={
                      <PatientNameDisplay
                        firstName={nextOfKin.first_name || ''}
                        lastName={nextOfKin.last_name || ''}
                      />
                    }
                  />
                  {nextOfKin.relation && (
                    <SectionItem
                      title={f('next_of_kin_section_relation_title')}
                      icon="supervisor_account"
                      description={nextOfKin.relation}
                    />
                  )}
                  {nextOfKin.phone_number && (
                    <SectionItem
                      title={f('next_of_kin_section_phone_number_title')}
                      icon="perm_phone_msg"
                      description={displayPhoneNumber(nextOfKin.phone_number)}
                    />
                  )}
                </span>
              )}
            </div>
          )}
        </Grid>
      </SectionExpander>
    </Section>
  )
}

export default PatientDetailsSectionView
