import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import transform from 'lodash/transform'

/**
 * Remove all falsy values from object and returns a new object
 *
 * - Removes any keys which have a fasly value (null/undefined/'')
 * - Removes any empty objects with no keys
 * - Does deep search of object and also any array values with nested objects
 *
 * @param object Object to remove falsy values from
 * @returns New object which contains only truthy key/value pairs
 *
 * @author Tameem Safi
 */
// @ts-ignore
const pickTruthyValues: any = (object: any) =>
  transform(object, (result, value, key) => {
    if (!value && typeof value !== 'boolean') {
      return
    }

    if (Array.isArray(value) || isPlainObject(value)) {
      value = pickTruthyValues(value)

      if (isEmpty(value)) {
        return
      }
    }
    // @ts-ignore
    result[key] = value
  })

export default pickTruthyValues
