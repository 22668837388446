import React from 'react'
import { FormattedMessage } from 'react-intl'
import uuid from 'uuid/v4'

import { ENABLE_F2F_BOOKINGS } from '~/constants'
import {
  CREATE_NHS_APPOINTMENT_CATEGORY,
  NHS_APPOINTMENT_MEDIUM_DIGITAL_LABEL,
  NHS_APPOINTMENT_MEDIUM_F2F_LABEL,
} from '~/constants/analytics'
import analytics from '~/core/analytics'
import { updateQuery } from '~/core/graphql'
import { errorAlert } from '~/core/with-error-message'
import { mapConsultationToWorkflowData } from '~/features/workflows/utils'
import { AppointmentInvitesDocument } from '~/generated'
import { Dialog } from '~/ui/Dialog'

import BookingConfirmationDialog from './BookingConfirmationDialog'

import bookingConfirmationDialogMessages from './BookingConfirmationDialog.messages'

const showErrorDialog = () => {
  Dialog.show({
    title: (
      <FormattedMessage {...bookingConfirmationDialogMessages.error_title} />
    ),
    message: (
      <FormattedMessage {...bookingConfirmationDialogMessages.error_message} />
    ),
  })
}

const trackEvent = analytics.trackEventFactory({
  category: CREATE_NHS_APPOINTMENT_CATEGORY,
})

export const createAppointmentInvite = (
  input,
  createAppointmentInviteMutation,
  consultationId
) => {
  return createAppointmentInviteMutation({
    variables: {
      input,
    },
    update: updateQuery(
      AppointmentInvitesDocument,
      {
        id: consultationId,
        f2fOn: ENABLE_F2F_BOOKINGS,
      },
      (cache, { data }) => {
        const appointmentInvite = data.createAppointmentInvite

        if (!appointmentInvite) {
          console.warn(
            'Mutation createAppointmentInvite returned null. This can potentially be a bug.'
          )

          return cache
        }

        const appointmentInvites = [
          ...cache.consultation.appointmentInvites,
          appointmentInvite,
        ]

        return {
          ...cache,
          consultation: {
            ...cache.consultation,
            appointmentInvites,
          },
        }
      }
    ),
  })
}

export const handleSubmitForm = ({
  consultation,
  user,
  createAppointmentWorkflowMutation,
  createAppointmentInviteMutation,
  closeModal,
  serviceTypes,
  updateWorkflowsV2Query,
  nhsAppointmentLabel,
  setShowDialog,
}) => async (values) => {
  const {
    selectedServiceTypeId,
    serviceTypeCategory,
    intimate,
    reason,
    mediumType,
    duration,
    urgency,
  } = values

  if (mediumType === 'digital') {
    try {
      const appointment = {
        type: 'appointment',
        sub_type: mediumType,
        notes: reason,
        gp_nurse_pharmacist: serviceTypeCategory,
        urgency,
        mobility: 'N/A',
        follow_up_date: 'N/A',
        interpreter: 'false',
      }

      const inputData = mapConsultationToWorkflowData(
        consultation,
        user.id,
        user.consultant.name
      )

      const response = await createAppointmentWorkflowMutation({
        variables: {
          appointment,
          data: inputData,
        },
      })

      trackEvent({
        action: serviceTypeCategory,
        label: NHS_APPOINTMENT_MEDIUM_DIGITAL_LABEL,
        value: urgency === 'urgent' ? 1 : 0,
      })

      if (response.data) {
        const digitalAppointment = {
          __typename: 'WorkflowV2',
          // Create a temporary uuid to keep the cache happy - this should really be fetched from remote
          // This code will be replaced soon by follow-up invites anyway
          id: uuid(),
          state: 'ACTIVE',
          variables: [
            {
              __typename: 'WorkflowVariable',
              name: 'actionType',
              value: 'appointment',
            },
            {
              __typename: 'WorkflowVariable',
              name: 'actionSubType',
              value: 'digital',
            },
          ],
          workflowDefinition: {
            __typename: 'WorkflowDefinition',
            // Create a temporary uuid to keep the cache happy - this should really be fetched from remote
            // This code will be replaced soon by follow-up invites anyway
            id: uuid(),
            group: {
              __typename: 'WorkflowDefinitionGroup',
              type: 'appointment',
              label: nhsAppointmentLabel,
            },
            roles: [],
            workflow_definition_name: 'Appointment',
          },
        }

        updateWorkflowsV2Query((prev) => {
          const results = [
            ...prev.consultationWorkflowsV2.workflows.results,
            digitalAppointment,
          ]

          return {
            ...prev,
            consultationWorkflowsV2: {
              ...prev.consultationWorkflowsV2,
              workflows: {
                ...prev.consultationWorkflowsV2.workflows,
                results,
              },
            },
          }
        })
      }

      setShowDialog(true)
    } catch (exception) {
      errorAlert({ logMessage: exception })
    }
  } else {
    const serviceType = serviceTypes[serviceTypeCategory].find(
      (v) => v.id === selectedServiceTypeId
    )

    Dialog.show({
      /** TODO: Change this after rewriting how Dialogs created */
      title: <FormattedMessage {...bookingConfirmationDialogMessages.title} />,
      children: (
        <BookingConfirmationDialog
          type={serviceType.name}
          clinician={serviceTypeCategory}
          duration={duration}
        />
      ),
      okLabel: (
        <FormattedMessage {...bookingConfirmationDialogMessages.ok_label} />
      ),
      cancelLabel: (
        <FormattedMessage {...bookingConfirmationDialogMessages.cancel_label} />
      ),
      onOkStyle: 'primary',
      onOk: async () => {
        try {
          const appointmentInvite = {
            member_uuid: consultation.patient.uuid,
            service_type_uuid: selectedServiceTypeId,
            source_appointment_id: consultation.id,
            consultation_type: mediumType,
            earliest_booking_date: new Date().toISOString(),
            duration_minutes: duration,
            notes_for_member: reason,
            intimate,
          }
          await createAppointmentInvite(
            appointmentInvite,
            createAppointmentInviteMutation,
            consultation.id
          )

          trackEvent({
            action: serviceTypeCategory,
            label: NHS_APPOINTMENT_MEDIUM_F2F_LABEL,
          })

          closeModal()
        } catch (error) {
          showErrorDialog()
        }
      },
    })
  }
}
