import React, { useState } from 'react'
import { Alert, DropdownSelect, Heading, Anchor } from '@babylon/core-ui'

import styles from '../styles.module.scss'
import ActionsForm from './ActionsForm'

const REPORT_ACTION_OPTIONS = [
  {
    key: 'normal',
    value: 'No appointment needed (normal or mildly abnormal)',
    tip:
      'The results will be sent to the patient and no appointment will be suggested.',
    actionState: 'SENT',
    suggestAppointment: false,
  },
  {
    key: 'abnormal',
    value: 'Appointment required (abnormal)',
    tip:
      'The results will be sent to the patient and an appointment will be suggested to further discuss them with a Babylon GP',
    actionState: 'SENT',
    suggestAppointment: true,
  },
  {
    key: 'error',
    value: 'Results cannot be sent to user',
    tip:
      'This option is for samples that cannot be sent to the user because of multiple reasons, such as haemolysed or clotted blood, underfilled sample, mismatched patient details among others.',
    actionState: 'ERROR',
    suggestAppointment: false,
  },
]

export default ({
  reportState,
  submitting,
  submitError,
  onUpdatePanel,
  clearSubmitError,
}) => {
  const [selectedAction, setSelectedAction] = useState(null)

  const actionOptions =
    reportState === 'NEW'
      ? REPORT_ACTION_OPTIONS
      : REPORT_ACTION_OPTIONS.filter((option) => option.key !== 'error')

  return (
    <div className={styles.report__actions} data-testid="actions">
      <Heading>Actions</Heading>
      <div className={styles.report__dropdown}>
        <DropdownSelect
          selectedOption={selectedAction}
          onChange={(selection) => {
            setSelectedAction(selection)
            clearSubmitError()
          }}
          options={actionOptions}
          placeholder="Select action"
          fill="#FFF"
        />
      </div>
      {selectedAction && (
        <p className={styles.report__tip}>{selectedAction.tip}</p>
      )}
      <ActionsForm
        selectedAction={selectedAction}
        onUpdatePanel={onUpdatePanel}
        submitting={submitting}
      />
      {submitError && (
        <Alert intent="error" className={styles['report__error-alert']}>
          <div className={styles['report__error-alert__content']}>
            <span
              role="img"
              aria-label="error"
              className={styles['report__error-alert__content__icon']}
            >
              ⚠️
            </span>
            Server error. Please contact Test Kits team urgently via&nbsp;
            <Anchor
              href="https://babylonhealth.slack.com/messages/CKG5T0JFN"
              target="_blank"
            >
              #test_kits_prod_supprt
            </Anchor>
          </div>
        </Alert>
      )}
    </div>
  )
}
