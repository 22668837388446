import { gql } from '@apollo/client'

export const CREATE_TEST_MUTATION = gql`
  mutation CreateTest($consultationId: ID!, $input: ConsultationTestInput!) {
    createConsultationTest(consultationId: $consultationId, input: $input) {
      id
      reason
      type
      testId
    }
  }
`

export const UPDATE_TEST_MUTATION = gql`
  mutation UpdateTest(
    $consultationId: ID!
    $id: ID!
    $type: String!
    $input: ConsultationTestInput!
  ) {
    updateConsultationTest(
      consultationId: $consultationId
      id: $id
      type: $type
      input: $input
    ) {
      id
      reason
      type
      testId
    }
  }
`
