import { useMemo } from 'react'

import { COUNTRY_CODE } from '~/constants'
import { InputRankingListEntry } from '~/features/clinical-coding/ranking/types'
import {
  ClinicalCodeWeightingsQuery,
  useClinicalCodeWeightingsQuery,
} from '~/generated'

export type ClinicalCodeWeighting = ClinicalCodeWeightingsQuery['cp3ClinicalCodeWeightings'][number]

const parseCodesJSON = (str: string): InputRankingListEntry[] => {
  try {
    return str ? JSON.parse(str) : []
  } catch (err) {
    console.error(
      'Error parsing Clinical Code Weightings JSON. Please check content in CMS is valid JSON format.',
      err
    )
    return []
  }
}

export const useClinicalCodeWeightings = (countryCode?: string) => {
  const { data, loading, error } = useClinicalCodeWeightingsQuery({
    variables: {
      countryCode: countryCode || COUNTRY_CODE,
    },
  })

  const rankingList: InputRankingListEntry[] = useMemo(() => {
    const clinicalCodeWeightings = data?.cp3ClinicalCodeWeightings ?? []

    return parseCodesJSON(clinicalCodeWeightings[0]?.rankings)
  }, [data])

  return { rankingList, loading, error }
}
