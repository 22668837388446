import { setAuthConfig } from '@babylon/babylon-auth'

import requestIdGenerator from '~/core/request-id-generator'

export const initialise = () => {
  setAuthConfig({
    tokenRefreshPoint: 2 / 30, // 2 mins (assuming a 30 min token) - we dont think this value is used as we no longer call `kongTokenRefreshDue`
    axiosOpts: () => ({
      headers: {
        'babylon-request-id': requestIdGenerator.generate(),
      },
    }),
  })
}

export default initialise
