import { useRef, useState } from 'react'
import useComponentSize from 'use-resize-observer'

const useAccordion = (startOpen = false, onClick?: () => void) => {
  const [showAccordion, setShowAccordion] = useState<boolean>(startOpen)
  const wrapper = useRef<HTMLDivElement>(null)
  const { height } = useComponentSize({ ref: wrapper })

  const handleClick = () => {
    if (onClick) onClick()
    setShowAccordion(!showAccordion)
  }

  return {
    wrapper,
    handleClick,
    accordionHeight: showAccordion ? height || Number.MAX_VALUE : 0,
  }
}

export { useAccordion }
