import { ApolloError } from '@apollo/client'
import cn from 'classnames'
import { format } from 'date-fns'
import { getName, isValid } from 'i18n-iso-countries'
import React from 'react'

import { Text } from '@babylon/core-ui'
import { BabylonTimeAgo, useIntl } from '@babylon/intl'

import { ReactComponent as QuestionCircle } from '~/assets/question-circle.svg'
import { ReactComponent as TickCircle } from '~/assets/tick-circle.svg'
import { useMessages } from '~/core/hooks'
import {
  AppointmentCheckInDetailsQueryResult,
  CheckInStatus,
  ConsultationStatusV2,
} from '~/generated'

import messages from './AppointmentCheckIn.messages'
import styles from './styles.module.scss'

type CheckInResult =
  | NonNullable<
      AppointmentCheckInDetailsQueryResult['data']
    >['getAppointmentCheckIn']
  | null

export interface AppointmentCheckInViewProps {
  checkIn: CheckInResult
  checkInDate: Date | null
  isRecentCheckIn: boolean
  consultationId: string
  consultationStatus: ConsultationStatusV2
  error?: ApolloError
  displayLocation?: boolean
  displayDetailedError?: boolean
  className?: string
}

const AppointmentCheckInView = ({
  checkIn,
  checkInDate,
  isRecentCheckIn,
  consultationId,
  consultationStatus,
  error,
  displayLocation,
  displayDetailedError,
  className,
}: AppointmentCheckInViewProps) => {
  const { locale } = useIntl()
  const f = useMessages(messages)

  if (
    consultationStatus === ConsultationStatusV2.Completed ||
    consultationStatus === ConsultationStatusV2.Cancelled ||
    consultationStatus === ConsultationStatusV2.NoShow
  ) {
    return null
  }

  if (error) {
    return (
      <div
        className={cn(styles.checkIn, className)}
        data-testid={`consultation-${consultationId}-check-in-error`}
      >
        <div className={styles.detailed}>
          {displayDetailedError && (
            <QuestionCircle
              className={styles.checkInIcon}
              data-testid="check-in-error-icon"
            />
          )}
          <Text color="grey-type">
            <Text color="brand-orange">
              {f('check_in_status_not_available')},
            </Text>{' '}
            {f('cant_tell_if_the_patient_is_ready')}
          </Text>
        </div>
        {displayDetailedError && (
          <Text tag="div" color="grey-type" className={styles.errorDetails}>
            {f('you_can_still_call_the_patient')}
          </Text>
        )}
      </div>
    )
  }

  if (!checkInDate || checkIn?.status !== CheckInStatus.CheckedIn) {
    return null
  }

  const checkInTime = format(checkInDate, 'HH:mm')
  const checkInMessage = isRecentCheckIn
    ? f('detailed_check_in_information')
    : f('generic_check_in_information', { time: checkInTime })

  const checkInLocation = checkIn?.location ?? null
  const atRegisteredAddress = checkInLocation?.useRegisteredLocation
  const temporaryCountryCode =
    checkInLocation?.temporaryLocation?.countryIsoCode
  const temporaryCountry =
    temporaryCountryCode && isValid(temporaryCountryCode)
      ? getName(temporaryCountryCode, locale.replace(/-.*/, ''))
      : null
  const temporaryAddress =
    checkInLocation?.temporaryLocation?.address || f('unknown_location')

  return (
    <div
      className={cn(styles.checkIn, className)}
      data-testid={`consultation-${consultationId}-check-in`}
    >
      {isRecentCheckIn ? (
        <div className={styles.detailed}>
          <TickCircle className={styles.checkInIcon} />
          <Text>
            <strong>{checkInMessage}</strong> (
            <BabylonTimeAgo date={checkInDate} prefix={f('checked_in')} />)
          </Text>
        </div>
      ) : (
        <Text>{checkInMessage}</Text>
      )}
      {displayLocation && checkInLocation && (
        <Text tag="div" color="grey-type" className={styles.checkInLocation}>
          {atRegisteredAddress ? (
            f('at_registered_address')
          ) : (
            <Text>
              {f('at_temporary_location_not_registered_address', {
                location: temporaryAddress,
                country: temporaryCountry,
              })}
            </Text>
          )}
        </Text>
      )}
    </div>
  )
}

export default AppointmentCheckInView
