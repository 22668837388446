import { useFormatMessage } from '@babylon/intl'
import * as Access from '@babylon/web-platform-access'

import messages from './SchedulePageNavigationItem.messages'

export type SchedulePageNavigationItemModel = {
  granted: boolean
  label: string
}

const useSchedulePageNavigationItemViewModel = (): SchedulePageNavigationItemModel => {
  const fm = useFormatMessage()
  const [granted, error] = Access.useDecide('schedule-page')

  if (error) {
    throw error
  }

  const label = fm(messages.consultations_text)

  return { granted, label }
}

export default useSchedulePageNavigationItemViewModel
