import cn from 'classnames'
import React, { CSSProperties, useState } from 'react'

import { Heading, Spinner } from '@babylon/core-ui'

import ErrorBoundary from '~/core/ErrorBoundary'

import Modal from '../Modal'
import { ModalContext } from '../Modal/Modal'

import styles from './styles.module.scss'

type Position = 'left' | 'right'

export type SlideoutProps = {
  children: React.ReactNode | ((context: ModalContext) => React.ReactNode)
  headerPadding?: boolean
  loading?: boolean
  noPadding?: 'sides' | 'bottom' | 'sides-bottom'
  onClose?: Function
  onOpen?: Function
  position?: Position
  title?: React.ReactNode
  width?: string
  className?: CSSProperties
  visible?: boolean
  onVisibleChange?: React.Dispatch<React.SetStateAction<boolean>>
}

const loadingSpinner = (
  <Spinner testid="slideout-loading" size="medium" centered />
)

const Slideout = ({
  children,
  headerPadding = true,
  loading = false,
  noPadding,
  onClose,
  onOpen,
  position = 'right',
  title,
  width = '50%',
  className,
  visible: controlledVisible,
  onVisibleChange,
}: SlideoutProps) => {
  const [localVisible, setLocalVisible] = useState(true)
  let visible = localVisible
  let setVisible = setLocalVisible

  if (onVisibleChange) {
    visible = !!controlledVisible
    setVisible = onVisibleChange
  }

  const transition =
    position === 'right'
      ? 'slideout-right-transition'
      : 'slideout-left-transition'

  const getPaddingStyle = () => {
    switch (noPadding) {
      case 'sides':
        return { padding: '0px 0px 40px 0px' }
      case 'bottom':
        return { padding: '0px 40px' }
      case 'sides-bottom':
        return { padding: '0px' }
      default:
        return { padding: '0px 40px 40px 40px' }
    }
  }

  return (
    <Modal
      className={cn(styles.slideout, className)}
      visible={visible}
      onVisibleChange={setVisible}
      style={{ width }}
      transition={transition}
      onTransitionEntered={onOpen}
      onTransitionExited={onClose}
    >
      {(modal: ModalContext) => {
        const content =
          typeof children === 'function' ? children(modal) : children

        return (
          <>
            <div
              className={cn(
                styles.header,
                !headerPadding && styles.noHeaderPadding
              )}
            >
              {title && (
                <Heading level="h2" color="grey-type">
                  {title}
                </Heading>
              )}
              <div
                role="button"
                tabIndex={0}
                className={styles.closeButton}
                onClick={modal.closeModal}
                data-testid="modal-close-button"
              >
                <i className="fas fa-times" />
              </div>
            </div>
            <div className={styles.content} style={getPaddingStyle()}>
              <ErrorBoundary
                fill="container"
                center
                gaAction="generic_slideout" // TODO: create SlideoutRoute component to be able to customise action and title on a per-slideout basis
              >
                {loading ? loadingSpinner : content}
              </ErrorBoundary>
            </div>
          </>
        )
      }}
    </Modal>
  )
}

export default Slideout
