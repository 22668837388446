import { pickBy } from 'lodash'

import {
  initialise,
  trackEvent,
  trackView,
  UTAG,
} from '@babylon/tracking/tealium'

import {
  APP_VERSION,
  ENVIRONMENT,
  ENVIRONMENT_COUNTRY_ISO_CODE,
  TEALIUM_SCRIPT_URL,
} from '~/constants'

import { isTruthy } from '../tsUtils'
import { AnalyticsEventContextConfig, IAnalyticsParams } from './types'
import { injectTealiumScript, logAnalyticsDev } from './utils'

// In order to enable console log messages on local dev env for Tealium
// run the below code into the console once your server has started

// document.cookie="utagdb=true";

let initPromise: Promise<UTAG>

const env = ENVIRONMENT.split('-')[0]

const isLocalHost = (environment: string) => environment === 'localhost'

type TealiumEventContext = {
  app_environment: string
  app_name: string
  app_version: string
  country_iso_code: string
  [key: string]: string
}

let tealiumEventContext: TealiumEventContext &
  Partial<AnalyticsEventContextConfig> = {
  app_environment: env,
  app_name: 'clinical-portal',
  app_version: APP_VERSION,
  country_iso_code: ENVIRONMENT_COUNTRY_ISO_CODE,
}

const tealiumEventContextMap: {
  [key in keyof AnalyticsEventContextConfig]: string
} = {
  consultationId: 'appointment_id',
  consultationUuid: 'appointment_uuid',
  userUuid: 'consultant_uuid',
  page: 'screen_title',
}

const setTealiumField = (fields: Partial<AnalyticsEventContextConfig>) => {
  const result = {
    ...tealiumEventContext,
  }
  Object.entries(fields).forEach(([key, value]) => {
    const field =
      tealiumEventContextMap[key as keyof AnalyticsEventContextConfig]
    if (field && value) {
      result[field] = value
    }
  })
  tealiumEventContext = result
}

const clearTealiumField = (keys: string[] | string) => {
  const result = { ...tealiumEventContext }
  if (Array.isArray(keys)) {
    keys.forEach((key) => {
      const field =
        tealiumEventContextMap[key as keyof AnalyticsEventContextConfig]
      if (field) {
        delete result[field]
      }
    })
  } else {
    const field =
      tealiumEventContextMap[keys as keyof AnalyticsEventContextConfig]
    delete result[field]
  }
  tealiumEventContext = result
}

export const createTrackEvent = (tealiumUrl: string, environment: string) => ({
  category,
  action,
  label,
  value,
}: IAnalyticsParams) => {
  if (!tealiumUrl) {
    return
  }

  const payload = pickBy(
    {
      event_action: action,
      event_category: category,
      event_label: label,
      event_value: value,
      tealium_event: 'trackEvent',
    },
    isTruthy
  )

  try {
    if (isLocalHost(environment)) {
      logAnalyticsDev('Tealium trackEvent', {
        ...payload,
        ...tealiumEventContext,
      })
      return
    }
    trackEvent({
      ...(payload as any),
      ...tealiumEventContext,
    })
  } catch (error) {
    console.error(error)
  }
}

const trackTealiumEvent = createTrackEvent(TEALIUM_SCRIPT_URL, env)

export const createTrackTealiumPage = (environment: string) => () => {
  try {
    if (isLocalHost(environment)) {
      logAnalyticsDev('Tealium trackPage', {
        ...tealiumEventContext,
        tealium_event: 'trackPage',
      })
      return
    }
    trackView({
      ...(tealiumEventContext as any),
      tealium_event: 'trackPage',
    })
  } catch (error) {
    console.error(error)
  }
}

const trackTealiumPage = createTrackTealiumPage(env)

export const createTrackTealiumModal = (environment: string) => (
  name: string
) => {
  try {
    if (isLocalHost(environment)) {
      logAnalyticsDev('Tealium trackModal', {
        ...tealiumEventContext,
        tealium_event: 'trackModal',
        event_value: name,
      })
      return
    }
    trackView({
      ...(tealiumEventContext as any),
      tealium_event: 'trackModal',
      event_value: name,
    })
  } catch (error) {
    console.error(error)
  }
}

const trackTealiumModal = createTrackTealiumModal(env)

window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}

export const createTealiumInitialiser = (
  environment: string,
  injectedScript: (src: string) => Promise<UTAG>,
  countryCode: string,
  tealiumUrl?: string
) => async () => {
  if (!tealiumUrl) {
    return
  }
  if (isLocalHost(environment)) {
    logAnalyticsDev('Tealium not initialised for localHost')
    return
  }
  initPromise = injectedScript(tealiumUrl)
  try {
    const utag: UTAG = await initPromise
    initialise(utag, countryCode)
    logAnalyticsDev('Tealium initialised')
  } catch (e) {
    console.error('Cannot initialise Tealium')
  }
}

const initialiseTealium = createTealiumInitialiser(
  ENVIRONMENT,
  injectTealiumScript,
  ENVIRONMENT_COUNTRY_ISO_CODE,
  TEALIUM_SCRIPT_URL
)

export {
  initialiseTealium,
  trackTealiumEvent,
  trackTealiumPage,
  trackTealiumModal,
  setTealiumField,
  clearTealiumField,
  injectTealiumScript,
}
