import { defineMessages } from 'react-intl'

const baseId = 'error_boundary'

export default defineMessages({
  fallback_title: {
    id: `${baseId}.fallback_title`,
    defaultMessage: 'An error occurred, try again or reload the page',
  },
})
