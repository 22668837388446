import { gql } from '@apollo/client'

export const SuggestDrugDirectionsMutation = gql`
  mutation SuggestDrugDirectionsMutation(
    $drugId: ID
    $drugName: String!
    $dosage: String!
    $duration: String!
    $region: String!
  ) {
    suggestDrugDirections(
      drugId: $drugId
      drugName: $drugName
      dosage: $dosage
      duration: $duration
      region: $region
    ) {
      drugId
      drugName
      directions
      dosage
      duration
      quantity
      errorInDosage
      errorInDuration
      errorInDirections
    }
  }
`

export const ValidateDrugMutation = gql`
  mutation ValidateDrug(
    $patientId: ID!
    $drug: PrescriptionDrugInput!
    $consultationId: ID
    $region: String!
    $prescriptionDrugId: ID
  ) {
    validateDrug(
      patientId: $patientId
      drug: $drug
      consultationId: $consultationId
      region: $region
      prescriptionDrugId: $prescriptionDrugId
    ) {
      warnings {
        action
        message
        allowPrescribing
      }
    }
  }
`

const prescriptionFieldsFragmentModal = gql`
  fragment PrescriptionFieldsFragmentModal on Prescription {
    id
    createdAt
    prescriptionState
    drugs {
      prescriptionDrugId
      drugId
      drugName
      dosage
      duration
      packagingSize
      pharmaceuticalForm
      strength
      refills
      quantity
      directions
      notes
      repeatable
      reviewDate
      repeats
      indications {
        iri
        id
        name
      }
    }
  }
`

export const CreatePrescriptionMutation = gql`
  mutation CreatePrescriptionMutation(
    $consultationId: ID!
    $patientId: ID!
    $drugs: [PrescriptionDrugInput]
    $consumerNetwork: String!
    $region: String!
  ) {
    createPrescription(
      region: $region
      consultationId: $consultationId
      patientId: $patientId
      drugs: $drugs
      consumerNetwork: $consumerNetwork
    ) {
      ...PrescriptionFieldsFragmentModal
    }
  }
  ${prescriptionFieldsFragmentModal}
`

export const UpdatePrescriptionMutation = gql`
  mutation UpdateDrugInPrescription(
    $id: ID!
    $prescriptionDrugId: ID!
    $drug: PrescriptionDrugInput!
    $region: String!
  ) {
    updateDrugInPrescription(
      id: $id
      prescriptionDrugId: $prescriptionDrugId
      drug: $drug
      region: $region
    ) {
      ...PrescriptionFieldsFragmentModal
    }
  }
  ${prescriptionFieldsFragmentModal}
`

export const AddDrugToPrescriptionMutation = gql`
  mutation addDrugToPrescription(
    $id: ID!
    $drug: PrescriptionDrugInput!
    $region: String!
  ) {
    addDrugToPrescription(id: $id, drug: $drug, region: $region) {
      ...PrescriptionFieldsFragmentModal
    }
  }
  ${prescriptionFieldsFragmentModal}
`
