import React from 'react'
import { MessageDescriptor } from 'react-intl'

import { useFormatMessage } from '@babylon/intl'

import { GENERIC_ERROR_BOUNDARY_ACTION } from '~/constants/analytics'

import ErrorBoundary, { ErrorBoundaryProps } from './ErrorBoundary'

type WithErrorBoundaryProps = Pick<
  ErrorBoundaryProps,
  | 'className'
  | 'retry'
  | 'gaAction'
  | 'loading'
  | 'mini'
  | 'center'
  | 'fill'
  | 'description'
  | 'retryAlwaysVisible'
> & {
  titleDescriptor?: MessageDescriptor
}

/**
 * This is implemented as a HOC specifically to encourage developers to wrap the
 * top level of a component with the error boundary.  This ensures that rendering
 * errors that occur within any of the component rendering logic are caught
 */

const withErrorBoundary = (input?: WithErrorBoundaryProps) => <
  P extends object
>(
  Component: React.ComponentType<P>
): React.FunctionComponent<P> =>
  function WithErrorBoundary(props: P) {
    const fm = useFormatMessage()

    const {
      titleDescriptor,
      className,
      retry,
      gaAction,
      loading,
      mini,
      center,
      fill,
      description,
      retryAlwaysVisible,
    } = input || {}

    return (
      <ErrorBoundary
        className={className}
        title={titleDescriptor && fm(titleDescriptor)}
        description={description}
        gaAction={gaAction || GENERIC_ERROR_BOUNDARY_ACTION}
        retry={retry}
        retryAlwaysVisible={retryAlwaysVisible}
        loading={loading}
        mini={mini}
        center={center}
        fill={fill}
      >
        <Component {...(props as P)} />
      </ErrorBoundary>
    )
  }

export default withErrorBoundary
