import { Location } from 'history'
import React, { useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'

import { useConsultantUser } from '@babylon/babylon-user'

import analytics from '~/core/analytics'
import { RoutesInterface } from '~/core/config/modules/generated/types'

import Page404 from './Page404'

/**
 * Returns list of Routes
 */
const Routes: RoutesInterface = ({ defaultRoute, routes }) => {
  const location = useLocation()
  const prevLocationRef = useRef<Location>()

  const user = useConsultantUser()

  useEffect(() => {
    if (location !== prevLocationRef.current) {
      analytics.setPage(location.pathname)
      analytics.trackPage(location.pathname)
      prevLocationRef.current = location
    }
  }, [location])

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <Redirect to={defaultRoute} />
        </Route>
        {routes.map(({ path, route }) => {
          return (
            <Route key={path} path={path}>
              {route}
            </Route>
          )
        })}
        {/* limit iframe urls - will redirect link clicks or /admin urls to availability page */}
        <Route path="/admin">
          <Redirect to={`/admin/consultants/${user.id}/availability`} />
        </Route>
        <Route component={Page404} />
      </Switch>
    </>
  )
}

export default Routes
