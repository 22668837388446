import { defineMessages } from 'react-intl'

export const ERROR_MESSAGES_BASE_ID = 'consultation.video_player'

export default defineMessages({
  hardware: {
    id: `${ERROR_MESSAGES_BASE_ID}.hardware`,
    defaultMessage:
      'Unable to access the camera or microphone. Please check your permissions in your browser settings.',
  },
  hardware_in_use: {
    id: `${ERROR_MESSAGES_BASE_ID}.hardware_in_use`,
    defaultMessage: 'Audio or Video is being used by another application',
  },
  contact_support: {
    id: `${ERROR_MESSAGES_BASE_ID}.contact_support`,
    defaultMessage: 'Unable to connect to video call service. Contact support.',
  },
  try_again: {
    id: `${ERROR_MESSAGES_BASE_ID}.try_again`,
    defaultMessage:
      'Unable to connect to video call service. Please try reloading the page.',
  },
  network_try_again: {
    id: `${ERROR_MESSAGES_BASE_ID}.network_try_again`,
    defaultMessage: 'Poor internet connection.',
  },
  media_not_supported: {
    id: `${ERROR_MESSAGES_BASE_ID}.media_not_supported`,
    defaultMessage: 'Unable to make video or audio calls with this device.',
  },
  browser_support: {
    id: `${ERROR_MESSAGES_BASE_ID}.browser_support`,
    defaultMessage: 'Unable to make video or audio calls with this browser.',
  },
  chrome_error: {
    id: `${ERROR_MESSAGES_BASE_ID}.chrome_error`,
    defaultMessage:
      'Cannot access your microphone with this version of Chrome.',
  },
  other_party_diconnected: {
    id: `${ERROR_MESSAGES_BASE_ID}.other_party_diconnected`,
    defaultMessage: 'The other party has disconnected.',
  },
  session_limit_reached: {
    id: `${ERROR_MESSAGES_BASE_ID}.session_limit_reached`,
    defaultMessage: 'Cannot join this call.',
  },
})
