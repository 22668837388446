import { $ref, Config } from './generated/lite-standard'

import messages from '../messages/config.messages'

const config: Config = {
  main: {
    module: 'Main',
    options: {
      layout: $ref('layout'),
    },
  },

  layout: {
    module: 'Layout',
    reactElement: true,
    options: {
      header: $ref('header'),
      navigation: $ref('navigation'),
      routes: $ref('routes'),
    },
  },

  routes: {
    module: 'Routes',
    reactElement: true,
    options: {
      defaultRoute: '/consultations',
      routes: [
        {
          path: '/consultations/:date?/:status?',
          route: $ref('schedulePage'),
        },
        {
          path: '/consultation/:id',
          route: $ref('consultationPage'),
        },
      ],
    },
  },

  header: {
    module: 'Header',
    reactElement: true,
    options: {
      scheduleEnabled: true,
      clinicianRatingEnabled: true,
      languageSelectorEnabled: true,
      telusLogo: false,
      supportOptions: ['clinops', 'technology-help'],
      ConsultationScheduleItem: $ref('consultationScheduleItem'),
      consultationContext: $ref('consultationContext'),
    },
  },

  navigation: {
    module: 'Navigation',
    options: {
      items: [
        $ref('schedulePageNavigationItem'),
        '---DIVIDER---',
        $ref('linksAndResources'),
      ],
    },
  },

  schedulePage: {
    module: 'SchedulePage',
    options: {
      ConsultationScheduleItem: $ref('consultationScheduleItem'),
    },
    reactElement: true,
  },

  consultationScheduleItem: {
    module: 'ConsultationScheduleItemPlugin',
    options: {
      hideIncompleteConsultationNotifications: true,
    },
  },

  schedulePageNavigationItem: {
    module: 'SchedulePageNavigationItem',
    options: {
      path: '/consultations',
    },
    reactElement: true,
  },

  consultationPage: {
    module: 'ConsultationLitePage',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      main: $ref('preparationSectionGroup'),
      call: $ref('call'),
      context: $ref('consultationProfile'),
      actions: $ref('consultationActionsPlugin'),
      completeDialog: $ref('completeDialogPlugin'),
      eventBus: $ref('eventBus'),
      providers: [$ref('callStatusReducerModelProvider')],
    },
  },

  consultationProfile: {
    module: 'ConsultationProfilePlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      eventBus: $ref('eventBus'),
      requestedTranslationEnabled: false,
      patientRecordDeepLink: $ref('patientRecordDeepLink'),
    },
  },

  patientRecordDeepLink: {
    module: 'PatientRecordDeepLinkPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  callStatusReducerModelProvider: {
    module: 'CallStatusReducerModelProvider',
    options: {
      callMedium: 'voice_and_video',
    },
  },

  call: {
    module: 'CallPlugin',
    reactElement: true,
    options: {
      callStatusReducerModelProvider: $ref('callStatusReducerModelProvider'),
      consultationContext: $ref('consultationContext'),
      callQualityFeedbackEnabled: true,
      callMedium: 'voice_and_video',
      recordingEnabled: true,
    },
  },

  bookingReason: {
    module: 'BookingReasonPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
    },
  },

  preparationSectionGroup: {
    module: 'SectionGroupPlugin',
    reactElement: true,
    options: {
      title: messages.preparation_section_group_title,
      titleColor: 'var(--brand-color-purple)',
      borderColor: 'var(--brand-color-purple)',
      children: [$ref('bookingReason'), $ref('patientDetails')],
    },
  },

  patientDetails: {
    module: 'PatientDetailsPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      hideIdVerificationEnabled: false,
    },
  },

  completeConsultationModel: {
    module: 'CompleteConsultationModel',
    options: {
      consultationContext: $ref('consultationContext'),
      extensions: [],
    },
  },

  callActiveModalPlugin: {
    module: 'CallActiveModalPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      redirectUrl: '/consultation/:id/review',
      stayOnPageUrl: '/consultation/:id',
    },
  },

  consultationActionsPlugin: {
    module: 'ConsultationActionsPlugin',
    reactElement: true,
    options: {
      reviewEnabled: false,
      completeConsultationModel: $ref('completeConsultationModel'),
      consultationContext: $ref('consultationContext'),
      callStatusReducerModelProvider: $ref('callStatusReducerModelProvider'),
      callActiveModal: $ref('callActiveModalPlugin'),
    },
  },

  completeDialogPlugin: {
    module: 'CompleteDialogPlugin',
    reactElement: true,
    options: {
      consultationContext: $ref('consultationContext'),
      completeConsultationModel: $ref('completeConsultationModel'),
      extensions: [],
    },
  },

  linksAndResources: {
    module: 'LinksAndResourcesPlugin',
    reactElement: true,
  },
}

export default config
