import { defineMessages } from 'react-intl'

const baseId = 'components.care_plan_section'

export default defineMessages({
  cant_load_care_plan: {
    id: `${baseId}.cant_load_care_plan`,
    defaultMessage: "We can't load the care plan right now",
  },
  cant_load_care_plan_draft: {
    id: `${baseId}.cant_load_care_plan_draft`,
    defaultMessage: "We can't load the care plan draft right now",
  },
  slideout_title: {
    id: `${baseId}.slideout_title`,
    defaultMessage: 'Health goals and actions',
  },
  feature_not_available: {
    id: `${baseId}.feature_not_available`,
    defaultMessage: 'This content is not visible for certain roles.',
  },
  draft_in_flight_title: {
    id: `${baseId}.draft_in_flight_title`,
    defaultMessage: 'You cannot edit this care plan at the moment',
  },
  draft_in_flight_paragraph_1: {
    id: `${baseId}.draft_in_flight_paragraph_1`,
    defaultMessage:
      'Another clinician is editing this care plan in an incomplete consultation.',
  },
  draft_in_flight_paragraph_2: {
    id: `${baseId}.draft_in_flight_paragraph_2`,
    defaultMessage:
      'If you need to edit this care plan, please contact the Senior Medical Team.',
  },
  revision_already_published_in_consultation_title: {
    id: `${baseId}.revision_already_published_in_consultation_title`,
    defaultMessage: 'This updated care plan is already published',
  },
  revision_already_published_in_consultation_paragraph_1: {
    id: `${baseId}.revision_already_published_in_consultation_paragraph_1`,
    defaultMessage:
      'Your changes to this care plan have already been shared with the patient. You can’t update it again as part of this consultation.',
  },
})
