import React from 'react'

import { ScheduleCheckInPluginInterface } from '~/core/config/modules/generated/types'
import { ConsultationStatusV2 } from '~/generated'

import AppointmentCheckInContainer from './AppointmentCheckInContainer'

import styles from './styles.module.scss'

export interface ScheduleCheckInProps {
  consultationId: string
  scheduledTime: string
  consultationStatus: ConsultationStatusV2
}

const ScheduleCheckInModule: ScheduleCheckInPluginInterface = ({
  displayLocation,
  displayDetailedError,
}) => {
  const ScheduleCheckInWrapper = ({
    consultationId,
    scheduledTime,
    consultationStatus,
  }: ScheduleCheckInProps) => {
    return (
      <AppointmentCheckInContainer
        consultationId={consultationId}
        scheduledTime={scheduledTime}
        consultationStatus={consultationStatus}
        displayLocation={displayLocation}
        displayDetailedError={displayDetailedError}
        className={styles.scheduleCheckIn}
      />
    )
  }

  return ScheduleCheckInWrapper
}

export default ScheduleCheckInModule
