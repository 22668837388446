import { withHandlers } from 'recompose'

import { errorOptions } from '~/ui/ErrorMessage'

import { ReferralsSpecialistsQuery } from './queries'

const handleCategoryChange = ({ updateState }) => (category) =>
  updateState({ category, specialism: null })

const handleSpecialismChange = ({ updateState }) => (specialism) =>
  updateState({ specialism })

const handleCommentsChange = ({ updateState }) => (comments) =>
  updateState({ comments })

const handlePostcodeChange = ({ updateState }) => (event) => {
  updateState({
    postcode: event.target.value,
  })
}

const handleSpecialistChange = ({ updateState }) => (specialist) =>
  updateState({ specialist })

const handleUrgentChange = ({ updateState, state }) => () => {
  updateState({ urgent: !state.urgent })
}

const formatResponseToOption = (item) => ({
  practitionerId: item.practitioner.id,
  practitionerName: item.practitioner.name,
  ...item,
})

const handleSpecialistLookup = ({
  data,
  updateState,
  state,
  client,
}) => async () => {
  const { consultation } = data
  const input = {
    practitioner: {
      language: 'English',
    },
    location: {
      postalCode: state.postcode,
      country: consultation.region.iso_code,
    },
    coding: {
      system: 'https://bbl.health/yoy9PRF35E',
      display: state.specialism.name,
    },
  }
  updateState({ isLookupLoading: true })

  try {
    const { data: referralSpecialistsData } = await client.query({
      query: ReferralsSpecialistsQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    })

    const options = referralSpecialistsData.referralSearch.map(
      formatResponseToOption
    )

    updateState({
      options,
      isLookupLoading: false,
    })
  } catch (error) {
    updateState({
      isLookupLoading: false,
      options: errorOptions,
    })
  }
  updateState({
    showSpecialistSelection: true,
  })
}
export default withHandlers({
  handleCategoryChange,
  handleSpecialismChange,
  handleCommentsChange,
  handlePostcodeChange,
  handleSpecialistLookup,
  handleSpecialistChange,
  handleUrgentChange,
})
