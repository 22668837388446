import { useEffect, useRef } from 'react'

import useSetState from './useSetState'

/**
 * Extends `useSetState` with a check to prevent the no-op "Can't call setState
 * on an unmounted component".  To avoid bugs and memory leaks.
 * This is the equivalent of setting _isMounted on componentDidMount and
 * unsetting _isMounted on componentWillUnmount in a regular class component
 * @param {Object} initialState
 * @return {useSetStateResult}
 * @author Christian Allen
 */

function useSafeSetState(initialState) {
  const [state, setState] = useSetState(initialState)

  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => (mountedRef.current = false)
  }, [])

  const safeSetState = (...args) => mountedRef.current && setState(...args)
  return [state, safeSetState]
}

export default useSafeSetState
