import { logException } from '~/core/sentry'
import { notify } from '~/ui/Notification'

import { CLINICAL_CODE } from './constants'

const END_WITH_ALPHANUMERIC = /([\w|-]+)$/
const START_WITH_PUNCTUATION = /^([\s|.|?|!]+)/
/**
 * Returns string when:
 * - Cursor is followed by whitespace, punctuation mark or EOL and;
 * - Cursor is preceded by 2 alphanumeric characters and;
 * - Text is not selected (selection width is 0)
 *
 * @param   {Value} value - Slate editor state
 * @returns {String}      - Returns a preceding word if autocomplete should be
 *                          shown, otherwise null
 */
export const getFocusedWord = (value, requiredCharacters = 0) => {
  /**
   * In some cases, like if the node that was selected gets deleted,
   * `startText` can be null.
   */
  if (!value.startText) {
    return null
  }
  /**
   * If there is any text selected, we should not show autocomplete
   */
  if (value.fragment.text.length > 0) {
    return null
  }

  const { text } = value.startText
  const offsetStart = value.selection.start.offset

  const head = text.slice(0, offsetStart)
  const tail = text.slice(offsetStart)
  /**
   * Check if next character is whitespace or punctuation, or does not exist.
   */
  const isFollowedByPunctuationMarks =
    tail.length === 0 || !!tail.match(START_WITH_PUNCTUATION)
  /**
   * Get previous word. If exists - will be returned from the function
   */
  const precedingWord = head.match(END_WITH_ALPHANUMERIC)
  /**
   * Check if previous word is long enough to show autocomplete and if it is
   * normalized word (does not contain special symbols except "-" and "_")
   */
  const isPrecededByAlphaNumeric =
    head.length > requiredCharacters && !!precedingWord

  return isFollowedByPunctuationMarks && isPrecededByAlphaNumeric
    ? precedingWord[1]
    : null
}

export const isClinicalCodeBlock = (node) => {
  return node.object === 'inline' && node.type === CLINICAL_CODE
}

export const validateClinicalCode = (term, code) => {
  if (!term || !code) {
    const errorMessage = `The clinical code with term: "${
      term || 'unknown'
    }" and code: "${code || 'unknown'}" is missing`
    const error = new Error(errorMessage)

    notify({
      title: 'Missing clinical code',
      message: errorMessage,
      type: 'error',
    })
    logException(error)

    return false
  }
  return true
}
