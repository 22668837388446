import { initContainer } from '~/core/container'
import ReactTransform from '~/core/container/react-transform'

import { observableValue } from '../observable'
import ConfigEditor from './components/ConfigEditor'
import Layout from './components/Layout'

const config = {
  layout: {
    module: 'Layout',
    options: {
      main: { $ref: 'configEditor' },
    },
    reactElement: true,
  },

  configEditor: {
    module: 'ConfigEditor',
    options: {
      configDataContext: { $ref: 'configDataContext' },
    },
    reactElement: true,
  },

  configDataContext: {
    module: 'DataContext',
  },
}

export const { resolve } = initContainer(
  config as any,
  {
    Layout,
    ConfigEditor,
    DataContext: observableValue,
  },
  {
    transforms: [ReactTransform],
  }
)
