import {
  ADD_REFERRAL_ACTION,
  ERROR_ADD_REFERRAL_ACTION,
  ERROR_UPDATE_REFERRAL_ACTION,
  REFERRAL_ACTIONS_CATEGORY,
  UPDATE_REFERRAL_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics/analytics'
import { createQueryUpdater } from '~/core/graphql'

import { mapStateToReferral } from './mappings'
import { ReferralsQuery } from './queries'

export const truthy = (...args) => {
  return args.every(Boolean)
}

const referralsUpdater = createQueryUpdater(ReferralsQuery)

const addReferralToCache = (cache, response) => {
  const referral = response.data.createConsultationReferral
  const referrals = cache.consultation.referrals || []

  return {
    ...cache,
    consultation: {
      ...cache.consultation,
      referrals: [...referrals, referral],
    },
  }
}

const existingReferralSpecialisms = (data) => {
  const { consultation: { referrals = [] } = {} } = data

  return referrals.reduce(
    (ids, referral) => [...ids, referral.specialismId],
    []
  )
}

export const createReferral = (
  data,
  state,
  user,
  query,
  withValidState,
  setShowDuplicateDialog,
  createReferralMutation,
  redirectToConsultation,
  errorAlert,
  markFinalizedConsultationAsEdited
) => {
  withValidState(state, async () => {
    const existingSpecialisms = existingReferralSpecialisms(data)

    if (existingSpecialisms.includes(state.specialism.id)) {
      setShowDuplicateDialog(true)

      return
    }

    const input = mapStateToReferral(state)
    const specialismName = state.specialism?.name
    const {
      consultation: { id: consultationId },
    } = data
    const consultantId = user?.consultant?.uuid

    try {
      await createReferralMutation({
        variables: {
          consultationId,
          input,
        },
        update: referralsUpdater({ consultationId }, addReferralToCache),
      })
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      analytics.trackEvent({
        action: ADD_REFERRAL_ACTION,
        category: REFERRAL_ACTIONS_CATEGORY,
        label: specialismName,
        consultantId,
        consultationId,
      })
      redirectToConsultation()
    } catch (exception) {
      analytics.trackEvent({
        action: ERROR_ADD_REFERRAL_ACTION,
        category: REFERRAL_ACTIONS_CATEGORY,
        label: specialismName,
        consultantId,
        consultationId,
      })
      errorAlert({ logMessage: exception })
    }
  })
}

export const updateReferral = (
  data,
  state,
  user,
  query,
  withValidState,
  updateReferralMutation,
  redirectToConsultation,
  setShowDuplicateDialog,
  errorAlert,
  markFinalizedConsultationAsEdited
) => {
  withValidState(state, async () => {
    const existingSpecialisms = existingReferralSpecialisms(data)

    if (existingSpecialisms.includes(state.specialism.id)) {
      setShowDuplicateDialog(true)

      return
    }

    const input = mapStateToReferral(state)
    const specialismName = state.specialism?.name
    const {
      consultation: { id: consultationId },
    } = data
    const consultantId = user?.consultant?.uuid
    const referralId = query.id

    try {
      await updateReferralMutation({
        variables: {
          referralId,
          input,
        },
      })
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      analytics.trackEvent({
        action: UPDATE_REFERRAL_ACTION,
        category: REFERRAL_ACTIONS_CATEGORY,
        label: specialismName,
        consultantId,
        consultationId,
      })
      redirectToConsultation()
    } catch (exception) {
      analytics.trackEvent({
        action: ERROR_UPDATE_REFERRAL_ACTION,
        category: REFERRAL_ACTIONS_CATEGORY,
        label: specialismName,
        consultantId,
        consultationId,
      })
      errorAlert({ logMessage: exception })
    }
  })
}
