import { useMutation } from '@apollo/client'
import { Form, Formik, FormikHelpers } from 'formik'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'

import {
  Button,
  FormikTextarea,
  LegacyFormikDropdown,
  LegacyFormikDropdownOption,
  Text,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  ADD_TEST_ACTION,
  ERROR_ADD_TEST_ACTION,
  ERROR_UPDATE_TEST_ACTION,
  TEST_ACTIONS_CATEGORY,
  UPDATE_TEST_ACTION,
} from '~/constants/analytics'
import analytics from '~/core/analytics/analytics'
import { usePermissions } from '~/core/permissions'
import { logException } from '~/core/sentry'
import { useConsultationId } from '~/features/consultation'
import { useRedirectToConsultation } from '~/features/consultation/utils'
import { useModalTestTypesQuery, useTestsQuery } from '~/generated'
import { Slideout } from '~/ui/Modal'
import Tooltip from '~/ui/Tooltip'

import { PATHOLOGY_TEST_TYPE } from './constants'
import { CREATE_TEST_MUTATION, UPDATE_TEST_MUTATION } from './mutations'
import {
  getTestName,
  getTestTypeOptions,
  getTestTypes,
  mapStateToTest,
  mapTestToState,
  TestFormState,
  validate,
} from './utils'

import styles from './styles.module.scss'
import messages from './Tests.messages'

type TestModalProps = {
  markFinalizedConsultationAsEdited?: () => void
}

const Test = ({ markFinalizedConsultationAsEdited }: TestModalProps) => {
  const onClose = useRedirectToConsultation()

  const [enableDiagnosisTestTypes, hideActionTooltips] = usePermissions(
    'see_diagnosis_test_types',
    'hide_action_tooltips'
  )
  const fm = useFormatMessage()
  const consultationId = useConsultationId()
  const { search } = useLocation()
  const { mode } = useParams<{ mode: 'create' | 'edit' }>()
  const [initialFormState, setInitialFormState] = useState<TestFormState>({
    testType: PATHOLOGY_TEST_TYPE,
    reason: '',
    test: null,
  })

  const { data: types, loading: testTypesLoading } = useModalTestTypesQuery()

  const {
    data: {
      // @ts-expect-error
      consultation: { pathologyTests = [], diagnosticTests = [] } = {},
    } = {},
    loading: testsLoading,
    refetch,
  } = useTestsQuery({
    variables: { consultationId },
    notifyOnNetworkStatusChange: true,
  })

  const isInCreateMode = mode === 'create'
  const query = qs.parse(search.replace('?', ''))

  const [
    createTestMutation,
    { loading: createTestLoading, error: createTestError },
  ] = useMutation(CREATE_TEST_MUTATION, {
    onCompleted: async () => {
      await refetch()
      onClose()
    },
  })

  const [
    updateTestMutation,
    { loading: updateTestLoading, error: updateTestError },
  ] = useMutation(UPDATE_TEST_MUTATION, {
    onCompleted: async () => {
      await refetch()
      onClose()
    },
  })

  const handleSubmit = async (values: TestFormState) => {
    const input = mapStateToTest(values)
    const testName = getTestName(values.test, values.testType, types)
    const actionMutation = isInCreateMode
      ? createTestMutation
      : updateTestMutation
    const eventAction = isInCreateMode ? ADD_TEST_ACTION : UPDATE_TEST_ACTION
    const eventErrorAction = isInCreateMode
      ? ERROR_ADD_TEST_ACTION
      : ERROR_UPDATE_TEST_ACTION
    const variables = isInCreateMode
      ? {
          consultationId,
          input,
        }
      : {
          consultationId,
          id: query.id,
          type: query.type,
          input,
        }

    try {
      await actionMutation({
        variables,
      })
      if (markFinalizedConsultationAsEdited) {
        markFinalizedConsultationAsEdited()
      }
      analytics.trackEvent({
        action: eventAction,
        category: TEST_ACTIONS_CATEGORY,
        label: testName,
      })
    } catch (exception) {
      analytics.trackEvent({
        action: eventErrorAction,
        category: TEST_ACTIONS_CATEGORY,
        label: testName,
      })

      logException(exception)
    }
  }

  const handleTestTypeChange = (
    option: LegacyFormikDropdownOption | null,
    setFieldValue: FormikHelpers<TestFormState>['setFieldValue']
  ) => {
    setFieldValue('test', null)
    setFieldValue('reason', '')
  }

  const reasonTooltip = hideActionTooltips ? null : fm(messages.reason_tooltip)
  const availableTestTypeOptions = getTestTypeOptions(
    fm,
    enableDiagnosisTestTypes
  )
  const loading = createTestLoading || updateTestLoading
  const error = createTestError || updateTestError

  useEffect(() => {
    if (!isInCreateMode && !initialFormState.test) {
      const tests = [...pathologyTests, ...diagnosticTests]
      const currentTest = tests.find((v) => v.id === query.id)
      if (!currentTest) return

      setInitialFormState(mapTestToState(fm, currentTest, types))
    }
  }, [
    isInCreateMode,
    query.id,
    types,
    fm,
    initialFormState.test,
    pathologyTests,
    diagnosticTests,
  ])

  return (
    <Slideout
      title={fm(messages.title_private)}
      loading={testTypesLoading || testsLoading}
      onClose={onClose}
    >
      <Formik
        key={consultationId}
        initialValues={initialFormState}
        onSubmit={handleSubmit}
        validate={validate(fm)}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          const isReasonDisabled = values.testType === PATHOLOGY_TEST_TYPE
          const testOptions = getTestTypes(types, values.testType) || []

          return (
            <Form>
              <LegacyFormikDropdown
                id="testType"
                name="testType"
                label={fm(messages.test_options_label)}
                placeholder={fm(messages.test_type_options_placeholder)}
                options={availableTestTypeOptions}
                clearable={false}
                onChange={(testType) =>
                  handleTestTypeChange(testType, setFieldValue)
                }
                data-testid="test-category"
              />
              <LegacyFormikDropdown
                id="test"
                name="test"
                labelKey="name"
                valueKey="testType"
                label={fm(messages.test_type_options_label)}
                placeholder={fm(messages.test_options_placeholder)}
                options={testOptions}
                clearable={false}
                searchable
                data-testid="test-type"
              />
              {!isReasonDisabled && (
                <FormikTextarea
                  id="reason"
                  name="reason"
                  label={
                    <>
                      {fm(messages.reason_label)}{' '}
                      <Tooltip info={reasonTooltip} />
                    </>
                  }
                  placeholder={fm(messages.reason_placeholder)}
                  data-testid="test-reason"
                />
              )}

              <Button inline intent="secondary" onClick={onClose}>
                {fm(messages.cancel_button_text)}
              </Button>
              <Button inline type="submit" loading={loading}>
                {isInCreateMode
                  ? fm(messages.create_button_text)
                  : fm(messages.update_button_text)}
              </Button>

              {error && (
                <Text tag="div" color="error" className={styles.submitError}>
                  {fm(messages.submit_error)}
                </Text>
              )}
            </Form>
          )
        }}
      </Formik>
    </Slideout>
  )
}

export default Test
