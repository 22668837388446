import { gql } from '@apollo/client'

export const CreateAppointmentInviteMutation = gql`
  mutation CreateAppointmentInvite($input: AppointmentInviteInput!) {
    createAppointmentInvite(input: $input) {
      id
      notes_for_member
      preferredMedium: preferred_medium
      service_type {
        id
        name
        grouping
      }
    }
  }
`
export const CreateAppointmentWorkflowMutation = gql`
  mutation CreateAppointmentWorkflow(
    $appointment: WorkflowAppointmentActionInput!
    $data: WorkflowInput!
  ) {
    createWorkflow(appointment: $appointment, data: $data) {
      id
      status
      type
      subType {
        value
        label
      }
    }
  }
`
