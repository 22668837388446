import { ElementType } from 'react'

import {
  FormikDatePicker,
  FormikInput,
  FormikNumberInput,
  FormikRadioGroup,
  FormikTextarea,
} from '@babylon/core-ui'

import { WorkflowDefinitionVariableType } from '~/generated'

export const FIELD_TYPES: {
  [key in WorkflowDefinitionVariableType]: ElementType
} = {
  STRING: FormikInput,
  LONG: FormikNumberInput,
  DOUBLE: FormikNumberInput,
  BOOLEAN: FormikRadioGroup,
  DATE: FormikDatePicker,
  DATE_TIME_TZ: FormikDatePicker,
  MULTI_LINE: FormikTextarea,
}

export const LOAD_OPTIONS_DEBOUNCE = 800
