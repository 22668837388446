import { defineMessages } from 'react-intl'

const baseId = 'utils.error_alerts'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Error',
  },
  error_id: {
    id: `${baseId}.error_id`,
    defaultMessage: 'Error ID: {eventId}',
  },
  internal_server_error: {
    id: `${baseId}.internal_server_error`,
    defaultMessage: 'We are sorry, an internal server error has occurred.',
  },
  please_reload: {
    id: `${baseId}.please_reload`,
    defaultMessage: 'Please reload the page and try again later.',
  },
  reload: {
    id: `${baseId}.reload`,
    defaultMessage: 'Reload',
  },
  okay: {
    id: `${baseId}.okay`,
    defaultMessage: 'OK',
  },
  no_access_action: {
    id: `${baseId}.no_access_action`,
    defaultMessage: "You don't have access to perform this action.",
  },
})
