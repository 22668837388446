import { defineMessages } from 'react-intl'

const baseId = 'consultation.modal.prescription'

export default defineMessages({
  title_private: {
    id: `${baseId}.title_private_v2`,
    defaultMessage: 'Private prescription',
  },
  consultation_fetch_error: {
    id: `${baseId}.title_private_error`,
    defaultMessage: 'Error fetching consultation data',
  },
  update_patient_details_alert_message: {
    id: `${baseId}.update_patient_details_alert_message_v2`,
    defaultMessage:
      "Please go to the 'Update patient details' dialog and add the missing information.",
  },
  drug_select_label: {
    id: `${baseId}.drug_select_label`,
    defaultMessage: 'Drug*',
  },
  drug_select_must_select_error_message: {
    id: `${baseId}.drug_select_must_select_error_message`,
    defaultMessage: 'You must select a drug',
  },
  drug_select_active_ingredients_message: {
    id: `${baseId}.drug_select_active_ingredients_message`,
    defaultMessage: 'Active ingredient: {ingredients}',
  },
  package_size_label: {
    id: `${baseId}.package_size_label_v2`,
    defaultMessage: 'Package size',
  },
  package_size_option_none_avaliable_label: {
    id: `${baseId}.package_size_option_none_avaliable_label`,
    defaultMessage: 'Package sizes are not available',
  },
  package_size_placeholder: {
    id: `${baseId}.package_size_placeholder`,
    defaultMessage: 'Select package size',
  },
  package_size_placeholder_no_sizes_available: {
    id: `${baseId}.package_size_placeholder_no_sizes_available`,
    defaultMessage: 'Package sizes are not available',
  },
  dose_select_label: {
    id: `${baseId}.dose_select_label`,
    defaultMessage: 'Dose',
  },
  dose_select_tooltip_message: {
    id: `${baseId}.dose_select_tooltip_message`,
    defaultMessage:
      'If this is a variable or complex prescription type instructions into the directions field below',
  },
  duration_field_label: {
    id: `${baseId}.duration_field_label`,
    defaultMessage: 'Duration in days',
  },
  duration_field_placeholder: {
    id: `${baseId}.duration_field_placeholder`,
    defaultMessage: 'e.g. 10 days',
  },
  directions_for_patient_label: {
    id: `${baseId}.directions_for_patient_label`,
    defaultMessage: 'Directions for patient*',
  },
  directions_for_patient_placeholder: {
    id: `${baseId}.directions_for_patient_placeholder`,
    defaultMessage: 'e.g. take 2 tablets once a day for 10 days',
  },
  directions_for_patient_generate_button_label: {
    id: `${baseId}.directions_for_patient_generate_button_label`,
    defaultMessage: 'Generate',
  },
  directions_for_patient_required_error_message: {
    id: `${baseId}.directions_for_patient_required_error_message`,
    defaultMessage: 'You must specify the directions',
  },
  quantity_label: {
    id: `${baseId}.quantity_label`,
    defaultMessage: 'Quantity*',
  },
  quantity_placeholder: {
    id: `${baseId}.quantity_placeholder`,
    defaultMessage: 'e.g. 10',
  },
  quanity_calculate_button_label: {
    id: `${baseId}.quanity_calculate_button_label`,
    defaultMessage: 'Calculate',
  },
  quantity_must_be_above_zero_error_message: {
    id: `${baseId}.quantity_must_be_above_zero_error_message`,
    defaultMessage: 'You must enter a numeric quantity greater than zero',
  },
  refills_label: {
    id: `${baseId}.refills_label`,
    defaultMessage: 'Refills',
  },
  indication_label: {
    id: `${baseId}.indication_label`,
    defaultMessage: 'Indication*',
  },
  indication_placeholder: {
    id: `${baseId}.indication_placeholder_v2`,
    defaultMessage: 'e.g. Search for indications',
  },
  indication_tooltip_message: {
    id: `${baseId}.indication_tooltip_message`,
    defaultMessage:
      'Use this to record information for the patient. This information is NOT represented on the prescription sent to the Pharmacist.',
  },
  indication_must_select_one_error_message: {
    id: `${baseId}.indication_must_select_one_error_message`,
    defaultMessage: 'You must select at least one indication',
  },
  cancel_button_label: {
    id: `${baseId}.cancel_button_label`,
    defaultMessage: 'Cancel',
  },
  submit_button_create_label: {
    id: `${baseId}.submit_button_create_label`,
    defaultMessage: 'Create',
  },
  submit_button_save_label: {
    id: `${baseId}.submit_button_save_label`,
    defaultMessage: 'Save',
  },
  submit_button_override_label: {
    id: `${baseId}.submit_button_override_label`,
    defaultMessage: 'Override',
  },
  remove_package_size_option_label: {
    id: `${baseId}.remove_package_size_option_label`,
    defaultMessage: 'Remove package size',
  },
  missing_consent_warning: {
    id: `${baseId}.missing_consent_warning`,
    defaultMessage:
      'I confirm that I’m happy to prescribe without this patient’s consent to share this information with their GP*',
  },
  missing_consent_alert: {
    id: `${baseId}.missing_consent_alert`,
    defaultMessage: 'Please confirm before continuing',
  },
})
