import cn from 'classnames'
import React from 'react'

export const SELECTION_MARK_TYPE = 'SELECTION_MARK_TYPE'
export const SELECTION_MARK_SELECTOR = 'selection-mark-context'

const createDecoration = (value) => {
  const { selection } = value

  return {
    anchor: {
      key: selection.anchor.key,
      offset: selection.anchor.offset,
    },
    focus: {
      key: selection.focus.key,
      offset: selection.focus.offset,
    },
    mark: {
      data: {
        isFocused: selection.isFocused,
      },
      type: SELECTION_MARK_TYPE,
    },
  }
}

const isMarkFocused = (mark) => {
  return mark.data.get('isFocused')
}

function Selection() {
  let prevValue = null
  let isMouseDown = false

  const onDocumentMouseUp = () => {
    isMouseDown = false
  }

  return {
    queries: {
      canShowSelectionDropdown: (editor) => {
        if (isMouseDown) {
          return false
        }

        const { selection, document } = editor.value

        const selectionCommonAncestor = document.getCommonAncestor(
          selection.focus.key,
          selection.anchor.key
        )

        return (
          /* Show menu when selection is not empty */
          editor.value.selection.isExpanded &&
          editor.hasDecorations(SELECTION_MARK_TYPE) &&
          /* Do not show menu if selection is split between paragraph */
          selectionCommonAncestor.object !== 'document'
        )
      },
    },
    /**
     * Make sure that dropdown is visible only after user finished selecting
     */
    onMouseDown: (props, editor, next) => {
      isMouseDown = true
      document.addEventListener('mouseup', onDocumentMouseUp, { once: true })

      next()
    },

    renderMark: (props, editor, next) => {
      const { attributes, children, mark } = props

      if (mark.type === SELECTION_MARK_TYPE) {
        return (
          <span
            {...attributes}
            className={cn(
              SELECTION_MARK_SELECTOR,
              isMarkFocused(mark) && 'selection-mark-focused'
            )}
          >
            {children}
          </span>
        )
      }

      return next()
    },
    /**
     * Triggered when user selects/unselects text or moves the cursor
     * (typing is considered moving the cursor too)
     */
    onChange: (editor, next) => {
      const { value } = editor

      const shouldSelectionDecorationUpdate =
        !prevValue ||
        prevValue.selection.isFocused !== value.selection.isFocused ||
        prevValue.fragment.text !== value.fragment.text
      /**
       * Filter out decoration if present in the value
       */
      let decorations = value.decorations.filter((decoration) => {
        return decoration.mark.type !== SELECTION_MARK_TYPE
      })
      /**
       * If selection changed after previous event - refresh the decorations
       */
      if (shouldSelectionDecorationUpdate) {
        /**
         * Reset selection decoration only when selection is longer than 0
         * characters
         */
        if (value.selection.isExpanded) {
          const decoration = createDecoration(value)

          decorations = decorations.push(decoration)
        }

        editor.withoutSaving(() => {
          editor.setDecorations(decorations)
        })
      }
      /**
       * Cache previous value
       */
      prevValue = value
      next()
    },
  }
}

export default Selection
