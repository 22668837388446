import React from 'react'

import { useFeatureFlags } from '~/core/core-modules'
import {
  useOverrideIdentityCheckMutation,
  useUpdatePatientDetailsMutation,
} from '~/generated'

import { PatientDetails } from '../types'
import PatientDetailsModalView from './PatientDetailsModalView'

interface PatientDetailsModalContainerProps {
  onClose: () => void
  patient: PatientDetails | undefined
}

const PatientDetailsModalContainer = ({
  onClose,
  patient,
}: PatientDetailsModalContainerProps) => {
  const [
    updatePatientDetailsMutation,
    { loading: updateLoading, error: updateError },
  ] = useUpdatePatientDetailsMutation()
  const [
    overrideIdentityCheckMutation,
    { loading: overrideLoading, error: overrideError },
  ] = useOverrideIdentityCheckMutation()

  const { overrideIdEnabled } = useFeatureFlags()

  return (
    <PatientDetailsModalView
      updatePatientDetailsMutation={updatePatientDetailsMutation}
      overrideIdentityCheckMutation={overrideIdentityCheckMutation}
      patient={patient}
      updateLoading={updateLoading}
      overrideLoading={overrideLoading}
      overrideError={overrideError}
      updateError={updateError}
      onClose={onClose}
      enableIdVerification={overrideIdEnabled}
    />
  )
}

export default PatientDetailsModalContainer
